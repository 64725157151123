<h4 mat-dialog-title>{{ "FILES.TITLE" | translate }}: {{ entityName }}</h4>
<mat-dialog-content
  style="flex: 1; overflow: auto; min-height: 300px; min-width: 400px"
>
  <input
    #file
    type="file"
    accept="image/*"
    (change)="upload($event)"
    multiple
    style="display: none"
  />
  <mat-nav-list disableRipple="true" style="padding: 0; max-height: 500px">
    <mat-list-item *ngFor="let item of files">
      <h4 mat-line>{{ item.name }}</h4>
      <p mat-line class="mat-secondary-color">{{ item.created | date }}</p>

      <button
        mat-icon-button
        title="{{ 'COMMON.DOWNLOAD' | translate }}"
        (click)="download(item); $event.stopPropagation()"
      >
        <mat-icon>save</mat-icon>
      </button>
      <button
        mat-icon-button
        title="{{ 'COMMON.DELETE' | translate }}"
        (click)="delete(item.id); $event.stopPropagation()"
      >
        <mat-icon>close</mat-icon>
      </button>
      <mat-divider></mat-divider>
    </mat-list-item>
  </mat-nav-list>
</mat-dialog-content>
<mat-dialog-actions style="padding: 12px 0">
  <div
    style="
      flex: 1;
      text-align: left;
      display: flex;
      align-items: center;
      padding: 0 8px;
    "
  >
    <ng-container *ngIf="uploadTotal > 0">
      <mat-spinner
        [diameter]="24"
        color="primary"
        style="display: inline-block; vertical-align: middle; margin-right: 8px"
      >
      </mat-spinner>
      <div
        class="mat-body-1"
        style="
          display: inline-block;
          line-height: 24px;
          font-size: 12px;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        "
      >
        {{
          "FILES.UPLOAD_PROGRESS"
            | translate: { count: uploadCount, total: uploadTotal }
        }}
      </div>
    </ng-container>
  </div>
  <button
    #uploadButton
    mat-button
    color="accent"
    [disabled]="uploadTotal > 0"
    (click)="file.click()"
  >
    <mat-icon>playlist_add</mat-icon>
    {{ "FILES.UPLOAD" | translate }}
  </button>
  <button mat-button [mat-dialog-close]="false">
    {{ "COMMON.CLOSE" | translate }}
  </button>
</mat-dialog-actions>
