import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TrafficObjectAdaptiveStatus } from 'projects/msu-its-web-tlc/src/dtos/tlc.dtos';

@Component({
  selector: 'potok-state-adaptive',
  templateUrl: './potok-state-adaptive.component.html',
  styleUrls: [`./potok-state-adaptive.component.css`],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PotokStateAdaptiveComponent {
  @Input()
  status: TrafficObjectAdaptiveStatus;

  constructor() {
    setTimeout(() => console.log(this.status),1000);
  }
}
