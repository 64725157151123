import { Injectable } from "@angular/core";
import { IGroupShareService } from "projects/msu-its-web-common/src/components/groups/group-shares.component";
import { map } from "rxjs/operators";
import {
  EntityGroupPermissions,
  GetPublicTransportStopGroupSharesRequest,
  UpdatePublicTransportStopGroupShareRequest,
} from "../../dtos/ptm.dtos";
import { PtmApiService } from "../ptm-api.service";

@Injectable()
export class BusStopGroupShareService implements IGroupShareService {
  constructor(private _api: PtmApiService) {}

  getList(id: string, customerId: string) {
    const request = new GetPublicTransportStopGroupSharesRequest();
    request.id = id;
    request.customerId = customerId;

    request.items = 0;
    request.page = 0;
    request.sort = "customerName";

    return this._api.get(request).pipe(map((m) => m.items));
  }

  update(model: EntityGroupPermissions, customerId: string) {
    const request = new UpdatePublicTransportStopGroupShareRequest();
    request.id = model.entityGroupId;
    request.entity = model;
    request.customerId = customerId;
    return this._api.put(request);
  }
}
