import {
  ChangeDetectionStrategy,
  Component,
  Input,
  ChangeDetectorRef,
  AfterViewInit,
} from "@angular/core";
import { finalize, map } from "rxjs/operators";
import { TranslateService } from "@ngx-translate/core";

import { FileItem } from "../../dtos/hub.dtos";
import { FileService } from "../../services/file.service";

import { FlashService } from "../../services/flash.service";
import { DialogService } from "../../services/dialog.service";

@Component({
  selector: "files-component",
  templateUrl: "./files.component.html",
  styles: [
    `
      :host {
        max-width: 500px;
      }
      .mat-nav-list .mat-list-item.mat-2-line {
        height: 52px;
      }
      .mat-nav-list .mat-list-item ::ng-deep .mat-list-item-content {
        padding-right: 8px;
      }
      .mat-nav-list .mat-list-item .mat-line {
        font-size: 14px;
      }
      .mat-nav-list .mat-list-item .mat-line:nth-child(n + 2) {
        font-size: 10px;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FilesComponent implements AfterViewInit {
  files: FileItem[] = [];

  uploadSuccess = 0;
  uploadCount = 0;
  uploadTotal = 0;

  @Input()
  entityId: string;

  @Input()
  entityName: string;

  constructor(
    private _fileService: FileService,
    private _flashService: FlashService,
    private _dialogService: DialogService,
    private _changeDetector: ChangeDetectorRef,
    public translate: TranslateService
  ) {}

  ngAfterViewInit(): void {
    this._loadFiles();
  }

  private _loadFiles() {
    if (this.entityId) {
      this._fileService
        .getList(this.entityId)
        .pipe(
          map((item: FileItem[]) => {
            item.forEach((file) => {
              try {
                file.name = decodeURI(file.name);
              } catch (e) {
                console.error(e);
              }
            });

            return item;
          })
        )
        .subscribe((files: FileItem[]) => {
          this.files = files;
          this._changeDetector.markForCheck();
        });
    } else {
      this.files = [];
      this._changeDetector.markForCheck();
    }
  }

  upload(event) {
    this.uploadCount = 0;
    this.uploadTotal = event.target.files.length;
    for (let index = 0; index < event.target.files.length; index++) {
      const file = event.target.files[index];
      this._fileService
        .upload(file, this.entityId)
        .pipe(
          finalize(() => {
            this.uploadCount++;
            this._changeDetector.markForCheck();
            if (this.uploadSuccess === this.uploadTotal) {
              this._flashService.success(
                this.translate.instant("FILES.UPLOAD_COMPLETED")
              );
            }
            if (this.uploadCount === this.uploadTotal) {
              this.uploadCount = 0;
              this.uploadTotal = 0;
              this.uploadSuccess = 0;
              this._loadFiles();
            }
          })
        )
        .subscribe(() => this.uploadSuccess++);
    }
    this._changeDetector.markForCheck();
  }

  delete(fileId) {
    this._dialogService
      .confirm(this.translate.instant("FILES.FILE_CONFIRM_DELETE"))
      .subscribe((confirm) => {
        if (confirm) {
          this._fileService.delete(this.entityId, fileId).subscribe((file) => {
            if (file) {
              this._flashService.success(
                this.translate.instant("FILES.FILE_DELETED")
              );
            }
            this._loadFiles();
          });
        }
      });
  }

  download(file: FileItem) {
    this._fileService.getUrl(file.id).then((url) => {
      // const a = document.createElement('a');
      // a.href = url;
      // // a.download = file.name;
      // a.target = '_blank';
      // // a.type = file.contentType;
      // document.body.appendChild(a);
      // a.click();
      // document.body.removeChild(a);

      window.open(url, "_blank");
    });
  }
}
