import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
} from '@angular/core';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { TranslateService } from '@ngx-translate/core';
import { SettingsService } from 'projects/msu-its-web-common/src/services/settings.service';

import { LayoutSvgUtils } from 'projects/msu-its-web-tlc/src/dtos/layout';
import { SchemaUtils } from 'projects/msu-its-web-tlc/src/dtos/schema';
import {
  PotokMovementType,
  PotokPlanType,
  TrafficMovementType,
  TrafficObjectState,
  TrafficObjectStatus,
  TrafficSchemaLight,
} from 'projects/msu-its-web-tlc/src/dtos/tlc.dtos';

import { getPlanStates, PotokPlan } from 'projects/msu-its-web-tlc/src/dtos/potok/potok-plan';

import { IPotokTrafficObjectSet, PotokStateService } from './potok-state.service';
import { PotokTrafficObjectService } from 'projects/msu-its-web-tlc/src/services/potok-traffic-object.service';

@Component({
  selector: 'potok-state-buttons',
  templateUrl: './potok-state-buttons.component.html',
  styleUrls: ['./potok-state-buttons.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PotokStateButtonsComponent implements AfterViewInit, OnDestroy {
  _destroy = new Subject();

  @Input()
  disabled = false;

  status: TrafficObjectStatus;
  trafficObjectSet: IPotokTrafficObjectSet;

  states: TrafficMovementType[][][] = [[], []];
  stateType = TrafficObjectState;

  get schema() {
    return this.trafficObjectSet.schemaSet.schema;
  }
  get schemaView() {
    return this.trafficObjectSet.schemaSet.schemaView;
  }

  constructor(
    private _stateService: PotokStateService,
    private _potokTrafficObjectService: PotokTrafficObjectService,
    private _settingsService: SettingsService,
    private _changeDetector: ChangeDetectorRef,
    public translate: TranslateService
  ) {
    this._stateService.trafficObjectSet$
      .pipe(takeUntil(this._destroy))
      .subscribe((trafficObjectSet) => {
        this.trafficObjectSet = trafficObjectSet;
        this._loadStates();
        this._changeDetector.markForCheck();
      });

    this._stateService.status$.pipe(takeUntil(this._destroy)).subscribe((status) => {
      this.status = status;
      this._changeDetector.markForCheck();
    });
  }

  ngAfterViewInit() {}

  ngOnDestroy() {
    this._destroy.next();
    this._destroy.complete();
  }

  setState(movements?: PotokMovementType[]) {
    this._stateService
      .loadingQuery(
        this._potokTrafficObjectService.setRemoteMode(
          this._stateService.modelId,
          this._stateService.customerId,
          movements
        )
      )
      .subscribe((status) => {
        this._stateService.status = status;
      });
  }

  setYellowBlink() {
    this._stateService
      .loadingQuery(
        this._potokTrafficObjectService.setYellowBlinkMode(
          this._stateService.modelId,
          this._stateService.customerId
        )
      )
      .subscribe((status) => {
        this._stateService.status = status;
      });
  }

  setAllRed() {
    this._stateService
      .loadingQuery(
        this._potokTrafficObjectService.setAllRedMode(
          this._stateService.modelId,
          this._stateService.customerId
        )
      )
      .subscribe((status) => {
        this._stateService.status = status;
      });
  }

  setOff() {
    this._stateService
      .loadingQuery(
        this._potokTrafficObjectService.setOffMode(
          this._stateService.modelId,
          this._stateService.customerId
        )
      )
      .subscribe((status) => {
        this._stateService.status = status;
      });
  }

  setSvgState(element, state: TrafficMovementType[]) {
    if (!state) return;

    const schema = this.schema;
    const schemaView = this.schemaView;
    const isDark = this._settingsService.darkTheme;
    const svg = element.contentDocument;

    const movements = [];
    state.forEach((m) => {
      movements.push(...SchemaUtils.schemaViewMovements(schema, m));
    });

    LayoutSvgUtils.updateLayoutSchema(svg, schema, schemaView, isDark);
    LayoutSvgUtils.showLayoutMovements(svg, schema, movements);
  }

  _loadStates() {
    if (!this.trafficObjectSet) {
      this.states = [];
      return;
    }

    const plans = this.trafficObjectSet.settings.plans;
    const cyclePlans = Object.keys(plans)
      .map((k) => plans[k] as PotokPlan)
      .filter((m) => m.enabled && m.type === PotokPlanType.PhaseCycle);

    if (cyclePlans.length) {
      const plan = cyclePlans[0];
      const barriers = plan.pedestrianPhasesEnabled
        ? plan.pedestrianBarriers
        : plan.transportBarriers;
      this.states = [].concat(getPlanStates(barriers));
    } else {
      this.states = [];
    }
  }

  isSchemaState(state: TrafficMovementType[], schemaLight: TrafficSchemaLight) {
    const schema = this.schema;
    if (schema && schemaLight) {
      const movements = [];
      state.forEach((m) => movements.push(...SchemaUtils.schemaViewMovements(schema, m)));

      const m1 = SchemaUtils.schemaLightToMovements(schema, schemaLight);
      const m2 = movements;
      return m1.length === m2.length && m1.every((m) => m2.includes(m));
    }
    return false;
  }
}
