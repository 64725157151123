import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';

import { TlcApiService } from './tlc-api.service';

import {
  UsdkPlans,
  UsdkSettings,
  SetUsdkControllerRequest,
  SetUsdkControllerAdaptiveModeRequest,
  SetUsdkControllerCoordinationModeRequest,
  SetUsdkControllerOfflineModeRequest,
  SetUsdkControllerRemoteModeRequest,
  GetUsdkControllerSettingsRequest,
  UpdateUsdkControllerSettingsRequest,
  SetUsdkControllerYellowBlinkModeRequest,
  GetUsdkTrafficObjectStatusRequest,
  SetUsdkControllerLocalModeRequest,
  DetachTrafficObjectControllerRequest,
} from '../dtos/tlc.dtos';

import { Schedule } from '../dtos/schedule';
import { UsdkPlan } from '../dtos/usdk/usdk-plan';
import { of } from 'rxjs';

@Injectable()
export class UsdkTrafficObjectService {
  constructor(private _api: TlcApiService) {}

  getSettings(id: string, customerId: string) {
    const request = new GetUsdkControllerSettingsRequest();
    request.id = id;
    request.customerId = customerId;
    return this._api.get(request).pipe(tap((m) => this.assignSettings(m)));
  }
  updateSettings(
    id: string,
    customerId: string,
    settings: UsdkSettings,
    uploadToController: boolean,
    forceUpdate: boolean
  ) {
    const request = new UpdateUsdkControllerSettingsRequest();
    request.id = id;
    request.settings = settings;
    request.customerId = customerId;
    request.uploadSettingsToController = uploadToController;
    request.forceUpdate = forceUpdate;
    return this._api.put(request).pipe(tap((m) => this.assignSettings(m)));
  }
  assignSettings(settings: UsdkSettings) {
    const newSchedule = new Schedule();
    if (settings.schedule?.year) {
      settings.schedule.year = Object.assign(newSchedule.year, settings.schedule.year);
    }
    settings.schedule = Object.assign(newSchedule, settings.schedule);

    settings.phases = settings.phases || [];

    const plans = new UsdkPlans({
      plan01: new UsdkPlan(),
      plan02: new UsdkPlan(),
      plan03: new UsdkPlan(),
      plan04: new UsdkPlan(),
      plan05: new UsdkPlan(),
      plan06: new UsdkPlan(),
      plan07: new UsdkPlan(),
      plan08: new UsdkPlan(),
      plan09: new UsdkPlan(),
      plan10: new UsdkPlan(),
      plan11: new UsdkPlan(),
      plan12: new UsdkPlan(),
    });
    if (settings.plans) {
      Object.keys(plans).forEach((k) => {
        settings.plans[k] = Object.assign(plans[k], settings.plans[k]);
      });
    } else {
      settings.plans = plans;
    }
  }

  setRemoteMode(id: string, customerId: string, phase: number, time = 180) {
    const request = new SetUsdkControllerRemoteModeRequest();
    request.id = id;
    request.usdkPhase = phase;
    request.modeTime = time;
    request.customerId = customerId;
    return this._api.post(request);
  }
  setYellowBlinkMode(id: string, customerId: string) {
    const request = new SetUsdkControllerYellowBlinkModeRequest();
    request.id = id;
    request.customerId = customerId;
    return this._api.post(request);
  }
  setCoordinationMode(id: string, customerId: string) {
    const request = new SetUsdkControllerCoordinationModeRequest();
    request.id = id;
    request.customerId = customerId;
    return this._api.post(request);
  }
  setOfflineMode(id: string, customerId: string) {
    const request = new SetUsdkControllerOfflineModeRequest();
    request.id = id;
    request.customerId = customerId;
    return this._api.post(request);
  }
  setAdaptiveMode(id: string, customerId: string) {
    const request = new SetUsdkControllerAdaptiveModeRequest();
    request.id = id;
    request.customerId = customerId;
    return this._api.post(request);
  }
  setLocalMode(id: string, customerId: string) {
    const request = new SetUsdkControllerLocalModeRequest();
    request.id = id;
    request.customerId = customerId;
    return this._api.post(request);
  }

  changeController(
    id: string,
    customerId: string,
    controllerId: string,
    uploadSettings: boolean,
    description: string
  ) {
    const request = new SetUsdkControllerRequest();
    request.id = id;
    request.newControllerId = controllerId;
    request.uploadSettingsToController = uploadSettings;
    request.description = description;
    request.customerId = customerId;
    return this._api.put(request);
  }

  getStatus(id: string, customerId: string, querySate = false) {
    const request = new GetUsdkTrafficObjectStatusRequest();
    request.id = id;
    request.customerId = customerId;
    request.queryState = querySate;
    return this._api.get(request);
  }
}
