<div *ngIf="loading" class="backdrop" style="z-index: 1000">
  <mat-spinner diameter="36" color="accent"></mat-spinner>
</div>
<h4 mat-dialog-title>
  <span *ngIf="isNew">{{ "METEO_OBJECTS.CREATE_METEO_OBJECT" | translate }}</span>
  <span *ngIf="!isNew">{{ "METEO_OBJECTS.EDIT_METEO_OBJECT" | translate }}</span>
</h4>
<mat-dialog-content style="flex: 1">
  <div style="overflow: overlay; margin-left: -12px">
    <div fxLayout="row" fxLayoutGap="24px" style="min-width: 900px; min-height: 600px">
      <div>
        <form [formGroup]="formGroup" (ngSubmit)="submit()" autocomplete="off">
          <div fxLayout="column" fxLayoutGap="12px" style="min-width: 250px; padding-left: 12px">
            <mat-form-field style="width: 100%">
              <input matInput [formControl]="name" placeholder="{{ 'COMMON.NAME' | translate }}" autocomplete="off" maxlength="100" />
              <mat-hint>{{ "METEO_OBJECTS.OBJECT_NAME_HINT" | translate }}</mat-hint>
              <mat-error *ngIf="name.hasError('minlength') || name.hasError('maxlength')">
                {{ "DETECTORS.NAME_LENGTH" | translate: { min: 4, max: 100 } }}
              </mat-error>
              <mat-error *ngIf="name.hasError('required')">
                {{ "DETECTORS.NAME_REQUIRED" | translate }}
              </mat-error>
              <mat-error *ngIf="name.hasError('exist')">
                {{ "TRAFFIC_OBJECTS.NAME_EXIST" | translate }}
              </mat-error>
            </mat-form-field>

            <mat-form-field style="width: 100%">
              <mat-select
                (openedChange)="$event && searchInput.focus()"
                [formControl]="meteoStationId"
                (selectionChange)="controllerSelected($event.value)"
                placeholder="{{ 'METEO_OBJECTS.STATIONS.TITLE' | translate }}"
                disableOptionCentering="true"
                panelClass="single-select-panel"
              >
                <mat-select-trigger> {{ selectedController?.prefix }} {{ selectedController?.number }} </mat-select-trigger>
                <div
                  (click)="$event.stopPropagation()"
                  class="mat-theme-bg"
                  style="position: sticky; top: 0; z-index: 2; padding: 8px 12px; font-size: 14px"
                >
                  <input
                    #searchInput
                    class="mat-input-element"
                    [placeholder]="('COMMON.SEARCH' | translate) + '...'"
                    autocomplete="off"
                    spellcheck="false"
                    (keyup)="searchInputSubject.next($event.target.value)"
                    (input)="searchInputSubject.next($event.target.value)"
                    (keydown)="$event.stopPropagation()"
                    (click)="$event.stopPropagation()"
                    style="width: 160px"
                  />
                  <!-- <div class=checkbox>
                      <label><input type="checkbox">Another Longer</label>
                  </div> -->
                  <mat-checkbox [formControl]="onlyFreeCheck" style="position: absolute; margin-left: 7px; margin-top: -2px">
                    {{ "DEVICES.NOT_BUSY" | translate }}</mat-checkbox
                  >
                </div>
                <mat-option *ngIf="selectedController" [value]="selectedController.id" style="height: 60px !important">
                  <div style="line-height: 1">{{ selectedController.prefix }} {{ selectedController.number }}</div>
                  <div
                    *ngIf="selectedController.ownerObjectName"
                    style="line-height: 1; text-overflow: ellipsis; overflow: hidden; color: #f54545bf; margin-top: 2px"
                    class="mat-hint"
                  >
                    {{ "DEVICES.CONNECTED_TO" | translate }}
                    {{ selectedController.ownerObjectName }}
                  </div>
                  <div style="line-height: 1; text-overflow: ellipsis; overflow: hidden; margin-top: 2px" class="mat-hint">
                    <small>{{ selectedController.description }}</small>
                  </div>
                </mat-option>
                <mat-option *ngFor="let item of controllerListSubject | async" [value]="item.id" style="height: 60px !important">
                  <div style="line-height: 1">{{ item.prefix }} {{ item.number }}</div>
                  <div
                    *ngIf="item.ownerObjectName"
                    style="line-height: 1; text-overflow: ellipsis; overflow: hidden; color: #f54545bf; margin-top: 2px"
                    class="mat-hint"
                  >
                    {{ "DEVICES.CONNECTED_TO" | translate }}{{ item.ownerObjectName }}
                  </div>
                  <div style="line-height: 1; text-overflow: ellipsis; overflow: hidden" class="mat-hint">
                    <small>{{ item.description }}</small>
                  </div>
                </mat-option>
              </mat-select>
              <mat-hint>{{ "DEVICES.CHOOSE_DEVICE" | translate }} </mat-hint>
              <mat-error *ngIf="meteoStationId.hasError('required')">
                {{ "DEVICES.DEVICE_REQUIRED" | translate }}
              </mat-error>
            </mat-form-field>

            <!-- <mat-form-field fxFlex>
                <mat-label>{{ "DETECTORS.USER_STATUS" | translate }}</mat-label>
                <mat-select [formControl]="userStatus" [disableOptionCentering]="true">
                  <mat-option *ngFor="let item of detectorUserStatus" [value]="item.status">
                    <mat-divider style></mat-divider>
                    {{ item.name | translate }}
                  </mat-option>
                </mat-select>
                <mat-hint>{{ "DEVICES.CHOOSE_USER_STATUS" | translate }} </mat-hint>
                <mat-error *ngIf="userStatus.hasError('required')">
                  {{ "DETECTORS.USER_STATUS_REQUIRED" | translate }}
                </mat-error>
              </mat-form-field> -->

            <mat-form-field>
              <input matInput disabled [value]="location?.latlon" />
              <mat-label>{{ "COMMON.LOCATION" | translate }}</mat-label>
              <mat-hint>{{ "DETECTORS.LOCATION_HINT" | translate }}</mat-hint>
              <button matSuffix mat-icon-button (click)="location.prompt()" title="{{ 'COMMON.EDIT' | translate }}">
                <mat-icon>edit</mat-icon>
              </button>
            </mat-form-field>

            <div style="display: flex; padding: 8px 0 4px 0">
              <mat-checkbox
                [checked]="direction?.value != null"
                style="margin-right: 24px; padding-top: 12px"
                (change)="direction?.setValue($event.checked ? 0 : null); onDirectionChanged()"
              >
                {{ "COMMON.DIRECTION" | translate }}
              </mat-checkbox>
              <mat-slider
                [min]="0"
                [max]="360"
                [value]="direction?.value"
                style="flex: 1"
                [disabled]="direction?.value == null"
                (input)="direction?.setValue($event.value); onDirectionChanged()"
              >
              </mat-slider>
            </div>

            <mat-form-field style="width: 100%">
              <mat-label>{{ "GROUPS.GROUPS" | translate }}</mat-label>
              <mat-select [formControl]="selectedGroups" multiple disableOptionCentering panelClass="multiple-select-panel left">
                <!-- <mat-select-trigger>
                    <mat-chip-list>
                      <mat-chip
                        *ngFor="let groupId of selectedGroups.value"
                        [removable]="true"
                        (removed)="onGroupRemoved(groupId)"
                        style="min-height: 28px"
                      >
                        {{ getGroupName(groupId) }}
                        <mat-icon matChipRemove>cancel</mat-icon>
                      </mat-chip>
                    </mat-chip-list>
                  </mat-select-trigger> -->
                <mat-option *ngFor="let group of groupList" [value]="group.id">
                  {{ group.name }}
                </mat-option>
              </mat-select>
              <mat-hint>{{ "GROUPS.GROUPS_HINT" | translate }}</mat-hint>
            </mat-form-field>

            <mat-form-field style="width: 100%">
              <textarea
                matInput
                [formControl]="description"
                placeholder="{{ 'COMMON.DESCRIPTION' | translate }}"
                matTextareaAutosize
                matAutosizeMinRows="2"
                matAutosizeMaxRows="5"
                spellcheck="false"
              ></textarea>
            </mat-form-field>

            <div>
              <mat-checkbox disableRipple [checked]="externalSystem" (change)="externalSystem = $event.checked">
                {{ "COMMON.EXTERNAL_SYSTEM" | translate }}
              </mat-checkbox>

              <div *ngIf="externalSystem" style="margin-top: 12px">
                <mat-form-field style="width: 100%">
                  <mat-label>{{ "COMMON.EXTERNAL_SYSTEM" | translate }}</mat-label>
                  <mat-select [formControl]="externalSystemId" disableOptionCentering panelClass="single-select-panel left">
                    <mat-option *ngFor="let system of externalSystemList" [value]="system.id">
                      {{ system.name }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
                <mat-form-field style="width: 100%">
                  <input
                    matInput
                    [formControl]="externalSystemEntityId"
                    placeholder="{{ 'COMMON.EXTERNAL_SYSTEM_ENTITY_ID' | translate }}"
                    autocomplete="off"
                  />
                </mat-form-field>
              </div>
            </div>
          </div>
        </form>
      </div>
      <div style="flex: 1">
        <location-component #location [lat]="model.lat" [lon]="model.lon" [direction]="model.direction"></location-component>
      </div>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button (click)="changeDevice()" color="primary">
    <span *ngIf="external">{{ "DETECTORS.CHANGE_DEVICE" | translate }}</span>
  </button>
  <!-- [disabled]="isDisabledButton"  -->
  <button mat-button (click)="submit()" color="primary">
    <span *ngIf="isNew">{{ "COMMON.CREATE" | translate }}</span>
    <span *ngIf="!isNew">{{ "COMMON.SAVE" | translate }}</span>
  </button>
  <button mat-button (click)="close()">
    {{ "COMMON.CANCEL" | translate }}
  </button>
</mat-dialog-actions>
