<div class="mat-theme-bg mat-elevation-z2" *ngIf="status && schemaSet && plan && planBarriers" style="position: relative; height: 120px">
  <mat-progress-bar [value]="(status.planTime * 100) / plan?.length" class="mat-theme-bg" style="position: absolute; width: 100%; height: 100%; top: 0; left: 0">
  </mat-progress-bar>

  <!-- [class.pedestrian]="trafficStatus?.planPhasesType == 'Pedestrian'" -->

  <div class="plan" [class.adaptive]="status?.mode == 'Adaptive'" style="position: absolute; width: 100%; height: 100%; top: 0; left: 0">
    <div *ngFor="let barrier of planBarriers; let i = index" class="barrier" [style.flex]="barrier.length">
      <ng-container *ngIf="barrier.type == 'Static'">
        <div class="ring">
          <div class="phase">
            <div class="item">
              <div class="label">{{ getBarrierPartNames(barrier.type, i + 1).join(":") }}:{{ barrier.length }}</div>
              <div [style.transform]="'rotate(' + schemaView?.angle + 'deg)'">
                <object
                  data="/assets/images/tlc/movements_static.svg"
                  style="max-height: 70px"
                  (load)="updateStaticSvg($event.target, barrier)"
                ></object>
              </div>
            </div>
          </div>
        </div>
      </ng-container>

      <ng-container *ngIf="barrier.type != 'Static'">
        <ng-container *ngFor="let ring of getRings(i)">
          <div class="ring">
            <div *ngFor="let part of ring" class="phase" [style.flex]="part.item.length">
              <ng-container *ngIf="part.type == 'Left'">
                <div class="item">
                  <div class="label">{{ getBarrierPartNames(barrier.type, i + 1, part).join(":") }}:{{ part.item.length }}</div>
                  <div [style.transform]="'rotate(' + schemaView?.angle + 'deg)'">
                    <object
                      data="/assets/images/tlc/movements_left.svg"
                      [class]="barrier.type + part.ringNumber + part.type"
                      (load)="updatePartSvg($event.target, barrier.type, part)"
                    ></object>
                  </div>
                </div>
              </ng-container>
              <ng-container *ngIf="part.type == 'Straight'">
                <div
                  *ngIf="part.item.pedestrianMovement && part.item.pedestrianMovementLength"
                  [style.flex]="part.item.pedestrianMovementLength"
                  class="item"
                >
                  <div class="label">
                    {{ getBarrierPartNames(barrier.type, i + 1, part, true).join(":") }}:{{ part.item.pedestrianMovementLength }}
                  </div>
                  <div [style.transform]="'rotate(' + schemaView?.angle + 'deg)'">
                    <object
                      data="/assets/images/tlc/movements_straight.svg"
                      [class]="barrier.type + part.ringNumber + part.type"
                      (load)="updatePartSvg($event.target, barrier.type, part, true)"
                    ></object>
                  </div>
                </div>

                <div
                  *ngIf="
                    part.item.straightMovement && (!part.item.pedestrianMovementLength || part.item.length != part.item.pedestrianMovementLength)
                  "
                  [style.flex]="part.item.length - part.item.pedestrianMovementLength"
                  class="item _straight"
                >
                  <div class="label">
                    {{ getBarrierPartNames(barrier.type, i + 1, part).join(":") }}:{{ part.item.length - part.item.pedestrianMovementLength }}
                  </div>
                  <div [style.transform]="'rotate(' + schemaView?.angle + 'deg)'">
                    <object
                      data="/assets/images/tlc/movements_straight.svg"
                      [class]="barrier.type + part.ringNumber + part.type"
                      (load)="updatePartSvg($event.target, barrier.type, part)"
                    ></object>
                  </div>
                </div>
              </ng-container>
            </div>
          </div>
        </ng-container>
      </ng-container>
    </div>
  </div>
</div>
