import { Injectable, Inject, Injector } from '@angular/core';
import { APP_ENVIRONMENT } from 'projects/msu-its-web-common/src/utils/shared-consts';
import { BaseApiService } from 'projects/msu-its-web-common/src/services/base-api.service';

@Injectable()
export class AccidentApiService extends BaseApiService {
  constructor(injector: Injector, @Inject(APP_ENVIRONMENT) appEnv) {
    super(injector, appEnv.modules.accident.apiUrl, 'Accident');
  }

  upload(file, url?: string) {
    const formData = new FormData();
    formData.append('file', file);
    return this._handleResult(
      fetch(url, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          Authorization: `Bearer ${this._tokenService.bearerToken}`,
        },
        mode: 'cors',
        body: formData,
      }).then((response) => response.text()),
      false
    );
  }
}
