import {
  Component,
  ChangeDetectionStrategy,
  ElementRef,
  OnInit,
  Input,
  ViewChild,
  OnDestroy,
  AfterViewInit,
  ChangeDetectorRef,
} from '@angular/core';
import { MatMenu, MatMenuTrigger } from '@angular/material/menu';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { MapService } from '../../services/map.service';
import { SettingsService } from '../../services/settings.service';
import { SidenavService } from '../../services/sidenav.service';

@Component({
  selector: 'map-component',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MapComponent implements AfterViewInit, OnInit, OnDestroy {
  _destroy = new Subject();

  map: any;

  @Input()
  options: any = {};

  @Input()
  options2: any = {};

  @Input()
  zoomControl = true;

  @Input()
  layersControl = true;

  @ViewChild('map', { static: true })
  _mapContainer: ElementRef;

  @ViewChild('layersMenuTrigger', { static: false })
  _layersMenuTrigger: MatMenuTrigger;

  constructor(
    private _settingsService: SettingsService,
    private _sidenavService: SidenavService,
    private _mapService: MapService,
    private _changeDetector: ChangeDetectorRef
  ) {}

  ngAfterViewInit() {}

  ngOnInit() {
    this.map = this._mapService
      .initMap(this._mapContainer.nativeElement, this.options, this.options2)
      .on('zoom', () => {
        this._changeDetector.markForCheck();
      });

    this._sidenavService.changedAnimationDone
      .pipe(takeUntil(this._destroy))
      .subscribe(() => {
        this.map.invalidateSize({ pan: false });
      });

    // setTimeout(() => {
    //   this.map.setView(
    //     this._settingsService.mapCenter,
    //     this._settingsService.mapZoom
    //   );
    // });
  }

  ngOnDestroy() {
    this._destroy.next();
    this._destroy.complete();

    this.map.remove();
    this.map = null;
  }

  get layers() {
    return this.map.options.layerLists;
  }

  get currentLayerId() {
    return this.map.options.currentLayer.options.id;
  }

  get trafficLayer() {
    return this.map.options.trafficLayer;
  }

  get showTraffic() {
    return this._settingsService.mapTraffic;
  }

  get showHeatMapTrafficAccident() {
    return this._settingsService.trafficAccident;
  }

  get showTrafficDisabled() {
    return !this.map.options.currentLayer.options.trafficLayer;
  }

  setLayer(id: string) {
    const layer = this.map.options.layerLists.find((m) => m.options.id == id);
    this.map.removeLayer(this.map.options.currentLayer);
    this.map.addLayer(layer);
    this.map.fire('baselayerchange', { layer });
  }

  setShowTraffic(checked: boolean) {
    this._settingsService.mapTraffic = checked;
  }
  setTrafficAccident(checked: boolean) {
    this._settingsService.trafficAccident = checked;
  }

  closeLayersMenu() {
    this._layersMenuTrigger?.closeMenu();
  }

  get zoomInDisabled() {
    return this.map._zoom === this.map.getMaxZoom();
  }

  get zoomOutDisabled() {
    return this.map._zoom === this.map.getMinZoom();
  }

  zoomIn(event: MouseEvent) {
    if (this.map._zoom < this.map.getMaxZoom()) {
      this.map.zoomIn(this.map.options.zoomDelta * (event.shiftKey ? 3 : 1));
    }
  }

  zoomOut(event: MouseEvent) {
    if (this.map._zoom > this.map.getMinZoom()) {
      this.map.zoomOut(this.map.options.zoomDelta * (event.shiftKey ? 3 : 1));
    }
  }
}
