import { marker as _ } from "@biesbjerg/ngx-translate-extract-marker";

import {
  Component,
  ChangeDetectionStrategy,
  OnInit,
  Input,
  ChangeDetectorRef,
  OnDestroy,
  Inject,
} from "@angular/core";
import { DateAdapter, ErrorStateMatcher } from "@angular/material/core";
import { MatDialogRef } from "@angular/material/dialog";
import { MatTabChangeEvent } from "@angular/material/tabs";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  FormGroupDirective,
  NgForm,
  Validators,
} from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";

import { LANGS_LOCALES } from "projects/msu-its-web-common/src/utils/langs";
import { chartOptions } from "projects/msu-its-web-common/src/utils//e-charts";
import {
  BaseUnit,
  MeasureParameter,
  MeteoObject,
  MeteoObjectReportType,
  MeteoParameterGraph,
  MeteoSensor,
  TableValue,
} from "../../dtos/meteo.dtos";
import {
  METEO_NAME,
  METEO_STATION_NAME,
  METEO_UNITS,
  TYPE_PRECIPITATION,
} from "../../dtos/enum";

import { MeteoObjectPopupService } from "../../services/meteo-object-popup.service";

import * as echarts from "echarts";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { FlashService } from "projects/msu-its-web-common/src/services/flash.service";
import { TokenService } from "projects/msu-its-web-common/src/services/token.service";
import { APP_ENVIRONMENT } from "projects/msu-its-web-common/src/utils/shared-consts";

const TYPE_EXPORT = [
  { type: MeteoObjectReportType.CSV, name: _("DETECTORS.EXPORT_CSV") },
  { type: MeteoObjectReportType.XLSX, name: _("DETECTORS.EXPORT_XLS") },
];

@Component({
  selector: "meteo-object-report",
  templateUrl: "./meteo-object-report.component.html",
  styles: [
    `
      .backdrop {
        top: 45px;
      }
      .chartGraf {
        width: 100%;
        /*min-height: 380px;*/
        margin-top: -12px;
        height: 100%;
        z-index: 2;
      }

      :host ::ng-deep .mat-tab-body-wrapper {
        flex: 1;
      }
      :host ::ng-deep .mat-tab-list .mat-tab-label {
        min-width: 80px !important;
      }

      .table {
        border: none;
        border-spacing: 0;
        width: 100%;
        font-size: 14px;
      }
      td {
        padding: 8px;
        font-size: 14px;
        border-bottom: solid 1px rgba(125, 125, 125, 0.15);
        vertical-align: middle;
      }
      td:first-child {
        padding-left: 0px;
      }
      td:last-child {
        padding-right: 0px;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MeteoObjectReportComponent implements OnInit, OnDestroy {
  private _destroy = new Subject();

  @Input()
  modelId: string;

  @Input()
  customerId: string;

  @Input()
  modelInfo: MeteoObject;

  @Input()
  meteoSensor: MeteoSensor[];

  rangeErrorStateMatcher: ErrorStateMatcher = {
    isErrorState(
      control: FormControl,
      form: FormGroupDirective | NgForm
    ): boolean {
      return false;
    },
  };

  chart = true;
  external = false;

  loading = false;
  loadGraph = false;
  loadLatestData = false;
  noData = false;

  itemTable: TableValue[];
  itemTableTranslation: { type: string; name: string }[];
  itemGraph: MeteoParameterGraph;

  selectedMeteoSensor: MeteoSensor;

  meteoUint = METEO_UNITS;
  meteoCategoryName = METEO_NAME;
  meteoStationName = METEO_STATION_NAME;
  typesFile = TYPE_EXPORT;

  chartParams;
  chartOptions = chartOptions;

  selectedMeteoSensorValue: MeteoSensor;

  formGroup: FormGroup = this._formBuilder.group({
    typeFile: [MeteoObjectReportType.XLSX, [Validators.required]],
    meteoParams: [null, [Validators.required]],
    meteoSensorControl: [null, [Validators.required]],
    dateFrom: [null, [Validators.required]],
    dateTo: [null, [Validators.required]],
  });
  get meteoParams() {
    return this.formGroup.get("meteoParams");
  }
  get meteoSensorControl() {
    return this.formGroup.get("meteoSensorControl");
  }
  get dateFrom() {
    return this.formGroup.get("dateFrom");
  }
  get dateTo() {
    return this.formGroup.get("dateTo");
  }
  get typeFile() {
    return this.formGroup.get("typeFile");
  }

  get locale() {
    return LANGS_LOCALES[this.translate.currentLang];
  }

  constructor(
    private _changeDetector: ChangeDetectorRef,
    private _meteoObjectPopupService: MeteoObjectPopupService,
    private _dialogRef: MatDialogRef<MeteoObjectReportComponent>,
    private _formBuilder: FormBuilder,
    private _dateAdapter: DateAdapter<any>,
    private _tokenService: TokenService,
    public translate: TranslateService,
    @Inject(APP_ENVIRONMENT) private appEnv: any
  ) {}

  ngOnDestroy() {
    this._destroy.next();
    this._destroy.complete();
  }

  ngOnInit() {
    this._dateAdapter.setLocale(this.locale);

    this.selectedMeteoSensor = this.meteoSensor[0];

    const from = new Date();
    this.dateFrom.setValue(
      new Date(from.getFullYear(), from.getMonth(), from.getDate())
    );
    const to = new Date();
    to.setDate(from.getDate() + 1);
    this.dateTo.setValue(
      new Date(to.getFullYear(), to.getMonth(), to.getDate())
    );
  }

  _loadSectionTab() {
    this._meteoObjectPopupService
      .getAvailableMeteoObjectSensorsRequest(this.modelId, 100, 100, "", "")
      .pipe(takeUntil(this._destroy))
      .subscribe((result) => {
        this.meteoSensor = result.items;

        this._changeDetector.markForCheck();
      });
  }

  changeEvent(event: MatTabChangeEvent) {
    this.selectedMeteoSensor = this.meteoSensor[event.tab.textLabel];
    this.meteoParams.setValue(this.selectedMeteoSensor.measureParameters[0].id);
    this._changeDetector.markForCheck();
  }

  getCategoryName(categoryItem: MeasureParameter) {
    const itemName = this.meteoCategoryName.find(
      (item) => item.type === categoryItem.name
    );
    return itemName.name;
  }

  getMeteoStationName(categoryItem: MeasureParameter) {
    const itemName = this.meteoStationName.find(
      (item) => item.name === categoryItem.name
    );
    return itemName ? _(itemName.nameTransl) : categoryItem.name;
  }

  getCategoryUnit(categoryItem: BaseUnit, text?: string) {
    const itemName = this.meteoUint.find((item) => item.type === categoryItem);
    return itemName?.name ? itemName.name : "";
  }

  submit() {
    if (this.formGroup.invalid) {
      return;
    }
    this.loading = true;

    const from = new Date(this.dateFrom.value);
    const to = new Date(this.dateTo.value);

    const params = new URLSearchParams();

    params.append("id", this.modelId);
    params.append("sensorType", this.meteoSensorControl.value.type);
    params.append(
      "measureParameterIds",
      JSON.stringify(this.meteoParams.value)
    );
    params.append("from", from.toISOString());
    params.append("reportType", this.typeFile.value);
    params.append("to", to.toISOString());
    params.append("timeZoneOffsetFromUtc", "7");

    const url = `${this.appEnv.modules.meteo.apiUrl}/json/reply/GetMeteoObjectReport?`;

    return fetch(url + params, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + this._tokenService.bearerToken,
      },
    })
      .then(function (resp) {
        return resp.blob();
      })
      .then((blob) => {
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement("a");
        a.href = url;
        a.download = `Отчет по метео ${from.toLocaleDateString()} - ${to.toLocaleDateString()}.${
          this.typeFile.value
        }`;
        document.body.appendChild(a);
        a.click();
        a.remove();
      })
      .finally(() => {
        this.loading = false;
        this._changeDetector.markForCheck();
      });
  }

  onResize($event) {
    this._changeDetector.markForCheck();
  }

  getTableItemName(type: string) {
    return (
      this.itemTableTranslation?.find((m) => m.type === type)?.name || type
    );
  }
}
