import { Inject, Injectable } from "@angular/core";
import {
  IDataSourceService,
  IPageParams,
  IPageReturn,
  setPageParams,
  getPageReturn,
} from "projects/msu-its-web-common/src/data/data-source";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import {
  CameraCommand,
  CreatePtzCameraRequest,
  DeletePtzCamerasRequest,
  GetPtzCameraCurrentUserPermissionsRequest,
  GetPtzCameraRequest,
  GetPtzCamerasLocationRequest,
  PtzCamera,
  PtzCameraCommandRequest,
  PtzCameraLocation,
  UpdatePtzCameraRequest,
} from "../../dtos/accident.dtos";
import { AccidentApiService } from "../accident-api.service";
import { APP_ENVIRONMENT } from "projects/msu-its-web-common/src/utils/shared-consts";
import { TokenService } from "projects/msu-its-web-common/src/services/token.service";

@Injectable()
export class CameraPTZService
  implements IDataSourceService<PtzCameraLocation, PtzCameraLocation> {
  constructor(
    private _api: AccidentApiService,
    private _tokenService: TokenService,
    @Inject(APP_ENVIRONMENT) private _appEnv
  ) {}

  getPagedList(
    params: IPageParams,
    customerId: string
  ): Observable<IPageReturn<PtzCameraLocation>> {
    const request = new GetPtzCamerasLocationRequest();
    request.customerId = customerId;
    setPageParams(request, params);
    return this._api.get(request).pipe(
      map((m) => {
        return getPageReturn(m);
      })
    );
  }

  getList(
    customerId: string,
    filter?: string,
    groupIds?: string[]
  ): Observable<PtzCameraLocation[]> {
    const request = new GetPtzCamerasLocationRequest();
    request.customerId = customerId;

    request.entityGroups = groupIds;
    request.page = 0;
    request.items = 0;
    request.sort = "name";
    request.filter = filter;

    return this._api.get(request).pipe(
      map((m) => {
        return m.items;
      })
    );
  }

  // getList(customerId: string, filter?: string, groupIds?: string[]): Observable<Camera[]> {
  //   const request = new GetCamerasPageRequest();
  //   request.customerId = customerId;
  //   request.entityGroups = groupIds;
  //   request.page = 0;
  //   request.items = 0;
  //   request.sort = 'name';
  //   request.filter = filter;

  //   return this._api.get(request).pipe(
  //     map((m) => {
  //       return m.items;
  //     })
  //   );
  // }

  get(id: any, customerId: string): Observable<PtzCamera> {
    const request = new GetPtzCameraRequest();
    request.customerId = customerId;
    request.id = id;
    return this._api.get(request);
  }

  add(
    model: PtzCamera,
    params: {
      customerId: string;
      groups: string[];
      updateGroups: boolean;
    }
  ) {
    const request = new CreatePtzCameraRequest();
    request.customerId = params.customerId;
    request.entity = model;
    request.entityGroups = params.groups;
    request.updateEntityGroups = params.updateGroups;
    return this._api.post(request);
  }

  update(
    model: PtzCamera,
    params: {
      customerId: string;
      groups: string[];
      updateGroups: boolean;
    }
  ) {
    const request = new UpdatePtzCameraRequest();
    request.customerId = params.customerId;
    request.id = model.id;
    request.entity = model;
    request.entityGroups = params.groups;
    request.updateEntityGroups = params.updateGroups;
    return this._api.put(request);
  }

  delete(ids: string[], customerId: string): Observable<number> {
    const request = new DeletePtzCamerasRequest();
    request.customerId = customerId;
    request.ids = ids;
    return this._api.delete(request);
  }

  getPermissions(id: string, customerId: string) {
    const request = new GetPtzCameraCurrentUserPermissionsRequest();
    request.id = id;
    request.customerId = customerId;
    return this._api.get(request);
  }

  camControl(id, cameraCommand: CameraCommand) {
    // const url = `http://krsk.asudd24.ru:8088/device/${id}/image?forceUpdate=${force}&t=${Date.now()}`;
    // const url = `${this._appEnv.modules.meteo.apiUrl}/json/reply/SendMeteoObjectCameraCommand`;
    // const body = { id: `${id}`, cameraCommand: `${cameraCommand}` };
    // const headers = new Headers();
    // headers.append("Authorization", `Bearer ${this._tokenService.bearerToken}`);
    // const request = new Request(url, {
    //   method: "POST",
    //   headers,
    //   mode: "cors",
    //   cache: "no-store",
    //   body: JSON.stringify(body),
    // });
    // return request;

    const request = new PtzCameraCommandRequest();
    request.id = id;
    request.command = cameraCommand;
    return this._api.post(request);
  }
}
