import { marker as _ } from "@biesbjerg/ngx-translate-extract-marker";

import { NgModule } from "@angular/core";
import { MatIconRegistry } from "@angular/material/icon";
import { DomSanitizer } from "@angular/platform-browser";
import { DatePipe } from "@angular/common";

import { RmmdApiService } from "../../services/rmmd-api.service";
import { RmmdApiEventService } from "../../services/rmmd-api-event.service";

import { ContractorsService } from "../../services/contractors/contractors.service";

import { VehicleService } from "../../services/vehicle/vehicle.service";
import { VehicleForGroupService } from "../../services/vehicle/vehicle-for-group.service";
import { VehicleGroupService } from "../../services/vehicle/vehicle-group.service";
import { VehicleGroupShareService } from "../../services/vehicle/vehicle-group-share.service";
import { VehicleGroupEventService } from "../../services/vehicle/vehicle-group-event.service";

import { VehicleStateComponent } from "../../components/vehicles/state/vehicle-state.component";

import { TerminalService } from "../../services/terminal/terminal.service";

import { ExternalSystemService } from "../../services/external-system/external-system.service";

import { ISidenavMenuCategory } from "projects/msu-its-web-common/src/utils/sidenav-menu";
import { IGisObjectGroup } from "projects/msu-its-web-common/src/utils/gis-object-group";
import {
  GIS_OBJECT_GROUP,
  SIDENAV_MENU,
} from "projects/msu-its-web-common/src/utils/shared-consts";

import { BaseModule } from "projects/msu-its-web-common/src/modules/base.module";

const sidenav: ISidenavMenuCategory[] = [
  {
    name: _("SIDENAV_MENU.RMMD"),
    items: [
      {
        name: _("ROAD_MAINTENANCE_CONTRACTOR.TITLE"),
        icon: "work",
        route: "maintenance-contractor",
        permissions: ["maintenanceContractor.read"],
      },
      {
        name: _("ROAD_MAINTENANCE_VEHICLE.TITLE"),
        icon: "local_shipping",
        route: "maintenance-vehicle",
        permissions: ["maintenanceVehicle.read"],
      },
    ],
  },
];

const maintenanceVehicleObjectGroup: IGisObjectGroup = {
  type: "maintenanceVehicle",
  name: _("SIDENAV_MENU.RMMD"),
  groupServiceType: VehicleGroupService,
  eventServiceType: VehicleGroupEventService,
  stateComponentType: VehicleStateComponent,
};

@NgModule({
  providers: [
    { provide: SIDENAV_MENU, useValue: sidenav, multi: true },
    {
      provide: GIS_OBJECT_GROUP,
      useValue: maintenanceVehicleObjectGroup,
      multi: true,
    },

    RmmdApiService,
    RmmdApiEventService,

    ContractorsService,

    VehicleService,
    VehicleForGroupService,
    VehicleGroupService,
    VehicleGroupShareService,
    VehicleGroupEventService,

    TerminalService,

    ExternalSystemService,

    DatePipe,
  ],
  imports: [BaseModule],
  declarations: [VehicleStateComponent],
  entryComponents: [],
  exports: [VehicleStateComponent],
})
export class RmmdSharedModule {
  constructor(
    private _matIconRegistry: MatIconRegistry,
    private _domSanitizer: DomSanitizer
  ) {
    // this._registerIcon('ruis-route', '/assets/images/ruis/icons/route.svg');
  }

  // private _registerIcon(name: string, url: string) {
  //   this._matIconRegistry.addSvgIcon(name, this._domSanitizer.bypassSecurityTrustResourceUrl(url));
  // }
}
