import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { Schema } from '../schema';

import {
  TrafficMovementType,
  TrafficPlanType,
  UsdkBarrier,
  UsdkPhase,
  UsdkPlan as DtoUsdkPlan,
  UsdkPlanType,
} from '../tlc.dtos';

export class UsdkPlan extends DtoUsdkPlan {
  enabled = false;
  type = TrafficPlanType.PhaseCycle;
  usdkPlanType = UsdkPlanType.PhaseCycle;
  offset = 0;
  barriers: UsdkBarrier[] = [];
}

const phaseNames = {
  ru: { major: 'ФГ', minor: 'ФВ' },
  en: { major: 'MJ', minor: 'MI' },
};

const majorMovements = [
  TrafficMovementType.Move1,
  TrafficMovementType.Move2,
  TrafficMovementType.Move5,
  TrafficMovementType.Move6,
];

export const getUsdkPhaseName = (phase: UsdkPhase, lang = 'ru') => {
  if (phase) {
    const names = phaseNames[lang] || phaseNames.en;
    const name = isUsdkPhaseMajor(phase) ? names.major : names.minor;
    return `${name}${phase.id}`;
  }
  return '';
};

export const isUsdkPhaseMajor = (phase: UsdkPhase) => {
  return phase.movements?.some((m) => majorMovements.includes(m));
};

export const getBarriersTime = (source: UsdkBarrier[]) => {
  return source.reduce((sum, item) => sum + item.length, 0);
};

export const getBarriersPercentTimes = (source: UsdkBarrier[]) => {
  const time = getBarriersTime(source);
  return source.map((item) => (time === 0 ? 100 / source.length : (item.length * 100) / time));
};

export const setBarriersPercentTimes = (
  barriers: UsdkBarrier[],
  barriersMinTimes: number[],
  sizes?: number[],
  time?: number
) => {
  if (barriers.length == 0) {
    return;
  }

  sizes = sizes || getBarriersPercentTimes(barriers);
  time = Math.max(
    time || getBarriersTime(barriers),
    barriersMinTimes.reduce((result, item) => result + item, 0)
  );
  const timePercent = time / 100;

  // update sizes
  let sum = 0;
  const barrierTimes = [];
  sizes.forEach((s, i) => {
    const barrierMinTime = barriersMinTimes[i];
    let barrierTime = i === sizes.length - 1 ? time - sum : Math.round(s * timePercent);
    barrierTime = Math.max(barrierTime, barrierMinTime);
    barrierTimes.push(barrierTime);
    sum += barrierTime;
  });

  // diff
  let diff = barrierTimes.reduce((result, item) => result + item, 0) - time;
  if (diff > 0) {
    barrierTimes.some((time, i) => {
      const minTime = barriersMinTimes[i];
      const newDiff = Math.min(time - minTime, diff);
      barrierTimes[i] = time - newDiff;
      diff = diff - newDiff;
      return diff == 0;
    });
  }

  // set length
  barriers.forEach((barrier, index) => {
    barrier.length = barrierTimes[index];
  });
};

export const updatePhaseSvg = (object: any, schema: Schema, phase: UsdkPhase) => {
  const movements = phase ? phase.movements : [];

  const svg = object.contentDocument;
  const show = [];
  const hide = [
    ...Object.keys(TrafficMovementType).filter((m: TrafficMovementType) => !movements.includes(m)),
    'MoveP',
  ];
  const addClass: string[][] = [];
  movements.forEach((m) => {
    const move: any = schema[m];
    move.controlled && addClass.push([m, 'protected']);
    move.deadEnd && addClass.push([m, 'deadend']);

    const turns = MOVEMENT_TURNS[m];
    if (turns) {
      turns.forEach((n) => {
        const turnMove = schema[n];
        turnMove.enabled && !turnMove.controlled && show.push(n);
      });
    }
  });

  if (
    schema.getIsPedestrian() &&
    (movements.includes(TrafficMovementType.MoveP8) ||
      movements.includes(TrafficMovementType.MoveP4))
  ) {
    hide.push(TrafficMovementType.MoveP8, TrafficMovementType.MoveP4);
    show.push('MoveP');
  }

  hide
    .filter((m) => !show.includes(m))
    .forEach((e) => {
      svg.getElementById(e).style.display = 'none';
    });
  addClass.forEach((m) => {
    svg.getElementById(m[0]).classList.add(m[1]);
  });
};

const MOVEMENT_TURNS = {
  Move2: [TrafficMovementType.Move12, TrafficMovementType.Move5],
  Move4: [TrafficMovementType.Move14, TrafficMovementType.Move7],
  Move6: [TrafficMovementType.Move16, TrafficMovementType.Move1],
  Move8: [TrafficMovementType.Move18, TrafficMovementType.Move3],
};
