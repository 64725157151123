<h4 mat-dialog-title style="margin-bottom: 16px">
  {{ "COMMON.LOGOUT_TITLE" | translate }}
</h4>
<mat-dialog-content style="min-width: 250px">
  <mat-checkbox
    [checked]="everywhere"
    (change)="everywhere = $event.checked"
    disableRipple="true"
  >
    {{ "COMMON.LOGOUT_EVERYWHERE" | translate }}
  </mat-checkbox>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-flat-button color="accent" autofocus (click)="logout()">
    {{ "COMMON.LOGOUT" | translate }}
  </button>
  <button mat-flat-button [mat-dialog-close]="false">
    {{ "COMMON.CANCEL" | translate }}
  </button>
</mat-dialog-actions>
