<div
  fxFlex
  fxLayout="column"
  style="min-width: 800px !important; min-height: 600px; max-height: 70vh;"
>
  <div *ngIf="loading" class="backdrop" style="z-index: 1000;">
    <mat-spinner diameter="36" color="accent"></mat-spinner>
  </div>
  <header>
    <h4 fxFlex fxLayoutAlign="start center">
      {{"CAMERAS_FTP.ALL_IMAGES"| translate}}
    </h4>
    <div align="end" fxFlex *ngIf="!external">
      <button mat-button (click)="close()">
        {{ "COMMON.CLOSE" | translate }}
      </button>
    </div>
  </header>

  <content fxFlex fxLayout="column" fxLayoutGap="24px">
    <!-- <div
      fxFlex
      style="
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 20px;
        margin-bottom: 24px;
        align-content: flex-start;
        justify-content: flex-start;
      "
      id="photo_{{modelId}}"
    ></div> -->
    <div
      fxFlex
      style="
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 20px;
        margin-bottom: 24px;
        align-content: flex-start;
        justify-content: flex-start;
      "
    >
      <div
        *ngFor="let item of imagesData"
        class="imageCard"
        (click)="showViewer(item.id)"
      >
        <div class="imageCard-image">
          <img [src]="'data:image/png;base64,' + item.image | safeHtml " />
        </div>
        <div class="imageCard-text">
          <div>
            <span>Имя файла:</span>
            <span>{{item.name}}</span>
          </div>

          <div>
            <span>Дата создания:</span>
            <span>{{item?.created | date: "short":null:locale}}</span>
          </div>
        </div>
      </div>
    </div>
  </content>

  <footer>
    <mat-paginator
      #paginator
      [pageSizeOptions]="[6, 18, 33]"
      [showFirstLastButtons]="false"
    >
    </mat-paginator>
  </footer>
</div>
