import {
  ViewChild,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ElementRef,
  AfterViewInit,
} from '@angular/core';
import { Subject } from 'rxjs';

import { TranslateService } from '@ngx-translate/core';
import { SettingsService } from 'projects/msu-its-web-common/src/services/settings.service';

import { LayoutSvgUtils } from 'projects/msu-its-web-tlc/src/dtos/layout';
import { SchemaSet, SchemaSvgUtils, SchemaUtils } from 'projects/msu-its-web-tlc/src/dtos/schema';
import {
  PotokPlan,
  PotokPlanType,
  PotokSettings,
  TrafficMovementLight,
  TrafficMovementType,
  TrafficObjectMode,
  TrafficObjectStatus,
} from 'projects/msu-its-web-tlc/src/dtos/tlc.dtos';

import { getPlanStates } from 'projects/msu-its-web-tlc/src/dtos/potok/potok-plan';
import { IPotokTrafficObjectSet, PotokStateService } from '../../state/potok-state.service';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'potok-select-phase-extended',
  templateUrl: './potok-select-phase-extended.component.html',
  styleUrls: ['./potok-select-phase-extended.component.css'],
  providers: [PotokStateService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PotokSelectPhaseExtendedComponent implements OnInit, OnDestroy, AfterViewInit {
  _destroy = new Subject();

  @Input()
  schemaSet: SchemaSet;

  @Input()
  selectedMovements: TrafficMovementType[] = [];

  @Input()
  settings: PotokSettings;

  @Output()
  selected = new EventEmitter<TrafficMovementType[]>();

  phases: [TrafficMovementType[][], TrafficMovementType[][]] = [[], []];

  get schema() {
    return this.schemaSet.schema;
  }
  get schemaView() {
    return this.schemaSet.schemaView;
  }

  trafficObjectSet: IPotokTrafficObjectSet;
  schemaMap;
  status: TrafficObjectStatus;

  @ViewChild('schemaSvg')
  _schemaSvg: ElementRef;
  _schemaSvgLoaded = false;


  constructor(
    private _settingsService: SettingsService,
    private _changeDetector: ChangeDetectorRef,
    public translate: TranslateService,
  ) {}

  ngAfterViewInit() {
    this._updateSchema();
    this._updateSchemaState();
  }

  ngOnInit() {
    this._updatePhases();
    this._changeDetector.markForCheck();
  }

  ngOnDestroy() {
    this._destroy.next();
    this._destroy.complete();
  }

  select(movements: TrafficMovementType[]) {
    this.selected.emit(movements);
  }

  setSvgState(element, stepMovements: TrafficMovementType[]) {
    const schema = this.schema;
    const schemaView = this.schemaView;
    const isDark = this._settingsService.darkTheme;
    const svg = element.contentDocument;

    const movements = [];
    stepMovements.forEach((m) => {
      movements.push(...SchemaUtils.schemaViewMovements(schema, m));
    });

    LayoutSvgUtils.updateLayoutSchema(svg, schema, schemaView, isDark);
    LayoutSvgUtils.showLayoutMovements(svg, schema, movements);
  }

  isSelected(stepMovements: TrafficMovementType[]) {
    return (
      this.selectedMovements.length == stepMovements?.length &&
      this.selectedMovements.every((m) => stepMovements.includes(m))
    );
  }

  _updatePhases() {
    const plans = this.settings.plans;
    const cyclePlans = Object.keys(plans)
      .map((k) => plans[k] as PotokPlan)
      .filter((m) => m.enabled && m.type === PotokPlanType.PhaseCycle);

    if (cyclePlans.length) {
      const plan = cyclePlans[0];
      const barriers = plan.pedestrianPhasesEnabled
        ? plan.pedestrianBarriers
        : plan.transportBarriers;
      this.phases = [].concat(getPlanStates(barriers)) as [[], []];
    } else {
      this.phases = [[], []];
    }
  }

  initSchemaSvg() {
    this._schemaSvgLoaded = true;
    this._updateSchema();
  }

  _updateSchema() {
    if (!this._schemaSvgLoaded) return;

    const schema = this.schema;
    const schemaView = this.schemaView;
    const svg = this._schemaSvg.nativeElement.contentDocument;

    LayoutSvgUtils.updateLayoutSchema(svg, schema, schemaView, false);
    LayoutSvgUtils.updateLayoutControl(svg, schema);

    SchemaSvgUtils.updateMovements(svg, schema);
    SchemaSvgUtils.updateTheme(svg, false);

    setTimeout(() => {
      this._schemaSvg.nativeElement.style.visibility = 'visible';
    }, 250);
  }


  _updateSchemaState() {
    const svg = this._schemaSvg.nativeElement.contentDocument;
    if (svg && this.status?.schemaLights && this.schema) {
      const lights = this.status.schemaLights;
      const schema = this.schema;

      this._updateSvgLight(svg, 'Move2', lights.move2, schema.Move2.enabled);
      this._updateSvgLight(svg, 'Move4', lights.move4, schema.Move4.enabled);
      this._updateSvgLight(svg, 'Move6', lights.move6, schema.Move6.enabled);
      this._updateSvgLight(svg, 'Move8', lights.move8, schema.Move8.enabled);
      this._updateSvgLight(svg, 'Move1', lights.move1, schema.Move1.enabled);
      this._updateSvgLight(svg, 'Move3', lights.move3, schema.Move3.enabled);
      this._updateSvgLight(svg, 'Move5', lights.move5, schema.Move5.enabled);
      this._updateSvgLight(svg, 'Move7', lights.move7, schema.Move7.enabled);
      this._updateSvgLight(svg, 'Move12', lights.move12, schema.Move12.enabled);
      this._updateSvgLight(svg, 'Move14', lights.move14, schema.Move14.enabled);
      this._updateSvgLight(svg, 'Move16', lights.move16, schema.Move16.enabled);
      this._updateSvgLight(svg, 'Move18', lights.move18, schema.Move18.enabled);
      this._updateSvgLight(svg, 'MoveP2', lights.moveP2, schema.MoveP2.enabled);
      this._updateSvgLight(svg, 'MoveP4', lights.moveP4, schema.MoveP4.enabled);
      this._updateSvgLight(svg, 'MoveP6', lights.moveP6, schema.MoveP6.enabled);
      this._updateSvgLight(svg, 'MoveP8', lights.moveP8, schema.MoveP8.enabled);

      const movements =
        this.status.mode != TrafficObjectMode.Off
          ? SchemaUtils.schemaLightToMovements(schema, lights)
          : [];

      LayoutSvgUtils.showLayoutMovements(svg, schema, movements);
    }
    this._changeDetector.markForCheck();
  }

  _updateSvgLight(svg, svgMovementId: string, state: TrafficMovementLight, enabled: boolean) {
    const elm = svg.getElementById(svgMovementId);
    if (elm === null) {
      return;
    }

    let fill = '#777';
    let stroke = '#999';
    let opacity = 1;
    let blink = '';

    if (enabled) {
      switch (state) {
        case TrafficMovementLight.Green:
          fill = '#2c2';
          stroke = '#080';
          break;
        case TrafficMovementLight.GreenBlink:
          fill = 'transparent';
          stroke = '#080';
          blink = 'green';
          break;
        case TrafficMovementLight.Red:
          fill = '#e00';
          stroke = '#800';
          break;
        case TrafficMovementLight.RedYellow:
          fill = 'url(#redyellow)';
          stroke = 'url(#redyellow_stroke)';
          break;
        case TrafficMovementLight.Yellow:
          fill = '#fe4';
          stroke = '#880';
          break;
        case TrafficMovementLight.YellowBlink:
          fill = 'transparent';
          stroke = '#880';
          blink = 'yellow';
          break;
        default:
          opacity = 0;
          break;
      }
    } else {
      opacity = 0;
    }

    elm.style.fill = fill;
    elm.style.stroke = stroke;
    elm.style.opacity = opacity;
    elm.setAttribute('data-blink', blink);
  }


  mouseOverMovement(item:string[]){
    const svg = this._schemaSvg.nativeElement.contentDocument;
    item.forEach(move=>{
      const elm = svg.getElementById(move);
      elm.style.fill = '#55ff55';
      elm.style.stroke = '#55ff55';
    })
  }
  mouseLeaveMovement(item:string[]){
    const svg = this._schemaSvg.nativeElement.contentDocument;
    item.forEach(move=>{
      const elm = svg.getElementById(move); 
      if (elm === null) {
        return;
      }
      elm.style.fill = '#aaa';
      elm.style.stroke = '#888';
    })
  }
}
