import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';

import { NgModule } from '@angular/core';
import { DatePipe } from '@angular/common';

import { RuisApiService } from '../../services/ruis-api.service';
import { RuisApiEventService } from '../../services/ruis-api-event.service';

import { PanelService } from '../../services/panels/panel.service';
import { PanelControllerService } from '../../services/panel-controllers/panel-controller.service';
import { PanelControllerEventLogService } from '../../services/panel-controllers/panel-controller-event-log.service';
import { PanelStandService } from '../../services/panel-stands/panel-stand.service';
import { PanelStandGroupService } from '../../services/panel-stands/panel-stand-group.service';
import { PanelStandGroupShareService } from '../../services/panel-stands/panel-stand-group-share.service';
import { PanelStandGroupEventService } from '../../services/panel-stands/panel-stand-group-event.service';

import { FrameTemplateService } from '../../services/frame-templates/frame-template.service';
import { FrameTemplateLibraryService } from '../../services/frame-templates/frame-template-library.service';

import { PanelStandStateComponent } from '../../components/panel-stands/panel-stand-state.component';
import { PanelStandStatePanelComponent } from '../../components/panel-stands/panel-stand-state-panel.component';

import { ISidenavMenuCategory } from 'projects/msu-its-web-common/src/utils/sidenav-menu';
import { IGisObjectGroup } from 'projects/msu-its-web-common/src/utils/gis-object-group';
import {
  GIS_OBJECT_GROUP,
  SIDENAV_MENU,
} from 'projects/msu-its-web-common/src/utils/shared-consts';

import { BaseModule } from 'projects/msu-its-web-common/src/modules/base.module';

const sidenav: ISidenavMenuCategory[] = [
  {
    name: _('SIDENAV_MENU.RUIS'),
    items: [
      {
        name: _('PANEL_STAND.TITLE'),
        icon: 'grid_on',
        route: 'panel-stands',
        permissions: ['panelStand.read'],
      },
    ],
  },
];

const panelStandGroup: IGisObjectGroup = {
  type: 'panelStand',
  name: _('SIDENAV_MENU.RUIS'),
  groupServiceType: PanelStandGroupService,
  eventServiceType: PanelStandGroupEventService,
  stateComponentType: PanelStandStateComponent,
};

@NgModule({
  providers: [
    { provide: SIDENAV_MENU, useValue: sidenav, multi: true },
    { provide: GIS_OBJECT_GROUP, useValue: panelStandGroup, multi: true },

    RuisApiService,
    RuisApiEventService,

    PanelStandService,
    PanelStandGroupService,
    PanelStandGroupShareService,
    PanelStandGroupEventService,
    PanelService,
    PanelControllerService,
    PanelControllerEventLogService,

    FrameTemplateService,
    FrameTemplateLibraryService,

    DatePipe,
  ],
  imports: [BaseModule],
  declarations: [PanelStandStateComponent, PanelStandStateComponent, PanelStandStatePanelComponent],
  // entryComponents: [
  //   PanelStandStateComponent,
  //   PanelStandStateComponent,
  //   PanelStandStatePanelComponent,
  // ],
  exports: [PanelStandStateComponent],
})
export class RuisSharedModule {
  // constructor(private _matIconRegistry: MatIconRegistry, private _domSanitizer: DomSanitizer) {
  //   this._registerIcon('panel-stand-route', '/assets/images/ruis/icons/route.svg');
  // }
  // private _registerIcon(name: string, url: string) {
  //   this._matIconRegistry.addSvgIcon(name, this._domSanitizer.bypassSecurityTrustResourceUrl(url));
  // }
}
