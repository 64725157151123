import { marker as _ } from "@biesbjerg/ngx-translate-extract-marker";

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
} from "@angular/core";
import { TranslateService } from "@ngx-translate/core";

import { Observable, Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

import { MsuPirsStateService } from "./msu-pirs-state.service";

import { FlashService } from "projects/msu-its-web-common/src/services/flash.service";
import { DialogService } from "projects/msu-its-web-common/src/services/dialog.service";

import {
  Parking,
  ParkingSpaceMode,
  ParkingSpaceStatus,
  ParkingStatus,
} from "projects/msu-its-web-parking/src/dtos/parking.dtos";

import { MsuPirsParkingService } from "projects/msu-its-web-parking/src/services/msu-pirs-parking.service";
import { MatSlideToggleChange } from "@angular/material/slide-toggle";

@Component({
  selector: "msu-pirs-state-spaces",
  templateUrl: "./msu-pirs-state-spaces.component.html",
  styles: [``],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MsuPirsStateSpacesComponent implements OnDestroy {
  _destroy = new Subject();

  model: Parking;
  status: ParkingStatus;

  showVersion = false;

  constructor(
    private _stateService: MsuPirsStateService,
    private _msuPirsParkingService: MsuPirsParkingService,
    private _dialogService: DialogService,
    private _flashService: FlashService,
    private _changeDetector: ChangeDetectorRef,
    public translate: TranslateService
  ) {
    this._stateService.parking$
      .pipe(takeUntil(this._destroy))
      .subscribe((model) => {
        this.model = model;
        this._changeDetector.markForCheck();
      });

    this._stateService.status$
      .pipe(takeUntil(this._destroy))
      .subscribe((status) => {
        this.status = status;
        this._changeDetector.markForCheck();
      });
  }

  ngOnDestroy() {
    this._destroy.next();
    this._destroy.complete();
  }

  trackStatus(index: number, item: ParkingSpaceStatus) {
    return (item as ParkingSpaceStatus).id;
  }

  _parkingSpaceCommand(request: Observable<ParkingSpaceStatus>) {
    this._stateService.loadingQuery(request).subscribe((status) => {
      const statuses = this._stateService.status.spaces;

      const index = statuses.findIndex((m) => m.id === status.id);
      if (index >= 0) {
        statuses.splice(index, 1, status);
      }

      this._flashService.success(
        this.translate.instant(_("PARKING_SPACES.COMMAND_COMPLETED"))
      );
    });
  }

  openParkingSpace(id: string) {
    this._parkingSpaceCommand(
      this._msuPirsParkingService.openParkingSpaceLock(
        id,
        this._stateService.customerId
      )
    );
  }

  closeParkingSpace(id: string) {
    this._parkingSpaceCommand(
      this._msuPirsParkingService.closeParkingSpaceLock(
        id,
        this._stateService.customerId
      )
    );
  }

  queryParkingSpace(id: string) {
    this._parkingSpaceCommand(
      this._msuPirsParkingService.queryParkingSpaceLock(
        id,
        this._stateService.customerId
      )
    );
  }

  rebootParkingSpace(id: string) {
    this._parkingSpaceCommand(
      this._msuPirsParkingService.queryParkingSpaceLock(
        id,
        this._stateService.customerId
      )
    );
  }

  getStatus(item: ParkingSpaceStatus) {
    if (
      item.statusDescription === "Готов" ||
      item.statusDescription === "Поломка ультразвуковых датчиков"
    ) {
      return "";
    } else if (item.statusDescription === "Нет связи") {
      if (item.mode === ParkingSpaceMode.Off) {
        return "";
      }
    }
    return item.statusDescription;
  }

  onChangeToggle($event: MatSlideToggleChange) {
    this.showVersion = $event.checked;
  }
}
