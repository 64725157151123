import {
  Component,
  ChangeDetectionStrategy,
  Injector,
  ChangeDetectorRef,
  Inject,
  ViewChild,
} from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";

import { APP_ENVIRONMENT } from "projects/msu-its-web-common/src/utils/shared-consts";
import { DataSourceForm } from "projects/msu-its-web-common/src/data/data-source-form";

import { EntityGroupInfo, PtzCamera } from "../../dtos/tfms.dtos";

import { LocationComponent } from "projects/msu-its-web-common/src/components/map/location.component";
import { WebCameraComponent } from "projects/msu-its-web-common/src/components/camera/web-camera.component";
import { forkJoin } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { EMPTY } from "rxjs";
import { CameraPTZGroupService } from "../../services/camera-ptz-group.service";

export interface IWebcamSettings {
  url: string;
  rtmp: string;
}

@Component({
  selector: "camera-ptz-edit",
  templateUrl: "./camera-ptz-edit.component.html",
  styles: [
    `
      :host {
        display: flex;
        flex-direction: column;
      }
      .mat-tab-body-content {
        height: 100%;
        overflow: hidden !important;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CameraPTZEditComponent extends DataSourceForm<PtzCamera> {
  @ViewChild("webcamera", { static: false })
  webcamera: WebCameraComponent;

  @ViewChild("location", { static: true })
  location: LocationComponent;

  customerId: string;
  groupList: EntityGroupInfo[] = [];
  selectedGroups = new FormControl({ value: [] as string[], disabled: true });

  constructor(
    injector: Injector,
    changeDetector: ChangeDetectorRef,
    private _cameraGroupService: CameraPTZGroupService,
    @Inject(APP_ENVIRONMENT) private _appEnv
  ) {
    super(injector, changeDetector);
  }

  update() {
    this.webcamera.url = this.rtmp.value;
    this.changeDetector.markForCheck();
  }

  initFormGroup(fb: FormBuilder): FormGroup {
    this.location.lat = this.model.lat;
    this.location.lon = this.model.lon;

    setTimeout(() => {
      this._loadGroups();
      this.update();
    }, 0);

    return fb.group({
      name: [
        "",
        [
          Validators.required,
          Validators.minLength(4),
          Validators.maxLength(100),
        ],
        [],
      ],
      description: "",
      username: ["", [Validators.required]],
      password: ["", [Validators.required]],
      url: [
        "",
        [Validators.required, Validators.pattern(/^https?:\/\/[^<>]*?$/i)],
      ],
      rtmp: ["", [Validators.required]],
    });
  }
  prepareModelToSave(): PtzCamera {
    const model = Object.assign(
      new PtzCamera(),
      this.model,
      this.formGroup.value
    );
    model.lat = this.location.lat;
    model.lon = this.location.lon;
    return model;
  }
  prepareParamsToSave() {
    const customerId = this.customerId;
    const groups = this.selectedGroups.value;
    const updateGroups = this.selectedGroups.enabled;

    if (this.isNew) {
      return { customerId, groups, updateGroups };
    } else {
      return { customerId, groups, updateGroups };
    }
  }

  _loadGroups() {
    forkJoin([
      this._cameraGroupService.getList(this.customerId, null, true),
      this._cameraGroupService
        .getObjectGroups(this.model.id, this.customerId)
        .pipe(map((m) => m.map((n) => n.id))),
    ])
      .pipe(catchError(() => EMPTY))
      .subscribe((result) => {
        this.groupList = result[0];
        this.selectedGroups.setValue(result[1]);
        this.selectedGroups.enable();
        this.changeDetector.markForCheck();
      });
  }
  onGroupRemoved(groupId: string) {
    const groupIds = this.selectedGroups.value as string[];
    this.selectedGroups.setValue(groupIds.filter((m) => m != groupId));
  }
  getGroupName(groupId: string) {
    return this.groupList.find((m) => m.id == groupId)?.name || groupId;
  }

  get url() {
    return this.formGroup.controls["url"];
  }
  get rtmp() {
    return this.formGroup.controls["rtmp"];
  }
  get name() {
    return this.formGroup.controls["name"];
  }
  get description() {
    return this.formGroup.controls["description"];
  }
  get username() {
    return this.formGroup.controls["username"];
  }
  get password() {
    return this.formGroup.controls["password"];
  }
}
