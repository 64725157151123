import { Inject, Injectable } from "@angular/core";

import { Observable, of } from "rxjs";
import { map } from "rxjs/operators";

import {
  IDataSourceService,
  setPageParams,
  getPageReturn,
  IPageParams,
  IPageReturn,
} from "projects/msu-its-web-common/src/data/data-source";

import { FtvApiService } from "../ftv-api.service";
import {
  CreateFixationPointRequest,
  DeleteFixationPointsRequest,
  FixationPoint,
  FixationPointChangeCustomerRequest,
  FixationPointInfo,
  FixationPointReplacement,
  GetFixationEventImagesRequest,
  GetFixationPointExistRequest,
  GetFixationPointInfoRequest,
  GetFixationPointRequest,
  GetFixationPointsRequest,
  SetFixationPointReplacementRequest,
  UpdateFixationPointRequest,
} from "../../dtos/ftv.dtos";
import { APP_ENVIRONMENT } from "projects/msu-its-web-common/src/utils/shared-consts";
import { TokenService } from "projects/msu-its-web-common/src/services/token.service";

@Injectable()
export class FixationPointService
  implements IDataSourceService<FixationPoint, FixationPoint> {
  constructor(
    private _api: FtvApiService,
    private _tokenService: TokenService,
    @Inject(APP_ENVIRONMENT) private _appEnv
  ) {}

  getPagedList(
    params: IPageParams,
    customerId: string
  ): Observable<IPageReturn<FixationPoint>> {
    if (!customerId) {
      return of(null);
    }

    const request = new GetFixationPointsRequest();
    request.customerId = customerId;

    setPageParams(request, params);
    return this._api.get(request).pipe(
      map((m) => {
        return getPageReturn(m);
      })
    );
  }
  getList(
    customerId: string,
    filter?: string,
    groupIds?: string[],
    items?: number
  ): Observable<FixationPoint[]> {
    const request = new GetFixationPointsRequest();
    request.customerId = customerId;
    request.entityGroups = groupIds || [];

    request.page = 0;
    request.items = items || 0;
    request.sort = "name";
    request.filter = filter || "";

    return this._api.get(request).pipe(
      map((m) => {
        return m.items;
      })
    );
  }

  get(id: string, customerId: string) {
    const request = new GetFixationPointRequest();
    request.id = id;
    request.customerId = customerId;
    return this._api.get(request);
  }
  getFixationPointInfo(
    id: string,
    customerId: string
  ): Observable<FixationPointInfo> {
    const request = new GetFixationPointInfoRequest();
    request.id = id;
    request.customerId = customerId;
    return this._api.get(request);
  }
  add(
    model: FixationPoint,
    params: {
      customerId: string;
      groups: string[];
      updateGroups: boolean;
    }
  ) {
    const request = new CreateFixationPointRequest();
    request.entity = model;
    request.customerId = params.customerId;
    request.entityGroups = params.groups;
    request.updateEntityGroups = params.updateGroups;
    return this._api.post(request);
  }
  update(
    model: FixationPoint,
    params: { customerId: string; groups: string[]; updateGroups: boolean }
  ) {
    const request = new UpdateFixationPointRequest();
    request.id = model.id;
    request.entity = model;
    request.customerId = params.customerId;
    request.entityGroups = params.groups;
    request.updateEntityGroups = params.updateGroups;
    return this._api.put(request);
  }
  delete(ids: string[], customerId: string) {
    const request = new DeleteFixationPointsRequest();
    request.ids = ids;
    request.customerId = customerId;
    return this._api.delete(request);
  }
  exist(name: string, customerId: string): Observable<boolean> {
    const request = new GetFixationPointExistRequest({
      name,
      customerId,
    });
    return this._api.get(request);
  }

  // getStatus(id: string, customerId: string) {
  //   const request = new GetFixationPointStatusRequest();
  //   request.id = id;
  //   request.customerId = customerId;
  //   return this._api.get(request);
  // }

  // getControllerList(
  //   filter: string,
  //   customerId: string,
  //   items = 0,
  //   controllerType?: ParkingControllerType,
  //   onlyFree?: boolean
  // ) {
  //   const request = new GetParkingControllersRequest();

  //   request.customerId = customerId;
  //   request.filter = filter;
  //   request.controllerType = controllerType || ParkingControllerType.None;
  //   request.onlyFree = onlyFree || false;
  //   request.page = 0;
  //   request.items = items;

  //   return this._api.get(request).pipe(map((m) => m.items));
  // }

  changeCustomer(
    id: string,
    customerId: string,
    params: { newCustomerId: string; description: string }
  ) {
    const request = new FixationPointChangeCustomerRequest();
    request.id = id;
    request.customerId = customerId;
    request.description = params.description;
    request.newCustomerId = params.newCustomerId;
    return this._api.post(request);
  }

  setDetectorReplacementRequest(
    customerId: string,
    entity: FixationPointReplacement
  ) {
    const request = new SetFixationPointReplacementRequest();
    request.customerId = customerId;
    request.entity = entity;
    request.entity.customerId = customerId;
    // request.entity.detectorDeviceId = deviceId;
    return this._api.put(request);
  }

  // getImagesRequest(id, customerId) {
  //   // const url = `http://krsk.asudd24.ru:8088/device/${id}/image?forceUpdate=${force}&t=${Date.now()}`;
  //   const url = `${this._appEnv.modules.ftv.apiUrl}/json/reply/GetFixationEventImagesRequest?id=${id}&customerId=${customerId}`;

  //   const headers = new Headers();
  //   headers.append("Authorization", `Bearer ${this._tokenService.bearerToken}`);
  //   const request = new Request(url, {
  //     method: "GET",
  //     headers,
  //     mode: "cors",
  //     cache: "no-store",
  //   });
  //   return request;
  // }

  getImagesTestRequest(id: string, customerId: string): Observable<string[]> {
    const request = new GetFixationEventImagesRequest({
      id,
      customerId,
    });
    return this._api.get(request);
  }
  // detachController(id: string, customerId: string, description: string) {
  //   const request = new DetachParkingControllerRequest();
  //   request.id = id;
  //   request.description = description;
  //   request.customerId = customerId;
  //   return this._api.post(request);
  // }

  // getPermissions(id: string, customerId: string) {
  //   const request = new GetParkingCurrentUserPermissionsRequest();
  //   request.id = id;
  //   request.customerId = customerId;
  //   return this._api.get(request);
  // }

  // getControllerEventTypes(id: string, customerId: string) {
  //   const request = new GetControllerEventTypesRequest();
  //   request.id = id;
  //   request.customerId = customerId;
  //   return this._api.get(request);
  // }
}
