import { Component, ChangeDetectionStrategy } from '@angular/core';

import { TableLayoutComponent } from './table-layout.component';

@Component({
  selector: 'table-layout-dialog',
  templateUrl: './table-layout-dialog.component.html',
  styleUrls: [
    './table-layout.component.css',
    './table-layout-dialog.component.css',
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TableLayoutDialogComponent<T> extends TableLayoutComponent<T> {}
