import { Injectable } from '@angular/core';

import { Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import {
  IDataSourceService,
  setPageParams,
  getPageReturn,
  IPageParams,
  IPageReturn,
} from '../data/data-source';

import {
  CustomerRole,
  CustomerRoleInfo,
  GetCustomerRolesRequest,
  GetCustomerRoleRequest,
  CreateCustomerRoleRequest,
  UpdateCustomerRoleRequest,
  DeleteCustomersRequest,
  ExistCustomerRoleNameRequest,
  DeleteCustomerRolesRequest,
} from '../dtos/hub.dtos';

import { HubApiService } from './hub-api.service';

@Injectable()
export class CustomerRoleService
  implements IDataSourceService<CustomerRole, CustomerRoleInfo> {
  constructor(private _api: HubApiService) {}

  getPagedList(
    params: IPageParams,
    customerId: string
  ): Observable<IPageReturn<CustomerRoleInfo>> {
    if (!customerId) {
      return of(null);
    }

    const request = new GetCustomerRolesRequest();
    request.customerId = customerId;

    setPageParams(request, params);
    return this._api.get(request).pipe(
      map((m) => {
        return getPageReturn(m);
      })
    );
  }

  getList(customerId: string, filter?: string): Observable<CustomerRoleInfo[]> {
    const request = new GetCustomerRolesRequest();
    request.customerId = customerId;

    request.page = 0;
    request.items = 0;
    request.sort = 'name';
    request.filter = filter;

    return this._api.get(request).pipe(
      map((m) => {
        return m.items;
      })
    );
  }

  get(id: any, customerId: string): Observable<CustomerRole> {
    const request = new GetCustomerRoleRequest();
    request.id = id;
    request.customerId = customerId;
    return this._api.get(request).pipe(
      tap((m) => {
        if (!Array.isArray(m.permissions)) {
          m.permissions = ((<any>m.permissions) as string).split(', ');
        }
      })
    );
  }

  add(
    customerRole: CustomerRole,
    customerId: string
  ): Observable<CustomerRole> {
    const request = new CreateCustomerRoleRequest();
    request.entity = customerRole;
    request.customerId = customerId;
    return this._api.post(request);
  }

  update(
    customerRole: CustomerRole,
    customerId: string
  ): Observable<CustomerRole> {
    const request = new UpdateCustomerRoleRequest();
    request.id = customerRole.id;
    request.customerId = customerId;
    request.entity = customerRole;
    return this._api.put(request);
  }

  delete(ids: string[], customerId: string): Observable<number> {
    const request = new DeleteCustomerRolesRequest();
    request.ids = ids;
    request.customerId = customerId;
    return this._api.delete(request);
  }

  exist(name: string, customerId: string): Observable<boolean> {
    const request = new ExistCustomerRoleNameRequest();
    request.name = name;
    request.customerId = customerId;
    return this._api.get(request);
  }
}
