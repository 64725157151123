import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';

import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import {
  PotokBarrier,
  PotokBarrierType,
  TrafficObjectMode,
} from 'projects/msu-its-web-tlc/src/dtos/tlc.dtos';

import {
  getBarrierPartNames,
  getRingParts,
  IRingPart,
  PotokPlan,
  updateBarrierPartSvg,
  updateBarrierStaticSvg,
} from 'projects/msu-its-web-tlc/src/dtos/potok/potok-plan';

import { LayoutSvgUtils } from 'projects/msu-its-web-tlc/src/dtos/layout';

import { SettingsService } from 'projects/msu-its-web-common/src/services/settings.service';

import { LANGS_LOCALES } from 'projects/msu-its-web-common/src/utils/langs';
import { CONTROLLER_STATES } from 'projects/msu-its-web-tlc/src/dtos/enums';

import { TranslateService } from '@ngx-translate/core';

import { SchemaSet } from 'projects/msu-its-web-tlc/src/dtos/schema';

import {
  TrafficObjectSceneStatus,
  TrafficPhaseBarrier,
  TrafficPlan,
  TrafficPlanType,
  TrafficSceneStatus,
} from '../../dtos/tss.dtos';

@Component({
  selector: 'traffic-scene-plan',
  templateUrl: './traffic-scene-plan.component.html',
  styleUrls: ['./traffic-scene-plan.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TrafficScenePlanComponent implements OnDestroy, OnInit {
  _destroy = new Subject();
  _rings = new Map<number, IRingPart[][]>();

  @Input()
  objectId: string;

  @Input()
  schemaSet: SchemaSet;

  @Input()
  statusSubject: BehaviorSubject<TrafficSceneStatus>;

  status: TrafficObjectSceneStatus;

  // _currentMode: TrafficObjectMode;
  // _currentPlan: number;

  // _currentPlanPhasesType: 'Transport' | 'Pedestrian';
  _currentPlanId: number;

  plan: TrafficPlan;
  planBarriers: TrafficPhaseBarrier[];

  controllerStates = {};

  get timezone() {
    return this._settingsService.timezone;
  }
  get locale() {
    return LANGS_LOCALES[this.translate.currentLang];
  }

  get schema() {
    return this.schemaSet?.schema;
  }
  get schemaView() {
    return this.schemaSet?.schemaView;
  }

  // get trafficStatus() {
  //   return this.status?.controllerStatus?.traffic;
  // }
  // get controllerStatus() {
  //   return this.status?.controllerStatus?.controller;
  // }

  constructor(
    private _settingsService: SettingsService,
    private _changeDetector: ChangeDetectorRef,
    public translate: TranslateService
  ) {
    CONTROLLER_STATES.forEach((m) => (this.controllerStates[m.type] = m));
  }
  ngOnInit() {
    this.statusSubject?.pipe(takeUntil(this._destroy)).subscribe((sceneStatus) => {
      const status = sceneStatus.trafficObjectStatuses.find((m) => m.id == this.objectId);
      this.status = status;
      this._updatePlanBarriers();
      this._changeDetector.markForCheck();
    });
  }

  ngOnDestroy() {
    this._destroy.next();
    this._destroy.complete();
  }

  _updatePlanBarriers() {
    if (
      this.status &&
      this.status.adaptivePlan &&
      this._currentPlanId !== this.status?.adaptivePlan?.id
    ) {
      this._currentPlanId = this.status.adaptivePlan?.id;
      this.plan = this.status.adaptivePlan;

      if (this.plan) {
        this._rings.clear();
        this.planBarriers =
          //  this._currentPlanPhasesType === 'Pedestrian'
          //    ? this.plan.pedestrianBarriers
          //    :
          this.plan.barriers;
      }
    }
  }

  getRings(index: number) {
    let rings = this._rings.get(index);
    if (!rings) {
      const barrier = this.planBarriers[index];
      rings = [getRingParts(barrier.ring1, 1), getRingParts(barrier.ring2, 2)].filter(
        (m) => m.length
      );
      this._rings.set(index, rings);
    }
    return rings;
  }

  getBarrierPartNames(
    barrierType: PotokBarrierType,
    barrierNumber: number,
    ringPart?: IRingPart,
    pedestrian?: boolean
  ) {
    return getBarrierPartNames(barrierNumber, barrierType, ringPart, pedestrian);
  }

  updateStaticSvg(target, barrier: PotokBarrier) {
    const darkTheme = this._settingsService.darkTheme;
    const svg = target.contentDocument;
    LayoutSvgUtils.updateLayoutSchema(svg, this.schema, this.schemaView, darkTheme);
    updateBarrierStaticSvg(target, this.schema, barrier);
  }

  updatePartSvg(object, barrierType: PotokBarrierType, part: IRingPart, pedestrian = false) {
    updateBarrierPartSvg(object, this.schema, barrierType, part, pedestrian);
  }
}
