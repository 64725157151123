import {
  Component,
  ChangeDetectionStrategy,
  Injector,
  ChangeDetectorRef,
  Inject,
  ViewChild,
  AfterViewInit,
  Input,
} from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";

import { DataSourceForm } from "projects/msu-its-web-common/src/data/data-source-form";
import { APP_ENVIRONMENT } from "projects/msu-its-web-common/src/utils/shared-consts";

import {
  Detector,
  DetectorDeviceInfo,
  EntityGroupInfo,
} from "../../dtos/tfms.dtos";

import { LocationComponent } from "projects/msu-its-web-common/src/components/map/location.component";
import { BehaviorSubject, EMPTY, NEVER, Subject } from "rxjs";
import { DetectorService } from "../../services/detector.service";
import { catchError, debounceTime, map, takeUntil } from "rxjs/operators";
import { ActivatedRoute } from "@angular/router";
import { DETECTOR_USER_STATUS } from "../../dtos/enum";
import { DetectorDeviceService } from "../../services/detector-device.service";
import { FormValidators } from "projects/msu-its-web-common/src/utils/form-validators";
import { DetectorChangeDeviceComponent } from "./detector-change-device.component";
import { DialogService } from "projects/msu-its-web-common/src/services/dialog.service";
import { DetectorGroupService } from "../../services/detector-group.service";
import { forkJoin } from "rxjs";

@Component({
  selector: "detector-edit",
  templateUrl: "./detector-edit.component.html",
  styles: [
    `
      :host {
        display: flex;
        flex-direction: column;
      }
      .checkbox label {
        text-align: right;
        float: left;
      }
      .checkbox input {
        margin-left: 16px;
        float: right;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DetectorEditComponent extends DataSourceForm<Detector>
  implements AfterViewInit {
  _destroy = new Subject();
  customerId: string;
  detectorUserStatus = DETECTOR_USER_STATUS;
  groupList: EntityGroupInfo[] = [];
  selectedGroups = new FormControl({ value: [] as string[], disabled: true });

  // isDisabledButton = false;

  constructor(
    injector: Injector,
    changeDetector: ChangeDetectorRef,
    private _route: ActivatedRoute,
    public dialogService: DialogService,
    private _detectorService: DetectorDeviceService,
    private _detectorGroupService: DetectorGroupService,
    @Inject(APP_ENVIRONMENT) private _appEnv
  ) {
    super(injector, changeDetector);
    this.customerId = this._route.snapshot.paramMap.get("customerId");
  }

  @Input()
  external: boolean = false;

  @ViewChild("location", { static: true })
  location: LocationComponent;

  searchTemp;
  searchInputSubject = new Subject<string>();
  controllerListSubject = new BehaviorSubject<DetectorDeviceInfo[]>([]);

  selectedController: DetectorDeviceInfo;

  ngAfterViewInit() {
    this.onlyFreeCheck.setValue(true);
    this.onlyFreeCheck.valueChanges.subscribe(() => {
      this._loadControllers(this.searchTemp);
    });
    if (this.isNew) {
      this.location.lat = 56.010499;
      this.location.lon = 92.86593;
      this.changeDetector.markForCheck();
    }
  }

  initFormGroup(fb: FormBuilder): FormGroup {
    if (this.isNew) {
      this.searchInputSubject
        .pipe(debounceTime(250))
        .pipe(takeUntil(this._destroy))
        .subscribe((search) => {
          this.searchTemp = search;
          this._loadControllers(search);
        });
    }

    setTimeout(() => {
      this._loadGroups();
      this._loadControllers(null);
    });
    this.location.lat = this.model.lat;
    this.location.lon = this.model.lon;
    this.location.direction = this.model.direction;
    this.selectedController = this.model.detectorDevice;

    return fb.group({
      name: [
        "",
        [
          Validators.required,
          Validators.minLength(4),
          Validators.maxLength(100),
        ],
        [
          FormValidators.exist(this.model.name, (name: string) =>
            (<DetectorService>this.modelService).exist(name)
          ),
        ],
      ],
      description: "",
      direction: "",
      detectorDeviceId: [{ value: "", disabled: !this.isNew }],
      userStatus: [""],
      customerSerialNumber: [""],
      detectionArea: [""],
      onlyFreeCheck: [""],
    });
  }

  _loadControllers(search: string) {
    if (this.isNew) {
      this._detectorService
        .getList(this.customerId, search, 11, [""], this.onlyFreeCheck.value)
        .pipe(catchError(() => NEVER))
        .subscribe((result) => {
          this.controllerListSubject.next(
            result.filter((m) => m.id != this.detectorDeviceId?.value)
          );
          this.changeDetector.markForCheck();
        });
    }
  }

  controllerSelected(controllerId) {
    const controllers = this.controllerListSubject.getValue();
    this.selectedController = controllers.find((m) => m.id === controllerId);
    this.controllerListSubject.next(
      controllers.filter((m) => m.id != this.selectedController?.id)
    );
  }

  _loadGroups() {
    forkJoin([
      this._detectorGroupService.getList(this.customerId, null, true),
      this._detectorGroupService
        .getObjectGroups(this.model.id, this.customerId)
        .pipe(map((m) => m.map((n) => n.id))),
    ])
      .pipe(catchError(() => EMPTY))
      .subscribe((result) => {
        this.groupList = result[0];
        this.selectedGroups.setValue(result[1]);
        this.selectedGroups.enable();
        this.changeDetector.markForCheck();
      });
  }
  onGroupRemoved(groupId: string) {
    const groupIds = this.selectedGroups.value as string[];
    this.selectedGroups.setValue(groupIds.filter((m) => m != groupId));
  }
  getGroupName(groupId: string) {
    return this.groupList.find((m) => m.id == groupId)?.name || groupId;
  }

  prepareModelToSave(): Detector {
    const model = Object.assign(
      new Detector(),
      this.model,
      this.formGroup.value
    );
    model.lat = this.location.lat;
    model.lon = this.location.lon;

    return model;
  }

  prepareParamsToSave() {
    const customerId = this.customerId;
    const groups = this.selectedGroups.value;
    const updateGroups = this.selectedGroups.enabled;

    if (this.isNew) {
      return { customerId, groups, updateGroups };
    } else {
      return { customerId, groups, updateGroups };
    }
  }

  onDirectionChanged() {
    this.location.setDirection(this.direction.value);
    this.changeDetector.markForCheck();
  }

  changeDevice() {
    this.getEdit(this.model.id).subscribe((result) => {
      const dialog = this.dialogService.dialog.open(
        DetectorChangeDeviceComponent,
        {
          disableClose: true,
        }
      );
      dialog.componentInstance.modelId = this.model.id;
      dialog.componentInstance.model = result;
      dialog.componentInstance.customerId = this.customerId;
      return dialog;
    });
  }

  getEdit(id) {
    return this.modelService.get(id, this.customerId);
  }

  get name() {
    return this.formGroup.controls["name"];
  }

  get description() {
    return this.formGroup.controls["description"];
  }
  get direction() {
    return this.formGroup.controls["direction"];
  }
  get detectorDeviceId() {
    return this.formGroup.controls["detectorDeviceId"];
  }
  get userStatus() {
    return this.formGroup.controls["userStatus"];
  }
  get onlyFreeCheck() {
    return this.formGroup.controls["onlyFreeCheck"];
  }
  get customerSerialNumber() {
    return this.formGroup.controls["customerSerialNumber"];
  }
  get detectionArea() {
    return this.formGroup.controls["detectionArea"];
  }
}
