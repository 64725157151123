import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import {
  ParkingControllerType,
  ParkingType,
  ParkingKioskType,
  UploadSettingsToParkingControllerResult,
  PirsKioskError,
  ParkingCameraEventType,
} from './parking.dtos';

export const PARKING_TYPES = [
  // {
  //   type: ParkingType.Open,
  //   name: _('PARKING_TYPE.OPEN'),
  // },
  // {
  //   type: ParkingType.Barrier,
  //   name: _('PARKING_TYPE.BARRIER'),
  // },
  {
    type: ParkingType.ParkingLock,
    name: _('PARKING_TYPE.PARKING_LOCK'),
  },
  {
    type: ParkingType.KeyPark,
    name: _('PARKING_TYPE.KEY_PARK'),
  },
];

export const PARKING_CONTROLLER_TYPES = [
  {
    type: ParkingControllerType.MsuPirs,
    name: _('PARKING_CONTROLLER_TYPE.MSU_PIRS'),
    prefix: 'pirs#',
  },
  // {
  //   type: ParkingControllerType.None,
  //   name: _('PARKING_CONTROLLER_TYPE.NONE'),
  //   prefix: '',
  // },
];

export const PARKING_KIOSK_TYPES = [
  {
    type: ParkingKioskType.PirsKiosk,
    name: _('PARKING_KIOSK_TYPE.PIRS_KIOSK'),
    prefix: 'pirskiosk#',
  },
  // {
  //   type: ParkingKioskType.None,
  //   name: _('PARKING_KIOSK_TYPE.NONE'),
  //   prefix: '',
  // },
];
export const PARKING_CAMERA_EVENT_TYPE = [
  {
    type: ParkingCameraEventType.Enter,
    name: _('PARKING_CAMERAS_EVENTS.ENTER'),
  },
  {
    type: ParkingCameraEventType.Leave,
    name: _('PARKING_CAMERAS_EVENTS.LEAVE'),
  },
  // {
  //   type: ParkingKioskType.None,
  //   name: _('PARKING_KIOSK_TYPE.NONE'),
  //   prefix: '',
  // },
];

export const PIRS_KIOSK_ERROR_TYPES = [
  {
    type: PirsKioskError.NoPaper,
    name: _('PIRS_KIOSK_ERROR_TYPE.NO_PAPER'),
  },
  {
    type: PirsKioskError.NoTerminalConnection,
    name: _('PIRS_KIOSK_ERROR_TYPE.NO_TERMINAL_CONNECTION'),
  },
  {
    type: PirsKioskError.NoScreenConnection,
    name: _('PIRS_KIOSK_ERROR_TYPE.NO_SCREEN_CONNECTION'),
  },
];

export const UPLOAD_SETTINGS_TO_CONTROLLER_TYPES = [
  {
    type: UploadSettingsToParkingControllerResult.Ok,
    name: _('UPLOAD_SETTINGS_TO_CONTROLLER_TYPES.OK'),
  },
  {
    type: UploadSettingsToParkingControllerResult.Failed,
    name: _('UPLOAD_SETTINGS_TO_CONTROLLER_TYPES.FAILED'),
  },
  {
    type: UploadSettingsToParkingControllerResult.OfflineQueued,
    name: _('UPLOAD_SETTINGS_TO_CONTROLLER_TYPES.OFFLINE_QUEUED'),
  },
];
