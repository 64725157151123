import { Injector, ChangeDetectorRef } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';

import { Observable, of } from 'rxjs';
import { finalize } from 'rxjs/operators';

import { IDataSource } from './data-source';

import { DialogService } from '../services/dialog.service';
import { FlashService } from '../services/flash.service';
import { TranslateService } from '@ngx-translate/core';

export abstract class DataSourceForm<T> {
  loading = false;
  isNew = false;

  model: T;
  modelService: IDataSource<T>;

  formGroup: FormGroup;
  formBuilder = this._injector.get(FormBuilder);
  flashService = this._injector.get(FlashService);
  translate = this._injector.get(TranslateService);

  dialog: MatDialogRef<DataSourceForm<T>>;

  constructor(
    protected _injector: Injector,
    public changeDetector: ChangeDetectorRef
  ) {}

  initForm(
    model: T,
    modelService: IDataSource<T>,
    isNew: boolean = false,
    dialog: MatDialogRef<DataSourceForm<T>>
  ) {
    this.model = model;
    this.isNew = isNew;
    this.modelService = modelService;
    this.dialog = dialog;
    this.formGroup = this.initFormGroup(this.formBuilder);
    this.formGroup.statusChanges.subscribe(() => {
      this.changeDetector.markForCheck();
    });
    this.reset();
  }

  abstract initFormGroup(fb: FormBuilder): FormGroup;
  abstract prepareModelToSave(): T;

  prepareParamsToSave(): any {}
  afterSave(model: T): Observable<any> {
    return of(null);
  }

  markAllAsTouched() {
    const controls = this.formGroup.controls;
    Object.keys(this.formGroup.controls).forEach((name) =>
      controls[name].markAsTouched()
    );
  }

  reset() {
    this.formGroup.reset(this.model);
  }

  submit(data?) {
    if (this.loading) return;

    if (this.formGroup.invalid) {
      this.markAllAsTouched();
      return;
    }

    this.loading = true;
    this.changeDetector.markForCheck();

    const modelToSave = this.prepareModelToSave();
    const paramsToSave = this.prepareParamsToSave();

    const request = this.isNew
      ? this.modelService.add(modelToSave, paramsToSave)
      : this.modelService.update(modelToSave, paramsToSave);

    request
      .pipe(
        finalize(() => {
          this.loading = false;
          this.changeDetector.markForCheck();
        })
      )
      .subscribe((model) => {
        this.afterSave(model).subscribe(() => {
          if (this.dialog) {
            this.dialog.close({ data: data || {}, item: model });
          } else {
            DialogService.windowMessage(
              this.translate.instant('COMMON.CHANGES_SAVED')
            );
            setTimeout(() => window.close());
          }
        });
      });
  }

  close() {
    if (this.dialog) {
      this.dialog.close(false);
    } else {
      window.close();
    }
  }
}
