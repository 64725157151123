import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';

import { Injectable, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { JsonServiceClient } from '@servicestack/client';

import { Observable, EMPTY, from } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';

import {
  Authenticate,
  GetAccessToken,
  GetCurrentUserPermissionsRequest,
  IReturn,
  LogoutCurrentUserEverywhereRequest,
} from '../dtos/hub.dtos';

import { TokenService } from './token.service';
import { FlashService } from '../services/flash.service';
import { DialogService } from '../services/dialog.service';

import { APP_ENVIRONMENT } from '../utils/shared-consts';

const LOGIN_PATH = '/login';

export interface IUserLogin {
  username: string;
  password: string;
  remember: boolean;
}

export function getInternetAndServerError(error) {
  if (window.navigator.onLine) {
    if (
      error instanceof TypeError &&
      (error.message === 'Failed to fetch' ||
        error.message === 'NetworkError when attempting to fetch resource.')
    ) {
      return _('API_ERROR.SERVER_UNAVAILABLE');
    } else {
      return error.message;
    }
  } else {
    return _('API_ERROR.NO_INTERNET_CONNECTION');
  }
}

@Injectable({ providedIn: 'root' })
export class AuthService {
  authApiUrl: string;

  constructor(
    private _router: Router,
    private _tokenService: TokenService,
    private _flashService: FlashService,
    private _dialogService: DialogService,
    public translate: TranslateService,
    @Inject(APP_ENVIRONMENT) _appEnv
  ) {
    this.authApiUrl = _appEnv.modules.hub.apiUrl;
  }

  get isLoggedIn() {
    return this._tokenService.bearerToken !== null;
  }

  login(userLogin: IUserLogin): Observable<void> {
    const request = new Authenticate();
    request.provider = 'credentials';
    request.userName = userLogin.username;
    request.password = userLogin.password;

    const client = new JsonServiceClient(this.authApiUrl);
    return from(client.post(request)).pipe(
      mergeMap((authResponse) => {
        client.bearerToken = authResponse.bearerToken;
        const permissionRequest = new GetCurrentUserPermissionsRequest();
        return from(client.get(permissionRequest)).pipe(
          map((permissions) => {
            return { authResponse, permissions };
          })
        );
      }),
      catchError((error) => {
        if (error.ResponseStatus || error.responseStatus) {
          const camel = !error.ResponseStatus;
          const status = camel ? error.responseStatus : error.ResponseStatus;
          const errorCode = camel ? status.errorCode : status.ErrorCode;
          const message = camel ? status.message : status.Message;
          switch (errorCode) {
            case 'Unauthorized':
              throw new Error(_('LOGIN_ERROR.BAD_USERNAME_OR_PASSWORD'));
            case 'Forbidden':
              throw new Error(_('LOGIN_ERROR.ACCESS_FORBIDDEN'));
            default:
              this._flashService.error(message);
              break;
          }
        } else {
          if (!document.hidden) {
            this._flashService.warn(
              this.translate.instant(getInternetAndServerError(error))
            );
          }
        }
        return EMPTY;
      }),
      map((result) => {
        this._tokenService.setToken(
          result.authResponse.bearerToken,
          result.authResponse.refreshToken,
          result.permissions,
          userLogin.remember
        );
      })
    );
  }

  logout(everywhere = false, fromUrl = '', loginUrl = LOGIN_PATH) {
    const request: IReturn<any> = everywhere
      ? new LogoutCurrentUserEverywhereRequest()
      : new Authenticate({ provider: 'logout' });

    const client = new JsonServiceClient(this.authApiUrl);
    client.bearerToken = this._tokenService.bearerToken;
    from(client.post(request)).subscribe();

    this._tokenService.clear();
    this._dialogService.dialog.closeAll();

    if (document.location.pathname.toLowerCase() !== loginUrl) {
      const returnUrl = fromUrl
        ? fromUrl
        : this._router.routerState.snapshot.url;
      this._router.navigate([loginUrl], { queryParams: { returnUrl } });
    }
  }

  async refresh() {
    if (!this._tokenService.refreshToken) {
      return false;
    }

    const request = new GetAccessToken();
    const remember = this._tokenService.remember;
    request.refreshToken = this._tokenService.refreshToken;

    const client = new JsonServiceClient(this.authApiUrl);
    client.bearerToken = this._tokenService.bearerToken;
    const response = await client.post(request);

    client.bearerToken = response.accessToken;
    const permissionRequest = new GetCurrentUserPermissionsRequest();
    const permissions = await client.get(permissionRequest);

    this._tokenService.setToken(
      response.accessToken,
      response.refreshToken,
      permissions,
      remember
    );

    return true;
  }
}
