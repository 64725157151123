<div
  style="
    height: 400px;
    width: 345px;
    background-color: #2121219c;
    position: absolute;
    top: 17px;
    right: 66px;
    z-index: 1000;
    box-sizing: border-box;
    padding: 12px;
    border-radius: 15px;

  "
  fxLayout="column"
  fxLayoutAlign="start center"
>
  <div *ngIf="loading" class="backdrop" style="overflow: hidden;">
    <mat-spinner diameter="36" color="accent"></mat-spinner>
  </div>
  <h3>Тепловая карта ДТП</h3>
  <span style="align-self: start;">Диапазон дат</span>
  <div
    style="
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
    "
  >
    <div>
      <mat-form-field style="margin-right: 8px; width: 135px;">
        <input
          matInput
          type="month"
          [formControl]="fromControl"
          class="spin-button"
        />
        <mat-icon class="date-button">today</mat-icon>
      </mat-form-field>

      <span style="margin-right: 8px;">-</span>

      <mat-form-field style="width: 135px;">
        <input
          matInput
          type="month"
          [formControl]="toControl"
          class="spin-button"
        />
        <mat-icon class="date-button">today</mat-icon>
      </mat-form-field>
    </div>
    <div style="cursor: pointer;" (click)="resetDataPicker()">
      <mat-icon>close</mat-icon>
    </div>
  </div>
  <mat-form-field
    style="margin-bottom: 15px; display: block; width: 100%; height: 42px;"
    floatLabel="never"
  >
    <mat-select
      [formControl]="accidentTypeControl"
      placeholder="{{ 'TRAFFIC_ACCIDENT.ACCIDENT_TYPE' | translate }}"
      multiple
    >
      <div *ngFor="let item of types">
        <mat-divider></mat-divider>
        <mat-option [value]="item.type">{{ item.name | translate }}</mat-option>
      </div>
    </mat-select>
  </mat-form-field>

  <div
    style="
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      gap: 8px;
    "
  >
    <span>
      Пострадавшие
    </span>
    <mat-form-field
      style="margin-bottom: 15px; display: block; width: 100%; height: 42px;"
      floatLabel="never"
    >
      <mat-select [formControl]="hasInjured">
        <mat-divider></mat-divider>
        <mat-option [value]="3">Неважно</mat-option>
        <mat-divider></mat-divider>
        <mat-option [value]="true">Есть</mat-option>
        <mat-divider></mat-divider>
        <mat-option [value]="false">Нет</mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div
    style="
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      gap: 8px;
    "
  >
    <span>
      Погибшие
    </span>
    <mat-form-field
      style="margin-bottom: 15px; display: block; width: 100%; height: 42px;"
      floatLabel="never"
    >
      <mat-select [formControl]="hasKilled">
        <mat-divider></mat-divider>
        <mat-option [value]="3">Неважно</mat-option>
        <mat-divider></mat-divider>
        <mat-option [value]="true">Есть</mat-option>
        <mat-divider></mat-divider>
        <mat-option [value]="false">Нет</mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <!-- <div>
    <mat-slide-toggle [formControl]="allInjuredKilled"
      >Показать всех</mat-slide-toggle
    >
    <mat-slide-toggle [formControl]="hasInjured"
      >Есть пострадавшие</mat-slide-toggle
    >
    <mat-slide-toggle [formControl]="hasKilled">Есть погибшие</mat-slide-toggle>
  </div> -->

  <div
    style="
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      width: 100%;
      margin-top: 14px;
    "
  >
    <mat-slide-toggle [formControl]="showClusterMarker"
      >Показать маркеры ДТП</mat-slide-toggle
    >
  </div>
</div>
