import { EventEmitter, Injectable, OnDestroy } from '@angular/core';

import { BehaviorSubject, forkJoin, Observable, of, Subject, timer } from 'rxjs';
import { catchError, finalize, map, shareReplay, switchMap, takeWhile, tap } from 'rxjs/operators';

import { SettingsService } from 'projects/msu-its-web-common/src/services/settings.service';

import {
  PotokSettings,
  RecoveryMovement,
  Route,
  RouteObject,
  StepMovement,
  TrafficControllerType,
  TrafficObject,
  UsdkSettings,
} from '../../dtos/tlc.dtos';
import { SchemaSet, SchemaUtils } from '../../dtos/schema';
import { LayoutSvgUtils } from '../../dtos/layout';
import { TrafficObjectService } from '../../services/traffic-object.service';
import { UsdkTrafficObjectService } from '../../services/usdk-traffic-object.service';
import { PotokTrafficObjectService } from '../../services/potok-traffic-object.service';
import { RouteService } from '../../services/route.service';

export enum EscortStepStatus {
  InProgress = 'InProgress',
  Completed = 'Completed',
  Wait = 'Wait',
}

export interface IEscortStep {
  routeObject: RouteObject;
  status: EscortStepStatus;
  startTime?: number;
  completeTime?: number;
}

@Injectable()
export class RouteEscortStateService implements OnDestroy {
  _destroy = new Subject();

  customerId: string;

  test = false;

  trafficObjects = new Map<string, TrafficObject>();
  trafficObjectsSchemaSets = new Map<string, SchemaSet>();
  trafficObjectsSettings = new Map<string, UsdkSettings | PotokSettings>();

  escortSteps: IEscortStep[] = [];

  escortStepsChanged = new EventEmitter();

  selectedRouteBounded = false;

  private readonly _loading = new BehaviorSubject<boolean>(false);
  readonly loading$ = this._loading.pipe(shareReplay(1));
  get loading(): boolean {
    return this._loading.getValue();
  }
  set loading(value) {
    this._loading.next(value);
  }

  private readonly _escortStarted = new BehaviorSubject<boolean>(false);
  readonly escortStarted$ = this._escortStarted.pipe(shareReplay(1));
  get escortStarted(): boolean {
    return this._escortStarted.getValue();
  }
  set escortStarted(value) {
    this._escortStarted.next(value);
  }

  private readonly _selectedRoute = new BehaviorSubject<Route>(null);
  readonly selectedRoute$ = this._selectedRoute.pipe(shareReplay(1));
  get selectedRoute(): Route {
    return this._selectedRoute.getValue();
  }
  set selectedRoute(value) {
    this._selectedRoute.next(value);
  }

  constructor(
    private _routeService: RouteService,
    private _trafficObjectService: TrafficObjectService,
    private _usdkTrafficObjectService: UsdkTrafficObjectService,
    private _potokTrafficObjectService: PotokTrafficObjectService,
    private _settingsService: SettingsService
  ) {}

  ngOnDestroy() {
    this._destroy.next();
    this._destroy.complete();
    this._destroy = null;

    this.escortStepsChanged.complete();
    this.escortStepsChanged = null;
  }

  selectRoute(id: string) {
    this.loading = true;
    return this._routeService
      .get(id, this.customerId)
      .pipe(switchMap((m) => this.loadRouteDetails(m).pipe(map(() => m))))
      .pipe(finalize(() => (this.loading = false)))
      .pipe(
        tap((selectedRoute) => {
          selectedRoute.settings.routeObjects = selectedRoute.settings.routeObjects.filter((m) =>
            this.trafficObjects.has(m.trafficObjectId)
          );
          this.selectedRoute = selectedRoute;
        })
      );
  }

  loadRouteDetails(selectedRoute: Route) {
    this.selectedRoute = null;

    this.trafficObjects.clear();
    this.trafficObjectsSchemaSets.clear();
    this.trafficObjectsSettings.clear();

    const forkObs = [of(null)];
    selectedRoute?.settings.routeObjects.forEach((m) => {
      forkObs.push(
        this._trafficObjectService.get(m.trafficObjectId, this.customerId).pipe(
          switchMap((t) => {
            this.trafficObjects.set(m.trafficObjectId, t);
            return t.controllerType == TrafficControllerType.Usdk
              ? this._usdkTrafficObjectService.getSettings(m.trafficObjectId, this.customerId)
              : t.controllerType == TrafficControllerType.Potok
              ? this._potokTrafficObjectService.getSettings(m.trafficObjectId, this.customerId)
              : of(null);
          }),
          tap((s) => this.trafficObjectsSettings.set(m.trafficObjectId, s))
        )
      );
      forkObs.push(
        this._trafficObjectService
          .getSchemaSet(m.trafficObjectId, this.customerId)
          .pipe(tap((t) => this.trafficObjectsSchemaSets.set(m.trafficObjectId, t)))
      );
    });

    return forkJoin(forkObs);
  }

  loadObjectDetails(objectId) {
    const forkObs = [of(null)];

    forkObs.push(
      this._trafficObjectService.get(objectId, this.customerId).pipe(
        switchMap((t) => {
          this.trafficObjects.set(objectId, t);
          return t.controllerType == TrafficControllerType.Usdk
            ? this._usdkTrafficObjectService.getSettings(objectId, this.customerId)
            : t.controllerType == TrafficControllerType.Potok
            ? this._potokTrafficObjectService.getSettings(objectId, this.customerId)
            : of(null);
        }),
        tap((s) => this.trafficObjectsSettings.set(objectId, s))
      )
    );
    forkObs.push(
      this._trafficObjectService
        .getSchemaSet(objectId, this.customerId)
        .pipe(tap((t) => this.trafficObjectsSchemaSets.set(objectId, t)))
    );

    return forkJoin(forkObs);
  }

  objectSvgLoad(target, item: RouteObject, step: StepMovement | RecoveryMovement) {
    const object = this.trafficObjects.get(item.trafficObjectId);
    const settings = this.trafficObjectsSchemaSets.get(item.trafficObjectId);

    const schema = settings.schema;
    const schemaView = settings.schemaView;
    const isDark = this._settingsService.darkTheme;
    const svg = target.contentDocument;

    const phaseMovements = [];
    switch (object.controllerType) {
      case TrafficControllerType.Potok:
        phaseMovements.push(...step.movements);
        break;
      case TrafficControllerType.Usdk:
        const phase = (this.trafficObjectsSettings.get(
          item.trafficObjectId
        ) as UsdkSettings)?.phases.find((m) => m.id == step.phase);
        phase && phaseMovements.push(...phase?.movements);
        break;
      default:
        break;
    }

    const movements = [];
    phaseMovements.forEach((m) => {
      movements.push(...SchemaUtils.schemaViewMovements(schema, m));
    });

    LayoutSvgUtils.updateLayoutSchema(svg, schema, schemaView, isDark);
    LayoutSvgUtils.showLayoutMovements(svg, schema, movements);
  }
  getObjectAngle(item: RouteObject) {
    return this.trafficObjectsSchemaSets.get(item.trafficObjectId)?.schemaView?.angle;
  }
  getObjectName(item: RouteObject) {
    return this.trafficObjects.get(item.trafficObjectId)?.name;
  }

  startEscort() {
    if (!this.selectedRoute || this.escortStarted) {
      return;
    }

    this.escortSteps = [];
    this.escortStarted = true;

    timer(0, 1000)
      .pipe(takeWhile(() => this.escortStarted))
      .subscribe(() => {
        this.escortStepsChanged.emit();
      });
  }
  stopEscort() {
    if (!this.escortStarted) {
      return;
    }

    const stopObs = [of(null)];
    this.escortSteps.forEach((m) => {
      m.status == EscortStepStatus.InProgress &&
        stopObs.push(this.stopStep(m.routeObject.trafficObjectId));
    });

    forkJoin(stopObs).subscribe(() => {
      this.escortStarted = false;
      this.selectedRoute = null;
    });
  }

  startStep(id) {
    let step = this.escortSteps.find(
      (m) => m.routeObject.trafficObjectId == id && m.status != EscortStepStatus.Completed
    );

    if (step?.status == EscortStepStatus.Wait) {
      return;
    }

    const object = this.trafficObjects.get(id);
    const routeObject = this.selectedRoute.settings.routeObjects.find(
      (m) => m.trafficObjectId == id
    );

    if (step) {
      step.status = EscortStepStatus.Wait;
    } else {
      step = { status: EscortStepStatus.Wait, routeObject };
      this.escortSteps.push(step);
    }

    let request$: Observable<any>;

    switch (object.controllerType) {
      case TrafficControllerType.Potok:
        request$ = this.test
          ? timer(4000)
          : this._potokTrafficObjectService.setRemoteMode(
              id,
              this.customerId,
              <any>routeObject.stepMovement.movements,
              this._settingsService.escortSuspendTime
            );
        break;
      case TrafficControllerType.Usdk:
        request$ = this.test
          ? timer(4000)
          : this._usdkTrafficObjectService.setRemoteMode(
              id,
              this.customerId,
              routeObject.stepMovement.phase,
              this._settingsService.escortSuspendTime
            );
        break;

      default:
        request$ = timer(4000);
        break;
    }

    return request$.pipe(catchError(() => of({ error: true }))).pipe(
      tap((result) => {
        const now = Date.now();
        if (result.error) {
          step.status = EscortStepStatus.Completed;
          step.startTime = now;
          step.completeTime = now;
        } else {
          step.startTime = now;
          step.completeTime = now + this._settingsService.escortSuspendTime * 1000;
          step.status = EscortStepStatus.InProgress;
        }
        this.escortStepsChanged.emit();
      })
    );
  }
  stopStep(id) {
    let step = this.escortSteps.find(
      (m) => m.routeObject.trafficObjectId == id && m.status != EscortStepStatus.Completed
    );

    if (step?.status == EscortStepStatus.Wait) {
      return;
    }

    const object = this.trafficObjects.get(id);
    const routeObject = this.selectedRoute.settings.routeObjects.find(
      (m) => m.trafficObjectId == id
    );

    if (step) {
      step.status = EscortStepStatus.Wait;
    } else {
      step = { status: EscortStepStatus.Wait, routeObject };
      this.escortSteps.push(step);
    }

    let request$: Observable<any>;

    switch (object.controllerType) {
      case TrafficControllerType.Potok:
        request$ = this.test
          ? timer(4000)
          : this._potokTrafficObjectService.setCoordinationMode(id, this.customerId);
        break;
      case TrafficControllerType.Usdk:
        request$ = this.test
          ? timer(4000)
          : this._usdkTrafficObjectService.setCoordinationMode(id, this.customerId);
        break;

      default:
        request$ = timer(4000);
        break;
    }

    return request$.pipe(catchError(() => of({ error: true }))).pipe(
      tap((result) => {
        const now = Date.now();
        if (result.error) {
          step.status = EscortStepStatus.Completed;
          step.startTime = now;
          step.completeTime = now;
        } else {
          step.status = EscortStepStatus.Completed;
          step.completeTime = now;
        }
        this.escortStepsChanged.emit();
      })
    );
  }
}
