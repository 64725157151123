import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnInit,
} from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import * as echarts from 'echarts';
import { DialogService } from 'projects/msu-its-web-common/src/services/dialog.service';
import { finalize } from 'rxjs/operators';
import { Detector, DetectorGraph, DetectorInfo, DetectorInternalGraph } from '../../dtos/tfms.dtos';
import { DetectorService } from '../../services/detector.service';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { MatDialogRef } from '@angular/material/dialog';
import { forkJoin, merge, Observable, Subject } from 'rxjs';
import { DatePipe } from '@angular/common';
import { DateAdapter } from '@angular/material/core';
import { TranslateService } from '@ngx-translate/core';
import { SettingsService } from 'projects/msu-its-web-common/src/services/settings.service';

@Component({
  selector: 'detector-external',
  templateUrl: './detector-history.component.html',
  styles: [
    `
      .chart {
        width: 100%;
        height: 230px;
        z-index: 2;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DetectorHistoryComponent implements AfterViewInit, OnInit {
  private _destroy = new Subject();
  @Input()
  modelId: string;

  @Input()
  customerId: string;

  @Input()
  modelInfo: Detector;

  selectedDataTypes;

  formGroup: FormGroup = this._formBuilder.group({
    Date1: [{ value: '', disabled: true }],
    Date2: [{ value: '', disabled: true }],
  });
  get date1() {
    return this.formGroup.get('Date1');
  }
  get date2() {
    return this.formGroup.get('Date2');
  }

  get locale() {
    return this.translate.currentLang;
  }
  get color() {
    return this._settingsService.darkTheme ? 'white' : 'black';
  }

  dataDetectors: DetectorGraph[] = [];
  dataEnternalDetectors: DetectorInternalGraph[] = [];
  AvgSpeedRed = '#ff3300';
  LaneFillGreen = '#00cc00';
  chartParams;
  options;
  constructor(
    private dateAdapter: DateAdapter<any>,
    private translate: TranslateService,
    private _route: ActivatedRoute,
    private _dialogRef: MatDialogRef<DetectorHistoryComponent>,
    private _detectorService: DetectorService,
    private _dialogService: DialogService,
    private _settingsService: SettingsService,
    private _changeDetector: ChangeDetectorRef,
    private _formBuilder: FormBuilder
  ) {}

  ngOnInit() {
    this.dateAdapter.setLocale(this.locale);
    const day1 = new Date();

    const day2 = new Date();

    day2.setDate(day2.getDate() - 2);
    this.selectedDataTypes = 'AvgSpeed';
    this.date1.setValue(day1);
    this.date2.setValue(day2);
    this._loadData();
    this.date1.valueChanges.subscribe(() => {
      this._loadData();
    });
    this.date2.valueChanges.subscribe(() => {
      this._loadData();
    });
  }
  ngAfterViewInit() {
    // Observable.merge(this.date1.valueChanges, this.date2.valueChanges)
    // .takeUntil(this._destroy)
    // .subscribe(() => {
    //   this._loadData();
    // });
  }
  changeType(event) {
    // if (
    //   this.selectedDataTypes === 'AvgSpeed' ||
    //   this.selectedDataTypes === 'Count' ||
    //   this.selectedDataTypes === 'LaneFill'
    // ) {
    //   this._updateChart(false);
    // } else {
    //   this._updateChart(true);
    // }
  }
  _loadData() {
    const start1 = new Date(this.date1.value);
    start1.setHours(0, 0, 0, 0);

    const end1 = new Date(this.date1.value);
    end1.setHours(24, 0, 0, 0);

    const start2 = new Date(this.date2.value);
    start2.setHours(0, 0, 0, 0);

    const end2 = new Date(this.date2.value);
    end2.setHours(24, 0, 0, 0);
    // to1.setHours(0);
    // to1.setHours(to1.getHours() - 24);
    // const to2 = new Date(this.date2.value);
    // to2.setHours(0);
    // to2.setHours(to2.getHours() - 24);
    // console.log(to1 + this.date1.value);
    // console.log(to2 + this.date2.value);
    const _date1 = new Date(this.date1.value);

    const _date2 = new Date(this.date2.value);

    forkJoin(
      this._detectorService.getDetectorGraph(this.modelId, start1, end1),
      this._detectorService.getDetectorGraph(this.modelId, start2, end2),
      this._detectorService.getDetectorInternalGraph(this.modelId, start1, end1),
      this._detectorService.getDetectorInternalGraph(this.modelId, start2, end2)
    ).subscribe(([result1, result2, result3, result4]) => {
      // this.dataDetectors.push(result1);
      // this.dataDetectors.push(result2);
      // this.dataEnternalDetectors.push(result3);
      // this.dataEnternalDetectors.push(result4);
      this.dataDetectors[0] = result1;
      this.dataDetectors[1] = result2;
      this.dataEnternalDetectors[0] = result3;
      this.dataEnternalDetectors[1] = result4;
      this._updateChart();
      this._changeDetector.markForCheck();
    });

    // if (
    //   this.selectedDataTypes === 'AvgSpeed' ||
    //   this.selectedDataTypes === 'Count' ||
    //   this.selectedDataTypes === 'LaneFill'
    // ) {
    //   forkJoin(
    //     this._detectorService.getDetectorGraph(this.modelId, to1, this.date1.value),
    //     this._detectorService.getDetectorGraph(this.modelId, to2, this.date2.value)
    //   ).subscribe((result) => {
    //     this.dataDetectors = result;
    //     this._updateChart(false);
    //     this._changeDetector.markForCheck();
    //   });
    // } else {
    //   forkJoin(
    //     this._detectorService.getDetectorInternalGraph(this.modelId, to1, this.date1.value),
    //     this._detectorService.getDetectorInternalGraph(this.modelId, to2, this.date2.value)
    //   ).subscribe((result) => {
    //     this.dataEnternalDetectors = result;
    //     this._updateChart(true);
    //     this._changeDetector.markForCheck();
    //   });
    // }
  }

  _updateChart() {
    if (this.chartParams) {
      this.chartParams.clear();
    }
    this.chartParams = echarts.init(document.getElementById('grafHistory') as HTMLDivElement);
    function time(value) {
      const _time = new Date(value);
      let label;
      if (_time.getMinutes() < 10) {
        label = _time.getHours() + ':0' + _time.getMinutes();
      } else {
        label = _time.getHours() + ':' + _time.getMinutes();
      }
      return label;
    }
    let axisLabel;
    if (this.selectedDataTypes === 'AvgSpeed') {
      // axisLabel = _('DETECTORS.SPEED_UNITS');
      axisLabel = 'км/ч';
    } else if (this.selectedDataTypes === 'Count') {
      // axisLabel = _('DETECTORS.COUTN_UNITS');
      axisLabel = 'ед';
    } else if (this.selectedDataTypes === 'LaneFill') {
      // axisLabel = _('DETECTORS.LANE_FILL_UNITS');
      axisLabel = '%';
    } else if (this.selectedDataTypes === 'Temp') {
      axisLabel = 'C';
    } else if (this.selectedDataTypes === 'Hum') {
      axisLabel = '%';
    } else if (this.selectedDataTypes === 'Heat') {
      axisLabel = ' ';
    }
    const dataAxis = [];
    const data1 = [];
    const data2 = [];
    if (
      this.selectedDataTypes === 'Temp' ||
      this.selectedDataTypes === 'Hum' ||
      this.selectedDataTypes === 'Heat'
    ) {
      this.dataEnternalDetectors[0].items.forEach((i) => {
        const _time = new Date(i.time);
        dataAxis.push(time(_time));
        if (this.selectedDataTypes === 'Temp') {
          data1.push(i.temperature);
        } else if (this.selectedDataTypes === 'Hum') {
          data1.push(i.humidityPercent);
        } else if (this.selectedDataTypes === 'Heat') {
          data1.push(i.heaterOn);
        }
      });
      this.dataEnternalDetectors[1].items.forEach((i) => {
        if (this.selectedDataTypes === 'Temp') {
          data2.push(i.temperature);
        } else if (this.selectedDataTypes === 'Hum') {
          data2.push(i.humidityPercent);
        } else if (this.selectedDataTypes === 'Heat') {
          data2.push(i.heaterOn);
        }
      });
    } else {
      this.dataDetectors[0].items.forEach((i) => {
        const _time = new Date(i.time);
        dataAxis.push(time(_time));
        if (this.selectedDataTypes === 'AvgSpeed') {
          data1.push(i.avgSpeed);
        } else if (this.selectedDataTypes === 'Count') {
          data1.push(i.count);
        } else if (this.selectedDataTypes === 'LaneFill') {
          data1.push(i.laneFill);
        }
      });
      this.dataDetectors[1].items.forEach((i) => {
        if (this.selectedDataTypes === 'AvgSpeed') {
          data2.push(i.avgSpeed);
        } else if (this.selectedDataTypes === 'Count') {
          data2.push(i.count);
        } else if (this.selectedDataTypes === 'LaneFill') {
          data2.push(i.laneFill);
        }
      });
    }

    const options = {
      // grid: {
      //   left: '2%',
      //   right: '4%',
      //   bottom: '12%',
      //   containLabel: true,
      // },
      tooltip: {
        trigger: 'axis',
        backgroundColor: '#ffffffbd',
        textStyle: {
          color: this.color,
        },
      },
      xAxis: {
        data: dataAxis,
        type: 'category',
        axisLine: {
          lineStyle: {
            color: this.color,
          },
        },
        axisLabel: {
          color: this.color,
        },
      },
      yAxis: {
        type: 'value',
        axisLine: {
          lineStyle: {
            color: this.color,
          },
        },
        axisLabel: {
          formatter: `{value} ${axisLabel}`,
          color: this.color,
        },
        splitLine: {
          show: true,
        },
      },
      series: [
        {
          name: `${new DatePipe('en').transform(this.date1.value, 'yyyy-MM-dd')}`,
          data: data1,
          type: 'line',
          smooth: true,
          color: this.AvgSpeedRed,
        },
        {
          name: `${new DatePipe('en').transform(this.date2.value, 'yyyy-MM-dd')}`,
          data: data2,
          type: 'line',
          smooth: true,
          color: this.LaneFillGreen,
        },
      ],
    };
    this.chartParams.setOption(options, true, true);
    // this._changeDetector.markForCheck();
  }
  cancel() {
    this._dialogRef.close(false);
  }
}
