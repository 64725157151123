import {
  Component,
  ViewChild,
  OnInit,
  ChangeDetectionStrategy,
  AfterViewInit,
  Inject,
} from '@angular/core';
import {
  trigger,
  style,
  state,
  transition,
  animate,
} from '@angular/animations';
import { TranslateService } from '@ngx-translate/core';

import { MatSidenav } from '@angular/material/sidenav';
import { SettingsService } from '../../services/settings.service';
import { SidenavService } from '../../services/sidenav.service';
import { DialogService } from '../../services/dialog.service';
import { TokenService } from '../../services/token.service';

import { APP_ENVIRONMENT, SIDENAV_MENU } from '../../utils/shared-consts';
import {
  getSidenavMenuCategories,
  ISidenavMenuCategory,
  ISidenavMenuItem,
} from '../../utils/sidenav-menu';
import { ActivatedRoute } from '@angular/router';
import { CustomerStateService } from '../../services/customer-state.service';

const maxWidth = '250px';
const minWidth = '64px';

export const sidenavAnimation = trigger('sidenavAnimation', [
  state(
    'expanded',
    style({
      width: maxWidth,
    })
  ),
  state(
    'collapsed',
    style({
      width: minWidth,
    })
  ),
  state(
    'collapsedOver',
    style({
      'margin-left': '-' + maxWidth,
      'box-shadow': 'none',
    })
  ),
  transition('expanded <=> collapsed', [animate('100ms')]),
  transition('expanded <=> collapsedOver', [
    animate('400ms cubic-bezier(0.25, 0.8, 0.25, 1)'),
  ]),
]);
export const sidenavContentAnimation = trigger('sidenavContentAnimation', [
  state(
    'expanded',
    style({
      'margin-left': maxWidth,
    })
  ),
  state(
    'collapsed',
    style({
      'margin-left': minWidth,
    })
  ),
  transition('expanded <=> collapsed', [animate('0.1s')]),
]);

@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.css'],
  animations: [sidenavAnimation, sidenavContentAnimation],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SidenavComponent implements OnInit, AfterViewInit {
  copy;
  menuCategories: ISidenavMenuCategory[];

  customerId: string;

  @ViewChild('sidenav', { static: false })
  sidenav: MatSidenav;

  constructor(
    private _dialogService: DialogService,
    private _tokenService: TokenService,
    private _customerStateService: CustomerStateService,
    private _settingsService: SettingsService,
    private _route: ActivatedRoute,
    public translate: TranslateService,
    public sidenavService: SidenavService,
    @Inject(APP_ENVIRONMENT) private _appEnv,
    @Inject(SIDENAV_MENU) private _sidenavMenu
  ) {
    this.customerId = this._route.snapshot.paramMap.get('customerId');
    this.menuCategories = getSidenavMenuCategories(
      this._sidenavMenu,
      this._tokenService,
      this._customerStateService
    );
    this.copy = `${this._appEnv.appTitle} © ${new Date().getFullYear()}`;
  }

  ngOnInit() {}

  ngAfterViewInit() {}

  contentAnimationDone() {
    this.sidenavService.changedAnimationDone.next();
  }

  get expanded() {
    return this.sidenavService.expanded;
  }

  get isDarkTheme() {
    return this._settingsService.darkTheme;
  }

  get over() {
    return true;
  }

  // about() {
  //   this._dialogService.about();
  // }

  openExternal(item: ISidenavMenuItem) {
    const url = `/${this.customerId}/ext/` + item.route;
    this._dialogService.windowDialog(
      url,
      'sidenav_' + item.route,
      'menubar=0,toolbar=0,titlebar=0,location=0,locationbar=0,width=1000,height=800,resizable=0'
    );
  }
}
