import {
  Component,
  ViewChild,
  Inject,
  OnInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, Validators } from '@angular/forms';
import { MatInput } from '@angular/material/input';

import { EMPTY, from } from 'rxjs';
import { catchError, finalize, switchMap } from 'rxjs/operators';

import { TranslateService } from '@ngx-translate/core';
import { DialogService } from 'projects/msu-its-web-common/src/services/dialog.service';
import { FlashService } from 'projects/msu-its-web-common/src/services/flash.service';
import { AuthService } from 'projects/msu-its-web-common/src/services/auth.service';
import { SettingsService } from 'projects/msu-its-web-common/src/services/settings.service';

import { LANGS } from 'projects/msu-its-web-common/src/utils/langs';

import { EPUTS_MODULES, EPUTS_SUBSYSTEMS } from './eputs-consts';

import { APP_ENVIRONMENT } from 'projects/msu-its-web-common/src/utils/shared-consts';

import { SelectLangComponent } from 'projects/msu-its-web-common/src/components/navbar/select-lang.component';

declare const Android: any;

@Component({
  selector: 'eputs-component',
  templateUrl: './eputs.component.html',
  styleUrls: ['./eputs.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EputsComponent implements OnInit {
  loading = false;

  submitted = false;
  passwordHide = true;
  errorMessage = '';

  langs = LANGS;
  modules = this.appEnv.eputsModules ?? EPUTS_MODULES;
  subsystems = this.appEnv.eputsSubsystems ?? EPUTS_SUBSYSTEMS;

  selectedItem: { id: string; name: string; type: string };
  selectedItemContent: string;

  formGroup = this._formBuilder.group({
    username: ['', Validators.required],
    password: ['', Validators.required],
    remember: false,
  });

  get username() {
    return this.formGroup.controls['username'];
  }
  get password() {
    return this.formGroup.controls['password'];
  }
  get remember() {
    return this.formGroup.controls['remember'];
  }

  @ViewChild('usernameInput', { static: false })
  public usernameInput: MatInput;

  constructor(
    private _router: Router,
    private _formBuilder: FormBuilder,
    private _dialogService: DialogService,
    private _flashService: FlashService,
    private _authService: AuthService,
    private _activatedRoute: ActivatedRoute,
    private _settingsService: SettingsService,
    private _changeDetector: ChangeDetectorRef,
    public translate: TranslateService,
    @Inject(APP_ENVIRONMENT) public appEnv
  ) {}

  ngOnInit() {
    // if (this._authService.isLoggedIn) {
    //   const returnUrl = this._activatedRoute.snapshot.queryParams['returnUrl'];
    //   this._router.navigate([returnUrl ? returnUrl : '/']);
    // }
    if (typeof Android !== 'undefined') {
      const values = JSON.parse(Android.getValues());
      this.username.setValue(values.user);
      this.password.setValue(values.password);
    }
  }

  submit() {
    if (!this.formGroup.valid || this.submitted) {
      return;
    }

    this._startSubmit();
    this._authService
      .login(this.formGroup.value)
      .pipe(
        catchError((error) => {
          this.errorMessage = error.message;
          this._resetSubmit();
          return EMPTY;
        }),
        finalize(() => {
          if (this.submitted) {
            this._resetSubmit();
          }
        })
      )
      .subscribe(() => {
        if (typeof Android !== 'undefined') {
          Android.saveValues(this.username.value, this.password.value);
        }

        const itemUrl = this._getItemUrl(this.selectedItem.id);
        if (itemUrl) {
          setTimeout(() => window.open(itemUrl));
          return;
        }

        const returnUrl = this._activatedRoute.snapshot.queryParams['returnUrl'];
        this._router.navigate([returnUrl ? returnUrl : '/']);
      });
  }

  private _getItemUrl(id: string) {
    switch (id) {
      case 'effectiveness':
        return this.appEnv?.modules.manager?.url;
      default:
        return null;
    }
  }

  private _startSubmit() {
    this.submitted = true;
    this.errorMessage = '';
    this.formGroup.disable();
    this._changeDetector.markForCheck();
  }

  private _resetSubmit() {
    this.submitted = false;
    this.formGroup.enable();
    this._changeDetector.markForCheck();
    if (this.errorMessage) {
      setTimeout(() => this.usernameInput?.focus(), 300);
    }
  }

  get darkTheme() {
    return this._settingsService.darkTheme;
  }

  setDarkTheme() {
    this._settingsService.darkTheme = !this._settingsService.darkTheme;
  }

  getErrorMessage() {
    const t = this.translate;
    return this.errorMessage ? t.instant(this.errorMessage) : '';
  }

  selectLang() {
    const dialog = this._dialogService.dialog.open(SelectLangComponent, {
      disableClose: false,
    });
    dialog.afterClosed().subscribe((lang) => {
      if (lang) {
        this.translate.get('COMMON.LANGUAGE').subscribe((val) => {
          this._flashService.success(val + ': ' + LANGS[lang].toLowerCase());
        });
      }
    });
  }

  getCurrentLangName() {
    return LANGS[this.translate.currentLang].toLowerCase();
  }

  selectItem(item, type) {
    if (this.selectedItem?.id == item.id) {
      this.selectedItem = null;
      this.selectedItemContent = '';
    } else {
      if (item.url) {
        window.open(item.url, "_self");
        return;
      }
      this.selectedItem = { ...item, type };
      this._loadContent();
    }
  }

  _loadContent() {
    this.loading = true;
    this.selectedItemContent = '';
    this._changeDetector.markForCheck();

    if (!this.selectedItem) return;

    from(fetch(`/assets/html/eputs/${this.translate.currentLang}/${this.selectedItem.id}.html`))
      .pipe(
        // catchError(() => EMPTY),
        finalize(() => {
          this.loading = false;
          this._changeDetector.markForCheck();
        })
      )
      .pipe(
        switchMap((response) => {
          return response.ok ? response.text() : EMPTY;
        })
      )
      .subscribe((html) => {
        this.selectedItemContent = html;
        this._changeDetector.markForCheck();
      });
  }
}
