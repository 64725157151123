<div
  fxLayout="column"
  fxLayoutGap="12px"
  style="min-width: 400px; position: relative; min-height: 250px;"
>
  <div *ngIf="loading" class="backdrop" style="position: absolute; z-index: 6;">
    <div
      class="backdrop-inner mat-dialog-bg mat-elevation-z4"
      style="padding-bottom: 16px;"
    >
      <mat-spinner diameter="36"></mat-spinner>
    </div>
  </div>

  <div
    *ngIf="isOffline || errors?.length || isEngineerMode"
    class="backdrop"
    style="position: absolute; z-index: 5; pointer-events: none;"
  >
    <div
      class="backdrop-inner mat-dialog-bg mat-elevation-z4"
      style="pointer-events: all; max-width: 350px;"
    >
      <div
        *ngIf="isOffline"
        fxLayout
        fxLayoutGap="12px"
        style="margin-bottom: 12px; align-items: center;"
      >
        <mat-icon class="dialog-warn">warning</mat-icon>
        <div>{{ "COMMON.NO_CONNECTION" | translate }}</div>
      </div>

      <div
        *ngFor="let error of errors"
        fxLayout
        fxLayoutGap="12px"
        style="margin-bottom: 12px; align-items: center;"
      >
        <mat-icon class="dialog-warn">warning</mat-icon>
        <div>{{ getErrorName(error) }}</div>
      </div>

      <div
        *ngIf="isEngineerMode"
        class="mat-primary-color"
        style="font-size: 20px; font-weight: 500;"
      >
        {{ "CONTROLLER_MODE.ENGINEER" | translate }}
      </div>
    </div>
  </div>

  <div class="state-container">
    <img #stateImage class="state-image" />

    <div
      class="mat-elevation-z2"
      style="display: flex; padding: 2px 0px 2px 6px; flex: 1;"
    >
      <div style="margin-right: 24px;">
        <div class="mat-small mat-secondary-color">
          {{ "PARKING_KIOSKS.PARKING_KIOSK" | translate }}:
        </div>
        <div class="mat-body-1" style="line-height: normal;">
          {{ model?.name }}
        </div>
      </div>

      <div style="flex: 1;">
        <ng-container *ngIf="status">
          <div class="mat-small mat-secondary-color">
            {{ "COMMON.CONNECTION_TIME" | translate }}:
          </div>
          <div
            class="mat-body-1"
            style="line-height: normal; padding-bottom: 3px;"
          >
            {{ status?.connectionTime | date: "dd.MM.yyyy
            HH:mm:ss":timezone:locale }}
          </div>
        </ng-container>
      </div>

      <button mat-icon-button [matMenuTriggerFor]="controllerMenu">
        <mat-icon>more_vert</mat-icon>
      </button>
    </div>
  </div>

  <mat-button-toggle-group
    [value]="modeStatus"
    class="mode-toggle-group"
    [disabled]="isOffline || isEngineerMode || (modeStatus==='')"
    (change)="toggleGroupChange($event)"
    appearance="standard"
  >
    <mat-button-toggle value="Work" (change)="setWorkMode()">
      {{ "PARKING_KIOSK_MODE.WORK" | translate }}
    </mat-button-toggle>

    <mat-button-toggle value="Off" (change)="setOffMode()">
      {{ "PARKING_KIOSK_MODE.OFF" | translate }}
    </mat-button-toggle>
  </mat-button-toggle-group>
</div>

<mat-menu
  #controllerMenu="matMenu"
  overlapTrigger="false"
  class="mat-menu-md"
  xPosition="before"
>
  <!-- <button [disabled]="!(['device.read'] | hasPermissions)" mat-menu-item (click)="openControllerFiles()">
    {{ "COMMON.FILES" | translate }}
  </button>
  <button [disabled]="!(['device.read'] | hasPermissions)" mat-menu-item (click)="openControllerAudit()">
    {{ "COMMON.AUDIT" | translate }}
  </button>
  <button [disabled]="!(['device.read'] | hasPermissions)" mat-menu-item (click)="openControllerErrorLog()">
    {{ "COMMON.ERROR_LOG" | translate }}
  </button>
  
  <button [disabled]="!(['device.modify'] | hasPermissions)" mat-menu-item (click)="openControllerEdit()">
    {{ "COMMON.EDIT" | translate }}
  </button>
  <button [disabled]="!(['device.control'] | hasPermissions)" mat-menu-item (click)="disconnectController()">
    {{ "DEVICES.SESSION_DISCONNECT" | translate }}
  </button> -->
  <button mat-menu-item (click)="eventLog()">
    {{ "EVENT_LOG.TITLE" | translate }}
  </button>
  <!-- <button mat-menu-item (click)="disconnectController()">
    {{ "DEVICES.SESSION_DISCONNECT" | translate }}
  </button> -->
</mat-menu>
