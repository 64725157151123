import { Injectable } from "@angular/core";

import { Observable, of } from "rxjs";
import { map } from "rxjs/operators";

import {
  IDataSourceService,
  setPageParams,
  getPageReturn,
  IPageParams,
  IPageReturn,
} from "projects/msu-its-web-common/src/data/data-source";

import { PtmApiService } from "../ptm-api.service";
import {
  CreatePublicTransportScheduleRequest,
  DeletePublicTransportSchedulesRequest,
  // GetPublicTransportScheduleExistRequest,
  GetPublicTransportScheduleRequest,
  GetPublicTransportSchedulesRequest,
  GetPublicTransportVerifiedScenesRequest,
  PublicTransportRouteScene,
  PublicTransportSchedule,
  UpdatePublicTransportScheduleRequest,
} from "../../dtos/ptm.dtos";

@Injectable()
export class ScheduleService
  implements
    IDataSourceService<PublicTransportSchedule, PublicTransportSchedule> {
  constructor(private _api: PtmApiService) {}

  getPagedList(
    params: IPageParams,
    customerId: string
  ): Observable<IPageReturn<PublicTransportSchedule>> {
    if (!customerId) {
      return of(null);
    }

    const request = new GetPublicTransportSchedulesRequest();
    request.customerId = customerId;

    setPageParams(request, params);
    return this._api.get(request).pipe(
      map((m) => {
        return getPageReturn(m);
      })
    );
  }
  getList(
    customerId: string,
    filter?: string,
    groupIds?: string[],
    items?: number
  ): Observable<PublicTransportSchedule[]> {
    const request = new GetPublicTransportSchedulesRequest();
    request.customerId = customerId;
    request.entityGroups = groupIds || [];

    request.page = 0;
    request.items = items || 0;
    request.sort = "name";
    request.filter = filter || "";

    return this._api.get(request).pipe(
      map((m) => {
        return m.items;
      })
    );
  }

  get(id: string, customerId: string) {
    const request = new GetPublicTransportScheduleRequest();
    request.id = id;
    request.customerId = customerId;
    return this._api.get(request);
  }
  add(
    model: PublicTransportSchedule,
    params: {
      customerId: string;
      groups: string[];
      updateGroups: boolean;
    }
  ) {
    const request = new CreatePublicTransportScheduleRequest();
    request.entity = model;
    request.customerId = params.customerId;
    request.entityGroups = params.groups;
    request.updateEntityGroups = params.updateGroups;
    return this._api.post(request);
  }
  update(
    model: PublicTransportSchedule,
    params: { customerId: string; groups: string[]; updateGroups: boolean }
  ) {
    const request = new UpdatePublicTransportScheduleRequest();
    request.id = model.id;
    request.entity = model;
    request.customerId = params.customerId;
    request.entityGroups = params.groups;
    request.updateEntityGroups = params.updateGroups;
    return this._api.put(request);
  }
  delete(ids: string[], customerId: string) {
    const request = new DeletePublicTransportSchedulesRequest();
    request.ids = ids;
    request.customerId = customerId;
    return this._api.delete(request);
  }
  exist(name: string, customerId: string): Observable<boolean> {
    return of(false);
    // const request = new GetPublicTransportScheduleExistRequest({ name, customerId });
    // return this._api.get(request);
  }
}
