import { Injectable } from '@angular/core';
import { IDataSourceService, IPageParams } from 'projects/msu-its-web-common/src/data/data-source';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import {
  GetPublicTransportGlonassTerminalsEventTypesRequest,
  GetPublicTransportGlonassTerminalsEventLogRequest,
  EntityEventLogDetails,
} from '../../dtos/ptm.dtos';

import { PtmApiService } from '../ptm-api.service';

@Injectable({ providedIn: 'root' })
export class TerminalEventLogService
  implements IDataSourceService<EntityEventLogDetails, EntityEventLogDetails>
{
  constructor(private _api: PtmApiService) {}

  getPagedList(
    params: IPageParams,
    requestParams: {
      id?: string;
      customerId: string;
      start?: string;
      end?: string;
      filter?: number[];
    }
  ) {
    const request = new GetPublicTransportGlonassTerminalsEventLogRequest();

    request.id = requestParams.id;
    request.customerId = requestParams.customerId;
    request.start = requestParams.start;
    request.end = requestParams.end;
    request.filterEvents = requestParams.filter;

    request.sort = params.sort;
    if (params.sort && params.desc) {
      request.sort += ' desc';
    }

    return this._api.get(request).pipe(
      map((m) => {
        return { page: 0, size: 0, total: 0, data: m.items };
      })
    );
  }

  get(id: any): Observable<EntityEventLogDetails> {
    throw 'not implemented';
  }

  add(model: EntityEventLogDetails): Observable<EntityEventLogDetails> {
    throw 'not implemented';
  }

  update(model: EntityEventLogDetails): Observable<EntityEventLogDetails> {
    throw 'not implemented';
  }

  delete(ids: string[]): Observable<number> {
    throw 'not implemented';
  }

  getEventTypes(id: string, customerId: string) {
    const request = new GetPublicTransportGlonassTerminalsEventTypesRequest({
      id,
      customerId,
    } as any);
    return this._api.get(request);
  }
}
