import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from "@angular/core";
import { marker as _ } from "@biesbjerg/ngx-translate-extract-marker";


import { Observable, of } from "rxjs";

import { TranslateService } from "@ngx-translate/core";
import { Parking, ParkingType } from "../../dtos/parking.dtos";
import { PARKING_TYPES } from "../../dtos/enums";

@Component({
  selector: "parking-popup",
  templateUrl: "./parking-popup.component.html",
  styles: [
    `
      :host {
        height: 100% !important;
        width: 100% !important;
        /* padding: 28px; */
      }
      a {
        color: #ffda00;
        text-decoration: none;
        font-weight: 400;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ParkingPopupComponent implements OnInit, AfterViewInit {
  @Input()
  modelId: string;

  external: boolean = false;

  @Input()
  customerId: string;

  @Input()
  modelInfo: Parking;

  modelPermissions$: Observable<string[]> = of(null);

  parkingTypeData = PARKING_TYPES;

  constructor(
    public translate: TranslateService,
  ) {}

  ngAfterViewInit(): void {

  }
  ngOnInit() {

  }

  getNameParkingType(item: ParkingType) {
    return this.parkingTypeData.find((m) => m.type === item).name;
  }
}
