<div style="width: 800px; height: 500px">
  <div fxLayout="column" fxLayoutAlign="space-around start">
    <div fxFlex="10" fxLayout="column">
      <h3 style="margin: 0; font-weight: 500; font-size: 16px; line-height: 1">
        {{ modelInfo.name }}<br />
        <span class="mat-small mat-hint mat-secondary">{{ modelInfo.description }}</span>
      </h3>
    </div>
    <div fxFlexFill fxLayout="row wrap" fxFlexOffset="2" style="z-index: 1">
      <div fxFlex fxLayoutGap="10px">
        <mat-form-field floatLabel="always" fxFlex>
          <input matInput [formControl]="date1" (click)="picker1.open()" [matDatepicker]="picker1" />
          <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
          <mat-datepicker #picker1 disabled="false"></mat-datepicker>
        </mat-form-field>

        <mat-form-field floatLabel="always" fxFlex>
          <input matInput [formControl]="date2" (click)="picker2.open()" [matDatepicker]="picker2" />
          <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
          <mat-datepicker #picker2 disabled="false"></mat-datepicker>
        </mat-form-field>
      </div>
      <div fxFlex fxLayoutAlign="end">
        <mat-form-field>
          <mat-select [(ngModel)]="selectedDataTypes" placeholder="Mode" (ngModelChange)="_updateChart()">
            <mat-option value="AvgSpeed"> {{ "DETECTORS.SPEED" | translate }}</mat-option>
            <mat-option value="Count"> {{ "DETECTORS.QUANTITY" | translate }}</mat-option>
            <mat-option value="LaneFill"> {{ "DETECTORS.FILLING" | translate }}</mat-option>
            <mat-divider></mat-divider>
            <mat-option value="Temp"> {{ "POPUP.TEMPRETURE" | translate }}</mat-option>
            <mat-option value="Hum"> {{ "POPUP.HUMIDITY" | translate }}</mat-option>
            <mat-option value="Heat"> Грелка</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <div fxFlexFill>
      <div echarts id="grafHistory" style="width: 100% !important; height: 400px; margin-top: -30px"></div>
    </div>
    <div fxFlexFill fxLayoutAlign="end center" >
      <button mat-button (click)="cancel()" style="margin-top: -15px !important;">
        <span>{{ "COMMON.CANCEL" | translate }}</span>
      </button>
    </div>
  </div>
</div>
