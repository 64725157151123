import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy } from '@angular/core';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { IPotokTrafficObjectSet, PotokStateService } from './potok-state.service';

import {
  PotokBarrier,
  PotokBarrierType,
  PotokControllerErrorType,
  PotokTrafficObjectStatus,
  TrafficMovementType,
  TrafficObjectMode,
} from 'projects/msu-its-web-tlc/src/dtos/tlc.dtos';
import {
  getBarrierPartNames,
  getRingParts,
  IRingPart,
  PotokPlan,
  updateBarrierPartSvg,
  updateBarrierStaticSvg,
} from 'projects/msu-its-web-tlc/src/dtos/potok/potok-plan';

import { LayoutSvgUtils } from 'projects/msu-its-web-tlc/src/dtos/layout';

import { SettingsService } from 'projects/msu-its-web-common/src/services/settings.service';

import { LANGS_LOCALES } from 'projects/msu-its-web-common/src/utils/langs';
import { TranslateService } from '@ngx-translate/core';
import { CONTROLLER_STATES } from 'projects/msu-its-web-tlc/src/dtos/enums';
import { SchemaUtils } from 'projects/msu-its-web-tlc/src/dtos/schema';
import { PotokTrafficObjectService } from 'projects/msu-its-web-tlc/src/services/potok-traffic-object.service';
import { FlashService } from 'projects/msu-its-web-common/src/services/flash.service';
import { TrafficObjectControllerService } from 'projects/msu-its-web-tlc/src/services/traffic-object-controller.service';
import { DialogService } from 'projects/msu-its-web-common/src/services/dialog.service';
import { TrafficObjectReportComponent } from 'projects/msu-its-web-tss/src/components/traffic-object-report/traffic-object-report.component';

@Component({
  selector: 'potok-state-controller',
  templateUrl: './potok-state-controller.component.html',
  styleUrls: ['./potok-state-controller.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PotokStateControllerComponent implements OnDestroy {
  _destroy = new Subject();
  _rings = new Map<number, IRingPart[][]>();

  status: PotokTrafficObjectStatus;
  trafficObjectSet: IPotokTrafficObjectSet;

  _currentMode: TrafficObjectMode;
  _currentPlan: number;

  _currentPlanPhasesType: 'Transport' | 'Pedestrian';
  _currentAdaptivePlanId: number;

  plan: PotokPlan;
  planBarriers: PotokBarrier[];

  controllerStates = {};

  get timezone() {
    return this._settingsService.timezone;
  }
  get locale() {
    return LANGS_LOCALES[this.translate.currentLang];
  }

  get schema() {
    return this.trafficObjectSet.schemaSet.schema;
  }
  get schemaView() {
    return this.trafficObjectSet.schemaSet.schemaView;
  }

  get trafficStatus() {
    return this.status?.controllerStatus?.traffic;
  }
  get controllerStatus() {
    return this.status?.controllerStatus?.controller;
  }

  constructor(
    private _flashService: FlashService,
    private _stateService: PotokStateService,
    private _settingsService: SettingsService,
    private _potokTrafficObjectService: PotokTrafficObjectService,
    private _controllerService: TrafficObjectControllerService,
    private _changeDetector: ChangeDetectorRef,
    private _dialogService: DialogService,
    public translate: TranslateService
  ) {
    CONTROLLER_STATES.forEach((m) => (this.controllerStates[m.type] = m));

    this._stateService.trafficObjectSet$
      .pipe(takeUntil(this._destroy))
      .subscribe((trafficObjectSet) => {
        this.trafficObjectSet = trafficObjectSet;
        this._updatePlanBarriers(true);
        this._changeDetector.markForCheck();
      });

    this._stateService.status$.pipe(takeUntil(this._destroy)).subscribe((status) => {
      this.status = status;
      this._updatePlanBarriers();
      this._changeDetector.markForCheck();
    });
  }

  ngOnDestroy() {
    this._destroy.next();
    this._destroy.complete();
  }

  _updatePlanBarriers(force = false) {
    if (
      (this.status && force) ||
      (this.status && this.trafficStatus && this._currentMode !== this.status.mode) ||
      this._currentPlan !== this.trafficStatus?.coordinationPlan ||
      this._currentPlanPhasesType !== this.trafficStatus?.planPhasesType ||
      this._currentAdaptivePlanId !== this.controllerStatus?.lastAdaptivePlan?.id
    ) {
      this._currentMode = this.status.mode;
      this._currentPlan = this.trafficStatus.coordinationPlan;
      this._currentPlanPhasesType = this.trafficStatus.planPhasesType;
      this._currentAdaptivePlanId = this.controllerStatus.lastAdaptivePlan?.id;

      if (this.status.mode === TrafficObjectMode.Adaptive) {
        this.plan = <PotokPlan>(
          Object.assign(new PotokPlan(), this.controllerStatus.lastAdaptivePlan)
        );
      } else {
        const leadZero = this._currentPlan >= 10 ? '' : '0';
        this.plan = this.trafficObjectSet.settings.plans['plan' + leadZero + this._currentPlan];
      }

      if (this.plan) {
        this._rings.clear();
        this.planBarriers =
          this._currentPlanPhasesType === 'Pedestrian'
            ? this.plan.pedestrianBarriers
            : this.plan.transportBarriers;
      }

      // if (this.plan && this.planBarriers) {
      // } else {
      //   this._currentMode = null;
      // }
    }
  }

  getRings(index: number) {
    let rings = this._rings.get(index);
    if (!rings) {
      const barrier = this.planBarriers[index];
      rings = [getRingParts(barrier.ring1, 1), getRingParts(barrier.ring2, 2)].filter(
        (m) => m.length
      );
      this._rings.set(index, rings);
    }
    return rings;
  }

  getBarrierPartNames(
    barrierType: PotokBarrierType,
    barrierNumber: number,
    ringPart?: IRingPart,
    pedestrian?: boolean
  ) {
    return getBarrierPartNames(barrierNumber, barrierType, ringPart, pedestrian);
  }

  updateStaticSvg(target, barrier: PotokBarrier) {
    const darkTheme = this._settingsService.darkTheme;
    const svg = target.contentDocument;
    LayoutSvgUtils.updateLayoutSchema(svg, this.schema, this.schemaView, darkTheme);
    updateBarrierStaticSvg(target, this.schema, barrier);
  }

  updatePartSvg(object, barrierType: PotokBarrierType, part: IRingPart, pedestrian = false) {
    updateBarrierPartSvg(object, this.schema, barrierType, part, pedestrian);
  }

  timeDiff() {
    const serverTime = Date.parse(this.status?.serverTime);
    const controllerTime = Date.parse(this.status?.controllerTime);
    return Math.abs(Math.floor((controllerTime - serverTime) / 1000));
  }
  timeDiffString() {
    const serverTime = Date.parse(this.status?.serverTime);
    const controllerTime = Date.parse(this.status?.controllerTime);
    const timeDiff = Math.floor((controllerTime - serverTime) / 1000);
    return timeDiff > 0 ? '+' + timeDiff : timeDiff;
  }

  get isOffline() {
    return this.controllerStatus?.errors?.some((m) => m.type == PotokControllerErrorType.Offline);
  }

  repeatRemoteMode() {
    const schema = this.schema;
    const movements: any[] = SchemaUtils.schemaLightToMovements(schema, this.status.schemaLights);

    this._stateService
      .loadingQuery(
        this._potokTrafficObjectService.setRemoteMode(
          this.trafficObjectSet.model.id,
          this._stateService.customerId,
          movements
        )
      )
      .subscribe();
  }

  // openControllerEdit() {
  //   const id = this.object.ControllerId;
  //   this._dialogService.windowDialog(
  //     '/settings/device-edit/' + id,
  //     'DeviceEdit' + id,
  //     'menubar=0,toolbar=0,titlebar=0,location=0,locationbar=0,width=1080,height=760,resizable=0'
  //   );
  // }

  // openControllerErrorLog() {
  //   const id = this.object.ControllerId;
  //   window.open(
  //     '/settings/device-error-log/' + id,
  //     'DeviceErrorLog' + id,
  //     'menubar=0,toolbar=0,titlebar=0,location=0,locationbar=0,width=960,height=850,resizable=0'
  //   );
  // }

  // openControllerEventLog() {
  //   const id = this.object.ControllerId;
  //   window.open(
  //     '/settings/event-log/device/' + id,
  //     'EventLog' + id,
  //     'menubar=0,toolbar=0,titlebar=0,location=0,locationbar=0,width=960,height=850,resizable=0'
  //   );
  // }

  // openControllerFiles() {
  //   const dialog = this._dialogService.dialog.open(FilesComponent, { disableClose: false });
  //   dialog.componentInstance.entityId = this.object.ControllerId;
  //   dialog.componentInstance.entityName = this.object.ControllerFullNumber;
  // }

  // openControllerAudit() {
  //   const id = this.object.ControllerId;
  //   window.open(
  //     '/settings/activity-log/device/' + id,
  //     'DeviceAudit' + id,
  //     'menubar=0,toolbar=0,titlebar=0,location=0,locationbar=0,width=960,height=850,resizable=0'
  //   );
  // }

  // openControllerSettings() {
  //   const id = this.object.ControllerId;
  //   this._dialogService.windowDialog(
  //     '/settings/device-settings/' + id,
  //     'DeviceSettings' + id,
  //     'menubar=0,toolbar=0,titlebar=0,location=0,locationbar=0,width=640,height=480,resizable=0'
  //   );
  // }

  disconnectController() {
    this._stateService
      .loadingQuery(
        this._controllerService.disconnect(
          this.trafficObjectSet.model.controllerId,
          this._stateService.customerId
        )
      )
      .subscribe((result) => {
        if (result) {
          this._flashService.success(
            this.translate.instant('DEVICES.SESSION_DISCONNECTED', {
              number: this.trafficObjectSet.model.controllerId,
            })
          );
        }
      });
  }
  trafficObjectReport() {
    const dialog = this._dialogService.dialog.open(TrafficObjectReportComponent, {
      disableClose: true,
    });
    dialog.componentInstance.trafficObjectId = this.trafficObjectSet.model.id;
    dialog.componentInstance.trafficObjectName = this.trafficObjectSet.model.name;
    dialog.componentInstance.schemaSet = this.trafficObjectSet.schemaSet;
  }

  eventLog() {
    const url = `${this._stateService.customerId}/ext/traffic-object-controller/${this.trafficObjectSet.model.controllerId}/event-log`;
    this._dialogService.windowDialog(
      url,
      'trafficObjectControllerEventLog_' + this.trafficObjectSet.model.controllerId,
      'menubar=0,toolbar=0,titlebar=0,location=0,locationbar=0,width=950,height=850,resizable=0'
    );
  }
}
