/* Options:
Date: 2024-04-03 08:42:14
Version: 6.70
Tip: To override a DTO option, remove "//" prefix before updating
BaseUrl: https://ftv.dev.its.msu24.ru

//GlobalNamespace: 
//MakePropertiesOptional: False
//AddServiceStackTypes: True
//AddResponseStatus: False
//AddImplicitVersion: 
//AddDescriptionAsComments: True
//IncludeTypes: 
//ExcludeTypes: 
//DefaultImports: 
*/

export interface IReturn<T> {
  createResponse(): T;
}

export interface IReturnVoid {
  createResponse(): void;
}

export interface IHasSessionId {
  sessionId: string;
}

export interface IHasBearerToken {
  bearerToken: string;
}

export interface IPost {}

// @DataContract
export class PageRequest {
  // @DataMember
  public page: number;

  // @DataMember
  public items: number;

  // @DataMember
  public sort: string;

  // @DataMember
  public filter: string;

  // @DataMember
  public propertiesTest: { [index: string]: number };

  public constructor(init?: Partial<PageRequest>) {
    (Object as any).assign(this, init);
  }
}

export interface ICustomerItemRequest extends ICustomerEntity {}

export interface ICustomerEntity {
  // @DataMember
  customerId: string;
}

export interface ICustomerIntegrationIdRequest {
  customerIntegrationId: string;
}

export interface IBaseEntityItemRequest {
  id: string;
}

export interface IDescription {
  // @DataMember
  description: string;
}

export interface IRequestWithEntity {}

// @DataContract
export class GetEntityGroupsRequestBase implements ICustomerItemRequest {
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public entityGroups: string[];

  // @DataMember
  public itemsIds: string[];

  // @DataMember
  public onlyOwnedItems: boolean;

  // @DataMember
  public page: number;

  // @DataMember
  public items: number;

  // @DataMember
  public sort: string;

  // @DataMember
  public filter: string;

  // @DataMember
  public propertiesTest: { [index: string]: number };

  public constructor(init?: Partial<GetEntityGroupsRequestBase>) {
    (Object as any).assign(this, init);
  }
}

export enum ViolationType {
  Unknown = 'Unknown',
  HighSpeed = 'HighSpeed',
  Buslane = 'Buslane',
  CellPhone = 'CellPhone',
  OncomingSide = 'OncomingSide',
  RedLight = 'RedLight',
  StopLine = 'StopLine',
  SeatBelts = 'SeatBelts',
  HeadLight = 'HeadLight',
  NoStopping = 'NoStopping',
  NoParking = 'NoParking',
  PedestrianPriority = 'PedestrianPriority',
  WrongLane = 'WrongLane',
}

// @DataContract
export class FixationEventInfo implements ICustomerEntityViewModel, IExternalSystemEntity, IEntity {
  // @DataMember
  public fixationPointId: string;

  // @DataMember
  public eventDate: string;

  // @DataMember
  public licensePlate: string;

  // @DataMember
  public violationType: ViolationType;

  // @DataMember
  public customerId: string;

  // @DataMember
  public customerName: string;

  // @DataMember
  public externalSystemId?: string;

  // @DataMember
  public externalSystemName: string;

  // @DataMember
  public externalSystemEntityId?: string;

  // @DataMember
  public id: string;

  // @DataMember
  public entityType: string;

  public constructor(init?: Partial<FixationEventInfo>) {
    (Object as any).assign(this, init);
  }
}

export enum VehicleClass {
  Unknown = 'Unknown',
  A = 'A',
  B = 'B',
  C = 'C',
  D = 'D',
  E = 'E',
}

export class DeleteCustomerItemsRequest implements ICustomerItemRequest, IDescription {
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public description: string;

  // @DataMember(IsRequired=true)
  public ids: string[];

  public constructor(init?: Partial<DeleteCustomerItemsRequest>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class ChangeCustomerRequestBase
  implements IDescription, ICustomerItemRequest, IBaseEntityItemRequest
{
  // @DataMember
  public description: string;

  // @DataMember
  public newCustomerId: string;

  // @DataMember
  public customerId: string;

  // @DataMember
  public id: string;

  public constructor(init?: Partial<ChangeCustomerRequestBase>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class GetEntityGroupRequestBase implements ICustomerItemRequest, IBaseEntityItemRequest {
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember(IsRequired=true)
  public id: string;

  public constructor(init?: Partial<GetEntityGroupRequestBase>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class EntityGroup
  implements
    ICustomerEntityViewModel,
    IExternalSystemEntity,
    INamedEntity,
    IChangeKey,
    IDescription,
    IModified,
    IEntity
{
  // @DataMember
  public items: string[];

  // @DataMember
  public customerId: string;

  // @DataMember
  public customerName: string;

  // @DataMember
  public externalSystemId?: string;

  // @DataMember
  public externalSystemName: string;

  // @DataMember
  public externalSystemEntityId?: string;

  // @DataMember
  public name: string;

  // @DataMember
  public description: string;

  // @DataMember
  public changeKey: string;

  // @DataMember
  public created: string;

  // @DataMember
  public createdBy: string;

  // @DataMember
  public modified: string;

  // @DataMember
  public modifiedBy: string;

  // @DataMember
  public id: string;

  // @DataMember
  public entityType: string;

  public constructor(init?: Partial<EntityGroup>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class CreateEntityGroupRequestBase implements ICustomerItemRequest, IDescription {
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public updateEntityGroups: boolean;

  // @DataMember
  public entityGroups: string[];

  // @DataMember(IsRequired=true)
  public entity: EntityGroup;

  // @DataMember
  public description: string;

  public constructor(init?: Partial<CreateEntityGroupRequestBase>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class UpdateEntityGroupRequestBase
  implements ICustomerItemRequest, IBaseEntityItemRequest, IDescription
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public updateEntityGroups: boolean;

  // @DataMember
  public entityGroups: string[];

  // @DataMember(IsRequired=true)
  public id: string;

  // @DataMember(IsRequired=true)
  public entity: EntityGroup;

  // @DataMember
  public description: string;

  public constructor(init?: Partial<UpdateEntityGroupRequestBase>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class DeleteEntityGroupsRequestBase implements ICustomerItemRequest, IDescription {
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public description: string;

  // @DataMember(IsRequired=true)
  public ids: string[];

  public constructor(init?: Partial<DeleteEntityGroupsRequestBase>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class GetEntityGroupSharesRequestBase
  implements IBaseEntityItemRequest, ICustomerItemRequest
{
  // @DataMember
  public id: string;

  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public entityGroups: string[];

  // @DataMember
  public itemsIds: string[];

  // @DataMember
  public onlyOwnedItems: boolean;

  // @DataMember
  public page: number;

  // @DataMember
  public items: number;

  // @DataMember
  public sort: string;

  // @DataMember
  public filter: string;

  // @DataMember
  public propertiesTest: { [index: string]: number };

  public constructor(init?: Partial<GetEntityGroupSharesRequestBase>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class EntityGroupPermissions implements IEntityTyped, ICustomerEntityViewModel {
  // @DataMember
  public customerId: string;

  // @DataMember
  public customerName: string;

  // @DataMember
  public entityGroupId: string;

  // @DataMember
  public permissions: string[];

  // @DataMember
  public entityType: string;

  public constructor(init?: Partial<EntityGroupPermissions>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class UpgradeEntityGroupShareRequestBase implements ICustomerItemRequest, IDescription {
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember(IsRequired=true)
  public id: string;

  // @DataMember(IsRequired=true)
  public entity: EntityGroupPermissions;

  // @DataMember
  public description: string;

  public constructor(init?: Partial<UpgradeEntityGroupShareRequestBase>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class IconStatus {
  // @DataMember
  public color: string;

  // @DataMember
  public borderColor: string;

  public constructor(init?: Partial<IconStatus>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class FixationPointStatus implements IEntity {
  // @DataMember
  public fixationDeviceId?: string;

  // @DataMember
  public iconStatus: IconStatus;

  // @DataMember
  public online: boolean;

  // @DataMember
  public connectionTime?: string;

  // @DataMember
  public id: string;

  // @DataMember
  public entityType: string;

  public constructor(init?: Partial<FixationPointStatus>) {
    (Object as any).assign(this, init);
  }
}

export interface IChangeKey extends IReadonlyChangeKey {
  // @DataMember
  changeKey: string;
}

export interface IReadonlyChangeKey {
  // @DataMember
  changeKey: string;
}

// @DataContract
export class BaseEntityViewModelBase implements IChangeKey, IDescription, IModified, IEntity {
  // @DataMember
  public description: string;

  // @DataMember
  public changeKey: string;

  // @DataMember
  public created: string;

  // @DataMember
  public createdBy: string;

  // @DataMember
  public modified: string;

  // @DataMember
  public modifiedBy: string;

  // @DataMember
  public id: string;

  // @DataMember
  public entityType: string;

  public constructor(init?: Partial<BaseEntityViewModelBase>) {
    (Object as any).assign(this, init);
  }
}

export interface IEntity {}

export interface IEntityTyped {
  // @DataMember
  entityType: string;
}

export interface IModified {
  // @DataMember
  modified: string;

  // @DataMember
  modifiedBy: string;
}

// @DataContract
export class EntityGroupInfo
  implements
    ICustomerEntityViewModel,
    IExternalSystemEntity,
    INamedEntity,
    IChangeKey,
    IDescription,
    IModified,
    IEntity
{
  // @DataMember
  public itemsCount: number;

  // @DataMember
  public customerId: string;

  // @DataMember
  public customerName: string;

  // @DataMember
  public externalSystemId?: string;

  // @DataMember
  public externalSystemName: string;

  // @DataMember
  public externalSystemEntityId?: string;

  // @DataMember
  public name: string;

  // @DataMember
  public description: string;

  // @DataMember
  public changeKey: string;

  // @DataMember
  public created: string;

  // @DataMember
  public createdBy: string;

  // @DataMember
  public modified: string;

  // @DataMember
  public modifiedBy: string;

  // @DataMember
  public id: string;

  // @DataMember
  public entityType: string;

  public constructor(init?: Partial<EntityGroupInfo>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export enum ReplacementReason {
  Demount = 'Demount',
  Replace = 'Replace',
  Other = 'Other',
}

export class CustomerBaseEntityViewModelBase
  implements
    ICustomerEntityViewModel,
    IExternalSystemEntity,
    IChangeKey,
    IDescription,
    IModified,
    IEntity
{
  // @DataMember
  public customerId: string;

  // @DataMember
  public customerName: string;

  // @DataMember
  public externalSystemId?: string;

  // @DataMember
  public externalSystemName: string;

  // @DataMember
  public externalSystemEntityId?: string;

  // @DataMember
  public description: string;

  // @DataMember
  public changeKey: string;

  // @DataMember
  public created: string;

  // @DataMember
  public createdBy: string;

  // @DataMember
  public modified: string;

  // @DataMember
  public modifiedBy: string;

  // @DataMember
  public id: string;

  // @DataMember
  public entityType: string;

  public constructor(init?: Partial<CustomerBaseEntityViewModelBase>) {
    (Object as any).assign(this, init);
  }
}

export interface ICustomerEntityViewModel extends ICustomerName, ICustomerEntity {}

export interface ICustomerName {
  // @DataMember
  customerName: string;
}

export interface IExternalSystemEntity {
  // @DataMember
  externalSystemId?: string;

  // @DataMember
  externalSystemName: string;

  // @DataMember
  externalSystemEntityId?: string;
}

// @DataContract
export enum FixationDeviceType {
  None = 'None',
  Azimuth = 'Azimuth',
  Duplo = 'Duplo',
}

export class CustomerEntityViewModelBase
  implements ICustomerEntityViewModel, IExternalSystemEntity, IEntity
{
  // @DataMember
  public customerId: string;

  // @DataMember
  public customerName: string;

  // @DataMember
  public externalSystemId?: string;

  // @DataMember
  public externalSystemName: string;

  // @DataMember
  public externalSystemEntityId?: string;

  // @DataMember
  public id: string;

  // @DataMember
  public entityType: string;

  public constructor(init?: Partial<CustomerEntityViewModelBase>) {
    (Object as any).assign(this, init);
  }
}

export class CustomerDeviceViewModelBase
  implements
    ICustomerEntity,
    ICustomerName,
    IDeviceItem,
    IOwnedDeviceItem,
    IChangeKey,
    IDescription,
    IModified,
    IEntity
{
  // @DataMember
  public customerId: string;

  // @DataMember
  public customerName: string;

  // @DataMember
  public ownerObjectName: string;

  // @DataMember
  public ownerObjectId: string;

  // @DataMember
  public prefix: string;

  // @DataMember
  public number: string;

  // @DataMember
  public phone1: string;

  // @DataMember
  public phone2: string;

  // @DataMember
  public ip1: string;

  // @DataMember
  public ip2: string;

  // @DataMember
  public imei: string;

  // @DataMember
  public deviceSettings: string;

  // @DataMember
  public lat: number;

  // @DataMember
  public lon: number;

  // @DataMember
  public direction: number;

  // @DataMember
  public fullNumber: string;

  // @DataMember
  public softwareVersion: string;

  // @DataMember
  public firmwareVersion: string;

  // @DataMember
  public hardwareVersion: string;

  // @DataMember
  public description: string;

  // @DataMember
  public changeKey: string;

  // @DataMember
  public created: string;

  // @DataMember
  public createdBy: string;

  // @DataMember
  public modified: string;

  // @DataMember
  public modifiedBy: string;

  // @DataMember
  public id: string;

  // @DataMember
  public entityType: string;

  public constructor(init?: Partial<CustomerDeviceViewModelBase>) {
    (Object as any).assign(this, init);
  }
}

export interface IDeviceItem extends IDeviceVersion {
  // @DataMember
  prefix: string;

  // @DataMember
  number: string;

  // @DataMember
  phone1: string;

  // @DataMember
  phone2: string;

  // @DataMember
  ip1: string;

  // @DataMember
  ip2: string;

  // @DataMember
  imei: string;

  // @DataMember
  lat: number;

  // @DataMember
  lon: number;

  // @DataMember
  direction: number;
}

export interface IDeviceVersion {
  // @DataMember
  softwareVersion: string;

  // @DataMember
  firmwareVersion: string;

  // @DataMember
  hardwareVersion: string;
}

export interface IOwnedDeviceItem {
  // @DataMember
  ownerObjectName: string;

  // @DataMember
  ownerObjectId: string;
}

export class CustomerNamedBaseEntityViewModelBase
  implements
    ICustomerEntityViewModel,
    IExternalSystemEntity,
    INamedEntity,
    IChangeKey,
    IDescription,
    IModified,
    IEntity
{
  // @DataMember
  public customerId: string;

  // @DataMember
  public customerName: string;

  // @DataMember
  public externalSystemId?: string;

  // @DataMember
  public externalSystemName: string;

  // @DataMember
  public externalSystemEntityId?: string;

  // @DataMember
  public name: string;

  // @DataMember
  public description: string;

  // @DataMember
  public changeKey: string;

  // @DataMember
  public created: string;

  // @DataMember
  public createdBy: string;

  // @DataMember
  public modified: string;

  // @DataMember
  public modifiedBy: string;

  // @DataMember
  public id: string;

  // @DataMember
  public entityType: string;

  public constructor(init?: Partial<CustomerNamedBaseEntityViewModelBase>) {
    (Object as any).assign(this, init);
  }
}

export interface INamedEntity extends IEntity, INamed {}

export interface INamed {
  // @DataMember
  name: string;
}

// @DataContract
export class FixationPointSession {
  // @DataMember
  public lastConnectionTime: string;

  // @DataMember
  public deviceNumber: string;

  public constructor(init?: Partial<FixationPointSession>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class WeightControlEventsStatDetails {
  // @DataMember
  public date: string;

  // @DataMember
  public events: number;

  // @DataMember
  public actsInProgress: number;

  // @DataMember
  public actsRejected: number;

  public constructor(init?: Partial<WeightControlEventsStatDetails>) {
    (Object as any).assign(this, init);
  }
}

export enum WeightControlPointType {
  Static = 'Static',
  Mobile = 'Mobile',
}

// @DataContract
export class ResponseError {
  // @DataMember(Order=1)
  public errorCode: string;

  // @DataMember(Order=2)
  public fieldName: string;

  // @DataMember(Order=3)
  public message: string;

  // @DataMember(Order=4)
  public meta: { [index: string]: string };

  public constructor(init?: Partial<ResponseError>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class ResponseStatus {
  // @DataMember(Order=1)
  public errorCode: string;

  // @DataMember(Order=2)
  public message: string;

  // @DataMember(Order=3)
  public stackTrace: string;

  // @DataMember(Order=4)
  public errors: ResponseError[];

  // @DataMember(Order=5)
  public meta: { [index: string]: string };

  public constructor(init?: Partial<ResponseStatus>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class DeleteItemsRequest implements IDescription {
  // @DataMember
  public description: string;

  // @DataMember(IsRequired=true)
  public ids: string[];

  public constructor(init?: Partial<DeleteItemsRequest>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class EntityViewModelBase implements IEntity {
  // @DataMember
  public id: string;

  // @DataMember
  public entityType: string;

  public constructor(init?: Partial<EntityViewModelBase>) {
    (Object as any).assign(this, init);
  }
}

export class DeviceViewModelBase
  implements IDeviceItem, IOwnedDeviceItem, IChangeKey, IDescription, IModified, IEntity
{
  // @DataMember
  public ownerObjectName: string;

  // @DataMember
  public ownerObjectId: string;

  // @DataMember
  public prefix: string;

  // @DataMember
  public number: string;

  // @DataMember
  public phone1: string;

  // @DataMember
  public phone2: string;

  // @DataMember
  public ip1: string;

  // @DataMember
  public ip2: string;

  // @DataMember
  public imei: string;

  // @DataMember
  public deviceSettings: string;

  // @DataMember
  public lat: number;

  // @DataMember
  public lon: number;

  // @DataMember
  public direction: number;

  // @DataMember
  public fullNumber: string;

  // @DataMember
  public softwareVersion: string;

  // @DataMember
  public firmwareVersion: string;

  // @DataMember
  public hardwareVersion: string;

  // @DataMember
  public description: string;

  // @DataMember
  public changeKey: string;

  // @DataMember
  public created: string;

  // @DataMember
  public createdBy: string;

  // @DataMember
  public modified: string;

  // @DataMember
  public modifiedBy: string;

  // @DataMember
  public id: string;

  // @DataMember
  public entityType: string;

  public constructor(init?: Partial<DeviceViewModelBase>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class NamedBaseEntityViewModelBase
  implements INamedEntity, IChangeKey, IDescription, IModified, IEntity
{
  // @DataMember
  public name: string;

  // @DataMember
  public description: string;

  // @DataMember
  public changeKey: string;

  // @DataMember
  public created: string;

  // @DataMember
  public createdBy: string;

  // @DataMember
  public modified: string;

  // @DataMember
  public modifiedBy: string;

  // @DataMember
  public id: string;

  // @DataMember
  public entityType: string;

  public constructor(init?: Partial<NamedBaseEntityViewModelBase>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class PageRequestBase {
  // @DataMember
  public page: number;

  // @DataMember
  public items: number;

  // @DataMember
  public sort: string;

  // @DataMember
  public filter: string;

  // @DataMember
  public propertiesTest: { [index: string]: number };

  public constructor(init?: Partial<PageRequestBase>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class EntityIntegration implements IChangeKey, IDescription, IModified, IEntity {
  // @DataMember
  public entityId: string;

  // @DataMember
  public customerIntegrationId: string;

  // @DataMember
  public externalId: string;

  // @DataMember
  public settings: string;

  // @DataMember
  public integrationId: string;

  // @DataMember
  public integrationName: string;

  // @DataMember
  public integrationType: string;

  // @DataMember
  public description: string;

  // @DataMember
  public changeKey: string;

  // @DataMember
  public created: string;

  // @DataMember
  public createdBy: string;

  // @DataMember
  public modified: string;

  // @DataMember
  public modifiedBy: string;

  // @DataMember
  public id: string;

  // @DataMember
  public entityType: string;

  public constructor(init?: Partial<EntityIntegration>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class GetEntityIntegrationResponse implements IChangeKey {
  // @DataMember
  public items: EntityIntegration[];

  // @DataMember
  public total: number;

  // @DataMember
  public page: number;

  // @DataMember
  public itemsPerPage: number;

  // @DataMember
  public changeKey: string;

  public constructor(init?: Partial<GetEntityIntegrationResponse>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class GetEntityGroupsResponse implements IChangeKey {
  // @DataMember
  public items: EntityGroupInfo[];

  // @DataMember
  public total: number;

  // @DataMember
  public page: number;

  // @DataMember
  public itemsPerPage: number;

  // @DataMember
  public changeKey: string;

  public constructor(init?: Partial<GetEntityGroupsResponse>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class GetEntitiesResponse implements IChangeKey {
  // @DataMember
  public items: Object[];

  // @DataMember
  public total: number;

  // @DataMember
  public page: number;

  // @DataMember
  public itemsPerPage: number;

  // @DataMember
  public changeKey: string;

  public constructor(init?: Partial<GetEntitiesResponse>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class FixationPointReplacement
  implements
    ICustomerEntityViewModel,
    IExternalSystemEntity,
    IChangeKey,
    IDescription,
    IModified,
    IEntity
{
  // @DataMember
  public date: string;

  // @DataMember
  public fixationPointId: string;

  // @DataMember
  public oldFixationDeviceId?: string;

  // @DataMember
  public fixationDeviceId?: string;

  // @DataMember
  public newDeviceNumber: string;

  // @DataMember
  public oldDeviceNumber: string;

  // @DataMember
  public reason: ReplacementReason;

  // @DataMember
  public customerId: string;

  // @DataMember
  public customerName: string;

  // @DataMember
  public externalSystemId?: string;

  // @DataMember
  public externalSystemName: string;

  // @DataMember
  public externalSystemEntityId?: string;

  // @DataMember
  public description: string;

  // @DataMember
  public changeKey: string;

  // @DataMember
  public created: string;

  // @DataMember
  public createdBy: string;

  // @DataMember
  public modified: string;

  // @DataMember
  public modifiedBy: string;

  // @DataMember
  public id: string;

  // @DataMember
  public entityType: string;

  public constructor(init?: Partial<FixationPointReplacement>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class FixationPointReplacementsResponse implements IChangeKey {
  // @DataMember
  public items: FixationPointReplacement[];

  // @DataMember
  public total: number;

  // @DataMember
  public page: number;

  // @DataMember
  public itemsPerPage: number;

  // @DataMember
  public changeKey: string;

  public constructor(init?: Partial<FixationPointReplacementsResponse>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class GetFixationEventsResponse implements IChangeKey {
  // @DataMember
  public items: FixationEventInfo[];

  // @DataMember
  public total: number;

  // @DataMember
  public page: number;

  // @DataMember
  public itemsPerPage: number;

  // @DataMember
  public changeKey: string;

  public constructor(init?: Partial<GetFixationEventsResponse>) {
    (Object as any).assign(this, init);
  }
}

export class FixationEvent implements ICustomerEntityViewModel, IExternalSystemEntity, IEntity {
  // @DataMember
  public fixationPointName: string;

  // @DataMember
  public fixationDeviceNumber: string;

  // @DataMember
  public fixationDeviceType: FixationDeviceType;

  // @DataMember
  public fixationPointId: string;

  // @DataMember
  public eventDate: string;

  // @DataMember
  public licensePlate: string;

  // @DataMember
  public violationType: ViolationType;

  // @DataMember
  public speed: number;

  // @DataMember
  public lat?: number;

  // @DataMember
  public lon?: number;

  // @DataMember
  public laneId?: number;

  // @DataMember
  public vehicleClass: VehicleClass;

  // @DataMember
  public customerId: string;

  // @DataMember
  public customerName: string;

  // @DataMember
  public externalSystemId?: string;

  // @DataMember
  public externalSystemName: string;

  // @DataMember
  public externalSystemEntityId?: string;

  // @DataMember
  public id: string;

  // @DataMember
  public entityType: string;

  public constructor(init?: Partial<FixationEvent>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class FixationDevice
  implements
    ICustomerEntity,
    ICustomerName,
    IDeviceItem,
    IOwnedDeviceItem,
    IChangeKey,
    IDescription,
    IModified,
    IEntity
{
  // @DataMember
  public fixationDeviceType: FixationDeviceType;

  // @DataMember
  public customerId: string;

  // @DataMember
  public customerName: string;

  // @DataMember
  public ownerObjectName: string;

  // @DataMember
  public ownerObjectId: string;

  // @DataMember
  public prefix: string;

  // @DataMember
  public number: string;

  // @DataMember
  public phone1: string;

  // @DataMember
  public phone2: string;

  // @DataMember
  public ip1: string;

  // @DataMember
  public ip2: string;

  // @DataMember
  public imei: string;

  // @DataMember
  public deviceSettings: string;

  // @DataMember
  public lat: number;

  // @DataMember
  public lon: number;

  // @DataMember
  public direction: number;

  // @DataMember
  public fullNumber: string;

  // @DataMember
  public softwareVersion: string;

  // @DataMember
  public firmwareVersion: string;

  // @DataMember
  public hardwareVersion: string;

  // @DataMember
  public description: string;

  // @DataMember
  public changeKey: string;

  // @DataMember
  public created: string;

  // @DataMember
  public createdBy: string;

  // @DataMember
  public modified: string;

  // @DataMember
  public modifiedBy: string;

  // @DataMember
  public id: string;

  // @DataMember
  public entityType: string;

  public constructor(init?: Partial<FixationDevice>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class GetFixationDevicesResponse implements IChangeKey {
  // @DataMember
  public items: FixationDevice[];

  // @DataMember
  public total: number;

  // @DataMember
  public page: number;

  // @DataMember
  public itemsPerPage: number;

  // @DataMember
  public changeKey: string;

  public constructor(init?: Partial<GetFixationDevicesResponse>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class GetEntityGroupSharesResponse implements IChangeKey {
  // @DataMember
  public items: EntityGroupPermissions[];

  // @DataMember
  public total: number;

  // @DataMember
  public page: number;

  // @DataMember
  public itemsPerPage: number;

  // @DataMember
  public changeKey: string;

  public constructor(init?: Partial<GetEntityGroupSharesResponse>) {
    (Object as any).assign(this, init);
  }
}

export class FixationPoint
  implements
    ICustomerEntityViewModel,
    IExternalSystemEntity,
    INamedEntity,
    IChangeKey,
    IDescription,
    IModified,
    IEntity
{
  // @DataMember
  public lat: number;

  // @DataMember
  public lon: number;

  // @DataMember
  public direction: number;

  // @DataMember
  public fixationDeviceId?: string;

  // @DataMember
  public fixationDeviceName: string;

  // @DataMember
  public customerId: string;

  // @DataMember
  public customerName: string;

  // @DataMember
  public externalSystemId?: string;

  // @DataMember
  public externalSystemName: string;

  // @DataMember
  public externalSystemEntityId?: string;

  // @DataMember
  public name: string;

  // @DataMember
  public description: string;

  // @DataMember
  public changeKey: string;

  // @DataMember
  public created: string;

  // @DataMember
  public createdBy: string;

  // @DataMember
  public modified: string;

  // @DataMember
  public modifiedBy: string;

  // @DataMember
  public id: string;

  // @DataMember
  public entityType: string;

  public constructor(init?: Partial<FixationPoint>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class GetFixationPointsResponse implements IChangeKey {
  // @DataMember
  public items: FixationPoint[];

  // @DataMember
  public total: number;

  // @DataMember
  public page: number;

  // @DataMember
  public itemsPerPage: number;

  // @DataMember
  public changeKey: string;

  public constructor(init?: Partial<GetFixationPointsResponse>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class GetFixationPointStatusesResponse implements IChangeKey {
  // @DataMember
  public items: FixationPointStatus[];

  // @DataMember
  public total: number;

  // @DataMember
  public page: number;

  // @DataMember
  public itemsPerPage: number;

  // @DataMember
  public changeKey: string;

  public constructor(init?: Partial<GetFixationPointStatusesResponse>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class FixationPointInfo
  implements
    ICustomerEntityViewModel,
    IExternalSystemEntity,
    INamedEntity,
    IChangeKey,
    IDescription,
    IModified,
    IEntity
{
  // @DataMember
  public fullNumber: string;

  // @DataMember
  public lat: number;

  // @DataMember
  public lon: number;

  // @DataMember
  public direction: number;

  // @DataMember
  public session: FixationPointSession;

  // @DataMember
  public customerId: string;

  // @DataMember
  public customerName: string;

  // @DataMember
  public externalSystemId?: string;

  // @DataMember
  public externalSystemName: string;

  // @DataMember
  public externalSystemEntityId?: string;

  // @DataMember
  public name: string;

  // @DataMember
  public description: string;

  // @DataMember
  public changeKey: string;

  // @DataMember
  public created: string;

  // @DataMember
  public createdBy: string;

  // @DataMember
  public modified: string;

  // @DataMember
  public modifiedBy: string;

  // @DataMember
  public id: string;

  // @DataMember
  public entityType: string;

  public constructor(init?: Partial<FixationPointInfo>) {
    (Object as any).assign(this, init);
  }
}

export class WeightControlPointData
  implements
    ICustomerEntityViewModel,
    IExternalSystemEntity,
    IChangeKey,
    IDescription,
    IModified,
    IEntity
{
  // @DataMember
  public weightControlPointId: string;

  // @DataMember
  public weightControlPointName: string;

  // @DataMember
  public date: string;

  // @DataMember
  public events: number;

  // @DataMember
  public actsInProgress: number;

  // @DataMember
  public actsRejected: number;

  // @DataMember
  public imposedFines: number;

  // @DataMember
  public paidFines: number;

  // @DataMember
  public customerId: string;

  // @DataMember
  public customerName: string;

  // @DataMember
  public externalSystemId?: string;

  // @DataMember
  public externalSystemName: string;

  // @DataMember
  public externalSystemEntityId?: string;

  // @DataMember
  public description: string;

  // @DataMember
  public changeKey: string;

  // @DataMember
  public created: string;

  // @DataMember
  public createdBy: string;

  // @DataMember
  public modified: string;

  // @DataMember
  public modifiedBy: string;

  // @DataMember
  public id: string;

  // @DataMember
  public entityType: string;

  public constructor(init?: Partial<WeightControlPointData>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class GetWeightControlPointDataListResponse implements IChangeKey {
  // @DataMember
  public items: WeightControlPointData[];

  // @DataMember
  public total: number;

  // @DataMember
  public page: number;

  // @DataMember
  public itemsPerPage: number;

  // @DataMember
  public changeKey: string;

  public constructor(init?: Partial<GetWeightControlPointDataListResponse>) {
    (Object as any).assign(this, init);
  }
}

export class WeightControlFinesStat {
  // @DataMember
  public totalImposedFines: number;

  // @DataMember
  public totalPaidFines: number;

  public constructor(init?: Partial<WeightControlFinesStat>) {
    (Object as any).assign(this, init);
  }
}

export class WeightControlEventsStat {
  // @DataMember
  public details: WeightControlEventsStatDetails[];

  // @DataMember
  public totalEvents: number;

  // @DataMember
  public totalActsInProgress: number;

  // @DataMember
  public totalActsRejected: number;

  public constructor(init?: Partial<WeightControlEventsStat>) {
    (Object as any).assign(this, init);
  }
}

export class WeightControlPoint
  implements
    ICustomerEntityViewModel,
    IExternalSystemEntity,
    INamedEntity,
    IChangeKey,
    IDescription,
    IModified,
    IEntity
{
  // @DataMember
  public lat: number;

  // @DataMember
  public lon: number;

  // @DataMember
  public type: WeightControlPointType;

  // @DataMember
  public customerId: string;

  // @DataMember
  public customerName: string;

  // @DataMember
  public externalSystemId?: string;

  // @DataMember
  public externalSystemName: string;

  // @DataMember
  public externalSystemEntityId?: string;

  // @DataMember
  public name: string;

  // @DataMember
  public description: string;

  // @DataMember
  public changeKey: string;

  // @DataMember
  public created: string;

  // @DataMember
  public createdBy: string;

  // @DataMember
  public modified: string;

  // @DataMember
  public modifiedBy: string;

  // @DataMember
  public id: string;

  // @DataMember
  public entityType: string;

  public constructor(init?: Partial<WeightControlPoint>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class GetWeightControlPointsResponse implements IChangeKey {
  // @DataMember
  public items: WeightControlPoint[];

  // @DataMember
  public total: number;

  // @DataMember
  public page: number;

  // @DataMember
  public itemsPerPage: number;

  // @DataMember
  public changeKey: string;

  public constructor(init?: Partial<GetWeightControlPointsResponse>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class AuthenticateResponse implements IHasSessionId, IHasBearerToken {
  // @DataMember(Order=1)
  public userId: string;

  // @DataMember(Order=2)
  public sessionId: string;

  // @DataMember(Order=3)
  public userName: string;

  // @DataMember(Order=4)
  public displayName: string;

  // @DataMember(Order=5)
  public referrerUrl: string;

  // @DataMember(Order=6)
  public bearerToken: string;

  // @DataMember(Order=7)
  public refreshToken: string;

  // @DataMember(Order=8)
  public profileUrl: string;

  // @DataMember(Order=9)
  public roles: string[];

  // @DataMember(Order=10)
  public permissions: string[];

  // @DataMember(Order=11)
  public responseStatus: ResponseStatus;

  // @DataMember(Order=12)
  public meta: { [index: string]: string };

  public constructor(init?: Partial<AuthenticateResponse>) {
    (Object as any).assign(this, init);
  }
}

// @Route("/healthStatus", "GET")
// @DataContract
export class GetHealthStatusRequest implements IReturn<string> {
  public constructor(init?: Partial<GetHealthStatusRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return '';
  }
  public getTypeName() {
    return 'GetHealthStatusRequest';
  }
}

// @Route("/customer/{customerId}/integration/{customerIntegrationId}/entities", "GET")
// @DataContract
export class GetEntityIntegrationsRequest
  implements
    IReturn<GetEntityIntegrationResponse>,
    ICustomerIntegrationIdRequest,
    ICustomerItemRequest
{
  // @DataMember
  public customerIntegrationId: string;

  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public entityGroups: string[];

  // @DataMember
  public itemsIds: string[];

  // @DataMember
  public onlyOwnedItems: boolean;

  // @DataMember
  public page: number;

  // @DataMember
  public items: number;

  // @DataMember
  public sort: string;

  // @DataMember
  public filter: string;

  // @DataMember
  public propertiesTest: { [index: string]: number };

  public constructor(init?: Partial<GetEntityIntegrationsRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new GetEntityIntegrationResponse();
  }
  public getTypeName() {
    return 'GetEntityIntegrationsRequest';
  }
}

// @Route("/customer/{customerId}/integration/{customerIntegrationId}/entity/{id}", "GET")
// @DataContract
export class GetEntityIntegrationRequest
  implements
    IReturn<EntityIntegration>,
    ICustomerIntegrationIdRequest,
    ICustomerItemRequest,
    IBaseEntityItemRequest
{
  // @DataMember
  public customerIntegrationId: string;

  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember(IsRequired=true)
  public id: string;

  public constructor(init?: Partial<GetEntityIntegrationRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new EntityIntegration();
  }
  public getTypeName() {
    return 'GetEntityIntegrationRequest';
  }
}

// @Route("/customer/{customerId}/integration/{customerIntegrationId}/entities", "POST")
// @DataContract
export class CreateEntityIntegrationRequest
  implements
    IReturn<EntityIntegration>,
    ICustomerIntegrationIdRequest,
    ICustomerItemRequest,
    IDescription
{
  // @DataMember
  public customerIntegrationId: string;

  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public updateEntityGroups: boolean;

  // @DataMember
  public entityGroups: string[];

  // @DataMember(IsRequired=true)
  public entity: EntityIntegration;

  // @DataMember
  public description: string;

  public constructor(init?: Partial<CreateEntityIntegrationRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new EntityIntegration();
  }
  public getTypeName() {
    return 'CreateEntityIntegrationRequest';
  }
}

// @Route("/customer/{customerId}/integration/{customerIntegrationId}/entity/{id}", "PUT")
// @DataContract
export class UpdateEntityIntegrationRequest
  implements
    IReturn<EntityIntegration>,
    ICustomerIntegrationIdRequest,
    ICustomerItemRequest,
    IBaseEntityItemRequest,
    IDescription
{
  // @DataMember
  public customerIntegrationId: string;

  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public updateEntityGroups: boolean;

  // @DataMember
  public entityGroups: string[];

  // @DataMember(IsRequired=true)
  public id: string;

  // @DataMember(IsRequired=true)
  public entity: EntityIntegration;

  // @DataMember
  public description: string;

  public constructor(init?: Partial<UpdateEntityIntegrationRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new EntityIntegration();
  }
  public getTypeName() {
    return 'UpdateEntityIntegrationRequest';
  }
}

// @Route("/entity/{id}/properties", "GET")
// @DataContract
export class GetEntityPropertiesRequest implements IReturn<any>, IBaseEntityItemRequest {
  // @DataMember(IsRequired=true)
  public id: string;

  public constructor(init?: Partial<GetEntityPropertiesRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return {};
  }
  public getTypeName() {
    return 'GetEntityPropertiesRequest';
  }
}

// @Route("/entity/{id}/properties", "PUT")
// @DataContract
export class UpdateEntityPropertiesRequest implements IReturn<any> {
  // @DataMember(IsRequired=true)
  public id: string;

  // @DataMember
  public description: string;

  // @DataMember
  public entity: { [index: string]: string };

  public constructor(init?: Partial<UpdateEntityPropertiesRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return {};
  }
  public getTypeName() {
    return 'UpdateEntityPropertiesRequest';
  }
}

// @Route("/entity/{id}/property/{property}", "GET")
// @DataContract
export class GetEntityPropertyRequest implements IReturn<string> {
  // @DataMember
  public property: string;

  // @DataMember(IsRequired=true)
  public id: string;

  public constructor(init?: Partial<GetEntityPropertyRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return '';
  }
  public getTypeName() {
    return 'GetEntityPropertyRequest';
  }
}

// @Route("/entity/{id}/property/{property}", "PUT")
// @DataContract
export class UpdateEntityPropertyRequest implements IReturn<string> {
  // @DataMember(IsRequired=true)
  public id: string;

  // @DataMember
  public description: string;

  // @DataMember
  public property: string;

  // @DataMember
  public value: string;

  public constructor(init?: Partial<UpdateEntityPropertyRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return '';
  }
  public getTypeName() {
    return 'UpdateEntityPropertyRequest';
  }
}

// @Route("/entities/property/{property}", "GET")
// @DataContract
export class GetEntitiesPropertyRequest implements IReturn<any> {
  // @DataMember
  public property: string;

  // @DataMember
  public filter: string;

  public constructor(init?: Partial<GetEntitiesPropertyRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return {};
  }
  public getTypeName() {
    return 'GetEntitiesPropertyRequest';
  }
}

// @Route("/customer/{customerId}/entity/{Id}/groups", "GET")
// @DataContract
export class GetItemEntityGroupsRequest
  implements IReturn<GetEntityGroupsResponse>, ICustomerItemRequest
{
  // @DataMember
  public id: string;

  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public entityGroups: string[];

  // @DataMember
  public itemsIds: string[];

  // @DataMember
  public onlyOwnedItems: boolean;

  // @DataMember
  public page: number;

  // @DataMember
  public items: number;

  // @DataMember
  public sort: string;

  // @DataMember
  public filter: string;

  // @DataMember
  public propertiesTest: { [index: string]: number };

  public constructor(init?: Partial<GetItemEntityGroupsRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new GetEntityGroupsResponse();
  }
  public getTypeName() {
    return 'GetItemEntityGroupsRequest';
  }
}

// @Route("/entities/remoteRepository/{repositoryId}/data", "GET")
// @DataContract
export class GetEntitiesRequest implements IReturn<GetEntitiesResponse> {
  // @DataMember
  public repositoryId: string;

  // @DataMember
  public modified?: string;

  // @DataMember
  public page: number;

  // @DataMember
  public items: number;

  // @DataMember
  public sort: string;

  // @DataMember
  public filter: string;

  // @DataMember
  public propertiesTest: { [index: string]: number };

  public constructor(init?: Partial<GetEntitiesRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new GetEntitiesResponse();
  }
  public getTypeName() {
    return 'GetEntitiesRequest';
  }
}

// @DataContract
export class SetFixationPointReplacementRequest
  implements
    IReturn<FixationPointReplacement>,
    ICustomerItemRequest,
    IBaseEntityItemRequest,
    IDescription
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public updateEntityGroups: boolean;

  // @DataMember
  public entityGroups: string[];

  // @DataMember(IsRequired=true)
  public id: string;

  // @DataMember(IsRequired=true)
  public entity: FixationPointReplacement;

  // @DataMember
  public description: string;

  public constructor(init?: Partial<SetFixationPointReplacementRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new FixationPointReplacement();
  }
  public getTypeName() {
    return 'SetFixationPointReplacementRequest';
  }
}

// @Route("/customer/{customerId}/fixationPointsReplacements", "GET")
// @DataContract
export class GetFixationPointReplacementsRequest
  implements IReturn<FixationPointReplacementsResponse>, ICustomerItemRequest
{
  // @DataMember(IsRequired=true)
  public fixationPointId: string;

  // @DataMember
  public from?: string;

  // @DataMember
  public to?: string;

  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public entityGroups: string[];

  // @DataMember
  public itemsIds: string[];

  // @DataMember
  public onlyOwnedItems: boolean;

  // @DataMember
  public page: number;

  // @DataMember
  public items: number;

  // @DataMember
  public sort: string;

  // @DataMember
  public filter: string;

  // @DataMember
  public propertiesTest: { [index: string]: number };

  public constructor(init?: Partial<GetFixationPointReplacementsRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new FixationPointReplacementsResponse();
  }
  public getTypeName() {
    return 'GetFixationPointReplacementsRequest';
  }
}

// @Route("/customer/{customerId}/fixationPointsReplacements/{Id}", "GET")
// @DataContract
export class GetFixationPointReplacementRequest
  implements IReturn<FixationPointReplacement>, ICustomerItemRequest, IBaseEntityItemRequest
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember(IsRequired=true)
  public id: string;

  public constructor(init?: Partial<GetFixationPointReplacementRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new FixationPointReplacement();
  }
  public getTypeName() {
    return 'GetFixationPointReplacementRequest';
  }
}

// @Route("/customer/{customerId}/fixationEvents", "GET")
// @DataContract
export class GetFixationEventsRequest
  implements IReturn<GetFixationEventsResponse>, ICustomerItemRequest
{
  // @DataMember
  public fixationPointId?: string;

  // @DataMember
  public violationType?: ViolationType;

  // @DataMember
  public startDate?: string;

  // @DataMember
  public endDate?: string;

  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public entityGroups: string[];

  // @DataMember
  public itemsIds: string[];

  // @DataMember
  public onlyOwnedItems: boolean;

  // @DataMember
  public page: number;

  // @DataMember
  public items: number;

  // @DataMember
  public sort: string;

  // @DataMember
  public filter: string;

  // @DataMember
  public propertiesTest: { [index: string]: number };

  public constructor(init?: Partial<GetFixationEventsRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new GetFixationEventsResponse();
  }
  public getTypeName() {
    return 'GetFixationEventsRequest';
  }
}

// @Route("/customer/{customerId}/fixationEvents/count", "GET")
// @DataContract
export class GetFixationEventsCountRequest implements IReturn<number> {
  // @DataMember
  public customerId: string;

  // @DataMember
  public fixationPointId?: string;

  // @DataMember
  public violationType?: ViolationType;

  // @DataMember
  public startDate?: string;

  // @DataMember
  public endDate?: string;

  public constructor(init?: Partial<GetFixationEventsCountRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return 0;
  }
  public getTypeName() {
    return 'GetFixationEventsCountRequest';
  }
}

// @Route("/customer/{customerId}/fixationEvent/{id}", "GET")
// @DataContract
export class GetFixationEventRequest
  implements IReturn<FixationEvent>, ICustomerItemRequest, IBaseEntityItemRequest
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember(IsRequired=true)
  public id: string;

  public constructor(init?: Partial<GetFixationEventRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new FixationEvent();
  }
  public getTypeName() {
    return 'GetFixationEventRequest';
  }
}

// @Route("/fixationEvents", "POST")
// @DataContract
export class CreateFixationEventRequest implements IReturn<FixationEvent> {
  // @DataMember
  public fixationDevicePrefix: string;

  // @DataMember
  public fixationDeviceId: string;

  // @DataMember
  public image: string;

  // @DataMember
  public licensePlateImage: string;

  // @DataMember
  public licensePlate: string;

  // @DataMember
  public eventDate: string;

  // @DataMember
  public speed: number;

  // @DataMember
  public laneId: number;

  // @DataMember
  public vehicleClass: VehicleClass;

  // @DataMember
  public violationType: ViolationType;

  public constructor(init?: Partial<CreateFixationEventRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new FixationEvent();
  }
  public getTypeName() {
    return 'CreateFixationEventRequest';
  }
}

// @Route("/customer/{customerId}/fixationEvent/{id}/images", "GET")
// @DataContract
export class GetFixationEventImagesRequest implements IReturn<string[]> {
  // @DataMember(IsRequired=true)
  public id: string;

  // @DataMember(IsRequired=true)
  public customerId: string;

  public constructor(init?: Partial<GetFixationEventImagesRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new Array<string>();
  }
  public getTypeName() {
    return 'GetFixationEventImagesRequest';
  }
}

// @Route("/customer/{customerId}/fixationDevices", "GET")
// @DataContract
export class GetFixationDevicesRequest
  implements IReturn<GetFixationDevicesResponse>, ICustomerItemRequest
{
  // @DataMember
  public onlyUnused: boolean;

  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public entityGroups: string[];

  // @DataMember
  public itemsIds: string[];

  // @DataMember
  public onlyOwnedItems: boolean;

  // @DataMember
  public page: number;

  // @DataMember
  public items: number;

  // @DataMember
  public sort: string;

  // @DataMember
  public filter: string;

  // @DataMember
  public propertiesTest: { [index: string]: number };

  public constructor(init?: Partial<GetFixationDevicesRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new GetFixationDevicesResponse();
  }
  public getTypeName() {
    return 'GetFixationDevicesRequest';
  }
}

// @Route("/customer/{customerId}/fixationDevice/{id}", "GET")
// @DataContract
export class GetFixationDeviceRequest
  implements IReturn<FixationDevice>, ICustomerItemRequest, IBaseEntityItemRequest
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember(IsRequired=true)
  public id: string;

  public constructor(init?: Partial<GetFixationDeviceRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new FixationDevice();
  }
  public getTypeName() {
    return 'GetFixationDeviceRequest';
  }
}

// @Route("/customer/{customerId}/fixationDevices/exists", "GET")
// @DataContract
export class GetFixationDeviceExistRequest implements IReturn<boolean>, ICustomerItemRequest {
  // @DataMember(IsRequired=true)
  public number: string;

  // @DataMember(IsRequired=true)
  public prefix: string;

  // @DataMember
  public customerId: string;

  public constructor(init?: Partial<GetFixationDeviceExistRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return false;
  }
  public getTypeName() {
    return 'GetFixationDeviceExistRequest';
  }
}

// @Route("/customer/{customerId}/fixationDevices", "POST")
// @DataContract
export class CreateFixationDeviceRequest
  implements IReturn<FixationDevice>, ICustomerItemRequest, IDescription
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public updateEntityGroups: boolean;

  // @DataMember
  public entityGroups: string[];

  // @DataMember(IsRequired=true)
  public entity: FixationDevice;

  // @DataMember
  public description: string;

  public constructor(init?: Partial<CreateFixationDeviceRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new FixationDevice();
  }
  public getTypeName() {
    return 'CreateFixationDeviceRequest';
  }
}

// @Route("/customer/{customerId}/fixationDevice/{id}", "PUT")
// @DataContract
export class UpdateFixationDeviceRequest
  implements IReturn<FixationDevice>, ICustomerItemRequest, IBaseEntityItemRequest, IDescription
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public updateEntityGroups: boolean;

  // @DataMember
  public entityGroups: string[];

  // @DataMember(IsRequired=true)
  public id: string;

  // @DataMember(IsRequired=true)
  public entity: FixationDevice;

  // @DataMember
  public description: string;

  public constructor(init?: Partial<UpdateFixationDeviceRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new FixationDevice();
  }
  public getTypeName() {
    return 'UpdateFixationDeviceRequest';
  }
}

// @Route("/customer/{customerId}/fixationDevices", "DELETE")
// @DataContract
export class DeleteFixationDevicesRequest
  implements IReturn<number>, ICustomerItemRequest, IDescription
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public description: string;

  // @DataMember(IsRequired=true)
  public ids: string[];

  public constructor(init?: Partial<DeleteFixationDevicesRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return 0;
  }
  public getTypeName() {
    return 'DeleteFixationDevicesRequest';
  }
}

// @Route("/customer/{customerId}/fixationDevice/{id}/changeCustomer", "POST")
// @DataContract
export class FixationDeviceChangeCustomerRequest
  implements IReturn<boolean>, IDescription, ICustomerItemRequest, IBaseEntityItemRequest
{
  // @DataMember
  public description: string;

  // @DataMember
  public newCustomerId: string;

  // @DataMember
  public customerId: string;

  // @DataMember
  public id: string;

  public constructor(init?: Partial<FixationDeviceChangeCustomerRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return false;
  }
  public getTypeName() {
    return 'FixationDeviceChangeCustomerRequest';
  }
}

// @Route("/customer/{customerId}/fixationPoints/groups", "GET")
// @DataContract
export class GetFixationPointGroupsRequest
  implements IReturn<GetEntityGroupsResponse>, ICustomerItemRequest
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public entityGroups: string[];

  // @DataMember
  public itemsIds: string[];

  // @DataMember
  public onlyOwnedItems: boolean;

  // @DataMember
  public page: number;

  // @DataMember
  public items: number;

  // @DataMember
  public sort: string;

  // @DataMember
  public filter: string;

  // @DataMember
  public propertiesTest: { [index: string]: number };

  public constructor(init?: Partial<GetFixationPointGroupsRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new GetEntityGroupsResponse();
  }
  public getTypeName() {
    return 'GetFixationPointGroupsRequest';
  }
}

// @Route("/customer/{customerId}/fixationPoints/groups/{id}", "GET")
// @DataContract
export class GetFixationPointGroupRequest
  implements IReturn<EntityGroup>, ICustomerItemRequest, IBaseEntityItemRequest
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember(IsRequired=true)
  public id: string;

  public constructor(init?: Partial<GetFixationPointGroupRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new EntityGroup();
  }
  public getTypeName() {
    return 'GetFixationPointGroupRequest';
  }
}

// @Route("/customer/{customerId}/fixationPoints/groups", "POST")
// @DataContract
export class CreateFixationPointGroupRequest
  implements IReturn<EntityGroup>, ICustomerItemRequest, IDescription
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public updateEntityGroups: boolean;

  // @DataMember
  public entityGroups: string[];

  // @DataMember(IsRequired=true)
  public entity: EntityGroup;

  // @DataMember
  public description: string;

  public constructor(init?: Partial<CreateFixationPointGroupRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new EntityGroup();
  }
  public getTypeName() {
    return 'CreateFixationPointGroupRequest';
  }
}

// @Route("/customer/{customerId}/fixationPoints/groups/{id}", "PUT")
// @DataContract
export class UpdateFixationPointGroupRequest
  implements IReturn<EntityGroup>, ICustomerItemRequest, IBaseEntityItemRequest, IDescription
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public updateEntityGroups: boolean;

  // @DataMember
  public entityGroups: string[];

  // @DataMember(IsRequired=true)
  public id: string;

  // @DataMember(IsRequired=true)
  public entity: EntityGroup;

  // @DataMember
  public description: string;

  public constructor(init?: Partial<UpdateFixationPointGroupRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new EntityGroup();
  }
  public getTypeName() {
    return 'UpdateFixationPointGroupRequest';
  }
}

// @Route("/customer/{customerId}/fixationPoints/groups", "DELETE")
// @DataContract
export class DeleteFixationPointGroupsRequest
  implements IReturn<number>, ICustomerItemRequest, IDescription
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public description: string;

  // @DataMember(IsRequired=true)
  public ids: string[];

  public constructor(init?: Partial<DeleteFixationPointGroupsRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return 0;
  }
  public getTypeName() {
    return 'DeleteFixationPointGroupsRequest';
  }
}

// @Route("/customer/{customerId}/fixationPoints/groups/{id}", "GET")
// @DataContract
export class GetFixationPointGroupSharesRequest
  implements IReturn<GetEntityGroupSharesResponse>, IBaseEntityItemRequest, ICustomerItemRequest
{
  // @DataMember
  public id: string;

  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public entityGroups: string[];

  // @DataMember
  public itemsIds: string[];

  // @DataMember
  public onlyOwnedItems: boolean;

  // @DataMember
  public page: number;

  // @DataMember
  public items: number;

  // @DataMember
  public sort: string;

  // @DataMember
  public filter: string;

  // @DataMember
  public propertiesTest: { [index: string]: number };

  public constructor(init?: Partial<GetFixationPointGroupSharesRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new GetEntityGroupSharesResponse();
  }
  public getTypeName() {
    return 'GetFixationPointGroupSharesRequest';
  }
}

// @Route("/customer/{customerId}/fixationPoints/groups/{id}", "PUT")
// @DataContract
export class UpdateFixationPointGroupShareRequest
  implements IReturn<EntityGroupPermissions>, ICustomerItemRequest, IDescription
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember(IsRequired=true)
  public id: string;

  // @DataMember(IsRequired=true)
  public entity: EntityGroupPermissions;

  // @DataMember
  public description: string;

  public constructor(init?: Partial<UpdateFixationPointGroupShareRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new EntityGroupPermissions();
  }
  public getTypeName() {
    return 'UpdateFixationPointGroupShareRequest';
  }
}

// @Route("/customer/{customerId}/fixationPoints", "GET")
// @DataContract
export class GetFixationPointsRequest
  implements IReturn<GetFixationPointsResponse>, ICustomerItemRequest
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public entityGroups: string[];

  // @DataMember
  public itemsIds: string[];

  // @DataMember
  public onlyOwnedItems: boolean;

  // @DataMember
  public page: number;

  // @DataMember
  public items: number;

  // @DataMember
  public sort: string;

  // @DataMember
  public filter: string;

  // @DataMember
  public propertiesTest: { [index: string]: number };

  public constructor(init?: Partial<GetFixationPointsRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new GetFixationPointsResponse();
  }
  public getTypeName() {
    return 'GetFixationPointsRequest';
  }
}

// @Route("/customer/{customerId}/fixationPoint/{id}", "GET")
// @DataContract
export class GetFixationPointRequest
  implements IReturn<FixationPoint>, ICustomerItemRequest, IBaseEntityItemRequest
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember(IsRequired=true)
  public id: string;

  public constructor(init?: Partial<GetFixationPointRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new FixationPoint();
  }
  public getTypeName() {
    return 'GetFixationPointRequest';
  }
}

// @Route("/customer/{customerId}/fixationPoints/exists", "GET")
// @DataContract
export class GetFixationPointExistRequest implements IReturn<boolean>, ICustomerItemRequest {
  // @DataMember(IsRequired=true)
  public name: string;

  // @DataMember
  public customerId: string;

  public constructor(init?: Partial<GetFixationPointExistRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return false;
  }
  public getTypeName() {
    return 'GetFixationPointExistRequest';
  }
}

// @Route("/customer/{customerId}/fixationPoints", "POST")
// @DataContract
export class CreateFixationPointRequest
  implements IReturn<FixationPoint>, ICustomerItemRequest, IDescription
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public updateEntityGroups: boolean;

  // @DataMember
  public entityGroups: string[];

  // @DataMember(IsRequired=true)
  public entity: FixationPoint;

  // @DataMember
  public description: string;

  public constructor(init?: Partial<CreateFixationPointRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new FixationPoint();
  }
  public getTypeName() {
    return 'CreateFixationPointRequest';
  }
}

// @Route("/customer/{customerId}/fixationPoint/{id}", "PUT")
// @DataContract
export class UpdateFixationPointRequest
  implements IReturn<FixationPoint>, ICustomerItemRequest, IBaseEntityItemRequest, IDescription
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public updateEntityGroups: boolean;

  // @DataMember
  public entityGroups: string[];

  // @DataMember(IsRequired=true)
  public id: string;

  // @DataMember(IsRequired=true)
  public entity: FixationPoint;

  // @DataMember
  public description: string;

  public constructor(init?: Partial<UpdateFixationPointRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new FixationPoint();
  }
  public getTypeName() {
    return 'UpdateFixationPointRequest';
  }
}

// @Route("/customer/{customerId}/fixationPoints", "DELETE")
// @DataContract
export class DeleteFixationPointsRequest
  implements IReturn<number>, ICustomerItemRequest, IDescription
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public description: string;

  // @DataMember(IsRequired=true)
  public ids: string[];

  public constructor(init?: Partial<DeleteFixationPointsRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return 0;
  }
  public getTypeName() {
    return 'DeleteFixationPointsRequest';
  }
}

// @Route("/customer/{customerId}/fixationPoint/{id}/changeCustomer", "POST")
// @DataContract
export class FixationPointChangeCustomerRequest
  implements IReturn<boolean>, IDescription, ICustomerItemRequest, IBaseEntityItemRequest
{
  // @DataMember
  public description: string;

  // @DataMember
  public newCustomerId: string;

  // @DataMember
  public customerId: string;

  // @DataMember
  public id: string;

  public constructor(init?: Partial<FixationPointChangeCustomerRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return false;
  }
  public getTypeName() {
    return 'FixationPointChangeCustomerRequest';
  }
}

// @Route("/customer/{customerId}/fixationPoints/statuses", "GET")
// @DataContract
export class GetFixationPointStatusesRequest
  implements IReturn<GetFixationPointStatusesResponse>, ICustomerItemRequest
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public entityGroups: string[];

  // @DataMember
  public itemsIds: string[];

  // @DataMember
  public onlyOwnedItems: boolean;

  // @DataMember
  public page: number;

  // @DataMember
  public items: number;

  // @DataMember
  public sort: string;

  // @DataMember
  public filter: string;

  // @DataMember
  public propertiesTest: { [index: string]: number };

  public constructor(init?: Partial<GetFixationPointStatusesRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new GetFixationPointStatusesResponse();
  }
  public getTypeName() {
    return 'GetFixationPointStatusesRequest';
  }
}

// @Route("/customer/{customerId}/fixationPoint/{id}/info", "GET")
// @DataContract
export class GetFixationPointInfoRequest
  implements IReturn<FixationPointInfo>, ICustomerItemRequest, IBaseEntityItemRequest
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember(IsRequired=true)
  public id: string;

  public constructor(init?: Partial<GetFixationPointInfoRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new FixationPointInfo();
  }
  public getTypeName() {
    return 'GetFixationPointInfoRequest';
  }
}

// @Route("/customer/{customerId}/weightControlPoints/groups", "GET")
// @DataContract
export class GetWeightControlPointGroupsRequest
  implements IReturn<GetEntityGroupsResponse>, ICustomerItemRequest
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public entityGroups: string[];

  // @DataMember
  public itemsIds: string[];

  // @DataMember
  public onlyOwnedItems: boolean;

  // @DataMember
  public page: number;

  // @DataMember
  public items: number;

  // @DataMember
  public sort: string;

  // @DataMember
  public filter: string;

  // @DataMember
  public propertiesTest: { [index: string]: number };

  public constructor(init?: Partial<GetWeightControlPointGroupsRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new GetEntityGroupsResponse();
  }
  public getTypeName() {
    return 'GetWeightControlPointGroupsRequest';
  }
}

// @Route("/customer/{customerId}/weightControlPoints/groups/{id}", "GET")
// @DataContract
export class GetWeightControlPointGroupRequest
  implements IReturn<EntityGroup>, ICustomerItemRequest, IBaseEntityItemRequest
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember(IsRequired=true)
  public id: string;

  public constructor(init?: Partial<GetWeightControlPointGroupRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new EntityGroup();
  }
  public getTypeName() {
    return 'GetWeightControlPointGroupRequest';
  }
}

// @Route("/customer/{customerId}/weightControlPoints/groups", "POST")
// @DataContract
export class CreateWeightControlPointGroupRequest
  implements IReturn<EntityGroup>, ICustomerItemRequest, IDescription
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public updateEntityGroups: boolean;

  // @DataMember
  public entityGroups: string[];

  // @DataMember(IsRequired=true)
  public entity: EntityGroup;

  // @DataMember
  public description: string;

  public constructor(init?: Partial<CreateWeightControlPointGroupRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new EntityGroup();
  }
  public getTypeName() {
    return 'CreateWeightControlPointGroupRequest';
  }
}

// @Route("/customer/{customerId}/weightControlPoints/groups/{id}", "PUT")
// @DataContract
export class UpdateWeightControlPointGroupRequest
  implements IReturn<EntityGroup>, ICustomerItemRequest, IBaseEntityItemRequest, IDescription
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public updateEntityGroups: boolean;

  // @DataMember
  public entityGroups: string[];

  // @DataMember(IsRequired=true)
  public id: string;

  // @DataMember(IsRequired=true)
  public entity: EntityGroup;

  // @DataMember
  public description: string;

  public constructor(init?: Partial<UpdateWeightControlPointGroupRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new EntityGroup();
  }
  public getTypeName() {
    return 'UpdateWeightControlPointGroupRequest';
  }
}

// @Route("/customer/{customerId}/weightControlPoints/groups", "DELETE")
// @DataContract
export class DeleteWeightControlPointGroupsRequest
  implements IReturn<number>, ICustomerItemRequest, IDescription
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public description: string;

  // @DataMember(IsRequired=true)
  public ids: string[];

  public constructor(init?: Partial<DeleteWeightControlPointGroupsRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return 0;
  }
  public getTypeName() {
    return 'DeleteWeightControlPointGroupsRequest';
  }
}

// @Route("/customer/{customerId}/weightControlPoints/groups/{id}", "GET")
// @DataContract
export class GetWeightControlPointGroupSharesRequest
  implements IReturn<GetEntityGroupSharesResponse>, IBaseEntityItemRequest, ICustomerItemRequest
{
  // @DataMember
  public id: string;

  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public entityGroups: string[];

  // @DataMember
  public itemsIds: string[];

  // @DataMember
  public onlyOwnedItems: boolean;

  // @DataMember
  public page: number;

  // @DataMember
  public items: number;

  // @DataMember
  public sort: string;

  // @DataMember
  public filter: string;

  // @DataMember
  public propertiesTest: { [index: string]: number };

  public constructor(init?: Partial<GetWeightControlPointGroupSharesRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new GetEntityGroupSharesResponse();
  }
  public getTypeName() {
    return 'GetWeightControlPointGroupSharesRequest';
  }
}

// @Route("/customer/{customerId}/weightControlPoints/groups/{id}", "PUT")
// @DataContract
export class UpdateWeightControlPointGroupShareRequest
  implements IReturn<EntityGroupPermissions>, ICustomerItemRequest, IDescription
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember(IsRequired=true)
  public id: string;

  // @DataMember(IsRequired=true)
  public entity: EntityGroupPermissions;

  // @DataMember
  public description: string;

  public constructor(init?: Partial<UpdateWeightControlPointGroupShareRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new EntityGroupPermissions();
  }
  public getTypeName() {
    return 'UpdateWeightControlPointGroupShareRequest';
  }
}

// @Route("/customer/{customerId}/weightControlPointDatas/export", "GET")
// @DataContract
export class GetExcelWeightControlPointDataListRequest implements IReturn<Uint8Array> {
  // @DataMember
  public customerId: string;

  // @DataMember
  public weightControlPointId?: string;

  // @DataMember
  public startDate?: string;

  // @DataMember
  public endDate?: string;

  public constructor(init?: Partial<GetExcelWeightControlPointDataListRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new Uint8Array(0);
  }
  public getTypeName() {
    return 'GetExcelWeightControlPointDataListRequest';
  }
}

// @Route("/customer/{customerId}/weightControlPointDatas", "GET")
// @DataContract
export class GetWeightControlPointDataListRequest
  implements IReturn<GetWeightControlPointDataListResponse>, ICustomerItemRequest
{
  // @DataMember
  public weightControlPointId?: string;

  // @DataMember
  public startDate?: string;

  // @DataMember
  public endDate?: string;

  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public entityGroups: string[];

  // @DataMember
  public itemsIds: string[];

  // @DataMember
  public onlyOwnedItems: boolean;

  // @DataMember
  public page: number;

  // @DataMember
  public items: number;

  // @DataMember
  public sort: string;

  // @DataMember
  public filter: string;

  // @DataMember
  public propertiesTest: { [index: string]: number };

  public constructor(init?: Partial<GetWeightControlPointDataListRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new GetWeightControlPointDataListResponse();
  }
  public getTypeName() {
    return 'GetWeightControlPointDataListRequest';
  }
}

// @Route("/customer/{customerId}/weightControlPointDatas/statFines", "GET")
// @DataContract
export class GetWeightControlFinesStatRequest implements IReturn<WeightControlFinesStat> {
  // @DataMember
  public customerId: string;

  // @DataMember
  public weightControlPointId?: string;

  // @DataMember
  public startDate?: string;

  // @DataMember
  public endDate?: string;

  public constructor(init?: Partial<GetWeightControlFinesStatRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new WeightControlFinesStat();
  }
  public getTypeName() {
    return 'GetWeightControlFinesStatRequest';
  }
}

// @Route("/customer/{customerId}/weightControlPointDatas/statEvents", "GET")
// @DataContract
export class GetWeightControlEventsStatRequest implements IReturn<WeightControlEventsStat> {
  // @DataMember
  public customerId: string;

  // @DataMember
  public weightControlPointId?: string;

  // @DataMember
  public startDate?: string;

  // @DataMember
  public endDate?: string;

  public constructor(init?: Partial<GetWeightControlEventsStatRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new WeightControlEventsStat();
  }
  public getTypeName() {
    return 'GetWeightControlEventsStatRequest';
  }
}

// @Route("/customer/{customerId}/weightControlPointData/{id}", "GET")
// @DataContract
export class GetWeightControlPointDataRequest
  implements IReturn<WeightControlPointData>, ICustomerItemRequest, IBaseEntityItemRequest
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember(IsRequired=true)
  public id: string;

  public constructor(init?: Partial<GetWeightControlPointDataRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new WeightControlPointData();
  }
  public getTypeName() {
    return 'GetWeightControlPointDataRequest';
  }
}

// @Route("/customer/{customerId}/weightControlPointDatas", "POST")
// @DataContract
export class CreateWeightControlPointDataRequest
  implements IReturn<WeightControlPointData>, ICustomerItemRequest, IDescription
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public updateEntityGroups: boolean;

  // @DataMember
  public entityGroups: string[];

  // @DataMember(IsRequired=true)
  public entity: WeightControlPointData;

  // @DataMember
  public description: string;

  public constructor(init?: Partial<CreateWeightControlPointDataRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new WeightControlPointData();
  }
  public getTypeName() {
    return 'CreateWeightControlPointDataRequest';
  }
}

// @Route("/customer/{customerId}/weightControlPointData/{id}", "PUT")
// @DataContract
export class UpdateWeightControlPointDataRequest
  implements
    IReturn<WeightControlPointData>,
    ICustomerItemRequest,
    IBaseEntityItemRequest,
    IDescription
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public updateEntityGroups: boolean;

  // @DataMember
  public entityGroups: string[];

  // @DataMember(IsRequired=true)
  public id: string;

  // @DataMember(IsRequired=true)
  public entity: WeightControlPointData;

  // @DataMember
  public description: string;

  public constructor(init?: Partial<UpdateWeightControlPointDataRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new WeightControlPointData();
  }
  public getTypeName() {
    return 'UpdateWeightControlPointDataRequest';
  }
}

// @Route("/customer/{customerId}/weightControlPointDatas", "DELETE")
// @DataContract
export class DeleteWeightControlPointDataRequest
  implements IReturn<number>, ICustomerItemRequest, IDescription
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public description: string;

  // @DataMember(IsRequired=true)
  public ids: string[];

  public constructor(init?: Partial<DeleteWeightControlPointDataRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return 0;
  }
  public getTypeName() {
    return 'DeleteWeightControlPointDataRequest';
  }
}

// @Route("/customer/{customerId}/weightControlPoints", "GET")
// @DataContract
export class GetWeightControlPointsRequest
  implements IReturn<GetWeightControlPointsResponse>, ICustomerItemRequest
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public entityGroups: string[];

  // @DataMember
  public itemsIds: string[];

  // @DataMember
  public onlyOwnedItems: boolean;

  // @DataMember
  public page: number;

  // @DataMember
  public items: number;

  // @DataMember
  public sort: string;

  // @DataMember
  public filter: string;

  // @DataMember
  public propertiesTest: { [index: string]: number };

  public constructor(init?: Partial<GetWeightControlPointsRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new GetWeightControlPointsResponse();
  }
  public getTypeName() {
    return 'GetWeightControlPointsRequest';
  }
}

// @Route("/customer/{customerId}/weightControlPoint/{id}", "GET")
// @DataContract
export class GetWeightControlPointRequest
  implements IReturn<WeightControlPoint>, ICustomerItemRequest, IBaseEntityItemRequest
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember(IsRequired=true)
  public id: string;

  public constructor(init?: Partial<GetWeightControlPointRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new WeightControlPoint();
  }
  public getTypeName() {
    return 'GetWeightControlPointRequest';
  }
}

// @Route("/customer/{customerId}/weightControlPoints/exists", "GET")
// @DataContract
export class GetWeightControlPointExistRequest implements IReturn<boolean>, ICustomerItemRequest {
  // @DataMember(IsRequired=true)
  public name: string;

  // @DataMember
  public customerId: string;

  public constructor(init?: Partial<GetWeightControlPointExistRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return false;
  }
  public getTypeName() {
    return 'GetWeightControlPointExistRequest';
  }
}

// @Route("/customer/{customerId}/weightControlPoints", "POST")
// @DataContract
export class CreateWeightControlPointRequest
  implements IReturn<WeightControlPoint>, ICustomerItemRequest, IDescription
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public updateEntityGroups: boolean;

  // @DataMember
  public entityGroups: string[];

  // @DataMember(IsRequired=true)
  public entity: WeightControlPoint;

  // @DataMember
  public description: string;

  public constructor(init?: Partial<CreateWeightControlPointRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new WeightControlPoint();
  }
  public getTypeName() {
    return 'CreateWeightControlPointRequest';
  }
}

// @Route("/customer/{customerId}/weightControlPoint/{id}", "PUT")
// @DataContract
export class UpdateWeightControlPointRequest
  implements
    IReturn<WeightControlPoint>,
    ICustomerItemRequest,
    IBaseEntityItemRequest,
    IDescription
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public updateEntityGroups: boolean;

  // @DataMember
  public entityGroups: string[];

  // @DataMember(IsRequired=true)
  public id: string;

  // @DataMember(IsRequired=true)
  public entity: WeightControlPoint;

  // @DataMember
  public description: string;

  public constructor(init?: Partial<UpdateWeightControlPointRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new WeightControlPoint();
  }
  public getTypeName() {
    return 'UpdateWeightControlPointRequest';
  }
}

// @Route("/customer/{customerId}/weightControlPoints", "DELETE")
// @DataContract
export class DeleteWeightControlPointsRequest
  implements IReturn<number>, ICustomerItemRequest, IDescription
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public description: string;

  // @DataMember(IsRequired=true)
  public ids: string[];

  public constructor(init?: Partial<DeleteWeightControlPointsRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return 0;
  }
  public getTypeName() {
    return 'DeleteWeightControlPointsRequest';
  }
}

// @Route("/customer/{customerId}/weightControlPoint/{id}/changeCustomer", "POST")
// @DataContract
export class WeightControlPointChangeCustomerRequest
  implements IReturn<boolean>, IDescription, ICustomerItemRequest, IBaseEntityItemRequest
{
  // @DataMember
  public description: string;

  // @DataMember
  public newCustomerId: string;

  // @DataMember
  public customerId: string;

  // @DataMember
  public id: string;

  public constructor(init?: Partial<WeightControlPointChangeCustomerRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return false;
  }
  public getTypeName() {
    return 'WeightControlPointChangeCustomerRequest';
  }
}

/**
 * Sign In
 */
// @Route("/auth", "OPTIONS,GET,POST,DELETE")
// @Route("/auth/{provider}", "OPTIONS,GET,POST,DELETE")
// @Api(Description="Sign In")
// @DataContract
export class Authenticate implements IReturn<AuthenticateResponse>, IPost {
  /**
   * AuthProvider, e.g. credentials
   */
  // @DataMember(Order=1)
  public provider: string;

  // @DataMember(Order=2)
  public state: string;

  // @DataMember(Order=3)
  public oauth_token: string;

  // @DataMember(Order=4)
  public oauth_verifier: string;

  // @DataMember(Order=5)
  public userName: string;

  // @DataMember(Order=6)
  public password: string;

  // @DataMember(Order=7)
  public rememberMe?: boolean;

  // @DataMember(Order=9)
  public errorView: string;

  // @DataMember(Order=10)
  public nonce: string;

  // @DataMember(Order=11)
  public uri: string;

  // @DataMember(Order=12)
  public response: string;

  // @DataMember(Order=13)
  public qop: string;

  // @DataMember(Order=14)
  public nc: string;

  // @DataMember(Order=15)
  public cnonce: string;

  // @DataMember(Order=17)
  public accessToken: string;

  // @DataMember(Order=18)
  public accessTokenSecret: string;

  // @DataMember(Order=19)
  public scope: string;

  // @DataMember(Order=20)
  public meta: { [index: string]: string };

  public constructor(init?: Partial<Authenticate>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new AuthenticateResponse();
  }
  public getTypeName() {
    return 'Authenticate';
  }
}
