import { CustomerStateService, ModuleSettingValue } from '../services/customer-state.service';
import { TokenService } from '../services/token.service';

export interface ISidenavMenuCategory {
  name: string;
  items: ISidenavMenuItem[];
}

export interface ISidenavMenuItem {
  name: string;
  route: string;

  permissions: string[];
  moduleSettings?: ModuleSettingValue[];

  icon?: string;
  svgIcon?: string;
  external?: boolean;
}

export function getSidenavMenuCategories(
  menuCategoriesList: ISidenavMenuCategory[][],
  tokenService: TokenService,
  customerStateService: CustomerStateService
): ISidenavMenuCategory[] {
  return menuCategoriesList.reduce((menuCategories, categories) => {
    categories.forEach((category) => {
      const categoryItems = category.items.reduce((items, item) => {
        if (!item.moduleSettings || customerStateService.hasModuleSettings(item.moduleSettings)) {
          tokenService.hasPermissions(item.permissions) &&
            items.push(Object.assign({}, item));
        }
        return items;
      }, []);

      categoryItems.length &&
        menuCategories.push(
          Object.assign({}, category, { items: categoryItems })
        );
    });
    return menuCategories;
  }, []);
}
