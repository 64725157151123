<div *ngIf="loading" class="backdrop">
  <mat-spinner diameter="36" color="accent"></mat-spinner>
</div>

<h4 mat-dialog-title>
  <span *ngIf="isNew">{{
    "EXTERNAL_SYSTEMS.CREATE_EXTERNAL_SYSTEM" | translate
  }}</span>
  <span *ngIf="!isNew">{{
    "EXTERNAL_SYSTEMS.EDIT_EXTERNAL_SYSTEM" | translate
  }}</span>
</h4>
<mat-dialog-content>
  <form [formGroup]="formGroup" (ngSubmit)="submit()" autocomplete="off">
    <div style="min-width: 320px; padding-bottom: 24px">
      <mat-form-field style="width: 100%">
        <input
          matInput
          [formControl]="name"
          placeholder="{{ 'COMMON.NAME' | translate }}"
          autocomplete="off"
          maxlength="100"
          trimValue
        />
        <mat-hint>{{ "EXTERNAL_SYSTEMS.NAME_HINT" | translate }}</mat-hint>
        <mat-error *ngIf="name.hasError('maxlength')">
          {{ "EXTERNAL_SYSTEMS.NAME_LENGTH" | translate }}
        </mat-error>
        <mat-error *ngIf="name.hasError('required')">
          {{ "EXTERNAL_SYSTEMS.NAME_REQUIRED" | translate }}
        </mat-error>
      </mat-form-field>

      <mat-form-field style="width: 100%; margin-top: 16px">
        <input
          matInput
          [formControl]="url"
          placeholder="{{ 'EXTERNAL_SYSTEMS.URL' | translate }}"
          autocomplete="off"
          maxlength="100"
          trimValue
        />
        <mat-hint>{{ "EXTERNAL_SYSTEMS.URL_HINT" | translate }}</mat-hint>
        <mat-error *ngIf="url.hasError('required')">
          {{ "EXTERNAL_SYSTEMS.URL_REQUIRED" | translate }}
        </mat-error>
        <mat-error *ngIf="url.hasError('pattern')">
          {{ "EXTERNAL_SYSTEMS.URL_PATTERN" | translate }}
        </mat-error>
      </mat-form-field>

      <mat-form-field style="width: 100%; margin-top: 16px">
        <input
          matInput
          [formControl]="userName"
          placeholder="{{ 'EXTERNAL_SYSTEMS.USER_NAME' | translate }}"
          autocomplete="off"
          maxlength="100"
          trimValue
        />
        <mat-hint>{{ "EXTERNAL_SYSTEMS.USER_NAME_HINT" | translate }}</mat-hint>
      </mat-form-field>

      <mat-form-field style="width: 100%; margin-top: 16px">
        <input
          matInput
          [formControl]="password"
          type="password"
          placeholder="{{ 'EXTERNAL_SYSTEMS.PASSWORD' | translate }}"
          autocomplete="new-password"
          maxlength="100"
          trimValue
        />
        <mat-hint>{{ "EXTERNAL_SYSTEMS.PASSWORD_HINT" | translate }}</mat-hint>
      </mat-form-field>
    </div>
  </form>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button (click)="submit()" color="primary">
    <span *ngIf="isNew">{{ "COMMON.CREATE" | translate }}</span>
    <span *ngIf="!isNew">{{ "COMMON.SAVE" | translate }}</span>
  </button>
  <button mat-button (click)="close()">
    {{ "COMMON.CANCEL" | translate }}
  </button>
</mat-dialog-actions>
