import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { Subject } from 'rxjs';

import { TranslateService } from '@ngx-translate/core';
import { SettingsService } from 'projects/msu-its-web-common/src/services/settings.service';

import { LayoutSvgUtils } from 'projects/msu-its-web-tlc/src/dtos/layout';
import { SchemaSet, SchemaUtils } from 'projects/msu-its-web-tlc/src/dtos/schema';
import { UsdkPhase, UsdkSettings } from 'projects/msu-its-web-tlc/src/dtos/tlc.dtos';
import { isUsdkPhaseMajor } from 'projects/msu-its-web-tlc/src/dtos/usdk/usdk-plan';

@Component({
  selector: 'usdk-select-phase',
  templateUrl: './usdk-select-phase.component.html',
  styleUrls: ['./usdk-select-phase.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UsdkSelectPhaseComponent implements OnInit, OnDestroy {
  _destroy = new Subject();

  @Input()
  schemaSet: SchemaSet;

  @Input()
  settings: UsdkSettings;

  @Input()
  selectedPhaseId: number;

  @Output()
  selected = new EventEmitter<UsdkPhase>();

  phases: [UsdkPhase[], UsdkPhase[]] = [[], []];

  get schema() {
    return this.schemaSet.schema;
  }
  get schemaView() {
    return this.schemaSet.schemaView;
  }

  constructor(
    private _settingsService: SettingsService,
    private _changeDetector: ChangeDetectorRef,
    public translate: TranslateService
  ) {}

  ngOnInit() {
    this._updatePhases();
    this._changeDetector.markForCheck();
  }

  ngOnDestroy() {
    this._destroy.next();
    this._destroy.complete();
  }

  select(phase: UsdkPhase) {
    this.selected.emit(phase);
  }

  setSvgState(element, phase: UsdkPhase) {
    const schema = this.schema;
    const schemaView = this.schemaView;
    const isDark = this._settingsService.darkTheme;
    const svg = element.contentDocument;

    const movements = [];
    phase.movements.forEach((m) => {
      movements.push(...SchemaUtils.schemaViewMovements(schema, m));
    });

    LayoutSvgUtils.updateLayoutSchema(svg, schema, schemaView, isDark);
    LayoutSvgUtils.showLayoutMovements(svg, schema, movements);
  }

  isSelected(phase: UsdkPhase) {
    return this.selectedPhaseId == phase?.id;
  }

  _updatePhases() {
    const majorPhases = [];
    const minorPhases = [];

    this.settings.phases.forEach((m) => {
      isUsdkPhaseMajor(m) ? majorPhases.push(m) : minorPhases.push(m);
    });

    this.phases = [majorPhases, minorPhases];
  }
}
