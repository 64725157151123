import { Injectable } from "@angular/core";
import { PtmApiService } from "../ptm-api.service";
import {
  GetPublicTransportVehiclePrioritySettingsRequest,
  PrioritySettings,
  PublicTransportPrioritySettings,
  UpdatePublicTransportVehiclePrioritySettingsRequest,
} from "../../dtos/ptm.dtos";
import { Observable } from "rxjs";

@Injectable()
export class AdaptiveModeService {
  constructor(private _api: PtmApiService) {}

  getParameters(
    customerId: string
  ): Observable<PublicTransportPrioritySettings> {
    const request = new GetPublicTransportVehiclePrioritySettingsRequest();
    request.customerId = customerId;
    return this._api.get(request);
  }
  updateParameters(settings: PrioritySettings, customerId: string) {
    const request = new UpdatePublicTransportVehiclePrioritySettingsRequest();
    request.settings = settings;
    request.customerId = customerId;

    return this._api.put(request);
  }
}
