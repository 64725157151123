// import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';

import { Component, ChangeDetectionStrategy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { ITabnavLink } from '../../utils/tabnav';

@Component({
  selector: 'tabnav-component',
  templateUrl: './tabnav.component.html',
  styles: [
    `
      :host {
        height: 100%;
        display: flex;
        flex-direction: column;
        padding: 0 16px 16px;
        box-sizing: border-box;
      }

      .mat-tab-link {
        height: 40px;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TabnavComponent {
  tabLinks: ITabnavLink[] = [];

  constructor(route: ActivatedRoute) {
    this.tabLinks = route.snapshot.data.tabLinks;
  }
}
