import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';

import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';

import * as echarts from 'echarts';
import { EChartsCoreOption } from 'echarts';
import { ControlSessionSummary } from '../../dtos/tpc.dtos';

@Component({
  selector: 'route-escort-summary2',
  templateUrl: './route-escort-summary2.component.html',
  styleUrls: ['./route-escort-summary2.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RouteEscortSummary2Component implements OnInit, OnDestroy, AfterViewInit {
  _destroy = new Subject();

  summary: ControlSessionSummary;

  _chart;
  _chartOptions: EChartsCoreOption = {
    tooltip: {
      trigger: 'axis',
    },
    grid: {
      top: 20,
      left: 40,
      right: 40,
      bottom: 20,
    },
    xAxis: {
      type: 'time',
      minorTick: {
        show: false,
      },
    },
    yAxis: {
      splitLine: {
        lineStyle: { color: '#99999980' },
      },
    },
    series: [
      {
        type: 'line',
        data: [],
        smooth: true,
        showSymbol: false,
      },
    ],
  };

  values1: { name: string; value: string; unit?: string }[] = [
    {
      name: _('ROUTE_ESCORT.ESCORT_DISTANCE'),
      value: '',
      unit: _('COMMON.UNITS.KM'),
    },
  ];
  values2: { name: string; value: string; unit?: string }[] = [
    {
      name: _('ROUTE_ESCORT.ESCORT_AVG_SPEED'),
      value: '',
      unit: _('COMMON.UNITS.KM_PER_HOUR'),
    },
  ];
  values3: { name: string; value: string; unit?: string }[] = [
    {
      name: _('ROUTE_ESCORT.ESCORT_DURATION'),
      value: '',
    },
  ];

  @ViewChild('chart', { static: true })
  chartContainer: ElementRef;

  constructor(
    private _changeDetector: ChangeDetectorRef,
    public translate: TranslateService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.summary = data.summary;
  }

  ngOnDestroy() {
    this._destroy.next();
    this._destroy.complete();
    this._destroy = null;
  }

  ngOnInit() {
    this._updateValues();
  }

  ngAfterViewInit() {
    this._chart = echarts.init(this.chartContainer.nativeElement);
    this._updateChart();
  }

  _updateChart() {
    const options = { ...this._chartOptions };
    options.series[0].data = this.summary.track.map((m, i) => [
      m.deviceTime,
      this.summary.speed[i],
    ]);
    this._chart.setOption(options);
  }

  _updateValues() {
    this.values1[0].value = this.summary.distance.toPrecision(2);
    this.values2[0].value = this.summary.avgSpeed.toPrecision(2);
    this.values3[0].value = this.getDuration(this.summary.duration);
  }

  getDuration(duration: number) {
    const parts = [
      Math.floor(duration / 3600), // hours
      Math.floor(duration / 60) % 60, // minutes
      Math.floor(duration) % 60, // seconds
    ];
    return parts.map((n) => ('0' + n).slice(-2)).join(':');
  }
}
