import { JsonServiceClient, IReturn } from '@servicestack/client';
import { TranslateService } from '@ngx-translate/core';

import { Observable, EMPTY, throwError, from } from 'rxjs';
import { timeoutWith, catchError } from 'rxjs/operators';

import { TokenService } from './token.service';
import { FlashService } from './flash.service';
import { DialogService } from './dialog.service';
import { AuthService, getInternetAndServerError } from './auth.service';
import { Injectable, Injector } from '@angular/core';
import { PERMISSIONS_TRANSLATE } from '../utils/permissions';

@Injectable()
export abstract class BaseApiService {
  protected _apiUrl: string;
  protected _moduleName: string;
  protected _client: JsonServiceClient;

  protected _authService: AuthService = this._injector.get(AuthService);
  protected _flashService: FlashService = this._injector.get(FlashService);
  protected _dialogService: DialogService = this._injector.get(DialogService);
  protected _tokenService: TokenService = this._injector.get(TokenService);
  protected _translate: TranslateService = this._injector.get(TranslateService);

  get apiUrl() {
    return this._apiUrl;
  }

  // set apiUrl(url: string) {
  //   this._apiUrl = url;
  //   this._client = new JsonServiceClient(this._apiUrl);
  //   this._client.onAuthenticationRequired = async () => {
  //     await this._authService.refresh();
  //     this._prepareBeforeRequest();
  //   };
  // }

  constructor(private _injector: Injector, apiUrl: string, moduleName: string) {
    this._apiUrl = apiUrl;
    this._moduleName = moduleName;
    this._client = new JsonServiceClient(this._apiUrl);
    this._client.onAuthenticationRequired = async () => {
      await this._authService.refresh();
      this._prepareBeforeRequest();
    };
  }

  private _prepareBeforeRequest() {
    this._client.bearerToken = this._tokenService.bearerToken;
  }

  private _catchRequestError = (error, isDialog: boolean) => {
    const module = this._moduleName ? `[Module: ${this._moduleName}]` : '';

    if (error.ResponseStatus || error.responseStatus) {
      const camel = !error.ResponseStatus;
      const status = camel ? error.responseStatus : error.ResponseStatus;
      const errorCode = camel ? status.errorCode : status.ErrorCode;
      const message = camel ? status.message : status.Message;

      switch (errorCode) {
        case 'TokenException':
          this._authService.logout();
          return;
        case 'RefreshTokenException':
          this._authService.logout();
          return;
        case 'PermissionsUsedInRoles':
          const params = JSON.parse(message);
          const permissions = (params.permissions as string[])
            .sort()
            .map((m) => {
              const parts = m.split('.');
              const category = this._translate.instant(
                PERMISSIONS_TRANSLATE[parts[0]]
              );
              const permission = this._translate.instant(
                PERMISSIONS_TRANSLATE[parts[1]]
              );
              return `${category}:${permission}`;
            })
            .join('<br>');
          const roles = (params.roles as string[]).join('<br>');

          // console.log(permissions, roles);

          this._dialogService.warn(
            this._translate.instant('API_ERROR.PERMISSIONS_USED_IN_ROLES', {
              permissions,
              roles,
            })
          );
          break;
        case 'FileExistException':
          this._flashService.warn(
            this._translate.instant('API_ERROR.FILE_EXIST', { file: message })
          );
          break;
        case 'FileMissingException':
          this._flashService.warn(
            this._translate.instant('API_ERROR.FILE_MISSING', { file: message })
          );
          break;
        case 'FileUploadException':
          this._flashService.warn(
            this._translate.instant('API_ERROR.FILE_UPLOAD', { file: message })
          );
          break;
        case 'FileNotFoundException':
          this._flashService.warn(
            this._translate.instant('API_ERROR.FILE_NOT_FOUND', {
              file: message,
            })
          );
          break;
        case 'NotFound':
          this._flashService.warn(
            this._translate.instant('API_ERROR.OBJECT_NOT_FOUND')
          );
          break;
        case 'Forbidden':
          this._flashService.warn(
            this._translate.instant('API_ERROR.FORBIDDEN', { message, module })
          );
          break;
        case 'RequestTimeout':
          this._flashService.warn(
            this._translate.instant('API_ERROR.REQUEST_TIMEOUT')
          );
          break;
        case 'Exception':
          if (message === 'Busy') {
            this._flashService.error(this._translate.instant('API_ERROR.BUSY'));
          } else {
            (isDialog ? this._dialogService : this._flashService).error(
              this._translate.instant('API_ERROR.ERROR_MESSAGE', {
                message: message?.replace(/\n/g, '<br />') || errorCode,
                module,
              })
            );
          }
          break;
        default:
          (isDialog ? this._dialogService : this._flashService).error(
            this._translate.instant('API_ERROR.ERROR_MESSAGE', {
              message: message?.replace(/\n/g, '<br />') || errorCode,
              module,
            })
          );
          break;
      }
      throw error;
    } else {
      if (!document.hidden) {
        this._flashService.warn(
          this._translate.instant(getInternetAndServerError(error), { module })
        );
      }
      throw error;
    }

    return EMPTY;
  };

  private _throwDemo<T>() {
    return this._handleResult(
      new Promise<T>(() => {
        throw new Error(this._translate.instant('API_ERROR.DEMO_ACCESS'));
      }),
      true
    );
  }

  protected _handleResult<T>(
    result: Promise<T>,
    isDialog: boolean
  ): Observable<T> {
    return from(result).pipe(
      timeoutWith(
        30 * 1000,
        throwError({ responseStatus: { errorCode: 'RequestTimeout' } })
      ),
      catchError((error) => this._catchRequestError(error, isDialog))
    );
  }

  get<T>(request: IReturn<T>, args?: any, isModal=false ): Observable<T> {
    this._prepareBeforeRequest();
    return this._handleResult(
      this._client.get(request, { ...args, _t: Date.now() }),
      isModal
    );
  }

  put<T>(request: IReturn<T>, args?: any): Observable<T> {
    if (this.isDemo()) {
      return this._throwDemo();
    }
    this._prepareBeforeRequest();
    return this._handleResult(this._client.put(request, args), true);
  }

  post<T>(request: IReturn<T>, args?: any): Observable<T> {
    if (this.isDemo()) {
      return this._throwDemo();
    }
    this._prepareBeforeRequest();
    return this._handleResult(this._client.post(request, args), true);
  }

  getUnsafe<T>(request: IReturn<T>, args?: any): Observable<T> {
    if (this.isDemo()) {
      return this._throwDemo();
    }
    this._prepareBeforeRequest();
    return from(this._client.get(request, { ...args, _t: Date.now() })).pipe(
      timeoutWith(
        30 * 1000,
        throwError({ responseStatus: { errorCode: 'RequestTimeout' } })
      )
    );
  }

  postUnsafe<T>(request: IReturn<T>, args?: any): Observable<T> {
    if (this.isDemo()) {
      return this._throwDemo();
    }
    this._prepareBeforeRequest();
    return from(this._client.post(request, args)).pipe(
      timeoutWith(
        30 * 1000,
        throwError({ responseStatus: { errorCode: 'RequestTimeout' } })
      )
    );
  }

  delete<T>(request: IReturn<T>, args?: any): Observable<T> {
    if (this.isDemo()) {
      return this._throwDemo();
    }
    this._prepareBeforeRequest();
    return this._handleResult(this._client.delete(request, args), true);
  }

  isDemo() {
    return (
      this._tokenService.getValue('preferred_username')?.toLowerCase() ===
      'demo'
    );
  }
}
