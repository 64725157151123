import { Injectable, OnDestroy } from "@angular/core";

import { BehaviorSubject, EMPTY, Observable } from "rxjs";
import { finalize, shareReplay } from "rxjs/operators";

import {
  ParkingKiosk,
  ParkingKioskStatus,
} from "projects/msu-its-web-parking/src/dtos/parking.dtos";

@Injectable()
export class MsuPirsParkingKioskStateService implements OnDestroy {
  modelId: string;
  customerId: string;

  private readonly _loading = new BehaviorSubject<boolean>(false);
  readonly loading$ = this._loading.pipe(shareReplay(1));
  get loading(): boolean {
    return this._loading.getValue();
  }
  set loading(value) {
    this._loading.next(value);
  }

  private readonly _parkingKiosk = new BehaviorSubject<ParkingKiosk>(null);
  readonly parkingKiosk$ = this._parkingKiosk.pipe(shareReplay(1));
  get parkingKiosk(): ParkingKiosk {
    return this._parkingKiosk.getValue();
  }
  set parkingKiosk(value) {
    this._parkingKiosk.next(value);
  }

  private readonly _status = new BehaviorSubject<ParkingKioskStatus>(null);
  readonly status$ = this._status.pipe(shareReplay(1));
  get status(): ParkingKioskStatus {
    return this._status.getValue();
  }
  set status(value) {
    this._status.next(value);
  }

  constructor() {}

  ngOnDestroy() {
    this._parkingKiosk.complete();
    this._status.complete();
  }

  loadingQuery<T>(query: Observable<T>) {
    if (this.loading) return EMPTY;
    this.loading = true;
    return query.pipe(finalize(() => (this.loading = false)));
  }
}
