import { Component, Inject } from '@angular/core';
import {
  MAT_SNACK_BAR_DATA,
  MatSnackBarRef,
} from '@angular/material/snack-bar';

@Component({
  selector: 'flash-component',
  template: `
    <div class="flash">
      <div *ngIf="icon" class="flash-icon flash-{{ data.type }}">
        <mat-icon>{{ icon }}</mat-icon>
      </div>
      <div class="flash-message" [innerHTML]="data.message"></div>
      <div class="flash-dismiss">
        <button mat-icon-button disableRipple="true" (click)="dismiss()">
          <mat-icon>close</mat-icon>
        </button>
      </div>
    </div>
  `,
  styles: [
    `
      .flash {
        display: flex;
        justify-content: space-between;
        line-height: 20px;
        font-size: 16px;
      }
      .flash-icon,
      .flash-dismiss {
        display: flex;
        flex-direction: column;
        flex-shrink: 0;
        justify-content: space-around;
      }
      .flash-icon {
        margin: -12px 12px -12px -2px;
      }
      .flash-dismiss {
        margin: -12px -10px -12px 8px;
      }
      .flash-dismiss > button {
        flex: 1;
      }
      .flash-message {
        flex-grow: 1;
      }
    `,
  ],
})
export class FlashComponent {
  data: { message: string; type: string };

  constructor(
    private _snackBarRef: MatSnackBarRef<FlashComponent>,
    @Inject(MAT_SNACK_BAR_DATA) data: any
  ) {
    this.data = data;
  }

  get icon(): string {
    switch (this.data.type) {
      case 'info':
        return 'info';
      case 'success':
        return 'check';
      case 'warn':
        return 'warning';
      case 'error':
        return 'error';
      default:
        return null;
    }
  }

  dismiss(): void {
    this._snackBarRef.dismiss();
  }
}
