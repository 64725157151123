import { Pipe, PipeTransform } from '@angular/core';
import { phoneMask } from '../utils/phone';

@Pipe({ name: 'phoneMask' })
export class PhoneMaskPipe implements PipeTransform {
  constructor() {}

  transform(value: string) {
    return phoneMask(value);
  }
}
