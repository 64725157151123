/* Options:
Date: 2023-03-16 09:17:06
Version: 6.00
Tip: To override a DTO option, remove "//" prefix before updating
BaseUrl: https://rmmd.dev.its.msu24.ru

//GlobalNamespace: 
//MakePropertiesOptional: False
//AddServiceStackTypes: True
//AddResponseStatus: False
//AddImplicitVersion: 
//AddDescriptionAsComments: True
//IncludeTypes: 
//ExcludeTypes: 
//DefaultImports: 
*/

export interface IReturn<T> {
  createResponse(): T;
}

export interface IReturnVoid {
  createResponse(): void;
}

export interface IHasSessionId {
  sessionId: string;
}

export interface IHasBearerToken {
  bearerToken: string;
}

export interface IPost {}

// @DataContract
export class PageRequest {
  // @DataMember
  public page: number;

  // @DataMember
  public items: number;

  // @DataMember
  public sort: string;

  // @DataMember
  public filter: string;

  public constructor(init?: Partial<PageRequest>) {
    (Object as any).assign(this, init);
  }
}

export interface ICustomerItemRequest extends ICustomerEntity {}

export interface ICustomerEntity {
  // @DataMember
  customerId: string;
}

export interface IBaseEntityItemRequest {
  id: string;
}

export interface IDescription {
  // @DataMember
  description: string;
}

export interface IRequestWithEntity {}

export class DeleteCustomerItemsRequest implements ICustomerItemRequest, IDescription {
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public description: string;

  // @DataMember(IsRequired=true)
  public ids: string[];

  public constructor(init?: Partial<DeleteCustomerItemsRequest>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class MaintenanceExternalSystemObject {
  // @DataMember
  public id: string;

  // @DataMember
  public externalSystemId: string;

  // @DataMember
  public externalSystemName: string;

  // @DataMember
  public name: string;

  // @DataMember
  public registrationNumber: string;

  // @DataMember
  public description: string;

  public constructor(init?: Partial<MaintenanceExternalSystemObject>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class GetEntityGroupsRequestBase implements ICustomerItemRequest {
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public entityGroups: string[];

  // @DataMember
  public itemsIds: string[];

  // @DataMember
  public onlyOwnedItems: boolean;

  // @DataMember
  public page: number;

  // @DataMember
  public items: number;

  // @DataMember
  public sort: string;

  // @DataMember
  public filter: string;

  public constructor(init?: Partial<GetEntityGroupsRequestBase>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class GetEntityGroupRequestBase implements ICustomerItemRequest, IBaseEntityItemRequest {
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember(IsRequired=true)
  public id: string;

  public constructor(init?: Partial<GetEntityGroupRequestBase>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class EntityGroup
  implements ICustomerEntityViewModel, INamedEntity, IChangeKey, IDescription, IModified, IEntity
{
  // @DataMember
  public items: string[];

  // @DataMember
  public customerId: string;

  // @DataMember
  public customerName: string;

  // @DataMember
  public name: string;

  // @DataMember
  public description: string;

  // @DataMember
  public changeKey: string;

  // @DataMember
  public created: string;

  // @DataMember
  public createdBy: string;

  // @DataMember
  public modified: string;

  // @DataMember
  public modifiedBy: string;

  // @DataMember
  public id: string;

  // @DataMember
  public entityType: string;

  public constructor(init?: Partial<EntityGroup>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class CreateEntityGroupRequestBase implements ICustomerItemRequest, IDescription {
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public updateEntityGroups: boolean;

  // @DataMember
  public entityGroups: string[];

  // @DataMember(IsRequired=true)
  public entity: EntityGroup;

  // @DataMember
  public description: string;

  public constructor(init?: Partial<CreateEntityGroupRequestBase>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class UpdateEntityGroupRequestBase
  implements ICustomerItemRequest, IBaseEntityItemRequest, IDescription
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public updateEntityGroups: boolean;

  // @DataMember
  public entityGroups: string[];

  // @DataMember(IsRequired=true)
  public id: string;

  // @DataMember(IsRequired=true)
  public entity: EntityGroup;

  // @DataMember
  public description: string;

  public constructor(init?: Partial<UpdateEntityGroupRequestBase>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class DeleteEntityGroupsRequestBase implements ICustomerItemRequest, IDescription {
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public description: string;

  // @DataMember(IsRequired=true)
  public ids: string[];

  public constructor(init?: Partial<DeleteEntityGroupsRequestBase>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class GetEntityGroupSharesRequestBase
  implements IBaseEntityItemRequest, ICustomerItemRequest
{
  // @DataMember
  public id: string;

  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public entityGroups: string[];

  // @DataMember
  public itemsIds: string[];

  // @DataMember
  public onlyOwnedItems: boolean;

  // @DataMember
  public page: number;

  // @DataMember
  public items: number;

  // @DataMember
  public sort: string;

  // @DataMember
  public filter: string;

  public constructor(init?: Partial<GetEntityGroupSharesRequestBase>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class EntityGroupPermissions implements IEntityTyped, ICustomerEntityViewModel {
  // @DataMember
  public customerId: string;

  // @DataMember
  public customerName: string;

  // @DataMember
  public entityGroupId: string;

  // @DataMember
  public permissions: string[];

  // @DataMember
  public entityType: string;

  public constructor(init?: Partial<EntityGroupPermissions>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class UpgradeEntityGroupShareRequestBase implements ICustomerItemRequest, IDescription {
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember(IsRequired=true)
  public id: string;

  // @DataMember(IsRequired=true)
  public entity: EntityGroupPermissions;

  // @DataMember
  public description: string;

  public constructor(init?: Partial<UpgradeEntityGroupShareRequestBase>) {
    (Object as any).assign(this, init);
  }
}

export interface ICustomerIntegrationIdRequest {
  customerIntegrationId: string;
}

export interface IChangeKey extends IReadonlyChangeKey {
  // @DataMember
  changeKey: string;
}

export interface IReadonlyChangeKey {
  // @DataMember
  changeKey: string;
}

export class CustomerNamedBaseEntityViewModelBase
  implements ICustomerEntityViewModel, INamedEntity, IChangeKey, IDescription, IModified, IEntity
{
  // @DataMember
  public customerId: string;

  // @DataMember
  public customerName: string;

  // @DataMember
  public name: string;

  // @DataMember
  public description: string;

  // @DataMember
  public changeKey: string;

  // @DataMember
  public created: string;

  // @DataMember
  public createdBy: string;

  // @DataMember
  public modified: string;

  // @DataMember
  public modifiedBy: string;

  // @DataMember
  public id: string;

  // @DataMember
  public entityType: string;

  public constructor(init?: Partial<CustomerNamedBaseEntityViewModelBase>) {
    (Object as any).assign(this, init);
  }
}

export interface IEntity {}

export interface IEntityTyped {
  // @DataMember
  entityType: string;
}

export interface IModified {
  // @DataMember
  modified: string;

  // @DataMember
  modifiedBy: string;
}

export interface INamedEntity extends IEntity, INamed {}

export interface INamed {
  // @DataMember
  name: string;
}

export interface ICustomerEntityViewModel extends ICustomerName, ICustomerEntity {}

export interface ICustomerName {
  // @DataMember
  customerName: string;
}

export enum MaintenanceExternalSystemType {
  AutoGRAPH = 'AutoGRAPH',
}

// @DataContract
export class MaintenanceExternalSystemSettings {
  // @DataMember
  public properties: { [index: string]: string };

  public constructor(init?: Partial<MaintenanceExternalSystemSettings>) {
    (Object as any).assign(this, init);
  }
}

export interface IEnabled {
  enabled: boolean;
}

// @DataContract
export class EntityGroupInfo
  implements ICustomerEntityViewModel, INamedEntity, IChangeKey, IDescription, IModified, IEntity
{
  // @DataMember
  public itemsCount: number;

  // @DataMember
  public customerId: string;

  // @DataMember
  public customerName: string;

  // @DataMember
  public name: string;

  // @DataMember
  public description: string;

  // @DataMember
  public changeKey: string;

  // @DataMember
  public created: string;

  // @DataMember
  public createdBy: string;

  // @DataMember
  public modified: string;

  // @DataMember
  public modifiedBy: string;

  // @DataMember
  public id: string;

  // @DataMember
  public entityType: string;

  public constructor(init?: Partial<EntityGroupInfo>) {
    (Object as any).assign(this, init);
  }
}

export enum MaintenanceVehicleType {
  Unknown = 'Unknown',
  SnowRemoval = 'SnowRemoval',
}

// @DataContract
export class MaintenanceRouteWay {
  // @DataMember
  public geoJson: string;

  public constructor(init?: Partial<MaintenanceRouteWay>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class MaintenanceRouteSettings {
  // @DataMember
  public way: MaintenanceRouteWay;

  public constructor(init?: Partial<MaintenanceRouteSettings>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class VehicleIconStatus {
  // @DataMember
  public color: string;

  // @DataMember
  public borderColor: string;

  // @DataMember
  public processing: boolean;

  public constructor(init?: Partial<VehicleIconStatus>) {
    (Object as any).assign(this, init);
  }
}

export enum MaintenanceVehicleTerminalType {
  None = 'None',
  Navtelecom = 'Navtelecom',
}

export interface ICoordinate {
  lat: number;
  lon: number;
}

export class CustomerDeviceViewModelBase
  implements
    ICustomerEntity,
    ICustomerName,
    IDeviceItem,
    IOwnedDeviceItem,
    IChangeKey,
    IDescription,
    IModified,
    IEntity
{
  // @DataMember
  public customerId: string;

  // @DataMember
  public customerName: string;

  // @DataMember
  public ownerObjectName: string;

  // @DataMember
  public ownerObjectId: string;

  // @DataMember
  public prefix: string;

  // @DataMember
  public number: string;

  // @DataMember
  public phone1: string;

  // @DataMember
  public phone2: string;

  // @DataMember
  public ip1: string;

  // @DataMember
  public ip2: string;

  // @DataMember
  public imei: string;

  // @DataMember
  public deviceSettings: string;

  // @DataMember
  public lat: number;

  // @DataMember
  public lon: number;

  // @DataMember
  public direction: number;

  // @DataMember
  public fullNumber: string;

  // @DataMember
  public softwareVersion: string;

  // @DataMember
  public firmwareVersion: string;

  // @DataMember
  public hardwareVersion: string;

  // @DataMember
  public description: string;

  // @DataMember
  public changeKey: string;

  // @DataMember
  public created: string;

  // @DataMember
  public createdBy: string;

  // @DataMember
  public modified: string;

  // @DataMember
  public modifiedBy: string;

  // @DataMember
  public id: string;

  // @DataMember
  public entityType: string;

  public constructor(init?: Partial<CustomerDeviceViewModelBase>) {
    (Object as any).assign(this, init);
  }
}

export interface IDeviceItem extends IDeviceVersion {
  // @DataMember
  prefix: string;

  // @DataMember
  number: string;

  // @DataMember
  phone1: string;

  // @DataMember
  phone2: string;

  // @DataMember
  ip1: string;

  // @DataMember
  ip2: string;

  // @DataMember
  imei: string;

  // @DataMember
  lat: number;

  // @DataMember
  lon: number;

  // @DataMember
  direction: number;
}

export interface IDeviceVersion {
  // @DataMember
  softwareVersion: string;

  // @DataMember
  firmwareVersion: string;

  // @DataMember
  hardwareVersion: string;
}

export interface IOwnedDeviceItem {
  // @DataMember
  ownerObjectName: string;

  // @DataMember
  ownerObjectId: string;
}

// @DataContract
export class BaseEntityViewModelBase implements IChangeKey, IDescription, IModified, IEntity {
  // @DataMember
  public description: string;

  // @DataMember
  public changeKey: string;

  // @DataMember
  public created: string;

  // @DataMember
  public createdBy: string;

  // @DataMember
  public modified: string;

  // @DataMember
  public modifiedBy: string;

  // @DataMember
  public id: string;

  // @DataMember
  public entityType: string;

  public constructor(init?: Partial<BaseEntityViewModelBase>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class ResponseError {
  // @DataMember(Order=1)
  public errorCode: string;

  // @DataMember(Order=2)
  public fieldName: string;

  // @DataMember(Order=3)
  public message: string;

  // @DataMember(Order=4)
  public meta: { [index: string]: string };

  public constructor(init?: Partial<ResponseError>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class ResponseStatus {
  // @DataMember(Order=1)
  public errorCode: string;

  // @DataMember(Order=2)
  public message: string;

  // @DataMember(Order=3)
  public stackTrace: string;

  // @DataMember(Order=4)
  public errors: ResponseError[];

  // @DataMember(Order=5)
  public meta: { [index: string]: string };

  public constructor(init?: Partial<ResponseStatus>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class DeleteItemsRequest implements IDescription {
  // @DataMember
  public description: string;

  // @DataMember(IsRequired=true)
  public ids: string[];

  public constructor(init?: Partial<DeleteItemsRequest>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class NamedBaseEntityViewModelBase
  implements INamedEntity, IChangeKey, IDescription, IModified, IEntity
{
  // @DataMember
  public name: string;

  // @DataMember
  public description: string;

  // @DataMember
  public changeKey: string;

  // @DataMember
  public created: string;

  // @DataMember
  public createdBy: string;

  // @DataMember
  public modified: string;

  // @DataMember
  public modifiedBy: string;

  // @DataMember
  public id: string;

  // @DataMember
  public entityType: string;

  public constructor(init?: Partial<NamedBaseEntityViewModelBase>) {
    (Object as any).assign(this, init);
  }
}

export class DeviceViewModelBase
  implements IDeviceItem, IOwnedDeviceItem, IChangeKey, IDescription, IModified, IEntity
{
  // @DataMember
  public ownerObjectName: string;

  // @DataMember
  public ownerObjectId: string;

  // @DataMember
  public prefix: string;

  // @DataMember
  public number: string;

  // @DataMember
  public phone1: string;

  // @DataMember
  public phone2: string;

  // @DataMember
  public ip1: string;

  // @DataMember
  public ip2: string;

  // @DataMember
  public imei: string;

  // @DataMember
  public deviceSettings: string;

  // @DataMember
  public lat: number;

  // @DataMember
  public lon: number;

  // @DataMember
  public direction: number;

  // @DataMember
  public fullNumber: string;

  // @DataMember
  public softwareVersion: string;

  // @DataMember
  public firmwareVersion: string;

  // @DataMember
  public hardwareVersion: string;

  // @DataMember
  public description: string;

  // @DataMember
  public changeKey: string;

  // @DataMember
  public created: string;

  // @DataMember
  public createdBy: string;

  // @DataMember
  public modified: string;

  // @DataMember
  public modifiedBy: string;

  // @DataMember
  public id: string;

  // @DataMember
  public entityType: string;

  public constructor(init?: Partial<DeviceViewModelBase>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class EntityViewModelBase implements IEntity {
  // @DataMember
  public id: string;

  // @DataMember
  public entityType: string;

  public constructor(init?: Partial<EntityViewModelBase>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class PageRequestBase {
  // @DataMember
  public page: number;

  // @DataMember
  public items: number;

  // @DataMember
  public sort: string;

  // @DataMember
  public filter: string;

  public constructor(init?: Partial<PageRequestBase>) {
    (Object as any).assign(this, init);
  }
}

export class MaintenanceContractor
  implements ICustomerEntityViewModel, INamedEntity, IChangeKey, IDescription, IModified, IEntity
{
  // @DataMember
  public customerId: string;

  // @DataMember
  public customerName: string;

  // @DataMember
  public name: string;

  // @DataMember
  public description: string;

  // @DataMember
  public changeKey: string;

  // @DataMember
  public created: string;

  // @DataMember
  public createdBy: string;

  // @DataMember
  public modified: string;

  // @DataMember
  public modifiedBy: string;

  // @DataMember
  public id: string;

  // @DataMember
  public entityType: string;

  public constructor(init?: Partial<MaintenanceContractor>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class GetMaintenanceContractorsResponse implements IChangeKey {
  // @DataMember
  public items: MaintenanceContractor[];

  // @DataMember
  public total: number;

  // @DataMember
  public page: number;

  // @DataMember
  public itemsPerPage: number;

  // @DataMember
  public changeKey: string;

  public constructor(init?: Partial<GetMaintenanceContractorsResponse>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class GetMaintenanceExternalSystemObjectsResponse implements IChangeKey {
  // @DataMember
  public items: MaintenanceExternalSystemObject[];

  // @DataMember
  public total: number;

  // @DataMember
  public page: number;

  // @DataMember
  public itemsPerPage: number;

  // @DataMember
  public changeKey: string;

  public constructor(init?: Partial<GetMaintenanceExternalSystemObjectsResponse>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class MaintenanceExternalSystem
  implements
    IEnabled,
    ICustomerEntityViewModel,
    INamedEntity,
    IChangeKey,
    IDescription,
    IModified,
    IEntity
{
  // @DataMember
  public externalSystemType: MaintenanceExternalSystemType;

  // @DataMember
  public errorMessage: string;

  // @DataMember
  public settings: MaintenanceExternalSystemSettings;

  // @DataMember
  public enabled: boolean;

  // @DataMember
  public online: boolean;

  // @DataMember
  public lastConnection?: string;

  // @DataMember
  public statusDescription: string;

  // @DataMember
  public errorDescription: string;

  // @DataMember
  public customerId: string;

  // @DataMember
  public customerName: string;

  // @DataMember
  public name: string;

  // @DataMember
  public description: string;

  // @DataMember
  public changeKey: string;

  // @DataMember
  public created: string;

  // @DataMember
  public createdBy: string;

  // @DataMember
  public modified: string;

  // @DataMember
  public modifiedBy: string;

  // @DataMember
  public id: string;

  // @DataMember
  public entityType: string;

  public constructor(init?: Partial<MaintenanceExternalSystem>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class GetMaintenanceExternalSystemsResponse implements IChangeKey {
  // @DataMember
  public items: MaintenanceExternalSystem[];

  // @DataMember
  public total: number;

  // @DataMember
  public page: number;

  // @DataMember
  public itemsPerPage: number;

  // @DataMember
  public changeKey: string;

  public constructor(init?: Partial<GetMaintenanceExternalSystemsResponse>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class GetEntityGroupsResponse implements IChangeKey {
  // @DataMember
  public items: EntityGroupInfo[];

  // @DataMember
  public total: number;

  // @DataMember
  public page: number;

  // @DataMember
  public itemsPerPage: number;

  // @DataMember
  public changeKey: string;

  public constructor(init?: Partial<GetEntityGroupsResponse>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class GetEntityGroupSharesResponse implements IChangeKey {
  // @DataMember
  public items: EntityGroupPermissions[];

  // @DataMember
  public total: number;

  // @DataMember
  public page: number;

  // @DataMember
  public itemsPerPage: number;

  // @DataMember
  public changeKey: string;

  public constructor(init?: Partial<GetEntityGroupSharesResponse>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class MaintenanceRoute
  implements ICustomerEntityViewModel, INamedEntity, IChangeKey, IDescription, IModified, IEntity
{
  // @DataMember
  public vehicleType: MaintenanceVehicleType;

  // @DataMember
  public settings: MaintenanceRouteSettings;

  // @DataMember
  public customerId: string;

  // @DataMember
  public customerName: string;

  // @DataMember
  public name: string;

  // @DataMember
  public description: string;

  // @DataMember
  public changeKey: string;

  // @DataMember
  public created: string;

  // @DataMember
  public createdBy: string;

  // @DataMember
  public modified: string;

  // @DataMember
  public modifiedBy: string;

  // @DataMember
  public id: string;

  // @DataMember
  public entityType: string;

  public constructor(init?: Partial<MaintenanceRoute>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class GetMaintenanceRoutesResponse implements IChangeKey {
  // @DataMember
  public items: MaintenanceRoute[];

  // @DataMember
  public total: number;

  // @DataMember
  public page: number;

  // @DataMember
  public itemsPerPage: number;

  // @DataMember
  public changeKey: string;

  public constructor(init?: Partial<GetMaintenanceRoutesResponse>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class MaintenanceVehicleStatus implements IEntity, ICoordinate, ICustomerEntity {
  // @DataMember
  public id: string;

  // @DataMember
  public entityType: string;

  // @DataMember
  public serverTime: string;

  // @DataMember
  public vehicleTime: string;

  // @DataMember
  public online: boolean;

  // @DataMember
  public iconStatus: VehicleIconStatus;

  // @DataMember
  public errorDescription: string;

  // @DataMember
  public lat: number;

  // @DataMember
  public lon: number;

  // @DataMember
  public speed: number;

  // @DataMember
  public direction: number;

  // @DataMember
  public model: string;

  // @DataMember
  public number: string;

  // @DataMember
  public terminalId?: string;

  // @DataMember
  public terminalNumber: string;

  // @DataMember
  public externalSystemId?: string;

  // @DataMember
  public externalSystemName: string;

  // @DataMember
  public externalObjectId: string;

  // @DataMember
  public externalObjectName: string;

  // @DataMember
  public contractorId?: string;

  // @DataMember
  public contractorName: string;

  // @DataMember
  public customerId: string;

  // @DataMember
  public customerName: string;

  // @DataMember
  public vehicleType: MaintenanceVehicleType;

  // @DataMember
  public terminalType: MaintenanceVehicleTerminalType;

  // @DataMember
  public address: string;

  public constructor(init?: Partial<MaintenanceVehicleStatus>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class GetMaintenanceVehicleStatusesResponse implements IChangeKey {
  // @DataMember
  public items: MaintenanceVehicleStatus[];

  // @DataMember
  public total: number;

  // @DataMember
  public page: number;

  // @DataMember
  public itemsPerPage: number;

  // @DataMember
  public changeKey: string;

  public constructor(init?: Partial<GetMaintenanceVehicleStatusesResponse>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class MaintenanceVehicle
  implements ICustomerEntityViewModel, INamedEntity, IChangeKey, IDescription, IModified, IEntity
{
  // @DataMember
  public model: string;

  // @DataMember
  public number: string;

  // @DataMember
  public vehicleType: MaintenanceVehicleType;

  // @DataMember
  public contractorId?: string;

  // @DataMember
  public contractorName: string;

  // @DataMember
  public terminalId?: string;

  // @DataMember
  public terminalNumber: string;

  // @DataMember
  public externalSystemId?: string;

  // @DataMember
  public externalSystemName: string;

  // @DataMember
  public externalObjectId: string;

  // @DataMember
  public externalObjectName: string;

  // @DataMember
  public externalData: boolean;

  // @DataMember
  public terminalType: MaintenanceVehicleTerminalType;

  // @DataMember
  public customerId: string;

  // @DataMember
  public customerName: string;

  // @DataMember
  public name: string;

  // @DataMember
  public description: string;

  // @DataMember
  public changeKey: string;

  // @DataMember
  public created: string;

  // @DataMember
  public createdBy: string;

  // @DataMember
  public modified: string;

  // @DataMember
  public modifiedBy: string;

  // @DataMember
  public id: string;

  // @DataMember
  public entityType: string;

  public constructor(init?: Partial<MaintenanceVehicle>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class GetMaintenanceVehiclesResponse implements IChangeKey {
  // @DataMember
  public items: MaintenanceVehicle[];

  // @DataMember
  public total: number;

  // @DataMember
  public page: number;

  // @DataMember
  public itemsPerPage: number;

  // @DataMember
  public changeKey: string;

  public constructor(init?: Partial<GetMaintenanceVehiclesResponse>) {
    (Object as any).assign(this, init);
  }
}

export class MaintenanceVehicleTerminal
  implements
    ICustomerEntity,
    ICustomerName,
    IDeviceItem,
    IOwnedDeviceItem,
    IChangeKey,
    IDescription,
    IModified,
    IEntity
{
  // @DataMember
  public terminalType: MaintenanceVehicleTerminalType;

  // @DataMember
  public customerId: string;

  // @DataMember
  public customerName: string;

  // @DataMember
  public ownerObjectName: string;

  // @DataMember
  public ownerObjectId: string;

  // @DataMember
  public prefix: string;

  // @DataMember
  public number: string;

  // @DataMember
  public phone1: string;

  // @DataMember
  public phone2: string;

  // @DataMember
  public ip1: string;

  // @DataMember
  public ip2: string;

  // @DataMember
  public imei: string;

  // @DataMember
  public deviceSettings: string;

  // @DataMember
  public lat: number;

  // @DataMember
  public lon: number;

  // @DataMember
  public direction: number;

  // @DataMember
  public fullNumber: string;

  // @DataMember
  public softwareVersion: string;

  // @DataMember
  public firmwareVersion: string;

  // @DataMember
  public hardwareVersion: string;

  // @DataMember
  public description: string;

  // @DataMember
  public changeKey: string;

  // @DataMember
  public created: string;

  // @DataMember
  public createdBy: string;

  // @DataMember
  public modified: string;

  // @DataMember
  public modifiedBy: string;

  // @DataMember
  public id: string;

  // @DataMember
  public entityType: string;

  public constructor(init?: Partial<MaintenanceVehicleTerminal>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class GetMaintenanceVehicleTerminalsResponse implements IChangeKey {
  // @DataMember
  public items: MaintenanceVehicleTerminal[];

  // @DataMember
  public total: number;

  // @DataMember
  public page: number;

  // @DataMember
  public itemsPerPage: number;

  // @DataMember
  public changeKey: string;

  public constructor(init?: Partial<GetMaintenanceVehicleTerminalsResponse>) {
    (Object as any).assign(this, init);
  }
}

export class MaintenanceWork
  implements ICustomerEntityViewModel, INamedEntity, IChangeKey, IDescription, IModified, IEntity
{
  // @DataMember
  public customerId: string;

  // @DataMember
  public customerName: string;

  // @DataMember
  public name: string;

  // @DataMember
  public description: string;

  // @DataMember
  public changeKey: string;

  // @DataMember
  public created: string;

  // @DataMember
  public createdBy: string;

  // @DataMember
  public modified: string;

  // @DataMember
  public modifiedBy: string;

  // @DataMember
  public id: string;

  // @DataMember
  public entityType: string;

  public constructor(init?: Partial<MaintenanceWork>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class GetMaintenanceWorksResponse implements IChangeKey {
  // @DataMember
  public items: MaintenanceWork[];

  // @DataMember
  public total: number;

  // @DataMember
  public page: number;

  // @DataMember
  public itemsPerPage: number;

  // @DataMember
  public changeKey: string;

  public constructor(init?: Partial<GetMaintenanceWorksResponse>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class EntityIntegration implements IChangeKey, IDescription, IModified, IEntity {
  // @DataMember
  public entityId: string;

  // @DataMember
  public customerIntegrationId: string;

  // @DataMember
  public externalId: string;

  // @DataMember
  public settings: string;

  // @DataMember
  public integrationId: string;

  // @DataMember
  public integrationName: string;

  // @DataMember
  public integrationType: string;

  // @DataMember
  public description: string;

  // @DataMember
  public changeKey: string;

  // @DataMember
  public created: string;

  // @DataMember
  public createdBy: string;

  // @DataMember
  public modified: string;

  // @DataMember
  public modifiedBy: string;

  // @DataMember
  public id: string;

  // @DataMember
  public entityType: string;

  public constructor(init?: Partial<EntityIntegration>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class GetEntityIntegrationResponse implements IChangeKey {
  // @DataMember
  public items: EntityIntegration[];

  // @DataMember
  public total: number;

  // @DataMember
  public page: number;

  // @DataMember
  public itemsPerPage: number;

  // @DataMember
  public changeKey: string;

  public constructor(init?: Partial<GetEntityIntegrationResponse>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class GetEntitiesResponse implements IChangeKey {
  // @DataMember
  public items: Object[];

  // @DataMember
  public total: number;

  // @DataMember
  public page: number;

  // @DataMember
  public itemsPerPage: number;

  // @DataMember
  public changeKey: string;

  public constructor(init?: Partial<GetEntitiesResponse>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class AuthenticateResponse implements IHasSessionId, IHasBearerToken {
  // @DataMember(Order=1)
  public userId: string;

  // @DataMember(Order=2)
  public sessionId: string;

  // @DataMember(Order=3)
  public userName: string;

  // @DataMember(Order=4)
  public displayName: string;

  // @DataMember(Order=5)
  public referrerUrl: string;

  // @DataMember(Order=6)
  public bearerToken: string;

  // @DataMember(Order=7)
  public refreshToken: string;

  // @DataMember(Order=8)
  public profileUrl: string;

  // @DataMember(Order=9)
  public roles: string[];

  // @DataMember(Order=10)
  public permissions: string[];

  // @DataMember(Order=11)
  public responseStatus: ResponseStatus;

  // @DataMember(Order=12)
  public meta: { [index: string]: string };

  public constructor(init?: Partial<AuthenticateResponse>) {
    (Object as any).assign(this, init);
  }
}

// @Route("/customer/{customerId}/contractors", "GET")
// @DataContract
export class GetMaintenanceContractorsRequest
  implements IReturn<GetMaintenanceContractorsResponse>, ICustomerItemRequest
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public entityGroups: string[];

  // @DataMember
  public itemsIds: string[];

  // @DataMember
  public onlyOwnedItems: boolean;

  // @DataMember
  public page: number;

  // @DataMember
  public items: number;

  // @DataMember
  public sort: string;

  // @DataMember
  public filter: string;

  public constructor(init?: Partial<GetMaintenanceContractorsRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new GetMaintenanceContractorsResponse();
  }
  public getTypeName() {
    return 'GetMaintenanceContractorsRequest';
  }
}

// @Route("/customer/{customerId}/contractor/{id}", "GET")
// @DataContract
export class GetMaintenanceContractorRequest
  implements IReturn<MaintenanceContractor>, ICustomerItemRequest, IBaseEntityItemRequest
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember(IsRequired=true)
  public id: string;

  public constructor(init?: Partial<GetMaintenanceContractorRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new MaintenanceContractor();
  }
  public getTypeName() {
    return 'GetMaintenanceContractorRequest';
  }
}

// @Route("/customer/{customerId}/contractors/exists", "GET")
// @DataContract
export class GetMaintenanceContractorExistRequest
  implements IReturn<boolean>, ICustomerItemRequest
{
  // @DataMember(IsRequired=true)
  public name: string;

  // @DataMember
  public customerId: string;

  public constructor(init?: Partial<GetMaintenanceContractorExistRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return false;
  }
  public getTypeName() {
    return 'GetMaintenanceContractorExistRequest';
  }
}

// @Route("/customer/{customerId}/contractors", "POST")
// @DataContract
export class CreateMaintenanceContractorRequest
  implements IReturn<MaintenanceContractor>, ICustomerItemRequest, IDescription
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public updateEntityGroups: boolean;

  // @DataMember
  public entityGroups: string[];

  // @DataMember(IsRequired=true)
  public entity: MaintenanceContractor;

  // @DataMember
  public description: string;

  public constructor(init?: Partial<CreateMaintenanceContractorRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new MaintenanceContractor();
  }
  public getTypeName() {
    return 'CreateMaintenanceContractorRequest';
  }
}

// @Route("/customer/{customerId}/contractor/{id}", "PUT")
// @DataContract
export class UpdateMaintenanceContractorRequest
  implements
    IReturn<MaintenanceContractor>,
    ICustomerItemRequest,
    IBaseEntityItemRequest,
    IDescription
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public updateEntityGroups: boolean;

  // @DataMember
  public entityGroups: string[];

  // @DataMember(IsRequired=true)
  public id: string;

  // @DataMember(IsRequired=true)
  public entity: MaintenanceContractor;

  // @DataMember
  public description: string;

  public constructor(init?: Partial<UpdateMaintenanceContractorRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new MaintenanceContractor();
  }
  public getTypeName() {
    return 'UpdateMaintenanceContractorRequest';
  }
}

// @Route("/customer/{customerId}/contractors", "DELETE")
// @DataContract
export class DeleteMaintenanceContractorsRequest
  implements IReturn<number>, ICustomerItemRequest, IDescription
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public description: string;

  // @DataMember(IsRequired=true)
  public ids: string[];

  public constructor(init?: Partial<DeleteMaintenanceContractorsRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return 0;
  }
  public getTypeName() {
    return 'DeleteMaintenanceContractorsRequest';
  }
}

// @Route("/customer/{customerId}/externalSystem/{id}/objects", "GET")
// @DataContract
export class GetMaintenanceExternalSystemObjectsRequest
  implements
    IReturn<GetMaintenanceExternalSystemObjectsResponse>,
    IBaseEntityItemRequest,
    ICustomerItemRequest
{
  // @DataMember
  public id: string;

  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public entityGroups: string[];

  // @DataMember
  public itemsIds: string[];

  // @DataMember
  public onlyOwnedItems: boolean;

  // @DataMember
  public page: number;

  // @DataMember
  public items: number;

  // @DataMember
  public sort: string;

  // @DataMember
  public filter: string;

  public constructor(init?: Partial<GetMaintenanceExternalSystemObjectsRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new GetMaintenanceExternalSystemObjectsResponse();
  }
  public getTypeName() {
    return 'GetMaintenanceExternalSystemObjectsRequest';
  }
}

// @Route("/customer/{customerId}/externalSystems", "GET")
// @DataContract
export class GetMaintenanceExternalSystemsRequest
  implements IReturn<GetMaintenanceExternalSystemsResponse>, ICustomerItemRequest
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public entityGroups: string[];

  // @DataMember
  public itemsIds: string[];

  // @DataMember
  public onlyOwnedItems: boolean;

  // @DataMember
  public page: number;

  // @DataMember
  public items: number;

  // @DataMember
  public sort: string;

  // @DataMember
  public filter: string;

  public constructor(init?: Partial<GetMaintenanceExternalSystemsRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new GetMaintenanceExternalSystemsResponse();
  }
  public getTypeName() {
    return 'GetMaintenanceExternalSystemsRequest';
  }
}

// @Route("/customer/{customerId}/externalSystem/{id}", "GET")
// @DataContract
export class GetMaintenanceExternalSystemRequest
  implements IReturn<MaintenanceExternalSystem>, ICustomerItemRequest, IBaseEntityItemRequest
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember(IsRequired=true)
  public id: string;

  public constructor(init?: Partial<GetMaintenanceExternalSystemRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new MaintenanceExternalSystem();
  }
  public getTypeName() {
    return 'GetMaintenanceExternalSystemRequest';
  }
}

// @Route("/customer/{customerId}/externalSystems/exists", "GET")
// @DataContract
export class GetMaintenanceExternalSystemExistRequest
  implements IReturn<boolean>, ICustomerItemRequest
{
  // @DataMember(IsRequired=true)
  public name: string;

  // @DataMember
  public customerId: string;

  public constructor(init?: Partial<GetMaintenanceExternalSystemExistRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return false;
  }
  public getTypeName() {
    return 'GetMaintenanceExternalSystemExistRequest';
  }
}

// @Route("/customer/{customerId}/externalSystems", "POST")
// @DataContract
export class CreateMaintenanceExternalSystemRequest
  implements IReturn<MaintenanceExternalSystem>, ICustomerItemRequest, IDescription
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public updateEntityGroups: boolean;

  // @DataMember
  public entityGroups: string[];

  // @DataMember(IsRequired=true)
  public entity: MaintenanceExternalSystem;

  // @DataMember
  public description: string;

  public constructor(init?: Partial<CreateMaintenanceExternalSystemRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new MaintenanceExternalSystem();
  }
  public getTypeName() {
    return 'CreateMaintenanceExternalSystemRequest';
  }
}

// @Route("/customer/{customerId}/externalSystem/{id}", "PUT")
// @DataContract
export class UpdateMaintenanceExternalSystemRequest
  implements
    IReturn<MaintenanceExternalSystem>,
    ICustomerItemRequest,
    IBaseEntityItemRequest,
    IDescription
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public updateEntityGroups: boolean;

  // @DataMember
  public entityGroups: string[];

  // @DataMember(IsRequired=true)
  public id: string;

  // @DataMember(IsRequired=true)
  public entity: MaintenanceExternalSystem;

  // @DataMember
  public description: string;

  public constructor(init?: Partial<UpdateMaintenanceExternalSystemRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new MaintenanceExternalSystem();
  }
  public getTypeName() {
    return 'UpdateMaintenanceExternalSystemRequest';
  }
}

// @Route("/customer/{customerId}/externalSystems", "DELETE")
// @DataContract
export class DeleteMaintenanceExternalSystemsRequest
  implements IReturn<number>, ICustomerItemRequest, IDescription
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public description: string;

  // @DataMember(IsRequired=true)
  public ids: string[];

  public constructor(init?: Partial<DeleteMaintenanceExternalSystemsRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return 0;
  }
  public getTypeName() {
    return 'DeleteMaintenanceExternalSystemsRequest';
  }
}

// @Route("/customer/{customerId}/routes/groups", "GET")
// @DataContract
export class GetMaintenanceRouteGroupsRequest
  implements IReturn<GetEntityGroupsResponse>, ICustomerItemRequest
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public entityGroups: string[];

  // @DataMember
  public itemsIds: string[];

  // @DataMember
  public onlyOwnedItems: boolean;

  // @DataMember
  public page: number;

  // @DataMember
  public items: number;

  // @DataMember
  public sort: string;

  // @DataMember
  public filter: string;

  public constructor(init?: Partial<GetMaintenanceRouteGroupsRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new GetEntityGroupsResponse();
  }
  public getTypeName() {
    return 'GetMaintenanceRouteGroupsRequest';
  }
}

// @Route("/customer/{customerId}/routes/groups/{id}", "GET")
// @DataContract
export class GetMaintenanceRouteGroupRequest
  implements IReturn<EntityGroup>, ICustomerItemRequest, IBaseEntityItemRequest
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember(IsRequired=true)
  public id: string;

  public constructor(init?: Partial<GetMaintenanceRouteGroupRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new EntityGroup();
  }
  public getTypeName() {
    return 'GetMaintenanceRouteGroupRequest';
  }
}

// @Route("/customer/{customerId}/routes/groups", "POST")
// @DataContract
export class CreateMaintenanceRouteGroupRequest
  implements IReturn<EntityGroup>, ICustomerItemRequest, IDescription
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public updateEntityGroups: boolean;

  // @DataMember
  public entityGroups: string[];

  // @DataMember(IsRequired=true)
  public entity: EntityGroup;

  // @DataMember
  public description: string;

  public constructor(init?: Partial<CreateMaintenanceRouteGroupRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new EntityGroup();
  }
  public getTypeName() {
    return 'CreateMaintenanceRouteGroupRequest';
  }
}

// @Route("/customer/{customerId}/routes/groups/{id}", "PUT")
// @DataContract
export class UpdateMaintenanceRouteGroupRequest
  implements IReturn<EntityGroup>, ICustomerItemRequest, IBaseEntityItemRequest, IDescription
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public updateEntityGroups: boolean;

  // @DataMember
  public entityGroups: string[];

  // @DataMember(IsRequired=true)
  public id: string;

  // @DataMember(IsRequired=true)
  public entity: EntityGroup;

  // @DataMember
  public description: string;

  public constructor(init?: Partial<UpdateMaintenanceRouteGroupRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new EntityGroup();
  }
  public getTypeName() {
    return 'UpdateMaintenanceRouteGroupRequest';
  }
}

// @Route("/customer/{customerId}/routes/groups", "DELETE")
// @DataContract
export class DeleteMaintenanceRouteGroupsRequest
  implements IReturn<number>, ICustomerItemRequest, IDescription
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public description: string;

  // @DataMember(IsRequired=true)
  public ids: string[];

  public constructor(init?: Partial<DeleteMaintenanceRouteGroupsRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return 0;
  }
  public getTypeName() {
    return 'DeleteMaintenanceRouteGroupsRequest';
  }
}

// @Route("/customer/{customerId}/routes/groups/{id}", "GET")
// @DataContract
export class GetMaintenanceRouteGroupSharesRequest
  implements IReturn<GetEntityGroupSharesResponse>, IBaseEntityItemRequest, ICustomerItemRequest
{
  // @DataMember
  public id: string;

  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public entityGroups: string[];

  // @DataMember
  public itemsIds: string[];

  // @DataMember
  public onlyOwnedItems: boolean;

  // @DataMember
  public page: number;

  // @DataMember
  public items: number;

  // @DataMember
  public sort: string;

  // @DataMember
  public filter: string;

  public constructor(init?: Partial<GetMaintenanceRouteGroupSharesRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new GetEntityGroupSharesResponse();
  }
  public getTypeName() {
    return 'GetMaintenanceRouteGroupSharesRequest';
  }
}

// @Route("/customer/{customerId}/routes/groups/{id}", "PUT")
// @DataContract
export class UpdateMaintenanceRouteGroupShareRequest
  implements IReturn<EntityGroupPermissions>, ICustomerItemRequest, IDescription
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember(IsRequired=true)
  public id: string;

  // @DataMember(IsRequired=true)
  public entity: EntityGroupPermissions;

  // @DataMember
  public description: string;

  public constructor(init?: Partial<UpdateMaintenanceRouteGroupShareRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new EntityGroupPermissions();
  }
  public getTypeName() {
    return 'UpdateMaintenanceRouteGroupShareRequest';
  }
}

// @Route("/customer/{customerId}/routes", "GET")
// @DataContract
export class GetMaintenanceRoutesRequest
  implements IReturn<GetMaintenanceRoutesResponse>, ICustomerItemRequest
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public entityGroups: string[];

  // @DataMember
  public itemsIds: string[];

  // @DataMember
  public onlyOwnedItems: boolean;

  // @DataMember
  public page: number;

  // @DataMember
  public items: number;

  // @DataMember
  public sort: string;

  // @DataMember
  public filter: string;

  public constructor(init?: Partial<GetMaintenanceRoutesRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new GetMaintenanceRoutesResponse();
  }
  public getTypeName() {
    return 'GetMaintenanceRoutesRequest';
  }
}

// @Route("/customer/{customerId}/route/{id}", "GET")
// @DataContract
export class GetMaintenanceRouteRequest
  implements IReturn<MaintenanceRoute>, ICustomerItemRequest, IBaseEntityItemRequest
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember(IsRequired=true)
  public id: string;

  public constructor(init?: Partial<GetMaintenanceRouteRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new MaintenanceRoute();
  }
  public getTypeName() {
    return 'GetMaintenanceRouteRequest';
  }
}

// @Route("/customer/{customerId}/routes/exists", "GET")
// @DataContract
export class GetMaintenanceRouteExistRequest implements IReturn<boolean>, ICustomerItemRequest {
  // @DataMember(IsRequired=true)
  public name: string;

  // @DataMember
  public customerId: string;

  public constructor(init?: Partial<GetMaintenanceRouteExistRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return false;
  }
  public getTypeName() {
    return 'GetMaintenanceRouteExistRequest';
  }
}

// @Route("/customer/{customerId}/routes", "POST")
// @DataContract
export class CreateMaintenanceRouteRequest
  implements IReturn<MaintenanceRoute>, ICustomerItemRequest, IDescription
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public updateEntityGroups: boolean;

  // @DataMember
  public entityGroups: string[];

  // @DataMember(IsRequired=true)
  public entity: MaintenanceRoute;

  // @DataMember
  public description: string;

  public constructor(init?: Partial<CreateMaintenanceRouteRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new MaintenanceRoute();
  }
  public getTypeName() {
    return 'CreateMaintenanceRouteRequest';
  }
}

// @Route("/customer/{customerId}/route/{id}", "PUT")
// @DataContract
export class UpdateMaintenanceRouteRequest
  implements IReturn<MaintenanceRoute>, ICustomerItemRequest, IBaseEntityItemRequest, IDescription
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public updateEntityGroups: boolean;

  // @DataMember
  public entityGroups: string[];

  // @DataMember(IsRequired=true)
  public id: string;

  // @DataMember(IsRequired=true)
  public entity: MaintenanceRoute;

  // @DataMember
  public description: string;

  public constructor(init?: Partial<UpdateMaintenanceRouteRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new MaintenanceRoute();
  }
  public getTypeName() {
    return 'UpdateMaintenanceRouteRequest';
  }
}

// @Route("/customer/{customerId}/routes", "DELETE")
// @DataContract
export class DeleteMaintenanceRoutesRequest
  implements IReturn<number>, ICustomerItemRequest, IDescription
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public description: string;

  // @DataMember(IsRequired=true)
  public ids: string[];

  public constructor(init?: Partial<DeleteMaintenanceRoutesRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return 0;
  }
  public getTypeName() {
    return 'DeleteMaintenanceRoutesRequest';
  }
}

// @Route("/customer/{customerId}/vehicles/groups", "GET")
// @DataContract
export class GetMaintenanceVehicleGroupsRequest
  implements IReturn<GetEntityGroupsResponse>, ICustomerItemRequest
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public entityGroups: string[];

  // @DataMember
  public itemsIds: string[];

  // @DataMember
  public onlyOwnedItems: boolean;

  // @DataMember
  public page: number;

  // @DataMember
  public items: number;

  // @DataMember
  public sort: string;

  // @DataMember
  public filter: string;

  public constructor(init?: Partial<GetMaintenanceVehicleGroupsRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new GetEntityGroupsResponse();
  }
  public getTypeName() {
    return 'GetMaintenanceVehicleGroupsRequest';
  }
}

// @Route("/customer/{customerId}/vehicles/groups/{id}", "GET")
// @DataContract
export class GetMaintenanceVehicleGroupRequest
  implements IReturn<EntityGroup>, ICustomerItemRequest, IBaseEntityItemRequest
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember(IsRequired=true)
  public id: string;

  public constructor(init?: Partial<GetMaintenanceVehicleGroupRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new EntityGroup();
  }
  public getTypeName() {
    return 'GetMaintenanceVehicleGroupRequest';
  }
}

// @Route("/customer/{customerId}/vehicles/groups", "POST")
// @DataContract
export class CreateMaintenanceVehicleGroupRequest
  implements IReturn<EntityGroup>, ICustomerItemRequest, IDescription
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public updateEntityGroups: boolean;

  // @DataMember
  public entityGroups: string[];

  // @DataMember(IsRequired=true)
  public entity: EntityGroup;

  // @DataMember
  public description: string;

  public constructor(init?: Partial<CreateMaintenanceVehicleGroupRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new EntityGroup();
  }
  public getTypeName() {
    return 'CreateMaintenanceVehicleGroupRequest';
  }
}

// @Route("/customer/{customerId}/vehicles/groups/{id}", "PUT")
// @DataContract
export class UpdateMaintenanceVehicleGroupRequest
  implements IReturn<EntityGroup>, ICustomerItemRequest, IBaseEntityItemRequest, IDescription
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public updateEntityGroups: boolean;

  // @DataMember
  public entityGroups: string[];

  // @DataMember(IsRequired=true)
  public id: string;

  // @DataMember(IsRequired=true)
  public entity: EntityGroup;

  // @DataMember
  public description: string;

  public constructor(init?: Partial<UpdateMaintenanceVehicleGroupRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new EntityGroup();
  }
  public getTypeName() {
    return 'UpdateMaintenanceVehicleGroupRequest';
  }
}

// @Route("/customer/{customerId}/vehicles/groups", "DELETE")
// @DataContract
export class DeleteMaintenanceVehicleGroupsRequest
  implements IReturn<number>, ICustomerItemRequest, IDescription
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public description: string;

  // @DataMember(IsRequired=true)
  public ids: string[];

  public constructor(init?: Partial<DeleteMaintenanceVehicleGroupsRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return 0;
  }
  public getTypeName() {
    return 'DeleteMaintenanceVehicleGroupsRequest';
  }
}

// @Route("/customer/{customerId}/vehicles/groups/{id}", "GET")
// @DataContract
export class GetMaintenanceVehicleGroupSharesRequest
  implements IReturn<GetEntityGroupSharesResponse>, IBaseEntityItemRequest, ICustomerItemRequest
{
  // @DataMember
  public id: string;

  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public entityGroups: string[];

  // @DataMember
  public itemsIds: string[];

  // @DataMember
  public onlyOwnedItems: boolean;

  // @DataMember
  public page: number;

  // @DataMember
  public items: number;

  // @DataMember
  public sort: string;

  // @DataMember
  public filter: string;

  public constructor(init?: Partial<GetMaintenanceVehicleGroupSharesRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new GetEntityGroupSharesResponse();
  }
  public getTypeName() {
    return 'GetMaintenanceVehicleGroupSharesRequest';
  }
}

// @Route("/customer/{customerId}/vehicles/groups/{id}", "PUT")
// @DataContract
export class UpdateMaintenanceVehicleGroupShareRequest
  implements IReturn<EntityGroupPermissions>, ICustomerItemRequest, IDescription
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember(IsRequired=true)
  public id: string;

  // @DataMember(IsRequired=true)
  public entity: EntityGroupPermissions;

  // @DataMember
  public description: string;

  public constructor(init?: Partial<UpdateMaintenanceVehicleGroupShareRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new EntityGroupPermissions();
  }
  public getTypeName() {
    return 'UpdateMaintenanceVehicleGroupShareRequest';
  }
}

// @Route("/customer/{customerId}/vehicle/{id}/status", "GET")
// @DataContract
export class GetMaintenanceVehicleStatusRequest
  implements IReturn<MaintenanceVehicleStatus>, ICustomerItemRequest, IBaseEntityItemRequest
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember(IsRequired=true)
  public id: string;

  public constructor(init?: Partial<GetMaintenanceVehicleStatusRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new MaintenanceVehicleStatus();
  }
  public getTypeName() {
    return 'GetMaintenanceVehicleStatusRequest';
  }
}

// @Route("/customer/{customerId}/vehicles/statuses", "GET")
// @DataContract
export class GetMaintenanceVehicleStatusesRequest
  implements IReturn<GetMaintenanceVehicleStatusesResponse>, ICustomerItemRequest
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public entityGroups: string[];

  // @DataMember
  public itemsIds: string[];

  // @DataMember
  public onlyOwnedItems: boolean;

  // @DataMember
  public page: number;

  // @DataMember
  public items: number;

  // @DataMember
  public sort: string;

  // @DataMember
  public filter: string;

  public constructor(init?: Partial<GetMaintenanceVehicleStatusesRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new GetMaintenanceVehicleStatusesResponse();
  }
  public getTypeName() {
    return 'GetMaintenanceVehicleStatusesRequest';
  }
}

// @Route("/customer/{customerId}/vehicles", "GET")
// @DataContract
export class GetMaintenanceVehiclesRequest
  implements IReturn<GetMaintenanceVehiclesResponse>, ICustomerItemRequest
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public entityGroups: string[];

  // @DataMember
  public itemsIds: string[];

  // @DataMember
  public onlyOwnedItems: boolean;

  // @DataMember
  public page: number;

  // @DataMember
  public items: number;

  // @DataMember
  public sort: string;

  // @DataMember
  public filter: string;

  public constructor(init?: Partial<GetMaintenanceVehiclesRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new GetMaintenanceVehiclesResponse();
  }
  public getTypeName() {
    return 'GetMaintenanceVehiclesRequest';
  }
}

// @Route("/customer/{customerId}/vehicle/{id}", "GET")
// @DataContract
export class GetMaintenanceVehicleRequest
  implements IReturn<MaintenanceVehicle>, ICustomerItemRequest, IBaseEntityItemRequest
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember(IsRequired=true)
  public id: string;

  public constructor(init?: Partial<GetMaintenanceVehicleRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new MaintenanceVehicle();
  }
  public getTypeName() {
    return 'GetMaintenanceVehicleRequest';
  }
}

// @Route("/customer/{customerId}/vehicles/exists", "GET")
// @DataContract
export class GetMaintenanceVehicleExistRequest implements IReturn<boolean>, ICustomerItemRequest {
  // @DataMember(IsRequired=true)
  public number: string;

  // @DataMember
  public customerId: string;

  public constructor(init?: Partial<GetMaintenanceVehicleExistRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return false;
  }
  public getTypeName() {
    return 'GetMaintenanceVehicleExistRequest';
  }
}

// @Route("/customer/{customerId}/vehicles", "POST")
// @DataContract
export class CreateMaintenanceVehicleRequest
  implements IReturn<MaintenanceVehicle>, ICustomerItemRequest, IDescription
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public updateEntityGroups: boolean;

  // @DataMember
  public entityGroups: string[];

  // @DataMember(IsRequired=true)
  public entity: MaintenanceVehicle;

  // @DataMember
  public description: string;

  public constructor(init?: Partial<CreateMaintenanceVehicleRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new MaintenanceVehicle();
  }
  public getTypeName() {
    return 'CreateMaintenanceVehicleRequest';
  }
}

// @Route("/customer/{customerId}/vehicle/{id}", "PUT")
// @DataContract
export class UpdateMaintenanceVehicleRequest
  implements
    IReturn<MaintenanceVehicle>,
    ICustomerItemRequest,
    IBaseEntityItemRequest,
    IDescription
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public updateEntityGroups: boolean;

  // @DataMember
  public entityGroups: string[];

  // @DataMember(IsRequired=true)
  public id: string;

  // @DataMember(IsRequired=true)
  public entity: MaintenanceVehicle;

  // @DataMember
  public description: string;

  public constructor(init?: Partial<UpdateMaintenanceVehicleRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new MaintenanceVehicle();
  }
  public getTypeName() {
    return 'UpdateMaintenanceVehicleRequest';
  }
}

// @Route("/customer/{customerId}/vehicles", "DELETE")
// @DataContract
export class DeleteMaintenanceVehiclesRequest
  implements IReturn<number>, ICustomerItemRequest, IDescription
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public description: string;

  // @DataMember(IsRequired=true)
  public ids: string[];

  public constructor(init?: Partial<DeleteMaintenanceVehiclesRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return 0;
  }
  public getTypeName() {
    return 'DeleteMaintenanceVehiclesRequest';
  }
}

// @Route("/customer/{customerId}/glonassTerminals", "GET")
// @DataContract
export class GetMaintenanceVehicleTerminalsRequest
  implements IReturn<GetMaintenanceVehicleTerminalsResponse>, ICustomerItemRequest
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public entityGroups: string[];

  // @DataMember
  public itemsIds: string[];

  // @DataMember
  public onlyOwnedItems: boolean;

  // @DataMember
  public page: number;

  // @DataMember
  public items: number;

  // @DataMember
  public sort: string;

  // @DataMember
  public filter: string;

  public constructor(init?: Partial<GetMaintenanceVehicleTerminalsRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new GetMaintenanceVehicleTerminalsResponse();
  }
  public getTypeName() {
    return 'GetMaintenanceVehicleTerminalsRequest';
  }
}

// @Route("/customer/{customerId}/glonassTerminal/{id}", "GET")
// @DataContract
export class GetMaintenanceVehicleTerminalRequest
  implements IReturn<MaintenanceVehicleTerminal>, ICustomerItemRequest, IBaseEntityItemRequest
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember(IsRequired=true)
  public id: string;

  public constructor(init?: Partial<GetMaintenanceVehicleTerminalRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new MaintenanceVehicleTerminal();
  }
  public getTypeName() {
    return 'GetMaintenanceVehicleTerminalRequest';
  }
}

// @Route("/customer/{customerId}/glonassTerminals/exists", "GET")
// @DataContract
export class GetMaintenanceVehicleTerminalExistRequest
  implements IReturn<boolean>, ICustomerItemRequest
{
  // @DataMember(IsRequired=true)
  public number: string;

  // @DataMember
  public customerId: string;

  public constructor(init?: Partial<GetMaintenanceVehicleTerminalExistRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return false;
  }
  public getTypeName() {
    return 'GetMaintenanceVehicleTerminalExistRequest';
  }
}

// @Route("/customer/{customerId}/glonassTerminals", "POST")
// @DataContract
export class CreateMaintenanceVehicleTerminalRequest
  implements IReturn<MaintenanceVehicleTerminal>, ICustomerItemRequest, IDescription
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public updateEntityGroups: boolean;

  // @DataMember
  public entityGroups: string[];

  // @DataMember(IsRequired=true)
  public entity: MaintenanceVehicleTerminal;

  // @DataMember
  public description: string;

  public constructor(init?: Partial<CreateMaintenanceVehicleTerminalRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new MaintenanceVehicleTerminal();
  }
  public getTypeName() {
    return 'CreateMaintenanceVehicleTerminalRequest';
  }
}

// @Route("/customer/{customerId}/glonassTerminal/{id}", "PUT")
// @DataContract
export class UpdateMaintenanceVehicleTerminalRequest
  implements
    IReturn<MaintenanceVehicleTerminal>,
    ICustomerItemRequest,
    IBaseEntityItemRequest,
    IDescription
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public updateEntityGroups: boolean;

  // @DataMember
  public entityGroups: string[];

  // @DataMember(IsRequired=true)
  public id: string;

  // @DataMember(IsRequired=true)
  public entity: MaintenanceVehicleTerminal;

  // @DataMember
  public description: string;

  public constructor(init?: Partial<UpdateMaintenanceVehicleTerminalRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new MaintenanceVehicleTerminal();
  }
  public getTypeName() {
    return 'UpdateMaintenanceVehicleTerminalRequest';
  }
}

// @Route("/customer/{customerId}/glonassTerminals", "DELETE")
// @DataContract
export class DeleteMaintenanceVehicleTerminalsRequest
  implements IReturn<number>, ICustomerItemRequest, IDescription
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public description: string;

  // @DataMember(IsRequired=true)
  public ids: string[];

  public constructor(init?: Partial<DeleteMaintenanceVehicleTerminalsRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return 0;
  }
  public getTypeName() {
    return 'DeleteMaintenanceVehicleTerminalsRequest';
  }
}

// @Route("/customer/{customerId}/works", "GET")
// @DataContract
export class GetMaintenanceWorksRequest
  implements IReturn<GetMaintenanceWorksResponse>, ICustomerItemRequest
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public entityGroups: string[];

  // @DataMember
  public itemsIds: string[];

  // @DataMember
  public onlyOwnedItems: boolean;

  // @DataMember
  public page: number;

  // @DataMember
  public items: number;

  // @DataMember
  public sort: string;

  // @DataMember
  public filter: string;

  public constructor(init?: Partial<GetMaintenanceWorksRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new GetMaintenanceWorksResponse();
  }
  public getTypeName() {
    return 'GetMaintenanceWorksRequest';
  }
}

// @Route("/customer/{customerId}/work/{id}", "GET")
// @DataContract
export class GetMaintenanceWorkRequest
  implements IReturn<MaintenanceWork>, ICustomerItemRequest, IBaseEntityItemRequest
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember(IsRequired=true)
  public id: string;

  public constructor(init?: Partial<GetMaintenanceWorkRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new MaintenanceWork();
  }
  public getTypeName() {
    return 'GetMaintenanceWorkRequest';
  }
}

// @Route("/customer/{customerId}/works/exists", "GET")
// @DataContract
export class GetMaintenanceWorkExistRequest implements IReturn<boolean>, ICustomerItemRequest {
  // @DataMember(IsRequired=true)
  public name: string;

  // @DataMember
  public customerId: string;

  public constructor(init?: Partial<GetMaintenanceWorkExistRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return false;
  }
  public getTypeName() {
    return 'GetMaintenanceWorkExistRequest';
  }
}

// @Route("/customer/{customerId}/works", "POST")
// @DataContract
export class CreateMaintenanceWorkRequest
  implements IReturn<MaintenanceWork>, ICustomerItemRequest, IDescription
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public updateEntityGroups: boolean;

  // @DataMember
  public entityGroups: string[];

  // @DataMember(IsRequired=true)
  public entity: MaintenanceWork;

  // @DataMember
  public description: string;

  public constructor(init?: Partial<CreateMaintenanceWorkRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new MaintenanceWork();
  }
  public getTypeName() {
    return 'CreateMaintenanceWorkRequest';
  }
}

// @Route("/customer/{customerId}/work/{id}", "PUT")
// @DataContract
export class UpdateMaintenanceWorkRequest
  implements IReturn<MaintenanceWork>, ICustomerItemRequest, IBaseEntityItemRequest, IDescription
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public updateEntityGroups: boolean;

  // @DataMember
  public entityGroups: string[];

  // @DataMember(IsRequired=true)
  public id: string;

  // @DataMember(IsRequired=true)
  public entity: MaintenanceWork;

  // @DataMember
  public description: string;

  public constructor(init?: Partial<UpdateMaintenanceWorkRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new MaintenanceWork();
  }
  public getTypeName() {
    return 'UpdateMaintenanceWorkRequest';
  }
}

// @Route("/customer/{customerId}/works", "DELETE")
// @DataContract
export class DeleteMaintenanceWorksRequest
  implements IReturn<number>, ICustomerItemRequest, IDescription
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public description: string;

  // @DataMember(IsRequired=true)
  public ids: string[];

  public constructor(init?: Partial<DeleteMaintenanceWorksRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return 0;
  }
  public getTypeName() {
    return 'DeleteMaintenanceWorksRequest';
  }
}

// @Route("/healthStatus", "GET")
// @DataContract
export class GetHealthStatusRequest implements IReturn<string> {
  public constructor(init?: Partial<GetHealthStatusRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return '';
  }
  public getTypeName() {
    return 'GetHealthStatusRequest';
  }
}

// @Route("/customer/{customerId}/integration/{customerIntegrationId}/entities", "GET")
// @DataContract
export class GetEntityIntegrationsRequest
  implements
    IReturn<GetEntityIntegrationResponse>,
    ICustomerIntegrationIdRequest,
    ICustomerItemRequest
{
  // @DataMember
  public customerIntegrationId: string;

  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public entityGroups: string[];

  // @DataMember
  public itemsIds: string[];

  // @DataMember
  public onlyOwnedItems: boolean;

  // @DataMember
  public page: number;

  // @DataMember
  public items: number;

  // @DataMember
  public sort: string;

  // @DataMember
  public filter: string;

  public constructor(init?: Partial<GetEntityIntegrationsRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new GetEntityIntegrationResponse();
  }
  public getTypeName() {
    return 'GetEntityIntegrationsRequest';
  }
}

// @Route("/customer/{customerId}/integration/{customerIntegrationId}/entity/{id}", "GET")
// @DataContract
export class GetEntityIntegrationRequest
  implements
    IReturn<EntityIntegration>,
    ICustomerIntegrationIdRequest,
    ICustomerItemRequest,
    IBaseEntityItemRequest
{
  // @DataMember
  public customerIntegrationId: string;

  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember(IsRequired=true)
  public id: string;

  public constructor(init?: Partial<GetEntityIntegrationRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new EntityIntegration();
  }
  public getTypeName() {
    return 'GetEntityIntegrationRequest';
  }
}

// @Route("/customer/{customerId}/integration/{customerIntegrationId}/entities", "POST")
// @DataContract
export class CreateEntityIntegrationRequest
  implements
    IReturn<EntityIntegration>,
    ICustomerIntegrationIdRequest,
    ICustomerItemRequest,
    IDescription
{
  // @DataMember
  public customerIntegrationId: string;

  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public updateEntityGroups: boolean;

  // @DataMember
  public entityGroups: string[];

  // @DataMember(IsRequired=true)
  public entity: EntityIntegration;

  // @DataMember
  public description: string;

  public constructor(init?: Partial<CreateEntityIntegrationRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new EntityIntegration();
  }
  public getTypeName() {
    return 'CreateEntityIntegrationRequest';
  }
}

// @Route("/customer/{customerId}/integration/{customerIntegrationId}/entity/{id}", "PUT")
// @DataContract
export class UpdateEntityIntegrationRequest
  implements
    IReturn<EntityIntegration>,
    ICustomerIntegrationIdRequest,
    ICustomerItemRequest,
    IBaseEntityItemRequest,
    IDescription
{
  // @DataMember
  public customerIntegrationId: string;

  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public updateEntityGroups: boolean;

  // @DataMember
  public entityGroups: string[];

  // @DataMember(IsRequired=true)
  public id: string;

  // @DataMember(IsRequired=true)
  public entity: EntityIntegration;

  // @DataMember
  public description: string;

  public constructor(init?: Partial<UpdateEntityIntegrationRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new EntityIntegration();
  }
  public getTypeName() {
    return 'UpdateEntityIntegrationRequest';
  }
}

// @Route("/entity/{id}/properties", "GET")
// @DataContract
export class GetEntityPropertiesRequest implements IReturn<any>, IBaseEntityItemRequest {
  // @DataMember(IsRequired=true)
  public id: string;

  public constructor(init?: Partial<GetEntityPropertiesRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return {};
  }
  public getTypeName() {
    return 'GetEntityPropertiesRequest';
  }
}

// @Route("/entity/{id}/properties", "PUT")
// @DataContract
export class UpdateEntityPropertiesRequest implements IReturn<any> {
  // @DataMember(IsRequired=true)
  public id: string;

  // @DataMember
  public description: string;

  // @DataMember
  public entity: { [index: string]: string };

  public constructor(init?: Partial<UpdateEntityPropertiesRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return {};
  }
  public getTypeName() {
    return 'UpdateEntityPropertiesRequest';
  }
}

// @Route("/entity/{id}/property/{property}", "GET")
// @DataContract
export class GetEntityPropertyRequest implements IReturn<string> {
  // @DataMember
  public property: string;

  // @DataMember(IsRequired=true)
  public id: string;

  public constructor(init?: Partial<GetEntityPropertyRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return '';
  }
  public getTypeName() {
    return 'GetEntityPropertyRequest';
  }
}

// @Route("/entity/{id}/property/{property}", "PUT")
// @DataContract
export class UpdateEntityPropertyRequest implements IReturn<string> {
  // @DataMember(IsRequired=true)
  public id: string;

  // @DataMember
  public description: string;

  // @DataMember
  public property: string;

  // @DataMember
  public value: string;

  public constructor(init?: Partial<UpdateEntityPropertyRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return '';
  }
  public getTypeName() {
    return 'UpdateEntityPropertyRequest';
  }
}

// @Route("/entities/property/{property}", "GET")
// @DataContract
export class GetEntitiesPropertyRequest implements IReturn<any> {
  // @DataMember
  public property: string;

  // @DataMember
  public filter: string;

  public constructor(init?: Partial<GetEntitiesPropertyRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return {};
  }
  public getTypeName() {
    return 'GetEntitiesPropertyRequest';
  }
}

// @Route("/customer/{customerId}/entity/{Id}/groups", "GET")
// @DataContract
export class GetItemEntityGroupsRequest
  implements IReturn<GetEntityGroupsResponse>, ICustomerItemRequest
{
  // @DataMember
  public id: string;

  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public entityGroups: string[];

  // @DataMember
  public itemsIds: string[];

  // @DataMember
  public onlyOwnedItems: boolean;

  // @DataMember
  public page: number;

  // @DataMember
  public items: number;

  // @DataMember
  public sort: string;

  // @DataMember
  public filter: string;

  public constructor(init?: Partial<GetItemEntityGroupsRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new GetEntityGroupsResponse();
  }
  public getTypeName() {
    return 'GetItemEntityGroupsRequest';
  }
}

// @Route("/entities/remoteRepository/{repositoryId}/data", "GET")
// @DataContract
export class GetEntitiesRequest implements IReturn<GetEntitiesResponse> {
  // @DataMember
  public repositoryId: string;

  // @DataMember
  public modified?: string;

  // @DataMember
  public page: number;

  // @DataMember
  public items: number;

  // @DataMember
  public sort: string;

  // @DataMember
  public filter: string;

  public constructor(init?: Partial<GetEntitiesRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new GetEntitiesResponse();
  }
  public getTypeName() {
    return 'GetEntitiesRequest';
  }
}

/**
 * Sign In
 */
// @Route("/auth")
// @Route("/auth/{provider}")
// @Api(Description="Sign In")
// @DataContract
export class Authenticate implements IReturn<AuthenticateResponse>, IPost {
  /**
   * AuthProvider, e.g. credentials
   */
  // @DataMember(Order=1)
  // @ApiMember(Description="AuthProvider, e.g. credentials")
  public provider: string;

  // @DataMember(Order=2)
  public state: string;

  // @DataMember(Order=3)
  public oauth_token: string;

  // @DataMember(Order=4)
  public oauth_verifier: string;

  // @DataMember(Order=5)
  public userName: string;

  // @DataMember(Order=6)
  public password: string;

  // @DataMember(Order=7)
  public rememberMe?: boolean;

  // @DataMember(Order=9)
  public errorView: string;

  // @DataMember(Order=10)
  public nonce: string;

  // @DataMember(Order=11)
  public uri: string;

  // @DataMember(Order=12)
  public response: string;

  // @DataMember(Order=13)
  public qop: string;

  // @DataMember(Order=14)
  public nc: string;

  // @DataMember(Order=15)
  public cnonce: string;

  // @DataMember(Order=17)
  public accessToken: string;

  // @DataMember(Order=18)
  public accessTokenSecret: string;

  // @DataMember(Order=19)
  public scope: string;

  // @DataMember(Order=20)
  public meta: { [index: string]: string };

  public constructor(init?: Partial<Authenticate>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new AuthenticateResponse();
  }
  public getTypeName() {
    return 'Authenticate';
  }
}
