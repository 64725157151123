import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  ViewChild,
} from '@angular/core';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { TranslateService } from '@ngx-translate/core';
import { SettingsService } from 'projects/msu-its-web-common/src/services/settings.service';

import { LayoutSvgUtils } from 'projects/msu-its-web-tlc/src/dtos/layout';
import { SchemaUtils } from 'projects/msu-its-web-tlc/src/dtos/schema';

import {
  TrafficMovementType,
  TrafficObjectMode,
  TrafficObjectState,
  UsdkSchemaPhaseStep,
  UsdkTrafficObjectStatus,
} from 'projects/msu-its-web-tlc/src/dtos/tlc.dtos';

import { IUsdkTrafficObjectSet, UsdkStateService } from './usdk-state.service';

import { CONTROLLER_STATES } from 'projects/msu-its-web-tlc/src/dtos/enums';
import { getUsdkPhaseName } from 'projects/msu-its-web-tlc/src/dtos/usdk/usdk-plan';

interface IStateTime {
  id: any;
  phase: UsdkSchemaPhaseStep;
  state: TrafficObjectState;
  changeTime: number;
  mainTime: number;
}

@Component({
  selector: 'usdk-state-timelog',
  templateUrl: './usdk-state-timelog.component.html',
  styleUrls: ['./usdk-state-timelog.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UsdkStateTimelogComponent implements AfterViewInit, OnDestroy {
  _destroy = new Subject();

  @Input()
  disabled = false;

  @ViewChild('container', { static: false })
  container: ElementRef;

  status: UsdkTrafficObjectStatus;
  trafficObjectSet: IUsdkTrafficObjectSet;

  stateTimes: IStateTime[] = [];

  get schema() {
    return this.trafficObjectSet.schemaSet.schema;
  }
  get schemaView() {
    return this.trafficObjectSet.schemaSet.schemaView;
  }

  constructor(
    private _stateService: UsdkStateService,
    private _settingsService: SettingsService,
    private _changeDetector: ChangeDetectorRef,
    public translate: TranslateService
  ) {
    this._stateService.trafficObjectSet$
      .pipe(takeUntil(this._destroy))
      .subscribe((trafficObjectSet) => {
        this.trafficObjectSet = trafficObjectSet;
        this._changeDetector.markForCheck();
      });

    this._stateService.status$.pipe(takeUntil(this._destroy)).subscribe((status) => {
      this.status = status;
      this._updateTimeState();
    });
  }

  ngAfterViewInit() {}

  ngOnDestroy() {
    this._destroy.next();
    this._destroy.complete();
  }

  _updateTimeState() {
    if (!this.status?.controllerStatus) {
      return;
    }

    let updateContainerScorll = false;

    const traffic = this.status.controllerStatus.traffic;

    const currentPhase = traffic.currentPhase;
    const currentTime = new Date(this.status.controllerTime).getTime();

    const lastItem = this.stateTimes.length ? this.stateTimes[this.stateTimes.length - 1] : null;

    if (lastItem === null || lastItem.phase?.phaseId !== currentPhase?.phaseId) {
      const previousPhase = traffic.previousPhase;

      if (lastItem != null && previousPhase != null) {
        // update prev
        lastItem.phase = previousPhase;
        this._updateTimeStateItem(lastItem);
      }

      // add new
      const newItem = {
        id: Date.now(),
        phase: currentPhase,
        state: this.status.state,
        mainTime: null,
        changeTime: null,
      };
      this._updateTimeStateItem(newItem, currentTime);
      this.stateTimes.push(newItem);
      updateContainerScorll = true;
    } else {
      // update current
      lastItem.phase = currentPhase;
      this._updateTimeStateItem(lastItem, currentTime);
    }
    this._changeDetector.markForCheck();

    if (updateContainerScorll) {
      this._updateContainerScroll();
    }
  }

  _updateTimeStateItem(item: IStateTime, currentTime?: number) {
    const phase = item.phase;
    if (!phase) return;

    const startTime = new Date(phase.startTime).getTime();
    const endTime = currentTime ? currentTime : new Date(phase.endTime).getTime();

    if (phase.changeIntervalEnd) {
      const changeTimeEnd = new Date(phase.changeIntervalEnd).getTime();
      item.changeTime = this._round(changeTimeEnd - startTime);
      item.mainTime = this._round(endTime - changeTimeEnd);
    } else if (endTime > startTime) {
      item.changeTime = this._round(endTime - startTime);
    }

    // console.log(
    //   JSON.stringify({
    //     changeTime: item.changeTime,
    //     mainTime: item.mainTime,
    //     totalTime: item.changeTime + item.mainTime,
    //   })
    // );
  }

  _updateContainerScroll() {
    setTimeout(() => {
      if (this.container) {
        const element = this.container.nativeElement;
        const newScrollTop = element.scrollHeight - element.clientHeight;
        if (newScrollTop - element.scrollTop <= 90) {
          element.scrollTop = newScrollTop;
        }
      }
    });
  }

  setSvgState(element, item: IStateTime) {
    const schema = this.schema;
    const schemaView = this.schemaView;

    const svg = element.contentDocument;
    const isDark = this._settingsService.darkTheme;

    const movements = item.phase?.lights
      ? SchemaUtils.schemaLightToMovements(schema, item.phase.lights)
      : [];

    LayoutSvgUtils.updateLayoutSchema(svg, schema, schemaView, isDark);
    LayoutSvgUtils.showLayoutMovements(svg, schema, movements);
  }

  getStateName(stateTime: IStateTime) {
    if (stateTime.state === TrafficObjectState.Phase) {
      const phase = this.trafficObjectSet.settings.phases.find(
        (m) => m.id == stateTime.phase.phaseId
      );
      return getUsdkPhaseName(phase, this.translate.currentLang);
    } else {
      const type = CONTROLLER_STATES.find((n) => n.type === stateTime.state);
      return type ? this.translate.instant(type.short) : null;
    }
  }

  reset() {
    this.stateTimes = [];
  }

  _round(value: number) {
    return Math.ceil(Math.floor(value / 100) / 10);
  }

  trackById(index, item) {
    return item.id;
  }
}
