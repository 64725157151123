import {
  Component,
  ChangeDetectionStrategy,
  AfterViewInit,
  OnDestroy,
  ChangeDetectorRef,
  Input,
} from '@angular/core';
import { FormBuilder, Validators, AbstractControl, ValidatorFn } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';

import { BehaviorSubject, Subject, NEVER, Observable } from 'rxjs';
import { catchError, debounceTime, takeUntil } from 'rxjs/operators';

import { TranslateService } from '@ngx-translate/core';
import { DialogService } from 'projects/msu-its-web-common/src/services/dialog.service';
import { FlashService } from 'projects/msu-its-web-common/src/services/flash.service';
import { DetectorService } from '../../services/detector.service';
import { TrafficControllerType } from 'projects/msu-its-web-tlc/src/dtos/tlc.dtos';
import {
  Detector,
  DetectorDevice,
  DetectorDeviceInfo,
  DetectorReplacement,
  ReplacementReason,
} from '../../dtos/tfms.dtos';
import { DetectorDeviceService } from '../../services/detector-device.service';

@Component({
  selector: 'detector-change-device',
  templateUrl: './detector-change-device.component.html',
  styles: [
    `
      :host {
        display: flex;
        flex-direction: column;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DetectorChangeDeviceComponent implements AfterViewInit, OnDestroy {
  _destroy = new Subject();

  @Input()
  model: Detector;

  @Input()
  modelId: String;

  @Input()
  customerId: string;

  searchInputSubject = new Subject<string>();
  controllerListSubject = new BehaviorSubject<DetectorDeviceInfo[]>([]);

  selectedController: DetectorDeviceInfo;

  _equalCurrentValidator: ValidatorFn = (control: AbstractControl) => {
    return this.model && control.value === this.model.detectorDeviceId
      ? { equalCurrent: true }
      : null;
  };
  searchTemp;
  formGroup = this._formBuilder.group({
    newDetectorDeviceId: ['', [Validators.required, this._equalCurrentValidator]],
    oldDetectorDevice: [{ value: '', disabled: true }],
    onlyFreeCheck: [''],
    description: [null, [Validators.required]],
  });

  get newDetectorDeviceId() {
    return this.formGroup.controls['newDetectorDeviceId'];
  }
  get oldDetectorDevice() {
    return this.formGroup.controls['oldDetectorDevice'];
  }
  get description() {
    return this.formGroup.controls['description'];
  }
  get onlyFreeCheck() {
    return this.formGroup.controls['onlyFreeCheck'];
  }


  constructor(
    private _formBuilder: FormBuilder,
    private _flashService: FlashService,
    private _dialog: MatDialogRef<DetectorChangeDeviceComponent>,
    private _detectorDeviceService: DetectorDeviceService,
    private _detectorService: DetectorService,
    private _changeDetector: ChangeDetectorRef,
    public translate: TranslateService
  ) {}

  ngAfterViewInit() {
    this.onlyFreeCheck.setValue(true);
    this.onlyFreeCheck.valueChanges.subscribe(() => {
      this._loadControllers(this.searchTemp);
    });
    this.searchInputSubject
      .pipe(debounceTime(250))
      .pipe(takeUntil(this._destroy))
      .subscribe((search) => {
        this.searchTemp = search;
        this._loadControllers(search);
      });
    this._loadControllers(null);
    this._loadControllersEdit(null);
    const controllers = this.controllerListSubject.getValue();
    this.selectedController = this.model.detectorDevice;
    // this.selectedController = controllers.find((m) => m.id === this.model.detectorDeviceId);
    this.controllerListSubject.next(controllers.filter((m) => m.id != this.selectedController?.id));
    // this.selectedController = controllers.find((m) => m.id === this.model.detectorDeviceId);

    setTimeout(() => this._loadControllers(null));
  }

  ngOnDestroy() {
    this._destroy.next();
    this._destroy.complete();
  }

  _loadControllers(search: string) {
    this._detectorDeviceService
      .getList(this.customerId, search, 11, [''], this.onlyFreeCheck.value)
      .pipe(catchError(() => NEVER))
      .subscribe((result) => {
        this.controllerListSubject.next(
          result.filter((m) => m.id != this.newDetectorDeviceId?.value)
        );
        this._changeDetector.markForCheck();
      });
  }
  _loadControllersEdit(search: string) {
    this._detectorDeviceService
      .getList(this.customerId, search)
      .pipe(catchError(() => NEVER))
      .subscribe((result) => {
        const temp = result.find((m) => m.id === this.model.detectorDeviceId);
        this.oldDetectorDevice.setValue(temp ? temp.prefix + temp.number : '');
        this._changeDetector.markForCheck();
      });
  }

  controllerSelected(controllerId) {
    const controllers = this.controllerListSubject.getValue();
    this.selectedController = controllers.find((m) => m.id === controllerId);
    this.controllerListSubject.next(controllers.filter((m) => m.id != this.selectedController?.id));
  }

  save() {
    if (!this.formGroup.valid) {
      this.formGroup.markAllAsTouched();
      return;
    }
    const modelForSave: DetectorReplacement = {
      detectorId: this.model.id,
      detectorDeviceId: this.newDetectorDeviceId.value,
      description: this.description.value,
      reason: ReplacementReason.Replace,
    } as DetectorReplacement;
    const resultRequest = this._detectorService.setDetectorReplacementRequest(
      this.customerId,
      modelForSave            
    );
    resultRequest.subscribe((result) => {
      this._dialog.close(true);
    });
  }

  close() {
    this._dialog ? this._dialog.close() : window.close();
  }
}
