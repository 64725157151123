import { Injectable } from '@angular/core';

import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import {
  getPageReturn,
  IDataSourceService,
  IPageParams,
  IPageReturn,
  setPageParams,
} from 'projects/msu-its-web-common/src/data/data-source';

import {
  TrackingDevice,
  TrackingDeviceInfo,
  CreateTrackingDeviceRequest,
  DeleteTrackingDevicesRequest,
  GetTrackingDeviceRequest,
  GetTrackingDevicesRequest,
  UpdateTrackingDeviceRequest,
  GetTrackingDeviceExistRequest,
  DisconnectTrackingDeviceRequest,
  TrackingDeviceChangeCustomerRequest,
  GetTrackingDeviceStatusRequest,
  GetTrackingDevicesStatusRequest,
} from '../dtos/tpc.dtos';

import { TpcApiService } from './tpc-api.service';

@Injectable()
export class TrackingDeviceService
  implements IDataSourceService<TrackingDevice, TrackingDeviceInfo>
{
  constructor(private _api: TpcApiService) {}

  getPagedList(
    params: IPageParams,
    customerId: string
  ): Observable<IPageReturn<TrackingDeviceInfo>> {
    if (!customerId) {
      return of(null);
    }

    const request = new GetTrackingDevicesRequest();
    request.customerId = customerId;

    setPageParams(request, params);
    return this._api.get(request).pipe(
      map((m) => {
        return getPageReturn(m);
      })
    );
  }
  getList(customerId: string, filter?: string, onlyFree = false) {
    const request = new GetTrackingDevicesRequest();

    request.customerId = customerId;
    request.page = 0;
    request.items = 0;
    request.sort = 'number';
    request.filter = filter;
    request.onlyFree = onlyFree;

    return this._api.get(request).pipe(
      map((m) => {
        return m.items;
      })
    );
  }

  get(id: string, customerId: string) {
    const request = new GetTrackingDeviceRequest();
    request.id = id;
    request.customerId = customerId;
    return this._api.get(request);
  }
  add(model: TrackingDevice, params: { customerId: string }) {
    const request = new CreateTrackingDeviceRequest();
    request.customerId = params.customerId;
    request.entity = model;
    return this._api.post(request);
  }
  update(model: TrackingDevice, params: { customerId: string }) {
    const request = new UpdateTrackingDeviceRequest();
    request.id = model.id;
    request.entity = model;
    request.customerId = params.customerId;
    return this._api.put(request);
  }
  delete(ids: string[], customerId: string): Observable<number> {
    const request = new DeleteTrackingDevicesRequest();
    request.ids = ids;
    request.customerId = customerId;
    return this._api.delete(request);
  }
  exist(number: string) {
    const request = new GetTrackingDeviceExistRequest();
    request.number = number;
    return this._api.get(request);
  }

  disconnect(id: string, customerId: string) {
    const request = new DisconnectTrackingDeviceRequest();
    request.id = id;
    request.customerId = customerId;
    return this._api.post(request);
  }

  changeCustomer(
    id: string,
    customerId: string,
    params: { newCustomerId: string; description: string }
  ) {
    const request = new TrackingDeviceChangeCustomerRequest();
    request.id = id;
    request.customerId = customerId;
    request.description = params.description;
    request.newCustomerId = params.newCustomerId;
    return this._api.post(request);
  }

  getStatus(id: string, customerId: string) {
    const request = new GetTrackingDeviceStatusRequest();
    request.id = id;
    request.customerId = customerId;
    return this._api.get(request);
  }
  getStatusList(ids: string[], customerId: string) {
    const request = new GetTrackingDevicesStatusRequest();
    request.ids = ids;
    request.items = 0;
    request.customerId = customerId;
    return this._api.get(request);
  }
}
