<div *ngIf="loading" class="backdrop">
  <mat-spinner diameter="36" color="accent"></mat-spinner>
</div>

<div fxLayout="column" style="height: 100%">
  <table-header>
    <ng-container title>
      {{ titleText }}
    </ng-container>
    <ng-container actions>
      <ng-content
        *ngIf="dataSource?.selection.isEmpty()"
        select="[table-layout-actions]"
      ></ng-content>

      <table-search
        *ngIf="showSearch && dataSource?.selection.isEmpty()"
        [disabled]="disabled"
        [dataSource]="dataSource"
      >
      </table-search>

      <div *ngIf="showRefresh && dataSource?.selection.isEmpty()">
        <button
          mat-icon-button
          [disabled]="disabled"
          (click)="dataSource?.reloadData()"
          title="{{ 'COMMON.REFRESH' | translate }}"
          style="margin-right: -8px"
        >
          <mat-icon>loop</mat-icon>
        </button>
      </div>

      <div
        fxLayout
        fxLayoutAlign="center center"
        *ngIf="!dataSource?.selection.isEmpty()"
      >
        {{ "COMMON.SELECTED" | translate }}
        {{ dataSource?.selection.selected.length }}
      </div>

      <button
        *ngIf="showCreate && dataSource?.selection.isEmpty()"
        mat-raised-button
        color="accent"
        [disabled]="disabled"
        (click)="add()"
      >
        <mat-icon>playlist_add</mat-icon>
        {{ addText }}
      </button>

      <button
        *ngIf="showDelete && !dataSource?.selection.isEmpty()"
        mat-raised-button
        color="primary"
        [disabled]="disabled"
        (click)="delete()"
      >
        <mat-icon>delete</mat-icon>
        {{ deleteText }}
      </button>

      <ng-content select="[table-layout-actions-end]"></ng-content>
    </ng-container>
  </table-header>

  <div
    fxLayout="column"
    fxFlex="grow"
    class="mat-elevation-z2"
    style="overflow: auto; overflow: overlay"
  >
    <mat-table [dataSource]="dataSource">
      <ng-container matColumnDef="select">
        <mat-header-cell *matHeaderCellDef>
          <mat-checkbox
            (change)="$event ? dataSource?.selectionAllToggle() : null"
            [checked]="dataSource?.isAllSelected()"
            [indeterminate]="
              dataSource?.selection.hasValue() && !dataSource?.isAllSelected()
            "
          >
          </mat-checkbox>
        </mat-header-cell>
        <mat-cell *matCellDef="let item">
          <mat-checkbox
            (click)="$event.stopPropagation()"
            (change)="
              $event ? dataSource?.selection.toggle(item[idField]) : null
            "
            [checked]="dataSource?.selection.isSelected(item[idField])"
          >
          </mat-checkbox>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="actions">
        <mat-header-cell *matHeaderCellDef></mat-header-cell>
        <mat-cell *matCellDef="let item">
          <button
            mat-icon-button
            #trigger="matMenuTrigger"
            [matMenuTriggerFor]="actionMenu"
            [matMenuTriggerData]="{
              item: item,
              trigger: trigger
            }"
            (click)="$event.stopPropagation(); $event.preventDefault()"
            (mousedown)="$event.stopPropagation(); $event.preventDefault()"
          >
            <mat-icon>more_vert</mat-icon>
          </button>
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row
        *matRowDef="let item; columns: displayedColumns"
        [class.selected]="dataSource?.selection.isSelected(item[idField])"
        (dblclick)="dblClick(item[idField])"
      >
      </mat-row>
    </mat-table>

    <mat-menu #actionMenu>
      <ng-template matMenuContent let-item="item" let-trigger="trigger">
        <ng-container
          *ngIf="getItemPermissions(item, trigger) | async as permissions"
        >
          <ng-container *ngFor="let action of rowActions">
            <button
              mat-menu-item
              [title]="action.name | translate"
              *ngIf="allowItemAction(action, item, permissions)"
              (click)="rowAction(action.action, item.id)"
            >
              <mat-icon *ngIf="action.icon">{{ action.icon }}</mat-icon>
              {{ action.name | translate }}
            </button>
          </ng-container>
        </ng-container>
      </ng-template>
    </mat-menu>

    <mat-paginator
      #paginator
      [class.hidden]="!showPaginator"
      [pageSizeOptions]="[25, 50, 100, 500, 1000]"
      [showFirstLastButtons]="false"
    >
    </mat-paginator>
  </div>
</div>
