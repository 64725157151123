import { Inject, Injectable } from "@angular/core";
import {
  IDataSourceService,
  IPageParams,
  IPageReturn,
  getPageReturn,
  setPageParams,
} from "projects/msu-its-web-common/src/data/data-source";
import { Observable, of } from "rxjs";
import { map } from "rxjs/operators";
import { APP_ENVIRONMENT } from "projects/msu-its-web-common/src/utils/shared-consts";
import { TokenService } from "projects/msu-its-web-common/src/services/token.service";
import { FtpCameraLocation } from "../../components/cameras-ftp/model";
import { fakeModels } from "./db/model";
import { AccidentApiService } from "../accident-api.service";
import {
  CreateStaticCameraImageRequest,
  CreateStaticCameraRequest,
  DeleteStaticCameraImagesRequest,
  DeleteStaticCamerasRequest,
  GetStaticCameraCurrentUserPermissionsRequest,
  GetStaticCameraImageRequest,
  GetStaticCameraImagesRequest,
  GetStaticCameraRequest,
  GetStaticCamerasRequest,
  StaticCamera,
  StaticCameraImage,
  StaticCameraLocation,
  UpdateStaticCameraRequest,
} from "../../dtos/accident.dtos";

@Injectable()
export class CameraFTPService
  implements IDataSourceService<StaticCamera,StaticCamera > {
  constructor(
    private _api: AccidentApiService,
    private _tokenService: TokenService,
    @Inject(APP_ENVIRONMENT) private _appEnv
  ) {}

  getPagedList(
    params: IPageParams,
    customerId: string
  ): Observable<IPageReturn<StaticCamera>> {
    const request = new GetStaticCamerasRequest();
    request.customerId = customerId;
    setPageParams(request, params);
    return this._api.get(request).pipe(
      map((m) => {
        return getPageReturn(m);
      })
    );
  }

  getList(
    customerId: string,
    filter?: string,
    groupIds?: string[]
  ): Observable<StaticCamera[]> {
    const request = new GetStaticCamerasRequest();
    request.customerId = customerId;

    request.entityGroups = groupIds;
    request.page = 0;
    request.items = 0;
    request.sort = "name";
    request.filter = filter;

    return this._api.get(request).pipe(
      map((m) => {
        return m.items;
      })
    );
  }


  get(id: any, customerId: string): Observable<StaticCamera> {
    const request = new GetStaticCameraRequest();
    request.customerId = customerId;
    request.id = id;
    return this._api.get(request);
  }

  add(
    model: StaticCamera,
    params: {
      customerId: string;
      groups: string[];
      updateGroups: boolean;
    }
  ) {
    const request = new CreateStaticCameraRequest();
    request.customerId = params.customerId;
    request.entity = model;
    request.entityGroups = params.groups;
    request.updateEntityGroups = params.updateGroups;
    return this._api.post(request);
  }

  update(
    model: StaticCamera,
    params: {
      customerId: string;
      groups: string[];
      updateGroups: boolean;
    }
  ) {
    const request = new UpdateStaticCameraRequest();
    request.customerId = params.customerId;
    request.id = model.id;
    request.entity = model;
    request.entityGroups = params.groups;
    request.updateEntityGroups = params.updateGroups;
    return this._api.put(request);
  }

  delete(ids: string[], customerId: string): Observable<number> {
    const request = new DeleteStaticCamerasRequest();
    request.customerId = customerId;
    request.ids = ids;
    return this._api.delete(request);
  }

  
  getPermissions(id: string, customerId: string) {
    const request = new GetStaticCameraCurrentUserPermissionsRequest();
    request.id = id;
    request.customerId = customerId;
    return this._api.get(request);
  }


  getImagesRequest(
    params: IPageParams,
    customerId: string
  ): Observable<IPageReturn<StaticCameraImage>> {
    const request = new GetStaticCameraImagesRequest();
    request.customerId = customerId;
    setPageParams(request, params);
    return this._api.get(request).pipe(
      map((m) => {
        return getPageReturn(m);
      })
    );
  }

  getImageRequest(id: any, customerId: string): Observable<StaticCameraImage> {
    const request = new GetStaticCameraImageRequest();
    request.customerId = customerId;
    request.id = id;
    return this._api.get(request);
  }


  createImageRequest(
    model: StaticCameraImage,
    params: {
      customerId: string;
      groups: string[];
      updateGroups: boolean;
    }
  ) {
    const request = new CreateStaticCameraImageRequest();
    request.customerId = params.customerId;
    request.entity = model;
    request.entityGroups = params.groups;
    request.updateEntityGroups = params.updateGroups;
    return this._api.post(request);
  }
  
  deleteImagesRequest(ids: string[], customerId: string): Observable<number> {
    const request = new DeleteStaticCameraImagesRequest();
    request.customerId = customerId;
    request.ids = ids;
    return this._api.delete(request);
  }
}
