import { Injectable } from '@angular/core';

import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import {
  getPageReturn,
  IDataSourceService,
  IPageParams,
  IPageReturn,
  setPageParams,
} from 'projects/msu-its-web-common/src/data/data-source';

import {
  ParkingController,
  ParkingControllerInfo,
  CreateParkingControllerRequest,
  DeleteParkingControllersRequest,
  GetParkingControllerRequest,
  GetParkingControllersRequest,
  UpdateParkingControllerRequest,
  ParkingControllerType,
  GetParkingControllerExistRequest,
  DisconnectParkingControllerRequest,
  ParkingControllerChangeCustomerRequest,
} from '../dtos/parking.dtos';

import { ParkingApiService } from './parking-api.service';

@Injectable()
export class ParkingControllerService
  implements IDataSourceService<ParkingController, ParkingControllerInfo>
{
  constructor(private _api: ParkingApiService) {}

  getPagedList(
    params: IPageParams,
    customerId: string
  ): Observable<IPageReturn<ParkingControllerInfo>> {
    if (!customerId) {
      return of(null);
    }

    const request = new GetParkingControllersRequest();
    request.customerId = customerId;

    setPageParams(request, params);
    return this._api.get(request).pipe(
      map((m) => {
        return getPageReturn(m);
      })
    );
  }
  getList(customerId: string, filter?: string, onlyFree = false) {
    const request = new GetParkingControllersRequest();

    request.customerId = customerId;
    request.page = 0;
    request.items = 0;
    request.sort = 'number';
    request.filter = filter || '';
    request.onlyFree = onlyFree;

    return this._api.get(request).pipe(
      map((m) => {
        return m.items;
      })
    );
  }

  get(id: string, customerId: string) {
    const request = new GetParkingControllerRequest();
    request.id = id;
    request.customerId = customerId;
    return this._api.get(request);
  }
  add(model: ParkingController, params: { customerId: string }) {
    const request = new CreateParkingControllerRequest();
    request.customerId = params.customerId;
    request.entity = model;
    return this._api.post(request);
  }
  update(model: ParkingController, params: { customerId: string }) {
    const request = new UpdateParkingControllerRequest();
    request.id = model.id;
    request.entity = model;
    request.customerId = params.customerId;
    return this._api.put(request);
  }
  delete(ids: string[], customerId: string): Observable<number> {
    const request = new DeleteParkingControllersRequest();
    request.ids = ids;
    request.customerId = customerId;
    return this._api.delete(request);
  }
  exist(type: ParkingControllerType, number: string) {
    const request = new GetParkingControllerExistRequest();
    request.type = type;
    request.number = number;
    return this._api.get(request);
  }

  disconnect(id: string, customerId: string) {
    const request = new DisconnectParkingControllerRequest();
    request.id = id;
    request.customerId = customerId;
    return this._api.post(request);
  }

  changeCustomer(
    id: string,
    customerId: string,
    params: { newCustomerId: string; description: string }
  ) {
    const request = new ParkingControllerChangeCustomerRequest();
    request.id = id;
    request.customerId = customerId;
    request.description = params.description;
    request.newCustomerId = params.newCustomerId;
    return this._api.post(request);
  }
}
