<div #container style="flex: 1; overflow: auto; height: 100%; scroll-behavior: smooth">
  <table>
    <tr>
      <td class="mat-small mat-secondary mat-dialog-bg" style="position: sticky; top: 0; z-index: 1" colspan="2">
        {{ "TRAFFIC_OBJECTS.STATE" | translate }}
        (<a href="#" (click)="reset(); $event.preventDefault()" class="mat-primary-color">{{ "COMMON.RESET" | translate | lowercase }}</a
        >)
      </td>
      <td
        class="mat-small mat-secondary mat-dialog-bg"
        style="position: sticky; top: 0; text-align: center; width: 32px"
        title="{{ 'TRAFFIC_OBJECTS.MAIN_TIME' | translate }}"
      >
        {{ "TRAFFIC_OBJECTS.MAIN_TIME_SHORT" | translate }}
      </td>
      <td
        class="mat-small mat-secondary mat-dialog-bg"
        style="position: sticky; top: 0; text-align: center; width: 32px"
        title="{{ 'TRAFFIC_OBJECTS.CHANGE_TIME' | translate }}"
      >
        {{ "TRAFFIC_OBJECTS.CHANGE_TIME_SHORT" | translate }}
      </td>
      <td
        class="mat-small mat-secondary mat-dialog-bg"
        style="position: sticky; top: 0; text-align: center; width: 32px"
        title="{{ 'TRAFFIC_OBJECTS.TOTAL_TIME' | translate }}"
      >
        {{ "TRAFFIC_OBJECTS.TOTAL_TIME_SHORT" | translate }}
      </td>
    </tr>
    <tr *ngFor="let item of stateTimes; trackBy: trackById; last as last; index as i" [class.mat-accented]="last">
      <td style="width: 10px; padding: 6px">
        <div class="mat-border" style="overflow: hidden; width: 40px; height: 40px; display: inline-block; vertical-align: middle">
          <object
            data="/assets/images/tlc/layout_button.svg"
            type="image/svg+xml"
            (load)="setSvgState($event.target, item.movements)"
            style="width: 44px; height: 44px; margin: -3px 0 0 -3px; pointer-events: none; display: block"
            [style.transform]="'rotate(' + schemaView.angle + 'deg)'"
          ></object>
        </div>
      </td>
      <td>{{ getStateName(item) }}</td>
      <td style="text-align: right; width: 10px">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" class="timer-svg">
          <circle cx="10" cy="10" r="10" fill="#000" opacity="0.5" />
          <text x="10" y="10" dy="3" font-size="9" text-anchor="middle">
            <tspan>{{ item.mainTime }}</tspan>
          </text>
        </svg>
      </td>
      <td style="text-align: right; width: 10px">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" class="timer-svg">
          <circle cx="10" cy="10" r="10" fill="#000" opacity="0.5" />
          <text x="10" y="10" dy="3" font-size="9" text-anchor="middle">
            <tspan>{{ item.changeTime }}</tspan>
          </text>
        </svg>
      </td>
      <td style="text-align: right; width: 10px; padding-right: 12px">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" class="timer-svg">
          <circle cx="10" cy="10" r="10" fill="#000" opacity="0.5" />
          <text x="10" y="10" dy="3" font-size="9" text-anchor="middle">
            <tspan>{{ item.mainTime + item.changeTime }}</tspan>
          </text>
        </svg>
      </td>
    </tr>
  </table>
</div>
