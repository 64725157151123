<div [style.height]="external ? '100%' : '520px'" [style.width]="external ? '100%' : '800px'">
  <div fxLayout="column" style="height: 100%">
    <h3 style="margin: 0; margin-bottom: 8px; font-weight: 500; font-size: 16px; line-height: 1">
      {{ modelInfo.name }}<br />
      <span class="mat-small mat-hint mat-secondary">{{ modelInfo.description }}</span>
    </h3>

    <div *ngIf="loadGraph" class="backdrop" style="z-index: 1">
      <mat-spinner diameter="36" color="accent"></mat-spinner>
    </div>

    <div *ngIf="noData" class="backdrop" style="z-index: 1">
      {{ "COMMON.NO_DATA" | translate }}
    </div>

    <div style="flex: 1; overflow: hidden">
      <mat-tab-group animationDuration="0ms" class="mat-tab-group-sm" style="height: 100%" (selectedTabChange)="changeEvent($event)">
        <mat-tab *ngFor="let item of meteoSensor; let index = index" [label]="index">
          <ng-template mat-tab-label>
            <span>{{ getMeteoStationName(item) | translate }}</span>
          </ng-template>

          <div fxFlexFill fxLayout="column" style="font-size: 14px">
            <div fxLayout="row" fxLayoutAlign="space-between center" style="padding-top: 8px">
              <div fxFlex="initial">
                <mat-form-field floatLabel="always" style="width: 200px">
                  <mat-date-range-input [rangePicker]="picker">
                    <input
                      matStartDate
                      [formControl]="dateFrom"
                      [errorStateMatcher]="rangeErrorStateMatcher"
                      placeholder="{{ 'COMMON.START_DATE' | translate }}"
                    />
                    <input
                      matEndDate
                      [formControl]="dateTo"
                      [errorStateMatcher]="rangeErrorStateMatcher"
                      placeholder="{{ 'COMMON.END_DATE' | translate }}"
                    />
                  </mat-date-range-input>
                  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                  <mat-date-range-picker #picker disabled="false"></mat-date-range-picker>
                </mat-form-field>
              </div>
              <div fxFlex></div>
              <div fxFlex="nogrow">
                <mat-form-field style="width: 250px">
                  <mat-select
                    placeholder="{{ 'COMMON.PARAMETER' | translate }}"
                    disableOptionCentering
                    panelClass="single-select-panel mat-select-md fs-14"
                    [formControl]="meteoParams"
                  >
                    <mat-option *ngFor="let param of item.measureParameters; let indexParam = index" [value]="param.id">
                      {{ getCategoryName(param) | translate }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>

            <ng-container *ngIf="chart">
              <div fxFlex style="overflow: auto; flex: 1">
                <div echarts id="grafHistory_{{ modelId }}" class="chartGraf" (window:resize)="onResize($event)" style="height: 100%"></div>
              </div>
              <div *ngIf="itemGraph" fxLayout="row wrap" fxLayoutGap="24px" style="font-size: 12px; white-space: nowrap">
                <div fxLayout="column" fxLayoutGap="4px">
                  <span>{{ "COMMON.MIN_VALUE" | translate }}:</span>
                  <b>{{ itemGraph.min }} {{ getCategoryUnit(itemGraph.unit) | translate }}</b>
                </div>
                <div fxLayout="column" fxLayoutGap="4px">
                  <span>{{ "COMMON.MAX_VALUE" | translate }}:</span>
                  <b>{{ itemGraph.max }} {{ getCategoryUnit(itemGraph.unit) | translate }}</b>
                </div>
                <div fxLayout="column" fxLayoutGap="4px">
                  <span>{{ "COMMON.AVG_VALUE" | translate }}:</span>
                  <b>{{ itemGraph.avg }} {{ getCategoryUnit(itemGraph.unit) | translate }}</b>
                </div>
                <div fxLayout="column" fxLayoutGap="4px">
                  <span>{{ "COMMON.SD_VALUE" | translate }}:</span>
                  <b>{{ itemGraph.rms }} {{ getCategoryUnit(itemGraph.unit) | translate }}</b>
                </div>
              </div>
            </ng-container>

            <ng-container *ngIf="!chart">
              <div>{{ "COMMON.HISTORY" | translate }}:</div>
              <div fxFlex style="overflow: auto; flex: 1">
                <table class="table">
                  <tr *ngFor="let item of itemTable" class="mat-hover">
                    <td>
                      {{ item?.timeStamp | date: "short":null:locale }}
                    </td>
                    <td>
                      {{ getTableItemName(item?.stringValue) | translate }}
                    </td>
                  </tr>
                </table>
              </div>
            </ng-container>
          </div>
        </mat-tab>
      </mat-tab-group>
    </div>

    <div *ngIf="!external" fxLayout="row" fxLayoutAlign="end center" style="margin-top: 8px; margin-bottom: -8px">
      <button mat-button (click)="cancel()" style="z-index: 10">
        <span>{{ "COMMON.CANCEL" | translate }}</span>
      </button>
    </div>
  </div>
</div>
