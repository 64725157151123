import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';

import { IGroupShareService } from 'projects/msu-its-web-common/src/components/groups/group-shares.component';

import {
  EntityGroupPermissions,
  GetPtzCameraGroupSharesRequest,
  UpdatePtzCameraGroupShareRequest,
} from '../../dtos/accident.dtos';

import { AccidentApiService } from '../accident-api.service';

@Injectable()
export class CameraPTZGroupShareService implements IGroupShareService {
  constructor(private _api: AccidentApiService) {}

  getList(id: string, customerId: string) {
    const request = new GetPtzCameraGroupSharesRequest();
    request.id = id;
    request.customerId = customerId;

    request.items = 0;
    request.page = 0;
    request.sort = 'customerName';

    return this._api.get(request).pipe(map((m) => m.items));
  }

  update(model: EntityGroupPermissions, customerId: string) {
    const request = new UpdatePtzCameraGroupShareRequest();
    request.id = model.entityGroupId;
    request.entity = model;
    request.customerId = customerId;
    return this._api.put(request);
  }
}
