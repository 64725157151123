import { Injectable, Injector } from '@angular/core';
import { ServerEventsClient, appendQueryString } from '@servicestack/client';
import { TokenService } from './token.service';

@Injectable()
export abstract class BaseApiEventService {
  protected _apiUrl: string;

  private _serverEventsClient: ServerEventsClient;
  private _serverEventsCmdReceiver: any;

  private _tokenService: TokenService = this._injector.get(TokenService);

  get apiUrl() {
    return this._apiUrl;
  }

  constructor(private _injector: Injector, apiUrl: string) {
    this._apiUrl = apiUrl;

    this._tokenService.tokenChanged.subscribe(() => {
      if (this._serverEventsClient) {
        this._tokenService.bearerToken
          ? this._serverEventsClient.stopped
            ? this._serverEventsClient.start()
            : this._serverEventsClient.reconnectServerEvents()
          : this._serverEventsClient.stop();
      }
    });
  }

  private _initServerEventClient(
    channels: string[],
    receivers?: { [id: string]: (value: any) => void }
  ) {
    const localApiUrl = localStorage.getItem('API_URL');
    const apiUrl = localApiUrl ? localApiUrl : this.apiUrl;

    this._serverEventsCmdReceiver = { cmd: Object.assign({}, receivers) };
    this._serverEventsClient = new ServerEventsClient(apiUrl, channels, {
      resolveStreamUrl: (url) =>
        appendQueryString(url, { 'ss-tok': this._tokenService.bearerToken }),
      handlers: {},
      receivers: this._serverEventsCmdReceiver,
      onException: (e) => {
        // console.log('-- ServerEvent onException', e);
      },
      onReconnect: (e) => {
        // console.log('-- ServerEvent onReconnect', e);
      },
    });
    this._serverEventsClient.start();
  }

  public subscribeServerEvents(
    channels: string[],
    receivers?: { [id: string]: (value: any) => void }
  ) {
    if (!this._serverEventsClient) {
      this._initServerEventClient(channels, receivers);
    } else {
      // register receivers
      this._serverEventsCmdReceiver.cmd = Object.assign(
        this._serverEventsCmdReceiver.cmd,
        receivers
      );

      // subscribe channnels
      if (this._serverEventsClient.stopped) {
        channels.forEach((m) => {
          if (this._serverEventsClient.channels.indexOf(m) < 0) {
            this._serverEventsClient.channels.push(m);
          }
        });
        this._serverEventsClient.start();
      } else {
        this._serverEventsClient.subscribeToChannels(...channels);
      }
    }
  }

  public unsubscribeServerEvents(
    channels?: string[],
    receivers?: { [id: string]: any }
  ) {
    if (this._serverEventsClient) {
      // unsubscribe channnels
      if (channels) {
        if (this._serverEventsClient.stopped) {
          this._serverEventsClient.channels = this._serverEventsClient.channels.filter(
            (m) => channels.indexOf(m) < 0
          );
        } else {
          this._serverEventsClient.unsubscribeFromChannels(...channels);
        }
      }

      // remove rceivers
      if (receivers) {
        Object.keys(receivers).forEach((m) => {
          delete this._serverEventsCmdReceiver.cmd[m];
        });
      }
    }
  }
}
