import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { environment } from '../environments/environment';

import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthGuard } from 'projects/msu-its-web-common/src/guards/auth.guard';
import { EputsGuard } from 'projects/msu-its-web/src/guards/eputs.guard';

import { EputsComponent } from 'projects/msu-its-web/src/components/eputs/eputs.component';
import { LoginComponent } from 'projects/msu-its-web-common/src/components/login/login.component';

import { SidenavComponent } from 'projects/msu-its-web-common/src/components/sidenav/sidenav.component';
import { ExternalComponent } from 'projects/msu-its-web-common/src/components/external/external.component';

import { ExternalSystemsComponent } from 'projects/msu-its-web-common/src/components/external-systems/external-systems.component';
import { CustomerSelectModalComponent } from 'projects/msu-its-web-common/src/components/customers/customer-select.component';
import { CustomerContainerComponent } from 'projects/msu-its-web-common/src/components/customer-container/customer-container.component';

import { EmptyComponent } from './empty.component';
import { PermissionsGuard } from 'projects/msu-its-web-common/src/guards/permissions.guard';

const tempRoutes = ((environment as any).tempSidenav || []).reduce((result, category) => {
  category.items.forEach((m) => result.push({ path: m.route, component: EmptyComponent }));
  return result;
}, []);

const routes: Routes = [
  {
    path: '',
    redirectTo: 'customers',
    pathMatch: 'full',
  },
  {
    path: 'customers',
    component: CustomerSelectModalComponent,
    pathMatch: 'full',
    canActivate: [EputsGuard],
    data: { title: _('CUSTOMERS.TITLE') },
  },
  {
    path: 'external-systems',
    component: ExternalSystemsComponent,
    pathMatch: 'full',
    canActivate: [EputsGuard, PermissionsGuard],
    data: {
      title: _('EXTERNAL_SYSTEMS.TITLE'),
      permissions: ['system.manage'],
    },
  },

  {
    path: 'eputs',

    children: [
      {
        path: '',
        component: EputsComponent,
        data: { title: _('EPUTS.TITLE') },
      },
      {
        path: '',
        loadChildren: () =>
          import('projects/msu-its-web-tlc/src/modules/tlc-module/tlc-eputs.module').then(
            (m) => m.TlcEputsModule
          ),
      },
      {
        path: '',
        loadChildren: () =>
          import('projects/msu-its-web-tfms/src/modules/tfms-module/tfms-eputs.module').then(
            (m) => m.TfmsEputsModule
          ),
      },
    ],
  },

  {
    path: 'login',
    component: LoginComponent,
    data: { title: _('USERS.SIGN_IN') },
  },

  {
    path: ':customerId',
    component: CustomerContainerComponent,
    canActivate: [AuthGuard],
    children: [
      // modules external routes
      {
        path: 'ext',
        component: ExternalComponent,
        canActivate: [AuthGuard],
        children: [
          // HUB
          {
            path: '',
            loadChildren: () =>
              import('projects/msu-its-web-common/src/modules/hub-module/hub.module').then(
                (m) => m.HubModule
              ),
          },

          // TELEMATIC
          ...(environment.modules.telematic
            ? [
                {
                  path: '',
                  loadChildren: () =>
                    import(
                      'projects/msu-its-web-telematic/src/modules/telematic-module/telematic.module'
                    ).then((m) => m.TelematicModule),
                },
              ]
            : []),

          // TLC
          ...(environment.modules.tlc
            ? [
                {
                  path: '',
                  loadChildren: () =>
                    import('projects/msu-its-web-tlc/src/modules/tlc-module/tlc.module').then(
                      (m) => m.TlcModule
                    ),
                },
              ]
            : []),

          // TSS
          ...(environment.modules.tss
            ? [
                {
                  path: '',
                  loadChildren: () =>
                    import('projects/msu-its-web-tss/src/modules/tss-module/tss.module').then(
                      (m) => m.TssModule
                    ),
                },
              ]
            : []),

          // TFMS
          ...(environment.modules.tfms
            ? [
                {
                  path: '',
                  loadChildren: () =>
                    import('projects/msu-its-web-tfms/src/modules/tfms-module/tfms.module').then(
                      (m) => m.TfmsModule
                    ),
                },
              ]
            : []),

          // Parking
          ...(environment.modules.parking
            ? [
                {
                  path: '',
                  loadChildren: () =>
                    import(
                      'projects/msu-its-web-parking/src/modules/parking-module/parking.module'
                    ).then((m) => m.ParkingModule),
                },
              ]
            : []),

          // GIS
          ...(environment.modules.gis
            ? [
                {
                  path: '',
                  loadChildren: () =>
                    import('projects/msu-its-web-gis/src/modules/gis-module/gis.module').then(
                      (m) => m.GisModule
                    ),
                },
              ]
            : []),

          // METEO
          ...(environment.modules.meteo
            ? [
                {
                  path: '',
                  loadChildren: () =>
                    import('projects/msu-its-web-meteo/src/modules/meteo-module/meteo.module').then(
                      (m) => m.MeteoModule
                    ),
                },
              ]
            : []),

          // PTM
          ...(environment.modules.ptm
            ? [
                {
                  path: '',
                  loadChildren: () =>
                    import('projects/msu-its-web-ptm/src/modules/ptm-module/ptm.module').then(
                      (m) => m.PtmModule
                    ),
                },
              ]
            : []),

          // RUIS
          ...(environment.modules.ruis
            ? [
                {
                  path: '',
                  loadChildren: () =>
                    import('projects/msu-its-web-ruis/src/modules/ruis-module/ruis.module').then(
                      (m) => m.RuisModule
                    ),
                },
              ]
            : []),

          // RMMD
          ...(environment.modules.rmmd
            ? [
                {
                  path: '',
                  loadChildren: () =>
                    import('projects/msu-its-web-rmmd/src/modules/rmmd-module/rmmd.module').then(
                      (m) => m.RmmdModule
                    ),
                },
              ]
            : []),

          // FTV
          ...(environment.modules.ftv
            ? [
                {
                  path: '',
                  loadChildren: () =>
                    import('projects/msu-its-web-ftv/src/modules/ftv-module/ftv.module').then(
                      (m) => m.FtvModule
                    ),
                },
              ]
            : []),

          // Accident
          ...(environment.modules.accident
            ? [
                {
                  path: '',
                  loadChildren: () =>
                    import(
                      'projects/msu-its-web-accident/src/modules/accident-module/accident.module'
                    ).then((m) => m.AccidentModule),
                },
              ]
            : []),

          // DASHBOARD
          ...(environment.modules.dashboard
            ? [
                {
                  path: '',
                  loadChildren: () =>
                    import(
                      'projects/msu-its-web-dashboard/src/modules/dashboard-module/dashboard.module'
                    ).then((m) => m.DashboardModule),
                },
              ]
            : []),
        ],
      },

      // modules main routes
      {
        path: '',
        component: SidenavComponent,
        canActivate: [AuthGuard],
        children: [
          {
            path: '',
            pathMatch: 'full',
            redirectTo: environment.modules.gis ? 'map' : 'users',
          },
          // HUB
          {
            path: '',
            loadChildren: () =>
              import('projects/msu-its-web-common/src/modules/hub-module/hub.module').then(
                (m) => m.HubModule
              ),
          },

          // TELEMATIC
          ...(environment.modules.telematic
            ? [
                {
                  path: '',
                  loadChildren: () =>
                    import(
                      'projects/msu-its-web-telematic/src/modules/telematic-module/telematic.module'
                    ).then((m) => m.TelematicModule),
                },
              ]
            : []),

          // TLC
          ...(environment.modules.tlc
            ? [
                {
                  path: '',
                  loadChildren: () =>
                    import('projects/msu-its-web-tlc/src/modules/tlc-module/tlc.module').then(
                      (m) => m.TlcModule
                    ),
                },
              ]
            : []),

          // TSS
          ...(environment.modules.tss
            ? [
                {
                  path: '',
                  loadChildren: () =>
                    import('projects/msu-its-web-tss/src/modules/tss-module/tss.module').then(
                      (m) => m.TssModule
                    ),
                },
              ]
            : []),

          // TFMS
          ...(environment.modules.tfms
            ? [
                {
                  path: '',
                  loadChildren: () =>
                    import('projects/msu-its-web-tfms/src/modules/tfms-module/tfms.module').then(
                      (m) => m.TfmsModule
                    ),
                },
              ]
            : []),

          // Parking
          ...(environment.modules.parking
            ? [
                {
                  path: '',
                  loadChildren: () =>
                    import(
                      'projects/msu-its-web-parking/src/modules/parking-module/parking.module'
                    ).then((m) => m.ParkingModule),
                },
              ]
            : []),

          // GIS
          ...(environment.modules.gis
            ? [
                {
                  path: '',
                  loadChildren: () =>
                    import('projects/msu-its-web-gis/src/modules/gis-module/gis.module').then(
                      (m) => m.GisModule
                    ),
                },
              ]
            : []),

          // METEO
          ...(environment.modules.meteo
            ? [
                {
                  path: '',
                  loadChildren: () =>
                    import('projects/msu-its-web-meteo/src/modules/meteo-module/meteo.module').then(
                      (m) => m.MeteoModule
                    ),
                },
              ]
            : []),

          // PTM
          ...(environment.modules.ptm
            ? [
                {
                  path: '',
                  loadChildren: () =>
                    import('projects/msu-its-web-ptm/src/modules/ptm-module/ptm.module').then(
                      (m) => m.PtmModule
                    ),
                },
              ]
            : []),

          // RUIS
          ...(environment.modules.ruis
            ? [
                {
                  path: '',
                  loadChildren: () =>
                    import('projects/msu-its-web-ruis/src/modules/ruis-module/ruis.module').then(
                      (m) => m.RuisModule
                    ),
                },
              ]
            : []),

          // RMMD
          ...(environment.modules.rmmd
            ? [
                {
                  path: '',
                  loadChildren: () =>
                    import('projects/msu-its-web-rmmd/src/modules/rmmd-module/rmmd.module').then(
                      (m) => m.RmmdModule
                    ),
                },
              ]
            : []),

          // FTV
          ...(environment.modules.ftv
            ? [
                {
                  path: '',
                  loadChildren: () =>
                    import('projects/msu-its-web-ftv/src/modules/ftv-module/ftv.module').then(
                      (m) => m.FtvModule
                    ),
                },
              ]
            : []),

          // accident
          ...(environment.modules.accident
            ? [
                {
                  path: '',
                  loadChildren: () =>
                    import(
                      'projects/msu-its-web-accident/src/modules/accident-module/accident.module'
                    ).then((m) => m.AccidentModule),
                },
              ]
            : []),

          // modelling
          ...(environment.modules.modelling
            ? [
                {
                  path: '',
                  loadChildren: () =>
                    import(
                      'projects/msu-its-web-modelling/src/modules/modelling-module/modelling.module'
                    ).then((m) => m.ModellingModule),
                },
              ]
            : []),

          // DASHBOARD
          ...(environment.modules.dashboard
            ? [
                {
                  path: '',
                  loadChildren: () =>
                    import(
                      'projects/msu-its-web-dashboard/src/modules/dashboard-module/dashboard.module'
                    ).then((m) => m.DashboardModule),
                },
              ]
            : []),

          // TEMP ROUTES
          ...tempRoutes,
        ],
      },
    ],
  },

  { path: '**', redirectTo: 'login' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { paramsInheritanceStrategy: 'always' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
