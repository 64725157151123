import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';

import { NgModule } from '@angular/core';

import { GisApiService } from '../../services/gis-api.service';
import { GisApiEventService } from '../../services/gis-api-event.service';
import { GeoObjectService } from '../../services/geo-object.service';
import { GeoObjectTypeService } from '../../services/geo-object-type.service';
import { GeoObjectGroupService } from '../../services/geo-object-group.service';
import { PointToPointRouteService } from '../../services/route.service';

import { ISidenavMenuCategory } from 'projects/msu-its-web-common/src/utils/sidenav-menu';
import {
  GIS_OBJECT_GROUP,
  SIDENAV_MENU,
} from 'projects/msu-its-web-common/src/utils/shared-consts';
import { IGisObjectGroup } from 'projects/msu-its-web-common/src/utils/gis-object-group';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

const sidenav: ISidenavMenuCategory[] = [
  {
    name: _('SIDENAV_MENU.GIS'),
    items: [
      {
        name: _('GIS.MAP'),
        icon: 'map',
        route: 'map',
        permissions: [],
      },
      {
        name: _('GEO_OBJECTS.TITLE'),
        icon: 'place',
        route: 'geo-objects',
        permissions: ['geoObject.read'],
      },
    ],
  },
];

const geoObjectGroup: IGisObjectGroup = {
  type: 'geoObject',
  name: _('GEO_OBJECTS.TITLE'),
  groupServiceType: GeoObjectGroupService,
  // eventServiceType: GeoObjectGroupEventService,
  // stateComponentType: GeoObjectStateComponent,
};

@NgModule({
  providers: [
    { provide: SIDENAV_MENU, useValue: sidenav, multi: true },
    { provide: GIS_OBJECT_GROUP, useValue: geoObjectGroup, multi: true },
    GisApiService,
    GisApiEventService,
    GeoObjectService,
    GeoObjectTypeService,
    GeoObjectGroupService,
    PointToPointRouteService,
  ],
})
export class GisSharedModule {
  constructor(private _matIconRegistry: MatIconRegistry, private _domSanitizer: DomSanitizer) {
    this._registerIcon('gis-line', '/assets/images/gis/icons/line.svg');
    this._registerIcon('gis-polygon', '/assets/images/gis/icons/polygon.svg');
    this._registerIcon('gis-point', '/assets/images/gis/icons/point.svg');
  }

  private _registerIcon(name: string, url: string) {
    this._matIconRegistry.addSvgIcon(name, this._domSanitizer.bypassSecurityTrustResourceUrl(url));
  }
}
