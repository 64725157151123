import {
  MAJOR_DISABLE_MOVEMENTS,
  MINOR_DISABLE_MOVEMENTS,
  SCHEMA_STATIC_CONFLICTS,
  SCHEMA_STATIC_DEADEND_EXCLUDE_CONFLICTS,
  SCHEMA_STREET_CONFLICTS,
} from "./enums";

import {
  TrafficSchema,
  TrafficSchemaViewSettings,
  TrafficMovementType,
  TrafficWayMovement,
  PotokBarrierType,
  TrafficObjectSchemaSet,
  TrafficSchemaLight,
  TrafficMovementLight,
  PotokSchemaLight,
  PotokMovementLight,
} from "./tlc.dtos";

export class Schema extends TrafficSchema {
  way2 = {
    straight: {
      enabled: true,
      deadEnd: false,
      lanesIn: 0,
      lanesOut: 0,
      laneWidth: 0,
      tMin: 10,
    },
    left: {
      enabled: true,
      controlled: true,
      lanes: 0,
      lanesProtected: 0,
      tMin: 10,
    },
    right: {
      enabled: true,
      controlled: true,
      lanes: 0,
      lanesProtected: 0,
      tMin: 10,
    },
    pedestrian: {
      enabled: true,
      zebraWidth: 0,
      zebraCrossOffset: 0,
      tMin: 10,
    },
  };

  way4 = {
    straight: {
      enabled: true,
      deadEnd: false,
      lanesIn: 0,
      lanesOut: 0,
      laneWidth: 0,
      tMin: 10,
    },
    left: {
      enabled: true,
      controlled: true,
      lanes: 0,
      lanesProtected: 0,
      tMin: 10,
    },
    right: {
      enabled: true,
      controlled: true,
      lanes: 0,
      lanesProtected: 0,
      tMin: 10,
    },
    pedestrian: {
      enabled: true,
      zebraWidth: 0,
      zebraCrossOffset: 0,
      tMin: 10,
    },
  };

  way6 = {
    straight: {
      enabled: true,
      deadEnd: false,
      lanesIn: 0,
      lanesOut: 0,
      laneWidth: 0,
      tMin: 10,
    },
    left: {
      enabled: true,
      controlled: true,
      lanes: 0,
      lanesProtected: 0,
      tMin: 10,
    },
    right: {
      enabled: true,
      controlled: true,
      lanes: 0,
      lanesProtected: 0,
      tMin: 10,
    },
    pedestrian: {
      enabled: true,
      zebraWidth: 0,
      zebraCrossOffset: 0,
      tMin: 10,
    },
  };

  way8 = {
    straight: {
      enabled: true,
      deadEnd: false,
      lanesIn: 0,
      lanesOut: 0,
      laneWidth: 0,
      tMin: 10,
    },
    left: {
      enabled: true,
      controlled: true,
      lanes: 0,
      lanesProtected: 0,
      tMin: 10,
    },
    right: {
      enabled: true,
      controlled: true,
      lanes: 0,
      lanesProtected: 0,
      tMin: 10,
    },
    pedestrian: {
      enabled: true,
      zebraWidth: 0,
      zebraCrossOffset: 0,
      tMin: 10,
    },
  };

  get Move2() {
    return this.way2.straight;
  }
  get Move4() {
    return this.way4.straight;
  }
  get Move6() {
    return this.way6.straight;
  }
  get Move8() {
    return this.way8.straight;
  }

  get Move12() {
    return this.way2.right;
  }
  get Move14() {
    return this.way4.right;
  }
  get Move16() {
    return this.way6.right;
  }
  get Move18() {
    return this.way8.right;
  }

  get MoveP2() {
    return this.way2.pedestrian;
  }
  get MoveP4() {
    return this.way4.pedestrian;
  }
  get MoveP6() {
    return this.way6.pedestrian;
  }
  get MoveP8() {
    return this.way8.pedestrian;
  }

  get Move1() {
    return this.way6.left;
  }
  get Move3() {
    return this.way8.left;
  }
  get Move5() {
    return this.way2.left;
  }
  get Move7() {
    return this.way4.left;
  }

  getWay2Enabled() {
    return (
      this.Move2.enabled ||
      (this.Move6.enabled && !this.Move6.deadEnd) ||
      this.Move3.enabled ||
      this.Move14.enabled
    );
  }
  getWay4Enabled() {
    return (
      this.Move4.enabled ||
      (this.Move8.enabled && !this.Move8.deadEnd) ||
      this.Move5.enabled ||
      this.Move16.enabled
    );
  }
  getWay6Enabled() {
    return (
      this.Move6.enabled ||
      (this.Move2.enabled && !this.Move2.deadEnd) ||
      this.Move7.enabled ||
      this.Move18.enabled
    );
  }
  getWay8Enabled() {
    return (
      this.Move8.enabled ||
      (this.Move4.enabled && !this.Move4.deadEnd) ||
      this.Move1.enabled ||
      this.Move12.enabled
    );
  }

  getWay2Twoways() {
    return (
      this.Move2.enabled &&
      ((this.Move6.enabled && !this.Move6.deadEnd) ||
        this.Move3.enabled ||
        this.Move14.enabled)
    );
  }
  getWay4Twoways() {
    return (
      this.Move4.enabled &&
      ((this.Move8.enabled && !this.Move8.deadEnd) ||
        this.Move5.enabled ||
        this.Move16.enabled)
    );
  }
  getWay6Twoways() {
    return (
      this.Move6.enabled &&
      ((this.Move2.enabled && !this.Move2.deadEnd) ||
        this.Move7.enabled ||
        this.Move18.enabled)
    );
  }
  getWay8Twoways() {
    return (
      this.Move8.enabled &&
      ((this.Move4.enabled && !this.Move4.deadEnd) ||
        this.Move1.enabled ||
        this.Move12.enabled)
    );
  }

  getMoveP2Enabled() {
    return (
      this.MoveP2.enabled &&
      (this.Move8.enabled ||
        this.Move18.enabled ||
        this.Move3.enabled ||
        (this.Move4.enabled && !this.Move4.deadEnd) ||
        this.Move12.enabled)
    );
  }
  getMoveP4Enabled() {
    return (
      this.MoveP4.enabled &&
      (this.Move2.enabled ||
        this.Move12.enabled ||
        this.Move5.enabled ||
        (this.Move6.enabled && !this.Move6.deadEnd) ||
        this.Move14.enabled)
    );
  }
  getMoveP6Enabled() {
    return (
      this.MoveP6.enabled &&
      (this.Move4.enabled ||
        this.Move14.enabled ||
        this.Move7.enabled ||
        (this.Move8.enabled && !this.Move8.deadEnd) ||
        this.Move16.enabled)
    );
  }
  getMoveP8Enabled() {
    return (
      this.MoveP8.enabled &&
      (this.Move6.enabled ||
        this.Move16.enabled ||
        this.Move1.enabled ||
        (this.Move2.enabled && !this.Move2.deadEnd) ||
        this.Move18.enabled)
    );
  }

  getIsPedestrian() {
    return (
      !this.getWay4Enabled() &&
      !this.getWay8Enabled() &&
      (this.getWay2Enabled() || this.getWay6Enabled())
    );
  }

  updateDeadEnds() {
    if (!this.Move2.enabled) {
      this.Move12.enabled = false;
      this.Move5.enabled = false;
    }

    if (!this.Move4.enabled) {
      this.Move14.enabled = false;
      this.Move7.enabled = false;
    }

    if (!this.Move6.enabled) {
      this.Move16.enabled = false;
      this.Move1.enabled = false;
    }

    if (!this.Move8.enabled) {
      this.Move18.enabled = false;
      this.Move3.enabled = false;
    }

    if (!this.Move2.enabled || !(this.Move12.enabled || this.Move5.enabled)) {
      this.Move2.deadEnd = false;
    }
    if (!this.Move4.enabled || !(this.Move14.enabled || this.Move7.enabled)) {
      this.Move4.deadEnd = false;
    }
    if (!this.Move6.enabled || !(this.Move16.enabled || this.Move1.enabled)) {
      this.Move6.deadEnd = false;
    }
    if (!this.Move8.enabled || !(this.Move18.enabled || this.Move3.enabled)) {
      this.Move8.deadEnd = false;
    }
  }

  getWaysByNumber(
    wayNumber: number
  ): {
    currentWay: TrafficWayMovement;
    oppositeWay: TrafficWayMovement;
  } {
    switch (wayNumber) {
      case 2:
        return {
          currentWay: this.way2,
          oppositeWay: this.way6,
        };
      case 4:
        return {
          currentWay: this.way4,
          oppositeWay: this.way8,
        };
      case 6:
        return {
          currentWay: this.way6,
          oppositeWay: this.way2,
        };
      case 8:
        return {
          currentWay: this.way8,
          oppositeWay: this.way2,
        };
      default:
        return null;
    }
  }
}

export class SchemaViewSettings extends TrafficSchemaViewSettings {
  public majorStreet = "";
  public minorStreet = "";

  public way2 = "";
  public way6 = "";
  public way4 = "";
  public way8 = "";

  public lat = 0;
  public lon = 0;
  public zoom = 5;

  public angle = 0;
  public angle2 = 0;
  public angle4 = 0;
  public angle6 = 0;
  public angle8 = 0;
}

export class SchemaSet extends TrafficObjectSchemaSet {
  schema = new Schema();
  schemaView = new SchemaViewSettings();
}

export class SchemaUtils {
  public static schemaViewMovements(schema: Schema, move: TrafficMovementType) {
    if (
      schema.getIsPedestrian() &&
      (move == TrafficMovementType.MoveP4 || move == TrafficMovementType.MoveP8)
    ) {
      return ["MoveP"];
    }

    const result: string[] = [];
    switch (move) {
      case TrafficMovementType.Move2:
        if (schema.Move2.enabled && !schema.Move2.deadEnd) {
          result.push(TrafficMovementType.Move2);
        }
        if (schema.Move5.enabled && !schema.Move5.controlled) {
          result.push(TrafficMovementType.Move5);
        }
        if (schema.Move12.enabled && !schema.Move12.controlled) {
          result.push(TrafficMovementType.Move12);
        }
        return result;
      case TrafficMovementType.Move4:
        if (schema.Move4.enabled && !schema.Move4.deadEnd) {
          result.push(TrafficMovementType.Move4);
        }
        if (schema.Move7.enabled && !schema.Move7.controlled) {
          result.push(TrafficMovementType.Move7);
        }
        if (schema.Move14.enabled && !schema.Move14.controlled) {
          result.push(TrafficMovementType.Move14);
        }
        return result;
      case TrafficMovementType.Move6:
        if (schema.Move6.enabled && !schema.Move6.deadEnd) {
          result.push(TrafficMovementType.Move6);
        }
        if (schema.Move1.enabled && !schema.Move1.controlled) {
          result.push(TrafficMovementType.Move1);
        }
        if (schema.Move16.enabled && !schema.Move16.controlled) {
          result.push(TrafficMovementType.Move16);
        }
        return result;
      case TrafficMovementType.Move8:
        if (schema.Move8.enabled && !schema.Move8.deadEnd) {
          result.push(TrafficMovementType.Move8);
        }
        if (schema.Move3.enabled && !schema.Move3.controlled) {
          result.push(TrafficMovementType.Move3);
        }
        if (schema.Move18.enabled && !schema.Move18.controlled) {
          result.push(TrafficMovementType.Move18);
        }
        return result;
      default:
        result.push(move);
        return result;
    }
  }

  public static schemaStaticConflicts(schema: Schema) {
    const conflicts = SCHEMA_STATIC_CONFLICTS;
    const result = conflicts.reduce((result, item) => {
      result[item.type] = [...item.conflicts];
      return result;
    }, {});

    if (!schema.Move1.controlled) {
      const notCoflict = [
        TrafficMovementType.Move2,
        TrafficMovementType.Move12,
      ];
      updateNotConflicts(result, TrafficMovementType.Move1, notCoflict);
    }

    if (!schema.Move3.controlled) {
      const notCoflict = [
        TrafficMovementType.Move4,
        TrafficMovementType.Move14,
      ];
      updateNotConflicts(result, TrafficMovementType.Move3, notCoflict);
    }

    if (!schema.Move5.controlled) {
      const notCoflict = [
        TrafficMovementType.Move6,
        TrafficMovementType.Move16,
      ];
      updateNotConflicts(result, TrafficMovementType.Move5, notCoflict);
    }

    if (!schema.Move7.controlled) {
      const notCoflict = [
        TrafficMovementType.Move8,
        TrafficMovementType.Move18,
      ];
      updateNotConflicts(result, TrafficMovementType.Move7, notCoflict);
    }

    if (schema.Move2.deadEnd) {
      const notControlled = [];
      !schema.Move5.controlled && notControlled.push(TrafficMovementType.Move5);
      !schema.Move12.controlled &&
        notControlled.push(TrafficMovementType.Move12);
      updateDeadEndConflicts(result, TrafficMovementType.Move2, notControlled);
    }

    if (schema.Move6.deadEnd) {
      const notControlled = [];
      !schema.Move1.controlled && notControlled.push(TrafficMovementType.Move1);
      !schema.Move16.controlled &&
        notControlled.push(TrafficMovementType.Move16);
      updateDeadEndConflicts(result, TrafficMovementType.Move6, notControlled);
    }

    if (schema.Move4.deadEnd) {
      const notControlled = [];
      !schema.Move7.controlled && notControlled.push(TrafficMovementType.Move7);
      !schema.Move14.controlled &&
        notControlled.push(TrafficMovementType.Move14);
      updateDeadEndConflicts(result, TrafficMovementType.Move4, notControlled);
    }

    if (schema.Move8.deadEnd) {
      const notControlled = [];
      !schema.Move3.controlled && notControlled.push(TrafficMovementType.Move3);
      !schema.Move18.controlled &&
        notControlled.push(TrafficMovementType.Move18);
      updateDeadEndConflicts(result, TrafficMovementType.Move8, notControlled);
    }

    return result;
  }

  public static schemaStreetConflicts() {
    const conflicts = SCHEMA_STREET_CONFLICTS;
    const result = conflicts.reduce((result, item) => {
      result[item.type] = [...item.conflicts];
      return result;
    }, {});
    return result;
  }

  public static schemaDisableMovements(
    schema: Schema,
    barrierType: PotokBarrierType
  ) {
    let result: TrafficMovementType[] = [];
    let exclude: TrafficMovementType[] = [];
    switch (barrierType) {
      case PotokBarrierType.MajorStreet:
        result = [...MAJOR_DISABLE_MOVEMENTS];
        schema.Move2.deadEnd && exclude.push(TrafficMovementType.MoveP8);
        schema.Move6.deadEnd && exclude.push(TrafficMovementType.MoveP4);
        schema.Move1.controlled && exclude.push(TrafficMovementType.Move18);
        schema.Move5.controlled && exclude.push(TrafficMovementType.Move14);
        break;
      case PotokBarrierType.MinorStreet:
        result = [...MINOR_DISABLE_MOVEMENTS];
        schema.Move4.deadEnd && exclude.push(TrafficMovementType.MoveP2);
        schema.Move8.deadEnd && exclude.push(TrafficMovementType.MoveP6);
        schema.Move3.controlled && exclude.push(TrafficMovementType.Move12);
        schema.Move7.controlled && exclude.push(TrafficMovementType.Move16);
        break;
      default:
        result = [];
        break;
    }
    return result.filter((m) => !exclude.includes(m));
  }

  public static schemaLightToMovements(
    schema: Schema,
    state: TrafficSchemaLight | PotokSchemaLight
  ): TrafficMovementType[] {
    const result: TrafficMovementType[] = [];
    if (schema.Move1.enabled) {
      const move = schema.Move1.controlled ? state.move1 : state.move6;
      if (SchemaUtils._isMoveLight(move)) {
        result.push(TrafficMovementType.Move1);
      }
    }
    if (schema.Move3.enabled) {
      const move = schema.Move3.controlled ? state.move3 : state.move8;
      if (SchemaUtils._isMoveLight(move)) {
        result.push(TrafficMovementType.Move3);
      }
    }
    if (schema.Move5.enabled) {
      const move = schema.Move5.controlled ? state.move5 : state.move2;
      if (SchemaUtils._isMoveLight(move)) {
        result.push(TrafficMovementType.Move5);
      }
    }
    if (schema.Move7.enabled) {
      const move = schema.Move7.controlled ? state.move7 : state.move4;
      if (SchemaUtils._isMoveLight(move)) {
        result.push(TrafficMovementType.Move7);
      }
    }
    if (schema.Move12.enabled) {
      const move =
        // schema.Move3.enabled && schema.Move3.controlled &&
        schema.Move12.controlled ? state.move12 : state.move2;
      if (SchemaUtils._isMoveLight(move)) {
        result.push(TrafficMovementType.Move12);
      }
    }
    if (schema.Move14.enabled) {
      const move =
        // schema.Move5.enabled && schema.Move5.controlled &&
        schema.Move14.controlled ? state.move14 : state.move4;
      if (SchemaUtils._isMoveLight(move)) {
        result.push(TrafficMovementType.Move14);
      }
    }
    if (schema.Move16.enabled) {
      const move =
        // schema.Move7.enabled && schema.Move7.controlled &&
        schema.Move16.controlled ? state.move16 : state.move6;
      if (SchemaUtils._isMoveLight(move)) {
        result.push(TrafficMovementType.Move16);
      }
    }
    if (schema.Move18.enabled) {
      const move =
        // schema.Move1.enabled && schema.Move1.controlled &&
        schema.Move18.controlled ? state.move18 : state.move8;
      if (SchemaUtils._isMoveLight(move)) {
        result.push(TrafficMovementType.Move18);
      }
    }
    if (
      schema.Move2.enabled &&
      !schema.Move2.deadEnd &&
      SchemaUtils._isMoveLight(state.move2)
    ) {
      result.push(TrafficMovementType.Move2);
    }
    if (
      schema.Move4.enabled &&
      !schema.Move4.deadEnd &&
      SchemaUtils._isMoveLight(state.move4)
    ) {
      result.push(TrafficMovementType.Move4);
    }
    if (
      schema.Move6.enabled &&
      !schema.Move6.deadEnd &&
      SchemaUtils._isMoveLight(state.move6)
    ) {
      result.push(TrafficMovementType.Move6);
    }
    if (
      schema.Move8.enabled &&
      !schema.Move8.deadEnd &&
      SchemaUtils._isMoveLight(state.move8)
    ) {
      result.push(TrafficMovementType.Move8);
    }
    if (schema.MoveP2.enabled && SchemaUtils._isMoveLight(state.moveP2)) {
      result.push(TrafficMovementType.MoveP2);
    }
    if (schema.MoveP4.enabled && SchemaUtils._isMoveLight(state.moveP4)) {
      result.push(TrafficMovementType.MoveP4);
    }
    if (schema.MoveP6.enabled && SchemaUtils._isMoveLight(state.moveP6)) {
      result.push(TrafficMovementType.MoveP6);
    }
    if (schema.MoveP8.enabled && SchemaUtils._isMoveLight(state.moveP8)) {
      result.push(TrafficMovementType.MoveP8);
    }
    return result;
  }

  private static _isMoveLight(
    light: TrafficMovementLight | PotokMovementLight
  ) {
    return (
      light === TrafficMovementLight.Green ||
      light === TrafficMovementLight.GreenBlink
    );
  }

  public static predefinedSchemas: any = {
    protected: (): Partial<Schema> => {
      return {
        way2: {
          straight: {
            enabled: true,
            deadEnd: false,
            laneWidth: 0,
            lanesIn: 0,
            lanesOut: 0,
            tMin: 10,
          },
          left: {
            enabled: true,
            controlled: true,
            lanes: 0,
            lanesProtected: 0,
            tMin: 10,
          },
          right: {
            enabled: true,
            controlled: true,
            lanes: 0,
            lanesProtected: 0,
            tMin: 10,
          },
          pedestrian: {
            enabled: true,
            zebraWidth: 0,
            zebraCrossOffset: 0,
            tMin: 10,
          },
        },
        way4: {
          straight: {
            enabled: true,
            deadEnd: false,
            laneWidth: 0,
            lanesIn: 0,
            lanesOut: 0,
            tMin: 10,
          },
          left: {
            enabled: true,
            controlled: true,
            lanes: 0,
            lanesProtected: 0,
            tMin: 10,
          },
          right: {
            enabled: true,
            controlled: true,
            lanes: 0,
            lanesProtected: 0,
            tMin: 10,
          },
          pedestrian: {
            enabled: true,
            zebraWidth: 0,
            zebraCrossOffset: 0,
            tMin: 10,
          },
        },
        way6: {
          straight: {
            enabled: true,
            deadEnd: false,
            laneWidth: 0,
            lanesIn: 0,
            lanesOut: 0,
            tMin: 10,
          },
          left: {
            enabled: true,
            controlled: true,
            lanes: 0,
            lanesProtected: 0,
            tMin: 10,
          },
          right: {
            enabled: true,
            controlled: true,
            lanes: 0,
            lanesProtected: 0,
            tMin: 10,
          },
          pedestrian: {
            enabled: true,
            zebraWidth: 0,
            zebraCrossOffset: 0,
            tMin: 10,
          },
        },
        way8: {
          straight: {
            enabled: true,
            deadEnd: false,
            laneWidth: 0,
            lanesIn: 0,
            lanesOut: 0,
            tMin: 10,
          },
          left: {
            enabled: true,
            controlled: true,
            lanes: 0,
            lanesProtected: 0,
            tMin: 10,
          },
          right: {
            enabled: true,
            controlled: true,
            lanes: 0,
            lanesProtected: 0,
            tMin: 10,
          },
          pedestrian: {
            enabled: true,
            zebraWidth: 0,
            zebraCrossOffset: 0,
            tMin: 10,
          },
        },
      };
    },
    permitted: (): Partial<Schema> => {
      return {
        way2: {
          straight: {
            enabled: true,
            deadEnd: false,
            laneWidth: 0,
            lanesIn: 0,
            lanesOut: 0,
            tMin: 10,
          },
          left: {
            enabled: true,
            controlled: false,
            lanes: 0,
            lanesProtected: 0,
            tMin: 10,
          },
          right: {
            enabled: true,
            controlled: false,
            lanes: 0,
            lanesProtected: 0,
            tMin: 10,
          },
          pedestrian: {
            enabled: true,
            zebraWidth: 0,
            zebraCrossOffset: 0,
            tMin: 10,
          },
        },
        way4: {
          straight: {
            enabled: true,
            deadEnd: false,
            laneWidth: 0,
            lanesIn: 0,
            lanesOut: 0,
            tMin: 10,
          },
          left: {
            enabled: true,
            controlled: false,
            lanes: 0,
            lanesProtected: 0,
            tMin: 10,
          },
          right: {
            enabled: true,
            controlled: false,
            lanes: 0,
            lanesProtected: 0,
            tMin: 10,
          },
          pedestrian: {
            enabled: true,
            zebraWidth: 0,
            zebraCrossOffset: 0,
            tMin: 10,
          },
        },
        way6: {
          straight: {
            enabled: true,
            deadEnd: false,
            laneWidth: 0,
            lanesIn: 0,
            lanesOut: 0,
            tMin: 10,
          },
          left: {
            enabled: true,
            controlled: false,
            lanes: 0,
            lanesProtected: 0,
            tMin: 10,
          },
          right: {
            enabled: true,
            controlled: false,
            lanes: 0,
            lanesProtected: 0,
            tMin: 10,
          },
          pedestrian: {
            enabled: true,
            zebraWidth: 0,
            zebraCrossOffset: 0,
            tMin: 10,
          },
        },
        way8: {
          straight: {
            enabled: true,
            deadEnd: false,
            laneWidth: 0,
            lanesIn: 0,
            lanesOut: 0,
            tMin: 10,
          },
          left: {
            enabled: true,
            controlled: false,
            lanes: 0,
            lanesProtected: 0,
            tMin: 10,
          },
          right: {
            enabled: true,
            controlled: false,
            lanes: 0,
            lanesProtected: 0,
            tMin: 10,
          },
          pedestrian: {
            enabled: true,
            zebraWidth: 0,
            zebraCrossOffset: 0,
            tMin: 10,
          },
        },
      };
    },
    pedestrian: (): Partial<Schema> => {
      return {
        way2: {
          straight: {
            enabled: true,
            deadEnd: false,
            laneWidth: 0,
            lanesIn: 0,
            lanesOut: 0,
            tMin: 10,
          },
          left: {
            enabled: false,
            controlled: false,
            lanes: 0,
            lanesProtected: 0,
            tMin: 10,
          },
          right: {
            enabled: false,
            controlled: false,
            lanes: 0,
            lanesProtected: 0,
            tMin: 10,
          },
          pedestrian: {
            enabled: false,
            zebraWidth: 0,
            zebraCrossOffset: 0,
            tMin: 10,
          },
        },
        way4: {
          straight: {
            enabled: false,
            deadEnd: false,
            laneWidth: 0,
            lanesIn: 0,
            lanesOut: 0,
            tMin: 10,
          },
          left: {
            enabled: false,
            controlled: false,
            lanes: 0,
            lanesProtected: 0,
            tMin: 10,
          },
          right: {
            enabled: false,
            controlled: false,
            lanes: 0,
            lanesProtected: 0,
            tMin: 10,
          },
          pedestrian: {
            enabled: true,
            zebraWidth: 0,
            zebraCrossOffset: 0,
            tMin: 10,
          },
        },
        way6: {
          straight: {
            enabled: true,
            deadEnd: false,
            laneWidth: 0,
            lanesIn: 0,
            lanesOut: 0,
            tMin: 10,
          },
          left: {
            enabled: false,
            controlled: false,
            lanes: 0,
            lanesProtected: 0,
            tMin: 10,
          },
          right: {
            enabled: false,
            controlled: false,
            lanes: 0,
            lanesProtected: 0,
            tMin: 10,
          },
          pedestrian: {
            enabled: false,
            zebraWidth: 0,
            zebraCrossOffset: 0,
            tMin: 10,
          },
        },
        way8: {
          straight: {
            enabled: false,
            deadEnd: false,
            laneWidth: 0,
            lanesIn: 0,
            lanesOut: 0,
            tMin: 10,
          },
          left: {
            enabled: false,
            controlled: false,
            lanes: 0,
            lanesProtected: 0,
            tMin: 10,
          },
          right: {
            enabled: false,
            controlled: false,
            lanes: 0,
            lanesProtected: 0,
            tMin: 10,
          },
          pedestrian: {
            enabled: false,
            zebraWidth: 0,
            zebraCrossOffset: 0,
            tMin: 10,
          },
        },
      };
    },
  };
}

export class SchemaSvgUtils {
  public static updateLabels(svg, settings: SchemaViewSettings) {
    if (svg && settings) {
      this._updateLabel(svg, "MajorStreet_Label", settings.majorStreet);
      this._updateLabel(svg, "MinorStreet_Label", settings.minorStreet);
      this._updateLabel(svg, "Way2_Label", settings.way2);
      this._updateLabel(svg, "Way6_Label", settings.way6);
      this._updateLabel(svg, "Way4_Label", settings.way4);
      this._updateLabel(svg, "Way8_Label", settings.way8);
    }
  }

  public static updateAngle(svg, settings: SchemaViewSettings) {
    if (svg && settings) {
      svg.style.transform = `rotate(${settings.angle}deg)`;
    }
  }

  public static updateRoads(svg, schema: Schema) {
    if (svg && schema) {
      const hide = [];
      const show = [];
      if (!schema.getWay2Enabled()) {
        hide.push(".road-8-2", ".road-2-4");
        show.push(".roadline-2");
      }
      if (!schema.getWay4Enabled()) {
        hide.push(".road-2-4", ".road-4-6");
        show.push("roadline-4");
      }
      if (!schema.getWay6Enabled()) {
        hide.push(".road-4-6", ".road-6-8");
        show.push(".roadline-6");
      }
      if (!schema.getWay8Enabled()) {
        hide.push(".road-6-8", ".road-8-2");
        show.push(".roadline-8");
      }

      show.forEach((cls) => {
        const elm = svg.querySelector(cls);
        if (elm) {
          elm.removeAttribute("display");
          elm.style.display = null;
        }
      });
      hide.forEach((cls) => {
        const elm = svg.querySelector(cls);
        if (elm) {
          elm.setAttribute("display", "none");
          elm.style.display = "none";
        }
      });
    }
  }

  public static updateMovements(svg, schema: Schema) {
    if (svg && schema) {
      const hideMoves = [];
      if (!schema.Move1.enabled || !schema.Move1.controlled) {
        hideMoves.push("1");
      }
      if (!schema.Move3.enabled || !schema.Move3.controlled) {
        hideMoves.push("3");
      }
      if (!schema.Move5.enabled || !schema.Move5.controlled) {
        hideMoves.push("5");
      }
      if (!schema.Move7.enabled || !schema.Move7.controlled) {
        hideMoves.push("7");
      }
      if (!schema.Move2.enabled) {
        hideMoves.push("2");
      }
      if (!schema.Move4.enabled) {
        hideMoves.push("4");
      }
      if (!schema.Move6.enabled) {
        hideMoves.push("6");
      }
      if (!schema.Move8.enabled) {
        hideMoves.push("8");
      }
      if (!schema.Move12.enabled || !schema.Move12.controlled) {
        hideMoves.push("12");
      }
      if (!schema.Move14.enabled || !schema.Move14.controlled) {
        hideMoves.push("14");
      }
      if (!schema.Move16.enabled || !schema.Move16.controlled) {
        hideMoves.push("16");
      }
      if (!schema.Move18.enabled || !schema.Move18.controlled) {
        hideMoves.push("18");
      }
      if (!schema.MoveP2.enabled) {
        hideMoves.push("P2");
      }
      if (!schema.MoveP4.enabled) {
        hideMoves.push("P4");
      }
      if (!schema.MoveP6.enabled) {
        hideMoves.push("P6");
      }
      if (!schema.MoveP8.enabled) {
        hideMoves.push("P8");
      }

      hideMoves.forEach((n) => {
        const elms = [
          svg.querySelector(`#Move${n}`),
          svg.querySelector(`#Move${n}_Label`),
          svg.querySelector(`#Movement${n}`),
          svg.querySelector(`#Movement${n}_Label`),
        ];
        elms.forEach((elm) => {
          if (elm) {
            elm.setAttribute("display", "none");
            elm.style.display = "none";
          }
        });
      });
    }
  }

  public static updateTheme(svg, dark: boolean) {
    if (svg) {
      const movementColor = dark ? "#ddd" : "#444";
      const labelColor = dark ? "#eee" : "#333";
      this._setFillByClass(svg, "movement-label", movementColor);
      this._setFillByClass(svg, "street-label", labelColor);
      this._setFillByClass(svg, "way-label", labelColor);
    }
  }

  private static _updateLabel(svg, svgLabelId, text) {
    svg.getElementById(svgLabelId).textContent = text;
  }

  private static _setFillByClass(svg, className, fillColor) {
    const items = svg.getElementsByClassName(className);
    for (let i = 0; i < items.length; i++) {
      items[i].style.fill = fillColor;
    }
  }
}

export const SCHEMA_SVG = `
  <svg viewBox="0 0 530 530" preserveAspectRatio="xMidYMid meet">
    <defs>
      <path id="road" d="M0,140 h120 a20,20 0 0 0 20,-20 v-120" style="stroke: #777; stroke-width: 1px; fill: none" />
      <path id="roadline" d="M0,140 h450" style="stroke: #777; stroke-width: 1px; fill: none" />
      <path id="direct" d="M0,-6 h50 v-8 l20,14 -20,14 v-8 h-50 z" />
      <path id="deadend" d="M0,-6 h40 v-8 l10,0 0,28 -10,0 v-8 h-40 z" />
      <g id="turn" transform="scale(1,1) translate(0, 12)">
        <path d="M-4,-5 l20.4,-18 a16,8 0 0 1 16,-4  h15 v-8 l20,14 -20,14 v-8 h-10 a16,8 0 0 0 -16,4 l-17,15 z" transform="rotate(42 0 0)" />
      </g>
      <path id="pedestrian" d="M20,6 v8 l-14,-14 14,-14 v8 h110 v-8 l14,14 -14,14 v-8 z" />
      <pattern id="pattern1" width="26" height="5" patternTransform="translate(-4 0)" patternUnits="userSpaceOnUse">
        <line stroke="#4faa6d" stroke-width="40" y2="50" />
      </pattern>
      <pattern id="pattern2" width="26" height="5" patternTransform="translate(-0.1 0)" patternUnits="userSpaceOnUse">
        <line stroke="#4faa6d" stroke-width="40" y2="50" />
      </pattern>
      <linearGradient id="redyellow">
        <stop offset="45%" stop-color="#FE4" />
        <stop offset="45%" stop-color="#E00" />
      </linearGradient>
      <linearGradient id="redyellow_stroke">
        <stop offset="45%" stop-color="#880" />
        <stop offset="45%" stop-color="#800" />
      </linearGradient>
    </defs>

    <clipPath id="clip1">
      <rect x="150" y="30" width="30" height="150" />
    </clipPath>
    <clipPath id="clip2">
      <rect x="350" y="150" width="140" height="30" />
    </clipPath>

    <g clip-path="url(#clip1)">
      <text id="MajorStreet_Label" class="street-label" x="170" y="165" style="text-anchor: start" transform="rotate(-90 170 165)">Главная улица</text>
    </g>
    <g clip-path="url(#clip2)">
      <text id="MinorStreet_Label" class="street-label" x="365" y="170" style="text-anchor: start">Второстепенная улица</text>
    </g>

    <text id="Way2_Label" class="way-label" x="265" y="5" dy="1em" style="text-anchor: middle">Направление №2</text>
    <text id="Way6_Label" class="way-label" x="265" y="520" style="text-anchor: middle">Направление №6</text>
    <text id="Way4_Label" class="way-label" x="505" y="265" style="text-anchor: middle" transform="rotate(90 505 265)">Направлнеие №4</text>
    <text id="Way8_Label" class="way-label" x="25" y="265" style="text-anchor: middle" transform="rotate(-90 25 265)">Направление №8</text>

    <g transform="translate(40 40)">
      <use xlink:href="#road" class="road-8-2" x="0" y="0" />
      <use xlink:href="#road" class="road-2-4" x="450" y="0" transform="rotate(90 450 0)" />
      <use xlink:href="#road" class="road-4-6" x="450" y="450" transform="rotate(180 450 450)" />
      <use xlink:href="#road" class="road-6-8" x="0" y="450" transform="rotate(-90 0 450)" />

      <use xlink:href="#roadline" class="roadline-2" x="0" y="0" style="display: none;"/>
      <use xlink:href="#roadline" class="roadline-4" x="450" y="0" transform="rotate(90 450 0)" style="display: none;"/>
      <use xlink:href="#roadline" class="roadline-6" x="450" y="450" transform="rotate(180 450 450)" style="display: none;"/>
      <use xlink:href="#roadline" class="roadline-8" x="0" y="450" transform="rotate(-90 0 450)" style="display: none;"/>

      <text id="Move5_Label" class="movement-label" x="256" y="20">5</text>
      <text id="Move2_Label" class="movement-label" x="220" y="20">2</text>
      <text id="Move12_Label" class="movement-label" x="176" y="20">12</text>
      <text id="MoveP2_Label" class="movement-label" x="150" y="232">П2</text>

      <text id="Move1_Label" class="movement-label" x="183" y="425" dy="1em">1</text>
      <text id="Move6_Label" class="movement-label" x="220" y="425" dy="1em">6</text>
      <text id="Move16_Label" class="movement-label" x="251" y="425" dy="1em">16</text>
      <text id="MoveP6_Label" class="movement-label" x="300" y="232" style="text-anchor: end">П6</text>

      <text id="Move3_Label" class="movement-label" x="20" y="195" style="text-anchor: end">3</text>
      <text id="Move8_Label" class="movement-label" x="20" y="232" style="text-anchor: end">8</text>
      <text id="Move18_Label" class="movement-label" x="20" y="269" style="text-anchor: end">18</text>
      <text id="MoveP8_Label" class="movement-label" x="225" y="300" style="text-anchor: middle">П8</text>

      <text id="Move7_Label" class="movement-label" x="430" y="269">7</text>
      <text id="Move4_Label" class="movement-label" x="430" y="232">4</text>
      <text id="Move14_Label" class="movement-label" x="430" y="195">14</text>
      <text id="MoveP4_Label" class="movement-label" x="225" y="146" dy="1em" style="text-anchor: middle">П4</text>

      <use id="Move3" class="movement" xlink:href="#turn" transform="scale(1 -1) translate(25 -200)" />
      <use id="Move8" class="movement" xlink:href="#direct" x="25" y="225" />
      <use id="Move18" class="movement" xlink:href="#turn" x="25" y="250" />
      <use id="MoveP8" class="movement pedestrian" xlink:href="#pedestrian" x="150" y="318" />

      <use id="Move7" class="movement" xlink:href="#turn" transform="scale(-1 1) translate(-425 250)" />
      <use id="Move4" class="movement" xlink:href="#direct" transform="scale(-1 1) translate(-425 225)" />
      <use id="Move14" class="movement" xlink:href="#turn" transform="scale(-1 -1) translate(-425 -200)" />
      <use id="MoveP4" class="movement pedestrian" xlink:href="#pedestrian" x="150" y="132" />

      <use id="Move1" class="movement" xlink:href="#turn" transform="scale(-1 1) translate(-200 425) rotate(-90)" />
      <use id="Move6" class="movement" xlink:href="#direct" transform="scale(-1 1) translate(-225 425) rotate(-90)" />
      <use id="Move16" class="movement" xlink:href="#turn" transform="scale(1 1) translate(250 425) rotate(-90)" />
      <use id="MoveP6" class="movement pedestrian" xlink:href="#pedestrian" transform="rotate(90) translate(150 -318)" />

      <use id="Move5" class="movement" xlink:href="#turn" transform="scale(-1 1) translate(-250 25) rotate(90)" />
      <use id="Move2" class="movement" xlink:href="#direct" transform="scale(-1 1) translate(-225 25) rotate(90)" />
      <use id="Move12" class="movement" xlink:href="#turn" transform="scale(1 1) translate(200 25) rotate(90)" />
      <use id="MoveP2" class="movement pedestrian" xlink:href="#pedestrian" transform="rotate(90) translate(150 -132)" />
    </g>
  </svg>
`;

export const SCHEMA_SVG_FOR_DETECTORS = `
<svg id="svg86" width="446" height="440" version="1.1" viewBox="0 0 446 440" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
<defs >
<path id="road" d="M0,140 h120 a20,20 0 0 0 20,-20 v-120" style="stroke: #777; stroke-width: 1px; fill: none" />
<path id="roadline" d="M0,140 h450" style="stroke: #777; stroke-width: 1px; fill: none" />
<path id="direct" d="M0,-6 h50 v-8 l20,14 -20,14 v-8 h-50 z" />
<path id="deadend" d="M0,-6 h40 v-8 l10,0 0,28 -10,0 v-8 h-40 z" />
<g id="turn" transform="scale(1,1) translate(0, 12)">
  <path d="M-4,-5 l20.4,-18 a16,8 0 0 1 16,-4  h15 v-8 l20,14 -20,14 v-8 h-10 a16,8 0 0 0 -16,4 l-17,15 z" transform="rotate(42 0 0)" />
</g>
<path id="pedestrian" d="M20,6 v8 l-14,-14 14,-14 v8 h110 v-8 l14,14 -14,14 v-8 z" />
<pattern id="pattern1" width="26" height="5" patternTransform="translate(-4 0)" patternUnits="userSpaceOnUse">
  <line stroke="#4faa6d" stroke-width="40" y2="50" />
</pattern>
<pattern id="pattern2" width="26" height="5" patternTransform="translate(-0.1 0)" patternUnits="userSpaceOnUse">
  <line stroke="#4faa6d" stroke-width="40" y2="50" />
</pattern>
<linearGradient id="redyellow">
  <stop offset="45%" stop-color="#FE4" />
  <stop offset="45%" stop-color="#E00" />
</linearGradient>
<linearGradient id="redyellow_stroke">
  <stop offset="45%" stop-color="#880" />
  <stop offset="45%" stop-color="#800" />
</linearGradient>
</defs>
<clipPath id="clip1">
 <rect id="rect28" x="150" y="30" width="30" height="150"/>
</clipPath>
<clipPath id="clip2">
 <rect id="rect31" x="350" y="150" width="140" height="30"/>
</clipPath>
<g id="g35" transform="translate(-57.01 -57.72)" clip-path="url(#clip1)">
 <text id="MajorStreet_Label" class="street-label" transform="rotate(-90,170,165)" x="170" y="165">Главная улица</text>
</g>
<g id="g38" transform="translate(-59.83 -55.6)" clip-path="url(#clip2)">
 <text id="MinorStreet_Label" class="street-label" x="365" y="170">Второстепенная улица</text>
</g>
<text id="Way2_Label" class="way-label" x="208.69191" y="4.2961473" dy="12" style="text-anchor:middle">Направление №2</text>
<text id="Way6_Label" class="way-label" x="206.58034" y="402.45685" style="text-anchor:middle">Направление №6</text>
<text id="Way4_Label" class="way-label" transform="rotate(90)" x="207.98805" y="-391.67993" style="text-anchor:middle">Направлнеие №4</text>
<text id="Way8_Label" class="way-label" transform="rotate(-90)" x="-210.80345" y="21.095613" style="text-anchor:middle">Направление №8</text>
<g id="g84" transform="translate(40,40)">
 <use id="use44" class="road-8-2" transform="matrix(.862 0 0 .8545 -37.67 -37.29)" width="100%" height="100%" xlink:href="#road"/>
 <use id="use46" class="road-2-4" transform="matrix(0 .8421 -.8796 0 376.1 -414.6)" x="450" width="100%" height="100%" xlink:href="#road"/>
 <use id="use48" class="road-4-6" transform="matrix(-.8466 0 0 -.882 752.5 772.7)" x="450" y="450" width="100%" height="100%" xlink:href="#road"/>
 <use id="use50" class="road-6-8" transform="matrix(0 -.8213 .8318 0 -407.8 367.3)" y="450" width="100%" height="100%" xlink:href="#road"/>
 <use id="use52" class="roadline-2" width="100%" height="100%" style="display:none" xlink:href="#roadline"/>
 <use id="use54" class="roadline-4" transform="rotate(90,450,0)" x="450" width="100%" height="100%" style="display:none" xlink:href="#roadline"/>
 <use id="use56" class="roadline-6" transform="rotate(180,450,450)" x="450" y="450" width="100%" height="100%" style="display:none" xlink:href="#roadline"/>
 <use id="use58" class="roadline-8" transform="rotate(-90,0,450)" y="450" width="100%" height="100%" style="display:none" xlink:href="#roadline"/>
 <text id="Move5_Label" class="movement-label" x="198.98805" y="3.8114252">5</text>
 <text id="Move2_Label" class="movement-label" x="162.98805" y="3.8114252">2</text>
 <text id="Move12_Label" class="movement-label" x="118.98804" y="3.8114252">12</text>
 <text id="Move1_Label" class="movement-label" x="125.98804" y="333.94159" dy="12">1</text>
 <text id="Move6_Label" class="movement-label" x="162.98805" y="333.94159" dy="12">6</text>
 <text id="Move16_Label" class="movement-label" x="193.98805" y="333.94159" dy="12">16</text>
 <text id="Move3_Label" class="movement-label" x="8.3532515" y="137.28419" style="text-anchor:end">3</text>
 <text id="Move8_Label" class="movement-label" x="8.3532515" y="174.28419" style="text-anchor:end">8</text>
 <text id="Move18_Label" class="movement-label" x="8.3532515" y="211.28419" style="text-anchor:end">18</text>
 <text id="Move7_Label" class="movement-label" x="323.01459" y="211.28419">7</text>
 <text id="Move4_Label" class="movement-label" x="323.01459" y="174.28419">4</text>
 <text id="Move14_Label" class="movement-label" x="323.01459" y="137.28419">14</text>
 <use id="Move3" class="movement" transform="matrix(1.274 0 0 -1.274 13.25 148.7)" width="100%" height="100%" xlink:href="#turn"/>
 <use id="Move8" class="movement" transform="matrix(1.259 0 0 1.259 -18.13 -116.1)" x="25" y="225" width="100%" height="100%" xlink:href="#direct"/>
 <use id="Move18" class="movement" transform="matrix(1.229 0 0 1.229 -17.45 -120.3)" x="25" y="250" width="100%" height="100%" xlink:href="#turn"/>
 <use id="Move7" class="movement" transform="matrix(-1.184 0 0 1.184 318.1 187.9)" width="100%" height="100%" xlink:href="#turn"/>
 <use id="Move4" class="movement" transform="matrix(-1.239 0 0 1.239 318 167.3)" width="100%" height="100%" xlink:href="#direct"/>
 <use id="Move14" class="movement" transform="matrix(-1.188 0 0 -1.188 318.1 146.7)" width="100%" height="100%" xlink:href="#turn"/>
 <use id="Move1" class="movement" transform="matrix(0 -1.232 -1.232 0 148.4 323)" width="100%" height="100%" xlink:href="#turn"/>
 <use id="Move6" class="movement" transform="matrix(0 -1.269 -1.269 0 168 322.9)" width="100%" height="100%" xlink:href="#direct"/>
 <use id="Move16" class="movement" transform="matrix(0 -1.261 1.261 0 186.8 323)" width="100%" height="100%" xlink:href="#turn"/>
 <use id="Move5" class="movement" transform="matrix(0 1.24 1.24 0 187.3 8.722)" width="100%" height="100%" xlink:href="#turn"/>
 <use id="Move2" class="movement" transform="matrix(0 1.201 1.201 0 168 8.811)" width="100%" height="100%" xlink:href="#direct"/>
 <use id="Move12" class="movement" transform="matrix(0 1.174 -1.174 0 147.1 8.747)" width="100%" height="100%" xlink:href="#turn"/>
</g>
</svg>

`;

const updateDeadEndConflicts = (
  result: any,
  move: TrafficMovementType,
  notControlledMoves: TrafficMovementType[]
) => {
  result[move] = [];
  Object.keys(result).forEach((m) => {
    result[m] = result[m].filter((x) => x != move);
  });

  notControlledMoves.forEach((m) => {
    const exclude = SCHEMA_STATIC_DEADEND_EXCLUDE_CONFLICTS.find(
      (n) => n.type === m
    );
    if (exclude) {
      result[m] = result[m].filter((n) => !exclude.conflicts.includes(n));
    }
    result[move] = [...result[move], ...result[m]];
    result[m].forEach((n) => result[n].push(move));
  });
};

const updateNotConflicts = (
  result: any,
  move: TrafficMovementType,
  notConflicted: TrafficMovementType[]
) => {
  result[move] = result[move].filter((m) => !notConflicted.includes(m));
  notConflicted.forEach(
    (m) => (result[m] = result[m].filter((n) => n != move))
  );
};
