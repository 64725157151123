import {
  AbstractControl,
  AsyncValidatorFn,
  ValidatorFn,
  ValidationErrors,
} from '@angular/forms';

import { Observable, of, timer } from 'rxjs';
import { switchMap, map } from 'rxjs/operators';

export class FormValidators {
  static exist(
    current: (() => string) | string,
    exist: (value: string) => Observable<boolean>
  ): AsyncValidatorFn {
    return (control: AbstractControl) => {
      const currentValue = current instanceof Function ? current() : current;
      return control.value &&
        control.value.trim().toLowerCase() !==
          (currentValue || '').trim().toLowerCase()
        ? timer(500).pipe(
            switchMap(() => exist(control.value)),
            map((result) => {
              return result ? { exist: true } : null;
            })
          )
        : of(null);
    };
  }

  static equal(source: string, destination: string): ValidatorFn {
    return (control: AbstractControl) => {
      const sourceControl = control.get(source);
      const destinationControl = control.get(destination);
      destinationControl.setErrors(
        (sourceControl.value || '') !== (destinationControl.value || '')
          ? { equal: true }
          : null
      );
      return control.errors;
    };
  }

  static imei(control: AbstractControl): ValidationErrors {
    if (control.value) {
      const len = control.value.length;
      const parity = len % 2;
      let sum = 0;
      for (let i = len - 1; i >= 0; i--) {
        let d = parseInt(control.value.charAt(i), 10);
        if (i % 2 === parity) {
          d *= 2;
        }
        if (d > 9) {
          d -= 9;
        }
        sum += d;
      }
      const valid = sum % 10 === 0;
      if (!valid) {
        return { imei: true };
      }
    }
    return null;
  }

  static arrayLength(minLength: number): ValidatorFn {
    return (conrtol: AbstractControl): { [key: string]: any } => {
      if (conrtol.value && conrtol.value.length >= minLength) return null;
      return { arrayLength: true };
    };
  }
}
