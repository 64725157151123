export const chartOptions: any = {
  grid: {
    left: '8px',
    right: '0',
    bottom: '0',
    top: '20px',
    containLabel: true,
  },
  tooltip: {
    trigger: "axis",
  },
  xAxis: {
    type: "time",
  },
  yAxis: {},
  series: [
    {
      type: "line",
      data: [],
      animationDelay: (idx) => idx * 10,
    },
  ],
  axisLine: {
    lineStyle: {
      color: "white",
    },
  },
  axisLabel: {
    color: "white",
  },
  animationEasing: "elasticOut",
  animationDelayUpdate: (idx) => idx * 5,
};