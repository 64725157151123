import { Injectable } from '@angular/core';

import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import {
  IDataSourceService,
  setPageParams,
  getPageReturn,
  IPageParams,
  IPageReturn,
} from 'projects/msu-its-web-common/src/data/data-source';

import {
  GeoObject,
  GeoObjectInfo,
  CreateGeoObjectRequest,
  DeleteGeoObjectsRequest,
  GetGeoObjectRequest,
  GetGeoObjectsRequest,
  UpdateGeoObjectRequest,
  GetGeoObjectsDetailsRequest,
} from '../dtos/gis.dtos';

import { GisApiService } from './gis-api.service';

@Injectable()
export class GeoObjectService implements IDataSourceService<GeoObject, GeoObjectInfo> {
  constructor(private _api: GisApiService) {}

  getPagedList(params: IPageParams, customerId: string): Observable<IPageReturn<GeoObjectInfo>> {
    if (!customerId) return of(null);

    const request = new GetGeoObjectsRequest();
    request.customerId = customerId;
    setPageParams(request, params);

    return this._api.get(request).pipe(map((m) => getPageReturn(m)));
  }
  getList(customerId: string, filter?: string, groupIds?: string[], onlyValid = false) {
    const request = new GetGeoObjectsDetailsRequest();
    request.customerId = customerId;
    request.entityGroups = groupIds;

    request.page = 0;
    request.items = 0;
    request.sort = 'name';
    request.filter = filter;
    request.onlyValid = onlyValid;

    return this._api.get(request).pipe(
      map((m) => {
        return m.items;
      })
    );
  }

  get(id: string, customerId: string) {
    const request = new GetGeoObjectRequest();
    request.id = id;
    request.customerId = customerId;
    return this._api.get(request);
  }
  add(model: GeoObject, params: { customerId: string; groups: string[]; updateGroups: boolean }) {
    const request = new CreateGeoObjectRequest();
    request.entity = model;
    request.customerId = params.customerId;
    request.entityGroups = params.groups;
    request.updateEntityGroups = params.updateGroups;
    return this._api.post(request);
  }
  update(
    model: GeoObject,
    params: { customerId: string; groups: string[]; updateGroups: boolean }
  ) {
    const request = new UpdateGeoObjectRequest();
    request.id = model.id;
    request.entity = model;
    request.customerId = params.customerId;
    request.entityGroups = params.groups;
    request.updateEntityGroups = params.updateGroups;
    return this._api.put(request);
  }
  delete(ids: string[], customerId: string) {
    const request = new DeleteGeoObjectsRequest();
    request.ids = ids;
    request.customerId = customerId;
    return this._api.delete(request);
  }
  exist(name: string, customerId: string): Observable<boolean> {
    // const request = new ExistGeoObjectNameRequest();
    // request.name = name;
    // request.customerId = customerId;
    // return this._api.get(request);
    return of(false);
  }
}
