import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';

export const EPUTS_MODULES = [
  { id: 'gis', name: _('EPUTS_MODULES.GIS'), enabled: true },
  { id: 'coordination', name: _('EPUTS_MODULES.COORDINATION'), enabled: true },
  { id: 'scenes', name: _('EPUTS_MODULES.SCENES'), enabled: true },
  { id: 'effectiveness', name: _('EPUTS_MODULES.EFFECTIVENESS'), enabled: true },

  { id: 'meteo', name: _('EPUTS_MODULES.METEO'), enabled: true },
  { id: 'parking', name: _('EPUTS_MODULES.PARKING'), enabled: true },
  { id: 'video', name: _('EPUTS_MODULES.VIDEO'), enabled: true },
  { id: 'road_maintenance', name: _('EPUTS_MODULES.ROAD_MAINTENANCE'), enabled: true },

  { id: 'transport_monitoring', name: _('EPUTS_MODULES.TRANSPORT_MONITORING'), enabled: true },
  { id: 'transport', name: _('EPUTS_MODULES.TRANSPORT'), enabled: true },
  { id: 'fixating_traffic_violation', name: 'Фотовидеофиксация', enabled: true }, 
  { id: 'ruis', name: 'Информирования участников ДД с помощью ДИТ и ЗПИ', enabled: true },
  { id: 'default', name: 'Управление наружным освещением (АСУНО)', enabled: false },


  { id: 'default', name: 'Транспортные разрешения', enabled: false },
  { id: 'default', name: 'Суперсервис', enabled: false },

  { id: '', name: 'Модуль интеллектуальной транспортной системы', enabled: false },

  { id: '', name: 'Модуль интеллектуальной транспортной системы', enabled: false },
  { id: '', name: 'Модуль интеллектуальной транспортной системы', enabled: false },
  { id: '', name: 'Модуль интеллектуальной транспортной системы', enabled: false },
  { id: '', name: 'Модуль интеллектуальной транспортной системы', enabled: false },
];

export const EPUTS_SUBSYSTEMS = [
  { id: 'tlc', name: _('EPUTS_SUBSYSTEMS.TLC') },
  { id: 'tfms', name: _('EPUTS_SUBSYSTEMS.TFMS') },

  { id: '', name: '' },
  { id: '', name: '' },
  { id: '', name: '' },
];

export const EPUTS_ONLY_MODULES = [
  { id: 'eputs', name: _('EPUTS_MODULES.EPUTS'), enabled: true },
  { id: '', name: 'Модуль интеллектуальной транспортной системы', enabled: false },
  { id: '', name: 'Модуль интеллектуальной транспортной системы', enabled: false },
  { id: '', name: 'Модуль интеллектуальной транспортной системы', enabled: false },

  { id: '', name: 'Модуль интеллектуальной транспортной системы', enabled: false },
  { id: '', name: 'Модуль интеллектуальной транспортной системы', enabled: false },
  { id: '', name: 'Модуль интеллектуальной транспортной системы', enabled: false },
  { id: '', name: 'Модуль интеллектуальной транспортной системы', enabled: false },

  { id: '', name: 'Модуль интеллектуальной транспортной системы', enabled: false },
  { id: '', name: 'Модуль интеллектуальной транспортной системы', enabled: false },
  { id: '', name: 'Модуль интеллектуальной транспортной системы', enabled: false },
  { id: '', name: 'Модуль интеллектуальной транспортной системы', enabled: false },

  { id: '', name: 'Модуль интеллектуальной транспортной системы', enabled: false },
  { id: '', name: 'Модуль интеллектуальной транспортной системы', enabled: false },
  { id: '', name: 'Модуль интеллектуальной транспортной системы', enabled: false },
  { id: '', name: 'Модуль интеллектуальной транспортной системы', enabled: false },
];

export const EPUTS_ONLY_SUBSYSTEMS = [
  { id: '', name: '' },
  { id: '', name: '' },

  { id: '', name: '' },
  { id: '', name: '' },
  { id: '', name: '' },
];
