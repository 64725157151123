import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
} from '@angular/core';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { TranslateService } from '@ngx-translate/core';
import { SettingsService } from 'projects/msu-its-web-common/src/services/settings.service';

import { LayoutSvgUtils } from 'projects/msu-its-web-tlc/src/dtos/layout';
import { SchemaUtils } from 'projects/msu-its-web-tlc/src/dtos/schema';

import {
  UsdkPhase,
  TrafficObjectState,
  UsdkTrafficObjectStatus,
  DKMode,
} from 'projects/msu-its-web-tlc/src/dtos/tlc.dtos';

import { IUsdkTrafficObjectSet, UsdkStateService } from './usdk-state.service';
import { UsdkTrafficObjectService } from 'projects/msu-its-web-tlc/src/services/usdk-traffic-object.service';
import { isUsdkPhaseMajor } from 'projects/msu-its-web-tlc/src/dtos/usdk/usdk-plan';

@Component({
  selector: 'usdk-state-buttons',
  templateUrl: './usdk-state-buttons.component.html',
  styleUrls: ['./usdk-state-buttons.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UsdkStateButtonsComponent implements AfterViewInit, OnDestroy {
  _destroy = new Subject();

  @Input()
  disabled = false;

  status: UsdkTrafficObjectStatus;
  trafficObjectSet: IUsdkTrafficObjectSet;
  phases: [UsdkPhase[], UsdkPhase[]] = [[], []];

  stateType = TrafficObjectState;

  get schema() {
    return this.trafficObjectSet.schemaSet.schema;
  }
  get schemaView() {
    return this.trafficObjectSet.schemaSet.schemaView;
  }

  get mode(): DKMode {
    return this.status?.controllerStatus?.traffic.trafficMode;
  }

  constructor(
    private _stateService: UsdkStateService,
    private _usdkTrafficObjectService: UsdkTrafficObjectService,
    private _settingsService: SettingsService,
    private _changeDetector: ChangeDetectorRef,
    public translate: TranslateService
  ) {
    this._stateService.trafficObjectSet$
      .pipe(takeUntil(this._destroy))
      .subscribe((trafficObjectSet) => {
        this.trafficObjectSet = trafficObjectSet;
        this._updatePhases();
        this._changeDetector.markForCheck();
      });

    this._stateService.status$.pipe(takeUntil(this._destroy)).subscribe((status) => {
      this.status = status;
      this._changeDetector.markForCheck();
    });
  }

  ngAfterViewInit() {}

  ngOnDestroy() {
    this._destroy.next();
    this._destroy.complete();
  }

  setState(phase: UsdkPhase) {
    this._stateService
      .loadingQuery(
        this._usdkTrafficObjectService.setRemoteMode(
          this._stateService.modelId,
          this._stateService.customerId,
          phase.id
        )
      )
      .subscribe((status) => {
        this._stateService.status = status;
      });
  }

  setYellowBlink() {
    this._stateService
      .loadingQuery(
        this._usdkTrafficObjectService.setYellowBlinkMode(
          this._stateService.modelId,
          this._stateService.customerId
        )
      )
      .subscribe((status) => {
        this._stateService.status = status;
      });
  }

  setOff() {
    this._stateService
      .loadingQuery(
        this._usdkTrafficObjectService.setOfflineMode(
          this._stateService.modelId,
          this._stateService.customerId
        )
      )
      .subscribe((status) => {
        this._stateService.status = status;
      });
  }

  setSvgState(element, phase: UsdkPhase) {
    const schema = this.schema;
    const schemaView = this.schemaView;
    const isDark = this._settingsService.darkTheme;
    const svg = element.contentDocument;

    const movements = [];
    phase.movements.forEach((m) => {
      movements.push(...SchemaUtils.schemaViewMovements(schema, m));
    });

    LayoutSvgUtils.updateLayoutSchema(svg, schema, schemaView, isDark);
    LayoutSvgUtils.showLayoutMovements(svg, schema, movements);
  }

  isCurrentPhase(phase: UsdkPhase) {
    this.status?.controllerStatus?.traffic?.currentPhase.phaseId == phase.id;
  }

  _updatePhases() {
    const majorPhases = [];
    const minorPhases = [];

    this.trafficObjectSet?.settings.phases.forEach((m) => {
      isUsdkPhaseMajor(m) ? majorPhases.push(m) : minorPhases.push(m);
    });

    this.phases = [majorPhases, minorPhases];
  }
}
