import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy } from '@angular/core';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { TranslateService } from '@ngx-translate/core';
import { IUsdkTrafficObjectSet, UsdkStateService } from './usdk-state.service';

import {
  UsdkBarrier,
  TrafficObjectMode,
  UsdkTrafficObjectStatus,
  UsdkControllerErrorType,
} from 'projects/msu-its-web-tlc/src/dtos/tlc.dtos';
import { updatePhaseSvg, UsdkPlan } from 'projects/msu-its-web-tlc/src/dtos/usdk/usdk-plan';

import { LayoutSvgUtils } from 'projects/msu-its-web-tlc/src/dtos/layout';

import { SettingsService } from 'projects/msu-its-web-common/src/services/settings.service';

import { LANGS_LOCALES } from 'projects/msu-its-web-common/src/utils/langs';

import { CONTROLLER_STATES } from 'projects/msu-its-web-tlc/src/dtos/enums';

import { FlashService } from 'projects/msu-its-web-common/src/services/flash.service';

import { TrafficObjectControllerService } from 'projects/msu-its-web-tlc/src/services/traffic-object-controller.service';
import { UsdkTrafficObjectService } from 'projects/msu-its-web-tlc/src/services/usdk-traffic-object.service';
import { DialogService } from 'projects/msu-its-web-common/src/services/dialog.service';
import { TrafficObjectReportComponent } from 'projects/msu-its-web-tss/src/components/traffic-object-report/traffic-object-report.component';

@Component({
  selector: 'usdk-state-controller',
  templateUrl: './usdk-state-controller.component.html',
  styleUrls: ['./usdk-state-controller.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UsdkStateControllerComponent implements OnDestroy {
  _destroy = new Subject();

  status: UsdkTrafficObjectStatus;
  trafficObjectSet: IUsdkTrafficObjectSet;

  _currentMode: TrafficObjectMode;
  _currentPlan: number;
  // _currentAdaptivePlanId: string;

  plan: UsdkPlan;

  controllerStates = {};

  get timezone() {
    return this._settingsService.timezone;
  }
  get locale() {
    return LANGS_LOCALES[this.translate.currentLang];
  }

  get schema() {
    return this.trafficObjectSet.schemaSet.schema;
  }
  get schemaView() {
    return this.trafficObjectSet.schemaSet.schemaView;
  }

  get trafficStatus() {
    return this.status?.controllerStatus?.traffic;
  }
  get controllerStatus() {
    return this.status?.controllerStatus?.controller;
  }

  constructor(
    private _flashService: FlashService,
    private _controllerService: TrafficObjectControllerService,
    private _usdkTrafficObjectService: UsdkTrafficObjectService,
    private _stateService: UsdkStateService,
    private _settingsService: SettingsService,
    private _changeDetector: ChangeDetectorRef,
    private _dialogService: DialogService,
    public translate: TranslateService
  ) {
    CONTROLLER_STATES.forEach((m) => (this.controllerStates[m.type] = m));

    this._stateService.trafficObjectSet$
      .pipe(takeUntil(this._destroy))
      .subscribe((trafficObjectSet) => {
        this.trafficObjectSet = trafficObjectSet;
        this._updatePlan();
        this._changeDetector.markForCheck();
      });

    this._stateService.status$.pipe(takeUntil(this._destroy)).subscribe((status) => {
      this.status = status;
      this._changeDetector.markForCheck();
    });
  }

  ngOnDestroy() {
    this._destroy.next();
    this._destroy.complete();
  }

  _updatePlan() {
    if (
      (this.status && this._currentMode !== this.status.mode) ||
      this._currentPlan !== this.status?.controllerStatus?.traffic.coordinationPlan //||
      // this._currentAdaptivePlanId !== this.status.AdaptivePlanId ||
    ) {
      this._currentMode = this.status.mode;
      this._currentPlan = this.status.controllerStatus.traffic.coordinationPlan;
      // this._currentAdaptivePlanId = this.status.AdaptivePlanId;

      if (this.status.mode === TrafficObjectMode.Adaptive) {
        // this.plan = <Plan>this.status.AdaptivePlan;
        this.plan = null;
      } else {
        const leadZero = this._currentPlan >= 10 ? '' : '0';
        this.plan = this.trafficObjectSet.settings.plans['Plan' + leadZero + this._currentPlan];
      }

      if (this.plan && this.plan.barriers) {
      } else {
        this._currentMode = null;
      }
    }
  }

  updateStaticSvg(target, barrier: UsdkBarrier) {
    const darkTheme = this._settingsService.darkTheme;
    const svg = target.contentDocument;
    const phase = this.trafficObjectSet.settings.phases.find((m) => m.id == barrier.phase);
    LayoutSvgUtils.updateLayoutSchema(svg, this.schema, this.schemaView, darkTheme);
    updatePhaseSvg(target, this.schema, phase);
  }

  timeDiff() {
    const serverTime = Date.parse(this.status?.serverTime);
    const controllerTime = Date.parse(this.status?.controllerTime);
    return Math.abs(Math.floor((controllerTime - serverTime) / 1000));
  }
  timeDiffString() {
    const serverTime = Date.parse(this.status?.serverTime);
    const controllerTime = Date.parse(this.status?.controllerTime);
    const timeDiff = Math.floor((controllerTime - serverTime) / 1000);
    return timeDiff > 0 ? '+' + timeDiff : timeDiff;
  }

  get isOffline() {
    return this.controllerStatus?.errors?.some((m) => m.type == UsdkControllerErrorType.Offline);
  }

  // openControllerEdit() {
  //   const id = this.object.ControllerId;
  //   this._dialogService.windowDialog(
  //     '/settings/device-edit/' + id,
  //     'DeviceEdit' + id,
  //     'menubar=0,toolbar=0,titlebar=0,location=0,locationbar=0,width=1080,height=760,resizable=0'
  //   );
  // }

  // openControllerErrorLog() {
  //   const id = this.object.ControllerId;
  //   window.open(
  //     '/settings/device-error-log/' + id,
  //     'DeviceErrorLog' + id,
  //     'menubar=0,toolbar=0,titlebar=0,location=0,locationbar=0,width=960,height=850,resizable=0'
  //   );
  // }

  // openControllerEventLog() {
  //   const id = this.object.ControllerId;
  //   window.open(
  //     '/settings/event-log/device/' + id,
  //     'EventLog' + id,
  //     'menubar=0,toolbar=0,titlebar=0,location=0,locationbar=0,width=960,height=850,resizable=0'
  //   );
  // }

  // openControllerFiles() {
  //   const dialog = this._dialogService.dialog.open(FilesComponent, { disableClose: false });
  //   dialog.componentInstance.entityId = this.object.ControllerId;
  //   dialog.componentInstance.entityName = this.object.ControllerFullNumber;
  // }

  // openControllerAudit() {
  //   const id = this.object.ControllerId;
  //   window.open(
  //     '/settings/activity-log/device/' + id,
  //     'DeviceAudit' + id,
  //     'menubar=0,toolbar=0,titlebar=0,location=0,locationbar=0,width=960,height=850,resizable=0'
  //   );
  // }

  // openControllerSettings() {
  //   const id = this.object.ControllerId;
  //   this._dialogService.windowDialog(
  //     '/settings/device-settings/' + id,
  //     'DeviceSettings' + id,
  //     'menubar=0,toolbar=0,titlebar=0,location=0,locationbar=0,width=640,height=480,resizable=0'
  //   );
  // }

  disconnectController() {
    this._stateService
      .loadingQuery(
        this._controllerService.disconnect(
          this.trafficObjectSet.model.controllerId,
          this._stateService.customerId
        )
      )
      .subscribe((result) => {
        if (result) {
          this._flashService.success(
            this.translate.instant('DEVICES.SESSION_DISCONNECTED', {
              number: this.trafficObjectSet.model.controllerId,
            })
          );
        }
      });
  }

  queryController() {
    this._stateService
      .loadingQuery(
        this._usdkTrafficObjectService.getStatus(
          this.trafficObjectSet.model.id,
          this._stateService.customerId,
          true
        )
      )
      .subscribe((result) => {
        if (result) {
          this._stateService.status = result;
          this._flashService.success(
            this.translate.instant('DEVICES.QUERY_STATE_COMPLETED', {
              number: this.trafficObjectSet.model.controller.fullNumber,
            })
          );
        }
      });
  }

  repeatRemoteMode() {
    const schema = this.schema;
    const phaseId = this.trafficStatus.currentPhase.phaseId;

    this._stateService
      .loadingQuery(
        this._usdkTrafficObjectService.setRemoteMode(
          this.trafficObjectSet.model.id,
          this._stateService.customerId,
          phaseId
        )
      )
      .subscribe();
  }
  trafficObjectReport() {
    const dialog = this._dialogService.dialog.open(TrafficObjectReportComponent, {
      disableClose: true,
    });
    dialog.componentInstance.trafficObjectId = this.trafficObjectSet.model.id;
    dialog.componentInstance.trafficObjectName = this.trafficObjectSet.model.name;
    dialog.componentInstance.schemaSet = this.trafficObjectSet.schemaSet;
  }

  eventLog() {
    const url = `${this._stateService.customerId}/ext/traffic-object-controller/${this.trafficObjectSet.model.controllerId}/event-log`;
    this._dialogService.windowDialog(
      url,
      'trafficObjectControllerEventLog_' + this.trafficObjectSet.model.controllerId,
      'menubar=0,toolbar=0,titlebar=0,location=0,locationbar=0,width=950,height=850,resizable=0'
    );
  }
}
