import { Injectable } from "@angular/core";

import { Observable, of } from "rxjs";
import { map } from "rxjs/operators";

import {
  IDataSourceService,
  setPageParams,
  getPageReturn,
  IPageParams,
  IPageReturn,
} from "projects/msu-its-web-common/src/data/data-source";

import { PtmApiService } from "../ptm-api.service";
import {
  CreatePublicTransportRouteRequest,
  DeletePublicTransportRoutesRequest,
  GetPublicTransportRouteExistRequest,
  GetPublicTransportRouteRequest,
  GetPublicTransportRouteSceneRequest,
  GetPublicTransportRoutesRequest,
  GetPublicTransportSceneScheduleRequest,
  GetPublicTransportSceneStopsStatusesRequest,
  GetPublicTransportSceneTrafficObjectsStatusesRequest,
  GetPublicTransportVehicleSceneStatusesRequest,
  GetPublicTransportVerifiedScenesRequest,
  PublicTransportRoute,
  PublicTransportRouteScene,
  PublicTransportVehiclePriorityStartRequest,
  PublicTransportVehiclePriorityStatusRequest,
  PublicTransportVehiclePriorityStopRequest,
  PublicTransportVehicleSceneStatus,
  StopStatus,
  TrafficObjectStatus,
  UpdatePublicTransportRouteRequest,
  UpdatePublicTransportRouteSceneRequest,
} from "../../dtos/ptm.dtos";

@Injectable()
export class RouteService
  implements IDataSourceService<PublicTransportRoute, PublicTransportRoute> {
  constructor(private _api: PtmApiService) {}

  getPagedList(
    params: IPageParams,
    customerId: string
  ): Observable<IPageReturn<PublicTransportRoute>> {
    if (!customerId) {
      return of(null);
    }

    const request = new GetPublicTransportRoutesRequest();
    request.customerId = customerId;

    setPageParams(request, params);
    return this._api.get(request).pipe(
      map((m) => {
        return getPageReturn(m);
      })
    );
  }
  getList(
    customerId: string,
    filter?: string,
    groupIds?: string[],
    items?: number
  ): Observable<PublicTransportRoute[]> {
    const request = new GetPublicTransportRoutesRequest();
    request.customerId = customerId;
    request.entityGroups = groupIds || [];

    request.page = 0;
    request.items = items || 0;
    request.sort = "name";
    request.filter = filter || "";

    return this._api.get(request).pipe(
      map((m) => {
        return m.items;
      })
    );
  }

  get(id: string, customerId: string) {
    const request = new GetPublicTransportRouteRequest();
    request.id = id;
    request.customerId = customerId;
    return this._api.get(request);
  }
  add(
    model: PublicTransportRoute,
    params: {
      customerId: string;
      groups: string[];
      updateGroups: boolean;
    }
  ) {
    const request = new CreatePublicTransportRouteRequest();
    request.entity = model;
    request.customerId = params.customerId;
    request.entityGroups = params.groups;
    request.updateEntityGroups = params.updateGroups;
    return this._api.post(request);
  }
  update(
    model: PublicTransportRoute,
    params: { customerId: string; groups: string[]; updateGroups: boolean }
  ) {
    const request = new UpdatePublicTransportRouteRequest();
    request.id = model.id;
    request.entity = model;
    request.customerId = params.customerId;
    request.entityGroups = params.groups;
    request.updateEntityGroups = params.updateGroups;
    return this._api.put(request);
  }
  delete(ids: string[], customerId: string) {
    const request = new DeletePublicTransportRoutesRequest();
    request.ids = ids;
    request.customerId = customerId;
    return this._api.delete(request);
  }
  exist(name: string, customerId: string): Observable<boolean> {
    const request = new GetPublicTransportRouteExistRequest({
      name,
      customerId,
    });
    return this._api.get(request);
  }

  getPublicTransportRouteSceneRequest(id: string, customerId: string) {
    const request = new GetPublicTransportRouteSceneRequest({
      id,
      customerId,
    });
    return this._api.get(request,{}, true);
  }
  updatePublicTransportRouteSceneRequest(
    customerId: string,
    model: PublicTransportRouteScene
  ) {
    const request = new UpdatePublicTransportRouteSceneRequest();
    request.id = model.id;
    request.entity = model;
    request.customerId = customerId;
    return this._api.put(request);
  }

  getListRouterScene(
    customerId: string
  ): Observable<PublicTransportRouteScene[]> {
    const request = new GetPublicTransportVerifiedScenesRequest();
    request.customerId = customerId;
    return this._api.get(request);
  }
  // getPublicTransportAllVehicleStatusesRequest(
  //   customerId: string
  // ): Observable<PublicTransportVehicleSceneStatus[]> {
  //   const request = new this.getPublicTransportAllVehicleStatusesRequest();
  //   request.customerId = customerId;
  //   return this._api.get(request);
  // }
  getPublicTransportVehicleSceneStatusesRequest(
    customerId: string,
    routerId: string
  ): Observable<PublicTransportVehicleSceneStatus[]> {
    const request = new GetPublicTransportVehicleSceneStatusesRequest();
    request.customerId = customerId;
    request.routeId = routerId;
    return this._api.get(request);
  }

  startSystem(customerId: string): Observable<boolean> {
    const request = new PublicTransportVehiclePriorityStartRequest();
    request.customerId = customerId;
    return this._api.post(request);
  }
  stopSystem(customerId: string): Observable<boolean> {
    const request = new PublicTransportVehiclePriorityStopRequest();
    request.customerId = customerId;
    return this._api.post(request);
  }
  getStatusSystem(customerId: string): Observable<boolean> {
    const request = new PublicTransportVehiclePriorityStatusRequest();
    request.customerId = customerId;
    return this._api.get(request);
  }

  getPublicTransportSceneTrafficObjectsStatusesRequest(
    customerId: string,
    routerId: string
  ): Observable<TrafficObjectStatus[]> {
    const request = new GetPublicTransportSceneTrafficObjectsStatusesRequest();
    request.customerId = customerId;
    request.routeId = routerId;
    return this._api.get(request);
  }
  getPublicTransportSceneStopsStatusesRequest(
    customerId: string,
    routerId: string
  ): Observable<StopStatus[]> {
    const request = new GetPublicTransportSceneStopsStatusesRequest();
    request.customerId = customerId;
    request.routeId = routerId;
    return this._api.get(request);
  }
  getPublicTransportSceneScheduleRequest(
    customerId: string,
    routerId: string
  ): Observable<number> {
    const request = new GetPublicTransportSceneScheduleRequest();
    request.customerId = customerId;
    request.routeId = routerId;
    return this._api.get(request);
  }
}
