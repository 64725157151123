import { Injectable } from '@angular/core';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class LocalizedMatPaginatorIntl extends MatPaginatorIntl {
  ofLabel: string;

  constructor(private translate: TranslateService) {
    super();
    this.translate.onLangChange.subscribe(() => this._updateTranslation());
    this._updateTranslation();
  }

  private _updateTranslation() {
    this.itemsPerPageLabel = this.translate.instant('PAGING.ITEMS_PER_PAGE');
    this.nextPageLabel = this.translate.instant('PAGING.NEXT_PAGE');
    this.previousPageLabel = this.translate.instant('PAGING.PREVIOUS_PAGE');
    this.firstPageLabel = this.translate.instant('PAGING.FIRST_PAGE');
    this.lastPageLabel = this.translate.instant('PAGING.LAST_PAGE');
    this.ofLabel = this.translate.instant('PAGING.OF_LABEL');
    this.changes.next();
  }

  getRangeLabel = (page: number, pageSize: number, length: number) => {
    if (length === 0 || pageSize === 0) { return `0 ${this.ofLabel} ${length}`; }
    length = Math.max(length, 0);
    const startIndex = page * pageSize;
    const endIndex = startIndex < length ?
      Math.min(startIndex + pageSize, length) :
      startIndex + pageSize;
    return `${startIndex + 1} - ${endIndex} ${this.ofLabel} ${length}`;
  }
}
