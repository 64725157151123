<div #map class="map"></div>
<div #topRight fxLayout="column" fxLayoutGap="8px" class="top-right">
  <div
    *ngIf="zoomControl"
    style="display: flex; flex-direction: column; padding-bottom: 8px;"
  >
    <button
      mat-flat-button
      class="mat-elevation-z2"
      [title]="'COMMON.ZOOM_IN' | translate"
      style="border-bottom-left-radius: 0; border-bottom-right-radius: 0;"
      (click)="zoomIn($event)"
      [class.mat-theme-bg]="zoomInDisabled"
      [disabled]="zoomInDisabled"
    >
      <mat-icon>add</mat-icon>
    </button>
    <button
      mat-flat-button
      class="mat-elevation-z2"
      [title]="'COMMON.ZOOM_OUT' | translate"
      style="border-top-left-radius: 0; border-top-right-radius: 0;"
      (click)="zoomOut($event)"
      [class.mat-theme-bg]="zoomOutDisabled"
      [disabled]="zoomOutDisabled"
    >
      <mat-icon>remove</mat-icon>
    </button>
  </div>

  <button
    *ngIf="layersControl"
    mat-flat-button
    class="mat-elevation-z2"
    #layersMenuTrigger="matMenuTrigger"
    [matMenuTriggerFor]="layersMenu"
    [title]="'COMMON.MAP_LAYERS' | translate"
  >
    <mat-icon>map</mat-icon>
  </button>
  <ng-content select="[topRight]"></ng-content>
</div>

<div #topLeft fxLayout="column" fxLayoutGap="8px" class="top-left">
  <ng-content select="[topLeft]"></ng-content>
</div>

<mat-menu
  #layersMenu
  hasBackdrop="false"
  overlapTrigger="true"
  xPosition="before"
>
  <div
    fxLayout="column"
    fxLayoutGap="4px"
    class="layers-menu mat-color"
    (click)="$event.stopPropagation()"
  >
    <div fxLayout fxLayoutGap="24px">
      <mat-radio-group
        fxLayout="column"
        fxLayoutGap="10px"
        [value]="currentLayerId"
      >
        <mat-radio-button
          *ngFor="let l of layers"
          [value]="l.options.id"
          (change)="setLayer($event.value)"
        >
          {{ l.options.key | translate }}
        </mat-radio-button>
      </mat-radio-group>
      <div>
        <button
          mat-icon-button
          (click)="closeLayersMenu()"
          style="margin: -12px -10px;"
        >
          <mat-icon>close</mat-icon>
        </button>
      </div>
    </div>

    <ng-container *ngIf="trafficLayer">
      <div>
        <mat-divider style="margin: 4px -12px;"></mat-divider>
      </div>

      <mat-checkbox
        disableRipple="true"
        style="padding: 2px;"
        [disabled]="showTrafficDisabled"
        [checked]="showTraffic"
        (change)="setShowTraffic($event.checked)"
      >
        {{ "MAP.TRAFFIC" | translate }}
      </mat-checkbox>
    </ng-container>
  </div>
</mat-menu>
