import { Injectable } from "@angular/core";

import { Observable, of } from "rxjs";
import { map } from "rxjs/operators";

import {
  getPageReturn,
  IDataSourceService,
  IPageParams,
  IPageReturn,
  setPageParams,
} from "projects/msu-its-web-common/src/data/data-source";
import { ParkingApiService } from "../parking-api.service";
import { TokenService } from "projects/msu-its-web-common/src/services/token.service";

import { IGisObjectGroupService } from "projects/msu-its-web-common/src/utils/gis-object-group";
import { ParkingCameraService } from "./parking-camera.service";
import {
  CreateParkingCameraGroupRequest,
  DeleteParkingCameraGroupsRequest,
  EntityGroup,
  EntityGroupInfo,
  GetItemEntityGroupsRequest,
  GetParkingCameraGroupRequest,
  GetParkingCameraGroupsRequest,
  UpdateParkingCameraGroupRequest,
} from "../../dtos/parking.dtos";

@Injectable()
export class ParkingCameraGroupService
  implements
    IDataSourceService<EntityGroup, EntityGroupInfo>,
    IGisObjectGroupService {
  constructor(
    private _api: ParkingApiService,
    private _tokenService: TokenService,
    private _parkingCameraService: ParkingCameraService
  ) {}

  permissions = ["parkingCamera.read"];

  getObjectGroups(objectId: string, customerId: string) {
    const request = new GetItemEntityGroupsRequest({
      id: objectId,
      customerId,
    });
    return this._api.get(request).pipe(map((m) => m.items));
  }

  getPagedList(
    params: IPageParams,
    customerId: string
  ): Observable<IPageReturn<EntityGroupInfo>> {
    if (!customerId) {
      return of(null);
    }

    const request = new GetParkingCameraGroupsRequest();
    request.customerId = customerId;

    setPageParams(request, params);
    return this._api.get(request).pipe(
      map((m) => {
        return getPageReturn(m);
      })
    );
  }

  getList(customerId: string, filter?: string, owned = false) {
    const request = new GetParkingCameraGroupsRequest();
    request.customerId = customerId;

    request.page = 0;
    request.items = 0;
    request.sort = "name";
    request.filter = filter;
    request.onlyOwnedItems = owned;

    return this._api.get(request).pipe(
      map((m) => {
        return m.items;
      })
    );
  }

  getGroupList(customerId: string) {
    return this.getList(customerId);
  }

  getObjectList(customerId: string, groupIds: string[]) {
    return this._parkingCameraService.getList(customerId, null, groupIds);
  }

  get(id: string, customerId: string) {
    const request = new GetParkingCameraGroupRequest();
    request.id = id;
    request.customerId = customerId;
    return this._api.get(request);
  }

  add(model: EntityGroup, params: { customerId: string }) {
    const request = new CreateParkingCameraGroupRequest();
    request.customerId = params.customerId;
    request.entity = model;
    return this._api.post(request);
  }

  update(model: EntityGroup, params: { customerId: string }) {
    const request = new UpdateParkingCameraGroupRequest();
    request.id = model.id;
    request.entity = model;
    request.customerId = params.customerId;
    return this._api.put(request);
  }

  delete(ids: string[], customerId: string): Observable<number> {
    const request = new DeleteParkingCameraGroupsRequest();
    request.ids = ids;
    request.customerId = customerId;
    return this._api.delete(request);
  }

  exist(name: string, customerId: string) {
    return of(false);
  }
}
