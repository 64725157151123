<table-layout
  [dataSource]="dataSource"
  [displayedColumns]="displayedColumns"
  [rowActions]="rowActions"
  titleText="{{ 'CUSTOMERS.TITLE' | translate }}"
  addText="{{ 'CUSTOMERS.CREATE_CUSTOMER' | translate }}"
  deleteText="{{ 'COMMON.DELETE' | translate }}"
  (addBtnClick)="add()"
  (deleteBtnClick)="deleteSelected()"
  (rowDblClick)="edit($event)"
>
  <ng-container ngPackageAs="table-layout-columns" matSort>
    <ng-container matColumnDef="name">
      <mat-header-cell *matHeaderCellDef mat-sort-header>
        {{ "COMMON.NAME" | translate }}
      </mat-header-cell>
      <mat-cell *matCellDef="let item">
        <b>{{ item.name }}</b>
      </mat-cell>
    </ng-container>
  </ng-container>
</table-layout>
