/* Options:
Date: 2023-12-19 06:57:35
Version: 6.70
Tip: To override a DTO option, remove "//" prefix before updating
BaseUrl: https://ptm.dev.its.msu24.ru

//GlobalNamespace: 
//MakePropertiesOptional: False
//AddServiceStackTypes: True
//AddResponseStatus: False
//AddImplicitVersion: 
//AddDescriptionAsComments: True
//IncludeTypes: 
//ExcludeTypes: 
//DefaultImports: 
*/

export interface IReturn<T> {
  createResponse(): T;
}

export interface IReturnVoid {
  createResponse(): void;
}

export interface IHasSessionId {
  sessionId: string;
}

export interface IHasBearerToken {
  bearerToken: string;
}

export interface IPost {}

// @DataContract
export class PageRequest {
  // @DataMember
  public page: number;

  // @DataMember
  public items: number;

  // @DataMember
  public sort: string;

  // @DataMember
  public filter: string;

  // @DataMember
  public propertiesTest: { [index: string]: number };

  public constructor(init?: Partial<PageRequest>) {
    (Object as any).assign(this, init);
  }
}

export interface ICustomerItemRequest extends ICustomerEntity {}

export interface ICustomerEntity {
  // @DataMember
  customerId: string;
}

export interface IBaseEntityItemRequest {
  id: string;
}

export interface IDescription {
  // @DataMember
  description: string;
}

export interface IRequestWithEntity {}

export class DeleteCustomerItemsRequest implements ICustomerItemRequest, IDescription {
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public description: string;

  // @DataMember(IsRequired=true)
  public ids: string[];

  public constructor(init?: Partial<DeleteCustomerItemsRequest>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class GetEntityGroupsRequestBase implements ICustomerItemRequest {
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public entityGroups: string[];

  // @DataMember
  public itemsIds: string[];

  // @DataMember
  public onlyOwnedItems: boolean;

  // @DataMember
  public page: number;

  // @DataMember
  public items: number;

  // @DataMember
  public sort: string;

  // @DataMember
  public filter: string;

  // @DataMember
  public propertiesTest: { [index: string]: number };

  public constructor(init?: Partial<GetEntityGroupsRequestBase>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class GetEntityGroupRequestBase implements ICustomerItemRequest, IBaseEntityItemRequest {
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember(IsRequired=true)
  public id: string;

  public constructor(init?: Partial<GetEntityGroupRequestBase>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class EntityGroup
  implements ICustomerEntityViewModel, INamedEntity, IChangeKey, IDescription, IModified, IEntity
{
  // @DataMember
  public items: string[];

  // @DataMember
  public customerId: string;

  // @DataMember
  public customerName: string;

  // @DataMember
  public name: string;

  // @DataMember
  public description: string;

  // @DataMember
  public changeKey: string;

  // @DataMember
  public created: string;

  // @DataMember
  public createdBy: string;

  // @DataMember
  public modified: string;

  // @DataMember
  public modifiedBy: string;

  // @DataMember
  public id: string;

  // @DataMember
  public entityType: string;

  public constructor(init?: Partial<EntityGroup>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class CreateEntityGroupRequestBase implements ICustomerItemRequest, IDescription {
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public updateEntityGroups: boolean;

  // @DataMember
  public entityGroups: string[];

  // @DataMember(IsRequired=true)
  public entity: EntityGroup;

  // @DataMember
  public description: string;

  public constructor(init?: Partial<CreateEntityGroupRequestBase>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class UpdateEntityGroupRequestBase
  implements ICustomerItemRequest, IBaseEntityItemRequest, IDescription
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public updateEntityGroups: boolean;

  // @DataMember
  public entityGroups: string[];

  // @DataMember(IsRequired=true)
  public id: string;

  // @DataMember(IsRequired=true)
  public entity: EntityGroup;

  // @DataMember
  public description: string;

  public constructor(init?: Partial<UpdateEntityGroupRequestBase>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class DeleteEntityGroupsRequestBase implements ICustomerItemRequest, IDescription {
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public description: string;

  // @DataMember(IsRequired=true)
  public ids: string[];

  public constructor(init?: Partial<DeleteEntityGroupsRequestBase>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class GetEntityGroupSharesRequestBase
  implements IBaseEntityItemRequest, ICustomerItemRequest
{
  // @DataMember
  public id: string;

  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public entityGroups: string[];

  // @DataMember
  public itemsIds: string[];

  // @DataMember
  public onlyOwnedItems: boolean;

  // @DataMember
  public page: number;

  // @DataMember
  public items: number;

  // @DataMember
  public sort: string;

  // @DataMember
  public filter: string;

  // @DataMember
  public propertiesTest: { [index: string]: number };

  public constructor(init?: Partial<GetEntityGroupSharesRequestBase>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class EntityGroupPermissions implements IEntityTyped, ICustomerEntityViewModel {
  // @DataMember
  public customerId: string;

  // @DataMember
  public customerName: string;

  // @DataMember
  public entityGroupId: string;

  // @DataMember
  public permissions: string[];

  // @DataMember
  public entityType: string;

  public constructor(init?: Partial<EntityGroupPermissions>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class UpgradeEntityGroupShareRequestBase implements ICustomerItemRequest, IDescription {
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember(IsRequired=true)
  public id: string;

  // @DataMember(IsRequired=true)
  public entity: EntityGroupPermissions;

  // @DataMember
  public description: string;

  public constructor(init?: Partial<UpgradeEntityGroupShareRequestBase>) {
    (Object as any).assign(this, init);
  }
}

export enum PriorityEventType {
  LeaveRoute = 'LeaveRoute',
  OverSpeed = 'OverSpeed',
  Sos = 'Sos',
  LongStopOnRoute = 'LongStopOnRoute',
}

// @DataContract
export class PriorityEvent
  implements ICustomerEntityViewModel, IChangeKey, IDescription, IModified, IEntity
{
  // @DataMember
  public vehicleId: string;

  // @DataMember
  public evenDate: string;

  // @DataMember
  public eventType: PriorityEventType;

  // @DataMember
  public vehicleName: string;

  // @DataMember
  public customerId: string;

  // @DataMember
  public customerName: string;

  // @DataMember
  public description: string;

  // @DataMember
  public changeKey: string;

  // @DataMember
  public created: string;

  // @DataMember
  public createdBy: string;

  // @DataMember
  public modified: string;

  // @DataMember
  public modifiedBy: string;

  // @DataMember
  public id: string;

  // @DataMember
  public entityType: string;

  public constructor(init?: Partial<PriorityEvent>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class PrioritySettings {
  // @DataMember
  public remotePedestrianDuration: number;

  // @DataMember
  public remoteAdaptiveDuration: number;

  // @DataMember
  public remoteDistance: number;

  // @DataMember
  public remoteMinPeriod: number;

  // @DataMember
  public alarmSpeedLimit: number;

  // @DataMember
  public alarmStopDuration: number;

  public constructor(init?: Partial<PrioritySettings>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class EntityEventLogDetails {
  // @DataMember
  public id: number;

  // @DataMember
  public eventTime: string;

  // @DataMember
  public eventId: number;

  // @DataMember
  public message: string;

  // @DataMember
  public data: string;

  public constructor(init?: Partial<EntityEventLogDetails>) {
    (Object as any).assign(this, init);
  }
}

export interface ICustomerIntegrationIdRequest {
  customerIntegrationId: string;
}

export interface IChangeKey extends IReadonlyChangeKey {
  // @DataMember
  changeKey: string;
}

export interface IReadonlyChangeKey {
  // @DataMember
  changeKey: string;
}

// @DataContract
export enum PublicTransportCarrierType {
  Municipal = 'Municipal',
  Commercial = 'Commercial',
}

export class CustomerNamedBaseEntityViewModelBase
  implements ICustomerEntityViewModel, INamedEntity, IChangeKey, IDescription, IModified, IEntity
{
  // @DataMember
  public customerId: string;

  // @DataMember
  public customerName: string;

  // @DataMember
  public name: string;

  // @DataMember
  public description: string;

  // @DataMember
  public changeKey: string;

  // @DataMember
  public created: string;

  // @DataMember
  public createdBy: string;

  // @DataMember
  public modified: string;

  // @DataMember
  public modifiedBy: string;

  // @DataMember
  public id: string;

  // @DataMember
  public entityType: string;

  public constructor(init?: Partial<CustomerNamedBaseEntityViewModelBase>) {
    (Object as any).assign(this, init);
  }
}

export interface IEntity {}

export interface IEntityTyped {
  // @DataMember
  entityType: string;
}

export interface IModified {
  // @DataMember
  modified: string;

  // @DataMember
  modifiedBy: string;
}

export interface INamedEntity extends IEntity, INamed {}

export interface INamed {
  // @DataMember
  name: string;
}

export interface ICustomerEntityViewModel extends ICustomerName, ICustomerEntity {}

export interface ICustomerName {
  // @DataMember
  customerName: string;
}

// @DataContract
export class EntityGroupInfo
  implements ICustomerEntityViewModel, INamedEntity, IChangeKey, IDescription, IModified, IEntity
{
  // @DataMember
  public itemsCount: number;

  // @DataMember
  public customerId: string;

  // @DataMember
  public customerName: string;

  // @DataMember
  public name: string;

  // @DataMember
  public description: string;

  // @DataMember
  public changeKey: string;

  // @DataMember
  public created: string;

  // @DataMember
  public createdBy: string;

  // @DataMember
  public modified: string;

  // @DataMember
  public modifiedBy: string;

  // @DataMember
  public id: string;

  // @DataMember
  public entityType: string;

  public constructor(init?: Partial<EntityGroupInfo>) {
    (Object as any).assign(this, init);
  }
}

export enum PublicTransportVehicleType {
  Bus = 'Bus',
  Train = 'Train',
  Trolleybus = 'Trolleybus',
}

// @DataContract
export class RouteWayStop {
  // @DataMember
  public id: string;

  // @DataMember
  public lat: number;

  // @DataMember
  public lon: number;

  // @DataMember
  public name: string;

  /**
   * Время прибытия от старта маршрута, мин.
   */
  // @DataMember
  // @ApiMember(Description="Время прибытия от старта маршрута, мин.")
  public arriveTime: number;

  // @DataMember
  public wayOrder: number;

  public constructor(init?: Partial<RouteWayStop>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class StepMovements {
  // @DataMember
  public movements: TrafficMovementType[];

  // @DataMember
  public phase: number;

  public constructor(init?: Partial<StepMovements>) {
    (Object as any).assign(this, init);
  }
}

export enum TrafficObjectType {
  Adaptive = 'Adaptive',
  Pedestrian = 'Pedestrian',
}

// @DataContract
export enum TrafficControllerType {
  None = 'None',
  Potok = 'Potok',
  Usdk = 'Usdk',
  Megapolis = 'Megapolis',
}

// @DataContract
export class RouteWayTrafficObject {
  // @DataMember
  public trafficObjectId: string;

  // @DataMember
  public lat: number;

  // @DataMember
  public lon: number;

  // @DataMember
  public name: string;

  // @DataMember
  public movement: StepMovements;

  // @DataMember
  public type: TrafficObjectType;

  // @DataMember
  public controllerType: TrafficControllerType;

  // @DataMember
  public wayOrder: number;

  public constructor(init?: Partial<RouteWayTrafficObject>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class RouteWay {
  // @DataMember
  public stops: RouteWayStop[];

  // @DataMember
  public trafficObjects: RouteWayTrafficObject[];

  // @DataMember
  public geoJson: string;

  public constructor(init?: Partial<RouteWay>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class PublicTransportRouteSettings {
  // @DataMember
  public forward: RouteWay;

  // @DataMember
  public backward: RouteWay;

  public constructor(init?: Partial<PublicTransportRouteSettings>) {
    (Object as any).assign(this, init);
  }
}

export enum RouteWayObjectType {
  Stop = 'Stop',
  TrafficObject = 'TrafficObject',
}

// @DataContract
export class RouteWayObject {
  // @DataMember
  public id: string;

  // @DataMember
  public position: number;

  // @DataMember
  public lat: number;

  // @DataMember
  public lon: number;

  // @DataMember
  public name: string;

  // @DataMember
  public order: number;

  // @DataMember
  public type: RouteWayObjectType;

  // @DataMember
  public trafficObjectType: TrafficObjectType;

  // @DataMember
  public trafficControllerType: TrafficControllerType;

  // @DataMember
  public movement: StepMovements;

  public constructor(init?: Partial<RouteWayObject>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class PublicTransportRouteSceneSettings {
  // @DataMember
  public geoJson: string;

  // @DataMember
  public totalLength: number;

  // @DataMember
  public objects: RouteWayObject[];

  public constructor(init?: Partial<PublicTransportRouteSceneSettings>) {
    (Object as any).assign(this, init);
  }
}

export interface IPosition extends ICoordinate {
  altitude?: number;
}

// @DataContract
export class PublicTransportScheduleWeek {
  // @DataMember(Order=0)
  public id: number;

  // @DataMember(Order=1)
  public monday: number;

  // @DataMember(Order=2)
  public tuesday: number;

  // @DataMember(Order=3)
  public wednesday: number;

  // @DataMember(Order=4)
  public thursday: number;

  // @DataMember(Order=5)
  public friday: number;

  // @DataMember(Order=6)
  public saturday: number;

  // @DataMember(Order=7)
  public sunday: number;

  public constructor(init?: Partial<PublicTransportScheduleWeek>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class PublicTransportScheduleDayTime {
  /**
   * Выезд маршрута с начальной точки
   */
  // @DataMember
  // @ApiMember(Description="Выезд маршрута с начальной точки")
  public time: string;

  public constructor(init?: Partial<PublicTransportScheduleDayTime>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class PublicTransportScheduleInterval {
  /**
   * Время начала действия интервала
   */
  // @DataMember
  // @ApiMember(Description="Время начала действия интервала")
  public time: string;

  // @DataMember
  public interval: number;

  public constructor(init?: Partial<PublicTransportScheduleInterval>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class PublicTransportScheduleDay {
  // @DataMember(Order=1)
  public id: number;

  // @DataMember(Order=2)
  public forward: PublicTransportScheduleDayTime[];

  // @DataMember(Order=3)
  public backward: PublicTransportScheduleDayTime[];

  // @DataMember(Order=4)
  public intervals: PublicTransportScheduleInterval[];

  public constructor(init?: Partial<PublicTransportScheduleDay>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class PublicTransportScheduleSettings {
  // @DataMember
  public week: PublicTransportScheduleWeek;

  // @DataMember
  public days: PublicTransportScheduleDay[];

  public constructor(init?: Partial<PublicTransportScheduleSettings>) {
    (Object as any).assign(this, init);
  }
}

export interface ICoordinate {
  lat: number;
  lon: number;
}

// @DataContract
export enum TrafficObjectMode {
  Off = 'Off',
  Coordination = 'Coordination',
  Adaptive = 'Adaptive',
  Remote = 'Remote',
  Local = 'Local',
}

// @DataContract
export enum TrafficObjectState {
  Off = 'Off',
  YellowBlink = 'YellowBlink',
  AllRed = 'AllRed',
  Phase = 'Phase',
}

// @DataContract
export class TrafficObjectIconStatus {
  // @DataMember
  public color: string;

  // @DataMember
  public borderColor: string;

  // @DataMember
  public processing: boolean;

  public constructor(init?: Partial<TrafficObjectIconStatus>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export enum TrafficMovementLight {
  Off = 'Off',
  Red = 'Red',
  Yellow = 'Yellow',
  Green = 'Green',
  RedYellow = 'RedYellow',
  YellowBlink = 'YellowBlink',
  GreenBlink = 'GreenBlink',
}

// @DataContract
export class TrafficSchemaLight {
  // @DataMember(Order=0)
  public move1: TrafficMovementLight;

  // @DataMember(Order=1)
  public move2: TrafficMovementLight;

  // @DataMember(Order=2)
  public move3: TrafficMovementLight;

  // @DataMember(Order=3)
  public move4: TrafficMovementLight;

  // @DataMember(Order=4)
  public move5: TrafficMovementLight;

  // @DataMember(Order=5)
  public move6: TrafficMovementLight;

  // @DataMember(Order=6)
  public move7: TrafficMovementLight;

  // @DataMember(Order=7)
  public move8: TrafficMovementLight;

  // @DataMember(Order=8)
  public move12: TrafficMovementLight;

  // @DataMember(Order=9)
  public move14: TrafficMovementLight;

  // @DataMember(Order=10)
  public move16: TrafficMovementLight;

  // @DataMember(Order=11)
  public move18: TrafficMovementLight;

  // @DataMember(Order=12)
  public moveP2: TrafficMovementLight;

  // @DataMember(Order=13)
  public moveP4: TrafficMovementLight;

  // @DataMember(Order=14)
  public moveP6: TrafficMovementLight;

  // @DataMember(Order=15)
  public moveP8: TrafficMovementLight;

  public constructor(init?: Partial<TrafficSchemaLight>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class PedestrianAdaptiveStatus implements IEnabled {
  // @DataMember
  public enabled: boolean;

  // @DataMember
  public active: boolean;

  public constructor(init?: Partial<PedestrianAdaptiveStatus>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class PublicTransportAdaptiveStatus implements IEnabled {
  // @DataMember
  public enabled: boolean;

  // @DataMember
  public active: boolean;

  public constructor(init?: Partial<PublicTransportAdaptiveStatus>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class SpecialTransportAdaptiveStatus implements IEnabled {
  // @DataMember
  public enabled: boolean;

  // @DataMember
  public active: boolean;

  public constructor(init?: Partial<SpecialTransportAdaptiveStatus>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class TransportAdaptiveStatus implements IEnabled {
  // @DataMember
  public enabled: boolean;

  // @DataMember
  public active: boolean;

  public constructor(init?: Partial<TransportAdaptiveStatus>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class LocalAdaptiveStatus implements IEnabled {
  // @DataMember
  public enabled: boolean;

  // @DataMember
  public active: boolean;

  public constructor(init?: Partial<LocalAdaptiveStatus>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class TrafficObjectAdaptiveStatus {
  // @DataMember
  public pedestrian: PedestrianAdaptiveStatus;

  // @DataMember
  public publicTransport: PublicTransportAdaptiveStatus;

  // @DataMember
  public specialTransport: SpecialTransportAdaptiveStatus;

  // @DataMember
  public transport: TransportAdaptiveStatus;

  // @DataMember
  public local: LocalAdaptiveStatus;

  public constructor(init?: Partial<TrafficObjectAdaptiveStatus>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class TrafficObjectStatus implements IEntity {
  // @DataMember
  public serverTime: string;

  // @DataMember
  public stateTime: string;

  // @DataMember
  public mode: TrafficObjectMode;

  // @DataMember
  public state: TrafficObjectState;

  // @DataMember
  public iconStatus: TrafficObjectIconStatus;

  // @DataMember
  public online: boolean;

  // @DataMember
  public controllerLastConnectionTime: string;

  // @DataMember
  public controllerLastPacketTime: string;

  // @DataMember
  public controllerTime: string;

  // @DataMember
  public controllerStatusDescription: string;

  // @DataMember
  public controllerErrorDescription: string;

  // @DataMember
  public schemaLights: TrafficSchemaLight;

  // @DataMember
  public commandProcessing: boolean;

  // @DataMember
  public coordinationPlan: number;

  // @DataMember
  public planTime: number;

  // @DataMember
  public adaptiveStatus: TrafficObjectAdaptiveStatus;

  // @DataMember
  public id: string;

  // @DataMember
  public entityType: string;

  public constructor(init?: Partial<TrafficObjectStatus>) {
    (Object as any).assign(this, init);
  }
}

export enum DirectionType {
  Forward = 'Forward',
  Backward = 'Backward',
  Unknown = 'Unknown',
}

// @DataContract
export enum StopStatusType {
  Unknown = 'Unknown',
  Normal = 'Normal',
  Delay = 'Delay',
}

// @DataContract
export class StopIconStatus {
  // @DataMember
  public color: string;

  // @DataMember
  public borderColor: string;

  public constructor(init?: Partial<StopIconStatus>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class StopStatus {
  // @DataMember
  public routeId: string;

  // @DataMember
  public stopId: string;

  // @DataMember
  public lat: number;

  // @DataMember
  public lon: number;

  // @DataMember
  public routeOrder: number;

  // @DataMember
  public directionType: DirectionType;

  // @DataMember
  public name: string;

  // @DataMember
  public stopStatusType: StopStatusType;

  // @DataMember
  public description: string;

  // @DataMember
  public iconStatus: StopIconStatus;

  // @DataMember
  public lastArrivedTime: string;

  public constructor(init?: Partial<StopStatus>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class VehicleIconStatus {
  // @DataMember
  public color: string;

  // @DataMember
  public borderColor: string;

  // @DataMember
  public processing: boolean;

  public constructor(init?: Partial<VehicleIconStatus>) {
    (Object as any).assign(this, init);
  }
}

export class PublicTransportVehicleSceneStatus {
  // @DataMember
  public id: string;

  // @DataMember
  public serverTime: string;

  // @DataMember
  public vehicleTime: string;

  // @DataMember
  public online: boolean;

  // @DataMember
  public iconStatus: VehicleIconStatus;

  // @DataMember
  public position: number;

  // @DataMember
  public routeId?: string;

  // @DataMember
  public routeName: string;

  // @DataMember
  public vehicleName: string;

  // @DataMember
  public description: string;

  // @DataMember
  public directionType: DirectionType;

  public constructor(init?: Partial<PublicTransportVehicleSceneStatus>) {
    (Object as any).assign(this, init);
  }
}

export class CustomerBaseEntityViewModelBase
  implements ICustomerEntityViewModel, IChangeKey, IDescription, IModified, IEntity
{
  // @DataMember
  public customerId: string;

  // @DataMember
  public customerName: string;

  // @DataMember
  public description: string;

  // @DataMember
  public changeKey: string;

  // @DataMember
  public created: string;

  // @DataMember
  public createdBy: string;

  // @DataMember
  public modified: string;

  // @DataMember
  public modifiedBy: string;

  // @DataMember
  public id: string;

  // @DataMember
  public entityType: string;

  public constructor(init?: Partial<CustomerBaseEntityViewModelBase>) {
    (Object as any).assign(this, init);
  }
}

export enum GlonassTerminalType {
  None = 'None',
  Navtelecom = 'Navtelecom',
  Mukgt = 'Mukgt',
  NavtelecomS2333 = 'NavtelecomS2333',
  NavtelecomS2433 = 'NavtelecomS2433',
  NavtelecomS2551 = 'NavtelecomS2551',
  NavtelecomS2651 = 'NavtelecomS2651',
  NavtelecomS2653 = 'NavtelecomS2653',
}

export class CustomerDeviceViewModelBase
  implements
    ICustomerEntity,
    ICustomerName,
    IDeviceItem,
    IOwnedDeviceItem,
    IChangeKey,
    IDescription,
    IModified,
    IEntity
{
  // @DataMember
  public customerId: string;

  // @DataMember
  public customerName: string;

  // @DataMember
  public ownerObjectName: string;

  // @DataMember
  public ownerObjectId: string;

  // @DataMember
  public prefix: string;

  // @DataMember
  public number: string;

  // @DataMember
  public phone1: string;

  // @DataMember
  public phone2: string;

  // @DataMember
  public ip1: string;

  // @DataMember
  public ip2: string;

  // @DataMember
  public imei: string;

  // @DataMember
  public deviceSettings: string;

  // @DataMember
  public lat: number;

  // @DataMember
  public lon: number;

  // @DataMember
  public direction: number;

  // @DataMember
  public fullNumber: string;

  // @DataMember
  public softwareVersion: string;

  // @DataMember
  public firmwareVersion: string;

  // @DataMember
  public hardwareVersion: string;

  // @DataMember
  public description: string;

  // @DataMember
  public changeKey: string;

  // @DataMember
  public created: string;

  // @DataMember
  public createdBy: string;

  // @DataMember
  public modified: string;

  // @DataMember
  public modifiedBy: string;

  // @DataMember
  public id: string;

  // @DataMember
  public entityType: string;

  public constructor(init?: Partial<CustomerDeviceViewModelBase>) {
    (Object as any).assign(this, init);
  }
}

export interface IDeviceItem extends IDeviceVersion {
  // @DataMember
  prefix: string;

  // @DataMember
  number: string;

  // @DataMember
  phone1: string;

  // @DataMember
  phone2: string;

  // @DataMember
  ip1: string;

  // @DataMember
  ip2: string;

  // @DataMember
  imei: string;

  // @DataMember
  lat: number;

  // @DataMember
  lon: number;

  // @DataMember
  direction: number;
}

export interface IDeviceVersion {
  // @DataMember
  softwareVersion: string;

  // @DataMember
  firmwareVersion: string;

  // @DataMember
  hardwareVersion: string;
}

export interface IOwnedDeviceItem {
  // @DataMember
  ownerObjectName: string;

  // @DataMember
  ownerObjectId: string;
}

// @DataContract
export enum EntityEventLogLevel {
  Trace = 'Trace',
  Debug = 'Debug',
  Info = 'Info',
  Warn = 'Warn',
  Error = 'Error',
  Fatal = 'Fatal',
}

// @DataContract
export class EntityEventLogType {
  // @DataMember
  public id: number;

  // @DataMember
  public name: string;

  // @DataMember
  public level: EntityEventLogLevel;

  public constructor(init?: Partial<EntityEventLogType>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class BaseEntityViewModelBase implements IChangeKey, IDescription, IModified, IEntity {
  // @DataMember
  public description: string;

  // @DataMember
  public changeKey: string;

  // @DataMember
  public created: string;

  // @DataMember
  public createdBy: string;

  // @DataMember
  public modified: string;

  // @DataMember
  public modifiedBy: string;

  // @DataMember
  public id: string;

  // @DataMember
  public entityType: string;

  public constructor(init?: Partial<BaseEntityViewModelBase>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class ResponseError {
  // @DataMember(Order=1)
  public errorCode: string;

  // @DataMember(Order=2)
  public fieldName: string;

  // @DataMember(Order=3)
  public message: string;

  // @DataMember(Order=4)
  public meta: { [index: string]: string };

  public constructor(init?: Partial<ResponseError>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class ResponseStatus {
  // @DataMember(Order=1)
  public errorCode: string;

  // @DataMember(Order=2)
  public message: string;

  // @DataMember(Order=3)
  public stackTrace: string;

  // @DataMember(Order=4)
  public errors: ResponseError[];

  // @DataMember(Order=5)
  public meta: { [index: string]: string };

  public constructor(init?: Partial<ResponseStatus>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class DeleteItemsRequest implements IDescription {
  // @DataMember
  public description: string;

  // @DataMember(IsRequired=true)
  public ids: string[];

  public constructor(init?: Partial<DeleteItemsRequest>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class NamedBaseEntityViewModelBase
  implements INamedEntity, IChangeKey, IDescription, IModified, IEntity
{
  // @DataMember
  public name: string;

  // @DataMember
  public description: string;

  // @DataMember
  public changeKey: string;

  // @DataMember
  public created: string;

  // @DataMember
  public createdBy: string;

  // @DataMember
  public modified: string;

  // @DataMember
  public modifiedBy: string;

  // @DataMember
  public id: string;

  // @DataMember
  public entityType: string;

  public constructor(init?: Partial<NamedBaseEntityViewModelBase>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class EntityViewModelBase implements IEntity {
  // @DataMember
  public id: string;

  // @DataMember
  public entityType: string;

  public constructor(init?: Partial<EntityViewModelBase>) {
    (Object as any).assign(this, init);
  }
}

export class DeviceViewModelBase
  implements IDeviceItem, IOwnedDeviceItem, IChangeKey, IDescription, IModified, IEntity
{
  // @DataMember
  public ownerObjectName: string;

  // @DataMember
  public ownerObjectId: string;

  // @DataMember
  public prefix: string;

  // @DataMember
  public number: string;

  // @DataMember
  public phone1: string;

  // @DataMember
  public phone2: string;

  // @DataMember
  public ip1: string;

  // @DataMember
  public ip2: string;

  // @DataMember
  public imei: string;

  // @DataMember
  public deviceSettings: string;

  // @DataMember
  public lat: number;

  // @DataMember
  public lon: number;

  // @DataMember
  public direction: number;

  // @DataMember
  public fullNumber: string;

  // @DataMember
  public softwareVersion: string;

  // @DataMember
  public firmwareVersion: string;

  // @DataMember
  public hardwareVersion: string;

  // @DataMember
  public description: string;

  // @DataMember
  public changeKey: string;

  // @DataMember
  public created: string;

  // @DataMember
  public createdBy: string;

  // @DataMember
  public modified: string;

  // @DataMember
  public modifiedBy: string;

  // @DataMember
  public id: string;

  // @DataMember
  public entityType: string;

  public constructor(init?: Partial<DeviceViewModelBase>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class PageRequestBase {
  // @DataMember
  public page: number;

  // @DataMember
  public items: number;

  // @DataMember
  public sort: string;

  // @DataMember
  public filter: string;

  // @DataMember
  public propertiesTest: { [index: string]: number };

  public constructor(init?: Partial<PageRequestBase>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export enum TrafficMovementType {
  Move1 = 'Move1',
  Move2 = 'Move2',
  Move3 = 'Move3',
  Move4 = 'Move4',
  Move5 = 'Move5',
  Move6 = 'Move6',
  Move7 = 'Move7',
  Move8 = 'Move8',
  Move12 = 'Move12',
  Move14 = 'Move14',
  Move16 = 'Move16',
  Move18 = 'Move18',
  MoveP2 = 'MoveP2',
  MoveP4 = 'MoveP4',
  MoveP6 = 'MoveP6',
  MoveP8 = 'MoveP8',
}

export class AdaptiveStatusBase implements IEnabled {
  // @DataMember
  public enabled: boolean;

  // @DataMember
  public active: boolean;

  public constructor(init?: Partial<AdaptiveStatusBase>) {
    (Object as any).assign(this, init);
  }
}

export interface IEnabled {
  enabled: boolean;
}

export class PublicTransportCarrier
  implements ICustomerEntityViewModel, INamedEntity, IChangeKey, IDescription, IModified, IEntity
{
  // @DataMember
  public carrierType: PublicTransportCarrierType;

  // @DataMember
  public customerId: string;

  // @DataMember
  public customerName: string;

  // @DataMember
  public name: string;

  // @DataMember
  public description: string;

  // @DataMember
  public changeKey: string;

  // @DataMember
  public created: string;

  // @DataMember
  public createdBy: string;

  // @DataMember
  public modified: string;

  // @DataMember
  public modifiedBy: string;

  // @DataMember
  public id: string;

  // @DataMember
  public entityType: string;

  public constructor(init?: Partial<PublicTransportCarrier>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class GetPublicTransportCarriersResponse implements IChangeKey {
  // @DataMember
  public items: PublicTransportCarrier[];

  // @DataMember
  public total: number;

  // @DataMember
  public page: number;

  // @DataMember
  public itemsPerPage: number;

  // @DataMember
  public changeKey: string;

  public constructor(init?: Partial<GetPublicTransportCarriersResponse>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class GetEntityGroupsResponse implements IChangeKey {
  // @DataMember
  public items: EntityGroupInfo[];

  // @DataMember
  public total: number;

  // @DataMember
  public page: number;

  // @DataMember
  public itemsPerPage: number;

  // @DataMember
  public changeKey: string;

  public constructor(init?: Partial<GetEntityGroupsResponse>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class GetEntityGroupSharesResponse implements IChangeKey {
  // @DataMember
  public items: EntityGroupPermissions[];

  // @DataMember
  public total: number;

  // @DataMember
  public page: number;

  // @DataMember
  public itemsPerPage: number;

  // @DataMember
  public changeKey: string;

  public constructor(init?: Partial<GetEntityGroupSharesResponse>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class PublicTransportRoute
  implements ICustomerEntityViewModel, INamedEntity, IChangeKey, IDescription, IModified, IEntity
{
  // @DataMember
  public vehicleType: PublicTransportVehicleType;

  // @DataMember
  public settings: PublicTransportRouteSettings;

  // @DataMember
  public verified: boolean;

  // @DataMember
  public customerId: string;

  // @DataMember
  public customerName: string;

  // @DataMember
  public name: string;

  // @DataMember
  public description: string;

  // @DataMember
  public changeKey: string;

  // @DataMember
  public created: string;

  // @DataMember
  public createdBy: string;

  // @DataMember
  public modified: string;

  // @DataMember
  public modifiedBy: string;

  // @DataMember
  public id: string;

  // @DataMember
  public entityType: string;

  public constructor(init?: Partial<PublicTransportRoute>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class GetPublicTransportRoutesResponse implements IChangeKey {
  // @DataMember
  public items: PublicTransportRoute[];

  // @DataMember
  public total: number;

  // @DataMember
  public page: number;

  // @DataMember
  public itemsPerPage: number;

  // @DataMember
  public changeKey: string;

  public constructor(init?: Partial<GetPublicTransportRoutesResponse>) {
    (Object as any).assign(this, init);
  }
}

export class PublicTransportRouteScene
  implements ICustomerEntityViewModel, INamedEntity, IChangeKey, IDescription, IModified, IEntity
{
  // @DataMember
  public forward: PublicTransportRouteSceneSettings;

  // @DataMember
  public backward: PublicTransportRouteSceneSettings;

  // @DataMember
  public customerId: string;

  // @DataMember
  public customerName: string;

  // @DataMember
  public name: string;

  // @DataMember
  public description: string;

  // @DataMember
  public changeKey: string;

  // @DataMember
  public created: string;

  // @DataMember
  public createdBy: string;

  // @DataMember
  public modified: string;

  // @DataMember
  public modifiedBy: string;

  // @DataMember
  public id: string;

  // @DataMember
  public entityType: string;

  public constructor(init?: Partial<PublicTransportRouteScene>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class PublicTransportSchedule
  implements ICustomerEntityViewModel, INamedEntity, IChangeKey, IDescription, IModified, IEntity
{
  /**
   * Маршрут
   */
  // @DataMember
  // @ApiMember(Description="Маршрут")
  public routeId: string;

  // @DataMember
  public routeName: string;

  // @DataMember
  public routeVehicleType: PublicTransportVehicleType;

  /**
   * Действует с
   */
  // @DataMember
  // @ApiMember(Description="Действует с")
  public from: string;

  /**
   * Настройки расписания
   */
  // @DataMember
  // @ApiMember(Description="Настройки расписания")
  public settings: PublicTransportScheduleSettings;

  // @DataMember
  public customerId: string;

  // @DataMember
  public customerName: string;

  // @DataMember
  public name: string;

  // @DataMember
  public description: string;

  // @DataMember
  public changeKey: string;

  // @DataMember
  public created: string;

  // @DataMember
  public createdBy: string;

  // @DataMember
  public modified: string;

  // @DataMember
  public modifiedBy: string;

  // @DataMember
  public id: string;

  // @DataMember
  public entityType: string;

  public constructor(init?: Partial<PublicTransportSchedule>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class GetPublicTransportSchedulesResponse implements IChangeKey {
  // @DataMember
  public items: PublicTransportSchedule[];

  // @DataMember
  public total: number;

  // @DataMember
  public page: number;

  // @DataMember
  public itemsPerPage: number;

  // @DataMember
  public changeKey: string;

  public constructor(init?: Partial<GetPublicTransportSchedulesResponse>) {
    (Object as any).assign(this, init);
  }
}

export class PublicTransportStop
  implements
    ICoordinate,
    ICustomerEntityViewModel,
    INamedEntity,
    IChangeKey,
    IDescription,
    IModified,
    IEntity
{
  // @DataMember
  public lat: number;

  // @DataMember
  public lon: number;

  // @DataMember
  public address: string;

  // @DataMember
  public customerId: string;

  // @DataMember
  public customerName: string;

  // @DataMember
  public name: string;

  // @DataMember
  public description: string;

  // @DataMember
  public changeKey: string;

  // @DataMember
  public created: string;

  // @DataMember
  public createdBy: string;

  // @DataMember
  public modified: string;

  // @DataMember
  public modifiedBy: string;

  // @DataMember
  public id: string;

  // @DataMember
  public entityType: string;

  public constructor(init?: Partial<PublicTransportStop>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class GetPublicTransportStopsResponse implements IChangeKey {
  // @DataMember
  public items: PublicTransportStop[];

  // @DataMember
  public total: number;

  // @DataMember
  public page: number;

  // @DataMember
  public itemsPerPage: number;

  // @DataMember
  public changeKey: string;

  public constructor(init?: Partial<GetPublicTransportStopsResponse>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class GetPublicTransportVehiclePriorityEventsResponse implements IChangeKey {
  // @DataMember
  public items: PriorityEvent[];

  // @DataMember
  public total: number;

  // @DataMember
  public page: number;

  // @DataMember
  public itemsPerPage: number;

  // @DataMember
  public changeKey: string;

  public constructor(init?: Partial<GetPublicTransportVehiclePriorityEventsResponse>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class PublicTransportPrioritySettings
  implements ICustomerEntityViewModel, IChangeKey, IDescription, IModified, IEntity
{
  // @DataMember
  public settings: PrioritySettings;

  // @DataMember
  public customerId: string;

  // @DataMember
  public customerName: string;

  // @DataMember
  public description: string;

  // @DataMember
  public changeKey: string;

  // @DataMember
  public created: string;

  // @DataMember
  public createdBy: string;

  // @DataMember
  public modified: string;

  // @DataMember
  public modifiedBy: string;

  // @DataMember
  public id: string;

  // @DataMember
  public entityType: string;

  public constructor(init?: Partial<PublicTransportPrioritySettings>) {
    (Object as any).assign(this, init);
  }
}

export class PublicTransportVehicle
  implements ICustomerEntityViewModel, IChangeKey, IDescription, IModified, IEntity
{
  // @DataMember
  public model: string;

  // @DataMember
  public number: string;

  // @DataMember
  public vehicleType: PublicTransportVehicleType;

  // @DataMember
  public carrierId?: string;

  // @DataMember
  public carrierName: string;

  // @DataMember
  public routeId?: string;

  // @DataMember
  public routeName: string;

  // @DataMember
  public terminalId?: string;

  // @DataMember
  public terminalNumber: string;

  // @DataMember
  public terminalType: GlonassTerminalType;

  // @DataMember
  public customerId: string;

  // @DataMember
  public customerName: string;

  // @DataMember
  public description: string;

  // @DataMember
  public changeKey: string;

  // @DataMember
  public created: string;

  // @DataMember
  public createdBy: string;

  // @DataMember
  public modified: string;

  // @DataMember
  public modifiedBy: string;

  // @DataMember
  public id: string;

  // @DataMember
  public entityType: string;

  public constructor(init?: Partial<PublicTransportVehicle>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class GetPublicTransportVehiclesResponse implements IChangeKey {
  // @DataMember
  public items: PublicTransportVehicle[];

  // @DataMember
  public total: number;

  // @DataMember
  public page: number;

  // @DataMember
  public itemsPerPage: number;

  // @DataMember
  public changeKey: string;

  public constructor(init?: Partial<GetPublicTransportVehiclesResponse>) {
    (Object as any).assign(this, init);
  }
}

export class PublicTransportVehicleStatus implements IEntity, ICoordinate, ICustomerEntity {
  // @DataMember
  public id: string;

  // @DataMember
  public entityType: string;

  // @DataMember
  public serverTime: string;

  // @DataMember
  public sos: boolean;

  // @DataMember
  public vehicleTime: string;

  // @DataMember
  public online: boolean;

  // @DataMember
  public lat: number;

  // @DataMember
  public lon: number;

  // @DataMember
  public speed: number;

  // @DataMember
  public direction: number;

  // @DataMember
  public routeId?: string;

  // @DataMember
  public routeName: string;

  // @DataMember
  public model: string;

  // @DataMember
  public number: string;

  // @DataMember
  public terminalId?: string;

  // @DataMember
  public terminalNumber: string;

  // @DataMember
  public carrierId?: string;

  // @DataMember
  public carrierName: string;

  // @DataMember
  public customerId: string;

  // @DataMember
  public customerName: string;

  // @DataMember
  public vehicleType: PublicTransportVehicleType;

  // @DataMember
  public terminalType: GlonassTerminalType;

  public constructor(init?: Partial<PublicTransportVehicleStatus>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class GetPublicTransportVehicleStatusesResponse implements IChangeKey {
  // @DataMember
  public items: PublicTransportVehicleStatus[];

  // @DataMember
  public total: number;

  // @DataMember
  public page: number;

  // @DataMember
  public itemsPerPage: number;

  // @DataMember
  public changeKey: string;

  public constructor(init?: Partial<GetPublicTransportVehicleStatusesResponse>) {
    (Object as any).assign(this, init);
  }
}

export class PublicTransportGlonassTerminal
  implements
    ICustomerEntity,
    ICustomerName,
    IDeviceItem,
    IOwnedDeviceItem,
    IChangeKey,
    IDescription,
    IModified,
    IEntity
{
  // @DataMember
  public terminalType: GlonassTerminalType;

  // @DataMember
  public customerId: string;

  // @DataMember
  public customerName: string;

  // @DataMember
  public ownerObjectName: string;

  // @DataMember
  public ownerObjectId: string;

  // @DataMember
  public prefix: string;

  // @DataMember
  public number: string;

  // @DataMember
  public phone1: string;

  // @DataMember
  public phone2: string;

  // @DataMember
  public ip1: string;

  // @DataMember
  public ip2: string;

  // @DataMember
  public imei: string;

  // @DataMember
  public deviceSettings: string;

  // @DataMember
  public lat: number;

  // @DataMember
  public lon: number;

  // @DataMember
  public direction: number;

  // @DataMember
  public fullNumber: string;

  // @DataMember
  public softwareVersion: string;

  // @DataMember
  public firmwareVersion: string;

  // @DataMember
  public hardwareVersion: string;

  // @DataMember
  public description: string;

  // @DataMember
  public changeKey: string;

  // @DataMember
  public created: string;

  // @DataMember
  public createdBy: string;

  // @DataMember
  public modified: string;

  // @DataMember
  public modifiedBy: string;

  // @DataMember
  public id: string;

  // @DataMember
  public entityType: string;

  public constructor(init?: Partial<PublicTransportGlonassTerminal>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class GetPublicTransportGlonassTerminalsResponse implements IChangeKey {
  // @DataMember
  public items: PublicTransportGlonassTerminal[];

  // @DataMember
  public total: number;

  // @DataMember
  public page: number;

  // @DataMember
  public itemsPerPage: number;

  // @DataMember
  public changeKey: string;

  public constructor(init?: Partial<GetPublicTransportGlonassTerminalsResponse>) {
    (Object as any).assign(this, init);
  }
}

export class GetDeviceEventLogResponse implements IChangeKey {
  // @DataMember
  public items: EntityEventLogDetails[];

  // @DataMember
  public total: number;

  // @DataMember
  public page: number;

  // @DataMember
  public itemsPerPage: number;

  // @DataMember
  public changeKey: string;

  public constructor(init?: Partial<GetDeviceEventLogResponse>) {
    (Object as any).assign(this, init);
  }
}

export class Waybill
  implements ICustomerEntityViewModel, IChangeKey, IDescription, IModified, IEntity
{
  // @DataMember
  public vehicleId: string;

  // @DataMember
  public routeId: string;

  // @DataMember
  public startTime: string;

  // @DataMember
  public endTime: string;

  // @DataMember
  public routeName: string;

  // @DataMember
  public vehicleName: string;

  // @DataMember
  public customerId: string;

  // @DataMember
  public customerName: string;

  // @DataMember
  public description: string;

  // @DataMember
  public changeKey: string;

  // @DataMember
  public created: string;

  // @DataMember
  public createdBy: string;

  // @DataMember
  public modified: string;

  // @DataMember
  public modifiedBy: string;

  // @DataMember
  public id: string;

  // @DataMember
  public entityType: string;

  public constructor(init?: Partial<Waybill>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class GetWaybillsResponse implements IChangeKey {
  // @DataMember
  public items: Waybill[];

  // @DataMember
  public total: number;

  // @DataMember
  public page: number;

  // @DataMember
  public itemsPerPage: number;

  // @DataMember
  public changeKey: string;

  public constructor(init?: Partial<GetWaybillsResponse>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class EntityIntegration implements IChangeKey, IDescription, IModified, IEntity {
  // @DataMember
  public entityId: string;

  // @DataMember
  public customerIntegrationId: string;

  // @DataMember
  public externalId: string;

  // @DataMember
  public settings: string;

  // @DataMember
  public integrationId: string;

  // @DataMember
  public integrationName: string;

  // @DataMember
  public integrationType: string;

  // @DataMember
  public description: string;

  // @DataMember
  public changeKey: string;

  // @DataMember
  public created: string;

  // @DataMember
  public createdBy: string;

  // @DataMember
  public modified: string;

  // @DataMember
  public modifiedBy: string;

  // @DataMember
  public id: string;

  // @DataMember
  public entityType: string;

  public constructor(init?: Partial<EntityIntegration>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class GetEntityIntegrationResponse implements IChangeKey {
  // @DataMember
  public items: EntityIntegration[];

  // @DataMember
  public total: number;

  // @DataMember
  public page: number;

  // @DataMember
  public itemsPerPage: number;

  // @DataMember
  public changeKey: string;

  public constructor(init?: Partial<GetEntityIntegrationResponse>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class GetEntitiesResponse implements IChangeKey {
  // @DataMember
  public items: Object[];

  // @DataMember
  public total: number;

  // @DataMember
  public page: number;

  // @DataMember
  public itemsPerPage: number;

  // @DataMember
  public changeKey: string;

  public constructor(init?: Partial<GetEntitiesResponse>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class AuthenticateResponse implements IHasSessionId, IHasBearerToken {
  // @DataMember(Order=1)
  public userId: string;

  // @DataMember(Order=2)
  public sessionId: string;

  // @DataMember(Order=3)
  public userName: string;

  // @DataMember(Order=4)
  public displayName: string;

  // @DataMember(Order=5)
  public referrerUrl: string;

  // @DataMember(Order=6)
  public bearerToken: string;

  // @DataMember(Order=7)
  public refreshToken: string;

  // @DataMember(Order=8)
  public profileUrl: string;

  // @DataMember(Order=9)
  public roles: string[];

  // @DataMember(Order=10)
  public permissions: string[];

  // @DataMember(Order=11)
  public responseStatus: ResponseStatus;

  // @DataMember(Order=12)
  public meta: { [index: string]: string };

  public constructor(init?: Partial<AuthenticateResponse>) {
    (Object as any).assign(this, init);
  }
}

// @Route("/customer/{customerId}/carriers", "GET")
// @DataContract
export class GetPublicTransportCarriersRequest
  implements IReturn<GetPublicTransportCarriersResponse>, ICustomerItemRequest
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public entityGroups: string[];

  // @DataMember
  public itemsIds: string[];

  // @DataMember
  public onlyOwnedItems: boolean;

  // @DataMember
  public page: number;

  // @DataMember
  public items: number;

  // @DataMember
  public sort: string;

  // @DataMember
  public filter: string;

  // @DataMember
  public propertiesTest: { [index: string]: number };

  public constructor(init?: Partial<GetPublicTransportCarriersRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new GetPublicTransportCarriersResponse();
  }
  public getTypeName() {
    return 'GetPublicTransportCarriersRequest';
  }
}

// @Route("/customer/{customerId}/carrier/{id}", "GET")
// @DataContract
export class GetPublicTransportCarrierRequest
  implements IReturn<PublicTransportCarrier>, ICustomerItemRequest, IBaseEntityItemRequest
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember(IsRequired=true)
  public id: string;

  public constructor(init?: Partial<GetPublicTransportCarrierRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new PublicTransportCarrier();
  }
  public getTypeName() {
    return 'GetPublicTransportCarrierRequest';
  }
}

// @Route("/customer/{customerId}/carriers/exists", "GET")
// @DataContract
export class GetPublicTransportCarrierExistRequest
  implements IReturn<boolean>, ICustomerItemRequest
{
  // @DataMember(IsRequired=true)
  public name: string;

  // @DataMember
  public customerId: string;

  public constructor(init?: Partial<GetPublicTransportCarrierExistRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return false;
  }
  public getTypeName() {
    return 'GetPublicTransportCarrierExistRequest';
  }
}

// @Route("/customer/{customerId}/carriers", "POST")
// @DataContract
export class CreatePublicTransportCarrierRequest
  implements IReturn<PublicTransportCarrier>, ICustomerItemRequest, IDescription
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public updateEntityGroups: boolean;

  // @DataMember
  public entityGroups: string[];

  // @DataMember(IsRequired=true)
  public entity: PublicTransportCarrier;

  // @DataMember
  public description: string;

  public constructor(init?: Partial<CreatePublicTransportCarrierRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new PublicTransportCarrier();
  }
  public getTypeName() {
    return 'CreatePublicTransportCarrierRequest';
  }
}

// @Route("/customer/{customerId}/carrier/{id}", "PUT")
// @DataContract
export class UpdatePublicTransportCarrierRequest
  implements
    IReturn<PublicTransportCarrier>,
    ICustomerItemRequest,
    IBaseEntityItemRequest,
    IDescription
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public updateEntityGroups: boolean;

  // @DataMember
  public entityGroups: string[];

  // @DataMember(IsRequired=true)
  public id: string;

  // @DataMember(IsRequired=true)
  public entity: PublicTransportCarrier;

  // @DataMember
  public description: string;

  public constructor(init?: Partial<UpdatePublicTransportCarrierRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new PublicTransportCarrier();
  }
  public getTypeName() {
    return 'UpdatePublicTransportCarrierRequest';
  }
}

// @Route("/customer/{customerId}/carriers", "DELETE")
// @DataContract
export class DeletePublicTransportCarriersRequest
  implements IReturn<number>, ICustomerItemRequest, IDescription
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public description: string;

  // @DataMember(IsRequired=true)
  public ids: string[];

  public constructor(init?: Partial<DeletePublicTransportCarriersRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return 0;
  }
  public getTypeName() {
    return 'DeletePublicTransportCarriersRequest';
  }
}

// @Route("/customer/{customerId}/routes/groups", "GET")
// @DataContract
export class GetPublicTransportRouteGroupsRequest
  implements IReturn<GetEntityGroupsResponse>, ICustomerItemRequest
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public entityGroups: string[];

  // @DataMember
  public itemsIds: string[];

  // @DataMember
  public onlyOwnedItems: boolean;

  // @DataMember
  public page: number;

  // @DataMember
  public items: number;

  // @DataMember
  public sort: string;

  // @DataMember
  public filter: string;

  // @DataMember
  public propertiesTest: { [index: string]: number };

  public constructor(init?: Partial<GetPublicTransportRouteGroupsRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new GetEntityGroupsResponse();
  }
  public getTypeName() {
    return 'GetPublicTransportRouteGroupsRequest';
  }
}

// @Route("/customer/{customerId}/routes/groups/{id}", "GET")
// @DataContract
export class GetPublicTransportRouteGroupRequest
  implements IReturn<EntityGroup>, ICustomerItemRequest, IBaseEntityItemRequest
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember(IsRequired=true)
  public id: string;

  public constructor(init?: Partial<GetPublicTransportRouteGroupRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new EntityGroup();
  }
  public getTypeName() {
    return 'GetPublicTransportRouteGroupRequest';
  }
}

// @Route("/customer/{customerId}/routes/groups", "POST")
// @DataContract
export class CreatePublicTransportRouteGroupRequest
  implements IReturn<EntityGroup>, ICustomerItemRequest, IDescription
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public updateEntityGroups: boolean;

  // @DataMember
  public entityGroups: string[];

  // @DataMember(IsRequired=true)
  public entity: EntityGroup;

  // @DataMember
  public description: string;

  public constructor(init?: Partial<CreatePublicTransportRouteGroupRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new EntityGroup();
  }
  public getTypeName() {
    return 'CreatePublicTransportRouteGroupRequest';
  }
}

// @Route("/customer/{customerId}/routes/groups/{id}", "PUT")
// @DataContract
export class UpdatePublicTransportRouteGroupRequest
  implements IReturn<EntityGroup>, ICustomerItemRequest, IBaseEntityItemRequest, IDescription
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public updateEntityGroups: boolean;

  // @DataMember
  public entityGroups: string[];

  // @DataMember(IsRequired=true)
  public id: string;

  // @DataMember(IsRequired=true)
  public entity: EntityGroup;

  // @DataMember
  public description: string;

  public constructor(init?: Partial<UpdatePublicTransportRouteGroupRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new EntityGroup();
  }
  public getTypeName() {
    return 'UpdatePublicTransportRouteGroupRequest';
  }
}

// @Route("/customer/{customerId}/routes/groups", "DELETE")
// @DataContract
export class DeletePublicTransportRouteGroupsRequest
  implements IReturn<number>, ICustomerItemRequest, IDescription
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public description: string;

  // @DataMember(IsRequired=true)
  public ids: string[];

  public constructor(init?: Partial<DeletePublicTransportRouteGroupsRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return 0;
  }
  public getTypeName() {
    return 'DeletePublicTransportRouteGroupsRequest';
  }
}

// @Route("/customer/{customerId}/routes/groups/{id}", "GET")
// @DataContract
export class GetPublicTransportRouteGroupSharesRequest
  implements IReturn<GetEntityGroupSharesResponse>, IBaseEntityItemRequest, ICustomerItemRequest
{
  // @DataMember
  public id: string;

  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public entityGroups: string[];

  // @DataMember
  public itemsIds: string[];

  // @DataMember
  public onlyOwnedItems: boolean;

  // @DataMember
  public page: number;

  // @DataMember
  public items: number;

  // @DataMember
  public sort: string;

  // @DataMember
  public filter: string;

  // @DataMember
  public propertiesTest: { [index: string]: number };

  public constructor(init?: Partial<GetPublicTransportRouteGroupSharesRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new GetEntityGroupSharesResponse();
  }
  public getTypeName() {
    return 'GetPublicTransportRouteGroupSharesRequest';
  }
}

// @Route("/customer/{customerId}/routes/groups/{id}", "PUT")
// @DataContract
export class UpdatePublicTransportRouteGroupShareRequest
  implements IReturn<EntityGroupPermissions>, ICustomerItemRequest, IDescription
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember(IsRequired=true)
  public id: string;

  // @DataMember(IsRequired=true)
  public entity: EntityGroupPermissions;

  // @DataMember
  public description: string;

  public constructor(init?: Partial<UpdatePublicTransportRouteGroupShareRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new EntityGroupPermissions();
  }
  public getTypeName() {
    return 'UpdatePublicTransportRouteGroupShareRequest';
  }
}

// @Route("/customer/{customerId}/routes", "GET")
// @DataContract
export class GetPublicTransportRoutesRequest
  implements IReturn<GetPublicTransportRoutesResponse>, ICustomerItemRequest
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public entityGroups: string[];

  // @DataMember
  public itemsIds: string[];

  // @DataMember
  public onlyOwnedItems: boolean;

  // @DataMember
  public page: number;

  // @DataMember
  public items: number;

  // @DataMember
  public sort: string;

  // @DataMember
  public filter: string;

  // @DataMember
  public propertiesTest: { [index: string]: number };

  public constructor(init?: Partial<GetPublicTransportRoutesRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new GetPublicTransportRoutesResponse();
  }
  public getTypeName() {
    return 'GetPublicTransportRoutesRequest';
  }
}

// @Route("/customer/{customerId}/route/{id}", "GET")
// @DataContract
export class GetPublicTransportRouteRequest
  implements IReturn<PublicTransportRoute>, ICustomerItemRequest, IBaseEntityItemRequest
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember(IsRequired=true)
  public id: string;

  public constructor(init?: Partial<GetPublicTransportRouteRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new PublicTransportRoute();
  }
  public getTypeName() {
    return 'GetPublicTransportRouteRequest';
  }
}

// @Route("/customer/{customerId}/routes/scenes", "GET")
// @DataContract
export class GetPublicTransportVerifiedScenesRequest
  implements IReturn<PublicTransportRouteScene[]>
{
  // @DataMember
  public customerId: string;

  public constructor(init?: Partial<GetPublicTransportVerifiedScenesRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new Array<PublicTransportRouteScene>();
  }
  public getTypeName() {
    return 'GetPublicTransportVerifiedScenesRequest';
  }
}

// @Route("/customer/{customerId}/route/{id}/scene", "GET")
// @DataContract
export class GetPublicTransportRouteSceneRequest
  implements IReturn<PublicTransportRouteScene>, ICustomerItemRequest, IBaseEntityItemRequest
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember(IsRequired=true)
  public id: string;

  public constructor(init?: Partial<GetPublicTransportRouteSceneRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new PublicTransportRouteScene();
  }
  public getTypeName() {
    return 'GetPublicTransportRouteSceneRequest';
  }
}

// @Route("/customer/{customerId}/route/{id}/scene", "PUT")
// @DataContract
export class UpdatePublicTransportRouteSceneRequest
  implements
    IReturn<PublicTransportRouteScene>,
    ICustomerItemRequest,
    IBaseEntityItemRequest,
    IDescription
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public updateEntityGroups: boolean;

  // @DataMember
  public entityGroups: string[];

  // @DataMember(IsRequired=true)
  public id: string;

  // @DataMember(IsRequired=true)
  public entity: PublicTransportRouteScene;

  // @DataMember
  public description: string;

  public constructor(init?: Partial<UpdatePublicTransportRouteSceneRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new PublicTransportRouteScene();
  }
  public getTypeName() {
    return 'UpdatePublicTransportRouteSceneRequest';
  }
}

// @Route("/customer/{customerId}/routes/exists", "GET")
// @DataContract
export class GetPublicTransportRouteExistRequest implements IReturn<boolean>, ICustomerItemRequest {
  // @DataMember(IsRequired=true)
  public name: string;

  // @DataMember
  public customerId: string;

  public constructor(init?: Partial<GetPublicTransportRouteExistRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return false;
  }
  public getTypeName() {
    return 'GetPublicTransportRouteExistRequest';
  }
}

// @Route("/customer/{customerId}/routes", "POST")
// @DataContract
export class CreatePublicTransportRouteRequest
  implements IReturn<PublicTransportRoute>, ICustomerItemRequest, IDescription
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public updateEntityGroups: boolean;

  // @DataMember
  public entityGroups: string[];

  // @DataMember(IsRequired=true)
  public entity: PublicTransportRoute;

  // @DataMember
  public description: string;

  public constructor(init?: Partial<CreatePublicTransportRouteRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new PublicTransportRoute();
  }
  public getTypeName() {
    return 'CreatePublicTransportRouteRequest';
  }
}

// @Route("/customer/{customerId}/route/{id}", "PUT")
// @DataContract
export class UpdatePublicTransportRouteRequest
  implements
    IReturn<PublicTransportRoute>,
    ICustomerItemRequest,
    IBaseEntityItemRequest,
    IDescription
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public updateEntityGroups: boolean;

  // @DataMember
  public entityGroups: string[];

  // @DataMember(IsRequired=true)
  public id: string;

  // @DataMember(IsRequired=true)
  public entity: PublicTransportRoute;

  // @DataMember
  public description: string;

  public constructor(init?: Partial<UpdatePublicTransportRouteRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new PublicTransportRoute();
  }
  public getTypeName() {
    return 'UpdatePublicTransportRouteRequest';
  }
}

// @Route("/customer/{customerId}/routes", "DELETE")
// @DataContract
export class DeletePublicTransportRoutesRequest
  implements IReturn<number>, ICustomerItemRequest, IDescription
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public description: string;

  // @DataMember(IsRequired=true)
  public ids: string[];

  public constructor(init?: Partial<DeletePublicTransportRoutesRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return 0;
  }
  public getTypeName() {
    return 'DeletePublicTransportRoutesRequest';
  }
}

// @Route("/customer/{customerId}/schedules", "GET")
// @DataContract
export class GetPublicTransportSchedulesRequest
  implements IReturn<GetPublicTransportSchedulesResponse>, ICustomerItemRequest
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public entityGroups: string[];

  // @DataMember
  public itemsIds: string[];

  // @DataMember
  public onlyOwnedItems: boolean;

  // @DataMember
  public page: number;

  // @DataMember
  public items: number;

  // @DataMember
  public sort: string;

  // @DataMember
  public filter: string;

  // @DataMember
  public propertiesTest: { [index: string]: number };

  public constructor(init?: Partial<GetPublicTransportSchedulesRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new GetPublicTransportSchedulesResponse();
  }
  public getTypeName() {
    return 'GetPublicTransportSchedulesRequest';
  }
}

// @Route("/customer/{customerId}/schedule/{id}", "GET")
// @DataContract
export class GetPublicTransportScheduleRequest
  implements IReturn<PublicTransportSchedule>, ICustomerItemRequest, IBaseEntityItemRequest
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember(IsRequired=true)
  public id: string;

  public constructor(init?: Partial<GetPublicTransportScheduleRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new PublicTransportSchedule();
  }
  public getTypeName() {
    return 'GetPublicTransportScheduleRequest';
  }
}

// @Route("/customer/{customerId}/schedules", "POST")
// @DataContract
export class CreatePublicTransportScheduleRequest
  implements IReturn<PublicTransportSchedule>, ICustomerItemRequest, IDescription
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public updateEntityGroups: boolean;

  // @DataMember
  public entityGroups: string[];

  // @DataMember(IsRequired=true)
  public entity: PublicTransportSchedule;

  // @DataMember
  public description: string;

  public constructor(init?: Partial<CreatePublicTransportScheduleRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new PublicTransportSchedule();
  }
  public getTypeName() {
    return 'CreatePublicTransportScheduleRequest';
  }
}

// @Route("/customer/{customerId}/schedule/{id}", "PUT")
// @DataContract
export class UpdatePublicTransportScheduleRequest
  implements
    IReturn<PublicTransportSchedule>,
    ICustomerItemRequest,
    IBaseEntityItemRequest,
    IDescription
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public updateEntityGroups: boolean;

  // @DataMember
  public entityGroups: string[];

  // @DataMember(IsRequired=true)
  public id: string;

  // @DataMember(IsRequired=true)
  public entity: PublicTransportSchedule;

  // @DataMember
  public description: string;

  public constructor(init?: Partial<UpdatePublicTransportScheduleRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new PublicTransportSchedule();
  }
  public getTypeName() {
    return 'UpdatePublicTransportScheduleRequest';
  }
}

// @Route("/customer/{customerId}/schedules", "DELETE")
// @DataContract
export class DeletePublicTransportSchedulesRequest
  implements IReturn<number>, ICustomerItemRequest, IDescription
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public description: string;

  // @DataMember(IsRequired=true)
  public ids: string[];

  public constructor(init?: Partial<DeletePublicTransportSchedulesRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return 0;
  }
  public getTypeName() {
    return 'DeletePublicTransportSchedulesRequest';
  }
}

// @Route("/customer/{customerId}/stops/groups", "GET")
// @DataContract
export class GetPublicTransportStopGroupsRequest
  implements IReturn<GetEntityGroupsResponse>, ICustomerItemRequest
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public entityGroups: string[];

  // @DataMember
  public itemsIds: string[];

  // @DataMember
  public onlyOwnedItems: boolean;

  // @DataMember
  public page: number;

  // @DataMember
  public items: number;

  // @DataMember
  public sort: string;

  // @DataMember
  public filter: string;

  // @DataMember
  public propertiesTest: { [index: string]: number };

  public constructor(init?: Partial<GetPublicTransportStopGroupsRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new GetEntityGroupsResponse();
  }
  public getTypeName() {
    return 'GetPublicTransportStopGroupsRequest';
  }
}

// @Route("/customer/{customerId}/stops/groups/{id}", "GET")
// @DataContract
export class GetPublicTransportStopGroupRequest
  implements IReturn<EntityGroup>, ICustomerItemRequest, IBaseEntityItemRequest
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember(IsRequired=true)
  public id: string;

  public constructor(init?: Partial<GetPublicTransportStopGroupRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new EntityGroup();
  }
  public getTypeName() {
    return 'GetPublicTransportStopGroupRequest';
  }
}

// @Route("/customer/{customerId}/stops/groups", "POST")
// @DataContract
export class CreatePublicTransportStopGroupRequest
  implements IReturn<EntityGroup>, ICustomerItemRequest, IDescription
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public updateEntityGroups: boolean;

  // @DataMember
  public entityGroups: string[];

  // @DataMember(IsRequired=true)
  public entity: EntityGroup;

  // @DataMember
  public description: string;

  public constructor(init?: Partial<CreatePublicTransportStopGroupRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new EntityGroup();
  }
  public getTypeName() {
    return 'CreatePublicTransportStopGroupRequest';
  }
}

// @Route("/customer/{customerId}/stops/groups/{id}", "PUT")
// @DataContract
export class UpdatePublicTransportStopGroupRequest
  implements IReturn<EntityGroup>, ICustomerItemRequest, IBaseEntityItemRequest, IDescription
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public updateEntityGroups: boolean;

  // @DataMember
  public entityGroups: string[];

  // @DataMember(IsRequired=true)
  public id: string;

  // @DataMember(IsRequired=true)
  public entity: EntityGroup;

  // @DataMember
  public description: string;

  public constructor(init?: Partial<UpdatePublicTransportStopGroupRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new EntityGroup();
  }
  public getTypeName() {
    return 'UpdatePublicTransportStopGroupRequest';
  }
}

// @Route("/customer/{customerId}/stops/groups", "DELETE")
// @DataContract
export class DeletePublicTransportStopGroupsRequest
  implements IReturn<number>, ICustomerItemRequest, IDescription
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public description: string;

  // @DataMember(IsRequired=true)
  public ids: string[];

  public constructor(init?: Partial<DeletePublicTransportStopGroupsRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return 0;
  }
  public getTypeName() {
    return 'DeletePublicTransportStopGroupsRequest';
  }
}

// @Route("/customer/{customerId}/stops/groups/{id}", "GET")
// @DataContract
export class GetPublicTransportStopGroupSharesRequest
  implements IReturn<GetEntityGroupSharesResponse>, IBaseEntityItemRequest, ICustomerItemRequest
{
  // @DataMember
  public id: string;

  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public entityGroups: string[];

  // @DataMember
  public itemsIds: string[];

  // @DataMember
  public onlyOwnedItems: boolean;

  // @DataMember
  public page: number;

  // @DataMember
  public items: number;

  // @DataMember
  public sort: string;

  // @DataMember
  public filter: string;

  // @DataMember
  public propertiesTest: { [index: string]: number };

  public constructor(init?: Partial<GetPublicTransportStopGroupSharesRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new GetEntityGroupSharesResponse();
  }
  public getTypeName() {
    return 'GetPublicTransportStopGroupSharesRequest';
  }
}

// @Route("/customer/{customerId}/stops/groups/{id}", "PUT")
// @DataContract
export class UpdatePublicTransportStopGroupShareRequest
  implements IReturn<EntityGroupPermissions>, ICustomerItemRequest, IDescription
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember(IsRequired=true)
  public id: string;

  // @DataMember(IsRequired=true)
  public entity: EntityGroupPermissions;

  // @DataMember
  public description: string;

  public constructor(init?: Partial<UpdatePublicTransportStopGroupShareRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new EntityGroupPermissions();
  }
  public getTypeName() {
    return 'UpdatePublicTransportStopGroupShareRequest';
  }
}

// @Route("/customer/{customerId}/stops", "GET")
// @DataContract
export class GetPublicTransportStopsRequest
  implements IReturn<GetPublicTransportStopsResponse>, ICustomerItemRequest
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public entityGroups: string[];

  // @DataMember
  public itemsIds: string[];

  // @DataMember
  public onlyOwnedItems: boolean;

  // @DataMember
  public page: number;

  // @DataMember
  public items: number;

  // @DataMember
  public sort: string;

  // @DataMember
  public filter: string;

  // @DataMember
  public propertiesTest: { [index: string]: number };

  public constructor(init?: Partial<GetPublicTransportStopsRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new GetPublicTransportStopsResponse();
  }
  public getTypeName() {
    return 'GetPublicTransportStopsRequest';
  }
}

// @Route("/customer/{customerId}/stop/{id}", "GET")
// @DataContract
export class GetPublicTransportStopRequest
  implements IReturn<PublicTransportStop>, ICustomerItemRequest, IBaseEntityItemRequest
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember(IsRequired=true)
  public id: string;

  public constructor(init?: Partial<GetPublicTransportStopRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new PublicTransportStop();
  }
  public getTypeName() {
    return 'GetPublicTransportStopRequest';
  }
}

// @Route("/customer/{customerId}/stops/exists", "GET")
// @DataContract
export class GetPublicTransportStopExistRequest implements IReturn<boolean>, ICustomerItemRequest {
  // @DataMember(IsRequired=true)
  public name: string;

  // @DataMember
  public customerId: string;

  public constructor(init?: Partial<GetPublicTransportStopExistRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return false;
  }
  public getTypeName() {
    return 'GetPublicTransportStopExistRequest';
  }
}

// @Route("/customer/{customerId}/stops", "POST")
// @DataContract
export class CreatePublicTransportStopRequest
  implements IReturn<PublicTransportStop>, ICustomerItemRequest, IDescription
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public updateEntityGroups: boolean;

  // @DataMember
  public entityGroups: string[];

  // @DataMember(IsRequired=true)
  public entity: PublicTransportStop;

  // @DataMember
  public description: string;

  public constructor(init?: Partial<CreatePublicTransportStopRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new PublicTransportStop();
  }
  public getTypeName() {
    return 'CreatePublicTransportStopRequest';
  }
}

// @Route("/customer/{customerId}/stop/{id}", "PUT")
// @DataContract
export class UpdatePublicTransportStopRequest
  implements
    IReturn<PublicTransportStop>,
    ICustomerItemRequest,
    IBaseEntityItemRequest,
    IDescription
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public updateEntityGroups: boolean;

  // @DataMember
  public entityGroups: string[];

  // @DataMember(IsRequired=true)
  public id: string;

  // @DataMember(IsRequired=true)
  public entity: PublicTransportStop;

  // @DataMember
  public description: string;

  public constructor(init?: Partial<UpdatePublicTransportStopRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new PublicTransportStop();
  }
  public getTypeName() {
    return 'UpdatePublicTransportStopRequest';
  }
}

// @Route("/customer/{customerId}/stops", "DELETE")
// @DataContract
export class DeletePublicTransportStopsRequest
  implements IReturn<number>, ICustomerItemRequest, IDescription
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public description: string;

  // @DataMember(IsRequired=true)
  public ids: string[];

  public constructor(init?: Partial<DeletePublicTransportStopsRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return 0;
  }
  public getTypeName() {
    return 'DeletePublicTransportStopsRequest';
  }
}

// @Route("/customer/{customerId}/vehiclePriority/start", "POST")
// @DataContract
export class PublicTransportVehiclePriorityStartRequest implements IReturn<boolean> {
  // @DataMember
  public customerId: string;

  public constructor(init?: Partial<PublicTransportVehiclePriorityStartRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return false;
  }
  public getTypeName() {
    return 'PublicTransportVehiclePriorityStartRequest';
  }
}

// @Route("/customer/{customerId}/vehiclePriority/stop", "POST")
// @DataContract
export class PublicTransportVehiclePriorityStopRequest implements IReturn<boolean> {
  // @DataMember
  public customerId: string;

  public constructor(init?: Partial<PublicTransportVehiclePriorityStopRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return false;
  }
  public getTypeName() {
    return 'PublicTransportVehiclePriorityStopRequest';
  }
}

// @Route("/customer/{customerId}/vehiclePriority/status", "GET")
// @DataContract
export class PublicTransportVehiclePriorityStatusRequest implements IReturn<boolean> {
  // @DataMember
  public customerId: string;

  public constructor(init?: Partial<PublicTransportVehiclePriorityStatusRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return false;
  }
  public getTypeName() {
    return 'PublicTransportVehiclePriorityStatusRequest';
  }
}

// @Route("/customer/{customerId}/vehiclePriority/events", "GET")
// @DataContract
export class GetPublicTransportVehiclePriorityEventsRequest
  implements IReturn<GetPublicTransportVehiclePriorityEventsResponse>, ICustomerItemRequest
{
  // @DataMember
  public startDate?: string;

  // @DataMember
  public endDate?: string;

  // @DataMember
  public eventType?: PriorityEventType;

  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public entityGroups: string[];

  // @DataMember
  public itemsIds: string[];

  // @DataMember
  public onlyOwnedItems: boolean;

  // @DataMember
  public page: number;

  // @DataMember
  public items: number;

  // @DataMember
  public sort: string;

  // @DataMember
  public filter: string;

  // @DataMember
  public propertiesTest: { [index: string]: number };

  public constructor(init?: Partial<GetPublicTransportVehiclePriorityEventsRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new GetPublicTransportVehiclePriorityEventsResponse();
  }
  public getTypeName() {
    return 'GetPublicTransportVehiclePriorityEventsRequest';
  }
}

// @Route("/customer/{customerId}/vehiclePriority/trafficObjects", "GET")
// @DataContract
export class GetPublicTransportSceneTrafficObjectsStatusesRequest
  implements IReturn<TrafficObjectStatus[]>
{
  // @DataMember
  public customerId: string;

  // @DataMember
  public routeId: string;

  public constructor(init?: Partial<GetPublicTransportSceneTrafficObjectsStatusesRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new Array<TrafficObjectStatus>();
  }
  public getTypeName() {
    return 'GetPublicTransportSceneTrafficObjectsStatusesRequest';
  }
}

// @Route("/customer/{customerId}/vehiclePriority/stops", "GET")
// @DataContract
export class GetPublicTransportSceneStopsStatusesRequest implements IReturn<StopStatus[]> {
  // @DataMember
  public customerId: string;

  // @DataMember
  public routeId: string;

  public constructor(init?: Partial<GetPublicTransportSceneStopsStatusesRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new Array<StopStatus>();
  }
  public getTypeName() {
    return 'GetPublicTransportSceneStopsStatusesRequest';
  }
}

// @Route("/customer/{customerId}/vehiclePriority/schedule", "GET")
// @DataContract
export class GetPublicTransportSceneScheduleRequest implements IReturn<number> {
  // @DataMember
  public customerId: string;

  // @DataMember
  public routeId: string;

  public constructor(init?: Partial<GetPublicTransportSceneScheduleRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return 0;
  }
  public getTypeName() {
    return 'GetPublicTransportSceneScheduleRequest';
  }
}

// @Route("/customer/{customerId}/vehiclePriority/vehicles", "GET")
// @DataContract
export class GetPublicTransportVehicleSceneStatusesRequest
  implements IReturn<PublicTransportVehicleSceneStatus[]>
{
  // @DataMember
  public customerId: string;

  // @DataMember
  public routeId: string;

  public constructor(init?: Partial<GetPublicTransportVehicleSceneStatusesRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new Array<PublicTransportVehicleSceneStatus>();
  }
  public getTypeName() {
    return 'GetPublicTransportVehicleSceneStatusesRequest';
  }
}

// @Route("/customer/{customerId}/vehiclePriority/settings", "GET")
// @DataContract
export class GetPublicTransportVehiclePrioritySettingsRequest
  implements IReturn<PublicTransportPrioritySettings>
{
  // @DataMember
  public customerId: string;

  public constructor(init?: Partial<GetPublicTransportVehiclePrioritySettingsRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new PublicTransportPrioritySettings();
  }
  public getTypeName() {
    return 'GetPublicTransportVehiclePrioritySettingsRequest';
  }
}

// @Route("/customer/{customerId}/vehiclePriority/settings", "PUT")
// @DataContract
export class UpdatePublicTransportVehiclePrioritySettingsRequest
  implements IReturn<PublicTransportPrioritySettings>
{
  // @DataMember
  public customerId: string;

  // @DataMember
  public settings: PrioritySettings;

  public constructor(init?: Partial<UpdatePublicTransportVehiclePrioritySettingsRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new PublicTransportPrioritySettings();
  }
  public getTypeName() {
    return 'UpdatePublicTransportVehiclePrioritySettingsRequest';
  }
}

// @Route("/customer/{customerId}/vehicles/groups", "GET")
// @DataContract
export class GetPublicTransportVehicleGroupsRequest
  implements IReturn<GetEntityGroupsResponse>, ICustomerItemRequest
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public entityGroups: string[];

  // @DataMember
  public itemsIds: string[];

  // @DataMember
  public onlyOwnedItems: boolean;

  // @DataMember
  public page: number;

  // @DataMember
  public items: number;

  // @DataMember
  public sort: string;

  // @DataMember
  public filter: string;

  // @DataMember
  public propertiesTest: { [index: string]: number };

  public constructor(init?: Partial<GetPublicTransportVehicleGroupsRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new GetEntityGroupsResponse();
  }
  public getTypeName() {
    return 'GetPublicTransportVehicleGroupsRequest';
  }
}

// @Route("/customer/{customerId}/vehicles/groups/{id}", "GET")
// @DataContract
export class GetPublicTransportVehicleGroupRequest
  implements IReturn<EntityGroup>, ICustomerItemRequest, IBaseEntityItemRequest
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember(IsRequired=true)
  public id: string;

  public constructor(init?: Partial<GetPublicTransportVehicleGroupRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new EntityGroup();
  }
  public getTypeName() {
    return 'GetPublicTransportVehicleGroupRequest';
  }
}

// @Route("/customer/{customerId}/vehicles/groups", "POST")
// @DataContract
export class CreatePublicTransportVehicleGroupRequest
  implements IReturn<EntityGroup>, ICustomerItemRequest, IDescription
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public updateEntityGroups: boolean;

  // @DataMember
  public entityGroups: string[];

  // @DataMember(IsRequired=true)
  public entity: EntityGroup;

  // @DataMember
  public description: string;

  public constructor(init?: Partial<CreatePublicTransportVehicleGroupRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new EntityGroup();
  }
  public getTypeName() {
    return 'CreatePublicTransportVehicleGroupRequest';
  }
}

// @Route("/customer/{customerId}/vehicles/groups/{id}", "PUT")
// @DataContract
export class UpdatePublicTransportVehicleGroupRequest
  implements IReturn<EntityGroup>, ICustomerItemRequest, IBaseEntityItemRequest, IDescription
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public updateEntityGroups: boolean;

  // @DataMember
  public entityGroups: string[];

  // @DataMember(IsRequired=true)
  public id: string;

  // @DataMember(IsRequired=true)
  public entity: EntityGroup;

  // @DataMember
  public description: string;

  public constructor(init?: Partial<UpdatePublicTransportVehicleGroupRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new EntityGroup();
  }
  public getTypeName() {
    return 'UpdatePublicTransportVehicleGroupRequest';
  }
}

// @Route("/customer/{customerId}/vehicles/groups", "DELETE")
// @DataContract
export class DeletePublicTransportVehicleGroupsRequest
  implements IReturn<number>, ICustomerItemRequest, IDescription
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public description: string;

  // @DataMember(IsRequired=true)
  public ids: string[];

  public constructor(init?: Partial<DeletePublicTransportVehicleGroupsRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return 0;
  }
  public getTypeName() {
    return 'DeletePublicTransportVehicleGroupsRequest';
  }
}

// @Route("/customer/{customerId}/vehicles/groups/{id}", "GET")
// @DataContract
export class GetPublicTransportVehicleGroupSharesRequest
  implements IReturn<GetEntityGroupSharesResponse>, IBaseEntityItemRequest, ICustomerItemRequest
{
  // @DataMember
  public id: string;

  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public entityGroups: string[];

  // @DataMember
  public itemsIds: string[];

  // @DataMember
  public onlyOwnedItems: boolean;

  // @DataMember
  public page: number;

  // @DataMember
  public items: number;

  // @DataMember
  public sort: string;

  // @DataMember
  public filter: string;

  // @DataMember
  public propertiesTest: { [index: string]: number };

  public constructor(init?: Partial<GetPublicTransportVehicleGroupSharesRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new GetEntityGroupSharesResponse();
  }
  public getTypeName() {
    return 'GetPublicTransportVehicleGroupSharesRequest';
  }
}

// @Route("/customer/{customerId}/vehicles/groups/{id}", "PUT")
// @DataContract
export class UpdatePublicTransportVehicleGroupShareRequest
  implements IReturn<EntityGroupPermissions>, ICustomerItemRequest, IDescription
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember(IsRequired=true)
  public id: string;

  // @DataMember(IsRequired=true)
  public entity: EntityGroupPermissions;

  // @DataMember
  public description: string;

  public constructor(init?: Partial<UpdatePublicTransportVehicleGroupShareRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new EntityGroupPermissions();
  }
  public getTypeName() {
    return 'UpdatePublicTransportVehicleGroupShareRequest';
  }
}

// @Route("/customer/{customerId}/vehicles", "GET")
// @DataContract
export class GetPublicTransportVehiclesRequest
  implements IReturn<GetPublicTransportVehiclesResponse>, ICustomerItemRequest
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public entityGroups: string[];

  // @DataMember
  public itemsIds: string[];

  // @DataMember
  public onlyOwnedItems: boolean;

  // @DataMember
  public page: number;

  // @DataMember
  public items: number;

  // @DataMember
  public sort: string;

  // @DataMember
  public filter: string;

  // @DataMember
  public propertiesTest: { [index: string]: number };

  public constructor(init?: Partial<GetPublicTransportVehiclesRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new GetPublicTransportVehiclesResponse();
  }
  public getTypeName() {
    return 'GetPublicTransportVehiclesRequest';
  }
}

// @Route("/customer/{customerId}/vehicle/{id}/status", "GET")
// @DataContract
export class GetPublicTransportVehicleStatusRequest
  implements IReturn<PublicTransportVehicleStatus>, ICustomerItemRequest, IBaseEntityItemRequest
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember(IsRequired=true)
  public id: string;

  public constructor(init?: Partial<GetPublicTransportVehicleStatusRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new PublicTransportVehicleStatus();
  }
  public getTypeName() {
    return 'GetPublicTransportVehicleStatusRequest';
  }
}

// @Route("/customer/{customerId}/vehicles/statuses", "GET")
// @DataContract
export class GetPublicTransportVehicleStatusesRequest
  implements IReturn<GetPublicTransportVehicleStatusesResponse>, ICustomerItemRequest
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public entityGroups: string[];

  // @DataMember
  public itemsIds: string[];

  // @DataMember
  public onlyOwnedItems: boolean;

  // @DataMember
  public page: number;

  // @DataMember
  public items: number;

  // @DataMember
  public sort: string;

  // @DataMember
  public filter: string;

  // @DataMember
  public propertiesTest: { [index: string]: number };

  public constructor(init?: Partial<GetPublicTransportVehicleStatusesRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new GetPublicTransportVehicleStatusesResponse();
  }
  public getTypeName() {
    return 'GetPublicTransportVehicleStatusesRequest';
  }
}

// @Route("/customer/{customerId}/vehicle/{id}", "GET")
// @DataContract
export class GetPublicTransportVehicleRequest
  implements IReturn<PublicTransportVehicle>, ICustomerItemRequest, IBaseEntityItemRequest
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember(IsRequired=true)
  public id: string;

  public constructor(init?: Partial<GetPublicTransportVehicleRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new PublicTransportVehicle();
  }
  public getTypeName() {
    return 'GetPublicTransportVehicleRequest';
  }
}

// @Route("/customer/{customerId}/vehicles/exists", "GET")
// @DataContract
export class GetPublicTransportVehicleExistRequest
  implements IReturn<boolean>, ICustomerItemRequest
{
  // @DataMember(IsRequired=true)
  public number: string;

  // @DataMember
  public customerId: string;

  public constructor(init?: Partial<GetPublicTransportVehicleExistRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return false;
  }
  public getTypeName() {
    return 'GetPublicTransportVehicleExistRequest';
  }
}

// @Route("/customer/{customerId}/vehicles", "POST")
// @DataContract
export class CreatePublicTransportVehicleRequest
  implements IReturn<PublicTransportVehicle>, ICustomerItemRequest, IDescription
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public updateEntityGroups: boolean;

  // @DataMember
  public entityGroups: string[];

  // @DataMember(IsRequired=true)
  public entity: PublicTransportVehicle;

  // @DataMember
  public description: string;

  public constructor(init?: Partial<CreatePublicTransportVehicleRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new PublicTransportVehicle();
  }
  public getTypeName() {
    return 'CreatePublicTransportVehicleRequest';
  }
}

// @Route("/customer/{customerId}/vehicle/{id}", "PUT")
// @DataContract
export class UpdatePublicTransportVehicleRequest
  implements
    IReturn<PublicTransportVehicle>,
    ICustomerItemRequest,
    IBaseEntityItemRequest,
    IDescription
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public updateEntityGroups: boolean;

  // @DataMember
  public entityGroups: string[];

  // @DataMember(IsRequired=true)
  public id: string;

  // @DataMember(IsRequired=true)
  public entity: PublicTransportVehicle;

  // @DataMember
  public description: string;

  public constructor(init?: Partial<UpdatePublicTransportVehicleRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new PublicTransportVehicle();
  }
  public getTypeName() {
    return 'UpdatePublicTransportVehicleRequest';
  }
}

// @Route("/customer/{customerId}/vehicles", "DELETE")
// @DataContract
export class DeletePublicTransportVehiclesRequest
  implements IReturn<number>, ICustomerItemRequest, IDescription
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public description: string;

  // @DataMember(IsRequired=true)
  public ids: string[];

  public constructor(init?: Partial<DeletePublicTransportVehiclesRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return 0;
  }
  public getTypeName() {
    return 'DeletePublicTransportVehiclesRequest';
  }
}

// @Route("/customer/{customerId}/glonassTerminals", "GET")
// @DataContract
export class GetPublicTransportGlonassTerminalsRequest
  implements IReturn<GetPublicTransportGlonassTerminalsResponse>, ICustomerItemRequest
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public entityGroups: string[];

  // @DataMember
  public itemsIds: string[];

  // @DataMember
  public onlyOwnedItems: boolean;

  // @DataMember
  public page: number;

  // @DataMember
  public items: number;

  // @DataMember
  public sort: string;

  // @DataMember
  public filter: string;

  // @DataMember
  public propertiesTest: { [index: string]: number };

  public constructor(init?: Partial<GetPublicTransportGlonassTerminalsRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new GetPublicTransportGlonassTerminalsResponse();
  }
  public getTypeName() {
    return 'GetPublicTransportGlonassTerminalsRequest';
  }
}

// @Route("/customer/{customerId}/glonassTerminal/{id}", "GET")
// @DataContract
export class GetPublicTransportGlonassTerminalRequest
  implements IReturn<PublicTransportGlonassTerminal>, ICustomerItemRequest, IBaseEntityItemRequest
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember(IsRequired=true)
  public id: string;

  public constructor(init?: Partial<GetPublicTransportGlonassTerminalRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new PublicTransportGlonassTerminal();
  }
  public getTypeName() {
    return 'GetPublicTransportGlonassTerminalRequest';
  }
}

// @Route("/customer/{customerId}/glonassTerminals/exists", "GET")
// @DataContract
export class GetPublicTransportGlonassTerminalExistRequest
  implements IReturn<boolean>, ICustomerItemRequest
{
  // @DataMember(IsRequired=true)
  public number: string;

  // @DataMember
  public customerId: string;

  public constructor(init?: Partial<GetPublicTransportGlonassTerminalExistRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return false;
  }
  public getTypeName() {
    return 'GetPublicTransportGlonassTerminalExistRequest';
  }
}

// @Route("/customer/{customerId}/glonassTerminals", "POST")
// @DataContract
export class CreatePublicTransportGlonassTerminalRequest
  implements IReturn<PublicTransportGlonassTerminal>, ICustomerItemRequest, IDescription
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public updateEntityGroups: boolean;

  // @DataMember
  public entityGroups: string[];

  // @DataMember(IsRequired=true)
  public entity: PublicTransportGlonassTerminal;

  // @DataMember
  public description: string;

  public constructor(init?: Partial<CreatePublicTransportGlonassTerminalRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new PublicTransportGlonassTerminal();
  }
  public getTypeName() {
    return 'CreatePublicTransportGlonassTerminalRequest';
  }
}

// @Route("/customer/{customerId}/glonassTerminal/{id}", "PUT")
// @DataContract
export class UpdatePublicTransportGlonassTerminalRequest
  implements
    IReturn<PublicTransportGlonassTerminal>,
    ICustomerItemRequest,
    IBaseEntityItemRequest,
    IDescription
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public updateEntityGroups: boolean;

  // @DataMember
  public entityGroups: string[];

  // @DataMember(IsRequired=true)
  public id: string;

  // @DataMember(IsRequired=true)
  public entity: PublicTransportGlonassTerminal;

  // @DataMember
  public description: string;

  public constructor(init?: Partial<UpdatePublicTransportGlonassTerminalRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new PublicTransportGlonassTerminal();
  }
  public getTypeName() {
    return 'UpdatePublicTransportGlonassTerminalRequest';
  }
}

// @Route("/customer/{customerId}/glonassTerminals", "DELETE")
// @DataContract
export class DeletePublicTransportGlonassTerminalsRequest
  implements IReturn<number>, ICustomerItemRequest, IDescription
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public description: string;

  // @DataMember(IsRequired=true)
  public ids: string[];

  public constructor(init?: Partial<DeletePublicTransportGlonassTerminalsRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return 0;
  }
  public getTypeName() {
    return 'DeletePublicTransportGlonassTerminalsRequest';
  }
}

// @Route("/customer/{customerId}/glonassTerminal/{id}/eventTypes", "GET")
// @DataContract
export class GetPublicTransportGlonassTerminalsEventTypesRequest
  implements IReturn<EntityEventLogType[]>, IBaseEntityItemRequest
{
  // @DataMember
  public id: string;

  public constructor(init?: Partial<GetPublicTransportGlonassTerminalsEventTypesRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new Array<EntityEventLogType>();
  }
  public getTypeName() {
    return 'GetPublicTransportGlonassTerminalsEventTypesRequest';
  }
}

// @Route("/customer/{customerId}/glonassTerminal/{id}/eventLog", "GET")
// @DataContract
export class GetPublicTransportGlonassTerminalsEventLogRequest
  implements IReturn<GetDeviceEventLogResponse>, IBaseEntityItemRequest, ICustomerItemRequest
{
  // @DataMember
  public id: string;

  // @DataMember
  public start?: string;

  // @DataMember
  public end?: string;

  // @DataMember
  public filterEvents: number[];

  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public entityGroups: string[];

  // @DataMember
  public itemsIds: string[];

  // @DataMember
  public onlyOwnedItems: boolean;

  // @DataMember
  public page: number;

  // @DataMember
  public items: number;

  // @DataMember
  public sort: string;

  // @DataMember
  public filter: string;

  // @DataMember
  public propertiesTest: { [index: string]: number };

  public constructor(init?: Partial<GetPublicTransportGlonassTerminalsEventLogRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new GetDeviceEventLogResponse();
  }
  public getTypeName() {
    return 'GetPublicTransportGlonassTerminalsEventLogRequest';
  }
}

// @Route("/customer/{customerId}/waybills", "GET")
// @DataContract
export class GetWaybillsRequest implements IReturn<GetWaybillsResponse>, ICustomerItemRequest {
  // @DataMember
  public onlyActive: boolean;

  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public entityGroups: string[];

  // @DataMember
  public itemsIds: string[];

  // @DataMember
  public onlyOwnedItems: boolean;

  // @DataMember
  public page: number;

  // @DataMember
  public items: number;

  // @DataMember
  public sort: string;

  // @DataMember
  public filter: string;

  // @DataMember
  public propertiesTest: { [index: string]: number };

  public constructor(init?: Partial<GetWaybillsRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new GetWaybillsResponse();
  }
  public getTypeName() {
    return 'GetWaybillsRequest';
  }
}

// @Route("/customer/{customerId}/waybill/{id}", "GET")
// @DataContract
export class GetWaybillRequest
  implements IReturn<Waybill>, ICustomerItemRequest, IBaseEntityItemRequest
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember(IsRequired=true)
  public id: string;

  public constructor(init?: Partial<GetWaybillRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new Waybill();
  }
  public getTypeName() {
    return 'GetWaybillRequest';
  }
}

// @Route("/customer/{customerId}/waybills", "POST")
// @DataContract
export class CreateWaybillRequest implements IReturn<Waybill>, ICustomerItemRequest, IDescription {
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public updateEntityGroups: boolean;

  // @DataMember
  public entityGroups: string[];

  // @DataMember(IsRequired=true)
  public entity: Waybill;

  // @DataMember
  public description: string;

  public constructor(init?: Partial<CreateWaybillRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new Waybill();
  }
  public getTypeName() {
    return 'CreateWaybillRequest';
  }
}

// @Route("/customer/{customerId}/waybill/{id}", "PUT")
// @DataContract
export class UpdateWaybillRequest
  implements IReturn<Waybill>, ICustomerItemRequest, IBaseEntityItemRequest, IDescription
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public updateEntityGroups: boolean;

  // @DataMember
  public entityGroups: string[];

  // @DataMember(IsRequired=true)
  public id: string;

  // @DataMember(IsRequired=true)
  public entity: Waybill;

  // @DataMember
  public description: string;

  public constructor(init?: Partial<UpdateWaybillRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new Waybill();
  }
  public getTypeName() {
    return 'UpdateWaybillRequest';
  }
}

// @Route("/customer/{customerId}/waybills", "DELETE")
// @DataContract
export class DeleteWaybillsRequest implements IReturn<number>, ICustomerItemRequest, IDescription {
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public description: string;

  // @DataMember(IsRequired=true)
  public ids: string[];

  public constructor(init?: Partial<DeleteWaybillsRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return 0;
  }
  public getTypeName() {
    return 'DeleteWaybillsRequest';
  }
}

// @Route("/healthStatus", "GET")
// @DataContract
export class GetHealthStatusRequest implements IReturn<string> {
  public constructor(init?: Partial<GetHealthStatusRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return '';
  }
  public getTypeName() {
    return 'GetHealthStatusRequest';
  }
}

// @Route("/customer/{customerId}/integration/{customerIntegrationId}/entities", "GET")
// @DataContract
export class GetEntityIntegrationsRequest
  implements
    IReturn<GetEntityIntegrationResponse>,
    ICustomerIntegrationIdRequest,
    ICustomerItemRequest
{
  // @DataMember
  public customerIntegrationId: string;

  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public entityGroups: string[];

  // @DataMember
  public itemsIds: string[];

  // @DataMember
  public onlyOwnedItems: boolean;

  // @DataMember
  public page: number;

  // @DataMember
  public items: number;

  // @DataMember
  public sort: string;

  // @DataMember
  public filter: string;

  // @DataMember
  public propertiesTest: { [index: string]: number };

  public constructor(init?: Partial<GetEntityIntegrationsRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new GetEntityIntegrationResponse();
  }
  public getTypeName() {
    return 'GetEntityIntegrationsRequest';
  }
}

// @Route("/customer/{customerId}/integration/{customerIntegrationId}/entity/{id}", "GET")
// @DataContract
export class GetEntityIntegrationRequest
  implements
    IReturn<EntityIntegration>,
    ICustomerIntegrationIdRequest,
    ICustomerItemRequest,
    IBaseEntityItemRequest
{
  // @DataMember
  public customerIntegrationId: string;

  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember(IsRequired=true)
  public id: string;

  public constructor(init?: Partial<GetEntityIntegrationRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new EntityIntegration();
  }
  public getTypeName() {
    return 'GetEntityIntegrationRequest';
  }
}

// @Route("/customer/{customerId}/integration/{customerIntegrationId}/entities", "POST")
// @DataContract
export class CreateEntityIntegrationRequest
  implements
    IReturn<EntityIntegration>,
    ICustomerIntegrationIdRequest,
    ICustomerItemRequest,
    IDescription
{
  // @DataMember
  public customerIntegrationId: string;

  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public updateEntityGroups: boolean;

  // @DataMember
  public entityGroups: string[];

  // @DataMember(IsRequired=true)
  public entity: EntityIntegration;

  // @DataMember
  public description: string;

  public constructor(init?: Partial<CreateEntityIntegrationRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new EntityIntegration();
  }
  public getTypeName() {
    return 'CreateEntityIntegrationRequest';
  }
}

// @Route("/customer/{customerId}/integration/{customerIntegrationId}/entity/{id}", "PUT")
// @DataContract
export class UpdateEntityIntegrationRequest
  implements
    IReturn<EntityIntegration>,
    ICustomerIntegrationIdRequest,
    ICustomerItemRequest,
    IBaseEntityItemRequest,
    IDescription
{
  // @DataMember
  public customerIntegrationId: string;

  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public updateEntityGroups: boolean;

  // @DataMember
  public entityGroups: string[];

  // @DataMember(IsRequired=true)
  public id: string;

  // @DataMember(IsRequired=true)
  public entity: EntityIntegration;

  // @DataMember
  public description: string;

  public constructor(init?: Partial<UpdateEntityIntegrationRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new EntityIntegration();
  }
  public getTypeName() {
    return 'UpdateEntityIntegrationRequest';
  }
}

// @Route("/entity/{id}/properties", "GET")
// @DataContract
export class GetEntityPropertiesRequest implements IReturn<any>, IBaseEntityItemRequest {
  // @DataMember(IsRequired=true)
  public id: string;

  public constructor(init?: Partial<GetEntityPropertiesRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return {};
  }
  public getTypeName() {
    return 'GetEntityPropertiesRequest';
  }
}

// @Route("/entity/{id}/properties", "PUT")
// @DataContract
export class UpdateEntityPropertiesRequest implements IReturn<any> {
  // @DataMember(IsRequired=true)
  public id: string;

  // @DataMember
  public description: string;

  // @DataMember
  public entity: { [index: string]: string };

  public constructor(init?: Partial<UpdateEntityPropertiesRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return {};
  }
  public getTypeName() {
    return 'UpdateEntityPropertiesRequest';
  }
}

// @Route("/entity/{id}/property/{property}", "GET")
// @DataContract
export class GetEntityPropertyRequest implements IReturn<string> {
  // @DataMember
  public property: string;

  // @DataMember(IsRequired=true)
  public id: string;

  public constructor(init?: Partial<GetEntityPropertyRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return '';
  }
  public getTypeName() {
    return 'GetEntityPropertyRequest';
  }
}

// @Route("/entity/{id}/property/{property}", "PUT")
// @DataContract
export class UpdateEntityPropertyRequest implements IReturn<string> {
  // @DataMember(IsRequired=true)
  public id: string;

  // @DataMember
  public description: string;

  // @DataMember
  public property: string;

  // @DataMember
  public value: string;

  public constructor(init?: Partial<UpdateEntityPropertyRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return '';
  }
  public getTypeName() {
    return 'UpdateEntityPropertyRequest';
  }
}

// @Route("/entities/property/{property}", "GET")
// @DataContract
export class GetEntitiesPropertyRequest implements IReturn<any> {
  // @DataMember
  public property: string;

  // @DataMember
  public filter: string;

  public constructor(init?: Partial<GetEntitiesPropertyRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return {};
  }
  public getTypeName() {
    return 'GetEntitiesPropertyRequest';
  }
}

// @Route("/customer/{customerId}/entity/{Id}/groups", "GET")
// @DataContract
export class GetItemEntityGroupsRequest
  implements IReturn<GetEntityGroupsResponse>, ICustomerItemRequest
{
  // @DataMember
  public id: string;

  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public entityGroups: string[];

  // @DataMember
  public itemsIds: string[];

  // @DataMember
  public onlyOwnedItems: boolean;

  // @DataMember
  public page: number;

  // @DataMember
  public items: number;

  // @DataMember
  public sort: string;

  // @DataMember
  public filter: string;

  // @DataMember
  public propertiesTest: { [index: string]: number };

  public constructor(init?: Partial<GetItemEntityGroupsRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new GetEntityGroupsResponse();
  }
  public getTypeName() {
    return 'GetItemEntityGroupsRequest';
  }
}

// @Route("/entities/remoteRepository/{repositoryId}/data", "GET")
// @DataContract
export class GetEntitiesRequest implements IReturn<GetEntitiesResponse> {
  // @DataMember
  public repositoryId: string;

  // @DataMember
  public modified?: string;

  // @DataMember
  public page: number;

  // @DataMember
  public items: number;

  // @DataMember
  public sort: string;

  // @DataMember
  public filter: string;

  // @DataMember
  public propertiesTest: { [index: string]: number };

  public constructor(init?: Partial<GetEntitiesRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new GetEntitiesResponse();
  }
  public getTypeName() {
    return 'GetEntitiesRequest';
  }
}

/**
 * Sign In
 */
// @Route("/auth", "OPTIONS,GET,POST,DELETE")
// @Route("/auth/{provider}", "OPTIONS,GET,POST,DELETE")
// @Api(Description="Sign In")
// @DataContract
export class Authenticate implements IReturn<AuthenticateResponse>, IPost {
  /**
   * AuthProvider, e.g. credentials
   */
  // @DataMember(Order=1)
  public provider: string;

  // @DataMember(Order=2)
  public state: string;

  // @DataMember(Order=3)
  public oauth_token: string;

  // @DataMember(Order=4)
  public oauth_verifier: string;

  // @DataMember(Order=5)
  public userName: string;

  // @DataMember(Order=6)
  public password: string;

  // @DataMember(Order=7)
  public rememberMe?: boolean;

  // @DataMember(Order=9)
  public errorView: string;

  // @DataMember(Order=10)
  public nonce: string;

  // @DataMember(Order=11)
  public uri: string;

  // @DataMember(Order=12)
  public response: string;

  // @DataMember(Order=13)
  public qop: string;

  // @DataMember(Order=14)
  public nc: string;

  // @DataMember(Order=15)
  public cnonce: string;

  // @DataMember(Order=17)
  public accessToken: string;

  // @DataMember(Order=18)
  public accessTokenSecret: string;

  // @DataMember(Order=19)
  public scope: string;

  // @DataMember(Order=20)
  public meta: { [index: string]: string };

  public constructor(init?: Partial<Authenticate>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new AuthenticateResponse();
  }
  public getTypeName() {
    return 'Authenticate';
  }
}
