import { Injectable } from "@angular/core";
import {
  getPageReturn,
  IDataSourceService,
  IPageParams,
  IPageReturn,
  setPageParams,
} from "projects/msu-its-web-common/src/data/data-source";

import { Observable } from "rxjs";
import { map } from "rxjs/operators";

import { FixationPointReplacement, GetFixationPointReplacementsRequest } from "../../dtos/ftv.dtos";
import { FtvApiService } from "../ftv-api.service";

@Injectable()
export class FixationPointLogService
  implements
    IDataSourceService<FixationPointReplacement, FixationPointReplacement> {
  constructor(private _api: FtvApiService) {}

  getPagedList(
    params: IPageParams,
    requestParams: {
      customemrId?: string;
      fixationPointId?: string;
      beginDate?: string;
      endDate?: string;
    }
  ): Observable<IPageReturn<FixationPointReplacement>> {
    const request = new GetFixationPointReplacementsRequest();
    setPageParams(request, params);

    request.fixationPointId = requestParams?.fixationPointId;
    request.customerId = requestParams?.customemrId;
    request.from = requestParams?.beginDate;
    request.to = requestParams?.endDate;

    return this._api.get(request).pipe(
      map((m) => {
        return getPageReturn(m);
      })
    );
  }

  getList(
    filter?: string,
    fixationPointId?: string,
    beginDate?: string,
    endDate?: string,
    count = 20
  ) {
    const request = new GetFixationPointReplacementsRequest();

    request.fixationPointId = fixationPointId;
    request.from = beginDate;
    request.to = endDate;

    request.page = 0;
    request.items = count;
    request.filter = filter;

    request.sort = "activityTime desc";

    return this._api.get(request).pipe(
      map((m) => {
        return getPageReturn(m).data;
      })
    );
  }

  get(id: any): Observable<FixationPointReplacement> {
    throw "not implemented";
  }

  add(model: FixationPointReplacement): Observable<FixationPointReplacement> {
    throw "not implemented";
  }

  update(model: FixationPointReplacement): Observable<FixationPointReplacement> {
    throw "not implemented";
  }

  delete(ids: string[]): Observable<number> {
    throw "not implemented";
  }
}
