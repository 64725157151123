/* Options:
Date: 2023-10-30 04:58:04
Version: 6.70
Tip: To override a DTO option, remove "//" prefix before updating
BaseUrl: https://tlc.dev.its.msu24.ru

//GlobalNamespace: 
//MakePropertiesOptional: False
//AddServiceStackTypes: True
//AddResponseStatus: False
//AddImplicitVersion: 
//AddDescriptionAsComments: True
//IncludeTypes: 
//ExcludeTypes: 
//DefaultImports: 
*/

export interface IReturn<T> {
  createResponse(): T;
}

export interface IReturnVoid {
  createResponse(): void;
}

export interface IHasSessionId {
  sessionId: string;
}

export interface IHasBearerToken {
  bearerToken: string;
}

export interface IPost {}

export interface ICustomerItemRequest extends ICustomerEntity {}

export interface ICustomerEntity {
  // @DataMember
  customerId: string;
}

export interface IBaseEntityItemRequest {
  id: string;
}

export interface IDescription {
  // @DataMember
  description: string;
}

export class SetControllerModeRequestBase
  implements ICustomerItemRequest, IBaseEntityItemRequest, IDescription
{
  // @DataMember
  public id: string;

  // @DataMember
  public customerId: string;

  // @DataMember
  public async: boolean;

  // @DataMember
  public description: string;

  public constructor(init?: Partial<SetControllerModeRequestBase>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class PageRequest {
  // @DataMember
  public page: number;

  // @DataMember
  public items: number;

  // @DataMember
  public sort: string;

  // @DataMember
  public filter: string;

  // @DataMember
  public propertiesTest: { [index: string]: number };

  public constructor(init?: Partial<PageRequest>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class GetEntityGroupsRequestBase implements ICustomerItemRequest {
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public entityGroups: string[];

  // @DataMember
  public itemsIds: string[];

  // @DataMember
  public onlyOwnedItems: boolean;

  // @DataMember
  public page: number;

  // @DataMember
  public items: number;

  // @DataMember
  public sort: string;

  // @DataMember
  public filter: string;

  // @DataMember
  public propertiesTest: { [index: string]: number };

  public constructor(init?: Partial<GetEntityGroupsRequestBase>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class GetEntityGroupRequestBase implements ICustomerItemRequest, IBaseEntityItemRequest {
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember(IsRequired=true)
  public id: string;

  public constructor(init?: Partial<GetEntityGroupRequestBase>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class EntityGroup
  implements ICustomerEntityViewModel, INamedEntity, IChangeKey, IDescription, IModified, IEntity
{
  // @DataMember
  public items: string[];

  // @DataMember
  public customerId: string;

  // @DataMember
  public customerName: string;

  // @DataMember
  public name: string;

  // @DataMember
  public description: string;

  // @DataMember
  public changeKey: string;

  // @DataMember
  public created: string;

  // @DataMember
  public createdBy: string;

  // @DataMember
  public modifiedBy: string;

  // @DataMember
  public modified: string;

  // @DataMember
  public id: string;

  // @DataMember
  public entityType: string;

  public constructor(init?: Partial<EntityGroup>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class CreateEntityGroupRequestBase implements ICustomerItemRequest, IDescription {
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public updateEntityGroups: boolean;

  // @DataMember
  public entityGroups: string[];

  // @DataMember(IsRequired=true)
  public entity: EntityGroup;

  // @DataMember
  public description: string;

  public constructor(init?: Partial<CreateEntityGroupRequestBase>) {
    (Object as any).assign(this, init);
  }
}

export interface IRequestWithEntity {}

// @DataContract
export class UpdateEntityGroupRequestBase
  implements ICustomerItemRequest, IBaseEntityItemRequest, IDescription
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public updateEntityGroups: boolean;

  // @DataMember
  public entityGroups: string[];

  // @DataMember(IsRequired=true)
  public id: string;

  // @DataMember(IsRequired=true)
  public entity: EntityGroup;

  // @DataMember
  public description: string;

  public constructor(init?: Partial<UpdateEntityGroupRequestBase>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class DeleteEntityGroupsRequestBase implements ICustomerItemRequest, IDescription {
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public description: string;

  // @DataMember(IsRequired=true)
  public ids: string[];

  public constructor(init?: Partial<DeleteEntityGroupsRequestBase>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class RouteInfo
  implements ICustomerEntityViewModel, INamedEntity, IChangeKey, IDescription, IModified, IEntity
{
  // @DataMember
  public customerId: string;

  // @DataMember
  public customerName: string;

  // @DataMember
  public name: string;

  // @DataMember
  public description: string;

  // @DataMember
  public changeKey: string;

  // @DataMember
  public created: string;

  // @DataMember
  public createdBy: string;

  // @DataMember
  public modifiedBy: string;

  // @DataMember
  public modified: string;

  // @DataMember
  public id: string;

  // @DataMember
  public entityType: string;

  public constructor(init?: Partial<RouteInfo>) {
    (Object as any).assign(this, init);
  }
}

export class DeleteCustomerItemsRequest implements ICustomerItemRequest, IDescription {
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public description: string;

  // @DataMember(IsRequired=true)
  public ids: string[];

  public constructor(init?: Partial<DeleteCustomerItemsRequest>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class EntityEventLogDetails {
  // @DataMember
  public id: number;

  // @DataMember
  public eventTime: string;

  // @DataMember
  public eventId: number;

  // @DataMember
  public message: string;

  // @DataMember
  public data: string;

  public constructor(init?: Partial<EntityEventLogDetails>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export enum TrafficControllerType {
  None = 'None',
  Potok = 'Potok',
  Usdk = 'Usdk',
  Megapolis = 'Megapolis',
}

// @DataContract
export class TrafficObjectControllerInfo
  implements
    ICustomerEntity,
    ICustomerName,
    IDeviceItem,
    IOwnedDeviceItem,
    IChangeKey,
    IDescription,
    IModified,
    IEntity
{
  // @DataMember
  public controllerType: TrafficControllerType;

  // @DataMember
  public customerId: string;

  // @DataMember
  public customerName: string;

  // @DataMember
  public ownerObjectName: string;

  // @DataMember
  public ownerObjectId: string;

  // @DataMember
  public prefix: string;

  // @DataMember
  public number: string;

  // @DataMember
  public phone1: string;

  // @DataMember
  public phone2: string;

  // @DataMember
  public ip1: string;

  // @DataMember
  public ip2: string;

  // @DataMember
  public imei: string;

  // @DataMember
  public lat: number;

  // @DataMember
  public lon: number;

  // @DataMember
  public direction: number;

  // @DataMember
  public fullNumber: string;

  // @DataMember
  public softwareVersion: string;

  // @DataMember
  public firmwareVersion: string;

  // @DataMember
  public hardwareVersion: string;

  // @DataMember
  public description: string;

  // @DataMember
  public changeKey: string;

  // @DataMember
  public created: string;

  // @DataMember
  public createdBy: string;

  // @DataMember
  public modifiedBy: string;

  // @DataMember
  public modified: string;

  // @DataMember
  public id: string;

  // @DataMember
  public entityType: string;

  public constructor(init?: Partial<TrafficObjectControllerInfo>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class ChangeCustomerRequestBase
  implements IDescription, ICustomerItemRequest, IBaseEntityItemRequest
{
  // @DataMember
  public description: string;

  // @DataMember
  public newCustomerId: string;

  // @DataMember
  public customerId: string;

  // @DataMember
  public id: string;

  public constructor(init?: Partial<ChangeCustomerRequestBase>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class GetEntityGroupSharesRequestBase
  implements IBaseEntityItemRequest, ICustomerItemRequest
{
  // @DataMember
  public id: string;

  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public entityGroups: string[];

  // @DataMember
  public itemsIds: string[];

  // @DataMember
  public onlyOwnedItems: boolean;

  // @DataMember
  public page: number;

  // @DataMember
  public items: number;

  // @DataMember
  public sort: string;

  // @DataMember
  public filter: string;

  // @DataMember
  public propertiesTest: { [index: string]: number };

  public constructor(init?: Partial<GetEntityGroupSharesRequestBase>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class EntityGroupPermissions implements IEntityTyped, ICustomerEntityViewModel {
  // @DataMember
  public customerId: string;

  // @DataMember
  public customerName: string;

  // @DataMember
  public entityGroupId: string;

  // @DataMember
  public permissions: string[];

  // @DataMember
  public entityType: string;

  public constructor(init?: Partial<EntityGroupPermissions>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class UpgradeEntityGroupShareRequestBase implements ICustomerItemRequest, IDescription {
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember(IsRequired=true)
  public id: string;

  // @DataMember(IsRequired=true)
  public entity: EntityGroupPermissions;

  // @DataMember
  public description: string;

  public constructor(init?: Partial<UpgradeEntityGroupShareRequestBase>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export enum AdaptiveType {
  Pedestrian = 'Pedestrian',
  PublicTransport = 'PublicTransport',
  SpecialTransport = 'SpecialTransport',
  Transport = 'Transport',
  Local = 'Local',
}

// @DataContract
export enum TrafficObjectState {
  Off = 'Off',
  YellowBlink = 'YellowBlink',
  AllRed = 'AllRed',
  Phase = 'Phase',
}

// @DataContract
export class TrafficObjectInfo
  implements ICustomerEntityViewModel, INamedEntity, IChangeKey, IDescription, IModified, IEntity
{
  // @DataMember
  public state: TrafficObjectState;

  // @DataMember
  public controllerType: TrafficControllerType;

  // @DataMember
  public lat: number;

  // @DataMember
  public lon: number;

  // @DataMember
  public controllerId: string;

  // @DataMember
  public controllerNumber: string;

  // @DataMember
  public controller: TrafficObjectControllerInfo;

  // @DataMember
  public controllerFirmwareVersion: string;

  // @DataMember
  public controllerSoftwareVersion: string;

  // @DataMember
  public controllerHardwareVersion: string;

  // @DataMember
  public adaptiveTypes: AdaptiveType[];

  // @DataMember
  public customerId: string;

  // @DataMember
  public customerName: string;

  // @DataMember
  public name: string;

  // @DataMember
  public description: string;

  // @DataMember
  public changeKey: string;

  // @DataMember
  public created: string;

  // @DataMember
  public createdBy: string;

  // @DataMember
  public modifiedBy: string;

  // @DataMember
  public modified: string;

  // @DataMember
  public id: string;

  // @DataMember
  public entityType: string;

  public constructor(init?: Partial<TrafficObjectInfo>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class GetCustomerEntityCurrentUserPermissionsRequestBase
  implements ICustomerItemRequest, IBaseEntityItemRequest
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember(IsRequired=true)
  public id: string;

  public constructor(init?: Partial<GetCustomerEntityCurrentUserPermissionsRequestBase>) {
    (Object as any).assign(this, init);
  }
}

export enum ControllerEventType {
  Power220 = 'Power220',
  NGParitet = 'NGParitet',
  LostConnection = 'LostConnection',
  NoControl = 'NoControl',
  Conflict = 'Conflict',
  NoCoordination = 'NoCoordination',
  NegodPoPereg = 'NegodPoPereg',
  Offline = 'Offline',
  RezervProg = 'RezervProg',
  YellowBlink = 'YellowBlink',
  Off = 'Off',
  ButtonNoConnection = 'ButtonNoConnection',
  LampControl = 'LampControl',
  Adaptive = 'Adaptive',
  PowerBoardOffline = 'PowerBoardOffline',
  ControllerChange = 'ControllerChange',
}

// @DataContract
export class TrafficObjectIconStatus {
  // @DataMember
  public color: string;

  // @DataMember
  public borderColor: string;

  // @DataMember
  public processing: boolean;

  public constructor(init?: Partial<TrafficObjectIconStatus>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class TrafficObjectStatusInfo {
  // @DataMember
  public id: string;

  // @DataMember
  public name: string;

  // @DataMember
  public iconStatus: TrafficObjectIconStatus;

  public constructor(init?: Partial<TrafficObjectStatusInfo>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export enum TrafficObjectMode {
  Off = 'Off',
  Coordination = 'Coordination',
  Adaptive = 'Adaptive',
  Remote = 'Remote',
  Local = 'Local',
}

// @DataContract
export enum TrafficMovementLight {
  Off = 'Off',
  Red = 'Red',
  Yellow = 'Yellow',
  Green = 'Green',
  RedYellow = 'RedYellow',
  YellowBlink = 'YellowBlink',
  GreenBlink = 'GreenBlink',
}

// @DataContract
export class TrafficSchemaLight {
  // @DataMember(Order=0)
  public move1: TrafficMovementLight;

  // @DataMember(Order=1)
  public move2: TrafficMovementLight;

  // @DataMember(Order=2)
  public move3: TrafficMovementLight;

  // @DataMember(Order=3)
  public move4: TrafficMovementLight;

  // @DataMember(Order=4)
  public move5: TrafficMovementLight;

  // @DataMember(Order=5)
  public move6: TrafficMovementLight;

  // @DataMember(Order=6)
  public move7: TrafficMovementLight;

  // @DataMember(Order=7)
  public move8: TrafficMovementLight;

  // @DataMember(Order=8)
  public move12: TrafficMovementLight;

  // @DataMember(Order=9)
  public move14: TrafficMovementLight;

  // @DataMember(Order=10)
  public move16: TrafficMovementLight;

  // @DataMember(Order=11)
  public move18: TrafficMovementLight;

  // @DataMember(Order=12)
  public moveP2: TrafficMovementLight;

  // @DataMember(Order=13)
  public moveP4: TrafficMovementLight;

  // @DataMember(Order=14)
  public moveP6: TrafficMovementLight;

  // @DataMember(Order=15)
  public moveP8: TrafficMovementLight;

  public constructor(init?: Partial<TrafficSchemaLight>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class PedestrianAdaptiveStatus implements IEnabled {
  // @DataMember
  public enabled: boolean;

  // @DataMember
  public active: boolean;

  public constructor(init?: Partial<PedestrianAdaptiveStatus>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class PublicTransportAdaptiveStatus implements IEnabled {
  // @DataMember
  public enabled: boolean;

  // @DataMember
  public active: boolean;

  public constructor(init?: Partial<PublicTransportAdaptiveStatus>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class SpecialTransportAdaptiveStatus implements IEnabled {
  // @DataMember
  public enabled: boolean;

  // @DataMember
  public active: boolean;

  public constructor(init?: Partial<SpecialTransportAdaptiveStatus>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class TransportAdaptiveStatus implements IEnabled {
  // @DataMember
  public enabled: boolean;

  // @DataMember
  public active: boolean;

  public constructor(init?: Partial<TransportAdaptiveStatus>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class LocalAdaptiveStatus implements IEnabled {
  // @DataMember
  public enabled: boolean;

  // @DataMember
  public active: boolean;

  public constructor(init?: Partial<LocalAdaptiveStatus>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class TrafficObjectAdaptiveStatus {
  // @DataMember
  public pedestrian: PedestrianAdaptiveStatus;

  // @DataMember
  public publicTransport: PublicTransportAdaptiveStatus;

  // @DataMember
  public specialTransport: SpecialTransportAdaptiveStatus;

  // @DataMember
  public transport: TransportAdaptiveStatus;

  // @DataMember
  public local: LocalAdaptiveStatus;

  public constructor(init?: Partial<TrafficObjectAdaptiveStatus>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class TrafficObjectStatus implements IEntity {
  // @DataMember
  public serverTime: string;

  // @DataMember
  public stateTime: string;

  // @DataMember
  public mode: TrafficObjectMode;

  // @DataMember
  public state: TrafficObjectState;

  // @DataMember
  public iconStatus: TrafficObjectIconStatus;

  // @DataMember
  public online: boolean;

  // @DataMember
  public controllerLastConnectionTime: string;

  // @DataMember
  public controllerLastPacketTime: string;

  // @DataMember
  public controllerTime: string;

  // @DataMember
  public controllerStatusDescription: string;

  // @DataMember
  public controllerErrorDescription: string;

  // @DataMember
  public schemaLights: TrafficSchemaLight;

  // @DataMember
  public commandProcessing: boolean;

  // @DataMember
  public coordinationPlan: number;

  // @DataMember
  public planTime: number;

  // @DataMember
  public adaptiveStatus: TrafficObjectAdaptiveStatus;

  // @DataMember
  public id: string;

  // @DataMember
  public entityType: string;

  public constructor(init?: Partial<TrafficObjectStatus>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class PedestrianAdaptiveOptions implements IEnabled {
  // @DataMember
  public adaptiveType: AdaptiveType;

  // @DataMember
  public enabled: boolean;

  public constructor(init?: Partial<PedestrianAdaptiveOptions>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class PublicTransportAdaptiveOptions implements IEnabled {
  // @DataMember
  public adaptiveType: AdaptiveType;

  // @DataMember
  public enabled: boolean;

  public constructor(init?: Partial<PublicTransportAdaptiveOptions>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class SpecialTransportAdaptiveOptions implements IEnabled {
  // @DataMember
  public adaptiveType: AdaptiveType;

  // @DataMember
  public enabled: boolean;

  public constructor(init?: Partial<SpecialTransportAdaptiveOptions>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class TransportAdaptiveOptions implements IEnabled {
  // @DataMember
  public adaptiveType: AdaptiveType;

  // @DataMember
  public enabled: boolean;

  public constructor(init?: Partial<TransportAdaptiveOptions>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class LocalAdaptiveOptions implements IEnabled {
  // @DataMember
  public adaptiveType: AdaptiveType;

  // @DataMember
  public enabled: boolean;

  public constructor(init?: Partial<LocalAdaptiveOptions>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class TrafficObjectAdaptiveSettings {
  // @DataMember
  public pedestrian: PedestrianAdaptiveOptions;

  // @DataMember
  public publicTransport: PublicTransportAdaptiveOptions;

  // @DataMember
  public specialTransport: SpecialTransportAdaptiveOptions;

  // @DataMember
  public transport: TransportAdaptiveOptions;

  // @DataMember
  public local: LocalAdaptiveOptions;

  public constructor(init?: Partial<TrafficObjectAdaptiveSettings>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class AdaptiveTrafficObjectInfo
  implements ICustomerEntityViewModel, INamedEntity, IChangeKey, IDescription, IModified, IEntity
{
  // @DataMember
  public adaptiveSettings: TrafficObjectAdaptiveSettings;

  // @DataMember
  public adaptiveSettingsDescription: string;

  // @DataMember
  public state: TrafficObjectState;

  // @DataMember
  public controllerType: TrafficControllerType;

  // @DataMember
  public lat: number;

  // @DataMember
  public lon: number;

  // @DataMember
  public controllerId: string;

  // @DataMember
  public controllerNumber: string;

  // @DataMember
  public controller: TrafficObjectControllerInfo;

  // @DataMember
  public controllerFirmwareVersion: string;

  // @DataMember
  public controllerSoftwareVersion: string;

  // @DataMember
  public controllerHardwareVersion: string;

  // @DataMember
  public adaptiveTypes: AdaptiveType[];

  // @DataMember
  public customerId: string;

  // @DataMember
  public customerName: string;

  // @DataMember
  public name: string;

  // @DataMember
  public description: string;

  // @DataMember
  public changeKey: string;

  // @DataMember
  public created: string;

  // @DataMember
  public createdBy: string;

  // @DataMember
  public modifiedBy: string;

  // @DataMember
  public modified: string;

  // @DataMember
  public id: string;

  // @DataMember
  public entityType: string;

  public constructor(init?: Partial<AdaptiveTrafficObjectInfo>) {
    (Object as any).assign(this, init);
  }
}

// @Flags()
// @DataContract
export enum PotokMovementType {
  Move1 = 'Move1',
  Move2 = 'Move2',
  Move3 = 'Move3',
  Move4 = 'Move4',
  Move5 = 'Move5',
  Move6 = 'Move6',
  Move7 = 'Move7',
  Move8 = 'Move8',
  Move12 = 'Move12',
  Move14 = 'Move14',
  Move16 = 'Move16',
  Move18 = 'Move18',
  MoveP2 = 'MoveP2',
  MoveP4 = 'MoveP4',
  MoveP6 = 'MoveP6',
  MoveP8 = 'MoveP8',
}

// @DataContract
export enum PotokPlanType {
  None = 'None',
  PhaseCycle = 'PhaseCycle',
  YellowBlink = 'YellowBlink',
  Off = 'Off',
}

// @DataContract
export class PotokPlan implements IEnabled {
  // @DataMember(Order=1)
  public id: number;

  // @DataMember(Order=2)
  public name: string;

  // @DataMember(Order=3)
  public type: PotokPlanType;

  // @DataMember(Order=4)
  public offset: number;

  // @DataMember(Order=5)
  public length: number;

  // @DataMember(Order=6)
  public enabled: boolean;

  // @DataMember(Order=7)
  public transportBarriers: PotokBarrier[];

  // @DataMember(Order=8)
  public pedestrianBarriers: PotokBarrier[];

  // @DataMember(Order=9)
  public pedestrianPhasesEnabled: boolean;

  public constructor(init?: Partial<PotokPlan>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export enum PotokBarrierType {
  MajorStreet = 'MajorStreet',
  MinorStreet = 'MinorStreet',
  Static = 'Static',
}

export interface ICustomerIntegrationIdRequest {
  customerIntegrationId: string;
}

// @DataContract
export class UsdkSchemaPhaseStep {
  // @DataMember(Order=0)
  public startTime: string;

  // @DataMember(Order=1)
  public changeIntervalEnd?: string;

  // @DataMember(Order=2)
  public endTime?: string;

  // @DataMember(Order=3)
  public lights: TrafficSchemaLight;

  // @DataMember(Order=3)
  public phaseId: number;

  public constructor(init?: Partial<UsdkSchemaPhaseStep>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export enum DKMode {
  RU = 'RU',
  RP = 'RP',
  ZU = 'ZU',
  DU = 'DU',
  LU = 'LU',
  LRP = 'LRP',
  MGR = 'MGR',
  KU = 'KU',
  RKU = 'RKU',
}

// @DataContract
export class UsdkTrafficStatus {
  // @DataMember
  public previousPhase: UsdkSchemaPhaseStep;

  // @DataMember
  public currentPhase: UsdkSchemaPhaseStep;

  // @DataMember
  public trafficMode: DKMode;

  // @DataMember
  public trafficState: TrafficObjectState;

  // @DataMember
  public changeInterval: boolean;

  // @DataMember
  public coordinationPlan: number;

  // @DataMember
  public dayPlan: number;

  // @DataMember
  public weekPlan: number;

  // @DataMember
  public planLength: number;

  // @DataMember
  public planTime: number;

  // @DataMember
  public nextPhase: number;

  // @DataMember
  public packetCounter: number;

  // @DataMember
  public currentPhaseTimeLeft?: number;

  public constructor(init?: Partial<UsdkTrafficStatus>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export enum DKState {
  OK = 'OK',
  PEREHOD = 'PEREHOD',
  OBRIVLS = 'OBRIVLS',
  HGPARITET = 'HGPARITET',
  Unknown4 = 'Unknown4',
  KONFL_SIT = 'KONFL_SIT',
  NEGOD_PO_PEREG = 'NEGOD_PO_PEREG',
  NEVKL_V_KOORD = 'NEVKL_V_KOORD',
  nepodkomanda = 'nepodkomanda',
  DLIN_PROMTAKT = 'DLIN_PROMTAKT',
  NESUSH_FAZA = 'NESUSH_FAZA',
  OBRIV_CV_USDK = 'OBRIV_CV_USDK',
  obriv_LS_EVM = 'obriv_LS_EVM',
}

// @DataContract
export enum UsdkDevice {
  VPU = 'VPU',
  SKA = 'SKA',
  IPUSDK = 'IPUSDK',
  USDK = 'USDK',
  IPKZ1 = 'IPKZ1',
  DPOU = 'DPOU',
  EVM = 'EVM',
  KZC1 = 'KZC1',
  EVM2 = 'EVM2',
}

// @DataContract
export enum UsdkControllerErrorType {
  Offline = 'Offline',
  PowerOff = 'PowerOff',
  LampControl = 'LampControl',
  NgParitet = 'NgParitet',
  ConflictSit = 'ConflictSit',
  NegodPoPereg = 'NegodPoPereg',
  NevklVKoord = 'NevklVKoord',
  NepodCommand = 'NepodCommand',
  ObrivLs = 'ObrivLs',
  ObrivLsEvm = 'ObrivLsEvm',
  ClockNotSync = 'ClockNotSync',
  GpsError = 'GpsError',
  NoData = 'NoData',
}

// @DataContract
export class UsdkControllerError {
  // @DataMember(Order=0)
  public startTime: string;

  // @DataMember(Order=1)
  public type: UsdkControllerErrorType;

  // @DataMember(Order=2)
  public data: string;

  // @DataMember(Order=3)
  public details: string;

  public constructor(init?: Partial<UsdkControllerError>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class UsdkControllerStatus {
  // @DataMember
  public statusDescription: string;

  // @DataMember
  public state: DKState;

  // @DataMember
  public device: UsdkDevice;

  // @DataMember
  public gps: number;

  // @DataMember
  public errors: UsdkControllerError[];

  // @DataMember
  public mode: DKMode;

  // @DataMember
  public packetCounter: number;

  public constructor(init?: Partial<UsdkControllerStatus>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class UsdkStatus {
  // @DataMember(Order=0)
  public traffic: UsdkTrafficStatus;

  // @DataMember(Order=1)
  public controller: UsdkControllerStatus;

  public constructor(init?: Partial<UsdkStatus>) {
    (Object as any).assign(this, init);
  }
}

export interface IEntity {}

export interface IEntityTyped {
  // @DataMember
  entityType: string;
}

export interface IChangeKey extends IReadonlyChangeKey {
  // @DataMember
  changeKey: string;
}

export interface IReadonlyChangeKey {
  // @DataMember
  changeKey: string;
}

// @DataContract
export class UsdkPhase {
  // @DataMember
  public id: number;

  // @DataMember
  public callPhase: boolean;

  // @DataMember
  public movements: TrafficMovementType[];

  public constructor(init?: Partial<UsdkPhase>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export enum UsdkPlanType {
  None = 'None',
  PhaseCycle = 'PhaseCycle',
  LocalMode = 'LocalMode',
  YellowBlink = 'YellowBlink',
  Disabled = 'Disabled',
  PhaseCycleLocal = 'PhaseCycleLocal',
}

// @DataContract
export enum TrafficPlanType {
  PhaseCycle = 'PhaseCycle',
  YellowBlink = 'YellowBlink',
  Off = 'Off',
}

// @DataContract
export class UsdkPlan implements IEnabled {
  // @DataMember
  public usdkPlanType: UsdkPlanType;

  // @DataMember
  public barriers: UsdkBarrier[];

  // @DataMember
  public id: number;

  // @DataMember
  public name: string;

  // @DataMember
  public type: TrafficPlanType;

  // @DataMember
  public offset: number;

  // @DataMember
  public length: number;

  // @DataMember
  public enabled: boolean;

  public constructor(init?: Partial<UsdkPlan>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class UsdkPlans {
  // @DataMember
  public plan01: UsdkPlan;

  // @DataMember
  public plan02: UsdkPlan;

  // @DataMember
  public plan03: UsdkPlan;

  // @DataMember
  public plan04: UsdkPlan;

  // @DataMember
  public plan05: UsdkPlan;

  // @DataMember
  public plan06: UsdkPlan;

  // @DataMember
  public plan07: UsdkPlan;

  // @DataMember
  public plan08: UsdkPlan;

  // @DataMember
  public plan09: UsdkPlan;

  // @DataMember
  public plan10: UsdkPlan;

  // @DataMember
  public plan11: UsdkPlan;

  // @DataMember
  public plan12: UsdkPlan;

  public constructor(init?: Partial<UsdkPlans>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class UsdkDayTime {
  // @DataMember
  public planId: number;

  // @DataMember
  public time: string;

  public constructor(init?: Partial<UsdkDayTime>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class UsdkScheduleDay {
  // @DataMember
  public id: number;

  // @DataMember
  public times: UsdkDayTime[];

  public constructor(init?: Partial<UsdkScheduleDay>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class UsdkScheduleWeek {
  // @DataMember
  public id: number;

  // @DataMember
  public monday: number;

  // @DataMember
  public tuesday: number;

  // @DataMember
  public wednesday: number;

  // @DataMember
  public thursday: number;

  // @DataMember
  public friday: number;

  // @DataMember
  public saturday: number;

  // @DataMember
  public sunday: number;

  public constructor(init?: Partial<UsdkScheduleWeek>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class UsdkScheduleYear {
  // @DataMember
  public january: number[];

  // @DataMember
  public february: number[];

  // @DataMember
  public march: number[];

  // @DataMember
  public april: number[];

  // @DataMember
  public may: number[];

  // @DataMember
  public june: number[];

  // @DataMember
  public july: number[];

  // @DataMember
  public august: number[];

  // @DataMember
  public september: number[];

  // @DataMember
  public october: number[];

  // @DataMember
  public november: number[];

  // @DataMember
  public december: number[];

  public constructor(init?: Partial<UsdkScheduleYear>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class UsdkSchedule {
  // @DataMember
  public days: UsdkScheduleDay[];

  // @DataMember
  public weeks: UsdkScheduleWeek[];

  // @DataMember
  public year: UsdkScheduleYear;

  public constructor(init?: Partial<UsdkSchedule>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class TrafficControllerSettingsBase {
  public constructor(init?: Partial<TrafficControllerSettingsBase>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export enum UsdkTriggerType {
  None = 'None',
  TVP1 = 'TVP1',
  TVP2 = 'TVP2',
  TVP12 = 'TVP12',
}

export class UsdkBarrier {
  // @DataMember
  public phaseReplace: number;

  // @DataMember
  public trigger: UsdkTriggerType;

  // @DataMember
  public minLength: number;

  // @DataMember
  public length: number;

  // @DataMember
  public phase: number;

  public constructor(init?: Partial<UsdkBarrier>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class TrafficPlanBase implements IEnabled {
  // @DataMember
  public id: number;

  // @DataMember
  public name: string;

  // @DataMember
  public type: TrafficPlanType;

  // @DataMember
  public offset: number;

  // @DataMember
  public length: number;

  // @DataMember
  public enabled: boolean;

  public constructor(init?: Partial<TrafficPlanBase>) {
    (Object as any).assign(this, init);
  }
}

export interface IEnabled {
  enabled: boolean;
}

// @DataContract
export class EntityGroupInfo
  implements ICustomerEntityViewModel, INamedEntity, IChangeKey, IDescription, IModified, IEntity
{
  // @DataMember
  public itemsCount: number;

  // @DataMember
  public customerId: string;

  // @DataMember
  public customerName: string;

  // @DataMember
  public name: string;

  // @DataMember
  public description: string;

  // @DataMember
  public changeKey: string;

  // @DataMember
  public created: string;

  // @DataMember
  public createdBy: string;

  // @DataMember
  public modifiedBy: string;

  // @DataMember
  public modified: string;

  // @DataMember
  public id: string;

  // @DataMember
  public entityType: string;

  public constructor(init?: Partial<EntityGroupInfo>) {
    (Object as any).assign(this, init);
  }
}

export class CustomerNamedBaseEntityViewModelBase
  implements ICustomerEntityViewModel, INamedEntity, IChangeKey, IDescription, IModified, IEntity
{
  // @DataMember
  public customerId: string;

  // @DataMember
  public customerName: string;

  // @DataMember
  public name: string;

  // @DataMember
  public description: string;

  // @DataMember
  public changeKey: string;

  // @DataMember
  public created: string;

  // @DataMember
  public createdBy: string;

  // @DataMember
  public modifiedBy: string;

  // @DataMember
  public modified: string;

  // @DataMember
  public id: string;

  // @DataMember
  public entityType: string;

  public constructor(init?: Partial<CustomerNamedBaseEntityViewModelBase>) {
    (Object as any).assign(this, init);
  }
}

export interface IModified {
  // @DataMember
  modified: string;

  // @DataMember
  modifiedBy: string;
}

export interface INamedEntity extends IEntity, INamed {}

export interface INamed {
  // @DataMember
  name: string;
}

export interface ICustomerEntityViewModel extends ICustomerName, ICustomerEntity {}

export interface ICustomerName {
  // @DataMember
  customerName: string;
}

// @DataContract
export class RouteSettings implements IEntity {
  // @DataMember
  public routeObjects: RouteObject[];

  // @DataMember
  public id: string;

  // @DataMember
  public entityType: string;

  public constructor(init?: Partial<RouteSettings>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export enum EntityEventLogLevel {
  Trace = 'Trace',
  Debug = 'Debug',
  Info = 'Info',
  Warn = 'Warn',
  Error = 'Error',
  Fatal = 'Fatal',
}

// @DataContract
export class EntityEventLogType {
  // @DataMember
  public id: number;

  // @DataMember
  public name: string;

  // @DataMember
  public level: EntityEventLogLevel;

  public constructor(init?: Partial<EntityEventLogType>) {
    (Object as any).assign(this, init);
  }
}

export class CustomerDeviceViewModelBase
  implements
    ICustomerEntity,
    ICustomerName,
    IDeviceItem,
    IOwnedDeviceItem,
    IChangeKey,
    IDescription,
    IModified,
    IEntity
{
  // @DataMember
  public customerId: string;

  // @DataMember
  public customerName: string;

  // @DataMember
  public ownerObjectName: string;

  // @DataMember
  public ownerObjectId: string;

  // @DataMember
  public prefix: string;

  // @DataMember
  public number: string;

  // @DataMember
  public phone1: string;

  // @DataMember
  public phone2: string;

  // @DataMember
  public ip1: string;

  // @DataMember
  public ip2: string;

  // @DataMember
  public imei: string;

  // @DataMember
  public deviceSettings: string;

  // @DataMember
  public lat: number;

  // @DataMember
  public lon: number;

  // @DataMember
  public direction: number;

  // @DataMember
  public fullNumber: string;

  // @DataMember
  public softwareVersion: string;

  // @DataMember
  public firmwareVersion: string;

  // @DataMember
  public hardwareVersion: string;

  // @DataMember
  public description: string;

  // @DataMember
  public changeKey: string;

  // @DataMember
  public created: string;

  // @DataMember
  public createdBy: string;

  // @DataMember
  public modifiedBy: string;

  // @DataMember
  public modified: string;

  // @DataMember
  public id: string;

  // @DataMember
  public entityType: string;

  public constructor(init?: Partial<CustomerDeviceViewModelBase>) {
    (Object as any).assign(this, init);
  }
}

export interface IDeviceItem extends IDeviceVersion {
  // @DataMember
  prefix: string;

  // @DataMember
  number: string;

  // @DataMember
  phone1: string;

  // @DataMember
  phone2: string;

  // @DataMember
  ip1: string;

  // @DataMember
  ip2: string;

  // @DataMember
  imei: string;

  // @DataMember
  lat: number;

  // @DataMember
  lon: number;

  // @DataMember
  direction: number;
}

export interface IDeviceVersion {
  // @DataMember
  softwareVersion: string;

  // @DataMember
  firmwareVersion: string;

  // @DataMember
  hardwareVersion: string;
}

export interface IOwnedDeviceItem {
  // @DataMember
  ownerObjectName: string;

  // @DataMember
  ownerObjectId: string;
}

// @DataContract
export class TrafficTurnMovement implements IEnabled {
  // @DataMember(Order=10)
  public controlled: boolean;

  // @DataMember(Order=11)
  public lanes: number;

  // @DataMember(Order=12)
  public lanesProtected: number;

  // @DataMember(Order=0)
  public enabled: boolean;

  // @DataMember(Order=1)
  public tMin: number;

  public constructor(init?: Partial<TrafficTurnMovement>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class TrafficPedestrianMovement implements IEnabled {
  // @DataMember(Order=10)
  public zebraWidth: number;

  // @DataMember(Order=11)
  public zebraCrossOffset: number;

  // @DataMember(Order=0)
  public enabled: boolean;

  // @DataMember(Order=1)
  public tMin: number;

  public constructor(init?: Partial<TrafficPedestrianMovement>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class TrafficStraightMovement implements IEnabled {
  // @DataMember(Order=10)
  public deadEnd: boolean;

  // @DataMember(Order=13)
  public lanesIn: number;

  // @DataMember(Order=14)
  public lanesOut: number;

  // @DataMember(Order=15)
  public laneWidth: number;

  // @DataMember(Order=0)
  public enabled: boolean;

  // @DataMember(Order=1)
  public tMin: number;

  public constructor(init?: Partial<TrafficStraightMovement>) {
    (Object as any).assign(this, init);
  }
}

export class TrafficWayMovement {
  // @DataMember(Order=10)
  public left: TrafficTurnMovement;

  // @DataMember(Order=11)
  public right: TrafficTurnMovement;

  // @DataMember(Order=12)
  public pedestrian: TrafficPedestrianMovement;

  // @DataMember(Order=13)
  public straight: TrafficStraightMovement;

  public constructor(init?: Partial<TrafficWayMovement>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class TrafficSchema {
  // @DataMember(Order=0)
  public way2: TrafficWayMovement;

  // @DataMember(Order=1)
  public way4: TrafficWayMovement;

  // @DataMember(Order=2)
  public way6: TrafficWayMovement;

  // @DataMember(Order=3)
  public way8: TrafficWayMovement;

  public constructor(init?: Partial<TrafficSchema>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class TrafficSchemaViewSettings {
  // @DataMember
  public majorStreet: string;

  // @DataMember
  public minorStreet: string;

  // @DataMember
  public way2: string;

  // @DataMember
  public way6: string;

  // @DataMember
  public way4: string;

  // @DataMember
  public way8: string;

  // @DataMember
  public zoom: number;

  // @DataMember
  public angle: number;

  // @DataMember
  public angle2: number;

  // @DataMember
  public angle4: number;

  // @DataMember
  public angle6: number;

  // @DataMember
  public angle8: number;

  // @DataMember
  public lat: number;

  // @DataMember
  public lon: number;

  public constructor(init?: Partial<TrafficSchemaViewSettings>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class TrafficMovementChangeIntervals {
  // @DataMember
  public greenBlink: number;

  // @DataMember
  public yellow: number;

  // @DataMember
  public red: number;

  // @DataMember
  public redYellow: number;

  public constructor(init?: Partial<TrafficMovementChangeIntervals>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class TrafficChangeIntervals {
  // @DataMember
  public move1: TrafficMovementChangeIntervals;

  // @DataMember
  public move2: TrafficMovementChangeIntervals;

  // @DataMember
  public move3: TrafficMovementChangeIntervals;

  // @DataMember
  public move4: TrafficMovementChangeIntervals;

  // @DataMember
  public move5: TrafficMovementChangeIntervals;

  // @DataMember
  public move6: TrafficMovementChangeIntervals;

  // @DataMember
  public move7: TrafficMovementChangeIntervals;

  // @DataMember
  public move8: TrafficMovementChangeIntervals;

  // @DataMember
  public move12: TrafficMovementChangeIntervals;

  // @DataMember
  public move14: TrafficMovementChangeIntervals;

  // @DataMember
  public move16: TrafficMovementChangeIntervals;

  // @DataMember
  public move18: TrafficMovementChangeIntervals;

  // @DataMember
  public moveP2: TrafficMovementChangeIntervals;

  // @DataMember
  public moveP4: TrafficMovementChangeIntervals;

  // @DataMember
  public moveP6: TrafficMovementChangeIntervals;

  // @DataMember
  public moveP8: TrafficMovementChangeIntervals;

  public constructor(init?: Partial<TrafficChangeIntervals>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export enum TrafficMovementType {
  Move1 = 'Move1',
  Move2 = 'Move2',
  Move3 = 'Move3',
  Move4 = 'Move4',
  Move5 = 'Move5',
  Move6 = 'Move6',
  Move7 = 'Move7',
  Move8 = 'Move8',
  Move12 = 'Move12',
  Move14 = 'Move14',
  Move16 = 'Move16',
  Move18 = 'Move18',
  MoveP2 = 'MoveP2',
  MoveP4 = 'MoveP4',
  MoveP6 = 'MoveP6',
  MoveP8 = 'MoveP8',
}

// @DataContract
export class TrafficDayTime {
  // @DataMember(Order=1)
  public planId: number;

  // @DataMember(Order=2)
  public time: string;

  public constructor(init?: Partial<TrafficDayTime>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class TrafficScheduleDay {
  // @DataMember(Order=1)
  public id: number;

  // @DataMember(Order=2)
  public times: TrafficDayTime[];

  public constructor(init?: Partial<TrafficScheduleDay>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class TrafficScheduleWeek {
  // @DataMember(Order=0)
  public id: number;

  // @DataMember(Order=1)
  public monday: number;

  // @DataMember(Order=2)
  public tuesday: number;

  // @DataMember(Order=3)
  public wednesday: number;

  // @DataMember(Order=4)
  public thursday: number;

  // @DataMember(Order=5)
  public friday: number;

  // @DataMember(Order=6)
  public saturday: number;

  // @DataMember(Order=7)
  public sunday: number;

  public constructor(init?: Partial<TrafficScheduleWeek>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class TrafficScheduleYear {
  // @DataMember(Order=0)
  public january: number[];

  // @DataMember(Order=1)
  public february: number[];

  // @DataMember(Order=2)
  public march: number[];

  // @DataMember(Order=3)
  public april: number[];

  // @DataMember(Order=4)
  public may: number[];

  // @DataMember(Order=5)
  public june: number[];

  // @DataMember(Order=6)
  public july: number[];

  // @DataMember(Order=7)
  public august: number[];

  // @DataMember(Order=8)
  public september: number[];

  // @DataMember(Order=9)
  public october: number[];

  // @DataMember(Order=10)
  public november: number[];

  // @DataMember(Order=11)
  public december: number[];

  public constructor(init?: Partial<TrafficScheduleYear>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export enum TrafficBarrierType {
  MajorStreet = 'MajorStreet',
  MinorStreet = 'MinorStreet',
  Static = 'Static',
}

// @DataContract
export class TrafficRingStraightMovement {
  // @DataMember(Order=0)
  public enabled: boolean;

  // @DataMember(Order=1)
  public length: number;

  // @DataMember(Order=2)
  public straightMovement: boolean;

  // @DataMember(Order=3)
  public pedestrianMovement: boolean;

  // @DataMember(Order=4)
  public deadEndPedestrianMovement: boolean;

  // @DataMember(Order=5)
  public pedestrianMovementLength: number;

  // @DataMember(Order=6)
  public firstMovement: boolean;

  // @DataMember(Order=7)
  public rightMovement: boolean;

  // @DataMember(Order=8)
  public rightMovementPedestrianSeparated: boolean;

  public constructor(init?: Partial<TrafficRingStraightMovement>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class TrafficRingLeftMovement {
  // @DataMember(Order=0)
  public enabled: boolean;

  // @DataMember(Order=1)
  public length: number;

  // @DataMember(Order=2)
  public overlapped: boolean;

  public constructor(init?: Partial<TrafficRingLeftMovement>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class TrafficBarrierRing {
  // @DataMember(Order=1)
  public straightMovement: TrafficRingStraightMovement;

  // @DataMember(Order=2)
  public leftMovement: TrafficRingLeftMovement;

  public constructor(init?: Partial<TrafficBarrierRing>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class TrafficPhaseBarrier {
  // @DataMember(Order=0)
  public type: TrafficBarrierType;

  // @DataMember(Order=2)
  public ring1: TrafficBarrierRing;

  // @DataMember(Order=3)
  public ring2: TrafficBarrierRing;

  // @DataMember(Order=4)
  public staticMovements: TrafficMovementType[];

  // @DataMember
  public length: number;

  // @DataMember
  public phase: number;

  public constructor(init?: Partial<TrafficPhaseBarrier>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class ControllerEvent {
  // @DataMember
  public trafficObjectId: string;

  // @DataMember
  public controllerId: string;

  // @DataMember
  public controllerNumber: string;

  // @DataMember
  public name: string;

  // @DataMember
  public start: string;

  // @DataMember
  public end: string;

  // @DataMember
  public duration: number;

  // @DataMember
  public type: ControllerEventType;

  // @DataMember
  public description: string;

  public constructor(init?: Partial<ControllerEvent>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export enum PotokTrafficMode {
  Off = 'Off',
  Coordination = 'Coordination',
  Adaptive = 'Adaptive',
  Remote = 'Remote',
}

// @DataContract
export enum PotokTrafficState {
  Off = 'Off',
  YellowBlink = 'YellowBlink',
  AllRed = 'AllRed',
  Phase = 'Phase',
}

// @DataContract
export enum PotokMovementLight {
  Off = 'Off',
  Red = 'Red',
  Yellow = 'Yellow',
  Green = 'Green',
  RedYellow = 'RedYellow',
  YellowBlink = 'YellowBlink',
  GreenBlink = 'GreenBlink',
}

// @DataContract
export class PotokSchemaLight {
  // @DataMember(Order=0)
  public move1: PotokMovementLight;

  // @DataMember(Order=1)
  public move2: PotokMovementLight;

  // @DataMember(Order=2)
  public move3: PotokMovementLight;

  // @DataMember(Order=3)
  public move4: PotokMovementLight;

  // @DataMember(Order=4)
  public move5: PotokMovementLight;

  // @DataMember(Order=5)
  public move6: PotokMovementLight;

  // @DataMember(Order=6)
  public move7: PotokMovementLight;

  // @DataMember(Order=7)
  public move8: PotokMovementLight;

  // @DataMember(Order=8)
  public move12: PotokMovementLight;

  // @DataMember(Order=9)
  public move14: PotokMovementLight;

  // @DataMember(Order=10)
  public move16: PotokMovementLight;

  // @DataMember(Order=11)
  public move18: PotokMovementLight;

  // @DataMember(Order=12)
  public moveP2: PotokMovementLight;

  // @DataMember(Order=13)
  public moveP4: PotokMovementLight;

  // @DataMember(Order=14)
  public moveP6: PotokMovementLight;

  // @DataMember(Order=15)
  public moveP8: PotokMovementLight;

  public constructor(init?: Partial<PotokSchemaLight>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class PotokSchemaPhaseStep {
  // @DataMember(Order=0)
  public startTime: string;

  // @DataMember(Order=1)
  public changeIntervalStart?: string;

  // @DataMember(Order=2)
  public endTime?: string;

  // @DataMember(Order=3)
  public lights: PotokSchemaLight;

  public constructor(init?: Partial<PotokSchemaPhaseStep>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export enum PotokPlanPhasesType {
  Transport = 'Transport',
  Pedestrian = 'Pedestrian',
}

// @DataContract
export enum PotokButtonsStatus {
  None = 'None',
  Offline = 'Offline',
  Wait = 'Wait',
  Pressed = 'Pressed',
}

// @DataContract
export class PotokTrafficStatus {
  // @DataMember(Order=0)
  public deviceTime: string;

  // @DataMember(Order=1)
  public serverTime: string;

  // @DataMember(Order=2)
  public trafficMode: PotokTrafficMode;

  // @DataMember(Order=3)
  public trafficState: PotokTrafficState;

  // @DataMember(Order=4)
  public coordinationPlan: number;

  // @DataMember(Order=5)
  public planLength: number;

  // @DataMember(Order=6)
  public planTime: number;

  // @DataMember(Order=7)
  public changeInterval: boolean;

  // @DataMember(Order=8)
  public currentLights: PotokSchemaLight;

  // @DataMember(Order=9)
  public previousPhase: PotokSchemaPhaseStep;

  // @DataMember(Order=10)
  public currentPhase: PotokSchemaPhaseStep;

  // @DataMember(Order=11)
  public nextPhase: PotokSchemaPhaseStep;

  // @DataMember(Order=12)
  public currentPhaseTimeLeft?: number;

  // @DataMember(Order=13)
  public changeState: boolean;

  // @DataMember(Order=14)
  public planPhasesType: PotokPlanPhasesType;

  // @DataMember(Order=15)
  public dayPlan: number;

  // @DataMember(Order=16)
  public weekPlan: number;

  // @DataMember(Order=17)
  public buttonsStatus: PotokButtonsStatus;

  public constructor(init?: Partial<PotokTrafficStatus>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export enum PotokControllerMode {
  Engineer = 'Engineer',
  Normal = 'Normal',
}

// @DataContract
export enum PotokControllerErrorType {
  PowerOff = 'PowerOff',
  AnalogInputsNotConfigured = 'AnalogInputsNotConfigured',
  SchemaNotConfigured = 'SchemaNotConfigured',
  ModbusNoConnection = 'ModbusNoConnection',
  MqttNoConnection = 'MqttNoConnection',
  LampControl = 'LampControl',
  ButtonOffline = 'ButtonOffline',
  InsufficientDiskSpace = 'InsufficientDiskSpace',
  ButtonSensorError = 'ButtonSensorError',
  DiagnosticMode = 'DiagnosticMode',
  ButtonSensorStuckError = 'ButtonSensorStuckError',
  HighCpuUsage = 'HighCpuUsage',
  Offline = 'Offline',
  BadTimeZone = 'BadTimeZone',
  ClockNotSync = 'ClockNotSync',
}

// @DataContract
export class PotokControllerError {
  // @DataMember(Order=0)
  public startTime: string;

  // @DataMember(Order=1)
  public type: PotokControllerErrorType;

  // @DataMember(Order=2)
  public data: string;

  // @DataMember(Order=3)
  public details: string;

  public constructor(init?: Partial<PotokControllerError>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class PotokControllerStatus {
  // @DataMember(Order=0)
  public mode: PotokControllerMode;

  // @DataMember(Order=1)
  public errors: PotokControllerError[];

  // @DataMember(Order=2)
  public lastAdaptivePlan: PotokPlan;

  // @DataMember(Order=3)
  public changeModeInterval: boolean;

  public constructor(init?: Partial<PotokControllerStatus>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class PotokStatus {
  // @DataMember(Order=0)
  public traffic: PotokTrafficStatus;

  // @DataMember(Order=1)
  public controller: PotokControllerStatus;

  public constructor(init?: Partial<PotokStatus>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class PotokPlans {
  // @DataMember(Order=1)
  public plan01: PotokPlan;

  // @DataMember(Order=2)
  public plan02: PotokPlan;

  // @DataMember(Order=3)
  public plan03: PotokPlan;

  // @DataMember(Order=4)
  public plan04: PotokPlan;

  // @DataMember(Order=5)
  public plan05: PotokPlan;

  // @DataMember(Order=6)
  public plan06: PotokPlan;

  // @DataMember(Order=7)
  public plan07: PotokPlan;

  // @DataMember(Order=8)
  public plan08: PotokPlan;

  // @DataMember(Order=9)
  public plan09: PotokPlan;

  // @DataMember(Order=10)
  public plan10: PotokPlan;

  // @DataMember(Order=11)
  public plan11: PotokPlan;

  // @DataMember(Order=12)
  public plan12: PotokPlan;

  // @DataMember(Order=13)
  public plan13: PotokPlan;

  // @DataMember(Order=14)
  public plan14: PotokPlan;

  // @DataMember(Order=15)
  public plan15: PotokPlan;

  // @DataMember(Order=16)
  public plan16: PotokPlan;

  // @DataMember(Order=17)
  public plan17: PotokPlan;

  // @DataMember(Order=18)
  public plan18: PotokPlan;

  // @DataMember(Order=19)
  public plan19: PotokPlan;

  // @DataMember(Order=20)
  public plan20: PotokPlan;

  // @DataMember(Order=21)
  public plan21: PotokPlan;

  // @DataMember(Order=22)
  public plan22: PotokPlan;

  // @DataMember(Order=23)
  public plan23: PotokPlan;

  // @DataMember(Order=24)
  public plan24: PotokPlan;

  public constructor(init?: Partial<PotokPlans>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class PotokDayTime {
  // @DataMember(Order=1)
  public planId: number;

  // @DataMember(Order=2)
  public time: string;

  public constructor(init?: Partial<PotokDayTime>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class PotokScheduleDay {
  // @DataMember(Order=1)
  public id: number;

  // @DataMember(Order=2)
  public times: PotokDayTime[];

  public constructor(init?: Partial<PotokScheduleDay>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class PotokScheduleWeek {
  // @DataMember(Order=0)
  public id: number;

  // @DataMember(Order=1)
  public monday: number;

  // @DataMember(Order=2)
  public tuesday: number;

  // @DataMember(Order=3)
  public wednesday: number;

  // @DataMember(Order=4)
  public thursday: number;

  // @DataMember(Order=5)
  public friday: number;

  // @DataMember(Order=6)
  public saturday: number;

  // @DataMember(Order=7)
  public sunday: number;

  public constructor(init?: Partial<PotokScheduleWeek>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class PotokScheduleYear {
  // @DataMember(Order=0)
  public january: number[];

  // @DataMember(Order=1)
  public february: number[];

  // @DataMember(Order=2)
  public march: number[];

  // @DataMember(Order=3)
  public april: number[];

  // @DataMember(Order=4)
  public may: number[];

  // @DataMember(Order=5)
  public june: number[];

  // @DataMember(Order=6)
  public july: number[];

  // @DataMember(Order=7)
  public august: number[];

  // @DataMember(Order=8)
  public september: number[];

  // @DataMember(Order=9)
  public october: number[];

  // @DataMember(Order=10)
  public november: number[];

  // @DataMember(Order=11)
  public december: number[];

  public constructor(init?: Partial<PotokScheduleYear>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class PotokSchedule {
  // @DataMember(Order=0)
  public days: PotokScheduleDay[];

  // @DataMember(Order=1)
  public weeks: PotokScheduleWeek[];

  // @DataMember(Order=2)
  public year: PotokScheduleYear;

  public constructor(init?: Partial<PotokSchedule>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class PotokRingStraightMovement {
  // @DataMember(Order=0)
  public enabled: boolean;

  // @DataMember(Order=1)
  public length: number;

  // @DataMember(Order=2)
  public straightMovement: boolean;

  // @DataMember(Order=3)
  public pedestrianMovement: boolean;

  // @DataMember(Order=4)
  public deadEndPedestrianMovement: boolean;

  // @DataMember(Order=5)
  public pedestrianMovementLength: number;

  // @DataMember(Order=6)
  public firstMovement: boolean;

  // @DataMember(Order=7)
  public rightMovement: boolean;

  // @DataMember(Order=8)
  public rightMovementPedestrianSeparated: boolean;

  public constructor(init?: Partial<PotokRingStraightMovement>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class PotokRingLeftMovement {
  // @DataMember(Order=0)
  public enabled: boolean;

  // @DataMember(Order=1)
  public length: number;

  // @DataMember(Order=2)
  public overlapped: boolean;

  public constructor(init?: Partial<PotokRingLeftMovement>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class PotokBarrierRing {
  // @DataMember(Order=1)
  public straightMovement: PotokRingStraightMovement;

  // @DataMember(Order=2)
  public leftMovement: PotokRingLeftMovement;

  public constructor(init?: Partial<PotokBarrierRing>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class BaseEntityViewModelBase implements IChangeKey, IDescription, IModified, IEntity {
  // @DataMember
  public description: string;

  // @DataMember
  public changeKey: string;

  // @DataMember
  public created: string;

  // @DataMember
  public createdBy: string;

  // @DataMember
  public modifiedBy: string;

  // @DataMember
  public modified: string;

  // @DataMember
  public id: string;

  // @DataMember
  public entityType: string;

  public constructor(init?: Partial<BaseEntityViewModelBase>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class ResponseError {
  // @DataMember(Order=1)
  public errorCode: string;

  // @DataMember(Order=2)
  public fieldName: string;

  // @DataMember(Order=3)
  public message: string;

  // @DataMember(Order=4)
  public meta: { [index: string]: string };

  public constructor(init?: Partial<ResponseError>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class ResponseStatus {
  // @DataMember(Order=1)
  public errorCode: string;

  // @DataMember(Order=2)
  public message: string;

  // @DataMember(Order=3)
  public stackTrace: string;

  // @DataMember(Order=4)
  public errors: ResponseError[];

  // @DataMember(Order=5)
  public meta: { [index: string]: string };

  public constructor(init?: Partial<ResponseStatus>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class DeleteItemsRequest implements IDescription {
  // @DataMember
  public description: string;

  // @DataMember(IsRequired=true)
  public ids: string[];

  public constructor(init?: Partial<DeleteItemsRequest>) {
    (Object as any).assign(this, init);
  }
}

export class CustomerDeviceInfoViewModelBase
  implements
    ICustomerEntity,
    ICustomerName,
    IDeviceItem,
    IOwnedDeviceItem,
    IChangeKey,
    IDescription,
    IModified,
    IEntity
{
  // @DataMember
  public customerId: string;

  // @DataMember
  public customerName: string;

  // @DataMember
  public ownerObjectName: string;

  // @DataMember
  public ownerObjectId: string;

  // @DataMember
  public prefix: string;

  // @DataMember
  public number: string;

  // @DataMember
  public phone1: string;

  // @DataMember
  public phone2: string;

  // @DataMember
  public ip1: string;

  // @DataMember
  public ip2: string;

  // @DataMember
  public imei: string;

  // @DataMember
  public lat: number;

  // @DataMember
  public lon: number;

  // @DataMember
  public direction: number;

  // @DataMember
  public fullNumber: string;

  // @DataMember
  public softwareVersion: string;

  // @DataMember
  public firmwareVersion: string;

  // @DataMember
  public hardwareVersion: string;

  // @DataMember
  public description: string;

  // @DataMember
  public changeKey: string;

  // @DataMember
  public created: string;

  // @DataMember
  public createdBy: string;

  // @DataMember
  public modifiedBy: string;

  // @DataMember
  public modified: string;

  // @DataMember
  public id: string;

  // @DataMember
  public entityType: string;

  public constructor(init?: Partial<CustomerDeviceInfoViewModelBase>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class EntityViewModelBase implements IEntity {
  // @DataMember
  public id: string;

  // @DataMember
  public entityType: string;

  public constructor(init?: Partial<EntityViewModelBase>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class PotokBarrier {
  // @DataMember(Order=0)
  public type: PotokBarrierType;

  // @DataMember(Order=1)
  public length: number;

  // @DataMember(Order=2)
  public ring1: PotokBarrierRing;

  // @DataMember(Order=3)
  public ring2: PotokBarrierRing;

  // @DataMember(Order=4)
  public staticMovements: PotokMovementType[];

  public constructor(init?: Partial<PotokBarrier>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class TrafficPhase {
  // @DataMember
  public movements: TrafficMovementType[];

  public constructor(init?: Partial<TrafficPhase>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class PlanBarrier {
  // @DataMember
  public length: number;

  // @DataMember
  public phase: number;

  public constructor(init?: Partial<PlanBarrier>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class NamedBaseEntityViewModelBase
  implements INamedEntity, IChangeKey, IDescription, IModified, IEntity
{
  // @DataMember
  public name: string;

  // @DataMember
  public description: string;

  // @DataMember
  public changeKey: string;

  // @DataMember
  public created: string;

  // @DataMember
  public createdBy: string;

  // @DataMember
  public modifiedBy: string;

  // @DataMember
  public modified: string;

  // @DataMember
  public id: string;

  // @DataMember
  public entityType: string;

  public constructor(init?: Partial<NamedBaseEntityViewModelBase>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class StepMovement {
  // @DataMember
  public movements: TrafficMovementType[];

  // @DataMember
  public phase: number;

  public constructor(init?: Partial<StepMovement>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class RecoveryMovement {
  // @DataMember
  public movements: TrafficMovementType[];

  // @DataMember
  public phase: number;

  public constructor(init?: Partial<RecoveryMovement>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class RouteObject {
  // @DataMember
  public trafficObjectId: string;

  // @DataMember
  public distance: number;

  // @DataMember
  public stepMovement: StepMovement;

  // @DataMember
  public recoveryMovement: RecoveryMovement;

  public constructor(init?: Partial<RouteObject>) {
    (Object as any).assign(this, init);
  }
}

export class DeviceViewModelBase
  implements IDeviceItem, IOwnedDeviceItem, IChangeKey, IDescription, IModified, IEntity
{
  // @DataMember
  public ownerObjectName: string;

  // @DataMember
  public ownerObjectId: string;

  // @DataMember
  public prefix: string;

  // @DataMember
  public number: string;

  // @DataMember
  public phone1: string;

  // @DataMember
  public phone2: string;

  // @DataMember
  public ip1: string;

  // @DataMember
  public ip2: string;

  // @DataMember
  public imei: string;

  // @DataMember
  public deviceSettings: string;

  // @DataMember
  public lat: number;

  // @DataMember
  public lon: number;

  // @DataMember
  public direction: number;

  // @DataMember
  public fullNumber: string;

  // @DataMember
  public softwareVersion: string;

  // @DataMember
  public firmwareVersion: string;

  // @DataMember
  public hardwareVersion: string;

  // @DataMember
  public description: string;

  // @DataMember
  public changeKey: string;

  // @DataMember
  public created: string;

  // @DataMember
  public createdBy: string;

  // @DataMember
  public modifiedBy: string;

  // @DataMember
  public modified: string;

  // @DataMember
  public id: string;

  // @DataMember
  public entityType: string;

  public constructor(init?: Partial<DeviceViewModelBase>) {
    (Object as any).assign(this, init);
  }
}

export class DeviceInfoViewModelBase
  implements IDeviceItem, IOwnedDeviceItem, IChangeKey, IDescription, IModified, IEntity
{
  // @DataMember
  public ownerObjectName: string;

  // @DataMember
  public ownerObjectId: string;

  // @DataMember
  public prefix: string;

  // @DataMember
  public number: string;

  // @DataMember
  public phone1: string;

  // @DataMember
  public phone2: string;

  // @DataMember
  public ip1: string;

  // @DataMember
  public ip2: string;

  // @DataMember
  public imei: string;

  // @DataMember
  public lat: number;

  // @DataMember
  public lon: number;

  // @DataMember
  public direction: number;

  // @DataMember
  public fullNumber: string;

  // @DataMember
  public softwareVersion: string;

  // @DataMember
  public firmwareVersion: string;

  // @DataMember
  public hardwareVersion: string;

  // @DataMember
  public description: string;

  // @DataMember
  public changeKey: string;

  // @DataMember
  public created: string;

  // @DataMember
  public createdBy: string;

  // @DataMember
  public modifiedBy: string;

  // @DataMember
  public modified: string;

  // @DataMember
  public id: string;

  // @DataMember
  public entityType: string;

  public constructor(init?: Partial<DeviceInfoViewModelBase>) {
    (Object as any).assign(this, init);
  }
}

export class AdaptiveStatusBase implements IEnabled {
  // @DataMember
  public enabled: boolean;

  // @DataMember
  public active: boolean;

  public constructor(init?: Partial<AdaptiveStatusBase>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class AdaptiveOptions implements IEnabled {
  // @DataMember
  public enabled: boolean;

  // @DataMember
  public adaptiveType: AdaptiveType;

  public constructor(init?: Partial<AdaptiveOptions>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class PageRequestBase {
  // @DataMember
  public page: number;

  // @DataMember
  public items: number;

  // @DataMember
  public sort: string;

  // @DataMember
  public filter: string;

  // @DataMember
  public propertiesTest: { [index: string]: number };

  public constructor(init?: Partial<PageRequestBase>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class TrafficMovement implements IEnabled {
  // @DataMember(Order=0)
  public enabled: boolean;

  // @DataMember(Order=1)
  public tMin: number;

  public constructor(init?: Partial<TrafficMovement>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class TlcModuleSettings {
  public constructor(init?: Partial<TlcModuleSettings>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class ModuleSettingsBase {
  public constructor(init?: Partial<ModuleSettingsBase>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class TlcModuleTableViewSettings {
  // @DataMember
  public showDeviceVersion: boolean;

  public constructor(init?: Partial<TlcModuleTableViewSettings>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class TlcModuleCustomerSettings {
  // @DataMember
  public tableView: TlcModuleTableViewSettings;

  public constructor(init?: Partial<TlcModuleCustomerSettings>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class ModuleCustomerSettingsBase {
  public constructor(init?: Partial<ModuleCustomerSettingsBase>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class UsdkTrafficObjectStatus implements IEntity {
  // @DataMember
  public controllerStatus: UsdkStatus;

  // @DataMember
  public serverTime: string;

  // @DataMember
  public stateTime: string;

  // @DataMember
  public mode: TrafficObjectMode;

  // @DataMember
  public state: TrafficObjectState;

  // @DataMember
  public iconStatus: TrafficObjectIconStatus;

  // @DataMember
  public online: boolean;

  // @DataMember
  public controllerLastConnectionTime: string;

  // @DataMember
  public controllerLastPacketTime: string;

  // @DataMember
  public controllerTime: string;

  // @DataMember
  public controllerStatusDescription: string;

  // @DataMember
  public controllerErrorDescription: string;

  // @DataMember
  public schemaLights: TrafficSchemaLight;

  // @DataMember
  public commandProcessing: boolean;

  // @DataMember
  public coordinationPlan: number;

  // @DataMember
  public planTime: number;

  // @DataMember
  public adaptiveStatus: TrafficObjectAdaptiveStatus;

  // @DataMember
  public id: string;

  // @DataMember
  public entityType: string;

  public constructor(init?: Partial<UsdkTrafficObjectStatus>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class GetUsdkTrafficObjectsStatusesResponse implements IChangeKey {
  // @DataMember
  public items: UsdkTrafficObjectStatus[];

  // @DataMember
  public total: number;

  // @DataMember
  public page: number;

  // @DataMember
  public itemsPerPage: number;

  // @DataMember
  public changeKey: string;

  public constructor(init?: Partial<GetUsdkTrafficObjectsStatusesResponse>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class UsdkSettings {
  // @DataMember
  public phases: UsdkPhase[];

  // @DataMember
  public plans: UsdkPlans;

  // @DataMember
  public schedule: UsdkSchedule;

  public constructor(init?: Partial<UsdkSettings>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class GetEntityGroupsResponse implements IChangeKey {
  // @DataMember
  public items: EntityGroupInfo[];

  // @DataMember
  public total: number;

  // @DataMember
  public page: number;

  // @DataMember
  public itemsPerPage: number;

  // @DataMember
  public changeKey: string;

  public constructor(init?: Partial<GetEntityGroupsResponse>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class GetRoutesResponse implements IChangeKey {
  // @DataMember
  public items: RouteInfo[];

  // @DataMember
  public total: number;

  // @DataMember
  public page: number;

  // @DataMember
  public itemsPerPage: number;

  // @DataMember
  public changeKey: string;

  public constructor(init?: Partial<GetRoutesResponse>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class Route
  implements ICustomerEntityViewModel, INamedEntity, IChangeKey, IDescription, IModified, IEntity
{
  // @DataMember
  public settings: RouteSettings;

  // @DataMember
  public routeGeoJson: string;

  // @DataMember
  public customerId: string;

  // @DataMember
  public customerName: string;

  // @DataMember
  public name: string;

  // @DataMember
  public description: string;

  // @DataMember
  public changeKey: string;

  // @DataMember
  public created: string;

  // @DataMember
  public createdBy: string;

  // @DataMember
  public modifiedBy: string;

  // @DataMember
  public modified: string;

  // @DataMember
  public id: string;

  // @DataMember
  public entityType: string;

  public constructor(init?: Partial<Route>) {
    (Object as any).assign(this, init);
  }
}

export class GetDeviceEventLogResponse implements IChangeKey {
  // @DataMember
  public items: EntityEventLogDetails[];

  // @DataMember
  public total: number;

  // @DataMember
  public page: number;

  // @DataMember
  public itemsPerPage: number;

  // @DataMember
  public changeKey: string;

  public constructor(init?: Partial<GetDeviceEventLogResponse>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class GetTrafficObjectControllersResponse implements IChangeKey {
  // @DataMember
  public items: TrafficObjectControllerInfo[];

  // @DataMember
  public total: number;

  // @DataMember
  public page: number;

  // @DataMember
  public itemsPerPage: number;

  // @DataMember
  public changeKey: string;

  public constructor(init?: Partial<GetTrafficObjectControllersResponse>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class TrafficObjectController
  implements
    ICustomerEntity,
    ICustomerName,
    IDeviceItem,
    IOwnedDeviceItem,
    IChangeKey,
    IDescription,
    IModified,
    IEntity
{
  // @DataMember
  public controllerType: TrafficControllerType;

  // @DataMember
  public customerId: string;

  // @DataMember
  public customerName: string;

  // @DataMember
  public ownerObjectName: string;

  // @DataMember
  public ownerObjectId: string;

  // @DataMember
  public prefix: string;

  // @DataMember
  public number: string;

  // @DataMember
  public phone1: string;

  // @DataMember
  public phone2: string;

  // @DataMember
  public ip1: string;

  // @DataMember
  public ip2: string;

  // @DataMember
  public imei: string;

  // @DataMember
  public deviceSettings: string;

  // @DataMember
  public lat: number;

  // @DataMember
  public lon: number;

  // @DataMember
  public direction: number;

  // @DataMember
  public fullNumber: string;

  // @DataMember
  public softwareVersion: string;

  // @DataMember
  public firmwareVersion: string;

  // @DataMember
  public hardwareVersion: string;

  // @DataMember
  public description: string;

  // @DataMember
  public changeKey: string;

  // @DataMember
  public created: string;

  // @DataMember
  public createdBy: string;

  // @DataMember
  public modifiedBy: string;

  // @DataMember
  public modified: string;

  // @DataMember
  public id: string;

  // @DataMember
  public entityType: string;

  public constructor(init?: Partial<TrafficObjectController>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class SessionInfo {
  // @DataMember
  public id: string;

  // @DataMember
  public lastReceived: string;

  // @DataMember
  public lastSent: string;

  // @DataMember
  public deviceNumber: string;

  // @DataMember
  public sessionId: string;

  // @DataMember
  public connectionTime: string;

  // @DataMember
  public receiverHost: string;

  // @DataMember
  public remoteAddress: string;

  // @DataMember
  public connected: boolean;

  // @DataMember
  public lastUpdated: string;

  // @DataMember
  public entityType: string;

  // @DataMember
  public nextCheckSession: string;

  public constructor(init?: Partial<SessionInfo>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class GetEntityGroupSharesResponse implements IChangeKey {
  // @DataMember
  public items: EntityGroupPermissions[];

  // @DataMember
  public total: number;

  // @DataMember
  public page: number;

  // @DataMember
  public itemsPerPage: number;

  // @DataMember
  public changeKey: string;

  public constructor(init?: Partial<GetEntityGroupSharesResponse>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class GetTrafficObjectsResponse implements IChangeKey {
  // @DataMember
  public items: TrafficObjectInfo[];

  // @DataMember
  public total: number;

  // @DataMember
  public page: number;

  // @DataMember
  public itemsPerPage: number;

  // @DataMember
  public changeKey: string;

  public constructor(init?: Partial<GetTrafficObjectsResponse>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class TrafficObject
  implements ICustomerEntityViewModel, INamedEntity, IChangeKey, IDescription, IModified, IEntity
{
  // @DataMember
  public lat: number;

  // @DataMember
  public lon: number;

  // @DataMember
  public controllerType: TrafficControllerType;

  // @DataMember
  public controllerId: string;

  // @DataMember
  public controller: TrafficObjectController;

  // @DataMember
  public adaptiveTypes: AdaptiveType[];

  // @DataMember
  public customerId: string;

  // @DataMember
  public customerName: string;

  // @DataMember
  public name: string;

  // @DataMember
  public description: string;

  // @DataMember
  public changeKey: string;

  // @DataMember
  public created: string;

  // @DataMember
  public createdBy: string;

  // @DataMember
  public modifiedBy: string;

  // @DataMember
  public modified: string;

  // @DataMember
  public id: string;

  // @DataMember
  public entityType: string;

  public constructor(init?: Partial<TrafficObject>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class TrafficObjectSchemaSet implements IChangeKey {
  // @DataMember
  public changeKey: string;

  // @DataMember
  public schema: TrafficSchema;

  // @DataMember
  public schemaView: TrafficSchemaViewSettings;

  // @DataMember
  public id: string;

  // @DataMember
  public entityType: string;

  public constructor(init?: Partial<TrafficObjectSchemaSet>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class TrafficObjectAdaptiveOptions implements IChangeKey {
  // @DataMember
  public changeKey: string;

  // @DataMember
  public adaptiveSettings: TrafficObjectAdaptiveSettings;

  // @DataMember
  public id: string;

  // @DataMember
  public entityType: string;

  public constructor(init?: Partial<TrafficObjectAdaptiveOptions>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class TrafficObjectChangeIntervals implements IChangeKey {
  // @DataMember
  public changeKey: string;

  // @DataMember
  public changeIntervals: TrafficChangeIntervals;

  // @DataMember
  public id: string;

  // @DataMember
  public entityType: string;

  public constructor(init?: Partial<TrafficObjectChangeIntervals>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class TrafficSchedule {
  // @DataMember(Order=0)
  public days: TrafficScheduleDay[];

  // @DataMember(Order=1)
  public weeks: TrafficScheduleWeek[];

  // @DataMember(Order=2)
  public year: TrafficScheduleYear;

  public constructor(init?: Partial<TrafficSchedule>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class TrafficPlan implements IEnabled {
  // @DataMember
  public barriers: TrafficPhaseBarrier[];

  // @DataMember
  public id: number;

  // @DataMember
  public name: string;

  // @DataMember
  public type: TrafficPlanType;

  // @DataMember
  public offset: number;

  // @DataMember
  public length: number;

  // @DataMember
  public enabled: boolean;

  public constructor(init?: Partial<TrafficPlan>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class ControllerEventReport {
  // @DataMember
  public start: string;

  // @DataMember
  public end: string;

  // @DataMember
  public items: ControllerEvent[];

  public constructor(init?: Partial<ControllerEventReport>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class TrafficObjectsStatistic {
  // @DataMember
  public total: number;

  // @DataMember
  public online: number;

  public constructor(init?: Partial<TrafficObjectsStatistic>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class GetTrafficObjectsStatusesResponse implements IChangeKey {
  // @DataMember
  public items: TrafficObjectStatusInfo[];

  // @DataMember
  public total: number;

  // @DataMember
  public page: number;

  // @DataMember
  public itemsPerPage: number;

  // @DataMember
  public changeKey: string;

  public constructor(init?: Partial<GetTrafficObjectsStatusesResponse>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class GetTrafficObjectsFullStatusesResponse implements IChangeKey {
  // @DataMember
  public items: TrafficObjectStatus[];

  // @DataMember
  public total: number;

  // @DataMember
  public page: number;

  // @DataMember
  public itemsPerPage: number;

  // @DataMember
  public changeKey: string;

  public constructor(init?: Partial<GetTrafficObjectsFullStatusesResponse>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class TrafficObjectCommandStatus {
  // @DataMember
  public id: string;

  // @DataMember
  public ticketId: string;

  // @DataMember
  public startTime: string;

  // @DataMember
  public endTime?: string;

  // @DataMember
  public description: string;

  // @DataMember
  public error: string;

  // @DataMember
  public status: TrafficObjectStatus;

  public constructor(init?: Partial<TrafficObjectCommandStatus>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class GetAdaptiveTrafficObjectsResponse implements IChangeKey {
  // @DataMember
  public items: AdaptiveTrafficObjectInfo[];

  // @DataMember
  public total: number;

  // @DataMember
  public page: number;

  // @DataMember
  public itemsPerPage: number;

  // @DataMember
  public changeKey: string;

  public constructor(init?: Partial<GetAdaptiveTrafficObjectsResponse>) {
    (Object as any).assign(this, init);
  }
}

export class PotokTrafficObjectStatus implements IEntity {
  // @DataMember
  public controllerStatus: PotokStatus;

  // @DataMember
  public serverTime: string;

  // @DataMember
  public stateTime: string;

  // @DataMember
  public mode: TrafficObjectMode;

  // @DataMember
  public state: TrafficObjectState;

  // @DataMember
  public iconStatus: TrafficObjectIconStatus;

  // @DataMember
  public online: boolean;

  // @DataMember
  public controllerLastConnectionTime: string;

  // @DataMember
  public controllerLastPacketTime: string;

  // @DataMember
  public controllerTime: string;

  // @DataMember
  public controllerStatusDescription: string;

  // @DataMember
  public controllerErrorDescription: string;

  // @DataMember
  public schemaLights: TrafficSchemaLight;

  // @DataMember
  public commandProcessing: boolean;

  // @DataMember
  public coordinationPlan: number;

  // @DataMember
  public planTime: number;

  // @DataMember
  public adaptiveStatus: TrafficObjectAdaptiveStatus;

  // @DataMember
  public id: string;

  // @DataMember
  public entityType: string;

  public constructor(init?: Partial<PotokTrafficObjectStatus>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class PotokSettings {
  // @DataMember
  public plans: PotokPlans;

  // @DataMember
  public schedule: PotokSchedule;

  public constructor(init?: Partial<PotokSettings>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class GetPotokTrafficObjectsStatusesResponse implements IChangeKey {
  // @DataMember
  public items: PotokTrafficObjectStatus[];

  // @DataMember
  public total: number;

  // @DataMember
  public page: number;

  // @DataMember
  public itemsPerPage: number;

  // @DataMember
  public changeKey: string;

  public constructor(init?: Partial<GetPotokTrafficObjectsStatusesResponse>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class GetPotokTrafficObjectCurrentPlanResponse {
  // @DataMember
  public potokPlan: PotokPlan;

  // @DataMember
  public planStartTime: string;

  // @DataMember
  public plan: number;

  // @DataMember
  public dayPlan: number;

  // @DataMember
  public weekPlan: number;

  public constructor(init?: Partial<GetPotokTrafficObjectCurrentPlanResponse>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class EntityIntegration implements IChangeKey, IDescription, IModified, IEntity {
  // @DataMember
  public entityId: string;

  // @DataMember
  public customerIntegrationId: string;

  // @DataMember
  public externalId: string;

  // @DataMember
  public settings: string;

  // @DataMember
  public integrationId: string;

  // @DataMember
  public integrationName: string;

  // @DataMember
  public integrationType: string;

  // @DataMember
  public description: string;

  // @DataMember
  public changeKey: string;

  // @DataMember
  public created: string;

  // @DataMember
  public createdBy: string;

  // @DataMember
  public modifiedBy: string;

  // @DataMember
  public modified: string;

  // @DataMember
  public id: string;

  // @DataMember
  public entityType: string;

  public constructor(init?: Partial<EntityIntegration>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class GetEntityIntegrationResponse implements IChangeKey {
  // @DataMember
  public items: EntityIntegration[];

  // @DataMember
  public total: number;

  // @DataMember
  public page: number;

  // @DataMember
  public itemsPerPage: number;

  // @DataMember
  public changeKey: string;

  public constructor(init?: Partial<GetEntityIntegrationResponse>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class GetEntitiesResponse implements IChangeKey {
  // @DataMember
  public items: Object[];

  // @DataMember
  public total: number;

  // @DataMember
  public page: number;

  // @DataMember
  public itemsPerPage: number;

  // @DataMember
  public changeKey: string;

  public constructor(init?: Partial<GetEntitiesResponse>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class AuthenticateResponse implements IHasSessionId, IHasBearerToken {
  // @DataMember(Order=1)
  public userId: string;

  // @DataMember(Order=2)
  public sessionId: string;

  // @DataMember(Order=3)
  public userName: string;

  // @DataMember(Order=4)
  public displayName: string;

  // @DataMember(Order=5)
  public referrerUrl: string;

  // @DataMember(Order=6)
  public bearerToken: string;

  // @DataMember(Order=7)
  public refreshToken: string;

  // @DataMember(Order=8)
  public profileUrl: string;

  // @DataMember(Order=9)
  public roles: string[];

  // @DataMember(Order=10)
  public permissions: string[];

  // @DataMember(Order=11)
  public responseStatus: ResponseStatus;

  // @DataMember(Order=12)
  public meta: { [index: string]: string };

  public constructor(init?: Partial<AuthenticateResponse>) {
    (Object as any).assign(this, init);
  }
}

// @Route("/customer/{customerId}/trafficObject/{id}/controller/usdk/setController", "PUT")
// @DataContract
export class SetUsdkControllerRequest
  implements
    IReturn<UsdkTrafficObjectStatus>,
    ICustomerItemRequest,
    IBaseEntityItemRequest,
    IDescription
{
  // @DataMember
  public id: string;

  // @DataMember
  public customerId: string;

  // @DataMember
  public newControllerId: string;

  // @DataMember
  public uploadSettingsToController: boolean;

  // @DataMember
  public description: string;

  public constructor(init?: Partial<SetUsdkControllerRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new UsdkTrafficObjectStatus();
  }
  public getTypeName() {
    return 'SetUsdkControllerRequest';
  }
}

// @Route("/customer/{customerId}/trafficObject/{id}/controller/usdk/setLocalMode", "POST")
// @DataContract
export class SetUsdkControllerLocalModeRequest
  implements
    IReturn<UsdkTrafficObjectStatus>,
    ICustomerItemRequest,
    IBaseEntityItemRequest,
    IDescription
{
  // @DataMember
  public id: string;

  // @DataMember
  public customerId: string;

  // @DataMember
  public async: boolean;

  // @DataMember
  public description: string;

  public constructor(init?: Partial<SetUsdkControllerLocalModeRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new UsdkTrafficObjectStatus();
  }
  public getTypeName() {
    return 'SetUsdkControllerLocalModeRequest';
  }
}

// @Route("/customer/{customerId}/trafficObject/{id}/controller/usdk/setRemoteMode", "POST")
// @DataContract
export class SetUsdkControllerRemoteModeRequest
  implements
    IReturn<UsdkTrafficObjectStatus>,
    ICustomerItemRequest,
    IBaseEntityItemRequest,
    IDescription
{
  // @DataMember
  public usdkPhase: number;

  // @DataMember
  public modeTime: number;

  // @DataMember
  public id: string;

  // @DataMember
  public customerId: string;

  // @DataMember
  public async: boolean;

  // @DataMember
  public description: string;

  public constructor(init?: Partial<SetUsdkControllerRemoteModeRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new UsdkTrafficObjectStatus();
  }
  public getTypeName() {
    return 'SetUsdkControllerRemoteModeRequest';
  }
}

// @Route("/customer/{customerId}/trafficObject/{id}/controller/usdk/setCoordinationMode", "POST")
// @DataContract
export class SetUsdkControllerCoordinationModeRequest
  implements
    IReturn<UsdkTrafficObjectStatus>,
    ICustomerItemRequest,
    IBaseEntityItemRequest,
    IDescription
{
  // @DataMember
  public id: string;

  // @DataMember
  public customerId: string;

  // @DataMember
  public async: boolean;

  // @DataMember
  public description: string;

  public constructor(init?: Partial<SetUsdkControllerCoordinationModeRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new UsdkTrafficObjectStatus();
  }
  public getTypeName() {
    return 'SetUsdkControllerCoordinationModeRequest';
  }
}

// @Route("/customer/{customerId}/trafficObject/{id}/controller/usdk/setOfflineMode", "POST")
// @DataContract
export class SetUsdkControllerOfflineModeRequest
  implements
    IReturn<UsdkTrafficObjectStatus>,
    ICustomerItemRequest,
    IBaseEntityItemRequest,
    IDescription
{
  // @DataMember
  public id: string;

  // @DataMember
  public customerId: string;

  // @DataMember
  public async: boolean;

  // @DataMember
  public description: string;

  public constructor(init?: Partial<SetUsdkControllerOfflineModeRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new UsdkTrafficObjectStatus();
  }
  public getTypeName() {
    return 'SetUsdkControllerOfflineModeRequest';
  }
}

// @Route("/customer/{customerId}/trafficObject/{id}/controller/usdk/setYellowBlinkMode", "POST")
// @DataContract
export class SetUsdkControllerYellowBlinkModeRequest
  implements
    IReturn<UsdkTrafficObjectStatus>,
    ICustomerItemRequest,
    IBaseEntityItemRequest,
    IDescription
{
  // @DataMember
  public id: string;

  // @DataMember
  public customerId: string;

  // @DataMember
  public async: boolean;

  // @DataMember
  public description: string;

  public constructor(init?: Partial<SetUsdkControllerYellowBlinkModeRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new UsdkTrafficObjectStatus();
  }
  public getTypeName() {
    return 'SetUsdkControllerYellowBlinkModeRequest';
  }
}

// @Route("/customer/{customerId}/trafficObject/{id}/controller/usdk/setAdaptiveMode", "POST")
// @DataContract
export class SetUsdkControllerAdaptiveModeRequest
  implements
    IReturn<UsdkTrafficObjectStatus>,
    ICustomerItemRequest,
    IBaseEntityItemRequest,
    IDescription
{
  // @DataMember
  public id: string;

  // @DataMember
  public customerId: string;

  // @DataMember
  public async: boolean;

  // @DataMember
  public description: string;

  public constructor(init?: Partial<SetUsdkControllerAdaptiveModeRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new UsdkTrafficObjectStatus();
  }
  public getTypeName() {
    return 'SetUsdkControllerAdaptiveModeRequest';
  }
}

// @Route("/customer/{customerId}/trafficObjects/usdkStatuses", "GET")
// @DataContract
export class GetUsdkTrafficObjectsStatusesRequest
  implements IReturn<GetUsdkTrafficObjectsStatusesResponse>, ICustomerItemRequest
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public entityGroups: string[];

  // @DataMember
  public itemsIds: string[];

  // @DataMember
  public onlyOwnedItems: boolean;

  // @DataMember
  public page: number;

  // @DataMember
  public items: number;

  // @DataMember
  public sort: string;

  // @DataMember
  public filter: string;

  // @DataMember
  public propertiesTest: { [index: string]: number };

  public constructor(init?: Partial<GetUsdkTrafficObjectsStatusesRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new GetUsdkTrafficObjectsStatusesResponse();
  }
  public getTypeName() {
    return 'GetUsdkTrafficObjectsStatusesRequest';
  }
}

// @Route("/customer/{customerId}/trafficObject/{id}/controller/usdk/settings", "GET")
// @DataContract
export class GetUsdkControllerSettingsRequest
  implements IReturn<UsdkSettings>, ICustomerItemRequest, IBaseEntityItemRequest
{
  // @DataMember
  public customerId: string;

  // @DataMember(IsRequired=true)
  public id: string;

  public constructor(init?: Partial<GetUsdkControllerSettingsRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new UsdkSettings();
  }
  public getTypeName() {
    return 'GetUsdkControllerSettingsRequest';
  }
}

// @Route("/customer/{customerId}/trafficObject/{id}/controller/usdk/status", "GET")
// @DataContract
export class GetUsdkTrafficObjectStatusRequest
  implements IReturn<UsdkTrafficObjectStatus>, ICustomerItemRequest, IBaseEntityItemRequest
{
  // @DataMember
  public queryState: boolean;

  // @DataMember
  public id: string;

  // @DataMember
  public customerId: string;

  public constructor(init?: Partial<GetUsdkTrafficObjectStatusRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new UsdkTrafficObjectStatus();
  }
  public getTypeName() {
    return 'GetUsdkTrafficObjectStatusRequest';
  }
}

// @Route("/customer/{customerId}/trafficObject/{id}/controller/usdk/settings", "PUT")
// @DataContract
export class UpdateUsdkControllerSettingsRequest
  implements IReturn<UsdkSettings>, ICustomerItemRequest, IBaseEntityItemRequest, IDescription
{
  // @DataMember
  public customerId: string;

  // @DataMember
  public settings: UsdkSettings;

  // @DataMember
  public forceUpdate: boolean;

  // @DataMember
  public uploadSettingsToController: boolean;

  // @DataMember
  public description: string;

  // @DataMember(IsRequired=true)
  public id: string;

  public constructor(init?: Partial<UpdateUsdkControllerSettingsRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new UsdkSettings();
  }
  public getTypeName() {
    return 'UpdateUsdkControllerSettingsRequest';
  }
}

// @Route("/customer/{customerId}/trafficObject/{id}/controller/usdk/currentPlan", "GET")
// @DataContract
export class GetUsdkTrafficObjectCurrentPlanRequest
  implements IReturn<UsdkPlan>, ICustomerItemRequest, IBaseEntityItemRequest
{
  // @DataMember
  public id: string;

  // @DataMember
  public customerId: string;

  public constructor(init?: Partial<GetUsdkTrafficObjectCurrentPlanRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new UsdkPlan();
  }
  public getTypeName() {
    return 'GetUsdkTrafficObjectCurrentPlanRequest';
  }
}

// @Route("/customer/{customerId}/routes/groups", "GET")
// @DataContract
export class GetRouteGroupsRequest
  implements IReturn<GetEntityGroupsResponse>, ICustomerItemRequest
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public entityGroups: string[];

  // @DataMember
  public itemsIds: string[];

  // @DataMember
  public onlyOwnedItems: boolean;

  // @DataMember
  public page: number;

  // @DataMember
  public items: number;

  // @DataMember
  public sort: string;

  // @DataMember
  public filter: string;

  // @DataMember
  public propertiesTest: { [index: string]: number };

  public constructor(init?: Partial<GetRouteGroupsRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new GetEntityGroupsResponse();
  }
  public getTypeName() {
    return 'GetRouteGroupsRequest';
  }
}

// @Route("/customer/{customerId}/routes/groups/{id}", "GET")
// @DataContract
export class GetRouteGroupRequest
  implements IReturn<EntityGroup>, ICustomerItemRequest, IBaseEntityItemRequest
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember(IsRequired=true)
  public id: string;

  public constructor(init?: Partial<GetRouteGroupRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new EntityGroup();
  }
  public getTypeName() {
    return 'GetRouteGroupRequest';
  }
}

// @Route("/customer/{customerId}/routes/groups", "POST")
// @DataContract
export class CreateRouteGroupRequest
  implements IReturn<EntityGroup>, ICustomerItemRequest, IDescription
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public updateEntityGroups: boolean;

  // @DataMember
  public entityGroups: string[];

  // @DataMember(IsRequired=true)
  public entity: EntityGroup;

  // @DataMember
  public description: string;

  public constructor(init?: Partial<CreateRouteGroupRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new EntityGroup();
  }
  public getTypeName() {
    return 'CreateRouteGroupRequest';
  }
}

// @Route("/customer/{customerId}/routes/groups/{id}", "PUT,POST")
// @DataContract
export class UpdateRouteGroupRequest
  implements IReturn<EntityGroup>, ICustomerItemRequest, IBaseEntityItemRequest, IDescription
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public updateEntityGroups: boolean;

  // @DataMember
  public entityGroups: string[];

  // @DataMember(IsRequired=true)
  public id: string;

  // @DataMember(IsRequired=true)
  public entity: EntityGroup;

  // @DataMember
  public description: string;

  public constructor(init?: Partial<UpdateRouteGroupRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new EntityGroup();
  }
  public getTypeName() {
    return 'UpdateRouteGroupRequest';
  }
}

// @Route("/customer/{customerId}/routes/groups", "DELETE")
// @DataContract
export class DeleteRouteGroupsRequest
  implements IReturn<number>, ICustomerItemRequest, IDescription
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public description: string;

  // @DataMember(IsRequired=true)
  public ids: string[];

  public constructor(init?: Partial<DeleteRouteGroupsRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return 0;
  }
  public getTypeName() {
    return 'DeleteRouteGroupsRequest';
  }
}

// @Route("/customer/{customerId}/routes", "GET")
// @DataContract
export class GetRoutesRequest implements IReturn<GetRoutesResponse>, ICustomerItemRequest {
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public entityGroups: string[];

  // @DataMember
  public itemsIds: string[];

  // @DataMember
  public onlyOwnedItems: boolean;

  // @DataMember
  public page: number;

  // @DataMember
  public items: number;

  // @DataMember
  public sort: string;

  // @DataMember
  public filter: string;

  // @DataMember
  public propertiesTest: { [index: string]: number };

  public constructor(init?: Partial<GetRoutesRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new GetRoutesResponse();
  }
  public getTypeName() {
    return 'GetRoutesRequest';
  }
}

// @Route("/customer/{customerId}/routes/route/{id}", "GET")
// @DataContract
export class GetRouteRequest
  implements IReturn<Route>, ICustomerItemRequest, IBaseEntityItemRequest
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember(IsRequired=true)
  public id: string;

  public constructor(init?: Partial<GetRouteRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new Route();
  }
  public getTypeName() {
    return 'GetRouteRequest';
  }
}

// @Route("/customer/{customerId}/routes", "POST")
// @DataContract
export class CreateRouteRequest implements IReturn<Route>, ICustomerItemRequest, IDescription {
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public updateEntityGroups: boolean;

  // @DataMember
  public entityGroups: string[];

  // @DataMember(IsRequired=true)
  public entity: Route;

  // @DataMember
  public description: string;

  public constructor(init?: Partial<CreateRouteRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new Route();
  }
  public getTypeName() {
    return 'CreateRouteRequest';
  }
}

// @Route("/customer/{customerId}/route/{id}", "PUT")
// @DataContract
export class UpdateRouteRequest
  implements IReturn<Route>, ICustomerItemRequest, IBaseEntityItemRequest, IDescription
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public updateEntityGroups: boolean;

  // @DataMember
  public entityGroups: string[];

  // @DataMember(IsRequired=true)
  public id: string;

  // @DataMember(IsRequired=true)
  public entity: Route;

  // @DataMember
  public description: string;

  public constructor(init?: Partial<UpdateRouteRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new Route();
  }
  public getTypeName() {
    return 'UpdateRouteRequest';
  }
}

// @Route("/customer/{customerId}/routes", "DELETE")
// @DataContract
export class DeleteRouteRequest implements IReturn<number>, ICustomerItemRequest, IDescription {
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public description: string;

  // @DataMember(IsRequired=true)
  public ids: string[];

  public constructor(init?: Partial<DeleteRouteRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return 0;
  }
  public getTypeName() {
    return 'DeleteRouteRequest';
  }
}

// @Route("/customer/{customerId}/trafficObjectController/{id}/eventTypes", "GET")
// @DataContract
export class GetTrafficObjectControllerEventTypesRequest
  implements IReturn<EntityEventLogType[]>, IBaseEntityItemRequest
{
  // @DataMember
  public id: string;

  public constructor(init?: Partial<GetTrafficObjectControllerEventTypesRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new Array<EntityEventLogType>();
  }
  public getTypeName() {
    return 'GetTrafficObjectControllerEventTypesRequest';
  }
}

// @Route("/customer/{customerId}/trafficObjectController/{id}/eventLog", "GET")
// @DataContract
export class GetTrafficObjectControllerEventLogRequest
  implements IReturn<GetDeviceEventLogResponse>, IBaseEntityItemRequest, ICustomerItemRequest
{
  // @DataMember
  public id: string;

  // @DataMember
  public start?: string;

  // @DataMember
  public end?: string;

  // @DataMember
  public filterEvents: number[];

  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public entityGroups: string[];

  // @DataMember
  public itemsIds: string[];

  // @DataMember
  public onlyOwnedItems: boolean;

  // @DataMember
  public page: number;

  // @DataMember
  public items: number;

  // @DataMember
  public sort: string;

  // @DataMember
  public filter: string;

  // @DataMember
  public propertiesTest: { [index: string]: number };

  public constructor(init?: Partial<GetTrafficObjectControllerEventLogRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new GetDeviceEventLogResponse();
  }
  public getTypeName() {
    return 'GetTrafficObjectControllerEventLogRequest';
  }
}

// @Route("/trafficObjectController/exist", "GET")
// @DataContract
export class GetTrafficObjectControllerExistRequest implements IReturn<boolean> {
  // @DataMember(IsRequired=true)
  public type: TrafficControllerType;

  // @DataMember(IsRequired=true)
  public number: string;

  public constructor(init?: Partial<GetTrafficObjectControllerExistRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return false;
  }
  public getTypeName() {
    return 'GetTrafficObjectControllerExistRequest';
  }
}

// @Route("/customer/{customerId}/trafficObjectControllers", "GET")
// @DataContract
export class GetTrafficObjectControllersRequest
  implements IReturn<GetTrafficObjectControllersResponse>, ICustomerItemRequest
{
  // @DataMember
  public onlyUsed: boolean;

  // @DataMember
  public onlyFree: boolean;

  // @DataMember
  public controllerType: TrafficControllerType;

  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public entityGroups: string[];

  // @DataMember
  public itemsIds: string[];

  // @DataMember
  public onlyOwnedItems: boolean;

  // @DataMember
  public page: number;

  // @DataMember
  public items: number;

  // @DataMember
  public sort: string;

  // @DataMember
  public filter: string;

  // @DataMember
  public propertiesTest: { [index: string]: number };

  public constructor(init?: Partial<GetTrafficObjectControllersRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new GetTrafficObjectControllersResponse();
  }
  public getTypeName() {
    return 'GetTrafficObjectControllersRequest';
  }
}

// @Route("/customer/{customerId}/trafficObjectController/{id}", "GET")
// @DataContract
export class GetTrafficObjectControllerRequest
  implements IReturn<TrafficObjectController>, ICustomerItemRequest, IBaseEntityItemRequest
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember(IsRequired=true)
  public id: string;

  public constructor(init?: Partial<GetTrafficObjectControllerRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new TrafficObjectController();
  }
  public getTypeName() {
    return 'GetTrafficObjectControllerRequest';
  }
}

// @Route("/customer/{customerId}/trafficObjectController/{id}", "PUT")
// @DataContract
export class UpdateTrafficObjectControllerRequest
  implements
    IReturn<TrafficObjectController>,
    ICustomerItemRequest,
    IBaseEntityItemRequest,
    IDescription
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public updateEntityGroups: boolean;

  // @DataMember
  public entityGroups: string[];

  // @DataMember(IsRequired=true)
  public id: string;

  // @DataMember(IsRequired=true)
  public entity: TrafficObjectController;

  // @DataMember
  public description: string;

  public constructor(init?: Partial<UpdateTrafficObjectControllerRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new TrafficObjectController();
  }
  public getTypeName() {
    return 'UpdateTrafficObjectControllerRequest';
  }
}

// @Route("/customer/{customerId}/trafficObjectController", "POST")
// @DataContract
export class CreateTrafficObjectControllerRequest
  implements IReturn<TrafficObjectController>, ICustomerItemRequest, IDescription
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public updateEntityGroups: boolean;

  // @DataMember
  public entityGroups: string[];

  // @DataMember(IsRequired=true)
  public entity: TrafficObjectController;

  // @DataMember
  public description: string;

  public constructor(init?: Partial<CreateTrafficObjectControllerRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new TrafficObjectController();
  }
  public getTypeName() {
    return 'CreateTrafficObjectControllerRequest';
  }
}

// @Route("/customer/{customerId}/trafficObjectControllers", "DELETE")
// @DataContract
export class DeleteTrafficObjectControllersRequest
  implements IReturn<number>, ICustomerItemRequest, IDescription
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public description: string;

  // @DataMember(IsRequired=true)
  public ids: string[];

  public constructor(init?: Partial<DeleteTrafficObjectControllersRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return 0;
  }
  public getTypeName() {
    return 'DeleteTrafficObjectControllersRequest';
  }
}

// @Route("/customer/{customerId}/trafficObjectController/{id}/disconnect", "POST")
// @DataContract
export class DisconnectTrafficObjectControllerRequest
  implements IReturn<SessionInfo>, IDescription, ICustomerItemRequest, IBaseEntityItemRequest
{
  // @DataMember
  public description: string;

  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember(IsRequired=true)
  public id: string;

  public constructor(init?: Partial<DisconnectTrafficObjectControllerRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new SessionInfo();
  }
  public getTypeName() {
    return 'DisconnectTrafficObjectControllerRequest';
  }
}

// @Route("/customer/{customerId}/trafficObjectController/{id}/changeCustomer", "POST")
// @DataContract
export class TrafficObjectControllerChangeCustomerRequest
  implements IReturn<boolean>, IDescription, ICustomerItemRequest, IBaseEntityItemRequest
{
  // @DataMember
  public description: string;

  // @DataMember
  public newCustomerId: string;

  // @DataMember
  public customerId: string;

  // @DataMember
  public id: string;

  public constructor(init?: Partial<TrafficObjectControllerChangeCustomerRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return false;
  }
  public getTypeName() {
    return 'TrafficObjectControllerChangeCustomerRequest';
  }
}

// @Route("/customer/{customerId}/trafficObjects/groups", "GET")
// @DataContract
export class GetTrafficObjectGroupsRequest
  implements IReturn<GetEntityGroupsResponse>, ICustomerItemRequest
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public entityGroups: string[];

  // @DataMember
  public itemsIds: string[];

  // @DataMember
  public onlyOwnedItems: boolean;

  // @DataMember
  public page: number;

  // @DataMember
  public items: number;

  // @DataMember
  public sort: string;

  // @DataMember
  public filter: string;

  // @DataMember
  public propertiesTest: { [index: string]: number };

  public constructor(init?: Partial<GetTrafficObjectGroupsRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new GetEntityGroupsResponse();
  }
  public getTypeName() {
    return 'GetTrafficObjectGroupsRequest';
  }
}

// @Route("/customer/{customerId}/trafficObjects/groups/{id}", "GET")
// @DataContract
export class GetTrafficObjectGroupRequest
  implements IReturn<EntityGroup>, ICustomerItemRequest, IBaseEntityItemRequest
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember(IsRequired=true)
  public id: string;

  public constructor(init?: Partial<GetTrafficObjectGroupRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new EntityGroup();
  }
  public getTypeName() {
    return 'GetTrafficObjectGroupRequest';
  }
}

// @Route("/customer/{customerId}/trafficObjects/groups", "POST")
// @DataContract
export class CreateTrafficObjectGroupRequest
  implements IReturn<EntityGroup>, ICustomerItemRequest, IDescription
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public updateEntityGroups: boolean;

  // @DataMember
  public entityGroups: string[];

  // @DataMember(IsRequired=true)
  public entity: EntityGroup;

  // @DataMember
  public description: string;

  public constructor(init?: Partial<CreateTrafficObjectGroupRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new EntityGroup();
  }
  public getTypeName() {
    return 'CreateTrafficObjectGroupRequest';
  }
}

// @Route("/customer/{customerId}/trafficObjects/groups/{id}", "PUT")
// @DataContract
export class UpdateTrafficObjectGroupRequest
  implements IReturn<EntityGroup>, ICustomerItemRequest, IBaseEntityItemRequest, IDescription
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public updateEntityGroups: boolean;

  // @DataMember
  public entityGroups: string[];

  // @DataMember(IsRequired=true)
  public id: string;

  // @DataMember(IsRequired=true)
  public entity: EntityGroup;

  // @DataMember
  public description: string;

  public constructor(init?: Partial<UpdateTrafficObjectGroupRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new EntityGroup();
  }
  public getTypeName() {
    return 'UpdateTrafficObjectGroupRequest';
  }
}

// @Route("/customer/{customerId}/trafficObjects/groups", "DELETE")
// @DataContract
export class DeleteTrafficObjectGroupsRequest
  implements IReturn<number>, ICustomerItemRequest, IDescription
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public description: string;

  // @DataMember(IsRequired=true)
  public ids: string[];

  public constructor(init?: Partial<DeleteTrafficObjectGroupsRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return 0;
  }
  public getTypeName() {
    return 'DeleteTrafficObjectGroupsRequest';
  }
}

// @Route("/customer/{customerId}/trafficObjects/groups/{id}", "GET")
// @DataContract
export class GetTrafficObjectGroupSharesRequest
  implements IReturn<GetEntityGroupSharesResponse>, IBaseEntityItemRequest, ICustomerItemRequest
{
  // @DataMember
  public id: string;

  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public entityGroups: string[];

  // @DataMember
  public itemsIds: string[];

  // @DataMember
  public onlyOwnedItems: boolean;

  // @DataMember
  public page: number;

  // @DataMember
  public items: number;

  // @DataMember
  public sort: string;

  // @DataMember
  public filter: string;

  // @DataMember
  public propertiesTest: { [index: string]: number };

  public constructor(init?: Partial<GetTrafficObjectGroupSharesRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new GetEntityGroupSharesResponse();
  }
  public getTypeName() {
    return 'GetTrafficObjectGroupSharesRequest';
  }
}

// @Route("/customer/{customerId}/trafficObjects/groups/{id}", "PUT")
// @DataContract
export class UpdateTrafficObjectGroupShareRequest
  implements IReturn<EntityGroupPermissions>, ICustomerItemRequest, IDescription
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember(IsRequired=true)
  public id: string;

  // @DataMember(IsRequired=true)
  public entity: EntityGroupPermissions;

  // @DataMember
  public description: string;

  public constructor(init?: Partial<UpdateTrafficObjectGroupShareRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new EntityGroupPermissions();
  }
  public getTypeName() {
    return 'UpdateTrafficObjectGroupShareRequest';
  }
}

// @Route("/customer/{customerId}/trafficObjects", "GET")
// @DataContract
export class GetTrafficObjectsRequest
  implements IReturn<GetTrafficObjectsResponse>, ICustomerItemRequest
{
  // @DataMember
  public adaptiveType?: AdaptiveType;

  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public entityGroups: string[];

  // @DataMember
  public itemsIds: string[];

  // @DataMember
  public onlyOwnedItems: boolean;

  // @DataMember
  public page: number;

  // @DataMember
  public items: number;

  // @DataMember
  public sort: string;

  // @DataMember
  public filter: string;

  // @DataMember
  public propertiesTest: { [index: string]: number };

  public constructor(init?: Partial<GetTrafficObjectsRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new GetTrafficObjectsResponse();
  }
  public getTypeName() {
    return 'GetTrafficObjectsRequest';
  }
}

// @Route("/customer/{customerId}/trafficObject/{id}", "GET")
// @DataContract
export class GetTrafficObjectRequest
  implements IReturn<TrafficObject>, ICustomerItemRequest, IBaseEntityItemRequest
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember(IsRequired=true)
  public id: string;

  public constructor(init?: Partial<GetTrafficObjectRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new TrafficObject();
  }
  public getTypeName() {
    return 'GetTrafficObjectRequest';
  }
}

// @Route("/customer/{customerId}/trafficObject/exist", "GET")
// @DataContract
export class GetTrafficObjectExistRequest implements IReturn<boolean>, ICustomerItemRequest {
  // @DataMember(IsRequired=true)
  public name: string;

  // @DataMember
  public customerId: string;

  public constructor(init?: Partial<GetTrafficObjectExistRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return false;
  }
  public getTypeName() {
    return 'GetTrafficObjectExistRequest';
  }
}

// @Route("/customer/{customerId}/trafficObject/{id}/currentUserPermissions", "GET")
// @DataContract
export class GetTrafficObjectCurrentUserPermissionsRequest
  implements IReturn<string[]>, ICustomerItemRequest, IBaseEntityItemRequest
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember(IsRequired=true)
  public id: string;

  public constructor(init?: Partial<GetTrafficObjectCurrentUserPermissionsRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new Array<string>();
  }
  public getTypeName() {
    return 'GetTrafficObjectCurrentUserPermissionsRequest';
  }
}

// @Route("/customer/{customerId}/trafficObject", "POST")
// @DataContract
export class CreateTrafficObjectRequest
  implements IReturn<TrafficObject>, ICustomerItemRequest, IDescription
{
  // @DataMember
  public schemaSet: TrafficObjectSchemaSet;

  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public updateEntityGroups: boolean;

  // @DataMember
  public entityGroups: string[];

  // @DataMember(IsRequired=true)
  public entity: TrafficObject;

  // @DataMember
  public description: string;

  public constructor(init?: Partial<CreateTrafficObjectRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new TrafficObject();
  }
  public getTypeName() {
    return 'CreateTrafficObjectRequest';
  }
}

// @Route("/customer/{customerId}/trafficObject/{id}", "PUT")
// @DataContract
export class UpdateTrafficObjectRequest
  implements IReturn<TrafficObject>, ICustomerItemRequest, IBaseEntityItemRequest, IDescription
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public updateEntityGroups: boolean;

  // @DataMember
  public entityGroups: string[];

  // @DataMember(IsRequired=true)
  public id: string;

  // @DataMember(IsRequired=true)
  public entity: TrafficObject;

  // @DataMember
  public description: string;

  public constructor(init?: Partial<UpdateTrafficObjectRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new TrafficObject();
  }
  public getTypeName() {
    return 'UpdateTrafficObjectRequest';
  }
}

// @Route("/customer/{customerId}/trafficObjects", "DELETE")
// @DataContract
export class DeleteTrafficObjectsRequest
  implements IReturn<number>, ICustomerItemRequest, IDescription
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public description: string;

  // @DataMember(IsRequired=true)
  public ids: string[];

  public constructor(init?: Partial<DeleteTrafficObjectsRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return 0;
  }
  public getTypeName() {
    return 'DeleteTrafficObjectsRequest';
  }
}

// @Route("/customer/{customerId}/trafficObject/{id}/schemaSet", "PUT")
// @DataContract
export class UpdateTrafficObjectSchemaRequest
  implements
    IReturn<TrafficObjectSchemaSet>,
    ICustomerItemRequest,
    IBaseEntityItemRequest,
    IDescription
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public updateEntityGroups: boolean;

  // @DataMember
  public entityGroups: string[];

  // @DataMember(IsRequired=true)
  public id: string;

  // @DataMember(IsRequired=true)
  public entity: TrafficObjectSchemaSet;

  // @DataMember
  public description: string;

  public constructor(init?: Partial<UpdateTrafficObjectSchemaRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new TrafficObjectSchemaSet();
  }
  public getTypeName() {
    return 'UpdateTrafficObjectSchemaRequest';
  }
}

// @Route("/customer/{customerId}/trafficObject/{id}/schemaSet", "GET")
// @DataContract
export class GetTrafficObjectSchemaRequest
  implements IReturn<TrafficObjectSchemaSet>, ICustomerItemRequest, IBaseEntityItemRequest
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember(IsRequired=true)
  public id: string;

  public constructor(init?: Partial<GetTrafficObjectSchemaRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new TrafficObjectSchemaSet();
  }
  public getTypeName() {
    return 'GetTrafficObjectSchemaRequest';
  }
}

// @Route("/customer/{customerId}/trafficObject/{id}/adaptiveOptions", "PUT")
// @DataContract
export class UpdateTrafficObjectAdaptiveOptionsRequest
  implements
    IReturn<TrafficObjectAdaptiveOptions>,
    ICustomerItemRequest,
    IBaseEntityItemRequest,
    IDescription
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public updateEntityGroups: boolean;

  // @DataMember
  public entityGroups: string[];

  // @DataMember(IsRequired=true)
  public id: string;

  // @DataMember(IsRequired=true)
  public entity: TrafficObjectAdaptiveOptions;

  // @DataMember
  public description: string;

  public constructor(init?: Partial<UpdateTrafficObjectAdaptiveOptionsRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new TrafficObjectAdaptiveOptions();
  }
  public getTypeName() {
    return 'UpdateTrafficObjectAdaptiveOptionsRequest';
  }
}

// @Route("/customer/{customerId}/trafficObject/{id}/adaptiveOptions", "GET")
// @DataContract
export class GetTrafficObjectAdaptiveOptionsRequest
  implements IReturn<TrafficObjectAdaptiveOptions>, ICustomerItemRequest, IBaseEntityItemRequest
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember(IsRequired=true)
  public id: string;

  public constructor(init?: Partial<GetTrafficObjectAdaptiveOptionsRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new TrafficObjectAdaptiveOptions();
  }
  public getTypeName() {
    return 'GetTrafficObjectAdaptiveOptionsRequest';
  }
}

// @Route("/customer/{customerId}/trafficObject/{id}/changeIntervals", "PUT")
// @DataContract
export class UpdateTrafficObjectChangeIntervalsRequest
  implements
    IReturn<TrafficObjectChangeIntervals>,
    ICustomerItemRequest,
    IBaseEntityItemRequest,
    IDescription
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public updateEntityGroups: boolean;

  // @DataMember
  public entityGroups: string[];

  // @DataMember(IsRequired=true)
  public id: string;

  // @DataMember(IsRequired=true)
  public entity: TrafficObjectChangeIntervals;

  // @DataMember
  public description: string;

  public constructor(init?: Partial<UpdateTrafficObjectChangeIntervalsRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new TrafficObjectChangeIntervals();
  }
  public getTypeName() {
    return 'UpdateTrafficObjectChangeIntervalsRequest';
  }
}

// @Route("/customer/{customerId}/trafficObject/{id}/changeIntervals", "GET")
// @DataContract
export class GetTrafficObjectChangeIntervalsRequest
  implements IReturn<TrafficObjectChangeIntervals>, ICustomerItemRequest, IBaseEntityItemRequest
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember(IsRequired=true)
  public id: string;

  public constructor(init?: Partial<GetTrafficObjectChangeIntervalsRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new TrafficObjectChangeIntervals();
  }
  public getTypeName() {
    return 'GetTrafficObjectChangeIntervalsRequest';
  }
}

// @Route("/customer/{customerId}/trafficObject/{id}/movementTypes", "GET")
// @DataContract
export class GetTrafficObjectMovementsRequest
  implements IReturn<TrafficMovementType[]>, ICustomerItemRequest, IBaseEntityItemRequest
{
  // @DataMember
  public excludePedestrian: boolean;

  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember(IsRequired=true)
  public id: string;

  public constructor(init?: Partial<GetTrafficObjectMovementsRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new Array<TrafficMovementType>();
  }
  public getTypeName() {
    return 'GetTrafficObjectMovementsRequest';
  }
}

// @Route("/customer/{customerId}/trafficObject/{id}/plans", "GET")
// @DataContract
export class GetTrafficObjectPlansRequest
  implements IReturn<TrafficPlan[]>, ICustomerItemRequest, IBaseEntityItemRequest
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember(IsRequired=true)
  public id: string;

  public constructor(init?: Partial<GetTrafficObjectPlansRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new Array<TrafficPlan>();
  }
  public getTypeName() {
    return 'GetTrafficObjectPlansRequest';
  }
}

// @Route("/customer/{customerId}/trafficObject/{id}/schedule", "GET")
// @DataContract
export class GetTrafficObjectScheduleRequest
  implements IReturn<TrafficSchedule>, ICustomerItemRequest, IBaseEntityItemRequest
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember(IsRequired=true)
  public id: string;

  public constructor(init?: Partial<GetTrafficObjectScheduleRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new TrafficSchedule();
  }
  public getTypeName() {
    return 'GetTrafficObjectScheduleRequest';
  }
}

// @Route("/customer/{customerId}/trafficObject/{id}/plans/current", "GET")
// @DataContract
export class GetTrafficObjectCurrentPlanRequest
  implements IReturn<TrafficPlan>, ICustomerItemRequest, IBaseEntityItemRequest
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember(IsRequired=true)
  public id: string;

  public constructor(init?: Partial<GetTrafficObjectCurrentPlanRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new TrafficPlan();
  }
  public getTypeName() {
    return 'GetTrafficObjectCurrentPlanRequest';
  }
}

// @Route("/customer/{customerId}/trafficObject/{id}/changeCustomer", "POST")
// @DataContract
export class TrafficObjectChangeCustomerRequest
  implements IReturn<boolean>, IDescription, ICustomerItemRequest, IBaseEntityItemRequest
{
  // @DataMember
  public description: string;

  // @DataMember
  public newCustomerId: string;

  // @DataMember
  public customerId: string;

  // @DataMember
  public id: string;

  public constructor(init?: Partial<TrafficObjectChangeCustomerRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return false;
  }
  public getTypeName() {
    return 'TrafficObjectChangeCustomerRequest';
  }
}

// @Route("/customer/{customerId}/trafficObject/{id}/detachController", "POST")
// @DataContract
export class DetachTrafficObjectControllerRequest
  implements IReturn<TrafficObject>, IDescription, ICustomerItemRequest, IBaseEntityItemRequest
{
  // @DataMember
  public description: string;

  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember(IsRequired=true)
  public id: string;

  public constructor(init?: Partial<DetachTrafficObjectControllerRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new TrafficObject();
  }
  public getTypeName() {
    return 'DetachTrafficObjectControllerRequest';
  }
}

// @Route("/customer/{customerId}/trafficObject/{id}/eventTypes", "GET")
// @DataContract
export class GetControllerEventTypesRequest
  implements IReturn<ControllerEventType[]>, ICustomerItemRequest, IBaseEntityItemRequest
{
  // @DataMember
  public id: string;

  // @DataMember
  public customerId: string;

  public constructor(init?: Partial<GetControllerEventTypesRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new Array<ControllerEventType>();
  }
  public getTypeName() {
    return 'GetControllerEventTypesRequest';
  }
}

// @Route("/customer/{customerId}/trafficObject/{id}/eventReport", "GET")
// @DataContract
export class GetControllerEventReportRequest
  implements IReturn<ControllerEventReport>, ICustomerItemRequest, IBaseEntityItemRequest
{
  // @DataMember
  public start: string;

  // @DataMember
  public end: string;

  // @DataMember
  public eventFilter: ControllerEventType[];

  // @DataMember
  public sort: string;

  // @DataMember
  public id: string;

  // @DataMember
  public customerId: string;

  public constructor(init?: Partial<GetControllerEventReportRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new ControllerEventReport();
  }
  public getTypeName() {
    return 'GetControllerEventReportRequest';
  }
}

// @Route("/customer/{customerId}/trafficObjects/eventReport", "GET")
// @DataContract
export class GetTrafficObjectsControllerEventReportRequest
  implements IReturn<ControllerEventReport>, ICustomerItemRequest
{
  // @DataMember
  public entityGroups: string[];

  // @DataMember
  public start: string;

  // @DataMember
  public end: string;

  // @DataMember
  public eventFilter: ControllerEventType[];

  // @DataMember
  public customerId: string;

  // @DataMember
  public sort: string;

  public constructor(init?: Partial<GetTrafficObjectsControllerEventReportRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new ControllerEventReport();
  }
  public getTypeName() {
    return 'GetTrafficObjectsControllerEventReportRequest';
  }
}

// @Route("/customer/{customerId}/trafficObjects/statistics", "GET")
// @DataContract
export class GetTrafficObjectsStatisticRequest
  implements IReturn<TrafficObjectsStatistic>, ICustomerItemRequest, IBaseEntityItemRequest
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember(IsRequired=true)
  public id: string;

  public constructor(init?: Partial<GetTrafficObjectsStatisticRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new TrafficObjectsStatistic();
  }
  public getTypeName() {
    return 'GetTrafficObjectsStatisticRequest';
  }
}

// @Route("/customer/{customerId}/trafficObjects/statuses", "GET")
// @DataContract
export class GetTrafficObjectsStatusesRequest
  implements IReturn<GetTrafficObjectsStatusesResponse>, ICustomerItemRequest
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public entityGroups: string[];

  // @DataMember
  public itemsIds: string[];

  // @DataMember
  public onlyOwnedItems: boolean;

  // @DataMember
  public page: number;

  // @DataMember
  public items: number;

  // @DataMember
  public sort: string;

  // @DataMember
  public filter: string;

  // @DataMember
  public propertiesTest: { [index: string]: number };

  public constructor(init?: Partial<GetTrafficObjectsStatusesRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new GetTrafficObjectsStatusesResponse();
  }
  public getTypeName() {
    return 'GetTrafficObjectsStatusesRequest';
  }
}

// @Route("/customer/{customerId}/trafficObjects/fullStatuses", "GET")
// @DataContract
export class GetTrafficObjectsFullStatusesRequest
  implements IReturn<GetTrafficObjectsFullStatusesResponse>, ICustomerItemRequest
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public entityGroups: string[];

  // @DataMember
  public itemsIds: string[];

  // @DataMember
  public onlyOwnedItems: boolean;

  // @DataMember
  public page: number;

  // @DataMember
  public items: number;

  // @DataMember
  public sort: string;

  // @DataMember
  public filter: string;

  // @DataMember
  public propertiesTest: { [index: string]: number };

  public constructor(init?: Partial<GetTrafficObjectsFullStatusesRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new GetTrafficObjectsFullStatusesResponse();
  }
  public getTypeName() {
    return 'GetTrafficObjectsFullStatusesRequest';
  }
}

// @Route("/customer/{customerId}/trafficObjects/commands/{Id}", "GET")
// @DataContract
export class GetTrafficObjectsCommandTicketRequest
  implements IReturn<TrafficObjectCommandStatus>, ICustomerItemRequest, IBaseEntityItemRequest
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember(IsRequired=true)
  public id: string;

  public constructor(init?: Partial<GetTrafficObjectsCommandTicketRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new TrafficObjectCommandStatus();
  }
  public getTypeName() {
    return 'GetTrafficObjectsCommandTicketRequest';
  }
}

// @Route("/trafficObjects/adaptiveSettings", "GET")
// @DataContract
export class GetAdaptiveTrafficObjectsRequest
  implements IReturn<GetAdaptiveTrafficObjectsResponse>
{
  // @DataMember
  public page: number;

  // @DataMember
  public items: number;

  // @DataMember
  public sort: string;

  // @DataMember
  public filter: string;

  // @DataMember
  public propertiesTest: { [index: string]: number };

  public constructor(init?: Partial<GetAdaptiveTrafficObjectsRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new GetAdaptiveTrafficObjectsResponse();
  }
  public getTypeName() {
    return 'GetAdaptiveTrafficObjectsRequest';
  }
}

// @Route("/customer/{customerId}/trafficObject/{id}/controller/potok/setController", "PUT")
// @DataContract
export class SetPotokControllerRequest
  implements
    IReturn<PotokTrafficObjectStatus>,
    ICustomerItemRequest,
    IBaseEntityItemRequest,
    IDescription
{
  // @DataMember
  public id: string;

  // @DataMember
  public customerId: string;

  // @DataMember
  public newControllerId: string;

  // @DataMember
  public uploadSettingsToController: boolean;

  // @DataMember
  public description: string;

  public constructor(init?: Partial<SetPotokControllerRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new PotokTrafficObjectStatus();
  }
  public getTypeName() {
    return 'SetPotokControllerRequest';
  }
}

// @Route("/customer/{customerId}/trafficObject/{id}/controller/potok/setRemoteMode", "POST")
// @DataContract
export class SetPotokControllerRemoteModeRequest
  implements
    IReturn<PotokTrafficObjectStatus>,
    ICustomerItemRequest,
    IBaseEntityItemRequest,
    IDescription
{
  // @DataMember
  public movements: PotokMovementType[];

  // @DataMember
  public modeTime: number;

  // @DataMember
  public service: string;

  // @DataMember
  public id: string;

  // @DataMember
  public customerId: string;

  // @DataMember
  public async: boolean;

  // @DataMember
  public description: string;

  public constructor(init?: Partial<SetPotokControllerRemoteModeRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new PotokTrafficObjectStatus();
  }
  public getTypeName() {
    return 'SetPotokControllerRemoteModeRequest';
  }
}

// @Route("/customer/{customerId}/trafficObject/{id}/controller/potok/setCoordinationMode", "POST")
// @DataContract
export class SetPotokControllerCoordinationModeRequest
  implements
    IReturn<PotokTrafficObjectStatus>,
    ICustomerItemRequest,
    IBaseEntityItemRequest,
    IDescription
{
  // @DataMember
  public id: string;

  // @DataMember
  public customerId: string;

  // @DataMember
  public async: boolean;

  // @DataMember
  public description: string;

  public constructor(init?: Partial<SetPotokControllerCoordinationModeRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new PotokTrafficObjectStatus();
  }
  public getTypeName() {
    return 'SetPotokControllerCoordinationModeRequest';
  }
}

// @Route("/customer/{customerId}/trafficObject/{id}/controller/potok/setOfflineMode", "POST")
// @DataContract
export class SetPotokControllerOfflineModeRequest
  implements
    IReturn<PotokTrafficObjectStatus>,
    ICustomerItemRequest,
    IBaseEntityItemRequest,
    IDescription
{
  // @DataMember
  public id: string;

  // @DataMember
  public customerId: string;

  // @DataMember
  public async: boolean;

  // @DataMember
  public description: string;

  public constructor(init?: Partial<SetPotokControllerOfflineModeRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new PotokTrafficObjectStatus();
  }
  public getTypeName() {
    return 'SetPotokControllerOfflineModeRequest';
  }
}

// @Route("/customer/{customerId}/trafficObject/{id}/controller/potok/setYellowBlinkMode", "POST")
// @DataContract
export class SetPotokControllerYellowBlinkModeRequest
  implements
    IReturn<PotokTrafficObjectStatus>,
    ICustomerItemRequest,
    IBaseEntityItemRequest,
    IDescription
{
  // @DataMember
  public id: string;

  // @DataMember
  public customerId: string;

  // @DataMember
  public async: boolean;

  // @DataMember
  public description: string;

  public constructor(init?: Partial<SetPotokControllerYellowBlinkModeRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new PotokTrafficObjectStatus();
  }
  public getTypeName() {
    return 'SetPotokControllerYellowBlinkModeRequest';
  }
}

// @Route("/customer/{customerId}/trafficObject/{id}/controller/potok/setAdaptiveMode", "POST")
// @DataContract
export class SetPotokControllerAdaptiveModeRequest
  implements
    IReturn<PotokTrafficObjectStatus>,
    ICustomerItemRequest,
    IBaseEntityItemRequest,
    IDescription
{
  // @DataMember
  public planScheduleLocalStartTime: string;

  // @DataMember
  public adaptivePlan: PotokPlan;

  // @DataMember
  public modeTime: number;

  // @DataMember
  public id: string;

  // @DataMember
  public customerId: string;

  // @DataMember
  public async: boolean;

  // @DataMember
  public description: string;

  public constructor(init?: Partial<SetPotokControllerAdaptiveModeRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new PotokTrafficObjectStatus();
  }
  public getTypeName() {
    return 'SetPotokControllerAdaptiveModeRequest';
  }
}

// @Route("/customer/{customerId}/trafficObject/{id}/controller/potok/setAllRedMode", "POST")
// @DataContract
export class SetPotokControllerAllRedModeRequest
  implements
    IReturn<PotokTrafficObjectStatus>,
    ICustomerItemRequest,
    IBaseEntityItemRequest,
    IDescription
{
  // @DataMember
  public modeTime: number;

  // @DataMember
  public id: string;

  // @DataMember
  public customerId: string;

  // @DataMember
  public async: boolean;

  // @DataMember
  public description: string;

  public constructor(init?: Partial<SetPotokControllerAllRedModeRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new PotokTrafficObjectStatus();
  }
  public getTypeName() {
    return 'SetPotokControllerAllRedModeRequest';
  }
}

// @Route("/customer/{customerId}/trafficObject/{id}/controller/potok/settings", "GET")
// @DataContract
export class GetPotokControllerSettingsRequest
  implements IReturn<PotokSettings>, ICustomerItemRequest, IBaseEntityItemRequest
{
  // @DataMember
  public customerId: string;

  // @DataMember(IsRequired=true)
  public id: string;

  public constructor(init?: Partial<GetPotokControllerSettingsRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new PotokSettings();
  }
  public getTypeName() {
    return 'GetPotokControllerSettingsRequest';
  }
}

// @Route("/customer/{customerId}/trafficObjects/potokStatuses", "GET")
// @DataContract
export class GetPotokTrafficObjectsStatusesRequest
  implements IReturn<GetPotokTrafficObjectsStatusesResponse>, ICustomerItemRequest
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public entityGroups: string[];

  // @DataMember
  public itemsIds: string[];

  // @DataMember
  public onlyOwnedItems: boolean;

  // @DataMember
  public page: number;

  // @DataMember
  public items: number;

  // @DataMember
  public sort: string;

  // @DataMember
  public filter: string;

  // @DataMember
  public propertiesTest: { [index: string]: number };

  public constructor(init?: Partial<GetPotokTrafficObjectsStatusesRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new GetPotokTrafficObjectsStatusesResponse();
  }
  public getTypeName() {
    return 'GetPotokTrafficObjectsStatusesRequest';
  }
}

// @Route("/customer/{customerId}/trafficObject/{id}/controller/potok/status", "GET")
// @DataContract
export class GetPotokTrafficObjectStatusRequest
  implements IReturn<PotokTrafficObjectStatus>, ICustomerItemRequest, IBaseEntityItemRequest
{
  // @DataMember
  public id: string;

  // @DataMember
  public customerId: string;

  public constructor(init?: Partial<GetPotokTrafficObjectStatusRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new PotokTrafficObjectStatus();
  }
  public getTypeName() {
    return 'GetPotokTrafficObjectStatusRequest';
  }
}

// @Route("/customer/{customerId}/trafficObject/{id}/controller/potok/currentPlan", "GET")
// @DataContract
export class GetPotokTrafficObjectCurrentPlanRequest
  implements
    IReturn<GetPotokTrafficObjectCurrentPlanResponse>,
    ICustomerItemRequest,
    IBaseEntityItemRequest
{
  // @DataMember
  public id: string;

  // @DataMember
  public customerId: string;

  public constructor(init?: Partial<GetPotokTrafficObjectCurrentPlanRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new GetPotokTrafficObjectCurrentPlanResponse();
  }
  public getTypeName() {
    return 'GetPotokTrafficObjectCurrentPlanRequest';
  }
}

// @Route("/customer/{customerId}/trafficObject/{id}/controller/potok/settings", "PUT")
// @DataContract
export class UpdatePotokControllerSettingsRequest
  implements IReturn<PotokSettings>, ICustomerItemRequest, IBaseEntityItemRequest, IDescription
{
  // @DataMember
  public customerId: string;

  // @DataMember
  public settings: PotokSettings;

  // @DataMember
  public forceUpdate: boolean;

  // @DataMember
  public uploadSettingsToController: boolean;

  // @DataMember
  public description: string;

  // @DataMember(IsRequired=true)
  public id: string;

  public constructor(init?: Partial<UpdatePotokControllerSettingsRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new PotokSettings();
  }
  public getTypeName() {
    return 'UpdatePotokControllerSettingsRequest';
  }
}

// @Route("/customer/{customerId}/trafficObject/{id}/controller/potok/settings/buildPlanBarrier", "POST")
// @DataContract
export class BuildPotokControllerPlanBarrierRequest
  implements IReturn<PotokBarrier>, ICustomerItemRequest, IBaseEntityItemRequest
{
  // @DataMember(Order=0)
  public length: number;

  // @DataMember(Order=1)
  public movements: PotokMovementType[];

  // @DataMember(Order=3)
  public barrierType: PotokBarrierType;

  // @DataMember(Order=4)
  public customerId: string;

  // @DataMember(Order=5)
  public id: string;

  public constructor(init?: Partial<BuildPotokControllerPlanBarrierRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new PotokBarrier();
  }
  public getTypeName() {
    return 'BuildPotokControllerPlanBarrierRequest';
  }
}

// @Route("/healthStatus", "GET")
// @DataContract
export class GetHealthStatusRequest implements IReturn<string> {
  public constructor(init?: Partial<GetHealthStatusRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return '';
  }
  public getTypeName() {
    return 'GetHealthStatusRequest';
  }
}

// @Route("/customer/{customerId}/integration/{customerIntegrationId}/entities", "GET")
// @DataContract
export class GetEntityIntegrationsRequest
  implements
    IReturn<GetEntityIntegrationResponse>,
    ICustomerIntegrationIdRequest,
    ICustomerItemRequest
{
  // @DataMember
  public customerIntegrationId: string;

  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public entityGroups: string[];

  // @DataMember
  public itemsIds: string[];

  // @DataMember
  public onlyOwnedItems: boolean;

  // @DataMember
  public page: number;

  // @DataMember
  public items: number;

  // @DataMember
  public sort: string;

  // @DataMember
  public filter: string;

  // @DataMember
  public propertiesTest: { [index: string]: number };

  public constructor(init?: Partial<GetEntityIntegrationsRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new GetEntityIntegrationResponse();
  }
  public getTypeName() {
    return 'GetEntityIntegrationsRequest';
  }
}

// @Route("/customer/{customerId}/integration/{customerIntegrationId}/entity/{id}", "GET")
// @DataContract
export class GetEntityIntegrationRequest
  implements
    IReturn<EntityIntegration>,
    ICustomerIntegrationIdRequest,
    ICustomerItemRequest,
    IBaseEntityItemRequest
{
  // @DataMember
  public customerIntegrationId: string;

  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember(IsRequired=true)
  public id: string;

  public constructor(init?: Partial<GetEntityIntegrationRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new EntityIntegration();
  }
  public getTypeName() {
    return 'GetEntityIntegrationRequest';
  }
}

// @Route("/customer/{customerId}/integration/{customerIntegrationId}/entities", "POST")
// @DataContract
export class CreateEntityIntegrationRequest
  implements
    IReturn<EntityIntegration>,
    ICustomerIntegrationIdRequest,
    ICustomerItemRequest,
    IDescription
{
  // @DataMember
  public customerIntegrationId: string;

  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public updateEntityGroups: boolean;

  // @DataMember
  public entityGroups: string[];

  // @DataMember(IsRequired=true)
  public entity: EntityIntegration;

  // @DataMember
  public description: string;

  public constructor(init?: Partial<CreateEntityIntegrationRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new EntityIntegration();
  }
  public getTypeName() {
    return 'CreateEntityIntegrationRequest';
  }
}

// @Route("/customer/{customerId}/integration/{customerIntegrationId}/entity/{id}", "PUT")
// @DataContract
export class UpdateEntityIntegrationRequest
  implements
    IReturn<EntityIntegration>,
    ICustomerIntegrationIdRequest,
    ICustomerItemRequest,
    IBaseEntityItemRequest,
    IDescription
{
  // @DataMember
  public customerIntegrationId: string;

  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public updateEntityGroups: boolean;

  // @DataMember
  public entityGroups: string[];

  // @DataMember(IsRequired=true)
  public id: string;

  // @DataMember(IsRequired=true)
  public entity: EntityIntegration;

  // @DataMember
  public description: string;

  public constructor(init?: Partial<UpdateEntityIntegrationRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new EntityIntegration();
  }
  public getTypeName() {
    return 'UpdateEntityIntegrationRequest';
  }
}

// @Route("/entity/{id}/properties", "GET")
// @DataContract
export class GetEntityPropertiesRequest implements IReturn<any>, IBaseEntityItemRequest {
  // @DataMember(IsRequired=true)
  public id: string;

  public constructor(init?: Partial<GetEntityPropertiesRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return {};
  }
  public getTypeName() {
    return 'GetEntityPropertiesRequest';
  }
}

// @Route("/entity/{id}/properties", "PUT")
// @DataContract
export class UpdateEntityPropertiesRequest implements IReturn<any> {
  // @DataMember(IsRequired=true)
  public id: string;

  // @DataMember
  public description: string;

  // @DataMember
  public entity: { [index: string]: string };

  public constructor(init?: Partial<UpdateEntityPropertiesRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return {};
  }
  public getTypeName() {
    return 'UpdateEntityPropertiesRequest';
  }
}

// @Route("/entity/{id}/property/{property}", "GET")
// @DataContract
export class GetEntityPropertyRequest implements IReturn<string> {
  // @DataMember
  public property: string;

  // @DataMember(IsRequired=true)
  public id: string;

  public constructor(init?: Partial<GetEntityPropertyRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return '';
  }
  public getTypeName() {
    return 'GetEntityPropertyRequest';
  }
}

// @Route("/entity/{id}/property/{property}", "PUT")
// @DataContract
export class UpdateEntityPropertyRequest implements IReturn<string> {
  // @DataMember(IsRequired=true)
  public id: string;

  // @DataMember
  public description: string;

  // @DataMember
  public property: string;

  // @DataMember
  public value: string;

  public constructor(init?: Partial<UpdateEntityPropertyRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return '';
  }
  public getTypeName() {
    return 'UpdateEntityPropertyRequest';
  }
}

// @Route("/entities/property/{property}", "GET")
// @DataContract
export class GetEntitiesPropertyRequest implements IReturn<any> {
  // @DataMember
  public property: string;

  // @DataMember
  public filter: string;

  public constructor(init?: Partial<GetEntitiesPropertyRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return {};
  }
  public getTypeName() {
    return 'GetEntitiesPropertyRequest';
  }
}

// @Route("/customer/{customerId}/entity/{Id}/groups", "GET")
// @DataContract
export class GetItemEntityGroupsRequest
  implements IReturn<GetEntityGroupsResponse>, ICustomerItemRequest
{
  // @DataMember
  public id: string;

  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public entityGroups: string[];

  // @DataMember
  public itemsIds: string[];

  // @DataMember
  public onlyOwnedItems: boolean;

  // @DataMember
  public page: number;

  // @DataMember
  public items: number;

  // @DataMember
  public sort: string;

  // @DataMember
  public filter: string;

  // @DataMember
  public propertiesTest: { [index: string]: number };

  public constructor(init?: Partial<GetItemEntityGroupsRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new GetEntityGroupsResponse();
  }
  public getTypeName() {
    return 'GetItemEntityGroupsRequest';
  }
}

// @Route("/entities/remoteRepository/{repositoryId}/data", "GET")
// @DataContract
export class GetEntitiesRequest implements IReturn<GetEntitiesResponse> {
  // @DataMember
  public repositoryId: string;

  // @DataMember
  public modified?: string;

  // @DataMember
  public page: number;

  // @DataMember
  public items: number;

  // @DataMember
  public sort: string;

  // @DataMember
  public filter: string;

  // @DataMember
  public propertiesTest: { [index: string]: number };

  public constructor(init?: Partial<GetEntitiesRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new GetEntitiesResponse();
  }
  public getTypeName() {
    return 'GetEntitiesRequest';
  }
}

/**
 * Sign In
 */
// @Route("/auth", "OPTIONS,GET,POST,DELETE")
// @Route("/auth/{provider}", "OPTIONS,GET,POST,DELETE")
// @Api(Description="Sign In")
// @DataContract
export class Authenticate implements IReturn<AuthenticateResponse>, IPost {
  /**
   * AuthProvider, e.g. credentials
   */
  // @DataMember(Order=1)
  public provider: string;

  // @DataMember(Order=2)
  public state: string;

  // @DataMember(Order=3)
  public oauth_token: string;

  // @DataMember(Order=4)
  public oauth_verifier: string;

  // @DataMember(Order=5)
  public userName: string;

  // @DataMember(Order=6)
  public password: string;

  // @DataMember(Order=7)
  public rememberMe?: boolean;

  // @DataMember(Order=9)
  public errorView: string;

  // @DataMember(Order=10)
  public nonce: string;

  // @DataMember(Order=11)
  public uri: string;

  // @DataMember(Order=12)
  public response: string;

  // @DataMember(Order=13)
  public qop: string;

  // @DataMember(Order=14)
  public nc: string;

  // @DataMember(Order=15)
  public cnonce: string;

  // @DataMember(Order=17)
  public accessToken: string;

  // @DataMember(Order=18)
  public accessTokenSecret: string;

  // @DataMember(Order=19)
  public scope: string;

  // @DataMember(Order=20)
  public meta: { [index: string]: string };

  public constructor(init?: Partial<Authenticate>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new AuthenticateResponse();
  }
  public getTypeName() {
    return 'Authenticate';
  }
}
