import { Inject, Injectable } from "@angular/core";

import { Observable, of } from "rxjs";
import { map } from "rxjs/operators";

import {
  IDataSourceService,
  setPageParams,
  getPageReturn,
  IPageParams,
  IPageReturn,
} from "projects/msu-its-web-common/src/data/data-source";

import { FtvApiService } from "../ftv-api.service";

import { APP_ENVIRONMENT } from "projects/msu-its-web-common/src/utils/shared-consts";
import { TokenService } from "projects/msu-its-web-common/src/services/token.service";
import {
  CreateWeightControlPointDataRequest,
  DeleteWeightControlPointDataRequest,
  GetWeightControlPointDataListRequest,
  GetWeightControlPointDataRequest,
  UpdateWeightControlPointDataRequest,
  WeightControlPointData,
} from "../../dtos/ftv.dtos";

@Injectable()
export class WeightControlPointDataService
  implements
    IDataSourceService<WeightControlPointData, WeightControlPointData> {
  constructor(
    private _api: FtvApiService,
    private _tokenService: TokenService,
    @Inject(APP_ENVIRONMENT) private _appEnv
  ) {}

  getPagedList(
    params: IPageParams,
    requestParams: {
      customerId?: string;
      from?: string;
      to?: string;
    }
  ): Observable<IPageReturn<WeightControlPointData>> {
    if (!requestParams.customerId) {
      return of(null);
    }
    const request = new GetWeightControlPointDataListRequest();
    setPageParams(request, params);
    request.customerId = requestParams.customerId;
    request.startDate = requestParams?.from;
    request.endDate = requestParams?.to;
    return this._api.get(request).pipe(
      map((m) => {
        return getPageReturn(m);
      })
    );
  }
  getList(
    customerId: string,
    filter?: string,
    groupIds?: string[],
    items?: number
  ): Observable<WeightControlPointData[]> {
    const request = new GetWeightControlPointDataListRequest();
    request.customerId = customerId;
    request.entityGroups = groupIds || [];

    request.page = 0;
    request.items = items || 0;
    request.sort = "name";
    request.filter = filter || "";

    return this._api.get(request).pipe(
      map((m) => {
        return m.items;
      })
    );
  }

  get(id: string, customerId: string) {
    const request = new GetWeightControlPointDataRequest();
    request.id = id;
    request.customerId = customerId;
    return this._api.get(request);
  }

  add(
    model: WeightControlPointData,
    params: {
      customerId: string;
      groups: string[];
      updateGroups: boolean;
    }
  ) {
    const request = new CreateWeightControlPointDataRequest();
    request.entity = model;
    request.customerId = params.customerId;
    request.entityGroups = params.groups;
    request.updateEntityGroups = params.updateGroups;
    return this._api.post(request);
  }
  update(
    model: WeightControlPointData,
    params: { customerId: string; groups: string[]; updateGroups: boolean }
  ) {
    const request = new UpdateWeightControlPointDataRequest();
    request.id = model.id;
    request.entity = model;
    request.customerId = params.customerId;
    request.entityGroups = params.groups;
    request.updateEntityGroups = params.updateGroups;
    return this._api.put(request);
  }
  delete(ids: string[], customerId: string) {
    const request = new DeleteWeightControlPointDataRequest();
    request.ids = ids;
    request.customerId = customerId;
    return this._api.delete(request);
  }
}
