<div>
  <mat-form-field style="width: 100%; font-size: 14px" floatLabel="always">
    <mat-select
      #routeSelect
      (openedChange)="$event && searchInput.focus()"
      (selectionChange)="selectRoute($event.value)"
      [value]="selectedRoute?.id"
      placeholder="{{ 'COMMON.ROUTE' | translate }}"
      disableOptionCentering="true"
      panelClass="single-select-panel fs-14"
    >
      <mat-select-trigger>
        {{ selectedRoute?.name || "&nbsp;" }}
      </mat-select-trigger>
      <div (click)="$event.stopPropagation()" class="mat-theme-bg" style="position: sticky; top: 0; z-index: 2; padding: 12px 16px">
        <input
          #searchInput
          class="mat-input-element"
          [placeholder]="('COMMON.SEARCH' | translate) + '...'"
          autocomplete="off"
          spellcheck="false"
          (keyup)="routeSearchSubject.next($event.target.value)"
          (input)="routeSearchSubject.next($event.target.value)"
          (keydown)="$event.stopPropagation()"
          (click)="$event.stopPropagation()"
        />
      </div>
      <mat-option style="display: none"></mat-option>
      <mat-option *ngFor="let item of routeListSubject | async" [value]="item.id">
        <div style="line-height: 1">{{ item.name }}</div>
        <div style="line-height: 1; text-overflow: ellipsis; overflow: hidden" class="mat-hint">
          <small>{{ item.description }}</small>
        </div>
      </mat-option>
    </mat-select>
    <button
      *ngIf="selectedRoute"
      type="button"
      mat-icon-button
      class="mat-secondary-color"
      type="button"
      (click)="$event.stopPropagation(); selectRoute(null); routeSelect.value = null"
      style="position: absolute; right: 18px; width: 16px; height: 16px; line-height: 16px; top: 6px"
    >
      <mat-icon style="font-size: 14px; height: 16px; line-height: 16px; width: 16px">close</mat-icon>
    </button>
  </mat-form-field>

  <mat-form-field style="width: 100%; font-size: 14px" floatLabel="always">
    <mat-select
      #trackerSelect
      (openedChange)="$event && trackerSearchInput.focus()"
      (selectionChange)="selectTracker($event.value)"
      [value]="tracker?.id"
      placeholder="{{ 'ROUTE_ESCORT.TRACKER1' | translate }}"
      disableOptionCentering="true"
      panelClass="single-select-panel fs-14"
    >
      <mat-select-trigger>
        {{ tracker?.description || tracker?.fullNumber || "&nbsp;" }}
      </mat-select-trigger>
      <div (click)="$event.stopPropagation()" class="mat-theme-bg" style="position: sticky; top: 0; z-index: 2; padding: 12px 16px">
        <input
          #trackerSearchInput
          class="mat-input-element"
          [placeholder]="('COMMON.SEARCH' | translate) + '...'"
          autocomplete="off"
          spellcheck="false"
          (keyup)="trackerSearchSubject.next($event.target.value)"
          (input)="trackerSearchSubject.next($event.target.value)"
          (keydown)="$event.stopPropagation()"
          (click)="$event.stopPropagation()"
        />
      </div>
      <mat-option style="display: none"></mat-option>
      <mat-option *ngFor="let item of trackerListSubject | async" [value]="item.id">
        <div style="line-height: 1">{{ item.description }}</div>
        <div style="line-height: 1; text-overflow: ellipsis; overflow: hidden" class="mat-hint">
          <small>{{ item.fullNumber }}</small>
        </div>
      </mat-option>
    </mat-select>
    <button
      *ngIf="tracker"
      type="button"
      mat-icon-button
      class="mat-secondary-color"
      type="button"
      (click)="$event.stopPropagation(); selectTracker(null); trackerSelect.value = null"
      style="position: absolute; right: 18px; width: 16px; height: 16px; line-height: 16px; top: 6px"
    >
      <mat-icon style="font-size: 14px; height: 16px; line-height: 16px; width: 16px">close</mat-icon>
    </button>
  </mat-form-field>

  <mat-form-field style="width: 100%; font-size: 14px" floatLabel="always">
    <mat-select
      #tracker2Select
      (openedChange)="$event && tracker2SearchInput.focus()"
      (selectionChange)="selectTracker2($event.value)"
      [value]="tracker2?.id"
      placeholder="{{ 'ROUTE_ESCORT.TRACKER2' | translate }}"
      disableOptionCentering="true"
      panelClass="single-select-panel fs-14"
      [disabled]="!tracker"
    >
      <mat-select-trigger>
        {{ tracker2?.description || tracker2?.fullNumber || "&nbsp;" }}
      </mat-select-trigger>
      <div (click)="$event.stopPropagation()" class="mat-theme-bg" style="position: sticky; top: 0; z-index: 2; padding: 12px 16px">
        <input
          #tracker2SearchInput
          class="mat-input-element"
          [placeholder]="('COMMON.SEARCH' | translate) + '...'"
          autocomplete="off"
          spellcheck="false"
          (keyup)="tracker2SearchSubject.next($event.target.value)"
          (input)="tracker2SearchSubject.next($event.target.value)"
          (keydown)="$event.stopPropagation()"
          (click)="$event.stopPropagation()"
        />
      </div>
      <mat-option style="display: none"></mat-option>
      <mat-option *ngFor="let item of tracker2ListSubject | async" [value]="item.id">
        <div style="line-height: 1">{{ item.description }}</div>
        <div style="line-height: 1; text-overflow: ellipsis; overflow: hidden" class="mat-hint">
          <small>{{ item.fullNumber }}</small>
        </div>
      </mat-option>
    </mat-select>
    <button
      *ngIf="tracker2"
      type="button"
      mat-icon-button
      class="mat-secondary-color"
      type="button"
      (click)="$event.stopPropagation(); selectTracker2(null); tracker2Select.value = null"
      style="position: absolute; right: 18px; width: 16px; height: 16px; line-height: 16px; top: 6px"
      [disabled]="!tracker"
    >
      <mat-icon style="font-size: 14px; height: 16px; line-height: 16px; width: 16px">close</mat-icon>
    </button>
  </mat-form-field>

  <div fxLayout="row" fxLayoutGap="24px">
    <mat-form-field style="width: 120px; font-size: 14px" floatLabel="always">
      <input matInput [value]="detectionRadius" (change)="setDetectionRadius($event.target.value)" step="50" type="number" min="100" max="3000" />
      <mat-label>{{ "ROUTE_ESCORT.DETECTION_RADIUS" | translate }}</mat-label>
    </mat-form-field>
    <!-- <mat-form-field style="width: 120px; font-size: 14px" floatLabel="always">
      <input matInput [value]="suspendTime" (change)="setSuspendTime($event.target.value)" step="10" type="number" min="30" max="900" />
      <mat-label>{{ "ROUTE_ESCORT.SUSPEND_TIME" | translate }}</mat-label>
    </mat-form-field> -->
  </div>
</div>

<mat-divider *ngIf="selectedRoute?.settings.routeObjects?.length" style="margin: 0 -16px"></mat-divider>

<div style="margin: 0 -16px; overflow: auto; overflow: overlay; flex: 1">
  <mat-list style="padding: 0" cdkDropList1 (cdkDropListDropped)="drop($event)">
    <mat-list-item *ngFor="let item of selectedRoute?.settings.routeObjects; let i = index" style="height: 52px">
      <div
        fxLayout
        fxLayoutGap="8px"
        fxLayoutAlign="streight center"
        cdkDrag1
        cdkDragLockAxis1="y"
        class="mat-dialog-bg"
        style="font-size: 14px; width: 100%; height: 52px; padding-left: 12px; padding-right: 12px"
      >
        <!-- <mat-icon class="handler" cdkDragHandle1>more_vert</mat-icon> -->
        <span class="mat-border schemaState" style="box-sizing: border-box; padding: 0; border-radius: 3px !important">
          <object
            data="/assets/images/tlc/layout_button.svg"
            (load)="svgLoad($event.target, item, item.stepMovement)"
            style="width: 44px; height: 44px; margin: -3px 0 0 -3px; pointer-events: none; display: block"
            type="image/svg+xml"
            [style.transform]="'rotate(' + getAngle(item) + 'deg)'"
          ></object>
        </span>
        <span class="name" style="flex: 1" [title]="getName(item)">{{ i + 1 }}. {{ getName(item) }}</span>
        <!-- 
        <button mat-icon-button class="remove" (click)="remove(item)">
          <mat-icon class="mat-secondary-color">close</mat-icon>
        </button> 
        -->
      </div>
      <mat-divider></mat-divider>
    </mat-list-item>
  </mat-list>
</div>

<mat-divider *ngIf="selectedRoute?.settings.routeObjects?.length" style="margin: 0 -16px"></mat-divider>

<div style="padding: 12px 0">
  <button mat-flat-button style="width: 100%" color="primary" [disabled]="!selectedRoute || !tracker" (click)="start()">
    {{ "ROUTE_ESCORT.BEGIN_ESCORT" | translate }}
  </button>
</div>
