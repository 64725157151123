import { Component, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'alert-dialog',
  template: `
    <mat-dialog-content>
      <div style="display: flex;">
        <div class="dialog-{{ type }}">
          <mat-icon *ngIf="icon" style="margin-right: 10px;">
            {{ icon }}
          </mat-icon>
        </div>
        <div style="flex: 1; margin-top: 2px;" [innerHtml]="message"></div>
      </div>
    </mat-dialog-content>
    <mat-dialog-actions align="end">
      <button mat-button [mat-dialog-close]="{}">
        {{ 'COMMON.CLOSE' | translate }}
      </button>
    </mat-dialog-actions>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AlertDialogComponent {
  message: string;
  type: string;

  get icon(): string {
    switch (this.type) {
      case 'info':
        return 'info';
      case 'success':
        return 'check';
      case 'warn':
        return 'warning';
      case 'error':
        return 'error';
      default:
        return null;
    }
  }
}
