<div fxLayout="column" fxLayoutGap="12px" style="min-width: 450px; min-height: 400px; position: relative">
  <div *ngIf="loading" class="backdrop" style="position: absolute; z-index: 6">
    <div class="backdrop-inner mat-dialog-bg mat-elevation-z4" style="padding-bottom: 16px">
      <mat-spinner diameter="36"></mat-spinner>
    </div>
  </div>

  <div class="state-container">
    <img class="state-image" [src]="statusImage" />

    <div class="mat-elevation-z2" style="display: flex; flex: 1; padding: 2px 0px 2px 12px">
      <div style="flex: 1">
        <ng-container *ngIf="status">
          <div class="mat-small mat-secondary-color">{{ "COMMON.CONNECTION_TIME" | translate }}:</div>
          <div class="mat-body-1" style="line-height: normal; padding-bottom: 3px">
            {{ lastReceived | date: "dd.MM.yyyy HH:mm:ss":timezone:locale }}
          </div>
        </ng-container>
      </div>

      <div style="padding: 8px">
        <button mat-stroked-button (click)="setCustomMode('auto')" class="mode-btn" [class.mode-auto]="status?.customMode === 'auto'">
          {{ "PANEL_STAND.AUTO_MODE" | translate }}
        </button>
      </div>

      <button mat-icon-button [matMenuTriggerFor]="controllerMenu">
        <mat-icon>more_vert</mat-icon>
      </button>

      <mat-menu #controllerMenu="matMenu" overlapTrigger="false" class="mat-menu-md" xPosition="before">
        <!-- 
        <button [disabled]="!(['device.read'] | hasPermissions)" mat-menu-item (click)="openFiles()">
          {{ "COMMON.FILES" | translate }}
        </button>
        <button [disabled]="!(['device.read'] | hasPermissions)" mat-menu-item (click)="openAudit()">
          {{ "COMMON.AUDIT" | translate }}
        </button>
        <button [disabled]="!(['device.read'] | hasPermissions)" mat-menu-item (click)="openErrorLog()">
          {{ "COMMON.ERROR_LOG" | translate }}
        </button>
        <button [disabled]="!(['device.modify'] | hasPermissions)" mat-menu-item (click)="openEdit()">
          {{ "COMMON.EDIT" | translate }}
        </button>
        -->
        <!-- 
        <button mat-menu-item (click)="eventLog()">
          {{ "EVENT_LOG.TITLE" | translate }}
        </button>
        -->
        <button mat-menu-item (click)="disconnect()">
          {{ "DEVICES.SESSION_DISCONNECT" | translate }}
        </button>
        <button mat-menu-item (click)="controllerEventLog()">
          {{ "PANEL_CONTROLLER.CONTROLLER_LOG" | translate }}
        </button>
      </mat-menu>
    </div>
  </div>

  <div fxLayout="column" fxLayoutGap="12px">
    <panel-stand-state-panel *ngFor="let panel of status?.panels; trackBy: trackById" [status]="panel"></panel-stand-state-panel>
  </div>
</div>
