import { Injectable, Inject, Injector } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { APP_ENVIRONMENT } from '../utils/shared-consts';
import { BaseApiService } from './base-api.service';
import { FileItem, GetApplicationInstancesRequest } from '../dtos/hub.dtos';

@Injectable({ providedIn: 'root' })
export class HubApiService extends BaseApiService {
  constructor(injector: Injector, @Inject(APP_ENVIRONMENT) appEnv) {
    super(injector, appEnv.modules.hub.apiUrl, 'HUB');
  }

  public getSystemTimezoneOffset() {
    // const request = new GetSystemTimeZoneOffsetRequest();
    return (
      of(-24)
        // this.get(request)
        .pipe(catchError(() => of(-24))) // if -24 then get local system timezone
        .pipe(
          map((result: number) => {
            if (result === -24) {
              result = Math.round(new Date().getTimezoneOffset() / -60);
            }
            const plus = result >= 0;
            const time = String(Math.abs(result))
              .padStart(2, '0')
              .padEnd(4, '0');
            const timezone = (plus ? '+' : '-') + time;
            // console.log(timezone);
            return timezone;
          })
        )
    );
  }

  upload(file, entityId: string, description?: string): Observable<FileItem> {
    const url = `${this.apiUrl}/files/upload`;
    const formData = new FormData();
    formData.append('entityId', entityId);
    formData.append('name', encodeURI(file.name));
    formData.append('description', description || '');
    formData.append('file', file);
    return this._handleResult(
      fetch(url, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          Authorization: `Bearer ${this._tokenService.bearerToken}`,
        },
        mode: 'cors',
        body: formData,
      })
        .then((response) => Promise.all([response.ok, response.json()]))
        .then((result) => {
          if (result[0]) {
            return result[1];
          } else {
            throw result[1];
          }
        }),
      false
    );
  }

  getApplicationInfo() {
    const request = new GetApplicationInstancesRequest();
    return this.get(request);
  }
}
