import { Injectable } from "@angular/core";

import { Observable, of } from "rxjs";
import { map } from "rxjs/operators";

import {
  IDataSourceService,
  setPageParams,
  getPageReturn,
  IPageParams,
  IPageReturn,
} from "projects/msu-its-web-common/src/data/data-source";

import {
  FixationDevice,
  FixationEvent,
  FixationEventInfo,
  GetFixationDevicesRequest,
  GetFixationEventRequest,
  GetFixationEventsRequest,
  ViolationType,
} from "../../dtos/ftv.dtos";

import { FtvApiService } from "../ftv-api.service";

@Injectable()
export class FixationEventService
  implements IDataSourceService<FixationEvent, FixationEventInfo> {
  constructor(private _api: FtvApiService) {}

  getPagedList(
    params: IPageParams,
    requestParams: {
      customerId: string;
      fixationPointId?: string;
      violationType?: ViolationType;
      start?:string;
      end?:string;
    }
  ): Observable<IPageReturn<FixationEventInfo>> {
    if (!requestParams.customerId) {
      return of(null);
    }

    const request = new GetFixationEventsRequest();
    request.customerId = requestParams.customerId;
    request.fixationPointId = requestParams.fixationPointId || "";
    request.violationType = requestParams.violationType || null;
    request.startDate = requestParams.start || null;
    request.endDate = requestParams.end || null;
    setPageParams(request, params);
    return this._api.get(request).pipe(
      map((m) => {
        return getPageReturn(m);
      })
    );
  }
  getList(
    customerId: string,
    filter?: string,
    items?: number,
    groupIds?: string[],
    fixationPointId?: string
  ): Observable<FixationEventInfo[]> {
    const request = new GetFixationEventsRequest();
    request.customerId = customerId;
    request.entityGroups = groupIds || [];

    request.page = 0;
    request.items = items || 0;
    request.sort = "number";
    request.filter = filter || "";
    request.fixationPointId = fixationPointId || "";
    return this._api.get(request).pipe(
      map((m) => {
        return m.items;
      })
    );
  }

  get(id: string, customerId: string): Observable<FixationEvent> {
    const request = new GetFixationEventRequest();
    request.id = id;
    request.customerId = customerId;
    return this._api.get(request);
  }
  add(
    model: FixationEvent,
    params: {
      customerId: string;
      groups: string[];
      updateGroups: boolean;
    }
  ): Observable<FixationEvent> {
    // const request = new CreateFixationDeviceRequest();
    // request.entity = model;
    // request.customerId = params.customerId;
    // request.entityGroups = params.groups;
    // request.updateEntityGroups = params.updateGroups;
    // return this._api.post(request);
    throw "not implemented";
  }
  update(
    model: FixationEvent,
    params: { customerId: string; groups: string[]; updateGroups: boolean }
  ): Observable<FixationEvent> {
    // const request = new UpdateFixationDeviceRequest();
    // request.id = model.id;
    // request.entity = model;
    // request.customerId = params.customerId;
    // request.entityGroups = params.groups;
    // request.updateEntityGroups = params.updateGroups;
    // return this._api.put(request);
    throw "not implemented";
  }
  exist(number: string, customerId: string): Observable<boolean> {
    // const request = new GetFixationDeviceExistRequest({
    //   number,
    //   customerId,
    // });
    // return this._api.get(request);
    throw "not implemented";
  }

  delete(ids: string[]): Observable<number> {
    throw "not implemented";
  }
  // disconnect(id: string, customerId: string) {
  //   const request = new DisconnectParkingControllerRequest();
  //   request.id = id;
  //   request.customerId = customerId;
  //   return this._api.post(request);
  // }
}
