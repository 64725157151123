import { marker as _ } from "@biesbjerg/ngx-translate-extract-marker";

import { NgModule } from "@angular/core";
import { MatIconRegistry } from "@angular/material/icon";
import { DomSanitizer } from "@angular/platform-browser";
import { DatePipe } from "@angular/common";

import { PtmApiService } from "../../services/ptm-api.service";
import { PtmApiEventService } from "../../services/ptm-api-event.service";

import { CarriersService } from "../../services/carriers/carriers.service";

import { TerminalService } from "../../services/terminal/terminal.service";
import { TerminalEventLogService } from "../../services/terminal/terminal-event-log.service";

import { BusStopService } from "../../services/bus-stop/bus-stop.service";
import { BusStopGroupService } from "../../services/bus-stop/bus-stop-group.service";
import { BusStopGroupShareService } from "../../services/bus-stop/bus-stop-group-share.service";
// import { BusStopGroupEventService } from "../../services/bus-stop/bus-stop-group-event.service";

import { RouteService } from "../../services/route/route.service";
import { RouteGroupService } from "../../services/route/route-group.service";
import { RouteGroupShareService } from "../../services/route/route-group-share.service";
import { ScheduleService } from "../../services/route/schedule.service";
// import { BusStopGroupEventService } from "../../services/bus-stop/bus-stop-group-event.service";

import { VehicleService } from "../../services/vehicle/vehicle.service";
import { VehicleWaybillService } from "../../services/vehicle/vehicle-waybill.service";
import { VehicleEventsService } from "../../services/adaptive-mode/vehicle-events.service";
import { AdaptiveModeService } from "../../services/adaptive-mode/adaptive-mode.service";
import { VehicleForGroupService } from "../../services/vehicle/vehicle-for-group.service";
import { VehicleGroupService } from "../../services/vehicle/vehicle-group.service";
import { VehicleGroupShareService } from "../../services/vehicle/vehicle-group-share.service";
import { VehicleGroupEventService } from "../../services/vehicle/vehicle-group-event.service";

import { ISidenavMenuCategory } from "projects/msu-its-web-common/src/utils/sidenav-menu";
import { IGisObjectGroup } from "projects/msu-its-web-common/src/utils/gis-object-group";
import {
  GIS_OBJECT_GROUP,
  SIDENAV_MENU,
} from "projects/msu-its-web-common/src/utils/shared-consts";

import { BaseModule } from "projects/msu-its-web-common/src/modules/base.module";

const sidenav: ISidenavMenuCategory[] = [
  {
    name: _("SIDENAV_MENU.PTM"),
    items: [
      // {
      //   name: _("PUBLIC_TRANSPORT_CARRIER.TITLE"),
      //   icon: "business_center",
      //   route: "public-transport-carriers",
      //   permissions: ["publicTransportCarrier.read"],
      // },
      {
        name: _("PUBLIC_TRANSPORT_VEHICLE.TITLE"),
        icon: "directions_bus",
        route: "public-transport-vehicle",
        permissions: ["publicTransportVehicle.read"],
      },
      {
        name: _("PUBLIC_TRANSPORT_ROUTES.TITLE"),
        icon: "timeline",
        route: "public-transport-routes",
        permissions: ["publicTransportRoute.read"],
      },
      // {
      //   name: _("PUBLIC_TRANSPORT_BUS_STOP.TITLE"),
      //   icon: "font_download",
      //   route: "public-transport-stop",
      //   permissions: ["publicTransportStop.read"],
      // },
      {
        name: _("PUBLIC_TRANSPORT_GLONASS_TERMINAL.TITLE"),
        icon: "pin_drop",
        route: "public-transport-terminal",
        permissions: ["publicTransportGlonassTerminal.read"],
      },
      {
        name: _("PUBLIC_TRANSPORT_ADAPTIVE_MODE.TITLE"),
        icon: "settings",
        route: "public-transport-adaptive-mode",
        permissions: ["publicTransportPrioritySettings.read"],
      },
      {
        name: _("PUBLIC_TRANSPORT_ADAPTIVE_MODE.JOURNAL_ADAPTIVE_TITLE"),
        icon: "perm_contact_calendar",
        route: "public-transport-adaptive-mode-event",
        permissions: ['publicTransportPriorityEvent.read'],
      },
    ],
  },
];

const publicTransportVehicleObjectGroup: IGisObjectGroup = {
  type: 'publicTransportVehicle',
  name: _('PUBLIC_TRANSPORT.TITLE'),
  groupServiceType: VehicleGroupService,
  eventServiceType: VehicleGroupEventService,
  // stateComponentType: TransportStateComponent,
};

@NgModule({
  providers: [
    { provide: SIDENAV_MENU, useValue: sidenav, multi: true },
    { provide: GIS_OBJECT_GROUP, useValue: publicTransportVehicleObjectGroup, multi: true },

    PtmApiService,
    PtmApiEventService,

    CarriersService,

    BusStopService,
    BusStopGroupService,
    BusStopGroupShareService,

    RouteService,
    RouteGroupService,
    RouteGroupShareService,
    ScheduleService,

    VehicleService,
    VehicleWaybillService,
    VehicleEventsService,
    VehicleForGroupService,
    VehicleGroupService,
    VehicleGroupEventService,
    VehicleGroupShareService,

    TerminalService,
    TerminalEventLogService,

    AdaptiveModeService,

    DatePipe,
  ],
  imports: [BaseModule],
  declarations: [],
  entryComponents: [],
  exports: [],
})
export class PtmSharedModule {
  constructor(
    private _matIconRegistry: MatIconRegistry,
    private _domSanitizer: DomSanitizer
  ) {
    // this._registerIcon('tlc-route', '/assets/images/ptm/icons/route.svg');
    // this._registerIcon('tlc-route-auto', '/assets/images/ptm/icons/route-auto.svg');
  }

  // private _registerIcon(name: string, url: string) {
  //   this._matIconRegistry.addSvgIcon(name, this._domSanitizer.bypassSecurityTrustResourceUrl(url));
  // }
}
