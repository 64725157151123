import { Component, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'table-header',
  templateUrl: './table-header.component.html',
  styles: [
    `
      :host {
        display: flex;
        align-items: center;
        justify-content: space-between;
        box-sizing: border-box;
        padding-bottom: 12px;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TableHeaderComponent {}
