/* Options:
Date: 2023-08-17 08:15:10
Version: 6.70
Tip: To override a DTO option, remove "//" prefix before updating
BaseUrl: https://ruis.dev.its.msu24.ru

//GlobalNamespace: 
//MakePropertiesOptional: False
//AddServiceStackTypes: True
//AddResponseStatus: False
//AddImplicitVersion: 
//AddDescriptionAsComments: True
//IncludeTypes: 
//ExcludeTypes: 
//DefaultImports: 
*/

export interface IReturn<T> {
  createResponse(): T;
}

export interface IReturnVoid {
  createResponse(): void;
}

export interface IHasSessionId {
  sessionId: string;
}

export interface IHasBearerToken {
  bearerToken: string;
}

export interface IPost {}

// @DataContract
export class PageRequest {
  // @DataMember
  public page: number;

  // @DataMember
  public items: number;

  // @DataMember
  public sort: string;

  // @DataMember
  public filter: string;

  // @DataMember
  public propertiesTest: { [index: string]: number };

  public constructor(init?: Partial<PageRequest>) {
    (Object as any).assign(this, init);
  }
}

export interface ICustomerItemRequest extends ICustomerEntity {}

export interface ICustomerEntity {
  // @DataMember
  customerId: string;
}

export interface IBaseEntityItemRequest {
  id: string;
}

export interface IDescription {
  // @DataMember
  description: string;
}

export interface IRequestWithEntity {}

export class DeleteCustomerItemsRequest implements ICustomerItemRequest, IDescription {
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public description: string;

  // @DataMember(IsRequired=true)
  public ids: string[];

  public constructor(init?: Partial<DeleteCustomerItemsRequest>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class EntityEventLogDetails {
  // @DataMember
  public id: number;

  // @DataMember
  public eventTime: string;

  // @DataMember
  public eventId: number;

  // @DataMember
  public message: string;

  // @DataMember
  public data: string;

  public constructor(init?: Partial<EntityEventLogDetails>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class GetEntityGroupsRequestBase implements ICustomerItemRequest {
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public entityGroups: string[];

  // @DataMember
  public itemsIds: string[];

  // @DataMember
  public onlyOwnedItems: boolean;

  // @DataMember
  public page: number;

  // @DataMember
  public items: number;

  // @DataMember
  public sort: string;

  // @DataMember
  public filter: string;

  // @DataMember
  public propertiesTest: { [index: string]: number };

  public constructor(init?: Partial<GetEntityGroupsRequestBase>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class GetEntityGroupRequestBase implements ICustomerItemRequest, IBaseEntityItemRequest {
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember(IsRequired=true)
  public id: string;

  public constructor(init?: Partial<GetEntityGroupRequestBase>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class EntityGroup
  implements ICustomerEntityViewModel, INamedEntity, IChangeKey, IDescription, IModified, IEntity
{
  // @DataMember
  public items: string[];

  // @DataMember
  public customerId: string;

  // @DataMember
  public customerName: string;

  // @DataMember
  public name: string;

  // @DataMember
  public description: string;

  // @DataMember
  public changeKey: string;

  // @DataMember
  public created: string;

  // @DataMember
  public createdBy: string;

  // @DataMember
  public modified: string;

  // @DataMember
  public modifiedBy: string;

  // @DataMember
  public id: string;

  // @DataMember
  public entityType: string;

  public constructor(init?: Partial<EntityGroup>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class CreateEntityGroupRequestBase implements ICustomerItemRequest, IDescription {
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public updateEntityGroups: boolean;

  // @DataMember
  public entityGroups: string[];

  // @DataMember(IsRequired=true)
  public entity: EntityGroup;

  // @DataMember
  public description: string;

  public constructor(init?: Partial<CreateEntityGroupRequestBase>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class UpdateEntityGroupRequestBase
  implements ICustomerItemRequest, IBaseEntityItemRequest, IDescription
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public updateEntityGroups: boolean;

  // @DataMember
  public entityGroups: string[];

  // @DataMember(IsRequired=true)
  public id: string;

  // @DataMember(IsRequired=true)
  public entity: EntityGroup;

  // @DataMember
  public description: string;

  public constructor(init?: Partial<UpdateEntityGroupRequestBase>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class DeleteEntityGroupsRequestBase implements ICustomerItemRequest, IDescription {
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public description: string;

  // @DataMember(IsRequired=true)
  public ids: string[];

  public constructor(init?: Partial<DeleteEntityGroupsRequestBase>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class GetEntityGroupSharesRequestBase
  implements IBaseEntityItemRequest, ICustomerItemRequest
{
  // @DataMember
  public id: string;

  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public entityGroups: string[];

  // @DataMember
  public itemsIds: string[];

  // @DataMember
  public onlyOwnedItems: boolean;

  // @DataMember
  public page: number;

  // @DataMember
  public items: number;

  // @DataMember
  public sort: string;

  // @DataMember
  public filter: string;

  // @DataMember
  public propertiesTest: { [index: string]: number };

  public constructor(init?: Partial<GetEntityGroupSharesRequestBase>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class EntityGroupPermissions implements IEntityTyped, ICustomerEntityViewModel {
  // @DataMember
  public customerId: string;

  // @DataMember
  public customerName: string;

  // @DataMember
  public entityGroupId: string;

  // @DataMember
  public permissions: string[];

  // @DataMember
  public entityType: string;

  public constructor(init?: Partial<EntityGroupPermissions>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class UpgradeEntityGroupShareRequestBase implements ICustomerItemRequest, IDescription {
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember(IsRequired=true)
  public id: string;

  // @DataMember(IsRequired=true)
  public entity: EntityGroupPermissions;

  // @DataMember
  public description: string;

  public constructor(init?: Partial<UpgradeEntityGroupShareRequestBase>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class PanelProgramParameter {
  // @DataMember
  public id: string;

  // @DataMember
  public value: string;

  // @DataMember
  public description: string;

  public constructor(init?: Partial<PanelProgramParameter>) {
    (Object as any).assign(this, init);
  }
}

export interface ICustomerIntegrationIdRequest {
  customerIntegrationId: string;
}

export interface IChangeKey extends IReadonlyChangeKey {
  // @DataMember
  changeKey: string;
}

export interface IReadonlyChangeKey {
  // @DataMember
  changeKey: string;
}

export class CustomerNamedEntityViewModelBase
  implements ICustomerEntityViewModel, INamedEntity, IEntity
{
  // @DataMember
  public customerId: string;

  // @DataMember
  public customerName: string;

  // @DataMember
  public name: string;

  // @DataMember
  public id: string;

  // @DataMember
  public entityType: string;

  public constructor(init?: Partial<CustomerNamedEntityViewModelBase>) {
    (Object as any).assign(this, init);
  }
}

export interface IEntity {}

export interface IEntityTyped {
  // @DataMember
  entityType: string;
}

export interface INamedEntity extends IEntity, INamed {}

export interface INamed {
  // @DataMember
  name: string;
}

export interface ICustomerEntityViewModel extends ICustomerName, ICustomerEntity {}

export interface ICustomerName {
  // @DataMember
  customerName: string;
}

// @DataContract
export enum EntityEventLogLevel {
  Trace = 'Trace',
  Debug = 'Debug',
  Info = 'Info',
  Warn = 'Warn',
  Error = 'Error',
  Fatal = 'Fatal',
}

// @DataContract
export class EntityEventLogType {
  // @DataMember
  public id: number;

  // @DataMember
  public name: string;

  // @DataMember
  public level: EntityEventLogLevel;

  public constructor(init?: Partial<EntityEventLogType>) {
    (Object as any).assign(this, init);
  }
}

export enum PanelControllerType {
  None = 'None',
  Mayak = 'Mayak',
}

export class CustomerDeviceViewModelBase
  implements
    ICustomerEntity,
    ICustomerName,
    IDeviceItem,
    IOwnedDeviceItem,
    IChangeKey,
    IDescription,
    IModified,
    IEntity
{
  // @DataMember
  public customerId: string;

  // @DataMember
  public customerName: string;

  // @DataMember
  public ownerObjectName: string;

  // @DataMember
  public ownerObjectId: string;

  // @DataMember
  public prefix: string;

  // @DataMember
  public number: string;

  // @DataMember
  public phone1: string;

  // @DataMember
  public phone2: string;

  // @DataMember
  public ip1: string;

  // @DataMember
  public ip2: string;

  // @DataMember
  public imei: string;

  // @DataMember
  public deviceSettings: string;

  // @DataMember
  public lat: number;

  // @DataMember
  public lon: number;

  // @DataMember
  public direction: number;

  // @DataMember
  public fullNumber: string;

  // @DataMember
  public softwareVersion: string;

  // @DataMember
  public firmwareVersion: string;

  // @DataMember
  public hardwareVersion: string;

  // @DataMember
  public description: string;

  // @DataMember
  public changeKey: string;

  // @DataMember
  public created: string;

  // @DataMember
  public createdBy: string;

  // @DataMember
  public modified: string;

  // @DataMember
  public modifiedBy: string;

  // @DataMember
  public id: string;

  // @DataMember
  public entityType: string;

  public constructor(init?: Partial<CustomerDeviceViewModelBase>) {
    (Object as any).assign(this, init);
  }
}

export interface IModified {
  // @DataMember
  modified: string;

  // @DataMember
  modifiedBy: string;
}

export interface IDeviceItem extends IDeviceVersion {
  // @DataMember
  prefix: string;

  // @DataMember
  number: string;

  // @DataMember
  phone1: string;

  // @DataMember
  phone2: string;

  // @DataMember
  ip1: string;

  // @DataMember
  ip2: string;

  // @DataMember
  imei: string;

  // @DataMember
  lat: number;

  // @DataMember
  lon: number;

  // @DataMember
  direction: number;
}

export interface IDeviceVersion {
  // @DataMember
  softwareVersion: string;

  // @DataMember
  firmwareVersion: string;

  // @DataMember
  hardwareVersion: string;
}

export interface IOwnedDeviceItem {
  // @DataMember
  ownerObjectName: string;

  // @DataMember
  ownerObjectId: string;
}

// @DataContract
export class EntityGroupInfo
  implements ICustomerEntityViewModel, INamedEntity, IChangeKey, IDescription, IModified, IEntity
{
  // @DataMember
  public itemsCount: number;

  // @DataMember
  public customerId: string;

  // @DataMember
  public customerName: string;

  // @DataMember
  public name: string;

  // @DataMember
  public description: string;

  // @DataMember
  public changeKey: string;

  // @DataMember
  public created: string;

  // @DataMember
  public createdBy: string;

  // @DataMember
  public modified: string;

  // @DataMember
  public modifiedBy: string;

  // @DataMember
  public id: string;

  // @DataMember
  public entityType: string;

  public constructor(init?: Partial<EntityGroupInfo>) {
    (Object as any).assign(this, init);
  }
}

export class CustomerNamedBaseEntityViewModelBase
  implements ICustomerEntityViewModel, INamedEntity, IChangeKey, IDescription, IModified, IEntity
{
  // @DataMember
  public customerId: string;

  // @DataMember
  public customerName: string;

  // @DataMember
  public name: string;

  // @DataMember
  public description: string;

  // @DataMember
  public changeKey: string;

  // @DataMember
  public created: string;

  // @DataMember
  public createdBy: string;

  // @DataMember
  public modified: string;

  // @DataMember
  public modifiedBy: string;

  // @DataMember
  public id: string;

  // @DataMember
  public entityType: string;

  public constructor(init?: Partial<CustomerNamedBaseEntityViewModelBase>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class PanelStandIconStatus {
  // @DataMember
  public color: string;

  // @DataMember
  public borderColor: string;

  // @DataMember
  public processing: boolean;

  public constructor(init?: Partial<PanelStandIconStatus>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class PanelControllerStatus
  implements
    IDeviceConnectionInfo,
    ICustomerEntityViewModel,
    IChangeKey,
    IDescription,
    IModified,
    IEntity
{
  // @DataMember
  public number: string;

  // @DataMember
  public serverTime: string;

  // @DataMember
  public connectionTime?: string;

  // @DataMember
  public lastReceived?: string;

  // @DataMember
  public lastSent?: string;

  // @DataMember
  public deviceAddress: string;

  // @DataMember
  public online: boolean;

  // @DataMember
  public customerId: string;

  // @DataMember
  public customerName: string;

  // @DataMember
  public description: string;

  // @DataMember
  public changeKey: string;

  // @DataMember
  public created: string;

  // @DataMember
  public createdBy: string;

  // @DataMember
  public modified: string;

  // @DataMember
  public modifiedBy: string;

  // @DataMember
  public id: string;

  // @DataMember
  public entityType: string;

  public constructor(init?: Partial<PanelControllerStatus>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export enum PanelType {
  Common = 'Common',
  Sign = 'Sign',
  Info = 'Info',
}

// @DataContract
export class PanelsStatistic {
  // @DataMember
  public panelType: PanelType;

  // @DataMember
  public total: number;

  // @DataMember
  public online: number;

  // @DataMember
  public errors: number;

  public constructor(init?: Partial<PanelsStatistic>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class PanelStandsStatistic {
  // @DataMember
  public total: number;

  // @DataMember
  public online: number;

  // @DataMember
  public errors: number;

  public constructor(init?: Partial<PanelStandsStatistic>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class PanelProgramFrame {
  // @DataMember
  public id: string;

  // @DataMember
  public duration: number;

  // @DataMember
  public contentType: string;

  // @DataMember
  public contentBase64: string;

  public constructor(init?: Partial<PanelProgramFrame>) {
    (Object as any).assign(this, init);
  }
}

export class PanelProgram implements IEnabled, INamed, IDescription {
  // @DataMember
  public id: string;

  // @DataMember
  public index: number;

  // @DataMember
  public name: string;

  // @DataMember
  public frames: PanelProgramFrame[];

  // @DataMember
  public heightPx: number;

  // @DataMember
  public widthPx: number;

  // @DataMember
  public parameters: PanelProgramParameter[];

  // @DataMember
  public enabled: boolean;

  // @DataMember
  public description: string;

  public constructor(init?: Partial<PanelProgram>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class PanelPrograms {
  // @DataMember
  public program01: PanelProgram;

  // @DataMember
  public program02: PanelProgram;

  // @DataMember
  public program03: PanelProgram;

  // @DataMember
  public program04: PanelProgram;

  // @DataMember
  public program05: PanelProgram;

  // @DataMember
  public program06: PanelProgram;

  // @DataMember
  public program07: PanelProgram;

  // @DataMember
  public program08: PanelProgram;

  // @DataMember
  public program09: PanelProgram;

  // @DataMember
  public program10: PanelProgram;

  // @DataMember
  public program11: PanelProgram;

  // @DataMember
  public program12: PanelProgram;

  // @DataMember
  public program13: PanelProgram;

  // @DataMember
  public program14: PanelProgram;

  // @DataMember
  public program15: PanelProgram;

  // @DataMember
  public program16: PanelProgram;

  // @DataMember
  public program17: PanelProgram;

  // @DataMember
  public program18: PanelProgram;

  // @DataMember
  public program19: PanelProgram;

  // @DataMember
  public program20: PanelProgram;

  // @DataMember
  public program21: PanelProgram;

  // @DataMember
  public program22: PanelProgram;

  // @DataMember
  public program23: PanelProgram;

  // @DataMember
  public program24: PanelProgram;

  public constructor(init?: Partial<PanelPrograms>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class PanelScheduleWeek {
  // @DataMember(Order=0)
  public id: number;

  // @DataMember(Order=1)
  public monday: number;

  // @DataMember(Order=2)
  public tuesday: number;

  // @DataMember(Order=3)
  public wednesday: number;

  // @DataMember(Order=4)
  public thursday: number;

  // @DataMember(Order=5)
  public friday: number;

  // @DataMember(Order=6)
  public saturday: number;

  // @DataMember(Order=7)
  public sunday: number;

  public constructor(init?: Partial<PanelScheduleWeek>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class PanelScheduleDayTime {
  // @DataMember(Order=1)
  public programIndex?: number;

  // @DataMember(Order=2)
  public programName: string;

  // @DataMember(Order=3)
  public time: string;

  public constructor(init?: Partial<PanelScheduleDayTime>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class PanelScheduleDay {
  // @DataMember(Order=1)
  public id: number;

  // @DataMember(Order=2)
  public times: PanelScheduleDayTime[];

  public constructor(init?: Partial<PanelScheduleDay>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class PanelSchedule implements IEnabled, INamed, IDescription {
  // @DataMember
  public id: string;

  // @DataMember
  public name: string;

  // @DataMember
  public week: PanelScheduleWeek;

  // @DataMember
  public days: PanelScheduleDay[];

  // @DataMember
  public enabled: boolean;

  // @DataMember
  public description: string;

  // @DataMember
  public index: number;

  public constructor(init?: Partial<PanelSchedule>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class PanelSchedules {
  // @DataMember
  public schedule01: PanelSchedule;

  // @DataMember
  public schedule02: PanelSchedule;

  // @DataMember
  public schedule03: PanelSchedule;

  // @DataMember
  public schedule04: PanelSchedule;

  // @DataMember
  public schedule05: PanelSchedule;

  // @DataMember
  public schedule06: PanelSchedule;

  // @DataMember
  public schedule07: PanelSchedule;

  // @DataMember
  public schedule08: PanelSchedule;

  // @DataMember
  public schedule09: PanelSchedule;

  // @DataMember
  public schedule10: PanelSchedule;

  // @DataMember
  public schedule11: PanelSchedule;

  // @DataMember
  public schedule12: PanelSchedule;

  // @DataMember
  public schedule13: PanelSchedule;

  // @DataMember
  public schedule14: PanelSchedule;

  // @DataMember
  public schedule15: PanelSchedule;

  // @DataMember
  public schedule16: PanelSchedule;

  // @DataMember
  public schedule17: PanelSchedule;

  // @DataMember
  public schedule18: PanelSchedule;

  // @DataMember
  public schedule19: PanelSchedule;

  // @DataMember
  public schedule20: PanelSchedule;

  // @DataMember
  public schedule21: PanelSchedule;

  // @DataMember
  public schedule22: PanelSchedule;

  // @DataMember
  public schedule23: PanelSchedule;

  // @DataMember
  public schedule24: PanelSchedule;

  public constructor(init?: Partial<PanelSchedules>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class PanelAvailableSchedule implements INamed, IDescription {
  // @DataMember
  public id: string;

  // @DataMember
  public name: string;

  // @DataMember
  public description: string;

  // @DataMember
  public index: number;

  /**
   * Признак что расписание было последним выбранным
   */
  // @DataMember
  // @ApiMember(Description="Признак что расписание было последним выбранным")
  public lastSelected: boolean;

  /**
   * Список динамических параметров из всех задействованных программ расписания, необходимые для запуска
   */
  // @DataMember
  // @ApiMember(Description="Список динамических параметров из всех задействованных программ расписания, необходимые для запуска")
  public parameters: PanelProgramParameter[];

  public constructor(init?: Partial<PanelAvailableSchedule>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export enum PanelMode {
  Off = 'Off',
  Work = 'Work',
  Remote = 'Remote',
  Engineer = 'Engineer',
  Diagnostic = 'Diagnostic',
}

// @DataContract
export class PanelProgramInfo {
  // @DataMember
  public id: string;

  // @DataMember
  public name: string;

  // @DataMember
  public description: string;

  public constructor(init?: Partial<PanelProgramInfo>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class PanelScheduleInfo {
  // @DataMember
  public id: string;

  // @DataMember
  public name: string;

  // @DataMember
  public description: string;

  public constructor(init?: Partial<PanelScheduleInfo>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export enum PanelBrightnessMode {
  Static = 'Static',
  SunPhase = 'SunPhase',
  Schedule = 'Schedule',
}

export interface IDeviceConnectionInfo {
  /**
   * Время последнего соединения
   */
  // @DataMember
  // @ApiMember(Description="Время последнего соединения")
  connectionTime?: string;

  /**
   * Время последнего полученного пакета
   */
  // @DataMember
  // @ApiMember(Description="Время последнего полученного пакета")
  lastReceived?: string;

  /**
   * Время последнего отправленного пакета
   */
  // @DataMember
  // @ApiMember(Description="Время последнего отправленного пакета")
  lastSent?: string;

  /**
   * Адрес устройства
   */
  // @DataMember
  // @ApiMember(Description="Адрес устройства")
  deviceAddress: string;
}

export interface IEnabled {
  enabled: boolean;
}

// @DataContract
export class BaseEntityViewModelBase implements IChangeKey, IDescription, IModified, IEntity {
  // @DataMember
  public description: string;

  // @DataMember
  public changeKey: string;

  // @DataMember
  public created: string;

  // @DataMember
  public createdBy: string;

  // @DataMember
  public modified: string;

  // @DataMember
  public modifiedBy: string;

  // @DataMember
  public id: string;

  // @DataMember
  public entityType: string;

  public constructor(init?: Partial<BaseEntityViewModelBase>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class ResponseError {
  // @DataMember(Order=1)
  public errorCode: string;

  // @DataMember(Order=2)
  public fieldName: string;

  // @DataMember(Order=3)
  public message: string;

  // @DataMember(Order=4)
  public meta: { [index: string]: string };

  public constructor(init?: Partial<ResponseError>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class ResponseStatus {
  // @DataMember(Order=1)
  public errorCode: string;

  // @DataMember(Order=2)
  public message: string;

  // @DataMember(Order=3)
  public stackTrace: string;

  // @DataMember(Order=4)
  public errors: ResponseError[];

  // @DataMember(Order=5)
  public meta: { [index: string]: string };

  public constructor(init?: Partial<ResponseStatus>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class DeleteItemsRequest implements IDescription {
  // @DataMember
  public description: string;

  // @DataMember(IsRequired=true)
  public ids: string[];

  public constructor(init?: Partial<DeleteItemsRequest>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class NamedEntityViewModelBase implements INamedEntity, IEntity {
  // @DataMember
  public name: string;

  // @DataMember
  public id: string;

  // @DataMember
  public entityType: string;

  public constructor(init?: Partial<NamedEntityViewModelBase>) {
    (Object as any).assign(this, init);
  }
}

export class DeviceViewModelBase
  implements IDeviceItem, IOwnedDeviceItem, IChangeKey, IDescription, IModified, IEntity
{
  // @DataMember
  public ownerObjectName: string;

  // @DataMember
  public ownerObjectId: string;

  // @DataMember
  public prefix: string;

  // @DataMember
  public number: string;

  // @DataMember
  public phone1: string;

  // @DataMember
  public phone2: string;

  // @DataMember
  public ip1: string;

  // @DataMember
  public ip2: string;

  // @DataMember
  public imei: string;

  // @DataMember
  public deviceSettings: string;

  // @DataMember
  public lat: number;

  // @DataMember
  public lon: number;

  // @DataMember
  public direction: number;

  // @DataMember
  public fullNumber: string;

  // @DataMember
  public softwareVersion: string;

  // @DataMember
  public firmwareVersion: string;

  // @DataMember
  public hardwareVersion: string;

  // @DataMember
  public description: string;

  // @DataMember
  public changeKey: string;

  // @DataMember
  public created: string;

  // @DataMember
  public createdBy: string;

  // @DataMember
  public modified: string;

  // @DataMember
  public modifiedBy: string;

  // @DataMember
  public id: string;

  // @DataMember
  public entityType: string;

  public constructor(init?: Partial<DeviceViewModelBase>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class NamedBaseEntityViewModelBase
  implements INamedEntity, IChangeKey, IDescription, IModified, IEntity
{
  // @DataMember
  public name: string;

  // @DataMember
  public description: string;

  // @DataMember
  public changeKey: string;

  // @DataMember
  public created: string;

  // @DataMember
  public createdBy: string;

  // @DataMember
  public modified: string;

  // @DataMember
  public modifiedBy: string;

  // @DataMember
  public id: string;

  // @DataMember
  public entityType: string;

  public constructor(init?: Partial<NamedBaseEntityViewModelBase>) {
    (Object as any).assign(this, init);
  }
}

export class CustomerBaseEntityViewModelBase
  implements ICustomerEntityViewModel, IChangeKey, IDescription, IModified, IEntity
{
  // @DataMember
  public customerId: string;

  // @DataMember
  public customerName: string;

  // @DataMember
  public description: string;

  // @DataMember
  public changeKey: string;

  // @DataMember
  public created: string;

  // @DataMember
  public createdBy: string;

  // @DataMember
  public modified: string;

  // @DataMember
  public modifiedBy: string;

  // @DataMember
  public id: string;

  // @DataMember
  public entityType: string;

  public constructor(init?: Partial<CustomerBaseEntityViewModelBase>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class EntityViewModelBase implements IEntity {
  // @DataMember
  public id: string;

  // @DataMember
  public entityType: string;

  public constructor(init?: Partial<EntityViewModelBase>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class PageRequestBase {
  // @DataMember
  public page: number;

  // @DataMember
  public items: number;

  // @DataMember
  public sort: string;

  // @DataMember
  public filter: string;

  // @DataMember
  public propertiesTest: { [index: string]: number };

  public constructor(init?: Partial<PageRequestBase>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class FrameTemplate implements ICustomerEntityViewModel, INamedEntity, IEntity {
  // @DataMember
  public libraryId: string;

  // @DataMember
  public contentType: string;

  // @DataMember
  public contentBase64: string;

  // @DataMember
  public customerId: string;

  // @DataMember
  public customerName: string;

  // @DataMember
  public name: string;

  // @DataMember
  public id: string;

  // @DataMember
  public entityType: string;

  public constructor(init?: Partial<FrameTemplate>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class GetFrameTemplatesResponse implements IChangeKey {
  // @DataMember
  public items: FrameTemplate[];

  // @DataMember
  public total: number;

  // @DataMember
  public page: number;

  // @DataMember
  public itemsPerPage: number;

  // @DataMember
  public changeKey: string;

  public constructor(init?: Partial<GetFrameTemplatesResponse>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class FrameTemplateLibrary implements ICustomerEntityViewModel, INamedEntity, IEntity {
  /**
   * Библиотека доступна всем компаниям
   */
  // @DataMember
  // @ApiMember(Description="Библиотека доступна всем компаниям")
  public shared: boolean;

  // @DataMember
  public customerId: string;

  // @DataMember
  public customerName: string;

  // @DataMember
  public name: string;

  // @DataMember
  public id: string;

  // @DataMember
  public entityType: string;

  public constructor(init?: Partial<FrameTemplateLibrary>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class GetFrameTemplateLibrariesResponse implements IChangeKey {
  // @DataMember
  public items: FrameTemplateLibrary[];

  // @DataMember
  public total: number;

  // @DataMember
  public page: number;

  // @DataMember
  public itemsPerPage: number;

  // @DataMember
  public changeKey: string;

  public constructor(init?: Partial<GetFrameTemplateLibrariesResponse>) {
    (Object as any).assign(this, init);
  }
}

export class GetDeviceEventLogResponse implements IChangeKey {
  // @DataMember
  public items: EntityEventLogDetails[];

  // @DataMember
  public total: number;

  // @DataMember
  public page: number;

  // @DataMember
  public itemsPerPage: number;

  // @DataMember
  public changeKey: string;

  public constructor(init?: Partial<GetDeviceEventLogResponse>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class SessionInfo {
  // @DataMember
  public id: string;

  // @DataMember
  public lastReceived: string;

  // @DataMember
  public lastSent: string;

  // @DataMember
  public deviceNumber: string;

  // @DataMember
  public sessionId: string;

  // @DataMember
  public connectionTime: string;

  // @DataMember
  public receiverHost: string;

  // @DataMember
  public remoteAddress: string;

  // @DataMember
  public connected: boolean;

  // @DataMember
  public lastUpdated: string;

  // @DataMember
  public entityType: string;

  // @DataMember
  public nextCheckSession: string;

  public constructor(init?: Partial<SessionInfo>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class PanelController
  implements
    ICustomerEntity,
    ICustomerName,
    IDeviceItem,
    IOwnedDeviceItem,
    IChangeKey,
    IDescription,
    IModified,
    IEntity
{
  // @DataMember
  public controllerType: PanelControllerType;

  // @DataMember
  public customerId: string;

  // @DataMember
  public customerName: string;

  // @DataMember
  public ownerObjectName: string;

  // @DataMember
  public ownerObjectId: string;

  // @DataMember
  public prefix: string;

  // @DataMember
  public number: string;

  // @DataMember
  public phone1: string;

  // @DataMember
  public phone2: string;

  // @DataMember
  public ip1: string;

  // @DataMember
  public ip2: string;

  // @DataMember
  public imei: string;

  // @DataMember
  public deviceSettings: string;

  // @DataMember
  public lat: number;

  // @DataMember
  public lon: number;

  // @DataMember
  public direction: number;

  // @DataMember
  public fullNumber: string;

  // @DataMember
  public softwareVersion: string;

  // @DataMember
  public firmwareVersion: string;

  // @DataMember
  public hardwareVersion: string;

  // @DataMember
  public description: string;

  // @DataMember
  public changeKey: string;

  // @DataMember
  public created: string;

  // @DataMember
  public createdBy: string;

  // @DataMember
  public modified: string;

  // @DataMember
  public modifiedBy: string;

  // @DataMember
  public id: string;

  // @DataMember
  public entityType: string;

  public constructor(init?: Partial<PanelController>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class GetPanelControllersResponse implements IChangeKey {
  // @DataMember
  public items: PanelController[];

  // @DataMember
  public total: number;

  // @DataMember
  public page: number;

  // @DataMember
  public itemsPerPage: number;

  // @DataMember
  public changeKey: string;

  public constructor(init?: Partial<GetPanelControllersResponse>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class GetEntityGroupsResponse implements IChangeKey {
  // @DataMember
  public items: EntityGroupInfo[];

  // @DataMember
  public total: number;

  // @DataMember
  public page: number;

  // @DataMember
  public itemsPerPage: number;

  // @DataMember
  public changeKey: string;

  public constructor(init?: Partial<GetEntityGroupsResponse>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class GetEntityGroupSharesResponse implements IChangeKey {
  // @DataMember
  public items: EntityGroupPermissions[];

  // @DataMember
  public total: number;

  // @DataMember
  public page: number;

  // @DataMember
  public itemsPerPage: number;

  // @DataMember
  public changeKey: string;

  public constructor(init?: Partial<GetEntityGroupSharesResponse>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class PanelStand
  implements ICustomerEntityViewModel, INamedEntity, IChangeKey, IDescription, IModified, IEntity
{
  // @DataMember
  public lat: number;

  // @DataMember
  public lon: number;

  // @DataMember
  public customerId: string;

  // @DataMember
  public customerName: string;

  // @DataMember
  public name: string;

  // @DataMember
  public description: string;

  // @DataMember
  public changeKey: string;

  // @DataMember
  public created: string;

  // @DataMember
  public createdBy: string;

  // @DataMember
  public modified: string;

  // @DataMember
  public modifiedBy: string;

  // @DataMember
  public id: string;

  // @DataMember
  public entityType: string;

  public constructor(init?: Partial<PanelStand>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class GetPanelStandsResponse implements IChangeKey {
  // @DataMember
  public items: PanelStand[];

  // @DataMember
  public total: number;

  // @DataMember
  public page: number;

  // @DataMember
  public itemsPerPage: number;

  // @DataMember
  public changeKey: string;

  public constructor(init?: Partial<GetPanelStandsResponse>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class PanelStatus
  implements
    IDeviceConnectionInfo,
    ICustomerEntityViewModel,
    INamedEntity,
    IChangeKey,
    IDescription,
    IModified,
    IEntity
{
  // @DataMember
  public panelStandId?: string;

  // @DataMember
  public panelControllerId?: string;

  // @DataMember
  public controllerStatus: PanelControllerStatus;

  // @DataMember
  public serverTime: string;

  // @DataMember
  public connectionTime?: string;

  // @DataMember
  public lastReceived?: string;

  // @DataMember
  public lastSent?: string;

  // @DataMember
  public deviceAddress: string;

  // @DataMember
  public online: boolean;

  // @DataMember
  public mode: PanelMode;

  // @DataMember
  public thumbnailId: string;

  // @DataMember
  public panelType: PanelType;

  // @DataMember
  public program: PanelProgramInfo;

  // @DataMember
  public schedule: PanelScheduleInfo;

  /**
   * Яркость экрана
   */
  // @DataMember
  // @ApiMember(Description="Яркость экрана")
  public brightness: number;

  // @DataMember
  public brightnessMode: PanelBrightnessMode;

  // @DataMember
  public customerId: string;

  // @DataMember
  public customerName: string;

  // @DataMember
  public name: string;

  // @DataMember
  public description: string;

  // @DataMember
  public changeKey: string;

  // @DataMember
  public created: string;

  // @DataMember
  public createdBy: string;

  // @DataMember
  public modified: string;

  // @DataMember
  public modifiedBy: string;

  // @DataMember
  public id: string;

  // @DataMember
  public entityType: string;

  public constructor(init?: Partial<PanelStatus>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class PanelStandStatus
  implements ICustomerEntityViewModel, INamedEntity, IChangeKey, IDescription, IModified, IEntity
{
  // @DataMember
  public lat: number;

  // @DataMember
  public lon: number;

  // @DataMember
  public serverTime: string;

  // @DataMember
  public iconStatus: PanelStandIconStatus;

  // @DataMember
  public panels: PanelStatus[];

  // @DataMember
  public controllers: PanelControllerStatus[];

  // @DataMember
  public online: boolean;

  // @DataMember
  public customMode: string;

  // @DataMember
  public customerId: string;

  // @DataMember
  public customerName: string;

  // @DataMember
  public name: string;

  // @DataMember
  public description: string;

  // @DataMember
  public changeKey: string;

  // @DataMember
  public created: string;

  // @DataMember
  public createdBy: string;

  // @DataMember
  public modified: string;

  // @DataMember
  public modifiedBy: string;

  // @DataMember
  public id: string;

  // @DataMember
  public entityType: string;

  public constructor(init?: Partial<PanelStandStatus>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class GetPanelStandStatusesResponse implements IChangeKey {
  // @DataMember
  public items: PanelStandStatus[];

  // @DataMember
  public total: number;

  // @DataMember
  public page: number;

  // @DataMember
  public itemsPerPage: number;

  // @DataMember
  public changeKey: string;

  public constructor(init?: Partial<GetPanelStandStatusesResponse>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class GetStatisticResponse {
  // @DataMember
  public signPanelsStatistics: PanelsStatistic;

  // @DataMember
  public infoPanelsStatistics: PanelsStatistic;

  // @DataMember
  public commonPanelsStatistics: PanelsStatistic;

  // @DataMember
  public panelStandsStatistic: PanelStandsStatistic;

  public constructor(init?: Partial<GetStatisticResponse>) {
    (Object as any).assign(this, init);
  }
}

export class PanelShowSettings {
  // @DataMember
  public programs: PanelPrograms;

  // @DataMember
  public schedules: PanelSchedules;

  public constructor(init?: Partial<PanelShowSettings>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class Panel
  implements ICustomerEntityViewModel, INamedEntity, IChangeKey, IDescription, IModified, IEntity
{
  // @DataMember
  public deviceNumber: string;

  // @DataMember
  public panelStandId?: string;

  // @DataMember
  public panelControllerId?: string;

  // @DataMember
  public panelStandName: string;

  // @DataMember
  public panelControllerNumber: string;

  // @DataMember
  public showSettings: PanelShowSettings;

  // @DataMember
  public panelType: PanelType;

  // @DataMember
  public customerId: string;

  // @DataMember
  public customerName: string;

  // @DataMember
  public name: string;

  // @DataMember
  public description: string;

  // @DataMember
  public changeKey: string;

  // @DataMember
  public created: string;

  // @DataMember
  public createdBy: string;

  // @DataMember
  public modified: string;

  // @DataMember
  public modifiedBy: string;

  // @DataMember
  public id: string;

  // @DataMember
  public entityType: string;

  public constructor(init?: Partial<Panel>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class GetPanelsResponse implements IChangeKey {
  // @DataMember
  public items: Panel[];

  // @DataMember
  public total: number;

  // @DataMember
  public page: number;

  // @DataMember
  public itemsPerPage: number;

  // @DataMember
  public changeKey: string;

  public constructor(init?: Partial<GetPanelsResponse>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class GenerateTextFrameResponse {
  // @DataMember
  public contentType: string;

  // @DataMember
  public contentBase64: string;

  public constructor(init?: Partial<GenerateTextFrameResponse>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class PanelScheduledBrightnessSettings implements IEnabled {
  // @DataMember
  public enabled: boolean;

  public constructor(init?: Partial<PanelScheduledBrightnessSettings>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class PanelDayBrightnessSettings {
  // @DataMember
  public enabled: boolean;

  // @DataMember
  public month: number;

  // @DataMember
  public day: number;

  /**
   * Время начала ночи
   */
  // @DataMember
  // @ApiMember(Description="Время начала ночи")
  public nightTime: string;

  /**
   * Время начала рассвета
   */
  // @DataMember
  // @ApiMember(Description="Время начала рассвета")
  public dawnTime: string;

  /**
   * Время начала восхода
   */
  // @DataMember
  // @ApiMember(Description="Время начала восхода")
  public sunriseTime: string;

  /**
   * Время начала дня
   */
  // @DataMember
  // @ApiMember(Description="Время начала дня")
  public dayTime: string;

  /**
   * Время начала заката
   */
  // @DataMember
  // @ApiMember(Description="Время начала заката")
  public sunsetTime: string;

  /**
   * Время начала сумерек
   */
  // @DataMember
  // @ApiMember(Description="Время начала сумерек")
  public duskTime: string;

  /**
   * Яркость при ночи
   */
  // @DataMember
  // @ApiMember(Description="Яркость при ночи")
  public nightBrightness: number;

  /**
   * Яркость при рассвете
   */
  // @DataMember
  // @ApiMember(Description="Яркость при рассвете")
  public dawnBrightness: number;

  /**
   * Яркость при восходе
   */
  // @DataMember
  // @ApiMember(Description="Яркость при восходе")
  public sunriseBrightness: number;

  /**
   * Яркость при дне
   */
  // @DataMember
  // @ApiMember(Description="Яркость при дне")
  public dayBrightness: number;

  /**
   * Яркость при закате
   */
  // @DataMember
  // @ApiMember(Description="Яркость при закате")
  public sunsetBrightness: number;

  /**
   * Яркость при сумерках
   */
  // @DataMember
  // @ApiMember(Description="Яркость при сумерках")
  public duskBrightness: number;

  public constructor(init?: Partial<PanelDayBrightnessSettings>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class EntityIntegration implements IChangeKey, IDescription, IModified, IEntity {
  // @DataMember
  public entityId: string;

  // @DataMember
  public customerIntegrationId: string;

  // @DataMember
  public externalId: string;

  // @DataMember
  public settings: string;

  // @DataMember
  public integrationId: string;

  // @DataMember
  public integrationName: string;

  // @DataMember
  public integrationType: string;

  // @DataMember
  public description: string;

  // @DataMember
  public changeKey: string;

  // @DataMember
  public created: string;

  // @DataMember
  public createdBy: string;

  // @DataMember
  public modified: string;

  // @DataMember
  public modifiedBy: string;

  // @DataMember
  public id: string;

  // @DataMember
  public entityType: string;

  public constructor(init?: Partial<EntityIntegration>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class GetEntityIntegrationResponse implements IChangeKey {
  // @DataMember
  public items: EntityIntegration[];

  // @DataMember
  public total: number;

  // @DataMember
  public page: number;

  // @DataMember
  public itemsPerPage: number;

  // @DataMember
  public changeKey: string;

  public constructor(init?: Partial<GetEntityIntegrationResponse>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class GetEntitiesResponse implements IChangeKey {
  // @DataMember
  public items: Object[];

  // @DataMember
  public total: number;

  // @DataMember
  public page: number;

  // @DataMember
  public itemsPerPage: number;

  // @DataMember
  public changeKey: string;

  public constructor(init?: Partial<GetEntitiesResponse>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class AuthenticateResponse implements IHasSessionId, IHasBearerToken {
  // @DataMember(Order=1)
  public userId: string;

  // @DataMember(Order=2)
  public sessionId: string;

  // @DataMember(Order=3)
  public userName: string;

  // @DataMember(Order=4)
  public displayName: string;

  // @DataMember(Order=5)
  public referrerUrl: string;

  // @DataMember(Order=6)
  public bearerToken: string;

  // @DataMember(Order=7)
  public refreshToken: string;

  // @DataMember(Order=8)
  public profileUrl: string;

  // @DataMember(Order=9)
  public roles: string[];

  // @DataMember(Order=10)
  public permissions: string[];

  // @DataMember(Order=11)
  public responseStatus: ResponseStatus;

  // @DataMember(Order=12)
  public meta: { [index: string]: string };

  public constructor(init?: Partial<AuthenticateResponse>) {
    (Object as any).assign(this, init);
  }
}

// @Route("/frameTemplates/thumbnail/{ThumbnailId}", "GET")
// @DataContract
export class GetFrameTemplateThumbnailRequest implements IReturn<Object> {
  // @DataMember
  public thumbnailId: string;

  public constructor(init?: Partial<GetFrameTemplateThumbnailRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new Object();
  }
  public getTypeName() {
    return 'GetFrameTemplateThumbnailRequest';
  }
}

// @Route("/customer/{customerId}/frameTemplates", "GET")
// @DataContract
export class GetFrameTemplatesRequest
  implements IReturn<GetFrameTemplatesResponse>, ICustomerItemRequest
{
  // @DataMember
  public libraryId: string;

  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public entityGroups: string[];

  // @DataMember
  public itemsIds: string[];

  // @DataMember
  public onlyOwnedItems: boolean;

  // @DataMember
  public page: number;

  // @DataMember
  public items: number;

  // @DataMember
  public sort: string;

  // @DataMember
  public filter: string;

  // @DataMember
  public propertiesTest: { [index: string]: number };

  public constructor(init?: Partial<GetFrameTemplatesRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new GetFrameTemplatesResponse();
  }
  public getTypeName() {
    return 'GetFrameTemplatesRequest';
  }
}

// @Route("/customer/{customerId}/frameTemplate/{id}", "GET")
// @DataContract
export class GetFrameTemplateRequest
  implements IReturn<FrameTemplate>, ICustomerItemRequest, IBaseEntityItemRequest
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember(IsRequired=true)
  public id: string;

  public constructor(init?: Partial<GetFrameTemplateRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new FrameTemplate();
  }
  public getTypeName() {
    return 'GetFrameTemplateRequest';
  }
}

// @Route("/customer/{customerId}/frameTemplates/exists", "GET")
// @DataContract
export class GetFrameTemplateExistRequest implements IReturn<boolean> {
  // @DataMember(IsRequired=true)
  public name: string;

  public constructor(init?: Partial<GetFrameTemplateExistRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return false;
  }
  public getTypeName() {
    return 'GetFrameTemplateExistRequest';
  }
}

// @Route("/customer/{customerId}/frameTemplates", "POST")
// @DataContract
export class CreateFrameTemplateRequest
  implements IReturn<FrameTemplate>, ICustomerItemRequest, IDescription
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public updateEntityGroups: boolean;

  // @DataMember
  public entityGroups: string[];

  // @DataMember(IsRequired=true)
  public entity: FrameTemplate;

  // @DataMember
  public description: string;

  public constructor(init?: Partial<CreateFrameTemplateRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new FrameTemplate();
  }
  public getTypeName() {
    return 'CreateFrameTemplateRequest';
  }
}

// @Route("/customer/{customerId}/frameTemplate/{id}", "PUT")
// @DataContract
export class UpdateFrameTemplateRequest
  implements IReturn<FrameTemplate>, ICustomerItemRequest, IBaseEntityItemRequest, IDescription
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public updateEntityGroups: boolean;

  // @DataMember
  public entityGroups: string[];

  // @DataMember(IsRequired=true)
  public id: string;

  // @DataMember(IsRequired=true)
  public entity: FrameTemplate;

  // @DataMember
  public description: string;

  public constructor(init?: Partial<UpdateFrameTemplateRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new FrameTemplate();
  }
  public getTypeName() {
    return 'UpdateFrameTemplateRequest';
  }
}

// @Route("/customer/{customerId}/frameTemplates", "DELETE")
// @DataContract
export class DeleteFrameTemplatesRequest
  implements IReturn<number>, ICustomerItemRequest, IDescription
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public description: string;

  // @DataMember(IsRequired=true)
  public ids: string[];

  public constructor(init?: Partial<DeleteFrameTemplatesRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return 0;
  }
  public getTypeName() {
    return 'DeleteFrameTemplatesRequest';
  }
}

// @Route("/customer/{customerId}/frameTemplateLibraries", "GET")
// @DataContract
export class GetFrameTemplateLibrariesRequest
  implements IReturn<GetFrameTemplateLibrariesResponse>, ICustomerItemRequest
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public entityGroups: string[];

  // @DataMember
  public itemsIds: string[];

  // @DataMember
  public onlyOwnedItems: boolean;

  // @DataMember
  public page: number;

  // @DataMember
  public items: number;

  // @DataMember
  public sort: string;

  // @DataMember
  public filter: string;

  // @DataMember
  public propertiesTest: { [index: string]: number };

  public constructor(init?: Partial<GetFrameTemplateLibrariesRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new GetFrameTemplateLibrariesResponse();
  }
  public getTypeName() {
    return 'GetFrameTemplateLibrariesRequest';
  }
}

// @Route("/customer/{customerId}/frameTemplateLibrary/{id}", "GET")
// @DataContract
export class GetFrameTemplateLibraryRequest
  implements IReturn<FrameTemplateLibrary>, ICustomerItemRequest, IBaseEntityItemRequest
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember(IsRequired=true)
  public id: string;

  public constructor(init?: Partial<GetFrameTemplateLibraryRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new FrameTemplateLibrary();
  }
  public getTypeName() {
    return 'GetFrameTemplateLibraryRequest';
  }
}

// @Route("/customer/{customerId}/frameTemplateLibraries/exists", "GET")
// @DataContract
export class GetFrameTemplateLibraryExistRequest implements IReturn<boolean> {
  // @DataMember(IsRequired=true)
  public name: string;

  public constructor(init?: Partial<GetFrameTemplateLibraryExistRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return false;
  }
  public getTypeName() {
    return 'GetFrameTemplateLibraryExistRequest';
  }
}

// @Route("/customer/{customerId}/frameTemplateLibraries", "POST")
// @DataContract
export class CreateFrameTemplateLibraryRequest
  implements IReturn<FrameTemplateLibrary>, ICustomerItemRequest, IDescription
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public updateEntityGroups: boolean;

  // @DataMember
  public entityGroups: string[];

  // @DataMember(IsRequired=true)
  public entity: FrameTemplateLibrary;

  // @DataMember
  public description: string;

  public constructor(init?: Partial<CreateFrameTemplateLibraryRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new FrameTemplateLibrary();
  }
  public getTypeName() {
    return 'CreateFrameTemplateLibraryRequest';
  }
}

// @Route("/customer/{customerId}/frameTemplateLibrary/{id}", "PUT")
// @DataContract
export class UpdateFrameTemplateLibraryRequest
  implements
    IReturn<FrameTemplateLibrary>,
    ICustomerItemRequest,
    IBaseEntityItemRequest,
    IDescription
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public updateEntityGroups: boolean;

  // @DataMember
  public entityGroups: string[];

  // @DataMember(IsRequired=true)
  public id: string;

  // @DataMember(IsRequired=true)
  public entity: FrameTemplateLibrary;

  // @DataMember
  public description: string;

  public constructor(init?: Partial<UpdateFrameTemplateLibraryRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new FrameTemplateLibrary();
  }
  public getTypeName() {
    return 'UpdateFrameTemplateLibraryRequest';
  }
}

// @Route("/customer/{customerId}/frameTemplateLibraries", "DELETE")
// @DataContract
export class DeleteFrameTemplateLibrariesRequest
  implements IReturn<number>, ICustomerItemRequest, IDescription
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public description: string;

  // @DataMember(IsRequired=true)
  public ids: string[];

  public constructor(init?: Partial<DeleteFrameTemplateLibrariesRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return 0;
  }
  public getTypeName() {
    return 'DeleteFrameTemplateLibrariesRequest';
  }
}

/**
 * Получить миниатюру панели
 */
// @Route("/thumbnails/{id}", "GET")
// @Api(Description="Получить миниатюру панели")
// @DataContract
export class GetPanelThumbnailRequest implements IReturn<Object> {
  // @DataMember
  public id: string;

  public constructor(init?: Partial<GetPanelThumbnailRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new Object();
  }
  public getTypeName() {
    return 'GetPanelThumbnailRequest';
  }
}

// @Route("/customer/{customerId}/controller/{id}/eventTypes", "GET")
// @DataContract
export class GetPanelControllerEventTypesRequest
  implements IReturn<EntityEventLogType[]>, IBaseEntityItemRequest
{
  // @DataMember
  public id: string;

  public constructor(init?: Partial<GetPanelControllerEventTypesRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new Array<EntityEventLogType>();
  }
  public getTypeName() {
    return 'GetPanelControllerEventTypesRequest';
  }
}

// @Route("/customer/{customerId}/controller/{id}/eventLog", "GET")
// @DataContract
export class GetPanelControllerEventLogRequest
  implements IReturn<GetDeviceEventLogResponse>, IBaseEntityItemRequest, ICustomerItemRequest
{
  // @DataMember
  public id: string;

  // @DataMember
  public start?: string;

  // @DataMember
  public end?: string;

  // @DataMember
  public filterEvents: number[];

  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public entityGroups: string[];

  // @DataMember
  public itemsIds: string[];

  // @DataMember
  public onlyOwnedItems: boolean;

  // @DataMember
  public page: number;

  // @DataMember
  public items: number;

  // @DataMember
  public sort: string;

  // @DataMember
  public filter: string;

  // @DataMember
  public propertiesTest: { [index: string]: number };

  public constructor(init?: Partial<GetPanelControllerEventLogRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new GetDeviceEventLogResponse();
  }
  public getTypeName() {
    return 'GetPanelControllerEventLogRequest';
  }
}

// @Route("/customer/{customerId}/controller/{id}/disconnect", "POST")
// @DataContract
export class DisconnectPanelControllerRequest
  implements IReturn<SessionInfo>, IDescription, ICustomerItemRequest, IBaseEntityItemRequest
{
  // @DataMember
  public description: string;

  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember(IsRequired=true)
  public id: string;

  public constructor(init?: Partial<DisconnectPanelControllerRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new SessionInfo();
  }
  public getTypeName() {
    return 'DisconnectPanelControllerRequest';
  }
}

// @Route("/customer/{customerId}/controllers", "GET")
// @DataContract
export class GetPanelControllersRequest
  implements IReturn<GetPanelControllersResponse>, ICustomerItemRequest
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public entityGroups: string[];

  // @DataMember
  public itemsIds: string[];

  // @DataMember
  public onlyOwnedItems: boolean;

  // @DataMember
  public page: number;

  // @DataMember
  public items: number;

  // @DataMember
  public sort: string;

  // @DataMember
  public filter: string;

  // @DataMember
  public propertiesTest: { [index: string]: number };

  public constructor(init?: Partial<GetPanelControllersRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new GetPanelControllersResponse();
  }
  public getTypeName() {
    return 'GetPanelControllersRequest';
  }
}

// @Route("/customer/{customerId}/controller/{id}", "GET")
// @DataContract
export class GetPanelControllerRequest
  implements IReturn<PanelController>, ICustomerItemRequest, IBaseEntityItemRequest
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember(IsRequired=true)
  public id: string;

  public constructor(init?: Partial<GetPanelControllerRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new PanelController();
  }
  public getTypeName() {
    return 'GetPanelControllerRequest';
  }
}

// @Route("/customer/{customerId}/controllers/exists", "GET")
// @DataContract
export class GetPanelControllerExistRequest implements IReturn<boolean>, ICustomerItemRequest {
  // @DataMember(IsRequired=true)
  public number: string;

  // @DataMember
  public customerId: string;

  public constructor(init?: Partial<GetPanelControllerExistRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return false;
  }
  public getTypeName() {
    return 'GetPanelControllerExistRequest';
  }
}

// @Route("/customer/{customerId}/controllers", "POST")
// @DataContract
export class CreatePanelControllerRequest
  implements IReturn<PanelController>, ICustomerItemRequest, IDescription
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public updateEntityGroups: boolean;

  // @DataMember
  public entityGroups: string[];

  // @DataMember(IsRequired=true)
  public entity: PanelController;

  // @DataMember
  public description: string;

  public constructor(init?: Partial<CreatePanelControllerRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new PanelController();
  }
  public getTypeName() {
    return 'CreatePanelControllerRequest';
  }
}

// @Route("/customer/{customerId}/controller/{id}/uploadSettings", "POST")
// @DataContract
export class UploadPanelControllerSettingsRequest
  implements IReturn<PanelController>, ICustomerItemRequest, IBaseEntityItemRequest
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember(IsRequired=true)
  public id: string;

  public constructor(init?: Partial<UploadPanelControllerSettingsRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new PanelController();
  }
  public getTypeName() {
    return 'UploadPanelControllerSettingsRequest';
  }
}

// @Route("/customer/{customerId}/controller/{id}", "PUT")
// @DataContract
export class UpdatePanelControllerRequest
  implements IReturn<PanelController>, ICustomerItemRequest, IBaseEntityItemRequest, IDescription
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public updateEntityGroups: boolean;

  // @DataMember
  public entityGroups: string[];

  // @DataMember(IsRequired=true)
  public id: string;

  // @DataMember(IsRequired=true)
  public entity: PanelController;

  // @DataMember
  public description: string;

  public constructor(init?: Partial<UpdatePanelControllerRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new PanelController();
  }
  public getTypeName() {
    return 'UpdatePanelControllerRequest';
  }
}

// @Route("/customer/{customerId}/controllers", "DELETE")
// @DataContract
export class DeletePanelControllersRequest
  implements IReturn<number>, ICustomerItemRequest, IDescription
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public description: string;

  // @DataMember(IsRequired=true)
  public ids: string[];

  public constructor(init?: Partial<DeletePanelControllersRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return 0;
  }
  public getTypeName() {
    return 'DeletePanelControllersRequest';
  }
}

// @Route("/customer/{customerId}/vehicles/groups", "GET")
// @DataContract
export class GetPanelStandGroupsRequest
  implements IReturn<GetEntityGroupsResponse>, ICustomerItemRequest
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public entityGroups: string[];

  // @DataMember
  public itemsIds: string[];

  // @DataMember
  public onlyOwnedItems: boolean;

  // @DataMember
  public page: number;

  // @DataMember
  public items: number;

  // @DataMember
  public sort: string;

  // @DataMember
  public filter: string;

  // @DataMember
  public propertiesTest: { [index: string]: number };

  public constructor(init?: Partial<GetPanelStandGroupsRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new GetEntityGroupsResponse();
  }
  public getTypeName() {
    return 'GetPanelStandGroupsRequest';
  }
}

// @Route("/customer/{customerId}/vehicles/groups/{id}", "GET")
// @DataContract
export class GetPanelStandGroupRequest
  implements IReturn<EntityGroup>, ICustomerItemRequest, IBaseEntityItemRequest
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember(IsRequired=true)
  public id: string;

  public constructor(init?: Partial<GetPanelStandGroupRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new EntityGroup();
  }
  public getTypeName() {
    return 'GetPanelStandGroupRequest';
  }
}

// @Route("/customer/{customerId}/vehicles/groups", "POST")
// @DataContract
export class CreatePanelStandGroupRequest
  implements IReturn<EntityGroup>, ICustomerItemRequest, IDescription
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public updateEntityGroups: boolean;

  // @DataMember
  public entityGroups: string[];

  // @DataMember(IsRequired=true)
  public entity: EntityGroup;

  // @DataMember
  public description: string;

  public constructor(init?: Partial<CreatePanelStandGroupRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new EntityGroup();
  }
  public getTypeName() {
    return 'CreatePanelStandGroupRequest';
  }
}

// @Route("/customer/{customerId}/vehicles/groups/{id}", "PUT")
// @DataContract
export class UpdatePanelStandGroupRequest
  implements IReturn<EntityGroup>, ICustomerItemRequest, IBaseEntityItemRequest, IDescription
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public updateEntityGroups: boolean;

  // @DataMember
  public entityGroups: string[];

  // @DataMember(IsRequired=true)
  public id: string;

  // @DataMember(IsRequired=true)
  public entity: EntityGroup;

  // @DataMember
  public description: string;

  public constructor(init?: Partial<UpdatePanelStandGroupRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new EntityGroup();
  }
  public getTypeName() {
    return 'UpdatePanelStandGroupRequest';
  }
}

// @Route("/customer/{customerId}/vehicles/groups", "DELETE")
// @DataContract
export class DeletePanelStandGroupsRequest
  implements IReturn<number>, ICustomerItemRequest, IDescription
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public description: string;

  // @DataMember(IsRequired=true)
  public ids: string[];

  public constructor(init?: Partial<DeletePanelStandGroupsRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return 0;
  }
  public getTypeName() {
    return 'DeletePanelStandGroupsRequest';
  }
}

// @Route("/customer/{customerId}/vehicles/groups/{id}", "GET")
// @DataContract
export class GetPanelStandGroupSharesRequest
  implements IReturn<GetEntityGroupSharesResponse>, IBaseEntityItemRequest, ICustomerItemRequest
{
  // @DataMember
  public id: string;

  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public entityGroups: string[];

  // @DataMember
  public itemsIds: string[];

  // @DataMember
  public onlyOwnedItems: boolean;

  // @DataMember
  public page: number;

  // @DataMember
  public items: number;

  // @DataMember
  public sort: string;

  // @DataMember
  public filter: string;

  // @DataMember
  public propertiesTest: { [index: string]: number };

  public constructor(init?: Partial<GetPanelStandGroupSharesRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new GetEntityGroupSharesResponse();
  }
  public getTypeName() {
    return 'GetPanelStandGroupSharesRequest';
  }
}

// @Route("/customer/{customerId}/vehicles/groups/{id}", "PUT")
// @DataContract
export class UpdatePanelStandGroupShareRequest
  implements IReturn<EntityGroupPermissions>, ICustomerItemRequest, IDescription
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember(IsRequired=true)
  public id: string;

  // @DataMember(IsRequired=true)
  public entity: EntityGroupPermissions;

  // @DataMember
  public description: string;

  public constructor(init?: Partial<UpdatePanelStandGroupShareRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new EntityGroupPermissions();
  }
  public getTypeName() {
    return 'UpdatePanelStandGroupShareRequest';
  }
}

// @Route("/customer/{customerId}/panelStands", "GET")
// @DataContract
export class GetPanelStandsRequest
  implements IReturn<GetPanelStandsResponse>, ICustomerItemRequest
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public entityGroups: string[];

  // @DataMember
  public itemsIds: string[];

  // @DataMember
  public onlyOwnedItems: boolean;

  // @DataMember
  public page: number;

  // @DataMember
  public items: number;

  // @DataMember
  public sort: string;

  // @DataMember
  public filter: string;

  // @DataMember
  public propertiesTest: { [index: string]: number };

  public constructor(init?: Partial<GetPanelStandsRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new GetPanelStandsResponse();
  }
  public getTypeName() {
    return 'GetPanelStandsRequest';
  }
}

// @Route("/customer/{customerId}/panelStand/{id}", "GET")
// @DataContract
export class GetPanelStandRequest
  implements IReturn<PanelStand>, ICustomerItemRequest, IBaseEntityItemRequest
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember(IsRequired=true)
  public id: string;

  public constructor(init?: Partial<GetPanelStandRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new PanelStand();
  }
  public getTypeName() {
    return 'GetPanelStandRequest';
  }
}

// @Route("/customer/{customerId}/panelStands/exists", "GET")
// @DataContract
export class GetPanelStandExistRequest implements IReturn<boolean>, ICustomerItemRequest {
  // @DataMember(IsRequired=true)
  public name: string;

  // @DataMember
  public customerId: string;

  public constructor(init?: Partial<GetPanelStandExistRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return false;
  }
  public getTypeName() {
    return 'GetPanelStandExistRequest';
  }
}

// @Route("/customer/{customerId}/panelStands", "POST")
// @DataContract
export class CreatePanelStandRequest
  implements IReturn<PanelStand>, ICustomerItemRequest, IDescription
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public updateEntityGroups: boolean;

  // @DataMember
  public entityGroups: string[];

  // @DataMember(IsRequired=true)
  public entity: PanelStand;

  // @DataMember
  public description: string;

  public constructor(init?: Partial<CreatePanelStandRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new PanelStand();
  }
  public getTypeName() {
    return 'CreatePanelStandRequest';
  }
}

// @Route("/customer/{customerId}/panelStand/{id}/setCustomMode", "POST")
// @DataContract
export class SetCustomModePanelStandRequest
  implements IReturn<PanelStandStatus>, ICustomerItemRequest, IBaseEntityItemRequest
{
  // @DataMember
  public customMode: string;

  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember(IsRequired=true)
  public id: string;

  public constructor(init?: Partial<SetCustomModePanelStandRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new PanelStandStatus();
  }
  public getTypeName() {
    return 'SetCustomModePanelStandRequest';
  }
}

// @Route("/customer/{customerId}/panelStand/{id}", "PUT")
// @DataContract
export class UpdatePanelStandRequest
  implements IReturn<PanelStand>, ICustomerItemRequest, IBaseEntityItemRequest, IDescription
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public updateEntityGroups: boolean;

  // @DataMember
  public entityGroups: string[];

  // @DataMember(IsRequired=true)
  public id: string;

  // @DataMember(IsRequired=true)
  public entity: PanelStand;

  // @DataMember
  public description: string;

  public constructor(init?: Partial<UpdatePanelStandRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new PanelStand();
  }
  public getTypeName() {
    return 'UpdatePanelStandRequest';
  }
}

// @Route("/customer/{customerId}/panelStands", "DELETE")
// @DataContract
export class DeletePanelStandsRequest
  implements IReturn<number>, ICustomerItemRequest, IDescription
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public description: string;

  // @DataMember(IsRequired=true)
  public ids: string[];

  public constructor(init?: Partial<DeletePanelStandsRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return 0;
  }
  public getTypeName() {
    return 'DeletePanelStandsRequest';
  }
}

// @Route("/customer/{customerId}/panelStand/{id}/status", "GET")
// @DataContract
export class GetPanelStandStatusRequest
  implements IReturn<PanelStandStatus>, ICustomerItemRequest, IBaseEntityItemRequest
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember(IsRequired=true)
  public id: string;

  public constructor(init?: Partial<GetPanelStandStatusRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new PanelStandStatus();
  }
  public getTypeName() {
    return 'GetPanelStandStatusRequest';
  }
}

// @Route("/customer/{customerId}/panelStands/statuses", "GET")
// @DataContract
export class GetPanelStandStatusesRequest
  implements IReturn<GetPanelStandStatusesResponse>, ICustomerItemRequest
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public entityGroups: string[];

  // @DataMember
  public itemsIds: string[];

  // @DataMember
  public onlyOwnedItems: boolean;

  // @DataMember
  public page: number;

  // @DataMember
  public items: number;

  // @DataMember
  public sort: string;

  // @DataMember
  public filter: string;

  // @DataMember
  public propertiesTest: { [index: string]: number };

  public constructor(init?: Partial<GetPanelStandStatusesRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new GetPanelStandStatusesResponse();
  }
  public getTypeName() {
    return 'GetPanelStandStatusesRequest';
  }
}

// @Route("/customer/{customerId}/panelStand/{id}/disconnect", "POST")
// @DataContract
export class DisconnectPanelStandRequest
  implements IReturn<PanelStandStatus>, ICustomerItemRequest, IBaseEntityItemRequest
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember(IsRequired=true)
  public id: string;

  public constructor(init?: Partial<DisconnectPanelStandRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new PanelStandStatus();
  }
  public getTypeName() {
    return 'DisconnectPanelStandRequest';
  }
}

/**
 * Получить статистику панелей компании
 */
// @Route("/customer/{customerId}/panels/statistic", "GET")
// @Api(Description="Получить статистику панелей компании")
// @DataContract
export class GetStatisticRequest
  implements IReturn<GetStatisticResponse>, ICustomerItemRequest, IBaseEntityItemRequest
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember(IsRequired=true)
  public id: string;

  public constructor(init?: Partial<GetStatisticRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new GetStatisticResponse();
  }
  public getTypeName() {
    return 'GetStatisticRequest';
  }
}

/**
 * Получить панели
 */
// @Route("/customer/{customerId}/panels", "GET")
// @Api(Description="Получить панели")
// @DataContract
export class GetPanelsRequest implements IReturn<GetPanelsResponse>, ICustomerItemRequest {
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public entityGroups: string[];

  // @DataMember
  public itemsIds: string[];

  // @DataMember
  public onlyOwnedItems: boolean;

  // @DataMember
  public page: number;

  // @DataMember
  public items: number;

  // @DataMember
  public sort: string;

  // @DataMember
  public filter: string;

  // @DataMember
  public propertiesTest: { [index: string]: number };

  public constructor(init?: Partial<GetPanelsRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new GetPanelsResponse();
  }
  public getTypeName() {
    return 'GetPanelsRequest';
  }
}

/**
 * Получить панель
 */
// @Route("/customer/{customerId}/panel/{id}", "GET")
// @Api(Description="Получить панель")
// @DataContract
export class GetPanelRequest
  implements IReturn<Panel>, ICustomerItemRequest, IBaseEntityItemRequest
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember(IsRequired=true)
  public id: string;

  public constructor(init?: Partial<GetPanelRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new Panel();
  }
  public getTypeName() {
    return 'GetPanelRequest';
  }
}

// @Route("/customer/{customerId}/panels/exists", "GET")
// @DataContract
export class GetPanelExistRequest implements IReturn<boolean>, ICustomerItemRequest {
  // @DataMember(IsRequired=true)
  public name: string;

  // @DataMember
  public customerId: string;

  public constructor(init?: Partial<GetPanelExistRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return false;
  }
  public getTypeName() {
    return 'GetPanelExistRequest';
  }
}

/**
 * Создать панель
 */
// @Route("/customer/{customerId}/panels", "POST")
// @Api(Description="Создать панель")
// @DataContract
export class CreatePanelRequest implements IReturn<Panel>, ICustomerItemRequest, IDescription {
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public updateEntityGroups: boolean;

  // @DataMember
  public entityGroups: string[];

  // @DataMember(IsRequired=true)
  public entity: Panel;

  // @DataMember
  public description: string;

  public constructor(init?: Partial<CreatePanelRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new Panel();
  }
  public getTypeName() {
    return 'CreatePanelRequest';
  }
}

/**
 * Обновить панель
 */
// @Route("/customer/{customerId}/panel/{id}", "PUT")
// @Api(Description="Обновить панель")
// @DataContract
export class UpdatePanelRequest
  implements IReturn<Panel>, ICustomerItemRequest, IBaseEntityItemRequest, IDescription
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public updateEntityGroups: boolean;

  // @DataMember
  public entityGroups: string[];

  // @DataMember(IsRequired=true)
  public id: string;

  // @DataMember(IsRequired=true)
  public entity: Panel;

  // @DataMember
  public description: string;

  public constructor(init?: Partial<UpdatePanelRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new Panel();
  }
  public getTypeName() {
    return 'UpdatePanelRequest';
  }
}

/**
 * Обновись настройки программ панели
 */
// @Route("/customer/{customerId}/panel/{id}/showSettings", "PUT")
// @Api(Description="Обновись настройки программ панели")
// @DataContract
export class UpdatePanelShowSettingsRequest
  implements IReturn<PanelShowSettings>, ICustomerItemRequest, IBaseEntityItemRequest, IDescription
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public updateEntityGroups: boolean;

  // @DataMember
  public entityGroups: string[];

  // @DataMember(IsRequired=true)
  public id: string;

  // @DataMember(IsRequired=true)
  public entity: PanelShowSettings;

  // @DataMember
  public description: string;

  public constructor(init?: Partial<UpdatePanelShowSettingsRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new PanelShowSettings();
  }
  public getTypeName() {
    return 'UpdatePanelShowSettingsRequest';
  }
}

/**
 * Получить настройки программ панели
 */
// @Route("/customer/{customerId}/panel/{id}/showSettings", "GET")
// @Api(Description="Получить настройки программ панели")
// @DataContract
export class GetPanelShowSettingsRequest
  implements IReturn<PanelShowSettings>, ICustomerItemRequest, IBaseEntityItemRequest
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember(IsRequired=true)
  public id: string;

  public constructor(init?: Partial<GetPanelShowSettingsRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new PanelShowSettings();
  }
  public getTypeName() {
    return 'GetPanelShowSettingsRequest';
  }
}

// @Route("/customer/{customerId}/panels", "DELETE")
// @DataContract
export class DeletePanelsRequest implements IReturn<number>, ICustomerItemRequest, IDescription {
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public description: string;

  // @DataMember(IsRequired=true)
  public ids: string[];

  public constructor(init?: Partial<DeletePanelsRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return 0;
  }
  public getTypeName() {
    return 'DeletePanelsRequest';
  }
}

/**
 * Получить список расписаний которые доступны для запуска
 */
// @Route("/customer/{customerId}/panel/{id}/showSettings/availableSchedules", "GET")
// @Api(Description="Получить список расписаний которые доступны для запуска")
// @DataContract
export class GetPanelAvailableSchedulesRequest
  implements IReturn<PanelAvailableSchedule[]>, ICustomerItemRequest, IBaseEntityItemRequest
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember(IsRequired=true)
  public id: string;

  public constructor(init?: Partial<GetPanelAvailableSchedulesRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new Array<PanelAvailableSchedule>();
  }
  public getTypeName() {
    return 'GetPanelAvailableSchedulesRequest';
  }
}

/**
 * Установить панель в режим выкл
 */
// @Route("/customer/{customerId}/panel/{id}/setOffMode", "POST")
// @Api(Description="Установить панель в режим выкл")
// @DataContract
export class SetPanelOffModeRequest
  implements IReturn<PanelStatus>, ICustomerItemRequest, IBaseEntityItemRequest
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember(IsRequired=true)
  public id: string;

  public constructor(init?: Partial<SetPanelOffModeRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new PanelStatus();
  }
  public getTypeName() {
    return 'SetPanelOffModeRequest';
  }
}

/**
 * Установить панель в рабочий режим
 */
// @Route("/customer/{customerId}/panel/{id}/setWorkMode", "POST")
// @Api(Description="Установить панель в рабочий режим")
// @DataContract
export class SetPanelWorkModeRequest
  implements IReturn<PanelStatus>, ICustomerItemRequest, IBaseEntityItemRequest
{
  /**
   * Ид расписания. Если не указан берётся последний выбранный
   */
  // @DataMember
  // @ApiMember(Description="Ид расписания. Если не указан берётся последний выбранный")
  public scheduleId?: string;

  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember(IsRequired=true)
  public id: string;

  public constructor(init?: Partial<SetPanelWorkModeRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new PanelStatus();
  }
  public getTypeName() {
    return 'SetPanelWorkModeRequest';
  }
}

/**
 * Установить панель в дистанционный режим
 */
// @Route("/customer/{customerId}/panel/{id}/setRemoteMode", "POST")
// @Api(Description="Установить панель в дистанционный режим")
// @DataContract
export class SetPanelRemoteModeRequest
  implements IReturn<PanelStatus>, ICustomerItemRequest, IBaseEntityItemRequest
{
  /**
   * Ид расписания
   */
  // @DataMember
  // @ApiMember(Description="Ид расписания")
  public scheduleId: string;

  /**
   * Список значений динамических параметров для запуска
   */
  // @DataMember
  // @ApiMember(Description="Список значений динамических параметров для запуска")
  public parameters: PanelProgramParameter[];

  /**
   * Время работы режима в секундах
   */
  // @DataMember
  // @ApiMember(Description="Время работы режима в секундах")
  public modeTime: number;

  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember(IsRequired=true)
  public id: string;

  public constructor(init?: Partial<SetPanelRemoteModeRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new PanelStatus();
  }
  public getTypeName() {
    return 'SetPanelRemoteModeRequest';
  }
}

/**
 * Установить панель в рабочий режим
 */
// @Route("/customer/{customerId}/panel/{id}/generateTextFrame", "POST")
// @Api(Description="Установить панель в рабочий режим")
// @DataContract
export class GenerateTextFrameRequest
  implements IReturn<GenerateTextFrameResponse>, ICustomerItemRequest, IBaseEntityItemRequest
{
  /**
   * Текст сообщения
   */
  // @DataMember
  // @ApiMember(Description="Текст сообщения")
  public message: string;

  /**
   * Цвет текст
   */
  // @DataMember
  // @ApiMember(Description="Цвет текст")
  public color: string;

  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember(IsRequired=true)
  public id: string;

  public constructor(init?: Partial<GenerateTextFrameRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new GenerateTextFrameResponse();
  }
  public getTypeName() {
    return 'GenerateTextFrameRequest';
  }
}

/**
 * Получить режим годового расписания яркости
 */
// @Route("/customer/{customerId}/panel/{id}/scheduledBrightnessSettings", "GET")
// @Api(Description="Получить режим годового расписания яркости")
// @DataContract
export class GetPanelScheduledBrightnessSettingsRequest
  implements
    IReturn<PanelScheduledBrightnessSettings>,
    ICustomerItemRequest,
    IBaseEntityItemRequest
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember(IsRequired=true)
  public id: string;

  public constructor(init?: Partial<GetPanelScheduledBrightnessSettingsRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new PanelScheduledBrightnessSettings();
  }
  public getTypeName() {
    return 'GetPanelScheduledBrightnessSettingsRequest';
  }
}

/**
 * Установить режим годового расписания яркости
 */
// @Route("/customer/{customerId}/panel/{id}/scheduledBrightnessSettings", "POST")
// @Api(Description="Установить режим годового расписания яркости")
// @DataContract
export class SetPanelScheduledBrightnessSettingsRequest
  implements
    IReturn<PanelScheduledBrightnessSettings>,
    ICustomerItemRequest,
    IBaseEntityItemRequest
{
  /**
   * Настройки
   */
  // @DataMember
  // @ApiMember(Description="Настройки")
  public settings: PanelScheduledBrightnessSettings;

  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember(IsRequired=true)
  public id: string;

  public constructor(init?: Partial<SetPanelScheduledBrightnessSettingsRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new PanelScheduledBrightnessSettings();
  }
  public getTypeName() {
    return 'SetPanelScheduledBrightnessSettingsRequest';
  }
}

/**
 * Получить режим расписания яркости для конкретного дня
 */
// @Route("/customer/{customerId}/panel/{id}/dayBrightnessSettings", "GET")
// @Api(Description="Получить режим расписания яркости для конкретного дня")
// @DataContract
export class GetPanelDayBrightnessSettingsRequest
  implements IReturn<PanelDayBrightnessSettings>, ICustomerItemRequest, IBaseEntityItemRequest
{
  // @DataMember
  public month: number;

  // @DataMember
  public day: number;

  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember(IsRequired=true)
  public id: string;

  public constructor(init?: Partial<GetPanelDayBrightnessSettingsRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new PanelDayBrightnessSettings();
  }
  public getTypeName() {
    return 'GetPanelDayBrightnessSettingsRequest';
  }
}

// @Route("/healthStatus", "GET")
// @DataContract
export class GetHealthStatusRequest implements IReturn<string> {
  public constructor(init?: Partial<GetHealthStatusRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return '';
  }
  public getTypeName() {
    return 'GetHealthStatusRequest';
  }
}

// @Route("/customer/{customerId}/integration/{customerIntegrationId}/entities", "GET")
// @DataContract
export class GetEntityIntegrationsRequest
  implements
    IReturn<GetEntityIntegrationResponse>,
    ICustomerIntegrationIdRequest,
    ICustomerItemRequest
{
  // @DataMember
  public customerIntegrationId: string;

  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public entityGroups: string[];

  // @DataMember
  public itemsIds: string[];

  // @DataMember
  public onlyOwnedItems: boolean;

  // @DataMember
  public page: number;

  // @DataMember
  public items: number;

  // @DataMember
  public sort: string;

  // @DataMember
  public filter: string;

  // @DataMember
  public propertiesTest: { [index: string]: number };

  public constructor(init?: Partial<GetEntityIntegrationsRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new GetEntityIntegrationResponse();
  }
  public getTypeName() {
    return 'GetEntityIntegrationsRequest';
  }
}

// @Route("/customer/{customerId}/integration/{customerIntegrationId}/entity/{id}", "GET")
// @DataContract
export class GetEntityIntegrationRequest
  implements
    IReturn<EntityIntegration>,
    ICustomerIntegrationIdRequest,
    ICustomerItemRequest,
    IBaseEntityItemRequest
{
  // @DataMember
  public customerIntegrationId: string;

  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember(IsRequired=true)
  public id: string;

  public constructor(init?: Partial<GetEntityIntegrationRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new EntityIntegration();
  }
  public getTypeName() {
    return 'GetEntityIntegrationRequest';
  }
}

// @Route("/customer/{customerId}/integration/{customerIntegrationId}/entities", "POST")
// @DataContract
export class CreateEntityIntegrationRequest
  implements
    IReturn<EntityIntegration>,
    ICustomerIntegrationIdRequest,
    ICustomerItemRequest,
    IDescription
{
  // @DataMember
  public customerIntegrationId: string;

  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public updateEntityGroups: boolean;

  // @DataMember
  public entityGroups: string[];

  // @DataMember(IsRequired=true)
  public entity: EntityIntegration;

  // @DataMember
  public description: string;

  public constructor(init?: Partial<CreateEntityIntegrationRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new EntityIntegration();
  }
  public getTypeName() {
    return 'CreateEntityIntegrationRequest';
  }
}

// @Route("/customer/{customerId}/integration/{customerIntegrationId}/entity/{id}", "PUT")
// @DataContract
export class UpdateEntityIntegrationRequest
  implements
    IReturn<EntityIntegration>,
    ICustomerIntegrationIdRequest,
    ICustomerItemRequest,
    IBaseEntityItemRequest,
    IDescription
{
  // @DataMember
  public customerIntegrationId: string;

  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public updateEntityGroups: boolean;

  // @DataMember
  public entityGroups: string[];

  // @DataMember(IsRequired=true)
  public id: string;

  // @DataMember(IsRequired=true)
  public entity: EntityIntegration;

  // @DataMember
  public description: string;

  public constructor(init?: Partial<UpdateEntityIntegrationRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new EntityIntegration();
  }
  public getTypeName() {
    return 'UpdateEntityIntegrationRequest';
  }
}

// @Route("/entity/{id}/properties", "GET")
// @DataContract
export class GetEntityPropertiesRequest implements IReturn<any>, IBaseEntityItemRequest {
  // @DataMember(IsRequired=true)
  public id: string;

  public constructor(init?: Partial<GetEntityPropertiesRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return {};
  }
  public getTypeName() {
    return 'GetEntityPropertiesRequest';
  }
}

// @Route("/entity/{id}/properties", "PUT")
// @DataContract
export class UpdateEntityPropertiesRequest implements IReturn<any> {
  // @DataMember(IsRequired=true)
  public id: string;

  // @DataMember
  public description: string;

  // @DataMember
  public entity: { [index: string]: string };

  public constructor(init?: Partial<UpdateEntityPropertiesRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return {};
  }
  public getTypeName() {
    return 'UpdateEntityPropertiesRequest';
  }
}

// @Route("/entity/{id}/property/{property}", "GET")
// @DataContract
export class GetEntityPropertyRequest implements IReturn<string> {
  // @DataMember
  public property: string;

  // @DataMember(IsRequired=true)
  public id: string;

  public constructor(init?: Partial<GetEntityPropertyRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return '';
  }
  public getTypeName() {
    return 'GetEntityPropertyRequest';
  }
}

// @Route("/entity/{id}/property/{property}", "PUT")
// @DataContract
export class UpdateEntityPropertyRequest implements IReturn<string> {
  // @DataMember(IsRequired=true)
  public id: string;

  // @DataMember
  public description: string;

  // @DataMember
  public property: string;

  // @DataMember
  public value: string;

  public constructor(init?: Partial<UpdateEntityPropertyRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return '';
  }
  public getTypeName() {
    return 'UpdateEntityPropertyRequest';
  }
}

// @Route("/entities/property/{property}", "GET")
// @DataContract
export class GetEntitiesPropertyRequest implements IReturn<any> {
  // @DataMember
  public property: string;

  // @DataMember
  public filter: string;

  public constructor(init?: Partial<GetEntitiesPropertyRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return {};
  }
  public getTypeName() {
    return 'GetEntitiesPropertyRequest';
  }
}

// @Route("/customer/{customerId}/entity/{Id}/groups", "GET")
// @DataContract
export class GetItemEntityGroupsRequest
  implements IReturn<GetEntityGroupsResponse>, ICustomerItemRequest
{
  // @DataMember
  public id: string;

  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public entityGroups: string[];

  // @DataMember
  public itemsIds: string[];

  // @DataMember
  public onlyOwnedItems: boolean;

  // @DataMember
  public page: number;

  // @DataMember
  public items: number;

  // @DataMember
  public sort: string;

  // @DataMember
  public filter: string;

  // @DataMember
  public propertiesTest: { [index: string]: number };

  public constructor(init?: Partial<GetItemEntityGroupsRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new GetEntityGroupsResponse();
  }
  public getTypeName() {
    return 'GetItemEntityGroupsRequest';
  }
}

// @Route("/entities/remoteRepository/{repositoryId}/data", "GET")
// @DataContract
export class GetEntitiesRequest implements IReturn<GetEntitiesResponse> {
  // @DataMember
  public repositoryId: string;

  // @DataMember
  public modified?: string;

  // @DataMember
  public page: number;

  // @DataMember
  public items: number;

  // @DataMember
  public sort: string;

  // @DataMember
  public filter: string;

  // @DataMember
  public propertiesTest: { [index: string]: number };

  public constructor(init?: Partial<GetEntitiesRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new GetEntitiesResponse();
  }
  public getTypeName() {
    return 'GetEntitiesRequest';
  }
}

/**
 * Sign In
 */
// @Route("/auth", "OPTIONS,GET,POST,DELETE")
// @Route("/auth/{provider}", "OPTIONS,GET,POST,DELETE")
// @Api(Description="Sign In")
// @DataContract
export class Authenticate implements IReturn<AuthenticateResponse>, IPost {
  /**
   * AuthProvider, e.g. credentials
   */
  // @DataMember(Order=1)
  public provider: string;

  // @DataMember(Order=2)
  public state: string;

  // @DataMember(Order=3)
  public oauth_token: string;

  // @DataMember(Order=4)
  public oauth_verifier: string;

  // @DataMember(Order=5)
  public userName: string;

  // @DataMember(Order=6)
  public password: string;

  // @DataMember(Order=7)
  public rememberMe?: boolean;

  // @DataMember(Order=9)
  public errorView: string;

  // @DataMember(Order=10)
  public nonce: string;

  // @DataMember(Order=11)
  public uri: string;

  // @DataMember(Order=12)
  public response: string;

  // @DataMember(Order=13)
  public qop: string;

  // @DataMember(Order=14)
  public nc: string;

  // @DataMember(Order=15)
  public cnonce: string;

  // @DataMember(Order=17)
  public accessToken: string;

  // @DataMember(Order=18)
  public accessTokenSecret: string;

  // @DataMember(Order=19)
  public scope: string;

  // @DataMember(Order=20)
  public meta: { [index: string]: string };

  public constructor(init?: Partial<Authenticate>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new AuthenticateResponse();
  }
  public getTypeName() {
    return 'Authenticate';
  }
}

// @DataContract
export class PanelStandChangeCustomerRequest
  implements IReturn<boolean>, IDescription, ICustomerItemRequest, IBaseEntityItemRequest
{
  // @DataMember
  public description: string;

  // @DataMember
  public newCustomerId: string;

  // @DataMember
  public customerId: string;

  // @DataMember
  public id: string;

  public constructor(init?: Partial<PanelStandChangeCustomerRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return false;
  }
  public getTypeName() {
    return 'PanelStandChangeCustomerRequest';
  }
}

// @DataContract
export class UnbindControllerFromPanelRequest
  implements IReturn<Panel>, ICustomerItemRequest, IBaseEntityItemRequest, IDescription
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember(IsRequired=true)
  public id: string;

  // @DataMember(IsRequired=true)
  public entity: Panel;

  // @DataMember
  public description: string;

  public constructor(init?: Partial<UnbindControllerFromPanelRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new Panel();
  }
  public getTypeName() {
    return 'UnbindControllerFromPanelRequest';
  }
}