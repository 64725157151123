import { Injectable } from "@angular/core";
import {
  IDataSourceService,
  IPageParams,
} from "projects/msu-its-web-common/src/data/data-source";

import { Observable } from "rxjs";
import { map } from "rxjs/operators";


import { TfmsApiService } from "./tfms-api.service";
import { EntityEventLogDetails, GetDetectorDeviceEventTypesRequest } from "../dtos/tfms.dtos";
import { GetDetectorDeviceEventLogRequest } from "../dtos/tfms.dtos";

@Injectable({ providedIn: "root" })
export class DetectorDeviceEventLogService
  implements IDataSourceService<EntityEventLogDetails, EntityEventLogDetails> {
  constructor(private _api: TfmsApiService) {}

  getPagedList(
    params: IPageParams,
    requestParams: {
      id?: string;
      customerId: string;
      start?: string;
      end?: string;
      filter?: string;
      filterEvents?: number[];
    }
  ) {
    const request = new GetDetectorDeviceEventLogRequest();

    request.id = requestParams.id;
    request.customerId = requestParams.customerId;
    request.start = requestParams.start;
    request.end = requestParams.end;
    request.filter = requestParams.filter;
    request.filterEvents = requestParams.filterEvents;

    request.sort = params.sort;
    if (params.sort && params.desc) {
      request.sort += " desc";
    }

    return this._api.get(request).pipe(
      map((m) => {
        return { page: 0, size: 0, total: 0, data: m.items };
      })
    );
  }

  get(id: any): Observable<EntityEventLogDetails> {
    throw "not implemented";
  }

  add(model: EntityEventLogDetails): Observable<EntityEventLogDetails> {
    throw "not implemented";
  }

  update(model: EntityEventLogDetails): Observable<EntityEventLogDetails> {
    throw "not implemented";
  }

  delete(ids: string[]): Observable<number> {
    throw "not implemented";
  }

  getControllerEventTypes(id: string, customerId: string) {
    const request = new GetDetectorDeviceEventTypesRequest({
      id,
      customerId,
    } as any);
    return this._api.get(request);
  }
}
