import { Injectable, OnDestroy } from '@angular/core';
import { EMPTY, of, Subject, Subscription } from 'rxjs';
import { catchError, delay, mergeMap, repeat, tap } from 'rxjs/operators';

import {
  IGisObjectGroupEventService,
  IGisObjectStatus,
} from 'projects/msu-its-web-common/src/utils/gis-object-group';

import { GetTrafficObjectsStatusesRequest } from '../dtos/tlc.dtos';
import { TlcApiEventService } from './tlc-api-event.service';
import { TlcApiService } from './tlc-api.service';

@Injectable()
export class TrafficObjectGroupEventService implements IGisObjectGroupEventService, OnDestroy {
  _subscription: Subscription;
  permissions = ['trafficObject.read'];

  constructor(private _apiService: TlcApiService, private _eventService: TlcApiEventService) {}

  ngOnDestroy() {
    this.unsubscribe();
  }

  subscribe(customerId: string, groupIds: string[], updateSubject: Subject<IGisObjectStatus[]>) {
    this.unsubscribe();

    const request = new GetTrafficObjectsStatusesRequest({
      customerId,
      entityGroups: groupIds,
      items: 0,
      page: 0,
    });

    this._subscription = of(null)
      .pipe(
        mergeMap(() => this._apiService.getUnsafe(request).pipe(catchError(() => EMPTY))),
        tap((result) => updateSubject.next(result.items)),
        delay(2000),
        repeat()
      )
      .subscribe();
  }

  unsubscribe() {
    if (this._subscription) {
      this._subscription.unsubscribe();
      this._subscription = null;
    }
  }
}
