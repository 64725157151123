<table-layout
  [dataSource]="dataSource"
  [displayedColumns]="displayedColumns"
  [rowActions]="rowActions"
  [showSearch]="true"
  [showCreate]="false"
  titleText="{{ 'FIXATING_TRAFFIC_EVENTS.TABLE_TITLE' | translate }}"
  deleteText="{{ 'COMMON.DELETE' | translate }}"
  (addBtnClick)="add()"
>
  <!-- [getPermissions]="getPermissions" -->
  <ng-container table-layout-actions>

    <mat-form-field style="margin-right: 8px; width: 160px;">
      <input
        matInput
        type="datetime-local"
        [formControl]="startDateControl"
        class="spin-button"
      />
      <mat-icon class="date-button">today</mat-icon>
    </mat-form-field>

    <span style="margin-right: 8px;">-</span>

    <mat-form-field style="margin-right: 12px; width: 160px;">
      <input
        matInput
        type="datetime-local"
        [formControl]="endDateControl"
        class="spin-button"
      />
      <mat-icon class="date-button">today</mat-icon>
    </mat-form-field>


    <mat-form-field floatLabel="never">
      <mat-label
        >{{ "FIXATING_TRAFFIC_EVENTS.VIOLATION_TYPE" | translate }}</mat-label
      >
      <mat-select [formControl]="fixationViolationTypeControl">
        <mat-option>Ничего не выбрано</mat-option>
        <div *ngFor="let item of fixationViolationType">
          <mat-divider></mat-divider>
          <mat-option [value]="item.type">{{item.name | translate}}</mat-option>
        </div>
      </mat-select>
    </mat-form-field>

    <mat-form-field *ngIf="!external" fxFlex floatLabel="never">
      <mat-select
        (openedChange)="$event && searchInput.focus()"
        [formControl]="fixationPointIdControl"
        (selectionChange)="fixationPointSelected($event.value)"
        placeholder="{{ 'FIXATING_TRAFFIC_EVENTS.FIXATION_POINT' | translate }}"
        disableOptionCentering="true"
        panelClass="single-select-panel"
      >
        <div
          (click)="$event.stopPropagation()"
          class="mat-theme-bg"
          style="
            position: sticky;
            top: 0;
            z-index: 2;
            padding: 8px 12px;
            font-size: 14px;
          "
        >
          <input
            #searchInput
            class="mat-input-element"
            [placeholder]="('COMMON.SEARCH' | translate) + '...'"
            autocomplete="off"
            spellcheck="false"
            (keyup)="searchInputFixationPointSubject.next($event.target.value)"
            (input)="searchInputFixationPointSubject.next($event.target.value)"
            (keydown)="$event.stopPropagation()"
            (click)="$event.stopPropagation()"
            style="width: 300px;"
          />
        </div>
        <mat-option>Ничего не выбрано</mat-option>
        <mat-option
          *ngFor="let item of fixationPointListSubject | async"
          [value]="item.id"
          style="height: 40px !important;"
        >
          <div style="line-height: 1;">
            {{ item.name }}
          </div>
          <!-- <div
            style="
              line-height: 1;
              text-overflow: ellipsis;
              overflow: hidden;
            "
            class="mat-hint"
          >
            <small>{{ item.description }}</small>
          </div> -->
        </mat-option>
      </mat-select>
    </mat-form-field>
  </ng-container>

  <ng-container matSort>
    <ng-container matColumnDef="violationType">
      <mat-header-cell *matHeaderCellDef mat-sort-header>
        {{ "FIXATING_TRAFFIC_EVENTS.VIOLATION_TYPE" | translate }}
      </mat-header-cell>
      <mat-cell *matCellDef="let item">
        <b>{{ getTerminalType(item.violationType) | translate }}</b>
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="eventDate">
      <mat-header-cell *matHeaderCellDef mat-sort-header>
        {{ "FIXATING_TRAFFIC_EVENTS.EVENT_DATE" | translate }}
      </mat-header-cell>
      <mat-cell *matCellDef="let item">
        <b>
          {{ item.eventDate | date: "dd.MM.yyyy HH:mm":timezone:locale }}
        </b>
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="licensePlate">
      <mat-header-cell *matHeaderCellDef mat-sort-header>
        {{ "FIXATING_TRAFFIC_EVENTS.LICENSE_PLATE" | translate }}
      </mat-header-cell>
      <mat-cell *matCellDef="let item">
        <b>{{ item.licensePlate }}</b>
      </mat-cell>
    </ng-container>
  </ng-container>
</table-layout>
