import { Injectable } from '@angular/core';

import { Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import {
  IGisObject,
  IGisObjectGroup,
  IGisObjectGroupService,
} from 'projects/msu-its-web-common/src/utils/gis-object-group';

import {
  getPageReturn,
  IDataSourceService,
  IPageParams,
  IPageReturn,
  setPageParams,
} from 'projects/msu-its-web-common/src/data/data-source';

import {
  CreateAdaptiveSceneGroupRequest,
  DeleteAdaptiveSceneGroupsRequest,
  EntityGroup,
  EntityGroupInfo,
  GetItemEntityGroupsRequest,
  GetAdaptiveSceneGroupRequest,
  GetAdaptiveSceneGroupsRequest,
  AdaptiveScene,
  UpdateAdaptiveSceneGroupRequest,
} from '../dtos/tss.dtos';

import { TssApiService } from './tss-api.service';
import { AdaptiveSceneService } from './adaptive-scene.service';

class SceneGisObject extends AdaptiveScene implements IGisObject {
  get geoJson() {
    return this.settings.adaptiveSceneGeoJson;
  }
}

@Injectable()
export class AdaptiveSceneGroupService
  implements IDataSourceService<EntityGroup, EntityGroupInfo>, IGisObjectGroupService {
  constructor(private _api: TssApiService, private _adaptiveSceneService: AdaptiveSceneService) {}

  permissions = ['adaptiveScene.read'];

  getGroupList(customerId: string) {
    return this.getList(customerId);
  }
  getObjectList(customerId: string, groupIds: string[]) {
    return this._adaptiveSceneService
      .getList(customerId, null, groupIds)
      .pipe(map((m) => m.map((n) => Object.assign(new SceneGisObject(), n))));
  }

  getObjectGroups(objectId: string, customerId: string) {
    const request = new GetItemEntityGroupsRequest({
      id: objectId,
      customerId,
    });

    return this._api.get(request).pipe(map((m) => m.items));
  }

  getPagedList(params: IPageParams, customerId: string): Observable<IPageReturn<EntityGroupInfo>> {
    if (!customerId) {
      return of(null);
    }

    const request = new GetAdaptiveSceneGroupsRequest();
    request.customerId = customerId;

    setPageParams(request, params);
    return this._api.get(request).pipe(
      map((m) => {
        return getPageReturn(m);
      })
    );
  }
  getList(customerId: string, filter?: string, owned = false) {
    const request = new GetAdaptiveSceneGroupsRequest();
    request.customerId = customerId;

    request.page = 0;
    request.items = 0;
    request.sort = 'name';
    request.filter = filter;
    request.onlyOwnedItems = owned;

    return this._api.get(request).pipe(
      map((m) => {
        return m.items;
      })
    );
  }

  get(id: string, customerId: string) {
    const request = new GetAdaptiveSceneGroupRequest();
    request.id = id;
    request.customerId = customerId;
    return this._api.get(request);
  }
  add(model: EntityGroup, params: { customerId: string }) {
    const request = new CreateAdaptiveSceneGroupRequest();
    request.customerId = params.customerId;
    request.entity = model;
    return this._api.post(request);
  }
  update(model: EntityGroup, params: { customerId: string }) {
    const request = new UpdateAdaptiveSceneGroupRequest();
    request.id = model.id;
    request.entity = model;
    request.customerId = params.customerId;
    return this._api.put(request);
  }
  delete(ids: string[], customerId: string): Observable<number> {
    const request = new DeleteAdaptiveSceneGroupsRequest();
    request.ids = ids;
    request.customerId = customerId;
    return this._api.delete(request);
  }
  exist(name: string, customerId: string) {
    return of(false);
  }
}
