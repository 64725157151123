import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
} from "@angular/core";
import { ActivatedRoute } from "@angular/router";

import { DialogService } from "projects/msu-its-web-common/src/services/dialog.service";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { MeteoObject } from "../../dtos/meteo.dtos";
import { MeteoObjectsService } from "../../services/meteo-objects.service";
import { MeteoObjectEditComponent } from "../meteo-objects/meteo-object-edit.component";

@Component({
  selector: "meteo-object-edit-external",
  template: ``,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MeteoObjectEditExternalComponent
  implements AfterViewInit, OnDestroy {
  private _destroy = new Subject();

  objectId;
  customerId;

  constructor(
    private _route: ActivatedRoute,
    private _meteoObjectService: MeteoObjectsService,
    private _dialogService: DialogService
  ) {
    this.objectId = this._route.snapshot.paramMap.get("objectId")
      ? this._route.snapshot.paramMap.get("objectId")
      : null;
    this.customerId = this._route.snapshot.paramMap.get("customerId");
  }

  ngAfterViewInit() {
    if (this.objectId) {
      this._meteoObjectService
        .get(this.objectId, this.customerId)
        .pipe(takeUntil(this._destroy))
        .subscribe((model) => {
          const dialog = this._dialogService.dialog.open(
            MeteoObjectEditComponent,
            {
              disableClose: true,
              panelClass: "external-dialog",
              height: "756",
              width: "940",
            }
          );
          dialog.componentInstance.initForm(
            model,
            this._meteoObjectService,
            false,
            null
          );
          //   dialog.componentInstance.model = model;
          dialog.componentInstance.customerId = this.customerId;
          // dialog.componentInstance.external = true;
          //   dialog.componentInstance.external = true;
          //   dialog.componentInstance.modelId = this.objectId;
        });
    } else {
      const dialog = this._dialogService.dialog.open(MeteoObjectEditComponent, {
        disableClose: true,
        panelClass: "external-dialog",
        height: "756",
        width: "940",
      });
      const temp = new MeteoObject();
      dialog.componentInstance.customerId = this.customerId;
      dialog.componentInstance.initForm(
        temp,
        this._meteoObjectService,
        true,
        null
      );
      dialog.componentInstance.isNew = true;
    }
  }
  ngOnDestroy() {
    this._destroy.next();
    this._destroy.complete();
  }
}
