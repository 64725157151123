import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
} from "@angular/core";
import { ActivatedRoute } from "@angular/router";

import { DialogService } from "projects/msu-its-web-common/src/services/dialog.service";
import { forkJoin, Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { MeteoObjectPopupService } from "../../services/meteo-object-popup.service";
import { MeteoObjectsService } from "../../services/meteo-objects.service";
import { MeteoObjectHistoryComponent } from "../meteo-objects/meteo-object-history.component";

@Component({
  selector: "meteo-object-history-external",
  template: ``,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MeteoObjectHistoryExternalComponent
  implements AfterViewInit, OnDestroy {
  private _destroy = new Subject();
  objectId;
  customerId;

  constructor(
    private _route: ActivatedRoute,
    private _meteoObjectService: MeteoObjectsService,
    private _meteoObjectPopupService: MeteoObjectPopupService,
    private _dialogService: DialogService
  ) {
    this.objectId = this._route.snapshot.paramMap.get("objectId");
    this.customerId = this._route.snapshot.paramMap.get("customerId");
  }

  ngAfterViewInit() {
    forkJoin([
      this._meteoObjectService.get(this.objectId, this.customerId),
      this._meteoObjectPopupService.getAvailableMeteoObjectSensorsRequest(
        this.objectId,
        100,
        100,
        "",
        ""
      ),
    ])
      // .pipe(
      //   finalize(() => {

      //     this._changeDetector.markForCheck();
      //   })
      // )
      .pipe(takeUntil(this._destroy))
      .subscribe(([model, result]) => {
        const dialog = this._dialogService.dialog.open(
          MeteoObjectHistoryComponent,
          {
            disableClose: true,
            panelClass: "external-dialog",
          }
        );
        dialog.componentInstance.modelId = this.objectId;
        dialog.componentInstance.modelInfo = model;
        dialog.componentInstance.meteoSensor = result.items;
        dialog.componentInstance.customerId = this.customerId;
        dialog.componentInstance.external = true;
      });
  }
  ngOnDestroy() {
    this._destroy.next();
    this._destroy.complete();
  }
}
