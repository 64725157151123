import {
  Component,
  ChangeDetectorRef,
  Input,
  OnDestroy,
  ViewChild,
  ElementRef,
  ChangeDetectionStrategy,
  AfterViewInit,
} from '@angular/core';

import Hls from 'hls.js';

@Component({
  selector: 'web-camera',
  templateUrl: './web-camera.component.html',
  styles: [
    `
      .camera {
        display: block;
        width: 100%;
        height: 100%;
        border: none;
        background: #000;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WebCameraComponent implements OnDestroy, AfterViewInit {
  private _hls;
  private _url;

  @ViewChild('video', { static: false })
  video: ElementRef;

  @ViewChild('iframe', { static: false })
  iframe: ElementRef;

  get url() {
    return this._url;
  }
  @Input('url')
  set url(value) {
    // this._checkUrl(value).subscribe(url => {
    //   this._url = url;
    //   this._update();
    //   this._changeDetector.detectChanges();
    // });
    this._url = value;
    this._update();
    this._changeDetector.detectChanges();
  }

  constructor(
    // private _logService: LogService,
    private _changeDetector: ChangeDetectorRef
  ) {}

  ngAfterViewInit() {
    this.video.nativeElement.addEventListener('error', (event) => {
      // console.log(event);
    });
  }

  // private _checkUrl(url: string) {
  //   if (url && url.toLocaleLowerCase().indexOf('rcam.a42.ru') >= 0) {
  //     const id = url.match(/cam\d+\.stream/)[0];
  //     return Observable.from(fetch('https://pdd.a42.ru/camera/'))
  //       .catch(() => Observable.of(null))
  //       .switchMap((response: Response) => response ? Observable.from(response.text()) : Observable.of(''))
  //       .map(html => {
  //         return html ?
  //           new RegExp(`(https:\/\/rcam\.a42\.ru\/live\/${id}.*?key=.*?)'`, 'isg').exec(html)[1] : url;
  //       });
  //   }

  //   return Observable.of(url);
  // }

  ngOnDestroy() {
    if (this._hls) {
      this._hls.destroy();
    }
  }

  get isHls() {
    return this._url && /.*?\.m3u8($|\?.*?$)/gi.test(this._url);
  }

  get reloadTimeout() {
    return this._url && /.*?orionnet.*?/gi.test(this._url) ? 45000 : 0;
  }

  load() {
    this._update();
  }

  private _update() {
    if (!this._url) {
      return;
    }

    if (this._hls) {
      this._hls.destroy();
    }

    if (this.isHls) {
      if (this.video) {
        if (Hls.isSupported()) {
          this._hls = new Hls({ debug: false });
          this._hls.loadSource(this._url);

          this._hls.attachMedia(this.video.nativeElement);
          this._hls.on(Hls.Events.MANIFEST_PARSED, () => {
            this.video.nativeElement.play();
            this.video.nativeElement.muted = true;
            this.video.nativeElement.disablePictureInPicture = true;
          });
          this._hls.on(Hls.Events.ERROR, (event, details) => {
            if (details.type === 'mediaError') {
              setTimeout(() => {
                this.url = this._url;
              }, this.reloadTimeout);
            }
          });
        } else if (
          this.video.nativeElement.canPlayType('application/vnd.apple.mpegurl')
        ) {
          this.video.nativeElement.src = this._url;
          this.video.nativeElement.addEventListener('loadedmetadata', () => {
            this.video.nativeElement.play();
            this.video.nativeElement.muted = true;
          });
        }
      }
    } else {
      if (this.iframe && this._url !== this.iframe.nativeElement.src) {
        this.iframe.nativeElement.src = this._url;
      }
    }
  }
}
