import { marker as _ } from "@biesbjerg/ngx-translate-extract-marker";
import { NgModule } from "@angular/core";

import { ISidenavMenuCategory } from "projects/msu-its-web-common/src/utils/sidenav-menu";
import {
  GIS_OBJECT_GROUP,
  SIDENAV_MENU,
} from "projects/msu-its-web-common/src/utils/shared-consts";

import { BaseModule } from "projects/msu-its-web-common/src/modules/base.module";
import { MeteoStationsService } from "../../services/meteo-stations.service";
import { MeteoObjectsService } from "../../services/meteo-objects.service";
import { MeteoObjectPopupService } from "../../services/meteo-object-popup.service";
import { MeteoApiService } from "../../services/meteo-api.service";
import { MeteoApiEventService } from "../../services/meteo-api-event.service";
import { MeteoLogService } from "../../services/meteo-log.service";
import { MeteoObjectGroupService } from "../../services/meteo-object-group.service";
import { MeteoObjectGroupShareService } from "../../services/meteo-object-group-share.service";
import { MeteoObjectGroupEventService } from "../../services/meteo-object-group-event.service";
import { IGisObjectGroup } from "projects/msu-its-web-common/src/utils/gis-object-group";
import { MeteoObjectPopupComponent } from "../../components/meteo-popup/meteo-object-popup.component";
import { MeteoObjectExternalComponent } from "../../components/external/meteo-object-external.component";
import { MeteoObjectHistoryExternalComponent } from "../../components/external/meteo-object-history-external.component";
import { MeteoObjectEditExternalComponent } from "../../components/external/meteo-object-edit-external.component";
import { MeteoObjectHistoryComponent } from "../../components/meteo-objects/meteo-object-history.component";
import { MeteoObjectReportComponent } from "../../components/meteo-objects/meteo-object-report.component";
import { MeteoObjectLogComponent } from "../../components/meteo-objects/meteo-object-log.component";

const sidenav: ISidenavMenuCategory[] = [
  {
    name: _("SIDENAV_MENU.METEO"),
    items: [
      {
        name: _("METEO_OBJECTS.TITLE"),
        icon: "wb_cloudy",
        route: "meteo-objects",
        permissions: ["meteoObject.read"],
      },
    ],
  },
];

const meteObjectGroup: IGisObjectGroup = {
  type: "meteoObject",
  name: _("METEO_OBJECTS.TITLE"),
  groupServiceType: MeteoObjectGroupService,
  eventServiceType: MeteoObjectGroupEventService,
  popupComponentType: MeteoObjectPopupComponent,
  // stateComponentType: DetectorPopupComponent,
};
@NgModule({
  providers: [
    { provide: SIDENAV_MENU, useValue: sidenav, multi: true },
    { provide: GIS_OBJECT_GROUP, useValue: meteObjectGroup, multi: true },
    // { provide: GIS_OBJECT_GROUP, useValue: cameraGroup, multi: true },
    MeteoApiService,
    MeteoApiEventService,
    MeteoStationsService,
    MeteoObjectsService,
    MeteoObjectGroupService,
    MeteoObjectGroupShareService,
    MeteoObjectGroupEventService,
    MeteoObjectPopupService,
    MeteoLogService,
  ],
  imports: [BaseModule],
  declarations: [
    MeteoObjectPopupComponent,
    MeteoObjectExternalComponent,
    MeteoObjectHistoryExternalComponent,
    MeteoObjectEditExternalComponent,
    MeteoObjectHistoryComponent,
    MeteoObjectReportComponent,
    MeteoObjectLogComponent,
  ],
  entryComponents: [
    MeteoObjectPopupComponent,
    MeteoObjectExternalComponent,
    MeteoObjectHistoryExternalComponent,
    MeteoObjectEditExternalComponent,
    MeteoObjectHistoryComponent,
    MeteoObjectReportComponent,
    MeteoObjectLogComponent,
  ],
  exports: [
    MeteoObjectPopupComponent,
    MeteoObjectExternalComponent,
    MeteoObjectHistoryExternalComponent,
    MeteoObjectEditExternalComponent,
    MeteoObjectHistoryComponent,
    MeteoObjectReportComponent,
    MeteoObjectLogComponent,
  ],
})
export class MeteoSharedModule {}
