import { Injectable } from "@angular/core";
import {
  getPageReturn,
  IDataSourceService,
  IPageParams,
  IPageReturn,
  setPageParams,
} from "projects/msu-its-web-common/src/data/data-source";

import { Observable } from "rxjs";
import { map } from "rxjs/operators";

import { MeteoApiService } from "./meteo-api.service";
import {
  GetMeteoObjectReplacementsRequest,
  MeteoObjectReplacement,
} from "../dtos/meteo.dtos";

@Injectable()
export class MeteoLogService
  implements
    IDataSourceService<MeteoObjectReplacement, MeteoObjectReplacement> {
  constructor(private _api: MeteoApiService) {}

  getPagedList(
    params: IPageParams,
    requestParams: {
      customemrId?: string;
      detectorId?: string;
      beginDate?: string;
      endDate?: string;
    }
  ): Observable<IPageReturn<MeteoObjectReplacement>> {
    const request = new GetMeteoObjectReplacementsRequest();
    setPageParams(request, params);

    request.meteoObjectId = requestParams?.detectorId;
    request.customerId = requestParams?.customemrId;
    request.from = requestParams?.beginDate;
    request.to = requestParams?.endDate;

    return this._api.get(request).pipe(
      map((m) => {
        return getPageReturn(m);
      })
    );
  }

  getList(
    filter?: string,
    detectorId?: string,
    beginDate?: string,
    endDate?: string,
    count = 20
  ) {
    const request = new GetMeteoObjectReplacementsRequest();

    request.meteoObjectId = detectorId;
    request.from = beginDate;
    request.to = endDate;

    request.page = 0;
    request.items = count;
    request.filter = filter;

    request.sort = "activityTime desc";

    return this._api.get(request).pipe(
      map((m) => {
        return getPageReturn(m).data;
      })
    );
  }

  get(id: any): Observable<MeteoObjectReplacement> {
    throw "not implemented";
  }

  add(model: MeteoObjectReplacement): Observable<MeteoObjectReplacement> {
    throw "not implemented";
  }

  update(model: MeteoObjectReplacement): Observable<MeteoObjectReplacement> {
    throw "not implemented";
  }

  delete(ids: string[]): Observable<number> {
    throw "not implemented";
  }
}
