<table-layout-dialog
  [dataSource]="dataSource"
  [displayedColumns]="displayedColumns"
  [rowActions]="rowActions"
  titleText="{{ 'DETECTORS.HISTORY_CHANGE_DEVICE' | translate }}{{ entityName ? ': ' + entityName : '' }}"
  [showCreate]="false"
  [showDelete]="false"
  [showRefresh]="false"
>
  <ng-container table-layout-actions>
    <!-- <span>{{ "COMMON.DATE_RANGE" | translate }}</span> -->
    <mat-form-field style="margin-right: 8px; width: 160px">
      <input matInput type="datetime-local" [formControl]="fromControl" class="spin-button" />
      <mat-icon class="date-button">today</mat-icon>
    </mat-form-field>

    <span style="margin-right: 8px">-</span>

    <mat-form-field style="margin-right: 12px; width: 160px">
      <input matInput type="datetime-local" [formControl]="toControl" class="spin-button" />
      <mat-icon class="date-button">today</mat-icon>
    </mat-form-field>
  </ng-container>

  <ng-container table-layout-actions-end>
    <div style="padding-left: 8px">
      <button mat-raised-button color="accent" (click)="dataSource?.reloadData()">
        <mat-icon>loop</mat-icon>
        {{ "COMMON.REFRESH" | translate }}
      </button>
    </div>
  </ng-container>

  <ng-container ngPackageAs="table-layout-columns" matSort>
    
    <ng-container matColumnDef="date">
      <mat-header-cell *matHeaderCellDef mat-sort-header>
        {{ "DETECTORS.LOG.DATE" | translate }}
      </mat-header-cell>
      <mat-cell *matCellDef="let item">
        <b>
          {{ item.date | date: "dd.MM.yyyy HH:mm":timezone:locale }}
        </b>
      </mat-cell>
    </ng-container>


    <ng-container matColumnDef="customerName">
      <mat-header-cell *matHeaderCellDef mat-sort-header>
        {{ "DETECTORS.LOG.CUSTOMER_NAME" | translate }}
      </mat-header-cell>
      <mat-cell *matCellDef="let item">
        <b>{{ item.customerName }}</b>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="modifiedByName">
      <mat-header-cell *matHeaderCellDef mat-sort-header>
        {{ "DETECTORS.LOG.MODIFIED_BY_NAME" | translate }}
      </mat-header-cell>
      <mat-cell *matCellDef="let item">
        <b>{{ item.modifiedByName }}</b>
      </mat-cell>
    </ng-container>


    <ng-container matColumnDef="oldDeviceNumber">
      <mat-header-cell *matHeaderCellDef mat-sort-header>
        {{ "DETECTORS.LOG.OLD_DEVICE_NUMBER" | translate }}
      </mat-header-cell>
      <mat-cell *matCellDef="let item">
        <b>{{ item.oldDeviceNumber }}</b>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="newDeviceNumber">
      <mat-header-cell *matHeaderCellDef mat-sort-header>
        {{ "DETECTORS.LOG.NEW_DEVICE_NUMBER" | translate }}
      </mat-header-cell>
      <mat-cell *matCellDef="let item">
        <b>{{ item.newDeviceNumber }}</b>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="reason">
      <mat-header-cell *matHeaderCellDef mat-sort-header>
        {{ "COMMON.REASON" | translate }}
      </mat-header-cell>
      <mat-cell *matCellDef="let item">
        {{ getReplaceName(item.reason) | translate }}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="description">
      <mat-header-cell *matHeaderCellDef mat-sort-header>
        {{ "DETECTORS.LOG.DESCRIPTION" | translate }}
      </mat-header-cell>
      <mat-cell *matCellDef="let item">
        <b>{{ item.description }}</b>
      </mat-cell>
    </ng-container>

  </ng-container>
  
</table-layout-dialog>