import { marker as _ } from "@biesbjerg/ngx-translate-extract-marker";
import {
  BaseUnit,
  CameraCommand,
  Category,
  FrostsProbabilities,
  MeteoStationType,
  PrecipitationType,
  ReplacementReason,
} from "../dtos/meteo.dtos";

export const METEO_STATION_TYPE = [
  //   { type: MeteoStationType.None, name: _('METEO_OBJECTS.STATIONS.TYPE_NONE'), prefix: 'optic#' },
  {
    type: MeteoStationType.Briz,
    name: _("METEO_OBJECTS.STATIONS.TYPE_BRIZ_STATION"),
    prefix: "briz#",
  },
  {
    type: MeteoStationType.Burstroy,
    name: _("METEO_OBJECTS.STATIONS.TYPE_BURSTROY"),
    prefix: "burstroy#",
  },
  {
    type: MeteoStationType.Vaisala,
    name: _("METEO_OBJECTS.STATIONS.TYPE_VAISALA"),
    prefix: "vaisala#",
  },
];
export const METEO_STATION_NAME = [
  {
    name: "SurfaceConditionSensor",
    nameTransl: _("METEO_OBJECTS.SENSORS.SURFACE_ONDITION_SENSOR"),
  },
  {
    name: "MeteoSensor",
    nameTransl: _("METEO_OBJECTS.SENSORS.METEO_SENSOR"),
  },
  {
    name: "VisibilitySensor",
    nameTransl: _("METEO_OBJECTS.SENSORS.VISIBILITY_SENSOR"),
  },
  {
    name: "RoadSensor",
    nameTransl: _("METEO_OBJECTS.SENSORS.ROAD_SENSOR"),
  },
  {
    name: "EcologySensor",
    nameTransl: _("METEO_OBJECTS.SENSORS.ECOLOGY_SENSOR"),
  },
  {
    name: "RoadTempSensor",
    nameTransl: _("METEO_OBJECTS.SENSORS.ROAD_TEMP_SENSOR"),
  },
  {
    name: "TempSensor",
    nameTransl: _("METEO_OBJECTS.SENSORS.TEMP_SENSOR"),
  },
  {
    name: "WeatherSensor",
    nameTransl: _("METEO_OBJECTS.SENSORS.WEATHER_SENSOR"),
  },
  {
    name: "WindSensor",
    nameTransl: _("METEO_OBJECTS.SENSORS.WIND_SENSOR"),
  },
  {
    name: "PressureSensor",
    nameTransl: _("METEO_OBJECTS.SENSORS.PRESSURE_SENSOR"),
  },
];

export const COTROL_CAMERA = [
  { name: "MoveLeft", command: CameraCommand.MoveLeft },
  { name: "MoveRight", command: CameraCommand.MoveRight },
  { name: "MoveUp", command: CameraCommand.MoveUp },
  { name: "MoveDown", command: CameraCommand.MoveDown },
  { name: "MoveHome", command: CameraCommand.MoveHome },
  { name: "LightOn", command: CameraCommand.LightOn },
  { name: "LightOff", command: CameraCommand.LightOff },
  { name: "Wipe", command: CameraCommand.Wipe },
];
export const METEO_NAME = [
  {
    type: "HumidityPercent",
    name: _("METEO_OBJECTS.NAME_PARAMETR.HUMIDITY_PERCENT"),
  },
  {
    type: "AirTemperature",
    name: _("METEO_OBJECTS.NAME_PARAMETR.AIR_TEMPERATURE"),
  },
  {
    type: "AtmosphericPressure",
    name: _("METEO_OBJECTS.NAME_PARAMETR.ATMOSPHERIC_PRESSURE"),
  },
  {
    type: "DewPointTemperature",
    name: _("METEO_OBJECTS.NAME_PARAMETR.DEW_POINT_TEMPERATURE"),
  },
  { type: "WindSpeed", name: _("METEO_OBJECTS.NAME_PARAMETR.WIND_SPEED") },
  {
    type: "WindDirection",
    name: _("METEO_OBJECTS.NAME_PARAMETR.WIND_DIRECTION"),
  },
  { type: "WindGust", name: _("METEO_OBJECTS.NAME_PARAMETR.WIND_GUST") },
  {
    type: "Precipitation",
    name: _("METEO_OBJECTS.NAME_PARAMETR.PRECIPITATION"),
  },
  {
    type: "PrecipitationType",
    name: _("METEO_OBJECTS.NAME_PARAMETR.PRECIPITATION_TYPE"),
  },
  { type: "Voltage", name: _("METEO_OBJECTS.NAME_PARAMETR.VOLTAGE") },
  {
    type: "SurfaceTemperature",
    name: _("METEO_OBJECTS.NAME_PARAMETR.SURFACE_TEMPERATURE"),
  },
  {
    type: "AbrasivesFreezingPointTemperature",
    name: _("METEO_OBJECTS.NAME_PARAMETR.ABRASIVES_FREEZING_POINT_TEMPERATURE"),
  },
  {
    type: "WaterLayerThickness",
    name: _("METEO_OBJECTS.NAME_PARAMETR.WATER_LAYER_THICKNESS"),
  },
  {
    type: "SnowLayerThickness",
    name: _("METEO_OBJECTS.NAME_PARAMETR.SNOW_LAYER_THICKNESS"),
  },
  {
    type: "IceLayerThickness",
    name: _("METEO_OBJECTS.NAME_PARAMETR.ICE_LAYER_THICKNESS"),
  },
  {
    type: "AbrasivesPercentage",
    name: _("METEO_OBJECTS.NAME_PARAMETR.ABRASIVES_PERCENTAGE"),
  },
  {
    type: "SnowIcePercentage",
    name: _("METEO_OBJECTS.NAME_PARAMETR.SNOW_ICE_PERCENTAGE"),
  },
  {
    type: "SurfaceStatus",
    name: _("METEO_OBJECTS.NAME_PARAMETR.SURFACE_STATUS"),
  },
  {
    type: "SensorSurfaceGrip",
    name: _("METEO_OBJECTS.NAME_PARAMETR.SENSOR_SURFACE_GRIP"),
  },
  {
    type: "SensorPollution",
    name: _("METEO_OBJECTS.NAME_PARAMETR.SENSOR_POLLUTION"),
  },
  { type: "Visibility", name: _("METEO_OBJECTS.NAME_PARAMETR.VISIBILITY") },
  {
    type: "RoadTemperature",
    name: _("METEO_OBJECTS.NAME_PARAMETR.ROAD_TEMPERATURE"),
  },
  { type: "EcoNO2", name: _("METEO_OBJECTS.NAME_PARAMETR.ECONO2") },
  { type: "EcoNO", name: _("METEO_OBJECTS.NAME_PARAMETR.ECONO") },
  // { type: 'EcoNO2', name: 'NO' + '2'.sub },
  { type: "EcoO3", name: _("METEO_OBJECTS.NAME_PARAMETR.ECOO3") },
  { type: "EcoCO", name: _("METEO_OBJECTS.NAME_PARAMETR.ECOCO") },
  { type: "EcoSO2", name: _("METEO_OBJECTS.NAME_PARAMETR.ECOSO2") },
  { type: "EcoPM2", name: _("METEO_OBJECTS.NAME_PARAMETR.ECOPM2") },
  { type: "EcoPM10", name: _("METEO_OBJECTS.NAME_PARAMETR.ECOPM10") },
  { type: "SubSurfaceTemperature", name: _("METEO_OBJECTS.NAME_PARAMETR.SUB_SURFACE_TEMPERATURE") },
];
export const METEO_CATEGORY_NAME = [
  //   { type: MeteoStationType.None, name: _('METEO_OBJECTS.STATIONS.TYPE_NONE'), prefix: 'optic#' },
  {
    type: Category.Angle,
    name: _("METEO_OBJECTS.CATEGORY.ANGLE"),
    unit: _("METEO_OBJECTS.UNIT.ANGLE"),
  },
  {
    type: Category.Concentration,
    name: _("METEO_OBJECTS.CATEGORY.CONCENTRATION"),
    unit: _("METEO_OBJECTS.UNIT.CONCENTRATION"),
  },
  {
    type: Category.Current,
    name: _("METEO_OBJECTS.CATEGORY.CURRENT"),
    unit: _("METEO_OBJECTS.UNIT.CURRENT"),
  },
  {
    type: Category.Distance,
    name: _("METEO_OBJECTS.CATEGORY.DISTANCE"),
    unit: _("METEO_OBJECTS.UNIT.DISTANCE"),
  },
  {
    type: Category.Humidity,
    name: _("METEO_OBJECTS.CATEGORY.HUMIDITY"),
    unit: _("METEO_OBJECTS.UNIT.HUMIDITY"),
  },
  {
    type: Category.Power,
    name: _("METEO_OBJECTS.CATEGORY.POWER"),
    unit: _("METEO_OBJECTS.UNIT.POWER"),
  },
  {
    type: Category.Precipitation,
    name: _("METEO_OBJECTS.CATEGORY.PRECIPITATION"),
    unit: _("METEO_OBJECTS.UNIT.PRECIPITATION"),
  },
  {
    type: Category.Pressure,
    name: _("METEO_OBJECTS.CATEGORY.PRESSURE"),
    unit: _("METEO_OBJECTS.UNIT.PRESSURE"),
  },
  {
    type: Category.Speed,
    name: _("METEO_OBJECTS.CATEGORY.SPEED"),
    unit: _("METEO_OBJECTS.UNIT.SPEED"),
  },
  {
    type: Category.State,
    name: _("METEO_OBJECTS.CATEGORY.STATE"),
    unit: _("METEO_OBJECTS.UNIT.STATE"),
  },
  {
    type: Category.Temperature,
    name: _("METEO_OBJECTS.CATEGORY.TEMPERATURE"),
    // unit: _('METEO_OBJECTS.UNIT.TEMPERATURE'),
    unit: String.fromCharCode(176) + "C",
  },
  {
    type: Category.Thickness,
    name: _("METEO_OBJECTS.CATEGORY.THICKNESS"),
    unit: _("METEO_OBJECTS.UNIT.THICKNESS"),
  },
  {
    type: Category.Traction,
    name: _("METEO_OBJECTS.CATEGORY.TRACTION"),
    unit: _("METEO_OBJECTS.UNIT.TRACTION"),
  },
  {
    type: Category.Voltage,
    name: _("METEO_OBJECTS.CATEGORY.VOLTAGE"),
    unit: _("METEO_OBJECTS.UNIT.VOLTAGE"),
  },
];

export const METEO_UNITS = [
  {
    type: BaseUnit.Statement,
    name: _("METEO_OBJECTS.UNIT.STATEMENT"),
  },
  {
    type: BaseUnit.Number,
    name: _("METEO_OBJECTS.UNIT.NUMBER"),
  },
  {
    type: BaseUnit.DegreeCelsius,
    name: String.fromCharCode(176) + "C",
  },
  {
    type: BaseUnit.Degree,
    name: String.fromCharCode(176),
  },
  {
    type: BaseUnit.Percent,
    name: "%",
  },
  {
    type: BaseUnit.Millimeters,
    name: _("METEO_OBJECTS.UNIT.MILLIMETERS"),
  },
  {
    type: BaseUnit.MillimetersHg,
    name: _("METEO_OBJECTS.UNIT.MILLIMETERSHG"),
  },
  {
    type: BaseUnit.Gigapascal,
    name: _("METEO_OBJECTS.UNIT.GIGAPASCAL"),
  },
  {
    type: BaseUnit.MetersPerSecond,
    name: _("METEO_OBJECTS.UNIT.METERSPERSECOND"),
  },
  {
    type: BaseUnit.Meters,
    name: _("METEO_OBJECTS.UNIT.METERS"),
  },
  {
    type: BaseUnit.Coefficient,
    name: _("METEO_OBJECTS.UNIT.COEFFICIENT"),
  },
  {
    type: BaseUnit.MiligramsPerCubicMeter,
    name: _("METEO_OBJECTS.UNIT.MILIGRAMSPERCUBICMETER"),
  },
  {
    type: BaseUnit.MicrogramsPerCubicMeter,
    name: _("METEO_OBJECTS.UNIT.MICROGRAMSPERCUBICMETER"),
  },
  {
    type: BaseUnit.Volt,
    name: _("METEO_OBJECTS.UNIT.VOLT"),
  },
  {
    type: BaseUnit.Ampere,
    name: "A",
  },
  {
    type: BaseUnit.Watt,
    name: _("METEO_OBJECTS.UNIT.WATT"),
  },
];

export const TYPE_PRECIPITATION = [
  {
    type: PrecipitationType.Drizzle,
    name: _("METEO_OBJECTS.FORECAST.PRECIPITATION.DRIZZLE"),
  },
  {
    type: PrecipitationType.None,
    name: _("METEO_OBJECTS.FORECAST.PRECIPITATION.NONE "),
  },
  {
    type: PrecipitationType.Rain,
    name: _("METEO_OBJECTS.FORECAST.PRECIPITATION.RAIN "),
  },
  {
    type: PrecipitationType.IcyRain,
    name: _("METEO_OBJECTS.FORECAST.PRECIPITATION.ICYRAIN "),
  },
  {
    type: PrecipitationType.SnowAndRain,
    name: _("METEO_OBJECTS.FORECAST.PRECIPITATION.SNOWANDRAIN "),
  },
  {
    type: PrecipitationType.Snow,
    name: _("METEO_OBJECTS.FORECAST.PRECIPITATION.SNOW "),
  },
  {
    type: PrecipitationType.Hail,
    name: _("METEO_OBJECTS.FORECAST.PRECIPITATION.HAIL "),
  },
  {
    type: PrecipitationType.Unknown,
    name: _("METEO_OBJECTS.FORECAST.PRECIPITATION.UNKNOWN "),
  },
];
export const FROSTS_PROBABILITIES = [
  {
    type: FrostsProbabilities.Low,
    name: _("METEO_OBJECTS.FORECAST.FROSTS_PROBABILITIES.LOW"),
  },
  {
    type: FrostsProbabilities.Medium,
    name: _("METEO_OBJECTS.FORECAST.FROSTS_PROBABILITIES.MEDIUM"),
  },
  {
    type: FrostsProbabilities.High,
    name: _("METEO_OBJECTS.FORECAST.FROSTS_PROBABILITIES.HIGH"),
  },
];
export const DETECTOR_REASON_REPLACE = [
  { status: ReplacementReason.Demount, name: _("DETECTORS.REASON_DEMOUNTED") },
  { status: ReplacementReason.Replace, name: _("DETECTORS.REASON_REPLACE") },
];
