import { Injectable } from '@angular/core';

import { Observable, of, timer } from 'rxjs';
import { delay, map, switchMap, timeout } from 'rxjs/operators';

import {
  IDataSourceService,
  setPageParams,
  getPageReturn,
  IPageParams,
  IPageReturn,
} from 'projects/msu-its-web-common/src/data/data-source';

import { TssApiService } from './tss-api.service';

import {
  CreateTrafficSceneRequest,
  DeleteTrafficSceneRequest,
  GetTrafficSceneAnalysisRequest,
  GetTrafficSceneRequest,
  GetTrafficScenesDetailsRequest,
  GetTrafficScenesRequest,
  GetTrafficSceneStatusRequest,
  StartTrafficSceneAdaptiveModeRequest,
  StartTrafficSceneGreenStreetModeRequest,
  StartTrafficSceneObjectStepManualRequest,
  StartTrafficSceneRedStreetModeRequest,
  StopTrafficSceneControlMode,
  StopTrafficSceneObjectStepManualRequest,
  TrafficObjectSwitchMode,
  TrafficScene,
  TrafficSceneControlMode,
  TrafficSceneInfo,
  UpdateTrafficSceneRequest,
} from '../dtos/tss.dtos';

@Injectable()
export class TrafficSceneService implements IDataSourceService<TrafficScene, TrafficSceneInfo> {
  constructor(private _api: TssApiService) {}

  getPagedList(params: IPageParams, customerId: string): Observable<IPageReturn<TrafficSceneInfo>> {
    if (!customerId) {
      return of(null);
    }

    const request = new GetTrafficScenesRequest();
    request.customerId = customerId;

    setPageParams(request, params);
    return this._api.get(request).pipe(
      map((m) => {
        return getPageReturn(m);
      })
    );
  }
  getList(customerId: string, filter?: string, groupIds?: string[]) {
    const request = new GetTrafficScenesDetailsRequest();
    request.customerId = customerId;
    request.entityGroups = groupIds;

    request.page = 0;
    request.items = 0;
    request.sort = 'name';
    request.filter = filter;

    return this._api.get(request).pipe(
      map((m) => {
        return m.items;
      })
    );
  }

  get(id: string, customerId: string) {
    const request = new GetTrafficSceneRequest();
    request.id = id;
    request.customerId = customerId;
    return this._api.get(request);
  }
  add(
    model: TrafficScene,
    params: {
      customerId: string;
      groups: string[];
      updateGroups: boolean;
    }
  ) {
    const request = new CreateTrafficSceneRequest();
    request.entity = model;
    request.customerId = params.customerId;
    request.entityGroups = params.groups;
    request.updateEntityGroups = params.updateGroups;
    return this._api.post(request);
  }
  update(
    model: TrafficScene,
    params: { customerId: string; groups: string[]; updateGroups: boolean }
  ) {
    const request = new UpdateTrafficSceneRequest();
    request.id = model.id;
    request.entity = model;
    request.customerId = params.customerId;
    request.entityGroups = params.groups;
    request.updateEntityGroups = params.updateGroups;
    return this._api.put(request);
  }
  delete(ids: string[], customerId: string) {
    const request = new DeleteTrafficSceneRequest();
    request.ids = ids;
    request.customerId = customerId;
    return this._api.delete(request);
  }
  exist(name: string, customerId: string): Observable<boolean> {
    // const request = new ExistTrafficSceneNameRequest();
    // request.name = name;
    // request.customerId = customerId;
    // return this._api.get(request);
    return of(false);
  }

  startGreenStreetMode(
    id: string,
    customerId: string,
    forwarDirection: boolean,
    switchMode = TrafficObjectSwitchMode.Parallel,
    modeTime = 300
  ) {
    const request = new StartTrafficSceneGreenStreetModeRequest();

    request.id = id;
    request.customerId = customerId;
    request.forwardDirection = forwarDirection;
    request.switchMode = switchMode;
    request.modeTime = modeTime;

    return this._api.post(request, { customerId });
  }
  startRedStreetMode(
    id: string,
    customerId: string,
    forwarDirection: boolean,
    switchMode = TrafficObjectSwitchMode.Parallel,
    modeTime = 300
  ) {
    const request = new StartTrafficSceneRedStreetModeRequest();

    request.id = id;
    request.customerId = customerId;
    request.forwardDirection = forwarDirection;
    request.switchMode = switchMode;
    request.modeTime = modeTime;

    return this._api.post(request, { customerId });
  }
  startAdaptiveMode(
    id: string,
    customerId: string,
    forwarDirection: boolean,
    switchMode = TrafficObjectSwitchMode.Parallel,
    modeTime = 300
  ) {
    const request = new StartTrafficSceneAdaptiveModeRequest();

    request.id = id;
    request.customerId = customerId;
    request.forwardDirection = forwarDirection;
    request.switchMode = switchMode;
    request.modeTime = modeTime;

    return this._api.post(request, { customerId });
  }
  stopControlMode(id: string, customerId: string) {
    const request = new StopTrafficSceneControlMode();

    request.id = id;
    request.customerId = customerId;

    return this._api.post(request, { customerId });
  }

  startManual(
    id: string,
    customerId: string,
    objectId: string,
    mode: TrafficSceneControlMode,
    forward: boolean,
    modeTime = 300
  ) {
    const request = new StartTrafficSceneObjectStepManualRequest();

    request.id = id;
    request.customerId = customerId;
    request.trafficObjectId = objectId;
    request.forwardDirection = forward;
    request.controlMode = mode;
    request.modeTime = modeTime;

    return this._api.post(request, { customerId });
  }
  stopManual(id: string, customerId: string, objectId: string) {
    const request = new StopTrafficSceneObjectStepManualRequest();

    request.id = id;
    request.customerId = customerId;
    request.trafficObjectId = objectId;

    return this._api.post(request, { customerId });
  }

  getStatus(id: string, customerId: string) {
    const request = new GetTrafficSceneStatusRequest();

    request.id = id;
    request.customerId = customerId;

    return this._api.getUnsafe(request);
  }

  getAnalysis(
    id: string,
    customerId: string,
    startTime: string,
    finishTime: string,
    dayOfWeek: number
  ) {
    const request = new GetTrafficSceneAnalysisRequest();

    request.id = id;
    request.customerId = customerId;

    request.startTime = startTime;
    request.finishTime = finishTime;
    request.dayOfWeek = dayOfWeek;

    return timer(5000).pipe(switchMap(() => this._api.getUnsafe(request)));
  }
}
