import { Inject, Injectable } from '@angular/core';
import { catchError, map } from 'rxjs/operators';

import { TokenService } from 'projects/msu-its-web-common/src/services/token.service';
import { APP_ENVIRONMENT } from 'projects/msu-its-web-common/src/utils/shared-consts';

import {
  DashboardSettings,
  GetCameraGroupsRequest,
  GetCameraGroupsResponse,
  GetDashboardSettingsRequest,
  UpdateDashboardSettingsRequest,
} from '../dtos/dashboard.dtos';

import { DashboardApiService } from './dashboard-api.service';
import { of } from 'rxjs';

@Injectable()
export class DashboardSettingsService {
  constructor(
    private _api: DashboardApiService,
    private _tokenService: TokenService,
    @Inject(APP_ENVIRONMENT) private _appEnv
  ) {}

  getSettings(customerId: string) {
    const request = new GetDashboardSettingsRequest();
    request.customerId = customerId;
    return this._api.get(request);
  }

  updateSettings(settings: DashboardSettings, customerId: string) {
    const request = new UpdateDashboardSettingsRequest();
    request.entity = settings;
    request.customerId = customerId;
    settings.customerId = customerId;
    request.id = settings.id;
    return this._api.put(request);
  }

  getCamarasGroups(customerId: string) {
    const request = new GetCameraGroupsRequest();
    request.customerId = customerId;
    request.items = 0;
    request.page = 0;
    request.sort = 'name';
    return this._api
      .get(request)
      .pipe(
        catchError(() => {
          return of(new GetCameraGroupsResponse({ items: [] }));
        })
      )
      .pipe(map((m) => m.items));
  }
}
