import { Component, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'confirm-dialog',
  template: `
    <mat-dialog-content>
      {{message}}
    </mat-dialog-content>
    <mat-dialog-actions align="end">
      <button mat-button [mat-dialog-close]="true">{{'COMMON.YES'|translate}}</button>
      <button mat-button [mat-dialog-close]="false">{{'COMMON.NO'|translate}}</button>
    </mat-dialog-actions>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConfirmDialogComponent {
  public message: string;
}
