import { Injectable } from '@angular/core';
import {
  IDataSourceService,
  IPageParams,
  IPageReturn,
  setPageParams,
  getPageReturn,
} from 'projects/msu-its-web-common/src/data/data-source';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  CreateParkingCameraRequest,
  DeleteParkingCamerasRequest,
  GetParkingCameraControlSettingsRequest,
  GetParkingCameraRequest,
  GetParkingCamerasRequest,
  ParkingCamera,
  ParkingCameraControlSettings,
  UpdateParkingCameraControlSettingsRequest,
  UpdateParkingCameraRequest,
} from '../../dtos/parking.dtos';

import { ParkingApiService } from '../parking-api.service';

@Injectable()
export class ParkingCameraService implements IDataSourceService<ParkingCamera, ParkingCamera> {
  constructor(private _api: ParkingApiService) {}

  getPagedList(params: IPageParams, customerId: string): Observable<IPageReturn<ParkingCamera>> {
    const request = new GetParkingCamerasRequest();
    request.customerId = customerId;
    setPageParams(request, params);
    return this._api.get(request).pipe(
      map((m) => {
        return getPageReturn(m);
      })
    );
  }

  getList(customerId: string, filter?: string, groupIds?: string[]): Observable<ParkingCamera[]> {
    const request = new GetParkingCamerasRequest();
    request.customerId = customerId;

    request.entityGroups = groupIds;
    request.page = 0;
    request.items = 0;
    request.sort = 'name';
    request.filter = filter;

    return this._api.get(request).pipe(
      map((m) => {
        return m.items;
      })
    );
  }

  // getList(customerId: string, filter?: string, groupIds?: string[]): Observable<Camera[]> {
  //   const request = new GetCamerasPageRequest();
  //   request.customerId = customerId;
  //   request.entityGroups = groupIds;
  //   request.page = 0;
  //   request.items = 0;
  //   request.sort = 'name';
  //   request.filter = filter;

  //   return this._api.get(request).pipe(
  //     map((m) => {
  //       return m.items;
  //     })
  //   );
  // }

  get(id: any, customerId: string): Observable<ParkingCamera> {
    const request = new GetParkingCameraRequest();
    request.customerId = customerId;
    request.id = id;
    return this._api.get(request);
  }

  add(
    model: ParkingCamera,
    params: {
      customerId: string;
      groups: string[];
      updateGroups: boolean;
    }
  ) {
    const request = new CreateParkingCameraRequest();
    request.customerId = params.customerId;
    request.entity = model;
    request.entityGroups = params.groups;
    request.updateEntityGroups = params.updateGroups;
    return this._api.post(request);
  }

  update(
    model: ParkingCamera,
    params: {
      customerId: string;
      groups: string[];
      updateGroups: boolean;
    }
  ) {
    const request = new UpdateParkingCameraRequest();
    request.customerId = params.customerId;
    request.id = model.id;
    request.entity = model;
    request.entityGroups = params.groups;
    request.updateEntityGroups = params.updateGroups;
    return this._api.put(request);
  }

  delete(ids: string[], customerId: string): Observable<number> {
    const request = new DeleteParkingCamerasRequest();
    request.customerId = customerId;
    request.ids = ids;
    return this._api.delete(request);
  }

  getControlSettings(id: string, customerId: string) {
    const request = new GetParkingCameraControlSettingsRequest({ id, customerId });
    return this._api.get(request);
  }

  setControlSettings(id: string, customerId: string, settings: ParkingCameraControlSettings) {
    const request = new UpdateParkingCameraControlSettingsRequest({
      id,
      customerId,
      entity: settings,
    });
    return this._api.put(request);
  }

  //   getPermissions(id: string, customerId: string) {
  //     const request = new GetParkingCameraCurrentUserPermissionsRequest();
  //     request.id = id;
  //     request.customerId = customerId;
  //     return this._api.get(request);
  //   }
}
