import { Injectable } from '@angular/core';

import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import {
  getPageReturn,
  IDataSourceService,
  IPageParams,
  IPageReturn,
  setPageParams,
} from 'projects/msu-its-web-common/src/data/data-source';

import {
  TrafficObjectController,
  TrafficObjectControllerInfo,
  CreateTrafficObjectControllerRequest,
  DeleteTrafficObjectControllersRequest,
  GetTrafficObjectControllerRequest,
  GetTrafficObjectControllersRequest,
  UpdateTrafficObjectControllerRequest,
  TrafficControllerType,
  GetTrafficObjectControllerExistRequest,
  DisconnectTrafficObjectControllerRequest,
  TrafficObjectControllerChangeCustomerRequest,
} from '../dtos/tlc.dtos';

import { TlcApiService } from './tlc-api.service';

@Injectable()
export class TrafficObjectControllerService
  implements IDataSourceService<TrafficObjectController, TrafficObjectControllerInfo> {
  constructor(private _api: TlcApiService) {}

  getPagedList(
    params: IPageParams,
    customerId: string
  ): Observable<IPageReturn<TrafficObjectControllerInfo>> {
    if (!customerId) {
      return of(null);
    }

    const request = new GetTrafficObjectControllersRequest();
    request.customerId = customerId;

    setPageParams(request, params);
    return this._api.get(request).pipe(
      map((m) => {
        return getPageReturn(m);
      })
    );
  }
  getList(customerId: string, filter?: string, onlyFree = false) {
    const request = new GetTrafficObjectControllersRequest();

    request.customerId = customerId;
    request.page = 0;
    request.items = 0;
    request.sort = 'number';
    request.filter = filter;
    request.onlyFree = onlyFree;

    return this._api.get(request).pipe(
      map((m) => {
        return m.items;
      })
    );
  }

  get(id: string, customerId: string) {
    const request = new GetTrafficObjectControllerRequest();
    request.id = id;
    request.customerId = customerId;
    return this._api.get(request);
  }
  add(model: TrafficObjectController, params: { customerId: string }) {
    const request = new CreateTrafficObjectControllerRequest();
    request.customerId = params.customerId;
    request.entity = model;
    return this._api.post(request);
  }
  update(model: TrafficObjectController, params: { customerId: string }) {
    const request = new UpdateTrafficObjectControllerRequest();
    request.id = model.id;
    request.entity = model;
    request.customerId = params.customerId;
    return this._api.put(request);
  }
  delete(ids: string[], customerId: string): Observable<number> {
    const request = new DeleteTrafficObjectControllersRequest();
    request.ids = ids;
    request.customerId = customerId;
    return this._api.delete(request);
  }
  exist(type: TrafficControllerType, number: string) {
    const request = new GetTrafficObjectControllerExistRequest();
    request.type = type;
    request.number = number;
    return this._api.get(request);
  }

  disconnect(id: string, customerId: string) {
    const request = new DisconnectTrafficObjectControllerRequest();
    request.id = id;
    request.customerId = customerId;
    return this._api.post(request);
  }

  changeCustomer(
    id: string,
    customerId: string,
    params: { newCustomerId: string; description: string }
  ) {
    const request = new TrafficObjectControllerChangeCustomerRequest();
    request.id = id;
    request.customerId = customerId;
    request.description = params.description;
    request.newCustomerId = params.newCustomerId;
    return this._api.post(request);
  }
}
