export const SCHEMA_SVG = `
  <svg viewBox="0 0 540 540" preserveAspectRatio="xMidYMid meet">
    <defs>
      <path id="road" d="M0,140 h120 a20,20 0 0 0 20,-20 v-120" style="stroke: #777; stroke-width: 1px; fill: none" />
      <path id="roadline" d="M0,140 h450" style="stroke: #777; stroke-width: 1px; fill: none" />
      <path id="direct" d="M0,-6 h50 v-8 l20,14 -20,14 v-8 h-50 z" />
      <path id="deadend" d="M0,-6 h40 v-8 l10,0 0,28 -10,0 v-8 h-40 z" />
      <g id="turn" transform="scale(1,1) translate(0, 12)">
        <path d="M-4,-5 l20.4,-18 a16,8 0 0 1 16,-4  h15 v-8 l20,14 -20,14 v-8 h-10 a16,8 0 0 0 -16,4 l-17,15 z" transform="rotate(42 0 0)" />
      </g>
      <path id="pedestrian" d="M20,6 v8 l-14,-14 14,-14 v8 h110 v-8 l14,14 -14,14 v-8 z" />
      <pattern id="pattern1" width="26" height="5" patternTransform="translate(-4 0)" patternUnits="userSpaceOnUse">
        <line stroke="#4faa6d" stroke-width="40" y2="50" />
      </pattern>
      <pattern id="pattern2" width="26" height="5" patternTransform="translate(-0.1 0)" patternUnits="userSpaceOnUse">
        <line stroke="#4faa6d" stroke-width="40" y2="50" />
      </pattern>
      <linearGradient id="redyellow">
        <stop offset="45%" stop-color="#FE4" />
        <stop offset="45%" stop-color="#E00" />
      </linearGradient>
      <linearGradient id="redyellow_stroke">
        <stop offset="45%" stop-color="#880" />
        <stop offset="45%" stop-color="#800" />
      </linearGradient>
    </defs>

    <clipPath id="clip1">
      <rect x="150" y="30" width="30" height="150" />
    </clipPath>
    <clipPath id="clip2">
      <rect x="350" y="150" width="140" height="30" />
    </clipPath>

    <g clip-path="url(#clip1)">
      <text id="MajorStreet_Label" class="street-label" x="170" y="165" style="text-anchor: start" transform="rotate(-90 170 165)">Главная улица</text>
    </g>
    <g clip-path="url(#clip2)">
      <text id="MinorStreet_Label" class="street-label" x="365" y="170" style="text-anchor: start">Второстепенная улица</text>
    </g>

    <text id="Way2_Label" class="way-label" x="265" y="60" dy="1em" style="text-anchor: middle">Направление №2</text>
    <text  class="way-label" x="210" y="-5" dy="1em" style="text-anchor: middle;font-size: 20px;">скорость</text>
    <text  class="way-label" x="320" y="-5" dy="1em" style="text-anchor: middle;font-size: 20px;">количество</text>
    <text  id="Move2_LabelPercentSpeed" class="way-label-percent" x="210" y="20" dy="1em" style="text-anchor: middle;font-size: 40px;"> </text>
    <text  id="Move2_LabelPercentCount" class="way-label-percent" x="320" y="20" dy="1em" style="text-anchor: middle;font-size: 40px;"> </text>

    <text id="Way6_Label" class="way-label" x="265" y="465" style="text-anchor: middle">Направление №6</text>
    <text  class="way-label" x="210" y="535"style="text-anchor: middle;font-size: 20px;">скорость</text>
    <text  class="way-label" x="325" y="535"style="text-anchor: middle;font-size: 20px;">Количество</text>
    <text  id="Move6_LabelPercentSpeed" class="way-label-percent" x="210" y="505"style="text-anchor: middle;font-size: 40px;"> </text>
    <text  id="Move6_LabelPercentCount" class="way-label-percent" x="325" y="505"style="text-anchor: middle;font-size: 40px;"> </text>
    

    <text id="Way4_Label" class="way-label" x="505" y="230" style="text-anchor: middle"   transform="rotate(270 505 265)">Направлнеие №4</text>
    <text  class="way-label" x="430" y="290" style="text-anchor: middle;font-size: 20px;" transform="rotate(270 505 265)">скорость</text>
    <text  class="way-label" x="580" y="290" style="text-anchor: middle;font-size: 20px;" transform="rotate(270 505 265)">количество</text>
    <text id="Move4_LabelPercentSpeed" class="way-label-percent" x="430" y="270" style="text-anchor: middle;font-size: 40px;" transform="rotate(270 505 265)"> </text>
    <text id="Move4_LabelPercentCount" class="way-label-percent" x="580" y="270" style="text-anchor: middle;font-size: 40px;" transform="rotate(270 505 265)"> </text>

    <text id="Way8_Label" class="way-label" x="35" y="310" style="text-anchor: middle"  transform="rotate(-90 25 265)">Направление №8</text>
    <text class="way-label" x="-30" y="255" style="text-anchor: middle;font-size: 20px;"transform="rotate(-90 25 265)">скорость</text>
    <text class="way-label" x="80" y="255" style="text-anchor: middle;font-size: 20px;" transform="rotate(-90 25 265)">количество</text>
    <text id="Move8_LabelPercentSpeed" class="way-label-percent" x="-25" y="290" style="text-anchor: middle;font-size: 40px;" transform="rotate(-90 25 265)"> </text>
    <text id="Move8_LabelPercentCount" class="way-label-percent" x="85" y="290" style="text-anchor: middle;font-size: 40px;" transform="rotate(-90 25 265)"> </text>
    
    <g transform="translate(40 40)">
      <use xlink:href="#road" class="road-8-2" x="0" y="0" />
      <use xlink:href="#road" class="road-2-4" x="450" y="0" transform="rotate(90 450 0)" />
      <use xlink:href="#road" class="road-4-6" x="450" y="450" transform="rotate(180 450 450)" />
      <use xlink:href="#road" class="road-6-8" x="0" y="450" transform="rotate(-90 0 450)" />

      <use xlink:href="#roadline" class="roadline-2" x="0" y="0" style="display: none;"/>
      <use xlink:href="#roadline" class="roadline-4" x="450" y="0" transform="rotate(90 450 0)" style="display: none;"/>
      <use xlink:href="#roadline" class="roadline-6" x="450" y="450" transform="rotate(180 450 450)" style="display: none;"/>
      <use xlink:href="#roadline" class="roadline-8" x="0" y="450" transform="rotate(-90 0 450)" style="display: none;"/>

      <text id="Move5_Label" class="movement-label" x="256" y="65">5</text>
      <text id="Move2_Label" class="movement-label" x="220" y="65">2</text>
      <text id="Move12_Label" class="movement-label" x="176" y="65">12</text>


      <text id="Move1_Label" class="movement-label" x="183" y="380" dy="1em">1</text>
      <text id="Move6_Label" class="movement-label" x="220" y="380" dy="1em">6</text>
      <text id="Move16_Label" class="movement-label" x="251" y="380" dy="1em">16</text>


      <text id="Move3_Label" class="movement-label" x="60" y="195" style="text-anchor: end">3</text>
      <text id="Move8_Label" class="movement-label" x="60" y="232" style="text-anchor: end">8</text>
      <text id="Move18_Label" class="movement-label" x="60" y="269" style="text-anchor: end">18</text>


      <text id="Move7_Label" class="movement-label" x="390" y="269">7</text>
      <text id="Move4_Label" class="movement-label" x="390" y="232">4</text>
      <text id="Move14_Label" class="movement-label" x="390" y="195">14</text>
 

      <use id="Move3" class="movement" xlink:href="#turn" transform="scale(1 -1) translate(65 -200)" />
      <use id="Move8" class="movement" xlink:href="#direct" x="65" y="225" />
      <use id="Move18" class="movement" xlink:href="#turn" x="65" y="250" />


      <use id="Move7" class="movement" xlink:href="#turn" transform="scale(-1 1) translate(-385 250)" />
      <use id="Move4" class="movement" xlink:href="#direct" transform="scale(-1 1) translate(-385 225)" />
      <use id="Move14" class="movement" xlink:href="#turn" transform="scale(-1 -1) translate(-385 -200)" />


      <use id="Move1" class="movement" xlink:href="#turn" transform="scale(-1 1) translate(-200 380) rotate(-90)" />
      <use id="Move6" class="movement" xlink:href="#direct" transform="scale(-1 1) translate(-225 380) rotate(-90)" />
      <use id="Move16" class="movement" xlink:href="#turn" transform="scale(1 1) translate(250 380) rotate(-90)" />


      <use id="Move5" class="movement" xlink:href="#turn" transform="scale(-1 1) translate(-250 70) rotate(90)" />
      <use id="Move2" class="movement" xlink:href="#direct" transform="scale(-1 1) translate(-225 70) rotate(90)" />
      <use id="Move12" class="movement" xlink:href="#turn" transform="scale(1 1) translate(200 70) rotate(90)" />

    </g>
  </svg>
`;
