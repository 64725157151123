import { ElementRef } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { DataSource as CdkDataSource } from '@angular/cdk/table';
import { SelectionModel } from '@angular/cdk/collections';

import {
  Observable,
  Subscription,
  BehaviorSubject,
  Subject,
  NEVER,
  fromEvent,
  merge,
  EMPTY,
} from 'rxjs';

import {
  finalize,
  skip,
  mergeMap,
  debounceTime,
  distinctUntilChanged,
  catchError,
} from 'rxjs/operators';

import { SettingsService } from '../services/settings.service';

interface PageRequestBase {
  page: number;
  items: number;
  filter: string;
  sort: string;
}

interface PageResponse<T> {
  page: number;
  total: number;
  items: T[];
  itemsPerPage: number;
}

export interface IPageParams {
  filter: string;
  page: number;
  size: number;
  sort: string;
  desc: boolean;
}

export interface IPageReturn<T> {
  page: number;
  size: number;
  total: number;
  data: T[];
}

export interface IDataSourcePagedList<T> {
  getPagedList(
    params: IPageParams,
    requestParams?: any
  ): Observable<IPageReturn<T>>;
}

export interface IDataSource<T> {
  get(id: any, params?): Observable<T>;
  add(model: T, params?): Observable<T>;
  update(model: T, params?): Observable<T>;
  delete(ids: any[], params?): Observable<number>;
}

export interface IDataSourceService<T, TInfo>
  extends IDataSource<T>,
    IDataSourcePagedList<TInfo> {}

export function setPageParams(request: PageRequestBase, params: IPageParams) {
  request.page = params.page;
  request.items = params.size;
  request.filter = params.filter;
  request.sort = params.sort;
  if (params.sort && params.desc) {
    request.sort += ' desc';
  }
}

export function getPageReturn<T>(response: PageResponse<T>): IPageReturn<T> {
  const result = <IPageReturn<T>>{};
  result.page = response.page;
  result.size = response.itemsPerPage;
  result.total = response.total;
  result.data = response.items;
  return result;
}

export class DataSource<T> extends CdkDataSource<T> {
  showSearch = true;
  selection = new SelectionModel<any>(true, []);
  requestParams: any;

  defaultSize = 50;
  defaultSort = '';
  defaultSortDesc = false;

  private readonly _settingsService: SettingsService;
  readonly service: IDataSourcePagedList<T>;

  readonly loading = new BehaviorSubject<boolean>(false);
  private readonly _data = new BehaviorSubject<T[]>([]);
  private readonly _reloadData = new Subject();
  get data(): T[] {
    return this._data.value;
  }

  private readonly _filter = new BehaviorSubject<string>('');
  get filter(): string {
    return this._filter.value;
  }
  set filter(filter: string) {
    this._filter.next(filter);
  }

  private _sort: MatSort;
  get sort(): MatSort {
    return this._sort;
  }
  // set sort(sort: MatSort) {
  //   this._sort = sort;
  //   this._updateChangeSubscription();
  // }

  private _paginator: MatPaginator;
  get paginator(): MatPaginator {
    return this._paginator;
  }
  // set paginator(paginator: MatPaginator) {
  //   this._paginator = paginator;
  //   this._updateChangeSubscription();
  // }

  private _search: ElementRef;
  get search(): ElementRef {
    return this._search;
  }
  // set search(search: ElementRef) {
  //   this._search = search;
  //   this._updateSearchSubscription();
  // }

  private _searchSubscription: Subscription;
  private _updateSearchSubscription() {
    if (this._searchSubscription) {
      this._searchSubscription.unsubscribe();
    }
    this._searchSubscription = (this._search
      ? fromEvent(this._search.nativeElement, 'keyup')
      : NEVER
    )
      .pipe(debounceTime(250), distinctUntilChanged())
      .subscribe(() => {
        this.filter = this.search.nativeElement.value;
      });
  }

  private _changesSubscription: Subscription;
  private _updateChangeSubscription() {
    const displayChanges = [
      this._filter.pipe(skip(1)),
      this._reloadData,
      this._sort ? this._sort.sortChange : NEVER,
      this._paginator ? this._paginator.page : NEVER,
    ];
    if (this._changesSubscription) {
      this._changesSubscription.unsubscribe();
    }
    this._changesSubscription = merge(...displayChanges)
      .pipe(
        mergeMap(() => {
          const filter = this.filter;

          let page = 0;
          let size = this.defaultSize;
          if (this.paginator) {
            page = this.paginator.pageIndex || page;
            size = this.paginator.pageSize || size;
          }
          let sort = this.defaultSort;
          let desc = this.defaultSortDesc;
          if (this.sort && this.sort.active) {
            sort = this.sort.active;
            desc = this.sort.direction === 'desc';
          }

          this.loading.next(true);
          this.selection.clear();

          return this.service
            .getPagedList(
              { filter, page, size, sort, desc },
              this.requestParams
            )
            .pipe(
              catchError(() => {
                return EMPTY;
              })
            )
            .pipe(finalize(() => this.loadingComplete()));
        })
      )
      .subscribe((result) => {
        this._data.next(result?.data);
        if (this._paginator && result) {
          this._paginator.length = result.total;
          this._paginator.pageIndex = result.page;
          this._paginator.pageSize = result.size;
        }
      });
  }

  constructor(
    service: IDataSourcePagedList<T>,
    settingsService: SettingsService,
    public idField = 'id'
  ) {
    super();

    this._settingsService = settingsService;
    this.service = service;
  }

  destroy() {
    if (this.loading) {
      this.loading.complete();
    }
    if (this._data) {
      this._data.complete();
    }
    if (this._filter) {
      this._filter.complete();
    }
    if (this._reloadData) {
      this._reloadData.complete();
    }
    if (this._searchSubscription) {
      this._searchSubscription.unsubscribe();
    }
    if (this._changesSubscription) {
      this._changesSubscription.unsubscribe();
    }
  }

  setSortAndPaginator(sort: MatSort, paginator: MatPaginator) {
    this._sort = sort;
    this._paginator = paginator;

    if (this._paginator) {
      this._paginator.pageSize = this._settingsService.getPageSize(
        this.service.constructor.name
      );
    }

    this._updateChangeSubscription();

    this._filter.subscribe(() => {
      if (this._paginator) {
        this._paginator.pageIndex = 0;
      }
    });
  }

  setSearch(search: ElementRef) {
    this._search = search;
    this._updateSearchSubscription();
  }

  connect(): Observable<T[]> {
    return this._data;
  }

  disconnect() {}

  reloadData() {
    this._reloadData.next();
  }

  isAllSelected(): boolean {
    if (this.selection.isEmpty()) {
      return false;
    }
    return this.selection.selected.length === this._data.value.length;
  }

  selectionAllToggle() {
    if (this.isAllSelected()) {
      this.selection.clear();
    } else {
      this._data.value.forEach((item) =>
        this.selection.select(<any>item[this.idField])
      );
    }
  }

  toggleSearch() {
    this.showSearch = !this.showSearch;
    if (!this._search) {
      return;
    }
    if (this.showSearch) {
      this.search.nativeElement.focus();
    } else {
      // setTimeout(() => {
      if (this.search.nativeElement.value !== '') {
        this.search.nativeElement.value = '';
        this.filter = '';
      }
      // }, 200);
    }
  }

  loadingComplete() {
    this.loading.next(false);
    if (this._paginator) {
      this._settingsService.setPageSize(
        this.service.constructor.name,
        this._paginator.pageSize
      );
    }
  }
}
