import {
  Component,
  ChangeDetectionStrategy,
  Injector,
  Input,
  OnDestroy,
  OnInit,
  ChangeDetectorRef,
  AfterViewInit,
} from "@angular/core";
import { FormGroup } from "@angular/forms";
import { Subject } from "rxjs";

import { DataSourceForm } from "projects/msu-its-web-common/src/data/data-source-form";

import { FIXATION_DEVICE_TYPE, FIXATION_VEHICLE_TYPE } from "../../dtos/enum";
import { FixationDeviceService } from "../../services/fixation-device/fixation-device.service";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { IDataSource } from "projects/msu-its-web-common/src/data/data-source";
import {
  FixationDeviceType,
  FixationEventInfo,
  VehicleClass,
  ViolationType,
} from "../../dtos/ftv.dtos";
import { FixationEvent } from "../../dtos/ftv.dtos";
import { TranslateService } from "@ngx-translate/core";
import { SettingsService } from "projects/msu-its-web-common/src/services/settings.service";
import { FIXATION_VIOLATION_TYPE } from "../../dtos/enum";
import { FixationPointService } from "../../services/fixation-point/fixation-point.service";
import { finalize } from "rxjs/operators";

interface IArrayImages {
  id: number;
  base64: string;
  emptyBase64: string;
}

@Component({
  selector: "fixation-event-edit-ftv",
  templateUrl: "./fixation-event-edit.component.html",
  styleUrls: ["./fixation-event-edit.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FixationEventEditComponent
  implements OnDestroy, OnInit, AfterViewInit {
  @Input()
  customerId: string;

  @Input()
  dialogId: string;

  loading = false;
  loadingImages = false;

  fullscreen = false;

  model: FixationEvent;

  isNew;
  modelService;
  formGroup: FormGroup;

  imageUrlTest: string;

  imagesArray: IArrayImages[] = [];
  activeImagesArray: IArrayImages;

  private _destroy = new Subject();

  get locale() {
    return this.translate.currentLang;
  }
  get timezone() {
    return this._settingsService.timezone;
  }

  constructor(
    protected _injector: Injector,
    private _changeDetector: ChangeDetectorRef,
    public dialog: MatDialog,
    protected translate: TranslateService,
    private _settingsService: SettingsService,
    private _fixationPointService: FixationPointService
  ) {}

  ngOnDestroy() {
    this._destroy.next();
    this._destroy.complete();
  }
  ngOnInit() {}

  ngAfterViewInit(): void {
    this.getImages();
  }

  initForm(
    model: FixationEvent,
    modelService: IDataSource<FixationEvent>,
    isNew: boolean = false,
    dialog: MatDialogRef<DataSourceForm<FixationEventInfo>>
  ) {
    this.model = model;
    // this.isNew = isNew;
    // this.modelService = modelService;
    // this.dialog = dialog;
    // console.log(model);
  }

  getImages() {
    this.loadingImages = true;
    this._changeDetector.detectChanges();
    this._fixationPointService
      .getImagesTestRequest(this.model.id, this.customerId)
      .pipe(
        finalize(() => {
          this.loadingImages = false;
          this._changeDetector.markForCheck();
        })
      )
      .subscribe((res) => {
        if (res) {
          this.activeImagesArray = {
            id: 0,
            base64: "data:image/jpeg;base64," + res[0],
            emptyBase64: res[0],
          };
          res.map((item, index) => {
            this.imagesArray.push({
              id: index,
              base64: "data:image/jpeg;base64," + item,
              emptyBase64: item,
            });
          });
        }
        this.loadingImages = false;
        this._changeDetector.markForCheck();
      });
  }

  clickMiniImage(item: IArrayImages) {
    this.activeImagesArray = item;
  }
  clickBigImage() {
    this.fullscreen = !this.fullscreen;
    document.getElementById(
      "fullpage"
    ).style.background = `RGBA(0,0,0,.5) url(data:image/jpeg;base64,${this.activeImagesArray.emptyBase64}) no-repeat center`;
    this._changeDetector.markForCheck();
  }
  close() {
    this.dialog.getDialogById(this.dialogId).close();
  }

  getDeviceType(item: FixationDeviceType) {
    const temp = FIXATION_DEVICE_TYPE.find((m) => item === m.type)?.name;
    return temp || "Неизвестно";
  }
  getEventType(item: ViolationType) {
    const temp = FIXATION_VIOLATION_TYPE.find((m) => item === m.type)?.name;
    return temp || "Неизвестно";
  }
  getVehicleType(item: VehicleClass) {
    const temp = FIXATION_VEHICLE_TYPE.find((m) => item === m.type)?.name;
    return temp || "Неизвестно";
  }
}
