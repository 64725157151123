import { Inject, Injectable } from '@angular/core';

import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import {
  IDataSourceService,
  setPageParams,
  getPageReturn,
  IPageParams,
  IPageReturn,
} from '../data/data-source';

import {
  GetCustomersRequest,
  GetCustomerRequest,
  CreateCustomerRequest,
  UpdateCustomerRequest,
  DeleteCustomersRequest,
  Customer,
  CustomerInfo,
  GetCustomerPermissionsRequest,
  GetPermissionsRequest,
  UpdateCustomerPermissionsRequest,
  ExistCustomerNameRequest,
  GetAvailableCustomersRequest,
  GetModulesCustomerSettingsRequest,
  ModuleSettings,
} from '../dtos/hub.dtos';
import { APP_ENVIRONMENT } from '../utils/shared-consts';

import { HubApiService } from './hub-api.service';

@Injectable()
export class CustomerService
  implements IDataSourceService<Customer, CustomerInfo>
{
  constructor(
    private _api: HubApiService,
    @Inject(APP_ENVIRONMENT) private _appEnv
  ) {}

  getPagedList(params: IPageParams): Observable<IPageReturn<CustomerInfo>> {
    const request = new GetCustomersRequest();
    setPageParams(request, params);

    return this._api.get(request).pipe(
      map((m) => {
        return getPageReturn(m);
      })
    );
  }

  getList(filter?: string): Observable<CustomerInfo[]> {
    const request = new GetCustomersRequest();

    request.page = 0;
    request.items = 0;
    request.sort = 'name';
    request.filter = filter;

    return this._api.get(request).pipe(
      map((m) => {
        return m.items;
      })
    );
  }

  getShareAvailableList(
    customerId: string,
    filter?: string
  ): Observable<CustomerInfo[]> {
    const request = new GetAvailableCustomersRequest();

    request.page = 0;
    request.items = 0;
    request.sort = 'name';
    request.filter = filter;
    request.customerId = customerId;

    return this._api.get(request).pipe(
      map((m) => {
        return m.items.filter((m) => m.id != customerId);
      })
    );
  }

  get(id: any): Observable<Customer> {
    const request = new GetCustomerRequest();
    request.id = id;
    return this._api.get(request);
  }

  add(Customer: Customer): Observable<Customer> {
    const request = new CreateCustomerRequest();
    request.entity = Customer;
    return this._api.post(request);
  }

  update(Customer: Customer): Observable<Customer> {
    const request = new UpdateCustomerRequest();
    request.id = Customer.id;
    request.entity = Customer;
    return this._api.put(request);
  }

  delete(ids: string[]): Observable<number> {
    const request = new DeleteCustomersRequest();
    request.ids = ids;
    return this._api.delete(request);
  }

  exist(name: string) {
    const request = new ExistCustomerNameRequest();
    request.name = name;
    return this._api.get(request);
  }

  getPermissions(id: string) {
    const request = new GetCustomerPermissionsRequest();
    request.page = 0;
    request.items = 0;
    request.customerId = id;
    return this._api.get(request).pipe(map((m) => m.items));
  }

  getModulesSettings(id: string): Observable<ModuleSettings[]> {
    const request = new GetModulesCustomerSettingsRequest();
    request.customerId = id;
    request.camelCase = true;
    return this._api.get(request);
  }

  updatePermissions(id: string, permissions: string[]) {
    const request = new UpdateCustomerPermissionsRequest();
    request.customerId = id;
    request.entity = permissions;
    return this._api.put(request);
  }

  getAllPermissions() {
    const request = new GetPermissionsRequest();

    return this._api.get(request);
  }
}
