import { Injectable } from "@angular/core";
import { IGroupShareService } from "projects/msu-its-web-common/src/components/groups/group-shares.component";
import { map } from "rxjs/operators";
import {
  EntityGroupPermissions,
  GetFixationPointGroupSharesRequest,
  UpdateFixationPointGroupShareRequest,
} from "../../dtos/ftv.dtos";

import { FtvApiService } from "../ftv-api.service";

@Injectable()
export class FixationPointGroupShareService implements IGroupShareService {
  constructor(private _api: FtvApiService) {}

  getList(id: string, customerId: string) {
    const request = new GetFixationPointGroupSharesRequest();
    request.id = id;
    request.customerId = customerId;
    request.items = 0;
    request.page = 0;
    request.sort = "customerName";

    return this._api.get(request).pipe(map((m) => m.items));
  }

  update(model: EntityGroupPermissions, customerId: string) {
    const request = new UpdateFixationPointGroupShareRequest();
    request.id = model.entityGroupId;
    request.entity = model;
    request.customerId = customerId;
    return this._api.put(request);
  }
}
