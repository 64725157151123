import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { FlashComponent } from '../modules/flash-module/flash.component';

@Injectable()
export class FlashService {
  constructor(private _snackBar: MatSnackBar) {}

  private _lastType;
  private _lastMessage;
  private _lastTimeout;

  info(message: string) {
    this._openSnackBar(message, 'info');
  }
  success(message: string) {
    this._openSnackBar(message, 'success');
  }
  warn(message: string) {
    this._openSnackBar(message, 'warn');
  }
  error(message: string) {
    this._openSnackBar(message, 'error');
  }
  show(
    message: string,
    type: 'success' | 'warn' | 'error' | 'info' = 'success'
  ) {
    this._openSnackBar(message, type);
  }

  private _openSnackBar(
    message: string,
    type: 'success' | 'warn' | 'error' | 'info' = 'success'
  ) {
    if (
      !document.hidden &&
      (this._lastType !== type || this._lastMessage !== message)
    ) {
      this._lastType = type;
      this._lastMessage = message;
      clearTimeout(this._lastTimeout);
      const config: MatSnackBarConfig = {
        duration: 5000,
        horizontalPosition: 'left',
        verticalPosition: 'bottom',
        data: { message, type },
      };
      this._snackBar.openFromComponent(FlashComponent, config);
      this._lastTimeout = setTimeout(() => {
        this._lastType = null;
        this._lastMessage = null;
      }, 5000);
    }
  }
}
