import { Injectable } from '@angular/core';

import { Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import {
  IGisObject,
  IGisObjectGroup,
  IGisObjectGroupService,
} from 'projects/msu-its-web-common/src/utils/gis-object-group';

import {
  getPageReturn,
  IDataSourceService,
  IPageParams,
  IPageReturn,
  setPageParams,
} from 'projects/msu-its-web-common/src/data/data-source';

import {
  CreateRouteGroupRequest,
  DeleteRouteGroupsRequest,
  EntityGroup,
  EntityGroupInfo,
  GetItemEntityGroupsRequest,
  GetRouteGroupRequest,
  GetRouteGroupsRequest,
  Route,
  UpdateRouteGroupRequest,
} from '../dtos/tlc.dtos';

import { TlcApiService } from './tlc-api.service';
import { RouteService } from './route.service';

class RouteGisObject extends Route implements IGisObject {
  get geoJson() {
    return this.routeGeoJson;
  }
}

@Injectable()
export class RouteGroupService
  implements IDataSourceService<EntityGroup, EntityGroupInfo>, IGisObjectGroupService {
  constructor(private _api: TlcApiService, private _trafficSceneService: RouteService) {}

  permissions = ['route.read'];

  getGroupList(customerId: string) {
    return this.getList(customerId);
  }
  getObjectList(customerId: string, groupIds: string[]) {
    return this._trafficSceneService
      .getList(customerId, null, groupIds)
      .pipe(map((m) => m.map((n) => Object.assign(new RouteGisObject(), n))));
  }

  getObjectGroups(objectId: string, customerId: string) {
    const request = new GetItemEntityGroupsRequest({
      id: objectId,
      customerId,
    });

    return this._api.get(request).pipe(map((m) => m.items));
  }

  getPagedList(params: IPageParams, customerId: string): Observable<IPageReturn<EntityGroupInfo>> {
    if (!customerId) {
      return of(null);
    }

    const request = new GetRouteGroupsRequest();
    request.customerId = customerId;

    setPageParams(request, params);
    return this._api.get(request).pipe(
      map((m) => {
        return getPageReturn(m);
      })
    );
  }
  getList(customerId: string, filter?: string, owned = false) {
    const request = new GetRouteGroupsRequest();
    request.customerId = customerId;

    request.page = 0;
    request.items = 0;
    request.sort = 'name';
    request.filter = filter;
    request.onlyOwnedItems = owned;

    return this._api.get(request).pipe(
      map((m) => {
        return m.items;
      })
    );
  }

  get(id: string, customerId: string) {
    const request = new GetRouteGroupRequest();
    request.id = id;
    request.customerId = customerId;
    return this._api.get(request);
  }
  add(model: EntityGroup, params: { customerId: string }) {
    const request = new CreateRouteGroupRequest();
    request.customerId = params.customerId;
    request.entity = model;
    return this._api.post(request);
  }
  update(model: EntityGroup, params: { customerId: string }) {
    const request = new UpdateRouteGroupRequest();
    request.id = model.id;
    request.entity = model;
    request.customerId = params.customerId;
    return this._api.put(request);
  }
  delete(ids: string[], customerId: string): Observable<number> {
    const request = new DeleteRouteGroupsRequest();
    request.ids = ids;
    request.customerId = customerId;
    return this._api.delete(request);
  }
  exist(name: string, customerId: string) {
    return of(false);
  }
}
