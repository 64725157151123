<div *ngIf="loading" class="backdrop">
  <mat-spinner diameter="36" color="accent"></mat-spinner>
</div>

<table-header>
  <ng-container title>
    {{ "CUSTOMERS.TITLE" | translate }}
  </ng-container>
  <ng-container actions>
    <button
      mat-icon-button
      type="button"
      (click)="refresh()"
      title="{{ 'COMMON.REFRESH' | translate }}"
    >
      <mat-icon>refresh</mat-icon>
    </button>

    <button mat-flat-button color="accent" (click)="add()" disableRipple>
      <mat-icon>playlist_add</mat-icon>
      {{ "CUSTOMERS.CREATE_CUSTOMER" | translate }}
    </button>
  </ng-container>
</table-header>

<!-- <h4 mat-dialog-title>
  <span>{{ "CUSTOMERS.TITLE" | translate }}</span>
</h4> -->

<mat-dialog-content
  style="
    min-width: 50vw;
    min-height: 50vh;
    padding-top: 4px;
    padding-bottom: 4px;
  "
>
  <a tabindex="0"></a>
  <mat-list style="padding: 0">
    <mat-list-item
      class="mat-hover"
      (dblclick)="select(item.id)"
      *ngFor="let item of customers"
    >
      <p mat-line>{{ item.name }}</p>

      <button
        class="action"
        mat-icon-button
        title="{{ 'COMMON.SELECT' | translate }}"
        (click)="select(item.id)"
      >
        <mat-icon>open_in_browser</mat-icon>
      </button>

      <button
        class="action"
        mat-icon-button
        title="{{ 'CUSTOMERS.EDIT_CUSTOMER' | translate }}"
        (click)="edit(item.id)"
      >
        <mat-icon>edit</mat-icon>
      </button>
      <button
        class="action"
        mat-icon-button
        title="{{ 'CUSTOMERS.DELETE_CUSTOMER' | translate }}"
        (click)="delete(item.id, item.name)"
        style="margin-right: -12px"
      >
        <mat-icon>delete</mat-icon>
      </button>
      <mat-divider></mat-divider>
    </mat-list-item>
  </mat-list>
</mat-dialog-content>

<!--
<mat-dialog-actions align="end">
  <button autofocus mat-button color="primary" (click)="add()">
    <mat-icon>playlist_add</mat-icon>
    {{ "CUSTOMERS.CREATE_CUSTOMER" | translate }}
  </button>
  <button mat-button (click)="close()">
    {{ "COMMON.CLOSE" | translate }}
  </button>
</mat-dialog-actions>
-->
