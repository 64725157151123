import {
  Component,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  OnInit,
} from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { finalize } from 'rxjs/operators';

import { FlashService } from '../../services/flash.service';
import { DialogService } from '../../services/dialog.service';
import { TranslateService } from '@ngx-translate/core';

import { Customer, CustomerInfo } from '../../dtos/hub.dtos';

import { CustomerService } from '../../services/customer.service';
import { CustomerEditComponent } from './customer-edit.component';
import { Router } from '@angular/router';
import { TokenService } from '../../services/token.service';

@Component({
  selector: 'customer-select',
  templateUrl: './customer-select.component.html',
  styles: [
    `
      .action {
        opacity: 0.1;
      }
      .mat-hover:hover .action {
        opacity: 0.9;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomerSelectComponent implements OnInit {
  loading = false;
  customers: CustomerInfo[];

  constructor(
    private _router: Router,
    private _flashService: FlashService,
    private _dialogService: DialogService,
    private _customerService: CustomerService,
    private _changeDetector: ChangeDetectorRef,
    private _dialog: MatDialogRef<CustomerSelectComponent>,
    private _tokenService: TokenService,
    public translate: TranslateService
  ) {}

  ngOnInit() {
    this._loadCustomers();
  }

  private _loadCustomers() {
    this.loading = true;
    this._changeDetector.markForCheck();

    this._customerService
      .getList('')
      .pipe(
        finalize(() => {
          this.loading = false;
          this._changeDetector.markForCheck();
        })
      )
      .subscribe((result) => {
        this.customers = result;

        if (
          this.customers.length == 1 &&
          !this._tokenService.hasPermissions(['system.manage'])
        ) {
          this._dialog?.close();
          this._router.navigate([this.customers[0].id]);
        }

        this._changeDetector.markForCheck();
      });
  }

  private _editDialog(model: Customer, isNew: boolean) {
    const dialog = this._dialogService.dialog.open(CustomerEditComponent, {
      disableClose: true,
    });
    dialog.componentInstance.initForm(
      model,
      this._customerService,
      isNew,
      dialog
    );
    dialog.afterClosed().subscribe((result) => {
      if (result) {
        this._flashService.success(
          isNew
            ? this.translate.instant('CUSTOMERS.CUSTOMER_ADDED')
            : this.translate.instant('COMMON.CHANGES_SAVED')
        );
        this._loadCustomers();
      }
    });
  }

  refresh() {
    this._loadCustomers();
  }

  add() {
    const newModel = new Customer({ timeZoneId: 'UTC' });
    this._editDialog(newModel, true);
  }

  edit(id: string) {
    this._customerService.get(id).subscribe((model) => {
      this._editDialog(model, false);
    });
  }

  delete(id: string, name: string) {
    this._dialogService
      .confirm(this.translate.instant('CUSTOMERS.DELETE_CUSTOMER?', { name }))
      .subscribe((confirm) => {
        if (!confirm) {
          return;
        }
        this.loading = true;
        this._changeDetector.markForCheck();
        this._customerService
          .delete([id])
          .pipe(
            finalize(() => {
              this.loading = false;
              this._changeDetector.markForCheck();
            })
          )
          .subscribe(() => {
            this._flashService.success(
              this.translate.instant('CUSTOMERS.CUSTOMER_DELETED')
            );
            this._loadCustomers();
          });
      });
  }

  select(id: string) {
    this._dialog.close();
    this._router.navigate([id]);
  }

  // close() {
  //   this._dialog?.close(false);
  // }
}

@Component({
  selector: 'customer-select-modal',
  template: '',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomerSelectModalComponent implements OnInit {
  constructor(
    private _router: Router,
    private _dialogService: DialogService,
    private _customerService: CustomerService,
    private _tokenService: TokenService
  ) {}

  ngOnInit() {
    this._customerService
      .getList('')
      // .pipe(
      //   finalize(() => {
      //     this.loading = false;
      //     this._changeDetector.markForCheck();
      //   })
      // )
      .subscribe((result) => {
        // this.customers = result;

        if (
          result.length > 1 ||
          this._tokenService.hasPermissions(['system.manage'])
        ) {
          this._dialogService.dialog.open(CustomerSelectComponent, {
            disableClose: true,
          });

          return;
        }

        this._router.navigate([result[0].id]);
      });
  }
}
