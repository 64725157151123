import { Injectable } from '@angular/core';

import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import {
  IDataSourceService,
  setPageParams,
  getPageReturn,
  IPageParams,
  IPageReturn,
} from 'projects/msu-its-web-common/src/data/data-source';

import {
  FrameTemplate,
  UpdateFrameTemplateRequest,
  CreateFrameTemplateRequest,
  DeleteFrameTemplatesRequest,
  GetFrameTemplateExistRequest,
  GetFrameTemplateRequest,
  GetFrameTemplatesRequest,
  GenerateTextFrameRequest,
} from '../../dtos/ruis.dtos';

import { RuisApiService } from '../ruis-api.service';

@Injectable()
export class FrameTemplateService implements IDataSourceService<FrameTemplate, FrameTemplate> {
  constructor(private _api: RuisApiService) {}

  getPagedList(
    params: IPageParams,
    requestParams: { libraryId: string; customerId: string }
  ): Observable<IPageReturn<FrameTemplate>> {
    if (!requestParams.customerId) {
      return of(null);
    }

    const request = new GetFrameTemplatesRequest();
    request.customerId = requestParams.customerId;
    request.customerId = requestParams.libraryId;

    setPageParams(request, params);
    return this._api.get(request).pipe(
      map((m) => {
        return getPageReturn(m);
      })
    );
  }
  getList(
    customerId: string,
    libraryId?: string,
    filter?: string,
    groupIds?: string[],
    items?: number
  ): Observable<FrameTemplate[]> {
    const request = new GetFrameTemplatesRequest();
    request.customerId = customerId;
    request.libraryId = libraryId;
    request.entityGroups = groupIds || [];

    request.page = 0;
    request.items = items || 0;
    request.sort = 'name';
    request.filter = filter || '';

    return this._api.get(request).pipe(
      map((m) => {
        return m.items;
      })
    );
  }

  get(id: string, customerId: string) {
    const request = new GetFrameTemplateRequest();
    request.id = id;
    request.customerId = customerId;
    return this._api.get(request);
  }
  add(
    model: FrameTemplate,
    params: {
      customerId: string;
      groups: string[];
      updateGroups: boolean;
    }
  ) {
    const request = new CreateFrameTemplateRequest();
    request.entity = model;
    request.customerId = params.customerId;
    request.entityGroups = params.groups;
    request.updateEntityGroups = params.updateGroups;
    return this._api.post(request);
  }
  update(
    model: FrameTemplate,
    params: { customerId: string; groups: string[]; updateGroups: boolean }
  ) {
    const request = new UpdateFrameTemplateRequest();
    request.id = model.id;
    request.entity = model;
    request.customerId = params.customerId;
    request.entityGroups = params.groups;
    request.updateEntityGroups = params.updateGroups;
    return this._api.put(request);
  }
  delete(ids: string[], customerId: string) {
    const request = new DeleteFrameTemplatesRequest();
    request.ids = ids;
    request.customerId = customerId;
    return this._api.delete(request);
  }
  exist(name: string, customerId: string): Observable<boolean> {
    const request = new GetFrameTemplateExistRequest({
      name,
      customerId,
    } as any);
    return this._api.get(request);
  }

  getTextFrame(id: string, customerId: string, message: string, color: string) {
    const request = new GenerateTextFrameRequest({ id, customerId, message, color });
    return this._api.post(request);
  }

  getThumbnailUrl(thumbnailId: string) {
    return `${this._api.apiUrl}/frameTemplates/thumbnail/${thumbnailId}`;
  }
}
