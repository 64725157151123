import { Injectable } from '@angular/core';

import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import {
  IDataSourceService,
  setPageParams,
  getPageReturn,
  IPageParams,
  IPageReturn,
} from 'projects/msu-its-web-common/src/data/data-source';

import {
  WorkModeDetectorDeviceInfo,
  GetDetectorDevicesWorkModeRequest,
  GetDetectorDeviceWorkModeRequest,
  UpdateDetectorDeviceWorkModeRequest,
  DetectorDeviceWorkModeOptions,
} from '../dtos/tfms.dtos';

import { TfmsApiService } from './tfms-api.service';

@Injectable()
export class DetectorDeviceWorkModeService
  implements IDataSourceService<WorkModeDetectorDeviceInfo, WorkModeDetectorDeviceInfo>
{
  constructor(private _api: TfmsApiService) {}

  getPagedList(params: IPageParams): Observable<IPageReturn<WorkModeDetectorDeviceInfo>> {
    const request = new GetDetectorDevicesWorkModeRequest();
    setPageParams(request, params);
    return this._api.get(request).pipe(
      map((m) => {
        return getPageReturn(m);
      })
    );
  }

  get(id: any, params?: any): Observable<WorkModeDetectorDeviceInfo> {
    throw new Error('Method not implemented.');
  }
  add(model: WorkModeDetectorDeviceInfo, params?: any): Observable<WorkModeDetectorDeviceInfo> {
    throw new Error('Method not implemented.');
  }
  update(model: WorkModeDetectorDeviceInfo, params?: any): Observable<WorkModeDetectorDeviceInfo> {
    throw new Error('Method not implemented.');
  }
  delete(ids: any[], params?: any): Observable<number> {
    throw new Error('Method not implemented.');
  }

  getWorkModeOptions(id: string, customerId: string) {
    const request = new GetDetectorDeviceWorkModeRequest({ id, customerId });
    return this._api.get(request);
  }
  updateWorkModeOptions(id: string, customerId: string, entity: DetectorDeviceWorkModeOptions) {
    const request = new UpdateDetectorDeviceWorkModeRequest({ id, customerId, entity });
    return this._api.put(request);
  }
}
