<div [style.height]="external ? '100%' : '430px'">
  <div *ngIf="loadingTab" class="backdrop" style="z-index: 1;">
    <mat-spinner diameter="36" color="accent"></mat-spinner>
  </div>
  <mat-tab-group
    animationDuration="0ms"
    class="mat-tab-group-sm"
    style="height: 100%;"
    (selectedTabChange)="changeEvent($event)"
  >
    <mat-tab label="CONFIGURATION">
      <ng-template mat-tab-label>
        {{ "METEO_OBJECTS.POPUP.CONFIGURATION" | translate }}
      </ng-template>
      <div style="overflow: auto; padding: 8px;">
        <table class="table">
          <tr class="mat-hover">
            <td>{{ "POPUP.TYPE" | translate }}</td>
            <td>{{ modelInfo.deviceType }}</td>
          </tr>
          <tr class="mat-hover" *ngIf="!isFakeMeteo(modelInfo.id)">
            <td>{{ "DETECTORS.DEVICE" | translate }}</td>
            <td>{{ modelInfo.serialNumber }}</td>
          </tr>
          <tr class="mat-hover">
            <td>{{ "POPUP.STATUS_CONNECTED" | translate }}</td>
            <td
              *ngIf="!modelInfoSession?.session?.connected"
              style="color: #ff0000;"
            >
              {{ "POPUP.DONT_CONNECTED" | translate }}
            </td>
            <td
              *ngIf="modelInfoSession?.session?.connected"
              style="color: #00ff22;"
            >
              {{ "POPUP.CONNECTED" | translate }}
            </td>
          </tr>
          <tr class="mat-hover">
            <td>{{ "POPUP.CONNECTION_TIME" | translate }}</td>
            <td>
              {{ modelInfoSession?.session?.connectionTime | date:
              "long":null:locale }}
            </td>
          </tr>
          <tr class="mat-hover">
            <td>{{ "POPUP.LAST_RECEIVED" | translate }}</td>
            <td>
              {{ modelInfoSession?.session?.lastReceived | date:
              "long":null:locale }}
            </td>
          </tr>
          <tr class="mat-hover">
            <td>{{ "POPUP.LAST_SENT" | translate }}</td>
            <td>
              {{ modelInfoSession?.session?.lastSent | date: "long":null:locale
              }}
            </td>
          </tr>
          <tr class="mat-hover">
            <td>{{ "METEO_OBJECTS.POPUP.CREATED_OBJECT" | translate }}</td>
            <td>{{ modelInfoSession?.created | date: "long":null:locale }}</td>
          </tr>
          <tr class="mat-hover">
            <td>{{ "METEO_OBJECTS.POPUP.LAST_CHANGES_OBJECT" | translate }}</td>
            <td>{{ modelInfoSession?.modified | date: "long":null:locale }}</td>
          </tr>
        </table>
        <p>{{ "METEO_OBJECTS.POPUP.SENSORS_METEO_OBJECT" | translate }}:</p>
        <mat-accordion>
          <mat-expansion-panel
            *ngFor="let item of meteoSensor; let index = index"
            style="background-color: #62626234;"
          >
            <mat-expansion-panel-header style="background-color: #4c4c4c;">
              <mat-panel-title style="align-items: center;"
                >{{ index + 1 }}. {{ getMeteoStationName(item) | translate }}(
                {{ item.type }} )
                <div *ngIf="item.error" style="margin-left: 5px;">
                  <mat-icon style="vertical-align: middle; color: #bd2727;"
                    >error_outline</mat-icon
                  >
                </div>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <div *ngIf="item.error" style="margin-top: 10px;">
              <span style="border-bottom: 2px solid red;"
                >Ошибка : {{ item.error }}</span
              >
            </div>
            <p>{{ "METEO_OBJECTS.POPUP.PARAMETRS_SENSOR" | translate }}:</p>
            <div
              *ngFor="let param of item.measureParameters; let indexParam = index; let first = first"
            >
              <mat-divider *ngIf="!first"></mat-divider>
              <p>
                {{ indexParam + 1 }}. {{ getCategoryName(param) | translate }}
              </p>
            </div>
          </mat-expansion-panel>
        </mat-accordion>
      </div>
    </mat-tab>

    <mat-tab
      *ngIf="meteoSensor && isTypeBriz && moduleSettings?.meteoMonitoringSettings.enabled"
      label="PHOTO"
      style="align-items: center; min-height: 300px !important;"
    >
      <ng-template mat-tab-label>
        {{ "POPUP.PHOTO" | translate }}
      </ng-template>
      <div fxFlexFill fxLayoutAlign="center center" style="min-height: 360px;">
        <div style="position: absolute; right: 8px; z-index: 10; opacity: 0.8;">
          <div
            fxLayout="column"
            fxLayoutGap="5px"
            fxLayoutAlign="space-between center"
          >
            <button
              (click)="camControl('MoveUp')"
              mat-mini-fab
              title="Повернуть вверх"
            >
              <mat-icon style="transform: rotate(270deg);">play_arrow</mat-icon>
            </button>
            <div
              fxLayoutGap="5px"
              fxLayout="row"
              fxLayoutAlign="space-between center"
            >
              <button
                (click)="camControl('MoveLeft')"
                mat-mini-fab
                title="Повернуть налево"
              >
                <mat-icon style="transform: rotate(180deg);"
                  >play_arrow</mat-icon
                >
              </button>
              <button
                (click)="camControl('MoveHome')"
                mat-mini-fab
                title="Вернуть домой"
              >
                <mat-icon>home</mat-icon>
              </button>
              <button
                (click)="camControl('MoveRight')"
                mat-mini-fab
                title="Повернуть направо"
              >
                <mat-icon>play_arrow</mat-icon>
              </button>
            </div>
            <button
              (click)="camControl('MoveDown')"
              mat-mini-fab
              title="Повернуть вниз"
            >
              <mat-icon style="transform: rotate(90deg);">play_arrow</mat-icon>
            </button>
            <button
              mat-raised-button
              (click)="camControl('LightOn')"
              style="
                width: 170px;
                height: 25px;
                font-size: 13px;
                border: 0.7px solid #fdd540 !important;
                background: transparent;
                line-height: 1;
              "
            >
              {{ "METEO_OBJECTS.POPUP.ENABLE_LIGHT" | translate }}
            </button>
            <button
              mat-raised-button
              (click)="camControl('LightOff')"
              style="
                width: 170px;
                height: 25px;
                font-size: 13px;
                border: 0.7px solid #fdd540 !important;
                background: transparent;
                line-height: 1;
              "
            >
              {{ "METEO_OBJECTS.POPUP.DISABLE_LIGHT" | translate }}
            </button>
            <button
              mat-raised-button
              (click)="camControl('Wipe')"
              style="
                width: 170px;
                height: 25px;
                font-size: 13px;
                border: 0.7px solid #fdd540 !important;
                background: transparent;
                line-height: 1;
              "
            >
              {{ "METEO_OBJECTS.POPUP.CLEAR_PHOTO" | translate }}
            </button>
            <button
              mat-raised-button
              (click)="getImage(true)"
              style="
                width: 170px;
                height: 25px;
                font-size: 13px;
                border: 0.7px solid #fdd540 !important;
                background: transparent;
                line-height: 1;
              "
            >
              {{ "METEO_OBJECTS.POPUP.REFRESH_PHOTO" | translate }}
            </button>
          </div>
        </div>
        <div
          class="images"
          style="width: 100%; max-height: 100%; overflow: hidden;"
        >
          <img
            fxFill
            id="photo_{{ modelId }}"
            [style.width]="external ? '80wh' : '100%'"
            style="height: auto; max-height: 100%; min-height: 0;"
          />
        </div>
      </div>
    </mat-tab>

    <!-- <mat-tab label="{{ 'METEO_OBJECTS.POPUP.FORECAST' | translate }}">  -->
    <!-- <mat-tab label="FORECAST"> -->
    <!-- *ngIf="modelInfoSession?.session?.connected && moduleSettings?.meteoMonitoringSettings.enabled" -->
    <mat-tab
      *ngIf="modelInfoSession?.session?.connected && moduleSettings?.meteoMonitoringSettings.enabled  && isTypeBriz"
      label="FORECAST"
    >
      <ng-template mat-tab-label>
        {{ "METEO_OBJECTS.POPUP.FORECAST" | translate }}
      </ng-template>
      <div style="overflow: auto; padding: 8px;">
        <p>{{ "METEO_OBJECTS.POPUP.FORECAST_TITLE" | translate }}:</p>
        <table class="table">
          <tr class="mat-hover">
            <td>
              {{ "METEO_OBJECTS.NAME_PARAMETR.AIR_TEMPERATURE" | translate }}
            </td>
            <td>
              {{ foreCast?.airTemperature ? foreCast?.airTemperature + "
              &#176;С" : 'Нет данных' }}
            </td>
          </tr>
          <tr class="mat-hover">
            <td>
              {{ "METEO_OBJECTS.NAME_PARAMETR.ATMOSPHERIC_PRESSURE" | translate
              }}
            </td>
            <td>
              {{ foreCast?.atmosphericPressure ? foreCast?.atmosphericPressure +
              " мм.рт.ст." : "Нет данных" }}
            </td>
          </tr>
          <tr class="mat-hover">
            <td>
              {{ "METEO_OBJECTS.NAME_PARAMETR.HUMIDITY_PERCENT" | translate }}
            </td>
            <td>
              {{ foreCast?.humidityPercent ? foreCast?.humidityPercent + "%" :
              "Нет данных" }}
            </td>
          </tr>
        </table>
        <p
          *ngIf="!getTypePrecipitationNone(foreCast?.typePrecipitation) && foreCast?.typePrecipitation !='0'"
        >
          {{ "METEO_OBJECTS.POPUP.NO_PRECIPITATION" | translate}}
        </p>
        <p
          *ngIf="getTypePrecipitationNone(foreCast?.typePrecipitation) && foreCast?.typePrecipitation !='0' "
        >
          {{ "METEO_OBJECTS.POPUP.PROBABILITY_OF_PRECIPITATION" | translate: {
          type: getTypePrecipitation(foreCast?.typePrecipitation) , probability:
          foreCast?.probabilityPercent ? foreCast?.probabilityPercent : ' ' } }}

          <!-- Вероятность осадков типа "{{
          getTypePrecipitation(foreCast?.typePrecipitation) }}" составляет {{
          foreCast?.probabilityPercent }}% -->
        </p>
        <p *ngIf="foreCast?.probabilityFrosts">
          {{ "METEO_OBJECTS.POPUP.FROST_CHANCE" | translate: { type:
          getFrostsProbabilities(foreCast?.probabilityFrosts) } | lowercase }}
          <!-- Вероятность заморозков {{
          getFrostsProbabilities(foreCast?.probabilityFrosts) | translate |
          lowercase }}. -->
        </p>
      </div>
    </mat-tab>
    <!-- *ngIf="modelInfoSession?.session?.connected && moduleSettings?.ecoMonitoringSettings.enabled" -->
    <mat-tab
      *ngIf="moduleSettings?.ecoMonitoringSettings.enabled && haveEcology"
      label="ECO_FORECAST"
    >
      <ng-template mat-tab-label>
        {{ "METEO_OBJECTS.POPUP.ECO_FORECAST" | translate }}
      </ng-template>
      <div style="overflow: auto; padding: 8px;">
        <p>{{ "METEO_OBJECTS.POPUP.ECO_FORECAST_TITLE" | translate }}:</p>
        <table class="table">
          <!-- <tr
            class="mat-hover"
            *ngFor="let item of forecastEco; let index = index"
          >
            <td>
              {{ getCategoryName(item?.measureParameter) | translate }}
            </td>
            <td>
              {{ item?.value.toFixed(2) + " " + (
              getCategoryUnit(item?.measureParameter?.unit ) | translate) }}
            </td>
          </tr> -->
          <tr class="mat-hover" *ngFor="let item of forecastEco | keyvalue">
            <td
              *ngIf="item.key !== 'isDataFull' && item.key !== 'description' "
            >
              <!-- {{item.key}} -->

              {{ getCategoryName(item?.value?.measureParameter) | translate }}
              <!-- {{getName(item.key)}} -->
            </td>
            <td *ngIf="item.key !== 'isDataFull' && item.key !== 'description'">
              <!-- {{item.value.value}} -->

              {{ item?.value?.value?.toFixed(2) + " " + (
              getCategoryUnit(item?.value?.measureParameter?.unit ) | translate)
              }}

              <!-- {{getName(item.value)}} -->
            </td>
          </tr>
        </table>
        <div
          *ngIf="forecastEco.description && forecastEco?.description?.length !==0"
          style="display: flex; flex-direction: column;"
        >
          <span>
            Дополнительная информация:
          </span>
          <div
            *ngFor="let item of forecastEco.description ; let index = index"
            style="display: flex; flex-direction: column;"
          >
            <span>
              {{item }}
            </span>
          </div>
        </div>
      </div>
    </mat-tab>

    <!-- [label]="getMeteoStationName(item)" -->
    <mat-tab
      *ngFor="let item of meteoSensor; let index = index"
      [label]="index"
    >
      <div
        *ngIf="item.error"
        style="
          display: flex;
          position: absolute;
          top: 50%;
          left: 50%;
          background-color: #565656;
          padding: 15px 25px;
          z-index: 1000;
          border: 2px solid red;
          border-radius: 5px;
          transform: translate(-50%, -50%);
          opacity: 0.85;
        "
      >
        <span>{{ item.error }}</span>
      </div>
      <ng-template mat-tab-label>
        <span>{{ getMeteoStationName(item) | translate }}</span>
        <div *ngIf="item.error" style="margin-left: 5px;">
          <mat-icon style="vertical-align: middle; color: #bd2727;"
            >error_outline</mat-icon
          >
        </div>
      </ng-template>
      <div
        fxFlexFill
        fxLayout="column"
        style="font-size: 14px; padding: 0px 8px;"
      >
        <div
          fxLayout="row"
          fxLayoutAlign="space-between center"
          style="padding-top: 8px;"
        >
          <div
            fxFlex="auto"
            fxLayout="column"
            fxLayoutGap="8px"
            style="font-size: 12px;"
          >
            <div fxFlex>
              {{ "METEO_OBJECTS.POPUP.CURRENT_VALUE" | translate }}: {{
              latestValue }} {{ unitLastValue | translate }}
            </div>
            <div *ngIf="item.name==='EcologySensor'" fxFlex>
              {{ "METEO_OBJECTS.POPUP.PREDICTION_VALUE" | translate }}: {{
              predictionValue }} {{ unitLastValue | translate }}
            </div>
          </div>
          <div fxFlex></div>
          <div fxFlex="nogrow">
            <mat-form-field style="width: 250px;">
              <mat-select
                placeholder="{{ 'COMMON.PARAMETER' | translate }}"
                disableOptionCentering
                panelClass="single-select-panel mat-select-md fs-14"
                [formControl]="meteoParams"
              >
                <mat-option
                  *ngFor="let param of item.measureParameters; let indexParam = index"
                  [value]="param.id"
                >
                  {{ getCategoryName(param) | translate }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>

        <ng-container *ngIf="chart">
          <div fxFlex style="overflow: auto; flex: 1;">
            <div
              echarts
              id="graf_{{ modelId }}_{{ item.name }}"
              class="chartGraf"
              (window:resize)="onResize($event)"
              style="height: 100%;"
            ></div>
          </div>
        </ng-container>

        <ng-container *ngIf="!chart">
          <div>{{ "COMMON.HISTORY" | translate }}:</div>
          <div fxFlex style="overflow: auto; flex: 1;">
            <table class="table">
              <tr *ngFor="let item of itemTable" class="mat-hover">
                <td>
                  {{ item?.timeStamp | date: "short":null:locale }}
                </td>
                <td>
                  {{ getTableItemName(item?.stringValue) | translate }}
                </td>
              </tr>
            </table>
          </div>
        </ng-container>
      </div>
    </mat-tab>
  </mat-tab-group>
</div>
