import { Injectable } from '@angular/core';

import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import {
  IDataSourceService,
  setPageParams,
  getPageReturn,
  IPageParams,
  IPageReturn,
} from 'projects/msu-its-web-common/src/data/data-source';

import {
  GetTrafficObjectAdaptiveOptionsRequest,
  UpdateTrafficObjectAdaptiveOptionsRequest,
  TrafficObjectAdaptiveOptions,
  GetAdaptiveTrafficObjectsRequest,
  AdaptiveTrafficObjectInfo,
} from '../dtos/tlc.dtos';

import { TlcApiService } from './tlc-api.service';

@Injectable()
export class AdaptiveTrafficObjectService
  implements IDataSourceService<AdaptiveTrafficObjectInfo, AdaptiveTrafficObjectInfo>
{
  constructor(private _api: TlcApiService) {}

  getPagedList(params: IPageParams): Observable<IPageReturn<AdaptiveTrafficObjectInfo>> {
    const request = new GetAdaptiveTrafficObjectsRequest();
    setPageParams(request, params);
    return this._api.get(request).pipe(
      map((m) => {
        return getPageReturn(m);
      })
    );
  }

  get(id: any, params?: any): Observable<AdaptiveTrafficObjectInfo> {
    throw new Error('Method not implemented.');
  }
  add(model: AdaptiveTrafficObjectInfo, params?: any): Observable<AdaptiveTrafficObjectInfo> {
    throw new Error('Method not implemented.');
  }
  update(model: AdaptiveTrafficObjectInfo, params?: any): Observable<AdaptiveTrafficObjectInfo> {
    throw new Error('Method not implemented.');
  }
  delete(ids: any[], params?: any): Observable<number> {
    throw new Error('Method not implemented.');
  }

  getAdaptiveOptions(id: string, customerId: string) {
    const request = new GetTrafficObjectAdaptiveOptionsRequest({ id, customerId });
    return this._api.get(request);
  }
  updateAdaptiveOptions(id: string, customerId: string, entity: TrafficObjectAdaptiveOptions) {
    const request = new UpdateTrafficObjectAdaptiveOptionsRequest({ id, customerId, entity });
    return this._api.put(request);
  }
}
