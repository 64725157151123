import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import {
  TrafficPlanType,
  UsdkControllerErrorType,
  UsdkPlanType,
  UsdkTriggerType,
} from '../tlc.dtos';

export const USDK_PLAN_TYPES = [
  {
    type: UsdkPlanType.PhaseCycle,
    name: _('USDK_PLAN_TYPES.PHASE_CYCLE'),
    planType: TrafficPlanType.PhaseCycle,
  },
  {
    type: UsdkPlanType.LocalMode,
    name: _('USDK_PLAN_TYPES.LOCAL_MODE'),
    planType: TrafficPlanType.PhaseCycle,
  },
  {
    type: UsdkPlanType.YellowBlink,
    name: _('USDK_PLAN_TYPES.YELLOW_BLINK'),
    planType: TrafficPlanType.YellowBlink,
  },
  {
    type: UsdkPlanType.Disabled,
    name: _('USDK_PLAN_TYPES.DISABLED'),
    planType: TrafficPlanType.Off,
  },
  {
    type: UsdkPlanType.PhaseCycleLocal,
    name: _('USDK_PLAN_TYPES.PHASE_CYCLE_LOCAL'),
    planType: TrafficPlanType.PhaseCycle,
  },
];

export const USDK_TRIGGER_TYPES = [
  { type: UsdkTriggerType.TVP1, name: _('USDK_TRIGGER_TYPES.TVP1') },
  { type: UsdkTriggerType.TVP2, name: _('USDK_TRIGGER_TYPES.TVP2') },
  { type: UsdkTriggerType.TVP12, name: _('USDK_TRIGGER_TYPES.TVP12') },
];

export const USDK_LOCAL_TRIGGER_TYPES = [
  { type: UsdkTriggerType.TVP1, name: _('USDK_TRIGGER_TYPES.TVP1') },
  { type: UsdkTriggerType.TVP2, name: _('USDK_TRIGGER_TYPES.TVP2') },
];

export const USDK_ERROR_TYPES = [
  {
    type: UsdkControllerErrorType.PowerOff,
    name: _('USDK_ERROR_TYPES.POWER_OFF'),
  },
  {
    type: UsdkControllerErrorType.ConflictSit,
    name: _('USDK_ERROR_TYPES.CONFLICT_SIT'),
  },
  {
    type: UsdkControllerErrorType.LampControl,
    name: _('USDK_ERROR_TYPES.LAMP_CONTROL'),
  },
  {
    type: UsdkControllerErrorType.NegodPoPereg,
    name: _('USDK_ERROR_TYPES.NEGOD_PO_PEREG'),
  },
  {
    type: UsdkControllerErrorType.NepodCommand,
    name: _('USDK_ERROR_TYPES.NEPOD_COMMAND'),
  },
  {
    type: UsdkControllerErrorType.NevklVKoord,
    name: _('USDK_ERROR_TYPES.NEVKL_V_KOORD'),
  },
  {
    type: UsdkControllerErrorType.NgParitet,
    name: _('USDK_ERROR_TYPES.NG_PARITET'),
  },
  {
    type: UsdkControllerErrorType.ObrivLs,
    name: _('USDK_ERROR_TYPES.OBRIV_LS'),
  },
  {
    type: UsdkControllerErrorType.ObrivLsEvm,
    name: _('USDK_ERROR_TYPES.OBRIV_LS'),
  },
  {
    type: UsdkControllerErrorType.Offline,
    name: _('USDK_ERROR_TYPES.OFFLINE'),
  },
  {
    type: UsdkControllerErrorType.ClockNotSync,
    name: _('USDK_ERROR_TYPES.CLOCK_NOT_SYNC'),
  },
  {
    type: UsdkControllerErrorType.GpsError,
    name: _('USDK_ERROR_TYPES.GPS_ERROR'),
  },
  {
    type: UsdkControllerErrorType.NoData,
    name: _('USDK_ERROR_TYPES.NO_DATA'),
  },
];
