import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { IGroupShareService } from 'projects/msu-its-web-common/src/components/groups/group-shares.component';
import { MeteoApiService } from './meteo-api.service';
import {
  EntityGroupPermissions,
  GetMeteoObjectGroupSharesRequest,
  UpdateMeteoObjectGroupShareRequest,
} from '../dtos/meteo.dtos';

@Injectable()
export class MeteoObjectGroupShareService implements IGroupShareService {
  constructor(private _api: MeteoApiService) {}

  getList(id: string, customerId: string) {
    const request = new GetMeteoObjectGroupSharesRequest();
    request.id = id;
    request.customerId = customerId;
    request.items = 0;
    request.page = 0;
    request.sort = 'customerName';

    return this._api.get(request).pipe(map((m) => m.items));
  }

  update(model: EntityGroupPermissions, customerId: string) {
    const request = new UpdateMeteoObjectGroupShareRequest();
    request.id = model.entityGroupId;
    request.entity = model;
    request.customerId = customerId;
    return this._api.put(request);
  }
}
