/* Options:
Date: 2024-04-27 16:09:03
Version: 6.70
Tip: To override a DTO option, remove "//" prefix before updating
BaseUrl: https://accident.dev.its.msu24.ru

//GlobalNamespace: 
//MakePropertiesOptional: False
//AddServiceStackTypes: True
//AddResponseStatus: False
//AddImplicitVersion: 
//AddDescriptionAsComments: True
//IncludeTypes: 
//ExcludeTypes: 
//DefaultImports: 
*/

export interface IReturn<T> {
  createResponse(): T;
}

export interface IReturnVoid {
  createResponse(): void;
}

export interface IHasSessionId {
  sessionId: string;
}

export interface IHasBearerToken {
  bearerToken: string;
}

export interface IPost {}

// @DataContract
export class PageRequest {
  // @DataMember
  public page: number;

  // @DataMember
  public items: number;

  // @DataMember
  public sort: string;

  // @DataMember
  public filter: string;

  // @DataMember
  public propertiesTest: { [index: string]: number };

  public constructor(init?: Partial<PageRequest>) {
    (Object as any).assign(this, init);
  }
}

export interface ICustomerItemRequest extends ICustomerEntity {}

export interface ICustomerEntity {
  // @DataMember
  customerId: string;
}

export interface ICustomerIntegrationIdRequest {
  customerIntegrationId: string;
}

export interface IBaseEntityItemRequest {
  id: string;
}

export interface IDescription {
  // @DataMember
  description: string;
}

export interface IRequestWithEntity {}

// @DataContract
export class GetEntityGroupsRequestBase implements ICustomerItemRequest {
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public entityGroups: string[];

  // @DataMember
  public itemsIds: string[];

  // @DataMember
  public onlyOwnedItems: boolean;

  // @DataMember
  public page: number;

  // @DataMember
  public items: number;

  // @DataMember
  public sort: string;

  // @DataMember
  public filter: string;

  // @DataMember
  public propertiesTest: { [index: string]: number };

  public constructor(init?: Partial<GetEntityGroupsRequestBase>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class GetEntityGroupRequestBase implements ICustomerItemRequest, IBaseEntityItemRequest {
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember(IsRequired=true)
  public id: string;

  public constructor(init?: Partial<GetEntityGroupRequestBase>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class EntityGroup
  implements
    ICustomerEntityViewModel,
    IExternalSystemEntity,
    INamedEntity,
    IChangeKey,
    IDescription,
    IModified,
    IEntity
{
  // @DataMember
  public items: string[];

  // @DataMember
  public customerId: string;

  // @DataMember
  public customerName: string;

  // @DataMember
  public externalSystemId?: string;

  // @DataMember
  public externalSystemName: string;

  // @DataMember
  public externalSystemEntityId?: string;

  // @DataMember
  public name: string;

  // @DataMember
  public description: string;

  // @DataMember
  public changeKey: string;

  // @DataMember
  public created: string;

  // @DataMember
  public createdBy: string;

  // @DataMember
  public modified: string;

  // @DataMember
  public modifiedBy: string;

  // @DataMember
  public id: string;

  // @DataMember
  public entityType: string;

  public constructor(init?: Partial<EntityGroup>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class CreateEntityGroupRequestBase implements ICustomerItemRequest, IDescription {
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public updateEntityGroups: boolean;

  // @DataMember
  public entityGroups: string[];

  // @DataMember(IsRequired=true)
  public entity: EntityGroup;

  // @DataMember
  public description: string;

  public constructor(init?: Partial<CreateEntityGroupRequestBase>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class UpdateEntityGroupRequestBase
  implements ICustomerItemRequest, IBaseEntityItemRequest, IDescription
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public updateEntityGroups: boolean;

  // @DataMember
  public entityGroups: string[];

  // @DataMember(IsRequired=true)
  public id: string;

  // @DataMember(IsRequired=true)
  public entity: EntityGroup;

  // @DataMember
  public description: string;

  public constructor(init?: Partial<UpdateEntityGroupRequestBase>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class DeleteEntityGroupsRequestBase implements ICustomerItemRequest, IDescription {
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public description: string;

  // @DataMember(IsRequired=true)
  public ids: string[];

  public constructor(init?: Partial<DeleteEntityGroupsRequestBase>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class GetEntityGroupSharesRequestBase
  implements IBaseEntityItemRequest, ICustomerItemRequest
{
  // @DataMember
  public id: string;

  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public entityGroups: string[];

  // @DataMember
  public itemsIds: string[];

  // @DataMember
  public onlyOwnedItems: boolean;

  // @DataMember
  public page: number;

  // @DataMember
  public items: number;

  // @DataMember
  public sort: string;

  // @DataMember
  public filter: string;

  // @DataMember
  public propertiesTest: { [index: string]: number };

  public constructor(init?: Partial<GetEntityGroupSharesRequestBase>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class EntityGroupPermissions implements IEntityTyped, ICustomerEntityViewModel {
  // @DataMember
  public customerId: string;

  // @DataMember
  public customerName: string;

  // @DataMember
  public entityGroupId: string;

  // @DataMember
  public permissions: string[];

  // @DataMember
  public entityType: string;

  public constructor(init?: Partial<EntityGroupPermissions>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class UpgradeEntityGroupShareRequestBase implements ICustomerItemRequest, IDescription {
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember(IsRequired=true)
  public id: string;

  // @DataMember(IsRequired=true)
  public entity: EntityGroupPermissions;

  // @DataMember
  public description: string;

  public constructor(init?: Partial<UpgradeEntityGroupShareRequestBase>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class PtzCameraLocation
  implements
    ICustomerEntityViewModel,
    IExternalSystemEntity,
    INamedEntity,
    IChangeKey,
    IDescription,
    IModified,
    IEntity
{
  // @DataMember
  public lat: number;

  // @DataMember
  public lon: number;

  // @DataMember
  public note: string;

  // @DataMember
  public customerId: string;

  // @DataMember
  public customerName: string;

  // @DataMember
  public externalSystemId?: string;

  // @DataMember
  public externalSystemName: string;

  // @DataMember
  public externalSystemEntityId?: string;

  // @DataMember
  public name: string;

  // @DataMember
  public description: string;

  // @DataMember
  public changeKey: string;

  // @DataMember
  public created: string;

  // @DataMember
  public createdBy: string;

  // @DataMember
  public modified: string;

  // @DataMember
  public modifiedBy: string;

  // @DataMember
  public id: string;

  // @DataMember
  public entityType: string;

  public constructor(init?: Partial<PtzCameraLocation>) {
    (Object as any).assign(this, init);
  }
}

export class DeleteCustomerItemsRequest implements ICustomerItemRequest, IDescription {
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public description: string;

  // @DataMember(IsRequired=true)
  public ids: string[];

  public constructor(init?: Partial<DeleteCustomerItemsRequest>) {
    (Object as any).assign(this, init);
  }
}

export enum CameraCommand {
  MoveLeft = 'MoveLeft',
  MoveRight = 'MoveRight',
  MoveUp = 'MoveUp',
  MoveDown = 'MoveDown',
  MoveHome = 'MoveHome',
  FocusIn = 'FocusIn',
  FocusOut = 'FocusOut',
  ZoomIn = 'ZoomIn',
  ZoomOut = 'ZoomOut',
}

// @DataContract
export class GetCustomerEntityCurrentUserPermissionsRequestBase
  implements ICustomerItemRequest, IBaseEntityItemRequest
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember(IsRequired=true)
  public id: string;

  public constructor(init?: Partial<GetCustomerEntityCurrentUserPermissionsRequestBase>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class StaticCameraLocation
  implements
    ICustomerEntityViewModel,
    IExternalSystemEntity,
    INamedEntity,
    IChangeKey,
    IDescription,
    IModified,
    IEntity
{
  // @DataMember
  public lat: number;

  // @DataMember
  public lon: number;

  // @DataMember
  public note: string;

  // @DataMember
  public customerId: string;

  // @DataMember
  public customerName: string;

  // @DataMember
  public externalSystemId?: string;

  // @DataMember
  public externalSystemName: string;

  // @DataMember
  public externalSystemEntityId?: string;

  // @DataMember
  public name: string;

  // @DataMember
  public description: string;

  // @DataMember
  public changeKey: string;

  // @DataMember
  public created: string;

  // @DataMember
  public createdBy: string;

  // @DataMember
  public modified: string;

  // @DataMember
  public modifiedBy: string;

  // @DataMember
  public id: string;

  // @DataMember
  public entityType: string;

  public constructor(init?: Partial<StaticCameraLocation>) {
    (Object as any).assign(this, init);
  }
}

export enum AccidentType {
  HittingAnAnimal = 'HittingAnAnimal',
  Crash = 'Crash',
  Rollover = 'Rollover',
  HittingAStandingVehicle = 'HittingAStandingVehicle',
  HittingAnObstacle = 'HittingAnObstacle',
  HittingAPedestrian = 'HittingAPedestrian',
  HittingACyclist = 'HittingACyclist',
  HittingOnHorseDrawnTransport = 'HittingOnHorseDrawnTransport',
  PassengerFall = 'PassengerFall',
  Other = 'Other',
}

// @DataContract
export enum ReportFormat {
  Json = 'Json',
  Csv = 'Csv',
  Xlsx = 'Xlsx',
}

export interface IChangeKey extends IReadonlyChangeKey {
  // @DataMember
  changeKey: string;
}

export interface IReadonlyChangeKey {
  // @DataMember
  changeKey: string;
}

// @DataContract
export class BaseEntityViewModelBase implements IChangeKey, IDescription, IModified, IEntity {
  // @DataMember
  public description: string;

  // @DataMember
  public changeKey: string;

  // @DataMember
  public created: string;

  // @DataMember
  public createdBy: string;

  // @DataMember
  public modified: string;

  // @DataMember
  public modifiedBy: string;

  // @DataMember
  public id: string;

  // @DataMember
  public entityType: string;

  public constructor(init?: Partial<BaseEntityViewModelBase>) {
    (Object as any).assign(this, init);
  }
}

export interface IEntity {}

export interface IEntityTyped {
  // @DataMember
  entityType: string;
}

export interface IModified {
  // @DataMember
  modified: string;

  // @DataMember
  modifiedBy: string;
}

// @DataContract
export class EntityGroupInfo
  implements
    ICustomerEntityViewModel,
    IExternalSystemEntity,
    INamedEntity,
    IChangeKey,
    IDescription,
    IModified,
    IEntity
{
  // @DataMember
  public itemsCount: number;

  // @DataMember
  public customerId: string;

  // @DataMember
  public customerName: string;

  // @DataMember
  public externalSystemId?: string;

  // @DataMember
  public externalSystemName: string;

  // @DataMember
  public externalSystemEntityId?: string;

  // @DataMember
  public name: string;

  // @DataMember
  public description: string;

  // @DataMember
  public changeKey: string;

  // @DataMember
  public created: string;

  // @DataMember
  public createdBy: string;

  // @DataMember
  public modified: string;

  // @DataMember
  public modifiedBy: string;

  // @DataMember
  public id: string;

  // @DataMember
  public entityType: string;

  public constructor(init?: Partial<EntityGroupInfo>) {
    (Object as any).assign(this, init);
  }
}

export class CustomerNamedBaseEntityViewModelBase
  implements
    ICustomerEntityViewModel,
    IExternalSystemEntity,
    INamedEntity,
    IChangeKey,
    IDescription,
    IModified,
    IEntity
{
  // @DataMember
  public customerId: string;

  // @DataMember
  public customerName: string;

  // @DataMember
  public externalSystemId?: string;

  // @DataMember
  public externalSystemName: string;

  // @DataMember
  public externalSystemEntityId?: string;

  // @DataMember
  public name: string;

  // @DataMember
  public description: string;

  // @DataMember
  public changeKey: string;

  // @DataMember
  public created: string;

  // @DataMember
  public createdBy: string;

  // @DataMember
  public modified: string;

  // @DataMember
  public modifiedBy: string;

  // @DataMember
  public id: string;

  // @DataMember
  public entityType: string;

  public constructor(init?: Partial<CustomerNamedBaseEntityViewModelBase>) {
    (Object as any).assign(this, init);
  }
}

export interface INamedEntity extends IEntity, INamed {}

export interface INamed {
  // @DataMember
  name: string;
}

export interface ICustomerEntityViewModel extends ICustomerName, ICustomerEntity {}

export interface ICustomerName {
  // @DataMember
  customerName: string;
}

export interface IExternalSystemEntity {
  // @DataMember
  externalSystemId?: string;

  // @DataMember
  externalSystemName: string;

  // @DataMember
  externalSystemEntityId?: string;
}

export enum CameraSourceType {
  FTP = 'FTP',
}

// @DataContract
export class TrafficAccidentLocation
  implements
    ICustomerEntityViewModel,
    IExternalSystemEntity,
    INamedEntity,
    IChangeKey,
    IDescription,
    IModified,
    IEntity
{
  // @DataMember
  public accidentType: AccidentType;

  // @DataMember
  public lat: number;

  // @DataMember
  public lon: number;

  // @DataMember
  public customerId: string;

  // @DataMember
  public customerName: string;

  // @DataMember
  public externalSystemId?: string;

  // @DataMember
  public externalSystemName: string;

  // @DataMember
  public externalSystemEntityId?: string;

  // @DataMember
  public name: string;

  // @DataMember
  public description: string;

  // @DataMember
  public changeKey: string;

  // @DataMember
  public created: string;

  // @DataMember
  public createdBy: string;

  // @DataMember
  public modified: string;

  // @DataMember
  public modifiedBy: string;

  // @DataMember
  public id: string;

  // @DataMember
  public entityType: string;

  public constructor(init?: Partial<TrafficAccidentLocation>) {
    (Object as any).assign(this, init);
  }
}

export class CustomerBaseEntityViewModelBase
  implements
    ICustomerEntityViewModel,
    IExternalSystemEntity,
    IChangeKey,
    IDescription,
    IModified,
    IEntity
{
  // @DataMember
  public customerId: string;

  // @DataMember
  public customerName: string;

  // @DataMember
  public externalSystemId?: string;

  // @DataMember
  public externalSystemName: string;

  // @DataMember
  public externalSystemEntityId?: string;

  // @DataMember
  public description: string;

  // @DataMember
  public changeKey: string;

  // @DataMember
  public created: string;

  // @DataMember
  public createdBy: string;

  // @DataMember
  public modified: string;

  // @DataMember
  public modifiedBy: string;

  // @DataMember
  public id: string;

  // @DataMember
  public entityType: string;

  public constructor(init?: Partial<CustomerBaseEntityViewModelBase>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class ResponseError {
  // @DataMember(Order=1)
  public errorCode: string;

  // @DataMember(Order=2)
  public fieldName: string;

  // @DataMember(Order=3)
  public message: string;

  // @DataMember(Order=4)
  public meta: { [index: string]: string };

  public constructor(init?: Partial<ResponseError>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class ResponseStatus {
  // @DataMember(Order=1)
  public errorCode: string;

  // @DataMember(Order=2)
  public message: string;

  // @DataMember(Order=3)
  public stackTrace: string;

  // @DataMember(Order=4)
  public errors: ResponseError[];

  // @DataMember(Order=5)
  public meta: { [index: string]: string };

  public constructor(init?: Partial<ResponseStatus>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class DeleteItemsRequest implements IDescription {
  // @DataMember
  public description: string;

  // @DataMember(IsRequired=true)
  public ids: string[];

  public constructor(init?: Partial<DeleteItemsRequest>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class EntityViewModelBase implements IEntity {
  // @DataMember
  public id: string;

  // @DataMember
  public entityType: string;

  public constructor(init?: Partial<EntityViewModelBase>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class NamedBaseEntityViewModelBase
  implements INamedEntity, IChangeKey, IDescription, IModified, IEntity
{
  // @DataMember
  public name: string;

  // @DataMember
  public description: string;

  // @DataMember
  public changeKey: string;

  // @DataMember
  public created: string;

  // @DataMember
  public createdBy: string;

  // @DataMember
  public modified: string;

  // @DataMember
  public modifiedBy: string;

  // @DataMember
  public id: string;

  // @DataMember
  public entityType: string;

  public constructor(init?: Partial<NamedBaseEntityViewModelBase>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class PageRequestBase {
  // @DataMember
  public page: number;

  // @DataMember
  public items: number;

  // @DataMember
  public sort: string;

  // @DataMember
  public filter: string;

  // @DataMember
  public propertiesTest: { [index: string]: number };

  public constructor(init?: Partial<PageRequestBase>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class EntityIntegration implements IChangeKey, IDescription, IModified, IEntity {
  // @DataMember
  public entityId: string;

  // @DataMember
  public customerIntegrationId: string;

  // @DataMember
  public externalId: string;

  // @DataMember
  public settings: string;

  // @DataMember
  public integrationId: string;

  // @DataMember
  public integrationName: string;

  // @DataMember
  public integrationType: string;

  // @DataMember
  public description: string;

  // @DataMember
  public changeKey: string;

  // @DataMember
  public created: string;

  // @DataMember
  public createdBy: string;

  // @DataMember
  public modified: string;

  // @DataMember
  public modifiedBy: string;

  // @DataMember
  public id: string;

  // @DataMember
  public entityType: string;

  public constructor(init?: Partial<EntityIntegration>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class GetEntityIntegrationResponse implements IChangeKey {
  // @DataMember
  public items: EntityIntegration[];

  // @DataMember
  public total: number;

  // @DataMember
  public page: number;

  // @DataMember
  public itemsPerPage: number;

  // @DataMember
  public changeKey: string;

  public constructor(init?: Partial<GetEntityIntegrationResponse>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class GetEntityGroupsResponse implements IChangeKey {
  // @DataMember
  public items: EntityGroupInfo[];

  // @DataMember
  public total: number;

  // @DataMember
  public page: number;

  // @DataMember
  public itemsPerPage: number;

  // @DataMember
  public changeKey: string;

  public constructor(init?: Partial<GetEntityGroupsResponse>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class GetEntitiesResponse implements IChangeKey {
  // @DataMember
  public items: Object[];

  // @DataMember
  public total: number;

  // @DataMember
  public page: number;

  // @DataMember
  public itemsPerPage: number;

  // @DataMember
  public changeKey: string;

  public constructor(init?: Partial<GetEntitiesResponse>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class GetEntityGroupSharesResponse implements IChangeKey {
  // @DataMember
  public items: EntityGroupPermissions[];

  // @DataMember
  public total: number;

  // @DataMember
  public page: number;

  // @DataMember
  public itemsPerPage: number;

  // @DataMember
  public changeKey: string;

  public constructor(init?: Partial<GetEntityGroupSharesResponse>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class GetPtzCamerasLocationResponse implements IChangeKey {
  // @DataMember
  public items: PtzCameraLocation[];

  // @DataMember
  public total: number;

  // @DataMember
  public page: number;

  // @DataMember
  public itemsPerPage: number;

  // @DataMember
  public changeKey: string;

  public constructor(init?: Partial<GetPtzCamerasLocationResponse>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class PtzCamera
  implements
    ICustomerEntityViewModel,
    IExternalSystemEntity,
    INamedEntity,
    IChangeKey,
    IDescription,
    IModified,
    IEntity
{
  // @DataMember
  public lat: number;

  // @DataMember
  public lon: number;

  // @DataMember
  public url: string;

  // @DataMember
  public username: string;

  // @DataMember
  public password: string;

  // @DataMember
  public rtmp: string;

  // @DataMember
  public note: string;

  // @DataMember
  public customerId: string;

  // @DataMember
  public customerName: string;

  // @DataMember
  public externalSystemId?: string;

  // @DataMember
  public externalSystemName: string;

  // @DataMember
  public externalSystemEntityId?: string;

  // @DataMember
  public name: string;

  // @DataMember
  public description: string;

  // @DataMember
  public changeKey: string;

  // @DataMember
  public created: string;

  // @DataMember
  public createdBy: string;

  // @DataMember
  public modified: string;

  // @DataMember
  public modifiedBy: string;

  // @DataMember
  public id: string;

  // @DataMember
  public entityType: string;

  public constructor(init?: Partial<PtzCamera>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class GetPtzCamerasResponse implements IChangeKey {
  // @DataMember
  public items: PtzCamera[];

  // @DataMember
  public total: number;

  // @DataMember
  public page: number;

  // @DataMember
  public itemsPerPage: number;

  // @DataMember
  public changeKey: string;

  public constructor(init?: Partial<GetPtzCamerasResponse>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class StaticCameraSource
  implements
    ICustomerEntityViewModel,
    IExternalSystemEntity,
    INamedEntity,
    IChangeKey,
    IDescription,
    IModified,
    IEntity
{
  // @DataMember
  public sourceType: CameraSourceType;

  // @DataMember
  public url: string;

  // @DataMember
  public username: string;

  // @DataMember
  public password: string;

  // @DataMember
  public customerId: string;

  // @DataMember
  public customerName: string;

  // @DataMember
  public externalSystemId?: string;

  // @DataMember
  public externalSystemName: string;

  // @DataMember
  public externalSystemEntityId?: string;

  // @DataMember
  public name: string;

  // @DataMember
  public description: string;

  // @DataMember
  public changeKey: string;

  // @DataMember
  public created: string;

  // @DataMember
  public createdBy: string;

  // @DataMember
  public modified: string;

  // @DataMember
  public modifiedBy: string;

  // @DataMember
  public id: string;

  // @DataMember
  public entityType: string;

  public constructor(init?: Partial<StaticCameraSource>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class GetStaticCameraSourcesResponse implements IChangeKey {
  // @DataMember
  public items: StaticCameraSource[];

  // @DataMember
  public total: number;

  // @DataMember
  public page: number;

  // @DataMember
  public itemsPerPage: number;

  // @DataMember
  public changeKey: string;

  public constructor(init?: Partial<GetStaticCameraSourcesResponse>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class StaticCameraImage
  implements
    ICustomerEntityViewModel,
    IExternalSystemEntity,
    INamedEntity,
    IChangeKey,
    IDescription,
    IModified,
    IEntity
{
  // @DataMember
  public staticCameraId: string;

  // @DataMember
  public staticCameraName: string;

  // @DataMember
  public date: string;

  // @DataMember
  public image: string;

  // @DataMember
  public customerId: string;

  // @DataMember
  public customerName: string;

  // @DataMember
  public externalSystemId?: string;

  // @DataMember
  public externalSystemName: string;

  // @DataMember
  public externalSystemEntityId?: string;

  // @DataMember
  public name: string;

  // @DataMember
  public description: string;

  // @DataMember
  public changeKey: string;

  // @DataMember
  public created: string;

  // @DataMember
  public createdBy: string;

  // @DataMember
  public modified: string;

  // @DataMember
  public modifiedBy: string;

  // @DataMember
  public id: string;

  // @DataMember
  public entityType: string;

  public constructor(init?: Partial<StaticCameraImage>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class GetStaticCameraImagesResponse implements IChangeKey {
  // @DataMember
  public items: StaticCameraImage[];

  // @DataMember
  public total: number;

  // @DataMember
  public page: number;

  // @DataMember
  public itemsPerPage: number;

  // @DataMember
  public changeKey: string;

  public constructor(init?: Partial<GetStaticCameraImagesResponse>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class GetStaticCamerasLocationResponse implements IChangeKey {
  // @DataMember
  public items: StaticCameraLocation[];

  // @DataMember
  public total: number;

  // @DataMember
  public page: number;

  // @DataMember
  public itemsPerPage: number;

  // @DataMember
  public changeKey: string;

  public constructor(init?: Partial<GetStaticCamerasLocationResponse>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class StaticCamera
  implements
    ICustomerEntityViewModel,
    IExternalSystemEntity,
    INamedEntity,
    IChangeKey,
    IDescription,
    IModified,
    IEntity
{
  // @DataMember
  public lat: number;

  // @DataMember
  public lon: number;

  // @DataMember
  public serial: string;

  // @DataMember
  public staticCameraSourceId: string;

  // @DataMember
  public staticCameraSourceName: string;

  // @DataMember
  public externalId: string;

  // @DataMember
  public customerId: string;

  // @DataMember
  public customerName: string;

  // @DataMember
  public externalSystemId?: string;

  // @DataMember
  public externalSystemName: string;

  // @DataMember
  public externalSystemEntityId?: string;

  // @DataMember
  public name: string;

  // @DataMember
  public description: string;

  // @DataMember
  public changeKey: string;

  // @DataMember
  public created: string;

  // @DataMember
  public createdBy: string;

  // @DataMember
  public modified: string;

  // @DataMember
  public modifiedBy: string;

  // @DataMember
  public id: string;

  // @DataMember
  public entityType: string;

  public constructor(init?: Partial<StaticCamera>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class GetStaticCamerasResponse implements IChangeKey {
  // @DataMember
  public items: StaticCamera[];

  // @DataMember
  public total: number;

  // @DataMember
  public page: number;

  // @DataMember
  public itemsPerPage: number;

  // @DataMember
  public changeKey: string;

  public constructor(init?: Partial<GetStaticCamerasResponse>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class TrafficAccident
  implements
    ICustomerEntityViewModel,
    IExternalSystemEntity,
    IChangeKey,
    IDescription,
    IModified,
    IEntity
{
  // @DataMember
  public accidentType: AccidentType;

  // @DataMember
  public accidentDate: string;

  // @DataMember
  public lat: number;

  // @DataMember
  public lon: number;

  // @DataMember
  public address: string;

  // @DataMember
  public injuredCount: number;

  // @DataMember
  public killedCount: number;

  // @DataMember
  public roadState: string;

  // @DataMember
  public roadLighting: string;

  // @DataMember
  public customerId: string;

  // @DataMember
  public customerName: string;

  // @DataMember
  public externalSystemId?: string;

  // @DataMember
  public externalSystemName: string;

  // @DataMember
  public externalSystemEntityId?: string;

  // @DataMember
  public description: string;

  // @DataMember
  public changeKey: string;

  // @DataMember
  public created: string;

  // @DataMember
  public createdBy: string;

  // @DataMember
  public modified: string;

  // @DataMember
  public modifiedBy: string;

  // @DataMember
  public id: string;

  // @DataMember
  public entityType: string;

  public constructor(init?: Partial<TrafficAccident>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class GetTrafficAccidentsResponse implements IChangeKey {
  // @DataMember
  public items: TrafficAccident[];

  // @DataMember
  public total: number;

  // @DataMember
  public page: number;

  // @DataMember
  public itemsPerPage: number;

  // @DataMember
  public changeKey: string;

  public constructor(init?: Partial<GetTrafficAccidentsResponse>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class AuthenticateResponse implements IHasSessionId, IHasBearerToken {
  // @DataMember(Order=1)
  public userId: string;

  // @DataMember(Order=2)
  public sessionId: string;

  // @DataMember(Order=3)
  public userName: string;

  // @DataMember(Order=4)
  public displayName: string;

  // @DataMember(Order=5)
  public referrerUrl: string;

  // @DataMember(Order=6)
  public bearerToken: string;

  // @DataMember(Order=7)
  public refreshToken: string;

  // @DataMember(Order=8)
  public profileUrl: string;

  // @DataMember(Order=9)
  public roles: string[];

  // @DataMember(Order=10)
  public permissions: string[];

  // @DataMember(Order=11)
  public responseStatus: ResponseStatus;

  // @DataMember(Order=12)
  public meta: { [index: string]: string };

  public constructor(init?: Partial<AuthenticateResponse>) {
    (Object as any).assign(this, init);
  }
}

// @Route("/healthStatus", "GET")
// @DataContract
export class GetHealthStatusRequest implements IReturn<string> {
  public constructor(init?: Partial<GetHealthStatusRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return '';
  }
  public getTypeName() {
    return 'GetHealthStatusRequest';
  }
}

// @Route("/customer/{customerId}/integration/{customerIntegrationId}/entities", "GET")
// @DataContract
export class GetEntityIntegrationsRequest
  implements
    IReturn<GetEntityIntegrationResponse>,
    ICustomerIntegrationIdRequest,
    ICustomerItemRequest
{
  // @DataMember
  public customerIntegrationId: string;

  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public entityGroups: string[];

  // @DataMember
  public itemsIds: string[];

  // @DataMember
  public onlyOwnedItems: boolean;

  // @DataMember
  public page: number;

  // @DataMember
  public items: number;

  // @DataMember
  public sort: string;

  // @DataMember
  public filter: string;

  // @DataMember
  public propertiesTest: { [index: string]: number };

  public constructor(init?: Partial<GetEntityIntegrationsRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new GetEntityIntegrationResponse();
  }
  public getTypeName() {
    return 'GetEntityIntegrationsRequest';
  }
}

// @Route("/customer/{customerId}/integration/{customerIntegrationId}/entity/{id}", "GET")
// @DataContract
export class GetEntityIntegrationRequest
  implements
    IReturn<EntityIntegration>,
    ICustomerIntegrationIdRequest,
    ICustomerItemRequest,
    IBaseEntityItemRequest
{
  // @DataMember
  public customerIntegrationId: string;

  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember(IsRequired=true)
  public id: string;

  public constructor(init?: Partial<GetEntityIntegrationRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new EntityIntegration();
  }
  public getTypeName() {
    return 'GetEntityIntegrationRequest';
  }
}

// @Route("/customer/{customerId}/integration/{customerIntegrationId}/entities", "POST")
// @DataContract
export class CreateEntityIntegrationRequest
  implements
    IReturn<EntityIntegration>,
    ICustomerIntegrationIdRequest,
    ICustomerItemRequest,
    IDescription
{
  // @DataMember
  public customerIntegrationId: string;

  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public updateEntityGroups: boolean;

  // @DataMember
  public entityGroups: string[];

  // @DataMember(IsRequired=true)
  public entity: EntityIntegration;

  // @DataMember
  public description: string;

  public constructor(init?: Partial<CreateEntityIntegrationRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new EntityIntegration();
  }
  public getTypeName() {
    return 'CreateEntityIntegrationRequest';
  }
}

// @Route("/customer/{customerId}/integration/{customerIntegrationId}/entity/{id}", "PUT")
// @DataContract
export class UpdateEntityIntegrationRequest
  implements
    IReturn<EntityIntegration>,
    ICustomerIntegrationIdRequest,
    ICustomerItemRequest,
    IBaseEntityItemRequest,
    IDescription
{
  // @DataMember
  public customerIntegrationId: string;

  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public updateEntityGroups: boolean;

  // @DataMember
  public entityGroups: string[];

  // @DataMember(IsRequired=true)
  public id: string;

  // @DataMember(IsRequired=true)
  public entity: EntityIntegration;

  // @DataMember
  public description: string;

  public constructor(init?: Partial<UpdateEntityIntegrationRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new EntityIntegration();
  }
  public getTypeName() {
    return 'UpdateEntityIntegrationRequest';
  }
}

// @Route("/entity/{id}/properties", "GET")
// @DataContract
export class GetEntityPropertiesRequest implements IReturn<any>, IBaseEntityItemRequest {
  // @DataMember(IsRequired=true)
  public id: string;

  public constructor(init?: Partial<GetEntityPropertiesRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return {};
  }
  public getTypeName() {
    return 'GetEntityPropertiesRequest';
  }
}

// @Route("/entity/{id}/properties", "PUT")
// @DataContract
export class UpdateEntityPropertiesRequest implements IReturn<any> {
  // @DataMember(IsRequired=true)
  public id: string;

  // @DataMember
  public description: string;

  // @DataMember
  public entity: { [index: string]: string };

  public constructor(init?: Partial<UpdateEntityPropertiesRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return {};
  }
  public getTypeName() {
    return 'UpdateEntityPropertiesRequest';
  }
}

// @Route("/entity/{id}/property/{property}", "GET")
// @DataContract
export class GetEntityPropertyRequest implements IReturn<string> {
  // @DataMember
  public property: string;

  // @DataMember(IsRequired=true)
  public id: string;

  public constructor(init?: Partial<GetEntityPropertyRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return '';
  }
  public getTypeName() {
    return 'GetEntityPropertyRequest';
  }
}

// @Route("/entity/{id}/property/{property}", "PUT")
// @DataContract
export class UpdateEntityPropertyRequest implements IReturn<string> {
  // @DataMember(IsRequired=true)
  public id: string;

  // @DataMember
  public description: string;

  // @DataMember
  public property: string;

  // @DataMember
  public value: string;

  public constructor(init?: Partial<UpdateEntityPropertyRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return '';
  }
  public getTypeName() {
    return 'UpdateEntityPropertyRequest';
  }
}

// @Route("/entities/property/{property}", "GET")
// @DataContract
export class GetEntitiesPropertyRequest implements IReturn<any> {
  // @DataMember
  public property: string;

  // @DataMember
  public filter: string;

  public constructor(init?: Partial<GetEntitiesPropertyRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return {};
  }
  public getTypeName() {
    return 'GetEntitiesPropertyRequest';
  }
}

// @Route("/customer/{customerId}/entity/{Id}/groups", "GET")
// @DataContract
export class GetItemEntityGroupsRequest
  implements IReturn<GetEntityGroupsResponse>, ICustomerItemRequest
{
  // @DataMember
  public id: string;

  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public entityGroups: string[];

  // @DataMember
  public itemsIds: string[];

  // @DataMember
  public onlyOwnedItems: boolean;

  // @DataMember
  public page: number;

  // @DataMember
  public items: number;

  // @DataMember
  public sort: string;

  // @DataMember
  public filter: string;

  // @DataMember
  public propertiesTest: { [index: string]: number };

  public constructor(init?: Partial<GetItemEntityGroupsRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new GetEntityGroupsResponse();
  }
  public getTypeName() {
    return 'GetItemEntityGroupsRequest';
  }
}

// @Route("/entities/remoteRepository/{repositoryId}/data", "GET")
// @DataContract
export class GetEntitiesRequest implements IReturn<GetEntitiesResponse> {
  // @DataMember
  public repositoryId: string;

  // @DataMember
  public modified?: string;

  // @DataMember
  public page: number;

  // @DataMember
  public items: number;

  // @DataMember
  public sort: string;

  // @DataMember
  public filter: string;

  // @DataMember
  public propertiesTest: { [index: string]: number };

  public constructor(init?: Partial<GetEntitiesRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new GetEntitiesResponse();
  }
  public getTypeName() {
    return 'GetEntitiesRequest';
  }
}

// @Route("/customer/{customerId}/ptzCameras/groups", "GET")
// @DataContract
export class GetPtzCameraGroupsRequest
  implements IReturn<GetEntityGroupsResponse>, ICustomerItemRequest
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public entityGroups: string[];

  // @DataMember
  public itemsIds: string[];

  // @DataMember
  public onlyOwnedItems: boolean;

  // @DataMember
  public page: number;

  // @DataMember
  public items: number;

  // @DataMember
  public sort: string;

  // @DataMember
  public filter: string;

  // @DataMember
  public propertiesTest: { [index: string]: number };

  public constructor(init?: Partial<GetPtzCameraGroupsRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new GetEntityGroupsResponse();
  }
  public getTypeName() {
    return 'GetPtzCameraGroupsRequest';
  }
}

// @Route("/customer/{customerId}/ptzCameras/groups/{id}", "GET")
// @DataContract
export class GetPtzCameraGroupRequest
  implements IReturn<EntityGroup>, ICustomerItemRequest, IBaseEntityItemRequest
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember(IsRequired=true)
  public id: string;

  public constructor(init?: Partial<GetPtzCameraGroupRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new EntityGroup();
  }
  public getTypeName() {
    return 'GetPtzCameraGroupRequest';
  }
}

// @Route("/customer/{customerId}/ptzCameras/groups", "POST")
// @DataContract
export class CreatePtzCameraGroupRequest
  implements IReturn<EntityGroup>, ICustomerItemRequest, IDescription
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public updateEntityGroups: boolean;

  // @DataMember
  public entityGroups: string[];

  // @DataMember(IsRequired=true)
  public entity: EntityGroup;

  // @DataMember
  public description: string;

  public constructor(init?: Partial<CreatePtzCameraGroupRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new EntityGroup();
  }
  public getTypeName() {
    return 'CreatePtzCameraGroupRequest';
  }
}

// @Route("/customer/{customerId}/ptzCameras/groups/{id}", "PUT")
// @DataContract
export class UpdatePtzCameraGroupRequest
  implements IReturn<EntityGroup>, ICustomerItemRequest, IBaseEntityItemRequest, IDescription
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public updateEntityGroups: boolean;

  // @DataMember
  public entityGroups: string[];

  // @DataMember(IsRequired=true)
  public id: string;

  // @DataMember(IsRequired=true)
  public entity: EntityGroup;

  // @DataMember
  public description: string;

  public constructor(init?: Partial<UpdatePtzCameraGroupRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new EntityGroup();
  }
  public getTypeName() {
    return 'UpdatePtzCameraGroupRequest';
  }
}

// @Route("/customer/{customerId}/ptzCameras/groups", "DELETE")
// @DataContract
export class DeletePtzCameraGroupsRequest
  implements IReturn<number>, ICustomerItemRequest, IDescription
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public description: string;

  // @DataMember(IsRequired=true)
  public ids: string[];

  public constructor(init?: Partial<DeletePtzCameraGroupsRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return 0;
  }
  public getTypeName() {
    return 'DeletePtzCameraGroupsRequest';
  }
}

// @Route("/customer/{customerId}/ptzCameras/groups/{id}", "GET")
// @DataContract
export class GetPtzCameraGroupSharesRequest
  implements IReturn<GetEntityGroupSharesResponse>, IBaseEntityItemRequest, ICustomerItemRequest
{
  // @DataMember
  public id: string;

  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public entityGroups: string[];

  // @DataMember
  public itemsIds: string[];

  // @DataMember
  public onlyOwnedItems: boolean;

  // @DataMember
  public page: number;

  // @DataMember
  public items: number;

  // @DataMember
  public sort: string;

  // @DataMember
  public filter: string;

  // @DataMember
  public propertiesTest: { [index: string]: number };

  public constructor(init?: Partial<GetPtzCameraGroupSharesRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new GetEntityGroupSharesResponse();
  }
  public getTypeName() {
    return 'GetPtzCameraGroupSharesRequest';
  }
}

// @Route("/customer/{customerId}/ptzCameras/groups/{id}", "PUT")
// @DataContract
export class UpdatePtzCameraGroupShareRequest
  implements IReturn<EntityGroupPermissions>, ICustomerItemRequest, IDescription
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember(IsRequired=true)
  public id: string;

  // @DataMember(IsRequired=true)
  public entity: EntityGroupPermissions;

  // @DataMember
  public description: string;

  public constructor(init?: Partial<UpdatePtzCameraGroupShareRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new EntityGroupPermissions();
  }
  public getTypeName() {
    return 'UpdatePtzCameraGroupShareRequest';
  }
}

// @Route("/customer/{customerId}/ptzCamerasLocation", "GET")
// @DataContract
export class GetPtzCamerasLocationRequest
  implements IReturn<GetPtzCamerasLocationResponse>, ICustomerItemRequest
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public entityGroups: string[];

  // @DataMember
  public itemsIds: string[];

  // @DataMember
  public onlyOwnedItems: boolean;

  // @DataMember
  public page: number;

  // @DataMember
  public items: number;

  // @DataMember
  public sort: string;

  // @DataMember
  public filter: string;

  // @DataMember
  public propertiesTest: { [index: string]: number };

  public constructor(init?: Partial<GetPtzCamerasLocationRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new GetPtzCamerasLocationResponse();
  }
  public getTypeName() {
    return 'GetPtzCamerasLocationRequest';
  }
}

// @Route("/customer/{customerId}/ptzCameras", "GET")
// @DataContract
export class GetPtzCamerasRequest implements IReturn<GetPtzCamerasResponse>, ICustomerItemRequest {
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public entityGroups: string[];

  // @DataMember
  public itemsIds: string[];

  // @DataMember
  public onlyOwnedItems: boolean;

  // @DataMember
  public page: number;

  // @DataMember
  public items: number;

  // @DataMember
  public sort: string;

  // @DataMember
  public filter: string;

  // @DataMember
  public propertiesTest: { [index: string]: number };

  public constructor(init?: Partial<GetPtzCamerasRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new GetPtzCamerasResponse();
  }
  public getTypeName() {
    return 'GetPtzCamerasRequest';
  }
}

// @Route("/customer/{customerId}/ptzCamera/{id}", "GET")
// @DataContract
export class GetPtzCameraRequest
  implements IReturn<PtzCamera>, ICustomerItemRequest, IBaseEntityItemRequest
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember(IsRequired=true)
  public id: string;

  public constructor(init?: Partial<GetPtzCameraRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new PtzCamera();
  }
  public getTypeName() {
    return 'GetPtzCameraRequest';
  }
}

// @Route("/customer/{customerId}/ptzCameras", "POST")
// @DataContract
export class CreatePtzCameraRequest
  implements IReturn<PtzCamera>, ICustomerItemRequest, IDescription
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public updateEntityGroups: boolean;

  // @DataMember
  public entityGroups: string[];

  // @DataMember(IsRequired=true)
  public entity: PtzCamera;

  // @DataMember
  public description: string;

  public constructor(init?: Partial<CreatePtzCameraRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new PtzCamera();
  }
  public getTypeName() {
    return 'CreatePtzCameraRequest';
  }
}

// @Route("/customer/{customerId}/ptzCamera/{id}", "PUT")
// @DataContract
export class UpdatePtzCameraRequest
  implements IReturn<PtzCamera>, ICustomerItemRequest, IBaseEntityItemRequest, IDescription
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public updateEntityGroups: boolean;

  // @DataMember
  public entityGroups: string[];

  // @DataMember(IsRequired=true)
  public id: string;

  // @DataMember(IsRequired=true)
  public entity: PtzCamera;

  // @DataMember
  public description: string;

  public constructor(init?: Partial<UpdatePtzCameraRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new PtzCamera();
  }
  public getTypeName() {
    return 'UpdatePtzCameraRequest';
  }
}

// @Route("/customer/{customerId}/ptzCameras", "DELETE")
// @DataContract
export class DeletePtzCamerasRequest
  implements IReturn<number>, ICustomerItemRequest, IDescription
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public description: string;

  // @DataMember(IsRequired=true)
  public ids: string[];

  public constructor(init?: Partial<DeletePtzCamerasRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return 0;
  }
  public getTypeName() {
    return 'DeletePtzCamerasRequest';
  }
}

// @Route("/customer/{customerId}/ptzCamera/{id}/command", "POST")
// @DataContract
export class PtzCameraCommandRequest implements IReturn<boolean> {
  // @DataMember(IsRequired=true)
  public id: string;

  // @DataMember
  public command: CameraCommand;

  public constructor(init?: Partial<PtzCameraCommandRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return false;
  }
  public getTypeName() {
    return 'PtzCameraCommandRequest';
  }
}

// @Route("/customer/{customerId}/ptzCamera/{id}/currentUserPermissions", "GET")
// @DataContract
export class GetPtzCameraCurrentUserPermissionsRequest
  implements IReturn<string[]>, ICustomerItemRequest, IBaseEntityItemRequest
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember(IsRequired=true)
  public id: string;

  public constructor(init?: Partial<GetPtzCameraCurrentUserPermissionsRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new Array<string>();
  }
  public getTypeName() {
    return 'GetPtzCameraCurrentUserPermissionsRequest';
  }
}

// @Route("/customer/{customerId}/staticCameraSources", "GET")
// @DataContract
export class GetStaticCameraSourcesRequest
  implements IReturn<GetStaticCameraSourcesResponse>, ICustomerItemRequest
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public entityGroups: string[];

  // @DataMember
  public itemsIds: string[];

  // @DataMember
  public onlyOwnedItems: boolean;

  // @DataMember
  public page: number;

  // @DataMember
  public items: number;

  // @DataMember
  public sort: string;

  // @DataMember
  public filter: string;

  // @DataMember
  public propertiesTest: { [index: string]: number };

  public constructor(init?: Partial<GetStaticCameraSourcesRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new GetStaticCameraSourcesResponse();
  }
  public getTypeName() {
    return 'GetStaticCameraSourcesRequest';
  }
}

// @Route("/customer/{customerId}/staticCameraSource/{id}", "GET")
// @DataContract
export class GetStaticCameraSourceRequest
  implements IReturn<StaticCameraSource>, ICustomerItemRequest, IBaseEntityItemRequest
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember(IsRequired=true)
  public id: string;

  public constructor(init?: Partial<GetStaticCameraSourceRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new StaticCameraSource();
  }
  public getTypeName() {
    return 'GetStaticCameraSourceRequest';
  }
}

// @Route("/customer/{customerId}/staticCameraSources", "POST")
// @DataContract
export class CreateStaticCameraSourceRequest
  implements IReturn<StaticCameraSource>, ICustomerItemRequest, IDescription
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public updateEntityGroups: boolean;

  // @DataMember
  public entityGroups: string[];

  // @DataMember(IsRequired=true)
  public entity: StaticCameraSource;

  // @DataMember
  public description: string;

  public constructor(init?: Partial<CreateStaticCameraSourceRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new StaticCameraSource();
  }
  public getTypeName() {
    return 'CreateStaticCameraSourceRequest';
  }
}

// @Route("/customer/{customerId}/staticCameraSource/{id}", "PUT")
// @DataContract
export class UpdateStaticCameraSourceRequest
  implements
    IReturn<StaticCameraSource>,
    ICustomerItemRequest,
    IBaseEntityItemRequest,
    IDescription
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public updateEntityGroups: boolean;

  // @DataMember
  public entityGroups: string[];

  // @DataMember(IsRequired=true)
  public id: string;

  // @DataMember(IsRequired=true)
  public entity: StaticCameraSource;

  // @DataMember
  public description: string;

  public constructor(init?: Partial<UpdateStaticCameraSourceRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new StaticCameraSource();
  }
  public getTypeName() {
    return 'UpdateStaticCameraSourceRequest';
  }
}

// @Route("/customer/{customerId}/staticCameraSources", "DELETE")
// @DataContract
export class DeleteStaticCameraSourcesRequest
  implements IReturn<number>, ICustomerItemRequest, IDescription
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public description: string;

  // @DataMember(IsRequired=true)
  public ids: string[];

  public constructor(init?: Partial<DeleteStaticCameraSourcesRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return 0;
  }
  public getTypeName() {
    return 'DeleteStaticCameraSourcesRequest';
  }
}

// @Route("/customer/{customerId}/staticCameraImages", "GET")
// @DataContract
export class GetStaticCameraImagesRequest
  implements IReturn<GetStaticCameraImagesResponse>, ICustomerItemRequest
{
  // @DataMember
  public staticCameraId?: string;

  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public entityGroups: string[];

  // @DataMember
  public itemsIds: string[];

  // @DataMember
  public onlyOwnedItems: boolean;

  // @DataMember
  public page: number;

  // @DataMember
  public items: number;

  // @DataMember
  public sort: string;

  // @DataMember
  public filter: string;

  // @DataMember
  public propertiesTest: { [index: string]: number };

  public constructor(init?: Partial<GetStaticCameraImagesRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new GetStaticCameraImagesResponse();
  }
  public getTypeName() {
    return 'GetStaticCameraImagesRequest';
  }
}

// @Route("/customer/{customerId}/staticCameraImages/count", "GET")
// @DataContract
export class GetStaticCameraImagesCountRequest implements IReturn<number> {
  // @DataMember
  public customerId: string;

  // @DataMember
  public staticCameraId: string;

  public constructor(init?: Partial<GetStaticCameraImagesCountRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return 0;
  }
  public getTypeName() {
    return 'GetStaticCameraImagesCountRequest';
  }
}

// @Route("/customer/{customerId}/staticCameraImages/lastImage", "GET")
// @DataContract
export class GetStaticCameraLastImageRequest implements IReturn<StaticCameraImage> {
  // @DataMember
  public customerId: string;

  // @DataMember
  public staticCameraId: string;

  public constructor(init?: Partial<GetStaticCameraLastImageRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new StaticCameraImage();
  }
  public getTypeName() {
    return 'GetStaticCameraLastImageRequest';
  }
}

// @Route("/customer/{customerId}/staticCameraImage/{id}", "GET")
// @DataContract
export class GetStaticCameraImageRequest
  implements IReturn<StaticCameraImage>, ICustomerItemRequest, IBaseEntityItemRequest
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember(IsRequired=true)
  public id: string;

  public constructor(init?: Partial<GetStaticCameraImageRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new StaticCameraImage();
  }
  public getTypeName() {
    return 'GetStaticCameraImageRequest';
  }
}

// @Route("/customer/{customerId}/staticCameraImages", "POST")
// @DataContract
export class CreateStaticCameraImageRequest
  implements IReturn<StaticCameraImage>, ICustomerItemRequest, IDescription
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public updateEntityGroups: boolean;

  // @DataMember
  public entityGroups: string[];

  // @DataMember(IsRequired=true)
  public entity: StaticCameraImage;

  // @DataMember
  public description: string;

  public constructor(init?: Partial<CreateStaticCameraImageRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new StaticCameraImage();
  }
  public getTypeName() {
    return 'CreateStaticCameraImageRequest';
  }
}

// @Route("/customer/{customerId}/staticCameraImages", "DELETE")
// @DataContract
export class DeleteStaticCameraImagesRequest
  implements IReturn<number>, ICustomerItemRequest, IDescription
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public description: string;

  // @DataMember(IsRequired=true)
  public ids: string[];

  public constructor(init?: Partial<DeleteStaticCameraImagesRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return 0;
  }
  public getTypeName() {
    return 'DeleteStaticCameraImagesRequest';
  }
}

// @Route("/customer/{customerId}/staticCameras/groups", "GET")
// @DataContract
export class GetStaticCameraGroupsRequest
  implements IReturn<GetEntityGroupsResponse>, ICustomerItemRequest
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public entityGroups: string[];

  // @DataMember
  public itemsIds: string[];

  // @DataMember
  public onlyOwnedItems: boolean;

  // @DataMember
  public page: number;

  // @DataMember
  public items: number;

  // @DataMember
  public sort: string;

  // @DataMember
  public filter: string;

  // @DataMember
  public propertiesTest: { [index: string]: number };

  public constructor(init?: Partial<GetStaticCameraGroupsRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new GetEntityGroupsResponse();
  }
  public getTypeName() {
    return 'GetStaticCameraGroupsRequest';
  }
}

// @Route("/customer/{customerId}/staticCameras/groups/{id}", "GET")
// @DataContract
export class GetStaticCameraGroupRequest
  implements IReturn<EntityGroup>, ICustomerItemRequest, IBaseEntityItemRequest
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember(IsRequired=true)
  public id: string;

  public constructor(init?: Partial<GetStaticCameraGroupRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new EntityGroup();
  }
  public getTypeName() {
    return 'GetStaticCameraGroupRequest';
  }
}

// @Route("/customer/{customerId}/staticCameras/groups", "POST")
// @DataContract
export class CreateStaticCameraGroupRequest
  implements IReturn<EntityGroup>, ICustomerItemRequest, IDescription
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public updateEntityGroups: boolean;

  // @DataMember
  public entityGroups: string[];

  // @DataMember(IsRequired=true)
  public entity: EntityGroup;

  // @DataMember
  public description: string;

  public constructor(init?: Partial<CreateStaticCameraGroupRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new EntityGroup();
  }
  public getTypeName() {
    return 'CreateStaticCameraGroupRequest';
  }
}

// @Route("/customer/{customerId}/staticCameras/groups/{id}", "PUT")
// @DataContract
export class UpdateStaticCameraGroupRequest
  implements IReturn<EntityGroup>, ICustomerItemRequest, IBaseEntityItemRequest, IDescription
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public updateEntityGroups: boolean;

  // @DataMember
  public entityGroups: string[];

  // @DataMember(IsRequired=true)
  public id: string;

  // @DataMember(IsRequired=true)
  public entity: EntityGroup;

  // @DataMember
  public description: string;

  public constructor(init?: Partial<UpdateStaticCameraGroupRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new EntityGroup();
  }
  public getTypeName() {
    return 'UpdateStaticCameraGroupRequest';
  }
}

// @Route("/customer/{customerId}/staticCameras/groups", "DELETE")
// @DataContract
export class DeleteStaticCameraGroupsRequest
  implements IReturn<number>, ICustomerItemRequest, IDescription
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public description: string;

  // @DataMember(IsRequired=true)
  public ids: string[];

  public constructor(init?: Partial<DeleteStaticCameraGroupsRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return 0;
  }
  public getTypeName() {
    return 'DeleteStaticCameraGroupsRequest';
  }
}

// @Route("/customer/{customerId}/staticCameras/groups/{id}", "GET")
// @DataContract
export class GetStaticCameraGroupSharesRequest
  implements IReturn<GetEntityGroupSharesResponse>, IBaseEntityItemRequest, ICustomerItemRequest
{
  // @DataMember
  public id: string;

  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public entityGroups: string[];

  // @DataMember
  public itemsIds: string[];

  // @DataMember
  public onlyOwnedItems: boolean;

  // @DataMember
  public page: number;

  // @DataMember
  public items: number;

  // @DataMember
  public sort: string;

  // @DataMember
  public filter: string;

  // @DataMember
  public propertiesTest: { [index: string]: number };

  public constructor(init?: Partial<GetStaticCameraGroupSharesRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new GetEntityGroupSharesResponse();
  }
  public getTypeName() {
    return 'GetStaticCameraGroupSharesRequest';
  }
}

// @Route("/customer/{customerId}/staticCameras/groups/{id}", "PUT")
// @DataContract
export class UpdateStaticCameraGroupShareRequest
  implements IReturn<EntityGroupPermissions>, ICustomerItemRequest, IDescription
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember(IsRequired=true)
  public id: string;

  // @DataMember(IsRequired=true)
  public entity: EntityGroupPermissions;

  // @DataMember
  public description: string;

  public constructor(init?: Partial<UpdateStaticCameraGroupShareRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new EntityGroupPermissions();
  }
  public getTypeName() {
    return 'UpdateStaticCameraGroupShareRequest';
  }
}

// @Route("/customer/{customerId}/staticCamerasLocation", "GET")
// @DataContract
export class GetStaticCamerasLocationRequest
  implements IReturn<GetStaticCamerasLocationResponse>, ICustomerItemRequest
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public entityGroups: string[];

  // @DataMember
  public itemsIds: string[];

  // @DataMember
  public onlyOwnedItems: boolean;

  // @DataMember
  public page: number;

  // @DataMember
  public items: number;

  // @DataMember
  public sort: string;

  // @DataMember
  public filter: string;

  // @DataMember
  public propertiesTest: { [index: string]: number };

  public constructor(init?: Partial<GetStaticCamerasLocationRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new GetStaticCamerasLocationResponse();
  }
  public getTypeName() {
    return 'GetStaticCamerasLocationRequest';
  }
}

// @Route("/customer/{customerId}/staticCameras", "GET")
// @DataContract
export class GetStaticCamerasRequest
  implements IReturn<GetStaticCamerasResponse>, ICustomerItemRequest
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public entityGroups: string[];

  // @DataMember
  public itemsIds: string[];

  // @DataMember
  public onlyOwnedItems: boolean;

  // @DataMember
  public page: number;

  // @DataMember
  public items: number;

  // @DataMember
  public sort: string;

  // @DataMember
  public filter: string;

  // @DataMember
  public propertiesTest: { [index: string]: number };

  public constructor(init?: Partial<GetStaticCamerasRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new GetStaticCamerasResponse();
  }
  public getTypeName() {
    return 'GetStaticCamerasRequest';
  }
}

// @Route("/customer/{customerId}/staticCamera/{id}", "GET")
// @DataContract
export class GetStaticCameraRequest
  implements IReturn<StaticCamera>, ICustomerItemRequest, IBaseEntityItemRequest
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember(IsRequired=true)
  public id: string;

  public constructor(init?: Partial<GetStaticCameraRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new StaticCamera();
  }
  public getTypeName() {
    return 'GetStaticCameraRequest';
  }
}

// @Route("/customer/{customerId}/staticCameras", "POST")
// @DataContract
export class CreateStaticCameraRequest
  implements IReturn<StaticCamera>, ICustomerItemRequest, IDescription
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public updateEntityGroups: boolean;

  // @DataMember
  public entityGroups: string[];

  // @DataMember(IsRequired=true)
  public entity: StaticCamera;

  // @DataMember
  public description: string;

  public constructor(init?: Partial<CreateStaticCameraRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new StaticCamera();
  }
  public getTypeName() {
    return 'CreateStaticCameraRequest';
  }
}

// @Route("/customer/{customerId}/staticCamera/{id}", "PUT")
// @DataContract
export class UpdateStaticCameraRequest
  implements IReturn<StaticCamera>, ICustomerItemRequest, IBaseEntityItemRequest, IDescription
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public updateEntityGroups: boolean;

  // @DataMember
  public entityGroups: string[];

  // @DataMember(IsRequired=true)
  public id: string;

  // @DataMember(IsRequired=true)
  public entity: StaticCamera;

  // @DataMember
  public description: string;

  public constructor(init?: Partial<UpdateStaticCameraRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new StaticCamera();
  }
  public getTypeName() {
    return 'UpdateStaticCameraRequest';
  }
}

// @Route("/customer/{customerId}/staticCameras", "DELETE")
// @DataContract
export class DeleteStaticCamerasRequest
  implements IReturn<number>, ICustomerItemRequest, IDescription
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public description: string;

  // @DataMember(IsRequired=true)
  public ids: string[];

  public constructor(init?: Partial<DeleteStaticCamerasRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return 0;
  }
  public getTypeName() {
    return 'DeleteStaticCamerasRequest';
  }
}

// @Route("/customer/{customerId}/ptzCamera/{id}/currentUserPermissions", "GET")
// @DataContract
export class GetStaticCameraCurrentUserPermissionsRequest
  implements IReturn<string[]>, ICustomerItemRequest, IBaseEntityItemRequest
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember(IsRequired=true)
  public id: string;

  public constructor(init?: Partial<GetStaticCameraCurrentUserPermissionsRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new Array<string>();
  }
  public getTypeName() {
    return 'GetStaticCameraCurrentUserPermissionsRequest';
  }
}

// @Route("/customer/{customerId}/trafficAccidents", "GET")
// @DataContract
export class GetTrafficAccidentsRequest
  implements IReturn<GetTrafficAccidentsResponse>, ICustomerItemRequest
{
  // @DataMember
  public from?: string;

  // @DataMember
  public to?: string;

  // @DataMember
  public hasKilled?: boolean;

  // @DataMember
  public hasInjured?: boolean;

  // @DataMember
  public accidentTypes: AccidentType[];

  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public entityGroups: string[];

  // @DataMember
  public itemsIds: string[];

  // @DataMember
  public onlyOwnedItems: boolean;

  // @DataMember
  public page: number;

  // @DataMember
  public items: number;

  // @DataMember
  public sort: string;

  // @DataMember
  public filter: string;

  // @DataMember
  public propertiesTest: { [index: string]: number };

  public constructor(init?: Partial<GetTrafficAccidentsRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new GetTrafficAccidentsResponse();
  }
  public getTypeName() {
    return 'GetTrafficAccidentsRequest';
  }
}

// @Route("/customer/{customerId}/trafficAccidents/export", "GET")
// @DataContract
export class GetExportTrafficAccidentsRequest implements IReturn<Object> {
  // @DataMember
  public customerId: string;

  // @DataMember
  public filter: string;

  // @DataMember
  public from?: string;

  // @DataMember
  public to?: string;

  // @DataMember
  public hasKilled?: boolean;

  // @DataMember
  public hasInjured?: boolean;

  // @DataMember
  public accidentTypes: AccidentType[];

  // @DataMember
  public format: ReportFormat;

  // @DataMember
  public timeZone: string;

  public constructor(init?: Partial<GetExportTrafficAccidentsRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new Object();
  }
  public getTypeName() {
    return 'GetExportTrafficAccidentsRequest';
  }
}

// @Route("/customer/{customerId}/accidentsLocations", "GET")
// @DataContract
export class GetTrafficAccidentsLocationsRequest implements IReturn<TrafficAccidentLocation[]> {
  // @DataMember
  public customerId: string;

  // @DataMember
  public from?: string;

  // @DataMember
  public to?: string;

  // @DataMember
  public hasKilled?: boolean;

  // @DataMember
  public hasInjured?: boolean;

  // @DataMember
  public accidentTypes: AccidentType[];

  public constructor(init?: Partial<GetTrafficAccidentsLocationsRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new Array<TrafficAccidentLocation>();
  }
  public getTypeName() {
    return 'GetTrafficAccidentsLocationsRequest';
  }
}

// @Route("/customer/{customerId}/trafficAccident/{id}", "GET")
// @DataContract
export class GetTrafficAccidentRequest
  implements IReturn<TrafficAccident>, ICustomerItemRequest, IBaseEntityItemRequest
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember(IsRequired=true)
  public id: string;

  public constructor(init?: Partial<GetTrafficAccidentRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new TrafficAccident();
  }
  public getTypeName() {
    return 'GetTrafficAccidentRequest';
  }
}

// @Route("/customer/{customerId}/trafficAccidents", "POST")
// @DataContract
export class CreateTrafficAccidentRequest
  implements IReturn<TrafficAccident>, ICustomerItemRequest, IDescription
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public updateEntityGroups: boolean;

  // @DataMember
  public entityGroups: string[];

  // @DataMember(IsRequired=true)
  public entity: TrafficAccident;

  // @DataMember
  public description: string;

  public constructor(init?: Partial<CreateTrafficAccidentRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new TrafficAccident();
  }
  public getTypeName() {
    return 'CreateTrafficAccidentRequest';
  }
}

// @Route("/customer/{customerId}/trafficAccident/{id}", "PUT")
// @DataContract
export class UpdateTrafficAccidentRequest
  implements IReturn<TrafficAccident>, ICustomerItemRequest, IBaseEntityItemRequest, IDescription
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public updateEntityGroups: boolean;

  // @DataMember
  public entityGroups: string[];

  // @DataMember(IsRequired=true)
  public id: string;

  // @DataMember(IsRequired=true)
  public entity: TrafficAccident;

  // @DataMember
  public description: string;

  public constructor(init?: Partial<UpdateTrafficAccidentRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new TrafficAccident();
  }
  public getTypeName() {
    return 'UpdateTrafficAccidentRequest';
  }
}

// @Route("/customer/{customerId}/trafficAccidents", "DELETE")
// @DataContract
export class DeleteTrafficAccidentsRequest
  implements IReturn<number>, ICustomerItemRequest, IDescription
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public description: string;

  // @DataMember(IsRequired=true)
  public ids: string[];

  public constructor(init?: Partial<DeleteTrafficAccidentsRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return 0;
  }
  public getTypeName() {
    return 'DeleteTrafficAccidentsRequest';
  }
}

/**
 * Sign In
 */
// @Route("/auth", "OPTIONS,GET,POST,DELETE")
// @Route("/auth/{provider}", "OPTIONS,GET,POST,DELETE")
// @Api(Description="Sign In")
// @DataContract
export class Authenticate implements IReturn<AuthenticateResponse>, IPost {
  /**
   * AuthProvider, e.g. credentials
   */
  // @DataMember(Order=1)
  public provider: string;

  // @DataMember(Order=2)
  public state: string;

  // @DataMember(Order=3)
  public oauth_token: string;

  // @DataMember(Order=4)
  public oauth_verifier: string;

  // @DataMember(Order=5)
  public userName: string;

  // @DataMember(Order=6)
  public password: string;

  // @DataMember(Order=7)
  public rememberMe?: boolean;

  // @DataMember(Order=9)
  public errorView: string;

  // @DataMember(Order=10)
  public nonce: string;

  // @DataMember(Order=11)
  public uri: string;

  // @DataMember(Order=12)
  public response: string;

  // @DataMember(Order=13)
  public qop: string;

  // @DataMember(Order=14)
  public nc: string;

  // @DataMember(Order=15)
  public cnonce: string;

  // @DataMember(Order=17)
  public accessToken: string;

  // @DataMember(Order=18)
  public accessTokenSecret: string;

  // @DataMember(Order=19)
  public scope: string;

  // @DataMember(Order=20)
  public meta: { [index: string]: string };

  public constructor(init?: Partial<Authenticate>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new AuthenticateResponse();
  }
  public getTypeName() {
    return 'Authenticate';
  }
}
