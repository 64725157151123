import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { TrafficObjectSwitchMode } from './tss.dtos';

export const TRAFFIC_OBJECT_SWITCH_TYPES = [
  { type: TrafficObjectSwitchMode.Parallel, name: _('TRAFFIC_OBJECT_SWITCH_MODES.PARALLEL') },
  { type: TrafficObjectSwitchMode.Sequential, name: _('TRAFFIC_OBJECT_SWITCH_MODES.SEQUENTIAL') },
  {
    type: TrafficObjectSwitchMode.SequentialDelay,
    name: _('TRAFFIC_OBJECT_SWITCH_MODES.SEQUENTIAL_DELAY'),
  },
];
