import { Directive, HostListener, forwardRef } from '@angular/core';
import { DefaultValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

const TRIM_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => TrimValueDirective),
  multi: true,
};

@Directive({
  selector: `
    input[trimValue],
    textarea[trimValue]
  `,
  providers: [TRIM_VALUE_ACCESSOR],
})
export class TrimValueDirective extends DefaultValueAccessor {
  @HostListener('input', ['$event.target.value'])
  ngOnChange = (val: string) => {
    this.onChange(val.trim());
  };

  @HostListener('blur', ['$event.target.value'])
  ngOnBlur = (val: string) => {
    this.writeValue(val.trim());
    this.onTouched();
  };

  writeValue(value: any) {
    if (typeof value === 'string') {
      value = value.trim();
    }
    super.writeValue(value);
  }
}
