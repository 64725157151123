import { Injectable } from "@angular/core";

import { Observable, of } from "rxjs";
import { map } from "rxjs/operators";

import {
  IDataSourceService,
  setPageParams,
  getPageReturn,
  IPageParams,
  IPageReturn,
} from "projects/msu-its-web-common/src/data/data-source";
import { PtmApiService } from "../ptm-api.service";
import {
  Waybill,
  GetWaybillsRequest,
  GetWaybillRequest,
  CreateWaybillRequest,
  UpdateWaybillRequest,
  DeleteWaybillsRequest,
} from "../../dtos/ptm.dtos";

@Injectable()
export class VehicleWaybillService
  implements IDataSourceService<Waybill, Waybill> {
  constructor(private _api: PtmApiService) {}

  getPagedList(
    params: IPageParams,
    requestParams: {
      customerId: string;
      onlyActive: boolean;
    }
  ): Observable<IPageReturn<Waybill>> {
    if (!requestParams.customerId) {
      return of(null);
    }
    const request = new GetWaybillsRequest();
    request.customerId = requestParams.customerId;
    request.onlyActive = requestParams.onlyActive;

    setPageParams(request, params);
    return this._api.get(request).pipe(
      map((m) => {
        return getPageReturn(m);
      })
    );
  }
  getList(
    customerId: string,
    filter?: string,
    groupIds?: string[],
    items?: number
  ): Observable<Waybill[]> {
    const request = new GetWaybillsRequest();
    request.customerId = customerId;
    request.entityGroups = groupIds || [];

    request.page = 0;
    request.items = items || 0;
    request.sort = "number";
    request.filter = filter || "";

    return this._api.get(request).pipe(
      map((m) => {
        return m.items;
      })
    );
  }

  get(id: string, customerId: string) {
    const request = new GetWaybillRequest();
    request.id = id;
    request.customerId = customerId;
    return this._api.get(request);
  }
  add(
    model: Waybill,
    params: {
      customerId: string;
      groups: string[];
      updateGroups: boolean;
    }
  ) {
    const request = new CreateWaybillRequest();
    request.entity = model;
    request.customerId = params.customerId;
    request.entityGroups = params.groups;
    request.updateEntityGroups = params.updateGroups;
    return this._api.post(request);
  }
  update(
    model: Waybill,
    params: { customerId: string; groups: string[]; updateGroups: boolean }
  ) {
    const request = new UpdateWaybillRequest();
    request.id = model.id;
    request.entity = model;
    request.customerId = params.customerId;
    request.entityGroups = params.groups;
    request.updateEntityGroups = params.updateGroups;
    return this._api.put(request);
  }
  delete(ids: string[],  params: { customerId: string;}) {
    const request = new DeleteWaybillsRequest();
    request.ids = ids;
    request.customerId = params.customerId;
    return this._api.delete(request);
  }
}
