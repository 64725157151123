import { marker as _ } from "@biesbjerg/ngx-translate-extract-marker";

import {
  Component,
  ChangeDetectionStrategy,
  OnInit,
  Input,
  ChangeDetectorRef,
  OnDestroy,
} from "@angular/core";
import { DateAdapter, ErrorStateMatcher } from "@angular/material/core";
import { MatDialogRef } from "@angular/material/dialog";
import { MatTabChangeEvent } from "@angular/material/tabs";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  FormGroupDirective,
  NgForm,
} from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";

import { LANGS_LOCALES } from "projects/msu-its-web-common/src/utils/langs";
import { chartOptions } from "projects/msu-its-web-common/src/utils//e-charts";
import {
  BaseUnit,
  MeasureParameter,
  MeteoObject,
  MeteoParameterGraph,
  MeteoSensor,
  TableValue,
} from "../../dtos/meteo.dtos";
import {
  METEO_NAME,
  METEO_STATION_NAME,
  METEO_UNITS,
  TYPE_PRECIPITATION,
} from "../../dtos/enum";

import { MeteoObjectPopupService } from "../../services/meteo-object-popup.service";

import * as echarts from "echarts";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

@Component({
  selector: "meteo-object-history",
  templateUrl: "./meteo-object-history.component.html",
  styles: [
    `
      .backdrop {
        top: 45px;
      }
      .chartGraf {
        width: 100%;
        /*min-height: 380px;*/
        margin-top: -12px;
        height: 100%;
        z-index: 2;
      }

      :host ::ng-deep .mat-tab-body-wrapper {
        flex: 1;
      }
      :host ::ng-deep .mat-tab-list .mat-tab-label {
        min-width: 80px !important;
      }

      .table {
        border: none;
        border-spacing: 0;
        width: 100%;
        font-size: 14px;
      }
      td {
        padding: 8px;
        font-size: 14px;
        border-bottom: solid 1px rgba(125, 125, 125, 0.15);
        vertical-align: middle;
      }
      td:first-child {
        padding-left: 0px;
      }
      td:last-child {
        padding-right: 0px;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MeteoObjectHistoryComponent implements OnInit, OnDestroy {
  private _destroy = new Subject();

  @Input()
  modelId: string;

  @Input()
  customerId: string;

  @Input()
  modelInfo: MeteoObject;

  @Input()
  meteoSensor: MeteoSensor[];

  rangeErrorStateMatcher: ErrorStateMatcher = {
    isErrorState(
      control: FormControl,
      form: FormGroupDirective | NgForm
    ): boolean {
      return false;
    },
  };

  chart = true;
  external = false;

  loadingTab = false;
  loadGraph = false;
  loadLatestData = false;
  noData = false;

  itemTable: TableValue[];
  itemTableTranslation: { type: string; name: string }[];
  itemGraph: MeteoParameterGraph;

  selectedMeteoSensor: MeteoSensor;

  meteoUint = METEO_UNITS;
  meteoCategoryName = METEO_NAME;
  meteoStationName = METEO_STATION_NAME;

  chartParams;
  chartOptions = chartOptions;

  formGroup: FormGroup = this._formBuilder.group({
    meteoParams: [null],
    dateFrom: [null],
    dateTo: [null],
  });
  get meteoParams() {
    return this.formGroup.get("meteoParams");
  }
  get dateFrom() {
    return this.formGroup.get("dateFrom");
  }
  get dateTo() {
    return this.formGroup.get("dateTo");
  }

  get locale() {
    return LANGS_LOCALES[this.translate.currentLang];
  }

  constructor(
    private _changeDetector: ChangeDetectorRef,
    private _meteoObjectPopupService: MeteoObjectPopupService,
    private _dialogRef: MatDialogRef<MeteoObjectHistoryComponent>,
    private _formBuilder: FormBuilder,
    private _dateAdapter: DateAdapter<any>,
    public translate: TranslateService
  ) {}

  ngOnDestroy() {
    this._destroy.next();
    this._destroy.complete();
  }

  ngOnInit() {
    this._dateAdapter.setLocale(this.locale);

    this.selectedMeteoSensor = this.meteoSensor[0];

    const from = new Date();
    this.dateFrom.setValue(
      new Date(from.getFullYear(), from.getMonth(), from.getDate())
    );
    const to = new Date();
    to.setDate(from.getDate() + 1);
    this.dateTo.setValue(
      new Date(to.getFullYear(), to.getMonth(), to.getDate())
    );

    this.dateTo.valueChanges
      .pipe(takeUntil(this._destroy))
      .subscribe((value) => {
        if (value) {
          this._loadData();
          this._changeDetector.markForCheck();
        }
      });

    this.meteoParams.valueChanges
      .pipe(takeUntil(this._destroy))
      .subscribe(() => {
        this._loadData();
        this._changeDetector.markForCheck();
      });
  }

  private _loadData() {
    if (this.meteoParams.value && this.selectedMeteoSensor) {
      if (
        this.selectedMeteoSensor.measureParameters.find(
          (item) => item.id === this.meteoParams.value
        ).unit === BaseUnit.Statement
      ) {
        this.chart = false;
        this._loadDataTable(
          this.selectedMeteoSensor,
          this.meteoParams.value,
          new Date(this.dateFrom.value),
          new Date(this.dateTo.value)
        );
      } else {
        this.chart = true;
        this._loadDataGraph(
          this.selectedMeteoSensor,
          this.meteoParams.value,
          new Date(this.dateFrom.value),
          new Date(this.dateTo.value)
        );
      }
    }
  }

  private _loadDataTable(
    _meteoSensor: MeteoSensor,
    _meteoParametrId: string,
    to: Date,
    from: Date
  ) {
    this.noData = false;
    this.loadGraph = true;
    this._meteoObjectPopupService
      .getMeteoObjectSensorDataTableRequest(
        this.modelId,
        _meteoSensor.type,
        _meteoParametrId,
        to,
        from
      )
      .pipe(takeUntil(this._destroy))
      .subscribe((table) => {
        this.itemTable = table.values;

        if (_meteoParametrId === "Briz_MeteoSensorPrecipitationType") {
          this.itemTableTranslation = TYPE_PRECIPITATION;
        }

        this.loadGraph = false;
        this.noData = this.itemTable.length === 0;

        this._changeDetector.markForCheck();
      });
  }
  private _loadDataGraph(
    _meteoSensor: MeteoSensor,
    _meteoParametrId: string,
    to: Date,
    from: Date
  ) {
    this.noData = false;
    this.loadGraph = true;
    this._meteoObjectPopupService
      .getMeteoObjectSensorDataGraphRequest(
        this.modelId,
        _meteoSensor.type,
        [_meteoParametrId],
        to,
        from
      )
      .pipe(takeUntil(this._destroy))
      .subscribe((graph) => {
        this.itemGraph = graph[0];

        this.loadGraph = false;
        this.noData = this.itemGraph.values.every(
          (m) => m.doubleValue === undefined
        );

        this._changeDetector.markForCheck();
        setTimeout(() => this._initialGraph(_meteoSensor, graph[0]));
      });
  }

  _loadSectionTab() {
    this.loadingTab = true;
    this._meteoObjectPopupService
      .getAvailableMeteoObjectSensorsRequest(this.modelId, 100, 100, "", "")
      .pipe(takeUntil(this._destroy))
      .subscribe((result) => {
        this.meteoSensor = result.items;
        this.loadingTab = false;
        this._changeDetector.markForCheck();
      });
  }

  private _initialGraph(sensor: MeteoSensor, data: MeteoParameterGraph) {
    if (this.chartParams) {
      this.chartParams.clear();
    }
    this.chartParams = echarts.init(
      document.getElementById(`grafHistory_${this.modelId}`) as HTMLDivElement
    );
    const values = [];
    data.values.forEach((item) => {
      values.push([+new Date(item.timeStamp), item.doubleValue]);
    });

    this.chartOptions.series = [
      {
        type: "line",
        name: `${this.translate.instant(
          this.getCategoryName(data)
        )}, ${this.translate.instant(this.getCategoryUnit(data.unit))}`,
        data: values,
        showSymbol: false,
        animationDelay: (idx) => idx * 10,
      },
    ];

    this.chartOptions.yAxis = [
      {
        axisLabel: {
          formatter: `{value} ${this.translate.instant(
            this.getCategoryUnit(data.unit)
          )}`,
        },
        min:
          data.min >= 0
            ? data.min > 3
              ? Math.round(data.min - data.min * 0.2)
              : Math.floor(data.min * 10) / 10
            : Math.round(data.min + data.min * 0.2),
        max: Math.round(data.max + data.max * 0.1),
      },
    ];
    this.chartParams.setOption(this.chartOptions, true, true);
    this._changeDetector.markForCheck();
  }

  changeEvent(event: MatTabChangeEvent) {
    this.selectedMeteoSensor = this.meteoSensor[event.tab.textLabel];
    this.meteoParams.setValue(this.selectedMeteoSensor.measureParameters[0].id);
    // console.log(this.selectedMeteoSensor);
    this._changeDetector.markForCheck();
  }

  getCategoryName(categoryItem: MeasureParameter) {
    const itemName = this.meteoCategoryName.find(
      (item) => item.type === categoryItem.name
    );
    return itemName.name;
  }

  getMeteoStationName(categoryItem: MeasureParameter) {
    const itemName = this.meteoStationName.find(
      (item) => item.name === categoryItem.name
    );
    return itemName ? _(itemName.nameTransl) : categoryItem.name;
  }

  getCategoryUnit(categoryItem: BaseUnit, text?: string) {
    const itemName = this.meteoUint.find((item) => item.type === categoryItem);
    return itemName?.name ? itemName.name : "";
  }

  cancel() {
    this._dialogRef.close(false);
  }

  onResize($event) {
    // this.chartParams.resize();
    this._changeDetector.markForCheck();
  }

  getTableItemName(type: string) {
    return (
      this.itemTableTranslation?.find((m) => m.type === type)?.name || type
    );
  }
}
