import { Inject, Injectable } from '@angular/core';
import {
  DeleteDetectorsRequest,
  GetDetectorDevicesRequest,
  DetectorDevice,
  GetDetectorDeviceRequest,
  DetectorDeviceInfo,
  CreateDetectorDeviceRequest,
  UpdateDetectorDeviceRequest,
  DetectorDeviceType,
  GetDetectorDeviceExistRequest,
  DeleteDetectorDevicesRequest,
  DetectorDeviceChangeCustomerRequest,
  DisconnectDetectorDeviceRequest,
  GetDetectorDeviceSessionInfoRequest,
  DetectorDeviceSession,
  GetDetectorDeviceGraphReportRequest,
  GetDetectorDeviceGraphRequest,
  DetectorGraph,
} from '../dtos/tfms.dtos';
import { TfmsApiService } from './tfms-api.service';
import {
  IDataSourceService,
  setPageParams,
  getPageReturn,
  IPageParams,
  IPageReturn,
} from 'projects/msu-its-web-common/src/data/data-source';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { TokenService } from 'projects/msu-its-web-common/src/services/token.service';
import { APP_ENVIRONMENT } from 'projects/msu-its-web-common/src/utils/shared-consts';
import { GetCustomerRoleUsersRequest } from 'projects/msu-its-web-common/src/dtos/hub.dtos';

@Injectable()
export class DetectorDeviceService
  implements IDataSourceService<DetectorDevice, DetectorDeviceInfo> {
  constructor(
    private _api: TfmsApiService,
    private _tokenService: TokenService,
    @Inject(APP_ENVIRONMENT) private _appEnv
  ) {}

  getPagedList(
    params: IPageParams,
    customerId: string
  ): Observable<IPageReturn<DetectorDeviceInfo>> {
    const request = new GetDetectorDevicesRequest();
    request.customerId = customerId;
    // request.deviceType = DetectorDeviceType.Optic;
    setPageParams(request, params);
    return this._api.get(request).pipe(
      map((m) => {
        return getPageReturn(m);
      })
    );
  }

  getList(
    customerId: string,
    filter?: string,
    items?: number,
    groupIds?: string[],
    onlyFree?: boolean
  ): Observable<DetectorDeviceInfo[]> {
    const request = new GetDetectorDevicesRequest();
    request.customerId = customerId;
    request.entityGroups = groupIds;
    request.page = 0;
    request.items = items ? items : 0;
    request.sort = 'number';
    request.filter = filter;
    request.onlyFree = onlyFree;

    return this._api.get(request).pipe(
      map((m) => {
        return m.items;
      })
    );
  }

  get(id: any, customerId: string): Observable<DetectorDevice> {
    const request = new GetDetectorDeviceRequest();
    request.customerId = customerId;
    request.id = id;
    return this._api.get(request);
  }

  add(
    model: DetectorDevice,
    params: {
      customerId: string;
    }
  ) {
    const request = new CreateDetectorDeviceRequest();
    request.customerId = params.customerId;
    request.entity = model;
    return this._api.post(request);
  }

  update(
    model: DetectorDevice,
    params: {
      customerId: string;
    }
  ) {
    const request = new UpdateDetectorDeviceRequest();
    request.customerId = params.customerId;
    request.id = model.id;
    request.entity = model;
    return this._api.put(request);
  }

  delete(ids: string[], customerId: string): Observable<number> {
    const request = new DeleteDetectorDevicesRequest();
    request.customerId = customerId;
    request.ids = ids;
    return this._api.delete(request);
  }

  exist(number: string, type: DetectorDeviceType) {
    const request = new GetDetectorDeviceExistRequest();
    request.number = number;
    request.type = type;
    return this._api.get(request);
  }

  changeCustomer(
    id: string,
    customerId: string,
    params: { newCustomerId: string; description: string }
  ) {
    const request = new DetectorDeviceChangeCustomerRequest();
    request.id = id;
    request.customerId = customerId;
    request.description = params.description;
    request.newCustomerId = params.newCustomerId;
    return this._api.post(request);
  }
  disconnectDetectorDevice(id: string, customerId: string) {
    const request = new DisconnectDetectorDeviceRequest();
    request.id = id;
    request.customerId = customerId;
    return this._api.post(request);
  }

  getDetectorDeviceSessionInfoRequest(id: string): Observable<DetectorDeviceSession> {
    const request = new GetDetectorDeviceSessionInfoRequest();
    request.id = id;
    return this._api.get(request);
  }
  getDetectorDeviceGraphReportRequest(
    id,
    start: Date,
    end: Date,
    lane?: number
  ): Observable<DetectorGraph> {
    const request = new GetDetectorDeviceGraphRequest();
    request.id = id;
    request.startTime = start.toISOString();
    request.endTime = end.toISOString();
    request.groupBySeconds = 900;
    request.smooth = 300;
    request.lane = lane;
    return this._api.get(request);
  }

  getImages(id, force) {
    // const url = `http://krsk.asudd24.ru:8088/device/${id}/image?forceUpdate=${force}&t=${Date.now()}`;
    const url = `${this._appEnv.modules.tfms.apiUrl}/json/reply/GetDetectorDeviceImageRequest?id=${id}&ForceUpdate=${force}`;

    const headers = new Headers();
    headers.append('Authorization', `Bearer ${this._tokenService.bearerToken}`);
    const request = new Request(url, {
      method: 'GET',
      // tslint:disable-next-line:object-literal-shorthand
      headers: headers,
      mode: 'cors',
      cache: 'no-store',
    });
    return request;
  }
}
