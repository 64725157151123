import { Injectable, OnDestroy } from "@angular/core";
import { EMPTY, of, Subject, Subscription } from "rxjs";
import { catchError, delay, mergeMap, repeat, tap } from "rxjs/operators";

import {
  IGisObjectGroupEventService,
  IGisObjectStatus,
} from "projects/msu-its-web-common/src/utils/gis-object-group";
import { FtvApiEventService } from "../ftv-api-event.service";
import { FtvApiService } from "../ftv-api.service";


@Injectable()
export class WeightControlPointGroupEventService
  implements IGisObjectGroupEventService, OnDestroy {
  _subscription: Subscription | null;

  permissions = ["weightControlPoint.read"];

  constructor(
    private _apiService: FtvApiService,
    private _eventService: FtvApiEventService
  ) {}

  ngOnDestroy() {
    this.unsubscribe();
  }

  subscribe(
    customerId: string,
    groupIds: string[],
    updateSubject: Subject<IGisObjectStatus[]>
  ) {
    // this.unsubscribe();
    // const request = new GetFixationPointStatusesRequest({
    //   customerId,
    //   entityGroups: groupIds,
    //   items: 0,
    //   page: 0,
    // });
    // this._subscription = of(null)
    //   .pipe(
    //     mergeMap(() =>
    //       this._apiService.getUnsafe(request).pipe(catchError(() => EMPTY))
    //     ),
    //     tap((result) => updateSubject.next(result.items)),
    //     delay(5000),
    //     repeat()
    //   )
    //   .subscribe();
  }

  unsubscribe() {
    // if (this._subscription) {
    //   this._subscription.unsubscribe();
    //   this._subscription = null;
    // }
  }
}
