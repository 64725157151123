import { Injectable, OnDestroy } from '@angular/core';

import { BehaviorSubject, EMPTY, Observable } from 'rxjs';
import { finalize, shareReplay } from 'rxjs/operators';

import {
  PotokSettings,
  PotokTrafficObjectStatus,
  TrafficObject,
} from 'projects/msu-its-web-tlc/src/dtos/tlc.dtos';

import { SchemaSet } from 'projects/msu-its-web-tlc/src/dtos/schema';

export interface IPotokTrafficObjectSet {
  model: TrafficObject;
  schemaSet: SchemaSet;
  settings: PotokSettings;
}

@Injectable()
export class PotokStateService implements OnDestroy {
  modelId: string;
  customerId: string;

  private readonly _loading = new BehaviorSubject<boolean>(false);
  readonly loading$ = this._loading.pipe(shareReplay(1));
  get loading(): boolean {
    return this._loading.getValue();
  }
  set loading(value) {
    this._loading.next(value);
  }

  private readonly _trafficObjectSet = new BehaviorSubject<IPotokTrafficObjectSet>(null);
  readonly trafficObjectSet$ = this._trafficObjectSet.pipe(shareReplay(1));
  get trafficObjectSet(): IPotokTrafficObjectSet {
    return this._trafficObjectSet.getValue();
  }
  set trafficObjectSet(value) {
    this._trafficObjectSet.next(value);
  }

  private readonly _status = new BehaviorSubject<PotokTrafficObjectStatus>(null);
  readonly status$ = this._status.pipe(shareReplay(1));
  get status(): PotokTrafficObjectStatus {
    return this._status.getValue();
  }
  set status(value) {
    this._status.next(value);
  }

  constructor() {}

  ngOnDestroy() {
    this._trafficObjectSet.complete();
    this._status.complete();
  }

  loadingQuery<T>(query: Observable<T>) {
    if (this.loading) return EMPTY;
    this.loading = true;
    return query.pipe(finalize(() => (this.loading = false)));
  }
}
