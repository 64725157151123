import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';

import {
  ApplicationRef,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ComponentFactoryResolver,
  ComponentRef,
  EventEmitter,
  Injector,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { merge, Observable, of, Subject } from 'rxjs';
import { map, switchMap, takeUntil } from 'rxjs/operators';

import {
  PotokSettings,
  Route,
  RouteObject,
  RouteSettings,
  TrafficControllerType,
  UsdkSettings,
} from '../../dtos/tlc.dtos';
import { EscortStepStatus, RouteEscortStateService } from './route-escort-state.service';
import { DialogService } from 'projects/msu-its-web-common/src/services/dialog.service';
import { UsdkSelectPhaseComponent } from '../traffic-objects/usdk/shared/usdk-select-phase.component';
import { PotokSelectPhaseComponent } from '../traffic-objects/potok/shared/potok-select-phase.component';

declare const L;

@Component({
  selector: 'route-escort',
  templateUrl: './route-escort.component.html',
  styles: [
    `
      :host {
        display: flex;
        flex-direction: column;
        height: calc(100% + 12px);
        min-width: 350px;
        max-width: 350px;
        margin: 0 -12px -12px;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [RouteEscortStateService],
})
export class RouteEscortComponent implements OnDestroy, OnInit {
  _destroy = new Subject();

  _currentRoute: Route;
  _currentRouteLine = L.polyline([], {
    weight: 8,
    color: '#55e',
    opacity: 0.8,
  });

  routeChanged = new EventEmitter<Route>();

  @Input()
  customerId: string;

  @Input()
  map: any;

  @Input()
  mapLayers: Map<string, any>;

  get loading() {
    return this._stateService.loading;
  }

  get selectedRoute() {
    return this._stateService.selectedRoute;
  }

  get escortStarted() {
    return this._stateService.escortStarted;
  }

  _createRoute = { name: _('ROUTES.CREATE_ROUTE'), action: 'create' };
  _editRoute = { name: _('ROUTES.EDIT_ROUTE'), action: 'edit' };
  _refresh = { name: _('COMMON.REFRESH'), action: 'refresh' };
  _selectedRouteActions = [this._refresh, this._editRoute, this._createRoute];
  _noRouteActions = [this._createRoute];

  get actions(): { name: string; action: string }[] {
    return this.selectedRoute ? this._selectedRouteActions : this._noRouteActions;
  }

  constructor(
    private _appRef: ApplicationRef,
    private _cfr: ComponentFactoryResolver,
    private _injector: Injector,
    private _stateService: RouteEscortStateService,
    private _dialogService: DialogService,
    private _changeDetector: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this._stateService.customerId = this.customerId;

    merge(this._stateService.escortStarted$, this._stateService.loading$)
      .pipe(takeUntil(this._destroy))
      .subscribe(() => this._changeDetector.markForCheck());

    this._stateService.selectedRoute$.pipe(takeUntil(this._destroy)).subscribe((route) => {
      this._resetRoute(this._currentRoute);
      this._currentRoute = route;
      this._updateRoute(this._currentRoute);
      this.routeChanged.next(this._currentRoute);
    });

    this._stateService.escortStepsChanged.pipe(takeUntil(this._destroy)).subscribe(() => {
      this.escortStarted && this._updateRouteSteps();
      this._changeDetector.markForCheck();
    });
  }

  ngOnDestroy() {
    this._destroySelectPopupComponentRef();
    this._resetRoute(this._currentRoute);
    this._destroy.next();
    this._destroy.complete();
    this._destroy = null;
  }

  fitBounds() {
    if (
      !this._stateService.selectedRouteBounded &&
      this.selectedRoute?.settings?.routeObjects?.length
    ) {
      const bounds = L.latLngBounds(
        this.selectedRoute.settings.routeObjects.map((m) => {
          const object = this._stateService.trafficObjects.get(m.trafficObjectId);
          return [object.lat, object.lon];
        })
      );
      bounds.isValid() &&
        this.map.fitBounds(bounds, {
          paddingTopLeft: [50, 50],
          paddingBottomRight: [400, 50],
        });
    }

    this._stateService.selectedRouteBounded = false;
  }

  refresh() {
    this._stateService.selectRoute(this.selectedRoute.id).subscribe(() => {
      this._updateRoute(this.selectedRoute);
      this._changeDetector.markForCheck();
    });
  }

  updateRoute() {}
  _updateRoute(route: Route) {
    if (!this.mapLayers) return;

    if (route?.routeGeoJson) {
      const geoJson = JSON.parse(route?.routeGeoJson);
      const latlngs = geoJson?.features[0]?.geometry?.coordinates.map((m) => L.latLng(m[1], m[0]));
      latlngs && this._currentRouteLine.setLatLngs(latlngs);
      this._currentRouteLine.addTo(this.map);
      // this.fitBounds();
    }

    route?.settings.routeObjects?.forEach((m, i) => {
      const layer = this.mapLayers.get(m.trafficObjectId);
      layer &&
        layer.setStatus({
          radius: 28,
          weight: 7,
          label: { text: i + 1, color: '#fff' },
        });
      layer?.bringToFront();

      // if (layer) {
      //   layer.options.eventHandlers = {
      //     click: this._clickLayer,
      //     contextmenu: this._contextmenuLayer,
      //   };
      // }
    });

    this.fitBounds();
  }
  _resetRoute(route: Route) {
    this._destroySelectPopupComponentRef();

    if (!this.mapLayers) return;

    route?.settings.routeObjects?.forEach((m, i) => {
      const layer = this.mapLayers.get(m.trafficObjectId);
      layer &&
        layer.setStatus({
          radius: 18,
          weight: 6,
          label: undefined,
        });

      delete layer?.options.eventHandlers;
    });

    if (this._currentRouteLine) {
      this.map.removeLayer(this._currentRouteLine);
    }
  }

  _updateRouteSteps() {
    this._stateService.escortSteps.forEach((step) => {
      const layer = this.mapLayers.get(step.routeObject.trafficObjectId);

      const now = Date.now();

      let text;
      if (
        (step.status == EscortStepStatus.InProgress || step.status == EscortStepStatus.Wait) &&
        step.completeTime > now
      ) {
        text = Math.round((step.completeTime - now) / 1000);
      } else if (step.status == EscortStepStatus.InProgress) {
        this.escortStarted &&
          this._stateService.stopStep(step.routeObject.trafficObjectId).subscribe();
        text = 0;
      } else if (step.status == EscortStepStatus.Wait) {
      } else {
        text =
          this.selectedRoute.settings.routeObjects.findIndex(
            (m) => m.trafficObjectId == step.routeObject.trafficObjectId
          ) + 1;
      }

      layer.setStatus({
        label: { text, color: '#fff' },
      });

      if (this._stateService.test) {
        step.status == EscortStepStatus.Wait ? layer.startSpin() : layer.stopSpin();
      }
    });
  }

  _clickLayer = (objectId: string) => {
    this.escortStarted && this._stateService.startStep(objectId).subscribe();
  };
  _contextmenuLayer = (objectId: string) => {
    this.escortStarted && this._stateService.stopStep(objectId).subscribe();
  };

  edit() {
    this._dialogService.windowDialog(
      `${this.customerId}/ext/route/${this.selectedRoute.id}/edit`,
      'routeEdit_' + this.selectedRoute.id,
      'menubar=0,toolbar=0,titlebar=0,location=0,locationbar=0,width=1400,height=800,resizable=0'
    );
  }
  create() {
    this._dialogService.windowDialog(
      `${this.customerId}/ext/route/create`,
      'routeEdit_create',
      'menubar=0,toolbar=0,titlebar=0,location=0,locationbar=0,width=1400,height=800,resizable=0'
    );
  }

  clickLayer(objectId, layer) {
    this._destroySelectPopupComponentRef();

    const routeObject = this.selectedRoute.settings.routeObjects.find(
      (m) => m.trafficObjectId == objectId
    );

    if (routeObject) {
      this._clickLayer(objectId);
    } else {
      // load object datails
      this._stateService.loadObjectDetails(objectId).subscribe(() => {
        // open popup
        this._selectPhase(objectId, layer).subscribe((m) => {
          this._destroySelectPopupComponentRef();
          this._addToRoute(m);
          this._clickLayer(objectId);
        });
      });
    }
  }
  contextmenuLayer(objectId, layer) {
    const routeObject = this.selectedRoute.settings.routeObjects.find(
      (m) => m.trafficObjectId == objectId
    );

    if (routeObject) {
      this._contextmenuLayer(objectId);
    } else {
      return false;
    }
  }

  _selectPopupComponentRef: ComponentRef<any>;
  _destroySelectPopupComponentRef() {
    if (this._selectPopupComponentRef) {
      this._selectPopupComponentRef['_popup'].remove();
      this._selectPopupComponentRef?.destroy();
      this._selectPopupComponentRef = null;
    }
  }

  _selectPhase(objectId, layer): Observable<RouteObject> {
    const object = this._stateService.trafficObjects.get(objectId);
    const settings = this._stateService.trafficObjectsSettings.get(objectId);
    const schemaSet = this._stateService.trafficObjectsSchemaSets.get(objectId);

    let result: Observable<RouteObject>;

    switch (object.controllerType) {
      case TrafficControllerType.Usdk:
        const usdkComponentRef = this._cfr
          .resolveComponentFactory(UsdkSelectPhaseComponent)
          .create(this._injector);
        this._appRef.attachView(usdkComponentRef.hostView);
        usdkComponentRef.instance.schemaSet = schemaSet;
        usdkComponentRef.instance.settings = settings as UsdkSettings;
        result = usdkComponentRef.instance.selected.asObservable().pipe(
          map((m) => {
            return new RouteObject({
              trafficObjectId: objectId,
              stepMovement: {
                phase: m.id,
                movements: m.movements,
              },
            });
          })
        );
        this._selectPopupComponentRef = usdkComponentRef;
        break;
      case TrafficControllerType.Potok:
        const potokComponentRef = this._cfr
          .resolveComponentFactory(PotokSelectPhaseComponent)
          .create(this._injector);
        this._appRef.attachView(potokComponentRef.hostView);
        potokComponentRef.instance.schemaSet = schemaSet;
        potokComponentRef.instance.settings = settings as PotokSettings;
        result = potokComponentRef.instance.selected.pipe(
          map((m) => {
            return new RouteObject({
              trafficObjectId: objectId,
              stepMovement: {
                phase: 0,
                movements: m,
              },
            });
          })
        );
        this._selectPopupComponentRef = potokComponentRef;
        break;
      default:
        break;
    }

    if (!this._selectPopupComponentRef) return;

    const popup = L.popup({
      offset: [0, 0],
      autoPan: true,
      autoPanPadding: [20, 20],
      minWidth: 108,
      closeOnClick: true,
      closeButton: false,
      autoClose: false,
      keepInView: false,
    })
      .setContent(this._selectPopupComponentRef.location.nativeElement)
      .setLatLng([object.lat, object.lon])
      .on('remove', (e) => {
        this._destroySelectPopupComponentRef();
      });

    layer._map.openPopup(popup);
    this._selectPopupComponentRef['_id'] = object.id;
    this._selectPopupComponentRef['_popup'] = popup;
    setTimeout(() => layer.closePopup());

    return result || of(null);
  }

  _addToRoute(item: RouteObject) {
    const newRoute = new Route(this.selectedRoute);
    newRoute.settings = new RouteSettings(this.selectedRoute.settings);
    newRoute.settings.routeObjects = [...newRoute.settings.routeObjects, item];

    this._stateService.selectedRouteBounded = true;
    this._stateService.selectedRoute = newRoute;

    this._changeDetector.markForCheck();
  }

  closeSelectedRoute() {
    this._stateService.selectedRoute = null;
  }
}
