import { Injectable } from '@angular/core';

import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import {
  IDataSourceService,
  setPageParams,
  getPageReturn,
  IPageParams,
  IPageReturn,
} from 'projects/msu-its-web-common/src/data/data-source';

import { TssApiService } from './tss-api.service';

import {
  AdaptiveScene,
  AdaptiveSceneInfo,
  CreateAdaptiveSceneRequest,
  DeleteAdaptiveSceneRequest,
  UpdateAdaptiveSceneRequest,
  GetAdaptiveSceneRequest,
  GetAdaptiveScenesDetailsRequest,
  GetAdaptiveScenesRequest,
  GetAdaptiveSceneStatusRequest,
  StopAdaptiveSceneControlMode,
  StartAdaptiveSceneRequest,
} from '../dtos/tss.dtos';

@Injectable()
export class AdaptiveSceneService implements IDataSourceService<AdaptiveScene, AdaptiveSceneInfo> {
  constructor(private _api: TssApiService) {}

  getPagedList(
    params: IPageParams,
    customerId: string
  ): Observable<IPageReturn<AdaptiveSceneInfo>> {
    if (!customerId) {
      return of(null);
    }

    const request = new GetAdaptiveScenesRequest();
    request.customerId = customerId;

    setPageParams(request, params);
    return this._api.get(request).pipe(
      map((m) => {
        return getPageReturn(m);
      })
    );
  }
  getList(customerId: string, filter?: string, groupIds?: string[]) {
    const request = new GetAdaptiveScenesDetailsRequest();
    request.customerId = customerId;
    request.entityGroups = groupIds;

    request.page = 0;
    request.items = 0;
    request.sort = 'name';
    request.filter = filter;

    return this._api.get(request).pipe(
      map((m) => {
        return m.items;
      })
    );
  }

  get(id: string, customerId: string) {
    const request = new GetAdaptiveSceneRequest();
    request.id = id;
    request.customerId = customerId;
    return this._api.get(request);
  }
  add(
    model: AdaptiveScene,
    params: {
      customerId: string;
      groups: string[];
      updateGroups: boolean;
    }
  ) {
    const request = new CreateAdaptiveSceneRequest();
    request.entity = model;
    request.customerId = params.customerId;
    request.entityGroups = params.groups;
    request.updateEntityGroups = params.updateGroups;
    return this._api.post(request);
  }
  update(
    model: AdaptiveScene,
    params: { customerId: string; groups: string[]; updateGroups: boolean }
  ) {
    const request = new UpdateAdaptiveSceneRequest();
    request.id = model.id;
    request.entity = model;
    request.customerId = params.customerId;
    request.entityGroups = params.groups;
    request.updateEntityGroups = params.updateGroups;
    return this._api.put(request);
  }
  delete(ids: string[], customerId: string) {
    const request = new DeleteAdaptiveSceneRequest();
    request.ids = ids;
    request.customerId = customerId;
    return this._api.delete(request);
  }
  exist(name: string, customerId: string): Observable<boolean> {
    // const request = new ExistAdaptiveSceneNameRequest();
    // request.name = name;
    // request.customerId = customerId;
    // return this._api.get(request);
    return of(false);
  }

  startAdaptiveScene(id: string, customerId: string) {
    const request = new StartAdaptiveSceneRequest();

    request.id = id;
    request.customerId = customerId;

    return this._api.post(request, { customerId });
  }
  stopAdaptiveScene(id: string, customerId: string) {
    const request = new StopAdaptiveSceneControlMode();

    request.id = id;
    request.customerId = customerId;

    return this._api.post(request, { customerId });
  }

  getStatus(id: string, customerId: string) {
    const request = new GetAdaptiveSceneStatusRequest();

    request.id = id;
    request.customerId = customerId;

    return this._api.getUnsafe(request);
  }
}
