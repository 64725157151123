import { Inject, Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { APP_ENVIRONMENT } from '../utils/shared-consts';

const LANG = 'lang';
const DARK_THEME = 'dark_theme';
const SIDENAV_EXPANDED = 'sidenav_expanded';
const PAGE_SIZE = 'page_size_';

const MAP_ZOOM = 'map_zoom';
const MAP_CENTER = 'map_center';
const MAP_LAYER = 'map_layer';
const MAP_TRAFFIC = 'map_traffic';
const TRAFFIC_ACCIDENT = 'traffic_accident';

const MAP_GROUPS = 'map_groups';

const ESCORT_SUSPEND_TIME = 'escort_suspend_time';

const TRAFFIC_OBJECT_STATE_TAB = 'traffic_object_state_tab';

@Injectable({ providedIn: 'root' })
export class SettingsService {
  private _lang = this.lang;
  private _darkTheme = this.darkTheme;
  public darkThemeChanged = new Subject<boolean>();
  public mapTrafficChanged = new Subject<boolean>();
  public trafficAccidentChanged = new Subject<boolean>();

  constructor(
    @Inject(APP_ENVIRONMENT) private _appEnv,
    public translate: TranslateService
  ) {
    setInterval(() => {
      if (this._darkTheme !== this.darkTheme) {
        this._darkTheme = this.darkTheme;
        this.darkThemeChanged.next(this.darkTheme);
      }
      if (this._lang !== this.lang) {
        this._lang = this.lang;
        this.translate.use(this.lang);
      }
    }, 5000);
  }

  private _timezone: string = null;
  public get timezone() {
    if (!this._timezone) {
      // get local timezone
      const offsetHours = Math.round(new Date().getTimezoneOffset() / -60);
      const plus = offsetHours >= 0;
      const time = String(Math.abs(offsetHours))
        .padStart(2, '0')
        .padEnd(4, '0');
      this._timezone = (plus ? '+' : '-') + time;
      // console.log(this._timezone);
    }
    return this._timezone;
  }
  public set timezone(value: string) {
    this._timezone = value;
  }

  public get lang(): string {
    return localStorage.getItem(LANG);
  }
  public set lang(value: string) {
    localStorage.setItem(LANG, value);
  }

  public get darkTheme(): boolean {
    return localStorage.getItem(DARK_THEME) !== 'false';
  }
  public set darkTheme(value: boolean) {
    value
      ? localStorage.removeItem(DARK_THEME)
      : localStorage.setItem(DARK_THEME, 'false');
    this._darkTheme = this.darkTheme;
    this.darkThemeChanged.next(value);
  }

  public get sidenavExpanded(): boolean {
    return localStorage.getItem(SIDENAV_EXPANDED) !== 'false';
  }
  public set sidenavExpanded(value: boolean) {
    localStorage.setItem(SIDENAV_EXPANDED, value ? null : 'false');
  }

  public getPageSize(entity): number {
    const result = +localStorage.getItem(PAGE_SIZE + entity);
    return result ? result : 50;
  }
  public setPageSize(entity, value: number) {
    localStorage.setItem(PAGE_SIZE + entity, String(value));
  }

  public get mapZoom(): number {
    const value = localStorage.getItem(MAP_ZOOM);
    return value ? +value : 10;
  }
  public set mapZoom(value: number) {
    localStorage.setItem(MAP_ZOOM, value + '');
  }

  public get mapCenter(): any {
    const value = localStorage.getItem(MAP_CENTER);
    return value
      ? JSON.parse(value)
      : {
          lat: 56.01054,
          lon: 92.86599,
        };
  }
  public set mapCenter(value: any) {
    localStorage.setItem(MAP_CENTER, JSON.stringify(value));
  }

  public get mapLayer(): string {
    return localStorage.getItem(MAP_LAYER);
  }
  public set mapLayer(value: string) {
    localStorage.setItem(MAP_LAYER, value);
  }

  public get trafficAccident(): boolean {
    return localStorage.getItem(TRAFFIC_ACCIDENT ) !== 'false';
  }
  public set trafficAccident(value: boolean) {
    localStorage.setItem(TRAFFIC_ACCIDENT , value ? null : 'false');
    this.trafficAccidentChanged.next(value);
  }

  public get mapTraffic(): boolean {
    return localStorage.getItem(MAP_TRAFFIC) !== 'false';
  }
  public set mapTraffic(value: boolean) {
    localStorage.setItem(MAP_TRAFFIC, value ? null : 'false');
    this.mapTrafficChanged.next(value);
  }

  public get mapGroups(): string[] {
    const value = localStorage.getItem(MAP_GROUPS);
    return value ? JSON.parse(value) : [];
  }
  public set mapGroups(value: string[]) {
    localStorage.setItem(MAP_GROUPS, JSON.stringify(value));
  }

  public get escortSuspendTime(): number {
    return parseInt(localStorage.getItem(ESCORT_SUSPEND_TIME) || '180', 10);
  }
  public set escortSuspendTime(value: number) {
    localStorage.setItem(ESCORT_SUSPEND_TIME, value.toString());
  }

  public get TrafficObjectStateTab(): number {
    const value = localStorage.getItem(TRAFFIC_OBJECT_STATE_TAB);
    return value ? +value : 0;
  }
  public set TrafficObjectStateTab(value: number) {
    localStorage.setItem(TRAFFIC_OBJECT_STATE_TAB, value + '');
  }
}
