import { Inject, Injectable } from "@angular/core";
import {
  IDataSourceService,
  setPageParams,
  getPageReturn,
  IPageParams,
  IPageReturn,
} from "projects/msu-its-web-common/src/data/data-source";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { TokenService } from "projects/msu-its-web-common/src/services/token.service";
import { APP_ENVIRONMENT } from "projects/msu-its-web-common/src/utils/shared-consts";
import {
  CameraCommand,
  CreateMeteoObjectRequest,
  DeleteMeteoObjectsRequest,
  GetMeteoObjectExistRequest,
  GetMeteoObjectInfoRequest,
  GetMeteoObjectRequest,
  GetMeteoObjectsRequest,
  MeteoObject,
  MeteoObjectChangeCustomerRequest,
  MeteoObjectInfo,
  MeteoObjectReplacement,
  SetMeteoObjectReplacementRequest,
  UpdateMeteoObjectRequest,
} from "../dtos/meteo.dtos";
import { MeteoApiService } from "./meteo-api.service";

@Injectable()
export class MeteoObjectsService
  implements IDataSourceService<MeteoObject, MeteoObject> {
  constructor(
    private _api: MeteoApiService,
    private _tokenService: TokenService,
    @Inject(APP_ENVIRONMENT) private _appEnv
  ) {}

  getPagedList(
    params: IPageParams,
    customerId: string
  ): Observable<IPageReturn<MeteoObject>> {
    const request = new GetMeteoObjectsRequest();
    request.customerId = customerId;
    setPageParams(request, params);
    return this._api.get(request).pipe(
      map((m) => {
        return getPageReturn(m);
      })
    );
  }

  getList(
    customerId: string,
    filter?: string,
    groupIds?: string[],
    lat?: number,
    lon?: number,
    distance = 200
  ): Observable<MeteoObject[]> {
    const request = new GetMeteoObjectsRequest();
    request.customerId = customerId;
    request.entityGroups = groupIds;

    request.page = 0;
    request.items = 0;
    request.sort = "name";
    request.filter = filter;

    // request.lat = lat;
    // request.lon = lon;
    // request.distance = distance;

    return this._api.get(request).pipe(
      map((m) => {
        return m.items;
      })
    );
  }

  get(id: any, customerId: string): Observable<MeteoObject> {
    const request = new GetMeteoObjectRequest();
    request.customerId = customerId;
    request.id = id;
    return this._api.get(request);
  }
  getMeteoObjectInfoRequest(
    id: any,
    customerId: string
  ): Observable<MeteoObjectInfo> {
    const request = new GetMeteoObjectInfoRequest();
    request.customerId = customerId;
    request.id = id;
    return this._api.get(request);
  }

  add(
    model: MeteoObject,
    params: {
      customerId: string;
      groups: string[];
      updateGroups: boolean;
    }
  ) {
    const request = new CreateMeteoObjectRequest();
    request.customerId = params.customerId;
    request.entity = model;
    request.entity.customerId = params.customerId;
    request.entityGroups = params.groups;
    request.updateEntityGroups = params.updateGroups;
    return this._api.post(request);
  }

  update(
    model: MeteoObject,
    params: { customerId: string; groups: string[]; updateGroups: boolean }
  ) {
    const request = new UpdateMeteoObjectRequest();
    request.id = model.id;
    request.entity = model;
    request.customerId = params.customerId;
    request.entityGroups = params.groups;
    request.updateEntityGroups = params.updateGroups;
    return this._api.put(request);
  }

  delete(ids: string[], customerId: string): Observable<number> {
    const request = new DeleteMeteoObjectsRequest();
    request.customerId = customerId;
    request.ids = ids;
    return this._api.delete(request);
  }

  exist(name: string): Observable<boolean> {
    const request = new GetMeteoObjectExistRequest();
    request.name = name;
    return this._api.get(request);
  }
  changeCustomer(
    id: string,
    customerId: string,
    params: { newCustomerId: string; description: string }
  ) {
    const request = new MeteoObjectChangeCustomerRequest();
    request.id = id;
    request.customerId = customerId;
    request.description = params.description;
    request.newCustomerId = params.newCustomerId;
    return this._api.post(request);
  }

  getMeteoStationImageRequest(id, force) {
    // const url = `http://krsk.asudd24.ru:8088/device/${id}/image?forceUpdate=${force}&t=${Date.now()}`;
    const url = `${this._appEnv.modules.meteo.apiUrl}/json/reply/GetMeteoStationImageRequest?id=${id}&ForceUpdate=${force}`;

    const headers = new Headers();
    headers.append("Authorization", `Bearer ${this._tokenService.bearerToken}`);
    const request = new Request(url, {
      method: "GET",
      headers,
      mode: "cors",
      cache: "no-store",
    });
    return request;
  }

  getMeteoObjectImageRequest(id, force) {
    // const url = `http://krsk.asudd24.ru:8088/device/${id}/image?forceUpdate=${force}&t=${Date.now()}`;
    const url = `${this._appEnv.modules.meteo.apiUrl}/json/reply/GetMeteoObjectImageRequest?id=${id}&ForceUpdate=${force}`;

    const headers = new Headers();
    headers.append("Authorization", `Bearer ${this._tokenService.bearerToken}`);
    const request = new Request(url, {
      method: "GET",
      headers,
      mode: "cors",
      cache: "no-store",
    });
    return request;
  }
  camComtrol(id, cameraCommand: CameraCommand) {
    // const url = `http://krsk.asudd24.ru:8088/device/${id}/image?forceUpdate=${force}&t=${Date.now()}`;
    const url = `${this._appEnv.modules.meteo.apiUrl}/json/reply/SendMeteoObjectCameraCommand`;
    const body = { id: `${id}`, cameraCommand: `${cameraCommand}` };
    const headers = new Headers();
    headers.append("Authorization", `Bearer ${this._tokenService.bearerToken}`);
    const request = new Request(url, {
      method: "POST",
      headers,
      mode: "cors",
      cache: "no-store",
      body: JSON.stringify(body),
    });
    return request;
  }

  setDetectorReplacementRequest(
    customerId: string,
    entity: MeteoObjectReplacement,
  ) {
    const request = new SetMeteoObjectReplacementRequest();
    request.customerId = customerId;
    request.entity = entity;
    request.entity.customerId = customerId;
    return this._api.put(request);
  }
}
