import { Injectable } from '@angular/core';
import {
  getPageReturn,
  IDataSourceService,
  IPageParams,
  IPageReturn,
  setPageParams,
} from 'projects/msu-its-web-common/src/data/data-source';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {  DetectorReplacement, DetectorReplacementsResponse, GetDetectorReplacementsRequest } from '../dtos/tfms.dtos';
import { TfmsApiService } from './tfms-api.service';

@Injectable()
export class DetectorLogService
  implements IDataSourceService<DetectorReplacement, DetectorReplacement> {
  constructor(private _api: TfmsApiService) {}

  getPagedList(
    params: IPageParams,
    requestParams: {
      customemrId?:string;
      detectorId?: string;
      beginDate?: string;
      endDate?: string;
    }
  ): Observable<IPageReturn<DetectorReplacement>> {
    const request = new GetDetectorReplacementsRequest();
    setPageParams(request, params);

    request.detectorId = requestParams?.detectorId;
    request.customerId=requestParams?.customemrId;
    request.from = requestParams?.beginDate;
    request.to = requestParams?.endDate;

    return this._api.get(request).pipe(
      map((m) => {
        return getPageReturn(m);
      })
    );
  }

  getList(filter?: string, detectorId?: string, beginDate?: string, endDate?: string, count = 20) {
    const request = new GetDetectorReplacementsRequest();

    request.detectorId = detectorId;
    request.from = beginDate;
    request.to = endDate;

    request.page = 0;
    request.items = count;
    request.filter = filter;

    request.sort = 'activityTime desc';

    return this._api.get(request).pipe(
      map((m) => {
        return getPageReturn(m).data;
      })
    );
  }

  get(id: any): Observable<DetectorReplacement> {
    throw 'not implemented';
  }

  add(model: DetectorReplacement): Observable<DetectorReplacement> {
    throw 'not implemented';
  }

  update(model: DetectorReplacement): Observable<DetectorReplacement> {
    throw 'not implemented';
  }

  delete(ids: string[]): Observable<number> {
    throw 'not implemented';
  }
}
