import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';

import {
  Component,
  ChangeDetectionStrategy,
  Injector,
  Input,
  OnInit,
} from '@angular/core';

import { AuditItem, AuditItemDetails } from '../../dtos/hub.dtos';

import { TableBaseComponent } from '../tables/table-base.component';
import { AuditLogService } from '../../services/audit-log.service';
import { SettingsService } from '../../services/settings.service';
import { FormControl } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { catchError, finalize } from 'rxjs/operators';
import { EMPTY } from 'rxjs';

@Component({
  selector: 'audit-log',
  templateUrl: './audit-log.component.html',
  styles: [
    `
      :host {
        display: flex;
        flex: 1;
        width: 100%;
        height: 100%;
      }
      /*
      .mat-column-entityName {
        flex: 2;
      }
      */
      .mat-column-description {
        flex: 2;
      }
      .date-button {
        pointer-events: none;
        font-size: 16px;
        position: absolute;
        right: 0;
        width: 20px;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AuditLogComponent
  extends TableBaseComponent<AuditItem, AuditItemDetails>
  implements OnInit
{
  @Input()
  entityId: string;

  @Input()
  entityName: string;

  displayedColumns = [
    'activityTime',
    'userName',
    // 'activity',
    'entityName',
    'description',
  ];

  rowActions = [
    // { action: 'edit', name: _('COMMON.EDIT'), icon: 'edit' },
    // { action: 'delete', name: _('COMMON.DELETE'), icon: 'delete' },
  ];

  msgAddSaved = null;
  msgEditSaved = null;
  msgDeleteConfirm = null;
  msgDeleteSuccess = null;
  msgDeleteSelectedConfirm = null;
  msgDeleteSelectedSuccess = null;

  fromControl = new FormControl();
  toControl = new FormControl();

  get timezone() {
    return this._settingsService.timezone;
  }
  get locale() {
    return this.translate.currentLang;
  }

  constructor(injector: Injector, private _settingsService: SettingsService) {
    super(injector, injector.get(AuditLogService));

    const today = new Date();
    const nextday = new Date();
    nextday.setDate(today.getDate() + 1);

    this.fromControl.setValue(this._getISODateValue(today));
    this.toControl.setValue(this._getISODateValue(nextday));

    this.fromControl.valueChanges.subscribe((value) => this._setDateRange());
    this.toControl.valueChanges.subscribe((value) => this._setDateRange());
  }

  ngOnInit() {
    // this.dataSource.requestParams = { entityId: this.entityId };

    this.dataSource.requestParams = {
      id: this.entityId,
      // customerId: this.customerId,
    };

    this._setDateRange();
  }

  private _getISODateValue(date: Date) {
    date.setMinutes(date.getMinutes() - date.getTimezoneOffset());
    return date.toISOString().split("T")[0] + "T00:00";
  }

  private _getTimeString(pipe: DatePipe, date: Date | string) {
    return `${pipe.transform(
      date,
      'dd.MM.yyyy HH:mm',
      this.timezone,
      this.translate.currentLang
    )}`;
  }

  _setDateRange() {
    this.dataSource.requestParams.from = this.fromControl.value
      ? new Date(this.fromControl.value + this.timezone).toISOString()
      : null;

    this.dataSource.requestParams.to = this.toControl.value
      ? new Date(this.toControl.value + this.timezone).toISOString()
      : null;

    // this.dataSource.reloadData();
  }

  getNew() {
    return null;
  }

  getEdit(id) {
    return null;
  }

  getEditDialog() {
    return null;
  }

  saveCsv() {
    const datePipe = new DatePipe(this.translate.currentLang);

    const from = this._getTimeString(datePipe, this.fromControl.value);
    const to = this._getTimeString(datePipe, this.toControl.value);
    let content = `${this.entityName}\n${from} - ${to}\n\n`;

    const headers = [
      this.translate.instant('COMMON.TIME'),
      this.translate.instant('COMMON.USERNAME'),
      this.translate.instant('AUDIT_LOG.ENTITY_NAME'),
      this.translate.instant('AUDIT_LOG.ACTIVITY'),
      this.translate.instant('COMMON.DESCRIPTION'),
    ];

    content += headers.join(';') + '\n';

    this.dataSource.loading.next(true);

    this.dataService
      .getPagedList(
        {
          filter: this.dataSource.filter,
          page: 0,
          size: 0,
          sort: this.dataSource.sort.active,
          desc: this.dataSource.sort.direction === 'desc',
        },
        this.dataSource.requestParams
      )
      .pipe(
        catchError(() => {
          return EMPTY;
        })
      )
      .pipe(finalize(() => this.dataSource.loading.next(false)))
      .subscribe((result) => {
        result.data.forEach((n) => {
          const time = this._getTimeString(datePipe, n.activityTime);
          const user = n.userName;
          const type = n.activity;
          const name = n.entityName;
          const description = n.description ? n.description : '';

          content += `="${time}";="${user}";="${name}";${type};${description}\n`;
        });

        const blob = new Blob(['\uFEFF' + content], { type: 'text/csv' });
        const a = document.createElement('a');
        a.download = `${this.translate.instant(_('COMMON.AUDIT'))}_${
          this.entityName || ''
        }_${from}-${to}.csv`;
        a.href = window.URL.createObjectURL(blob);
        a.click();
      });
  }
}
