import { Injectable } from '@angular/core';

import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import {
  IDataSourceService,
  setPageParams,
  getPageReturn,
  IPageParams,
  IPageReturn,
} from 'projects/msu-its-web-common/src/data/data-source';
import { PtmApiService } from '../ptm-api.service';
import { CreatePublicTransportVehicleRequest, DeletePublicTransportVehiclesRequest, GetPublicTransportVehicleExistRequest, GetPublicTransportVehicleRequest, GetPublicTransportVehiclesRequest, PublicTransportVehicle, UpdatePublicTransportVehicleRequest } from '../../dtos/ptm.dtos';

@Injectable()
export class VehicleService implements IDataSourceService<PublicTransportVehicle, PublicTransportVehicle> {
  constructor(private _api: PtmApiService) {}

  getPagedList(params: IPageParams, customerId: string): Observable<IPageReturn<PublicTransportVehicle>> {
    if (!customerId) {
      return of(null);
    }

    const request = new GetPublicTransportVehiclesRequest();
    request.customerId = customerId;

    setPageParams(request, params);
    return this._api.get(request).pipe(
      map((m) => {
        return getPageReturn(m);
      })
    );
  }
  getList(customerId: string, filter?: string, groupIds?: string[],     items?: number): Observable<PublicTransportVehicle[]> {
    const request = new GetPublicTransportVehiclesRequest();
    request.customerId = customerId;
    request.entityGroups = groupIds || [];

    request.page = 0;
    request.items = items || 0;
    request.sort = 'number';
    request.filter = filter || '';

    return this._api.get(request).pipe(
      map((m) => {
        return m.items;
      })
    );
  }

  get(id: string, customerId: string) {
    const request = new GetPublicTransportVehicleRequest();
    request.id = id;
    request.customerId = customerId;
    return this._api.get(request);
  }
  add(
    model: PublicTransportVehicle,
    params: {
      customerId: string;
      groups: string[];
      updateGroups: boolean;
    }
  ) {
    const request = new CreatePublicTransportVehicleRequest();
    request.entity = model;
    request.customerId = params.customerId;
    request.entityGroups = params.groups;
    request.updateEntityGroups = params.updateGroups;
    return this._api.post(request);
  }
  update(model: PublicTransportVehicle, params: { customerId: string; groups: string[]; updateGroups: boolean }) {
    const request = new UpdatePublicTransportVehicleRequest();
    request.id = model.id;
    request.entity = model;
    request.customerId = params.customerId;
    request.entityGroups = params.groups;
    request.updateEntityGroups = params.updateGroups;
    return this._api.put(request);
  }
  delete(ids: string[], customerId: string) {
    const request = new DeletePublicTransportVehiclesRequest();
    request.ids = ids;
    request.customerId = customerId;
    return this._api.delete(request);
  }
  exist(number: string, customerId: string): Observable<boolean> {
    const request = new GetPublicTransportVehicleExistRequest({ number, customerId });
    return this._api.get(request);
  }
}
