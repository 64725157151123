<div id="host" [style.height]="external ? '500px' : '400px'">
  <div *ngIf="loading" class="backdrop" style="z-index: 100;">
    <mat-spinner diameter="36" color="accent"></mat-spinner>
  </div>
  <div *ngIf="modelInfo">
    <!-- <div fxFlexFill fxLayout="column" fxLayoutAlign="space-around start"> -->
    <div *ngIf="external" fxLayout="column">
      <span
        style="
          margin: 0;
          font-weight: 500;
          font-size: 15px;
          line-height: 1;
          flex: 1;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        "
        >{{ modelInfo.name }}</span
      >
      <span class="mat-small mat-hint mat-secondary"
        >{{ modelInfo.description }}</span
      >
    </div>
    <div fxFlexFill>
      <mat-tab-group
        mat-align-tabs="start"
        (selectedTabChange)="changeEvent($event)"
      >
        <mat-tab label="{{ 'POPUP.GRAPH' | translate }}" style="height: 100%;">
          <div *ngIf="loadingTab" class="backdrop" style="z-index: 100;">
            <mat-spinner diameter="36" color="accent"></mat-spinner>
          </div>
          <div fxFlexFill fxLayout="column">
            <div fxFlex="10" fxLayout="row" style="z-index: 5;">
              <div fxFlex="200px" fxLayoutAlign="space-around center">
                <span style="font-size: 14px;"
                  >{{ "POPUP.AVG_SPEED_TABLE" | translate }} : {{ avgSpeedTable
                  }} {{ "DETECTORS.SPEED_UNITS" | translate }}</span
                >
              </div>
              <div fxFlex fxLayoutAlign="space-around center">
                <span style="font-size: 14px;">
                  {{ "POPUP.COUNT_TABLE" | translate }} : {{ countTable }} {{
                  "DETECTORS.COUTN_UNITS" | translate }}</span
                >
              </div>
              <div fxFlex fxLayoutAlign="space-around center">
                <span style="font-size: 14px;">
                  {{ "POPUP.LANE_FILL_TABLE" | translate }} : {{ laneFillTable
                  }} {{ "DETECTORS.LANE_FILL_UNITS" | translate }}</span
                >
              </div>
              <div fxFlex="nogrow" fxLayoutAlign="space-around center">
                <mat-form-field
                  style="width: 90px; margin-bottom: -5px; z-index: 5;"
                >
                  <mat-select
                    [(ngModel)]="lane"
                    (ngModelChange)="selectLane($event)"
                  >
                    <mat-option [value]="0"
                      >{{ "COMMON.SUMMARY" | translate }}</mat-option
                    >
                    <mat-option *ngFor="let item of lanes" [value]="item">
                      {{ "COMMON.LANE" | translate }} #{{ item }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
            <div
              fxFlex
              style="height: 100%;"
              (window:resize)="onResize($event)"
            >
              <div
                echarts
                id="graf_{{ modelId }}"
                [autoResize]="true"
                class="chartGraf"
              ></div>
            </div>
          </div>
        </mat-tab>

        <mat-tab
          *ngIf="isOptic"
          label="{{ 'POPUP.PHOTO' | translate }}"
          style="align-items: center; min-height: 300px !important;"
        >
          <div *ngIf="loadingTab" class="backdrop" style="z-index: 100;">
            <mat-spinner diameter="36" color="accent"></mat-spinner>
          </div>
          <div fxLayoutAlign="center center" style="min-height: 360px;">
            <div id="controlPhoto" class="control-photo">
              <button
                mat-flat-button
                color="accent"
                (click)="refreshImg()"
                class="control-photo-button"
              >
                <mat-icon>refresh</mat-icon>
              </button>
              <div class="control-photo-toggle">
                <mat-slide-toggle
                  (change)="changeToggleArea()"
                  [(ngModel)]="isCheckedAreas"
                  >Зоны
                </mat-slide-toggle>
              </div>
            </div>
            <div class="images">
              <div style="position: absolute; width: 100%; height: 100%;">
                <svg
                  width="100%"
                  height="100%"
                  viewBox="0 0 100 100"
                  preserveAspectRatio="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <defs>
                    <marker
                      id="head"
                      orient="auto"
                      markerWidth="15"
                      markerHeight="10"
                      refX="15"
                      refY="5"
                    >
                      <path d="M0,0 L15,5 0,10 Z" fill="#D22" />
                    </marker>
                  </defs>
                  <g id="zone_{{ modelId }}"></g>
                </svg>
              </div>
              <img fxFill id="photo_{{ modelId }}" style="height: 330px;" />
              <!-- <img fxFill onerror="this.onerror=null;this.src='assets/images/img_not_found.png';" id="photo_{{ modelId }}" /> -->
            </div>
          </div>
        </mat-tab>

        <mat-tab *ngIf="isOptic" label="{{ 'POPUP.WEATHER' | translate }}">
          <div *ngIf="loadingTab" class="backdrop" style="z-index: 100;">
            <mat-spinner diameter="36" color="accent"></mat-spinner>
          </div>
          <div fxFlexFill fxLayout="column">
            <div
              fxFlex="10"
              fxLayout="row"
              fxFlexOffset="2"
              fxLayoutAlign="space-around center"
              style="z-index: 5;"
            >
              <div fxFlex>
                {{ "POPUP.TEMPRETURE" | translate }} : {{ temperature }} С
              </div>
              <div fxFlex>
                {{ "POPUP.HUMIDITY" | translate }} : {{ humidity }} %
              </div>
            </div>
            <div
              fxFlex
              style="height: 100%;"
              (window:resize)="onResize($event)"
            >
              <div echarts id="grafWeather_{{ modelId }}" class="chart"></div>
            </div>
          </div>
        </mat-tab>

        <mat-tab *ngIf="isOptic" label="{{ 'POPUP.ANALITICS' | translate }}">
          <div *ngIf="loadingTab" class="backdrop" style="z-index: 100;">
            <mat-spinner diameter="36" color="accent"></mat-spinner>
          </div>
          <div fxFlexFill fxLayout="column">
            <div
              fxFlex="10"
              fxLayout="row"
              fxFlexOffset="2"
              fxLayoutAlign="space-between center"
              style="z-index: 5;"
            >
              <div fxLayout="column">
                <div fxFlex>
                  <span *ngIf="atypicalSpeed" style="color: #ff2f2fda;">
                    {{ "POPUP.AVG_SPEED_TABLE" | translate }}
                  </span>
                  <span *ngIf="!atypicalSpeed">
                    {{ "POPUP.AVG_SPEED_TABLE" | translate }}
                  </span>
                </div>
                <div fxFlex fxLayout="row" fxLayoutAlign="space-around center">
                  <span fxFlex="100px" style="font-size: 14px;">
                    {{ "DETECTORS.NOW" | translate }} :
                  </span>
                  <span fxFlex style="font-size: 14px;"
                    >{{ currentSpeed }} {{ "DETECTORS.SPEED_UNITS" | translate
                    }}</span
                  >
                </div>
                <div fxFlex fxLayout="row" fxLayoutAlign="space-around center">
                  <span fxFlex="100px" style="font-size: 14px;">
                    {{ "DETECTORS.STATISTIC" | translate }} :
                  </span>
                  <span fxFlex style="font-size: 14px;"
                    >{{ pastYearAvgSpeed }} {{ "DETECTORS.SPEED_UNITS" |
                    translate }}</span
                  >
                </div>
              </div>
              <div fxLayout="column">
                <div fxFlex>
                  <span *ngIf="atypicalLaneFill" style="color: #ff2f2fda;">
                    {{ "POPUP.LANE_FILL_TABLE" | translate }}
                  </span>
                  <span *ngIf="!atypicalLaneFill">
                    {{ "POPUP.LANE_FILL_TABLE" | translate }}
                  </span>
                </div>
                <div fxFlex fxLayout="row" fxLayoutAlign="space-around center">
                  <span fxFlex="100px" style="font-size: 14px;">
                    {{ "DETECTORS.NOW" | translate }} :
                  </span>
                  <span fxFlex style="font-size: 14px;"
                    >{{ currentLaneFill }} {{ "DETECTORS.LANE_FILL_UNITS" |
                    translate }}</span
                  >
                </div>
                <div fxFlex fxLayout="row" fxLayoutAlign="space-around center">
                  <span fxFlex="100px" style="font-size: 14px;">
                    {{ "DETECTORS.STATISTIC" | translate }} :
                  </span>
                  <span fxFlex style="font-size: 14px;"
                    >{{ pastYearLaneFill }} {{ "DETECTORS.LANE_FILL_UNITS" |
                    translate }}</span
                  >
                </div>
              </div>
              <div fxLayout="column">
                <div fxFlex>
                  <span *ngIf="atypicalCount" style="color: #ff2f2fda;">
                    {{ "POPUP.COUNT_TABLE" | translate }}
                  </span>
                  <span *ngIf="!atypicalCount">
                    {{ "POPUP.COUNT_TABLE" | translate }}
                  </span>
                </div>
                <div fxFlex fxLayout="row" fxLayoutAlign="space-around center">
                  <span fxFlex="100px" style="font-size: 14px;">
                    {{ "DETECTORS.NOW" | translate }} :
                  </span>
                  <span fxFlex style="font-size: 14px;"
                    >{{ currentCount }} {{ "DETECTORS.COUTN_UNITS" | translate
                    }}</span
                  >
                </div>
                <div fxFlex fxLayout="row" fxLayoutAlign="space-around center">
                  <span fxFlex="100px" style="font-size: 14px;">
                    {{ "DETECTORS.STATISTIC" | translate }} :
                  </span>
                  <span fxFlex style="font-size: 14px;"
                    >{{ pastYearCount }} {{ "DETECTORS.COUTN_UNITS" | translate
                    }}</span
                  >
                </div>
              </div>
              <!-- <div fxFlex fxLayoutAlign="space-around center">
                {{ "POPUP.AVG_SPEED_TABLE" | translate }} : {{ avgSpeedTable }} {{ "DETECTORS.SPEED_UNITS" | translate }}
              </div>
              <div fxFlex fxLayoutAlign="space-around center">
                {{ "POPUP.COUNT_TABLE" | translate }} : {{ countTable }} {{ "DETECTORS.COUTN_UNITS" | translate }}
              </div>
              <div fxFlex fxLayoutAlign="space-around center">
                {{ "POPUP.LANE_FILL_TABLE" | translate }} : {{ laneFillTable }} {{ "DETECTORS.LANE_FILL_UNITS" | translate }}
              </div> -->
            </div>
            <div
              fxFlex="50px"
              fxLayout="row"
              fxFlexOffset="2"
              fxLayoutAlign="space-between center"
            >
              <span
                fxFlex
                *ngIf="atypicalCount || atypicalSpeed || atypicalLaneFill"
                style="color: #ff2f2fda;"
              >
                Нетипичное поведение</span
              >
              <mat-form-field fxFlex=" 130px" style="z-index: 5;">
                <mat-select
                  [(ngModel)]="selectedDataTypes"
                  (ngModelChange)="changeType($event)"
                >
                  <mat-option value="AvgSpeed">
                    {{ "DETECTORS.SPEED" | translate }}</mat-option
                  >
                  <mat-option value="Count">
                    {{ "DETECTORS.QUANTITY" | translate }}</mat-option
                  >
                  <mat-option value="LaneFill">
                    {{ "DETECTORS.FILLING" | translate }}</mat-option
                  >
                </mat-select>
              </mat-form-field>
            </div>
            <div fxFlex (window:resize)="onResize($event)">
              <div
                echarts
                id="chart_analitic_{{ modelId }}"
                style="
                  margin-top: -47px;
                  width: 100%;
                  min-height: 290px;
                  z-index: 2;
                "
              ></div>
            </div>
          </div>
        </mat-tab>

        <mat-tab
          *ngIf="isOptic"
          label="{{ 'DETECTOR.POPUP.FORECAST.TAB' | translate }}"
        >
          <div *ngIf="loadingTab" class="backdrop" style="z-index: 100;">
            <mat-spinner diameter="36" color="accent"></mat-spinner>
          </div>
          <div fxFlexFill fxLayout="column">
            <span style="margin-top: 8px;"
              >{{ 'DETECTOR.POPUP.FORECAST.TITLE' | translate }}</span
            >
            <!-- <div
              fxFlex="10"
              fxLayout="row"
              fxFlexOffset="2"
              fxLayoutAlign="space-between end"
              style="z-index: 5;"
            >
              <div fxLayout="column">
                <div fxFlex fxLayout="row" fxLayoutAlign="space-around center">
                  <span fxFlex>
                    {{ "POPUP.AVG_SPEED_TABLE" | translate }}:
                  </span>
                  <span fxFlex>{{avgSpeedForecast}} км/ч</span>
                </div>
              </div>
              <div fxLayout="column">
                <div fxFlex></div>
                <div fxFlex fxLayout="row" fxLayoutAlign="space-around center">
                  <span fxFlex>
                    {{ 'DETECTOR.POPUP.FORECAST.COUNT' | translate }}:
                  </span>
                  <span fxFlex>{{avgCountForecast}} шт</span>
                </div>
              </div>
              <div fxLayout="column">
                <div fxFlex fxLayout="row" fxLayoutAlign="space-around center">
                  <span fxFlex>
                    {{ 'DETECTOR.POPUP.FORECAST.LINE_FILL' | translate }}:
                  </span>
                  <span fxFlex>{{avgFillForecast }}%</span>
                </div>
              </div>
            </div> -->

            <div
              fxFlex
              fxLayout="row"
              fxLayoutAlign="space-around center"
              fxLayoutGap="18px"
              style="margin-top: 8px;"
            >
              <span fxFlex="auto" style="font-size: 14px;">
                {{ "POPUP.AVG_SPEED_TABLE" | translate }}: {{avgSpeedForecast}}
                км/ч
              </span>
              <span fxFlex="auto" style="font-size: 14px;">
                {{ 'DETECTOR.POPUP.FORECAST.COUNT' | translate }}:
                {{avgCountForecast}} шт
              </span>
              <span fxFlex="auto" style="font-size: 14px;">
                {{ 'DETECTOR.POPUP.FORECAST.LINE_FILL' | translate }}:
                {{avgFillForecast }}%
              </span>
            </div>
            <div
              fxFlex="50px"
              fxLayout="row"
              fxFlexOffset="2"
              fxLayoutAlign="space-between center"
            >
              <mat-form-field fxFlex=" 130px" style="z-index: 5;">
                <mat-select
                  [(ngModel)]="selectedForecastDataTypes"
                  (ngModelChange)="changeTypeForecast($event)"
                >
                  <mat-option value="AvgSpeed">
                    {{ "DETECTORS.SPEED" | translate }}</mat-option
                  >
                  <mat-option value="Count">
                    {{ "DETECTORS.QUANTITY" | translate }}</mat-option
                  >
                  <mat-option value="LaneFill">
                    {{ "DETECTORS.FILLING" | translate }}</mat-option
                  >
                </mat-select>
              </mat-form-field>
            </div>
            <div fxFlex (window:resize)="onResize($event)">
              <div
                echarts
                id="chart_forecast_{{ modelId }}"
                style="
                  margin-top: -47px;
                  width: 100%;
                  min-height: 290px;
                  z-index: 2;
                "
              ></div>
            </div>
          </div>
        </mat-tab>

        <mat-tab label="{{ 'POPUP.PARAMETRS' | translate }}">
          <table class="table">
            <tr>
              <td>{{ "POPUP.TYPE" | translate }}</td>
              <td>{{ modelInfo.deviceType }}</td>
            </tr>
            <tr>
              <td>{{ "DETECTORS.DEVICE" | translate }}</td>
              <td>{{ modelInfo.serialNumber }}</td>
            </tr>
            <tr>
              <td>{{ "POPUP.COOIRDINATE" | translate }}</td>
              <td>[{{ modelInfo.lat }}, {{ modelInfo.lon }}]</td>
            </tr>
            <tr *ngIf="isOptic">
              <td>{{ "POPUP.STATUS_CONNECTED" | translate }}</td>
              <td
                *ngIf="!modelInfoSession?.detectorDeviceSession?.connected"
                style="color: #ff0000;"
              >
                {{ "POPUP.DONT_CONNECTED" | translate }}
              </td>
              <td
                *ngIf="modelInfoSession?.detectorDeviceSession?.connected"
                style="color: #00ff22;"
              >
                {{ "POPUP.CONNECTED" | translate }}
              </td>
            </tr>
            <tr *ngIf="isOptic">
              <td>{{ "POPUP.CONNECTION_TIME" | translate }}</td>
              <td>
                {{ modelInfoSession?.detectorDeviceSession?.connectionTime |
                date: "long":null:locale }}
              </td>
            </tr>
            <tr *ngIf="isOptic">
              <td>{{ "POPUP.LAST_RECEIVED" | translate }}</td>
              <td>
                {{ modelInfoSession?.detectorDeviceSession?.lastReceived | date:
                "long":null:locale }}
              </td>
            </tr>
            <tr *ngIf="isOptic">
              <td>{{ "POPUP.LAST_SENT" | translate }}</td>
              <td>
                {{ modelInfoSession?.detectorDeviceSession?.lastSent | date:
                "long":null:locale }}
              </td>
            </tr>
            <tr *ngIf="isOptic">
              <td>{{ "POPUP.CREATED_POINT_DETECTOR" | translate }}</td>
              <td>
                {{ modelInfoSession?.created | date: "long":null:locale }}
              </td>
            </tr>
            <tr *ngIf="isOptic">
              <td>{{ "POPUP.LAST_CHANGES_POINT_DETECTOR" | translate }}</td>
              <td>
                {{ modelInfoSession?.lastChanges | date: "long":null:locale }}
              </td>
            </tr>

            <!-- <tr>
              <td>{{ "POPUP.ONLINE_STATUS" | translate }}</td>
              <td *ngIf="!modelInfo.offline" style="color: #00ff22">Online</td>
              <td *ngIf="modelInfo.offline" style="color: #ff0000">Offline</td>
            </tr> -->
          </table>
        </mat-tab>
      </mat-tab-group>
    </div>
  </div>
</div>
