export const environment = {
  production: false,
  aboutManufacturer: 'ООО МСУ г.Красноярск',
  aboutPhone: '+7 (391) 27 24 24 0',
  aboutEmail: 'info@msu24.ru',
  aboutSite: 'msu24.ru',
  system: 'dev',

  // Modules
  modules: {
    // Hub
    hub: { apiUrl: 'https://hub.dev.its.msu24.ru' },
    // GIS
    gis: { apiUrl: 'https://gis.dev.its.msu24.ru' },
    // Traffic Lights Control
    tlc: { apiUrl: 'https://tlc.dev.its.msu24.ru', routing: true },
    // Tracker Position
    tpc: { apiUrl: 'https://tpc.dev.its.msu24.ru' },
    // Traffic Flow Monitoring System
    tfms: { apiUrl: 'https://tfms.dev.its.msu24.ru' },
    // Traffic Scenen System
    tss: { apiUrl: 'https://tss.dev.its.msu24.ru' },
    // Meteo monitoring
    meteo: { apiUrl: 'https://meteo.dev.its.msu24.ru' },
    // Parking
    parking: { apiUrl: 'https://parking.dev.its.msu24.ru' },
    // Transport
    ptm: { apiUrl: 'https://ptm.dev.its.msu24.ru' },
    // DVS VID
    ruis: { apiUrl: 'https://ruis.dev.its.msu24.ru' },
    // Road maintenance management dispatching
    rmmd: { apiUrl: 'https://rmmd.dev.its.msu24.ru' },
    // Fixating traffic violation
    ftv: { apiUrl: 'https://ftv.dev.its.msu24.ru' },
    // Dashboard monitoring
    dashboard: { apiUrl: 'https://dashboard.dev.its.msu24.ru' },
    // Dashboards
    manager: { url: 'https://manager-dev.msu24.ru' },
    //
    accident: { apiUrl: 'https://accident.dev.its.msu24.ru' },
    modelling: { apiUrl: 'https://modelling.dev.its.msu24.ru' },
    // Telematic
    telematic: null,
  },

  // Eputs
  // eputsModules: [
  //   { id: 'gis', name: 'EPUTS_MODULES.GIS', enabled: true },
  //   { id: 'coordination', name: 'EPUTS_MODULES.COORDINATION', enabled: true },
  //   { id: 'scenes', name: 'EPUTS_MODULES.SCENES', enabled: true },
  //   { id: 'effectiveness', name: 'EPUTS_MODULES.EFFECTIVENESS', enabled: true },
  //   { id: 'meteo', name: 'EPUTS_MODULES.METEO', enabled: true },
  //   { id: 'default', name: 'Управление наружным освещением (АСУНО)', enabled: false },
  //   { id: 'default', name: 'Информирования участников ДД с помощью ДИТ и ЗПИ', enabled: false },
  //   { id: 'default', name: 'Транспортные разрешения', enabled: false },
  //   { id: 'default', name: 'Суперсервис', enabled: false },
  //   { id: '', name: 'Модуль интеллектуальной транспортной системы', enabled: false },
  //   { id: '', name: 'Модуль интеллектуальной транспортной системы', enabled: false },
  //   { id: '', name: 'Модуль интеллектуальной транспортной системы', enabled: false },
  //   { id: '', name: 'Модуль интеллектуальной транспортной системы', enabled: false },
  //   { id: '', name: 'Модуль интеллектуальной транспортной системы', enabled: false },
  //   { id: '', name: 'Модуль интеллектуальной транспортной системы', enabled: false },
  //   { id: '', name: 'Модуль интеллектуальной транспортной системы', enabled: false },
  //   { id: '', name: 'Модуль интеллектуальной транспортной системы', enabled: false },
  //   { id: '', name: 'Модуль интеллектуальной транспортной системы', enabled: false },
  //   { id: '', name: 'Модуль интеллектуальной транспортной системы', enabled: false },
  //   { id: '', name: 'Модуль интеллектуальной транспортной системы', enabled: false },
  // ],
  // eputsSubsystems: [
  //   { id: 'tlc', name: 'EPUTS_SUBSYSTEMS.TLC' },
  //   { id: 'tfms', name: 'EPUTS_SUBSYSTEMS.TFMS' },
  //   { id: '', name: '' },
  //   { id: '', name: '' },
  //   { id: '', name: '' },
  // ],

  // About
  aboutModules: [
    // { id: 'Msu.Its.Tfms', version: '0.8.25.0', buildTime: '2022-11-25' },
    // { id: 'Msu.Its.Parking', version: '0.1.13.0', buildTime: '2022-11-25' },
    // { id: 'Msu.Its.CoordControl', version: '0.6.49.0', buildTime: '2023-05-23' },
    // { id: 'Msu.Its.Vsae', version: '0.8.25.0', buildTime: '2023-05-23' },
    // { id: 'Msu.Its.Ptm', version: '0.0.4.0', buildTime: '2022-11-25' },
    // { id: 'Msu.Its.Ptm2', version: '0.0.4.0', buildTime: '2022-11-25' },
    // { id: 'Msu.Its.Ptm3', version: '0.0.4.0', buildTime: '2022-11-25' },
    // { id: 'Msu.Its.Vsae', version: '0.0.4.0', buildTime: '2022-11-25' }, // Video Surveillance of Accidents and Emergencies (VSAE) module
  ],
  // aboutModulesExclude: ['Msu.Its.Parking'],

  // tempSidenav: [
  //   {
  //     name: 'Видеонаблюдение, ДТП и ЧС',
  //     items: [
  //       {
  //         name: 'Cобытия',
  //         icon: 'report_problem',
  //         route: 'video-accidents-tmp',
  //         permissions: [],
  //       },
  //     ],
  //   },
  // ],
};
