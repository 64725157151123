import { Injectable } from '@angular/core';

import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import {
  getPageReturn,
  IDataSourceService,
  IPageParams,
  IPageReturn,
  setPageParams,
} from 'projects/msu-its-web-common/src/data/data-source';
import { TfmsApiService } from './tfms-api.service';
import { TokenService } from 'projects/msu-its-web-common/src/services/token.service';
import {
  CreateCameraGroupRequest,
  CreateDetectorGroupRequest,
  DeleteCameraGroupsRequest,
  DeleteDetectorGroupsRequest,
  EntityGroup,
  EntityGroupInfo,
  GetCameraGroupRequest,
  GetCameraGroupsRequest,
  GetDetectorGroupRequest,
  GetDetectorGroupsRequest,
  GetItemEntityGroupsRequest,
  UpdateCameraGroupRequest,
  UpdateDetectorGroupRequest,
} from '../dtos/tfms.dtos';
import { IGisObjectGroupService } from 'projects/msu-its-web-common/src/utils/gis-object-group';
import { CameraService } from './camera.service';

@Injectable()
export class CameraGroupService
  implements IDataSourceService<EntityGroup, EntityGroupInfo>, IGisObjectGroupService {
  constructor(
    private _api: TfmsApiService,
    private _tokenService: TokenService,
    private _cameraService: CameraService
  ) {}

  permissions = ['camera.read'];

  getObjectGroups(objectId: string, customerId: string) {
    const request = new GetItemEntityGroupsRequest({
      id: objectId,
      customerId,
    });
    return this._api.get(request).pipe(map((m) => m.items));
  }

  getPagedList(params: IPageParams, customerId: string): Observable<IPageReturn<EntityGroupInfo>> {
    if (!customerId) {
      return of(null);
    }

    const request = new GetCameraGroupsRequest();
    request.customerId = customerId;

    setPageParams(request, params);
    return this._api.get(request).pipe(
      map((m) => {
        return getPageReturn(m);
      })
    );
  }

  getList(customerId: string, filter?: string, owned = false) {
    const request = new GetCameraGroupsRequest();
    request.customerId = customerId;

    request.page = 0;
    request.items = 0;
    request.sort = 'name';
    request.filter = filter;
    request.onlyOwnedItems = owned;

    return this._api.get(request).pipe(
      map((m) => {
        return m.items;
      })
    );
  }

  getGroupList(customerId: string) {
    return this.getList(customerId);
  }

  getObjectList(customerId: string, groupIds: string[]) {
    return this._cameraService.getList(customerId, null, groupIds);
  }

  get(id: string, customerId: string) {
    const request = new GetCameraGroupRequest();
    request.id = id;
    request.customerId = customerId;
    return this._api.get(request);
  }

  add(model: EntityGroup, params: { customerId: string }) {
    const request = new CreateCameraGroupRequest();
    request.customerId = params.customerId;
    request.entity = model;
    return this._api.post(request);
  }

  update(model: EntityGroup, params: { customerId: string }) {
    const request = new UpdateCameraGroupRequest();
    request.id = model.id;
    request.entity = model;
    request.customerId = params.customerId;
    return this._api.put(request);
  }

  delete(ids: string[], customerId: string): Observable<number> {
    const request = new DeleteCameraGroupsRequest();
    request.ids = ids;
    request.customerId = customerId;
    return this._api.delete(request);
  }

  exist(name: string, customerId: string) {
    return of(false);
  }
}
