import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';

import { Component, ChangeDetectionStrategy, Injector } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

import { DataSourceForm } from '../../data/data-source-form';
import { Customer, CustomerInfo } from '../../dtos/hub.dtos';

import { CustomerService } from '../../services/customer.service';
import { TableBaseComponent } from '../tables/table-base.component';
import { CustomerEditComponent } from './customer-edit.component';

@Component({
  selector: 'customers-component',
  templateUrl: './customers.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomersComponent extends TableBaseComponent<
  Customer,
  CustomerInfo
> {
  displayedColumns = ['select', 'name', 'actions'];

  rowActions = [
    { action: 'edit', name: _('COMMON.EDIT'), icon: 'edit', permissions: [] },
    { action: 'delete', name: _('COMMON.DELETE'), icon: 'delete', permissions: [] },
  ];

  msgAddSaved = _('CUSTOMERS.CUSTOMER_ADDED');
  msgEditSaved = _('COMMON.CHANGES_SAVED');
  msgDeleteConfirm = _('CUSTOMERS.CUSTOMER_DELETE?');
  msgDeleteSuccess = _('CUSTOMERS.CUSTOMER_DELETED');
  msgDeleteSelectedConfirm = _('CUSTOMERS.CUSTOMERS_DELETE?');
  msgDeleteSelectedSuccess = _('CUSTOMERS.CUSTOMERS_DELETED');

  constructor(injector: Injector) {
    super(injector, injector.get(CustomerService));
  }

  getNew() {
    return new Customer({ timeZoneId: 'UTC' });
  }

  getEdit(id) {
    return this.dataService.get(id);
  }

  getEditDialog(): MatDialogRef<DataSourceForm<Customer>, any> {
    return this.dialogService.dialog.open(CustomerEditComponent, {
      disableClose: true,
    });
  }
}
