/* Options:
Date: 2021-08-30 03:44:58
Version: 5.110
Tip: To override a DTO option, remove "//" prefix before updating
BaseUrl: https://gis.dev.its.msu24.ru

//GlobalNamespace: 
//MakePropertiesOptional: False
//AddServiceStackTypes: True
//AddResponseStatus: False
//AddImplicitVersion: 
//AddDescriptionAsComments: True
//IncludeTypes: 
//ExcludeTypes: 
//DefaultImports: 
*/

export interface IReturn<T> {
  createResponse(): T;
}

export interface IReturnVoid {
  createResponse(): void;
}

export interface IHasSessionId {
  sessionId: string;
}

export interface IHasBearerToken {
  bearerToken: string;
}

export interface IPost {}

// @DataContract
export class ApplicationInfo implements INamed, IDescription {
  // @DataMember
  public id: string;

  // @DataMember
  public name: string;

  // @DataMember
  public description: string;

  // @DataMember
  public instance: string;

  // @DataMember
  public version: string;

  // @DataMember
  public buildTime: string;

  public constructor(init?: Partial<ApplicationInfo>) {
    (Object as any).assign(this, init);
  }
}

export interface ICustomerItemRequest extends ICustomerEntity {}

export interface ICustomerEntity {
  // @DataMember
  customerId: string;
}

export interface ICustomerIntegrationIdRequest {
  customerIntegrationId: string;
}

export interface IBaseEntityItemRequest {
  id: string;
}

export interface IDescription {
  // @DataMember
  description: string;
}

// @DataContract
export class EntityGroup
  implements ICustomerEntityViewModel, INamedEntity, IChangeKey, IDescription, IModified, IEntity
{
  // @DataMember
  public items: string[];

  // @DataMember
  public customerId: string;

  // @DataMember
  public customerName: string;

  // @DataMember
  public name: string;

  // @DataMember
  public description: string;

  // @DataMember
  public changeKey: string;

  // @DataMember
  public created: string;

  // @DataMember
  public createdBy: string;

  // @DataMember
  public modified: string;

  // @DataMember
  public modifiedBy: string;

  // @DataMember
  public id: string;

  // @DataMember
  public entityType: string;

  public constructor(init?: Partial<EntityGroup>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class GeoObjectInfo
  implements ICustomerEntityViewModel, INamedEntity, IChangeKey, IDescription, IModified, IEntity
{
  // @DataMember
  public image: string;

  // @DataMember
  public geoObjectTypeId: string;

  // @DataMember
  public geoObjectTypeName: string;

  // @DataMember
  public validFrom?: string;

  // @DataMember
  public validTo?: string;

  // @DataMember
  public customerId: string;

  // @DataMember
  public customerName: string;

  // @DataMember
  public name: string;

  // @DataMember
  public description: string;

  // @DataMember
  public changeKey: string;

  // @DataMember
  public created: string;

  // @DataMember
  public createdBy: string;

  // @DataMember
  public modified: string;

  // @DataMember
  public modifiedBy: string;

  // @DataMember
  public id: string;

  // @DataMember
  public entityType: string;

  public constructor(init?: Partial<GeoObjectInfo>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class Coordinate {
  // @DataMember
  public lat: number;

  // @DataMember
  public lon: number;

  public constructor(init?: Partial<Coordinate>) {
    (Object as any).assign(this, init);
  }
}

export interface IEntity {}

export interface IEntityTyped {
  // @DataMember
  entityType: string;
}

export interface IChangeKey extends IReadonlyChangeKey {
  // @DataMember
  changeKey: string;
}

export interface IReadonlyChangeKey {
  // @DataMember
  changeKey: string;
}

export interface IModified {
  // @DataMember
  modifiedBy: string;

  // @DataMember
  modified: string;
}

// @DataContract
export class EntityGroupInfo
  implements ICustomerEntityViewModel, INamedEntity, IChangeKey, IDescription, IModified, IEntity
{
  // @DataMember
  public itemsCount: number;

  // @DataMember
  public customerId: string;

  // @DataMember
  public customerName: string;

  // @DataMember
  public name: string;

  // @DataMember
  public description: string;

  // @DataMember
  public changeKey: string;

  // @DataMember
  public created: string;

  // @DataMember
  public createdBy: string;

  // @DataMember
  public modified: string;

  // @DataMember
  public modifiedBy: string;

  // @DataMember
  public id: string;

  // @DataMember
  public entityType: string;

  public constructor(init?: Partial<EntityGroupInfo>) {
    (Object as any).assign(this, init);
  }
}

export class CustomerNamedBaseEntityViewModelBase
  implements ICustomerEntityViewModel, INamedEntity, IChangeKey, IDescription, IModified, IEntity
{
  // @DataMember
  public customerId: string;

  // @DataMember
  public customerName: string;

  // @DataMember
  public name: string;

  // @DataMember
  public description: string;

  // @DataMember
  public changeKey: string;

  // @DataMember
  public created: string;

  // @DataMember
  public createdBy: string;

  // @DataMember
  public modified: string;

  // @DataMember
  public modifiedBy: string;

  // @DataMember
  public id: string;

  // @DataMember
  public entityType: string;

  public constructor(init?: Partial<CustomerNamedBaseEntityViewModelBase>) {
    (Object as any).assign(this, init);
  }
}

export interface INamedEntity extends IEntity, INamed {}

export interface INamed {
  // @DataMember
  name: string;
}

export interface ICustomerEntityViewModel extends ICustomerName, ICustomerEntity {}

export interface ICustomerName {
  // @DataMember
  customerName: string;
}

// @DataContract
export enum GeometryType {
  Point = 'Point',
  LineString = 'LineString',
  Polygon = 'Polygon',
}

// @DataContract
export class ResponseError {
  // @DataMember(Order=1)
  public errorCode: string;

  // @DataMember(Order=2)
  public fieldName: string;

  // @DataMember(Order=3)
  public message: string;

  // @DataMember(Order=4)
  public meta: { [index: string]: string };

  public constructor(init?: Partial<ResponseError>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class ResponseStatus {
  // @DataMember(Order=1)
  public errorCode: string;

  // @DataMember(Order=2)
  public message: string;

  // @DataMember(Order=3)
  public stackTrace: string;

  // @DataMember(Order=4)
  public errors: ResponseError[];

  // @DataMember(Order=5)
  public meta: { [index: string]: string };

  public constructor(init?: Partial<ResponseStatus>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class EntityIntegration implements IChangeKey, IDescription, IModified, IEntity {
  // @DataMember
  public entityId: string;

  // @DataMember
  public customerIntegrationId: string;

  // @DataMember
  public externalId: string;

  // @DataMember
  public settings: string;

  // @DataMember
  public integrationId: string;

  // @DataMember
  public integrationName: string;

  // @DataMember
  public integrationType: string;

  // @DataMember
  public description: string;

  // @DataMember
  public changeKey: string;

  // @DataMember
  public created: string;

  // @DataMember
  public createdBy: string;

  // @DataMember
  public modified: string;

  // @DataMember
  public modifiedBy: string;

  // @DataMember
  public id: string;

  // @DataMember
  public entityType: string;

  public constructor(init?: Partial<EntityIntegration>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class GetEntityIntegrationResponse {
  // @DataMember
  public items: EntityIntegration[];

  // @DataMember
  public total: number;

  // @DataMember
  public page: number;

  // @DataMember
  public itemsPerPage: number;

  public constructor(init?: Partial<GetEntityIntegrationResponse>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class GetEntityGroupsResponse {
  // @DataMember
  public items: EntityGroupInfo[];

  // @DataMember
  public total: number;

  // @DataMember
  public page: number;

  // @DataMember
  public itemsPerPage: number;

  public constructor(init?: Partial<GetEntityGroupsResponse>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class GetGeoObjectsResponse {
  // @DataMember
  public items: GeoObjectInfo[];

  // @DataMember
  public total: number;

  // @DataMember
  public page: number;

  // @DataMember
  public itemsPerPage: number;

  public constructor(init?: Partial<GetGeoObjectsResponse>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class GeoObject
  implements ICustomerEntityViewModel, INamedEntity, IChangeKey, IDescription, IModified, IEntity
{
  // @DataMember
  public geoJson: string;

  // @DataMember
  public geoObjectTypeId: string;

  // @DataMember
  public geoObjectTypeName: string;

  // @DataMember
  public image: string;

  // @DataMember
  public validFrom?: string;

  // @DataMember
  public validTo?: string;

  // @DataMember
  public customerId: string;

  // @DataMember
  public customerName: string;

  // @DataMember
  public name: string;

  // @DataMember
  public description: string;

  // @DataMember
  public changeKey: string;

  // @DataMember
  public created: string;

  // @DataMember
  public createdBy: string;

  // @DataMember
  public modified: string;

  // @DataMember
  public modifiedBy: string;

  // @DataMember
  public id: string;

  // @DataMember
  public entityType: string;

  public constructor(init?: Partial<GeoObject>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class GetGeoObjectsDetailsResponse {
  // @DataMember
  public items: GeoObject[];

  // @DataMember
  public total: number;

  // @DataMember
  public page: number;

  // @DataMember
  public itemsPerPage: number;

  public constructor(init?: Partial<GetGeoObjectsDetailsResponse>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class GeoObjectType
  implements ICustomerEntityViewModel, INamedEntity, IChangeKey, IDescription, IModified, IEntity
{
  // @DataMember
  public defaultImageId: string;

  // @DataMember
  public geometryType: GeometryType[];

  // @DataMember
  public style: string;

  // @DataMember
  public collection: boolean;

  // @DataMember
  public customerId: string;

  // @DataMember
  public customerName: string;

  // @DataMember
  public name: string;

  // @DataMember
  public description: string;

  // @DataMember
  public changeKey: string;

  // @DataMember
  public created: string;

  // @DataMember
  public createdBy: string;

  // @DataMember
  public modified: string;

  // @DataMember
  public modifiedBy: string;

  // @DataMember
  public id: string;

  // @DataMember
  public entityType: string;

  public constructor(init?: Partial<GeoObjectType>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class GetGeoObjectTypesResponse {
  // @DataMember
  public items: GeoObjectType[];

  // @DataMember
  public total: number;

  // @DataMember
  public page: number;

  // @DataMember
  public itemsPerPage: number;

  public constructor(init?: Partial<GetGeoObjectTypesResponse>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class AuthenticateResponse implements IHasSessionId, IHasBearerToken {
  // @DataMember(Order=1)
  public userId: string;

  // @DataMember(Order=2)
  public sessionId: string;

  // @DataMember(Order=3)
  public userName: string;

  // @DataMember(Order=4)
  public displayName: string;

  // @DataMember(Order=5)
  public referrerUrl: string;

  // @DataMember(Order=6)
  public bearerToken: string;

  // @DataMember(Order=7)
  public refreshToken: string;

  // @DataMember(Order=8)
  public profileUrl: string;

  // @DataMember(Order=9)
  public roles: string[];

  // @DataMember(Order=10)
  public permissions: string[];

  // @DataMember(Order=11)
  public responseStatus: ResponseStatus;

  // @DataMember(Order=12)
  public meta: { [index: string]: string };

  public constructor(init?: Partial<AuthenticateResponse>) {
    (Object as any).assign(this, init);
  }
}

// @Route("/validateToken", "GET")
// @DataContract
export class ValidateItsTokenRequest implements IReturn<boolean> {
  // @DataMember
  public application: ApplicationInfo;

  public constructor(init?: Partial<ValidateItsTokenRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return false;
  }
  public getTypeName() {
    return 'ValidateItsTokenRequest';
  }
}

// @Route("/healthStatus", "GET")
// @DataContract
export class GetHealthStatusRequest implements IReturn<string> {
  public constructor(init?: Partial<GetHealthStatusRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return '';
  }
  public getTypeName() {
    return 'GetHealthStatusRequest';
  }
}

// @Route("/customer/{customerId}/integration/{customerIntegrationId}/entities", "GET")
// @DataContract
export class GetEntityIntegrationsRequest
  implements
    IReturn<GetEntityIntegrationResponse>,
    ICustomerIntegrationIdRequest,
    ICustomerItemRequest
{
  // @DataMember
  public customerIntegrationId: string;

  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public entityGroups: string[];

  // @DataMember
  public itemsIds: string[];

  // @DataMember
  public onlyOwnedItems: boolean;

  // @DataMember
  public page: number;

  // @DataMember
  public items: number;

  // @DataMember
  public sort: string;

  // @DataMember
  public filter: string;

  public constructor(init?: Partial<GetEntityIntegrationsRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new GetEntityIntegrationResponse();
  }
  public getTypeName() {
    return 'GetEntityIntegrationsRequest';
  }
}

// @Route("/customer/{customerId}/integration/{customerIntegrationId}/entity/{id}", "GET")
// @DataContract
export class GetEntityIntegrationRequest
  implements
    IReturn<EntityIntegration>,
    ICustomerIntegrationIdRequest,
    ICustomerItemRequest,
    IBaseEntityItemRequest
{
  // @DataMember
  public customerIntegrationId: string;

  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember(IsRequired=true)
  public id: string;

  public constructor(init?: Partial<GetEntityIntegrationRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new EntityIntegration();
  }
  public getTypeName() {
    return 'GetEntityIntegrationRequest';
  }
}

// @Route("/customer/{customerId}/integration/{customerIntegrationId}/entities", "POST")
// @DataContract
export class CreateEntityIntegrationRequest
  implements
    IReturn<EntityIntegration>,
    ICustomerIntegrationIdRequest,
    ICustomerItemRequest,
    IDescription
{
  // @DataMember
  public customerIntegrationId: string;

  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public updateEntityGroups: boolean;

  // @DataMember
  public entityGroups: string[];

  // @DataMember(IsRequired=true)
  public entity: EntityIntegration;

  // @DataMember
  public description: string;

  public constructor(init?: Partial<CreateEntityIntegrationRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new EntityIntegration();
  }
  public getTypeName() {
    return 'CreateEntityIntegrationRequest';
  }
}

// @Route("/customer/{customerId}/integration/{customerIntegrationId}/entity/{id}", "PUT")
// @DataContract
export class UpdateEntityIntegrationRequest
  implements
    IReturn<EntityIntegration>,
    ICustomerIntegrationIdRequest,
    ICustomerItemRequest,
    IBaseEntityItemRequest,
    IDescription
{
  // @DataMember
  public customerIntegrationId: string;

  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public updateEntityGroups: boolean;

  // @DataMember
  public entityGroups: string[];

  // @DataMember(IsRequired=true)
  public id: string;

  // @DataMember(IsRequired=true)
  public entity: EntityIntegration;

  // @DataMember
  public description: string;

  public constructor(init?: Partial<UpdateEntityIntegrationRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new EntityIntegration();
  }
  public getTypeName() {
    return 'UpdateEntityIntegrationRequest';
  }
}

// @Route("/entity/{id}/properties", "GET")
// @DataContract
export class GetEntityPropertiesRequest implements IReturn<any>, IBaseEntityItemRequest {
  // @DataMember(IsRequired=true)
  public id: string;

  public constructor(init?: Partial<GetEntityPropertiesRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return {};
  }
  public getTypeName() {
    return 'GetEntityPropertiesRequest';
  }
}

// @Route("/entity/{id}/properties", "PUT")
// @DataContract
export class UpdateEntityPropertiesRequest implements IReturn<any> {
  // @DataMember(IsRequired=true)
  public id: string;

  // @DataMember
  public description: string;

  // @DataMember
  public entity: { [index: string]: string };

  public constructor(init?: Partial<UpdateEntityPropertiesRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return {};
  }
  public getTypeName() {
    return 'UpdateEntityPropertiesRequest';
  }
}

// @Route("/entity/{id}/property/{property}", "GET")
// @DataContract
export class GetEntityPropertyRequest implements IReturn<string> {
  // @DataMember
  public property: string;

  // @DataMember(IsRequired=true)
  public id: string;

  public constructor(init?: Partial<GetEntityPropertyRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return '';
  }
  public getTypeName() {
    return 'GetEntityPropertyRequest';
  }
}

// @Route("/entity/{id}/property/{property}", "PUT")
// @DataContract
export class UpdateEntityPropertyRequest implements IReturn<string> {
  // @DataMember(IsRequired=true)
  public id: string;

  // @DataMember
  public description: string;

  // @DataMember
  public property: string;

  // @DataMember
  public value: string;

  public constructor(init?: Partial<UpdateEntityPropertyRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return '';
  }
  public getTypeName() {
    return 'UpdateEntityPropertyRequest';
  }
}

// @Route("/customer/{customerId}/entities/property/{property}", "GET")
// @DataContract
export class GetEntitiesPropertyRequest implements IReturn<any> {
  // @DataMember
  public property: string;

  // @DataMember
  public filter: string;

  // @DataMember
  public customerId: string;

  public constructor(init?: Partial<GetEntitiesPropertyRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return {};
  }
  public getTypeName() {
    return 'GetEntitiesPropertyRequest';
  }
}

// @Route("/customer/{customerId}/entity/{Id}/groups", "GET")
// @DataContract
export class GetItemEntityGroupsRequest
  implements IReturn<GetEntityGroupsResponse>, ICustomerItemRequest
{
  // @DataMember
  public id: string;

  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public entityGroups: string[];

  // @DataMember
  public itemsIds: string[];

  // @DataMember
  public onlyOwnedItems: boolean;

  // @DataMember
  public page: number;

  // @DataMember
  public items: number;

  // @DataMember
  public sort: string;

  // @DataMember
  public filter: string;

  public constructor(init?: Partial<GetItemEntityGroupsRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new GetEntityGroupsResponse();
  }
  public getTypeName() {
    return 'GetItemEntityGroupsRequest';
  }
}

// @Route("/customer/{customerId}/geoObjects/groups", "GET")
// @DataContract
export class GetGeoObjectGroupsRequest
  implements IReturn<GetEntityGroupsResponse>, ICustomerItemRequest
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public entityGroups: string[];

  // @DataMember
  public itemsIds: string[];

  // @DataMember
  public onlyOwnedItems: boolean;

  // @DataMember
  public page: number;

  // @DataMember
  public items: number;

  // @DataMember
  public sort: string;

  // @DataMember
  public filter: string;

  public constructor(init?: Partial<GetGeoObjectGroupsRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new GetEntityGroupsResponse();
  }
  public getTypeName() {
    return 'GetGeoObjectGroupsRequest';
  }
}

// @Route("/customer/{customerId}/geoObjects/groups/{id}", "GET")
// @DataContract
export class GetGeoObjectGroupRequest
  implements IReturn<EntityGroup>, ICustomerItemRequest, IBaseEntityItemRequest
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember(IsRequired=true)
  public id: string;

  public constructor(init?: Partial<GetGeoObjectGroupRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new EntityGroup();
  }
  public getTypeName() {
    return 'GetGeoObjectGroupRequest';
  }
}

// @Route("/customer/{customerId}/geoObjects/groups", "POST")
// @DataContract
export class CreateGeoObjectGroupRequest
  implements IReturn<EntityGroup>, ICustomerItemRequest, IDescription
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public updateEntityGroups: boolean;

  // @DataMember
  public entityGroups: string[];

  // @DataMember(IsRequired=true)
  public entity: EntityGroup;

  // @DataMember
  public description: string;

  public constructor(init?: Partial<CreateGeoObjectGroupRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new EntityGroup();
  }
  public getTypeName() {
    return 'CreateGeoObjectGroupRequest';
  }
}

// @Route("/customer/{customerId}/geoObjects/groups/{id}", "PUT,POST")
// @DataContract
export class UpdateGeoObjectGroupRequest
  implements IReturn<EntityGroup>, ICustomerItemRequest, IBaseEntityItemRequest, IDescription
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public updateEntityGroups: boolean;

  // @DataMember
  public entityGroups: string[];

  // @DataMember(IsRequired=true)
  public id: string;

  // @DataMember(IsRequired=true)
  public entity: EntityGroup;

  // @DataMember
  public description: string;

  public constructor(init?: Partial<UpdateGeoObjectGroupRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new EntityGroup();
  }
  public getTypeName() {
    return 'UpdateGeoObjectGroupRequest';
  }
}

// @Route("/customer/{customerId}/geoObjects/groups", "DELETE")
// @DataContract
export class DeleteGeoObjectGroupsRequest
  implements IReturn<number>, ICustomerItemRequest, IDescription
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public description: string;

  // @DataMember(IsRequired=true)
  public ids: string[];

  public constructor(init?: Partial<DeleteGeoObjectGroupsRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return 0;
  }
  public getTypeName() {
    return 'DeleteGeoObjectGroupsRequest';
  }
}

// @Route("/customer/{customerId}/geoObjects", "GET")
// @DataContract
export class GetGeoObjectsRequest implements IReturn<GetGeoObjectsResponse>, ICustomerItemRequest {
  // @DataMember
  public onlyValid: boolean;

  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public entityGroups: string[];

  // @DataMember
  public itemsIds: string[];

  // @DataMember
  public onlyOwnedItems: boolean;

  // @DataMember
  public page: number;

  // @DataMember
  public items: number;

  // @DataMember
  public sort: string;

  // @DataMember
  public filter: string;

  public constructor(init?: Partial<GetGeoObjectsRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new GetGeoObjectsResponse();
  }
  public getTypeName() {
    return 'GetGeoObjectsRequest';
  }
}

// @Route("/customer/{customerId}/geoObjectsDetails", "GET")
// @DataContract
export class GetGeoObjectsDetailsRequest
  implements IReturn<GetGeoObjectsDetailsResponse>, ICustomerItemRequest
{
  // @DataMember
  public onlyValid: boolean;

  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public entityGroups: string[];

  // @DataMember
  public itemsIds: string[];

  // @DataMember
  public onlyOwnedItems: boolean;

  // @DataMember
  public page: number;

  // @DataMember
  public items: number;

  // @DataMember
  public sort: string;

  // @DataMember
  public filter: string;

  public constructor(init?: Partial<GetGeoObjectsDetailsRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new GetGeoObjectsDetailsResponse();
  }
  public getTypeName() {
    return 'GetGeoObjectsDetailsRequest';
  }
}

// @Route("/customer/{customerId}/geoObject/{id}", "GET")
// @DataContract
export class GetGeoObjectRequest
  implements IReturn<GeoObject>, ICustomerItemRequest, IBaseEntityItemRequest
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember(IsRequired=true)
  public id: string;

  public constructor(init?: Partial<GetGeoObjectRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new GeoObject();
  }
  public getTypeName() {
    return 'GetGeoObjectRequest';
  }
}

// @Route("/customer/{customerId}/geoObject", "POST")
// @DataContract
export class CreateGeoObjectRequest
  implements IReturn<GeoObject>, ICustomerItemRequest, IDescription
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public updateEntityGroups: boolean;

  // @DataMember
  public entityGroups: string[];

  // @DataMember(IsRequired=true)
  public entity: GeoObject;

  // @DataMember
  public description: string;

  public constructor(init?: Partial<CreateGeoObjectRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new GeoObject();
  }
  public getTypeName() {
    return 'CreateGeoObjectRequest';
  }
}

// @Route("/customer/{customerId}/geoObject/{id}", "PUT")
// @DataContract
export class UpdateGeoObjectRequest
  implements IReturn<GeoObject>, ICustomerItemRequest, IBaseEntityItemRequest, IDescription
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public updateEntityGroups: boolean;

  // @DataMember
  public entityGroups: string[];

  // @DataMember(IsRequired=true)
  public id: string;

  // @DataMember(IsRequired=true)
  public entity: GeoObject;

  // @DataMember
  public description: string;

  public constructor(init?: Partial<UpdateGeoObjectRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new GeoObject();
  }
  public getTypeName() {
    return 'UpdateGeoObjectRequest';
  }
}

// @Route("/customer/{customerId}/geoObjects", "DELETE")
// @DataContract
export class DeleteGeoObjectsRequest
  implements IReturn<number>, ICustomerItemRequest, IDescription
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public description: string;

  // @DataMember(IsRequired=true)
  public ids: string[];

  public constructor(init?: Partial<DeleteGeoObjectsRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return 0;
  }
  public getTypeName() {
    return 'DeleteGeoObjectsRequest';
  }
}

// @Route("/customer/{customerId}/geoObjectTypes", "GET")
// @DataContract
export class GetGeoObjectTypesRequest
  implements IReturn<GetGeoObjectTypesResponse>, ICustomerItemRequest
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public entityGroups: string[];

  // @DataMember
  public itemsIds: string[];

  // @DataMember
  public onlyOwnedItems: boolean;

  // @DataMember
  public page: number;

  // @DataMember
  public items: number;

  // @DataMember
  public sort: string;

  // @DataMember
  public filter: string;

  public constructor(init?: Partial<GetGeoObjectTypesRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new GetGeoObjectTypesResponse();
  }
  public getTypeName() {
    return 'GetGeoObjectTypesRequest';
  }
}

// @Route("/customer/{customerId}/geoObjectType/{id}", "GET")
// @DataContract
export class GetGeoObjectTypeRequest
  implements IReturn<GeoObjectType>, ICustomerItemRequest, IBaseEntityItemRequest
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember(IsRequired=true)
  public id: string;

  public constructor(init?: Partial<GetGeoObjectTypeRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new GeoObjectType();
  }
  public getTypeName() {
    return 'GetGeoObjectTypeRequest';
  }
}

// @Route("/customer/{customerId}/geoObjectType", "POST")
// @DataContract
export class CreateGeoObjectTypeRequest
  implements IReturn<GeoObjectType>, ICustomerItemRequest, IDescription
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public updateEntityGroups: boolean;

  // @DataMember
  public entityGroups: string[];

  // @DataMember(IsRequired=true)
  public entity: GeoObjectType;

  // @DataMember
  public description: string;

  public constructor(init?: Partial<CreateGeoObjectTypeRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new GeoObjectType();
  }
  public getTypeName() {
    return 'CreateGeoObjectTypeRequest';
  }
}

// @Route("/customer/{customerId}/geoObjectType/{id}", "PUT")
// @DataContract
export class UpdateGeoObjectTypeRequest
  implements IReturn<GeoObjectType>, ICustomerItemRequest, IBaseEntityItemRequest, IDescription
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public updateEntityGroups: boolean;

  // @DataMember
  public entityGroups: string[];

  // @DataMember(IsRequired=true)
  public id: string;

  // @DataMember(IsRequired=true)
  public entity: GeoObjectType;

  // @DataMember
  public description: string;

  public constructor(init?: Partial<UpdateGeoObjectTypeRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new GeoObjectType();
  }
  public getTypeName() {
    return 'UpdateGeoObjectTypeRequest';
  }
}

// @Route("/customer/{customerId}/geoObjectTypes", "DELETE")
// @DataContract
export class DeleteGeoObjectTypesRequest
  implements IReturn<number>, ICustomerItemRequest, IDescription
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public description: string;

  // @DataMember(IsRequired=true)
  public ids: string[];

  public constructor(init?: Partial<DeleteGeoObjectTypesRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return 0;
  }
  public getTypeName() {
    return 'DeleteGeoObjectTypesRequest';
  }
}

// @Route("/route", "POST")
// @DataContract
export class GetRouteRequest implements IReturn<string> {
  // @DataMember
  public start: Coordinate;

  // @DataMember
  public finish: Coordinate;

  public constructor(init?: Partial<GetRouteRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return '';
  }
  public getTypeName() {
    return 'GetRouteRequest';
  }
}

// @Route("/auth")
// @Route("/auth/{provider}")
// @DataContract
export class Authenticate implements IReturn<AuthenticateResponse>, IPost {
  // @DataMember(Order=1)
  public provider: string;

  // @DataMember(Order=2)
  public state: string;

  // @DataMember(Order=3)
  public oauth_token: string;

  // @DataMember(Order=4)
  public oauth_verifier: string;

  // @DataMember(Order=5)
  public userName: string;

  // @DataMember(Order=6)
  public password: string;

  // @DataMember(Order=7)
  public rememberMe?: boolean;

  // @DataMember(Order=9)
  public errorView: string;

  // @DataMember(Order=10)
  public nonce: string;

  // @DataMember(Order=11)
  public uri: string;

  // @DataMember(Order=12)
  public response: string;

  // @DataMember(Order=13)
  public qop: string;

  // @DataMember(Order=14)
  public nc: string;

  // @DataMember(Order=15)
  public cnonce: string;

  // @DataMember(Order=16)
  public useTokenCookie?: boolean;

  // @DataMember(Order=17)
  public accessToken: string;

  // @DataMember(Order=18)
  public accessTokenSecret: string;

  // @DataMember(Order=19)
  public scope: string;

  // @DataMember(Order=20)
  public meta: { [index: string]: string };

  public constructor(init?: Partial<Authenticate>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new AuthenticateResponse();
  }
  public getTypeName() {
    return 'Authenticate';
  }
}
