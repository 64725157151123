<div
  fxLayout="row"
  fxLayoutAlign="space-between center"
  style="padding-bottom: 16px;"
>
  <h3
    style="
      margin: 0;
      font-weight: 500;
      font-size: 15px;
      line-height: 1;
      flex: 1;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    "
  >
    <span>{{model?.address}}</span>
  </h3>
  <div>
    <div fxLayout style="margin: -4px; padding-left: 12px;">
      <!-- <button *ngIf="openInNew" mat-icon-button title="{{ 'COMMON.OPEN_IN_NEW_WINDOW' | translate }}" (click)="openNewWindow()">
        <mat-icon>open_in_new</mat-icon>
      </button>
      <button mat-icon-button type="button" *ngIf="actions && actions.length" [matMenuTriggerFor]="actionsMenu">
        <mat-icon>more_vert</mat-icon>
      </button> -->
      <button mat-icon-button type="button">
        <mat-icon (click)="close()">close</mat-icon>
      </button>
    </div>
  </div>
</div>
<div
  style="flex: 1; padding: 0 0px 18px 0px; overflow: auto; overflow: overlay;"
  fxLayout="column"
  fxLayoutAlign="start stretch"
>
  <div *ngIf="loading" class="backdrop" style="overflow: hidden;">
    <mat-spinner diameter="36" color="accent"></mat-spinner>
  </div>
  <div
    fxFlex
    fxLayout="row"
    fxLayoutAlign="start center"
    style="background-color: #747474cc; padding: 8px;"
  >
    <span fxFlex="40%"> {{ "COMMON.DATE" | translate }}:</span>
    <span fxFlex>
      {{ model?.accidentDate | date: "dd.MM.yyyy HH:mm":timezone:locale }}</span
    >
  </div>
  <div fxFlex fxLayout="row" fxLayoutAlign="start center" style="padding: 8px;">
    <span fxFlex="40%">
      {{ "TRAFFIC_ACCIDENT.ACCIDENT_TYPE" | translate }}:
    </span>
    <span fxFlex>
      {{ trafficAccidentType[model?.accidentType] | translate }}</span
    >
  </div>

  <div
    fxFlex
    fxLayout="row"
    fxLayoutAlign="start center"
    style="background-color: #747474cc; padding: 8px;"
  >
    <span fxFlex="40%"> {{ "TRAFFIC_ACCIDENT.ADDRESS" | translate }}: </span>
    <span fxFlex> {{ model?.address}}</span>
  </div>
  <div fxFlex fxLayout="row" fxLayoutAlign="start center" style="padding: 8px;">
    <span fxFlex="40%">
      {{ "TRAFFIC_ACCIDENT.KILLED_COUNT" | translate }}:
    </span>
    <span fxFlex> {{ model?.killedCount}}</span>
  </div>

  <div
    fxFlex
    fxLayout="row"
    fxLayoutAlign="start center"
    style="background-color: #747474cc; padding: 8px;"
  >
    <span fxFlex="40%">
      {{ "TRAFFIC_ACCIDENT.INJURED_COUNT" | translate }}:
    </span>
    <span fxFlex> {{ model?.injuredCount}}</span>
  </div>

  <div
    *ngIf="model?.roadLighting"
    fxFlex
    fxLayout="row"
    fxLayoutAlign="start center"
    style="padding: 8px;"
  >
    <span fxFlex="40%">
      {{ "TRAFFIC_ACCIDENT.ROAD_LIGHTING" | translate }}:
    </span>
    <span fxFlex> {{ model?.roadLighting}}</span>
  </div>

  <div
    *ngIf="model?.roadState"
    fxFlex
    fxLayout="row"
    fxLayoutAlign="start center"
    style="padding: 8px;"
    [style.backgroundColor]="model?.roadLighting ? '#747474cc' : 'null' "
  >
    <span fxFlex="40%">
      {{ "TRAFFIC_ACCIDENT.ROAD_STATE" | translate }}:
    </span>
    <span fxFlex> {{ model?.roadState}}</span>
  </div>

  <div
    *ngIf="model?.description"
    fxFlex
    fxLayout="column"
    fxLayoutAlign="start start"
    style="padding: 8px;"
  >
    <span>{{ "COMMON.DESCRIPTION" | translate }}:</span>
    <span fxFlex> {{ model?.description }}</span>
  </div>
</div>
