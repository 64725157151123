import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';

import { NgModule } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { DatePipe } from '@angular/common';

import { environment } from 'projects/msu-its-web/src/environments/environment';

import { TlcApiService } from '../../services/tlc-api.service';
import { TlcApiEventService } from '../../services/tlc-api-event.service';

import { TpcApiService } from '../../services/tpc-api.service';
import { TpcApiEventService } from '../../services/tpc-api-event.service';

import { AdaptiveTrafficObjectService } from '../../services/adaptive-traffic-object.service';
import { TrafficObjectService } from '../../services/traffic-object.service';
import { TrafficObjectControllerService } from '../../services/traffic-object-controller.service';
import { TrafficObjectGroupService } from '../../services/traffic-object-group.service';
import { TrafficObjectGroupEventService } from '../../services/traffic-object-group-event.service';
import { TrafficObjectGroupShareService } from '../../services/traffic-object-group-share.service';
import { UsdkTrafficObjectService } from '../../services/usdk-traffic-object.service';
import { PotokTrafficObjectService } from '../../services/potok-traffic-object.service';
import { TrackingDeviceService } from '../../services/tracking-device.service';
import { ControlSessionService } from '../../services/control-session.service';

import { TrafficObjectEventLogService } from '../../services/traffic-object-event-log.service';
import { TrafficObjectGroupEventLogService } from '../../services/traffic-object-group-event-log.service';
import { TrafficObjectControllerEventLogService } from '../../services/traffic-object-controller-event-log.service';

import { ISidenavMenuCategory } from 'projects/msu-its-web-common/src/utils/sidenav-menu';
import { IGisObjectGroup } from 'projects/msu-its-web-common/src/utils/gis-object-group';
import {
  GIS_OBJECT_GROUP,
  SIDENAV_MENU,
} from 'projects/msu-its-web-common/src/utils/shared-consts';

import { BaseModule } from 'projects/msu-its-web-common/src/modules/base.module';
import { MovementsComponent } from '../../components/traffic-objects/movements.component';
import { TrafficObjectStateComponent } from '../../components/traffic-objects/traffic-object-state.component';

import { PotokStateComponent } from '../../components/traffic-objects/potok/state/potok-state.component';
import { PotokStateControllerComponent } from '../../components/traffic-objects/potok/state/potok-state-controller.component';
import { PotokStateButtonsComponent } from '../../components/traffic-objects/potok/state/potok-state-buttons.component';
import { PotokStateSchemaComponent } from '../../components/traffic-objects/potok/state/potok-state-schema.component';
import { PotokStateTimelogComponent } from '../../components/traffic-objects/potok/state/potok-state-timelog.component';
import { PotokStateModeComponent } from '../../components/traffic-objects/potok/state/potok-state-mode.component';
import { PotokStateAdaptiveComponent } from '../../components/traffic-objects/potok/state/potok-state-adaptive.component';
import { PotokSelectPhaseComponent } from '../../components/traffic-objects/potok/shared/potok-select-phase.component';
import { PotokSelectPhaseExtendedComponent } from '../../components/traffic-objects/potok/shared/extended/potok-select-phase-extended.component';

import { UsdkStateComponent } from '../../components/traffic-objects/usdk/state/usdk-state.component';
import { UsdkStateModeComponent } from '../../components/traffic-objects/usdk/state/usdk-state-mode.component';
import { UsdkStateButtonsComponent } from '../../components/traffic-objects/usdk/state/usdk-state-buttons.component';
import { UsdkStateSchemaComponent } from '../../components/traffic-objects/usdk/state/usdk-state-schema.component';
import { UsdkStateTimelogComponent } from '../../components/traffic-objects/usdk/state/usdk-state-timelog.component';
import { UsdkStateControllerComponent } from '../../components/traffic-objects/usdk/state/usdk-state-controller.component';
import { UsdkSelectPhaseComponent } from '../../components/traffic-objects/usdk/shared/usdk-select-phase.component';

import { RouteEscortComponent } from '../../components/route-escort/route-escort.component';
import { RouteEscortSelectComponent } from '../../components/route-escort/route-escort-select.component';
import { RouteEscortProgressComponent } from '../../components/route-escort/route-escort-progress.component';

import { RouteEscort2Component } from '../../components/route-escort2/route-escort2.component';
import { RouteEscortSelect2Component } from '../../components/route-escort2/route-escort-select2.component';
import { RouteEscortProgress2Component } from '../../components/route-escort2/route-escort-progress2.component';
import { RouteEscortSummary2Component } from '../../components/route-escort2/route-escort-summary2.component';

import { RouteService } from '../../services/route.service';
import { RouteGroupService } from '../../services/route-group.service';

const sidenav: ISidenavMenuCategory[] = [
  {
    name: _('SIDENAV_MENU.TLC'),
    items: [
      {
        name: _('TRAFFIC_OBJECTS.TITLE'),
        icon: 'traffic_light',
        route: 'traffic-objects',
        permissions: ['trafficObject.read'],
      },
      // {
      //   name: 'TEST',
      //   icon: 'devices',
      //   route: 'traffic-objects/controllers',
      //   permissions: ['trafficObject.read'],
      // },
    ],
  },
  ...(environment.modules?.tlc?.routing
    ? [
        {
          name: _('SIDENAV_MENU.TPC'),
          items: [
            {
              name: _('ROUTES.TITLE'),
              svgIcon: 'tlc-route',
              route: 'routes',
              permissions: ['route.read'],
            },
            {
              name: _('TRACKING_DEVICES.TITLE'),
              icon: 'navigation',
              route: 'tracking-devices',
              permissions: ['system.manage'],
            },
          ],
        },
      ]
    : []),
];

const trafficObjectGroup: IGisObjectGroup = {
  type: 'trafficObject',
  name: _('TRAFFIC_OBJECTS.TITLE'),
  groupServiceType: TrafficObjectGroupService,
  eventServiceType: TrafficObjectGroupEventService,
  stateComponentType: TrafficObjectStateComponent,
};

@NgModule({
  providers: [
    { provide: SIDENAV_MENU, useValue: sidenav, multi: true },
    { provide: GIS_OBJECT_GROUP, useValue: trafficObjectGroup, multi: true },
    TlcApiService,
    TlcApiEventService,
    TpcApiService,
    TpcApiEventService,
    AdaptiveTrafficObjectService,
    TrafficObjectService,
    TrafficObjectControllerService,
    TrafficObjectGroupService,
    TrafficObjectGroupEventService,
    TrafficObjectGroupShareService,
    UsdkTrafficObjectService,
    TrafficObjectEventLogService,
    TrafficObjectGroupEventLogService,
    TrafficObjectControllerEventLogService,
    PotokTrafficObjectService,
    RouteService,
    RouteGroupService,
    TrackingDeviceService,
    ControlSessionService,
    DatePipe,
  ],
  imports: [BaseModule],
  declarations: [
    MovementsComponent,
    TrafficObjectStateComponent,

    PotokStateComponent,
    PotokStateControllerComponent,
    PotokStateButtonsComponent,
    PotokStateSchemaComponent,
    PotokStateTimelogComponent,
    PotokStateModeComponent,
    PotokStateAdaptiveComponent,
    PotokSelectPhaseComponent,
    PotokSelectPhaseExtendedComponent,

    UsdkStateComponent,
    UsdkStateModeComponent,
    UsdkStateButtonsComponent,
    UsdkStateSchemaComponent,
    UsdkStateTimelogComponent,
    UsdkStateControllerComponent,
    UsdkSelectPhaseComponent,

    RouteEscortComponent,
    RouteEscortSelectComponent,
    RouteEscortProgressComponent,

    RouteEscort2Component,
    RouteEscortSelect2Component,
    RouteEscortProgress2Component,
    RouteEscortSummary2Component,
  ],
  entryComponents: [
    MovementsComponent,
    TrafficObjectStateComponent,

    PotokStateComponent,
    PotokStateControllerComponent,
    PotokStateButtonsComponent,
    PotokStateSchemaComponent,
    PotokStateTimelogComponent,
    PotokStateModeComponent,
    PotokSelectPhaseComponent,
    PotokSelectPhaseExtendedComponent,

    UsdkStateComponent,
    UsdkStateModeComponent,
    UsdkStateButtonsComponent,
    UsdkStateSchemaComponent,
    UsdkStateTimelogComponent,
    UsdkStateControllerComponent,
    UsdkSelectPhaseComponent,

    RouteEscortComponent,
    RouteEscortSelectComponent,
    RouteEscortProgressComponent,

    RouteEscort2Component,
    RouteEscortSelect2Component,
    RouteEscortProgress2Component,
    RouteEscortSummary2Component,
  ],
  exports: [
    MovementsComponent,
    TrafficObjectStateComponent,
    PotokStateComponent,
    PotokSelectPhaseComponent,
    PotokSelectPhaseExtendedComponent,
    UsdkStateComponent,
    UsdkSelectPhaseComponent,
  ],
})
export class TlcSharedModule {
  constructor(private _matIconRegistry: MatIconRegistry, private _domSanitizer: DomSanitizer) {
    this._registerIcon('tlc-route', '/assets/images/tlc/icons/route.svg');
    this._registerIcon('tlc-route-auto', '/assets/images/tlc/icons/route-auto.svg');
  }

  private _registerIcon(name: string, url: string) {
    this._matIconRegistry.addSvgIcon(name, this._domSanitizer.bypassSecurityTrustResourceUrl(url));
  }
}
