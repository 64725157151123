import { marker as _ } from "@biesbjerg/ngx-translate-extract-marker";

import { NgModule } from "@angular/core";
import { MatIconRegistry } from "@angular/material/icon";
import { DomSanitizer } from "@angular/platform-browser";
import { DatePipe } from "@angular/common";
import { ISidenavMenuCategory } from "projects/msu-its-web-common/src/utils/sidenav-menu";
import { SIDENAV_MENU } from "projects/msu-its-web-common/src/utils/shared-consts";
import { ModellingApiService } from "../../services/modelling-api.service";
import { ModellingApiEventService } from "../../services/modelling-api-event.service";
import { ModelingSceneService } from "../../services/scene/modelling-scene.service";

import { BaseModule } from "projects/msu-its-web-common/src/modules/base.module";

const sidenav: ISidenavMenuCategory[] = [
  {
    name: _("SIDENAV_MENU.MODELLING"),
    items: [
      {
        name: _("MODELLING.TITLE"),
        icon: "grain",
        route: "modelling",
        permissions: ["modellingScene.read"],
      },
    ],
  },
];

@NgModule({
  providers: [
    { provide: SIDENAV_MENU, useValue: sidenav, multi: true },

    ModellingApiService,
    ModellingApiEventService,
    ModelingSceneService,

    DatePipe,
  ],
  imports: [BaseModule],
  declarations: [],
  entryComponents: [],
  exports: [],
})
export class ModellingSharedModule {
  constructor(
    private _matIconRegistry: MatIconRegistry,
    private _domSanitizer: DomSanitizer
  ) {
    // this._registerIcon('ruis-route', '/assets/images/ruis/icons/route.svg');
  }

  // private _registerIcon(name: string, url: string) {
  //   this._matIconRegistry.addSvgIcon(name, this._domSanitizer.bypassSecurityTrustResourceUrl(url));
  // }
}
