import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { APP_ENVIRONMENT } from 'projects/msu-its-web-common/src/utils/shared-consts';

import { from, of } from 'rxjs';
import { catchError, switchMap, tap } from 'rxjs/operators';

const updateEnvironmentFromLocalStorage = () => {
  const hubApiUrl = localStorage.getItem('hubApiUrl');
  if (hubApiUrl && environment.modules.hub) environment.modules.hub.apiUrl = hubApiUrl;

  const gisApiUrl = localStorage.getItem('gisApiUrl');
  if (gisApiUrl && environment.modules.gis) environment.modules.gis.apiUrl = gisApiUrl;

  const telematicApiUrl = localStorage.getItem('telematicApiUrl');
  if (telematicApiUrl && environment.modules.telematic)
    environment.modules.telematic.apiUrl = telematicApiUrl;

  const tlcApiUrl = localStorage.getItem('tlcApiUrl');
  if (tlcApiUrl && environment.modules.tlc) environment.modules.tlc.apiUrl = tlcApiUrl;

  const tpcApiUrl = localStorage.getItem('tpcApiUrl');
  if (tpcApiUrl && environment.modules.tpc) environment.modules.tpc.apiUrl = tpcApiUrl;

  const tfmsApiUrl = localStorage.getItem('tfmsApiUrl');
  if (tfmsApiUrl && environment.modules.tfms) environment.modules.tfms.apiUrl = tfmsApiUrl;

  const tssApiUrl = localStorage.getItem('tssApiUrl');
  if (tssApiUrl && environment.modules.tss) environment.modules.tss.apiUrl = tssApiUrl;

  const meteoApiUrl = localStorage.getItem('meteoApiUrl');
  if (meteoApiUrl && environment.modules.meteo) environment.modules.meteo.apiUrl = meteoApiUrl;

  const parkingApiUrl = localStorage.getItem('parkingApiUrl');
  if (parkingApiUrl && environment.modules.parking)
    environment.modules.parking.apiUrl = parkingApiUrl;

  const ptmApiUrl = localStorage.getItem('ptmApiUrl');
  if (ptmApiUrl && environment.modules.ptm) environment.modules.ptm.apiUrl = ptmApiUrl;

  const ruisApiUrl = localStorage.getItem('ruisApiUrl');
  if (ruisApiUrl && environment.modules.ruis) environment.modules.ruis.apiUrl = ruisApiUrl;

  const rmmdApiUrl = localStorage.getItem('rmmdApiUrl');
  if (rmmdApiUrl && environment.modules.rmmd) environment.modules.rmmd.apiUrl = rmmdApiUrl;

  const ftvApiUrl = localStorage.getItem('ftvApiUrl');
  if (ftvApiUrl && environment.modules.ftv) environment.modules.ftv.apiUrl = ftvApiUrl;

  const accidentApiUrl = localStorage.getItem('accidentApiUrl');
  if (ftvApiUrl && environment.modules.accident) environment.modules.accident.apiUrl = accidentApiUrl;
};

const updateEnvironmentFromSchema = () => {
  const host = window.location.host;
  const protocol = window.location.protocol;

  Object.keys(environment.modules).forEach((m) => {
    const module: any = environment.modules[m];
    if (module && typeof module === 'object' && 'apiUrl' in module) {
      const apiUrl = `${protocol}//${m}.${host}`;
      module.apiUrl = apiUrl;
    }
  });
};

const updateObjectFromConfig = (obj: any, cfg: any) => {
  for (let key of Object.keys(cfg)) {
    const objProp = obj[key];
    const cfgProp = cfg[key];

    if (!objProp) {
      obj[key] = cfgProp;
      continue;
    }

    if (
      cfgProp &&
      typeof cfgProp === 'object' &&
      typeof objProp === 'object' &&
      !Array.isArray(cfgProp) &&
      !Array.isArray(objProp)
    ) {
      updateObjectFromConfig(objProp, cfgProp);
    } else {
      obj[key] = cfgProp;
    }
  }
};

const updateEnvironmentFromConfig = () => {
  return from(fetch('./assets/config.json')).pipe(
    switchMap((response) => response.json()),
    catchError((e) => of(null)),
    tap((config) => {
      if (config) {
        console.log('config', config);
        updateObjectFromConfig(environment, config);

        // set to null disabled modules
        Object.keys(environment.modules).forEach((m) => {
          if (environment.modules[m]?.disabled) {
            environment.modules[m] = null;
          }
        });
      }
    })
  );
};

if (environment.production) {
  enableProdMode();
}

const providers = [
  {
    provide: APP_ENVIRONMENT,
    useValue: environment,
    deps: [],
  },
];

if (environment.production || location.hostname !== 'localhost') {
  updateEnvironmentFromSchema();
}

updateEnvironmentFromConfig()
  .pipe(
    switchMap(() => {
      updateEnvironmentFromLocalStorage();
      console.log('environment', environment);
      return platformBrowserDynamic(providers).bootstrapModule(AppModule);
    })
  )
  .toPromise();
