/* Options:
Date: 2021-12-26 15:25:40
Version: 5.110
Tip: To override a DTO option, remove "//" prefix before updating
BaseUrl: https://tpc.dev.its.msu24.ru

//GlobalNamespace: 
//MakePropertiesOptional: False
//AddServiceStackTypes: True
//AddResponseStatus: False
//AddImplicitVersion: 
//AddDescriptionAsComments: True
//IncludeTypes: 
//ExcludeTypes: 
//DefaultImports: 
*/

export interface IReturn<T> {
  createResponse(): T;
}

export interface IReturnVoid {
  createResponse(): void;
}

export interface IHasSessionId {
  sessionId: string;
}

export interface IHasBearerToken {
  bearerToken: string;
}

export interface IPost {}

export interface IDescription {
  // @DataMember
  description: string;
}

export interface IRequestWithEntity {}

export interface ICustomerItemRequest extends ICustomerEntity {}

export interface ICustomerEntity {
  // @DataMember
  customerId: string;
}

export interface IBaseEntityItemRequest {
  id: string;
}

// @DataContract
export class PageRequest {
  // @DataMember
  public page: number;

  // @DataMember
  public items: number;

  // @DataMember
  public sort: string;

  // @DataMember
  public filter: string;

  public constructor(init?: Partial<PageRequest>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export enum TrafficMovementType {
  Move1 = 'Move1',
  Move2 = 'Move2',
  Move3 = 'Move3',
  Move4 = 'Move4',
  Move5 = 'Move5',
  Move6 = 'Move6',
  Move7 = 'Move7',
  Move8 = 'Move8',
  Move12 = 'Move12',
  Move14 = 'Move14',
  Move16 = 'Move16',
  Move18 = 'Move18',
  MoveP2 = 'MoveP2',
  MoveP4 = 'MoveP4',
  MoveP6 = 'MoveP6',
  MoveP8 = 'MoveP8',
}

// @DataContract
export class TrackingDeviceInfo
  implements
    ICustomerEntity,
    ICustomerName,
    IDeviceItem,
    IOwnedDeviceItem,
    IChangeKey,
    IDescription,
    IModified,
    IEntity
{
  // @DataMember
  public customerId: string;

  // @DataMember
  public customerName: string;

  // @DataMember
  public ownerObjectName: string;

  // @DataMember
  public ownerObjectId: string;

  // @DataMember
  public prefix: string;

  // @DataMember
  public number: string;

  // @DataMember
  public phone1: string;

  // @DataMember
  public phone2: string;

  // @DataMember
  public ip1: string;

  // @DataMember
  public ip2: string;

  // @DataMember
  public imei: string;

  // @DataMember
  public lat: number;

  // @DataMember
  public lon: number;

  // @DataMember
  public direction: number;

  // @DataMember
  public fullNumber: string;

  // @DataMember
  public softwareVersion: string;

  // @DataMember
  public firmwareVersion: string;

  // @DataMember
  public hardwareVersion: string;

  // @DataMember
  public description: string;

  // @DataMember
  public changeKey: string;

  // @DataMember
  public created: string;

  // @DataMember
  public createdBy: string;

  // @DataMember
  public modified: string;

  // @DataMember
  public modifiedBy: string;

  // @DataMember
  public id: string;

  // @DataMember
  public entityType: string;

  public constructor(init?: Partial<TrackingDeviceInfo>) {
    (Object as any).assign(this, init);
  }
}

export class DeleteCustomerItemsRequest implements ICustomerItemRequest, IDescription {
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public description: string;

  // @DataMember(IsRequired=true)
  public ids: string[];

  public constructor(init?: Partial<DeleteCustomerItemsRequest>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class ChangeCustomerRequestBase
  implements IDescription, ICustomerItemRequest, IBaseEntityItemRequest
{
  // @DataMember
  public description: string;

  // @DataMember
  public newCustomerId: string;

  // @DataMember
  public customerId: string;

  // @DataMember
  public id: string;

  public constructor(init?: Partial<ChangeCustomerRequestBase>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class RouteInfo
  implements ICustomerEntityViewModel, INamedEntity, IChangeKey, IDescription, IModified, IEntity
{
  // @DataMember
  public customerId: string;

  // @DataMember
  public customerName: string;

  // @DataMember
  public name: string;

  // @DataMember
  public description: string;

  // @DataMember
  public changeKey: string;

  // @DataMember
  public created: string;

  // @DataMember
  public createdBy: string;

  // @DataMember
  public modified: string;

  // @DataMember
  public modifiedBy: string;

  // @DataMember
  public id: string;

  // @DataMember
  public entityType: string;

  public constructor(init?: Partial<RouteInfo>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class ApplicationInfo implements INamed, IDescription {
  // @DataMember
  public id: string;

  // @DataMember
  public name: string;

  // @DataMember
  public description: string;

  // @DataMember
  public instance: string;

  // @DataMember
  public version: string;

  // @DataMember
  public buildTime: string;

  public constructor(init?: Partial<ApplicationInfo>) {
    (Object as any).assign(this, init);
  }
}

export interface ICustomerIntegrationIdRequest {
  customerIntegrationId: string;
}

// @DataContract
export class GetEntityGroupsRequestBase implements ICustomerItemRequest {
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public entityGroups: string[];

  // @DataMember
  public itemsIds: string[];

  // @DataMember
  public onlyOwnedItems: boolean;

  // @DataMember
  public page: number;

  // @DataMember
  public items: number;

  // @DataMember
  public sort: string;

  // @DataMember
  public filter: string;

  public constructor(init?: Partial<GetEntityGroupsRequestBase>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class ControlSessionSettings {
  // @DataMember
  public cycle: boolean;

  // @DataMember
  public cycleTill?: string;

  // @DataMember
  public detectionRadius: number;

  // @DataMember
  public runEarlyCount: number;

  public constructor(init?: Partial<ControlSessionSettings>) {
    (Object as any).assign(this, init);
  }
}

export class CustomerNamedBaseEntityViewModelBase
  implements ICustomerEntityViewModel, INamedEntity, IChangeKey, IDescription, IModified, IEntity
{
  // @DataMember
  public customerId: string;

  // @DataMember
  public customerName: string;

  // @DataMember
  public name: string;

  // @DataMember
  public description: string;

  // @DataMember
  public changeKey: string;

  // @DataMember
  public created: string;

  // @DataMember
  public createdBy: string;

  // @DataMember
  public modified: string;

  // @DataMember
  public modifiedBy: string;

  // @DataMember
  public id: string;

  // @DataMember
  public entityType: string;

  public constructor(init?: Partial<CustomerNamedBaseEntityViewModelBase>) {
    (Object as any).assign(this, init);
  }
}

export interface IEntity {}

export interface IEntityTyped {
  // @DataMember
  entityType: string;
}

export interface IChangeKey extends IReadonlyChangeKey {
  // @DataMember
  changeKey: string;
}

export interface IReadonlyChangeKey {
  // @DataMember
  changeKey: string;
}

export interface IModified {
  // @DataMember
  modified: string;

  // @DataMember
  modifiedBy: string;
}

export interface INamedEntity extends IEntity, INamed {}

export interface INamed {
  // @DataMember
  name: string;
}

export interface ICustomerEntityViewModel extends ICustomerName, ICustomerEntity {}

export interface ICustomerName {
  // @DataMember
  customerName: string;
}

// @DataContract
export class Coordinate implements ICoordinate {
  // @DataMember
  public lat: number;

  // @DataMember
  public lon: number;

  public constructor(init?: Partial<Coordinate>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class DetectedTrafficObjectStatus {
  // @DataMember
  public id: string;

  // @DataMember
  public name: string;

  // @DataMember
  public detectionPoint: Coordinate;

  // @DataMember
  public radius: number;

  // @DataMember
  public routeStepIndex: number;

  public constructor(init?: Partial<DetectedTrafficObjectStatus>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export enum TrafficObjectMode {
  Off = 'Off',
  Coordination = 'Coordination',
  Adaptive = 'Adaptive',
  Remote = 'Remote',
  Local = 'Local',
}

// @DataContract
export enum TrafficObjectState {
  Off = 'Off',
  YellowBlink = 'YellowBlink',
  AllRed = 'AllRed',
  Phase = 'Phase',
}

// @DataContract
export class TrafficObjectIconStatus {
  // @DataMember
  public color: string;

  // @DataMember
  public borderColor: string;

  // @DataMember
  public processing: boolean;

  public constructor(init?: Partial<TrafficObjectIconStatus>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export enum TrafficMovementLight {
  Off = 'Off',
  Red = 'Red',
  Yellow = 'Yellow',
  Green = 'Green',
  RedYellow = 'RedYellow',
  YellowBlink = 'YellowBlink',
  GreenBlink = 'GreenBlink',
}

// @DataContract
export class TrafficSchemaLight {
  // @DataMember(Order=0)
  public move1: TrafficMovementLight;

  // @DataMember(Order=1)
  public move2: TrafficMovementLight;

  // @DataMember(Order=2)
  public move3: TrafficMovementLight;

  // @DataMember(Order=3)
  public move4: TrafficMovementLight;

  // @DataMember(Order=4)
  public move5: TrafficMovementLight;

  // @DataMember(Order=5)
  public move6: TrafficMovementLight;

  // @DataMember(Order=6)
  public move7: TrafficMovementLight;

  // @DataMember(Order=7)
  public move8: TrafficMovementLight;

  // @DataMember(Order=8)
  public move12: TrafficMovementLight;

  // @DataMember(Order=9)
  public move14: TrafficMovementLight;

  // @DataMember(Order=10)
  public move16: TrafficMovementLight;

  // @DataMember(Order=11)
  public move18: TrafficMovementLight;

  // @DataMember(Order=12)
  public moveP2: TrafficMovementLight;

  // @DataMember(Order=13)
  public moveP4: TrafficMovementLight;

  // @DataMember(Order=14)
  public moveP6: TrafficMovementLight;

  // @DataMember(Order=15)
  public moveP8: TrafficMovementLight;

  public constructor(init?: Partial<TrafficSchemaLight>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class EntityViewModelBase implements IEntity {
  // @DataMember
  public id: string;

  // @DataMember
  public entityType: string;

  public constructor(init?: Partial<EntityViewModelBase>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class Position {
  // @DataMember
  public deviceTime: string;

  // @DataMember
  public coordinate: Coordinate;

  // @DataMember
  public direction: number;

  public constructor(init?: Partial<Position>) {
    (Object as any).assign(this, init);
  }
}

export class CustomerDeviceViewModelBase
  implements
    ICustomerEntity,
    ICustomerName,
    IDeviceItem,
    IOwnedDeviceItem,
    IChangeKey,
    IDescription,
    IModified,
    IEntity
{
  // @DataMember
  public customerId: string;

  // @DataMember
  public customerName: string;

  // @DataMember
  public ownerObjectName: string;

  // @DataMember
  public ownerObjectId: string;

  // @DataMember
  public prefix: string;

  // @DataMember
  public number: string;

  // @DataMember
  public phone1: string;

  // @DataMember
  public phone2: string;

  // @DataMember
  public ip1: string;

  // @DataMember
  public ip2: string;

  // @DataMember
  public imei: string;

  // @DataMember
  public deviceSettings: string;

  // @DataMember
  public lat: number;

  // @DataMember
  public lon: number;

  // @DataMember
  public direction: number;

  // @DataMember
  public fullNumber: string;

  // @DataMember
  public softwareVersion: string;

  // @DataMember
  public firmwareVersion: string;

  // @DataMember
  public hardwareVersion: string;

  // @DataMember
  public description: string;

  // @DataMember
  public changeKey: string;

  // @DataMember
  public created: string;

  // @DataMember
  public createdBy: string;

  // @DataMember
  public modified: string;

  // @DataMember
  public modifiedBy: string;

  // @DataMember
  public id: string;

  // @DataMember
  public entityType: string;

  public constructor(init?: Partial<CustomerDeviceViewModelBase>) {
    (Object as any).assign(this, init);
  }
}

export interface IDeviceItem {
  // @DataMember
  prefix: string;

  // @DataMember
  number: string;

  // @DataMember
  phone1: string;

  // @DataMember
  phone2: string;

  // @DataMember
  ip1: string;

  // @DataMember
  ip2: string;

  // @DataMember
  imei: string;

  // @DataMember
  lat: number;

  // @DataMember
  lon: number;

  // @DataMember
  direction: number;

  // @DataMember
  softwareVersion: string;

  // @DataMember
  firmwareVersion: string;

  // @DataMember
  hardwareVersion: string;
}

export interface IOwnedDeviceItem {
  // @DataMember
  ownerObjectName: string;

  // @DataMember
  ownerObjectId: string;
}

// @DataContract
export class RouteSettings implements IEntity {
  // @DataMember
  public routeObjects: RouteObject[];

  // @DataMember
  public id: string;

  // @DataMember
  public entityType: string;

  public constructor(init?: Partial<RouteSettings>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class BaseEntityViewModelBase implements IChangeKey, IDescription, IModified, IEntity {
  // @DataMember
  public description: string;

  // @DataMember
  public changeKey: string;

  // @DataMember
  public created: string;

  // @DataMember
  public createdBy: string;

  // @DataMember
  public modified: string;

  // @DataMember
  public modifiedBy: string;

  // @DataMember
  public id: string;

  // @DataMember
  public entityType: string;

  public constructor(init?: Partial<BaseEntityViewModelBase>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class EntityGroupInfo
  implements ICustomerEntityViewModel, INamedEntity, IChangeKey, IDescription, IModified, IEntity
{
  // @DataMember
  public itemsCount: number;

  // @DataMember
  public customerId: string;

  // @DataMember
  public customerName: string;

  // @DataMember
  public name: string;

  // @DataMember
  public description: string;

  // @DataMember
  public changeKey: string;

  // @DataMember
  public created: string;

  // @DataMember
  public createdBy: string;

  // @DataMember
  public modified: string;

  // @DataMember
  public modifiedBy: string;

  // @DataMember
  public id: string;

  // @DataMember
  public entityType: string;

  public constructor(init?: Partial<EntityGroupInfo>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class ResponseError {
  // @DataMember(Order=1)
  public errorCode: string;

  // @DataMember(Order=2)
  public fieldName: string;

  // @DataMember(Order=3)
  public message: string;

  // @DataMember(Order=4)
  public meta: { [index: string]: string };

  public constructor(init?: Partial<ResponseError>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class ResponseStatus {
  // @DataMember(Order=1)
  public errorCode: string;

  // @DataMember(Order=2)
  public message: string;

  // @DataMember(Order=3)
  public stackTrace: string;

  // @DataMember(Order=4)
  public errors: ResponseError[];

  // @DataMember(Order=5)
  public meta: { [index: string]: string };

  public constructor(init?: Partial<ResponseStatus>) {
    (Object as any).assign(this, init);
  }
}

export class CustomerDeviceInfoViewModelBase
  implements
    ICustomerEntity,
    ICustomerName,
    IDeviceItem,
    IOwnedDeviceItem,
    IChangeKey,
    IDescription,
    IModified,
    IEntity
{
  // @DataMember
  public customerId: string;

  // @DataMember
  public customerName: string;

  // @DataMember
  public ownerObjectName: string;

  // @DataMember
  public ownerObjectId: string;

  // @DataMember
  public prefix: string;

  // @DataMember
  public number: string;

  // @DataMember
  public phone1: string;

  // @DataMember
  public phone2: string;

  // @DataMember
  public ip1: string;

  // @DataMember
  public ip2: string;

  // @DataMember
  public imei: string;

  // @DataMember
  public lat: number;

  // @DataMember
  public lon: number;

  // @DataMember
  public direction: number;

  // @DataMember
  public fullNumber: string;

  // @DataMember
  public softwareVersion: string;

  // @DataMember
  public firmwareVersion: string;

  // @DataMember
  public hardwareVersion: string;

  // @DataMember
  public description: string;

  // @DataMember
  public changeKey: string;

  // @DataMember
  public created: string;

  // @DataMember
  public createdBy: string;

  // @DataMember
  public modified: string;

  // @DataMember
  public modifiedBy: string;

  // @DataMember
  public id: string;

  // @DataMember
  public entityType: string;

  public constructor(init?: Partial<CustomerDeviceInfoViewModelBase>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class DeleteItemsRequest implements IDescription {
  // @DataMember
  public description: string;

  // @DataMember(IsRequired=true)
  public ids: string[];

  public constructor(init?: Partial<DeleteItemsRequest>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class NamedBaseEntityViewModelBase
  implements INamedEntity, IChangeKey, IDescription, IModified, IEntity
{
  // @DataMember
  public name: string;

  // @DataMember
  public description: string;

  // @DataMember
  public changeKey: string;

  // @DataMember
  public created: string;

  // @DataMember
  public createdBy: string;

  // @DataMember
  public modified: string;

  // @DataMember
  public modifiedBy: string;

  // @DataMember
  public id: string;

  // @DataMember
  public entityType: string;

  public constructor(init?: Partial<NamedBaseEntityViewModelBase>) {
    (Object as any).assign(this, init);
  }
}

export class DeviceViewModelBase
  implements IDeviceItem, IOwnedDeviceItem, IChangeKey, IDescription, IModified, IEntity
{
  // @DataMember
  public ownerObjectName: string;

  // @DataMember
  public ownerObjectId: string;

  // @DataMember
  public prefix: string;

  // @DataMember
  public number: string;

  // @DataMember
  public phone1: string;

  // @DataMember
  public phone2: string;

  // @DataMember
  public ip1: string;

  // @DataMember
  public ip2: string;

  // @DataMember
  public imei: string;

  // @DataMember
  public deviceSettings: string;

  // @DataMember
  public lat: number;

  // @DataMember
  public lon: number;

  // @DataMember
  public direction: number;

  // @DataMember
  public fullNumber: string;

  // @DataMember
  public softwareVersion: string;

  // @DataMember
  public firmwareVersion: string;

  // @DataMember
  public hardwareVersion: string;

  // @DataMember
  public description: string;

  // @DataMember
  public changeKey: string;

  // @DataMember
  public created: string;

  // @DataMember
  public createdBy: string;

  // @DataMember
  public modified: string;

  // @DataMember
  public modifiedBy: string;

  // @DataMember
  public id: string;

  // @DataMember
  public entityType: string;

  public constructor(init?: Partial<DeviceViewModelBase>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class StepMovement {
  // @DataMember
  public movements: TrafficMovementType[];

  // @DataMember
  public phase: number;

  public constructor(init?: Partial<StepMovement>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class RecoveryMovement {
  // @DataMember
  public movements: TrafficMovementType[];

  // @DataMember
  public phase: number;

  public constructor(init?: Partial<RecoveryMovement>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class RouteObject {
  // @DataMember
  public trafficObjectId: string;

  // @DataMember
  public distance: number;

  // @DataMember
  public stepMovement: StepMovement;

  // @DataMember
  public recoveryMovement: RecoveryMovement;

  public constructor(init?: Partial<RouteObject>) {
    (Object as any).assign(this, init);
  }
}

export class DeviceInfoViewModelBase
  implements IDeviceItem, IOwnedDeviceItem, IChangeKey, IDescription, IModified, IEntity
{
  // @DataMember
  public ownerObjectName: string;

  // @DataMember
  public ownerObjectId: string;

  // @DataMember
  public prefix: string;

  // @DataMember
  public number: string;

  // @DataMember
  public phone1: string;

  // @DataMember
  public phone2: string;

  // @DataMember
  public ip1: string;

  // @DataMember
  public ip2: string;

  // @DataMember
  public imei: string;

  // @DataMember
  public lat: number;

  // @DataMember
  public lon: number;

  // @DataMember
  public direction: number;

  // @DataMember
  public fullNumber: string;

  // @DataMember
  public softwareVersion: string;

  // @DataMember
  public firmwareVersion: string;

  // @DataMember
  public hardwareVersion: string;

  // @DataMember
  public description: string;

  // @DataMember
  public changeKey: string;

  // @DataMember
  public created: string;

  // @DataMember
  public createdBy: string;

  // @DataMember
  public modified: string;

  // @DataMember
  public modifiedBy: string;

  // @DataMember
  public id: string;

  // @DataMember
  public entityType: string;

  public constructor(init?: Partial<DeviceInfoViewModelBase>) {
    (Object as any).assign(this, init);
  }
}

export interface ICoordinate {
  lat: number;
  lon: number;
}

// @DataContract
export class PageRequestBase {
  // @DataMember
  public page: number;

  // @DataMember
  public items: number;

  // @DataMember
  public sort: string;

  // @DataMember
  public filter: string;

  public constructor(init?: Partial<PageRequestBase>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class ControlSession
  implements ICustomerEntityViewModel, INamedEntity, IChangeKey, IDescription, IModified, IEntity
{
  // @DataMember
  public routeId: string;

  // @DataMember
  public startTime: string;

  // @DataMember
  public endTime?: string;

  // @DataMember
  public trackingDevice?: string;

  // @DataMember
  public trackingDevice2?: string;

  // @DataMember
  public trackingDeviceNumber: string;

  // @DataMember
  public trackingDevice2Number: string;

  // @DataMember
  public settings: ControlSessionSettings;

  // @DataMember
  public customerId: string;

  // @DataMember
  public customerName: string;

  // @DataMember
  public name: string;

  // @DataMember
  public description: string;

  // @DataMember
  public changeKey: string;

  // @DataMember
  public created: string;

  // @DataMember
  public createdBy: string;

  // @DataMember
  public modified: string;

  // @DataMember
  public modifiedBy: string;

  // @DataMember
  public id: string;

  // @DataMember
  public entityType: string;

  public constructor(init?: Partial<ControlSession>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class GetPriorityControlSessionsResponse implements IChangeKey {
  // @DataMember
  public items: ControlSession[];

  // @DataMember
  public total: number;

  // @DataMember
  public page: number;

  // @DataMember
  public itemsPerPage: number;

  // @DataMember
  public changeKey: string;

  public constructor(init?: Partial<GetPriorityControlSessionsResponse>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class TrafficObjectStatus implements IEntity {
  // @DataMember
  public serverTime: string;

  // @DataMember
  public stateTime: string;

  // @DataMember
  public mode: TrafficObjectMode;

  // @DataMember
  public state: TrafficObjectState;

  // @DataMember
  public iconStatus: TrafficObjectIconStatus;

  // @DataMember
  public online: boolean;

  // @DataMember
  public controllerLastConnectionTime: string;

  // @DataMember
  public controllerLastPacketTime: string;

  // @DataMember
  public controllerTime: string;

  // @DataMember
  public controllerStatusDescription: string;

  // @DataMember
  public controllerErrorDescription: string;

  // @DataMember
  public schemaLights: TrafficSchemaLight;

  // @DataMember
  public commandProcessing: boolean;

  // @DataMember
  public coordinationPlan: number;

  // @DataMember
  public planTime: number;

  // @DataMember
  public id: string;

  // @DataMember
  public entityType: string;

  public constructor(init?: Partial<TrafficObjectStatus>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class TrackingDeviceStatus implements IEntity {
  // @DataMember
  public id: string;

  // @DataMember
  public entityType: string;

  // @DataMember
  public deviceNumber: string;

  // @DataMember
  public connectionTime: string;

  // @DataMember
  public position: Position;

  // @DataMember
  public speed: number;

  // @DataMember
  public connected: boolean;

  // @DataMember
  public serverTime: string;

  public constructor(init?: Partial<TrackingDeviceStatus>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class ControlSessionStatus implements IEntity {
  // @DataMember
  public id: string;

  // @DataMember
  public entityType: string;

  // @DataMember
  public detections: DetectedTrafficObjectStatus[];

  // @DataMember
  public trafficObjects: TrafficObjectStatus[];

  // @DataMember
  public trackingDevice: TrackingDeviceStatus;

  // @DataMember
  public trackingDevice2: TrackingDeviceStatus;

  // @DataMember
  public routeId: string;

  // @DataMember
  public routeName: string;

  // @DataMember
  public startTime: string;

  // @DataMember
  public endTime?: string;

  public constructor(init?: Partial<ControlSessionStatus>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class Route
  implements ICustomerEntityViewModel, INamedEntity, IChangeKey, IDescription, IModified, IEntity
{
  // @DataMember
  public settings: RouteSettings;

  // @DataMember
  public routeGeoJson: string;

  // @DataMember
  public customerId: string;

  // @DataMember
  public customerName: string;

  // @DataMember
  public name: string;

  // @DataMember
  public description: string;

  // @DataMember
  public changeKey: string;

  // @DataMember
  public created: string;

  // @DataMember
  public createdBy: string;

  // @DataMember
  public modified: string;

  // @DataMember
  public modifiedBy: string;

  // @DataMember
  public id: string;

  // @DataMember
  public entityType: string;

  public constructor(init?: Partial<Route>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class ControlSessionSummary {
  // @DataMember
  public route: Route;

  // @DataMember
  public session: ControlSession;

  // @DataMember
  public track: Position[];

  // @DataMember
  public avgSpeed?: number;

  // @DataMember
  public start: string;

  // @DataMember
  public end: string;

  // @DataMember
  public duration: number;

  // @DataMember
  public distance?: number;

  // @DataMember
  public speed: number[];

  public constructor(init?: Partial<ControlSessionSummary>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class GetTrackingDevicesResponse implements IChangeKey {
  // @DataMember
  public items: TrackingDeviceInfo[];

  // @DataMember
  public total: number;

  // @DataMember
  public page: number;

  // @DataMember
  public itemsPerPage: number;

  // @DataMember
  public changeKey: string;

  public constructor(init?: Partial<GetTrackingDevicesResponse>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class TrackingDevice
  implements
    ICustomerEntity,
    ICustomerName,
    IDeviceItem,
    IOwnedDeviceItem,
    IChangeKey,
    IDescription,
    IModified,
    IEntity
{
  // @DataMember
  public customerId: string;

  // @DataMember
  public customerName: string;

  // @DataMember
  public ownerObjectName: string;

  // @DataMember
  public ownerObjectId: string;

  // @DataMember
  public prefix: string;

  // @DataMember
  public number: string;

  // @DataMember
  public phone1: string;

  // @DataMember
  public phone2: string;

  // @DataMember
  public ip1: string;

  // @DataMember
  public ip2: string;

  // @DataMember
  public imei: string;

  // @DataMember
  public deviceSettings: string;

  // @DataMember
  public lat: number;

  // @DataMember
  public lon: number;

  // @DataMember
  public direction: number;

  // @DataMember
  public fullNumber: string;

  // @DataMember
  public softwareVersion: string;

  // @DataMember
  public firmwareVersion: string;

  // @DataMember
  public hardwareVersion: string;

  // @DataMember
  public description: string;

  // @DataMember
  public changeKey: string;

  // @DataMember
  public created: string;

  // @DataMember
  public createdBy: string;

  // @DataMember
  public modified: string;

  // @DataMember
  public modifiedBy: string;

  // @DataMember
  public id: string;

  // @DataMember
  public entityType: string;

  public constructor(init?: Partial<TrackingDevice>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class SessionInfo {
  // @DataMember
  public id: string;

  // @DataMember
  public lastReceived: string;

  // @DataMember
  public lastSent: string;

  // @DataMember
  public deviceNumber: string;

  // @DataMember
  public sessionId: string;

  // @DataMember
  public connectionTime: string;

  // @DataMember
  public receiverHost: string;

  // @DataMember
  public remoteAddress: string;

  // @DataMember
  public connected: boolean;

  // @DataMember
  public lastUpdated: string;

  // @DataMember
  public entityType: string;

  // @DataMember
  public nextCheckSession: string;

  public constructor(init?: Partial<SessionInfo>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class TrackingDeviceTrack implements IEntity {
  // @DataMember
  public id: string;

  // @DataMember
  public entityType: string;

  // @DataMember
  public position: Position[];

  // @DataMember
  public speed: number[];

  public constructor(init?: Partial<TrackingDeviceTrack>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class GetTrackingDevicesStatusResponse implements IChangeKey {
  // @DataMember
  public items: TrackingDeviceStatus[];

  // @DataMember
  public total: number;

  // @DataMember
  public page: number;

  // @DataMember
  public itemsPerPage: number;

  // @DataMember
  public changeKey: string;

  public constructor(init?: Partial<GetTrackingDevicesStatusResponse>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class GetRoutesResponse implements IChangeKey {
  // @DataMember
  public items: RouteInfo[];

  // @DataMember
  public total: number;

  // @DataMember
  public page: number;

  // @DataMember
  public itemsPerPage: number;

  // @DataMember
  public changeKey: string;

  public constructor(init?: Partial<GetRoutesResponse>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class EntityIntegration implements IChangeKey, IDescription, IModified, IEntity {
  // @DataMember
  public entityId: string;

  // @DataMember
  public customerIntegrationId: string;

  // @DataMember
  public externalId: string;

  // @DataMember
  public settings: string;

  // @DataMember
  public integrationId: string;

  // @DataMember
  public integrationName: string;

  // @DataMember
  public integrationType: string;

  // @DataMember
  public description: string;

  // @DataMember
  public changeKey: string;

  // @DataMember
  public created: string;

  // @DataMember
  public createdBy: string;

  // @DataMember
  public modified: string;

  // @DataMember
  public modifiedBy: string;

  // @DataMember
  public id: string;

  // @DataMember
  public entityType: string;

  public constructor(init?: Partial<EntityIntegration>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class GetEntityIntegrationResponse implements IChangeKey {
  // @DataMember
  public items: EntityIntegration[];

  // @DataMember
  public total: number;

  // @DataMember
  public page: number;

  // @DataMember
  public itemsPerPage: number;

  // @DataMember
  public changeKey: string;

  public constructor(init?: Partial<GetEntityIntegrationResponse>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class GetEntityGroupsResponse implements IChangeKey {
  // @DataMember
  public items: EntityGroupInfo[];

  // @DataMember
  public total: number;

  // @DataMember
  public page: number;

  // @DataMember
  public itemsPerPage: number;

  // @DataMember
  public changeKey: string;

  public constructor(init?: Partial<GetEntityGroupsResponse>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class GetEntitiesResponse implements IChangeKey {
  // @DataMember
  public items: Object[];

  // @DataMember
  public total: number;

  // @DataMember
  public page: number;

  // @DataMember
  public itemsPerPage: number;

  // @DataMember
  public changeKey: string;

  public constructor(init?: Partial<GetEntitiesResponse>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class AuthenticateResponse implements IHasSessionId, IHasBearerToken {
  // @DataMember(Order=1)
  public userId: string;

  // @DataMember(Order=2)
  public sessionId: string;

  // @DataMember(Order=3)
  public userName: string;

  // @DataMember(Order=4)
  public displayName: string;

  // @DataMember(Order=5)
  public referrerUrl: string;

  // @DataMember(Order=6)
  public bearerToken: string;

  // @DataMember(Order=7)
  public refreshToken: string;

  // @DataMember(Order=8)
  public profileUrl: string;

  // @DataMember(Order=9)
  public roles: string[];

  // @DataMember(Order=10)
  public permissions: string[];

  // @DataMember(Order=11)
  public responseStatus: ResponseStatus;

  // @DataMember(Order=12)
  public meta: { [index: string]: string };

  public constructor(init?: Partial<AuthenticateResponse>) {
    (Object as any).assign(this, init);
  }
}

// @Route("/customer/{customerId}/priorityControlSession/start", "POST")
// @DataContract
export class StartPriorityControlSessionRequest
  implements IReturn<ControlSession>, ICustomerItemRequest, IDescription
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public updateEntityGroups: boolean;

  // @DataMember
  public entityGroups: string[];

  // @DataMember(IsRequired=true)
  public entity: ControlSession;

  // @DataMember
  public description: string;

  public constructor(init?: Partial<StartPriorityControlSessionRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new ControlSession();
  }
  public getTypeName() {
    return 'StartPriorityControlSessionRequest';
  }
}

// @Route("/customer/{customerId}/priorityControlSession/{Id}/stop", "POST")
// @DataContract
export class StopPriorityControlSessionRequest
  implements IReturn<ControlSession>, ICustomerItemRequest, IBaseEntityItemRequest
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember(IsRequired=true)
  public id: string;

  public constructor(init?: Partial<StopPriorityControlSessionRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new ControlSession();
  }
  public getTypeName() {
    return 'StopPriorityControlSessionRequest';
  }
}

// @Route("/customer/{customerId}/priorityControlSessions", "GET")
// @DataContract
export class GetPriorityControlSessionsRequest
  implements IReturn<GetPriorityControlSessionsResponse>, ICustomerItemRequest
{
  // @DataMember
  public onlyActive: boolean;

  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public entityGroups: string[];

  // @DataMember
  public itemsIds: string[];

  // @DataMember
  public onlyOwnedItems: boolean;

  // @DataMember
  public page: number;

  // @DataMember
  public items: number;

  // @DataMember
  public sort: string;

  // @DataMember
  public filter: string;

  public constructor(init?: Partial<GetPriorityControlSessionsRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new GetPriorityControlSessionsResponse();
  }
  public getTypeName() {
    return 'GetPriorityControlSessionsRequest';
  }
}

// @Route("/customer/{customerId}/priorityControlSession/{Id}/status", "GET")
// @DataContract
export class GetPriorityControlSessionStatusRequest
  implements IReturn<ControlSessionStatus>, ICustomerItemRequest, IBaseEntityItemRequest
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember(IsRequired=true)
  public id: string;

  public constructor(init?: Partial<GetPriorityControlSessionStatusRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new ControlSessionStatus();
  }
  public getTypeName() {
    return 'GetPriorityControlSessionStatusRequest';
  }
}

// @Route("/customer/{customerId}/priorityControlSession/{Id}/trafficObject/{TrafficObjectId}/start", "POST")
// @DataContract
export class PriorityControlSessionStartTrafficObjectRequest
  implements IReturn<TrafficObjectStatus>, ICustomerItemRequest, IBaseEntityItemRequest
{
  // @DataMember
  public trafficObjectId: string;

  // @DataMember
  public phaseId: number;

  // @DataMember
  public movements: TrafficMovementType[];

  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember(IsRequired=true)
  public id: string;

  public constructor(init?: Partial<PriorityControlSessionStartTrafficObjectRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new TrafficObjectStatus();
  }
  public getTypeName() {
    return 'PriorityControlSessionStartTrafficObjectRequest';
  }
}

// @Route("/customer/{customerId}/priorityControlSession/{Id}/trafficObject/{TrafficObjectId}/stop", "POST")
// @DataContract
export class PriorityControlSessionStopTrafficObjectRequest
  implements IReturn<TrafficObjectStatus>, ICustomerItemRequest, IBaseEntityItemRequest
{
  // @DataMember
  public trafficObjectId: string;

  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember(IsRequired=true)
  public id: string;

  public constructor(init?: Partial<PriorityControlSessionStopTrafficObjectRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new TrafficObjectStatus();
  }
  public getTypeName() {
    return 'PriorityControlSessionStopTrafficObjectRequest';
  }
}

// @Route("/customer/{customerId}/priorityControlSession/{Id}/summary", "POST")
// @DataContract
export class GetPriorityControlSessionSummaryRequest
  implements IReturn<ControlSessionSummary>, ICustomerItemRequest, IBaseEntityItemRequest
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember(IsRequired=true)
  public id: string;

  public constructor(init?: Partial<GetPriorityControlSessionSummaryRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new ControlSessionSummary();
  }
  public getTypeName() {
    return 'GetPriorityControlSessionSummaryRequest';
  }
}

// @Route("/TrackingDeviceStatus/{Id}", "GET")
// @DataContract
export class GetTrackingDeviceStatusDebugRequest implements IReturn<TrackingDeviceStatus> {
  // @DataMember
  public id: string;

  public constructor(init?: Partial<GetTrackingDeviceStatusDebugRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new TrackingDeviceStatus();
  }
  public getTypeName() {
    return 'GetTrackingDeviceStatusDebugRequest';
  }
}

// @Route("/trackingDevice/exist", "GET")
// @DataContract
export class GetTrackingDeviceExistRequest implements IReturn<boolean> {
  // @DataMember(IsRequired=true)
  public number: string;

  public constructor(init?: Partial<GetTrackingDeviceExistRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return false;
  }
  public getTypeName() {
    return 'GetTrackingDeviceExistRequest';
  }
}

// @Route("/customer/{customerId}/trackingDevices", "GET")
// @DataContract
export class GetTrackingDevicesRequest
  implements IReturn<GetTrackingDevicesResponse>, ICustomerItemRequest
{
  // @DataMember
  public onlyUsed: boolean;

  // @DataMember
  public onlyFree: boolean;

  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public entityGroups: string[];

  // @DataMember
  public itemsIds: string[];

  // @DataMember
  public onlyOwnedItems: boolean;

  // @DataMember
  public page: number;

  // @DataMember
  public items: number;

  // @DataMember
  public sort: string;

  // @DataMember
  public filter: string;

  public constructor(init?: Partial<GetTrackingDevicesRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new GetTrackingDevicesResponse();
  }
  public getTypeName() {
    return 'GetTrackingDevicesRequest';
  }
}

// @Route("/customer/{customerId}/trackingDevice/{id}", "GET")
// @DataContract
export class GetTrackingDeviceRequest
  implements IReturn<TrackingDevice>, ICustomerItemRequest, IBaseEntityItemRequest
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember(IsRequired=true)
  public id: string;

  public constructor(init?: Partial<GetTrackingDeviceRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new TrackingDevice();
  }
  public getTypeName() {
    return 'GetTrackingDeviceRequest';
  }
}

// @Route("/customer/{customerId}/trackingDevice/{id}", "PUT")
// @DataContract
export class UpdateTrackingDeviceRequest
  implements IReturn<TrackingDevice>, ICustomerItemRequest, IBaseEntityItemRequest, IDescription
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public updateEntityGroups: boolean;

  // @DataMember
  public entityGroups: string[];

  // @DataMember(IsRequired=true)
  public id: string;

  // @DataMember(IsRequired=true)
  public entity: TrackingDevice;

  // @DataMember
  public description: string;

  public constructor(init?: Partial<UpdateTrackingDeviceRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new TrackingDevice();
  }
  public getTypeName() {
    return 'UpdateTrackingDeviceRequest';
  }
}

// @Route("/customer/{customerId}/trackingDevice", "POST")
// @DataContract
export class CreateTrackingDeviceRequest
  implements IReturn<TrackingDevice>, ICustomerItemRequest, IDescription
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public updateEntityGroups: boolean;

  // @DataMember
  public entityGroups: string[];

  // @DataMember(IsRequired=true)
  public entity: TrackingDevice;

  // @DataMember
  public description: string;

  public constructor(init?: Partial<CreateTrackingDeviceRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new TrackingDevice();
  }
  public getTypeName() {
    return 'CreateTrackingDeviceRequest';
  }
}

// @Route("/customer/{customerId}/trackingDevices", "DELETE")
// @DataContract
export class DeleteTrackingDevicesRequest
  implements IReturn<number>, ICustomerItemRequest, IDescription
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public description: string;

  // @DataMember(IsRequired=true)
  public ids: string[];

  public constructor(init?: Partial<DeleteTrackingDevicesRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return 0;
  }
  public getTypeName() {
    return 'DeleteTrackingDevicesRequest';
  }
}

// @Route("/customer/{customerId}/trackingDevice/{id}/disconnect", "POST")
// @DataContract
export class DisconnectTrackingDeviceRequest
  implements IReturn<SessionInfo>, IDescription, ICustomerItemRequest, IBaseEntityItemRequest
{
  // @DataMember
  public description: string;

  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember(IsRequired=true)
  public id: string;

  public constructor(init?: Partial<DisconnectTrackingDeviceRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new SessionInfo();
  }
  public getTypeName() {
    return 'DisconnectTrackingDeviceRequest';
  }
}

// @Route("/customer/{customerId}/trackingDevice/{id}/changeCustomer", "POST")
// @DataContract
export class TrackingDeviceChangeCustomerRequest
  implements IReturn<boolean>, IDescription, ICustomerItemRequest, IBaseEntityItemRequest
{
  // @DataMember
  public description: string;

  // @DataMember
  public newCustomerId: string;

  // @DataMember
  public customerId: string;

  // @DataMember
  public id: string;

  public constructor(init?: Partial<TrackingDeviceChangeCustomerRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return false;
  }
  public getTypeName() {
    return 'TrackingDeviceChangeCustomerRequest';
  }
}

// @Route("/customer/{customerId}/trackingDevice/{id}/status", "GET")
// @DataContract
export class GetTrackingDeviceStatusRequest
  implements IReturn<TrackingDeviceStatus>, ICustomerItemRequest, IBaseEntityItemRequest
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember(IsRequired=true)
  public id: string;

  public constructor(init?: Partial<GetTrackingDeviceStatusRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new TrackingDeviceStatus();
  }
  public getTypeName() {
    return 'GetTrackingDeviceStatusRequest';
  }
}

// @Route("/customer/{customerId}/trackingDevice/{id}/track", "GET")
// @DataContract
export class GetTrackingDeviceTrackRequest
  implements IReturn<TrackingDeviceTrack>, ICustomerItemRequest, IBaseEntityItemRequest
{
  // @DataMember
  public from: string;

  // @DataMember
  public to: string;

  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember(IsRequired=true)
  public id: string;

  public constructor(init?: Partial<GetTrackingDeviceTrackRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new TrackingDeviceTrack();
  }
  public getTypeName() {
    return 'GetTrackingDeviceTrackRequest';
  }
}

// @Route("/customer/{customerId}/trackingDevice/{id}/trackGeoJson", "GET")
// @DataContract
export class GetTrackingDeviceTrackGeoJsonRequest
  implements IReturn<string>, ICustomerItemRequest, IBaseEntityItemRequest
{
  // @DataMember
  public from: string;

  // @DataMember
  public to: string;

  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember(IsRequired=true)
  public id: string;

  public constructor(init?: Partial<GetTrackingDeviceTrackGeoJsonRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return '';
  }
  public getTypeName() {
    return 'GetTrackingDeviceTrackGeoJsonRequest';
  }
}

// @Route("/customer/{customerId}/trackingDevices/statuses", "GET")
// @DataContract
export class GetTrackingDevicesStatusRequest
  implements IReturn<GetTrackingDevicesStatusResponse>, ICustomerItemRequest
{
  // @DataMember
  public ids: string[];

  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public entityGroups: string[];

  // @DataMember
  public itemsIds: string[];

  // @DataMember
  public onlyOwnedItems: boolean;

  // @DataMember
  public page: number;

  // @DataMember
  public items: number;

  // @DataMember
  public sort: string;

  // @DataMember
  public filter: string;

  public constructor(init?: Partial<GetTrackingDevicesStatusRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new GetTrackingDevicesStatusResponse();
  }
  public getTypeName() {
    return 'GetTrackingDevicesStatusRequest';
  }
}

// @Route("/customer/{customerId}/routes", "GET")
// @DataContract
export class GetRoutesRequest implements IReturn<GetRoutesResponse>, ICustomerItemRequest {
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public entityGroups: string[];

  // @DataMember
  public itemsIds: string[];

  // @DataMember
  public onlyOwnedItems: boolean;

  // @DataMember
  public page: number;

  // @DataMember
  public items: number;

  // @DataMember
  public sort: string;

  // @DataMember
  public filter: string;

  public constructor(init?: Partial<GetRoutesRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new GetRoutesResponse();
  }
  public getTypeName() {
    return 'GetRoutesRequest';
  }
}

// @Route("/customer/{customerId}/routes/route/{id}", "GET")
// @DataContract
export class GetRouteRequest
  implements IReturn<Route>, ICustomerItemRequest, IBaseEntityItemRequest
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember(IsRequired=true)
  public id: string;

  public constructor(init?: Partial<GetRouteRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new Route();
  }
  public getTypeName() {
    return 'GetRouteRequest';
  }
}

// @Route("/customer/{customerId}/routes", "POST")
// @DataContract
export class CreateRouteRequest implements IReturn<Route>, ICustomerItemRequest, IDescription {
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public updateEntityGroups: boolean;

  // @DataMember
  public entityGroups: string[];

  // @DataMember(IsRequired=true)
  public entity: Route;

  // @DataMember
  public description: string;

  public constructor(init?: Partial<CreateRouteRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new Route();
  }
  public getTypeName() {
    return 'CreateRouteRequest';
  }
}

// @Route("/customer/{customerId}/route/{id}", "PUT")
// @DataContract
export class UpdateRouteRequest
  implements IReturn<Route>, ICustomerItemRequest, IBaseEntityItemRequest, IDescription
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public updateEntityGroups: boolean;

  // @DataMember
  public entityGroups: string[];

  // @DataMember(IsRequired=true)
  public id: string;

  // @DataMember(IsRequired=true)
  public entity: Route;

  // @DataMember
  public description: string;

  public constructor(init?: Partial<UpdateRouteRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new Route();
  }
  public getTypeName() {
    return 'UpdateRouteRequest';
  }
}

// @Route("/customer/{customerId}/routes", "DELETE")
// @DataContract
export class DeleteRouteRequest implements IReturn<number>, ICustomerItemRequest, IDescription {
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public description: string;

  // @DataMember(IsRequired=true)
  public ids: string[];

  public constructor(init?: Partial<DeleteRouteRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return 0;
  }
  public getTypeName() {
    return 'DeleteRouteRequest';
  }
}

// @Route("/validateToken", "GET")
// @DataContract
export class ValidateItsTokenRequest implements IReturn<boolean> {
  // @DataMember
  public application: ApplicationInfo;

  public constructor(init?: Partial<ValidateItsTokenRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return false;
  }
  public getTypeName() {
    return 'ValidateItsTokenRequest';
  }
}

// @Route("/healthStatus", "GET")
// @DataContract
export class GetHealthStatusRequest implements IReturn<string> {
  public constructor(init?: Partial<GetHealthStatusRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return '';
  }
  public getTypeName() {
    return 'GetHealthStatusRequest';
  }
}

// @Route("/customer/{customerId}/integration/{customerIntegrationId}/entities", "GET")
// @DataContract
export class GetEntityIntegrationsRequest
  implements
    IReturn<GetEntityIntegrationResponse>,
    ICustomerIntegrationIdRequest,
    ICustomerItemRequest
{
  // @DataMember
  public customerIntegrationId: string;

  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public entityGroups: string[];

  // @DataMember
  public itemsIds: string[];

  // @DataMember
  public onlyOwnedItems: boolean;

  // @DataMember
  public page: number;

  // @DataMember
  public items: number;

  // @DataMember
  public sort: string;

  // @DataMember
  public filter: string;

  public constructor(init?: Partial<GetEntityIntegrationsRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new GetEntityIntegrationResponse();
  }
  public getTypeName() {
    return 'GetEntityIntegrationsRequest';
  }
}

// @Route("/customer/{customerId}/integration/{customerIntegrationId}/entity/{id}", "GET")
// @DataContract
export class GetEntityIntegrationRequest
  implements
    IReturn<EntityIntegration>,
    ICustomerIntegrationIdRequest,
    ICustomerItemRequest,
    IBaseEntityItemRequest
{
  // @DataMember
  public customerIntegrationId: string;

  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember(IsRequired=true)
  public id: string;

  public constructor(init?: Partial<GetEntityIntegrationRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new EntityIntegration();
  }
  public getTypeName() {
    return 'GetEntityIntegrationRequest';
  }
}

// @Route("/customer/{customerId}/integration/{customerIntegrationId}/entities", "POST")
// @DataContract
export class CreateEntityIntegrationRequest
  implements
    IReturn<EntityIntegration>,
    ICustomerIntegrationIdRequest,
    ICustomerItemRequest,
    IDescription
{
  // @DataMember
  public customerIntegrationId: string;

  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public updateEntityGroups: boolean;

  // @DataMember
  public entityGroups: string[];

  // @DataMember(IsRequired=true)
  public entity: EntityIntegration;

  // @DataMember
  public description: string;

  public constructor(init?: Partial<CreateEntityIntegrationRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new EntityIntegration();
  }
  public getTypeName() {
    return 'CreateEntityIntegrationRequest';
  }
}

// @Route("/customer/{customerId}/integration/{customerIntegrationId}/entity/{id}", "PUT")
// @DataContract
export class UpdateEntityIntegrationRequest
  implements
    IReturn<EntityIntegration>,
    ICustomerIntegrationIdRequest,
    ICustomerItemRequest,
    IBaseEntityItemRequest,
    IDescription
{
  // @DataMember
  public customerIntegrationId: string;

  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public updateEntityGroups: boolean;

  // @DataMember
  public entityGroups: string[];

  // @DataMember(IsRequired=true)
  public id: string;

  // @DataMember(IsRequired=true)
  public entity: EntityIntegration;

  // @DataMember
  public description: string;

  public constructor(init?: Partial<UpdateEntityIntegrationRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new EntityIntegration();
  }
  public getTypeName() {
    return 'UpdateEntityIntegrationRequest';
  }
}

// @Route("/entity/{id}/properties", "GET")
// @DataContract
export class GetEntityPropertiesRequest implements IReturn<any>, IBaseEntityItemRequest {
  // @DataMember(IsRequired=true)
  public id: string;

  public constructor(init?: Partial<GetEntityPropertiesRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return {};
  }
  public getTypeName() {
    return 'GetEntityPropertiesRequest';
  }
}

// @Route("/entity/{id}/properties", "PUT")
// @DataContract
export class UpdateEntityPropertiesRequest implements IReturn<any> {
  // @DataMember(IsRequired=true)
  public id: string;

  // @DataMember
  public description: string;

  // @DataMember
  public entity: { [index: string]: string };

  public constructor(init?: Partial<UpdateEntityPropertiesRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return {};
  }
  public getTypeName() {
    return 'UpdateEntityPropertiesRequest';
  }
}

// @Route("/entity/{id}/property/{property}", "GET")
// @DataContract
export class GetEntityPropertyRequest implements IReturn<string> {
  // @DataMember
  public property: string;

  // @DataMember(IsRequired=true)
  public id: string;

  public constructor(init?: Partial<GetEntityPropertyRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return '';
  }
  public getTypeName() {
    return 'GetEntityPropertyRequest';
  }
}

// @Route("/entity/{id}/property/{property}", "PUT")
// @DataContract
export class UpdateEntityPropertyRequest implements IReturn<string> {
  // @DataMember(IsRequired=true)
  public id: string;

  // @DataMember
  public description: string;

  // @DataMember
  public property: string;

  // @DataMember
  public value: string;

  public constructor(init?: Partial<UpdateEntityPropertyRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return '';
  }
  public getTypeName() {
    return 'UpdateEntityPropertyRequest';
  }
}

// @Route("/customer/{customerId}/entities/property/{property}", "GET")
// @DataContract
export class GetEntitiesPropertyRequest implements IReturn<any> {
  // @DataMember
  public property: string;

  // @DataMember
  public filter: string;

  // @DataMember
  public customerId: string;

  public constructor(init?: Partial<GetEntitiesPropertyRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return {};
  }
  public getTypeName() {
    return 'GetEntitiesPropertyRequest';
  }
}

// @Route("/customer/{customerId}/entity/{Id}/groups", "GET")
// @DataContract
export class GetItemEntityGroupsRequest
  implements IReturn<GetEntityGroupsResponse>, ICustomerItemRequest
{
  // @DataMember
  public id: string;

  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public entityGroups: string[];

  // @DataMember
  public itemsIds: string[];

  // @DataMember
  public onlyOwnedItems: boolean;

  // @DataMember
  public page: number;

  // @DataMember
  public items: number;

  // @DataMember
  public sort: string;

  // @DataMember
  public filter: string;

  public constructor(init?: Partial<GetItemEntityGroupsRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new GetEntityGroupsResponse();
  }
  public getTypeName() {
    return 'GetItemEntityGroupsRequest';
  }
}

// @Route("/entities/remoteRepository/{repositoryId}/data", "GET")
// @DataContract
export class GetEntitiesRequest implements IReturn<GetEntitiesResponse> {
  // @DataMember
  public repositoryId: string;

  // @DataMember
  public modified?: string;

  // @DataMember
  public page: number;

  // @DataMember
  public items: number;

  // @DataMember
  public sort: string;

  // @DataMember
  public filter: string;

  public constructor(init?: Partial<GetEntitiesRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new GetEntitiesResponse();
  }
  public getTypeName() {
    return 'GetEntitiesRequest';
  }
}

// @Route("/auth")
// @Route("/auth/{provider}")
// @DataContract
export class Authenticate implements IReturn<AuthenticateResponse>, IPost {
  // @DataMember(Order=1)
  public provider: string;

  // @DataMember(Order=2)
  public state: string;

  // @DataMember(Order=3)
  public oauth_token: string;

  // @DataMember(Order=4)
  public oauth_verifier: string;

  // @DataMember(Order=5)
  public userName: string;

  // @DataMember(Order=6)
  public password: string;

  // @DataMember(Order=7)
  public rememberMe?: boolean;

  // @DataMember(Order=9)
  public errorView: string;

  // @DataMember(Order=10)
  public nonce: string;

  // @DataMember(Order=11)
  public uri: string;

  // @DataMember(Order=12)
  public response: string;

  // @DataMember(Order=13)
  public qop: string;

  // @DataMember(Order=14)
  public nc: string;

  // @DataMember(Order=15)
  public cnonce: string;

  // @DataMember(Order=16)
  public useTokenCookie?: boolean;

  // @DataMember(Order=17)
  public accessToken: string;

  // @DataMember(Order=18)
  public accessTokenSecret: string;

  // @DataMember(Order=19)
  public scope: string;

  // @DataMember(Order=20)
  public meta: { [index: string]: string };

  public constructor(init?: Partial<Authenticate>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new AuthenticateResponse();
  }
  public getTypeName() {
    return 'Authenticate';
  }
}
