import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

import {
  IGisObjectGroupEventService,
  IGisObjectStatus,
} from 'projects/msu-its-web-common/src/utils/gis-object-group';
import { TfmsApiEventService } from './tfms-api-event.service';

@Injectable()
export class CameraPTZGroupEventService implements IGisObjectGroupEventService {
  constructor(private _eventService: TfmsApiEventService) {}

  permissions = ['camera.read'];

  subscribe(customerId: string, groupIds: string[], updateSubject: Subject<IGisObjectStatus[]>) {
    // todo
  }

  unsubscribe() {
    // todo
  }
}
