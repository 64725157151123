import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
  ViewChild,
} from "@angular/core";
import { marker as _ } from "@biesbjerg/ngx-translate-extract-marker";

import { DialogService } from "projects/msu-its-web-common/src/services/dialog.service";

import { WebCameraComponent } from "projects/msu-its-web-common/src/components/camera/web-camera.component";
import { Observable, of } from "rxjs";
import { TokenService } from "projects/msu-its-web-common/src/services/token.service";
import { ParkingCamera } from "../../dtos/parking.dtos";
import { ParkingCameraService } from "../../services/parking-cameras/parking-camera.service";

@Component({
  selector: "parking-camera-popup",
  templateUrl: "./parking-camera-popup.component.html",
  styles: [
    `
      :host {
        height: 100% !important;
        width: 100% !important;
        /* padding: 28px; */
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ParkingCameraPopupComponent implements OnInit, AfterViewInit {
  @Input()
  modelId: string;

  external: boolean = false;

  @Input()
  customerId: string;

  @Input()
  modelInfo: ParkingCamera;

  @Input()
  closeIfNew = true;

  modelPermissions$: Observable<string[]> = of(null);

  @ViewChild("webcamera", { static: false })
  webcamera: WebCameraComponent;

  actions: { name: string; action: string; permissions?: string[] }[] = [
    {
      name: _("PARKING_CAMERAS_EVENTS.TITLE"),
      action: 'events',
      permissions: ['parkingCamera.read'],
    },
  ];

  openInNew() {
    this._dialogService.windowDialog(
      `${this.customerId}/ext/parking-camera/${this.modelId}`,
      "parkingCamera_" + this.modelId,
      "menubar=0,toolbar=0,titlebar=0,location=0,locationbar=0,width=880,height=645,resizable=0"
    );
  }
  // openEdit() {
  //   this._dialogService.windowDialog(
  //     `${this.customerId}/ext/cameras/${this.modelId}/edit`,
  //     'CameraEdit_' + this.modelId,
  //     'width=940,height=756'
  //   );
  // }

  constructor(
    private _cameraService: ParkingCameraService,
    private _changeDetector: ChangeDetectorRef,
    private _dialogService: DialogService,
    private _tokenService: TokenService
  ) {}

  ngAfterViewInit(): void {
    this._cameraService
      .get(this.modelId, this.customerId)
      .subscribe((result) => {
        this.webcamera.url = result.url;
        this._changeDetector.markForCheck();
      });
    // this.webcamera.url = this.modelInfo.url;
    // this._changeDetector.markForCheck();
  }
  ngOnInit() {
    // permissions
    if (this.modelInfo.customerId != this.customerId) {
      // this.modelPermissions$ = this._cameraService.getPermissions(this.modelId, this.customerId);
    }
    // this._updateActionsByPermissions();
  }

  // private _updateActionsByPermissions() {
  //   this.modelPermissions$.subscribe((m) => {
  //     this.actions = this.actions.filter((a) =>
  //       this._tokenService.hasPermissionsForItem(this.modelInfo, this.customerId, a.permissions, m)
  //     );
  //   });
  // }

  events() {
    // const model = this.dataSource.data.find((m) => m.id === id);
    // const dialog = this.dialogService.dialog.open(
    //   ParkingCameraEventsComponent,
    //   {
    //     disableClose: false,
    //     minHeight: "300px",
    //     minWidth: "500px",
    //   }
    // );
    // dialog.componentInstance.customerId = this.customerId;
    // dialog.componentInstance.modelId = model.id;
    this._dialogService.windowDialog(
      `${this.customerId}/ext/parking-camera/${this.modelId}/events`,
      "parkingCamera_" + this.modelId,
      "menubar=0,toolbar=0,titlebar=0,location=0,locationbar=0,width=880,height=645,resizable=0"
    );
  }
}
