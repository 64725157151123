import { marker as _ } from "@biesbjerg/ngx-translate-extract-marker";

import {
  Component,
  ChangeDetectionStrategy,
  Injector,
  Inject,
  Input,
} from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import { ActivatedRoute } from "@angular/router";

import { APP_ENVIRONMENT } from "projects/msu-its-web-common/src/utils/shared-consts";
import { TableBaseComponent } from "projects/msu-its-web-common/src/components/tables/table-base.component";
import { ITableLayoutRowAction } from "projects/msu-its-web-common/src/components/tables/table-layout.component";

import { FixationEventEditComponent } from "./fixation-event-edit.component";
import {
  FixationEvent,
  FixationEventInfo,
  FixationPoint,
  ViolationType,
} from "projects/msu-its-web-ftv/src/dtos/ftv.dtos";
import { FIXATION_VIOLATION_TYPE } from "../../dtos/enum";
import { FixationEventService } from "../../services/fixation-event/fixation-event.service";
import { FormControl, FormGroup } from "@angular/forms";
import { BehaviorSubject, NEVER, Subject } from "rxjs";
import { catchError, debounceTime, takeUntil } from "rxjs/operators";
import { FixationPointService } from "../../services/fixation-point/fixation-point.service";
import { SettingsService } from "projects/msu-its-web-common/src/services/settings.service";
import { DatePipe } from "@angular/common";

@Component({
  selector: "fixation-event-ftv",
  templateUrl: "./fixation-events.component.html",
  styles: [
    `
      .mat-column-description {
        flex: 2;
      }

      ::ng-deep .mat-select-panel {
        max-width: fit-content !important;
      }
      .date-button {
        pointer-events: none;
        font-size: 16px;
        position: absolute;
        right: 0;
        width: 20px;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FixationEventsComponent extends TableBaseComponent<
  FixationEvent,
  FixationEventInfo
> {
  _destroy = new Subject();

  @Input()
  objectFixationPoint?: FixationPoint;
  @Input()
  customerExternalId?: string;
  @Input()
  external? = false;

  startDateControl = new FormControl("");
  endDateControl = new FormControl("");

  displayedColumns = [
    "select",
    "eventDate",
    "licensePlate",
    "violationType",
    "actions",
  ];

  rowActions: ITableLayoutRowAction[] = [
    {
      action: "edit",
      name: _("FIXATING_TRAFFIC_EVENTS.SHOW_DETAILS"),
      icon: "edit",
      permissions: ["fixationDevice.read"],
    },
  ];

  get locale() {
    return this.translate.currentLang;
  }
  get timezone() {
    return this._settingsService.timezone;
  }

  customerId: string;

  fixationViolationTypeControl = new FormControl("");

  fixationViolationType = FIXATION_VIOLATION_TYPE;

  searchInputFixationPointSubject = new Subject<string>();
  fixationPointListSubject = new BehaviorSubject<FixationPoint[]>([]);
  selectedFixationPoint: FixationPoint;
  fixationPointIdControl = new FormControl("");

  msgAddSaved = _("FIXATING_VIOLATION_DEVICES.CONTROLLER_ADDED");
  msgEditSaved = _("COMMON.CHANGES_SAVED");
  msgDeleteConfirm = _("FIXATING_VIOLATION_DEVICES.CONTROLLER_DELETE?");
  msgDeleteSuccess = _("FIXATING_VIOLATION_DEVICES.CONTROLLER_DELETED");
  msgDeleteSelectedConfirm = _(
    "FIXATING_VIOLATION_DEVICES.CONTROLLERS_DELETE?"
  );
  msgDeleteSelectedSuccess = _(
    "FIXATING_VIOLATION_DEVICES.CONTROLLERS_DELETED"
  );

  controllerTypeNames = {};

  constructor(
    injector: Injector,
    private _route: ActivatedRoute,
    private _fixationPointService: FixationPointService,
    private _settingsService: SettingsService,
    @Inject(APP_ENVIRONMENT) private _appEnv: any
  ) {
    super(injector, injector.get(FixationEventService));
  }

  ngOnInit() {
    const temp = new Date();
    const twoWeekAge = new Date(
      temp.getFullYear(),
      temp.getMonth(),
      temp.getDate() - 14
    );
    this.endDateControl.setValue(
      new DatePipe(null).transform(
        temp,
        "YYYY-MM-ddT23:59",
        this.settingsService.timezone,
        this.translate.currentLang
      )
    );
    this.startDateControl.setValue(
      new DatePipe(null).transform(
        twoWeekAge,
        "YYYY-MM-ddT00:00",
        this.settingsService.timezone,
        this.translate.currentLang
      )
    );

    this.customerId = this.external
      ? this.customerExternalId
      : this._route.snapshot.paramMap.get("customerId");
    if (this.external) {
      this.rowActions = [
        {
          action: "edit",
          name: _("FIXATING_TRAFFIC_EVENTS.SHOW_DETAILS"),
          icon: "edit",
          // permissions: ["fixationDevice.update"],
        },
      ];
    }

    this.dataSource.requestParams = {
      customerId: this.customerId,
      fixationPointId: this.objectFixationPoint
        ? this.objectFixationPoint.id
        : "",
      violationType: this.fixationViolationTypeControl.value,
      start: this.startDateControl.value
        ? new Date(this.startDateControl.value + this.timezone).toISOString()
        : null,
      end: this.endDateControl.value
        ? new Date(this.endDateControl.value + this.timezone).toISOString()
        : null,
    };

    this.fixationPointIdControl.valueChanges.subscribe(() => {
      this._setDataSourceParams();
    });
    this.fixationViolationTypeControl.valueChanges.subscribe(() => {
      this._setDataSourceParams();
    });

    this.startDateControl.valueChanges.subscribe(() => {
      this._setDataSourceParams();
    });
    this.endDateControl.valueChanges.subscribe(() => {
      this._setDataSourceParams();
    });

    this.dataSource.reloadData();

    this.searchInputFixationPointSubject
      .pipe(debounceTime(250))
      .pipe(takeUntil(this._destroy))
      .subscribe((search) => {
        this._loadFixationPoints(search);
      });

    this._loadFixationPoints("", 11);
  }

  _setDataSourceParams() {
    this.dataSource.requestParams = {
      customerId: this.customerId,
      fixationPointId: this.external
        ? this.objectFixationPoint
          ? this.objectFixationPoint.id
          : ""
        : this.fixationPointIdControl.value,
      violationType: this.fixationViolationTypeControl.value,
      start: this.startDateControl.value
        ? new Date(this.startDateControl.value + this.timezone).toISOString()
        : null,
      end: this.endDateControl.value
        ? new Date(this.endDateControl.value + this.timezone).toISOString()
        : null,
    };

    this.dataSource.reloadData();
  }

  _loadFixationPoints(search: string, items?: number) {
    this._fixationPointService
      .getList(this.customerId, search, [""], items || 0)
      .pipe(catchError(() => NEVER))
      .subscribe((result) => {
        this.fixationPointListSubject.next(result);
        this.changeDetector.markForCheck();
      });
  }

  fixationPointSelected(controllerId) {
    const controllers = this.fixationPointListSubject.getValue();
    this.selectedFixationPoint = controllers.find((m) => m.id === controllerId);
    this.fixationPointListSubject.next(controllers);
    this.changeDetector.markForCheck();
  }

  getNew() {
    return new FixationEvent({
      customerId: this.customerId,
    });
  }

  getEdit(id) {
    return this.dataService.get(id, this.customerId);
  }

  getEditDialog(): MatDialogRef<any> {
    const dialog = this.dialogService.dialog.open(FixationEventEditComponent, {
      disableClose: true,
    });
    // dialog.id = "";
    dialog.componentInstance.dialogId = dialog.id;
    dialog.componentInstance.customerId = this.customerId;
    return dialog;
  }

  getTerminalType(item: ViolationType) {
    const temp = FIXATION_VIOLATION_TYPE.find((m) => item === m.type)?.name;
    return temp || "Неизвестно";
  }
}
