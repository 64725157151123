import { Inject, Injectable } from "@angular/core";

import { Observable, of } from "rxjs";
import { map } from "rxjs/operators";

import {
  IDataSourceService,
  setPageParams,
  getPageReturn,
  IPageParams,
  IPageReturn,
} from "projects/msu-its-web-common/src/data/data-source";

import { FtvApiService } from "../ftv-api.service";

import { APP_ENVIRONMENT } from "projects/msu-its-web-common/src/utils/shared-consts";
import { TokenService } from "projects/msu-its-web-common/src/services/token.service";
import {
  WeightControlPoint,
  GetWeightControlPointsRequest,
  GetWeightControlPointRequest,
  CreateWeightControlPointRequest,
  UpdateWeightControlPointRequest,
  DeleteWeightControlPointsRequest,
  GetWeightControlPointExistRequest,
  FixationPointChangeCustomerRequest,
  GetFixationEventImagesRequest,
} from "../../dtos/ftv.dtos";

@Injectable()
export class WeightControlPointService
  implements IDataSourceService<WeightControlPoint, WeightControlPoint> {
  constructor(
    private _api: FtvApiService,
    private _tokenService: TokenService,
    @Inject(APP_ENVIRONMENT) private _appEnv
  ) {}

  getPagedList(
    params: IPageParams,
    customerId: string
  ): Observable<IPageReturn<WeightControlPoint>> {
    if (!customerId) {
      return of(null);
    }

    const request = new GetWeightControlPointsRequest();
    request.customerId = customerId;

    setPageParams(request, params);
    return this._api.get(request).pipe(
      map((m) => {
        return getPageReturn(m);
      })
    );
  }
  getList(
    customerId: string,
    filter?: string,
    groupIds?: string[],
    items?: number
  ): Observable<WeightControlPoint[]> {
    const request = new GetWeightControlPointsRequest();
    request.customerId = customerId;
    request.entityGroups = groupIds || [];

    request.page = 0;
    request.items = items || 0;
    request.sort = "name";
    request.filter = filter || "";

    return this._api.get(request).pipe(
      map((m) => {
        return m.items;
      })
    );
  }

  get(id: string, customerId: string) {
    const request = new GetWeightControlPointRequest();
    request.id = id;
    request.customerId = customerId;
    return this._api.get(request);
  }

  add(
    model: WeightControlPoint,
    params: {
      customerId: string;
      groups: string[];
      updateGroups: boolean;
    }
  ) {
    const request = new CreateWeightControlPointRequest();
    request.entity = model;
    request.customerId = params.customerId;
    request.entityGroups = params.groups;
    request.updateEntityGroups = params.updateGroups;
    return this._api.post(request);
  }
  update(
    model: WeightControlPoint,
    params: { customerId: string; groups: string[]; updateGroups: boolean }
  ) {
    const request = new UpdateWeightControlPointRequest();
    request.id = model.id;
    request.entity = model;
    request.customerId = params.customerId;
    request.entityGroups = params.groups;
    request.updateEntityGroups = params.updateGroups;
    return this._api.put(request);
  }
  delete(ids: string[], customerId: string) {
    const request = new DeleteWeightControlPointsRequest();
    request.ids = ids;
    request.customerId = customerId;
    return this._api.delete(request);
  }
  exist(name: string, customerId: string): Observable<boolean> {
    const request = new GetWeightControlPointExistRequest({
      name,
      customerId,
    });
    return this._api.get(request);
  }

  // getStatus(id: string, customerId: string) {
  //   const request = new GetFixationPointStatusRequest();
  //   request.id = id;
  //   request.customerId = customerId;
  //   return this._api.get(request);
  // }

  // getControllerList(
  //   filter: string,
  //   customerId: string,
  //   items = 0,
  //   controllerType?: ParkingControllerType,
  //   onlyFree?: boolean
  // ) {
  //   const request = new GetParkingControllersRequest();

  //   request.customerId = customerId;
  //   request.filter = filter;
  //   request.controllerType = controllerType || ParkingControllerType.None;
  //   request.onlyFree = onlyFree || false;
  //   request.page = 0;
  //   request.items = items;

  //   return this._api.get(request).pipe(map((m) => m.items));
  // }

  changeCustomer(
    id: string,
    customerId: string,
    params: { newCustomerId: string; description: string }
  ) {
    const request = new FixationPointChangeCustomerRequest();
    request.id = id;
    request.customerId = customerId;
    request.description = params.description;
    request.newCustomerId = params.newCustomerId;
    return this._api.post(request);
  }

}
