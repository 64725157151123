import { Injectable } from "@angular/core";

import { Observable, of } from "rxjs";
import { map } from "rxjs/operators";

import {
  IDataSourceService,
  setPageParams,
  getPageReturn,
  IPageParams,
  IPageReturn,
} from "projects/msu-its-web-common/src/data/data-source";
import { CreateMaintenanceVehicleRequest, DeleteMaintenanceVehiclesRequest, GetMaintenanceVehicleExistRequest, GetMaintenanceVehicleRequest, GetMaintenanceVehiclesRequest, MaintenanceVehicle, UpdateMaintenanceVehicleRequest } from "../../dtos/rmmd.dtos";
import { RmmdApiService } from "../rmmd-api.service";

@Injectable()
export class VehicleService
  implements IDataSourceService<MaintenanceVehicle, MaintenanceVehicle> {
  constructor(private _api: RmmdApiService) {}

  getPagedList(
    params: IPageParams,
    customerId: string
  ): Observable<IPageReturn<MaintenanceVehicle>> {
    if (!customerId) {
      return of(null);
    }

    const request = new GetMaintenanceVehiclesRequest();
    request.customerId = customerId;

    setPageParams(request, params);
    return this._api.get(request).pipe(
      map((m) => {
        return getPageReturn(m);
      })
    );
  }
  getList(
    customerId: string,
    filter?: string,
    groupIds?: string[]
  ): Observable<MaintenanceVehicle[]> {
    const request = new GetMaintenanceVehiclesRequest();
    request.customerId = customerId;
    request.entityGroups = groupIds || [];

    request.page = 0;
    request.items = 0;
    request.sort = "number";
    request.filter = filter || "";

    return this._api.get(request).pipe(
      map((m) => {
        return m.items;
      })
    );
  }

  get(id: string, customerId: string) {
    const request = new GetMaintenanceVehicleRequest();
    request.id = id;
    request.customerId = customerId;
    return this._api.get(request);
  }
  add(
    model: MaintenanceVehicle,
    params: {
      customerId: string;
      groups: string[];
      updateGroups: boolean;
    }
  ) {
    const request = new CreateMaintenanceVehicleRequest();
    request.entity = model;
    request.customerId = params.customerId;
    request.entityGroups = params.groups;
    request.updateEntityGroups = params.updateGroups;
    return this._api.post(request);
  }
  update(
    model: MaintenanceVehicle,
    params: { customerId: string; groups: string[]; updateGroups: boolean }
  ) {
    const request = new UpdateMaintenanceVehicleRequest();
    request.id = model.id;
    request.entity = model;
    request.customerId = params.customerId;
    request.entityGroups = params.groups;
    request.updateEntityGroups = params.updateGroups;
    return this._api.put(request);
  }
  delete(ids: string[], customerId: string) {
    const request = new DeleteMaintenanceVehiclesRequest();
    request.ids = ids;
    request.customerId = customerId;
    return this._api.delete(request);
  }
  exist(number: string, customerId: string): Observable<boolean> {
    const request = new GetMaintenanceVehicleExistRequest({
      number,
      customerId,
    });
    return this._api.get(request);
  }
}
