import { Injectable } from '@angular/core';

import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import {
  IDataSourceService,
  setPageParams,
  getPageReturn,
  IPageParams,
  IPageReturn,
} from 'projects/msu-its-web-common/src/data/data-source';

import {
  CreateParkingRequest,
  DeleteParkingsRequest,
  Parking,
  ParkingInfo,
  GetParkingRequest,
  GetParkingsRequest,
  UpdateParkingRequest,
  GetParkingControllersRequest,
  // GetParkingControllerRequest,
  ParkingControllerType,
  GetParkingExistRequest,
  // ParkingChangeCustomerRequest,
  DetachParkingControllerRequest,
  // GetParkingCurrentUserPermissionsRequest,
  // GetControllerEventTypesRequest,
} from '../dtos/parking.dtos';

import { ParkingApiService } from './parking-api.service';

@Injectable()
export class ParkingService implements IDataSourceService<Parking, ParkingInfo> {
  constructor(private _api: ParkingApiService) {}

  getPagedList(params: IPageParams, customerId: string): Observable<IPageReturn<ParkingInfo>> {
    if (!customerId) {
      return of(null);
    }

    const request = new GetParkingsRequest();
    request.customerId = customerId;

    setPageParams(request, params);
    return this._api.get(request).pipe(
      map((m) => {
        return getPageReturn(m);
      })
    );
  }
  getList(customerId: string, filter?: string, groupIds?: string[]): Observable<ParkingInfo[]> {
    const request = new GetParkingsRequest();
    request.customerId = customerId;
    request.entityGroups = groupIds || [];

    request.page = 0;
    request.items = 0;
    request.sort = 'name';
    request.filter = filter || '';

    return this._api.get(request).pipe(
      map((m) => {
        return m.items;
      })
    );
  }

  get(id: string, customerId: string) {
    const request = new GetParkingRequest();
    request.id = id;
    request.customerId = customerId;
    return this._api.get(request);
  }
  add(
    model: Parking,
    params: {
      customerId: string;
      groups: string[];
      updateGroups: boolean;
    }
  ) {
    const request = new CreateParkingRequest();
    request.entity = model;
    request.customerId = params.customerId;
    request.entityGroups = params.groups;
    request.updateEntityGroups = params.updateGroups;
    return this._api.post(request);
  }
  update(model: Parking, params: { customerId: string; groups: string[]; updateGroups: boolean }) {
    const request = new UpdateParkingRequest();
    request.id = model.id;
    request.entity = model;
    request.customerId = params.customerId;
    request.entityGroups = params.groups;
    request.updateEntityGroups = params.updateGroups;
    return this._api.put(request);
  }
  delete(ids: string[], customerId: string) {
    const request = new DeleteParkingsRequest();
    request.ids = ids;
    request.customerId = customerId;
    return this._api.delete(request);
  }
  exist(name: string, customerId: string): Observable<boolean> {
    const request = new GetParkingExistRequest({ name, customerId });
    return this._api.get(request);
  }

  getControllerList(
    filter: string,
    customerId: string,
    items = 0,
    controllerType?: ParkingControllerType,
    onlyFree?: boolean
  ) {
    const request = new GetParkingControllersRequest();

    request.customerId = customerId;
    request.filter = filter;
    request.controllerType = controllerType || ParkingControllerType.None;
    request.onlyFree = onlyFree || false;
    request.page = 0;
    request.items = items;

    return this._api.get(request).pipe(map((m) => m.items));
  }

  // changeCustomer(
  //   id: string,
  //   customerId: string,
  //   params: { newCustomerId: string; description: string }
  // ) {
  //   const request = new ParkingChangeCustomerRequest();
  //   request.id = id;
  //   request.customerId = customerId;
  //   request.description = params.description;
  //   request.newCustomerId = params.newCustomerId;
  //   return this._api.post(request);
  // }

  detachController(id: string, customerId: string, description: string) {
    const request = new DetachParkingControllerRequest();
    request.id = id;
    request.description = description;
    request.customerId = customerId;
    return this._api.post(request);
  }

  // getPermissions(id: string, customerId: string) {
  //   const request = new GetParkingCurrentUserPermissionsRequest();
  //   request.id = id;
  //   request.customerId = customerId;
  //   return this._api.get(request);
  // }

  // getControllerEventTypes(id: string, customerId: string) {
  //   const request = new GetControllerEventTypesRequest();
  //   request.id = id;
  //   request.customerId = customerId;
  //   return this._api.get(request);
  // }
}
