import { AfterViewInit, ChangeDetectionStrategy, Component, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { WebCameraComponent } from 'projects/msu-its-web-common/src/components/camera/web-camera.component';

import { DialogService } from 'projects/msu-its-web-common/src/services/dialog.service';
import { CameraService } from '../../services/camera.service';
import { CameraPopupComponent } from '../cameras/camera-popup.componen';

@Component({
  selector: 'camera-external',
  template: `<web-camera #webcamera></web-camera>`,
  styles: [
    `
      :host {
        flex: 1;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CameraExternalComponent implements AfterViewInit {
  objectId;
  customerId;

  @ViewChild('webcamera', { static: false })
  webcamera: WebCameraComponent;

  constructor(
    private _route: ActivatedRoute,
    private _cameraService: CameraService,
    private _dialogService: DialogService,
    private _titleService: Title
  ) {
    this.objectId = this._route.snapshot.paramMap.get('objectId');
    this.customerId = this._route.snapshot.paramMap.get('customerId');
  }

  ngAfterViewInit() {
    this._cameraService.get(this.objectId, this.customerId).subscribe((model) => {
      // const dialog = this._dialogService.dialog.open(CameraPopupComponent, {
      //   disableClose: true,
      //   panelClass: 'external-dialog',
      // });
      // console.log('external');
      // dialog.componentInstance.modelInfo = model;
      // dialog.componentInstance.customerId = this.customerId;
      // dialog.componentInstance.external = true;
      // dialog.componentInstance.modelId = this.objectId;
      this.webcamera.url = model.url;
      this._titleService.setTitle(`${model.name}`);
    });
  }
}
