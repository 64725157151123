import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';

import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { DialogService } from 'projects/msu-its-web-common/src/services/dialog.service';

import { IGisObjectStateComponent } from 'projects/msu-its-web-common/src/utils/gis-object-group';
import {
  GreenStreetStep,
  StepMovements,
  TrafficObjectSceneStatus,
  TrafficObjectStatus,
  TrafficObjectSwitchMode,
  TrafficScene,
  TrafficSceneControlMode,
  TrafficSceneStatus,
} from '../../dtos/tss.dtos';
import { TrafficObjectService } from 'projects/msu-its-web-tlc/src/services/traffic-object.service';
import {
  TrafficControllerType,
  TrafficObjectInfo,
  TrafficObjectMode,
  UsdkSettings,
} from 'projects/msu-its-web-tlc/src/dtos/tlc.dtos';
import { BehaviorSubject, forkJoin, of, Subject, timer } from 'rxjs';
import { SchemaSet, SchemaUtils } from 'projects/msu-its-web-tlc/src/dtos/schema';
import { finalize, takeUntil, tap } from 'rxjs/operators';
import { UsdkTrafficObjectService } from 'projects/msu-its-web-tlc/src/services/usdk-traffic-object.service';
import { SettingsService } from 'projects/msu-its-web-common/src/services/settings.service';
import { LayoutSvgUtils } from 'projects/msu-its-web-tlc/src/dtos/layout';

import { TrafficSceneService } from '../../services/traffic-scene.service';
import { TRAFFIC_OBJECT_SWITCH_TYPES } from '../../dtos/enums';
import { getColor } from 'projects/msu-its-web-common/src/utils/colors';
import { PotokPlan } from 'projects/msu-its-web-tlc/src/dtos/potok/potok-plan';
import { TrafficSceneAnalysisComponent } from './traffic-scene-analysis.component';

declare const L;

@Component({
  selector: 'traffic-scene-state',
  templateUrl: './traffic-scene-state.component.html',
  styleUrls: ['./traffic-scene-state.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TrafficSceneStateComponent
  implements IGisObjectStateComponent, OnDestroy, AfterViewInit, OnInit
{
  _destroy = new Subject();

  loading = false;

  readonly statusSubject = new BehaviorSubject<TrafficSceneStatus>(null);

  get status() {
    return this.statusSubject.getValue();
  }
  set status(value) {
    this.statusSubject.next(value);
  }

  @Input()
  modelId: string;

  @Input()
  customerId: string;

  @Input()
  modelInfo: TrafficScene;

  @Input()
  mapLayers: Map<string, any>;

  actions: { name: string; action: string }[] = [
    { name: _('COMMON.EDIT'), action: 'edit' },
    { name: _('TRAFFIC_SCENES.ANALYSIS'), action: 'analysis' },
  ];

  switchType = TrafficObjectSwitchMode.Parallel;
  switchTypes = TRAFFIC_OBJECT_SWITCH_TYPES;

  modeTime = 300;
  forwardDirection = true;

  _objects = new Map<string, TrafficObjectInfo>();
  _steps = new Map<string, GreenStreetStep>();
  _schemaSets = new Map<string, SchemaSet>();
  _phasesSettings = new Map<string, UsdkSettings>();

  _statusesSvgs = new Map<string, any>();

  constructor(
    private _dialogService: DialogService,
    private _settingsService: SettingsService,
    private _trafficSceneService: TrafficSceneService,
    private _trafficObjectService: TrafficObjectService,
    private _usdkTrafficObjectService: UsdkTrafficObjectService,
    private _changeDetector: ChangeDetectorRef
  ) {}

  ngOnInit() {
    // update steps
    this.modelInfo.settings.greenStreet.steps.forEach((step) => {
      this._steps.set(step.trafficObjectId, step);
    });

    // get status by timer
    this._getStatus(1000);
  }

  ngAfterViewInit() {
    this._trafficObjectService.getList(this.customerId).subscribe((trafficObjects) => {
      trafficObjects.forEach((m) => this._objects.set(m.id, m));
      this._updateGreenSteps();
    });

    this._updateTrafficObjectStatuses();
  }

  ngOnDestroy() {
    this._resetTrafficObjectStatuses();
    this._destroy.next();
    this._destroy.complete();
    this._destroy = null;
  }

  _getStatus(duration: number) {
    this._trafficSceneService
      .getStatus(this.modelId, this.customerId)
      .pipe(takeUntil(this._destroy))
      .pipe(
        finalize(
          () =>
            duration &&
            timer(duration)
              .pipe(takeUntil(this._destroy))
              .subscribe(() => this._destroy && this._getStatus(duration))
        )
      )
      .subscribe((status) => {
        this.status = status;

        if (status.modeTime !== undefined) {
          this.modeTime = status.modeTime;
        }

        if (this.modeEnabled) {
          this.forwardDirection = status.forwardDirection;
        }

        this.updateChanges();
      });
  }

  _updateGreenSteps() {
    // check and add new
    const forkObservables = [];
    this.modelInfo.settings.trafficObjects.forEach((m) => {
      // check step
      if (!this._steps.has(m.trafficObjectId)) {
        const greenStep = new GreenStreetStep({
          forwardMovements: new StepMovements({ movements: [] }),
          forwardRedMovements: new StepMovements({ movements: [] }),
          backwardMovements: new StepMovements({ movements: [] }),
          backwardRedMovements: new StepMovements({ movements: [] }),
          trafficObjectId: m.trafficObjectId,
        });
        this._steps.set(m.trafficObjectId, greenStep);
      }
      //check settings
      if (!this._schemaSets.has(m.trafficObjectId)) {
        // schemaSet
        const schemaSetObservable = this._trafficObjectService.getSchemaSet(
          m.trafficObjectId,
          this.customerId
        );

        // usdk phases settings
        const phasesSettingsObservable =
          this._objects.get(m.trafficObjectId).controllerType == TrafficControllerType.Usdk
            ? this._usdkTrafficObjectService.getSettings(m.trafficObjectId, this.customerId)
            : of(null);

        forkObservables.push(
          forkJoin([schemaSetObservable, phasesSettingsObservable]).pipe(
            tap((result) => {
              this._schemaSets.set(m.trafficObjectId, result[0]);
              result[1] && this._phasesSettings.set(m.trafficObjectId, result[1]);
            })
          )
        );
      }
    });

    forkJoin(forkObservables).subscribe(() => {
      this.updateChanges();
    });
  }

  toggleGroupChange(event) {
    const toggle = event.source;
    if (toggle && this.status) {
      const group = toggle.buttonToggleGroup;
      group.value = this.status.controlMode;
    }
  }

  getStep(id: string) {
    return this._steps.get(id);
  }
  getObjectName(id: string) {
    return this._objects.get(id)?.name || '';
  }
  getSchemaSet(id: string) {
    return this._schemaSets.get(id);
  }
  getPlanLength(id: string) {
    return this.status.trafficObjectStatuses.find((m) => m.id == id)?.adaptivePlan?.length;
  }

  stepSvgLoad = (target, objectId: string, stepMovements: StepMovements) => {
    this._updateStepSvg(target, objectId, stepMovements);
  };

  _updateStepSvg(target, objectId: string, stepMovements: StepMovements) {
    const object = this._objects.get(objectId);
    const settings = this._schemaSets.get(objectId);

    const schema = settings.schema;
    const schemaView = settings.schemaView;
    const isDark = this._settingsService.darkTheme;
    const svg = target.contentDocument;

    const phaseMovements = [];
    switch (object.controllerType) {
      case TrafficControllerType.Potok:
        phaseMovements.push(...stepMovements.movements);
        break;
      case TrafficControllerType.Usdk:
        const phase = this._phasesSettings
          .get(objectId)
          ?.phases.find((m) => m.id == stepMovements.phase);
        phase && phaseMovements.push(...phase?.movements);
        break;
      default:
        break;
    }

    const movements = [];
    phaseMovements.forEach((m) => {
      movements.push(...SchemaUtils.schemaViewMovements(schema, m));
    });

    LayoutSvgUtils.updateLayoutSchema(svg, schema, schemaView, isDark);
    LayoutSvgUtils.showLayoutMovements(svg, schema, movements);
  }

  private _getExternalUrlBase() {
    return `${this.customerId}/ext/traffic-scene/${this.modelId}/`;
  }
  edit() {
    this._dialogService.windowDialog(
      this._getExternalUrlBase() + 'edit',
      'trafficSceneEdit_' + this.modelId,
      'menubar=0,toolbar=0,titlebar=0,location=0,locationbar=0,width=1400,height=800,resizable=0'
    );
  }

  get objects() {
    const result = [...this.modelInfo.settings.trafficObjects];
    return this.forwardDirection ? result : result.reverse();
  }

  updateChanges() {
    this._updateTrafficObjectStatuses();
    this._statusesSvgs.forEach((m, id) => m?.contentDocument && this._updateStatusSvg(m, id));
    this._changeDetector.markForCheck();
  }

  startGreenStreetMode() {
    this.loading = true;
    this._changeDetector.markForCheck();

    this._trafficSceneService
      .startGreenStreetMode(
        this.modelId,
        this.customerId,
        this.forwardDirection,
        this.switchType,
        this.modeTime
      )
      .pipe(
        finalize(() => {
          this.loading = false;
          this._changeDetector.markForCheck();
        })
      )
      .subscribe((status) => {
        this.status = status;
        this.updateChanges();
      });
  }
  startRedStreetMode() {
    this.loading = true;
    this._changeDetector.markForCheck();

    this._trafficSceneService
      .startRedStreetMode(
        this.modelId,
        this.customerId,
        this.forwardDirection,
        this.switchType,
        this.modeTime
      )
      .pipe(
        finalize(() => {
          this.loading = false;
          this._changeDetector.markForCheck();
        })
      )
      .subscribe((status) => {
        this.status = status;
        this.updateChanges();
      });
  }
  startAdaptiveMode() {
    this.loading = true;
    this._changeDetector.markForCheck();

    this._trafficSceneService
      .startAdaptiveMode(
        this.modelId,
        this.customerId,
        this.forwardDirection,
        this.switchType,
        this.modeTime
      )
      .pipe(
        finalize(() => {
          this.loading = false;
          this._changeDetector.markForCheck();
        })
      )
      .subscribe((status) => {
        this.status = status;
        this.updateChanges();
      });
  }
  stopControlMode() {
    this.loading = true;
    this._changeDetector.markForCheck();

    this._trafficSceneService
      .stopControlMode(this.modelId, this.customerId)
      .pipe(
        finalize(() => {
          this.loading = false;
          this._changeDetector.markForCheck();
        })
      )
      .subscribe((status) => {
        this.status = status;
        this.updateChanges();
      });
  }

  startManual(objectId: string, mode: TrafficSceneControlMode) {
    this._trafficSceneService
      .startManual(
        this.modelId,
        this.customerId,
        objectId,
        mode,
        this.forwardDirection,
        this.modeTime
      )
      .subscribe((status) => {
        this.status = status;
        this.updateChanges();
      });
  }
  stopManual(objectId: string) {
    this._trafficSceneService
      .stopManual(this.modelId, this.customerId, objectId)
      .subscribe((status) => {
        this.status = status;
        this.updateChanges();
      });
  }

  get modeTimeValue() {
    return this.status?.modeTime !== undefined ? this.status.modeTime : this.modeTime;
  }

  get modeDuration() {
    // if (!this.status) return '';

    const currentTime = this.modeEnabled ? this.status?.serverTime || 0 : 0;
    const startTime = this.modeEnabled ? this.status?.controlModeStartTime || 0 : 0;

    const start = new Date(startTime).getTime();
    const end = new Date(currentTime).getTime();

    const duration = Math.floor((end - start) / 1000);

    // const parts = [
    //   Math.floor(duration / 3600), // hours
    //   Math.floor(duration / 60) % 60, // minutes
    //   Math.floor(duration) % 60, // seconds
    // ];
    // return parts.map((n) => ('0' + n).slice(-2)).join(':');

    return duration ? duration : '';
  }
  get modeEnabled() {
    return this.status && this.status.controlMode != TrafficSceneControlMode.None;
  }

  getObjectMode(id: string) {
    const objectStatus = this.status.trafficObjectStatuses.find((m) => m.id == id);
    return objectStatus.mode;
  }
  getObjectStatus(objectId: string) {
    return this.status?.trafficObjectStatuses.find((m) => m.id == objectId);
  }
  getObjectModeTime(objectStatus: TrafficObjectSceneStatus) {
    if (
      objectStatus?.mode != TrafficObjectMode.Remote ||
      !objectStatus?.controlModeStartTime ||
      !objectStatus?.serverTime
    ) {
      return '';
    }

    const currentTime = objectStatus.serverTime;
    const startTime = objectStatus.controlModeStartTime;

    const start = new Date(startTime).getTime();
    const end = new Date(currentTime).getTime();

    return Math.floor((end - start) / 1000);
  }
  statusSvgLoad(target, objectId: string) {
    this._statusesSvgs.set(objectId, target);
    this._updateStatusSvg(target, objectId);
  }
  _updateStatusSvg(target, objectId: string) {
    const settings = this._schemaSets.get(objectId);

    const schema = settings.schema;
    const schemaView = settings.schemaView;
    const isDark = this._settingsService.darkTheme;
    const svg = target.contentDocument;

    const objectStatus = this.status?.trafficObjectStatuses.find((m) => m.id == objectId);
    const phaseMovements = objectStatus?.schemaLights
      ? SchemaUtils.schemaLightToMovements(schema, objectStatus.schemaLights)
      : [];

    const movements = [];
    phaseMovements.forEach((m) => {
      movements.push(...SchemaUtils.schemaViewMovements(schema, m));
    });

    LayoutSvgUtils.updateLayoutSchema(svg, schema, schemaView, isDark);
    LayoutSvgUtils.showLayoutMovements(svg, schema, movements);
  }

  getColor(color: string) {
    return getColor(color);
  }

  _updateTrafficObjectStatuses() {
    if (!this.mapLayers) return;

    this.objects.forEach((m, i) => {
      const layer = this.mapLayers.get(m.trafficObjectId);
      layer &&
        layer.setStatus({
          radius: 28,
          weight: 7,
          label: { text: i + 1, color: '#fff' },
        });
      layer?.bringToFront();

      if (layer) {
        layer.options.eventHandlers = {
          click: this._clickLayer,
          contextmenu: this._contextmenuLayer,
        };
      }
    });
  }
  _resetTrafficObjectStatuses() {
    if (!this.mapLayers) return;

    this.modelInfo.settings.trafficObjects.forEach((m, i) => {
      const layer = this.mapLayers.get(m.trafficObjectId);
      layer &&
        layer.setStatus({
          radius: 18,
          weight: 6,
          label: undefined,
        });

      delete layer?.options.eventHandlers;
    });
  }

  _clickLayer = (objectId: string) => {
    this.startManual(objectId, TrafficSceneControlMode.GreenStreet);
  };
  _contextmenuLayer = (objectId: string) => {
    this.stopManual(objectId);
  };

  analysis() {
    const dialog = this._dialogService.dialog.open(TrafficSceneAnalysisComponent, {
      disableClose: true,
    });
    dialog.componentInstance.modelId = this.modelId;
    dialog.componentInstance.modelInfo = this.modelInfo;
    dialog.componentInstance.customerId = this.customerId;
    dialog.componentInstance.objects = this._objects;
    dialog.componentInstance.schemaSets = this._schemaSets;
  }
}
