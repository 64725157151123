import { Injectable } from "@angular/core";

import { Observable, of } from "rxjs";
import { map } from "rxjs/operators";

import {
  IDataSourceService,
  setPageParams,
  getPageReturn,
  IPageParams,
  IPageReturn,
} from "projects/msu-its-web-common/src/data/data-source";
import {
  CreateSceneRequest,
  DeleteScenesRequest,
  GetSceneCalculationRequest,
  GetSceneExistRequest,
  GetSceneRequest,
  GetSceneRouteRequest,
  GetSceneStatusRequest,
  GetScenesRequest,
  GetTrafficObjectPlansRequest,
  Scene,
  SceneChangeCustomerRequest,
  UpdateSceneRequest,
} from "../../dtos/modelling.dtos";
import { ModellingApiService } from "../modelling-api.service";

@Injectable()
export class ModelingSceneService implements IDataSourceService<Scene, Scene> {
  constructor(private _api: ModellingApiService) {}

  getPagedList(
    params: IPageParams,
    customerId: string
  ): Observable<IPageReturn<Scene>> {
    if (!customerId) {
      return of(null);
    }

    const request = new GetScenesRequest();
    request.customerId = customerId;

    setPageParams(request, params);
    return this._api.get(request).pipe(
      map((m) => {
        return getPageReturn(m);
      })
    );
  }
  getList(
    customerId: string,
    filter?: string,
    groupIds?: string[],
    items?: number
  ): Observable<Scene[]> {
    const request = new GetScenesRequest();
    request.customerId = customerId;
    request.entityGroups = groupIds || [];

    request.page = 0;
    request.items = items || 0;
    request.sort = "name";
    request.filter = filter || "";

    return this._api.get(request).pipe(
      map((m) => {
        return m.items;
      })
    );
  }

  get(id: string, customerId: string) {
    const request = new GetSceneRequest();
    request.id = id;
    request.customerId = customerId;
    return this._api.get(request);
  }
  getSceneCalculationRequest(id: string, customerId: string, date: string) {
    const request = new GetSceneCalculationRequest();
    request.sceneId = id;
    request.date = date;
    request.customerId = customerId;
    return this._api.get(request);
  }
  getSceneStatusRequest(id: string, customerId: string, date: string) {
    const request = new GetSceneStatusRequest();
    request.sceneId = id;
    request.date = date;
    request.customerId = customerId;
    return this._api.get(request);
  }
  getTrafficObjectPlansRequest(trafficObjectId: string, customerId: string) {
    const request = new GetTrafficObjectPlansRequest();
    request.trafficObjectId = trafficObjectId;
    request.customerId = customerId;
    return this._api.get(request);
  }
  getRoute(
    id: string,
    customerId: string,
    startLat: number,
    startLon: number,
    finishLat: number,
    finishLon: number
  ) {
    const request = new GetSceneRouteRequest();
    request.id = id;
    request.customerId = customerId;
    request.startLat = startLat;
    request.startLon = startLon;
    request.finishLat = finishLat;
    request.finishLon = finishLon;
    return this._api.get(request);
  }

  add(
    model: Scene,
    params: {
      customerId: string;
      groups: string[];
      updateGroups: boolean;
    }
  ) {
    const request = new CreateSceneRequest();
    request.entity = model;
    request.customerId = params.customerId;
    request.entity.customerId = params.customerId;
    request.entityGroups = params.groups;
    request.updateEntityGroups = params.updateGroups;
    return this._api.post(request);
  }
  update(
    model: Scene,
    params: { customerId: string; groups: string[]; updateGroups: boolean }
  ) {
    const request = new UpdateSceneRequest();
    request.id = model.id;
    request.entity = model;
    request.customerId = params.customerId;
    request.entity.customerId = params.customerId;
    request.entityGroups = params.groups;
    request.updateEntityGroups = params.updateGroups;
    return this._api.put(request);
  }
  delete(ids: string[], customerId: string) {
    const request = new DeleteScenesRequest();
    request.ids = ids;
    request.customerId = customerId;
    return this._api.delete(request);
  }
  exist(name: string, customerId: string): Observable<boolean> {
    const request = new GetSceneExistRequest({
      name,
      customerId,
    });
    return this._api.get(request);
  }

  changeCustomer(
    id: string,
    customerId: string,
    params: { newCustomerId: string; description: string }
  ) {
    const request = new SceneChangeCustomerRequest();
    request.id = id;
    request.customerId = customerId;
    request.description = params.description;
    request.newCustomerId = params.newCustomerId;
    return this._api.post(request);
  }
}
