<iframe
  #iframe
  [style.display]="url && !isHls ? 'block' : 'none'"
  class="camera"
  allowfullscreen
  (load)="load()"
></iframe>
<video
  #video
  style="outline: none"
  [style.display]="url && isHls ? 'block' : 'none'"
  class="camera"
  controls
  disablePictureInPicture
  autoplay
  controlsList="nodownload"
></video>
