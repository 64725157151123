import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
} from "@angular/core";
import { Title } from "@angular/platform-browser";
import { ActivatedRoute } from "@angular/router";

import { DialogService } from "projects/msu-its-web-common/src/services/dialog.service";

import { CameraPTZPopupComponent } from "../cameras-ptz/camera-ptz-popup.component";
import { CameraPTZService } from "../../services/camera-ptz.service";

@Component({
  selector: "camera-ptz-external",
  template: ``,
  styles: [
    `
      :host {
        flex: 1;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CameraPTZExternalComponent implements AfterViewInit {
  objectId;
  customerId;


  constructor(
    private _route: ActivatedRoute,
    private _cameraService: CameraPTZService,
    private _dialogService: DialogService,
    private _titleService: Title
  ) {
    this.objectId = this._route.snapshot.paramMap.get("objectId");
    this.customerId = this._route.snapshot.paramMap.get("customerId");
  }

  ngAfterViewInit() {
    this._cameraService
      .get(this.objectId, this.customerId)
      .subscribe((model) => {
        const dialog = this._dialogService.dialog.open(
          CameraPTZPopupComponent,
          {
            disableClose: true,
            panelClass: "external-dialog",
          }
        );
        // console.log("external");
        dialog.componentInstance.modelInfo = model;
        dialog.componentInstance.customerId = this.customerId;
        dialog.componentInstance.external = true;
        dialog.componentInstance.modelId = this.objectId;
        // this.webcamera.url = model.url;
        this._titleService.setTitle(`${model.name}`);
      });
  }
}
