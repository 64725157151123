import { Injectable } from "@angular/core";

import { Observable, of } from "rxjs";
import { map } from "rxjs/operators";

import {
  getPageReturn,
  IDataSourceService,
  IPageParams,
  IPageReturn,
  setPageParams,
} from "projects/msu-its-web-common/src/data/data-source";
import { AccidentApiService } from "../../../../msu-its-web-accident/src/services/accident-api.service";
import { TokenService } from "projects/msu-its-web-common/src/services/token.service";
// import {
//   CreatePtzCameraGroupRequest,
//   DeletePtzCameraGroupsRequest,
//   EntityGroup,
//   EntityGroupInfo,
//   GetPtzCameraGroupRequest,
//   GetPtzCameraGroupsRequest,
//   GetItemEntityGroupsRequest,
//   UpdatePtzCameraGroupRequest,
// } from '../../../../msu-its-web-accident/src/dtos/accident.dtos';
import { IGisObjectGroupService } from "projects/msu-its-web-common/src/utils/gis-object-group";
import { CameraFTPService } from "./camera-ftp.service";
import { EntityGroup, EntityGroupInfo } from "../../dtos/tfms.dtos";
import { TfmsApiService } from "../tfms-api.service";
import { fakeGroups } from "./db/model";

@Injectable()
export class CameraFTPGroupService
  implements
    IDataSourceService<EntityGroup, EntityGroupInfo>,
    IGisObjectGroupService {
  constructor(
    private _api: TfmsApiService,
    private _tokenService: TokenService,
    private _cameraFtpService: CameraFTPService
  ) {}

  permissions = ["camera.read"];

  getObjectGroups(objectId: string, customerId: string) {
    // const request = new GetItemEntityGroupsRequest({
    //   id: objectId,
    //   customerId,
    // });
    return of(fakeGroups).pipe(map((m) => m.items));
  }

  getPagedList(
    params: IPageParams,
    customerId: string
  ): Observable<IPageReturn<EntityGroupInfo>> {
    if (!customerId) {
      return of(null);
    }
    // const request = new GetPtzCameraGroupsRequest();
    // request.customerId = customerId;

    // setPageParams(request, params);
    throw "not implemented";
  }

  getList(customerId: string, filter?: string, owned = false) {
    // const request = new GetPtzCameraGroupsRequest();
    // request.customerId = customerId;

    // request.page = 0;
    // request.items = 0;
    // request.sort = 'name';
    // request.filter = filter;
    // request.onlyOwnedItems = owned;

    return of(fakeGroups).pipe(
      map((m) => {
        return m.items;
      })
    );
  }

  getGroupList(customerId: string) {
    return this.getList(customerId);
  }

  getObjectList(customerId: string, groupIds: string[]) {
    return this._cameraFtpService.getList(customerId, null, groupIds);
  }

  get(id: string, customerId: string): Observable<any> {
    // const request = new GetPtzCameraGroupRequest();
    // request.id = id;
    // request.customerId = customerId;
    // return this._api.get(request);
    return of(fakeGroups).pipe(
      map((item) => {
        return item.items.filter((epic) => epic.id === id)[0];
      })
    );
  }

  add(model: EntityGroup, params: { customerId: string }): Observable<any> {
    // const request = new CreatePtzCameraGroupRequest();
    // request.customerId = params.customerId;
    // request.entity = model;
    // return this._api.post(request);
    throw "not implemented";
  }

  update(model: EntityGroup, params: { customerId: string }): Observable<any> {
    // const request = new UpdatePtzCameraGroupRequest();
    // request.id = model.id;
    // request.entity = model;
    // request.customerId = params.customerId;
    // return this._api.put(request);
    throw "not implemented";
  }

  delete(ids: string[], customerId: string): Observable<number> {
    // const request = new DeletePtzCameraGroupsRequest();
    // request.ids = ids;
    // request.customerId = customerId;
    // return this._api.delete(request);
    throw "not implemented";
  }

  exist(name: string, customerId: string) {
    return of(false);
  }
}
