import { marker as _ } from "@biesbjerg/ngx-translate-extract-marker";
import {
  Component,
  ChangeDetectionStrategy,
  Input,
  OnInit,
} from "@angular/core";

import { ParkingInfo } from "../../dtos/parking.dtos";

import { IGisObjectStateComponent } from "projects/msu-its-web-common/src/utils/gis-object-group";
import { DialogService } from "projects/msu-its-web-common/src/services/dialog.service";
import { Observable, of } from "rxjs";
import { ParkingService } from "../../services/parking.service";
import { TokenService } from "projects/msu-its-web-common/src/services/token.service";
import { FilesComponent } from "projects/msu-its-web-common/src/components/files/files.component";
import { MatDialog } from "@angular/material/dialog";

@Component({
  selector: "parking-state",
  templateUrl: "./parking-state.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ParkingStateComponent implements OnInit, IGisObjectStateComponent {
  @Input()
  modelId: string;

  @Input()
  customerId: string;

  @Input()
  modelInfo: ParkingInfo;

  modelPermissions$: Observable<string[]> = of(null);

  actions: { name: string; action: string; permissions?: string[] }[] = [
    {
      name: _("COMMON.SETTINGS"),
      action: "settings",
      permissions: ["parking.update"],
    },
    { name: _("COMMON.EDIT"), action: "edit", permissions: ["parking.update"] },
    {
      name: _("COMMON.LEGEND"),
      action: "legend",
      permissions: ["parking.read"],
    },
    // { name: _('AUDIT_LOG.TITLE'), action: 'auditLog', permissions: ['parking.read'] },
    // { name: _('EVENT_LOG.TITLE'), action: 'eventLog', permissions: ['parking.read'] },
    // { name: _('COMMON.FILES'), action: 'files', permissions: ['parking.read'] },
  ];

  constructor(
    private _parkingService: ParkingService,
    private _tokenService: TokenService,
    private _dialogService: DialogService,
    public dialog: MatDialog
  ) {}

  ngOnInit() {
    // if (this.modelInfo.customerId != this.customerId) {
    //   this.modelPermissions$ = this._parkingService.getPermissions(
    //     this.modelId,
    //     this.customerId
    //   );
    // }

    this._updateActionsByPermissions();
  }

  private _updateActionsByPermissions() {
    this.modelPermissions$.subscribe((m) => {
      this.actions = this.actions.filter((a) =>
        this._tokenService.hasPermissionsForItem(
          this.modelInfo,
          this.customerId,
          a.permissions,
          m
        )
      );
    });
  }

  private _getExternalUrlBase() {
    return `${this.customerId}/ext/parking/${this.modelId}/`;
  }

  openInNew() {
    this._dialogService.windowDialog(
      this._getExternalUrlBase() + "state",
      "parkingState_" + this.modelId,
      "menubar=0,toolbar=0,titlebar=0,location=0,locationbar=0,width=600,height=810,resizable=0"
    );
  }

  legend() {
    this.dialog.open(MsuPirsLegendDialog);
  }
  edit() {
    this._dialogService.windowDialog(
      this._getExternalUrlBase() + "edit",
      "parkingEdit_" + this.modelId,
      "menubar=0,toolbar=0,titlebar=0,location=0,locationbar=0,width=1000,height=610,resizable=0"
    );
  }

  settings() {
    this._dialogService.windowDialog(
      this._getExternalUrlBase() + "settings",
      "parkingSettings_" + this.modelId,
      "menubar=0,toolbar=0,titlebar=0,location=0,locationbar=0,width=1050,height=700,resizable=0"
    );
  }

  auditLog() {
    this._dialogService.windowDialog(
      this._getExternalUrlBase() + "audit-log",
      "parkingAuditLog_" + this.modelId,
      "menubar=0,toolbar=0,titlebar=0,location=0,locationbar=0,width=950,height=850,resizable=0"
    );
  }

  eventLog() {
    this._dialogService.windowDialog(
      this._getExternalUrlBase() + "event-log",
      "parkingEventLog_" + this.modelId,
      "menubar=0,toolbar=0,titlebar=0,location=0,locationbar=0,width=950,height=850,resizable=0"
    );
  }

  files() {
    const dialog = this._dialogService.dialog.open(FilesComponent, {
      disableClose: false,
    });
    dialog.componentInstance.entityId = this.modelId;
    dialog.componentInstance.entityName = this.modelInfo.name;
  }
}

@Component({
  selector: "msu-pirs-legend",
  templateUrl: "msu-pirs-legend.component.html",
})
export class MsuPirsLegendDialog {}
