import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
} from "@angular/core";
import { ActivatedRoute } from "@angular/router";

import { DialogService } from "projects/msu-its-web-common/src/services/dialog.service";
import { Subject } from "rxjs";
import { FixationEventsComponent } from "../fixation-events/fixation-events.component";
import { FixationPointService } from "../../services/fixation-point/fixation-point.service";

@Component({
  selector: "fixation-events-external",
  template: ``,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FixationEventsExternalComponent
  implements AfterViewInit, OnDestroy {
  private _destroy = new Subject();
  objectId;
  customerId;

  constructor(
    private _route: ActivatedRoute,
    private _fixationPointService: FixationPointService,
    private _dialogService: DialogService
  ) {
    this.objectId = this._route.snapshot.paramMap.get("objectId");
    this.customerId = this._route.snapshot.paramMap.get("customerId");
  }

  ngAfterViewInit() {
    this._fixationPointService
      .get(this.objectId, this.customerId)
      .subscribe((result) => {
        const dialog = this._dialogService.dialog.open(
          FixationEventsComponent,
          {
            disableClose: true,
            panelClass: "external-dialog",
          }
        );
        dialog.componentInstance.objectFixationPoint = result;
        dialog.componentInstance.customerExternalId = this.customerId;
        dialog.componentInstance.external = true;
      });
  }
  ngOnDestroy() {
    this._destroy.next();
    this._destroy.complete();
  }
}
