import { Inject, Injectable } from "@angular/core";
import {
  IDataSourceService,
  IPageParams,
  IPageReturn,
  getPageReturn,
  setPageParams,
} from "projects/msu-its-web-common/src/data/data-source";
import { AsyncSubject, Observable } from "rxjs";
import { map } from "rxjs/operators";
import { APP_ENVIRONMENT } from "projects/msu-its-web-common/src/utils/shared-consts";
import { TokenService } from "projects/msu-its-web-common/src/services/token.service";
import { AccidentApiService } from "../accident-api.service";
import {
  CreateStaticCameraImageRequest,
  DeleteStaticCameraImagesRequest,
  GetStaticCameraImageRequest,
  GetStaticCameraImagesCountRequest,
  GetStaticCameraImagesRequest,
  GetStaticCameraImagesResponse,
  GetStaticCameraLastImageRequest,
  StaticCameraImage,
} from "../../dtos/accident.dtos";

@Injectable()
export class CameraFTPPopupService {
  constructor(
    private _api: AccidentApiService,
    private _tokenService: TokenService,
    @Inject(APP_ENVIRONMENT) private _appEnv
  ) {}

  getStaticCameraImagesRequest(
    page: number,
    size: number,
    customerId: string,
    staticCameraId: string
  ): Observable<GetStaticCameraImagesResponse> {
    const request = new GetStaticCameraImagesRequest();
    request.customerId = customerId;
    request.staticCameraId = staticCameraId;
    request.items = size;
    request.page = page;
    request.sort = "created desc";
    return this._api.get(request);
  }
  getImagesPageRequest(
    params: IPageParams,
    customerId: string,
    staticCameraId: string
  ): Observable<IPageReturn<StaticCameraImage>> {
    const request = new GetStaticCameraImagesRequest();
    request.customerId = customerId;
    request.staticCameraId = staticCameraId;
    setPageParams(request, params);
    return this._api.get(request).pipe(
      map((m) => {
        return getPageReturn(m);
      })
    );
  }
  getList(
    staticCameraId: string,
    customerId: string,
    filter?: string,
    groupIds?: string[],
    items?: number
  ): Observable<{ item: StaticCameraImage[]; total: number }> {
    const request = new GetStaticCameraImagesRequest();
    request.customerId = customerId;
    request.staticCameraId = staticCameraId;
    request.entityGroups = groupIds;
    request.page = 0;
    request.items = items || 0;
    request.sort = "created desc";
    request.filter = filter;

    return this._api.get(request).pipe(
      map((m) => {
        return {
          item: m.items,
          total: m.total,
        };
      })
    );
  }

  getStaticCameraImagesCountRequest(
    staticCameraId: string,
    customerId: string
  ): Observable<number> {
    const request = new GetStaticCameraImagesCountRequest();
    request.customerId = customerId;
    request.staticCameraId = staticCameraId;
    return this._api.get(request);
  }

  getStaticCameraLastImageRequest(
    staticCameraId: any,
    customerId: string
  ): Observable<StaticCameraImage> {
    const request = new GetStaticCameraLastImageRequest();
    request.customerId = customerId;
    request.staticCameraId = staticCameraId;
    return this._api.get(request);
  }
  getImageRequest(id: any, customerId: string): Observable<StaticCameraImage> {
    const request = new GetStaticCameraImageRequest();
    request.customerId = customerId;
    request.id = id;
    return this._api.get(request);
  }

  createImageRequest(model: StaticCameraImage, customerId: string) {
    const request = new CreateStaticCameraImageRequest();
    request.customerId = customerId;
    request.entity = model;
    return this._api.post(request);
  }

  deleteImagesRequest(ids: string[], customerId: string): Observable<number> {
    const request = new DeleteStaticCameraImagesRequest();
    request.customerId = customerId;
    request.ids = ids;
    return this._api.delete(request);
  }

  toBase64(file: File): Observable<string> {
    const result = new AsyncSubject<any>();
    if (file) {
      var reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function () {
        result.next(reader.result);
        result.complete();
      };
      // reader.onerror = function (error) {
      //   console.log("Error: ", error);
      // };
      return result;
    } else {
      result.next("");
      result.complete();
      return result;
    }
  }
}
