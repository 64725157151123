<div *ngIf="status" class="mat-elevation-z2" style="display: flex; gap: 8px; padding: 8px">
  <div
    class="adaptive"
    [class.enabled]="status.pedestrian.enabled"
    [class.active]="status.pedestrian.active"
    title="{{ 'TRAFFIC_OBJECTS.ADAPTIVE.PEDESTRIAN' | translate }}"
  >
    <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 61.723 61.723">
      <path
        d="M15.972,10.035c2.766-0.313,4.748-2.81,4.433-5.571c-0.317-2.764-2.813-4.748-5.573-4.431 c-2.764,0.317-4.746,2.813-4.433,5.575C10.717,8.37,13.212,10.354,15.972,10.035z"
      ></path>
      <path
        d="M33.031,25.669c-0.104-0.358-0.244-0.721-0.434-1.087c-1.37-2.669-2.802-5.309-4.266-7.931 c1.551,0.12,3.102,0.24,4.654,0.361c1.062,2.099,2.123,4.197,3.186,6.297c1.711,3.38,6.775,0.407,5.07-2.963 c-1.368-2.709-2.99-8.799-6.528-9.073c-3.762-0.293-7.525-0.584-11.288-0.876c-0.09-0.007-0.168,0.006-0.255,0.005 c-2.857-0.767-6.208,1.262-6.791,4.184c-1.923,4.15-4.689,7.385-9.313,8.737c-3.628,1.061-2.083,6.73,1.561,5.663 c4.581-1.34,7.871-3.965,10.367-7.421c1.511,2.693,2.986,5.407,4.4,8.153c-3.109,2.144-5.826,4.739-8.206,7.729 c-0.664,0.836-0.777,1.693-0.554,2.449c-0.008,0.278,0.009,0.57,0.09,0.89c0.916,3.622,1.831,7.246,2.748,10.868 c0.972,3.861,7.082,2.759,6.101-1.125c-0.872-3.449-1.745-6.9-2.617-10.354c2.187-2.577,4.765-4.821,7.71-6.493 c0.694-0.396,1.13-0.938,1.373-1.537c4.031,6.176,8.062,14.717,12.089,19.896c2.456,3.154,7.373-0.629,4.908-3.794 C41.046,39.3,34.19,27.646,33.031,25.669z"
      ></path>
      <path
        d="M12.948,40.312c-0.2-0.908-0.091-1.83,0.3-2.69H6.806v24.101h7.635v-15.01l-1.337-5.288 C13.01,41.057,12.958,40.682,12.948,40.312z"
      ></path>
      <path
        d="M22.976,40.843l2.391,9.462c0.362,1.436,0.102,2.814-0.735,3.889 c-0.892,1.146-2.367,1.828-3.946,1.828c-0.067,0-0.134-0.004-0.202-0.008v5.709h7.635V37.621h-1.734 C25.193,38.574,24.052,39.648,22.976,40.843z"
      ></path>
      <path d="M40.822,53.326c-1.818-2.34-3.633-5.33-5.412-8.434V61.72h7.635v-6.775 C42.194,54.652,41.429,54.105,40.822,53.326z"></path>
      <path d="M49.089,37.62L49.089,37.62v10.696c0.604,1.267,0.622,2.685,0,3.966v9.438h7.636v-24.1H49.089z"></path>
    </svg>
  </div>
  <div
    class="adaptive"
    [class.enabled]="status.publicTransport.enabled"
    [class.active]="status.publicTransport.active"
    title="{{ 'TRAFFIC_OBJECTS.ADAPTIVE.PUBLIC_TRANSPORT' | translate }}"
  >
    <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 401.279 401.279">
      <path
        d="M374.878,79.565h-15.102V52.723C359.777,23.605,336.172,0,307.054,0H94.225C65.107,0,41.502,23.605,41.502,52.723v26.842 H26.401c-6.063,0-10.978,4.915-10.978,10.979v60.812c0,6.063,4.915,10.978,10.978,10.978h15.102v131.081 c-5.874,5.265-9.572,12.908-9.572,21.417v20.316c0,15.883,12.875,28.758,28.758,28.758h3.558v28.914 c0,4.672,3.79,8.459,8.462,8.459h38.908c4.672,0,8.458-3.787,8.458-8.459v-28.914h161.13v28.914c0,4.672,3.786,8.459,8.459,8.459 h38.907c4.672,0,8.461-3.787,8.461-8.459v-28.914h3.559c15.882,0,28.758-12.875,28.758-28.758v-20.316 c0-8.508-3.698-16.152-9.572-21.417V162.333h15.102c6.062,0,10.978-4.915,10.978-10.978V90.543 C385.856,84.48,380.942,79.565,374.878,79.565z M327.175,83.855v129.731H215.019V83.855H327.175z M144.722,27.49h111.836 c7.765,0,14.06,6.294,14.06,14.06c0,7.764-6.294,14.059-14.06,14.059H144.722c-7.765,0-14.06-6.295-14.06-14.059 C130.662,33.784,136.957,27.49,144.722,27.49z M74.105,83.855h112.156v129.731H74.105V83.855z M124.303,271.463 c0,7.643-6.216,13.858-13.86,13.858H87.572c-7.631,0-13.846-6.216-13.846-13.858v-15.42c0-7.645,6.215-13.859,13.846-13.859h22.871 c7.644,0,13.86,6.215,13.86,13.859V271.463z M276.978,271.463v-15.42c0-7.645,6.215-13.859,13.859-13.859h22.871 c7.631,0,13.846,6.215,13.846,13.859v15.42c0,7.643-6.215,13.858-13.846,13.858h-22.871 C283.194,285.322,276.978,279.105,276.978,271.463z"
      ></path>
    </svg>
  </div>
  <div
    class="adaptive"
    [class.enabled]="status.specialTransport.enabled"
    [class.active]="status.specialTransport.active"
    title="{{ 'TRAFFIC_OBJECTS.ADAPTIVE.SPECIAL_TRANSPORT' | translate }}"
  >
    <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" stroke-width="20" stroke="currentColor" viewBox="0 0 512 512">
      <path
        d="M422.957,400.696h-16.696V250.435c0-82.854-67.407-150.261-150.261-150.261s-150.261,67.407-150.261,150.261v150.261 H89.044c-9.22,0-16.696,7.475-16.696,16.696v77.913c0,9.22,7.475,16.696,16.696,16.696h333.913c9.22,0,16.696-7.475,16.696-16.696 v-77.913C439.652,408.171,432.177,400.696,422.957,400.696z M139.13,250.435c0-64.442,52.428-116.87,116.87-116.87 s116.87,52.428,116.87,116.87v150.261H139.13V250.435z M406.261,478.609H105.739v-44.522h300.522V478.609z"
      ></path>

      <path
        d="M256,0c-9.22,0-16.696,7.475-16.696,16.696v33.391c0,9.22,7.475,16.696,16.696,16.696s16.696-7.475,16.696-16.696V16.696 C272.696,7.475,265.22,0,256,0z"
      ></path>

      <path
        d="M256,166.957c-9.22,0-16.696,7.475-16.696,16.696c0,9.22,7.475,16.696,16.696,16.696 c27.618,0,50.087,22.469,50.087,50.087c0,9.22,7.475,16.696,16.696,16.696s16.696-7.475,16.696-16.696 C339.478,204.405,302.03,166.957,256,166.957z"
      ></path>
      <path
        d="M55.652,233.739H22.261c-9.22,0-16.696,7.475-16.696,16.696c0,9.22,7.475,16.696,16.696,16.696h33.391 c9.22,0,16.696-7.475,16.696-16.696C72.348,241.214,64.873,233.739,55.652,233.739z"
      ></path>
      <path
        d="M489.739,233.739h-33.391c-9.22,0-16.696,7.475-16.696,16.696c0,9.22,7.475,16.696,16.696,16.696h33.391 c9.22,0,16.696-7.475,16.696-16.696C506.435,241.214,498.96,233.739,489.739,233.739z"
      ></path>
      <path
        d="M433.084,73.351c-6.519-6.52-17.091-6.52-23.611,0l-23.611,23.611c-6.52,6.52-6.52,17.091,0,23.611 c6.518,6.52,17.091,6.521,23.611,0l23.611-23.611C439.604,90.441,439.604,79.871,433.084,73.351z"
      ></path>
      <path
        d="M126.138,96.962l-23.611-23.611c-6.519-6.52-17.091-6.52-23.611,0s-6.52,17.091,0,23.611l23.611,23.611 c6.519,6.52,17.091,6.521,23.611,0C132.658,114.052,132.658,103.482,126.138,96.962z"
      ></path>
    </svg>
  </div>
  <div
    class="adaptive"
    [class.enabled]="status.transport.enabled"
    [class.active]="status.transport.active"
    title="{{ 'TRAFFIC_OBJECTS.ADAPTIVE.TRANSPORT' | translate }}"
  >
    <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" stroke-width="20" stroke="currentColor" viewBox="0 0 488.001 488.001">
      <path
        d="M462.6,214.404l-39.2-17.9l-34.6-55.2c-10-16-27.6-25.7-46.5-25.6H194.9c-17.1,0-33.3,7.9-43.7,21.5l-44.7,58.5 l-74.5,20.4c-18.9,5.2-32,22.5-32,42.1v28.5c0,24.1,19.2,44,43.3,44h6.4c7.7,29.7,38,47.5,67.7,39.8c19.5-5.1,34.8-20.3,39.8-39.8 h168c7.7,29.7,38,47.5,67.7,39.8c19.5-5.1,34.8-20.3,39.8-39.8h12c24.1,0,43.3-19.9,43.3-44v-32.5 C488.1,237.104,478.1,221.504,462.6,214.404z M103.4,352.104c-19.6,0-35.5-15.9-35.5-35.5c0-19.6,15.9-35.5,35.5-35.5 c19.6,0,35.5,15.9,35.5,35.5C139,336.104,123.1,352.004,103.4,352.104z M378.9,352.104c-19.6,0-35.5-15.9-35.5-35.5 c0-19.6,15.9-35.5,35.5-35.5s35.5,15.9,35.5,35.5C414.5,336.104,398.6,352.004,378.9,352.104z M444.7,310.704h-10.6 c-3.3-30.5-30.8-52.5-61.3-49.1c-25.9,2.8-46.3,23.3-49.1,49.1H158.6c-3.3-30.5-30.8-52.5-61.3-49.1 c-25.9,2.8-46.3,23.3-49.1,49.1h-5c-13.1,0-23.3-10.9-23.3-24v-28.5c-0.1-10.7,7-20,17.3-22.9l77.9-21.3c2.1-0.6,4-1.8,5.3-3.6 l46.6-61.1c6.7-8.6,17-13.6,27.9-13.6h147.4c12-0.1,23.3,6,29.6,16.2l36.2,57.8c1,1.7,2.6,3,4.3,3.8l41.8,19.1 c8.4,3.9,13.8,12.3,13.7,21.6v32.5h0.1C468,299.804,457.8,310.704,444.7,310.704z"
      ></path>
    </svg>
  </div>
  <div
    class="adaptive"
    [class.enabled]="status.local.enabled"
    [class.active]="status.local.active"
    title="{{ 'TRAFFIC_OBJECTS.ADAPTIVE.LOCAL' | translate }}"
  >
    <svg viewBox="0 0 512 512" fill="currentColor" stroke="1" xmlns="http://www.w3.org/2000/svg">
      <path
        d="m334.772 118.026c5.858-5.858 5.858-15.355 0-21.213-43.435-43.435-114.108-43.435-157.544 0-5.858 5.858-5.858 15.355 0 21.213s15.355 5.858 21.213 0c31.738-31.738 83.379-31.738 115.117 0 5.859 5.858 15.356 5.858 21.214 0z"
      ></path>
      <path
        d="m152.998 72.583c56.796-56.796 149.209-56.796 206.004 0 5.858 5.858 15.355 5.858 21.213 0s5.858-15.355 0-21.213c-68.492-68.492-179.938-68.492-248.43 0-13.515 14.552 6.608 34.754 21.213 21.213z"
      ></path>
      <path
        d="m222.672 142.256c-13.531 14.572 6.639 34.747 21.213 21.213 6.68-6.68 17.55-6.68 24.23 0 5.858 5.858 15.355 5.858 21.213 0s5.858-15.355 0-21.213c-18.376-18.377-48.279-18.377-66.656 0z"
      ></path>
      <path
        d="m510.858 202.06c-2.322-5.605-7.791-9.26-13.858-9.26h-321.333c-25.801 0-47.133 20.847-47.133 47.134v96.399c0 25.861 20.907 47.134 47.133 47.134h65.333v34.267h-146.733v-17.134c0-25.989-21.144-47.133-47.133-47.133h-32.134c-8.284 0-15 6.716-15 15v128.533c0 8.284 6.716 15 15 15h32.133c25.989 0 47.133-21.145 47.133-47.134v-17.133h161.734c8.284 0 15-6.716 15-15v-49.267h129.599c8.284 0 15-6.716 15-15v-17.134h49.267c8.284 0 15-6.716 15-15v-90.188l27.74-27.739c4.29-4.289 5.573-10.74 3.252-16.345zm-446.591 262.806c0 9.447-7.686 17.134-17.133 17.134h-17.134v-98.533h17.133c9.447 0 17.133 7.686 17.133 17.133v64.266zm321.332-111.399h-209.932c-9.439 0-17.133-7.638-17.133-17.134v-49.267h227.066v66.401zm64.267-32.134h-34.267v-35.468c12.229-1.98 23.84-6.378 34.267-12.957zm-5.022-82.593c-11.818 11.817-27.531 18.326-44.245 18.326h-242.066v-17.133c0-9.476 7.676-17.134 17.133-17.134h285.118z"
      ></path>
    </svg>
  </div>
</div>
