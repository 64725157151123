import { Inject, Injectable } from "@angular/core";
import {
  IDataSourceService,
  IPageParams,
  IPageReturn,
  getPageReturn,
} from "projects/msu-its-web-common/src/data/data-source";
import { Observable, of } from "rxjs";
import { map } from "rxjs/operators";
import { APP_ENVIRONMENT } from "projects/msu-its-web-common/src/utils/shared-consts";
import { TokenService } from "projects/msu-its-web-common/src/services/token.service";
import { FtpCameraLocation } from "../../components/cameras-ftp/model";
import { fakeModels } from "./db/model";
import { TfmsApiService } from "../tfms-api.service";

@Injectable()
export class CameraFTPService
  implements IDataSourceService<FtpCameraLocation, FtpCameraLocation> {
  constructor(
    private _api: TfmsApiService,
    private _tokenService: TokenService,
    @Inject(APP_ENVIRONMENT) private _appEnv
  ) {}

  getPagedList(
    params: IPageParams,
    customerId: string
  ): Observable<IPageReturn<any>> {
    // const request = new GetPtzCamerasLocationRequest();
    // request.customerId = customerId;
    // setPageParams(request, params);
    return of(fakeModels).pipe(
      map((m) => {
        return getPageReturn(m);
      })
    );
  }

  getList(
    customerId: string,
    filter?: string,
    groupIds?: string[]
  ): Observable<any[]> {
    // const request = new GetPtzCamerasLocationRequest();
    // request.customerId = customerId;

    // request.entityGroups = groupIds;
    // request.page = 0;
    // request.items = 0;
    // request.sort = "name";
    // request.filter = filter;

    return of(fakeModels).pipe(
      map((m) => {
        return m.items;
      })
    );
  }

  // getList(customerId: string, filter?: string, groupIds?: string[]): Observable<Camera[]> {
  //   const request = new GetCamerasPageRequest();
  //   request.customerId = customerId;
  //   request.entityGroups = groupIds;
  //   request.page = 0;
  //   request.items = 0;
  //   request.sort = 'name';
  //   request.filter = filter;

  //   return this._api.get(request).pipe(
  //     map((m) => {
  //       return m.items;
  //     })
  //   );
  // }

  get(id: any, customerId: string): Observable<any> {
    // const request = new GetPtzCameraRequest();
    // request.customerId = customerId;
    // request.id = id;
    return of(fakeModels).pipe(
      map((item) => {
        return item.items.filter((epic) => epic.id === id)[0];
      })
    );
  }

  add(
    model: FtpCameraLocation,
    params: {
      customerId: string;
      groups: string[];
      updateGroups: boolean;
    }
  ): Observable<FtpCameraLocation> {
    // const request = new CreatePtzCameraRequest();
    // request.customerId = params.customerId;
    // request.entity = model;
    // request.entityGroups = params.groups;
    // request.updateEntityGroups = params.updateGroups;
    // return this._api.post(request);
    throw "not implemented";
  }

  update(
    model: FtpCameraLocation,
    params: {
      customerId: string;
      groups: string[];
      updateGroups: boolean;
    }
  ): Observable<FtpCameraLocation> {
    // const request = new UpdatePtzCameraRequest();
    // request.customerId = params.customerId;
    // request.id = model.id;
    // request.entity = model;
    // request.entityGroups = params.groups;
    // request.updateEntityGroups = params.updateGroups;
    // return this._api.put(request);
    throw "not implemented";
  }

  delete(ids: string[], customerId: string): Observable<number> {
    // const request = new DeletePtzCamerasRequest();
    // request.customerId = customerId;
    // request.ids = ids;
    // return this._api.delete(request);
    throw "not implemented";
  }

  getPermissions(id: string, customerId: string) {
    // const request = new GetPtzCameraCurrentUserPermissionsRequest();
    // request.id = id;
    // request.customerId = customerId;
    // return this._api.get(request);
    throw "not implemented";
  }
}
