import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { SettingsService } from './settings.service';

import * as jwt_decode_ from 'jwt-decode';
const jwt_decode = jwt_decode_.default;

const BEARER_TOKEN = 'bearer_token';
const REFRESH_TOKEN = 'refresh_token';
const PERMISSIONS = 'permissions';

@Injectable({ providedIn: 'root' })
export class TokenService {
  private _payload;
  private _bearerToken: string;
  private _permissions: string[];

  tokenChanged = new Subject();

  constructor(private _settingsService: SettingsService) {}

  getValue(key: string): string {
    if (!this._payload) {
      this._payload = this.bearerToken ? jwt_decode(this.bearerToken) : {};
    }
    return this._payload[key];
  }

  getPermissions() {
    if (this._permissions) {
      return this._permissions;
    }

    let value = localStorage.getItem(PERMISSIONS);
    value = value ? value : sessionStorage.getItem(PERMISSIONS);

    this._permissions = value?.split(',') || [];
    return this._permissions;
  }

  private _hasPermissions(
    expected: string[],
    allowed: string[],
    logic: 'or' | 'and' = 'or'
  ) {
    const regexps = expected.map(
      (p) => new RegExp('^' + p.replace('.', '.').replace('*', '.+') + '$', 'i')
    );
    if (logic === 'and') {
      return regexps.every((p) => allowed.some((n) => p.test(n)));
    } else {
      // if (logic === 'or') {
      return regexps.some((p) => allowed.some((n) => p.test(n)));
    }
  }

  hasPermissions(expectedPermissions: string[], logic: 'or' | 'and' = 'or') {
    if (!(expectedPermissions && expectedPermissions.length)) {
      return true;
    }

    const permissions = this.getPermissions();

    if (permissions.some((m) => m === 'system.manage')) return true;

    return this._hasPermissions(expectedPermissions, permissions, logic);

    // const regexps = expectedPermissions.map(
    //   (p) => new RegExp('^' + p.replace('.', '.').replace('*', '.+') + '$', 'i')
    // );
    // if (logic === 'and') {
    //   return regexps.every((p) => permissions.some((n) => p.test(n)));
    // } else {
    //   // if (logic === 'or') {
    //   return regexps.some((p) => permissions.some((n) => p.test(n)));
    // }
  }

  hasPermissionsForItem(
    item: { customerId?: string },
    customerId: string,
    expectedPermissions: string[],
    itemAllowedPermissions?: string[],
    logic: 'or' | 'and' = 'or'
  ) {
    if (expectedPermissions) {
      if (!item.customerId || item.customerId == customerId) {
        return this.hasPermissions(expectedPermissions, logic);
      } else if (itemAllowedPermissions) {
        return this._hasPermissions(
          expectedPermissions,
          itemAllowedPermissions,
          logic
        );
      } else {
        return false;
      }
    }
    return true;
  }

  get bearerToken() {
    if (this._bearerToken) {
      return this._bearerToken;
    }

    let value = localStorage.getItem(BEARER_TOKEN);
    value = value ? value : sessionStorage.getItem(BEARER_TOKEN);

    this._bearerToken = value;
    return this._bearerToken;
  }

  get refreshToken() {
    let value = localStorage.getItem(REFRESH_TOKEN);
    value = value ? value : sessionStorage.getItem(REFRESH_TOKEN);
    return value;
  }

  get remember() {
    return localStorage.getItem(REFRESH_TOKEN) ? true : false;
  }

  setToken(
    bearerToken: string,
    refreshToken: string,
    permissions: string[],
    remember: boolean
  ) {
    localStorage.removeItem(BEARER_TOKEN);
    localStorage.removeItem(REFRESH_TOKEN);
    localStorage.removeItem(PERMISSIONS);

    sessionStorage.removeItem(BEARER_TOKEN);
    sessionStorage.removeItem(REFRESH_TOKEN);
    sessionStorage.removeItem(PERMISSIONS);

    if (bearerToken) {
      const permissionsValue = (permissions || []).join(',');
      if (remember) {
        localStorage.setItem(BEARER_TOKEN, bearerToken);
        localStorage.setItem(REFRESH_TOKEN, refreshToken);
        localStorage.setItem(PERMISSIONS, permissionsValue);
      } else {
        sessionStorage.setItem(BEARER_TOKEN, bearerToken);
        sessionStorage.setItem(REFRESH_TOKEN, refreshToken);
        sessionStorage.setItem(PERMISSIONS, permissionsValue);
      }
    }

    this._bearerToken = bearerToken;
    this._permissions = permissions;

    this._payload = null;
    // this._settingsService.updateTimezone(this.getValue('timezone'));
    this.tokenChanged.next();

    // this.getValue('sub');
    // this.getPermissions();

    // subdomain cookie
    // this.setTokenCookie();
  }

  // setTokenCookie(token?) {
  //   const value = token ? token : this.bearerToken;
  //   const domain = window.location.hostname;

  //   const expires = new Date(
  //     new Date().getTime() + 24 * 60 * 60 * 1000
  //   ).toUTCString();
  //   const cookie = `token=${value}; domain=${domain}; expires=${expires};`;
  //   // const cookie = `token=${value}; domain=${domain};`;

  //   document.cookie = cookie;
  // }

  clear() {
    this.setToken(null, null, null, false);
  }
}
