import { ICustomerEntityViewModel, IEntityTyped } from "./hub.dtos";

// @DataContract
export class EntityGroupPermissions implements IEntityTyped, ICustomerEntityViewModel {
    // @DataMember
    public customerId: string;
  
    // @DataMember
    public customerName: string;
  
    // @DataMember
    public entityGroupId: string;
  
    // @DataMember
    public permissions: string[];
  
    // @DataMember
    public entityType: string;
  
    public constructor(init?: Partial<EntityGroupPermissions>) {
      (Object as any).assign(this, init);
    }
  }