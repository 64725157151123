import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
} from "@angular/core";
import { ActivatedRoute } from "@angular/router";

import { DialogService } from "projects/msu-its-web-common/src/services/dialog.service";
import { DetectorService } from "../../services/detector.service";
import { DetectorEditComponent } from "../detectors/detector-edit.component";

@Component({
  selector: "camera-edit-external",
  template: ``,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DetectorEditExternalComponent implements AfterViewInit {
  objectId;
  customerId;

  constructor(
    private _route: ActivatedRoute,
    private _detectorService: DetectorService,
    private _dialogService: DialogService
  ) {
    this.objectId = this._route.snapshot.paramMap.get("objectId");
    this.customerId = this._route.snapshot.paramMap.get("customerId");
  }

  ngAfterViewInit() {
    this._detectorService
      .get(this.objectId, this.customerId)
      .subscribe((model) => {
        const dialog = this._dialogService.dialog.open(DetectorEditComponent, {
          disableClose: true,
          panelClass: "external-dialog",
          height: "756",
          width: "940",
        });
        dialog.componentInstance.initForm(
          model,
          this._detectorService,
          false,
          null
        );
        //   dialog.componentInstance.model = model;
        dialog.componentInstance.customerId = this.customerId;
        dialog.componentInstance.external = true;
        //   dialog.componentInstance.external = true;
        //   dialog.componentInstance.modelId = this.objectId;
      });
  }
}
