<mat-form-field style="width: 100%">
  <input matInput placeholder="{{ 'COMMON.ROUTE' | translate }}" [disabled]="true" [value]="selectedRoute.name" />
</mat-form-field>

<mat-divider *ngIf="selectedRoute?.settings.routeObjects?.length" style="margin: 0 -16px"></mat-divider>

<div style="margin: 0 -16px; overflow: auto; overflow: overlay; flex: 1">
  <mat-list style="padding: 0">
    <mat-list-item *ngFor="let item of escortSteps; let i = index" style="font-size: 12px; height: 53px">
      <div matListIcon class="mat-border schemaState" style="box-sizing: border-box; padding: 0; border-radius: 3px !important">
        <object
          data="/assets/images/tlc/layout_button.svg"
          (load)="svgLoad($event.target, item.routeObject, item.routeObject.stepMovement)"
          style="width: 44px; height: 44px; margin: -3px 0 0 -3px; pointer-events: none; display: block"
          type="image/svg+xml"
          [style.transform]="'rotate(' + getAngle(item.routeObject) + 'deg)'"
        ></object>
      </div>

      <h4 matLine>{{ getName(item.routeObject) }}</h4>

      <div
        class="timer mat-theme-bg active highlight"
        style="overflow: hidden; cursor: pointer"
        (click)="startStep(item)"
        (contextmenu)="stopStep(item); $event.preventDefault(); $event.stopPropagation()"
      >
        <div class="spin" [class.active]="item.status == 'Wait'"></div>
        <div class="time" style="background: transparent; font-weight: 700; box-shadow: none">
          {{ getSuspendTime(item) }}
        </div>
      </div>

      <mat-divider></mat-divider>
    </mat-list-item>
  </mat-list>
</div>

<mat-divider style="margin: 0 -16px"></mat-divider>

<div style="padding: 12px 0" fxLayout="column">
  <div style="font-size: 14px" fxLayout fxLayoutAlign="space-between">
    <mat-form-field floatLabel="always" style="width: 105px" title="{{ 'ROUTE_ESCORT.SUSPEND_TIME' | translate }}">
      <input matInput [value]="suspendTime" (change)="suspendTime = +$event.target.value" step="10" type="number" min="30" max="900" />
      <mat-label>{{ "ROUTE_ESCORT.SUSPEND_TIME" | translate }}</mat-label>
    </mat-form-field>

    <mat-checkbox *hasPermissions="['system.manage']" [checked]="test" (change)="test = $event.checked">
      {{ "COMMON.TEST" | translate }}
    </mat-checkbox>
  </div>
  <button mat-flat-button color="primary" [disabled]="!selectedRoute" (click)="stop()">
    {{ "ROUTE_ESCORT.COMPLETE_ESCORT" | translate }}
  </button>
</div>
