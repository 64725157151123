<div fxLayout="column" fxLayoutGap="16px" fxLayoutAlign="space-between" style="min-height: 100%">
  <div class="remote">
    <ng-container *ngFor="let items of states; let j = index">
      <ng-container *ngFor="let item of items; let i = index">
        <button
          mat-raised-button
          [disabled]="disabled"
          [class.mat-accent]="status?.mode == 'Remote' && isSchemaState(item, status?.currentPhase?.lights)"
          class="mat-button-hover"
          [style.grid-column]="j + 1"
          [style.grid-row]="i + 1"
          (click)="setState(item)"
          style="display: block"
        >
          <div class="remote-svg mat-border">
            <object
              type="image/svg+xml"
              data="/assets/images/tlc/layout_button.svg"
              (load)="setSvgState($event.target, item)"
              [style.transform]="'rotate(' + schemaView.angle + 'deg)'"
            ></object>
          </div>
        </button>
      </ng-container>
    </ng-container>
  </div>
  <div class="remote special">
    <button
      mat-raised-button
      class="mat-button-hover yellow-blink"
      [disabled]="disabled"
      [class.mat-accent]="status?.mode == 'Remote' && status?.state == 'YellowBlink'"
      [style.grid-column]="'1 / 3'"
      [style.grid-row]="1"
      style="display: block"
      (click)="setYellowBlink()"
      title="{{ 'CONTROLLER_STATE.YELLOW_BLINK' | translate }}"
    >
      {{ "CONTROLLER_STATE.YELLOW_BLINK_SHORT" | translate }}
    </button>

    <button
      mat-raised-button
      class="mat-button-hover off"
      [disabled]="disabled"
      [class.mat-accent]="status?.mode == 'Remote' && status?.state == 'Off'"
      [style.grid-column]="1"
      [style.grid-row]="2"
      style="display: block"
      (click)="setOff()"
      title="{{ 'CONTROLLER_STATE.OFF' | translate }}"
    >
      {{ "CONTROLLER_STATE.OFF_SHORT" | translate }}
    </button>

    <button
      mat-raised-button
      class="mat-button-hover all-red"
      [disabled]="disabled"
      [class.mat-accent]="status?.mode == 'Remote' && status?.state == 'AllRed'"
      [style.grid-column]="2"
      [style.grid-row]="2"
      style="display: block"
      (click)="setAllRed()"
      title="{{ 'CONTROLLER_STATE.ALL_RED' | translate }}"
    >
      {{ "CONTROLLER_STATE.ALL_RED_SHORT" | translate }}
    </button>
  </div>
</div>
