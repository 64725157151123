import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostListener,
  Input,
  OnInit,
} from "@angular/core";
import { marker as _ } from "@biesbjerg/ngx-translate-extract-marker";

import { TrafficAccident } from "../../dtos/accident.dtos";
import { CONTROL_CAMERA, TRAFFIC_ACCIDENT_TYPE } from "../../dtos/enum";
import { TranslateService } from "@ngx-translate/core";
import { TrafficAccidentService } from "../../services/traffic-accident/traffic-accident.service";
import { Subject } from "rxjs";
import { SettingsService } from "projects/msu-its-web-common/src/services/settings.service";
import { finalize } from "rxjs/operators";

@Component({
  selector: "traffic-accident-popup",
  templateUrl: "./traffic-accident-popup.component.html",
  styles: [
    `
      :host {
        height: 100% !important;
        width: 350px;
        /* padding: 28px; */
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TrafficAccidentPopupComponent implements OnInit, AfterViewInit {
  loading = true;
  @Input()
  modelId: string;

  external: boolean = false;

  @Input()
  customerId: string;

  model: TrafficAccident;

  onMouseOver = new Subject();
  @HostListener("mouseover")
  hostMouseOver() {
    this.onMouseOver.next();
  }

  @Input()
  close: () => void;

  trafficAccidentType = {};

  actions: { name: string; action: string; permissions?: string[] }[] = [
    {
      name: _("COMMON.EDIT"),
      action: "openEdit",
      permissions: ["accidentTrafficAccident.manage"],
    },
  ];

  openInNew() {
    // this._dialogService.windowDialog(
    //   `${this.customerId}/ext/cameras-ptz/${this.modelId}`,
    //   "Camera_" + this.modelId,
    //   "menubar=0,toolbar=0,titlebar=0,location=0,locationbar=0,width=880,height=645,resizable=0"
    // );
  }
  openEdit() {
    // this._dialogService.windowDialog(
    //   `${this.customerId}/ext/cameras-ptz/${this.modelId}/edit`,
    //   "CameraEdit_" + this.modelId,
    //   "width=940,height=756"
    // );
  }

  get timezone() {
    return this._settingsService.timezone;
  }
  get locale() {
    return this.translate.currentLang;
  }

  constructor(
    private _trafficAccidentService: TrafficAccidentService,
    public translate: TranslateService,
    private _changeDetector: ChangeDetectorRef,
    private _settingsService: SettingsService
  ) {
    TRAFFIC_ACCIDENT_TYPE.forEach(
      (m) => (this.trafficAccidentType[m.type] = m.name)
    );
  }

  ngAfterViewInit(): void {
    this.loading = true;
    this._trafficAccidentService
      .get(this.modelId, this.customerId)
      .pipe(
        finalize(() => {
          this.loading = false;
          this._changeDetector.markForCheck();
        })
      )
      .subscribe((result) => {
        this.model = result;
        this.loading = false;
        this._changeDetector.markForCheck();
      });
    // this.webcamera.url = this.modelInfo.url;
    // this._changeDetector.markForCheck();
  }
  ngOnInit() {
    // // permissions
    // if (this.modelInfo.customerId != this.customerId) {
    //   this.modelPermissions$ = this._cameraPtzService.getPermissions(
    //     this.modelId,
    //     this.customerId
    //   );
    // }
    // this._updateActionsByPermissions();
  }

  // private _updateActionsByPermissions() {
  //   this.modelPermissions$.subscribe((m) => {
  //     this.actions = this.actions.filter((a) =>
  //       this._tokenService.hasPermissionsForItem(
  //         this.modelInfo,
  //         this.customerId,
  //         a.permissions,
  //         m
  //       )
  //     );
  //   });
  // }
}
