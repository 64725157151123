import {
  Component,
  ChangeDetectionStrategy,
  AfterViewInit,
  OnDestroy,
  ChangeDetectorRef,
  Input,
  ViewChild,
  ElementRef,
  Output,
  EventEmitter,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SettingsService } from 'projects/msu-its-web-common/src/services/settings.service';
import { Subject } from 'rxjs';

import { LayoutSvgUtils } from '../../dtos/layout';
import { Schema, SchemaSet, SchemaUtils, SchemaViewSettings } from '../../dtos/schema';
import { TrafficMovementType } from '../../dtos/tlc.dtos';

@Component({
  selector: 'movements-component',
  templateUrl: './movements.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MovementsComponent implements AfterViewInit, OnDestroy {
  _destroy = new Subject();

  initialized = false;

  @Input()
  schemaSet: SchemaSet;

  @Input()
  conflicts = {};

  @Input()
  enabledMoves: TrafficMovementType[] = [];

  @Input()
  disabledMoves: TrafficMovementType[] = [];

  @Input()
  dependMoves: {
    move: TrafficMovementType;
    depend: TrafficMovementType;
  }[] = [];

  @Output()
  readonly selectionChange = new EventEmitter<TrafficMovementType[]>();

  @ViewChild('layoutSvg', { static: false })
  _layoutSvg: ElementRef;

  _bindedMoves: TrafficMovementType[] = [];

  constructor(
    private _settingsService: SettingsService,
    private _changeDetector: ChangeDetectorRef,
    public translate: TranslateService
  ) {}

  ngAfterViewInit() {
    this._changeDetector.markForCheck();
  }

  ngOnDestroy() {
    this._destroy.next();
    this._destroy.complete();
  }

  initLayoutSvg() {
    const schema = this.schemaSet.schema;
    const schemaViewSettings = this.schemaSet.schemaView;
    const svg = this._layoutSvg.nativeElement.contentDocument;
    const isDark = this._settingsService.darkTheme;

    LayoutSvgUtils.updateLayoutSchema(svg, schema, schemaViewSettings, isDark);
    LayoutSvgUtils.updateLayoutControl(svg, schema);

    const bind = [];
    schema.Move1.enabled && schema.Move1.controlled && bind.push(TrafficMovementType.Move1);
    schema.Move3.enabled && schema.Move3.controlled && bind.push(TrafficMovementType.Move3);
    schema.Move5.enabled && schema.Move5.controlled && bind.push(TrafficMovementType.Move5);
    schema.Move7.enabled && schema.Move7.controlled && bind.push(TrafficMovementType.Move7);

    schema.Move12.enabled && schema.Move12.controlled && bind.push(TrafficMovementType.Move12);
    schema.Move14.enabled && schema.Move14.controlled && bind.push(TrafficMovementType.Move14);
    schema.Move16.enabled && schema.Move16.controlled && bind.push(TrafficMovementType.Move16);
    schema.Move18.enabled && schema.Move18.controlled && bind.push(TrafficMovementType.Move18);

    (schema.Move2.enabled ||
      (schema.Move12.enabled && !schema.Move12.controlled) ||
      (schema.Move5.enabled && !schema.Move5.controlled)) &&
      bind.push(TrafficMovementType.Move2);
    (schema.Move4.enabled ||
      (schema.Move14.enabled && !schema.Move14.controlled) ||
      (schema.Move7.enabled && !schema.Move7.controlled)) &&
      bind.push(TrafficMovementType.Move4);
    (schema.Move6.enabled ||
      (schema.Move16.enabled && !schema.Move16.controlled) ||
      (schema.Move1.enabled && !schema.Move1.controlled)) &&
      bind.push(TrafficMovementType.Move6);
    (schema.Move8.enabled ||
      (schema.Move18.enabled && !schema.Move18.controlled) ||
      (schema.Move3.enabled && !schema.Move3.controlled)) &&
      bind.push(TrafficMovementType.Move8);

    schema.MoveP2.enabled && bind.push(TrafficMovementType.MoveP2);
    schema.MoveP4.enabled && bind.push(TrafficMovementType.MoveP4);
    schema.MoveP6.enabled && bind.push(TrafficMovementType.MoveP6);
    schema.MoveP8.enabled && bind.push(TrafficMovementType.MoveP8);

    this._bindedMoves = bind.filter((m) => !this.disabledMoves.includes(m));
    this._bindedMoves.forEach((m) => {
      svg.getElementById(m + '_Label').style.fill = isDark ? '#fff' : '#333';
      this._bindMoveClick(svg, m);
    });

    this.disabledMoves.forEach((m) => {
      const elm = svg.getElementById(m);
      elm.classList.toggle('disabled', true);
      const elmLbl = svg.getElementById(m + '_Label');
      elmLbl.style.fill = isDark ? '#fff' : '#333';
      elmLbl.style['fill-opacity'] = 0.3;
    });

    this.updateChanges();
    this.initialized = true;
  }

  updateChanges() {
    const schema = this.schemaSet.schema;

    const svgElement = this._layoutSvg.nativeElement;
    const doc = svgElement.contentDocument;

    this._bindedMoves.forEach((move) => {
      const el = doc.getElementById(move);
      const active = this.enabledMoves.some((n) => n === move);
      const conflict = this.enabledMoves.some((m) => this.conflicts[m]?.some((c) => c === move));
      el.classList.toggle('active', active);
      if (active) {
        el.style.display = null;
        el.removeAttribute('display');
      } else if (conflict) {
        el.style.display = 'none';
        el.setAttribute('display', 'none');
      } else {
        el.style.display = null;
        el.removeAttribute('display');
      }

      SchemaUtils.schemaViewMovements(schema, move).forEach((vm) => {
        const viewElmns = doc.getElementsByClassName(vm);
        for (let i = 0; i < viewElmns.length; i++) {
          const elm = viewElmns[i];
          if (active) {
            elm.style.display = null;
            elm.removeAttribute('display');
          } else {
            elm.style.display = 'none';
            elm.setAttribute('display', 'none');
          }
        }
      });
    });
  }

  setSvgLayoutState(element, movements: TrafficMovementType[]) {
    const isDark = this._settingsService.darkTheme;
    LayoutSvgUtils.updateLayoutSchema(
      element.contentDocument,
      this.schemaSet.schema,
      this.schemaSet.schemaView,
      isDark
    );
    LayoutSvgUtils.showLayoutMovements(element.contentDocument, this.schemaSet.schema, movements);
  }

  private _bindMoveClick(doc, moveType: TrafficMovementType) {
    const move: any = this.schemaSet.schema[moveType];
    const element = doc.getElementById(moveType);
    move?.deadEnd && element.setAttribute('xlink:href', '#deadend');

    element.addEventListener('click', () => {
      const index = this.enabledMoves.indexOf(moveType);
      if (index < 0) {
        this.enabledMoves = this.enabledMoves.filter(
          (n) => !this.conflicts[moveType] || this.conflicts[moveType].indexOf(n) < 0
        );
        this.enabledMoves.push(moveType);
        this._checkDepend(true);
        // this._checkPedestrianMovesEnable(true);
      } else {
        this.enabledMoves.splice(index, 1);
        this._checkDepend(false);
        // this._checkPedestrianMovesEnable(false);
      }

      this.selectionChange.emit(this.enabledMoves);
      this.updateChanges();
    });
  }

  private _checkDepend(enable: boolean) {
    this.dependMoves.forEach((m) => {
      if (this.enabledMoves.includes(m.move) && !this.enabledMoves.includes(m.depend)) {
        if (enable) {
          this.enabledMoves.push(m.depend);
        } else {
          const index = this.enabledMoves.indexOf(m.move);
          this.enabledMoves.splice(index, 1);
        }
      }
    });
  }
}
