import { Injectable } from '@angular/core';

import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import {
  IGisObject,
  IGisObjectGroupService,
} from 'projects/msu-its-web-common/src/utils/gis-object-group';

import {
  getPageReturn,
  IDataSourceService,
  IPageParams,
  IPageReturn,
  setPageParams,
} from 'projects/msu-its-web-common/src/data/data-source';

import {
  CreateGeoObjectGroupRequest,
  DeleteGeoObjectGroupsRequest,
  EntityGroup,
  EntityGroupInfo,
  GeoObject,
  GetGeoObjectGroupRequest,
  GetGeoObjectGroupsRequest,
  GetItemEntityGroupsRequest,
  UpdateGeoObjectGroupRequest,
} from '../dtos/gis.dtos';

import { GisApiService } from './gis-api.service';
import { GeoObjectService } from './geo-object.service';

@Injectable()
export class GeoObjectGroupService
  implements IDataSourceService<EntityGroup, EntityGroupInfo>, IGisObjectGroupService {
  constructor(private _api: GisApiService, private _geoObjectService: GeoObjectService) {}

  permissions = ['geoObject.read'];

  getGroupList(customerId: string) {
    return this.getList(customerId);
  }
  getObjectList(customerId: string, groupIds: string[]): Observable<IGisObject[]> {
    return this._geoObjectService
      .getList(customerId, null, groupIds, true)
      .pipe(catchError(() => of([] as GeoObject[])))
      .pipe(
        map((items) => {
          return items.map((m) => ({
            id: m.id,
            name: m.name,
            description: m.description,
            geoJson: m.geoJson,
          }));
        })
      );
  }

  getObjectGroups(objectId: string, customerId: string) {
    const request = new GetItemEntityGroupsRequest({
      id: objectId,
      customerId,
    });

    return this._api.get(request).pipe(map((m) => m.items));
  }

  getPagedList(params: IPageParams, customerId: string): Observable<IPageReturn<EntityGroupInfo>> {
    if (!customerId) {
      return of(null);
    }

    const request = new GetGeoObjectGroupsRequest();
    request.customerId = customerId;

    setPageParams(request, params);
    return this._api.get(request).pipe(
      map((m) => {
        return getPageReturn(m);
      })
    );
  }
  getList(customerId: string, filter?: string, owned = false) {
    const request = new GetGeoObjectGroupsRequest();
    request.customerId = customerId;

    request.page = 0;
    request.items = 0;
    request.sort = 'name';
    request.filter = filter;
    request.onlyOwnedItems = owned;

    return this._api.get(request).pipe(
      map((m) => {
        return m.items;
      })
    );
  }

  get(id: string, customerId: string) {
    const request = new GetGeoObjectGroupRequest();
    request.id = id;
    request.customerId = customerId;
    return this._api.get(request);
  }
  add(model: EntityGroup, params: { customerId: string }) {
    const request = new CreateGeoObjectGroupRequest();
    request.customerId = params.customerId;
    request.entity = model;
    return this._api.post(request);
  }
  update(model: EntityGroup, params: { customerId: string }) {
    const request = new UpdateGeoObjectGroupRequest();
    request.id = model.id;
    request.entity = model;
    request.customerId = params.customerId;
    return this._api.put(request);
  }
  delete(ids: string[], customerId: string): Observable<number> {
    const request = new DeleteGeoObjectGroupsRequest();
    request.ids = ids;
    request.customerId = customerId;
    return this._api.delete(request);
  }
  exist(name: string, customerId: string) {
    return of(false);
  }
}
