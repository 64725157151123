import { Inject, Injectable } from "@angular/core";
import {
  getPageReturn,
  IPageParams,
  IPageReturn,
  setPageParams,
} from "projects/msu-its-web-common/src/data/data-source";

import { TokenService } from "projects/msu-its-web-common/src/services/token.service";
import { APP_ENVIRONMENT } from "projects/msu-its-web-common/src/utils/shared-consts";
import { Observable, of } from "rxjs";
import { map } from "rxjs/operators";

import {
  Camera,
  CameraGroup,
  CreateCameraGroupRequest,
  DeleteCameraGroupsRequest,
  GetCameraGroupRequest,
  GetCameraGroupsRequest,
  GetCameraRequest,
  GetCamerasRequest,
  GetItemEntityGroupsRequest,
  UpdateCameraGroupRequest,
} from "../dtos/dashboard.dtos";

import { DashboardApiService } from "./dashboard-api.service";

@Injectable()
export class DashboardCameraGroupsService {
  constructor(
    private _api: DashboardApiService,
    private _tokenService: TokenService,
    @Inject(APP_ENVIRONMENT) private _appEnv
  ) {}

  getObjectGroups(objectId: string, customerId: string) {
    const request = new GetItemEntityGroupsRequest({
      id: objectId,
      customerId,
    });
    return this._api.get(request).pipe(map((m) => m.items));
  }

  getPagedList(
    params: IPageParams,
    customerId: string
  ): Observable<IPageReturn<CameraGroup>> {
    if (!customerId) {
      return of(null);
    }

    const request = new GetCameraGroupsRequest();
    request.customerId = customerId;

    setPageParams(request, params);
    return this._api.get(request).pipe(
      map((m) => {
        return getPageReturn(m);
      })
    );
  }

  getList(customerId: string, filter?: string, owned = false) {
    const request = new GetCameraGroupsRequest();
    request.customerId = customerId;

    request.page = 0;
    request.items = 0;
    request.sort = "name";
    request.filter = filter;
    request.onlyOwnedItems = owned;

    return this._api.get(request).pipe(
      map((m) => {
        return m.items;
      })
    );
  }
  getCameraList(
    customerId: string,
    filter?: string,
    owned = false
  ): Observable<Camera[]> {
    const request = new GetCamerasRequest();
    request.customerId = customerId;

    request.page = 0;
    request.items = 0;
    request.sort = "name";
    request.filter = filter;
    request.onlyOwnedItems = owned;
    return this._api.get(request).pipe(
      map((m: any) => {
        return m.items;
      })
    );
  }
  getCamera(customerId: string, id: string): Observable<Camera> {
    const request = new GetCameraRequest();
    request.customerId = customerId;
    request.id = id;
    return this._api.get(request);
  }

  getGroupList(customerId: string) {
    return this.getList(customerId);
  }

  getDashboardCameraGroupsRequest(params: IPageParams, customerId: string) {
    const request = new GetCameraGroupsRequest();
    setPageParams(request, params);
    request.customerId = customerId;
    return this._api.get(request);
  }

  get(customerId: string, id: string) {
    const request = new GetCameraGroupRequest();
    request.customerId = customerId;
    request.id = id;
    return this._api.get(request);
  }
  add(entity: CameraGroup, params: { customerId: string }) {
    const request = new CreateCameraGroupRequest();
    request.entity = entity;
    request.customerId = params.customerId;
    return this._api.post(request);
  }
  update(entity: CameraGroup, params: { customerId: string; modelId: string }) {
    const request = new UpdateCameraGroupRequest();
    request.entity = entity;
    request.customerId = params.customerId;
    request.id = params.modelId;
    return this._api.put(request);
  }
  delete(ids: string[], customerId: string) {
    const request = new DeleteCameraGroupsRequest();
    request.ids = ids;
    request.customerId = customerId;
    return this._api.delete(request);
  }

  getCamerasRequest(params: IPageParams, customerId: string) {
    const request = new GetCamerasRequest();
    setPageParams(request, params);
    request.customerId = customerId;
    return this._api.get(request);
  }
}
