import { Injectable, OnDestroy } from '@angular/core';

import { BehaviorSubject, EMPTY, forkJoin, NEVER, of, Subject } from 'rxjs';
import {
  catchError,
  delay,
  finalize,
  map,
  mergeMap,
  repeat,
  shareReplay,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs/operators';

import { SettingsService } from 'projects/msu-its-web-common/src/services/settings.service';

import {
  PotokSettings,
  RecoveryMovement,
  Route,
  RouteInfo,
  RouteObject,
  StepMovement,
  TrafficControllerType,
  TrafficObject,
  UsdkSettings,
} from '../../dtos/tlc.dtos';
import {
  ControlSession,
  ControlSessionStatus,
  ControlSessionSummary,
  GetTrackingDevicesStatusResponse,
  Position,
  TrackingDeviceInfo,
  TrackingDeviceStatus,
  TrafficMovementType,
} from '../../dtos/tpc.dtos';
import { SchemaSet, SchemaUtils } from '../../dtos/schema';
import { LayoutSvgUtils } from '../../dtos/layout';

import { TrafficObjectService } from '../../services/traffic-object.service';
import { UsdkTrafficObjectService } from '../../services/usdk-traffic-object.service';
import { PotokTrafficObjectService } from '../../services/potok-traffic-object.service';
import { RouteService } from '../../services/route.service';
import { TrackingDeviceService } from '../../services/tracking-device.service';
import { ControlSessionService } from '../../services/control-session.service';
import { FlashService } from 'projects/msu-its-web-common/src/services/flash.service';
import { TranslateService } from '@ngx-translate/core';

export enum EscortStepStatus {
  InProgress = 'InProgress',
  Completed = 'Completed',
  Wait = 'Wait',
}

export interface IEscortStep {
  routeObject: RouteObject;
  status: EscortStepStatus;
  startTime?: number;
  completeTime?: number;
}

@Injectable()
export class RouteEscortState2Service implements OnDestroy {
  _destroy = new Subject();

  // test = false;
  customerId: string;
  selectedRouteBounded = false;

  detectionRadius = 500;
  suspendTime = 180;

  routesSubject = new BehaviorSubject<RouteInfo[]>([]);
  trackersSubject = new BehaviorSubject<TrackingDeviceInfo[]>([]);
  trackersStatusSubject = new BehaviorSubject<{ [id: string]: TrackingDeviceStatus }>(null);

  escortSessionStatusSubject = new BehaviorSubject<ControlSessionStatus>(null);
  escortSessionSummarySubject = new BehaviorSubject<ControlSessionSummary>(null);

  trafficObjects = new Map<string, TrafficObject>();
  trafficObjectsSchemaSets = new Map<string, SchemaSet>();
  trafficObjectsSettings = new Map<string, UsdkSettings | PotokSettings>();

  private readonly _tracker = new BehaviorSubject<TrackingDeviceInfo>(null);
  readonly tracker$ = this._tracker.pipe(shareReplay(1));
  get tracker(): TrackingDeviceInfo {
    return this._tracker.getValue();
  }
  set tracker(value) {
    this._tracker.next(value);
  }

  private readonly _tracker2 = new BehaviorSubject<TrackingDeviceInfo>(null);
  readonly tracker2$ = this._tracker2.pipe(shareReplay(1));
  get tracker2(): TrackingDeviceInfo {
    return this._tracker2.getValue();
  }
  set tracker2(value) {
    this._tracker2.next(value);
  }

  private readonly _selectedRoute = new BehaviorSubject<Route>(null);
  readonly selectedRoute$ = this._selectedRoute.pipe(shareReplay(1));
  get selectedRoute(): Route {
    return this._selectedRoute.getValue();
  }
  set selectedRoute(value) {
    this._selectedRoute.next(value);
  }

  private readonly _escortSession = new BehaviorSubject<ControlSession>(null);
  readonly escortSession$ = this._selectedRoute.pipe(shareReplay(1));
  get escortSession(): ControlSession {
    return this._escortSession.getValue();
  }
  set escortSession(value) {
    this._escortSession.next(value);
  }

  // ================================================================

  // escortSteps: IEscortStep[] = [];
  // escortStepsChanged = new EventEmitter();

  private readonly _loading = new BehaviorSubject<boolean>(false);
  readonly loading$ = this._loading.pipe(shareReplay(1));
  get loading(): boolean {
    return this._loading.getValue();
  }
  set loading(value) {
    this._loading.next(value);
  }

  // private readonly _escortStarted = new BehaviorSubject<boolean>(false);
  // readonly escortStarted$ = this._escortStarted.pipe(shareReplay(1));
  // get escortStarted(): boolean {
  //   return this._escortStarted.getValue();
  // }
  // set escortStarted(value) {
  //   this._escortStarted.next(value);
  // }

  constructor(
    private _routeService: RouteService,
    private _escortSessionService: ControlSessionService,
    private _trackerService: TrackingDeviceService,
    private _trafficObjectService: TrafficObjectService,
    private _usdkTrafficObjectService: UsdkTrafficObjectService,
    private _potokTrafficObjectService: PotokTrafficObjectService,
    private _flashService: FlashService,
    private _settingsService: SettingsService,
    public translation: TranslateService
  ) {
    of(null)
      .pipe(
        mergeMap(() =>
          this.escortSession ? this._getEscortSessionStatus() : this._getTrackersStatus()
        ),
        catchError(() => EMPTY),
        // tap((result) => {}),
        delay(1 * 1000),
        repeat(),
        takeUntil(this._destroy)
      )
      .subscribe();

    // this.testSummary();
  }

  testSummary() {
    const m = new ControlSessionSummary();

    m.route = new Route({});
    m.session = new ControlSession({ name: 'Session name' });

    m.avgSpeed = 60;
    m.distance = 3.75;
    m.duration = 3000;
    m.start = new Date().toISOString();
    m.end = new Date().toISOString();

    m.speed = [];
    m.track = [];

    for (let index = 0; index < 100; index++) {
      const date = new Date();
      date.setMinutes(index);
      m.track.push(new Position({ deviceTime: date.toISOString() }));
      m.speed.push((Math.cos(index * 2) + 5) * 5);
    }

    this.escortSessionSummarySubject.next(m);
  }

  ngOnDestroy() {
    this._destroy.next();
    this._destroy.complete();

    this._tracker.complete();
    this._tracker2.complete();
    this._selectedRoute.complete();

    this.routesSubject.complete();
    this.trackersSubject.complete();

    // this.escortStepsChanged.complete();
  }

  loadRoutes() {
    this._routeService
      .getList(this.customerId)
      .pipe(catchError(() => NEVER))
      .subscribe((result) => {
        this.routesSubject.next(result);
      });
  }
  loadTrackers() {
    this._trackerService
      .getList(this.customerId)
      .pipe(catchError(() => NEVER))
      .subscribe((result) => {
        this.trackersSubject.next(result);
      });
  }

  selectRoute(id: string) {
    if (!id) {
      this.selectedRoute = null;
      return of(null);
    }

    this.loading = true;
    return this._routeService
      .get(id, this.customerId)
      .pipe(
        switchMap((route) => {
          return this.loadRouteDetails(route).pipe(map(() => route));
        })
      )
      .pipe(finalize(() => (this.loading = false)))
      .pipe(
        tap((route) => {
          route.settings.routeObjects = route.settings.routeObjects.filter((m) =>
            this.trafficObjects.has(m.trafficObjectId)
          );
          this.selectedRoute = route;
        })
      );
  }
  loadRouteDetails(selectedRoute: Route) {
    this.selectedRoute = null;

    this.trafficObjects.clear();
    this.trafficObjectsSchemaSets.clear();
    this.trafficObjectsSettings.clear();

    const forkObs = [of(null)];
    selectedRoute?.settings.routeObjects.forEach((m) => {
      forkObs.push(
        this._trafficObjectService.get(m.trafficObjectId, this.customerId).pipe(
          switchMap((t) => {
            this.trafficObjects.set(m.trafficObjectId, t);
            return t.controllerType == TrafficControllerType.Usdk
              ? this._usdkTrafficObjectService.getSettings(m.trafficObjectId, this.customerId)
              : t.controllerType == TrafficControllerType.Potok
              ? this._potokTrafficObjectService.getSettings(m.trafficObjectId, this.customerId)
              : of(null);
          }),
          tap((s) => this.trafficObjectsSettings.set(m.trafficObjectId, s))
        )
      );
      forkObs.push(
        this._trafficObjectService
          .getSchemaSet(m.trafficObjectId, this.customerId)
          .pipe(tap((t) => this.trafficObjectsSchemaSets.set(m.trafficObjectId, t)))
      );
    });

    return forkJoin(forkObs);
  }
  loadObjectDetails(objectId) {
    const forkObs = [of(null)];

    forkObs.push(
      this._trafficObjectService.get(objectId, this.customerId).pipe(
        switchMap((t) => {
          this.trafficObjects.set(objectId, t);
          return t.controllerType == TrafficControllerType.Usdk
            ? this._usdkTrafficObjectService.getSettings(objectId, this.customerId)
            : t.controllerType == TrafficControllerType.Potok
            ? this._potokTrafficObjectService.getSettings(objectId, this.customerId)
            : of(null);
        }),
        tap((s) => this.trafficObjectsSettings.set(objectId, s))
      )
    );
    forkObs.push(
      this._trafficObjectService
        .getSchemaSet(objectId, this.customerId)
        .pipe(tap((t) => this.trafficObjectsSchemaSets.set(objectId, t)))
    );

    return forkJoin(forkObs);
  }

  objectSvgLoad(target, item: RouteObject, step: StepMovement | RecoveryMovement) {
    const object = this.trafficObjects.get(item.trafficObjectId);
    const settings = this.trafficObjectsSchemaSets.get(item.trafficObjectId);

    const schema = settings.schema;
    const schemaView = settings.schemaView;
    const isDark = this._settingsService.darkTheme;
    const svg = target.contentDocument;

    const phaseMovements = [];
    switch (object.controllerType) {
      case TrafficControllerType.Potok:
        phaseMovements.push(...step.movements);
        break;
      case TrafficControllerType.Usdk:
        const phase = (
          this.trafficObjectsSettings.get(item.trafficObjectId) as UsdkSettings
        )?.phases.find((m) => m.id == step.phase);
        phase && phaseMovements.push(...phase?.movements);
        break;
      default:
        break;
    }

    const movements = [];
    phaseMovements.forEach((m) => {
      movements.push(...SchemaUtils.schemaViewMovements(schema, m));
    });

    LayoutSvgUtils.updateLayoutSchema(svg, schema, schemaView, isDark);
    LayoutSvgUtils.showLayoutMovements(svg, schema, movements);
  }
  getObjectAngle(item: RouteObject) {
    return this.trafficObjectsSchemaSets.get(item.trafficObjectId)?.schemaView?.angle;
  }
  getObjectName(item: RouteObject) {
    return this.trafficObjects.get(item.trafficObjectId)?.name;
  }

  selectTracker(id: string) {
    this.tracker = id ? this.trackersSubject.value.find((m) => m.id === id) : null;
    if (!id) this.tracker2 = null;
  }
  selectTracker2(id: string) {
    this.tracker2 = id ? this.trackersSubject.value.find((m) => m.id === id) : null;
  }

  _getTrackersStatus() {
    const ids = [this.tracker?.id, this.tracker2?.id].filter((m) => m);
    return (
      ids.length
        ? this._trackerService.getStatusList(ids, this.customerId)
        : of(null as GetTrackingDevicesStatusResponse)
    ).pipe(
      map((res) => {
        const trackersStatus = (res?.items || []).reduce((result, item) => {
          result[item.id] = item;
          return result;
        }, {} as { [id: string]: TrackingDeviceStatus });
        this.trackersStatusSubject.next(trackersStatus);
      })
    );
  }
  _getEscortSessionStatus() {
    return this._escortSessionService.getStatus(this.escortSession.id, this.customerId).pipe(
      map((status) => {
        const trackersStatus = [status.trackingDevice, status.trackingDevice2].reduce(
          (result, item) => {
            if (!item) return;
            result[item.id] = item;
            return result;
          },
          {} as { [id: string]: TrackingDeviceStatus }
        );

        this.escortSessionStatusSubject.next(status);
        this.trackersStatusSubject.next(trackersStatus);
      })
    );
  }

  startEscort() {
    this.loading = true;
    this.escortSessionStatusSubject.next(null);
    this.escortSessionSummarySubject.next(null);

    this._escortSessionService
      .start(
        {
          name: this.selectedRoute.name,
          routeId: this.selectedRoute.id,
          trackerId: this.tracker?.id,
          tracker2Id: this.tracker2?.id,
          detectionRadius: this.detectionRadius,
          suspendTime: this.suspendTime,
        },
        this.customerId
      )
      .pipe(finalize(() => (this.loading = false)))
      .subscribe((session) => {
        this._escortSession.next(session);
      });
  }
  stopEscort() {
    this.loading = true;
    this._escortSessionService
      .stop(this.escortSession.id, this.customerId)
      .pipe(
        switchMap(() =>
          this._escortSessionService.getSummary(this.escortSession.id, this.customerId)
        )
      )
      .pipe(finalize(() => (this.loading = false)))
      .subscribe((summary) => {
        this._escortSession.next(null);
        this.escortSessionStatusSubject.next(null);
        this.escortSessionSummarySubject.next(summary);
        // this._flashService.success(this.translation.instant('ROUTE_ECORT.ESCORT_COMPLETED'));
      });
  }

  startTrafficObject(trafficObjectId, phaseId?: number, movements?: TrafficMovementType[]) {
    if (!this.escortSession) return;
    this._escortSessionService
      .startTrafficObject(
        this.escortSession.id,
        this.customerId,
        trafficObjectId,
        phaseId,
        movements
      )
      .subscribe();
  }
  stopTrafficObject(trafficObjectId) {
    if (!this.escortSession) return;
    this._escortSessionService
      .stopTrafficObject(this.escortSession.id, this.customerId, trafficObjectId)
      .subscribe();
  }

  // =============================================================================

  // startEscort() {
  //   if (!this.selectedRoute || this.escortStarted) {
  //     return;
  //   }

  //   this.escortSteps = [];
  //   this.escortStarted = true;

  //   timer(0, 1000)
  //     .pipe(takeWhile(() => this.escortStarted))
  //     .subscribe(() => {
  //       this.escortStepsChanged.emit();
  //     });
  // }
  // stopEscort() {
  //   if (!this.escortStarted) {
  //     return;
  //   }

  //   const stopObs = [of(null)];
  //   this.escortSteps.forEach((m) => {
  //     m.status == EscortStepStatus.InProgress &&
  //       stopObs.push(this.stopStep(m.routeObject.trafficObjectId));
  //   });

  //   forkJoin(stopObs).subscribe(() => {
  //     this.escortStarted = false;
  //     this.selectedRoute = null;
  //   });
  // }

  // startStep(id) {
  //   let step = this.escortSteps.find(
  //     (m) => m.routeObject.trafficObjectId == id && m.status != EscortStepStatus.Completed
  //   );

  //   if (step?.status == EscortStepStatus.Wait) {
  //     return;
  //   }

  //   const object = this.trafficObjects.get(id);
  //   const routeObject = this.selectedRoute.settings.routeObjects.find(
  //     (m) => m.trafficObjectId == id
  //   );

  //   if (step) {
  //     step.status = EscortStepStatus.Wait;
  //   } else {
  //     step = { status: EscortStepStatus.Wait, routeObject };
  //     this.escortSteps.push(step);
  //   }

  //   let request$: Observable<any>;

  //   switch (object.controllerType) {
  //     case TrafficControllerType.Potok:
  //       request$ = this.test
  //         ? timer(4000)
  //         : this._potokTrafficObjectService.setRemoteMode(
  //             id,
  //             this.customerId,
  //             <any>routeObject.stepMovement.movements,
  //             this._settingsService.escortSuspendTime
  //           );
  //       break;
  //     case TrafficControllerType.Usdk:
  //       request$ = this.test
  //         ? timer(4000)
  //         : this._usdkTrafficObjectService.setRemoteMode(
  //             id,
  //             this.customerId,
  //             routeObject.stepMovement.phase,
  //             this._settingsService.escortSuspendTime
  //           );
  //       break;

  //     default:
  //       request$ = timer(4000);
  //       break;
  //   }

  //   return request$.pipe(catchError(() => of({ error: true }))).pipe(
  //     tap((result) => {
  //       const now = Date.now();
  //       if (result.error) {
  //         step.status = EscortStepStatus.Completed;
  //         step.startTime = now;
  //         step.completeTime = now;
  //       } else {
  //         step.startTime = now;
  //         step.completeTime = now + this._settingsService.escortSuspendTime * 1000;
  //         step.status = EscortStepStatus.InProgress;
  //       }
  //       this.escortStepsChanged.emit();
  //     })
  //   );
  // }
  // stopStep(id) {
  //   let step = this.escortSteps.find(
  //     (m) => m.routeObject.trafficObjectId == id && m.status != EscortStepStatus.Completed
  //   );

  //   if (step?.status == EscortStepStatus.Wait) {
  //     return;
  //   }

  //   const object = this.trafficObjects.get(id);
  //   const routeObject = this.selectedRoute.settings.routeObjects.find(
  //     (m) => m.trafficObjectId == id
  //   );

  //   if (step) {
  //     step.status = EscortStepStatus.Wait;
  //   } else {
  //     step = { status: EscortStepStatus.Wait, routeObject };
  //     this.escortSteps.push(step);
  //   }

  //   let request$: Observable<any>;

  //   switch (object.controllerType) {
  //     case TrafficControllerType.Potok:
  //       request$ = this.test
  //         ? timer(4000)
  //         : this._potokTrafficObjectService.setCoordinationMode(id, this.customerId);
  //       break;
  //     case TrafficControllerType.Usdk:
  //       request$ = this.test
  //         ? timer(4000)
  //         : this._usdkTrafficObjectService.setCoordinationMode(id, this.customerId);
  //       break;

  //     default:
  //       request$ = timer(4000);
  //       break;
  //   }

  //   return request$.pipe(catchError(() => of({ error: true }))).pipe(
  //     tap((result) => {
  //       const now = Date.now();
  //       if (result.error) {
  //         step.status = EscortStepStatus.Completed;
  //         step.startTime = now;
  //         step.completeTime = now;
  //       } else {
  //         step.status = EscortStepStatus.Completed;
  //         step.completeTime = now;
  //       }
  //       this.escortStepsChanged.emit();
  //     })
  //   );
  // }
}
