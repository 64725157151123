import {
  Component,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Injector,
} from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';

import { DataSourceForm } from '../../data/data-source-form';

import { Customer } from '../../dtos/hub.dtos';
import { FormValidators } from '../../utils/form-validators';
import { CustomerService } from '../../services/customer.service';

@Component({
  selector: 'customer-edit',
  templateUrl: './customer-edit.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomerEditComponent extends DataSourceForm<Customer> {
  constructor(injector: Injector, changeDetector: ChangeDetectorRef) {
    super(injector, changeDetector);
  }

  initFormGroup(fb: FormBuilder): FormGroup {
    return fb.group({
      name: [
        '',
        [
          Validators.required,
          // Validators.minLength(3),
          Validators.maxLength(100),
        ],
        [
          FormValidators.exist(this.model.name, (name: string) =>
            (<CustomerService>this.modelService).exist(name)
          ),
        ],
      ],
    });
  }

  prepareModelToSave(): Customer {
    const model = Object.assign(new Customer(), this.model);
    model.name = this.name.value;
    return model;
  }

  get name() {
    return this.formGroup.controls['name'];
  }
}
