<h4 mat-dialog-title fxLayoutAlign="start center">
  <span> {{"CAMERAS_FTP.IMAGE_VIEWER"| translate}}</span>
</h4>
<div
  mat-dialog-content
  fxLayout="row"
  fxLayoutGap="24px"
  style="justify-content: center; align-items: center;"
>
  <img
    style="max-width: 72vw; max-height: 70vh;"
    [src]="'data:image/png;base64,' + imageData?.image | safeHtml "
  />
</div>

<div mat-dialog-actions align="end">
  <div fxLayout="row" fxLayoutAlign="end center">
    <button mat-button (click)="close()">
      {{ "COMMON.CANCEL" | translate }}
    </button>
  </div>
</div>
