import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  Input,
  OnInit,
} from "@angular/core";
import { marker as _ } from "@biesbjerg/ngx-translate-extract-marker";
import { MatTabChangeEvent } from "@angular/material/tabs";

import {
  Detector,
  DetectorDeviceType,
  DetectorGraphAnalysis,
  DetectorInfo,
} from "../../dtos/tfms.dtos";
import { DetectorService } from "../../services/detector.service";
import * as echarts from "echarts";
import { finalize } from "rxjs/operators";
import { DialogService } from "projects/msu-its-web-common/src/services/dialog.service";
import { DetectorHistoryComponent } from "./detector-history.component";
import { DetectorExportComponent } from "./detector-export.component";
import { APP_ENVIRONMENT } from "projects/msu-its-web-common/src/utils/shared-consts";
import { TranslateService } from "@ngx-translate/core";
import { LANGS_LOCALES } from "projects/msu-its-web-common/src/utils/langs";
import { SettingsService } from "projects/msu-its-web-common/src/services/settings.service";
import { DetectorDeviceService } from "../../services/detector-device.service";
import { FlashService } from "projects/msu-its-web-common/src/services/flash.service";
import { Observable, of } from "rxjs";
import { TokenService } from "projects/msu-its-web-common/src/services/token.service";

interface IBarnaulOpticLine {
  x1: string;
  y1: string;
  x2: string;
  y2: string;
}

interface IBarnaulOpticDraw {
  type: "line" | "path" | "line-dasharray";
  path?: string;
  line?: IBarnaulOpticLine;
}

interface IBarnaulOptic {
  opticId: string;
  draw: IBarnaulOpticDraw[];
}

@Component({
  selector: "app-detector-popup",
  templateUrl: "./detector-popup.component.html",
  styleUrls: ["./detector-popup.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DetectorPopupComponent implements OnInit {
  barnaulOpticData: IBarnaulOptic[] = [
    {
      opticId: "c470f792-f1e3-4cf1-b237-574bcb50c38e",
      draw: [
        {
          type: "line",
          line: {
            x1: "52.5",
            y1: "35",
            x2: "61",
            y2: "90",
          },
        },
        { type: "path", path: "M47, 35 L 47,90 75,90 58,35 47,35 Z" },
      ],
    },
    {
      opticId: "03355199-e016-4373-9b58-ee910c8d07eb",
      draw: [
        {
          type: "line",
          line: {
            x1: "57.5",
            y1: "35",
            x2: "62",
            y2: "90",
          },
        },
        { type: "path", path: "M50, 35 L 47,90 77,90 65,35 50,35 Z" },
      ],
    },
    {
      opticId: "097972bf-eb3b-4e70-96ed-a9d7e8fb3f04",
      draw: [
        {
          type: "line",
          line: {
            x1: "39",
            y1: "45",
            x2: "39.75",
            y2: "90",
          },
        },
        {
          type: "line",
          line: {
            x1: "51",
            y1: "45",
            x2: "62.25",
            y2: "90",
          },
        },
        {
          type: "line-dasharray",
          line: {
            x1: "45",
            y1: "45",
            x2: "52.5",
            y2: "90",
          },
        },
        { type: "path", path: "M33, 45 L 27,90 78,90 57,45 33,45 Z" },
      ],
    },
    {
      opticId: "71d2f759-76f4-49db-9a39-e6c7889a0390",
      draw: [
        {
          type: "line",
          line: {
            x1: "44",
            y1: "49.5",
            x2: "53.5",
            y2: "88",
          },
        },
        { type: "path", path: "M37, 50 L 40,89 67,87 51,49 37,50 Z" },
      ],
    },
  ];

  @Input()
  modelId: string;

  @Input()
  customerId: string;

  @Input()
  modelInfo: Detector;

  modelInfoSession: DetectorInfo;

  modelPermissions$: Observable<string[]> = of(null);

  external: boolean = false;

  isCheckedAreas = false;

  actions: { name: string; action: string; permissions?: string[] }[] = [
    {
      name: _("COMMON.EDIT"),
      action: "openEdit",
      permissions: ["detector.update"],
    },
    {
      name: _("COMMON.HISTORY"),
      action: "openHistory",
      permissions: ["detector.read"],
    },
    {
      name: _("COMMON.EXPORT"),
      action: "export",
      permissions: ["detector.read"],
    },
    // { name: _('DEVICES.SESSION_DISCONNECT'), action: 'disconnect' },
    // { name: _("AUDIT_LOG.TITLE"), action: "auditLog" },
    { name: _("EVENT_LOG.TITLE"), action: "eventLog" },
  ];

  lane = 0;

  get color() {
    return this._settingsService.darkTheme ? "white" : "black";
  }

  get lanes() {
    return [1, 2, 3, 4, 5, 6, 7, 8];
  }
  loading = false;
  loadingTab = false;
  indexTab;
  isOptic;
  chartParams;
  avgSpeedTable: any;
  countTable: any;
  laneFillTable: any;
  options;
  AvgSpeedRed = "#ff3300";
  CountYellow = "#ffcc00";
  LaneFillGreen = "#00cc00";
  detectorDeviceSession;
  temptureColor = this.CountYellow;
  humidityColor = "#4d94ff";
  heaterColor = "#ff6a00";
  chartWeather: any;
  optionWeather: any;
  temperature;
  humidity;

  dataAnalyserDetectors: DetectorGraphAnalysis;
  dataForecastDetectors: DetectorGraphAnalysis;
  currentSpeed = 0;
  pastYearAvgSpeed = 0;
  currentLaneFill = 0;
  pastYearLaneFill = 0;
  pastYearCount = 0;
  currentCount = 0;
  atypicalCount = false;
  atypicalLaneFill = false;
  atypicalSpeed = false;
  atypicalAll = false;
  selectedDataTypes;
  selectedForecastDataTypes;

  avgSpeedForecast = 0;
  avgCountForecast = 0;
  avgFillForecast = 0;

  get locale() {
    return LANGS_LOCALES[this.translate.currentLang];
  }

  openEdit() {
    this._dialogService.windowDialog(
      `${this.customerId}/ext/detectors/${this.modelId}/edit`,
      "DetectorEdit_" + this.modelId,
      "width=940,height=756"
    );
  }

  openInNew() {
    this._dialogService.windowDialog(
      `${this.customerId}/ext/detectors/${this.modelId}`,
      "Detector_" + this.modelId,
      "menubar=0,toolbar=0,titlebar=0,location=0,locationbar=0,width=800,height=610,resizable=0"
    );
  }
  disconnect() {
    this._detectorDeviceService
      .disconnectDetectorDevice(
        this.modelInfo.detectorDeviceId,
        this.customerId
      )
      .subscribe((result) => {
        if (result) {
          this._flashService.success(
            this.translate.instant("DEVICES.SESSION_DISCONNECTED", {
              number: this.modelInfo.detectorDeviceId,
            })
          );
        }
      });
  }
  // auditLog() {
  //   this._dialogService.windowDialog(
  //     `${this.customerId}/ext/detectors/${this.modelId}/` + "audit-log",
  //     "trafficObjectAuditLog_" + this.modelId,
  //     "menubar=0,toolbar=0,titlebar=0,location=0,locationbar=0,width=950,height=850,resizable=0"
  //   );
  // }
  eventLog() {
    this._dialogService.windowDialog(
      `${this.customerId}/ext/detectors/${this.modelId}/` + "event-log",
      "trafficObjectEventLog_" + this.modelId,
      "menubar=0,toolbar=0,titlebar=0,location=0,locationbar=0,width=950,height=850,resizable=0"
    );
  }

  openHistory() {
    const dialog = this._dialogService.dialog.open(DetectorHistoryComponent, {
      disableClose: true,
    });
    dialog.componentInstance.modelId = this.modelId;
    dialog.componentInstance.modelInfo = this.modelInfo;
    dialog.componentInstance.customerId = this.customerId;
  }
  export() {
    const dialog = this._dialogService.dialog.open(DetectorExportComponent, {
      disableClose: true,
    });
    dialog.componentInstance.modelId = this.modelId;
    dialog.componentInstance.modelName = this.modelInfo.name;
  }

  dataDetector: Detector;
  constructor(
    private _detectorService: DetectorService,
    private _changeDetector: ChangeDetectorRef,
    private _dialogService: DialogService,
    private _flashService: FlashService,
    private _settingsService: SettingsService,
    private _detectorDeviceService: DetectorDeviceService,
    private _tokenService: TokenService,
    public translate: TranslateService,
    @Inject(APP_ENVIRONMENT) private appEnv: any
  ) {}

  ngOnInit() {
    // permissions
    if (this.modelInfo.customerId != this.customerId) {
      this.modelPermissions$ = this._detectorService.getPermissions(
        this.modelId,
        this.customerId
      );
    }
    this._updateActionsByPermissions();

    // echarts
    if (!this.external) {
      let echartsTest = document.getElementById(`host`) as HTMLDivElement;
      echartsTest.style.height = "395px";
    } else {
      let echartsTest = document.getElementById(`host`) as HTMLDivElement;
      echartsTest.style.height = "500px";
    }

    this.isOptic =
      this.modelInfo.deviceType === DetectorDeviceType.Optic ? true : false;
    this.isOptic === true
      ? this.actions.push({
          name: _("DEVICES.SESSION_DISCONNECT"),
          action: "disconnect",
        })
      : null;
    setTimeout(() => this.graf(), 500);
    this.selectedDataTypes = "AvgSpeed";
    this.selectedForecastDataTypes = "AvgSpeed";
    this.lane = 0;
    this._changeDetector.markForCheck();
  }

  private _updateActionsByPermissions() {
    this.modelPermissions$.subscribe((m) => {
      this.actions = this.actions.filter((a) =>
        this._tokenService.hasPermissionsForItem(
          this.modelInfo,
          this.customerId,
          a.permissions,
          m
        )
      );
    });
  }

  changeEvent(event: MatTabChangeEvent): void {
    this.indexTab = event.index;
    switch (event.index) {
      case 0: {
        // this.isOptic ? this.graf() : null;
        this.graf();
        break;
      }
      case 1: {
        this.isOptic
          ? this.getImage(false, this.isCheckedAreas)
          : this._loadInfo();
        // this.isOptic ? this.getImage(false) : this.graf();
        break;
      }
      case 2: {
        this.isOptic ? this.grafWeather() : null;
        break;
      }
      case 3: {
        this.isOptic ? this.grafAnalyser() : null;
        break;
      }
      case 4: {
        this.isOptic ? this.grafForecast() : null;
        break;
      }
      case 5: {
        this.isOptic ? this._loadInfo() : null;
        break;
      }
    }
  }
  _loadInfo() {
    this._detectorService
      .getDetectorInfo(this.customerId, this.modelId)
      .subscribe((result) => {
        this.modelInfoSession = result;
        this._changeDetector.markForCheck();
      });
  }

  changeToggleArea() {
    const barnaulData = this.barnaulOpticData.find(
      (m) => m.opticId === this.modelId
    );

    if (barnaulData) {
      this.getImage(true, false, barnaulData);
    } else {
      this.getImage(true, this.isCheckedAreas);
    }
  }

  refreshImg(): void {
    this.getImage(
      true,
      this.barnaulOpticData.find((m) => m.opticId === this.modelId)
        ? false
        : this.isCheckedAreas
    );
  }
  getImage(force, showAreas, barnauldata?: IBarnaulOptic): void {
    this.loadingTab = true;
    const dark = this._settingsService.darkTheme;
    const myImage = document.getElementById(
      `photo_${this.modelId}`
    ) as HTMLImageElement;

    const zoneSVG = document.getElementById(`zone_${this.modelId}`);

    fetch(this._detectorService.getTestImages(this.modelId, force, showAreas))
      .finally(() => {
        this.loadingTab = false;
        this._changeDetector.markForCheck();
      })
      .then((response) => {
        if (!response.ok) {
          if (response.status === 500) {
            this._flashService.warn(
              "Сервер не доступен, повторите попытку позже "
            );
          }
          throw response;
        }
        return response.blob();
      })
      .then(function (myBlob) {
        if (myBlob.size !== 0) {
          const objectURL = URL.createObjectURL(myBlob);
          myImage.src = objectURL;

          if (barnauldata) {
            function drawPath(path: string) {
              var newPath = document.createElementNS(
                "http://www.w3.org/2000/svg",
                "path"
              );
              newPath.setAttribute("d", path);
              newPath.setAttribute("fill", "none");
              newPath.setAttribute("vector-effect", "non-scaling-stroke");
              newPath.setAttribute("style", "stroke: #FF0; stroke-width: 2px;");

              return newPath;
            }
            function drawLine(x1, y1, x2, y2) {
              var newLine = document.createElementNS(
                "http://www.w3.org/2000/svg",
                "line"
              );
              newLine.setAttribute("x1", x1);
              newLine.setAttribute("y1", y1);
              newLine.setAttribute("x2", x2);
              newLine.setAttribute("y2", y2);
              newLine.setAttribute("marker-end", "url(#head)");
              newLine.setAttribute("vector-effect", "non-scaling-stroke");
              newLine.setAttribute(
                "style",
                "stroke: #D22; stroke-width: 1.5px;"
              );

              return newLine;
            }
            function drawLinedasharray(x1, y1, x2, y2) {
              var newLine = document.createElementNS(
                "http://www.w3.org/2000/svg",
                "line"
              );
              newLine.setAttribute("x1", x1);
              newLine.setAttribute("y1", y1);
              newLine.setAttribute("x2", x2);
              newLine.setAttribute("y2", y2);
              newLine.setAttribute("vector-effect", "non-scaling-stroke");
              newLine.setAttribute(
                "style",
                "stroke: #FF0; stroke-width: 1.5px;stroke-dasharray: 5 1 ;"
              );

              return newLine;
            }

            if (zoneSVG?.firstChild) {
              while (zoneSVG.firstChild) {
                zoneSVG.removeChild(zoneSVG.firstChild);
              }
            } else {
              barnauldata.draw.forEach((item) => {
                if (item.type === "line") {
                  zoneSVG.append(
                    drawLine(
                      item.line.x1,
                      item.line.y1,
                      item.line.x2,
                      item.line.y2
                    )
                  );
                }
                if (item.type === "line-dasharray") {
                  zoneSVG.append(
                    drawLinedasharray(
                      item.line.x1,
                      item.line.y1,
                      item.line.x2,
                      item.line.y2
                    )
                  );
                }
                if (item.type === "path") {
                  zoneSVG.append(drawPath(item.path));
                }

                // item.type === "line"
                //   ? zoneSVG.append(
                //       drawLine(
                //         item.line.x1,
                //         item.line.y1,
                //         item.line.x2,
                //         item.line.y2
                //       )
                //     )
                //   : zoneSVG.append(drawPath(item.path));
              });
            }

            // if (this.isCheckedAreas === false) {

            // } else if (this.isCheckedAreas === true) {
            //   zoneSVG.remove();
            // }
          }
        } else {
          dark
            ? (myImage.src = "assets/images/img_not_found.png")
            : (myImage.src = "assets/images/img_not_found_dark.png");
        }
      })
      .catch(function (error) {
        console.log(error);
        dark
          ? (myImage.src = "assets/images/img_not_found.png")
          : (myImage.src = "assets/images/img_not_found_dark.png");
      });
    this._settingsService.darkThemeChanged.subscribe((result) => {
      if (
        myImage.src === myImage.baseURI + "assets/images/img_not_found.png" ||
        myImage.src === myImage.baseURI + "assets/images/img_not_found_dark.png"
      ) {
        result
          ? (myImage.src = "assets/images/img_not_found.png")
          : (myImage.src = "assets/images/img_not_found_dark.png");
      }
    });

    // this._detectorService
    //   .getImagesTestRequest(this.modelId, force, showAreas)
    //   .subscribe((result) => {
    //     // myImage.src = URL.createObjectURL(
    //     //   new Blob([result.buffer], { type: "image/png" })
    //     // );
    //   });
  }
  selectLane(lane) {
    this.graf();
  }

  private graf(): void {
    this.loadingTab = true;

    if (this.chartParams) {
      this.chartParams.clear();
    }
    this.chartParams = echarts.init(
      document.getElementById(`graf_${this.modelId}`) as HTMLDivElement
    );

    function time(value) {
      const _time = new Date(value);
      let label;
      if (_time.getMinutes() < 10) {
        label = _time.getHours() + ":0" + _time.getMinutes();
      } else {
        label = _time.getHours() + ":" + _time.getMinutes();
      }
      return label;
    }
    const dataAxis = [];
    const dataAvgSpeed = [];
    const dataCount = [];
    const dataLaneFill = [];
    const dataTypeCarA = [];
    const dataTypeCarB = [];
    const from = new Date(Date.now());
    const to = new Date(Date.now());

    function getRandomIntInclusive(min, max) {
      min = Math.ceil(min);
      max = Math.floor(max);
      return Math.floor(Math.random() * (max - min + 1) + min); // The maximum is inclusive and the minimum is inclusive
    }
    to.setHours(to.getHours() - 24);
    this._detectorService
      .getDetectorGraph(this.modelId, to, from, this.lane)
      .pipe(
        finalize(() => {
          this.loadingTab = false;
          this._changeDetector.markForCheck();
        })
      )
      .subscribe((graph) => {
        this.avgSpeedTable = graph.avgSpeed;
        this.countTable = graph.count;
        this.laneFillTable = graph.laneFill;
        graph.items.forEach((item) => {
          let temp = getRandomIntInclusive(0.6 * item.count, item.count);
          const _time = new Date(item.time);
          dataAxis.push(time(_time));
          dataAvgSpeed.push(item.avgSpeed);
          dataCount.push(item.count);
          dataLaneFill.push(item.laneFill);
          dataTypeCarA.push(item.countCars);
          dataTypeCarB.push(item.countTrucks);
        });
        this.echartOption(
          dataAxis,
          dataAvgSpeed,
          dataCount,
          dataLaneFill,
          dataTypeCarA,
          dataTypeCarB
        );
      });
    this._settingsService.darkThemeChanged.subscribe((result) => {
      this.echartOption(
        dataAxis,
        dataAvgSpeed,
        dataCount,
        dataLaneFill,
        dataTypeCarB,
        dataTypeCarB
      );
    });
  }

  private echartOption(
    dataAxis,
    dataAvgSpeed,
    dataCount,
    dataLaneFill,
    dataCarTypeA,
    dataCarTypeB
  ): void {
    const langRu = {
      speed: "км/ч",
      count: "ед",
      AvgSpeed: "Cкорость",
      LaneFill: "Занятость",
      Count: "Количество",
    };
    this.setOptionGraf(
      dataAxis,
      dataAvgSpeed,
      dataCount,
      dataLaneFill,
      dataCarTypeA,
      dataCarTypeB,
      langRu
    );
    // this.options.backgroundColor = '#424242';
    this.options.xAxis = {
      data: dataAxis,
      type: "category",
      axisLine: {
        lineStyle: {
          color: this.color,
        },
      },
      axisLabel: {
        color: this.color,
      },
    };
    this.chartParams.setOption(this.options, true, true);
    if (this.external) {
      let echartsTest = document.getElementById(
        `graf_${this.modelId}`
      ) as HTMLDivElement;
      echartsTest.style.height = "75vh";
      this.chartParams.resize();
      this._changeDetector.markForCheck();
    }
  }
  onResize($event) {
    // if (this.external) {
    //   let echartsTest = document.getElementById(`graf_${this.modelId}`) as HTMLDivElement;
    //   echartsTest.style.height = '50vh';
    // }
    this.chartParams.resize();
    this._changeDetector.markForCheck();
  }

  private setOptionGraf(
    dataAxis,
    dataAvgSpeed,
    dataCount,
    dataLaneFill,
    dataCarTypeA,
    dataCarTypeB,
    language
  ): void {
    this.options = {
      grid: {
        left: 20,
        right: 20,

        containLabel: true,
      },
      tooltip: {
        trigger: "axis",
        backgroundColor: "#ffffffbd",
        color: "#fff",
      },

      xAxis: {
        data: dataAxis,
        type: "category",
      },
      yAxis: [
        {
          type: "value",
          // min: dataAvgSpeed.reduce((x, y) => {
          //   return x < y ? x : y;
          // }),
          // max: dataAvgSpeed.reduce((x, y) => {
          //   return x > y ? x : y;
          // }),
          axisLine: {
            lineStyle: {
              color: this.AvgSpeedRed,
            },
          },
          axisLabel: {
            formatter: `{value} ${language.speed}`,
            color: this.AvgSpeedRed,
          },
          splitLine: {
            show: false,
          },
        },
        {
          type: "value",
          // min: dataLaneFill.reduce((x, y) => {
          //   return x < y ? x : y;
          // }),
          // max: dataLaneFill.reduce((x, y) => {
          //   return x > y ? x : y;
          // }),
          axisLine: {
            lineStyle: {
              color: this.LaneFillGreen,
            },
          },
          axisLabel: {
            formatter: "{value}%",
            color: this.LaneFillGreen,
          },
          splitLine: {
            show: false,
          },
        },
        {
          type: "value",
          // min: dataCount.reduce((x, y) => {
          //   return x < y ? x : y;
          // }),
          // max: dataCount.reduce((x, y) => {
          //   return x > y ? x : y;
          // }),
          position: "right",
          offset: 45,
          axisLabel: {
            formatter: `{value} ${language.count}`,
            color: this.CountYellow,
          },
          axisLine: {
            lineStyle: {
              color: this.CountYellow,
            },
          },
          splitLine: {
            show: false,
          },
        },
      ],
      legend: {
        x: "center",
        y: "bottom",
        itemWidth: 50,
        itemGap: 20,
        data: [
          {
            name: `${language.AvgSpeed}`,
            textStyle: {
              color: this.color,
            },
          },
          {
            name: `${language.LaneFill}`,
            textStyle: {
              color: this.color,
            },
          },
          {
            name: `${language.Count}`,
            textStyle: {
              color: this.color,
            },
          },
          {
            name: "Кол-во легковых и мотоциклов",
            textStyle: {
              color: this.color,
            },
          },
          {
            name: "Кол-во грузовых и автобусов",
            textStyle: {
              color: this.color,
            },
          },
        ],
      },
      series: [
        {
          name: `${language.AvgSpeed}`,
          data: dataAvgSpeed,
          type: "line",
          smooth: true,
          color: this.AvgSpeedRed,
        },
        {
          name: `${language.LaneFill}`,
          data: dataLaneFill,
          type: "line",
          yAxisIndex: 1,
          smooth: true,
          color: this.LaneFillGreen,
        },
        {
          name: `${language.Count}`,
          data: dataCount,
          type: "line",
          smooth: true,
          color: this.CountYellow,
          yAxisIndex: 2,
        },
        {
          name: "Кол-во легковых и мотоциклов",
          type: "bar",
          barGap: 0,
          stack: "total",
          // label: labelOption,
          emphasis: {
            focus: "series",
          },
          color: "#2ECFDB",
          data: dataCarTypeA,
          yAxisIndex: 2,
        },
        {
          name: "Кол-во грузовых и автобусов",
          type: "bar",
          barGap: 0,
          stack: "total",
          // label: labelOption,
          emphasis: {
            focus: "series",
          },
          color: "#9C619C",
          yAxisIndex: 2,
          data: dataCarTypeB,
        },
      ],
      animation: false,
    };
    this.chartParams.setOption(this.options, true, true);
  }

  private grafWeather(): void {
    if (this.chartWeather) {
      this.chartWeather.clear();
    }
    this.chartWeather = echarts.init(
      document.getElementById(`grafWeather_${this.modelId}`) as HTMLDivElement
    );
    this.loadingTab = true;

    function time(value): void {
      const _time = new Date(value);
      let label;
      if (_time.getMinutes() < 10) {
        label = _time.getHours() + ":0" + _time.getMinutes();
      } else {
        label = _time.getHours() + ":" + _time.getMinutes();
      }
      return label;
    }

    const dataAxis = [];
    const dataTemp = [];
    const dataHum = [];
    const dataHeater = [];
    // this.chetNone = 0;
    // this.chetOk = 0;
    const from = new Date(Date.now());
    const to = new Date(Date.now());
    from.setHours(from.getHours() - 24);
    this._detectorService
      .getDetectorInternalGraph(this.modelId, to, from)
      .pipe(
        finalize(() => {
          this.loadingTab = false;
          this.echartWeather(dataAxis, dataTemp, dataHum, dataHeater);
          this._changeDetector.markForCheck();
        })
      )
      .subscribe((result) => {
        this.temperature = result.temperature;
        this.humidity = result.humidity;
        result.items.forEach((item) => {
          // item.time += 'Z';
          const _time = new Date(item.time);
          dataAxis.push(time(_time));
          dataTemp.push(item.temperature);
          dataHum.push(item.humidityPercent);
          // let heater = item.heaterOn ? 1: 0
          dataHeater.push(item.heaterOn ? 1 : 0);
        });
      });
    this._settingsService.darkThemeChanged.subscribe((result) => {
      this.echartWeather(dataAxis, dataTemp, dataHum, dataHeater);
    });
  }

  private echartWeather(dateAxis, dateTemp, dateHum, dateHeater): void {
    const langRu = {
      temprute: "Температура",
      humidity: "Влажность",
      heater: "Грелка",
    };
    this.setOptionChartWeather(dateAxis, dateTemp, dateHum, dateHeater, langRu);
    // this.optionWeather.backgroundColor = '#424242';
    this.optionWeather.xAxis = {
      data: dateAxis,
      type: "category",
      axisLine: {
        lineStyle: {
          color: this.color,
        },
      },
      axisLabel: {
        color: this.color,
      },
    };

    // this.setOptionChartWeather(dateAxis, dateTemp, dateHum, langRu);

    this.chartWeather.setOption(this.optionWeather, true, true);
    if (this.external) {
      let echartsTest = document.getElementById(
        `grafWeather_${this.modelId}`
      ) as HTMLDivElement;
      echartsTest.style.height = "75vh";
      this.chartParams.resize();
      this._changeDetector.markForCheck();
    }
  }

  private setOptionChartWeather(
    dateAxis,
    dateTemp,
    dateHum,
    dateHeater,
    lang
  ): void {
    this.optionWeather = {
      tooltip: {
        trigger: "axis",
        backgroundColor: "#ffffffbd",
        color: "#fff",
      },
      legend: {
        data: [
          {
            name: lang.temprute,
            textStyle: {
              color: this.color,
            },
          },
          {
            name: lang.humidity,
            textStyle: {
              color: this.color,
            },
          },
          {
            name: lang.heater,
            textStyle: {
              color: this.color,
            },
          },
        ],
        x: "center",
        y: "bottom",
      },
      grid: {
        top: "10%",
        left: "2%",
        right: "2%",
        bottom: "10%",
        containLabel: true,
      },
      xAxis: {
        type: "category",
        data: dateAxis,
      },
      yAxis: [
        {
          type: "value",
          axisLine: {
            lineStyle: {
              color: this.humidityColor,
            },
          },
          axisLabel: {
            formatter: `{value} %`,
            color: this.humidityColor,
          },
          splitLine: {
            show: false,
          },
        },
        {
          type: "value",
          axisLine: {
            lineStyle: {
              color: this.temptureColor,
            },
          },
          axisLabel: {
            formatter: `{value} C`,
            color: this.temptureColor,
          },
          splitLine: {
            show: false,
          },
        },
        // {
        //   type: 'value',
        //   axisLine: {
        //     lineStyle: {
        //       color: this.heaterColor,
        //     },
        //   },
        //   axisLabel: {
        //     formatter: `{value}`,
        //     color: this.heaterColor,
        //   },
        //   splitLine: {
        //     show: false,
        //   },
        // },
      ],
      // visualMap: {
      //   type: 'continuous',
      //   show: false,
      //   pieces: [{
      //     lte: 0,
      //     gt: 60,
      //     color: '#660099'
      //   }],
      //   outOfRange: {
      //     color: '#999'
      //   }
      // },
      series: [
        {
          name: lang.humidity,
          data: dateHum,
          type: "line",
          smooth: true,
          color: this.humidityColor,
          markArea: {
            silent: true,
            itemStyle: {
              normal: {
                color: "transparent",
                borderWidth: 1,
                borderType: "dashed",
              },
            },
            data: [
              [
                {
                  xAxis: "0",
                  yAxis: "60",
                },
                {
                  xAxis: "max",
                  yAxis: "80",
                },
              ],
            ],
          },
        },
        {
          name: lang.temprute,
          data: dateTemp,
          type: "line",
          smooth: true,
          yAxisIndex: 1,
          color: this.temptureColor,
        },
        {
          name: lang.heater,
          data: dateHeater,
          type: "line",
          smooth: true,
          yAxisIndex: 1,
          color: this.heaterColor,
        },
      ],
      animation: false,
    };
    this.chartWeather.setOption(this.optionWeather, true, true);
  }

  private grafAnalyser() {
    this.loadingTab = true;
    //  const today = new Date(Date.now());
    // const start = new Date(today);
    // start.setHours(0, 0, 0, 0);

    // const end = new Date(today);
    // end.setHours(24, 0, 0, 0);
    const start = new Date(
      new Date(
        new Date(
          new Date().getFullYear(),
          new Date().getMonth(),
          new Date().getDate(),
          0
        )
      )
    );
    const end = new Date(
      new Date(
        new Date(
          new Date().getFullYear(),
          new Date().getMonth(),
          new Date().getDate(),
          24
        )
      )
    );

    this._detectorService
      .getDetectorAnalysisGraph(this.modelId, start, end)
      .pipe(
        finalize(() => {
          this.loadingTab = false;
          this._changeDetector.markForCheck();
        })
      )
      .subscribe((result) => {
        this.dataAnalyserDetectors = result;
        this.currentSpeed = result.currentSpeed;
        this.pastYearAvgSpeed = result.pastYearAvgSpeed;
        this.currentCount = result.currentCount;
        this.pastYearCount = result.pastYearAvgCount;
        this.currentLaneFill = result.currentLaneFill;
        this.pastYearLaneFill = result.pastYearAvgLaneFill;
        if (
          result.laneFillDifferencePercent > 30 ||
          result.laneFillDifferencePercent < -30
        ) {
          this.atypicalLaneFill = true;
        }
        if (
          result.speedDifferencePercent > 30 ||
          result.speedDifferencePercent < -30
        ) {
          this.atypicalSpeed = true;
        }
        if (
          result.countDifferecePercent > 30 ||
          result.countDifferecePercent < -30
        ) {
          this.atypicalCount = true;
        }
        this._updateChart();
      });

    this._settingsService.darkThemeChanged.subscribe((result) => {
      this._updateChart();
    });
  }
  private _updateChart() {
    if (this.chartParams) {
      this.chartParams.clear();
    }
    this.chartParams = echarts.init(
      document.getElementById(
        `chart_analitic_${this.modelId}`
      ) as HTMLDivElement
    );
    function time(value) {
      const _time = new Date(value);
      _time.setMinutes(new Date(value).getMinutes() - 15);
      let label;
      if (_time.getMinutes() < 10) {
        label = _time.getHours() + ":0" + _time.getMinutes();
      } else {
        label = _time.getHours() + ":" + _time.getMinutes();
      }
      return label;
    }
    let axisLabel;
    if (this.selectedDataTypes === "AvgSpeed") {
      // axisLabel = _('DETECTORS.SPEED_UNITS');
      axisLabel = "км/ч";
    } else if (this.selectedDataTypes === "Count") {
      // axisLabel = _('DETECTORS.COUTN_UNITS');
      axisLabel = "ед";
    } else if (this.selectedDataTypes === "LaneFill") {
      // axisLabel = _('DETECTORS.LANE_FILL_UNITS');
      axisLabel = "%";
    }
    const dataAxis = [];
    const data1 = [];
    const data2 = [];
    this.dataAnalyserDetectors.currentItems.forEach((i) => {
      if (this.selectedDataTypes === "AvgSpeed") {
        data1.push(i.avgSpeed);
      } else if (this.selectedDataTypes === "Count") {
        data1.push(i.count);
      } else if (this.selectedDataTypes === "LaneFill") {
        data1.push(i.laneFill);
      }
    });
    this.dataAnalyserDetectors.pastYearAverage.forEach((i) => {
      const _time = new Date(i.time);
      dataAxis.push(time(_time));
      if (this.selectedDataTypes === "AvgSpeed") {
        data2.push(i.avgSpeed);
      } else if (this.selectedDataTypes === "Count") {
        data2.push(i.count);
      } else if (this.selectedDataTypes === "LaneFill") {
        data2.push(i.laneFill);
      }
    });

    const options = {
      grid: {
        left: 20,
        right: 30,
        width: "95%",
        containLabel: true,
      },
      tooltip: {
        trigger: "axis",
        backgroundColor: "#ffffffbd",
        textStyle: {
          color: "#fff",
        },
      },
      xAxis: {
        data: dataAxis,
        type: "category",
        axisLine: {
          lineStyle: {
            color: this.color,
          },
        },
        axisLabel: {
          color: this.color,
        },
      },
      yAxis: {
        type: "value",
        axisLine: {
          lineStyle: {
            color: this.color,
          },
        },
        axisLabel: {
          formatter: `{value} ${axisLabel}`,
          color: this.color,
        },
        splitLine: {
          show: true,
        },
      },
      series: [
        {
          name: `Сейчас`,
          data: data1,
          type: "line",
          smooth: true,
          color: this.AvgSpeedRed,
        },
        {
          name: `Статистика`,
          data: data2,
          type: "line",
          smooth: true,
          color: this.LaneFillGreen,
        },
      ],

      legend: {
        x: "center",
        y: "bottom",
        itemWidth: 50,
        itemGap: 20,
        data: [
          {
            name: `Сейчас`,
            textStyle: {
              color: this.color,
            },
          },
          {
            name: `Статистика`,
            textStyle: {
              color: this.color,
            },
          },
        ],
      },
      animation: false,
    };
    this.chartParams.setOption(options, true, true);
    if (this.external) {
      let echartsTest = document.getElementById(
        `chart_analitic_${this.modelId}`
      ) as HTMLDivElement;
      echartsTest.style.height = "65vh";
      this.chartParams.resize();
      this._changeDetector.markForCheck();
    }
    // this._changeDetector.markForCheck();
  }

  private grafForecast() {
    this.loadingTab = true;
    const start = new Date(
      new Date(
        new Date(
          new Date().getFullYear(),
          new Date().getMonth(),
          new Date().getDate(),
          0
        )
      )
    );
    const end = new Date(
      new Date(
        new Date(
          new Date().getFullYear(),
          new Date().getMonth(),
          new Date().getDate(),
          24
        )
      )
    );

    this._detectorService
      .getDetectorAnalysisGraph(this.modelId, start, end)
      .pipe(
        finalize(() => {
          this.loadingTab = false;
          this._changeDetector.markForCheck();
        })
      )
      .subscribe((result) => {
        this.dataForecastDetectors = result;

        this._updateForecastChart();
      });

    this._settingsService.darkThemeChanged.subscribe((result) => {
      this._updateForecastChart();
    });
  }

  changeType(event) {
    this._updateChart();
  }
  changeTypeForecast(event) {
    this._updateForecastChart();
  }

  private _updateForecastChart() {
    if (this.chartParams) {
      this.chartParams.clear();
    }
    this.chartParams = echarts.init(
      document.getElementById(
        `chart_forecast_${this.modelId}`
      ) as HTMLDivElement
    );
    function time(value) {
      const _time = new Date(value);
      _time.setMinutes(new Date(value).getMinutes() - 15);
      let label;
      if (_time.getMinutes() < 10) {
        label = _time.getHours() + ":0" + _time.getMinutes();
      } else {
        label = _time.getHours() + ":" + _time.getMinutes();
      }
      return label;
    }

    let axisLabel;
    if (this.selectedForecastDataTypes === "AvgSpeed") {
      // axisLabel = _('DETECTORS.SPEED_UNITS');
      axisLabel = "км/ч";
    } else if (this.selectedForecastDataTypes === "Count") {
      // axisLabel = _('DETECTORS.COUTN_UNITS');
      axisLabel = "ед";
    } else if (this.selectedForecastDataTypes === "LaneFill") {
      // axisLabel = _('DETECTORS.LANE_FILL_UNITS');
      axisLabel = "%";
    }
    let indexForecast = null;
    const dataAxis = [];
    const data1 = [];
    const data2 = [];
    this.dataForecastDetectors.currentItems.forEach((i, index) => {
      if (new Date(i.time) < new Date()) {
        if (this.selectedForecastDataTypes === "AvgSpeed") {
          data1.push(i.avgSpeed);
        } else if (this.selectedForecastDataTypes === "Count") {
          data1.push(i.count);
        } else if (this.selectedForecastDataTypes === "LaneFill") {
          data1.push(i.laneFill);
        }
        indexForecast = index;
      }
    });
    // indexForecast = indexForecast - 1;

    this.dataForecastDetectors.pastYearAverage.forEach((i, index) => {
      const _time = new Date(i.time);
      dataAxis.push(time(_time));
      if (index > indexForecast) {
        if (this.selectedForecastDataTypes === "AvgSpeed") {
          data1.push(i.avgSpeed);
        } else if (this.selectedForecastDataTypes === "Count") {
          data1.push(i.count);
        } else if (this.selectedForecastDataTypes === "LaneFill") {
          data1.push(i.laneFill);
        }
      }
    });

    this.avgSpeedForecast = this.dataForecastDetectors.pastYearAverage[
      indexForecast === this.dataForecastDetectors.pastYearAverage.length
        ? indexForecast
        : indexForecast + 1
    ].avgSpeed;
    this.avgCountForecast = this.dataForecastDetectors.pastYearAverage[
      indexForecast === this.dataForecastDetectors.pastYearAverage.length
        ? indexForecast
        : indexForecast + 1
    ].count;
    this.avgFillForecast = this.dataForecastDetectors.pastYearAverage[
      indexForecast === this.dataForecastDetectors.pastYearAverage.length
        ? indexForecast
        : indexForecast + 1
    ].laneFill;

    // console.log(indexForecast);
    indexForecast === 0 && indexForecast++;

    // console.log(indexForecast);
    const options = {
      tooltip: {
        trigger: "axis",
        backgroundColor: "#ffffffbd",
        textStyle: {
          color: "#fff",
        },
      },
      grid: {
        left: 20,
        right: 20,
        boottom: 0,
        containLabel: true,
      },
      xAxis: {
        data: dataAxis,
        type: "category",
        axisLine: {
          lineStyle: {
            color: this.color,
          },
        },
        axisLabel: {
          color: this.color,
        },
      },
      yAxis: {
        type: "value",
        axisLine: {
          lineStyle: {
            color: this.color,
          },
        },
        axisLabel: {
          formatter: `{value} ${axisLabel}`,
          color: this.color,
        },
        splitLine: {
          show: true,
        },
      },
      visualMap: {
        show: false,
        dimension: 0,
        pieces: [
          {
            gt: 0,
            lte: indexForecast,
            color: "red",
          },
          {
            gt: indexForecast,
            color: "green",
          },
        ],
      },
      series: [
        {
          name: `Прогноз`,
          data: data1,
          type: "line",
          smooth: true,
          color: this.AvgSpeedRed,
        },
      ],
      animation: false,
    };
    this.chartParams.setOption(options, true, true);
    if (this.external) {
      let echartsTest = document.getElementById(
        `chart_forecast_${this.modelId}`
      ) as HTMLDivElement;
      echartsTest.style.height = "65vh";
      this.chartParams.resize();
      this._changeDetector.markForCheck();
    }
    // this._changeDetector.markForCheck();
  }
}
