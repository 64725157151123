import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';

import { TlcApiService } from './tlc-api.service';
import { Schedule } from '../dtos/schedule';

import {
  PotokSettings,
  SetPotokControllerRequest,
  SetPotokControllerAdaptiveModeRequest,
  SetPotokControllerCoordinationModeRequest,
  SetPotokControllerOfflineModeRequest,
  SetPotokControllerRemoteModeRequest,
  GetPotokControllerSettingsRequest,
  UpdatePotokControllerSettingsRequest,
  PotokPlans,
  PotokBarrierType,
  PotokMovementType,
  BuildPotokControllerPlanBarrierRequest,
  SetPotokControllerYellowBlinkModeRequest,
  SetPotokControllerAllRedModeRequest,
  GetPotokTrafficObjectStatusRequest,
  PotokBarrier,
  PotokPlanType,
} from '../dtos/tlc.dtos';

import { PotokPlan } from '../dtos/potok/potok-plan';
import { Observable, of } from 'rxjs';

@Injectable()
export class PotokTrafficObjectService {
  constructor(private _api: TlcApiService) {}

  getSettings(id: string, customerId: string) {
    const request = new GetPotokControllerSettingsRequest();
    request.id = id;
    request.customerId = customerId;
    return this._api.get(request).pipe(tap((m) => this.assignSettings(m)));
  }
  updateSettings(
    id: string,
    customerId: string,
    settings: PotokSettings,
    uploadToController: boolean,
    forceUpdate: boolean
  ) {
    // const badPlans = this._getBadPlans(settings);
    this._updatePlansLength(settings);

    const request = new UpdatePotokControllerSettingsRequest();
    request.id = id;
    request.settings = settings;
    request.customerId = customerId;
    request.uploadSettingsToController = uploadToController;
    request.forceUpdate = forceUpdate;
    return this._api.put(request).pipe(tap((m) => this.assignSettings(m)));
  }
  private _updatePlansLength(settings: PotokSettings) {
    if (settings && settings.plans) {
      Object.keys(settings.plans).forEach((planKey, index) => {
        if (!planKey.startsWith('plan')) return;

        const plan = <PotokPlan>settings.plans[planKey];
        if (plan.enabled) {
          plan.length = this._getBarriersTime(plan.transportBarriers);
        }
      });
    }
  }
  private _getBadPlans(settings: PotokSettings) {
    const badPlans: { transport: number[]; pedestrian: number[] } = {
      transport: [],
      pedestrian: [],
    };

    if (settings.plans) {
      Object.keys(settings.plans).forEach((planKey, index) => {
        if (!planKey.startsWith('plan')) return;

        const plan = <PotokPlan>settings.plans[planKey];
        const planNumber = index + 1;

        if (plan.enabled && plan.type === PotokPlanType.PhaseCycle) {
          if (this._getBarriersTime(plan.transportBarriers) <= 0) {
            badPlans.transport.push(planNumber);
          }

          if (plan.pedestrianPhasesEnabled) {
            if (this._getBarriersTime(plan.pedestrianBarriers) <= 0) {
              badPlans.pedestrian.push(planNumber);
            }
          }
        }
      });
    }

    return badPlans;
  }
  private _getBarriersTime(barriers: PotokBarrier[]) {
    return barriers.reduce((result, b) => result + b.length, 0);
  }

  assignSettings(settings: PotokSettings) {
    const newSchedule = new Schedule();
    if (settings.schedule?.year) {
      settings.schedule.year = Object.assign(newSchedule.year, settings.schedule.year);
    }
    settings.schedule = Object.assign(newSchedule, settings.schedule);

    const plans = new PotokPlans({
      plan01: new PotokPlan(),
      plan02: new PotokPlan(),
      plan03: new PotokPlan(),
      plan04: new PotokPlan(),
      plan05: new PotokPlan(),
      plan06: new PotokPlan(),
      plan07: new PotokPlan(),
      plan08: new PotokPlan(),
      plan09: new PotokPlan(),
      plan10: new PotokPlan(),
      plan11: new PotokPlan(),
      plan12: new PotokPlan(),
      plan13: new PotokPlan(),
      plan14: new PotokPlan(),
      plan15: new PotokPlan(),
      plan16: new PotokPlan(),
      plan17: new PotokPlan(),
      plan18: new PotokPlan(),
      plan19: new PotokPlan(),
      plan20: new PotokPlan(),
      plan21: new PotokPlan(),
      plan22: new PotokPlan(),
      plan23: new PotokPlan(),
      plan24: new PotokPlan(),
    });
    if (settings.plans) {
      Object.keys(plans).forEach((k) => {
        settings.plans[k] = Object.assign(plans[k], settings.plans[k]);
      });
    } else {
      settings.plans = plans;
    }
  }

  setRemoteMode(id: string, customerId: string, movements?: PotokMovementType[], modeTime = 180) {
    const request = new SetPotokControllerRemoteModeRequest();
    request.id = id;
    request.movements = movements;
    request.modeTime = modeTime;
    request.customerId = customerId;
    return this._api.post(request);
  }
  setCoordinationMode(id: string, customerId: string) {
    const request = new SetPotokControllerCoordinationModeRequest();
    request.id = id;
    request.customerId = customerId;
    return this._api.post(request);
  }
  setYellowBlinkMode(id: string, customerId: string) {
    const request = new SetPotokControllerYellowBlinkModeRequest();
    request.id = id;
    request.customerId = customerId;
    return this._api.post(request);
  }
  setAllRedMode(id: string, customerId: string, modeTime = 180) {
    const request = new SetPotokControllerAllRedModeRequest();
    request.id = id;
    request.customerId = customerId;
    request.modeTime = modeTime;
    return this._api.post(request);
  }
  setOffMode(id: string, customerId: string) {
    const request = new SetPotokControllerOfflineModeRequest();
    request.id = id;
    request.customerId = customerId;
    return this._api.post(request);
  }
  setAdaptiveMode(id: string, customerId: string, modeTime?) {
    const request = new SetPotokControllerAdaptiveModeRequest();
    request.id = id;
    request.customerId = customerId;
    request.modeTime = modeTime;
    return this._api.post(request);
  }

  changeController(
    id: string,
    customerId: string,
    controllerId: string,
    uploadSettings: boolean,
    description: string
  ) {
    const request = new SetPotokControllerRequest();
    request.id = id;
    request.newControllerId = controllerId;
    request.uploadSettingsToController = uploadSettings;
    request.description = description;
    request.customerId = customerId;
    return this._api.put(request);
  }
  buildPlanBarrier(
    id: string,
    customerId: string,
    barrierType: PotokBarrierType,
    movements: PotokMovementType[],
    length = 30
  ): Observable<PotokBarrier> {
    const request = new BuildPotokControllerPlanBarrierRequest({
      id,
      barrierType,
      movements,
      length,
      customerId,
    });
    return this._api.post(request);
  }

  getStatus(id: string, customerId: string) {
    const request = new GetPotokTrafficObjectStatusRequest();
    request.id = id;
    request.customerId = customerId;
    return this._api.get(request);
  }
}
