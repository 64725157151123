import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';

import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { DialogService } from 'projects/msu-its-web-common/src/services/dialog.service';

import { IGisObjectStateComponent } from 'projects/msu-its-web-common/src/utils/gis-object-group';
import {
  StepMovements,
  TrafficObjectSceneStatus,
  AdaptiveScene,
  AdaptiveSceneStatus,
} from '../../dtos/tss.dtos';
import { TrafficObjectService } from 'projects/msu-its-web-tlc/src/services/traffic-object.service';
import {
  TrafficControllerType,
  TrafficObjectInfo,
  TrafficObjectMode,
  UsdkSettings,
} from 'projects/msu-its-web-tlc/src/dtos/tlc.dtos';
import { BehaviorSubject, forkJoin, of, Subject, timer } from 'rxjs';
import { SchemaSet, SchemaUtils } from 'projects/msu-its-web-tlc/src/dtos/schema';
import { finalize, takeUntil, tap } from 'rxjs/operators';

import { getColor } from 'projects/msu-its-web-common/src/utils/colors';
import { SettingsService } from 'projects/msu-its-web-common/src/services/settings.service';
import { LayoutSvgUtils } from 'projects/msu-its-web-tlc/src/dtos/layout';

import { AdaptiveSceneService } from '../../services/adaptive-scene.service';
import { UsdkTrafficObjectService } from 'projects/msu-its-web-tlc/src/services/usdk-traffic-object.service';
import { TranslateService } from '@ngx-translate/core';

declare const L;

@Component({
  selector: 'adaptive-scene-state',
  templateUrl: './adaptive-scene-state.component.html',
  styleUrls: ['./adaptive-scene-state.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AdaptiveSceneStateComponent
  implements IGisObjectStateComponent, OnDestroy, AfterViewInit, OnInit
{
  _destroy = new Subject();

  loading = false;

  readonly statusSubject = new BehaviorSubject<AdaptiveSceneStatus>(null);

  get status() {
    return this.statusSubject.getValue();
  }
  set status(value) {
    this.statusSubject.next(value);
  }

  @Input()
  modelId: string;

  @Input()
  customerId: string;

  @Input()
  modelInfo: AdaptiveScene;

  @Input()
  mapLayers: Map<string, any>;

  get adaptiveEnabled() {
    return this.status?.enabled;
  }

  actions: { name: string; action: string }[] = [
    {
      name: _('COMMON.EDIT'),
      action: 'edit',
    },
  ];

  _objects = new Map<string, TrafficObjectInfo>();
  _schemaSets = new Map<string, SchemaSet>();
  _phasesSettings = new Map<string, UsdkSettings>();
  _statusesSvgs = new Map<string, any>();

  constructor(
    private _dialogService: DialogService,
    private _settingsService: SettingsService,
    private _adaptiveSceneService: AdaptiveSceneService,
    private _trafficObjectService: TrafficObjectService,
    private _usdkTrafficObjectService: UsdkTrafficObjectService,
    private _changeDetector: ChangeDetectorRef,
    public translate: TranslateService
  ) {}

  ngOnInit() {
    // get status by timer
    this._getStatus(1000);
  }

  ngAfterViewInit() {
    const itemIds = this.modelInfo?.settings?.trafficObjects?.map((m) => m.trafficObjectId);
    this._trafficObjectService
      .getList(this.customerId, null, null, null, itemIds)
      .subscribe((trafficObjects) => {
        trafficObjects.forEach((m) => this._objects.set(m.id, m));
        this._updateTrafficObjectSettings();
      });

    this._updateTrafficObjectStatuses();
  }

  ngOnDestroy() {
    this._resetTrafficObjectStatuses();
    this._destroy.next();
    this._destroy.complete();
    this._destroy = null;
  }

  _getStatus(duration: number) {
    this._adaptiveSceneService
      .getStatus(this.modelId, this.customerId)
      .pipe(takeUntil(this._destroy))
      .pipe(
        finalize(
          () =>
            duration &&
            timer(duration)
              .pipe(takeUntil(this._destroy))
              .subscribe(() => this._destroy && this._getStatus(duration))
        )
      )
      .subscribe((status) => {
        this.status = status;
        this.updateChanges();
      });
  }

  _updateTrafficObjectSettings() {
    // check and add new
    const forkObservables = [];
    this.modelInfo.settings.trafficObjects.forEach((m) => {
      //check settings
      if (!this._schemaSets.has(m.trafficObjectId)) {
        // schemaSet
        const schemaSetObservable = this._trafficObjectService.getSchemaSet(
          m.trafficObjectId,
          this.customerId
        );

        // usdk phases settings
        const phasesSettingsObservable =
          this._objects.get(m.trafficObjectId).controllerType == TrafficControllerType.Usdk
            ? this._usdkTrafficObjectService.getSettings(m.trafficObjectId, this.customerId)
            : of(null);

        forkObservables.push(
          forkJoin([schemaSetObservable, phasesSettingsObservable]).pipe(
            tap((result) => {
              this._schemaSets.set(m.trafficObjectId, result[0]);
              result[1] && this._phasesSettings.set(m.trafficObjectId, result[1]);
            })
          )
        );
      }
    });

    forkJoin(forkObservables).subscribe(() => {
      this.updateChanges();
    });
  }

  getObjectName(id: string) {
    return this._objects.get(id)?.name || '';
  }
  getSchemaSet(id: string) {
    return this._schemaSets.get(id);
  }
  getObjectPlan(id: string) {
    const objStatus = this.status?.trafficObjectStatuses?.find((m) => m.id == id);

    return objStatus
      ? objStatus.adaptivePlan?.name ||
          this.translate.instant('TRAFFIC_OBJECTS.PLAN_SHORT') + objStatus.coordinationPlan
      : '';
  }

  stepSvgLoad = (target, objectId: string, stepMovements: StepMovements) => {
    this._updateStepSvg(target, objectId, stepMovements);
  };

  _updateStepSvg(target, objectId: string, stepMovements: StepMovements) {
    const object = this._objects.get(objectId);
    const settings = this._schemaSets.get(objectId);

    const schema = settings.schema;
    const schemaView = settings.schemaView;
    const isDark = this._settingsService.darkTheme;
    const svg = target.contentDocument;

    const phaseMovements = [];
    switch (object.controllerType) {
      case TrafficControllerType.Potok:
        phaseMovements.push(...stepMovements.movements);
        break;
      case TrafficControllerType.Usdk:
        const phase = this._phasesSettings
          .get(objectId)
          ?.phases.find((m) => m.id == stepMovements.phase);
        phase && phaseMovements.push(...phase?.movements);
        break;
      default:
        break;
    }

    const movements = [];
    phaseMovements.forEach((m) => {
      movements.push(...SchemaUtils.schemaViewMovements(schema, m));
    });

    LayoutSvgUtils.updateLayoutSchema(svg, schema, schemaView, isDark);
    LayoutSvgUtils.showLayoutMovements(svg, schema, movements);
  }

  private _getExternalUrlBase() {
    return `${this.customerId}/ext/adaptive-scene/${this.modelId}/`;
  }
  edit() {
    this._dialogService.windowDialog(
      this._getExternalUrlBase() + 'edit',
      'adaptiveSceneEdit_' + this.modelId,
      'menubar=0,toolbar=0,titlebar=0,location=0,locationbar=0,width=1400,height=800,resizable=0'
    );
  }

  get objects() {
    const result = [...this.modelInfo.settings.trafficObjects];
    return result;
  }

  updateChanges() {
    this._updateTrafficObjectStatuses();
    this._statusesSvgs.forEach((m, id) => m?.contentDocument && this._updateStatusSvg(m, id));
    this._changeDetector.markForCheck();
  }

  start(event) {
    if (this.adaptiveEnabled) {
      event.source.checked = true;
      return;
    }

    this.loading = true;
    this._changeDetector.markForCheck();

    this._adaptiveSceneService
      .startAdaptiveScene(this.modelId, this.customerId)
      .pipe(
        finalize(() => {
          this.loading = false;
          this._changeDetector.markForCheck();
        })
      )
      .subscribe((status) => {
        this.status = status;
        this.updateChanges();
      });
  }
  stop() {
    this.loading = true;
    this._changeDetector.markForCheck();

    this._adaptiveSceneService
      .stopAdaptiveScene(this.modelId, this.customerId)
      .pipe(
        finalize(() => {
          this.loading = false;
          this._changeDetector.markForCheck();
        })
      )
      .subscribe((status) => {
        this.status = status;
        this.updateChanges();
      });
  }

  getObjectMode(id: string) {
    const objectStatus = this.status.trafficObjectStatuses.find((m) => m.id == id);
    return objectStatus.mode;
  }
  getObjectStatus(objectId: string) {
    return this.status?.trafficObjectStatuses.find((m) => m.id == objectId);
  }
  getObjectModeTime(objectStatus: TrafficObjectSceneStatus) {
    if (
      objectStatus?.mode != TrafficObjectMode.Remote ||
      !objectStatus?.controlModeStartTime ||
      !objectStatus?.serverTime
    ) {
      return '';
    }

    const currentTime = objectStatus.serverTime;
    const startTime = objectStatus.controlModeStartTime;

    const start = new Date(startTime).getTime();
    const end = new Date(currentTime).getTime();

    return Math.floor((end - start) / 1000);
  }
  statusSvgLoad(target, objectId: string) {
    this._statusesSvgs.set(objectId, target);
    this._updateStatusSvg(target, objectId);
  }
  _updateStatusSvg(target, objectId: string) {
    const settings = this._schemaSets.get(objectId);

    const schema = settings.schema;
    const schemaView = settings.schemaView;
    const isDark = this._settingsService.darkTheme;
    const svg = target.contentDocument;

    const objectStatus = this.status?.trafficObjectStatuses.find((m) => m.id == objectId);
    const phaseMovements = objectStatus?.schemaLights
      ? SchemaUtils.schemaLightToMovements(schema, objectStatus.schemaLights)
      : [];

    const movements = [];
    phaseMovements.forEach((m) => {
      movements.push(...SchemaUtils.schemaViewMovements(schema, m));
    });

    LayoutSvgUtils.updateLayoutSchema(svg, schema, schemaView, isDark);
    LayoutSvgUtils.showLayoutMovements(svg, schema, movements);
  }

  getColor(color: string) {
    return getColor(color);
  }

  _updateTrafficObjectStatuses() {
    if (!this.mapLayers) return;

    this.objects.forEach((m, i) => {
      const layer = this.mapLayers.get(m.trafficObjectId);
      layer &&
        layer.setStatus({
          radius: 28,
          weight: 7,
          label: { text: i + 1, color: '#fff' },
        });
      layer?.bringToFront();

      // if (layer) {
      //   layer.options.eventHandlers = {
      //     click: this._clickLayer,
      //     contextmenu: this._contextmenuLayer,
      //   };
      // }
    });
  }
  _resetTrafficObjectStatuses() {
    if (!this.mapLayers) return;

    this.modelInfo.settings.trafficObjects.forEach((m, i) => {
      const layer = this.mapLayers.get(m.trafficObjectId);
      layer &&
        layer.setStatus({
          radius: 18,
          weight: 6,
          label: undefined,
        });

      delete layer?.options.eventHandlers;
    });
  }
}
