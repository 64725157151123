import { Injectable, Inject, Injector } from '@angular/core';
import { APP_ENVIRONMENT } from 'projects/msu-its-web-common/src/utils/shared-consts';
import { BaseApiService } from 'projects/msu-its-web-common/src/services/base-api.service';

@Injectable()
export class TpcApiService extends BaseApiService {
  constructor(injector: Injector, @Inject(APP_ENVIRONMENT) appEnv) {
    super(injector, appEnv.modules.tpc.apiUrl, "TPC");
  }
}
