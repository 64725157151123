import {
  PotokDayTime,
  PotokSchedule,
  PotokScheduleDay,
  PotokScheduleWeek,
  PotokScheduleYear,
} from './tlc.dtos';

export class ScheduleDay extends PotokScheduleDay {}
export class ScheduleDayTime extends PotokDayTime {}
export class ScheduleWeek extends PotokScheduleWeek {}
export class ScheduleYear extends PotokScheduleYear {}

export class Schedule extends PotokSchedule {
  days: ScheduleDay[] = [];
  weeks: ScheduleWeek[] = [];
  year: ScheduleYear = {
    january: new Array<number>(31).fill(1),
    february: new Array<number>(29).fill(1),
    march: new Array<number>(31).fill(1),
    april: new Array<number>(30).fill(1),
    may: new Array<number>(31).fill(1),
    june: new Array<number>(30).fill(1),
    july: new Array<number>(31).fill(1),
    august: new Array<number>(31).fill(1),
    september: new Array<number>(30).fill(1),
    october: new Array<number>(31).fill(1),
    november: new Array<number>(30).fill(1),
    december: new Array<number>(31).fill(1),
  };

  getPlanUsed(planId) {
    return this.days.some((d) => d.times.some((n) => n.planId === planId));
  }

  getDayUsed(dayId) {
    return this.weeks.some(
      (w) =>
        w.monday === dayId ||
        w.tuesday === dayId ||
        w.wednesday === dayId ||
        w.thursday === dayId ||
        w.friday === dayId ||
        w.saturday === dayId ||
        w.sunday === dayId
    );
  }

  getWeekUsed(weekId) {
    return (
      this.year.january.includes(weekId) ||
      this.year.february.includes(weekId) ||
      this.year.march.includes(weekId) ||
      this.year.april.includes(weekId) ||
      this.year.may.includes(weekId) ||
      this.year.june.includes(weekId) ||
      this.year.july.includes(weekId) ||
      this.year.august.includes(weekId) ||
      this.year.september.includes(weekId) ||
      this.year.october.includes(weekId) ||
      this.year.november.includes(weekId) ||
      this.year.december.includes(weekId)
    );
  }

  public static groupDaysPeriods(schedule: Schedule) {
    if (!schedule || !schedule.days) {
      return;
    }

    schedule.days.forEach((day) => {
      if (day.times && day.times.length > 1) {
        // sort by time
        const maxTime = '00:00';
        day.times = day.times.sort((a, b) => {
          return a.time === maxTime
            ? 1
            : b.time === maxTime
            ? -1
            : a.time.localeCompare(b.time);
        });

        // check periods
        const result: PotokDayTime[] = [];
        let prevItem: PotokDayTime = null;
        day.times.reverse().forEach((dayTime) => {
          if (!prevItem || dayTime.planId !== prevItem.planId) {
            prevItem = dayTime;
            result.unshift(dayTime);
          }
        });
        if (result.length > 1 && result[0].planId === prevItem.planId) {
          result.splice(-1, 1);
        }
        day.times = result;
      }
    });
  }
}

export interface ITrafficObjectPlans {
  plans: {
    plan01: { enabled: boolean };
    plan02: { enabled: boolean };
    plan03: { enabled: boolean };
    plan04: { enabled: boolean };
    plan06: { enabled: boolean };
    plan07: { enabled: boolean };
    plan08: { enabled: boolean };
    plan09: { enabled: boolean };
    plan10: { enabled: boolean };
    plan11: { enabled: boolean };
    plan12: { enabled: boolean };
    plan13: { enabled: boolean };
    plan14: { enabled: boolean };
    plan15: { enabled: boolean };
    plan16: { enabled: boolean };
    plan17: { enabled: boolean };
    plan18: { enabled: boolean };
    plan19: { enabled: boolean };
    plan20: { enabled: boolean };
    plan21: { enabled: boolean };
    plan22: { enabled: boolean };
    plan23: { enabled: boolean };
    plan24: { enabled: boolean };
  };
}
