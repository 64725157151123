import { marker as _ } from "@biesbjerg/ngx-translate-extract-marker";
import {
  FixationDeviceType,
  ReplacementReason,
  VehicleClass,
  ViolationType,
  WeightControlPointType,
} from "./ftv.dtos";

export const FIXATION_DEVICE_TYPE = [
  {
    type: FixationDeviceType.Azimuth,
    name: _("FIXATING_VIOLATION_DEVICES.TYPE.AZIMUTH"),
    prefix: "azimuth#",
  },
  {
    type: FixationDeviceType.Duplo,
    name: _("FIXATING_VIOLATION_DEVICES.TYPE.DUPLO"),
    prefix: "duplo#",
  },
];
export const FIXATION_VEHICLE_TYPE = [
  {
    type: VehicleClass.A,
    name: _("FIXATING_VIOLATION_DEVICES.VEHICLE_TYPE.A"),
  },
  {
    type: VehicleClass.B,
    name: _("FIXATING_VIOLATION_DEVICES.VEHICLE_TYPE.B"),
  },
  {
    type: VehicleClass.C,
    name: _("FIXATING_VIOLATION_DEVICES.VEHICLE_TYPE.C"),
  },
  {
    type: VehicleClass.D,
    name: _("FIXATING_VIOLATION_DEVICES.VEHICLE_TYPE.D"),
  },
  {
    type: VehicleClass.E,
    name: _("FIXATING_VIOLATION_DEVICES.VEHICLE_TYPE.E"),
  },
];
export const FIXATION_VIOLATION_TYPE = [
  {
    type: ViolationType.Buslane,
    name: _("FIXATING_TRAFFIC_EVENTS.VIOLATION_ENUM.BUS_LANE"),
  },
  {
    type: ViolationType.CellPhone,
    name: _("FIXATING_TRAFFIC_EVENTS.VIOLATION_ENUM.CELL_PHONE"),
  },
  {
    type: ViolationType.HighSpeed,
    name: _("FIXATING_TRAFFIC_EVENTS.VIOLATION_ENUM.HIGH_SPEED"),
  },
  {
    type: ViolationType.OncomingSide,
    name: _("FIXATING_TRAFFIC_EVENTS.VIOLATION_ENUM.ON_COMING_SIDE"),
  },
  {
    type: ViolationType.RedLight,
    name: _("FIXATING_TRAFFIC_EVENTS.VIOLATION_ENUM.RED_LIGHT"),
  },
  {
    type: ViolationType.SeatBelts,
    name: _("FIXATING_TRAFFIC_EVENTS.VIOLATION_ENUM.SEAT_BELTS"),
  },
  {
    type: ViolationType.StopLine,
    name: _("FIXATING_TRAFFIC_EVENTS.VIOLATION_ENUM.STOP_LINE"),
  },
  {
    type: ViolationType.Unknown,
    name: _("FIXATING_TRAFFIC_EVENTS.VIOLATION_ENUM.UNKNOWN"),
  },
  {
    type: ViolationType.HeadLight,
    name: _("FIXATING_TRAFFIC_EVENTS.VIOLATION_ENUM.HEAD_LIGHT"),
  },
  {
    type: ViolationType.NoStopping,
    name: _("FIXATING_TRAFFIC_EVENTS.VIOLATION_ENUM.NO_STOPPING"),
  },
  {
    type: ViolationType.NoParking,
    name: _("FIXATING_TRAFFIC_EVENTS.VIOLATION_ENUM.NO_PARKING"),
  },
  {
    type: ViolationType.PedestrianPriority,
    name: _("FIXATING_TRAFFIC_EVENTS.VIOLATION_ENUM.PEDESTRIAN_PRIORITY"),
  },
  {
    type: ViolationType.WrongLane,
    name: _("FIXATING_TRAFFIC_EVENTS.VIOLATION_ENUM.WRONG_LANE"),
  },
];

export const WEIGHT_CONTROL_POINT_TYPE = [
  {
    status: WeightControlPointType.Mobile,
    name: _("WEIGHT_CONTROL_POINTS.MOBILE_TYPE"),
  },
  {
    status: WeightControlPointType.Static,
    name: _("WEIGHT_CONTROL_POINTS.STATIC_TYPE"),
  },
];

export const DEVICE_FTV_SVG_ICONS = `
<?xml version="1.0" encoding="utf-8"?>
<svg version="1.1" id="Слой_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 48 48" enable-background="new 0 0 48 48" xml:space="preserve">
<path fill="#FFFFFF" d="M8.4,17.1v13.8c0,1,0.8,1.9,1.9,1.9h27.4c1,0,1.9-0.8,1.9-1.9V17.1c0-1-0.8-1.9-1.9-1.9H10.3
	C9.3,15.2,8.4,16.1,8.4,17.1z"/>
<circle fill="#1D1D1B" cx="15.2" cy="19.1" r="2.4"/>
<circle fill="none" stroke="#1D1D1B" stroke-width="2" stroke-miterlimit="10" cx="30.3" cy="24.1" r="6.3"/>
</svg>

  `;

export const POINT_REASON_REPLACE = [
  { status: ReplacementReason.Demount, name: _("DETECTORS.REASON_DEMOUNTED") },
  { status: ReplacementReason.Replace, name: _("DETECTORS.REASON_REPLACE") },
];
