import { marker as _ } from "@biesbjerg/ngx-translate-extract-marker";
import { KeyValue } from "@angular/common";
import {
  Component,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Inject,
  Input,
  OnInit,
  OnDestroy,
} from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { MatTabChangeEvent } from "@angular/material/tabs";
import { TranslateService } from "@ngx-translate/core";

import { forkJoin, Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

import { DialogService } from "projects/msu-its-web-common/src/services/dialog.service";
import { FlashService } from "projects/msu-its-web-common/src/services/flash.service";
import { SettingsService } from "projects/msu-its-web-common/src/services/settings.service";

import { APP_ENVIRONMENT } from "projects/msu-its-web-common/src/utils/shared-consts";
import { LANGS_LOCALES } from "projects/msu-its-web-common/src/utils/langs";
import { chartOptions } from "projects/msu-its-web-common/src/utils/e-charts";

import * as echarts from "echarts";
import {
  BaseUnit,
  EcoParameter,
  EcoParameterForecast,
  FrostsProbabilities,
  MeasureParameter,
  MeteoModuleCustomerSettings,
  MeteoObject,
  MeteoObjectInfo,
  MeteoParameterGraph,
  MeteoSensor,
  MeteoStationType,
  PrecipitationType,
  TableValue,
} from "../../dtos/meteo.dtos";
import {
  COTROL_CAMERA,
  FROSTS_PROBABILITIES,
  METEO_NAME,
  METEO_STATION_NAME,
  METEO_UNITS,
  TYPE_PRECIPITATION,
} from "../../dtos/enum";

import {
  CustomerStateService,
  ModuleIds,
} from "projects/msu-its-web-common/src/services/customer-state.service";
import { MeteoObjectPopupService } from "../../services/meteo-object-popup.service";
import { MeteoObjectsService } from "../../services/meteo-objects.service";

import { MeteoObjectHistoryComponent } from "../meteo-objects/meteo-object-history.component";
import { MeteoObjectReportComponent } from "../meteo-objects/meteo-object-report.component";

class ForeCast {
  airTemperature: number;
  atmosphericPressure: number;
  humidityPercent: number;
  probabilityPercent: number;
  typePrecipitation: PrecipitationType;
  probabilityFrosts: FrostsProbabilities;
}

@Component({
  selector: "meteo-object-popup",
  templateUrl: "./meteo-object-popup.component.html",
  styles: [
    `
      .backdrop {
        top: 45px;
      }
      .chartGraf {
        width: 100%;
        /*min-height: 340px;*/
        margin-top: -12px;
        height: 100%;
        z-index: 2;
      }

      .table {
        border: none;
        border-spacing: 0;
        width: 100%;
        font-size: 14px;
      }

      td {
        padding: 8px;
        font-size: 14px;
        border-bottom: solid 1px rgba(125, 125, 125, 0.15);
        vertical-align: middle;
      }
      td:first-child {
        padding-left: 0px;
      }
      td:last-child {
        padding-right: 0px;
      }

      .images {
        margin: 8px 0px;
        align-items: center;
        z-index: 1;
      }

      :host ::ng-deep .mat-tab-body-wrapper {
        flex: 1;
      }
      :host ::ng-deep .mat-tab-list .mat-tab-label {
        min-width: 80px !important;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MeteoObjectPopupComponent implements OnInit, OnDestroy {
  private _destroy = new Subject();
  moduleSettings: MeteoModuleCustomerSettings;

  @Input()
  modelId: string;

  @Input()
  external = false;

  @Input()
  customerId: string;

  @Input()
  modelInfo: MeteoObject;

  modelInfoSession: MeteoObjectInfo;

  foreCast = new ForeCast();
  forecastEco = new EcoParameterForecast();

  actions: { name: string; action: string; permissions?: string[] }[] = [
    // {
    //   name: _('COMMON.ADD'),
    //   action: 'openAdd',
    //   permissions: ['meteoObject.update'],
    // },
    {
      name: _("COMMON.EDIT"),
      action: "openEdit",
      permissions: ["meteoObject.update"],
    },
    {
      name: _("COMMON.HISTORY"),
      action: "openHistory",
      permissions: ["meteoObject.read"],
    },
  ];
  meteCategoryName = METEO_NAME;
  meteoUint = METEO_UNITS;
  _cameraControl = COTROL_CAMERA;
  meteoStationName = METEO_STATION_NAME;
  frostsProbabilities = FROSTS_PROBABILITIES;
  typePrecipitation = TYPE_PRECIPITATION;

  meteoSensor: MeteoSensor[];
  selectedMeteoSensor: MeteoSensor;

  itemTable: TableValue[];
  itemTableTranslation: { type: string; name: string }[];

  options = chartOptions;

  chart = true;
  chartParams;

  latestValue;
  unitLastValue;
  predictionValue;

  formGroup: FormGroup = this._formBuilder.group({
    meteoParams: [null],
  });

  loadingTab = false;
  loadGraph = false;
  loadLatestData = false;
  loadSession = false;
  selected;

  get meteoParams() {
    return this.formGroup.get("meteoParams");
  }

  get haveEcology() {
    return this.meteoSensor?.find((item) => item.name === "EcologySensor")
      ? true
      : false;
  }

  get locale() {
    return LANGS_LOCALES[this.translate.currentLang];
  }

  get isTypeBriz() {
    return this.modelInfo.deviceType === MeteoStationType.Briz ? true : false;
  }

  constructor(
    private _changeDetector: ChangeDetectorRef,
    private _dialogService: DialogService,
    private _flashService: FlashService,
    private _settingsService: SettingsService,
    private _meteoObjectPopupService: MeteoObjectPopupService,
    private _meteoObjectsService: MeteoObjectsService,
    private _formBuilder: FormBuilder,
    private _customerStateService: CustomerStateService,
    public translate: TranslateService,
    @Inject(APP_ENVIRONMENT) private appEnv: any
  ) {
    this.appEnv.system === "krudor" &&
      this.actions.push({
        name: _("COMMON.REPORT"),
        action: "report",
        permissions: ["meteoObject.update"],
      });
  }

  openInNew() {
    this._dialogService.windowDialog(
      `${this.customerId}/ext/meteo-objects/${this.modelId}`,
      "meteoObject_" + this.modelId,
      "menubar=0,toolbar=0,titlebar=0,location=0,locationbar=0,width=800,height=610,resizable=0"
    );
  }

  openEdit() {
    this._dialogService.windowDialog(
      `${this.customerId}/ext/meteo-objects/${this.modelId}/edit`,
      "meteoObjectEdit_" + this.modelId,
      "width=940,height=756"
    );
  }
  report() {
    const dialog = this._dialogService.dialog.open(MeteoObjectReportComponent, {
      disableClose: true,
    });
    dialog.componentInstance.modelId = this.modelId;
    dialog.componentInstance.modelInfo = this.modelInfo;
    dialog.componentInstance.customerId = this.customerId;
    dialog.componentInstance.meteoSensor = this.meteoSensor;
  }

  openHistory() {
    const dialog = this._dialogService.dialog.open(
      MeteoObjectHistoryComponent,
      {
        disableClose: true,
      }
    );
    dialog.componentInstance.modelId = this.modelId;
    dialog.componentInstance.modelInfo = this.modelInfo;
    dialog.componentInstance.customerId = this.customerId;
    dialog.componentInstance.meteoSensor = this.meteoSensor;
  }

  onResize($event) {
    // this.chartParams.resize();
    this._changeDetector.markForCheck();
  }
  isFakeMeteo(id: string): boolean {
    if (
      id === "bf48edb2-d040-48ef-9785-b2a6c75f52d1" ||
      id === "7a50c727-639c-4cf0-bc97-329031986627" ||
      id === "1b10cf0a-e3fa-4cc3-815c-19a79f027bb0" ||
      id === "69213bc5-6a7e-42a9-8720-457491f52028" ||
      id === "d36b1293-fe4b-4ed4-a757-5fc574e79d7a" ||
      id === "ea1ea96e-a018-4339-b75f-1b593a623dcf" ||
      id === "2dd311a5-712c-42b1-b6db-35a3056201b8" ||
      id === "da95421e-465e-4b92-8b97-0b68015b9d32" ||
      id === "fddf33a5-77ec-446e-8ee4-20eeb482ffbc" ||
      id === "1bb7dbfa-987c-4647-9782-b3a5cf8644ed" ||
      id === "1d193ea8-c8b6-439a-837a-963936d8acd5" ||
      id === "a7eb6014-d41c-4c7f-8e36-a83202f6bec4" ||
      id === "00f8ddde-7cca-49bf-bbaa-0575bcf36d56" ||
      id === "f6a2e3f4-b00e-4bad-b3f6-56861b262bc5" ||
      id === "ee5f84d6-3894-4ca9-8ffb-e98af27eebbf" ||
      id === "fea1bf0c-17fc-4b02-a411-4717938de582" ||
      id === "01098998-470e-4bf1-97d7-bbbe14064715" ||
      id === "94fefe75-d2f4-4134-af14-7b80e12f7627"
    ) {
      return true;
    } else {
      return false;
    }
  }
  ngOnInit() {
    this.moduleSettings = this._customerStateService.modulesSettings[
      ModuleIds.meteo
    ];

    this._loadObjectInfo(this.modelId);
    this._loadSectionTab();
    this.meteoParams.valueChanges
      .pipe(takeUntil(this._destroy))
      .subscribe((data) => {
        const from = new Date(Date.now());
        const to = new Date(Date.now());
        to.setHours(to.getHours() - 24);
        if (
          this.selectedMeteoSensor.measureParameters.find(
            (item) => item.id === data
          ).unit === BaseUnit.Statement
        ) {
          this.chart = false;
          this._loadDataTable(this.selectedMeteoSensor, data, to, from);
        } else {
          this.chart = true;
          this._loadDataGraph(this.selectedMeteoSensor, data, to, from);
        }
      });
  }

  ngOnDestroy() {
    this._destroy.next();
    this._destroy.complete();
  }

  private _loadDataTable(
    _meteoSensor: MeteoSensor,
    _meteoParametrId: string,
    to: Date,
    from: Date
  ) {
    this.loadingTab = true;
    forkJoin({
      latestData: this._meteoObjectPopupService.getMeteoObjectSensorLatestDataRequest(
        this.modelId,
        _meteoSensor.type,
        [_meteoParametrId]
      ),
      table: this._meteoObjectPopupService.getMeteoObjectSensorDataTableRequest(
        this.modelId,
        _meteoSensor.type,
        _meteoParametrId,
        to,
        from
      ),
    })
      .pipe(takeUntil(this._destroy))
      .subscribe(({ latestData, table }) => {
        this.latestValue = this.modelInfoSession?.session?.connected
          ? latestData.length !== 0
            ? latestData[0].stringValue
            : null
          : null;
        this.unitLastValue = this.modelInfoSession?.session?.connected
          ? latestData.length !== 0
            ? this.getCategoryUnit(latestData[0]?.unit)
            : null
          : null;
        this.itemTable = table.values;

        if (_meteoParametrId === "Briz_MeteoSensorPrecipitationType") {
          this.itemTableTranslation = TYPE_PRECIPITATION;
        }

        this.loadingTab = false;
        this._changeDetector.markForCheck();
      });
  }

  private _loadDataGraph(
    _meteoSensor: MeteoSensor,
    _meteoParametrId: string,
    to: Date,
    from: Date
  ) {
    this.loadingTab = true;
    forkJoin({
      latestData: this._meteoObjectPopupService.getMeteoObjectSensorLatestDataRequest(
        this.modelId,
        _meteoSensor.type,
        [_meteoParametrId]
      ),
      graph: this._meteoObjectPopupService.getMeteoObjectSensorDataGraphRequest(
        this.modelId,
        _meteoSensor.type,
        [_meteoParametrId],
        to,
        from
      ),
      prediction: this._meteoObjectPopupService.getMeteoObjectSensorPredictionDataRequest(
        this.modelId,
        _meteoSensor.type,
        [_meteoParametrId],
        new Date(from.setHours(from.getHours() + 2))
      ),
    })
      .pipe(takeUntil(this._destroy))
      .subscribe(({ latestData, graph, prediction }) => {
        this.latestValue = this.modelInfoSession?.session?.connected
          ? latestData.length !== 0
            ? latestData[0].doubleValue
            : null
          : null;
        this.unitLastValue = this.modelInfoSession?.session?.connected
          ? latestData.length !== 0
            ? this.getCategoryUnit(latestData[0]?.unit)
            : null
          : null;
        this.predictionValue = this.modelInfoSession?.session?.connected
          ? prediction.length !== 0
            ? prediction[0].doubleValue
            : null
          : null;
        this._initialGraph(_meteoSensor, graph[0]);
        // console.log('category ' + graph[0].category, '  unit' + graph[0].unit);
      });
  }

  private _loadObjectInfo(id: string) {
    this.loadSession = true;
    this._meteoObjectsService
      .getMeteoObjectInfoRequest(id, this.customerId)
      .pipe(takeUntil(this._destroy))
      .subscribe((result) => {
        this.loadSession = false;
        this.modelInfoSession = result;
        this._changeDetector.markForCheck();
      });
  }

  private _loadingForecastEcoParameter(id: string) {
    this.loadingTab = true;
    this._meteoObjectPopupService
      .getEcoParameterForecast(id)
      .subscribe((result: EcoParameterForecast) => {
        this.forecastEco = result;
        this.loadingTab = false;
      });
  }
  _loadSectionTab() {
    this.loadingTab = true;
    this._meteoObjectPopupService
      .getAvailableMeteoObjectSensorsRequest(this.modelId, 100, 100, "", "")
      .pipe(takeUntil(this._destroy))
      .subscribe((result) => {
        let tempArray: MeteoSensor[] = [];
        let tempEcology: MeteoSensor[] = [];

        if (this.moduleSettings?.meteoMonitoringSettings.enabled) {
          tempArray = result.items.filter(
            (item) => item.name !== "EcologySensor"
          );
        }
        if (this.moduleSettings?.ecoMonitoringSettings.enabled) {
          tempEcology = result.items.filter(
            (item) => item.name === "EcologySensor"
          );
        }
        this.meteoSensor = tempArray.concat(tempEcology);
        this.loadingTab = false;
        this._changeDetector.markForCheck();
      });
  }

  private _initialGraph(sensor: MeteoSensor, data: MeteoParameterGraph) {
    const id = `graf_${this.modelId}_${sensor.name}`;
    const element = document.getElementById(id) as HTMLDivElement;

    // console.log(id, element);

    this.chartParams = echarts.init(element);

    if (this.chartParams) {
      this.chartParams.clear();
    }

    // function time(value) {
    //   const _time = new Date(value);
    //   let label;
    //   if (_time.getMinutes() < 10) {
    //     label = _time.getHours() + ":0" + _time.getMinutes();
    //   } else {
    //     label = _time.getHours() + ":" + _time.getMinutes();
    //   }
    //   return label;
    // }
    // if (!data) {
    //   this.loadingTab = false;
    //   return;
    // }
    // const values = [];
    // const dataAxis = [];
    // data.values.forEach((item) => {
    //   dataAxis.push(time(item.timeStamp));
    //   values.push(item.doubleValue);
    // });
    const values = [];
    data.values.forEach((item) => {
      values.push([+new Date(item.timeStamp), item.doubleValue]);
    });

    this.options.series = [
      {
        type: "line",
        name: `${this.translate.instant(
          this.getCategoryName(data)
        )}, ${this.translate.instant(this.getCategoryUnit(data.unit))}`,
        data: values,
        animationDelay: (idx) => idx * 10,
      },
    ];
    this.options.xAxis = [
      {
        data: values,
        type: "time",
      },
    ];
    this.options.yAxis = [
      {
        axisLabel: {
          formatter: `{value} ${this.translate.instant(
            this.getCategoryUnit(data.unit)
          )}`,
        },
        axisLine: {
          onZero: false,
        },
        min:
          data.min >= 0
            ? data.min > 3
              ? Math.round(data.min - data.min * 0.2)
              : Math.floor(data.min * 10) / 10
            : Math.round(data.min + data.min * 0.2),
        // max: Math.round(data.max + data.max * 0.1),

        // min: Math.floor(data.min * 10) / 10,
        // max: Math.round(data.max + data.max * 0.1),
      },
    ];
    this.loadingTab = false;

    this.chartParams.setOption(this.options);
    this._changeDetector.markForCheck();
  }

  getImage(force): void {
    this.loadingTab = true;
    const dark = this._settingsService.darkTheme;
    const myImage = document.getElementById(
      `photo_${this.modelId}`
    ) as HTMLImageElement;
    fetch(
      this._meteoObjectsService.getMeteoObjectImageRequest(this.modelId, force)
    )
      .finally(() => {
        this.loadingTab = false;
        this._changeDetector.markForCheck();
      })
      .then((response) => response.blob())
      .then((myBlob) => {
        // console.log("get image", myBlob);
        if (myBlob.size > 2000) {
          // console.log("(myBlob.size !== 0");
          const objectURL = URL.createObjectURL(myBlob);
          myImage.src = objectURL;
        } else {
          // console.log(" else (myBlob.size !== 0");
          dark
            ? (myImage.src = "assets/images/img_not_found.png")
            : (myImage.src = "assets/images/img_not_found_dark.png");
          // myImage.height = 300;
          // myImage.width = 300;
        }
      });

    this._changeDetector.markForCheck();
  }

  camControl(move: string) {
    const dark = this._settingsService.darkTheme;
    const myImage = document.getElementById(
      `photo_${this.modelId}`
    ) as HTMLImageElement;
    const control = this._cameraControl.find((item) => item.name === move);

    fetch(this._meteoObjectsService.camComtrol(this.modelId, control.command))
      .then((response) => (response.ok ? response.blob() : response.json()))
      .then((result) => {
        if (result instanceof Blob) {
          this._flashService.success(this.translate.instant("COMMAND.SUCCESS"));
          const objectURL = URL.createObjectURL(result);
          myImage.src = objectURL;
        } else {
          this._flashService.error(
            this.translate.instant("COMMAND.ERROR", {
              message: result?.responseStatus?.message,
            })
          );
          dark
            ? (myImage.src = "assets/images/img_not_found.png")
            : (myImage.src = "assets/images/img_not_found_dark.png");
        }
      })
      .catch((error) => {
        this._flashService.error(
          this.translate.instant("COMMAND.ERROR", {
            message: JSON.stringify(error),
          })
        );
      })
      .finally(() => {
        this.loadingTab = false;
        this._changeDetector.markForCheck();
      });

    this.loadingTab = true;
    this._flashService.success(this.translate.instant("COMMAND.SENT"));
    this._changeDetector.markForCheck();
  }

  changeEvent(event: MatTabChangeEvent) {
    if (event.tab.textLabel === "CONFIGURATION") {
      //configuration
      this._loadSectionTab();
    } else if (event.tab.textLabel === "PHOTO") {
      this.getImage(false);
    } else if (event.tab.textLabel === "FORECAST") {
      this._loadForeCast();
    } else if (event.tab.textLabel === "ECO_FORECAST") {
      this._loadingForecastEcoParameter(this.modelInfo.id);
    } else {
      this.selectedMeteoSensor = this.meteoSensor[event.tab.textLabel];
      this.meteoParams.setValue(
        this.selectedMeteoSensor.measureParameters[0].id
      );
    }

    this._changeDetector.markForCheck();
  }
  _loadForeCast() {
    this.loadingTab = true;
    forkJoin({
      AirParameters: this._meteoObjectPopupService.getAirParametersForecast(
        this.modelInfo.id
      ),
      Precipitation: this._meteoObjectPopupService.getPrecipitationForecast(
        this.modelInfo.id
      ),
      FrostsProbability: this._meteoObjectPopupService.getFrostsProbabilityForecast(
        this.modelInfo.id
      ),
    })
      .pipe(takeUntil(this._destroy))
      .subscribe(
        ({
          AirParameters,
          Precipitation,
          FrostsProbability /*MeteoParameterAnalysis*/,
        }) => {
          this.foreCast.airTemperature = Math.round(
            AirParameters.airTemperature
          );
          this.foreCast.atmosphericPressure = Math.round(
            AirParameters.atmosphericPressure
          );
          this.foreCast.humidityPercent = Math.round(
            AirParameters.humidityPercent
          );
          this.foreCast.probabilityPercent = Math.round(
            Precipitation.probabilityPercent
          );
          this.foreCast.typePrecipitation = Precipitation.type;
          this.foreCast.probabilityFrosts = FrostsProbability.probability;
          this.loadingTab = false;
          this._changeDetector.markForCheck();
        }
      );
  }

  getCategoryName(categoryItem: MeasureParameter) {
    const itemName = this.meteCategoryName.find(
      (item) => item.type === categoryItem?.name
    );
    return itemName ? itemName.name : categoryItem.name;
  }
  getMeteoStationName(categoryItem: MeasureParameter) {
    const itemName = this.meteoStationName.find(
      (item) => item.name === categoryItem.name
    );
    return itemName ? _(itemName.nameTransl) : categoryItem.name;
  }
  getCategoryUnit(categoryItem: BaseUnit, text?: string) {
    const itemName = this.meteoUint.find((item) => item.type === categoryItem);
    return itemName?.name ? itemName.name : "";
  }
  getFrostsProbabilities(categoryItem: FrostsProbabilities, text?: string) {
    const itemName = this.frostsProbabilities.find(
      (item) => item.type === categoryItem
    );
    return itemName?.name ? this.translate.instant(itemName.name) : "";
  }
  getTypePrecipitation(categoryItem: PrecipitationType, text?: string) {
    const itemName = this.typePrecipitation.find(
      (item) => item.type === categoryItem
    );
    return itemName?.name ? this.translate.instant(itemName.name) : "";
  }
  getTypePrecipitationNone(categoryItem: PrecipitationType, text?: string) {
    return categoryItem === PrecipitationType.None ? false : true;
  }
  getTableItemName(type: string) {
    return (
      this.itemTableTranslation?.find((m) => m.type === type)?.name || type
    );
  }

  getName(item: any) {
    console.log(item);
  }
}
