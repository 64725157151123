<mat-form-field style="width: 100%" floatLabel="always">
  <mat-select
    #routeSelect
    (openedChange)="$event && searchInput.focus()"
    (selectionChange)="selectRoute($event.value)"
    placeholder="{{ 'COMMON.ROUTE' | translate }}"
    disableOptionCentering="true"
    panelClass="single-select-panel"
  >
    <mat-select-trigger>
      {{ selectedRouteName }}
    </mat-select-trigger>
    <div (click)="$event.stopPropagation()" class="mat-theme-bg" style="position: sticky; top: 0; z-index: 2; padding: 12px 16px">
      <input
        #searchInput
        class="mat-input-element"
        [placeholder]="('COMMON.SEARCH' | translate) + '...'"
        autocomplete="off"
        spellcheck="false"
        (keyup)="searchInputSubject.next($event.target.value)"
        (input)="searchInputSubject.next($event.target.value)"
        (keydown)="$event.stopPropagation()"
        (click)="$event.stopPropagation()"
      />
    </div>
    <mat-option style="display: none"></mat-option>
    <mat-option *ngFor="let item of routeListSubject | async" [value]="item.id">
      <div style="line-height: 1">{{ item.name }}</div>
      <div style="line-height: 1; text-overflow: ellipsis; overflow: hidden" class="mat-hint">
        <small>{{ item.description }}</small>
      </div>
    </mat-option>
  </mat-select>
  <button
    *ngIf="selectedRoute"
    type="button"
    mat-icon-button
    class="mat-secondary-color"
    type="button"
    (click)="$event.stopPropagation(); selectRoute(null); routeSelect.value = null"
    style="position: absolute; right: 22px; width: 18px; height: 18px; line-height: 18px"
  >
    <mat-icon style="font-size: 18px; height: 18px; line-height: 18px; width: 18px">close</mat-icon>
  </button>
</mat-form-field>

<mat-divider *ngIf="selectedRoute?.settings.routeObjects?.length" style="margin: 0 -16px"></mat-divider>

<div style="margin: 0 -16px; overflow: auto; overflow: overlay; flex: 1">
  <mat-list style="padding: 0" cdkDropList (cdkDropListDropped)="drop($event)">
    <mat-list-item *ngFor="let item of selectedRoute?.settings.routeObjects; let i = index" style="height: 52px">
      <div
        fxLayout
        fxLayoutGap="8px"
        fxLayoutAlign="streight center"
        cdkDrag
        cdkDragLockAxis="y"
        class="mat-dialog-bg"
        style="font-size: 14px; width: 100%; height: 52px"
      >
        <mat-icon class="handler" cdkDragHandle>more_vert</mat-icon>
        <span class="mat-border schemaState" style="box-sizing: border-box; padding: 0; border-radius: 3px !important">
          <object
            data="/assets/images/tlc/layout_button.svg"
            (load)="svgLoad($event.target, item, item.stepMovement)"
            style="width: 44px; height: 44px; margin: -3px 0 0 -3px; pointer-events: none; display: block"
            type="image/svg+xml"
            [style.transform]="'rotate(' + getAngle(item) + 'deg)'"
          ></object>
        </span>
        <span class="name" style="flex: 1" [title]="getName(item)">{{ i + 1 }}. {{ getName(item) }}</span>
        <button mat-icon-button class="remove" (click)="remove(item)">
          <mat-icon class="mat-secondary-color">close</mat-icon>
        </button>
      </div>
      <mat-divider></mat-divider>
    </mat-list-item>
  </mat-list>
</div>

<mat-divider style="margin: 0 -16px"></mat-divider>

<div style="padding: 12px 0">
  <button mat-flat-button style="flex: 1; width: 100%" color="primary" [disabled]="!selectedRoute" (click)="start()">
    {{ "ROUTE_ESCORT.BEGIN_ESCORT" | translate }}
  </button>
</div>
