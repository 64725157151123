import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';

import { Component, ChangeDetectionStrategy, Injector, Input, OnInit } from '@angular/core';

import { FormControl } from '@angular/forms';
import { TableBaseComponent } from 'projects/msu-its-web-common/src/components/tables/table-base.component';
import { SettingsService } from 'projects/msu-its-web-common/src/services/settings.service';
import { DetectorReplacement, ReplacementReason } from '../../dtos/tfms.dtos';
import { DetectorLogService } from '../../services/detector-log.service';
import { ActivatedRoute } from '@angular/router';
import { DETECTOR_REASON_REPLACE } from '../../dtos/enum';

@Component({
  selector: 'detector-log',
  templateUrl: './detector-log.component.html',
  styles: [
    `
      :host {
        display: flex;
        flex: 1;
        width: 100%;
        height: 100%;
      }
      /*
      .mat-column-entityName {
        flex: 2;
      }
      */
      .mat-column-description {
        flex: 2;
      }
      .date-button {
        pointer-events: none;
        font-size: 16px;
        position: absolute;
        right: 0;
        width: 20px;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DetectorLogComponent
  extends TableBaseComponent<DetectorReplacement, DetectorReplacement>
  implements OnInit {
  customerId: string;

  @Input()
  entityId: string;

  @Input()
  entityName: string;

  displayedColumns = [
    // 'modified',
    'date',
    'customerName',
    'modifiedByName',

    'oldDeviceNumber',
    'newDeviceNumber',
    'reason',
    'description',
    // 'beginDate',
    // 'endDate',
    // 'message',

    // 'eventType',
  ];

  rowActions = [
    // { action: 'edit', name: _('COMMON.EDIT'), icon: 'edit' },
    // { action: 'delete', name: _('COMMON.DELETE'), icon: 'delete' },
  ];

  msgAddSaved = null;
  msgEditSaved = null;
  msgDeleteConfirm = null;
  msgDeleteSuccess = null;
  msgDeleteSelectedConfirm = null;
  msgDeleteSelectedSuccess = null;

  fromControl = new FormControl();
  toControl = new FormControl();

  get timezone() {
    return this._settingsService.timezone;
  }
  get locale() {
    return this.translate.currentLang;
  }

  constructor(
    private _route: ActivatedRoute,
    injector: Injector,
    private _settingsService: SettingsService
  ) {
    super(injector, injector.get(DetectorLogService));

    this.fromControl.valueChanges.subscribe(() => this._setDateRange());
    this.toControl.valueChanges.subscribe(() => this._setDateRange());
  }

  ngOnInit() {
    this.entityId = this._route.snapshot.paramMap.get('objectId');
    this.customerId = this._route.snapshot.paramMap.get('customerId');
    this.dataSource.requestParams = { customemrId: this.customerId, detectorId: this.entityId };
  }

  _setDateRange() {
    this.dataSource.requestParams.beginDate = this.fromControl.value
      ? new Date(this.fromControl.value + this.timezone).toISOString()
      : null;

    this.dataSource.requestParams.endDate = this.toControl.value
      ? new Date(this.toControl.value + this.timezone).toISOString()
      : null;

    this.dataSource.reloadData();
  }

  getNew() {
    return null;
  }

  getEdit(id) {
    return null;
  }

  getEditDialog() {
    return null;
  }

  getReplaceName(value: ReplacementReason) {
    const item = DETECTOR_REASON_REPLACE.find((m) => m.status === value);
    return item ? item.name : value;
  }
}
