/* Options:
Date: 2024-03-20 05:27:27
Version: 6.70
Tip: To override a DTO option, remove "//" prefix before updating
BaseUrl: https://meteo.dev.its.msu24.ru

//GlobalNamespace: 
//MakePropertiesOptional: False
//AddServiceStackTypes: True
//AddResponseStatus: False
//AddImplicitVersion: 
//AddDescriptionAsComments: True
//IncludeTypes: 
//ExcludeTypes: 
//DefaultImports: 
*/

export interface IReturn<T> {
  createResponse(): T;
}

export interface IReturnVoid {
  createResponse(): void;
}

export interface IHasSessionId {
  sessionId: string;
}

export interface IHasBearerToken {
  bearerToken: string;
}

export interface IPost {}

// @DataContract
export enum MeteoSensorType {
  None = 'None',
  IWS2 = 'IWS2',
  BurstroyDspd = 'BurstroyDspd',
  Vs2k = 'Vs2k',
  Owen = 'Owen',
  Gank4RB = 'Gank4RB',
  Peleng = 'Peleng',
  OneWireTemp = 'OneWireTemp',
  PWD12 = 'PWD12',
  WMT700 = 'WMT700',
  DRS511 = 'DRS511',
  HMP155 = 'HMP155',
  PTB110 = 'PTB110',
  DTS12G = 'DTS12G',
  PWD22 = 'PWD22',
  WA15 = 'WA15',
}

export enum MeteoObjectReportType {
  CSV = 'CSV',
  XLSX = 'XLSX',
}

export interface IBaseEntityItemRequest {
  id: string;
}

export interface ICustomerItemRequest extends ICustomerEntity {}

export interface ICustomerEntity {
  // @DataMember
  customerId: string;
}

export interface IDescription {
  // @DataMember
  description: string;
}

export interface IRequestWithEntity {}

// @DataContract
export class PageRequest {
  // @DataMember
  public page: number;

  // @DataMember
  public items: number;

  // @DataMember
  public sort: string;

  // @DataMember
  public filter: string;

  // @DataMember
  public propertiesTest: { [index: string]: number };

  public constructor(init?: Partial<PageRequest>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export enum Category {
  Temperature = 'Temperature',
  Pressure = 'Pressure',
  Humidity = 'Humidity',
  Precipitation = 'Precipitation',
  Speed = 'Speed',
  Distance = 'Distance',
  State = 'State',
  Concentration = 'Concentration',
  Traction = 'Traction',
  Thickness = 'Thickness',
  Angle = 'Angle',
  Voltage = 'Voltage',
  Current = 'Current',
  Power = 'Power',
}

// @DataContract
export enum BaseUnit {
  Statement = 'Statement',
  Number = 'Number',
  Degree = 'Degree',
  DegreeCelsius = 'DegreeCelsius',
  Percent = 'Percent',
  Millimeters = 'Millimeters',
  MillimetersHg = 'MillimetersHg',
  Gigapascal = 'Gigapascal',
  MetersPerSecond = 'MetersPerSecond',
  Meters = 'Meters',
  Coefficient = 'Coefficient',
  MiligramsPerCubicMeter = 'MiligramsPerCubicMeter',
  MicrogramsPerCubicMeter = 'MicrogramsPerCubicMeter',
  Volt = 'Volt',
  Ampere = 'Ampere',
  Watt = 'Watt',
}

// @DataContract
export class MeasureParameter {
  // @DataMember
  public id: string;

  // @DataMember
  public name: string;

  // @DataMember
  public category: Category;

  // @DataMember
  public unit: BaseUnit;

  // @DataMember
  public special: boolean;

  public constructor(init?: Partial<MeasureParameter>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class MeteoSensor {
  // @DataMember
  public name: string;

  // @DataMember
  public error: string;

  // @DataMember
  public errorId: string;

  // @DataMember
  public type: MeteoSensorType;

  // @DataMember
  public measureParameters: MeasureParameter[];

  public constructor(init?: Partial<MeteoSensor>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class GetMeteoGraphRequestBase implements IBaseEntityItemRequest {
  // @DataMember
  public smooth?: number;

  // @DataMember
  public sensorType: MeteoSensorType;

  // @DataMember
  public measureParameterIds: string[];

  // @DataMember(IsRequired=true)
  public id: string;

  // @DataMember(IsRequired=true)
  public startTime: string;

  // @DataMember(IsRequired=true)
  public endTime: string;

  // @DataMember
  public groupBySeconds: number;

  public constructor(init?: Partial<GetMeteoGraphRequestBase>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class GetMeteoTableRequestBase implements IBaseEntityItemRequest {
  // @DataMember
  public sensorType: MeteoSensorType;

  // @DataMember
  public measureParameterId: string;

  // @DataMember(IsRequired=true)
  public id: string;

  // @DataMember(IsRequired=true)
  public startTime: string;

  // @DataMember(IsRequired=true)
  public endTime: string;

  // @DataMember
  public groupBySeconds: number;

  public constructor(init?: Partial<GetMeteoTableRequestBase>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class GetEntityGroupsRequestBase implements ICustomerItemRequest {
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public entityGroups: string[];

  // @DataMember
  public itemsIds: string[];

  // @DataMember
  public onlyOwnedItems: boolean;

  // @DataMember
  public page: number;

  // @DataMember
  public items: number;

  // @DataMember
  public sort: string;

  // @DataMember
  public filter: string;

  // @DataMember
  public propertiesTest: { [index: string]: number };

  public constructor(init?: Partial<GetEntityGroupsRequestBase>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class GetEntityGroupRequestBase implements ICustomerItemRequest, IBaseEntityItemRequest {
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember(IsRequired=true)
  public id: string;

  public constructor(init?: Partial<GetEntityGroupRequestBase>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class EntityGroup
  implements
    ICustomerEntityViewModel,
    IExternalSystemEntity,
    INamedEntity,
    IChangeKey,
    IDescription,
    IModified,
    IEntity
{
  // @DataMember
  public items: string[];

  // @DataMember
  public customerId: string;

  // @DataMember
  public customerName: string;

  // @DataMember
  public externalSystemId?: string;

  // @DataMember
  public externalSystemName: string;

  // @DataMember
  public externalSystemEntityId?: string;

  // @DataMember
  public name: string;

  // @DataMember
  public description: string;

  // @DataMember
  public changeKey: string;

  // @DataMember
  public created: string;

  // @DataMember
  public createdBy: string;

  // @DataMember
  public modifiedBy: string;

  // @DataMember
  public modified: string;

  // @DataMember
  public id: string;

  // @DataMember
  public entityType: string;

  public constructor(init?: Partial<EntityGroup>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class CreateEntityGroupRequestBase implements ICustomerItemRequest, IDescription {
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public updateEntityGroups: boolean;

  // @DataMember
  public entityGroups: string[];

  // @DataMember(IsRequired=true)
  public entity: EntityGroup;

  // @DataMember
  public description: string;

  public constructor(init?: Partial<CreateEntityGroupRequestBase>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class UpdateEntityGroupRequestBase
  implements ICustomerItemRequest, IBaseEntityItemRequest, IDescription
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public updateEntityGroups: boolean;

  // @DataMember
  public entityGroups: string[];

  // @DataMember(IsRequired=true)
  public id: string;

  // @DataMember(IsRequired=true)
  public entity: EntityGroup;

  // @DataMember
  public description: string;

  public constructor(init?: Partial<UpdateEntityGroupRequestBase>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class DeleteEntityGroupsRequestBase implements ICustomerItemRequest, IDescription {
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public description: string;

  // @DataMember(IsRequired=true)
  public ids: string[];

  public constructor(init?: Partial<DeleteEntityGroupsRequestBase>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class GetEntityGroupSharesRequestBase
  implements IBaseEntityItemRequest, ICustomerItemRequest
{
  // @DataMember
  public id: string;

  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public entityGroups: string[];

  // @DataMember
  public itemsIds: string[];

  // @DataMember
  public onlyOwnedItems: boolean;

  // @DataMember
  public page: number;

  // @DataMember
  public items: number;

  // @DataMember
  public sort: string;

  // @DataMember
  public filter: string;

  // @DataMember
  public propertiesTest: { [index: string]: number };

  public constructor(init?: Partial<GetEntityGroupSharesRequestBase>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class EntityGroupPermissions implements IEntityTyped, ICustomerEntityViewModel {
  // @DataMember
  public customerId: string;

  // @DataMember
  public customerName: string;

  // @DataMember
  public entityGroupId: string;

  // @DataMember
  public permissions: string[];

  // @DataMember
  public entityType: string;

  public constructor(init?: Partial<EntityGroupPermissions>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class UpgradeEntityGroupShareRequestBase implements ICustomerItemRequest, IDescription {
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember(IsRequired=true)
  public id: string;

  // @DataMember(IsRequired=true)
  public entity: EntityGroupPermissions;

  // @DataMember
  public description: string;

  public constructor(init?: Partial<UpgradeEntityGroupShareRequestBase>) {
    (Object as any).assign(this, init);
  }
}

export class DeleteCustomerItemsRequest implements ICustomerItemRequest, IDescription {
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public description: string;

  // @DataMember(IsRequired=true)
  public ids: string[];

  public constructor(init?: Partial<DeleteCustomerItemsRequest>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class ChangeCustomerRequestBase
  implements IDescription, ICustomerItemRequest, IBaseEntityItemRequest
{
  // @DataMember
  public description: string;

  // @DataMember
  public newCustomerId: string;

  // @DataMember
  public customerId: string;

  // @DataMember
  public id: string;

  public constructor(init?: Partial<ChangeCustomerRequestBase>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class GetCustomerEntityCurrentUserPermissionsRequestBase
  implements ICustomerItemRequest, IBaseEntityItemRequest
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember(IsRequired=true)
  public id: string;

  public constructor(init?: Partial<GetCustomerEntityCurrentUserPermissionsRequestBase>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class IconStatus {
  // @DataMember
  public color: string;

  // @DataMember
  public borderColor: string;

  // @DataMember
  public processing: boolean;

  public constructor(init?: Partial<IconStatus>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class MeteoObjectStatus implements IEntity {
  // @DataMember
  public iconStatus: IconStatus;

  // @DataMember
  public id: string;

  // @DataMember
  public entityType: string;

  public constructor(init?: Partial<MeteoObjectStatus>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export enum CameraCommand {
  MoveLeft = 'MoveLeft',
  MoveRight = 'MoveRight',
  MoveUp = 'MoveUp',
  MoveDown = 'MoveDown',
  MoveHome = 'MoveHome',
  LightOn = 'LightOn',
  LightOff = 'LightOff',
  Wipe = 'Wipe',
}

// @DataContract
export class HistoricalDataRequestBase implements IBaseEntityItemRequest {
  // @DataMember(IsRequired=true)
  public id: string;

  // @DataMember(IsRequired=true)
  public startTime: string;

  // @DataMember(IsRequired=true)
  public endTime: string;

  // @DataMember
  public groupBySeconds: number;

  public constructor(init?: Partial<HistoricalDataRequestBase>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export enum MeteoStationType {
  None = 'None',
  Briz = 'Briz',
  Burstroy = 'Burstroy',
  Vaisala = 'Vaisala',
}

// @DataContract
export class MeteoStationInfo
  implements
    ICustomerEntity,
    ICustomerName,
    IDeviceItem,
    IOwnedDeviceItem,
    IChangeKey,
    IDescription,
    IModified,
    IEntity
{
  // @DataMember
  public stationType: MeteoStationType;

  // @DataMember
  public customerId: string;

  // @DataMember
  public customerName: string;

  // @DataMember
  public ownerObjectName: string;

  // @DataMember
  public ownerObjectId: string;

  // @DataMember
  public prefix: string;

  // @DataMember
  public number: string;

  // @DataMember
  public phone1: string;

  // @DataMember
  public phone2: string;

  // @DataMember
  public ip1: string;

  // @DataMember
  public ip2: string;

  // @DataMember
  public imei: string;

  // @DataMember
  public lat: number;

  // @DataMember
  public lon: number;

  // @DataMember
  public direction: number;

  // @DataMember
  public fullNumber: string;

  // @DataMember
  public softwareVersion: string;

  // @DataMember
  public firmwareVersion: string;

  // @DataMember
  public hardwareVersion: string;

  // @DataMember
  public description: string;

  // @DataMember
  public changeKey: string;

  // @DataMember
  public created: string;

  // @DataMember
  public createdBy: string;

  // @DataMember
  public modifiedBy: string;

  // @DataMember
  public modified: string;

  // @DataMember
  public id: string;

  // @DataMember
  public entityType: string;

  public constructor(init?: Partial<MeteoStationInfo>) {
    (Object as any).assign(this, init);
  }
}

export interface ICustomerIntegrationIdRequest {
  customerIntegrationId: string;
}

export class DataFullnessDescription {
  // @DataMember
  public isDataFull: boolean;

  // @DataMember
  public description: string[];

  public constructor(init?: Partial<DataFullnessDescription>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export enum PrecipitationType {
  None = 'None',
  Precipitation = 'Precipitation',
  Drizzle = 'Drizzle',
  Rain = 'Rain',
  Snow = 'Snow',
  Sleet = 'Sleet',
  IcyRain = 'IcyRain',
  Hail = 'Hail',
  Unknown = 'Unknown',
  SnowAndRain = 'SnowAndRain',
  FreezingDrizzle = 'FreezingDrizzle',
  FreezingRain = 'FreezingRain',
}

// @DataContract
export enum FrostsProbabilities {
  Low = 'Low',
  Medium = 'Medium',
  High = 'High',
}

// @DataContract
export class MeteoParameterAnalysis {
  // @DataMember
  public minValue?: number;

  // @DataMember
  public maxValue?: number;

  // @DataMember
  public averageAtPeriod?: number;

  // @DataMember
  public measureParameter: MeasureParameter;

  public constructor(init?: Partial<MeteoParameterAnalysis>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class EcoParameter {
  // @DataMember
  public value?: number;

  // @DataMember
  public measureParameter: MeasureParameter;

  public constructor(init?: Partial<EcoParameter>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export enum RequiredAction {
  None = 'None',
  SurfaceCleaning = 'SurfaceCleaning',
  AddReagents = 'AddReagents',
  ChangeAbrasives = 'ChangeAbrasives',
  SetUpAttentionSigns = 'SetUpAttentionSigns',
  CleanWaterStocks = 'CleanWaterStocks',
}

// @DataContract
export class LatestMeteoData {
  // @DataMember
  public meteoStationId: string;

  // @DataMember
  public humidityPercent?: number;

  // @DataMember
  public airTemperature?: number;

  // @DataMember
  public atmosphericPressure?: number;

  // @DataMember
  public dewPointTemperature?: number;

  // @DataMember
  public windSpeed?: number;

  // @DataMember
  public windDirection?: number;

  // @DataMember
  public windGust?: number;

  // @DataMember
  public precipitation?: number;

  // @DataMember
  public precipitationType: string;

  // @DataMember
  public roadTemperature?: number;

  // @DataMember
  public surfaceTemperature?: number;

  // @DataMember
  public abrasivesFreezingPointTemperature?: number;

  // @DataMember
  public waterLayerThickness?: number;

  // @DataMember
  public snowLayerThickness?: number;

  // @DataMember
  public iceLayerThickness?: number;

  // @DataMember
  public abrasivesPercentage?: number;

  // @DataMember
  public snowIcePercentage?: number;

  // @DataMember
  public sensorSurfaceGrip?: number;

  // @DataMember
  public surfaceStatus: string;

  // @DataMember
  public pollution?: number;

  // @DataMember
  public visibility?: number;

  // @DataMember
  public ecoNO?: number;

  // @DataMember
  public ecoNO2?: number;

  // @DataMember
  public ecoO3?: number;

  // @DataMember
  public ecoCO?: number;

  // @DataMember
  public ecoSO2?: number;

  // @DataMember
  public ecoPM2?: number;

  // @DataMember
  public ecoPM10?: number;

  // @DataMember
  public isDataFull: boolean;

  // @DataMember
  public description: string[];

  public constructor(init?: Partial<LatestMeteoData>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class EcoInfoSummaryGraphItem {
  // @DataMember
  public time?: string;

  // @DataMember
  public co?: number;

  // @DataMember
  public no?: number;

  // @DataMember
  public nO2?: number;

  // @DataMember
  public o3?: number;

  // @DataMember
  public pM2?: number;

  // @DataMember
  public pM10?: number;

  // @DataMember
  public sO2?: number;

  public constructor(init?: Partial<EcoInfoSummaryGraphItem>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class MeteoInfoSummaryGraphItem {
  // @DataMember
  public time?: string;

  // @DataMember
  public atmosphericPressure?: number;

  // @DataMember
  public humidityPercent?: number;

  // @DataMember
  public airTemperature?: number;

  // @DataMember
  public windSpeed?: number;

  // @DataMember
  public isDataFull: boolean;

  // @DataMember
  public description: string[];

  public constructor(init?: Partial<MeteoInfoSummaryGraphItem>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export enum ReplacementReason {
  Demount = 'Demount',
  Replace = 'Replace',
  Other = 'Other',
}

export class CustomerBaseEntityViewModelBase
  implements
    ICustomerEntityViewModel,
    IExternalSystemEntity,
    IChangeKey,
    IDescription,
    IModified,
    IEntity
{
  // @DataMember
  public customerId: string;

  // @DataMember
  public customerName: string;

  // @DataMember
  public externalSystemId?: string;

  // @DataMember
  public externalSystemName: string;

  // @DataMember
  public externalSystemEntityId?: string;

  // @DataMember
  public description: string;

  // @DataMember
  public changeKey: string;

  // @DataMember
  public created: string;

  // @DataMember
  public createdBy: string;

  // @DataMember
  public modifiedBy: string;

  // @DataMember
  public modified: string;

  // @DataMember
  public id: string;

  // @DataMember
  public entityType: string;

  public constructor(init?: Partial<CustomerBaseEntityViewModelBase>) {
    (Object as any).assign(this, init);
  }
}

export interface IEntity {}

export interface IEntityTyped {
  // @DataMember
  entityType: string;
}

export interface IChangeKey extends IReadonlyChangeKey {
  // @DataMember
  changeKey: string;
}

export interface IReadonlyChangeKey {
  // @DataMember
  changeKey: string;
}

export interface IModified {
  // @DataMember
  modified: string;

  // @DataMember
  modifiedBy: string;
}

export interface ICustomerEntityViewModel extends ICustomerName, ICustomerEntity {}

export interface ICustomerName {
  // @DataMember
  customerName: string;
}

export interface IExternalSystemEntity {
  // @DataMember
  externalSystemId?: string;

  // @DataMember
  externalSystemName: string;

  // @DataMember
  externalSystemEntityId?: string;
}

// @DataContract
export class MeteoParameterValue implements ICompleteValue, INamed {
  // @DataMember
  public timeStamp: string;

  // @DataMember
  public boolValue?: boolean;

  // @DataMember
  public doubleValue?: number;

  // @DataMember
  public stringValue: string;

  // @DataMember
  public id: string;

  // @DataMember
  public name: string;

  // @DataMember
  public category: Category;

  // @DataMember
  public unit: BaseUnit;

  // @DataMember
  public special: boolean;

  public constructor(init?: Partial<MeteoParameterValue>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class GraphValue implements ITimedNumericValue {
  // @DataMember
  public timeStamp: string;

  // @DataMember
  public doubleValue?: number;

  public constructor(init?: Partial<GraphValue>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class MeteoParameterGraph implements INamed {
  // @DataMember
  public min?: number;

  // @DataMember
  public max?: number;

  // @DataMember
  public avg?: number;

  // @DataMember
  public rms?: number;

  // @DataMember
  public values: GraphValue[];

  // @DataMember
  public id: string;

  // @DataMember
  public name: string;

  // @DataMember
  public category: Category;

  // @DataMember
  public unit: BaseUnit;

  // @DataMember
  public special: boolean;

  public constructor(init?: Partial<MeteoParameterGraph>) {
    (Object as any).assign(this, init);
  }
}

export interface INamed {
  // @DataMember
  name: string;
}

// @DataContract
export class EntityGroupInfo
  implements
    ICustomerEntityViewModel,
    IExternalSystemEntity,
    INamedEntity,
    IChangeKey,
    IDescription,
    IModified,
    IEntity
{
  // @DataMember
  public itemsCount: number;

  // @DataMember
  public customerId: string;

  // @DataMember
  public customerName: string;

  // @DataMember
  public externalSystemId?: string;

  // @DataMember
  public externalSystemName: string;

  // @DataMember
  public externalSystemEntityId?: string;

  // @DataMember
  public name: string;

  // @DataMember
  public description: string;

  // @DataMember
  public changeKey: string;

  // @DataMember
  public created: string;

  // @DataMember
  public createdBy: string;

  // @DataMember
  public modifiedBy: string;

  // @DataMember
  public modified: string;

  // @DataMember
  public id: string;

  // @DataMember
  public entityType: string;

  public constructor(init?: Partial<EntityGroupInfo>) {
    (Object as any).assign(this, init);
  }
}

export class CustomerNamedBaseEntityViewModelBase
  implements
    ICustomerEntityViewModel,
    IExternalSystemEntity,
    INamedEntity,
    IChangeKey,
    IDescription,
    IModified,
    IEntity
{
  // @DataMember
  public customerId: string;

  // @DataMember
  public customerName: string;

  // @DataMember
  public externalSystemId?: string;

  // @DataMember
  public externalSystemName: string;

  // @DataMember
  public externalSystemEntityId?: string;

  // @DataMember
  public name: string;

  // @DataMember
  public description: string;

  // @DataMember
  public changeKey: string;

  // @DataMember
  public created: string;

  // @DataMember
  public createdBy: string;

  // @DataMember
  public modifiedBy: string;

  // @DataMember
  public modified: string;

  // @DataMember
  public id: string;

  // @DataMember
  public entityType: string;

  public constructor(init?: Partial<CustomerNamedBaseEntityViewModelBase>) {
    (Object as any).assign(this, init);
  }
}

export interface INamedEntity extends IEntity, INamed {}

// @DataContract
export class TableValue implements ITimedStringValue {
  // @DataMember
  public timeStamp: string;

  // @DataMember
  public stringValue: string;

  public constructor(init?: Partial<TableValue>) {
    (Object as any).assign(this, init);
  }
}

export class CustomerDeviceViewModelBase
  implements
    ICustomerEntity,
    ICustomerName,
    IDeviceItem,
    IOwnedDeviceItem,
    IChangeKey,
    IDescription,
    IModified,
    IEntity
{
  // @DataMember
  public customerId: string;

  // @DataMember
  public customerName: string;

  // @DataMember
  public ownerObjectName: string;

  // @DataMember
  public ownerObjectId: string;

  // @DataMember
  public prefix: string;

  // @DataMember
  public number: string;

  // @DataMember
  public phone1: string;

  // @DataMember
  public phone2: string;

  // @DataMember
  public ip1: string;

  // @DataMember
  public ip2: string;

  // @DataMember
  public imei: string;

  // @DataMember
  public deviceSettings: string;

  // @DataMember
  public lat: number;

  // @DataMember
  public lon: number;

  // @DataMember
  public direction: number;

  // @DataMember
  public fullNumber: string;

  // @DataMember
  public softwareVersion: string;

  // @DataMember
  public firmwareVersion: string;

  // @DataMember
  public hardwareVersion: string;

  // @DataMember
  public description: string;

  // @DataMember
  public changeKey: string;

  // @DataMember
  public created: string;

  // @DataMember
  public createdBy: string;

  // @DataMember
  public modifiedBy: string;

  // @DataMember
  public modified: string;

  // @DataMember
  public id: string;

  // @DataMember
  public entityType: string;

  public constructor(init?: Partial<CustomerDeviceViewModelBase>) {
    (Object as any).assign(this, init);
  }
}

export interface IDeviceItem extends IDeviceVersion {
  // @DataMember
  prefix: string;

  // @DataMember
  number: string;

  // @DataMember
  phone1: string;

  // @DataMember
  phone2: string;

  // @DataMember
  ip1: string;

  // @DataMember
  ip2: string;

  // @DataMember
  imei: string;

  // @DataMember
  lat: number;

  // @DataMember
  lon: number;

  // @DataMember
  direction: number;
}

export interface IDeviceVersion {
  // @DataMember
  softwareVersion: string;

  // @DataMember
  firmwareVersion: string;

  // @DataMember
  hardwareVersion: string;
}

export interface IOwnedDeviceItem {
  // @DataMember
  ownerObjectName: string;

  // @DataMember
  ownerObjectId: string;
}

// @DataContract
export class BaseEntityViewModelBase implements IChangeKey, IDescription, IModified, IEntity {
  // @DataMember
  public description: string;

  // @DataMember
  public changeKey: string;

  // @DataMember
  public created: string;

  // @DataMember
  public createdBy: string;

  // @DataMember
  public modifiedBy: string;

  // @DataMember
  public modified: string;

  // @DataMember
  public id: string;

  // @DataMember
  public entityType: string;

  public constructor(init?: Partial<BaseEntityViewModelBase>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class ResponseError {
  // @DataMember(Order=1)
  public errorCode: string;

  // @DataMember(Order=2)
  public fieldName: string;

  // @DataMember(Order=3)
  public message: string;

  // @DataMember(Order=4)
  public meta: { [index: string]: string };

  public constructor(init?: Partial<ResponseError>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class ResponseStatus {
  // @DataMember(Order=1)
  public errorCode: string;

  // @DataMember(Order=2)
  public message: string;

  // @DataMember(Order=3)
  public stackTrace: string;

  // @DataMember(Order=4)
  public errors: ResponseError[];

  // @DataMember(Order=5)
  public meta: { [index: string]: string };

  public constructor(init?: Partial<ResponseStatus>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class DeleteItemsRequest implements IDescription {
  // @DataMember
  public description: string;

  // @DataMember(IsRequired=true)
  public ids: string[];

  public constructor(init?: Partial<DeleteItemsRequest>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class EntityViewModelBase implements IEntity {
  // @DataMember
  public id: string;

  // @DataMember
  public entityType: string;

  public constructor(init?: Partial<EntityViewModelBase>) {
    (Object as any).assign(this, init);
  }
}

export class CustomerDeviceInfoViewModelBase
  implements
    ICustomerEntity,
    ICustomerName,
    IDeviceItem,
    IOwnedDeviceItem,
    IChangeKey,
    IDescription,
    IModified,
    IEntity
{
  // @DataMember
  public customerId: string;

  // @DataMember
  public customerName: string;

  // @DataMember
  public ownerObjectName: string;

  // @DataMember
  public ownerObjectId: string;

  // @DataMember
  public prefix: string;

  // @DataMember
  public number: string;

  // @DataMember
  public phone1: string;

  // @DataMember
  public phone2: string;

  // @DataMember
  public ip1: string;

  // @DataMember
  public ip2: string;

  // @DataMember
  public imei: string;

  // @DataMember
  public lat: number;

  // @DataMember
  public lon: number;

  // @DataMember
  public direction: number;

  // @DataMember
  public fullNumber: string;

  // @DataMember
  public softwareVersion: string;

  // @DataMember
  public firmwareVersion: string;

  // @DataMember
  public hardwareVersion: string;

  // @DataMember
  public description: string;

  // @DataMember
  public changeKey: string;

  // @DataMember
  public created: string;

  // @DataMember
  public createdBy: string;

  // @DataMember
  public modifiedBy: string;

  // @DataMember
  public modified: string;

  // @DataMember
  public id: string;

  // @DataMember
  public entityType: string;

  public constructor(init?: Partial<CustomerDeviceInfoViewModelBase>) {
    (Object as any).assign(this, init);
  }
}

export interface ICompleteValue extends IBoolValue, ITimedNumericValue, IStringValue {}

export interface IBoolValue {
  // @DataMember
  boolValue?: boolean;
}

export interface ITimedNumericValue extends ITimedValue, IDoubleValue {}

export interface ITimedValue {
  // @DataMember
  timeStamp: string;
}

export interface IDoubleValue {
  // @DataMember
  doubleValue?: number;
}

export interface IStringValue {
  // @DataMember
  stringValue: string;
}

// @DataContract
export class NamedBaseEntityViewModelBase
  implements INamedEntity, IChangeKey, IDescription, IModified, IEntity
{
  // @DataMember
  public name: string;

  // @DataMember
  public description: string;

  // @DataMember
  public changeKey: string;

  // @DataMember
  public created: string;

  // @DataMember
  public createdBy: string;

  // @DataMember
  public modifiedBy: string;

  // @DataMember
  public modified: string;

  // @DataMember
  public id: string;

  // @DataMember
  public entityType: string;

  public constructor(init?: Partial<NamedBaseEntityViewModelBase>) {
    (Object as any).assign(this, init);
  }
}

export interface ITimedStringValue extends ITimedValue, IStringValue {}

export class DeviceViewModelBase
  implements IDeviceItem, IOwnedDeviceItem, IChangeKey, IDescription, IModified, IEntity
{
  // @DataMember
  public ownerObjectName: string;

  // @DataMember
  public ownerObjectId: string;

  // @DataMember
  public prefix: string;

  // @DataMember
  public number: string;

  // @DataMember
  public phone1: string;

  // @DataMember
  public phone2: string;

  // @DataMember
  public ip1: string;

  // @DataMember
  public ip2: string;

  // @DataMember
  public imei: string;

  // @DataMember
  public deviceSettings: string;

  // @DataMember
  public lat: number;

  // @DataMember
  public lon: number;

  // @DataMember
  public direction: number;

  // @DataMember
  public fullNumber: string;

  // @DataMember
  public softwareVersion: string;

  // @DataMember
  public firmwareVersion: string;

  // @DataMember
  public hardwareVersion: string;

  // @DataMember
  public description: string;

  // @DataMember
  public changeKey: string;

  // @DataMember
  public created: string;

  // @DataMember
  public createdBy: string;

  // @DataMember
  public modifiedBy: string;

  // @DataMember
  public modified: string;

  // @DataMember
  public id: string;

  // @DataMember
  public entityType: string;

  public constructor(init?: Partial<DeviceViewModelBase>) {
    (Object as any).assign(this, init);
  }
}

export class DeviceInfoViewModelBase
  implements IDeviceItem, IOwnedDeviceItem, IChangeKey, IDescription, IModified, IEntity
{
  // @DataMember
  public ownerObjectName: string;

  // @DataMember
  public ownerObjectId: string;

  // @DataMember
  public prefix: string;

  // @DataMember
  public number: string;

  // @DataMember
  public phone1: string;

  // @DataMember
  public phone2: string;

  // @DataMember
  public ip1: string;

  // @DataMember
  public ip2: string;

  // @DataMember
  public imei: string;

  // @DataMember
  public lat: number;

  // @DataMember
  public lon: number;

  // @DataMember
  public direction: number;

  // @DataMember
  public fullNumber: string;

  // @DataMember
  public softwareVersion: string;

  // @DataMember
  public firmwareVersion: string;

  // @DataMember
  public hardwareVersion: string;

  // @DataMember
  public description: string;

  // @DataMember
  public changeKey: string;

  // @DataMember
  public created: string;

  // @DataMember
  public createdBy: string;

  // @DataMember
  public modifiedBy: string;

  // @DataMember
  public modified: string;

  // @DataMember
  public id: string;

  // @DataMember
  public entityType: string;

  public constructor(init?: Partial<DeviceInfoViewModelBase>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class PageRequestBase {
  // @DataMember
  public page: number;

  // @DataMember
  public items: number;

  // @DataMember
  public sort: string;

  // @DataMember
  public filter: string;

  // @DataMember
  public propertiesTest: { [index: string]: number };

  public constructor(init?: Partial<PageRequestBase>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class MeteoModuleSettings {
  public constructor(init?: Partial<MeteoModuleSettings>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class ModuleSettingsBase {
  public constructor(init?: Partial<ModuleSettingsBase>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class MeteoMonitoringSettings {
  // @DataMember
  public enabled: boolean;

  public constructor(init?: Partial<MeteoMonitoringSettings>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class EcoMonitoringSettings {
  // @DataMember
  public enabled: boolean;

  public constructor(init?: Partial<EcoMonitoringSettings>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class DeviceSettings {
  // @DataMember
  public vaisalaDeviceSupport: boolean;

  // @DataMember
  public brizDeviceSupport: boolean;

  // @DataMember
  public burstroyDeviceSupport: boolean;

  public constructor(init?: Partial<DeviceSettings>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class MeteoModuleCustomerSettings {
  // @DataMember
  public meteoMonitoringSettings: MeteoMonitoringSettings;

  // @DataMember
  public ecoMonitoringSettings: EcoMonitoringSettings;

  // @DataMember
  public deviceSettings: DeviceSettings;

  public constructor(init?: Partial<MeteoModuleCustomerSettings>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class ModuleCustomerSettingsBase {
  public constructor(init?: Partial<ModuleCustomerSettingsBase>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class AirParametersForecast {
  // @DataMember
  public atmosphericPressure?: number;

  // @DataMember
  public humidityPercent?: number;

  // @DataMember
  public airTemperature?: number;

  // @DataMember
  public isDataFull: boolean;

  // @DataMember
  public description: string[];

  public constructor(init?: Partial<AirParametersForecast>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class PrecipitationForecast {
  // @DataMember
  public probabilityPercent?: number;

  // @DataMember
  public atmosphericPressure?: number;

  // @DataMember
  public type: PrecipitationType;

  // @DataMember
  public isDataFull: boolean;

  // @DataMember
  public description: string[];

  public constructor(init?: Partial<PrecipitationForecast>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class FrostsProbabilityForecast {
  // @DataMember
  public probability?: FrostsProbabilities;

  // @DataMember
  public currentTemperature?: number;

  // @DataMember
  public isDataFull: boolean;

  // @DataMember
  public description: string[];

  public constructor(init?: Partial<FrostsProbabilityForecast>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class EcoParameterForecast {
  // @DataMember
  public co: EcoParameter;

  // @DataMember
  public no: EcoParameter;

  // @DataMember
  public nO2: EcoParameter;

  // @DataMember
  public o3: EcoParameter;

  // @DataMember
  public pM2: EcoParameter;

  // @DataMember
  public pM10: EcoParameter;

  // @DataMember
  public sO2: EcoParameter;

  // @DataMember
  public isDataFull: boolean;

  // @DataMember
  public description: string[];

  public constructor(init?: Partial<EcoParameterForecast>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class RoadServicesInformation {
  // @DataMember
  public roadSurfaceTemperature?: number;

  // @DataMember
  public dewPointTemperature?: number;

  // @DataMember
  public participationType: string;

  // @DataMember
  public participationAmount?: number;

  // @DataMember
  public surfaceGripCoefficient?: number;

  // @DataMember
  public iceLayerThickness?: number;

  // @DataMember
  public snowLayerThickness?: number;

  // @DataMember
  public waterLayerThickness?: number;

  // @DataMember
  public abrasivesPercentage?: number;

  // @DataMember
  public abrasivesFreezingPoint?: number;

  // @DataMember
  public visibility?: number;

  // @DataMember
  public requiredActions: RequiredAction[];

  // @DataMember
  public isDataFull: boolean;

  // @DataMember
  public description: string[];

  public constructor(init?: Partial<RoadServicesInformation>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class EcoInfoSummary {
  // @DataMember
  public time: string;

  // @DataMember
  public co?: number;

  // @DataMember
  public no?: number;

  // @DataMember
  public nO2?: number;

  // @DataMember
  public o3?: number;

  // @DataMember
  public pM2?: number;

  // @DataMember
  public pM10?: number;

  // @DataMember
  public sO2?: number;

  // @DataMember
  public items: EcoInfoSummaryGraphItem[];

  // @DataMember
  public isDataFull: boolean;

  // @DataMember
  public description: string[];

  public constructor(init?: Partial<EcoInfoSummary>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class MeteoInfoSummary {
  // @DataMember
  public time: string;

  // @DataMember
  public atmosphericPressure?: number;

  // @DataMember
  public humidityPercent?: number;

  // @DataMember
  public airTemperature?: number;

  // @DataMember
  public windSpeed?: number;

  // @DataMember
  public items: MeteoInfoSummaryGraphItem[];

  public constructor(init?: Partial<MeteoInfoSummary>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class MeteoObjectReplacement
  implements
    ICustomerEntityViewModel,
    IExternalSystemEntity,
    IChangeKey,
    IDescription,
    IModified,
    IEntity
{
  // @DataMember
  public date: string;

  // @DataMember
  public meteoObjectId: string;

  // @DataMember
  public oldMeteoStationId?: string;

  // @DataMember
  public meteoStationId?: string;

  // @DataMember
  public modifiedBy: string;

  // @DataMember
  public modifiedByName: string;

  // @DataMember
  public newDeviceNumber: string;

  // @DataMember
  public oldDeviceNumber: string;

  // @DataMember
  public reason: ReplacementReason;

  // @DataMember
  public customerId: string;

  // @DataMember
  public customerName: string;

  // @DataMember
  public externalSystemId?: string;

  // @DataMember
  public externalSystemName: string;

  // @DataMember
  public externalSystemEntityId?: string;

  // @DataMember
  public description: string;

  // @DataMember
  public changeKey: string;

  // @DataMember
  public created: string;

  // @DataMember
  public createdBy: string;

  // @DataMember
  public modified: string;

  // @DataMember
  public id: string;

  // @DataMember
  public entityType: string;

  public constructor(init?: Partial<MeteoObjectReplacement>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class MeteoObjectReplacementsResponse implements IChangeKey {
  // @DataMember
  public items: MeteoObjectReplacement[];

  // @DataMember
  public total: number;

  // @DataMember
  public page: number;

  // @DataMember
  public itemsPerPage: number;

  // @DataMember
  public changeKey: string;

  public constructor(init?: Partial<MeteoObjectReplacementsResponse>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class MeteoSensorsResponse implements IChangeKey {
  // @DataMember
  public items: MeteoSensor[];

  // @DataMember
  public total: number;

  // @DataMember
  public page: number;

  // @DataMember
  public itemsPerPage: number;

  // @DataMember
  public changeKey: string;

  public constructor(init?: Partial<MeteoSensorsResponse>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class MeteoParameterTable implements INamed {
  // @DataMember
  public values: TableValue[];

  // @DataMember
  public id: string;

  // @DataMember
  public name: string;

  // @DataMember
  public category: Category;

  // @DataMember
  public unit: BaseUnit;

  // @DataMember
  public special: boolean;

  public constructor(init?: Partial<MeteoParameterTable>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class GetEntityGroupsResponse implements IChangeKey {
  // @DataMember
  public items: EntityGroupInfo[];

  // @DataMember
  public total: number;

  // @DataMember
  public page: number;

  // @DataMember
  public itemsPerPage: number;

  // @DataMember
  public changeKey: string;

  public constructor(init?: Partial<GetEntityGroupsResponse>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class GetEntityGroupSharesResponse implements IChangeKey {
  // @DataMember
  public items: EntityGroupPermissions[];

  // @DataMember
  public total: number;

  // @DataMember
  public page: number;

  // @DataMember
  public itemsPerPage: number;

  // @DataMember
  public changeKey: string;

  public constructor(init?: Partial<GetEntityGroupSharesResponse>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class MeteoStation
  implements
    ICustomerEntity,
    ICustomerName,
    IDeviceItem,
    IOwnedDeviceItem,
    IChangeKey,
    IDescription,
    IModified,
    IEntity
{
  // @DataMember
  public stationType: MeteoStationType;

  // @DataMember
  public customerId: string;

  // @DataMember
  public customerName: string;

  // @DataMember
  public ownerObjectName: string;

  // @DataMember
  public ownerObjectId: string;

  // @DataMember
  public prefix: string;

  // @DataMember
  public number: string;

  // @DataMember
  public phone1: string;

  // @DataMember
  public phone2: string;

  // @DataMember
  public ip1: string;

  // @DataMember
  public ip2: string;

  // @DataMember
  public imei: string;

  // @DataMember
  public deviceSettings: string;

  // @DataMember
  public lat: number;

  // @DataMember
  public lon: number;

  // @DataMember
  public direction: number;

  // @DataMember
  public fullNumber: string;

  // @DataMember
  public softwareVersion: string;

  // @DataMember
  public firmwareVersion: string;

  // @DataMember
  public hardwareVersion: string;

  // @DataMember
  public description: string;

  // @DataMember
  public changeKey: string;

  // @DataMember
  public created: string;

  // @DataMember
  public createdBy: string;

  // @DataMember
  public modifiedBy: string;

  // @DataMember
  public modified: string;

  // @DataMember
  public id: string;

  // @DataMember
  public entityType: string;

  public constructor(init?: Partial<MeteoStation>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class MeteoObject
  implements
    ICustomerEntityViewModel,
    IExternalSystemEntity,
    INamedEntity,
    IChangeKey,
    IDescription,
    IModified,
    IEntity
{
  // @DataMember
  public meteoStationId?: string;

  // @DataMember
  public lat: number;

  // @DataMember
  public lon: number;

  // @DataMember
  public direction: number;

  // @DataMember
  public deviceType: MeteoStationType;

  // @DataMember
  public serialNumber: string;

  // @DataMember
  public meteoStation: MeteoStation;

  // @DataMember
  public customerId: string;

  // @DataMember
  public customerName: string;

  // @DataMember
  public externalSystemId?: string;

  // @DataMember
  public externalSystemName: string;

  // @DataMember
  public externalSystemEntityId?: string;

  // @DataMember
  public name: string;

  // @DataMember
  public description: string;

  // @DataMember
  public changeKey: string;

  // @DataMember
  public created: string;

  // @DataMember
  public createdBy: string;

  // @DataMember
  public modifiedBy: string;

  // @DataMember
  public modified: string;

  // @DataMember
  public id: string;

  // @DataMember
  public entityType: string;

  public constructor(init?: Partial<MeteoObject>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class MeteoObjectsResponse implements IChangeKey {
  // @DataMember
  public items: MeteoObject[];

  // @DataMember
  public total: number;

  // @DataMember
  public page: number;

  // @DataMember
  public itemsPerPage: number;

  // @DataMember
  public changeKey: string;

  public constructor(init?: Partial<MeteoObjectsResponse>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class MeteoObjectStatusesResponse implements IChangeKey {
  // @DataMember
  public items: MeteoObjectStatus[];

  // @DataMember
  public total: number;

  // @DataMember
  public page: number;

  // @DataMember
  public itemsPerPage: number;

  // @DataMember
  public changeKey: string;

  public constructor(init?: Partial<MeteoObjectStatusesResponse>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class MeteoStationSession {
  // @DataMember
  public lastReceived: string;

  // @DataMember
  public lastSent: string;

  // @DataMember
  public deviceNumber: string;

  // @DataMember
  public connectionTime: string;

  // @DataMember
  public connected: boolean;

  // @DataMember
  public prefix: string;

  public constructor(init?: Partial<MeteoStationSession>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class MeteoObjectInfo
  implements
    ICustomerEntityViewModel,
    IExternalSystemEntity,
    INamedEntity,
    IChangeKey,
    IDescription,
    IModified,
    IEntity
{
  // @DataMember
  public meteoStationId?: string;

  // @DataMember
  public imei: string;

  // @DataMember
  public fullNumber: string;

  // @DataMember
  public lat: number;

  // @DataMember
  public lon: number;

  // @DataMember
  public direction: number;

  // @DataMember
  public type: MeteoStationType;

  // @DataMember
  public session: MeteoStationSession;

  // @DataMember
  public sensors: MeteoSensor[];

  // @DataMember
  public customerId: string;

  // @DataMember
  public customerName: string;

  // @DataMember
  public externalSystemId?: string;

  // @DataMember
  public externalSystemName: string;

  // @DataMember
  public externalSystemEntityId?: string;

  // @DataMember
  public name: string;

  // @DataMember
  public description: string;

  // @DataMember
  public changeKey: string;

  // @DataMember
  public created: string;

  // @DataMember
  public createdBy: string;

  // @DataMember
  public modifiedBy: string;

  // @DataMember
  public modified: string;

  // @DataMember
  public id: string;

  // @DataMember
  public entityType: string;

  public constructor(init?: Partial<MeteoObjectInfo>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class GetMeteoStationsResponse implements IChangeKey {
  // @DataMember
  public items: MeteoStationInfo[];

  // @DataMember
  public total: number;

  // @DataMember
  public page: number;

  // @DataMember
  public itemsPerPage: number;

  // @DataMember
  public changeKey: string;

  public constructor(init?: Partial<GetMeteoStationsResponse>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class SessionInfo {
  // @DataMember
  public id: string;

  // @DataMember
  public lastReceived: string;

  // @DataMember
  public lastSent: string;

  // @DataMember
  public deviceNumber: string;

  // @DataMember
  public sessionId: string;

  // @DataMember
  public connectionTime: string;

  // @DataMember
  public receiverHost: string;

  // @DataMember
  public remoteAddress: string;

  // @DataMember
  public connected: boolean;

  // @DataMember
  public lastUpdated: string;

  // @DataMember
  public entityType: string;

  // @DataMember
  public nextCheckSession: string;

  public constructor(init?: Partial<SessionInfo>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class EntityIntegration implements IChangeKey, IDescription, IModified, IEntity {
  // @DataMember
  public entityId: string;

  // @DataMember
  public customerIntegrationId: string;

  // @DataMember
  public externalId: string;

  // @DataMember
  public settings: string;

  // @DataMember
  public integrationId: string;

  // @DataMember
  public integrationName: string;

  // @DataMember
  public integrationType: string;

  // @DataMember
  public description: string;

  // @DataMember
  public changeKey: string;

  // @DataMember
  public created: string;

  // @DataMember
  public createdBy: string;

  // @DataMember
  public modifiedBy: string;

  // @DataMember
  public modified: string;

  // @DataMember
  public id: string;

  // @DataMember
  public entityType: string;

  public constructor(init?: Partial<EntityIntegration>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class GetEntityIntegrationResponse implements IChangeKey {
  // @DataMember
  public items: EntityIntegration[];

  // @DataMember
  public total: number;

  // @DataMember
  public page: number;

  // @DataMember
  public itemsPerPage: number;

  // @DataMember
  public changeKey: string;

  public constructor(init?: Partial<GetEntityIntegrationResponse>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class GetEntitiesResponse implements IChangeKey {
  // @DataMember
  public items: Object[];

  // @DataMember
  public total: number;

  // @DataMember
  public page: number;

  // @DataMember
  public itemsPerPage: number;

  // @DataMember
  public changeKey: string;

  public constructor(init?: Partial<GetEntitiesResponse>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class AuthenticateResponse implements IHasSessionId, IHasBearerToken {
  // @DataMember(Order=1)
  public userId: string;

  // @DataMember(Order=2)
  public sessionId: string;

  // @DataMember(Order=3)
  public userName: string;

  // @DataMember(Order=4)
  public displayName: string;

  // @DataMember(Order=5)
  public referrerUrl: string;

  // @DataMember(Order=6)
  public bearerToken: string;

  // @DataMember(Order=7)
  public refreshToken: string;

  // @DataMember(Order=8)
  public profileUrl: string;

  // @DataMember(Order=9)
  public roles: string[];

  // @DataMember(Order=10)
  public permissions: string[];

  // @DataMember(Order=11)
  public responseStatus: ResponseStatus;

  // @DataMember(Order=12)
  public meta: { [index: string]: string };

  public constructor(init?: Partial<AuthenticateResponse>) {
    (Object as any).assign(this, init);
  }
}

// @Route("/customer/{customerId}/meteoObject/{id}/report", "GET")
// @DataContract
export class GetMeteoObjectReport
  implements IReturn<Uint8Array>, ICustomerItemRequest, IBaseEntityItemRequest
{
  // @DataMember(IsRequired=true)
  public from: string;

  // @DataMember(IsRequired=true)
  public to: string;

  // @DataMember
  public sensorType: MeteoSensorType;

  // @DataMember
  public measureParameterIds: string[];

  // @DataMember
  public timeZoneOffsetFromUtc: number;

  // @DataMember
  public reportType: MeteoObjectReportType;

  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember(IsRequired=true)
  public id: string;

  public constructor(init?: Partial<GetMeteoObjectReport>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new Uint8Array(0);
  }
  public getTypeName() {
    return 'GetMeteoObjectReport';
  }
}

// @Route("/customer/{customerId}/meteoObject/{id}/shortForecast/airParameters", "GET")
// @DataContract
export class GetAirParametersForecast
  implements IReturn<AirParametersForecast>, ICustomerItemRequest, IBaseEntityItemRequest
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember(IsRequired=true)
  public id: string;

  public constructor(init?: Partial<GetAirParametersForecast>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new AirParametersForecast();
  }
  public getTypeName() {
    return 'GetAirParametersForecast';
  }
}

// @Route("/customer/{customerId}/meteoObject/{id}/shortForecast/precipitation", "GET")
// @DataContract
export class GetPrecipitationForecast
  implements IReturn<PrecipitationForecast>, ICustomerItemRequest, IBaseEntityItemRequest
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember(IsRequired=true)
  public id: string;

  public constructor(init?: Partial<GetPrecipitationForecast>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new PrecipitationForecast();
  }
  public getTypeName() {
    return 'GetPrecipitationForecast';
  }
}

// @Route("/customer/{customerId}/meteoObject/{id}/shortForecast/frostsProbability", "GET")
// @DataContract
export class GetFrostsProbabilityForecast
  implements IReturn<FrostsProbabilityForecast>, ICustomerItemRequest, IBaseEntityItemRequest
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember(IsRequired=true)
  public id: string;

  public constructor(init?: Partial<GetFrostsProbabilityForecast>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new FrostsProbabilityForecast();
  }
  public getTypeName() {
    return 'GetFrostsProbabilityForecast';
  }
}

// @Route("/customer/{customerId}/meteoObject/{id}/meteoParameterAnalysis", "GET")
// @DataContract
export class GetMeteoParameterAnalysis
  implements IReturn<MeteoParameterAnalysis[]>, ICustomerItemRequest, IBaseEntityItemRequest
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember(IsRequired=true)
  public id: string;

  public constructor(init?: Partial<GetMeteoParameterAnalysis>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new Array<MeteoParameterAnalysis>();
  }
  public getTypeName() {
    return 'GetMeteoParameterAnalysis';
  }
}

// @Route("/ecoParameterAnalysis/ecoProbability", "GET")
// @DataContract
export class GetEcoParameterForecast
  implements IReturn<EcoParameterForecast>, ICustomerItemRequest, IBaseEntityItemRequest
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember(IsRequired=true)
  public id: string;

  public constructor(init?: Partial<GetEcoParameterForecast>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new EcoParameterForecast();
  }
  public getTypeName() {
    return 'GetEcoParameterForecast';
  }
}

// @Route("/customer/{customerId}/meteoObject/{id}/forecast/roadServicesInformation", "GET")
// @DataContract
export class GetRoadServicesInformationRequest
  implements IReturn<RoadServicesInformation>, ICustomerItemRequest, IBaseEntityItemRequest
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember(IsRequired=true)
  public id: string;

  public constructor(init?: Partial<GetRoadServicesInformationRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new RoadServicesInformation();
  }
  public getTypeName() {
    return 'GetRoadServicesInformationRequest';
  }
}

// @Route("/forecast/latestMeteoData", "GET")
// @DataContract
export class GetLatestMeteoDataRequest implements IReturn<LatestMeteoData[]> {
  // @DataMember(IsRequired=true)
  public meteoStationIds: string[];

  public constructor(init?: Partial<GetLatestMeteoDataRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new Array<LatestMeteoData>();
  }
  public getTypeName() {
    return 'GetLatestMeteoDataRequest';
  }
}

// @Route("/forecast/ecoInfoSummary", "GET")
// @DataContract
export class GetEcoInfoSummaryRequest implements IReturn<EcoInfoSummary> {
  // @DataMember(IsRequired=true)
  public meteoStationIds: string[];

  public constructor(init?: Partial<GetEcoInfoSummaryRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new EcoInfoSummary();
  }
  public getTypeName() {
    return 'GetEcoInfoSummaryRequest';
  }
}

// @Route("/forecast/meteoInfoSummary", "GET")
// @DataContract
export class GetMeteoInfoSummaryRequest implements IReturn<MeteoInfoSummary> {
  // @DataMember(IsRequired=true)
  public meteoStationIds: string[];

  public constructor(init?: Partial<GetMeteoInfoSummaryRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new MeteoInfoSummary();
  }
  public getTypeName() {
    return 'GetMeteoInfoSummaryRequest';
  }
}

// @DataContract
export class SetMeteoObjectReplacementRequest
  implements
    IReturn<MeteoObjectReplacement>,
    ICustomerItemRequest,
    IBaseEntityItemRequest,
    IDescription
{
  // @DataMember
  public setMeteoObjectCoordsToDevice: boolean;

  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public updateEntityGroups: boolean;

  // @DataMember
  public entityGroups: string[];

  // @DataMember(IsRequired=true)
  public id: string;

  // @DataMember(IsRequired=true)
  public entity: MeteoObjectReplacement;

  // @DataMember
  public description: string;

  public constructor(init?: Partial<SetMeteoObjectReplacementRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new MeteoObjectReplacement();
  }
  public getTypeName() {
    return 'SetMeteoObjectReplacementRequest';
  }
}

// @Route("/customer/{customerId}/meteoObjectsReplacements", "GET")
// @DataContract
export class GetMeteoObjectReplacementsRequest
  implements IReturn<MeteoObjectReplacementsResponse>, ICustomerItemRequest
{
  // @DataMember(IsRequired=true)
  public meteoObjectId: string;

  // @DataMember
  public from?: string;

  // @DataMember
  public to?: string;

  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public entityGroups: string[];

  // @DataMember
  public itemsIds: string[];

  // @DataMember
  public onlyOwnedItems: boolean;

  // @DataMember
  public page: number;

  // @DataMember
  public items: number;

  // @DataMember
  public sort: string;

  // @DataMember
  public filter: string;

  // @DataMember
  public propertiesTest: { [index: string]: number };

  public constructor(init?: Partial<GetMeteoObjectReplacementsRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new MeteoObjectReplacementsResponse();
  }
  public getTypeName() {
    return 'GetMeteoObjectReplacementsRequest';
  }
}

// @Route("/customer/{customerId}/meteoObjectsReplacements/{Id}", "GET")
// @DataContract
export class GetMeteoObjectReplacementRequest
  implements IReturn<MeteoObjectReplacement>, ICustomerItemRequest, IBaseEntityItemRequest
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember(IsRequired=true)
  public id: string;

  public constructor(init?: Partial<GetMeteoObjectReplacementRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new MeteoObjectReplacement();
  }
  public getTypeName() {
    return 'GetMeteoObjectReplacementRequest';
  }
}

// @Route("/meteoObjects/sensors", "GET")
// @DataContract
export class GetAvailableMeteoObjectSensorsRequest implements IReturn<MeteoSensorsResponse> {
  // @DataMember
  public meteoObjectId: string;

  // @DataMember
  public page: number;

  // @DataMember
  public items: number;

  // @DataMember
  public sort: string;

  // @DataMember
  public filter: string;

  // @DataMember
  public propertiesTest: { [index: string]: number };

  public constructor(init?: Partial<GetAvailableMeteoObjectSensorsRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new MeteoSensorsResponse();
  }
  public getTypeName() {
    return 'GetAvailableMeteoObjectSensorsRequest';
  }
}

// @Route("/meteoObject/{Id}/sensor/latestData", "GET")
// @DataContract
export class GetMeteoObjectSensorLatestDataRequest
  implements IReturn<MeteoParameterValue[]>, IBaseEntityItemRequest
{
  // @DataMember
  public sensorType?: MeteoSensorType;

  // @DataMember
  public measureParameterIds: string[];

  // @DataMember(IsRequired=true)
  public id: string;

  public constructor(init?: Partial<GetMeteoObjectSensorLatestDataRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new Array<MeteoParameterValue>();
  }
  public getTypeName() {
    return 'GetMeteoObjectSensorLatestDataRequest';
  }
}

// @Route("/meteoObject/{Id}/sensor/graph", "GET")
// @DataContract
export class GetMeteoObjectSensorDataGraphRequest
  implements IReturn<MeteoParameterGraph[]>, IBaseEntityItemRequest
{
  // @DataMember
  public smooth?: number;

  // @DataMember
  public sensorType: MeteoSensorType;

  // @DataMember
  public measureParameterIds: string[];

  // @DataMember(IsRequired=true)
  public id: string;

  // @DataMember(IsRequired=true)
  public startTime: string;

  // @DataMember(IsRequired=true)
  public endTime: string;

  // @DataMember
  public groupBySeconds: number;

  public constructor(init?: Partial<GetMeteoObjectSensorDataGraphRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new Array<MeteoParameterGraph>();
  }
  public getTypeName() {
    return 'GetMeteoObjectSensorDataGraphRequest';
  }
}

// @Route("/meteoObject/{Id}/sensor/table", "GET")
// @DataContract
export class GetMeteoObjectSensorDataTableRequest
  implements IReturn<MeteoParameterTable>, IBaseEntityItemRequest
{
  // @DataMember
  public sensorType: MeteoSensorType;

  // @DataMember
  public measureParameterId: string;

  // @DataMember(IsRequired=true)
  public id: string;

  // @DataMember(IsRequired=true)
  public startTime: string;

  // @DataMember(IsRequired=true)
  public endTime: string;

  // @DataMember
  public groupBySeconds: number;

  public constructor(init?: Partial<GetMeteoObjectSensorDataTableRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new MeteoParameterTable();
  }
  public getTypeName() {
    return 'GetMeteoObjectSensorDataTableRequest';
  }
}

// @Route("/customer/{customerId}/meteoObjects/groups", "GET")
// @DataContract
export class GetMeteoObjectGroupsRequest
  implements IReturn<GetEntityGroupsResponse>, ICustomerItemRequest
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public entityGroups: string[];

  // @DataMember
  public itemsIds: string[];

  // @DataMember
  public onlyOwnedItems: boolean;

  // @DataMember
  public page: number;

  // @DataMember
  public items: number;

  // @DataMember
  public sort: string;

  // @DataMember
  public filter: string;

  // @DataMember
  public propertiesTest: { [index: string]: number };

  public constructor(init?: Partial<GetMeteoObjectGroupsRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new GetEntityGroupsResponse();
  }
  public getTypeName() {
    return 'GetMeteoObjectGroupsRequest';
  }
}

// @Route("/customer/{customerId}/meteoObjects/groups/{id}", "GET")
// @DataContract
export class GetMeteoObjectGroupRequest
  implements IReturn<EntityGroup>, ICustomerItemRequest, IBaseEntityItemRequest
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember(IsRequired=true)
  public id: string;

  public constructor(init?: Partial<GetMeteoObjectGroupRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new EntityGroup();
  }
  public getTypeName() {
    return 'GetMeteoObjectGroupRequest';
  }
}

// @Route("/customer/{customerId}/meteoObjects/groups", "POST")
// @DataContract
export class CreateMeteoObjectGroupRequest
  implements IReturn<EntityGroup>, ICustomerItemRequest, IDescription
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public updateEntityGroups: boolean;

  // @DataMember
  public entityGroups: string[];

  // @DataMember(IsRequired=true)
  public entity: EntityGroup;

  // @DataMember
  public description: string;

  public constructor(init?: Partial<CreateMeteoObjectGroupRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new EntityGroup();
  }
  public getTypeName() {
    return 'CreateMeteoObjectGroupRequest';
  }
}

// @Route("/customer/{customerId}/meteoObjects/groups/{id}", "PUT")
// @DataContract
export class UpdateMeteoObjectGroupRequest
  implements IReturn<EntityGroup>, ICustomerItemRequest, IBaseEntityItemRequest, IDescription
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public updateEntityGroups: boolean;

  // @DataMember
  public entityGroups: string[];

  // @DataMember(IsRequired=true)
  public id: string;

  // @DataMember(IsRequired=true)
  public entity: EntityGroup;

  // @DataMember
  public description: string;

  public constructor(init?: Partial<UpdateMeteoObjectGroupRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new EntityGroup();
  }
  public getTypeName() {
    return 'UpdateMeteoObjectGroupRequest';
  }
}

// @Route("/customer/{customerId}/meteoObjects/groups", "DELETE")
// @DataContract
export class DeleteMeteoObjectGroupsRequest
  implements IReturn<number>, ICustomerItemRequest, IDescription
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public description: string;

  // @DataMember(IsRequired=true)
  public ids: string[];

  public constructor(init?: Partial<DeleteMeteoObjectGroupsRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return 0;
  }
  public getTypeName() {
    return 'DeleteMeteoObjectGroupsRequest';
  }
}

// @Route("/customer/{customerId}/meteoObjects/groups/{id}", "GET")
// @DataContract
export class GetMeteoObjectGroupSharesRequest
  implements IReturn<GetEntityGroupSharesResponse>, IBaseEntityItemRequest, ICustomerItemRequest
{
  // @DataMember
  public id: string;

  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public entityGroups: string[];

  // @DataMember
  public itemsIds: string[];

  // @DataMember
  public onlyOwnedItems: boolean;

  // @DataMember
  public page: number;

  // @DataMember
  public items: number;

  // @DataMember
  public sort: string;

  // @DataMember
  public filter: string;

  // @DataMember
  public propertiesTest: { [index: string]: number };

  public constructor(init?: Partial<GetMeteoObjectGroupSharesRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new GetEntityGroupSharesResponse();
  }
  public getTypeName() {
    return 'GetMeteoObjectGroupSharesRequest';
  }
}

// @Route("/customer/{customerId}/meteoObjects/groups/{id}", "PUT")
// @DataContract
export class UpdateMeteoObjectGroupShareRequest
  implements IReturn<EntityGroupPermissions>, ICustomerItemRequest, IDescription
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember(IsRequired=true)
  public id: string;

  // @DataMember(IsRequired=true)
  public entity: EntityGroupPermissions;

  // @DataMember
  public description: string;

  public constructor(init?: Partial<UpdateMeteoObjectGroupShareRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new EntityGroupPermissions();
  }
  public getTypeName() {
    return 'UpdateMeteoObjectGroupShareRequest';
  }
}

// @Route("/customer/{customerId}/meteoObjects", "GET")
// @DataContract
export class GetMeteoObjectsRequest implements IReturn<MeteoObjectsResponse>, ICustomerItemRequest {
  // @DataMember
  public lat?: number;

  // @DataMember
  public lon?: number;

  // @DataMember
  public distance?: number;

  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public entityGroups: string[];

  // @DataMember
  public itemsIds: string[];

  // @DataMember
  public onlyOwnedItems: boolean;

  // @DataMember
  public page: number;

  // @DataMember
  public items: number;

  // @DataMember
  public sort: string;

  // @DataMember
  public filter: string;

  // @DataMember
  public propertiesTest: { [index: string]: number };

  public constructor(init?: Partial<GetMeteoObjectsRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new MeteoObjectsResponse();
  }
  public getTypeName() {
    return 'GetMeteoObjectsRequest';
  }
}

// @Route("/customer/{customerId}/meteoObject/{id}", "GET")
// @DataContract
export class GetMeteoObjectRequest
  implements IReturn<MeteoObject>, ICustomerItemRequest, IBaseEntityItemRequest
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember(IsRequired=true)
  public id: string;

  public constructor(init?: Partial<GetMeteoObjectRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new MeteoObject();
  }
  public getTypeName() {
    return 'GetMeteoObjectRequest';
  }
}

// @Route("/customer/{customerId}/meteoObjects", "POST")
// @DataContract
export class CreateMeteoObjectRequest
  implements IReturn<MeteoObject>, ICustomerItemRequest, IDescription
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public updateEntityGroups: boolean;

  // @DataMember
  public entityGroups: string[];

  // @DataMember(IsRequired=true)
  public entity: MeteoObject;

  // @DataMember
  public description: string;

  public constructor(init?: Partial<CreateMeteoObjectRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new MeteoObject();
  }
  public getTypeName() {
    return 'CreateMeteoObjectRequest';
  }
}

// @Route("/customer/{customerId}/meteoObject/{id}", "PUT")
// @DataContract
export class UpdateMeteoObjectRequest
  implements IReturn<MeteoObject>, ICustomerItemRequest, IBaseEntityItemRequest, IDescription
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public updateEntityGroups: boolean;

  // @DataMember
  public entityGroups: string[];

  // @DataMember(IsRequired=true)
  public id: string;

  // @DataMember(IsRequired=true)
  public entity: MeteoObject;

  // @DataMember
  public description: string;

  public constructor(init?: Partial<UpdateMeteoObjectRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new MeteoObject();
  }
  public getTypeName() {
    return 'UpdateMeteoObjectRequest';
  }
}

// @Route("/customer/{customerId}/meteoObjects/{Ids}", "DELETE")
// @DataContract
export class DeleteMeteoObjectsRequest
  implements IReturn<number>, ICustomerItemRequest, IDescription
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public description: string;

  // @DataMember(IsRequired=true)
  public ids: string[];

  public constructor(init?: Partial<DeleteMeteoObjectsRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return 0;
  }
  public getTypeName() {
    return 'DeleteMeteoObjectsRequest';
  }
}

// @Route("/customer/{customerId}/meteoObjects/exist", "GET")
// @DataContract
export class GetMeteoObjectExistRequest implements IReturn<boolean> {
  // @DataMember(IsRequired=true)
  public name: string;

  public constructor(init?: Partial<GetMeteoObjectExistRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return false;
  }
  public getTypeName() {
    return 'GetMeteoObjectExistRequest';
  }
}

// @Route("/customer/{customerId}/meteoObject/{id}/changeCustomer", "POST")
// @DataContract
export class MeteoObjectChangeCustomerRequest
  implements IReturn<boolean>, IDescription, ICustomerItemRequest, IBaseEntityItemRequest
{
  // @DataMember
  public description: string;

  // @DataMember
  public newCustomerId: string;

  // @DataMember
  public customerId: string;

  // @DataMember
  public id: string;

  public constructor(init?: Partial<MeteoObjectChangeCustomerRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return false;
  }
  public getTypeName() {
    return 'MeteoObjectChangeCustomerRequest';
  }
}

// @Route("/customer/{customerId}/meteoObject/{id}/currentUserPermissions", "GET")
// @DataContract
export class GetMeteoObjectCurrentUserPermissionsRequest
  implements IReturn<string[]>, ICustomerItemRequest, IBaseEntityItemRequest
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember(IsRequired=true)
  public id: string;

  public constructor(init?: Partial<GetMeteoObjectCurrentUserPermissionsRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new Array<string>();
  }
  public getTypeName() {
    return 'GetMeteoObjectCurrentUserPermissionsRequest';
  }
}

// @Route("/customer/{customerId}/meteoObjectsState", "GET")
// @DataContract
export class GetMeteoObjectStatusesRequest
  implements IReturn<MeteoObjectStatusesResponse>, ICustomerItemRequest
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public entityGroups: string[];

  // @DataMember
  public itemsIds: string[];

  // @DataMember
  public onlyOwnedItems: boolean;

  // @DataMember
  public page: number;

  // @DataMember
  public items: number;

  // @DataMember
  public sort: string;

  // @DataMember
  public filter: string;

  // @DataMember
  public propertiesTest: { [index: string]: number };

  public constructor(init?: Partial<GetMeteoObjectStatusesRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new MeteoObjectStatusesResponse();
  }
  public getTypeName() {
    return 'GetMeteoObjectStatusesRequest';
  }
}

// @Route("/customer/{customerId}/meteoObjects/info/{Id}", "GET")
// @DataContract
export class GetMeteoObjectInfoRequest
  implements IReturn<MeteoObjectInfo>, ICustomerItemRequest, IBaseEntityItemRequest
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember(IsRequired=true)
  public id: string;

  public constructor(init?: Partial<GetMeteoObjectInfoRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new MeteoObjectInfo();
  }
  public getTypeName() {
    return 'GetMeteoObjectInfoRequest';
  }
}

// @Route("/customer/{customerId}/meteoObjects/image", "GET")
// @DataContract
export class GetMeteoObjectImageRequest implements IReturn<Uint8Array>, IBaseEntityItemRequest {
  // @DataMember(IsRequired=true)
  public id: string;

  // @DataMember
  public customerId: string;

  // @DataMember
  public forceUpdate: boolean;

  public constructor(init?: Partial<GetMeteoObjectImageRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new Uint8Array(0);
  }
  public getTypeName() {
    return 'GetMeteoObjectImageRequest';
  }
}

// @Route("/meteoObjects/sendCommand", "POST")
// @DataContract
export class SendMeteoObjectCameraCommand implements IReturn<Uint8Array>, IBaseEntityItemRequest {
  // @DataMember(IsRequired=true)
  public id: string;

  // @DataMember
  public cameraCommand: CameraCommand;

  public constructor(init?: Partial<SendMeteoObjectCameraCommand>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new Uint8Array(0);
  }
  public getTypeName() {
    return 'SendMeteoObjectCameraCommand';
  }
}

// @Route("/customer/{customerId}/meteoObject/{id}/setStation", "POST")
// @DataContract
export class SetMeteoStationRequest
  implements IReturn<MeteoObject>, ICustomerItemRequest, IBaseEntityItemRequest, IDescription
{
  // @DataMember
  public id: string;

  // @DataMember
  public customerId: string;

  // @DataMember
  public newMeteoStationId: string;

  // @DataMember
  public description: string;

  public constructor(init?: Partial<SetMeteoStationRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new MeteoObject();
  }
  public getTypeName() {
    return 'SetMeteoStationRequest';
  }
}

// @Route("/customer/{customerId}/meteoObject/{id}/meteoStations/sensors", "GET")
// @DataContract
export class GetAvailableMeteoSensorsRequest
  implements IReturn<MeteoSensor[]>, ICustomerItemRequest, IBaseEntityItemRequest
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember(IsRequired=true)
  public id: string;

  public constructor(init?: Partial<GetAvailableMeteoSensorsRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new Array<MeteoSensor>();
  }
  public getTypeName() {
    return 'GetAvailableMeteoSensorsRequest';
  }
}

// @Route("/meteoObject/{Id}/sensor/predictionData", "GET")
// @DataContract
export class GetMeteoObjectSensorPredictionDataRequest
  implements IReturn<MeteoParameterValue[]>, IBaseEntityItemRequest
{
  // @DataMember
  public sensorType?: MeteoSensorType;

  // @DataMember
  public measureParameterIds: string[];

  // @DataMember
  public predictionTime: string;

  // @DataMember(IsRequired=true)
  public id: string;

  public constructor(init?: Partial<GetMeteoObjectSensorPredictionDataRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new Array<MeteoParameterValue>();
  }
  public getTypeName() {
    return 'GetMeteoObjectSensorPredictionDataRequest';
  }
}

// @Route("/meteoObject/{Id}/sensor/predictionGraph", "GET")
// @DataContract
export class GetMeteoObjectSensorPredictionDataGraphRequest
  implements IReturn<MeteoParameterGraph[]>, IBaseEntityItemRequest
{
  // @DataMember
  public smooth?: number;

  // @DataMember
  public sensorType: MeteoSensorType;

  // @DataMember
  public measureParameterIds: string[];

  // @DataMember(IsRequired=true)
  public id: string;

  // @DataMember(IsRequired=true)
  public startTime: string;

  // @DataMember(IsRequired=true)
  public endTime: string;

  // @DataMember
  public groupBySeconds: number;

  public constructor(init?: Partial<GetMeteoObjectSensorPredictionDataGraphRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new Array<MeteoParameterGraph>();
  }
  public getTypeName() {
    return 'GetMeteoObjectSensorPredictionDataGraphRequest';
  }
}

// @Route("/meteoObject/{Id}/sensor/predictionTable", "GET")
// @DataContract
export class GetMeteoObjectSensorPredictionDataTableRequest
  implements IReturn<MeteoParameterTable>, IBaseEntityItemRequest
{
  // @DataMember
  public sensorType: MeteoSensorType;

  // @DataMember
  public measureParameterId: string;

  // @DataMember(IsRequired=true)
  public id: string;

  // @DataMember(IsRequired=true)
  public startTime: string;

  // @DataMember(IsRequired=true)
  public endTime: string;

  // @DataMember
  public groupBySeconds: number;

  public constructor(init?: Partial<GetMeteoObjectSensorPredictionDataTableRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new MeteoParameterTable();
  }
  public getTypeName() {
    return 'GetMeteoObjectSensorPredictionDataTableRequest';
  }
}

// @Route("/meteoStation/{Id}/sensor/latestData", "GET")
// @DataContract
export class GetMeteoStationSensorLatestDataRequest
  implements IReturn<MeteoParameterValue[]>, IBaseEntityItemRequest
{
  // @DataMember
  public sensorType?: MeteoSensorType;

  // @DataMember
  public measureParameterIds: string[];

  // @DataMember(IsRequired=true)
  public id: string;

  public constructor(init?: Partial<GetMeteoStationSensorLatestDataRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new Array<MeteoParameterValue>();
  }
  public getTypeName() {
    return 'GetMeteoStationSensorLatestDataRequest';
  }
}

// @Route("/meteoStation/{Id}/sensor/graph", "GET")
// @DataContract
export class GetMeteoStationSensorDataGraphRequest
  implements IReturn<MeteoParameterGraph[]>, IBaseEntityItemRequest
{
  // @DataMember
  public smooth?: number;

  // @DataMember
  public sensorType: MeteoSensorType;

  // @DataMember
  public measureParameterIds: string[];

  // @DataMember(IsRequired=true)
  public id: string;

  // @DataMember(IsRequired=true)
  public startTime: string;

  // @DataMember(IsRequired=true)
  public endTime: string;

  // @DataMember
  public groupBySeconds: number;

  public constructor(init?: Partial<GetMeteoStationSensorDataGraphRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new Array<MeteoParameterGraph>();
  }
  public getTypeName() {
    return 'GetMeteoStationSensorDataGraphRequest';
  }
}

// @Route("/meteoStation/{Id}/sensor/errors", "GET")
// @DataContract
export class GetMeteoSensorErrorsHistoryRequest
  implements IReturn<TableValue[]>, IBaseEntityItemRequest
{
  // @DataMember(IsRequired=true)
  public errorId: string;

  // @DataMember(IsRequired=true)
  public id: string;

  // @DataMember(IsRequired=true)
  public startTime: string;

  // @DataMember(IsRequired=true)
  public endTime: string;

  // @DataMember
  public groupBySeconds: number;

  public constructor(init?: Partial<GetMeteoSensorErrorsHistoryRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new Array<TableValue>();
  }
  public getTypeName() {
    return 'GetMeteoSensorErrorsHistoryRequest';
  }
}

// @Route("/customer/{customerId}/meteoStations/exist", "GET")
// @DataContract
export class GetMeteoStationExistRequest implements IReturn<boolean> {
  // @DataMember(IsRequired=true)
  public type: MeteoStationType;

  // @DataMember(IsRequired=true)
  public number: string;

  public constructor(init?: Partial<GetMeteoStationExistRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return false;
  }
  public getTypeName() {
    return 'GetMeteoStationExistRequest';
  }
}

// @Route("/customer/{customerId}/meteoStations", "GET")
// @DataContract
export class GetMeteoStationsRequest
  implements IReturn<GetMeteoStationsResponse>, ICustomerItemRequest
{
  // @DataMember
  public onlyUsed: boolean;

  // @DataMember
  public onlyFree: boolean;

  // @DataMember
  public deviceType: MeteoStationType;

  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public entityGroups: string[];

  // @DataMember
  public itemsIds: string[];

  // @DataMember
  public onlyOwnedItems: boolean;

  // @DataMember
  public page: number;

  // @DataMember
  public items: number;

  // @DataMember
  public sort: string;

  // @DataMember
  public filter: string;

  // @DataMember
  public propertiesTest: { [index: string]: number };

  public constructor(init?: Partial<GetMeteoStationsRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new GetMeteoStationsResponse();
  }
  public getTypeName() {
    return 'GetMeteoStationsRequest';
  }
}

// @Route("/customer/{customerId}/meteoStation/{id}", "GET")
// @DataContract
export class GetMeteoStationRequest
  implements IReturn<MeteoStation>, ICustomerItemRequest, IBaseEntityItemRequest
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember(IsRequired=true)
  public id: string;

  public constructor(init?: Partial<GetMeteoStationRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new MeteoStation();
  }
  public getTypeName() {
    return 'GetMeteoStationRequest';
  }
}

// @Route("/customer/{customerId}/meteoStation/{id}", "PUT")
// @DataContract
export class UpdateMeteoStationRequest
  implements IReturn<MeteoStation>, ICustomerItemRequest, IBaseEntityItemRequest, IDescription
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public updateEntityGroups: boolean;

  // @DataMember
  public entityGroups: string[];

  // @DataMember(IsRequired=true)
  public id: string;

  // @DataMember(IsRequired=true)
  public entity: MeteoStation;

  // @DataMember
  public description: string;

  public constructor(init?: Partial<UpdateMeteoStationRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new MeteoStation();
  }
  public getTypeName() {
    return 'UpdateMeteoStationRequest';
  }
}

// @Route("/customer/{customerId}/meteoStations", "POST")
// @DataContract
export class CreateMeteoStationRequest
  implements IReturn<MeteoStation>, ICustomerItemRequest, IDescription
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public updateEntityGroups: boolean;

  // @DataMember
  public entityGroups: string[];

  // @DataMember(IsRequired=true)
  public entity: MeteoStation;

  // @DataMember
  public description: string;

  public constructor(init?: Partial<CreateMeteoStationRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new MeteoStation();
  }
  public getTypeName() {
    return 'CreateMeteoStationRequest';
  }
}

// @Route("/customer/{customerId}/meteoStations/{Ids}", "DELETE")
// @DataContract
export class DeleteMeteoStationsRequest
  implements IReturn<number>, ICustomerItemRequest, IDescription
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public description: string;

  // @DataMember(IsRequired=true)
  public ids: string[];

  public constructor(init?: Partial<DeleteMeteoStationsRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return 0;
  }
  public getTypeName() {
    return 'DeleteMeteoStationsRequest';
  }
}

// @Route("/customer/{customerId}/meteoStation/{id}/disconnect", "POST")
// @DataContract
export class DisconnectMeteoStationRequest
  implements IReturn<SessionInfo>, ICustomerItemRequest, IBaseEntityItemRequest
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember(IsRequired=true)
  public id: string;

  public constructor(init?: Partial<DisconnectMeteoStationRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new SessionInfo();
  }
  public getTypeName() {
    return 'DisconnectMeteoStationRequest';
  }
}

// @Route("/customer/{customerId}/meteoStation/{id}/changeCustomer", "POST")
// @DataContract
export class MeteoStationChangeCustomerRequest
  implements IReturn<boolean>, IDescription, ICustomerItemRequest, IBaseEntityItemRequest
{
  // @DataMember
  public description: string;

  // @DataMember
  public newCustomerId: string;

  // @DataMember
  public customerId: string;

  // @DataMember
  public id: string;

  public constructor(init?: Partial<MeteoStationChangeCustomerRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return false;
  }
  public getTypeName() {
    return 'MeteoStationChangeCustomerRequest';
  }
}

// @Route("/customer/{customerId}/meteoStations/sensors/{Id}", "GET")
// @DataContract
export class GetMeteoStationSensorsRequest
  implements IReturn<MeteoSensor[]>, ICustomerItemRequest, IBaseEntityItemRequest
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember(IsRequired=true)
  public id: string;

  public constructor(init?: Partial<GetMeteoStationSensorsRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new Array<MeteoSensor>();
  }
  public getTypeName() {
    return 'GetMeteoStationSensorsRequest';
  }
}

// @Route("/customer/{customerId}/meteoStations/sessionInfo/{Id}", "GET")
// @DataContract
export class GetMeteoStationSessionInfoRequest
  implements IReturn<MeteoStationSession>, ICustomerItemRequest, IBaseEntityItemRequest
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember(IsRequired=true)
  public id: string;

  public constructor(init?: Partial<GetMeteoStationSessionInfoRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new MeteoStationSession();
  }
  public getTypeName() {
    return 'GetMeteoStationSessionInfoRequest';
  }
}

// @Route("/customer/{customerId}/meteoStations/image", "GET")
// @DataContract
export class GetMeteoStationImageRequest
  implements IReturn<Uint8Array>, ICustomerItemRequest, IBaseEntityItemRequest
{
  // @DataMember
  public forceUpdate: boolean;

  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember(IsRequired=true)
  public id: string;

  public constructor(init?: Partial<GetMeteoStationImageRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new Uint8Array(0);
  }
  public getTypeName() {
    return 'GetMeteoStationImageRequest';
  }
}

// @Route("/healthStatus", "GET")
// @DataContract
export class GetHealthStatusRequest implements IReturn<string> {
  public constructor(init?: Partial<GetHealthStatusRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return '';
  }
  public getTypeName() {
    return 'GetHealthStatusRequest';
  }
}

// @Route("/customer/{customerId}/integration/{customerIntegrationId}/entities", "GET")
// @DataContract
export class GetEntityIntegrationsRequest
  implements
    IReturn<GetEntityIntegrationResponse>,
    ICustomerIntegrationIdRequest,
    ICustomerItemRequest
{
  // @DataMember
  public customerIntegrationId: string;

  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public entityGroups: string[];

  // @DataMember
  public itemsIds: string[];

  // @DataMember
  public onlyOwnedItems: boolean;

  // @DataMember
  public page: number;

  // @DataMember
  public items: number;

  // @DataMember
  public sort: string;

  // @DataMember
  public filter: string;

  // @DataMember
  public propertiesTest: { [index: string]: number };

  public constructor(init?: Partial<GetEntityIntegrationsRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new GetEntityIntegrationResponse();
  }
  public getTypeName() {
    return 'GetEntityIntegrationsRequest';
  }
}

// @Route("/customer/{customerId}/integration/{customerIntegrationId}/entity/{id}", "GET")
// @DataContract
export class GetEntityIntegrationRequest
  implements
    IReturn<EntityIntegration>,
    ICustomerIntegrationIdRequest,
    ICustomerItemRequest,
    IBaseEntityItemRequest
{
  // @DataMember
  public customerIntegrationId: string;

  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember(IsRequired=true)
  public id: string;

  public constructor(init?: Partial<GetEntityIntegrationRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new EntityIntegration();
  }
  public getTypeName() {
    return 'GetEntityIntegrationRequest';
  }
}

// @Route("/customer/{customerId}/integration/{customerIntegrationId}/entities", "POST")
// @DataContract
export class CreateEntityIntegrationRequest
  implements
    IReturn<EntityIntegration>,
    ICustomerIntegrationIdRequest,
    ICustomerItemRequest,
    IDescription
{
  // @DataMember
  public customerIntegrationId: string;

  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public updateEntityGroups: boolean;

  // @DataMember
  public entityGroups: string[];

  // @DataMember(IsRequired=true)
  public entity: EntityIntegration;

  // @DataMember
  public description: string;

  public constructor(init?: Partial<CreateEntityIntegrationRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new EntityIntegration();
  }
  public getTypeName() {
    return 'CreateEntityIntegrationRequest';
  }
}

// @Route("/customer/{customerId}/integration/{customerIntegrationId}/entity/{id}", "PUT")
// @DataContract
export class UpdateEntityIntegrationRequest
  implements
    IReturn<EntityIntegration>,
    ICustomerIntegrationIdRequest,
    ICustomerItemRequest,
    IBaseEntityItemRequest,
    IDescription
{
  // @DataMember
  public customerIntegrationId: string;

  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public updateEntityGroups: boolean;

  // @DataMember
  public entityGroups: string[];

  // @DataMember(IsRequired=true)
  public id: string;

  // @DataMember(IsRequired=true)
  public entity: EntityIntegration;

  // @DataMember
  public description: string;

  public constructor(init?: Partial<UpdateEntityIntegrationRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new EntityIntegration();
  }
  public getTypeName() {
    return 'UpdateEntityIntegrationRequest';
  }
}

// @Route("/entity/{id}/properties", "GET")
// @DataContract
export class GetEntityPropertiesRequest implements IReturn<any>, IBaseEntityItemRequest {
  // @DataMember(IsRequired=true)
  public id: string;

  public constructor(init?: Partial<GetEntityPropertiesRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return {};
  }
  public getTypeName() {
    return 'GetEntityPropertiesRequest';
  }
}

// @Route("/entity/{id}/properties", "PUT")
// @DataContract
export class UpdateEntityPropertiesRequest implements IReturn<any> {
  // @DataMember(IsRequired=true)
  public id: string;

  // @DataMember
  public description: string;

  // @DataMember
  public entity: { [index: string]: string };

  public constructor(init?: Partial<UpdateEntityPropertiesRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return {};
  }
  public getTypeName() {
    return 'UpdateEntityPropertiesRequest';
  }
}

// @Route("/entity/{id}/property/{property}", "GET")
// @DataContract
export class GetEntityPropertyRequest implements IReturn<string> {
  // @DataMember
  public property: string;

  // @DataMember(IsRequired=true)
  public id: string;

  public constructor(init?: Partial<GetEntityPropertyRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return '';
  }
  public getTypeName() {
    return 'GetEntityPropertyRequest';
  }
}

// @Route("/entity/{id}/property/{property}", "PUT")
// @DataContract
export class UpdateEntityPropertyRequest implements IReturn<string> {
  // @DataMember(IsRequired=true)
  public id: string;

  // @DataMember
  public description: string;

  // @DataMember
  public property: string;

  // @DataMember
  public value: string;

  public constructor(init?: Partial<UpdateEntityPropertyRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return '';
  }
  public getTypeName() {
    return 'UpdateEntityPropertyRequest';
  }
}

// @Route("/entities/property/{property}", "GET")
// @DataContract
export class GetEntitiesPropertyRequest implements IReturn<any> {
  // @DataMember
  public property: string;

  // @DataMember
  public filter: string;

  public constructor(init?: Partial<GetEntitiesPropertyRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return {};
  }
  public getTypeName() {
    return 'GetEntitiesPropertyRequest';
  }
}

// @Route("/customer/{customerId}/entity/{Id}/groups", "GET")
// @DataContract
export class GetItemEntityGroupsRequest
  implements IReturn<GetEntityGroupsResponse>, ICustomerItemRequest
{
  // @DataMember
  public id: string;

  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public entityGroups: string[];

  // @DataMember
  public itemsIds: string[];

  // @DataMember
  public onlyOwnedItems: boolean;

  // @DataMember
  public page: number;

  // @DataMember
  public items: number;

  // @DataMember
  public sort: string;

  // @DataMember
  public filter: string;

  // @DataMember
  public propertiesTest: { [index: string]: number };

  public constructor(init?: Partial<GetItemEntityGroupsRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new GetEntityGroupsResponse();
  }
  public getTypeName() {
    return 'GetItemEntityGroupsRequest';
  }
}

// @Route("/entities/remoteRepository/{repositoryId}/data", "GET")
// @DataContract
export class GetEntitiesRequest implements IReturn<GetEntitiesResponse> {
  // @DataMember
  public repositoryId: string;

  // @DataMember
  public modified?: string;

  // @DataMember
  public page: number;

  // @DataMember
  public items: number;

  // @DataMember
  public sort: string;

  // @DataMember
  public filter: string;

  // @DataMember
  public propertiesTest: { [index: string]: number };

  public constructor(init?: Partial<GetEntitiesRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new GetEntitiesResponse();
  }
  public getTypeName() {
    return 'GetEntitiesRequest';
  }
}

/**
 * Sign In
 */
// @Route("/auth", "OPTIONS,GET,POST,DELETE")
// @Route("/auth/{provider}", "OPTIONS,GET,POST,DELETE")
// @Api(Description="Sign In")
// @DataContract
export class Authenticate implements IReturn<AuthenticateResponse>, IPost {
  /**
   * AuthProvider, e.g. credentials
   */
  // @DataMember(Order=1)
  public provider: string;

  // @DataMember(Order=2)
  public state: string;

  // @DataMember(Order=3)
  public oauth_token: string;

  // @DataMember(Order=4)
  public oauth_verifier: string;

  // @DataMember(Order=5)
  public userName: string;

  // @DataMember(Order=6)
  public password: string;

  // @DataMember(Order=7)
  public rememberMe?: boolean;

  // @DataMember(Order=9)
  public errorView: string;

  // @DataMember(Order=10)
  public nonce: string;

  // @DataMember(Order=11)
  public uri: string;

  // @DataMember(Order=12)
  public response: string;

  // @DataMember(Order=13)
  public qop: string;

  // @DataMember(Order=14)
  public nc: string;

  // @DataMember(Order=15)
  public cnonce: string;

  // @DataMember(Order=17)
  public accessToken: string;

  // @DataMember(Order=18)
  public accessTokenSecret: string;

  // @DataMember(Order=19)
  public scope: string;

  // @DataMember(Order=20)
  public meta: { [index: string]: string };

  public constructor(init?: Partial<Authenticate>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new AuthenticateResponse();
  }
  public getTypeName() {
    return 'Authenticate';
  }
}
