import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';

import { AuthService } from 'projects/msu-its-web-common/src/services/auth.service';

const EPUTS_PATH = '/eputs';

@Injectable({ providedIn: 'root' })
export class EputsGuard implements CanActivate {
  constructor(private _router: Router, private _authService: AuthService) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (!this._authService.isLoggedIn) {
      this._router.navigate([EPUTS_PATH], {});
      return false;
    }
    return true;
  }
}
