import { Injectable, OnDestroy } from '@angular/core';

import { BehaviorSubject, EMPTY, Observable } from 'rxjs';
import { finalize, shareReplay } from 'rxjs/operators';

import { Parking, ParkingStatus } from 'projects/msu-its-web-parking/src/dtos/parking.dtos';

@Injectable()
export class MsuPirsStateService implements OnDestroy {
  modelId: string;
  customerId: string;

  private readonly _loading = new BehaviorSubject<boolean>(false);
  readonly loading$ = this._loading.pipe(shareReplay(1));
  get loading(): boolean {
    return this._loading.getValue();
  }
  set loading(value) {
    this._loading.next(value);
  }

  private readonly _parking = new BehaviorSubject<Parking>(null);
  readonly parking$ = this._parking.pipe(shareReplay(1));
  get parking(): Parking {
    return this._parking.getValue();
  }
  set parking(value) {
    this._parking.next(value);
  }

  private readonly _status = new BehaviorSubject<ParkingStatus>(null);
  readonly status$ = this._status.pipe(shareReplay(1));
  get status(): ParkingStatus {
    return this._status.getValue();
  }
  set status(value) {
    this._status.next(value);
  }

  constructor() {}

  ngOnDestroy() {
    this._parking.complete();
    this._status.complete();
  }

  loadingQuery<T>(query: Observable<T>) {
    if (this.loading) return EMPTY;
    this.loading = true;
    return query.pipe(finalize(() => (this.loading = false)));
  }
}
