import { marker as _ } from "@biesbjerg/ngx-translate-extract-marker";

import { NgModule } from "@angular/core";
import { MatIconRegistry } from "@angular/material/icon";
import { DomSanitizer } from "@angular/platform-browser";
import { DatePipe } from "@angular/common";

import { FtvApiService } from "../../services/ftv-api.service";
import { FtvApiEventService } from "../../services/ftv-api-event.service";

import { WeightControlPointDataService } from "../../services/weight-control-point-data/weight-control-point-data.service";

import { WeightControlPointService } from "../../services/weight-control-point/weight-control-point.service";
import { WeightControlPointGroupService } from "../../services/weight-control-point/weight-control-point-group.service";
import { WeightControlPointGroupShareService } from "../../services/weight-control-point/weight-control-point-group-share.service";
import { WeightControlPointGroupEventService } from "../../services/weight-control-point/weight-control-point-group-event.service";

import { FixationPointService } from "../../services/fixation-point/fixation-point.service";
import { FixationPointGroupService } from "../../services/fixation-point/fixation-point-group.service";
import { FixationPointGroupShareService } from "../../services/fixation-point/fixation-point-group-share.service";
import { FixationPointGroupEventService } from "../../services/fixation-point/fixation-point-group-event.service";
import { FixationDeviceService } from "../../services/fixation-device/fixation-device.service";
import { FixationEventService } from "../../services/fixation-event/fixation-event.service";
import { FixationPointLogComponent } from "../../components/fixation-point/fixation-point-log.component";
import { FixationPointPopupComponent } from "../../components/fixation-point/fixation-point-popup.component";
import { FixationEventsExternalComponent } from "../../components/external/fixation-events-external.component";
import { FixationPointLogService } from "../../services/fixation-point/fixation-point-log.service";
import { ISidenavMenuCategory } from "projects/msu-its-web-common/src/utils/sidenav-menu";
import { IGisObjectGroup } from "projects/msu-its-web-common/src/utils/gis-object-group";
import {
  GIS_OBJECT_GROUP,
  SIDENAV_MENU,
} from "projects/msu-its-web-common/src/utils/shared-consts";

import { BaseModule } from "projects/msu-its-web-common/src/modules/base.module";

const sidenav: ISidenavMenuCategory[] = [
  {
    name: _("SIDENAV_MENU.FTV"),
    items: [
      {
        name: _("FIXATING_TRAFFIC_VIOLATION.TITLE"),
        icon: "photo_camera",
        route: "fixation-traffic-violation",
        permissions: ["fixationPoint.read"],
      },
      {
        name: _("FIXATING_TRAFFIC_EVENTS.TITLE"),
        icon: "event_note",
        route: "fixation-traffic-events",
        permissions: ["fixationEvent.read"],
      },
      {
        name: _("WEIGHT_CONTROL_POINTS.TITLE"),
        icon: "crop_square",
        route: "weight-control-points",
        permissions: ["weightControlPoint.read"],
      },
    ],
  },
];

const transportObjectGroup: IGisObjectGroup = {
  type: "fixationPoint",
  name: _("FIXATING_TRAFFIC_VIOLATION.TITLE"),
  groupServiceType: FixationPointGroupService,
  eventServiceType: FixationPointGroupEventService,
  // stateComponentType: FixationPointPopupComponent,
  popupComponentType: FixationPointPopupComponent,
};

@NgModule({
  providers: [
    { provide: SIDENAV_MENU, useValue: sidenav, multi: true },
    { provide: GIS_OBJECT_GROUP, useValue: transportObjectGroup, multi: true },

    FtvApiService,
    FtvApiEventService,

    WeightControlPointDataService,

    WeightControlPointService,
    WeightControlPointGroupService,
    WeightControlPointGroupShareService,
    WeightControlPointGroupEventService,

    FixationPointService,
    FixationPointGroupService,
    FixationPointGroupShareService,
    FixationPointGroupEventService,
    FixationDeviceService,
    FixationPointLogService,

    FixationEventService,

    DatePipe,
  ],
  imports: [BaseModule],
  declarations: [
    FixationPointPopupComponent,
    FixationEventsExternalComponent,
    FixationPointLogComponent,
  ],
  entryComponents: [
    FixationPointPopupComponent,
    FixationEventsExternalComponent,
    FixationPointLogComponent,
  ],
  exports: [
    FixationPointPopupComponent,
    FixationEventsExternalComponent,
    FixationPointLogComponent,
  ],
})
export class FtvSharedModule {
  constructor(
    private _matIconRegistry: MatIconRegistry,
    private _domSanitizer: DomSanitizer
  ) {
    // this._registerIcon('ruis-route', '/assets/images/ruis/icons/route.svg');
  }

  // private _registerIcon(name: string, url: string) {
  //   this._matIconRegistry.addSvgIcon(name, this._domSanitizer.bypassSecurityTrustResourceUrl(url));
  // }
}
