import { Injectable } from '@angular/core';

import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import {
  IDataSourceService,
  setPageParams,
  getPageReturn,
  IPageParams,
  IPageReturn,
} from 'projects/msu-its-web-common/src/data/data-source';

import {
  CreateParkingKioskRequest,
  DeleteParkingKiosksRequest,
  ParkingKiosk,
  ParkingKioskInfo,
  GetParkingKioskRequest,
  GetParkingKiosksRequest,
  UpdateParkingKioskRequest,
  GetParkingKioskExistRequest,
  ParkingKioskChangeCustomerRequest,
  ParkingKioskType,
  SetParkingKioskOffModeRequest,
  SetParkingKioskWorkModeRequest,
} from '../dtos/parking.dtos';

import { ParkingApiService } from './parking-api.service';

@Injectable()
export class ParkingKioskService implements IDataSourceService<ParkingKiosk, ParkingKioskInfo> {
  constructor(private _api: ParkingApiService) {}

  getPagedList(params: IPageParams, customerId: string): Observable<IPageReturn<ParkingKioskInfo>> {
    if (!customerId) {
      return of(null);
    }

    const request = new GetParkingKiosksRequest();
    request.customerId = customerId;

    setPageParams(request, params);
    return this._api.get(request).pipe(
      map((m) => {
        return getPageReturn(m);
      })
    );
  }
  getList(
    customerId: string,
    filter?: string,
    groupIds?: string[]
  ): Observable<ParkingKioskInfo[]> {
    const request = new GetParkingKiosksRequest();
    request.customerId = customerId;
    request.entityGroups = groupIds || [];

    request.page = 0;
    request.items = 0;
    request.sort = 'name';
    request.filter = filter || '';

    return this._api.get(request).pipe(
      map((m) => {
        return m.items;
      })
    );
  }

  get(id: string, customerId: string) {
    const request = new GetParkingKioskRequest();
    request.id = id;
    request.customerId = customerId;
    return this._api.get(request);
  }
  add(
    model: ParkingKiosk,
    params: {
      customerId: string;
      groups: string[];
      updateGroups: boolean;
    }
  ) {
    const request = new CreateParkingKioskRequest();
    request.entity = model;
    request.customerId = params.customerId;
    request.entityGroups = params.groups;
    request.updateEntityGroups = params.updateGroups;
    return this._api.post(request);
  }
  update(
    model: ParkingKiosk,
    params: { customerId: string; groups: string[]; updateGroups: boolean }
  ) {
    const request = new UpdateParkingKioskRequest();
    request.id = model.id;
    request.entity = model;
    request.customerId = params.customerId;
    request.entityGroups = params.groups;
    request.updateEntityGroups = params.updateGroups;
    return this._api.put(request);
  }
  delete(ids: string[], customerId: string) {
    const request = new DeleteParkingKiosksRequest();
    request.ids = ids;
    request.customerId = customerId;
    return this._api.delete(request);
  }

  existNumber(type: ParkingKioskType, number: string, customerId: string) {
    const request = new GetParkingKioskExistRequest({ type, number, customerId } as any);
    return this._api.get(request);
  }
  existName(name: string, customerId: string) {
    return of(false);
  }

  changeCustomer(
    id: string,
    customerId: string,
    params: { newCustomerId: string; description: string }
  ) {
    const request = new ParkingKioskChangeCustomerRequest();
    request.id = id;
    request.customerId = customerId;
    request.description = params.description;
    request.newCustomerId = params.newCustomerId;
    return this._api.post(request);
  }

  setOffModel(id: string, customerId: string) {
    const request = new SetParkingKioskOffModeRequest({ id, customerId });
    return this._api.post(request);
  }
  setWorkModel(id: string, customerId: string) {
    const request = new SetParkingKioskWorkModeRequest({ id, customerId });
    return this._api.post(request);
  }

  // getPermissions(id: string, customerId: string) {
  //   const request = new GetParkingCurrentUserPermissionsRequest();
  //   request.id = id;
  //   request.customerId = customerId;
  //   return this._api.get(request);
  // }
}
