import { Component, ChangeDetectionStrategy } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'logout-component',
  templateUrl: './logout.component.html',
  styles: [``],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LogoutComponent {
  everywhere = true;

  constructor(
    private _dialogRef: MatDialogRef<LogoutComponent>,
    public translate: TranslateService
  ) {}

  logout() {
    this._dialogRef.close({ everywhere: this.everywhere });
  }
}
