import { Injectable } from "@angular/core";

import { Observable, of } from "rxjs";
import { map } from "rxjs/operators";

import {
  getPageReturn,
  IDataSourceService,
  IPageParams,
  IPageReturn,
  setPageParams,
} from "projects/msu-its-web-common/src/data/data-source";

import { TokenService } from "projects/msu-its-web-common/src/services/token.service";

import { IGisObjectGroupService } from "projects/msu-its-web-common/src/utils/gis-object-group";
import { CameraFTPService } from "./camera-ftp.service";
import { AccidentApiService } from "../accident-api.service";
import { CreateStaticCameraGroupRequest, DeleteStaticCameraGroupsRequest, EntityGroup, EntityGroupInfo, GetItemEntityGroupsRequest, GetStaticCameraGroupRequest, GetStaticCameraGroupsRequest, UpdateStaticCameraGroupRequest } from "../../dtos/accident.dtos";

@Injectable()
export class CameraFTPGroupService
  implements
    IDataSourceService<EntityGroup, EntityGroupInfo>,
    IGisObjectGroupService {
  constructor(
    private _api: AccidentApiService,
    private _tokenService: TokenService,
    private _cameraFtpService: CameraFTPService
  ) {}

  permissions = ['accidentPtzCamera.read'];

  getObjectGroups(objectId: string, customerId: string) {
    const request = new GetItemEntityGroupsRequest({
      id: objectId,
      customerId,
    });
    return this._api.get(request).pipe(map((m) => m.items));
  }

  getPagedList(params: IPageParams, customerId: string): Observable<IPageReturn<EntityGroupInfo>> {
    if (!customerId) {
      return of(null);
    }

    const request = new GetStaticCameraGroupsRequest();
    request.customerId = customerId;

    setPageParams(request, params);
    return this._api.get(request).pipe(
      map((m) => {
        return getPageReturn(m);
      })
    );
  }

  getList(customerId: string, filter?: string, owned = false) {
    const request = new GetStaticCameraGroupsRequest();
    request.customerId = customerId;

    request.page = 0;
    request.items = 0;
    request.sort = 'name';
    request.filter = filter;
    request.onlyOwnedItems = owned;

    return this._api.get(request).pipe(
      map((m) => {
        return m.items;
      })
    );
  }

  getGroupList(customerId: string) {
    return this.getList(customerId);
  }

  getObjectList(customerId: string, groupIds: string[]) {
    return this._cameraFtpService.getList(customerId, null, groupIds);
  }

  get(id: string, customerId: string) {
    const request = new GetStaticCameraGroupRequest();
    request.id = id;
    request.customerId = customerId;
    return this._api.get(request);
  }

  add(model: EntityGroup, params: { customerId: string }) {
    const request = new CreateStaticCameraGroupRequest();
    request.customerId = params.customerId;
    request.entity = model;
    return this._api.post(request);
  }

  update(model: EntityGroup, params: { customerId: string }) {
    const request = new UpdateStaticCameraGroupRequest();
    request.id = model.id;
    request.entity = model;
    request.customerId = params.customerId;
    return this._api.put(request);
  }

  delete(ids: string[], customerId: string): Observable<number> {
    const request = new DeleteStaticCameraGroupsRequest();
    request.ids = ids;
    request.customerId = customerId;
    return this._api.delete(request);
  }

  exist(name: string, customerId: string) {
    return of(false);
  }
}
