import {
  Component,
  ViewChild,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  OnDestroy,
  AfterViewInit,
} from '@angular/core';
import { MatSlideToggle } from '@angular/material/slide-toggle';
import { MatMenuTrigger } from '@angular/material/menu';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';

import { Subject, interval, timer, Observable } from 'rxjs';
import { finalize, takeUntil, takeWhile } from 'rxjs/operators';

// import { ProfileEditComponent } from './profile-edit.component';
// import { ChangePasswordComponent } from './change-password.component';
import { SelectLangComponent } from './select-lang.component';

import { User, Customer, AuditItemDetails } from '../../dtos/hub.dtos';
// import { UserService } from "../../services/user.service";

import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '../../services/auth.service';
import { TokenService } from '../../services/token.service';
import { FlashService } from '../../services/flash.service';
import { DialogService } from '../../services/dialog.service';
import { SidenavService } from '../../services/sidenav.service';
import { SettingsService } from '../../services/settings.service';
// import { NotifyService } from 'src/app/data/services/notify.service';

import { LANGS } from '../../utils/langs';
import { CustomerService } from '../../services/customer.service';
import { Title } from '@angular/platform-browser';
import { AuditLogService } from '../../services/audit-log.service';
import { PERMISSIONS_TRANSLATE } from '../../utils/permissions';
import { AboutDialogComponent } from '../../modules/dialog-module/about-dialog.component';
import { LogoutComponent } from './logout.component';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavbarComponent implements AfterViewInit, OnDestroy {
  time;
  title;

  notifyCount = 0;
  notifyLoading = false;
  notifyItems: AuditItemDetails[] = [];

  _destroy = new Subject();

  customer: Customer;

  get timezone() {
    return this._settingsService.timezone;
  }
  get locale() {
    return this.translate.currentLang;
  }

  @ViewChild('darkThemeToggle', { static: false })
  darkThemeToggle: MatSlideToggle;

  @ViewChild('notifyMenuTrigger', { static: false })
  notifyMenuTrigger: MatMenuTrigger;

  constructor(
    private _router: Router,
    private _route: ActivatedRoute,
    private _authService: AuthService,
    private _titleService: Title,
    // private _userService: UserService,
    private _auditService: AuditLogService,
    private _customerService: CustomerService,
    private _tokenService: TokenService,
    private _flashService: FlashService,
    private _dialogService: DialogService,
    private _sidenavService: SidenavService,
    private _settingsService: SettingsService,
    private _changeDetector: ChangeDetectorRef,
    public translate: TranslateService
  ) {
    translate.onLangChange
      .pipe(takeUntil(this._destroy))
      .subscribe(() => this._updateTitle());

    const customerId = this._route.snapshot.params.customerId;

    this._customerService.getList('').subscribe((customers) => {
      this.customer = customers?.find((m) => m.id == customerId);
      this._updateTitle();
    });

    // this._customerService.get(customerId).subscribe((customer) => {
    //   this.customer = customer;
    //   this._updateTitle();
    // });

    _router.events.pipe(takeUntil(this._destroy)).subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this._updateTitle();
      }
    });

    _tokenService.tokenChanged
      .pipe(takeUntil(this._destroy))
      .subscribe(() => this._changeDetector.markForCheck());

    _settingsService.darkThemeChanged
      .pipe(takeUntil(this._destroy))
      .subscribe((value) => (this.darkThemeToggle.checked = value));

    interval(1000)
      .pipe(takeUntil(this._destroy))
      .subscribe(() => {
        this.time = Date.now();
        this._changeDetector.markForCheck();
      });

    this.time = Date.now();
  }

  ngAfterViewInit() {
    this.darkThemeToggle.checked = this._settingsService.darkTheme;

    this.darkThemeToggle.change.pipe(takeUntil(this._destroy)).subscribe(() => {
      this._settingsService.darkTheme = this.darkThemeToggle.checked;
    });

    timer(10000, 60000)
      .pipe(takeUntil(this._destroy))
      .subscribe(() => {
        const from = this.notifyItems.length
          ? this.notifyItems[0].activityTime
          : null;
        this._auditService
          .getList(null, null, from, null, 1)
          .subscribe((result) => {
            if (result.length && result[0].activityTime != from) {
              this.notifyCount = result.length;
              this._changeDetector.markForCheck();
            }
          });
      });

    this.notifyMenuTrigger.menuOpened
      .pipe(takeUntil(this._destroy))
      .subscribe(() => {
        timer(0, 60000)
          .pipe(takeUntil(this._destroy))
          .pipe(takeWhile(() => this.notifyMenuTrigger.menuOpen))
          .subscribe(() => {
            if (this.notifyCount > 0 || this.notifyItems.length === 0) {
              this.loadNotifyItems();
            }
          });
      });
  }

  ngOnDestroy() {
    this._destroy.next();
  }

  editProfile() {
    // this._userService.getProfile().subscribe((model) => {
    //   const dialog = this._dialogService.dialog.open<ProfileEditComponent>(
    //     ProfileEditComponent,
    //     { disableClose: true }
    //   );
    //   dialog.componentInstance.initForm(model, this._userService, dialog);
    //   dialog.afterClosed().subscribe((result) => {
    //     if (result === true) {
    //       this._flashService.success(
    //         this.translate.instant('PROFILE.PROFILE_CHANGED')
    //       );
    //       this._userService.getProfile().subscribe();
    //     }
    //   });
    // });
  }

  changePassword() {
    // const dialog = this._dialogService.dialog.open<ChangePasswordComponent>(
    //   ChangePasswordComponent,
    //   { disableClose: true }
    // );
    // dialog.componentInstance.initForm(new User(), this._userService, dialog);
    // dialog.afterClosed().subscribe((result) => {
    //   if (result === true) {
    //     this._flashService.success(
    //       this.translate.instant('PROFILE.PASSWORD_CHANGED')
    //     );
    //   }
    // });
  }

  selectLang() {
    const dialog = this._dialogService.dialog.open(SelectLangComponent, {
      disableClose: false,
    });
    dialog.afterClosed().subscribe((lang) => {
      if (lang) {
        this.translate.get('COMMON.LANGUAGE').subscribe((val) => {
          this._flashService.success(val + ': ' + LANGS[lang].toLowerCase());
        });
      }
    });
  }

  getCurrentLangName() {
    return LANGS[this.translate.currentLang].toLowerCase();
  }

  logout() {
    this._dialogService.dialog
      .open(LogoutComponent, { disableClose: false })
      .afterClosed()
      .subscribe((result: { everywhere: boolean }) => {
        if (result) {
          this._authService.logout(result.everywhere, '', '/');
        }
      });
  }

  toggleSidenav() {
    this._sidenavService.toggle();
  }

  get isDark() {
    return this._settingsService.darkTheme;
  }

  get displayName() {
    return this._tokenService.getValue('name');
  }

  private _updateTitle() {
    const titles = this._getTitle(
      this._router.routerState,
      this._router.routerState.root
    ).concat([this.customer?.name]);
    const t = this.translate;

    const titleParts = titles.filter((m) => m).map((m) => t.instant(m));
    this._titleService.setTitle(titleParts.join(' - '));
    this.title = titleParts.reverse().join(': ');
    this._changeDetector.markForCheck();
  }

  private _getTitle(state, parent) {
    const data: string[] = [];
    if (parent && parent.snapshot.data && parent.snapshot.data.title) {
      data.push(parent.snapshot.data.title);
    }
    if (state && parent) {
      data.push(...this._getTitle(state, state.firstChild(parent)));
    }
    return data;
  }

  loadNotifyItems() {
    if (this.notifyLoading) {
      return;
    }

    this.notifyLoading = true;

    this._auditService
      .getList(null, null, null, null, 20)
      .pipe(
        finalize(() => {
          if (this.notifyLoading) {
            this.notifyLoading = false;
            this._changeDetector.markForCheck();
          }
        })
      )
      .subscribe((result) => {
        this.notifyCount = 0;
        this.notifyItems = result;
        this.notifyLoading = false;
        this._changeDetector.markForCheck();
      });
  }

  getActivityName(activity: string) {
    return this.translate.instant(PERMISSIONS_TRANSLATE[activity] || activity);
  }

  public about() {
    this._dialogService.dialog.open(AboutDialogComponent);
  }
}
