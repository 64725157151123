import { Injectable } from "@angular/core";
import {
  IDataSourceService,
  IPageParams,
  IPageReturn,
  setPageParams,
  getPageReturn,
} from "projects/msu-its-web-common/src/data/data-source";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

import { ParkingApiService } from "../parking-api.service";
import {
  GetParkingCameraEventsRequest,
  ParkingCameraEvent,
} from "../../dtos/parking.dtos";

@Injectable()
export class ParkingCameraEventsService
  implements IDataSourceService<ParkingCameraEvent, ParkingCameraEvent> {
  constructor(private _api: ParkingApiService) {}

  getPagedList(
    params: IPageParams,
    requestParams: { modelId: string; customerId: string }
  ): Observable<IPageReturn<ParkingCameraEvent>> {
    const request = new GetParkingCameraEventsRequest();
    request.customerId = requestParams.customerId;
    request.cameraId = requestParams.modelId;
    setPageParams(request, params);
    return this._api.get(request).pipe(
      map((m) => {
        return getPageReturn(m);
      })
    );
  }

  getList(
    customerId: string,
    filter?: string,
    groupIds?: string[]
  ): Observable<ParkingCameraEvent[]> {
    const request = new GetParkingCameraEventsRequest();
    request.customerId = customerId;

    request.entityGroups = groupIds;
    request.page = 0;
    request.items = 0;
    request.sort = "name";
    request.filter = filter;

    return this._api.get(request).pipe(
      map((m) => {
        return m.items;
      })
    );
  }

  // getList(customerId: string, filter?: string, groupIds?: string[]): Observable<Camera[]> {
  //   const request = new GetCamerasPageRequest();
  //   request.customerId = customerId;
  //   request.entityGroups = groupIds;
  //   request.page = 0;
  //   request.items = 0;
  //   request.sort = 'name';
  //   request.filter = filter;

  //   return this._api.get(request).pipe(
  //     map((m) => {
  //       return m.items;
  //     })
  //   );
  // }

  get(id: any, customerId: string): Observable<ParkingCameraEvent> {
    throw "not implemented";
  }

  add(): Observable<ParkingCameraEvent> {
    throw "not implemented";
  }

  update(
    model: ParkingCameraEvent,
    params: {}
  ): Observable<ParkingCameraEvent> {
    throw "not implemented";
  }

  delete(ids: string[], customerId: string): Observable<number> {
    throw "not implemented";
  }
}
