import { Component, ChangeDetectionStrategy } from '@angular/core';
import { LANGS } from '../../utils/langs';
import { TranslateService } from '@ngx-translate/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'select-lang',
  templateUrl: './select-lang.component.html',
  styles: [`
    .mat-list-base .mat-list-item .mat-list-icon {
      width: 34px;
      height: 26px;
      border-radius: 0;
      padding: 0;
      margin-right: 12px;
    }
  `],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectLangComponent {
  langs = LANGS;

  constructor(
    private _dialogRef: MatDialogRef<SelectLangComponent>,
    public translate: TranslateService,
  ) { }

  setLang(lang) {
    this.translate.use(lang);
    if (this._dialogRef) {
      this._dialogRef.close(lang);
    }
  }
}
