import { Injectable } from '@angular/core';
import {
  GetTrafficObjectDetectorsReport,
  GetTrafficObjectDetectorsRequest,
  TrafficObjectDetectors,
  UpdateTrafficObjectDetectorsRequest,
} from '../dtos/tss.dtos';
import { TssApiService } from './tss-api.service';

@Injectable({
  providedIn: 'root',
})
export class TrafficObjectReportService {
  constructor(private _api: TssApiService) {}

  getTrafficObjectDetectorsReport(id: string, sourceDate: Date, compareWithDate: Date) {
    const request = new GetTrafficObjectDetectorsReport();
    request.id = id;
    // request.sourceDate = '2021-01-03T17:00:00.000Z'; // текущей дата
    // request.compareWithDate = '2021-01-01T17:00:00.000Z';
    request.sourceDate = sourceDate.toISOString(); // текущей дата
    request.compareWithDate = compareWithDate.toISOString();
    return this._api.get(request);
  }
  getUrl(id: string, sourceDate: Date, compareWithDate: Date): any {
    return `${
      this._api.apiUrl
    }/trafficObjectDetectorsReportFile/${id}?sourceDate=${sourceDate.toISOString()}&compareWithDate=${compareWithDate.toISOString()}&format=2`;
  }
  getDetectors(id: string, customerId: string) {
    const request = new GetTrafficObjectDetectorsRequest();
    request.id = id;
    request.customerId = customerId;
    return this._api.get(request);
  }
  updateDetectors(id: string, customerId: string, detectors: TrafficObjectDetectors) {
    detectors.id = id;
    const request = new UpdateTrafficObjectDetectorsRequest();
    request.id = id;
    request.entity = detectors;
    request.customerId = customerId;
    return this._api.put(request);
  }
}
