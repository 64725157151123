<h4 mat-dialog-title>{{ "COMMON.ACCESS" | translate }}: {{ model.name }}</h4>
<mat-dialog-content>
  <div>
    <mat-form-field style="width: 100%">
      <mat-label>{{ "COMMON.CUSTOMER" | translate }}</mat-label>
      <input matInput disabled [value]="share.customerName" />
    </mat-form-field>
  </div>
  <div style="padding-bottom: 12px">
    <mat-list>
      <mat-list-item *ngFor="let permission of permissions" style="height: 36px">
        <mat-checkbox
          disableRipple
          [checked]="getPermissionChecked(permission)"
          (change)="setPermissionChecked(permission, $event.checked)"
          style="font-size: 12px"
        >
          {{ getPermissionName(permission) }}
        </mat-checkbox>
      </mat-list-item>
    </mat-list>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button (click)="close()">
    {{ "COMMON.CANCEL" | translate }}
  </button>
  <button mat-button (click)="apply()">
    {{ "COMMON.SAVE" | translate }}
  </button>
</mat-dialog-actions>
