import { Component, ChangeDetectionStrategy, AfterViewInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { DetectorService } from 'projects/msu-its-web-tfms/src/services/detector.service';
import { forkJoin } from 'rxjs';

@Component({
  selector: 'detector-compare',
  templateUrl: './detector-compare.component.html',
  styles: [``],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DetectorCompareComponent implements AfterViewInit {
  _detectorId: '651f3e12-a9f9-4d73-9cbc-d5578c552343';

  formGroup: FormGroup = this._formBuilder.group({});

  constructor(private _formBuilder: FormBuilder, private _detectorService: DetectorService) {}

  ngAfterViewInit() {
    const date = new Date(2020, 11, 10);
    // console.log(date);
    const start1 = new Date(date.getFullYear(), date.getMonth(), date.getDate() - 14);
    const end2 = new Date(date.getFullYear(), date.getMonth(), date.getDate() + 14);
    const from = new Date(Date.now());
    const to = new Date(Date.now());

    forkJoin(
      this._detectorService.getDetectorGraph(this._detectorId, start1, date),
      this._detectorService.getDetectorGraph(this._detectorId, date, end2)
    ).subscribe(([res1, res2]) => {
      // console.log(res1);
      // console.log(res2);
    });
  }

  export() {}
}
