import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';

import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MatCardModule } from '@angular/material/card';
import { MatChipsModule } from '@angular/material/chips';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatListModule } from '@angular/material/list';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatBadgeModule } from '@angular/material/badge';

import { BaseModule } from '../base.module';
import { LoginComponent } from '../../components/login/login.component';
import { NavbarComponent } from '../../components/navbar/navbar.component';
import { SidenavComponent } from '../../components/sidenav/sidenav.component';
import { SelectLangComponent } from '../../components/navbar/select-lang.component';
import { LogoutComponent } from '../../components/navbar/logout.component';
import { CustomersComponent } from '../../components/customers/customers.component';
import { CustomerEditComponent } from '../../components/customers/customer-edit.component';
import { CustomerContainerComponent } from '../../components/customer-container/customer-container.component';
import {
  CustomerSelectComponent,
  CustomerSelectModalComponent,
} from '../../components/customers/customer-select.component';
import { ExternalSystemsComponent } from '../../components/external-systems/external-systems.component';
import { ExternalSystemEditComponent } from '../../components/external-systems/external-system-edit.component';

import { ISidenavMenuCategory } from '../../utils/sidenav-menu';
import { SIDENAV_MENU } from '../../utils/shared-consts';

import { CustomerService } from '../../services/customer.service';
import { CustomerRoleService } from '../../services/customer-role.service';
import { UserService } from '../../services/user.service';

import { environment } from '../../../../msu-its-web/src/environments/environment';

const sidenavLogs: ISidenavMenuCategory[] = [
  ...((environment as any).tempSidenav || []),
  {
    name: _('SIDENAV_MENU.LOGS'),
    items: [
      {
        name: _('AUDIT_LOG.TITLE'),
        icon: 'perm_contact_calendar',
        route: 'audit-log',
        permissions: [], // ['system.manage'],
        external: true,
      },
      // {
      //   name: _('EVENT_LOG.TITLE'),
      //   icon: 'event',
      //   route: 'event-log',
      //   permissions: ['system.manage'],
      //   external: true,
      // },
    ],
  },
];

const sidenavSecurity: ISidenavMenuCategory[] = [
  {
    name: _('SIDENAV_MENU.SECURITY'),
    items: [
      {
        name: _('USERS.TITLE'),
        icon: 'people',
        route: 'users',
        permissions: ['user.read'],
      },
      {
        name: _('ROLES.TITLE'),
        icon: 'security',
        route: 'roles',
        permissions: ['customerRole.update'],
      },
      {
        name: _('PERMISSIONS.TITLE'),
        icon: 'verified_user',
        route: 'permissions',
        permissions: ['system.manage'],
      },
    ],
  },
];

@NgModule({
  providers: [
    { provide: SIDENAV_MENU, useValue: sidenavLogs, multi: true },
    { provide: SIDENAV_MENU, useValue: sidenavSecurity, multi: true },
    CustomerService,
    CustomerRoleService,
    UserService,
  ],
  imports: [
    BaseModule,

    MatBadgeModule,
    MatChipsModule,
    MatCardModule,
    MatAutocompleteModule,
    MatSidenavModule,
    MatListModule,
    MatToolbarModule,
    MatBadgeModule,
  ],
  declarations: [
    LoginComponent,
    NavbarComponent,
    SidenavComponent,
    SelectLangComponent,
    LogoutComponent,
    CustomerContainerComponent,

    CustomersComponent,
    CustomerEditComponent,
    CustomerSelectComponent,
    CustomerSelectModalComponent,

    ExternalSystemsComponent,
    ExternalSystemEditComponent,
  ],
  entryComponents: [],
  exports: [],
})
export class HubSharedModule {}
