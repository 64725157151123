<div style="display: flex; align-items: center; margin-right: -12px">
  <button
    mat-icon-button
    [disabled]="disabled"
    (click)="dataSource?.toggleSearch()"
  >
    <mat-icon>search</mat-icon>
  </button>

  <div
    [style.margin-right]="dataSource?.showSearch ? '8px' : 0"
    [style.width]="dataSource?.showSearch ? '200px' : 0"
  >
    <mat-form-field
      floatLabel="never"
      style="margin-top: -14px; margin-bottom: -14px; width: 100%"
    >
      <input
        matInput
        #search
        autofocus
        [disabled]="disabled"
        placeholder="{{ ('COMMON.SEARCH' | translate | lowercase) + '...' }}"
        autocomplete="off"
        spellcheck="false"
      />
    </mat-form-field>
  </div>
</div>
