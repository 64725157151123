import { Injectable, Inject, Injector } from '@angular/core';
import { APP_ENVIRONMENT } from 'projects/msu-its-web-common/src/utils/shared-consts';
import { BaseApiEventService } from 'projects//msu-its-web-common/src/services/base-api-event.service';

@Injectable()
export class AccidentApiEventService extends BaseApiEventService {
  constructor(injector: Injector, @Inject(APP_ENVIRONMENT) appEnv) {
    super(injector, appEnv.modules.accident.apiUrl);
  }
}
