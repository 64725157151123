import {
  CircleMarkerOptions,
  CircleMarker,
  LatLngExpression,
  Point,
  PointTuple,
  Bounds,
  LatLngTuple,
} from "leaflet";

import "heatmap.js";
import "leaflet-heatmap";
import "leaflet.marker.slideto";
import "@geoman-io/leaflet-geoman-free";

declare const L;

const imageSet = {};

const detailsMinZoom = 11;

const defaultImageOptions = {
  url: null,
  size: [30, 30],
  anchor: [0, 0],
  rotate: 0,
  opacity: 1,
};

const spinRatio = 0.007;
const spinStartAngle = 2 * Math.PI;
const spinEndAngle = 0.5 * Math.PI;
const spinDefaultColor = "rgba(0 ,0, 220, 0.7)";

const directionMarker = L.marker([0, 0], { interactive: false });

export const showDirectionMarker = (layer) => {
  if (layer._map._zoom < detailsMinZoom) {
    return;
  }

  const map = layer._map;
  const latlng = layer.getLatLng();
  const rotate = layer.options.rotate;

  if (map && rotate !== undefined) {
    directionMarker.setLatLng(latlng);

    const zoom = map.getZoom();
    const scale = zoom * 0.26 - 2.0;

    const w = 31 * scale;
    const h = 48 * scale;

    directionMarker.setIcon(
      new L.DivIcon({
        className: "direction-marker",
        html: `<img src="/assets/images/map/direction2.svg"
               style="transform: rotate(${Math.round(rotate)}deg)" />`,
        iconSize: [w, h],
        iconAnchor: [w / 2, h],
      })
    );
    directionMarker.addTo(map);
  }
};
export const hideDirectionMarker = (layer) => {
  layer._map?.removeLayer(directionMarker);
};

export const getScale = (zoom: number) => {
  return Math.max(Math.min(zoom * 0.125 - 1.25, 1), 0.25);
  // return Math.min(zoom * 0.06 + 0.1, 1);
};

L.Canvas.include({
  _updateStateImg(layer) {
    if (this._map._zoom < detailsMinZoom || layer.options.label?.text) {
      return;
    }

    const { img, rotate, radius, weight } = layer.options;
    const p = layer._point.round();

    // scale size by radius, for draw image inside circle
    const scale = Math.min((radius * 2) / Math.max(...img.size), 1);
    const size = [img.size[0] * scale, img.size[1] * scale];

    this._ctx.save();
    this._ctx.globalAlpha = img.opacity;
    this._ctx.translate(p.x, p.y);
    this._ctx.rotate(((rotate || 0) * Math.PI) / 180);
    this._ctx.drawImage(img.el, -size[0] / 2, -size[1] / 2, size[0], size[1]);
    this._ctx.restore();
  },
  _updateStateSpin(layer) {
    if (this._map._zoom < detailsMinZoom) {
      return;
    }

    const p = layer._point.round();
    const radius = layer.options.radius;
    const weight = layer.options.weight;
    const spinColor = layer.options.spinColor || spinDefaultColor;

    const currentAngle = (Date.now() * spinRatio) % spinStartAngle;
    const spinStart = spinStartAngle + currentAngle;
    const spinEnd = spinEndAngle + currentAngle;

    this._ctx.beginPath();
    this._ctx.arc(p.x, p.y, radius, spinStart, spinEnd, false);
    this._ctx.strokeStyle = spinColor;
    this._ctx.lineWidth = weight;
    this._ctx.stroke();
  },
  _updateStateLabel(layer) {
    if (this._map._zoom < detailsMinZoom) {
      return;
    }

    const { text, color, opacity } = layer.options.label;
    const p = layer._point.round();
    const r = layer.options.radius;
    const fontSize = Math.round(r * 0.75);

    this._ctx.font = `500 ${fontSize}px Roboto`;
    opacity && (this._ctx.globalAlpha = opacity);
    this._ctx.textAlign = "center";
    this._ctx.textBaseline = "middle";
    this._ctx.fillStyle = color || "#fff";
    this._ctx.fillText(text, p.x, p.y);
  },
  _updateImg(layer) {
    const { img } = layer.options;
    const p = layer._point.round();

    const zoom = this._map._zoom;
    if (zoom < detailsMinZoom && img.index != 0) {
      return;
    }

    // const scale = Math.min(zoom * 0.052, 1);
    const scale = getScale(this._map._zoom);
    const size = [scale * img.size[0], scale * img.size[1]];
    const anchor = [scale * img.anchor[0], scale * img.anchor[1]];

    this._ctx.save();
    this._ctx.globalAlpha = img.opacity || 1;
    this._ctx.translate(p.x, p.y);
    this._ctx.rotate(((img.rotate || 0) * Math.PI) / 180);
    this._ctx.translate(-size[0] / 2, -size[1] / 2);
    this._ctx.drawImage(
      img.el,
      size[0] / 2 - anchor[0],
      size[1] / 2 - anchor[1],
      size[0],
      size[1]
    );
    this._ctx.restore();

    // ///////////////////
    // this._ctx.beginPath();
    // this._ctx.arc(p.x, p.y, 2, 0, 360, false);
    // this._ctx.fill();
    // ///////////////////
  },

  _updateImageOptions(layer) {
    const imageOptions = layer.options.image as LeafletImageOptions;
    const scale = getScale(this._map._zoom);

    const size = [scale * imageOptions.size![0], scale * imageOptions.size![1]];
    const anchor = [
      scale * imageOptions.anchor![0],
      scale * imageOptions.anchor![1],
    ];

    const p = layer._point; //.round();

    this._ctx.save();
    this._ctx.globalAlpha = imageOptions.opacity || 1;
    this._ctx.translate(p.x, p.y);
    this._ctx.rotate(((imageOptions.rotate || 0) * Math.PI) / 180);
    this._ctx.translate(-size[0] / 2, -size[1] / 2);
    !imageOptions._element._broken &&
      this._ctx.drawImage(
        imageOptions._element,
        size[0] / 2 - anchor[0],
        size[1] / 2 - anchor[1],
        size[0],
        size[1]
      );
    this._ctx.restore();

    /////////////////////
    // this._ctx.beginPath();
    // this._ctx.arc(p.x, p.y, 1, 0, 360, false);
    // this._ctx.fill();
    /////////////////////
  },
});

L.StatusMarker = L.CircleMarker.extend({
  _update: function () {
    this._updateRadius();
    if (this._map) {
      this._updatePath();
    }
  },
  _updateRadius: function () {
    if (!this._r) {
      this._r = this.options.radius;
      this._w = this.options.weight;
    }

    const zoom = this._map.getZoom();
    const scale = Math.max(Math.min(zoom * 0.125 - 1.25, 1), 0.25);

    this.options.radius = Math.round(this._r * scale);
    this.options.weight = Math.round(this._w * scale);

    // const scale = zoom * 0.1 - 0.8;

    // this.options.radius = Math.round(
    //   Math.min(Math.max(this._r * scale, 4), 18)
    // );
    // this.options.weight = Math.round(
    //   Math.min(Math.max(this._w * scale, 1), 6.0)
    // );

    this._radius = this.options.radius;
  },
  _updatePath: function () {
    if (this.options.img && this.options.img.url) {
      if (this.options.img.el) {
        this._renderer._updateCircle(this);
        this._renderImg();
        this._renderLabel();
        this._updateSpin();
      } else {
        this._loadImg();
      }
    } else {
      this._renderer._updateCircle(this);
      this._renderLabel();
      this._updateSpin();
    }
  },

  _loadImg: function () {
    this.options.img = { ...defaultImageOptions, ...this.options.img };

    const imgUrl = this.options.img.url;
    let img = imageSet[imgUrl];
    if (img && img._loaded) {
      this.options.img.el = img;
      this.redraw();
      return;
    }

    if (img) {
      img._redraw.push(this);
    } else {
      img = document.createElement("img");
      img._redraw = [this];
      img.onload = () => {
        img._loaded = true;
        img._redraw.forEach((m) => m.redraw());
        img._redraw = null;
      };
      img.onerror = () => {
        img._redraw.forEach((m) => (m.options.img = null));
      };
      img.src = imgUrl;

      this.options.img.el = img;
      imageSet[imgUrl] = img;
    }
  },
  _renderImg: function () {
    this._renderer._updateStateImg(this);
  },

  _renderLabel: function () {
    if (this.options.label?.text) {
      this._renderer._updateStateLabel(this);
    }
  },

  _animateSpin: function (timestamp) {
    this._spinId =
      this._spin && requestAnimationFrame((ts) => this._animateSpin(ts));

    const delta = timestamp - (this._spinTimestamp || timestamp);

    if (delta < 30) {
      return;
    }

    this.redraw();
    this._spinTimestamp = timestamp;
  },
  _updateSpin: function () {
    if (this._spin) {
      this._renderer._updateStateSpin(this);
    }
    if (this._spin && !this._spinId) {
      this._animateSpin(this);
    }
  },
  startSpin: function () {
    this._spin = true;
    this.redraw();
  },
  stopSpin: function () {
    this._spin = false;
    this._spinId = null;
    this.redraw();
  },

  _getPopupAnchor: function () {
    return [0, -this._radius];
  },
  openPopup: function (layer, latlng) {
    if (this._popup && this._map) {
      latlng = this._latlng;
      this._map.openPopup(this._popup, latlng);
    }
    return this;
  },

  setStatus: function (options) {
    Object.assign(this.options, options);
    if (options.radius) {
      this._r = undefined;
      this._w = undefined;
    }
    this.redraw();
  },
});
L.statusMarker = function (latlng, options) {
  return new L.StatusMarker(latlng, options);
};

L.ImageMarker = L.CircleMarker.extend({
  _updatePath() {
    if (!this.options.img || !this.options.img.url) return;
    if (!this.options.img.el) {
      this.options.img = { ...defaultImageOptions, ...this.options.img };

      const imgUrl = this.options.img.url;
      let img = imageSet[imgUrl];
      if (img && img._loaded) {
        this.options.img.el = img;
        this._renderer._updateImg(this);
        return;
      }

      if (img) {
        img._redraw.push(this);
      } else {
        img = document.createElement("img");
        img._redraw = [this];
        img.onload = () => {
          img._loaded = true;
          img._redraw.forEach((m) => m.redraw());
          img._redraw = null;
        };
        img.onerror = () => {
          img._redraw.forEach((m) => (m.options.img = null));
        };
        img.src = imgUrl;

        this.options.img.el = img;
        imageSet[imgUrl] = img;
      }
    } else {
      this._renderer._updateImg(this);
    }
  },
  _containsPoint: function (p) {
    const { img } = this.options;

    // const zoom = this._map._zoom;
    // const scale = Math.min(zoom * 0.056, 1);
    const scale = getScale((this as any)._map._zoom);

    const imgSize = img?.size || [0, 0];
    const imgAnchor = img?.anchor || [0, 0];
    const size = [scale * imgSize[0], scale * imgSize[1]];
    const anchor = [scale * imgAnchor[0], scale * imgAnchor[1]];

    // const point = {
    //   x: this._point.x - anchor[0] / 2,
    //   y: this._point.y - anchor[1] / 2,
    // };
    // const radius = Math.max(...size) / 2;

    // return p.distanceTo(point) <= radius + this._clickTolerance();

    const centerPoint = new L.Point(
      this._point.x + size[0] / 2 - anchor[0],
      this._point.y + size[1] / 2 - anchor[1]
    );
    const t = 0; // (this as any)._clickTolerance();
    const halfSize = [Math.ceil(size[0] / 2) + t, Math.ceil(size[1] / 2) + t];
    const bounds = new L.Bounds(
      centerPoint.subtract(halfSize),
      centerPoint.add(halfSize)
    );
    return bounds.contains(p);
  },
  _getPopupAnchor() {
    const imageOptions = this.options.img;
    const scale = getScale((this as any)._map._zoom);

    const imgSize = imageOptions?.size || [0, 0];
    const imgAnchor = imageOptions?.anchor || [0, 0];

    const size = [scale * imgSize[0], scale * imgSize[1]];
    const anchor = [scale * imgAnchor[0], scale * imgAnchor[1]];

    // return [0, -(this as any).options.image.size[1] * scale * 0.75];
    return [size[0] / 2 - anchor[0], -anchor[1] * 0.75];
  },
  _getTooltipAnchor() {
    const imageOptions = this.options.img;
    const scale = getScale((this as any)._map._zoom);

    const imgSize = imageOptions?.size || [0, 0];
    const imgAnchor = imageOptions?.anchor || [0, 0];

    const size = [scale * imgSize[0], scale * imgSize[1]];
    const anchor = [scale * imgAnchor[0], scale * imgAnchor[1]];

    // return [0, -(this as any).options.image.size[1] * scale * 0.75];
    return [size[0] / 2 - anchor[0], -anchor[1] * 0.6];
  },
  _updateBounds: function () {
    const { img } = this.options;

    // const zoom = this._map._zoom;
    // const scale = Math.min(zoom * 0.056, 1);
    const scale = getScale((this as any)._map._zoom);

    const imgSize = img?.size || [0, 0];
    const imgAnchor = img?.anchor || [0, 0];

    const size = [scale * imgSize[0], scale * imgSize[1]];
    const anchor = [scale * imgAnchor[0], scale * imgAnchor[1]];

    // const r = size[0] / 2;
    // const r2 = size[1] / 2;
    // const point = new L.point(
    //   this._point.x - anchor[0] / 2,
    //   this._point.y - anchor[1] / 2
    // );

    // const w = this._clickTolerance();
    // const p = [r + w, r2 + w];

    // this._pxBounds = new L.Bounds(point.subtract(p), point.add(p));

    const centerPoint = new L.Point(
      (this as any)._point.x + size[0] / 2 - anchor[0],
      (this as any)._point.y + size[1] / 2 - anchor[1]
    );

    const t = 0; // (this as any)._clickTolerance();
    const halfSize = [Math.ceil(size[0] / 2) + t, Math.ceil(size[1] / 2) + t];

    (this as any)._pxBounds = new L.Bounds(
      centerPoint.subtract(halfSize),
      centerPoint.add(halfSize)
    );
  },
  setOptions: function (options) {
    Object.assign(this.options, options);
    this.redraw();
  },
});
L.imageMarker = function (latlng, options) {
  return new L.ImageMarker(latlng, options);
};

////////////////////////////////////////////////////////////
// LeafletImageMarker
////////////////////////////////////////////////////////////

const imagesMap = new Map<string, any>();

L.Canvas.include({
  _updateLeafletImageMarker(layer) {
    const imageOptions = layer.options.image as LeafletImageOptions;
    const scale = getScale(this._map._zoom);

    const size = [scale * imageOptions.size![0], scale * imageOptions.size![1]];
    const anchor = [
      scale * imageOptions.anchor![0],
      scale * imageOptions.anchor![1],
    ];

    const p = layer._point; //.round();

    this._ctx.save();
    this._ctx.globalAlpha = imageOptions.opacity || 1;
    this._ctx.translate(p.x, p.y);
    this._ctx.rotate(((imageOptions.rotate || 0) * Math.PI) / 180);
    this._ctx.translate(-size[0] / 2, -size[1] / 2);
    !imageOptions._element._broken &&
      this._ctx.drawImage(
        imageOptions._element,
        size[0] / 2 - anchor[0],
        size[1] / 2 - anchor[1],
        size[0],
        size[1]
      );
    this._ctx.restore();

    /////////////////////
    // this._ctx.beginPath();
    // this._ctx.arc(p.x, p.y, 1, 0, 360, false);
    // this._ctx.fill();
    /////////////////////
  },
});

export const parkingSvg = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 128" width="32" height="32">
<rect fill="{color}" stroke="{borderColor}" stroke-width="10" width="118" height="118" x="5" y="5" rx="15" ry="15" />
<g transform="scale(0.6) translate(42 42)" fill="#ffffff"><path d="M 23.520836,11.511719 L 68.450523,11.511719 C 
81.809827,11.511824 92.052004,14.488383 99.177086,20.441406 C 106.34886,26.347746 109.9348,34.785238 109.9349,45.753906 
C 109.9348,56.769591 106.34886,65.253957 99.177086,71.207031 C 92.052004,77.113321 81.809827,80.066443 68.450523,80.066406 
L 50.591148,80.066406 L 50.591148,116.48828 L 23.520836,116.48828 L 23.520836,11.511719 M 50.591148,31.128906 L 
50.591148,60.449219 L 65.567711,60.449219 C 70.81765,60.449275 74.872334,59.183651 77.731773,56.652344 C 80.591078,54.074281 
82.020764,50.441472 82.020836,45.753906 C 82.020764,41.066482 80.591078,37.45711 77.731773,34.925781 C 74.872334,32.394615 
70.81765,31.128992 65.567711,31.128906 L 50.591148,31.128906" /></g></svg>`;

export const parkingKioskSvg = `<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 1000 1000">
<g transform="matrix(1 0 0 -1 0 1920)" stroke="{borderColor}" fill="{color}" stroke-width="40px"><path d="M357,1135.2c-22.3,0-42.4,8-60.1,24.1c-17.7,
16.1-30.4,36.1-38,60.1l-85.8,405.4V1825c0,23.4,8.3,43.5,24.9,60.1c16.6,16.6,36.6,24.9,60.1,24.9h483.9c23.4,0,43.5-8.3,60.1-24.9c16.6-16.6,
24.9-36.6,24.9-60.1v-200.3l-85.8-405.4c-7.9-23.4-20.6-43.3-38.2-59.7c-17.6-16.3-37.5-24.5-59.9-24.5H357z M385.6,1661.5h228.9l63.8,99.3c-0.5,
1.4-1.4,3.1-2.5,5.1c-1.1,2-3.8,6.1-8.2,12.1c-4.4,6-9.3,11.7-14.9,17.2c-5.6,5.4-13.3,11.5-23.3,18.2c-9.9,6.7-20.7,12.4-32.3,17.2c-11.6,4.8-25.9,
8.8-42.9,12.1c-17,3.3-35.1,4.9-54.1,4.9c-26.4,0-50.6-3-72.5-9c-21.9-6-39.1-13.2-51.5-21.7c-12.4-8.4-23-16.9-31.7-25.3c-8.7-8.4-14.6-15.7-17.6-21.7
l-4.9-9L385.6,1661.5z M502,1707.7l79.7,107.1l-15.5-107.1H502z M383.9,1248.8h68.7c1.6,0,3.1,0.3,4.3,1c1.2,0.7,2.2,1.7,3.1,3.1c0.8,1.4,1.2,2.7,1.2,4.1
v72.7h88.7c18.8,0,35.7,2.9,50.7,8.6s27.1,13.6,36.4,23.5c9.3,9.9,16.3,21.3,21.3,33.9c4.9,12.7,7.4,26.2,7.4,40.7c0,15-2.1,28.8-6.3,41.5
c-4.2,12.7-10.7,24.2-19.4,34.7s-20.7,18.7-36,24.7c-15.3,6-33.2,9-53.9,9H383.9c-1.6,0-3.1-0.4-4.3-1.2c-1.2-0.8-2.2-1.8-3.1-3.1c-0.8-1.2-1.2-2.7-1.2-4.3
v-280.8c0-1.1,0.3-2.1,0.8-3.1c0.5-1,1.2-1.8,1.8-2.7c0.7-0.8,1.6-1.4,2.7-1.8C381.8,1249,382.8,1248.8,383.9,1248.8z M461.2,1379.5v118.5H509c21.8,0,38.6-4.9,
50.5-14.7c11.9-9.8,17.8-24.4,17.8-43.7c0-10.6-2-20-6.1-28c-4.1-8-9.5-14.3-16.3-18.8c-6.8-4.5-14.1-7.8-21.9-10c-7.8-2.2-15.7-3.3-23.9-3.3H461.2z M418.3,930
v163.5h163.5V930H418.3z" /></g></svg>`;

export const publicTransportSvg = `<svg width="50" height="60" viewBox="0 0 50 60" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M40.5443 18.9422C49.1519 27.6485 49.1519 41.7782 40.5443 50.4845C31.9555 59.1718 18.0445 59.1718 9.45571 50.4845C0.848097 
41.7782 0.848097 27.6485 9.45571 18.9422L25 3.21963L40.5443 18.9422Z" fill="{color}" stroke="{borderColor}" stroke-width="6"/>
</svg>`;

export const maintenanceTransportSvg = `<svg width="50" height="60" viewBox="0 0 50 60" xmlns="http://www.w3.org/2000/svg" >
<path d="M40.5,17.1c7.4,7.8,6.9,22.5,6.5,34.4c-0.1,3.2-2.6,5.7-5.6,5.7C26.4,57.1,22.9,57,8.4,57c-3,0-5.5-2.5-5.5-5.7
	C2.6,39.9,2.1,24.9,9.5,17.1L21.1,4.8c2.2-2.3,5.7-2.3,7.9,0L40.5,17.1z" fill="{color}" stroke="{borderColor}" stroke-width="6"/>
</svg>
`;

export const panelStand2col = `<svg width="76" height="50" viewBox="0 0 76.5 49.6" xmlns="http://www.w3.org/2000/svg" >
<path d="M5.4,10.7v39.2H0V13.5c0-1.6,1-2.8,2.1-2.8H5.4z" fill="#f0f0f0"/>
<path d="M76.5,13.6v36.3h-5.4V10.8h3.2C75.6,10.8,76.5,12.1,76.5,13.6z" fill="#f0f0f0"/>
<path d="M10,18.7H5.4v-8H10c0.7,0,1.3,0.6,1.3,1.3v5.4C11.3,18.1,10.7,18.7,10,18.7z" fill="#f0f0f0"/>
<path d="M71.1,18.7h-4.9c-0.5,0-0.9-0.4-0.9-0.9v-6.1c0-0.5,0.4-0.9,0.9-0.9h4.9V18.7z" fill="#f0f0f0"/>
<path d="M64.9,34.2H11.6c-0.3,0-0.6-0.2-0.6-0.6V3.6C11,3.2,11.2,3,11.6,3h53.5c0.2,0,0.5,0.2,0.5,0.6v29.9
	C65.5,33.9,65.3,34.2,64.9,34.2z" fill="{color}" stroke="{borderColor}" stroke-width="6" />
</svg>`;

export const getImgSrc = (
  src: string,
  color?: string,
  borderColor?: string
) => {
  let result = src;
  if (!src.startsWith("<")) return result;
  color && (result = result.replace(/\{color\}/g, color));
  borderColor && (result = result.replace(/\{borderColor\}/g, borderColor));
  return "data:image/svg+xml;base64," + btoa(result);
};

interface LeafletImageOptions {
  src?: string;
  size?: [number, number];
  anchor?: [number, number];
  tooltipYScale?: number;
  opacity?: number;
  rotate?: number;
  color?: string;
  borderColor?: string;
  _element?: any;
}

interface LeafletImageMarkerOptions extends CircleMarkerOptions {
  image: LeafletImageOptions;
  selected: boolean;
  permanentTooltip: boolean;
  object: any;
}

const defaultLeafletMarkerImageOptions: LeafletImageOptions = {
  src: parkingSvg,
  size: [40, 40],
  anchor: [20, 20], //[22, 22],
  tooltipYScale: 1,
  rotate: 0,
  opacity: 1,
  color: "#999",
  borderColor: "#fff",
};

let slideToCount = 0;

export class LeafletImageMarker extends CircleMarker {
  declare options: LeafletImageMarkerOptions;

  _updatePath() {
    // if (!this.options.image.src) return;

    if (!this.options.image._element) {
      this.options.image = {
        ...defaultLeafletMarkerImageOptions,
        ...this.options.image,
      };
      const imageOptions = this.options.image as LeafletImageOptions;

      const imgSrc = getImgSrc(
        imageOptions.src!,
        imageOptions.color,
        imageOptions.borderColor
      );
      let img = imagesMap.get(imgSrc);
      if (img && img._loaded) {
        this.options.image._element = img;
        (this as any)._renderer._updateLeafletImageMarker(this);
        return;
      }

      if (img) {
        img._redraw.push(this);
      } else {
        img = document.createElement("img");
        img._redraw = [this];
        img.onload = () => {
          img._loaded = true;
          img._redraw.forEach((m: any) => m.redraw());
          img._redraw = null;
        };
        img.onerror = () => {
          // img._redraw.forEach((m: any) => (m.options.image = null));
          img._broken = true;
        };

        img.src = getImgSrc(
          imageOptions.src!,
          imageOptions.color,
          imageOptions.borderColor
        );
        imagesMap.set(imgSrc, img);
        this.options.image._element = img;
      }
    } else {
      (this as any)._renderer._updateLeafletImageMarker(this);
    }
  }
  _containsPoint(p: any) {
    const imageOptions = this.options.image as LeafletImageOptions;

    const scale = getScale((this as any)._map._zoom);

    const imgSize = imageOptions?.size || [0, 0];
    const imgAnchor = imageOptions?.anchor || [0, 0];

    const size = [scale * imgSize[0], scale * imgSize[1]];
    const anchor = [scale * imgAnchor[0], scale * imgAnchor[1]];

    // const point = {
    //   x: (this as any)._point.x, // - anchor[0] / 2,
    //   y: (this as any)._point.y - anchor[1] / 2,
    // };
    // const radius = Math.max(...size) / 2;
    // return p.distanceTo(point) <= radius + (this as any)._clickTolerance();

    const centerPoint = new Point(
      (this as any)._point.x + size[0] / 2 - anchor[0],
      (this as any)._point.y + size[1] / 2 - anchor[1]
    );
    const t = 0; // (this as any)._clickTolerance();
    const halfSize: PointTuple = [
      Math.ceil(size[0] / 2) + t,
      Math.ceil(size[1] / 2) + t,
    ];
    const bounds = new Bounds(
      centerPoint.subtract(halfSize),
      centerPoint.add(halfSize)
    );
    return bounds.contains(p);
  }
  _getPopupAnchor() {
    const imageOptions = this.options.image as LeafletImageOptions;
    const scale = getScale((this as any)._map._zoom);

    const imgSize = imageOptions?.size || [0, 0];
    const imgAnchor = imageOptions?.anchor || [0, 0];

    const size = [scale * imgSize[0], scale * imgSize[1]];
    const anchor = [scale * imgAnchor[0], scale * imgAnchor[1]];

    // return [0, -(this as any).options.image.size[1] * scale * 0.75];
    return [size[0] / 2 - anchor[0], -anchor[1] * 0.75];
  }
  _getTooltipAnchor() {
    const imageOptions = this.options.image as LeafletImageOptions;
    const scale = getScale((this as any)._map._zoom);

    const imgSize = imageOptions?.size || [0, 0];
    const imgAnchor = imageOptions?.anchor || [0, 0];
    const yScale = imageOptions.tooltipYScale || 1;

    const size = [scale * imgSize[0], scale * imgSize[1]];
    const anchor = [scale * imgAnchor[0], scale * imgAnchor[1]];

    // return [0, -(this as any).options.image.size[1] * scale * 0.75];
    return [size[0] / 2 - anchor[0], -anchor[1] * yScale];
  }
  _updateBounds() {
    const imageOptions = this.options.image as LeafletImageOptions;
    const scale = getScale((this as any)._map._zoom);

    const imgSize = imageOptions?.size || [0, 0];
    const imgAnchor = imageOptions?.anchor || [0, 0];

    const size = [scale * imgSize[0], scale * imgSize[1]];
    const anchor = [scale * imgAnchor[0], scale * imgAnchor[1]];

    const centerPoint = new Point(
      (this as any)._point.x + size[0] / 2 - anchor[0],
      (this as any)._point.y + size[1] / 2 - anchor[1]
    );

    const t = 0; // (this as any)._clickTolerance();
    const halfSize: PointTuple = [
      Math.ceil(size[0] / 2) + t,
      Math.ceil(size[1] / 2) + t,
    ];

    (this as any)._pxBounds = new Bounds(
      centerPoint.subtract(halfSize),
      centerPoint.add(halfSize)
    );
  }

  setImage(image: LeafletImageOptions) {
    if (this.options.image.src != image.src) {
      this.options.image._element = null;
    }

    // if (this._slideToRotate != null) {
    //   const newRotate = image.rotate;
    //   image.rotate = this.options.image.rotate;
    //   this._slideToRotate = newRotate || 0;
    // }

    this.options.image = { ...this.options.image, ...image };
    this.redraw();
  }

  // setStatus

  setStatus(status?: { fillColor?: string; color?: string }) {
    if (
      this.options.image.color !== status?.fillColor ||
      this.options.image.borderColor !== status?.color
    ) {
      this.options.image.color = status?.fillColor;
      this.options.image.borderColor = status?.color;
      this.options.image._element = null;
    }
    this.redraw();
  }

  // slideTo

  _slideFrame = 0;
  private _slideToUntil = 0;
  private _slideToDuration = 1000;
  private _slideToLatLng: LatLngExpression = [0, 0];
  private _slideFromLatLng: LatLngExpression = [0, 0];
  private _slideToRotate: number | null = null;

  addInitHook = () => {
    this.on("move", () => this.cancelSlide(), this);
  };

  cancelSlide(redraw = true) {
    if (this._slideFrame) {
      L.Util.cancelAnimFrame(this._slideFrame);
      this._slideFrame = 0;
      slideToCount--;
      if (redraw) {
        this.setLatLng(this._slideToLatLng);
        if (this._slideToRotate !== null) {
          this.options.image.rotate = this._slideToRotate;
          this.setImage(this.options.image);
          this._slideToRotate = null;
        }
      }
    }
  }

  _prevNow = 0;
  private _slide() {
    if (!this._map) {
      this._slideFrame = 0;
      slideToCount--;
      return;
    }

    if ((this._map as any)._zooming || (this._map.touchZoom as any)?._zooming) {
      this._slideFrame = L.Util.requestAnimFrame(this._slide, this);
      return;
    }

    const now = performance.now();
    if (now - this._prevNow < 200) {
      this._slideFrame = L.Util.requestAnimFrame(this._slide, this);
      return;
    }

    this._prevNow = now;
    const remaining = this._slideToUntil - now;

    if (remaining < 0) {
      this._slideFrame = 0;
      slideToCount--;
      this.setLatLng(this._slideToLatLng);
      if (this._slideToRotate !== null) {
        this.options.image.rotate = this._slideToRotate;
        this.setImage(this.options.image);
        this._slideToRotate = null;
      }
      this.fire("moveend");
      return this;
    }

    const startPoint = this._map.latLngToContainerPoint(this._slideFromLatLng);
    const endPoint = this._map.latLngToContainerPoint(this._slideToLatLng);
    const percentDone =
      (this._slideToDuration - remaining) / this._slideToDuration;

    const currPoint = endPoint
      .multiplyBy(percentDone)
      .add(startPoint.multiplyBy(1 - percentDone));
    const currLatLng = this._map.containerPointToLatLng(currPoint);

    this.setLatLng(currLatLng);
    this._slideFrame = L.Util.requestAnimFrame(this._slide, this);
  }

  startSlide = (latlng: LatLngTuple, duration: number, moveAngle = true) => {
    const ll = this.getLatLng();
    if (!this._map || (ll.lat === latlng[0] && ll.lng === latlng[1])) {
      return;
    }

    this.cancelSlide(false);

    this._slideToDuration = duration;
    this._slideFromLatLng = this.getLatLng();
    this._slideToLatLng = latlng;
    this._slideToUntil = performance.now() + duration;

    if (moveAngle) {
      const startPoint = this._map.latLngToContainerPoint(
        this._slideFromLatLng
      );
      const endPoint = this._map.latLngToContainerPoint(this._slideToLatLng);
      const slideAngle =
        (Math.atan2(endPoint.y - startPoint.y, endPoint.x - startPoint.x) *
          180) /
          Math.PI +
        90;

      this._slideToRotate = this.options.image.rotate || null;
      this.options.image.rotate = slideAngle;
    }

    this.setImage(this.options.image);
    this.fire("movestart");
    slideToCount++;
    this._slide();

    return this;
  };
}

export const leafletImageMarker = function (
  latlng: LatLngExpression,
  options: LeafletImageMarkerOptions
) {
  return new LeafletImageMarker(latlng, options);
};
