import { Injectable } from '@angular/core';
import {
  getPageReturn,
  IDataSourceService,
  IPageParams,
  IPageReturn,
  setPageParams,
} from 'projects/msu-its-web-common/src/data/data-source';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  AddToSelectedIncidentsDetectorDevicesRequest,
  DeleteSelectedIncidentsDetectorDevicesRequest,
  Detector,
  DetectorDevice,
  DetectorDevicesPageResponse,
  GetAvailableToSelectDetectorDevicesRequest,
  GetDetectorDeviceSelectedIncidentsRequest,
  GetIncidentsAnalysisDetectorsRequest,
} from '../dtos/tfms.dtos';
import { TfmsApiService } from './tfms-api.service';

@Injectable({
  providedIn: 'root',
})
export class DetectorIncidentsAnalysisService implements IDataSourceService<Detector, Detector> {
  constructor(private _api: TfmsApiService) {}

  getPagedList(
    params: IPageParams,
    requestParams: { showExcluded: boolean }
  ): Observable<IPageReturn<Detector>> {
    const request = new GetIncidentsAnalysisDetectorsRequest();
    // requestParams.showExcluded = false;
    request.showExcluded = requestParams.showExcluded;
    request.showIncluded = !requestParams.showExcluded;
    setPageParams(request, params);
    return this._api.get(request).pipe(
      map((m) => {
        return getPageReturn(m);
      })
    );
  }

  getList(filter?: string, items?: number): Observable<Detector[]> {
    const request = new GetIncidentsAnalysisDetectorsRequest();
    request.page = 0;
    request.items = items ? items : 0;
    request.sort = '';
    request.filter = filter;

    return this._api.get(request).pipe(
      map((m) => {
        return m.items;
      })
    );
  }

  get(id: any): Observable<Detector> {
    // const request = new GetDetectorMaintenanceTypeRequest();
    // request.id = id;
    // return this._api.get(request);
    return null;
  }

  add(model: Detector) {
    // const request = new CreateDetectorMaintenanceTypeRequest();
    // request.entity = model;
    // return this._api.post(request);
    return null;
  }

  update(model: Detector) {
    // const request = new UpdateDetectorMaintenanceTypeRequest();
    // request.id = model.id;
    // request.entity = model;
    // return this._api.put(request);
    return null;
  }

  delete(ids: string[]): Observable<number> {
    // const request = new DeleteDetectorsMaintenanceTypeRequest();
    // request.ids = ids;
    // return this._api.delete(request);
    return null;
  }

  getAvailableToSelectDetectorDevicesRequest(filter?: string, items?: number): Observable<any[]> {
    const request = new GetAvailableToSelectDetectorDevicesRequest();
    request.page = 0;
    // request.items = items ? items : 0;
    request.sort = '';
    request.filter = filter;
    return this._api.get(request).pipe(
      map((m) => {
        return m.items;
      })
    );
  }
  getDetectorDeviceSelectedIncidentsRequest(filter?: string, items?: number): Observable<any[]> {
    const request = new GetDetectorDeviceSelectedIncidentsRequest();
    request.page = 0;
    // request.items = items ? items : 0;
    request.sort = '';
    request.filter = filter;
    return this._api.get(request).pipe(
      map((m) => {
        return m.items;
      })
    );
  }

  addToSelectedIncidentsDetectorDevicesRequest(detectorDeviceIds: string[]) {
    const request = new AddToSelectedIncidentsDetectorDevicesRequest();
    request.detectorDeviceIds = detectorDeviceIds;

    return this._api.post(request);
  }

  deleteSelectedIncidentsDetectorDevicesRequest(ids: string[]): Observable<number> {
    const request = new DeleteSelectedIncidentsDetectorDevicesRequest();
    request.ids = ids;
    return this._api.delete(request);
  }

  getPermissions(id: string, customerId: string) {
    return null;
  }
}
