<mat-sidenav-container>
  <mat-sidenav-content>
    <div fxLayout="column" style="flex: 1">
      <div class="header">
        <div>
          <img src="/assets/images/eputs/logo.svg" />
        </div>

        <div class="header-title" innerHtml="{{ 'EPUTS.TITLE_FULL' | translate }}">
          <!-- Единая платформа управления<br />
          транспортной системой -->
        </div>

        <div style="flex-grow: 1"></div>

        <!-- <div>clock</div> -->
      </div>

      <div class="modules-container">
        <div class="modules-wrapper">
          <div
            *ngFor="let module of modules"
            class="module-item"
            (click)="selectItem(module, 'module')"
            [class.disabled]="!module.id"
            [class.selectable]="module.enabled"
            [class.active]="selectedItem?.id == module.id"
          >
            <div>
              <img src="/assets/images/eputs/modules/{{ module.id || 'default' }}.svg" />
            </div>
            <div>{{ module.name | translate }}</div>
          </div>
        </div>
      </div>

      <div class="subsystems-container">
        <div class="subsystems-wrapper">
          <div
            *ngFor="let subsystem of subsystems"
            class="subsystem-item"
            (click)="selectItem(subsystem, 'subsystem')"
            [class.disabled]="!subsystem.id"
            [class.active]="selectedItem?.id == subsystem.id"
          >
            <div [style.background]="'url(/assets/images/eputs/subsystems/' + (subsystem.id || 'default') + '.jpg)'"></div>
            <div>
              {{ subsystem.name | translate }}
            </div>
          </div>
          <div style="min-width: 1px"></div>
        </div>
      </div>
    </div>
  </mat-sidenav-content>
  <mat-sidenav #sidenav mode="side" position="end" [autoFocus]="true" [opened]="selectedItem">
    <div style="height: 100%; width: 100%; display: flex; flex-direction: column">
      <div fxLayout="column" class="login-container">
        <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="16px" style="padding-bottom: 48px">
          <img src="/assets/images/eputs/city.svg" />
          <div style="text-transform: uppercase; font-weight: 700; font-size: 24px">
            <!-- {{ city/region }} -->
          </div>
        </div>
        <form [formGroup]="formGroup" (ngSubmit)="submit()" style="display: flex">
          <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="24px" style="flex: 1">
            <div class="login-input">
              <mat-icon>person</mat-icon>
              <input
                matInput
                type="text"
                [formControl]="username"
                placeholder="{{ 'COMMON.USERNAME' | translate }}"
                autocomplete="off"
                spellcheck="false"
              />
            </div>
            <div class="login-input">
              <mat-icon>lock</mat-icon>
              <input
                matInput
                type="password"
                [formControl]="password"
                placeholder="{{ 'USERS.PASSWORD' | translate }}"
                autocomplete="new-password"
                spellcheck="false"
              />
            </div>
            <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="16px">
              <button mat-flat-button type="submit" class="login-button">{{ "USERS.SIGN_IN" | translate }}</button>
              <div>
                <mat-error class="mat-small">
                  {{ getErrorMessage() }}
                </mat-error>
              </div>
            </div>
          </div>
        </form>
      </div>
      <div class="sidenav-details">
        <div class="sidenav-details-header" fxLayoutAlign="start center" fxLayout fxLayoutGap="24px">
          <div
            *ngIf="selectedItem?.type == 'subsystem'"
            class="img"
            [style.background-image]="'url(/assets/images/eputs/subsystems/' + (selectedItem.id || 'default') + '.jpg)'"
          ></div>
          <img *ngIf="selectedItem?.type == 'module'" class="img" src="/assets/images/eputs/modules/{{ selectedItem.id || 'default' }}.svg" />
          <div>{{ selectedItem?.name | translate }}</div>
        </div>
        <div
          class="sidenav-details-content"
          [innerHtml]="selectedItemContent"
          style="padding-left: 12px; padding-top: 12px; line-height: 1.4em; color: #aaa;"
        ></div>
      </div>
    </div>
  </mat-sidenav>
</mat-sidenav-container>

<!--temp-->
<mat-form-field style="display: none">
  <input matInput />
</mat-form-field>
<!--temp-->
