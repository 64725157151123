import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';

import { Component, ChangeDetectionStrategy, Injector } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

import { DataSourceForm } from '../../data/data-source-form';
import { ExternalSystem } from '../../dtos/hub.dtos';

import { ExternalSystemService } from '../../services/external-system.service';
import { TableBaseComponent } from '../tables/table-base.component';
import { ExternalSystemEditComponent } from './external-system-edit.component';
import { Router } from '@angular/router';

@Component({
  selector: 'external-systems-component',
  templateUrl: './external-systems.component.html',
  styles: [
    `
      :host {
        flex: 1;
        padding: 24px;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ExternalSystemsComponent extends TableBaseComponent<
  ExternalSystem,
  ExternalSystem
> {
  displayedColumns = ['select', 'name', 'url', 'userName', 'actions'];

  rowActions = [
    {
      action: 'edit',
      name: _('COMMON.EDIT'),
      icon: 'edit',
    },
    {
      action: 'delete',
      name: _('COMMON.DELETE'),
      icon: 'delete',
    },
  ];

  msgAddSaved = _('EXTERNAL_SYSTEMS.EXTERNAL_SYSTEM_ADDED');
  msgEditSaved = _('COMMON.CHANGES_SAVED');
  msgDeleteConfirm = _('EXTERNAL_SYSTEMS.EXTERNAL_SYSTEM_DELETE?');
  msgDeleteSuccess = _('EXTERNAL_SYSTEMS.EXTERNAL_SYSTEM_DELETED');
  msgDeleteSelectedConfirm = _('EXTERNAL_SYSTEMS.EXTERNAL_SYSTEMS_DELETE?');
  msgDeleteSelectedSuccess = _('EXTERNAL_SYSTEMS.EXTERNAL_SYSTEMS_DELETED');

  constructor(private _router: Router, injector: Injector) {
    super(injector, injector.get(ExternalSystemService));
  }

  getNew() {
    return new ExternalSystem({});
  }

  getEdit(id) {
    return this.dataService.get(id);
  }

  getEditDialog(): MatDialogRef<DataSourceForm<ExternalSystem>, any> {
    return this.dialogService.dialog.open(ExternalSystemEditComponent, {
      disableClose: true,
    });
  }

  close() {
    this._router.navigate(['']);
  }
}
