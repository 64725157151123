import { Injectable } from '@angular/core';
import {
  getPageReturn,
  IDataSourceService,
  IPageParams,
  IPageReturn,
  setPageParams,
} from 'projects/msu-its-web-common/src/data/data-source';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  CreateDetectorDeviceIncidentRequest,
  DataType,
  DeleteDetectorDeviceIncidentRequest,
  DetectorDeviceIncident,
  DetectorIncidentType,
  GetDetectorDeviceIncidentRequest,
  GetDetectorDeviceIncidentsReportRequest,
  GetDetectorDeviceIncidentsRequest,
  GetIncidentsReportDownloadLinkRequest,
  IncidentStatus,
  UpdateDetectorDeviceIncidentRequest,
} from '../dtos/tfms.dtos';
import { TfmsApiService } from './tfms-api.service';

@Injectable({
  providedIn: 'root',
})
export class DetectorDeviceIncidentsService
  implements IDataSourceService<DetectorDeviceIncident, DetectorDeviceIncident> {
  constructor(private _api: TfmsApiService) {}

  getPagedList(
    params: IPageParams,
    requestParams: {
      start: Date;
      end: Date;
      status: IncidentStatus;
      incidentType: DetectorIncidentType;
    }
  ): Observable<IPageReturn<DetectorDeviceIncident>> {
    const request = new GetDetectorDeviceIncidentsRequest();
    request.incidentType = requestParams.incidentType;
    request.status = requestParams.status;
    request.start = !requestParams.start ? '' : requestParams.start.toISOString();
    request.end = !requestParams.end ? '' : requestParams.end.toISOString();
    setPageParams(request, params);
    return this._api.get(request).pipe(
      map((m) => {
        return getPageReturn(m);
      })
    );
  }

  getList(filter?: string, items?: number): Observable<DetectorDeviceIncident[]> {
    const request = new GetDetectorDeviceIncidentsRequest();
    request.page = 0;
    request.items = items ? items : 0;
    request.sort = '';
    request.filter = filter;

    return this._api.get(request).pipe(
      map((m) => {
        return m.items;
      })
    );
  }

  get(id: any): Observable<DetectorDeviceIncident> {
    const request = new GetDetectorDeviceIncidentRequest();
    request.id = id;
    return this._api.get(request);
  }

  add(model: DetectorDeviceIncident) {
    const request = new CreateDetectorDeviceIncidentRequest();
    request.entity = model;
    return this._api.post(request);
  }

  update(model: DetectorDeviceIncident) {
    const request = new UpdateDetectorDeviceIncidentRequest();
    request.id = model.id;
    request.entity = model;
    return this._api.put(request);
  }

  delete(ids: string[]): Observable<number> {
    const request = new DeleteDetectorDeviceIncidentRequest();
    request.ids = ids;
    return this._api.delete(request);
  }

  getPermissions(id: string, customerId: string) {
    return null;
  }
  getIncidentsReportDownloadLinkRequest() {
    const request = new GetIncidentsReportDownloadLinkRequest();
    return this._api.get(request);
  }
  getDetectorDeviceIncidentsReportRequest(
    start: Date,
    end: Date,
    status: IncidentStatus,
    incidentType: DetectorIncidentType,
    link: string
  ) {
    const request = new GetDetectorDeviceIncidentsReportRequest();
    request.incidentType = incidentType;
    request.status = status;
    request.start = !start ? '' : start.toISOString();
    request.end = !end ? '' : end.toISOString();
    request.link = link;
    request.timeZone = 7;
    request.type = DataType.Xls;
    return this._api.get(request);
  }
}

// export class DetectorDeviceIncidentsService {
//   constructor(private _api: TfmsApiService) {}

//   getDetectorDeviceIncidentRequest(id): Observable<DetectorDeviceIncident> {
//     const request = new GetDetectorDeviceIncidentRequest();
//     request.id = id;
//     return this._api.get(request);
//   }

//   getDetectorDeviceIncidentsRequest(
//     page: number,
//     size: number,
//     sort: string,
//     filter: string,
//     // isResolved?: boolean,
//     incidentType?: DetectorIncidentType
//   ): Observable<DetectorDeviceIncidentsResponse> {
//     const request = new GetDetectorDeviceIncidentsRequest();
//     request.items = size;
//     request.page = page;
//     request.sort = sort;
//     request.filter = filter;
//     request.detectorId
//     // request..
//     // request.detectorDeviceId = isResolved;
//     request.incidentType = incidentType;
//     return this._api.get(request);
//   }

//   //   createCatchDogsRequest(item: CatchDog) {
//   //     const request = new CreateCatchDogsRequest();
//   //     request.Entity = item;
//   //     return this._api.post(request);
//   //   }

//   updateDetectorDeviceIncidentRequest(
//     id: string,
//     item: DetectorDeviceIncident
//   ): Observable<DetectorDeviceIncident> {
//     const request = new UpdateDetectorDeviceIncidentRequest();
//     request.id = id;
//     request.entity = item;
//     request.entity.id = id;
//     return this._api.put(request);
//   }

//   //   deleteCatchDogsRequest(id: string[]) {
//   //     const request = new DeleteCatchDogsRequest();
//   //     request.Ids = id;
//   //     return this._api.delete(request);
//   //   }
// }
