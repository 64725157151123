import { TrafficMovementType } from './tlc.dtos';
import { Schema } from './schema';

export interface ILabels {
  majorStreet: string;
  minorStreet: string;
  way2: string;
  way4: string;
  way6: string;
  way8: string;
}

export class LayoutSvgUtils {
  public static updateLayoutSchema(svg, schema: Schema, labels: ILabels, isDark: boolean) {
    const doc = svg;

    const center = doc.getElementById('center');
    const way_2 = doc.getElementById('way-2');
    const way_4 = doc.getElementById('way-4');
    const way_6 = doc.getElementById('way-6');
    const way_8 = doc.getElementById('way-8');
    const corner_2_4 = doc.getElementById('corner-2-4');
    const corner_4_6 = doc.getElementById('corner-4-6');
    const corner_6_8 = doc.getElementById('corner-6-8');
    const corner_8_2 = doc.getElementById('corner-8-2');
    const without_2 = doc.getElementById('without-2');
    const without_4 = doc.getElementById('without-4');
    const without_6 = doc.getElementById('without-6');
    const without_8 = doc.getElementById('without-8');
    const pedestrian = doc.getElementById('pedestrian');
    const pedestrian_2 = doc.getElementById('pedestrian-2');
    const pedestrian_4 = doc.getElementById('pedestrian-4');
    const pedestrian_6 = doc.getElementById('pedestrian-6');
    const pedestrian_8 = doc.getElementById('pedestrian-8');
    const label_2 = doc.getElementById('label-2');
    const label_4 = doc.getElementById('label-4');
    const label_6 = doc.getElementById('label-6');
    const label_8 = doc.getElementById('label-8');
    const label_rect_2 = doc.getElementById('label-rect-2');
    const label_rect_4 = doc.getElementById('label-rect-4');
    const label_rect_6 = doc.getElementById('label-rect-6');
    const label_rect_8 = doc.getElementById('label-rect-8');
    const street_major_1 = doc.getElementById('major-street-1');
    const street_major_2 = doc.getElementById('major-street-2');
    const street_minor_1 = doc.getElementById('minor-street-1');
    const street_minor_2 = doc.getElementById('minor-street-2');
    const twoways_2 = doc.getElementById('twoways-2');
    const twoways_4 = doc.getElementById('twoways-4');
    const twoways_6 = doc.getElementById('twoways-6');
    const twoways_8 = doc.getElementById('twoways-8');
    const oneway_2 = doc.getElementById('oneway-2');
    const oneway_4 = doc.getElementById('oneway-4');
    const oneway_6 = doc.getElementById('oneway-6');
    const oneway_8 = doc.getElementById('oneway-8');

    const hide = [];
    const show = [center];
    const text = [];
    const color = [];

    text.push([street_major_1, labels.majorStreet]);
    text.push([street_major_2, labels.majorStreet]);
    text.push([street_minor_1, labels.minorStreet]);
    text.push([street_minor_2, labels.minorStreet]);

    schema.getIsPedestrian() ? show.push(pedestrian) : hide.push(pedestrian);

    if (schema.getWay2Enabled()) {
      hide.push(without_2, schema.getWay2Twoways() ? oneway_2 : twoways_2);
      show.push(way_2, corner_2_4, schema.getWay2Twoways() ? twoways_2 : oneway_2);
      schema.getMoveP4Enabled() && !schema.getIsPedestrian()
        ? show.push(pedestrian_4)
        : hide.push(pedestrian_4);
      text.push([label_2, labels.way2]);
    } else {
      show.push(without_2);
      hide.push(way_2, corner_2_4, pedestrian_4, twoways_2, oneway_2);
      text.push(/*[label_2, ''],*/ [street_major_1, '']);
      color.push(label_rect_2);
    }
    if (schema.getWay4Enabled()) {
      hide.push(without_4, schema.getWay4Twoways() ? oneway_4 : twoways_4);
      show.push(way_4, corner_4_6, schema.getWay4Twoways() ? twoways_4 : oneway_4);
      schema.getMoveP6Enabled() ? show.push(pedestrian_6) : hide.push(pedestrian_6);
      text.push([label_4, labels.way4]);
    } else {
      show.push(without_4);
      hide.push(way_4, corner_4_6, pedestrian_6, twoways_4, oneway_4);
      text.push(/*[label_4, ''],*/ [street_minor_1, '']);
      color.push(label_rect_4);
    }
    if (schema.getWay6Enabled()) {
      hide.push(without_6, schema.getWay6Twoways() ? oneway_6 : twoways_6);
      show.push(way_6, corner_6_8, schema.getWay6Twoways() ? twoways_6 : oneway_6);
      schema.getMoveP8Enabled() && !schema.getIsPedestrian()
        ? show.push(pedestrian_8)
        : hide.push(pedestrian_8);
      text.push([label_6, labels.way6]);
    } else {
      show.push(without_6);
      hide.push(way_6, corner_6_8, pedestrian_8, twoways_6, oneway_6);
      text.push(/*[label_6, ''],*/ [street_major_2, '']);
      color.push(label_rect_6);
    }
    if (schema.getWay8Enabled()) {
      hide.push(without_8, schema.getWay8Twoways() ? oneway_8 : twoways_8);
      show.push(way_8, corner_8_2, schema.getWay8Twoways() ? twoways_8 : oneway_8);
      schema.getMoveP2Enabled() ? show.push(pedestrian_2) : hide.push(pedestrian_2);
      text.push([label_8, labels.way8]);
    } else {
      show.push(without_8);
      hide.push(way_8, corner_8_2, pedestrian_2, twoways_8, oneway_8);
      text.push(/*[label_8, ''],*/ [street_minor_2, '']);
      color.push(label_rect_8);
    }

    text.push(
      [label_2, labels.way2],
      [label_4, labels.way4],
      [label_6, labels.way6],
      [label_8, labels.way8]
    );

    if (show.some((s) => s === without_2)) {
      hide.push(corner_8_2);
    }
    if (show.some((s) => s === without_4)) {
      hide.push(corner_2_4);
    }
    if (show.some((s) => s === without_6)) {
      hide.push(corner_4_6);
    }
    if (show.some((s) => s === without_8)) {
      hide.push(corner_6_8);
    }

    show.forEach((el) => {
      if (el) {
        el.removeAttribute('display');
        el.style.display = null;
      }
    });
    hide.forEach((el) => {
      if (el) {
        el.setAttribute('display', 'none');
        el.style.display = 'none';
      }
    });
    text.forEach((t) => {
      if (t[0]) {
        t[0].textContent = t[1];
      }
    });

    [label_rect_2, label_rect_4, label_rect_6, label_rect_8].forEach((el) => {
      if (el) {
        el.style.fill = color.indexOf(el) >= 0 ? (isDark ? '#fff' : '#333') : '#fff';
      }
    });

    const items = doc.getElementsByClassName('street-label');
    for (let i = 0; i < items.length; i++) {
      items[i].style.fill = isDark ? '#fff' : '#333';
    }
  }

  public static updateLayoutControl(svg, schema: Schema) {
    const hide = [];

    !schema.getWay2Enabled() && hide.push('control-2');
    !schema.getWay4Enabled() && hide.push('control-4');
    !schema.getWay6Enabled() && hide.push('control-6');
    !schema.getWay8Enabled() && hide.push('control-8');

    !(schema.Move1.enabled && schema.Move1.controlled) && hide.push(TrafficMovementType.Move1);
    !(schema.Move3.enabled && schema.Move3.controlled) && hide.push(TrafficMovementType.Move3);
    !(schema.Move5.enabled && schema.Move5.controlled) && hide.push(TrafficMovementType.Move5);
    !(schema.Move7.enabled && schema.Move7.controlled) && hide.push(TrafficMovementType.Move7);

    !(schema.Move12.enabled && schema.Move12.controlled) && hide.push(TrafficMovementType.Move12);
    !(schema.Move14.enabled && schema.Move14.controlled) && hide.push(TrafficMovementType.Move14);
    !(schema.Move16.enabled && schema.Move16.controlled) && hide.push(TrafficMovementType.Move16);
    !(schema.Move18.enabled && schema.Move18.controlled) && hide.push(TrafficMovementType.Move18);

    !schema.Move2.enabled &&
      !(schema.Move12.enabled && !schema.Move12.controlled) &&
      !(schema.Move5.enabled && !schema.Move5.controlled) &&
      hide.push(TrafficMovementType.Move2);
    !schema.Move4.enabled &&
      !(schema.Move14.enabled && !schema.Move14.controlled) &&
      !(schema.Move7.enabled && !schema.Move7.controlled) &&
      hide.push(TrafficMovementType.Move4);
    !schema.Move6.enabled &&
      !(schema.Move16.enabled && !schema.Move16.controlled) &&
      !(schema.Move1.enabled && !schema.Move1.controlled) &&
      hide.push(TrafficMovementType.Move6);
    !schema.Move8.enabled &&
      !(schema.Move18.enabled && !schema.Move18.controlled) &&
      !(schema.Move3.enabled && !schema.Move3.controlled) &&
      hide.push(TrafficMovementType.Move8);

    !schema.MoveP2.enabled && hide.push(TrafficMovementType.MoveP2);
    !schema.MoveP4.enabled && hide.push(TrafficMovementType.MoveP4);
    !schema.MoveP6.enabled && hide.push(TrafficMovementType.MoveP6);
    !schema.MoveP8.enabled && hide.push(TrafficMovementType.MoveP8);

    const deadend = [];
    schema.Move2.deadEnd && deadend.push(TrafficMovementType.Move2);
    schema.Move4.deadEnd && deadend.push(TrafficMovementType.Move4);
    schema.Move6.deadEnd && deadend.push(TrafficMovementType.Move6);
    schema.Move8.deadEnd && deadend.push(TrafficMovementType.Move8);

    hide.forEach((m) => {
      const elm = svg.getElementById(m);
      const elmLbl = svg.getElementById(m + '_Label');
      if (elm) {
        elm.setAttribute('display', 'none');
        elm.style.display = 'none';
      }
      if (elmLbl) {
        elmLbl.setAttribute('display', 'none');
        elmLbl.style.display = 'none';
      }
    });

    deadend.forEach((m) => {
      const elm = svg.getElementById(m);
      elm.setAttribute('xlink:href', '#deadend');
    });
  }

  // public static showLayoutPhases(svg, schema: Schema, phaseTypes: PhaseType[]) {
  //   const movements = SchemaUtils.phaseTypesToMovemets(schema, phaseTypes);
  //   LayoutSvgUtils.showLayoutMovements(svg, schema, movements);
  // }

  // public static showLayoutState(svg, schema: Schema, state: SchemaState) {
  //   const movements = SchemaUtils.schemaStateToMovements(schema, state);
  //   LayoutSvgUtils.showLayoutMovements(svg, schema, movements);
  // }

  public static showLayoutMovements(svg, schema: Schema, showMovements: string[]) {
    const doc = svg;
    if (!doc) {
      return;
    }

    const hideMovements = [
      TrafficMovementType.Move1,
      TrafficMovementType.Move2,
      TrafficMovementType.Move3,
      TrafficMovementType.Move4,
      TrafficMovementType.Move5,
      TrafficMovementType.Move6,
      TrafficMovementType.Move7,
      TrafficMovementType.Move8,
      TrafficMovementType.Move12,
      TrafficMovementType.Move14,
      TrafficMovementType.Move16,
      TrafficMovementType.Move18,
      TrafficMovementType.MoveP2,
      TrafficMovementType.MoveP4,
      TrafficMovementType.MoveP6,
      TrafficMovementType.MoveP8,
      'MoveP',
    ];

    if (
      schema.getIsPedestrian() &&
      showMovements.some(
        (n) => n === TrafficMovementType.MoveP4 || n === TrafficMovementType.MoveP8
      )
    ) {
      showMovements = showMovements.filter(
        (n) => n !== TrafficMovementType.MoveP4 && n !== TrafficMovementType.MoveP8
      );
      showMovements.push('MoveP');
    }

    hideMovements.forEach((hideClass) => {
      if (!showMovements.some((showClass) => showClass === hideClass)) {
        const items = doc.getElementsByClassName(hideClass);
        for (let i = 0; i < items.length; i++) {
          LayoutSvgUtils._hideElement(items[i]);
        }
        const stopItems = doc.getElementsByClassName(hideClass.replace('Move', 'Stop'));
        for (let i = 0; i < stopItems.length; i++) {
          LayoutSvgUtils._showElement(items[i]);
        }
      }
    });
    showMovements.forEach((showClass) => {
      const items = doc.getElementsByClassName(showClass);
      for (let i = 0; i < items.length; i++) {
        LayoutSvgUtils._showElement(items[i]);
      }
      const stopItems = doc.getElementsByClassName(showClass.replace('Move', 'Stop'));
      for (let i = 0; i < stopItems.length; i++) {
        LayoutSvgUtils._hideElement(items[i]);
      }
    });
  }

  public static clearLayout(doc) {
    if (!doc) {
      return;
    }
    const hideClasses = [
      TrafficMovementType.Move1,
      TrafficMovementType.Move2,
      TrafficMovementType.Move3,
      TrafficMovementType.Move4,
      TrafficMovementType.Move5,
      TrafficMovementType.Move6,
      TrafficMovementType.Move7,
      TrafficMovementType.Move8,
      TrafficMovementType.Move12,
      TrafficMovementType.Move14,
      TrafficMovementType.Move16,
      TrafficMovementType.Move18,
      TrafficMovementType.MoveP2,
      TrafficMovementType.MoveP4,
      TrafficMovementType.MoveP6,
      TrafficMovementType.MoveP8,
      'MoveP',
    ];
    const hideIds = [
      'center',
      'way-2',
      'way-4',
      'way-6',
      'way-8',
      'corner-2-4',
      'corner-4-6',
      'corner-6-8',
      'corner-8-2',
      'without-2',
      'without-4',
      'without-6',
      'without-8',
      'pedestrian',
      'pedestrian-2',
      'pedestrian-4',
      'pedestrian-6',
      'pedestrian-8',
      'twoways-2',
      'twoways-4',
      'twoways-6',
      'twoways-8',
      'oneway-2',
      'oneway-4',
      'oneway-6',
      'oneway-8',
    ];
    const labelIds = [
      'label-2',
      'label-4',
      'label-6',
      'label-8',
      'major-street-1',
      'major-street-2',
      'minor-street-1',
      'minor-street-2',
    ];

    hideClasses.forEach((hideClass) => {
      const items = doc.getElementsByClassName(hideClass);
      for (let i = 0; i < items.length; i++) {
        LayoutSvgUtils._hideElement(items[i]);
      }
      const stopItems = doc.getElementsByClassName(hideClass.replace('Move', 'Stop'));
      for (let i = 0; i < stopItems.length; i++) {
        LayoutSvgUtils._hideElement(items[i]);
      }
    });
    hideIds.forEach((hideClass) => {
      const el = doc.getElementById(hideClass);
      LayoutSvgUtils._hideElement(el);
    });
    labelIds.forEach((labelId) => {
      const el = doc.getElementById(labelId);
      if (el) {
        el.textContent = '';
      }
    });
  }

  private static _showElement(el) {
    el.style.display = null;
    el.removeAttribute('display');
  }

  private static _hideElement(el) {
    el.style.display = 'none';
    el.setAttribute('display', 'none');
  }
}
