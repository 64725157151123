<table-layout-dialog
  [dataSource]="dataSource"
  [displayedColumns]="displayedColumns"
  [rowActions]="rowActions"
  titleText="{{ 'EVENT_LOG.TITLE' | translate }}{{
    entityName ? ': ' + entityName : ''
  }}"
  [showCreate]="false"
  [showDelete]="false"
  [showRefresh]="false"
>
  <ng-container table-layout-actions>
    <!-- <span>{{ "COMMON.DATE_RANGE" | translate }}</span> -->
    <mat-form-field style="margin-right: 8px; width: 160px">
      <input
        matInput
        type="datetime-local"
        [formControl]="fromControl"
        class="spin-button"
      />
      <mat-icon class="date-button">today</mat-icon>
    </mat-form-field>

    <span style="margin-right: 8px">-</span>

    <mat-form-field style="margin-right: 12px; width: 160px">
      <input
        matInput
        type="datetime-local"
        [formControl]="toControl"
        class="spin-button"
      />
      <mat-icon class="date-button">today</mat-icon>
    </mat-form-field>
  </ng-container>

  <ng-container table-layout-actions-end>
    <div style="padding-left: 8px">
      <button
        mat-raised-button
        color="accent"
        (click)="dataSource?.reloadData()"
      >
        <mat-icon>loop</mat-icon>
        {{ "COMMON.REFRESH" | translate }}
      </button>
    </div>
  </ng-container>

  <ng-container ngPackageAs="table-layout-columns" matSort>
    <ng-container matColumnDef="eventTime">
      <mat-header-cell *matHeaderCellDef mat-sort-header>
        {{ "COMMON.TIME" | translate }}
      </mat-header-cell>
      <mat-cell *matCellDef="let item">
        <b>
          {{ item.eventTime | date: "dd.MM.yyyy HH:mm":timezone:locale }}
        </b>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="eventType">
      <mat-header-cell *matHeaderCellDef mat-sort-header>
        {{ "EVENT_LOG.EVENT_TYPE" | translate }}
      </mat-header-cell>
      <mat-cell *matCellDef="let item">
        <b>{{ item.eventType }}</b>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="entityName">
      <mat-header-cell *matHeaderCellDef mat-sort-header>
        {{ "EVENT_LOG.ENTITY_NAME" | translate }}
      </mat-header-cell>
      <mat-cell *matCellDef="let item">
        <b>{{ item.entityName }}</b>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="description">
      <mat-header-cell *matHeaderCellDef mat-sort-header>
        {{ "COMMON.DESCRIPTION" | translate }}
      </mat-header-cell>
      <mat-cell *matCellDef="let item">
        <b>{{ item.description }}</b>
      </mat-cell>
    </ng-container>
  </ng-container>
</table-layout-dialog>

<!--
<ng-container matColumnDef="entityType">
  <mat-header-cell *matHeaderCellDef mat-sort-header>
    {{ "EVENT_LOG.ENTITY_TYPE" | translate }}
  </mat-header-cell>
  <mat-cell *matCellDef="let item">
    <b>{{ item.entityType }}</b>
  </mat-cell>
</ng-container>

<ng-container matColumnDef="entitySubType">
  <mat-header-cell *matHeaderCellDef mat-sort-header>
    {{ "EVENT_LOG.ENTITY_SUBTYPE" | translate }}
  </mat-header-cell>
  <mat-cell *matCellDef="let item">
    <b>{{ item.entitySubType }}</b>
  </mat-cell>
</ng-container>

<ng-container matColumnDef="moduleId">
  <mat-header-cell *matHeaderCellDef mat-sort-header>
    {{ "EVENT_LOG.MODULE" | translate }}
  </mat-header-cell>
  <mat-cell *matCellDef="let item">
    <b>{{ item.moduleId }}</b>
  </mat-cell>
</ng-container>
-->
