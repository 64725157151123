<h4 mat-dialog-title style="margin-bottom: 16px;">
  {{ "COMMON.SELECT_LANGUAGE" | translate }}
</h4>
<mat-dialog-content style="min-width: 250px;">
  <a tabindex="0"></a>
  <mat-action-list>
    <button
      *ngFor="let l of translate.langs"
      mat-list-item
      class="mat-hover"
      [class.mat-selected]="l == translate.currentLang"
      (click)="setLang(l)"
    >
      <img
        mat-list-icon
        class="flag"
        [src]="'/assets/images/langs/' + l + '.png'"
      />
      {{ langs[l] }}
    </button>
  </mat-action-list>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button [mat-dialog-close]="false">
    {{ "COMMON.CLOSE" | translate }}
  </button>
</mat-dialog-actions>
