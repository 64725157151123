import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

import { SettingsService } from './settings.service';

@Injectable({ providedIn: 'root' })
export class SidenavService {
  constructor(private _settingsService: SettingsService) {}

  showing = false;
  public changed = new Subject<boolean>();
  public changedAnimationDone = new Subject<boolean>();

  get expanded() {
    return this._settingsService.sidenavExpanded;
  }

  toggle(expanded?: boolean) {
    this._settingsService.sidenavExpanded =
      expanded === false || expanded === true
        ? expanded
        : !this._settingsService.sidenavExpanded;

    this.changed.next(this._settingsService.sidenavExpanded);
  }
}
