<div fxLayout="column" fxLayoutGap="12px" style="min-width: 550px; position: relative; width: 580px">
  <div *ngIf="showCenterBlock" class="backdrop" style="position: absolute; z-index: 5; pointer-events: none">
    <div class="backdrop-inner mat-dialog-bg mat-elevation-z4" style="pointer-events: all; max-width: 350px">
      <div *ngIf="controllerStatus?.mode == 'Engineer'" class="mat-primary-color" style="margin: 12px 0 24px; font-size: 20px; font-weight: 500">
        {{ "CONTROLLER_MODE.ENGINEER" | translate }}
      </div>

      <div *ngFor="let error of controllerStatus?.errors" style="margin-bottom: 12px">
        <div fxLayout fxLayoutGap="12px">
          <mat-icon *ngIf="controllerStatus?.errors" class="dialog-warn">warning</mat-icon>
          <div>
            {{ getErrorTypeName(error.type) | translate }}
            <div class="mat-small mat-secondary-color">
              {{ error.startTime | date: "shortDate":timezone:locale }}
              {{ error.startTime | date: "shortTime":timezone:locale }}
              ({{ getDurationString(error.startTime) }})
            </div>
            <div *ngIf="error.data" [innerHtml]="getErrorSplitData(error.data,error.type)" class="mat-small mat-secondary-color"></div>
            <div *ngIf="error.details" [innerHtml]="getErrorSplitData(error.details,error.type)" class="mat-small mat-secondary-color"></div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="loading" class="backdrop" style="position: absolute; z-index: 6">
    <div class="backdrop-inner mat-dialog-bg mat-elevation-z4" style="padding-bottom: 16px">
      <mat-spinner diameter="36"></mat-spinner>
    </div>
  </div>

  <potok-state-mode [disabled]="disabled"></potok-state-mode>
  <potok-state-controller></potok-state-controller>

  <div fxLayout="row" fxLayoutGap="12px">
    <potok-state-buttons [disabled]="disabled"></potok-state-buttons>

    <div class="mat-elevation-z2" style="flex: 1; display: flex; flex-direction: column; overflow: hidden; min-height: 460px; max-height: 560px">
      <mat-tab-group mat-stretch-tabs [selectedIndex]="selectedTab" (selectedIndexChange)="selectedTab = $event" animationDuration="0">
        <ng-container>
          <mat-tab [label]="'TRAFFIC_OBJECTS.SCHEMA' | translate"></mat-tab>
          <mat-tab [label]="'TRAFFIC_OBJECTS.TIME_LOG' | translate"></mat-tab>
          <!--
          <mat-tab [label]="'TRAFFIC_OBJECTS.DETECTORS'|translate" disabled></mat-tab>
          -->
        </ng-container>
      </mat-tab-group>
      <potok-state-schema style="display: none" [style.display]="selectedTab == 0 ? 'flex' : 'none'"></potok-state-schema>
      <potok-state-timelog style="display: none" [style.display]="selectedTab == 1 ? 'flex' : 'none'"> </potok-state-timelog>
    </div>
  </div>

  <potok-state-adaptive [status]="adaptiveStatus"></potok-state-adaptive>
</div>
