import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { PotokBarrierType, PotokControllerErrorType, PotokPlanType } from '../tlc.dtos';

export const POTOK_PLAN_TYPES = [
  { type: PotokPlanType.PhaseCycle, name: _('PLAN_TYPES.PHASE_CYCLE') },
  { type: PotokPlanType.YellowBlink, name: _('PLAN_TYPES.YELLOW_BLINK') },
  { type: PotokPlanType.Off, name: _('PLAN_TYPES.OFF') },
];

export const POTOK_BARRIER_TYPES = [
  { type: PotokBarrierType.MajorStreet, name: _('PLANS.BARRIER_MAJOR_STREET') },
  { type: PotokBarrierType.MinorStreet, name: _('PLANS.BARRIER_MINOR_STREET') },
  { type: PotokBarrierType.Static, name: _('PLANS.BARRIER_STATIC') },
];

export const POTOK_ERROR_TYPES = [
  {
    type: PotokControllerErrorType.AnalogInputsNotConfigured,
    name: _('POTOK_ERROR_TYPES.ANALOG_INPUT_NOT_CONFIGURED'),
  },
  {
    type: PotokControllerErrorType.LampControl,
    name: _('POTOK_ERROR_TYPES.LAMP_CONTROL'),
  },
  {
    type: PotokControllerErrorType.ModbusNoConnection,
    name: _('POTOK_ERROR_TYPES.MODBUS_CONNECTION'),
  },
  {
    type: PotokControllerErrorType.MqttNoConnection,
    name: _('POTOK_ERROR_TYPES.MQTT_NO_CONNECTION'),
  },
  {
    type: PotokControllerErrorType.PowerOff,
    name: _('POTOK_ERROR_TYPES.POWER_OFF'),
  },
  {
    type: PotokControllerErrorType.SchemaNotConfigured,
    name: _('POTOK_ERROR_TYPES.SCHEMA_NOT_CONFIGURED'),
  },
  {
    type: PotokControllerErrorType.ButtonOffline,
    name: _('POTOK_ERROR_TYPES.BUTTON_OFFLINE'),
  },
  {
    type: PotokControllerErrorType.Offline,
    name: _('POTOK_ERROR_TYPES.OFFLINE'),
  },
  {
    type: PotokControllerErrorType.ButtonSensorError,
    name: _('POTOK_ERROR_TYPES.BUTTON_SENSOR_ERROR'),
  },
  {
    type: PotokControllerErrorType.InsufficientDiskSpace,
    name: _('POTOK_ERROR_TYPES.INSUFFICIENT_DISK_SPACE'),
  },
  {
    type: PotokControllerErrorType.DiagnosticMode,
    name: _('POTOK_ERROR_TYPES.DIAGNOSTIC_MODE'),
  },
  {
    type: PotokControllerErrorType.ButtonSensorStuckError,
    name: _('POTOK_ERROR_TYPES.BUTTON_SENSOR_STUCK_ERROR'),
  },
  {
    type: PotokControllerErrorType.HighCpuUsage,
    name: _('POTOK_ERROR_TYPES.HIGH_CPU_USAGE'),
  },
  {
    type: PotokControllerErrorType.BadTimeZone,
    name: _('POTOK_ERROR_TYPES.BAD_TIME_ZONE'),
  },
  {
    type: PotokControllerErrorType.ClockNotSync,
    name: _('POTOK_ERROR_TYPES.CLOCK_NOT_SYNC'),
  },
];
