<div class="remote">
  <ng-container *ngFor="let items of phases; let j = index">
    <ng-container *ngFor="let item of items; let i = index">
      <button
        mat-raised-button
        [class.mat-accent]="isSelected(item)"
        class="mat-button-hover"
        [style.grid-column]="j + 1"
        [style.grid-row]="i + 1"
        (click)="select(item)"
        style="display: block"
      >
        <div class="remote-svg mat-border">
          <object
            type="image/svg+xml"
            data="/assets/images/tlc/layout_button.svg"
            (load)="setSvgState($event.target, item)"
            [style.transform]="'rotate(' + schemaView.angle + 'deg)'"
          ></object>
        </div>
      </button>
    </ng-container>
  </ng-container>
</div>
