import { Injectable } from "@angular/core";

import { Observable, of } from "rxjs";
import { map } from "rxjs/operators";

import {
  IDataSourceService,
  setPageParams,
  getPageReturn,
  IPageParams,
  IPageReturn,
} from "projects/msu-its-web-common/src/data/data-source";

import {
  CreateTrafficObjectRequest,
  DeleteTrafficObjectsRequest,
  TrafficObject,
  TrafficObjectInfo,
  GetTrafficObjectRequest,
  GetTrafficObjectsRequest,
  UpdateTrafficObjectRequest,
  TrafficObjectSchemaSet,
  GetTrafficObjectSchemaRequest,
  UpdateTrafficObjectSchemaRequest,
  GetTrafficObjectControllersRequest,
  TrafficControllerType,
  GetTrafficObjectExistRequest,
  TrafficObjectChangeCustomerRequest,
  DetachTrafficObjectControllerRequest,
  GetTrafficObjectCurrentUserPermissionsRequest,
  GetTrafficObjectMovementsRequest,
  GetControllerEventTypesRequest,
  GetTrafficObjectChangeIntervalsRequest,
  AdaptiveType,
  GetTrafficObjectPlansRequest,
} from "../dtos/tlc.dtos";

import { SchemaSet } from "../dtos/schema";
import { TlcApiService } from "./tlc-api.service";

@Injectable()
export class TrafficObjectService
  implements IDataSourceService<TrafficObject, TrafficObjectInfo> {
  constructor(private _api: TlcApiService) {}

  getPagedList(
    params: IPageParams,
    customerId: string
  ): Observable<IPageReturn<TrafficObjectInfo>> {
    if (!customerId) {
      return of(null);
    }

    const request = new GetTrafficObjectsRequest();
    request.customerId = customerId;

    setPageParams(request, params);
    return this._api.get(request).pipe(
      map((m) => {
        return getPageReturn(m);
      })
    );
  }
  getList(
    customerId: string,
    filter?: string,
    groupIds?: string[],
    adaptiveType?: AdaptiveType,
    itemsIds?: string[]
  ): Observable<TrafficObjectInfo[]> {
    const request = new GetTrafficObjectsRequest();
    request.customerId = customerId;
    request.entityGroups = groupIds;
    request.adaptiveType = adaptiveType;
    request.page = 0;
    request.items = 0;
    request.sort = "name";
    request.filter = filter;
    request.itemsIds = itemsIds;

    return this._api.get(request).pipe(
      map((m) => {
        return m.items;
      })
    );
  }

  getListSearch(
    customerId: string,
    filter?: string,
    groupIds?: string[],
    adaptiveType?: AdaptiveType,
    items?: number
  ): Observable<TrafficObjectInfo[]> {
    const request = new GetTrafficObjectsRequest();
    request.customerId = customerId;
    request.entityGroups = groupIds;
    request.adaptiveType = adaptiveType;
    request.page = 0;
    request.items = items;
    request.sort = "name";
    request.filter = filter;

    return this._api.get(request).pipe(
      map((m) => {
        return m.items;
      })
    );
  }

  get(id: string, customerId: string) {
    const request = new GetTrafficObjectRequest();
    request.id = id;
    request.customerId = customerId;
    return this._api.get(request);
  }
  add(
    model: TrafficObject,
    params: {
      customerId: string;
      groups: string[];
      updateGroups: boolean;
      schemaSet: TrafficObjectSchemaSet;
    }
  ) {
    const request = new CreateTrafficObjectRequest();
    request.entity = model;
    request.schemaSet = params.schemaSet;
    request.customerId = params.customerId;
    request.entityGroups = params.groups;
    request.updateEntityGroups = params.updateGroups;
    return this._api.post(request);
  }
  update(
    model: TrafficObject,
    params: { customerId: string; groups: string[]; updateGroups: boolean }
  ) {
    const request = new UpdateTrafficObjectRequest();
    request.id = model.id;
    request.entity = model;
    request.customerId = params.customerId;
    request.entityGroups = params.groups;
    request.updateEntityGroups = params.updateGroups;
    return this._api.put(request);
  }
  delete(ids: string[], customerId: string) {
    const request = new DeleteTrafficObjectsRequest();
    request.ids = ids;
    request.customerId = customerId;
    return this._api.delete(request);
  }
  exist(name: string, customerId: string): Observable<boolean> {
    const request = new GetTrafficObjectExistRequest({ name, customerId });
    return this._api.get(request);
  }

  getSchemaSet(id: string, customerId: string) {
    const request = new GetTrafficObjectSchemaRequest();
    request.id = id;
    request.customerId = customerId;
    return this._api.get(request).pipe(map((m) => this._assignSchemaSet(m)));
  }
  updateSchemaSet(
    id: string,
    customerId: string,
    schemaSet: TrafficObjectSchemaSet
  ) {
    const request = new UpdateTrafficObjectSchemaRequest();
    request.id = id;
    request.entity = schemaSet;
    request.entity.id = id;
    request.customerId = customerId;
    return this._api.put(request).pipe(map((m) => this._assignSchemaSet(m)));
  }
  _assignSchemaSet(schemaSet: TrafficObjectSchemaSet) {
    const result = new SchemaSet();
    result.schema = Object.assign(result.schema, schemaSet.schema);
    result.schemaView = Object.assign(result.schemaView, schemaSet.schemaView);
    return result;
  }

  getControllerList(
    filter: string,
    customerId: string,
    items = 0,
    controllerType?: TrafficControllerType,
    onlyFree?: boolean
  ) {
    const request = new GetTrafficObjectControllersRequest();

    request.customerId = customerId;
    request.filter = filter;
    request.controllerType = controllerType;
    request.onlyFree = onlyFree;
    request.page = 0;
    request.items = items;

    return this._api.get(request).pipe(map((m) => m.items));
  }

  changeCustomer(
    id: string,
    customerId: string,
    params: { newCustomerId: string; description: string }
  ) {
    const request = new TrafficObjectChangeCustomerRequest();
    request.id = id;
    request.customerId = customerId;
    request.description = params.description;
    request.newCustomerId = params.newCustomerId;
    return this._api.post(request);
  }

  detachController(id: string, customerId: string, description: string) {
    const request = new DetachTrafficObjectControllerRequest();
    request.id = id;
    request.description = description;
    request.customerId = customerId;
    return this._api.post(request);
  }

  getPermissions(id: string, customerId: string) {
    const request = new GetTrafficObjectCurrentUserPermissionsRequest();
    request.id = id;
    request.customerId = customerId;
    return this._api.get(request);
  }

  getMovements(id: string, customerId: string) {
    const request = new GetTrafficObjectMovementsRequest();
    request.id = id;
    request.customerId = customerId;
    return this._api.get(request);
  }

  getControllerEventTypes(id: string, customerId: string) {
    const request = new GetControllerEventTypesRequest();
    request.id = id;
    request.customerId = customerId;
    return this._api.get(request);
  }

  getChangeIntervals(id: string, customerId: string) {
    const request = new GetTrafficObjectChangeIntervalsRequest();
    request.id = id;
    request.customerId = customerId;
    return this._api.get(request);
  }

  getPlans(id: string, customerId: string) {
    const request = new GetTrafficObjectPlansRequest();
    request.id = id;
    request.customerId = customerId;
    return this._api.get(request);
  }

  // getController(id: string, customerId: string) {
  //   const request = new GetTrafficObjectControllerRequest();
  //   request.id = id;
  //   request.customerId = customerId;
  //   return this._api.get(request);
  // }
}
