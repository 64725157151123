import { Injectable, OnDestroy } from '@angular/core';
import { PanelStand, PanelStandStatus } from 'projects/msu-its-web-ruis/src/dtos/ruis.dtos';

import { BehaviorSubject, EMPTY, Observable } from 'rxjs';
import { finalize, shareReplay } from 'rxjs/operators';

@Injectable()
export class PanelStandStateService implements OnDestroy {
  modelId: string;
  customerId: string;

  private readonly _loading = new BehaviorSubject<boolean>(false);
  readonly loading$ = this._loading.pipe(shareReplay(1));
  get loading(): boolean {
    return this._loading.getValue();
  }
  set loading(value) {
    this._loading.next(value);
  }

  private readonly _panelStand = new BehaviorSubject<PanelStand>(null);
  readonly panelStand$ = this._panelStand.pipe(shareReplay(1));
  get panelStand(): PanelStand {
    return this._panelStand.getValue();
  }
  set panelStand(value) {
    this._panelStand.next(value);
  }

  private readonly _status = new BehaviorSubject<PanelStandStatus>(null);
  readonly status$ = this._status.pipe(shareReplay(1));
  get status(): PanelStandStatus {
    return this._status.getValue();
  }
  set status(value) {
    this._status.next(value);
  }

  constructor() {}

  ngOnDestroy() {
    this._panelStand.complete();
    this._status.complete();
  }

  loadingQuery<T>(query: Observable<T>) {
    if (this.loading) return EMPTY;
    this.loading = true;
    return query.pipe(finalize(() => (this.loading = false)));
  }
}
