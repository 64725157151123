import { Injectable } from "@angular/core";

import { Observable, of } from "rxjs";
import { map } from "rxjs/operators";

import {
  IGisObject,
  IGisObjectGroup,
  IGisObjectGroupService,
} from "projects/msu-its-web-common/src/utils/gis-object-group";

import {
  getPageReturn,
  IDataSourceService,
  IPageParams,
  IPageReturn,
  setPageParams,
} from "projects/msu-its-web-common/src/data/data-source";

import { VehicleService } from "./vehicle.service";
import { RmmdApiService } from "../rmmd-api.service";
import {
  CreateMaintenanceVehicleGroupRequest,
  DeleteMaintenanceVehicleGroupsRequest,
  EntityGroup,
  EntityGroupInfo,
  GetItemEntityGroupsRequest,
  GetMaintenanceVehicleGroupRequest,
  GetMaintenanceVehicleGroupsRequest,
  MaintenanceVehicleType,
  UpdateMaintenanceVehicleGroupRequest,
} from "../../dtos/rmmd.dtos";

@Injectable()
export class VehicleGroupService
  implements
    IDataSourceService<EntityGroup, EntityGroupInfo>,
    IGisObjectGroupService {
  constructor(
    private _api: RmmdApiService,
    private _vehicleService: VehicleService
  ) {}

  permissions = ["maintenanceVehicle.read"];

  getGroupList(customerId: string) {
    return this.getList(customerId);
  }
  getObjectList(customerId: string, groupIds: string[]) {
    return this._vehicleService.getList(customerId, null, groupIds).pipe(
      map((m) => {
        const result: IGisObject[] = m.map((n) => ({
          id: n.id,
          name: `${
            n.vehicleType === MaintenanceVehicleType.SnowRemoval
              ? "Снегоуборочная машина"
              : n.externalData
              ? n.model
              : "Неизвестная модель"
          }: ${n.number}`,
          changeKey: n.changeKey,
          description: `${
            n.vehicleType === MaintenanceVehicleType.SnowRemoval
              ? "Снегоуборочная машина"
              : n.externalData
              ? n.model
              : "Неизвестная модель"
          }<br/>Номер: ${n.number}
          <br/>Подрядчик: ${n.contractorName}`,
          entityType: n.entityType,
        }));
        return result;
      })
    );
  }
  // getObjectList(customerId: string, groupIds: string[]) {
  //   return this._vehicleService.getList(customerId, null, groupIds).pipe(
  //     map((m) => {
  //       const temp: IGisObject[] = [];
  //       m.forEach((n) =>
  //         temp.push({
  //           id: n.id,
  //           name: n.carrierName,
  //           changeKey: n.changeKey,
  //           description: n.description,
  //           entityType: n.entityType,
  //         } as IGisObject)
  //       );
  //       return temp;
  //     })
  //   );
  // }

  getObjectGroups(objectId: string, customerId: string) {
    const request = new GetItemEntityGroupsRequest({
      id: objectId,
      customerId,
    });

    return this._api.get(request).pipe(map((m) => m.items));
  }

  getPagedList(
    params: IPageParams,
    customerId: string
  ): Observable<IPageReturn<EntityGroupInfo>> {
    if (!customerId) {
      return of(null);
    }

    const request = new GetMaintenanceVehicleGroupsRequest();
    request.customerId = customerId;

    setPageParams(request, params);
    return this._api.get(request).pipe(
      map((m) => {
        return getPageReturn(m);
      })
    );
  }
  getList(customerId: string, filter?: string, owned = false) {
    const request = new GetMaintenanceVehicleGroupsRequest();
    request.customerId = customerId;

    request.page = 0;
    request.items = 0;
    request.sort = "name";
    request.filter = filter || "";
    request.onlyOwnedItems = owned;

    return this._api.get(request).pipe(
      map((m) => {
        return m.items;
      })
    );
  }

  get(id: string, customerId: string) {
    const request = new GetMaintenanceVehicleGroupRequest();
    request.id = id;
    request.customerId = customerId;
    return this._api.get(request);
  }
  add(model: EntityGroup, params: { customerId: string }) {
    const request = new CreateMaintenanceVehicleGroupRequest();
    request.customerId = params.customerId;
    request.entity = model;
    return this._api.post(request);
  }
  update(model: EntityGroup, params: { customerId: string }) {
    const request = new UpdateMaintenanceVehicleGroupRequest();
    request.id = model.id;
    request.entity = model;
    request.customerId = params.customerId;
    return this._api.put(request);
  }
  delete(ids: string[], customerId: string): Observable<number> {
    const request = new DeleteMaintenanceVehicleGroupsRequest();
    request.ids = ids;
    request.customerId = customerId;
    return this._api.delete(request);
  }
  exist(name: string, customerId: string) {
    return of(false);
  }
}
