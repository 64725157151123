import { Injectable } from '@angular/core';
import {
  getPageReturn,
  IDataSourceService,
  IPageParams,
  IPageReturn,
  setPageParams,
} from 'projects/msu-its-web-common/src/data/data-source';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  CreateDetectorMaintenanceTypeRequest,
  DeleteDetectorsMaintenanceTypeRequest,
  DetectorMaintenanceType,
  GetCameraCurrentUserPermissionsRequest,
  GetDetectorMaintenanceTypeRequest,
  GetDetectorsMaintenanceTypeRequest,
  UpdateDetectorMaintenanceTypeRequest,
} from '../dtos/tfms.dtos';
import { TfmsApiService } from './tfms-api.service';

@Injectable({
  providedIn: 'root',
})
export class DetectorMaintenanceTypeService
  implements IDataSourceService<DetectorMaintenanceType, DetectorMaintenanceType> {
  constructor(private _api: TfmsApiService) {}

  getPagedList(params: IPageParams): Observable<IPageReturn<DetectorMaintenanceType>> {
    const request = new GetDetectorsMaintenanceTypeRequest();
    setPageParams(request, params);
    return this._api.get(request).pipe(
      map((m) => {
        return getPageReturn(m);
      })
    );
  }

  getList(filter?: string, items?: number): Observable<DetectorMaintenanceType[]> {
    const request = new GetDetectorsMaintenanceTypeRequest();
    request.page = 0;
    request.items = items ? items : 0;
    request.sort = '';
    request.filter = filter;

    return this._api.get(request).pipe(
      map((m) => {
        return m.items;
      })
    );
  }

  get(id: any): Observable<DetectorMaintenanceType> {
    const request = new GetDetectorMaintenanceTypeRequest();
    request.id = id;
    return this._api.get(request);
  }

  add(model: DetectorMaintenanceType) {
    const request = new CreateDetectorMaintenanceTypeRequest();
    request.entity = model;
    return this._api.post(request);
  }

  update(model: DetectorMaintenanceType) {
    const request = new UpdateDetectorMaintenanceTypeRequest();
    request.id = model.id;
    request.entity = model;
    return this._api.put(request);
  }

  delete(ids: string[]): Observable<number> {
    const request = new DeleteDetectorsMaintenanceTypeRequest();
    request.ids = ids;
    return this._api.delete(request);
  }

  getPermissions(id: string, customerId: string) {
    return null;
  }
}
