import { Injectable, OnDestroy } from '@angular/core';
import { EMPTY, of, Subject, Subscription } from 'rxjs';
import { catchError, mergeMap, tap, delay, repeat } from 'rxjs/operators';

import {
  IGisObjectGroupEventService,
  IGisObjectStatus,
} from 'projects/msu-its-web-common/src/utils/gis-object-group';

import { GetDetectorsStateRequest } from '../dtos/tfms.dtos';
import { TfmsApiEventService } from './tfms-api-event.service';
import { TfmsApiService } from './tfms-api.service';

@Injectable()
export class DetectorGroupEventService implements IGisObjectGroupEventService, OnDestroy {
  _subscription: Subscription;
  permissions = ['detector.read'];

  constructor(private _apiService: TfmsApiService, private _eventService: TfmsApiEventService) {}

  ngOnDestroy() {
    this.unsubscribe();
  }

  subscribe(customerId: string, groupIds: string[], updateSubject: Subject<IGisObjectStatus[]>) {
    this.unsubscribe();

    const request = new GetDetectorsStateRequest({
      customerId,
      entityGroups: groupIds,
      items: 0,
      page: 0,
    });

    this._subscription = of(null)
      .pipe(
        mergeMap(() => this._apiService.getUnsafe(request).pipe(catchError(() => EMPTY))),
        tap((result) => updateSubject.next(result.items)),
        delay(5000),
        repeat()
      )
      .subscribe();
  }

  unsubscribe() {
    if (this._subscription) {
      this._subscription.unsubscribe();
      this._subscription = null;
    }
  }
}
