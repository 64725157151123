<div *ngIf="loading" class="backdrop" style="z-index: 100000;">
  <mat-spinner diameter="36" color="accent"></mat-spinner>
</div>

<h4 mat-dialog-title>
  <!-- <span>{{ "FIXATING_VIOLATION_DEVICES.CREATE_DEVICE" | translate }}</span> -->
  <span
    >{{ "FIXATING_TRAFFIC_EVENTS.MODAL_TITLE" | translate }} с устройства {{
    getDeviceType(model?.fixationDeviceType)| translate }} {{ "#" +
    model?.fixationDeviceNumber}}</span
  >
</h4>
<div mat-dialog-content style="flex: 1; overflow-y: hidden;">
  <div style="height: 100%; display: flex; width: 1000px;">
    <div
      fxFlexFill
      fxLayout="row"
      fxLayoutAlign="space-between start"
      fxLayoutGap="20px"
    >
      <div fxFlex="40%">
        <div fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="8px">
          <div
            fxLayout="row"
            fxFlexFill
            fxLayoutAlign="start start"
            fxLayoutGap="4px"
          >
            <span fxFlex="60%">
              {{ "FIXATING_TRAFFIC_EVENTS.EVENT_DATE" | translate }}</span
            >
            <span fxFlex="40%">
              {{ model?.eventDate | date: "dd.MM.yyyy HH:mm":timezone:locale
              }}</span
            >
          </div>
          <div fxLayout="row" fxFlexFill fxLayoutAlign="start start">
            <span fxFlex="60%">
              {{ "FIXATING_TRAFFIC_EVENTS.LICENSE_PLATE" | translate }}</span
            >
            <span fxFlex="40%">{{model?.licensePlate}}</span>
          </div>
          <div fxLayout="row" fxFlexFill fxLayoutAlign="start start">
            <span fxFlex="60%">
              {{ "FIXATING_TRAFFIC_EVENTS.VIOLATION_TYPE" | translate }}</span
            >
            <span fxFlex="40%"
              >{{getEventType(model?.violationType) | translate}}</span
            >
          </div>
          <div fxLayout="row" fxFlexFill fxLayoutAlign="start start">
            <span fxFlex="60%">
              {{ "FIXATING_TRAFFIC_EVENTS.SPEED" | translate }}</span
            >
            <span fxFlex="40%">{{model?.speed}} км/ч</span>
          </div>
          <div
            fxLayout="row"
            fxFlexFill
            fxLayoutAlign="start start"
            style="margin-bottom: 8px;"
          >
            <span fxFlex="60%">
              {{ "FIXATING_TRAFFIC_EVENTS.VEHICLE_CLASS" | translate }}</span
            >
            <span fxFlex="40%"
              >{{getVehicleType(model?.vehicleClass) | translate}}</span
            >
          </div>

          <div
            fxLayout="row"
            fxFlexFill
            fxLayoutAlign="start start"
            style="
              border-top: 1px solid rgba(255, 255, 255, 0.713);
              padding-top: 8px;
              margin-top: 10px !important;
            "
          >
            <span fxFlex="60%">
              {{ "FIXATING_VIOLATION_DEVICES.CONTROLLER_TYPE" | translate
              }}</span
            >
            <span fxFlex="40%"
              >{{ getDeviceType(model?.fixationDeviceType) || 'Нет данных' |
              translate}}</span
            >
          </div>

          <div fxLayout="row" fxFlexFill fxLayoutAlign="start start">
            <span fxFlex="60%">
              {{ "FIXATING_VIOLATION_DEVICES.DEVICE_NUMBER" | translate }}</span
            >
            <span fxFlex="40%"
              >{{model?.fixationDeviceNumber || 'Нет данных'}}</span
            >
          </div>

          <div fxLayout="row" fxFlexFill fxLayoutAlign="start start">
            <span fxFlex="60%">
              {{ "FIXATING_VIOLATION_POINTS.OBJECT_NAME_HINT" | translate
              }}</span
            >
            <span fxFlex="40%"
              >{{model?.fixationPointName || 'Нет данных'}}</span
            >
          </div>
        </div>
      </div>
      <div
        fxFlex="60%"
        fxLayout="column"
        fxLayoutAlign="space-between stretch"
        fxLayoutGap="12px"
        style="border: 1px solid #9b9b9b21; position: relative;"
      >
        <div *ngIf="loadingImages" class="backdrop" style="z-index: 100000;">
          <mat-spinner diameter="36" color="accent"></mat-spinner>
        </div>
        <div
          fxFlex="400px"
          fxLayoutAlign="center center"
          class="magnify-wrapper"
        >
          <img
            *ngIf="activeImagesArray?.emptyBase64;else unset"
            id="mainPhotoFtv"
            src="{{activeImagesArray?.base64}}"
            class="mainPhotoFtv"
            [ngClass]="{'fullscreen' : fullscreen}"
            (click)="clickBigImage()"
          />
          <ng-template #unset>
            <div *ngIf="!loadingImages">
              <span>Изображения не найдены</span>
            </div>
          </ng-template>
          <div
            id="fullpage"
            (click)="clickBigImage()"
            [ngStyle]="{'display': fullscreen ? 'block':'none' }"
          ></div>
        </div>
        <div
          fxFlex="100px"
          fxLayout="row"
          fxLayoutGap="6px"
          fxLayoutAlign="center"
          style="min-height: 100px;"
        >
          <div
            *ngFor="let item of imagesArray; index as i;"
            style="
              cursor: pointer;
              max-height: 100px;
              max-width: 200px;
              transition: all 250ms;
            "
            (click)="clickMiniImage(item)"
            [ngClass]="{
               'activeSmallImag': item?.id === activeImagesArray?.id ,
            'notActiveSmallImag': item?.id !== activeImagesArray?.id}"
          >
            <img
              fxFlexFill
              id="mainPhotoFtv_{{item?.id}}"
              src="{{item?.base64}}"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div mat-dialog-actions align="end">
  <button mat-button (click)="close()">
    {{ "COMMON.CANCEL" | translate }}
  </button>
</div>
