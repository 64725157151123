import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import {
  IDataSourceService,
  setPageParams,
  getPageReturn,
  IPageParams,
  IPageReturn,
} from '../data/data-source';

import {
  ExternalSystem,
  GetExternalSystemsRequest,
  GetExternalSystemRequest,
  CreateExternalSystemRequest,
  UpdateExternalSystemRequest,
  // DeleteExternalSystemsRequest,
} from '../dtos/hub.dtos';

import { HubApiService } from './hub-api.service';

@Injectable({ providedIn: 'root' })
export class ExternalSystemService
  implements IDataSourceService<ExternalSystem, ExternalSystem>
{
  constructor(private _api: HubApiService) {}

  getPagedList(params: IPageParams): Observable<IPageReturn<ExternalSystem>> {
    const request = new GetExternalSystemsRequest();
    setPageParams(request, params);

    return this._api.get(request).pipe(
      map((m) => {
        return getPageReturn(m);
      })
    );
  }

  getList(filter?: string): Observable<ExternalSystem[]> {
    const request = new GetExternalSystemsRequest();

    request.page = 0;
    request.items = 0;
    request.sort = 'name';
    request.filter = filter;

    return this._api.get(request).pipe(
      map((m) => {
        return m.items;
      })
    );
  }

  get(id: string): Observable<ExternalSystem> {
    const request = new GetExternalSystemRequest({ id });
    return this._api.get(request);
  }

  add(model: ExternalSystem): Observable<ExternalSystem> {
    const request = new CreateExternalSystemRequest({ entity: model });
    return this._api.post(request);
  }

  update(model: ExternalSystem): Observable<ExternalSystem> {
    const request = new UpdateExternalSystemRequest({
      id: model.id,
      entity: model,
    });
    return this._api.put(request);
  }

  delete(ids: string[]): Observable<number> {
    throw 'not implemented';
    // const request = new DeleteExternalSystemsRequest({ ids });
    // return this._api.delete(request);
  }
}
