<h4 mat-dialog-title>
  {{ "DETECTORS.CHANGE_DEVICE" | translate }}
</h4>
<mat-dialog-content style="flex: 1;">
  <form [formGroup]="formGroup" (ngSubmit)="save()" autocomplete="off">
    <div
      fxLayout="column"
      fxLayoutGap="12px"
      style="min-width: 350px; padding-bottom: 12px;"
    >
      <mat-form-field style="width: 100%;">
        <input
          matInput
          [value]="model.name"
          disabled
          placeholder="{{ 'COMMON.NAME' | translate }}"
        />
        <mat-hint>{{ "DETECTORS.NAME_HINT" | translate }}</mat-hint>
      </mat-form-field>

      <mat-form-field style="width: 100%;">
        <input
          matInput
          [value]="model.detectorDevice?.prefix + model.detectorDevice?.number"
          disabled
          placeholder="{{ 'DETECTORS.DEVICE' | translate }}"
        />
        <mat-hint>{{ "DETECTORS.DEVICE_HINT" | translate }}</mat-hint>
      </mat-form-field>

      <!-- <mat-form-field style="width: 100%">
        <input matInput [value]="oldDetectorDevice?.name" disabled placeholder="{{ 'TRAFFIC_OBJECTS.CONTROLLER' | translate }}" />
        <mat-hint>{{ "TRAFFIC_OBJECTS.CONTROLLER_HINT" | translate }}</mat-hint>
      </mat-form-field> -->

      <mat-form-field style="width: 100%;">
        <mat-select
          (openedChange)="$event && searchInput.focus()"
          [formControl]="newDetectorDeviceId"
          (selectionChange)="controllerSelected($event.value)"
          placeholder="{{ 'DETECTORS.NEW_DEVICE' | translate }}"
          disableOptionCentering="true"
          panelClass="single-select-panel"
        >
          <mat-select-trigger>
            {{ selectedController?.prefix }} {{ selectedController?.number }}
          </mat-select-trigger>
          <div
            (click)="$event.stopPropagation()"
            class="mat-theme-bg"
            style="
              position: sticky;
              top: 0;
              z-index: 2;
              padding: 8px 12px;
              font-size: 14px;
            "
          >
            <input
              #searchInput
              class="mat-input-element"
              [placeholder]="('COMMON.SEARCH' | translate) + '...'"
              autocomplete="off"
              spellcheck="false"
              (keyup)="searchInputSubject.next($event.target.value)"
              (input)="searchInputSubject.next($event.target.value)"
              (keydown)="$event.stopPropagation()"
              (click)="$event.stopPropagation()"
              style="width: 220px;"
            />
            <mat-checkbox
              [formControl]="onlyFreeCheck"
              style="position: absolute; margin-left: 7px; margin-top: -2px;"
            >
              {{ "DEVICES.NOT_BUSY" | translate }}</mat-checkbox
            >
          </div>
          <mat-option
            *ngIf="selectedController"
            [value]="selectedController.id"
            style="height: 60px !important;"
          >
            <div style="line-height: 1;">
              {{ selectedController.prefix }} {{ selectedController.number }}
            </div>
            <div
              *ngIf="selectedController.ownerObjectName"
              style="
                line-height: 1;
                text-overflow: ellipsis;
                overflow: hidden;
                color: #f54545bf;
                margin-top: 2px;
              "
              class="mat-hint"
            >
              {{ "DEVICES.CONNECTED_TO" | translate }} {{
              selectedController.ownerObjectName }}
            </div>
            <div
              style="
                line-height: 1;
                text-overflow: ellipsis;
                overflow: hidden;
                margin-top: 2px;
              "
              class="mat-hint"
            >
              <small>{{ selectedController.description }}</small>
            </div>
          </mat-option>
          <mat-option
            *ngFor="let item of controllerListSubject | async"
            [value]="item.id"
            style="height: 60px !important;"
          >
            <div style="line-height: 1;">
              {{ item.prefix }} {{ item.number }}
            </div>
            <div
              *ngIf="item.ownerObjectName"
              style="
                line-height: 1;
                text-overflow: ellipsis;
                overflow: hidden;
                color: #f54545bf;
                margin-top: 2px;
              "
              class="mat-hint"
            >
              {{ "DEVICES.CONNECTED_TO" | translate }}{{ item.ownerObjectName }}
            </div>
            <div
              style="line-height: 1; text-overflow: ellipsis; overflow: hidden;"
              class="mat-hint"
            >
              <small>{{ item.description }}</small>
            </div>
          </mat-option>
        </mat-select>
        <mat-hint>{{ "DEVICES.DEVICE_HINT" | translate }} </mat-hint>
        <mat-error *ngIf="newDetectorDeviceId.hasError('required')">
          {{ "DEVICES.DEVICE_REQUIRED" | translate }}
        </mat-error>
      </mat-form-field>
      <!-- <mat-checkbox [formControl]="setDetectorCoordsToDevice">
        {{ "DEVICES.CONNECTED_COORDINATE" | translate }}</mat-checkbox
      > -->
      <!-- <mat-checkbox [formControl]="setDetectorCoordsToDevice">Совместить координаты оборудования с ТД</mat-checkbox> -->
      <mat-form-field>
        <textarea
          matInput
          [formControl]="description"
          placeholder="{{ 'COMMON.REASON' | translate }}"
          matTextareaAutosize
          matAutosizeMinRows="2"
          matAutosizeMaxRows="5"
          spellcheck="false"
        ></textarea>
        <mat-error *ngIf="description.hasError('required')">
          {{ "COMMON.REASON_REQUIRED" | translate }}
        </mat-error>
      </mat-form-field>
    </div>
  </form>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button (click)="save()" color="primary">
    {{ "COMMON.SAVE" | translate }}
  </button>
  <button mat-button (click)="close()">
    {{ "COMMON.CANCEL" | translate }}
  </button>
</mat-dialog-actions>
