import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from "@angular/core";
import { marker as _ } from "@biesbjerg/ngx-translate-extract-marker";

import { TranslateService } from "@ngx-translate/core";
import { FtpCameraLocation } from "./model";
import { CameraFTPPopupService } from "../../services/camera-ftp/camera-ftp-popup.service";
import { finalize, takeUntil } from "rxjs/operators";
import { StaticCameraImage } from "../../dtos/accident.dtos";
import { DateAdapter } from "@angular/material/core";
import { LANGS_LOCALES } from "projects/msu-its-web-common/src/utils/langs";
import {
  Subject,
} from "rxjs";
import { DialogService } from "projects/msu-its-web-common/src/services/dialog.service";

@Component({
  selector: "camera-ftp-popup",
  templateUrl: "./camera-ftp-popup.component.html",
  styles: [
    `
      :host {
        min-width: 622px !important;
      }
      table {
        font-family: arial, sans-serif;
        border-collapse: collapse;
        width: 100%;
        z-index: 1;
      }

      td,
      th {
        text-align: left;
        padding: 8px;
      }
      canvas {
        width: 100%;
        height: 100%;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CameraFTPPopupComponent
  implements OnInit, AfterViewInit, OnDestroy {
  _destroy = new Subject();
  loading = false;

  @Input()
  modelId: string;

  external: boolean = false;

  @Input()
  customerId: string;

  @Input()
  modelInfo: FtpCameraLocation;

  totalImages: number = 0;

  lastImages: StaticCameraImage;
  warningMessages: boolean = false;

  get disabledNext() {
    return this.currentIndex + 1 === this.totalImages;
  }

  listImages: StaticCameraImage[] = [];
  dataImages;
  page = 0;
  currentIndex = 0;

  get locale() {
    return LANGS_LOCALES[this.translate.currentLang];
  }

  constructor(
    private _dialogService: DialogService,
    private _dateAdapter: DateAdapter<any>,
    public translate: TranslateService,
    private _popupImagesService: CameraFTPPopupService,
    private _changeDetector: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.loading = true;
    this._dateAdapter.setLocale(this.locale);
  }
  ngAfterViewInit(): void {
    this.loadDataAll();
  }

  ngOnDestroy() {
    this._destroy.next();
    this._destroy.complete();
  }

  loadDataAll() {
    this._popupImagesService
      .getImagesPageRequest(
        {
          page: this.page,
          filter: "",
          size: 5,
          sort: "created desc",
          desc: false,
        },
        this.customerId,
        this.modelId
      )
      .pipe(
        takeUntil(this._destroy),
        finalize(() => {
          this.loading = false;
          this._changeDetector.markForCheck();
        })
      )
      .subscribe((result) => {
        if (result.total === 0) {
          this.drawNotFound();
          this.totalImages = result.total;
        } else {
          this.listImages = [...result.data];
          this.totalImages = result.total;
          this.drawImages(result.data[0]);
          this.dataImages = result.data[0].created;
        }

        this._changeDetector.markForCheck();
      });
  }

  // loadData() {
  //   forkJoin(
  //     this._popupImagesService
  //       .getStaticCameraImagesCountRequest(this.modelId, this.customerId)
  //       .pipe(
  //         catchError(() => {
  //           return of(null);
  //         })
  //       ),
  //     this._popupImagesService
  //       .getStaticCameraLastImageRequest(this.modelId, this.customerId)
  //       .pipe(
  //         catchError(() => {
  //           return of(null);
  //         })
  //       )
  //   )
  //     .pipe(
  //       takeUntil(this._destroy),
  //       finalize(() => {
  //         this.loading = false;
  //         this._changeDetector.markForCheck();
  //       })
  //     )

  //     .subscribe(
  //       ([response1, response2]) => {
  //         if (response1) {
  //           this.totalImages = response1;
  //         }

  //         if (response2) {
  //           this.lastImages = response2;
  //           this.drawImages(response2);
  //         } else {
  //           this.drawNotFound();
  //           this.warningMessages = true;
  //         }
  //         this._changeDetector.markForCheck();
  //       },
  //       (error) => {
  //         console.log(error);
  //       }
  //     );
  // }

  drawNotFound() {
    var images = document.getElementById(
      `photo_${this.modelId}`
    ) as HTMLImageElement;
    images.src = "assets/images/img_not_found.png";
  }

  drawImages(item: StaticCameraImage) {
    this.loading = true;
    var images = document.getElementById(
      `photo_${this.modelId}`
    ) as HTMLImageElement;

    fetch(`data:image/png;base64,${item.image}`)
      .then((res) => res.blob())
      .then((res) => {
        images.src = URL.createObjectURL(res);
        this.loading = false;
        this._changeDetector.markForCheck();
      });
  }

  loadAllImages() {
    const url = `${this.customerId}/ext/cameras-ftp/${this.modelId}/images`;
    this._dialogService.windowDialog(
      url,
      "cameraFtpImages_" + this.modelId,
      "menubar=0,toolbar=0,titlebar=0,location=0,locationbar=0,width=1100,height=850,resizable=0"
    );
  }

  nextImage() {
    this.currentIndex++;

    if (this.currentIndex < this.listImages.length) {
      this.drawImages(this.listImages[this.currentIndex]);
      this.dataImages = this.listImages[this.currentIndex].created;
    } else if (this.currentIndex === this.listImages.length) {
      if (this.currentIndex < this.totalImages) {
        this.loading = true;
        this.page++;
        this._popupImagesService
          .getImagesPageRequest(
            {
              page: this.page,
              filter: "",
              size: 5,
              sort: "created desc",
              desc: false,
            },
            this.customerId,
            this.modelId
          )
          .pipe(
            takeUntil(this._destroy),
            finalize(() => {
              this.loading = false;
              this._changeDetector.markForCheck();
            })
          )
          .subscribe((result) => {
            this.listImages = [...this.listImages, ...result.data];

            this.drawImages(this.listImages[this.currentIndex]);
            this.dataImages = this.listImages[this.currentIndex].created;
          });
      }
    }
  }

  prevImage() {
    if (this.currentIndex > 0) {
      this.currentIndex--;
      this.drawImages(this.listImages[this.currentIndex]);
      this.dataImages = this.listImages[this.currentIndex].created;
    }
    if (this.currentIndex === 0) {
      this.drawImages(this.listImages[this.currentIndex]);
      this.dataImages = this.listImages[this.currentIndex].created;
    }
  }
}
