import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  CreateDetectorMaintenanceDetailsRequest,
  CreateDetectorMaintenanceRequest,
  DeleteDetectorsMaintenanceDetailsRequest,
  DeleteDetectorsMaintenanceRequest,
  DetectorMaintenance,
  DetectorMaintenanceDetails,
  DetectorsMaintenanceDetailsResponse,
  DetectorsMaintenanceResponse,
  GetDetectorMaintenanceDetailsRequest,
  GetDetectorMaintenanceRequest,
  GetDetectorsMaintenanceDetailsRequest,
  GetDetectorsMaintenanceRequest,
  UpdateDetectorMaintenanceDetailsRequest,
  UpdateDetectorMaintenanceRequest,
} from '../dtos/tfms.dtos';
import { TfmsApiService } from './tfms-api.service';

@Injectable({
  providedIn: 'root',
})
export class DetectorMaintenanceService {
  constructor(private _api: TfmsApiService) {}

  getDetectorMaintenanceRequest(id): Observable<DetectorMaintenance> {
    const request = new GetDetectorMaintenanceRequest();
    request.id = id;
    return this._api.get(request);
  }

  getDetectorsMaintenanceRequest(
    page: number,
    size: number,
    sort: string,
    filter: string,
    detectorId: string,
    start: Date,
    end: Date,
    maintenanceTypeId: string
  ): Observable<DetectorsMaintenanceResponse> {
    const request = new GetDetectorsMaintenanceRequest();
    request.items = size;
    request.page = page;
    request.sort = sort;
    request.filter = filter;
    request.detectorId = detectorId;
    request.start = !start ? '' : start.toISOString();
    request.end = !end ? '' : end.toISOString();
    request.maintenanceTypeId = maintenanceTypeId;
    return this._api.get(request);
  }

  createDetectorMaintenanceRequest(item: DetectorMaintenance) {
    const request = new CreateDetectorMaintenanceRequest();
    request.entity = item;
    return this._api.post(request);
  }

  updateDetectorMaintenanceRequest(
    id: string,
    item: DetectorMaintenance
  ): Observable<DetectorMaintenance> {
    const request = new UpdateDetectorMaintenanceRequest();
    request.id = id;
    request.entity = item;
    request.entity.id = id;
    return this._api.put(request);
  }

  deleteDetectorsMaintenanceRequest(id: string[]) {
    const request = new DeleteDetectorsMaintenanceRequest();
    request.ids = id;
    return this._api.delete(request);
  }
  getDetectorMaintenanceDetailsRequest(id): Observable<DetectorMaintenanceDetails> {
    const request = new GetDetectorMaintenanceDetailsRequest();
    request.id = id;
    return this._api.get(request);
  }

  getDetectorsMaintenanceDetailsRequest(
    page: number,
    size: number,
    sort: string,
    filter: string,
    maintenanceId: string,
    typeId?: string
  ): Observable<DetectorsMaintenanceDetailsResponse> {
    const request = new GetDetectorsMaintenanceDetailsRequest();
    request.items = size;
    request.page = page;
    request.sort = sort;
    request.filter = filter;
    request.maintenanceId = maintenanceId;
    request.typeId = typeId;

    return this._api.get(request);
  }

  createDetectorMaintenanceDetailsRequest(item: DetectorMaintenanceDetails) {
    const request = new CreateDetectorMaintenanceDetailsRequest();
    request.entity = item;
    return this._api.post(request);
  }

  updateDetectorMaintenanceDetailsRequest(
    id: string,
    item: DetectorMaintenanceDetails
  ): Observable<DetectorMaintenanceDetails> {
    const request = new UpdateDetectorMaintenanceDetailsRequest();
    request.id = id;
    request.entity = item;
    request.entity.id = id;
    return this._api.put(request);
  }

  deleteDetectorsMaintenanceDetailsRequest(id: string[]) {
    const request = new DeleteDetectorsMaintenanceDetailsRequest();
    request.ids = id;
    return this._api.delete(request);
  }
}
