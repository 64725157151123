import { Injectable } from '@angular/core';
import { GetParkingKioskModeRequest, GetParkingKioskStatusRequest } from '../dtos/parking.dtos';
import { ParkingApiService } from './parking-api.service';
import { Observable } from 'rxjs';

@Injectable()
export class MsuPirsParkingKioskService {
  constructor(private _api: ParkingApiService) {}

  getStatus(parkingKioskId: string, customerId: string) {
    const request = new GetParkingKioskStatusRequest({ customerId, id: parkingKioskId });
    return this._api.get(request);
  }
  getMode(parkingKioskId: string, customerId: string): Observable<boolean>  {
    const request = new GetParkingKioskModeRequest({ customerId, id: parkingKioskId });
    return this._api.get(request);
  }
}
