import {
  Component,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  OnDestroy,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { forkJoin } from 'rxjs';

import { CustomerService } from '../../services/customer.service';
import { CustomerStateService } from '../../services/customer-state.service';

@Component({
  selector: 'customer-container',
  templateUrl: './customer-container.component.html',
  styleUrls: ['./customer-container.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomerContainerComponent implements OnDestroy {
  get customer() {
    return this._customerStateService.customer;
  }

  get modulesSettings() {
    return this._customerStateService.modulesSettings;
  }

  constructor(
    private _route: ActivatedRoute,
    private _customerService: CustomerService,
    private _customerStateService: CustomerStateService,
    private _changeDetector: ChangeDetectorRef
  ) {
    const customerId = this._route.snapshot.paramMap.get('customerId');

    if (customerId) {
      forkJoin([
        this._customerService.getList(),
        this._customerService.getModulesSettings(customerId),
      ]).subscribe((result) => {
        const customer = result[0].find((m) => m.id === customerId);
        const modulesSettings = (result[1] || []).reduce((result, item) => {
          result[item.moduleId] = JSON.parse(item.settings);
          return result;
        }, {} as { [moduleId: string]: any });
        this._customerStateService.customer = customer;
        this._customerStateService.modulesSettings = modulesSettings;
        this._changeDetector.markForCheck();
      });
    }
  }
  ngOnDestroy() {
    this._customerStateService.customer = null;
    this._customerStateService.modulesSettings = {};
  }
}
