import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

let counter = 0;
let currentVersion = '';
let currentTimestamp = '';

@Injectable({ providedIn: 'root' })
export class VersionService {
  constructor(private http: HttpClient) {}

  public initVersionCheck(timeout = 1000 * 60 * 1) {
    this._checkVersion();
    setInterval(() => this._checkVersion(), timeout);
  }

  private _checkVersion() {
    this.http
      .get<any>('assets/version.json', { params: { t: Date.now().toString() } })
      .subscribe(
        (response: { version: string; timestamp: number }) => {
          if (!currentVersion) {
            currentVersion = response.version;
            currentTimestamp = response.timestamp.toString();
            return;
          }

          if (currentVersion !== response.version) {
            counter++;
            if (counter > 1) {
              (<any>location).reload(true);
            }
          }
        },
        () => {}
      );
  }

  get version() {
    return currentVersion;
  }
  get timestamp() {
    return currentTimestamp;
  }
}
