import {
  Component,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Injector,
  ViewChild,
  Input,
  OnDestroy,
  AfterViewInit,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { DialogService } from 'projects/msu-its-web-common/src/services/dialog.service';
import { FlashService } from 'projects/msu-its-web-common/src/services/flash.service';
import { WEEK_DAYS } from 'projects/msu-its-web-tlc/src/dtos/enums';
import { SchemaSet } from 'projects/msu-its-web-tlc/src/dtos/schema';
import { TrafficObjectInfo, UsdkSettings } from 'projects/msu-its-web-tlc/src/dtos/tlc.dtos';
import { BehaviorSubject, of, Subject } from 'rxjs';
import { catchError, delay, finalize } from 'rxjs/operators';
import {
  TrafficObjectSceneStatus,
  TrafficScene,
  TrafficSceneAnalysis,
  TrafficSceneStatus,
} from '../../dtos/tss.dtos';
import { TrafficSceneService } from '../../services/traffic-scene.service';

@Component({
  selector: 'traffic-scene-analysis',
  templateUrl: './traffic-scene-analysis.component.html',
  styleUrls: ['./traffic-scene-analysis.component.css'],
  styles: [
    `
      :host {
        height: 100%;
        display: flex;
        flex-direction: column;
      }
      .date-button {
        pointer-events: none;
        font-size: 16px;
        position: absolute;
        right: 0;
        width: 20px;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TrafficSceneAnalysisComponent implements OnDestroy, AfterViewInit {
  _destroy = new Subject();
  _statusIndex = 0;

  loading = false;

  modelId: string;
  modelInfo: TrafficScene;

  customerId: string;

  objects = new Map<string, TrafficObjectInfo>();
  schemaSets = new Map<string, SchemaSet>();

  analysis: TrafficSceneAnalysis = new TrafficSceneAnalysis();
  statusSubject = new BehaviorSubject<TrafficSceneStatus>(null);

  weekDays = WEEK_DAYS;
  dayOfWeekControl = new FormControl();
  fromControl = new FormControl();
  toControl = new FormControl();

  constructor(
    private _flashService: FlashService,
    private _dialog: MatDialogRef<TrafficSceneAnalysisComponent>,
    private _trafficSceneService: TrafficSceneService,
    private _changeDetector: ChangeDetectorRef,
    public translate: TranslateService
  ) {
    const date = new Date();
    const day = date.getDay();
    const hours = date.getHours();
    date.setHours(hours + 1);
    const hoursPlusOne = date.getHours();

    this.fromControl.setValue(`0${hours}:00`.substr(-5));
    this.toControl.setValue(`0${hoursPlusOne}:00`.substr(-5));
    this.dayOfWeekControl.setValue(this.weekDays.find((m) => m.index == day).type);
  }

  ngAfterViewInit() {
    // console.log(this.modelId, this.modelInfo);
    // setTimeout(() => this.calculate());
  }

  ngOnDestroy() {
    this._destroy.next();
    this._destroy.complete();
  }

  calculate() {
    this.loading = true;
    this.analysis = null;
    this._changeDetector.markForCheck();

    const dayOfWeek = this.weekDays.find((m) => m.type == this.dayOfWeekControl.value).index;

    this._trafficSceneService
      .getAnalysis(
        this.modelId,
        this.customerId,
        this.fromControl.value,
        this.toControl.value,
        dayOfWeek
      )
      .pipe(
        finalize(() => {
          this.loading = false;
          this._changeDetector.markForCheck();
        })
      )
      .pipe(
        catchError(() => {
          return of(null as TrafficSceneAnalysis);
        })
      )
      .subscribe((result) => {
        const status = new TrafficSceneStatus({
          trafficObjectStatuses: [],
        });

        result?.trafficObjectSceneAnalyses.forEach((m) => {
          status.trafficObjectStatuses.push(
            new TrafficObjectSceneStatus({
              id: m.id,
              adaptivePlan: m.plan,
              planTime: 0,
            })
          );
        });

        this.analysis = result;
        this.statusSubject.next(status);
        this._changeDetector.markForCheck();
      });
  }

  getObjectName(id: string) {
    return this.objects.get(id)?.name || '';
  }
  getSchemaSet(id: string) {
    return this.schemaSets.get(id);
  }

  save() {
    const message = this.translate.instant('COMMON.CHANGES_SAVED');

    if (this._dialog) {
      this._flashService.success(message);
    } else {
      DialogService.windowMessage(message);
    }

    this.close();
  }

  close() {
    if (this._dialog) {
      this._dialog.close();
    } else {
      window.close();
    }
  }
}
