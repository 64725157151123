import { Injectable } from '@angular/core';
import { IDataSourceService, IPageParams } from 'projects/msu-its-web-common/src/data/data-source';

import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import {
  GetControllerEventReportRequest,
  ControllerEvent,
  ControllerEventType,
} from '../dtos/tlc.dtos';

import { TlcApiService } from './tlc-api.service';

@Injectable({ providedIn: 'root' })
export class TrafficObjectEventLogService
  implements IDataSourceService<ControllerEvent, ControllerEvent> {
  constructor(private _api: TlcApiService) {}

  getPagedList(
    params: IPageParams,
    requestParams: {
      id?: string;
      customerId: string;
      start?: string;
      end?: string;
      filter?: ControllerEventType[];
    }
  ) {
    const request = new GetControllerEventReportRequest();

    request.id = requestParams.id;
    request.customerId = requestParams.customerId;
    request.start = requestParams.start;
    request.end = requestParams.end;
    request.eventFilter = requestParams.filter;
    
    request.sort = params.sort;
    if (params.sort && params.desc) {
      request.sort += ' desc';
    }

    return this._api.get(request).pipe(
      map((m) => {
        return { page: 0, size: 0, total: 0, data: m.items };
      })
    );
  }

  get(id: any): Observable<ControllerEvent> {
    throw 'not implemented';
  }

  add(model: ControllerEvent): Observable<ControllerEvent> {
    throw 'not implemented';
  }

  update(model: ControllerEvent): Observable<ControllerEvent> {
    throw 'not implemented';
  }

  delete(ids: string[]): Observable<number> {
    throw 'not implemented';
  }
}
