import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import {
  IDataSourceService,
  setPageParams,
  getPageReturn,
  IPageParams,
} from '../data/data-source';

import { EventItemDetails, GetEventsRequest } from '../dtos/hub.dtos';

import { HubApiService } from './hub-api.service';

@Injectable({ providedIn: 'root' })
export class EventLogService
  implements IDataSourceService<EventItemDetails, EventItemDetails> {
  constructor(private _api: HubApiService) {}

  getPagedList(
    params: IPageParams,
    requestParams: {
      entityId?: string;
      from?: string;
      to?: string;
      eventTypes?: string[];
    }
  ) {
    const request = new GetEventsRequest();
    setPageParams(request, params);

    request.id = requestParams?.entityId;
    request.from = requestParams?.from;
    request.to = requestParams?.to;
    request.eventTypes = requestParams?.eventTypes;

    request.sort = 'activityTime desc';

    return this._api.get(request).pipe(
      map((m) => {
        return getPageReturn(m);
      })
    );
  }

  getList(
    id?: string,
    filter?: string,
    from?: string,
    to?: string,
    eventTypes?: string[],
    count = 20
  ) {
    const request = new GetEventsRequest();

    request.id = id;
    request.from = from;
    request.to = to;
    request.eventTypes = eventTypes;

    request.page = 0;
    request.items = count;
    request.filter = filter;

    request.sort = 'activityTime desc';

    return this._api.get(request).pipe(
      map((m) => {
        return getPageReturn(m).data;
      })
    );
  }

  get(id: any): Observable<EventItemDetails> {
    throw 'not implemented';
  }

  add(model: EventItemDetails): Observable<EventItemDetails> {
    throw 'not implemented';
  }

  update(model: EventItemDetails): Observable<EventItemDetails> {
    throw 'not implemented';
  }

  delete(ids: string[]): Observable<number> {
    throw 'not implemented';
  }
}
