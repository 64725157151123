import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';

import { Camera } from '../../dtos/tfms.dtos';
import { DialogService } from 'projects/msu-its-web-common/src/services/dialog.service';
import { CameraService } from '../../services/camera.service';
import { WebCameraComponent } from 'projects/msu-its-web-common/src/components/camera/web-camera.component';
import { Observable, of } from 'rxjs';
import { TokenService } from 'projects/msu-its-web-common/src/services/token.service';

@Component({
  selector: 'camera-popup',
  templateUrl: './camera-popup.component.html',
  styles: [
    `
      :host {
        height: 100% !important;
        width: 100% !important;
        /* padding: 28px; */
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CameraPopupComponent implements OnInit, AfterViewInit {
  @Input()
  modelId: string;

  external: boolean = false;

  @Input()
  customerId: string;

  @Input()
  modelInfo: Camera;

  @Input()
  closeIfNew = true;

  modelPermissions$: Observable<string[]> = of(null);

  @ViewChild('webcamera', { static: false })
  webcamera: WebCameraComponent;

  actions: { name: string; action: string; permissions?: string[] }[] = [
    {
      name: _('COMMON.EDIT'),
      action: 'openEdit',
      permissions: ['camera.update'],
    },
  ];

  openInNew() {
    this._dialogService.windowDialog(
      `${this.customerId}/ext/cameras/${this.modelId}`,
      'Camera_' + this.modelId,
      'menubar=0,toolbar=0,titlebar=0,location=0,locationbar=0,width=880,height=645,resizable=0'
    );
  }
  openEdit() {
    this._dialogService.windowDialog(
      `${this.customerId}/ext/cameras/${this.modelId}/edit`,
      'CameraEdit_' + this.modelId,
      'width=940,height=756'
    );
  }

  constructor(
    private _cameraService: CameraService,
    private _changeDetector: ChangeDetectorRef,
    private _dialogService: DialogService,
    private _tokenService: TokenService
  ) {}

  ngAfterViewInit(): void {
    this._cameraService.get(this.modelId, this.customerId).subscribe((result) => {
      this.webcamera.url = result.url;
      this._changeDetector.markForCheck();
    });
    // this.webcamera.url = this.modelInfo.url;
    // this._changeDetector.markForCheck();
  }
  ngOnInit() {
    // permissions
    if (this.modelInfo.customerId != this.customerId) {
      this.modelPermissions$ = this._cameraService.getPermissions(this.modelId, this.customerId);
    }
    this._updateActionsByPermissions();
  }

  private _updateActionsByPermissions() {
    this.modelPermissions$.subscribe((m) => {
      this.actions = this.actions.filter((a) =>
        this._tokenService.hasPermissionsForItem(this.modelInfo, this.customerId, a.permissions, m)
      );
    });
  }
}
