import { marker as _ } from "@biesbjerg/ngx-translate-extract-marker";
import {
  CameraCommand,
  DetectorDeviceType,
  DetectorIncidentType,
  DetectorUserStatus,
  IncidentStatus,
  ReplacementReason,
} from "../dtos/tfms.dtos";

export const DEVICE_INCIDENT_TYPE = [
  {
    type: DetectorIncidentType.CameraOverlight,
    name: _("DETECTOR_DEVICE_INCIDENTS.CAMERA_OVERLIGHT"),
  },
  {
    type: DetectorIncidentType.CameraContrast,
    name: _("DETECTOR_DEVICE_INCIDENTS.CAMERA_CONTRAST"),
  },
  {
    type: DetectorIncidentType.CountError,
    name: _("DETECTOR_DEVICE_INCIDENTS.COUNT_ERROR"),
  },
];
export const DEVICE_INCIDENT_STATUS = [
  {
    type: IncidentStatus.Resolved,
    name: _("DETECTOR_DEVICE_INCIDENTS.RESOLVED"),
  },
  { type: IncidentStatus.New, name: _("DETECTOR_DEVICE_INCIDENTS.NEW") },
  {
    type: IncidentStatus.Canceled,
    name: _("DETECTOR_DEVICE_INCIDENTS.CANCELED"),
  },
];
export const DETECTOR_USER_STATUS = [
  {
    status: DetectorUserStatus.Demounted,
    name: _("DETECTORS.STATUS_DEMOUNTED"),
  },
  { status: DetectorUserStatus.InWork, name: _("DETECTORS.STATUS_IN_WORK") },
];
export const DETECTOR_REASON_REPLACE = [
  { status: ReplacementReason.Demount, name: _("DETECTORS.REASON_DEMOUNTED") },
  { status: ReplacementReason.Replace, name: _("DETECTORS.REASON_REPLACE") },
];
export const DETECTOR_DEVICE_TYPE = [
  // { type: DetectorDeviceType.None, name: _('DETECTORS.TYPE_NONE') },
  {
    type: DetectorDeviceType.Optic,
    name: _("DETECTORS.TYPE_OPTIC"),
    prefix: "optic#",
  },
  {
    type: DetectorDeviceType.Gortrans,
    name: _("DETECTORS.TYPE_GORTRANS"),
    prefix: "gbus#",
  },
  {
    type: DetectorDeviceType.Aist,
    name: _("DETECTORS.TYPE_AIST"),
    prefix: "aist#",
  },
  {
    type: DetectorDeviceType.RDScience,
    name: _("DETECTORS.TYPE_RDSCIENCE"),
    prefix: "rdscince#",
  },
  {
    type: DetectorDeviceType.Andromeda,
    name: _("DETECTORS.TYPE_ANDROMEDA"),
    prefix: "andromeda#",
  },
];

export const CONTROL_CAMERA = [
  { name: "MoveLeft", command: CameraCommand.MoveLeft },
  { name: "MoveRight", command: CameraCommand.MoveRight },
  { name: "MoveUp", command: CameraCommand.MoveUp },
  { name: "MoveDown", command: CameraCommand.MoveDown },
  { name: "MoveHome", command: CameraCommand.MoveHome },
  { name: "FocusIn", command: CameraCommand.FocusIn },
  { name: "FocusOut", command: CameraCommand.FocusOut },
  { name: "ZoomIn", command: CameraCommand.ZoomIn },
  { name: "ZoomOut", command: CameraCommand.ZoomOut },
  // { name: "LightOn", command: CameraCommand.LightOn },
  // { name: "LightOff", command: CameraCommand.LightOff },
  // { name: "Wipe", command: CameraCommand.Wipe },
];

export const DETECTOR_DEVICE_SVG_ICONS = {
  Optic: `<svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
    <circle class="border" cx="24" cy="24" r="23" fill="{{fillBorder}}"/>
    <circle class="center" cx="24" cy="24" r="19" fill="{{fillMain}}"/>
    <g class="image" fill="#fff" transform="matrix(1.3,0,0,1.3,2,1)">
    <path d="M15,26h4c1.7,0,3-1.3,3-3v-8H12v8C12,24.7,13.3,26,15,26z M9,10v3h16v-3H9z"/></g>
    </svg>`,
  Gortrans: `<svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
  <circle class="border" cx="24" cy="24" r="23" fill="#888"/>
  <circle class="center" cx="24" cy="24" r="19" fill="#888"/>
    <g class="image" fill="#fff" transform="matrix(1.3,0,0,1.3,2,1)">
      <path d="M16.96,13.06c-1.45,0-2.84,0.53-3.79,1.45l-0.66,0.64l1.21,0.85c0,0,1.23-1.33,3.24-1.33
      s3.22,1.31,3.22,1.31l1.22-0.83l-0.66-0.63C19.79,13.58,18.41,13.06,16.96,13.06z M16.34,18.38L13.5,24h6.92l-2.84-5.62
      C17.31,17.88,16.6,17.88,16.34,18.38z M24.71,11.19c-1.93-1.88-4.75-2.95-7.75-2.95c-3.03,0-5.88,1.1-7.81,3L8.48,11.9l1.51,1.15
      l0.12-0.12l0.36-0.36c1.58-1.56,3.94-2.46,6.48-2.46c2.51,0,4.86,0.88,6.44,2.42l0.42,0.43l0.1,0.1l1.59-1.06L24.71,11.19z"/>
    </g>
  </svg>
  `,
};
