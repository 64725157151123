import { Injectable } from '@angular/core';

import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import {
  IDataSourceService,
  setPageParams,
  getPageReturn,
  IPageParams,
  IPageReturn,
} from 'projects/msu-its-web-common/src/data/data-source';

import {
  GeoObjectType,
  CreateGeoObjectTypeRequest,
  DeleteGeoObjectTypesRequest,
  GetGeoObjectTypeRequest,
  GetGeoObjectTypesRequest,
  UpdateGeoObjectTypeRequest,
} from '../dtos/gis.dtos';

import { GisApiService } from './gis-api.service';

@Injectable()
export class GeoObjectTypeService implements IDataSourceService<GeoObjectType, GeoObjectType> {
  constructor(private _api: GisApiService) {}

  getPagedList(params: IPageParams, customerId: string): Observable<IPageReturn<GeoObjectType>> {
    if (!customerId) return of(null);

    const request = new GetGeoObjectTypesRequest({ customerId });
    setPageParams(request, params);

    return this._api.get(request).pipe(map((m) => getPageReturn(m)));
  }

  getList(customerId: string, filter?: string): Observable<GeoObjectType[]> {
    const request = new GetGeoObjectTypesRequest({ customerId });

    request.page = 0;
    request.items = 0;
    request.sort = 'name';
    request.filter = filter;

    return this._api.get(request).pipe(
      map((m) => {
        return m.items;
      })
    );
  }

  get(id: string, customerId: string) {
    const request = new GetGeoObjectTypeRequest();
    request.id = id;
    request.customerId = customerId;
    return this._api.get(request);
  }

  add(model: GeoObjectType, customerId: string) {
    const request = new CreateGeoObjectTypeRequest();
    request.entity = model;
    request.customerId = customerId;
    return this._api.post(request);
  }

  update(model: GeoObjectType, customerId: string) {
    const request = new UpdateGeoObjectTypeRequest();
    request.id = model.id;
    request.entity = model;
    request.customerId = customerId;
    return this._api.put(request);
  }

  delete(ids: string[], customerId: string) {
    const request = new DeleteGeoObjectTypesRequest();
    request.ids = ids;
    request.customerId = customerId;
    return this._api.delete(request);
  }

  exist(name: string, customerId: string): Observable<boolean> {
    // const request = new ExistGeoObjectNameRequest();
    // request.name = name;
    // request.customerId = customerId;
    // return this._api.get(request);
    return of(false);
  }
}
