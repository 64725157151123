<!-- <div fxLayout="column" fxLayoutGap="12px"> -->
<h4 mat-dialog-title fxLayoutAlign="start center">
  <span *ngIf="isNew">{{ "CAMERAS.CREATE_CAMERA" | translate }}</span>
  <span *ngIf="!isNew">{{ "CAMERAS.EDIT_CAMERA" | translate }}</span>
</h4>
<div mat-dialog-content fxLayout="row" fxLayoutGap="24px">
  <div fxFlex="30%" fxLayout="column" fxLayoutGap="16px">
    <mat-form-field fxFlex>
      <input matInput [formControl]="name" placeholder="{{ 'COMMON.NAME' | translate }}" autocomplete="off" maxlength="100" />
      <mat-hint>{{ "CAMERAS.NAME_HINT" | translate }}</mat-hint>
      <mat-error *ngIf="name.hasError('minlength') || name.hasError('maxlength')">
        {{ "CAMERAS.NAME_LENGTH" | translate: { min: 4, max: 100 } }}
      </mat-error>
      <mat-error *ngIf="name.hasError('required')">
        {{ "CAMERAS.NAME_REQUIRED" | translate }}
      </mat-error>
      <mat-error *ngIf="name.hasError('exist')">
        {{ "CAMERAS.NAME_EXIST" | translate }}
      </mat-error>
    </mat-form-field>

    <mat-form-field fxFlex>
      <input matInput disabled [value]="location?.latlon" />
      <mat-label>{{ "COMMON.LOCATION" | translate }}</mat-label>
      <mat-hint>{{ "CAMERAS.LOCATION_HINT" | translate }}</mat-hint>
      <button matSuffix mat-icon-button (click)="location.prompt()" title="{{ 'COMMON.EDIT' | translate }}">
        <mat-icon>edit</mat-icon>
      </button>
    </mat-form-field>

    <div fxFlex>
      <mat-checkbox
        [checked]="direction?.value != null"
        style="margin-right: 24px; padding-top: 12px"
        (change)="direction?.setValue($event.checked ? 0 : null); onDirectionChanged()"
      >
        {{ "COMMON.DIRECTION" | translate }}
      </mat-checkbox>
      <mat-slider
        [min]="0"
        [max]="360"
        [value]="direction?.value"
        style="flex: 1"
        [disabled]="direction?.value == null"
        (input)="direction?.setValue($event.value); onDirectionChanged()"
      >
      </mat-slider>
    </div>

    <mat-form-field fxFlex>
      <input matInput [formControl]="url" placeholder="{{ 'DEVICES.WEBCAM_URL' | translate }}" autocomplete="off" type="url" (change)="update()" />
      <mat-hint>
        {{ "DEVICES.WEBCAM_URL_HINT" | translate }}
      </mat-hint>
      <mat-error *ngIf="url.hasError('required')">
        {{ "DEVICES.WEBCAM_URL_REQUIRED" | translate }}
      </mat-error>
      <mat-error *ngIf="url.hasError('pattern')">
        {{ "DEVICES.WEBCAM_URL_PATTERN" | translate }}
      </mat-error>
    </mat-form-field>

    <mat-form-field fxFlex>
      <input matInput [formControl]="rtmp" placeholder="{{ 'DEVICES.WEBCAM_RTMP' | translate }}" autocomplete="off" type="url" />
      <mat-hint>
        {{ "DEVICES.WEBCAM_RTMP_HINT" | translate }}
      </mat-hint>
      <mat-error *ngIf="rtmp.hasError('pattern')">
        {{ "DEVICES.WEBCAM_RTMP_PATTERN" | translate }}
      </mat-error>
    </mat-form-field>

    <mat-form-field fxFlex>
      <mat-label>{{ "GROUPS.GROUPS" | translate }}</mat-label>
      <mat-select [formControl]="selectedGroups" multiple disableOptionCentering panelClass="multiple-select-panel left">
        <!-- <mat-select-trigger>
            <mat-chip-list>
              <mat-chip
                *ngFor="let groupId of selectedGroups.value"
                [removable]="true"
                (removed)="onGroupRemoved(groupId)"
                style="min-height: 28px"
              >
                {{ getGroupName(groupId) }}
                <mat-icon matChipRemove>cancel</mat-icon>
              </mat-chip>
            </mat-chip-list>
          </mat-select-trigger> -->
        <mat-option *ngFor="let group of groupList" [value]="group.id">
          {{ group.name }}
        </mat-option>
      </mat-select>
      <mat-hint>{{ "GROUPS.GROUPS_HINT" | translate }}</mat-hint>
    </mat-form-field>

    <mat-form-field fxFlex>
      <textarea
        matInput
        [formControl]="description"
        placeholder="{{ 'COMMON.DESCRIPTION' | translate }}"
        matTextareaAutosize
        matAutosizeMinRows="2"
        matAutosizeMaxRows="5"
        spellcheck="false"
      ></textarea>
    </mat-form-field>
  </div>

  <div fxFlex fxLayout="column" fxLayoutGap="24px">
    <div style="flex: 1; min-width: 600px; min-height: 320px">
      <location-component #location [lat]="model.lat" [lon]="model.lon" [direction]="1"></location-component>
    </div>

    <div style="flex: 1; min-width: 600px">
      <web-camera #webcamera></web-camera>
    </div>
  </div>
</div>

<div mat-dialog-actions align="end">
  <div fxLayout="row" fxLayoutAlign="end center">
    <button mat-button (click)="submit()" color="primary">
      <span *ngIf="isNew">{{ "COMMON.CREATE" | translate }}</span>
      <span *ngIf="!isNew">{{ "COMMON.SAVE" | translate }}</span>
    </button>
    <button mat-button (click)="close()">
      {{ "COMMON.CANCEL" | translate }}
    </button>
  </div>
  <!-- </div> -->
</div>
