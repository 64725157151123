<div class="mat-elevation-z2" style="display: flex; padding: 2px 0px 2px 6px">
  <div style="margin-right: 16px">
    <div class="mat-small mat-secondary-color">{{ "TRAFFIC_OBJECTS.CONTROLLER" | translate }}:</div>
    <div class="mat-body-1" style="line-height: normal">
      {{ trafficObjectSet?.model.controller?.fullNumber }}
    </div>
  </div>

  <div style="flex: 1">
    <ng-container *ngIf="status">
      <div class="mat-small mat-secondary-color">
        {{ "COMMON.STATUS" | translate }}:
        <span class="mat-color" style="margin-left: 8px">
          {{ status?.controllerTime | date: "shortDate":timezone:locale }}
          {{ status?.controllerTime | date: "longTime":timezone:locale }}
        </span>
        <span *ngIf="!isOffline && timeDiff() >= 10" style="color: #f44">({{ timeDiffString() }})</span>
      </div>
      <div class="mat-body-1" style="line-height: normal; padding-bottom: 3px">
        <span *ngIf="status?.mode == 'Off'">
          {{ "CONTROLLER_MODE.OFF" | translate }}
        </span>
        <span *ngIf="status?.mode == 'Remote'">
          {{ "CONTROLLER_MODE.REMOTE" | translate }}:
          <br />
          <span style="white-space: nowrap">
            {{ controllerStates[status?.state]?.short | translate }}
            <ng-container *ngIf="trafficStatus?.currentPhaseTimeLeft">
              ({{ "CONTROLLER_MODE.REMOTE_TIME_LEFT" | translate }}:
              {{ trafficStatus?.currentPhaseTimeLeft }}
              <a href="#" (click)="repeatRemoteMode(); $event.preventDefault()" class="mat-primary-color">
                {{ "CONTROLLER_MODE.REMOTE_REPEAT" | translate | lowercase }}</a
              >)
            </ng-container>
          </span>
        </span>
        <span *ngIf="status?.mode == 'Coordination'">
          <!-- {{ "CONTROLLER_MODE.COORDINATION" | translate }}<br /> -->
          <span *ngIf="status?.state == 'Phase'; else state" style="white-space: nowrap">
            <span>{{ "PLANS.WEEK_PLAN_SHORT" | translate }}{{ trafficStatus?.weekPlan }} </span>
            <span>{{ "PLANS.DAY_PLAN_SHORT" | translate }}{{ trafficStatus?.dayPlan }} </span>
            <span>
              {{ "PLANS.PLAN_SHORT" | translate }}{{ trafficStatus?.coordinationPlan }}
              <span *ngIf="trafficStatus?.planPhasesType == 'Pedestrian'"> ({{ "PLANS.PEDESTRIAN_PLAN_SHORT" | translate }}) </span>
            </span>
            <span>
              {{ trafficStatus?.planLength }}
              ({{ trafficStatus?.planTime }})
              {{ trafficStatus?.planLength - trafficStatus?.planTime }}
              ({{ trafficStatus?.currentPhaseTimeLeft }})
            </span>
          </span>
          <ng-template #state>
            {{ controllerStates[status?.state]?.short | translate }}
            {{ trafficStatus?.currentPhaseTimeLeft }}
          </ng-template>
        </span>
        <span *ngIf="status?.mode == 'Adaptive'">
          {{ "CONTROLLER_MODE.ADAPTIVE" | translate }}
          <span *ngIf="trafficStatus?.planPhasesType == 'Pedestrian'">({{ "PLANS.PEDESTRIAN_PLAN_SHORT" | translate }})</span>
          <span>
            {{ trafficStatus?.planLength }}
            ({{ trafficStatus?.planTime }})
            {{ trafficStatus?.planLength - trafficStatus?.planTime }}
            ({{ trafficStatus?.currentPhaseTimeLeft }})
          </span>
        </span>
        <span *ngIf="controllerStatus?.mode == 'Engineer'">
          {{ "CONTROLLER_MODE.ENGINEER" | translate }}
        </span>
      </div>
    </ng-container>
  </div>

  <button mat-icon-button [matMenuTriggerFor]="controllerMenu">
    <mat-icon>more_vert</mat-icon>
  </button>
</div>

<mat-menu #controllerMenu="matMenu" overlapTrigger="false" class="mat-menu-md" xPosition="before">
  <!-- <button [disabled]="!(['device.read'] | hasPermissions)" mat-menu-item (click)="openControllerFiles()">
    {{ "COMMON.FILES" | translate }}
  </button>
  <button [disabled]="!(['device.read'] | hasPermissions)" mat-menu-item (click)="openControllerAudit()">
    {{ "COMMON.AUDIT" | translate }}
  </button>
  <button [disabled]="!(['device.read'] | hasPermissions)" mat-menu-item (click)="openControllerErrorLog()">
    {{ "COMMON.ERROR_LOG" | translate }}
  </button>
  <button [disabled]="!(['device.read'] | hasPermissions)" mat-menu-item (click)="openControllerEventLog()">
    {{ "COMMON.EVENT_LOG" | translate }}
  </button>
  <button [disabled]="!(['device.modify'] | hasPermissions)" mat-menu-item (click)="openControllerEdit()">
    {{ "COMMON.EDIT" | translate }}
  </button>
  <button [disabled]="!(['device.control'] | hasPermissions)" mat-menu-item (click)="disconnectController()">
    {{ "DEVICES.SESSION_DISCONNECT" | translate }}
  </button> -->
  <button mat-menu-item (click)="disconnectController()">
    {{ "DEVICES.SESSION_DISCONNECT" | translate }}
  </button>
  <button *hasModules="['tss', 'tfms']" mat-menu-item (click)="trafficObjectReport()">
    {{ "DEVICES.TRAFFIC_OBJECT_REPORT" | translate }}
  </button>
  <button mat-menu-item (click)="eventLog()">
    {{ "TRAFFIC_CONTROLLERS.CONTROLLER_LOG" | translate }}
  </button>
</mat-menu>

<div
  class="mat-theme-bg mat-elevation-z2"
  *ngIf="(status?.mode == 'Coordination' || status?.mode == 'Adaptive') && status.state == 'Phase' && planBarriers"
  style="position: relative; height: 120px"
>
  <mat-progress-bar
    [value]="(trafficStatus?.planTime * 100) / trafficStatus?.planLength"
    class="mat-theme-bg"
    style="position: absolute; width: 100%; height: 100%; top: 0; left: 0"
  >
  </mat-progress-bar>

  <div
    class="plan"
    [class.adaptive]="status?.mode == 'Adaptive'"
    [class.pedestrian]="trafficStatus?.planPhasesType == 'Pedestrian'"
    style="position: absolute; width: 100%; height: 100%; top: 0; left: 0"
  >
    <div *ngFor="let barrier of planBarriers; let i = index" class="barrier" [style.flex]="barrier.length">
      <ng-container *ngIf="barrier.type == 'Static'">
        <div class="ring">
          <div class="phase">
            <div class="item">
              <div class="label">{{ getBarrierPartNames(barrier.type, i + 1).join(":") }}:{{ barrier.length }}</div>
              <div [style.transform]="'rotate(' + schemaView.angle + 'deg)'">
                <object
                  data="/assets/images/tlc/movements_static.svg"
                  style="max-height: 70px"
                  (load)="updateStaticSvg($event.target, barrier)"
                ></object>
              </div>
            </div>
          </div>
        </div>
      </ng-container>

      <ng-container *ngIf="barrier.type != 'Static'">
        <ng-container *ngFor="let ring of getRings(i)">
          <div class="ring">
            <div *ngFor="let part of ring" class="phase" [style.flex]="part.item.length">
              <ng-container *ngIf="part.type == 'Left'">
                <div class="item">
                  <div class="label">{{ getBarrierPartNames(barrier.type, i + 1, part).join(":") }}:{{ part.item.length }}</div>
                  <div [style.transform]="'rotate(' + schemaView.angle + 'deg)'">
                    <object
                      data="/assets/images/tlc/movements_left.svg"
                      [class]="barrier.type + part.ringNumber + part.type"
                      (load)="updatePartSvg($event.target, barrier.type, part)"
                    ></object>
                  </div>
                </div>
              </ng-container>
              <ng-container *ngIf="part.type == 'Straight'">
                <div
                  *ngIf="part.item.pedestrianMovement && part.item.pedestrianMovementLength"
                  [style.flex]="part.item.pedestrianMovementLength"
                  class="item"
                >
                  <div class="label">
                    {{ getBarrierPartNames(barrier.type, i + 1, part, true).join(":") }}:{{ part.item.pedestrianMovementLength }}
                  </div>
                  <div [style.transform]="'rotate(' + schemaView.angle + 'deg)'">
                    <object
                      data="/assets/images/tlc/movements_straight.svg"
                      [class]="barrier.type + part.ringNumber + part.type"
                      (load)="updatePartSvg($event.target, barrier.type, part, true)"
                    ></object>
                  </div>
                </div>

                <div
                  *ngIf="
                    part.item.straightMovement && (!part.item.pedestrianMovementLength || part.item.length != part.item.pedestrianMovementLength)
                  "
                  [style.flex]="part.item.length - part.item.pedestrianMovementLength"
                  class="item _straight"
                >
                  <div class="label">
                    {{ getBarrierPartNames(barrier.type, i + 1, part).join(":") }}:{{ part.item.length - part.item.pedestrianMovementLength }}
                  </div>
                  <div [style.transform]="'rotate(' + schemaView.angle + 'deg)'">
                    <object
                      data="/assets/images/tlc/movements_straight.svg"
                      [class]="barrier.type + part.ringNumber + part.type"
                      (load)="updatePartSvg($event.target, barrier.type, part)"
                    ></object>
                  </div>
                </div>
              </ng-container>
            </div>
          </div>
        </ng-container>
      </ng-container>
    </div>
  </div>
</div>
