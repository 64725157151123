import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
} from "@angular/core";
import { marker as _ } from "@biesbjerg/ngx-translate-extract-marker";

import { TranslateService } from "@ngx-translate/core";
import { Subject } from "rxjs";
import { StaticCameraImage } from "../../dtos/accident.dtos";
import { MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: "camera-ftp-images-viewer",
  templateUrl: "./camera-ftp-images-viewer.component.html",
  styles: [``],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CameraFTPImagesViewerComponent
  implements OnInit, AfterViewInit, OnDestroy {
  _destroy = new Subject();

  imageData: StaticCameraImage;

  constructor(
    private _dialog: MatDialogRef<CameraFTPImagesViewerComponent>,
    public translate: TranslateService
  ) {}

  ngOnInit() {}
  ngAfterViewInit(): void {}

  ngOnDestroy() {
    this._destroy.next();
    this._destroy.complete();
  }

  close() {
    if (this._dialog) {
      this._dialog.close(false);
    } else {
      window.close();
    }
  }
}
