<div fxLayout="row" fxLayoutGap="12px" fxLayoutAlign="center center">
  <div class="object-state active mat-elevation-z2" style="border-radius: 50%">
    <div *ngIf="status?.mode == 'Remote' && status?.state == 'Phase'" class="text">
      {{ remotePhaseTime }}
    </div>
    <svg viewBox="0 0 36 36">
      <circle class="border" cx="18" cy="18" r="18" [style.fill]="getColor(status?.iconStatus?.borderColor)" />
      <!--
      <g class="spin">
        <circle cx="18" cy="18" r="18" />
        <path d="M 36 18 A 18 18 0 0 0 18 0" style="stroke-width: 10px;" />
      </g>
      -->
      <circle class="center" cx="18" cy="18" r="13" [style.fill]="getColor(status?.iconStatus?.color)" />
    </svg>
  </div>

  <mat-button-toggle-group [value]="mode" [disabled]="disabled" class="mode-toggle-group" (change)="toggleGroupChange($event)" appearance="standard">
    <mat-button-toggle
      value="DU"
      title="{{ 'CONTROLLER_MODE.REMOTE' | translate }}"
      [matMenuTriggerFor]="remoteMenu"
      #remoteMenuTrigger="matMenuTrigger"
    >
      {{ "CONTROLLER_MODE.REMOTE_SHORT" | translate }}
    </mat-button-toggle>

    <mat-button-toggle value="KU" (change)="setCoordinationMode()" title="{{ 'CONTROLLER_MODE.COORDINATION' | translate }}">
      {{ "CONTROLLER_MODE.COORDINATION_SHORT" | translate }}
    </mat-button-toggle>

    <!-- <mat-button-toggle value="Adaptive" (change)="setAdaptiveMode()" title="{{ 'CONTROLLER_MODE.ADAPTIVE' | translate }}" [disabled]="true">
      {{ "CONTROLLER_MODE.ADAPTIVE_SHORT" | translate }}
    </mat-button-toggle> -->

    <mat-button-toggle value="LU" (change)="setLocalMode()" title="{{ 'CONTROLLER_MODE.LOCAL' | translate }}">
      {{ "CONTROLLER_MODE.LOCAL_SHORT" | translate }}
    </mat-button-toggle>

    <mat-button-toggle value="RU" (change)="setAdaptiveMode()" title="{{ 'CONTROLLER_MODE.MANUAL' | translate }}" [disabled]="true">
      {{ "CONTROLLER_MODE.MANUAL_SHORT" | translate }}
    </mat-button-toggle>

    <!-- <mat-button-toggle value="Off" (change)="setOffMode()" title="{{ 'CONTROLLER_MODE.OFF' | translate }}">
      {{ "CONTROLLER_MODE.OFF_SHORT" | translate }}
    </mat-button-toggle> -->
  </mat-button-toggle-group>
</div>

<mat-menu #remoteMenu="matMenu" class="menu-panel-extended">
  <ng-container *ngIf="remoteMenuTrigger.menuOpen && trafficObjectSet.schemaSet">
    <div class="remote">
      <button
        mat-raised-button
        class="mat-button-hover off"
        [disabled]="disabled"
        [class.mat-accent]="status?.mode == 'Remote' && status?.state == 'Off'"
        style="display: block; grid-row: 1; grid-column: 1"
        (click)="setOfflineMode()"
        title="{{ 'CONTROLLER_STATE.OFF' | translate }}"
      >
        {{ "CONTROLLER_STATE.OFF_SHORT" | translate }}
      </button>
      <button
        mat-raised-button
        class="mat-button-hover yellow-blink"
        [disabled]="disabled"
        [class.mat-accent]="status?.mode == 'Remote' && status?.state == 'YellowBlink'"
        style="display: block; grid-row: 1; grid-column: 2"
        (click)="setYellowBlinkMode()"
        title="{{ 'CONTROLLER_STATE.YELLOW_BLINK' | translate }}"
      >
        {{ "CONTROLLER_STATE.YELLOW_BLINK_SHORT" | translate }}
      </button>

      <button
        mat-raised-button
        *ngFor="let item of trafficObjectSet.settings.phases; let i = index"
        class="mat-button-hover phase-button"
        [style.grid-row]="2"
        [style.grid-column]="i + 1"
        (click)="setRemoteMode(item); remoteMenuTrigger.closeMenu()"
      >
        <div class="remote-svg mat-border">
          <object
            type="image/svg+xml"
            data="/assets/images/tlc/layout_button.svg"
            (load)="setSvgLayoutState($event.target, item)"
            [style.transform]="'rotate(' + trafficObjectSet.schemaSet.schemaView.angle + 'deg)'"
          ></object>
        </div>
      </button>
    </div>
  </ng-container>
</mat-menu>
