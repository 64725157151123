import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { Component, ChangeDetectionStrategy, Input, OnInit, Inject } from '@angular/core';

import { TrafficObjectInfo } from '../../dtos/tlc.dtos';

import { IGisObjectStateComponent } from 'projects/msu-its-web-common/src/utils/gis-object-group';
import { DialogService } from 'projects/msu-its-web-common/src/services/dialog.service';
import { Observable, of } from 'rxjs';
import { TrafficObjectService } from '../../services/traffic-object.service';
import { TokenService } from 'projects/msu-its-web-common/src/services/token.service';
import { FilesComponent } from 'projects/msu-its-web-common/src/components/files/files.component';
import { APP_ENVIRONMENT } from 'projects/msu-its-web-common/src/utils/shared-consts';

@Component({
  selector: 'traffic-object-state',
  templateUrl: './traffic-object-state.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TrafficObjectStateComponent implements OnInit, IGisObjectStateComponent {
  @Input()
  modelId: string;

  @Input()
  customerId: string;

  @Input()
  modelInfo: TrafficObjectInfo;

  modelPermissions$: Observable<string[]> = of(null);

  actions: { name: string; action: string; permissions?: string[] }[] = [
    { name: _('COMMON.SETTINGS'), action: 'settings', permissions: ['trafficObject.update'] },
    { name: _('COMMON.EDIT'), action: 'edit', permissions: ['trafficObject.update'] },
    ...(this._hasModules(['tfms'])
      ? [
          {
            name: _('TRAFFIC_OBJECTS.DETECTORS'),
            action: 'detectors',
            permissions: ['trafficObject.update'],
          },
        ]
      : []),
    { name: _('AUDIT_LOG.TITLE'), action: 'auditLog', permissions: ['trafficObject.read'] },
    { name: _('EVENT_LOG.TITLE'), action: 'eventLog', permissions: ['trafficObject.read'] },
    { name: _('COMMON.FILES'), action: 'files', permissions: ['trafficObject.read'] },
  ];

  constructor(
    private _trafficObjectService: TrafficObjectService,
    private _tokenService: TokenService,
    private _dialogService: DialogService,
    @Inject(APP_ENVIRONMENT) private _appEnv: any
  ) {}

  ngOnInit() {
    if (this.modelInfo.customerId != this.customerId) {
      this.modelPermissions$ = this._trafficObjectService.getPermissions(
        this.modelId,
        this.customerId
      );
    }

    this._updateActionsByPermissions();
  }

  private _hasModules(modules: string[]) {
    return modules.every((m) => this._appEnv.modules[m]);
  }

  private _updateActionsByPermissions() {
    this.modelPermissions$.subscribe((m) => {
      this.actions = this.actions.filter((a) =>
        this._tokenService.hasPermissionsForItem(this.modelInfo, this.customerId, a.permissions, m)
      );
    });
  }

  private _getExternalUrlBase() {
    return `${this.customerId}/ext/traffic-object/${this.modelId}/`;
  }

  openInNew() {
    this._dialogService.windowDialog(
      this._getExternalUrlBase() + 'state',
      'trafficObjectState_' + this.modelId,
      'menubar=0,toolbar=0,titlebar=0,location=0,locationbar=0,width=600,height=810,resizable=0'
    );
  }

  edit() {
    this._dialogService.windowDialog(
      this._getExternalUrlBase() + 'edit',
      'trafficObjectEdit_' + this.modelId,
      'menubar=0,toolbar=0,titlebar=0,location=0,locationbar=0,width=1000,height=610,resizable=0'
    );
  }

  settings() {
    this._dialogService.windowDialog(
      this._getExternalUrlBase() + 'settings',
      'trafficObjectSettings_' + this.modelId,
      'menubar=0,toolbar=0,titlebar=0,location=0,locationbar=0,width=1100,height=900,resizable=0'
    );
  }

  detectors() {
    this._dialogService.windowDialog(
      this._getExternalUrlBase() + 'detectors',
      'trafficObjectDetectors_' + this.modelId,
      'menubar=0,toolbar=0,titlebar=0,location=0,locationbar=0,width=880,height=840,resizable=0'
    );
  }

  auditLog() {
    this._dialogService.windowDialog(
      this._getExternalUrlBase() + 'audit-log',
      'trafficObjectAuditLog_' + this.modelId,
      'menubar=0,toolbar=0,titlebar=0,location=0,locationbar=0,width=950,height=850,resizable=0'
    );
  }

  eventLog() {
    this._dialogService.windowDialog(
      this._getExternalUrlBase() + 'event-log',
      'trafficObjectEventLog_' + this.modelId,
      'menubar=0,toolbar=0,titlebar=0,location=0,locationbar=0,width=950,height=850,resizable=0'
    );
  }

  files() {
    const dialog = this._dialogService.dialog.open(FilesComponent, { disableClose: false });
    dialog.componentInstance.entityId = this.modelId;
    dialog.componentInstance.entityName = this.modelInfo.name;
  }
}
