import { marker as _ } from "@biesbjerg/ngx-translate-extract-marker";
import { AccidentType, CameraCommand, CameraSourceType } from "../dtos/accident.dtos";

export const CONTROL_CAMERA = [
  { name: "MoveLeft", command: CameraCommand.MoveLeft },
  { name: "MoveRight", command: CameraCommand.MoveRight },
  { name: "MoveUp", command: CameraCommand.MoveUp },
  { name: "MoveDown", command: CameraCommand.MoveDown },
  { name: "MoveHome", command: CameraCommand.MoveHome },
  { name: "FocusIn", command: CameraCommand.FocusIn },
  { name: "FocusOut", command: CameraCommand.FocusOut },
  { name: "ZoomIn", command: CameraCommand.ZoomIn },
  { name: "ZoomOut", command: CameraCommand.ZoomOut },
  // { name: "LightOn", command: CameraCommand.LightOn },
  // { name: "LightOff", command: CameraCommand.LightOff },
  // { name: "Wipe", command: CameraCommand.Wipe },
];

export const CAMERA_FTP_SOURCES_TYPE = [
  {
    type: CameraSourceType.FTP,
    name: _("CAMERA_FTP_SOURCES.TYPE_FTP"),
  }
];
export const TRAFFIC_ACCIDENT_TYPE = [
  {
    type: AccidentType.HittingACyclist,
    name: _("TRAFFIC_ACCIDENT_TYPE.HITTING_A_CYCLIST"),
  },
  {
    type: AccidentType.HittingOnHorseDrawnTransport,
    name: _("TRAFFIC_ACCIDENT_TYPE.HITTING_ON_HORSE_DRAWN_TRANSPORT"),
  },
  {
    type: AccidentType.HittingAnAnimal,
    name: _("TRAFFIC_ACCIDENT_TYPE.HITTING_AN_ANIMAL"),
  },

  {
    type: AccidentType.HittingAPedestrian,
    name: _("TRAFFIC_ACCIDENT_TYPE.HITTING_A_PEDESTRIAN"),
  },
  {
    type: AccidentType.HittingAnObstacle,
    name: _("TRAFFIC_ACCIDENT_TYPE.HITTING_AN_OBSTACLE"),
  },
  {
    type: AccidentType.HittingAStandingVehicle,
    name: _("TRAFFIC_ACCIDENT_TYPE.HITTING_A_STANDING_VEHICLE"),
  },
  {
    type: AccidentType.Rollover,
    name: _("TRAFFIC_ACCIDENT_TYPE.ROLLOVER"),
  },

  {
    type: AccidentType.PassengerFall,
    name: _("TRAFFIC_ACCIDENT_TYPE.PASSENGER_FALL"),
  },
  {
    type: AccidentType.Crash,
    name: _("TRAFFIC_ACCIDENT_TYPE.CRASH"),
  },
  {
    type: AccidentType.Other,
    name: _("TRAFFIC_ACCIDENT_TYPE.OTHER"),
  },
];

export const itemArray = [
  [56.035312, 92.89209],
  [55.990704, 92.887068],
  [55.990094, 92.886577],
  [56.050172, 92.898416],
  [56.004694, 92.850313],
  [56.022989, 92.805441],
  [56.033385, 93.039688],
  [56.032838, 93.040253],
  [56.032952, 93.039198],
  [56.040904, 92.85622],
  [56.040872, 92.857403],
  [56.040609, 92.855997],
  [56.040669, 92.855668],
  [56.04069, 92.854568],
  [56.040838, 92.855668],
  [56.041309, 92.855635],
  [56.044055, 92.944521],
  [56.044187, 92.910833],
  [56.042642, 92.910483],
  [56.042547, 92.910385],
  [56.042787, 92.907978],
  [56.04291, 92.907774],
  [56.043927, 92.908421],
  [56.050692, 92.89803],
  [56.050115, 92.897451],
  [56.05007, 92.89807],
  [56.050581, 92.897115],
  [56.054809, 92.906055],
  [56.054377, 92.906161],
  [56.053887, 92.906268],
  [56.052865, 92.908079],
  [56.052597, 92.905769],
  [56.052679, 92.903154],
  [56.053423, 92.902937],
  [56.054226, 92.903312],
  [56.055221, 92.90431],
  [56.05868, 92.913807],
  [56.058506, 92.914431],
  [56.057859, 92.91484],
  [56.058033, 92.914284],
  [56.057904, 92.913315],
  [56.058171, 92.912804],
  [56.058595, 92.913201],
  [56.058883, 92.913051],
  [56.065807, 92.927966],
  [56.065552, 92.928342],
  [56.065243, 92.928608],
  [56.064934, 92.928488],
  [56.06522, 92.927686],
  [56.06552, 92.927211],
  [56.065675, 92.927324],
  [56.066015, 92.927382],
  [56.062355, 92.920746],
  [56.062054, 92.921398],
  [56.061741, 92.921242],
  [56.06184, 92.920852],
  [56.062002, 92.920089],
  [56.062433, 92.920368],
  [56.069141, 92.934459],
  [56.06884, 92.934756],
  [56.068418, 92.934787],
  [56.068436, 92.934086],
  [56.068721, 92.933605],
  [56.069037, 92.934121],
  [56.067104, 92.931204],
  [56.051576, 92.908036],
  [56.067392, 92.930871],
  [56.048908, 92.914306],
  [56.041219, 92.92702],
  [56.040849, 92.928533],
  [56.040031, 92.92806],
  [56.039574, 92.927461],
  [56.039382, 92.925456],
  [56.039425, 92.924711],
  [56.040616, 92.92501],
  [56.048278, 92.913008],
  [56.015838, 92.867129],
  [56.015863, 92.865943],
  [55.990206, 92.758033],
  [55.989808, 92.757792],
  [55.989754, 92.757371],
  [55.989885, 92.757399],
  [55.990065, 92.757538],
  [55.990049, 92.757682],
  [55.998803, 92.965779],
  [55.99856, 92.965127],
  [55.998481, 92.964039],
  [56.051957, 92.913159],
  [56.051856, 92.91392],
  [56.051475, 92.9098],
  [56.049321, 92.909339],
  [56.049913, 92.909201],
  [56.051036, 92.908922],
  [56.044069, 92.897603],
  [56.044273, 92.898301],
  [56.043433, 92.897847],
  [56.043227, 92.896145],
  [56.044084, 92.896598],
  [56.044246, 92.896532],
  [56.055311, 92.919694],
  [56.054999, 92.920416],
  [56.05447, 92.920325],
  [56.054422, 92.920035],
  [56.054567, 92.919566],
  [56.054707, 92.918539],
  [56.055115, 92.918837],
  [56.05531, 92.918898],
  [56.031285, 92.907735],
  [56.031254, 92.907222],
  [56.032477, 92.907496],
  [56.033603, 92.910229],
  [56.028182, 92.907456],
  [56.028574, 92.907482],
  [56.03099, 92.892805],
  [56.037083, 92.834727],
  [56.037039, 92.834791],
  [56.020881, 92.870629],
  [56.020629, 92.871262],
  [56.019736, 92.868993],
  [56.020064, 92.868665],
  [56.020737, 92.867517],
  [56.020854, 92.867464],
  [56.020295, 92.866562],
  [56.02031, 92.866415],
  [56.020779, 92.866144],
  [56.020893, 92.865052],
  [56.004153, 92.955846],
  [56.003888, 92.956724],
  [56.003878, 92.955914],
  [56.004032, 92.955175],
  [56.004021, 92.954705],
  [56.034218, 92.90884],
  [56.033809, 92.909153],
  [56.033714, 92.909473],
  [56.032906, 92.908373],
  [56.033711, 92.908271],
  [56.033834, 92.90803],
  [56.034017, 92.90863],
  [56.034753, 92.919987],
  [56.033773, 92.919493],
  [56.033839, 92.919426],
  [56.034615, 92.919745],
  [56.03384, 92.9126],
  [56.03373, 92.91242],
  [56.056371, 92.946246],
  [56.019819, 92.792143],
  [56.019865, 92.792299],
  [56.021692, 92.789133],
  [56.021574, 92.789348],
  [56.021613, 92.78912],
  [56.021714, 92.788967],
  [56.024773, 92.785219],
  [56.024198, 92.784975],
  [56.024144, 92.784771],
  [56.024127, 92.784294],
  [56.024153, 92.78402],
  [56.024607, 92.783854],
  [56.024832, 92.783811],
  [56.023388, 93.061841],
  [56.022893, 93.062294],
  [56.022856, 93.06274],
  [56.022455, 93.058537],
  [56.0226, 93.058605],
  [56.023422, 93.061728],
  [56.019352, 93.031878],
  [56.019266, 93.032103],
  [56.018589, 93.028773],
  [56.018512, 93.028775],
  [56.015506, 92.847704],
  [56.015111, 92.848766],
  [56.014654, 92.847907],
  [55.980988, 92.936161],
  [55.980905, 92.93696],
  [55.980882, 92.936886],
  [55.980408, 92.935434],
  [55.980383, 92.935372],
  [55.98095, 92.93605],
  [55.987523, 92.963119],
  [55.987484, 92.963146],
  [55.98707, 92.963696],
  [55.987021, 92.964105],
  [55.986968, 92.963491],
  [55.986979, 92.962844],
  [55.984228, 92.948443],
  [55.983971, 92.949959],
  [55.983913, 92.949991],
  [55.983121, 92.94922],
  [55.983537, 92.948524],
  [55.983556, 92.948175],
  [56.0045, 92.848141],
  [56.007102, 92.864334],
  [56.00693, 92.870868],
  [56.006964, 92.870832],
  [56.004636, 92.849922],
  [56.004305, 92.8496],
  [56.004499, 92.84948],
  [56.007283, 92.859491],
  [56.006955, 92.85991],
  [56.006318, 92.85929],
  [56.006943, 92.864463],
  [56.006888, 92.864012],
  [56.006968, 92.864205],
  [56.007005, 92.870304],
  [56.006851, 92.870361],
  [56.008642, 92.881755],
  [56.008331, 92.882829],
  [56.008133, 92.882342],
  [56.007836, 92.88104],
  [56.00839, 92.881709],
  [56.006925, 92.867779],
  [56.006879, 92.867419],
  [56.006974, 92.867559],
  [56.00749, 92.878144],
  [56.007157, 92.875539],
  [56.007236, 92.875502],
  [56.007408, 92.877214],
  [56.000772, 92.840745],
  [56.000537, 92.840406],
  [56.00926, 92.888387],
  [56.009601, 92.888694],
  [56.024623, 92.75612],
  [56.024181, 92.756938],
  [56.024124, 92.75696],
  [56.023973, 92.756579],
  [56.023934, 92.756079],
  [56.023873, 92.755603],
  [56.024604, 92.755931],
  [56.023137, 92.750464],
  [56.022939, 92.750826],
  [56.022814, 92.748771],
  [56.022921, 92.749168],
  [56.023409, 92.74923],
  [56.02352, 92.749748],
  [56.113867, 92.940304],
  [56.113455, 92.940117],
  [56.113803, 92.940016],
  [56.100071, 92.926351],
  [56.100034, 92.926391],
  [56.084925, 92.933012],
  [56.0861, 92.93439],
  [56.017438, 92.843784],
  [56.017438, 92.843538],
  [56.034062, 92.819707],
  [56.033884, 92.819365],
  [56.047726, 92.770675],
  [56.033167, 92.814948],
  [56.033062, 92.815214],
  [56.051289, 92.756087],
  [56.051179, 92.75583],
  [56.055034, 92.740244],
  [56.054957, 92.739635],
  [56.035035, 92.830457],
  [56.034783, 92.825847],
  [56.036108, 92.79261],
  [56.04017, 92.784737],
  [56.040492, 92.784043],
  [56.04047, 92.78447],
  [56.035739, 92.793749],
  [56.042711, 92.780088],
  [56.044338, 92.773698],
  [56.044249, 92.773993],
  [56.042988, 92.779884],
  [56.034444, 92.824656],
  [56.046038, 92.885799],
  [56.045733, 92.885526],
  [56.045732, 92.884968],
  [56.045978, 92.885097],
  [56.044795, 92.879153],
  [56.044993, 92.88117],
  [56.044532, 92.87903],
  [56.043479, 92.878955],
  [56.044498, 92.878627],
  [56.04441, 92.877002],
  [56.04481, 92.878804],
  [56.043411, 92.871895],
  [56.043591, 92.873735],
  [56.043152, 92.871787],
  [56.042612, 92.871498],
  [56.04319, 92.871493],
  [56.043072, 92.86991],
  [56.043357, 92.871578],
  [56.042969, 92.865071],
  [56.042204, 92.865374],
  [56.042279, 92.866772],
  [56.041834, 92.864433],
  [56.042135, 92.864932],
  [56.011042, 92.871529],
  [56.009947, 92.871804],
  [56.009947, 92.871694],
  [56.010324, 92.8713],
  [56.010537, 92.870363],
  [56.011072, 92.871304],
  [56.010184, 92.858968],
  [56.009911, 92.859694],
  [56.009607, 92.859059],
  [56.009738, 92.85852],
  [56.011539, 92.892043],
  [56.011416, 92.892167],
  [56.011218, 92.891765],
  [56.011169, 92.89155],
  [56.01138, 92.890677],
  [56.011714, 92.891276],
  [56.01056, 92.867171],
  [56.010255, 92.868092],
  [56.009815, 92.86727],
  [56.009795, 92.86706],
  [56.010247, 92.866069],
  [56.010867, 92.877579],
  [56.010371, 92.876525],
  [56.010719, 92.875178],
  [56.011348, 92.876338],
  [56.008993, 92.84283],
  [56.01433, 92.801732],
  [56.014199, 92.80148],
  [56.014096, 92.801085],
  [56.013979, 92.800978],
  [56.015275, 92.799779],
  [56.015339, 92.799885],
  [56.011756, 92.801218],
  [56.01175, 92.801123],
  [56.017315, 92.800827],
  [56.017173, 92.800988],
  [56.017243, 92.80161],
  [56.016599, 92.80095],
  [56.016545, 92.800746],
  [56.017176, 92.80073],
  [56.001589, 92.800441],
  [56.001341, 92.800441],
  [56.000722, 92.800532],
  [55.999676, 92.798874],
  [55.999709, 92.798748],
  [55.975803, 92.806878],
  [56.035699, 92.831532],
  [56.035679, 92.830874],
  [56.057518, 92.940753],
  [56.058322, 92.939272],
  [56.012476, 92.813723],
  [56.012436, 92.813526],
  [56.012124, 92.813433],
  [56.012469, 92.813206],
  [56.012246, 92.805795],
  [56.012606, 92.816821],
  [56.012351, 92.806881],
  [56.006037, 92.95429],
  [56.005694, 92.954333],
  [56.005642, 92.954162],
  [56.006267, 92.953511],
  [56.006319, 92.953708],
  [56.00842, 92.84115],
  [56.0485, 92.767986],
  [56.052306, 92.941493],
  [56.052132, 92.94116],
  [56.01186, 92.815921],
  [56.011589, 92.814457],
  [56.010856, 92.813652],
  [56.011, 92.813558],
  [56.011628, 92.812767],
  [56.01207, 92.819174],
  [56.011685, 92.818529],
  [56.011777, 92.818372],
  [56.011877, 92.818415],
  [56.023366, 93.014763],
  [56.02624, 93.017909],
  [56.022953, 93.014653],
  [56.023074, 93.01623],
  [56.022627, 93.014202],
  [56.022134, 93.01113],
  [56.007893, 92.952515],
  [56.007679, 92.952705],
  [56.007546, 92.9522],
  [56.007661, 92.951546],
  [56.007808, 92.952029],
  [56.007859, 92.95219],
  [56.013411, 92.971966],
  [56.012134, 92.972821],
  [56.012121, 92.972767],
  [56.012378, 92.972166],
  [56.01242, 92.971313],
  [56.013369, 92.971794],
  [55.996673, 92.906152],
  [55.993112, 92.892296],
  [56.013889, 92.975276],
  [56.013655, 92.975323],
  [56.016242, 92.789433],
  [56.014218, 92.813368],
  [56.014584, 92.813237],
  [56.018746, 92.813618],
  [56.018196, 92.814092],
  [56.018159, 92.814072],
  [56.01806, 92.813366],
  [56.017454, 92.812969],
  [56.018123, 92.813094],
  [56.018158, 92.812957],
  [56.018751, 92.813523],
  [56.013683, 92.87295],
  [56.013591, 92.872851],
  [56.012925, 92.871257],
  [56.012816, 92.871034],
  [56.013464, 92.870432],
  [56.013557, 92.870239],
  [56.013238, 92.8585],
  [56.012632, 92.858381],
  [56.012783, 92.857782],
  [56.012848, 92.857887],
  [56.01373, 92.866645],
  [56.013353, 92.867572],
  [56.013234, 92.867302],
  [56.012894, 92.866688],
  [56.013037, 92.865582],
  [56.013238, 92.865582],
  [56.011839, 92.836859],
  [56.01176, 92.837569],
  [56.012154, 92.836405],
  [56.012094, 92.842391],
  [56.014433, 92.884866],
  [56.013865, 92.87738],
  [56.013944, 92.877186],
  [56.026567, 92.831077],
  [56.026581, 92.830905],
  [56.019075, 92.838142],
  [56.015838, 92.870705],
  [56.015045, 92.872146],
  [56.014419, 92.871017],
  [56.014376, 92.870783],
  [56.014872, 92.86925],
  [56.015855, 92.870491],
  [56.014804, 92.867238],
  [56.014974, 92.866277],
  [56.070759, 92.987788],
  [56.071128, 92.989225],
  [56.069864, 92.989494],
  [56.069684, 92.989633],
  [56.069954, 92.986329],
  [56.069545, 92.985226],
  [56.071587, 92.983883],
  [56.071674, 92.984322],
  [56.04829, 92.950125],
  [56.048203, 92.950446],
  [56.047654, 92.9499],
  [56.035312, 92.89209],
  [55.990704, 92.887068],
  [55.990094, 92.886577],
  [56.050172, 92.898416],
  [56.004694, 92.850313],
  [56.022989, 92.805441],
  [56.033385, 93.039688],
  [56.032838, 93.040253],
  [56.032952, 93.039198],
  [56.040904, 92.85622],
  [56.040872, 92.857403],
  [56.040609, 92.855997],
  [56.040669, 92.855668],
  [56.04069, 92.854568],
  [56.040838, 92.855668],
  [56.041309, 92.855635],
  [56.044055, 92.944521],
  [56.044187, 92.910833],
  [56.042642, 92.910483],
  [56.042547, 92.910385],
  [56.042787, 92.907978],
  [56.04291, 92.907774],
  [56.043927, 92.908421],
  [56.050692, 92.89803],
  [56.050115, 92.897451],
  [56.05007, 92.89807],
  [56.050581, 92.897115],
  [56.054809, 92.906055],
  [56.054377, 92.906161],
  [56.053887, 92.906268],
  [56.052865, 92.908079],
  [56.052597, 92.905769],
  [56.052679, 92.903154],
  [56.053423, 92.902937],
  [56.054226, 92.903312],
  [56.055221, 92.90431],
  [56.05868, 92.913807],
  [56.058506, 92.914431],
  [56.057859, 92.91484],
  [56.058033, 92.914284],
  [56.057904, 92.913315],
  [56.058171, 92.912804],
  [56.058595, 92.913201],
  [56.058883, 92.913051],
  [56.065807, 92.927966],
  [56.065552, 92.928342],
  [56.065243, 92.928608],
  [56.064934, 92.928488],
  [56.06522, 92.927686],
  [56.06552, 92.927211],
  [56.065675, 92.927324],
  [56.066015, 92.927382],
  [56.062355, 92.920746],
  [56.062054, 92.921398],
  [56.061741, 92.921242],
  [56.06184, 92.920852],
  [56.062002, 92.920089],
  [56.062433, 92.920368],
  [56.069141, 92.934459],
  [56.06884, 92.934756],
  [56.068418, 92.934787],
  [56.068436, 92.934086],
  [56.068721, 92.933605],
  [56.069037, 92.934121],
  [56.067104, 92.931204],
  [56.051576, 92.908036],
  [56.067392, 92.930871],
  [56.048908, 92.914306],
  [56.041219, 92.92702],
  [56.040849, 92.928533],
  [56.040031, 92.92806],
  [56.039574, 92.927461],
  [56.039382, 92.925456],
  [56.039425, 92.924711],
  [56.040616, 92.92501],
  [56.048278, 92.913008],
  [56.015838, 92.867129],
  [56.015863, 92.865943],
  [55.990206, 92.758033],
  [55.989808, 92.757792],
  [55.989754, 92.757371],
  [55.989885, 92.757399],
  [55.990065, 92.757538],
  [55.990049, 92.757682],
  [55.998803, 92.965779],
  [55.99856, 92.965127],
  [55.998481, 92.964039],
  [56.051957, 92.913159],
  [56.051856, 92.91392],
  [56.051475, 92.9098],
  [56.049321, 92.909339],
  [56.049913, 92.909201],
  [56.051036, 92.908922],
  [56.044069, 92.897603],
  [56.044273, 92.898301],
  [56.043433, 92.897847],
  [56.043227, 92.896145],
  [56.044084, 92.896598],
  [56.044246, 92.896532],
  [56.055311, 92.919694],
  [56.054999, 92.920416],
  [56.05447, 92.920325],
  [56.054422, 92.920035],
  [56.054567, 92.919566],
  [56.054707, 92.918539],
  [56.055115, 92.918837],
  [56.05531, 92.918898],
  [56.031285, 92.907735],
  [56.031254, 92.907222],
  [56.032477, 92.907496],
  [56.033603, 92.910229],
  [56.028182, 92.907456],
  [56.028574, 92.907482],
  [56.03099, 92.892805],
  [56.037083, 92.834727],
  [56.037039, 92.834791],
  [56.020881, 92.870629],
  [56.020629, 92.871262],
  [56.019736, 92.868993],
  [56.020064, 92.868665],
  [56.020737, 92.867517],
  [56.020854, 92.867464],
  [56.020295, 92.866562],
  [56.02031, 92.866415],
  [56.020779, 92.866144],
  [56.020893, 92.865052],
  [56.004153, 92.955846],
  [56.003888, 92.956724],
  [56.003878, 92.955914],
  [56.004032, 92.955175],
  [56.004021, 92.954705],
  [56.034218, 92.90884],
  [56.033809, 92.909153],
  [56.033714, 92.909473],
  [56.032906, 92.908373],
  [56.033711, 92.908271],
  [56.033834, 92.90803],
  [56.034017, 92.90863],
  [56.034753, 92.919987],
  [56.033773, 92.919493],
  [56.033839, 92.919426],
  [56.034615, 92.919745],
  [56.03384, 92.9126],
  [56.03373, 92.91242],
  [56.056371, 92.946246],
  [56.019819, 92.792143],
  [56.019865, 92.792299],
  [56.021692, 92.789133],
  [56.021574, 92.789348],
  [56.021613, 92.78912],
  [56.021714, 92.788967],
  [56.024773, 92.785219],
  [56.024198, 92.784975],
  [56.024144, 92.784771],
  [56.024127, 92.784294],
  [56.024153, 92.78402],
  [56.024607, 92.783854],
  [56.024832, 92.783811],
  [56.023388, 93.061841],
  [56.022893, 93.062294],
  [56.022856, 93.06274],
  [56.022455, 93.058537],
  [56.0226, 93.058605],
  [56.023422, 93.061728],
  [56.019352, 93.031878],
  [56.019266, 93.032103],
  [56.018589, 93.028773],
  [56.018512, 93.028775],
  [56.015506, 92.847704],
  [56.015111, 92.848766],
  [56.014654, 92.847907],
  [55.980988, 92.936161],
  [55.980905, 92.93696],
  [55.980882, 92.936886],
  [55.980408, 92.935434],
  [55.980383, 92.935372],
  [55.98095, 92.93605],
  [55.987523, 92.963119],
  [55.987484, 92.963146],
  [55.98707, 92.963696],
  [55.987021, 92.964105],
  [55.986968, 92.963491],
  [55.986979, 92.962844],
  [55.984228, 92.948443],
  [55.983971, 92.949959],
  [55.983913, 92.949991],
  [55.983121, 92.94922],
  [55.983537, 92.948524],
  [55.983556, 92.948175],
  [56.0045, 92.848141],
  [56.007102, 92.864334],
  [56.00693, 92.870868],
  [56.006964, 92.870832],
  [56.004636, 92.849922],
  [56.004305, 92.8496],
  [56.004499, 92.84948],
  [56.007283, 92.859491],
  [56.006955, 92.85991],
  [56.006318, 92.85929],
  [56.006943, 92.864463],
  [56.006888, 92.864012],
  [56.006968, 92.864205],
  [56.007005, 92.870304],
  [56.006851, 92.870361],
  [56.008642, 92.881755],
  [56.008331, 92.882829],
  [56.008133, 92.882342],
  [56.007836, 92.88104],
  [56.00839, 92.881709],
  [56.006925, 92.867779],
  [56.006879, 92.867419],
  [56.006974, 92.867559],
  [56.00749, 92.878144],
  [56.007157, 92.875539],
  [56.007236, 92.875502],
  [56.007408, 92.877214],
  [56.000772, 92.840745],
  [56.000537, 92.840406],
  [56.00926, 92.888387],
  [56.009601, 92.888694],
  [56.024623, 92.75612],
  [56.024181, 92.756938],
  [56.024124, 92.75696],
  [56.023973, 92.756579],
  [56.023934, 92.756079],
  [56.023873, 92.755603],
  [56.024604, 92.755931],
  [56.023137, 92.750464],
  [56.022939, 92.750826],
  [56.022814, 92.748771],
  [56.022921, 92.749168],
  [56.023409, 92.74923],
  [56.02352, 92.749748],
  [56.113867, 92.940304],
  [56.113455, 92.940117],
  [56.113803, 92.940016],
  [56.100071, 92.926351],
  [56.100034, 92.926391],
  [56.084925, 92.933012],
  [56.0861, 92.93439],
  [56.017438, 92.843784],
  [56.017438, 92.843538],
  [56.034062, 92.819707],
  [56.033884, 92.819365],
  [56.047726, 92.770675],
  [56.033167, 92.814948],
  [56.033062, 92.815214],
  [56.051289, 92.756087],
  [56.051179, 92.75583],
  [56.055034, 92.740244],
  [56.054957, 92.739635],
  [56.035035, 92.830457],
  [56.034783, 92.825847],
  [56.036108, 92.79261],
  [56.04017, 92.784737],
  [56.040492, 92.784043],
  [56.04047, 92.78447],
  [56.035739, 92.793749],
  [56.042711, 92.780088],
  [56.044338, 92.773698],
  [56.044249, 92.773993],
  [56.042988, 92.779884],
  [56.034444, 92.824656],
  [56.046038, 92.885799],
  [56.045733, 92.885526],
  [56.045732, 92.884968],
  [56.045978, 92.885097],
  [56.044795, 92.879153],
  [56.044993, 92.88117],
  [56.044532, 92.87903],
  [56.043479, 92.878955],
  [56.044498, 92.878627],
  [56.04441, 92.877002],
  [56.04481, 92.878804],
  [56.043411, 92.871895],
  [56.043591, 92.873735],
  [56.043152, 92.871787],
  [56.042612, 92.871498],
  [56.04319, 92.871493],
  [56.043072, 92.86991],
  [56.043357, 92.871578],
  [56.042969, 92.865071],
  [56.042204, 92.865374],
  [56.042279, 92.866772],
  [56.041834, 92.864433],
  [56.042135, 92.864932],
  [56.011042, 92.871529],
  [56.009947, 92.871804],
  [56.009947, 92.871694],
  [56.010324, 92.8713],
  [56.010537, 92.870363],
  [56.011072, 92.871304],
  [56.010184, 92.858968],
  [56.009911, 92.859694],
  [56.009607, 92.859059],
  [56.009738, 92.85852],
  [56.011539, 92.892043],
  [56.011416, 92.892167],
  [56.011218, 92.891765],
  [56.011169, 92.89155],
  [56.01138, 92.890677],
  [56.011714, 92.891276],
  [56.01056, 92.867171],
  [56.010255, 92.868092],
  [56.009815, 92.86727],
  [56.009795, 92.86706],
  [56.010247, 92.866069],
  [56.010867, 92.877579],
  [56.010371, 92.876525],
  [56.010719, 92.875178],
  [56.011348, 92.876338],
  [56.008993, 92.84283],
  [56.01433, 92.801732],
  [56.014199, 92.80148],
  [56.014096, 92.801085],
  [56.013979, 92.800978],
  [56.015275, 92.799779],
  [56.015339, 92.799885],
  [56.011756, 92.801218],
  [56.01175, 92.801123],
  [56.017315, 92.800827],
  [56.017173, 92.800988],
  [56.017243, 92.80161],
  [56.016599, 92.80095],
  [56.016545, 92.800746],
  [56.017176, 92.80073],
  [56.001589, 92.800441],
  [56.001341, 92.800441],
  [56.000722, 92.800532],
  [55.999676, 92.798874],
  [55.999709, 92.798748],
  [55.975803, 92.806878],
  [56.035699, 92.831532],
  [56.035679, 92.830874],
  [56.057518, 92.940753],
  [56.058322, 92.939272],
  [56.012476, 92.813723],
  [56.012436, 92.813526],
  [56.012124, 92.813433],
  [56.012469, 92.813206],
  [56.012246, 92.805795],
  [56.012606, 92.816821],
  [56.012351, 92.806881],
  [56.006037, 92.95429],
  [56.005694, 92.954333],
  [56.005642, 92.954162],
  [56.006267, 92.953511],
  [56.006319, 92.953708],
  [56.00842, 92.84115],
  [56.0485, 92.767986],
  [56.052306, 92.941493],
  [56.052132, 92.94116],
  [56.01186, 92.815921],
  [56.011589, 92.814457],
  [56.010856, 92.813652],
  [56.011, 92.813558],
  [56.011628, 92.812767],
  [56.01207, 92.819174],
  [56.011685, 92.818529],
  [56.011777, 92.818372],
  [56.011877, 92.818415],
  [56.023366, 93.014763],
  [56.02624, 93.017909],
  [56.022953, 93.014653],
  [56.023074, 93.01623],
  [56.022627, 93.014202],
  [56.022134, 93.01113],
  [56.007893, 92.952515],
  [56.007679, 92.952705],
  [56.007546, 92.9522],
  [56.007661, 92.951546],
  [56.007808, 92.952029],
  [56.007859, 92.95219],
  [56.013411, 92.971966],
  [56.012134, 92.972821],
  [56.012121, 92.972767],
  [56.012378, 92.972166],
  [56.01242, 92.971313],
  [56.013369, 92.971794],
  [55.996673, 92.906152],
  [55.993112, 92.892296],
  [56.013889, 92.975276],
  [56.013655, 92.975323],
  [56.016242, 92.789433],
  [56.014218, 92.813368],
  [56.014584, 92.813237],
  [56.018746, 92.813618],
  [56.018196, 92.814092],
  [56.018159, 92.814072],
  [56.01806, 92.813366],
  [56.017454, 92.812969],
  [56.018123, 92.813094],
  [56.018158, 92.812957],
  [56.018751, 92.813523],
  [56.013683, 92.87295],
  [56.013591, 92.872851],
  [56.012925, 92.871257],
  [56.012816, 92.871034],
  [56.013464, 92.870432],
  [56.013557, 92.870239],
  [56.013238, 92.8585],
  [56.012632, 92.858381],
  [56.012783, 92.857782],
  [56.012848, 92.857887],
  [56.01373, 92.866645],
  [56.013353, 92.867572],
  [56.013234, 92.867302],
  [56.012894, 92.866688],
  [56.013037, 92.865582],
  [56.013238, 92.865582],
  [56.011839, 92.836859],
  [56.01176, 92.837569],
  [56.012154, 92.836405],
  [56.012094, 92.842391],
  [56.014433, 92.884866],
  [56.013865, 92.87738],
  [56.013944, 92.877186],
  [56.026567, 92.831077],
  [56.026581, 92.830905],
  [56.019075, 92.838142],
  [56.015838, 92.870705],
  [56.015045, 92.872146],
  [56.014419, 92.871017],
  [56.014376, 92.870783],
  [56.014872, 92.86925],
  [56.015855, 92.870491],
  [56.014804, 92.867238],
  [56.014974, 92.866277],
  [56.070759, 92.987788],
  [56.071128, 92.989225],
  [56.069864, 92.989494],
  [56.069684, 92.989633],
  [56.069954, 92.986329],
  [56.069545, 92.985226],
  [56.071587, 92.983883],
  [56.071674, 92.984322],
  [56.04829, 92.950125],
  [56.048203, 92.950446],
  [56.047654, 92.9499],
  [56.047356, 92.949041],
  [56.047559, 92.948965],
  [56.057752, 92.9653],
  [56.057482, 92.965289],
  [56.055591, 92.962249],
  [56.044932, 92.944765],
  [56.044822, 92.945022],
  [56.056144, 92.962705],
  [56.010815, 92.841613],
  [56.01287, 92.958949],
  [56.01169, 92.959179],
  [56.047632, 92.917009],
  [56.047482, 92.917529],
  [56.047079, 92.917041],
  [56.046999, 92.916437],
  [56.046353, 92.916248],
  [56.046424, 92.915261],
  [56.046516, 92.914842],
  [56.047098, 92.91597],
  [56.040623, 92.903252],
  [56.040552, 92.903558],
  [56.039936, 92.903441],
  [56.039762, 92.902101],
  [56.039952, 92.901834],
  [56.060815, 92.718802],
  [56.060744, 92.718819],
  [56.038978, 92.923694],
  [56.038522, 92.923794],
  [56.038722, 92.923475],
  [56.036906, 92.919897],
  [56.036961, 92.920764],
  [56.036419, 92.920319],
  [56.036271, 92.919699],
  [56.036804, 92.919345],
  [56.036973, 92.919548],
  [55.999573, 92.968858],
  [55.999157, 92.968447],
  [55.999311, 92.967984],
  [55.999536, 92.968188],
  [56.026969, 92.908526],
  [56.026739, 92.908716],
  [56.026074, 92.907166],
  [56.026529, 92.907214],
  [56.027229, 92.9073],
  [56.044009, 92.941375],
  [56.043894, 92.943002],
  [56.043534, 92.942727],
  [56.04183, 92.940004],
  [56.042057, 92.939878],
  [56.04438, 92.941117],
  [56.032881, 92.921977],
  [56.032774, 92.922312],
  [56.032197, 92.92121],
  [56.032368, 92.920931],
  [56.032834, 92.921178],
  [56.033332, 92.920652],
  [56.039793, 92.936225],
  [56.03903, 92.934948],
  [56.039444, 92.935072],
  [56.039661, 92.935367],
  [56.030126, 92.91613],
  [56.030185, 92.916994],
  [56.011886, 92.803654],
  [56.01704, 92.865936],
  [56.016574, 92.86659],
  [56.016407, 92.865997],
  [56.016427, 92.865136],
  [56.016942, 92.865701],
  [56.07566, 92.998596],
  [56.075801, 92.999534],
  [56.075049, 92.997834],
  [56.074886, 92.996995],
  [56.075351, 92.997582],
  [55.992891, 92.890349],
  [55.992578, 92.885306],
  [55.992707, 92.885537],
  [55.991561, 92.885603],
  [55.991607, 92.885552],
  [56.007326, 92.83756],
  [56.007141, 92.837448],
  [56.007236, 92.836234],
  [56.007386, 92.836316],
  [56.009979, 92.837038],
  [56.010599, 92.83709],
  [56.042643, 93.021909],
  [56.042342, 93.022045],
  [56.016578, 92.854016],
  [56.01609, 92.853386],
  [56.016466, 92.852785],
  [56.016589, 92.853032],
  [56.016013, 92.840501],
  [56.015721, 92.840133],
  [56.016265, 92.846809],
  [56.016449, 92.846929],
  [56.015879, 92.845384],
  [56.015842, 92.845387],
  [56.016261, 92.843114],
  [56.016134, 92.842938],
  [56.016336, 92.844839],
  [56.007471, 92.842202],
  [56.007458, 92.842042],
  [56.013327, 92.840872],
  [56.012397, 92.841018],
  [56.012636, 92.840528],
  [56.012907, 92.840466],
  [56.03129, 93.06915],
  [56.030408, 93.068082],
  [56.029052, 93.066409],
  [55.972702, 92.790334],
  [55.973128, 92.792898],
  [55.972196, 92.790275],
  [55.972031, 92.790093],
  [55.972227, 92.789347],
  [55.972383, 92.789108],
  [55.978999, 92.842507],
  [55.978942, 92.842617],
  [55.978697, 92.84241],
  [55.978684, 92.841874],
  [55.978812, 92.841529],
  [55.978917, 92.841704],
  [55.96794, 92.732365],
  [55.96791, 92.732583],
  [55.972139, 92.788074],
  [55.971954, 92.788339],
  [55.971947, 92.78769],
  [55.96606, 92.723779],
  [55.965799, 92.7235],
  [55.983181, 92.879599],
  [55.983046, 92.879421],
  [55.975482, 92.801441],
  [55.975311, 92.801119],
  [55.97424, 92.796223],
  [55.974026, 92.796216],
  [55.981778, 92.865263],
  [55.981646, 92.865286],
  [55.981757, 92.864769],
  [55.982684, 92.874396],
  [55.982531, 92.874176],
  [55.971044, 92.781488],
  [55.980542, 92.856721],
  [55.980381, 92.856569],
  [55.979921, 92.850823],
  [55.979741, 92.850823],
  [55.977496, 92.829085],
  [55.977306, 92.828998],
  [55.977336, 92.828491],
  [55.977647, 92.831768],
  [55.971115, 92.781836],
  [55.976045, 92.807478],
  [55.977116, 92.823505],
  [55.976942, 92.823108],
  [56.023345, 92.805805],
  [56.023417, 92.805231],
  [56.023335, 92.804975],
  [56.023488, 92.805293],
  [56.003564, 92.767401],
  [56.003471, 92.767404],
  [56.002657, 92.765843],
  [56.002632, 92.765668],
  [56.00298, 92.765459],
  [56.018232, 92.795133],
  [56.018192, 92.794813],
  [56.018266, 92.79356],
  [56.019698, 92.796391],
  [56.020202, 92.797447],
  [56.019779, 92.79719],
  [56.01966, 92.797219],
  [56.020148, 92.797083],
  [56.019458, 92.795774],
  [56.020014, 92.796525],
  [56.020058, 92.796519],
  [56.023723, 92.82154],
  [56.023474, 92.820614],
  [56.0235, 92.820608],
  [56.023685, 92.819996],
  [56.02384, 92.820398],
  [56.023705, 92.814163],
  [56.023579, 92.815118],
  [56.022943, 92.81324],
  [56.022954, 92.813058],
  [56.023488, 92.812468],
  [56.023693, 92.81177],
  [56.015726, 92.788715],
  [56.015719, 92.789069],
  [56.015314, 92.788221],
  [56.015205, 92.787727],
  [56.015509, 92.787781],
  [56.015677, 92.787304],
  [56.023752, 92.815551],
  [56.023693, 92.802262],
  [56.023655, 92.802357],
  [56.022596, 92.801863],
  [56.02295, 92.80235],
  [56.016379, 92.790367],
  [56.017675, 92.792652],
  [56.064386, 92.888366],
  [56.06403, 92.887544],
  [56.027412, 92.834752],
  [55.98435, 92.871521],
  [55.983317, 92.873086],
  [55.983244, 92.873036],
  [55.983046, 92.870385],
  [55.983044, 92.870342],
  [55.984443, 92.871643],
  [56.016188, 93.031014],
  [56.016085, 93.03117],
  [56.015716, 93.030998],
  [56.015691, 93.030809],
  [56.01593, 93.030914],
  [56.016012, 93.030702],
  [56.021675, 92.740252],
  [56.021434, 92.739758],
  [55.985726, 92.854267],
  [55.985081, 92.855055],
  [55.985016, 92.855065],
  [55.984792, 92.85455],
  [55.984775, 92.854424],
  [55.98484, 92.853942],
  [55.984938, 92.85396],
  [55.985136, 92.854317],
  [55.982113, 92.833472],
  [55.981994, 92.833173],
  [55.983506, 92.843603],
  [55.98342, 92.843539],
  [56.013249, 92.876369],
  [56.015654, 92.875789],
  [56.015499, 92.876336],
  [56.015395, 92.876841],
  [56.014789, 92.875972],
  [56.015244, 92.874513],
  [56.016003, 92.875607],
  [56.025398, 92.787918],
  [56.025033, 92.787918],
  [56.030311, 92.776578],
  [56.029652, 92.776666],
  [56.029593, 92.776553],
  [56.029951, 92.774837],
  [56.029998, 92.774779],
  [56.039831, 92.769134],
  [56.039871, 92.769633],
  [56.038552, 92.770242],
  [56.03836, 92.770062],
  [56.039636, 92.768319],
  [56.039768, 92.76812],
  [56.032527, 92.774688],
  [56.03216, 92.775132],
  [56.031827, 92.775181],
  [56.03169, 92.774875],
  [56.031963, 92.774523],
  [56.032712, 92.774178],
  [56.014942, 92.971457],
  [56.014868, 92.971664],
  [56.015321, 92.97045],
  [56.015309, 92.9706],
  [56.041903, 92.89321],
  [56.042183, 92.894103],
  [56.04072, 92.892988],
  [56.040522, 92.892543],
  [56.042435, 92.892365],
  [56.042651, 92.892655],
  [56.031236, 92.890365],
  [56.030897, 92.890445],
  [56.030539, 92.889764],
  [56.030801, 92.889673],
  [56.030877, 92.889552],
  [56.033987, 92.89162],
  [56.033792, 92.892516],
  [56.033735, 92.892105],
  [56.033306, 92.891411],
  [56.033423, 92.89096],
  [56.033666, 92.890091],
  [56.033822, 92.890274],
  [56.033828, 92.891145],
  [56.023435, 92.885327],
  [56.024306, 92.885263],
  [56.036006, 92.892451],
  [56.035415, 92.892865],
  [56.035243, 92.892427],
  [56.034884, 92.891518],
  [56.035554, 92.891805],
  [56.037726, 92.89258],
  [56.037353, 92.892108],
  [55.991332, 92.954244],
  [55.991505, 92.954123],
  [55.988676, 92.853915],
  [55.988209, 92.853947],
  [55.987808, 92.854586],
  [55.987817, 92.854505],
  [55.987723, 92.853692],
  [55.987727, 92.853592],
  [55.988182, 92.853005],
  [55.988188, 92.852951],
  [55.98857, 92.853867],
  [56.06022, 92.945177],
];
export const itemArray2 = [
  [56.030126, 92.91613],
  [56.030185, 92.916994],
  [56.011886, 92.803654],
  [56.01704, 92.865936],
  [56.016574, 92.86659],
  [56.016407, 92.865997],
  [56.016427, 92.865136],
  [56.016942, 92.865701],
  [56.07566, 92.998596],
  [56.075801, 92.999534],
  [56.075049, 92.997834],
  [56.074886, 92.996995],
  [56.075351, 92.997582],
  [55.992891, 92.890349],
  [55.992578, 92.885306],
  [55.992707, 92.885537],
  [55.991561, 92.885603],
  [55.991607, 92.885552],
  [56.007326, 92.83756],
  [56.007141, 92.837448],
  [56.007236, 92.836234],
  [56.007386, 92.836316],
  [56.009979, 92.837038],
  [56.010599, 92.83709],
  [56.042643, 93.021909],
  [56.042342, 93.022045],
  [56.016578, 92.854016],
  [56.01609, 92.853386],
  [56.016466, 92.852785],
  [56.016589, 92.853032],
  [56.016013, 92.840501],
  [56.015721, 92.840133],
  [56.016265, 92.846809],
  [56.016449, 92.846929],
  [56.015879, 92.845384],
  [56.015842, 92.845387],
  [56.016261, 92.843114],
  [56.016134, 92.842938],
  [56.016336, 92.844839],
  [56.007471, 92.842202],
  [56.007458, 92.842042],
  [56.013327, 92.840872],
  [56.012397, 92.841018],
  [56.012636, 92.840528],
  [56.012907, 92.840466],
  [56.03129, 93.06915],
  [56.030408, 93.068082],
  [56.029052, 93.066409],
  [55.972702, 92.790334],
  [55.973128, 92.792898],
  [55.972196, 92.790275],
  [55.972031, 92.790093],
  [55.972227, 92.789347],
  [55.972383, 92.789108],
  [55.978999, 92.842507],
  [55.978942, 92.842617],
  [55.978697, 92.84241],
  [55.978684, 92.841874],
  [55.978812, 92.841529],
  [55.978917, 92.841704],
  [55.96794, 92.732365],
  [55.96791, 92.732583],
  [55.972139, 92.788074],
  [55.971954, 92.788339],
  [55.971947, 92.78769],
  [55.96606, 92.723779],
  [55.965799, 92.7235],
  [55.983181, 92.879599],
  [55.983046, 92.879421],
  [55.975482, 92.801441],
  [55.975311, 92.801119],
  [55.97424, 92.796223],
  [55.974026, 92.796216],
  [55.981778, 92.865263],
  [55.981646, 92.865286],
  [55.981757, 92.864769],
  [55.982684, 92.874396],
  [55.982531, 92.874176],
  [55.971044, 92.781488],
  [55.980542, 92.856721],
  [55.980381, 92.856569],
  [55.979921, 92.850823],
  [55.979741, 92.850823],
  [55.977496, 92.829085],
  [55.977306, 92.828998],
  [55.977336, 92.828491],
  [55.977647, 92.831768],
  [55.971115, 92.781836],
  [55.976045, 92.807478],
  [55.977116, 92.823505],
  [55.976942, 92.823108],
  [56.023345, 92.805805],
  [56.023417, 92.805231],
  [56.023335, 92.804975],
  [56.023488, 92.805293],
  [56.003564, 92.767401],
  [56.003471, 92.767404],
  [56.002657, 92.765843],
  [56.002632, 92.765668],
  [56.00298, 92.765459],
  [56.018232, 92.795133],
  [56.018192, 92.794813],
  [56.018266, 92.79356],
  [56.019698, 92.796391],
  [56.020202, 92.797447],
  [56.019779, 92.79719],
  [56.01966, 92.797219],
  [56.020148, 92.797083],
  [56.019458, 92.795774],
  [56.020014, 92.796525],
  [56.020058, 92.796519],
  [56.023723, 92.82154],
  [56.023474, 92.820614],
  [56.0235, 92.820608],
  [56.023685, 92.819996],
  [56.02384, 92.820398],
  [56.023705, 92.814163],
  [56.023579, 92.815118],
  [56.022943, 92.81324],
  [56.022954, 92.813058],
  [56.023488, 92.812468],
  [56.023693, 92.81177],
  [56.015726, 92.788715],
  [56.015719, 92.789069],
  [56.015314, 92.788221],
  [56.015205, 92.787727],
  [56.015509, 92.787781],
  [56.015677, 92.787304],
  [56.023752, 92.815551],
  [56.023693, 92.802262],
  [56.023655, 92.802357],
  [56.022596, 92.801863],
  [56.02295, 92.80235],
  [56.016379, 92.790367],
  [56.017675, 92.792652],
  [56.064386, 92.888366],
  [56.06403, 92.887544],
  [56.027412, 92.834752],
  [55.98435, 92.871521],
  [55.983317, 92.873086],
  [55.983244, 92.873036],
  [55.983046, 92.870385],
  [55.983044, 92.870342],
  [55.984443, 92.871643],
  [56.016188, 93.031014],
  [56.016085, 93.03117],
  [56.015716, 93.030998],
  [56.015691, 93.030809],
  [56.01593, 93.030914],
  [56.016012, 93.030702],
  [56.021675, 92.740252],
  [56.021434, 92.739758],
  [55.985726, 92.854267],
  [55.985081, 92.855055],
  [55.985016, 92.855065],
  [55.984792, 92.85455],
  [55.984775, 92.854424],
  [55.98484, 92.853942],
  [55.984938, 92.85396],
  [55.985136, 92.854317],
  [55.982113, 92.833472],
  [55.981994, 92.833173],
  [55.983506, 92.843603],
  [55.98342, 92.843539],
  [56.013249, 92.876369],
  [56.015654, 92.875789],
  [56.015499, 92.876336],
  [56.015395, 92.876841],
  [56.014789, 92.875972],
  [56.015244, 92.874513],
  [56.016003, 92.875607],
  [56.025398, 92.787918],
  [56.025033, 92.787918],
  [56.030311, 92.776578],
  [56.029652, 92.776666],
  [56.029593, 92.776553],
  [56.029951, 92.774837],
  [56.029998, 92.774779],
  [56.039831, 92.769134],
  [56.039871, 92.769633],
  [56.038552, 92.770242],
  [56.03836, 92.770062],
  [56.039636, 92.768319],
  [56.039768, 92.76812],
  [56.032527, 92.774688],
  [56.03216, 92.775132],
  [56.031827, 92.775181],
  [56.03169, 92.774875],
  [56.031963, 92.774523],
  [56.032712, 92.774178],
  [56.014942, 92.971457],
  [56.014868, 92.971664],
  [56.015321, 92.97045],
  [56.015309, 92.9706],
  [56.041903, 92.89321],
  [56.042183, 92.894103],
  [56.04072, 92.892988],
  [56.040522, 92.892543],
  [56.042435, 92.892365],
  [56.042651, 92.892655],
  [56.031236, 92.890365],
  [56.030897, 92.890445],
  [56.030539, 92.889764],
  [56.030801, 92.889673],
  [56.030877, 92.889552],
  [56.033987, 92.89162],
  [56.033792, 92.892516],
  [56.033735, 92.892105],
  [56.033306, 92.891411],
  [56.033423, 92.89096],
  [56.033666, 92.890091],
  [56.033822, 92.890274],
  [56.033828, 92.891145],
  [56.023435, 92.885327],
  [56.024306, 92.885263],
  [56.036006, 92.892451],
  [56.035415, 92.892865],
  [56.035243, 92.892427],
  [56.034884, 92.891518],
  [56.035554, 92.891805],
  [56.037726, 92.89258],
  [56.037353, 92.892108],
  [55.991332, 92.954244],
  [55.991505, 92.954123],
  [55.988676, 92.853915],
  [55.988209, 92.853947],
  [55.987808, 92.854586],
  [55.987817, 92.854505],
  [55.987723, 92.853692],
  [55.987727, 92.853592],
  [55.988182, 92.853005],
  [55.988188, 92.852951],
  [55.98857, 92.853867],
  [56.06022, 92.945177],
];
