<div style="min-width: 350px; max-width: 800px; overflow: hidden" (click)="$event.stopPropagation()">
  <object
    #layoutSvg
    type="image/svg+xml"
    data="/assets/images/tlc/layout_full.svg"
    (load)="initLayoutSvg()"
    [style.opacity]="initialized ? 1 : 0"
    [style.transform]="'rotate(' + schemaSet.schemaView.angle + 'deg)'"
    style="width: 100%"
  ></object>
</div>
