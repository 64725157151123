import { Injectable } from '@angular/core';

import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import {
  IDataSourceService,
  setPageParams,
  getPageReturn,
  IPageParams,
  IPageReturn,
} from 'projects/msu-its-web-common/src/data/data-source';

import { CreatePublicTransportStopRequest, DeletePublicTransportStopsRequest, GetPublicTransportStopExistRequest, GetPublicTransportStopRequest, GetPublicTransportStopsRequest, PublicTransportStop, UpdatePublicTransportStopRequest } from '../../dtos/ptm.dtos';
import { PtmApiService } from '../ptm-api.service';

@Injectable()
export class BusStopService implements IDataSourceService<PublicTransportStop, PublicTransportStop> {
  constructor(private _api: PtmApiService) {}

  getPagedList(params: IPageParams, customerId: string): Observable<IPageReturn<PublicTransportStop>> {
    if (!customerId) {
      return of(null);
    }

    const request = new GetPublicTransportStopsRequest();
    request.customerId = customerId;

    setPageParams(request, params);
    return this._api.get(request).pipe(
      map((m) => {
        return getPageReturn(m);
      })
    );
  }
  getList(customerId: string, filter?: string, groupIds?: string[]): Observable<PublicTransportStop[]> {
    const request = new GetPublicTransportStopsRequest();
    request.customerId = customerId;
    request.entityGroups = groupIds || [];

    request.page = 0;
    request.items = 0;
    request.sort = 'name';
    request.filter = filter || '';

    return this._api.get(request).pipe(
      map((m) => {
        return m.items;
      })
    );
  }

  get(id: string, customerId: string) {
    const request = new GetPublicTransportStopRequest();
    request.id = id;
    request.customerId = customerId;
    return this._api.get(request);
  }
  add(
    model: PublicTransportStop,
    params: {
      customerId: string;
      groups: string[];
      updateGroups: boolean;
    }
  ) {
    const request = new CreatePublicTransportStopRequest();
    request.entity = model;
    request.customerId = params.customerId;
    request.entityGroups = params.groups;
    request.updateEntityGroups = params.updateGroups;
    return this._api.post(request);
  }
  update(model: PublicTransportStop, params: { customerId: string; groups: string[]; updateGroups: boolean }) {
    const request = new UpdatePublicTransportStopRequest();
    request.id = model.id;
    request.entity = model;
    request.customerId = params.customerId;
    request.entityGroups = params.groups;
    request.updateEntityGroups = params.updateGroups;
    return this._api.put(request);
  }
  delete(ids: string[], customerId: string) {
    const request = new DeletePublicTransportStopsRequest();
    request.ids = ids;
    request.customerId = customerId;
    return this._api.delete(request);
  }
  exist(name: string, customerId: string): Observable<boolean> {
    const request = new GetPublicTransportStopExistRequest({ name, customerId });
    return this._api.get(request);
  }

}
