import { Injectable } from '@angular/core';
import { Coordinate, GetRouteRequest } from '../dtos/gis.dtos';

import { GisApiService } from './gis-api.service';

@Injectable()
export class PointToPointRouteService {
  constructor(private _api: GisApiService) {}

  getRoute(customerId: string, start: Coordinate, finish: Coordinate) {
    const request = new GetRouteRequest();

    request.start = start;
    request.finish = finish;

    return this._api.post(request);
  }
}
