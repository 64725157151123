import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
} from "@angular/core";
import { marker as _ } from "@biesbjerg/ngx-translate-extract-marker";

import { CameraFTPService } from "../../services/camera-ftp/camera-ftp.service";
import { TranslateService } from "@ngx-translate/core";
import { FtpCameraLocation } from "./model";

@Component({
  selector: "camera-ftp-popup",
  templateUrl: "./camera-ftp-popup.component.html",
  styles: [
    `
      :host {
        min-width: 622px !important;
      }
      table {
        font-family: arial, sans-serif;
        border-collapse: collapse;
        width: 100%;
        z-index: 1;
      }

      td,
      th {
        text-align: left;
        padding: 8px;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CameraFTPPopupComponent implements OnInit, AfterViewInit {
  @Input()
  modelId: string;

  external: boolean = false;

  @Input()
  customerId: string;

  @Input()
  modelInfo: FtpCameraLocation;

  constructor(
    private _cameraFTPService: CameraFTPService,
    public translate: TranslateService,
    private _changeDetector: ChangeDetectorRef
  ) {}

  ngAfterViewInit(): void {
    console.log(this.modelInfo);
    this._cameraFTPService
      .get(this.modelId, this.customerId)
      .subscribe((result) => {
        console.log(result);
        this._changeDetector.markForCheck();
      });
  }
  ngOnInit() {}
}
