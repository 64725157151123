import { Injectable } from '@angular/core';
import { ValidatorFn } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { AboutDialogComponent } from '../modules/dialog-module/about-dialog.component';
import { AlertDialogComponent } from '../modules/dialog-module/alert-dialog.component';
import { ConfirmDialogComponent } from '../modules/dialog-module/confirm-dialog.component';
import { PromptDialogComponent } from '../modules/dialog-module/prompt-dialog.component';
import { FlashService } from './flash.service';

const DIALOG_TYPE_KEY = 'dialog_type';
const DIALOG_MESSAGE_KEY = 'dialog_message';

@Injectable()
export class DialogService {
  constructor(public dialog: MatDialog, private _flashService: FlashService) {}

  public confirm(message: string): Observable<boolean> {
    const dialogRef = this.dialog.open<ConfirmDialogComponent>(
      ConfirmDialogComponent
    );
    dialogRef.componentInstance.message = message;
    return dialogRef.afterClosed();
  }

  public prompt(
    value: string,
    title: string,
    label: string,
    hint: string,
    errorMessage?: string,
    validator?: ValidatorFn
  ): Observable<any> {
    const dialogRef = this.dialog.open<PromptDialogComponent>(
      PromptDialogComponent
    );
    dialogRef.componentInstance.prompt.setValue(value);
    dialogRef.componentInstance.title = title;
    dialogRef.componentInstance.label = label;
    dialogRef.componentInstance.hint = hint;
    if (errorMessage) {
      dialogRef.componentInstance.errorMessage = errorMessage;
    }
    if (validator) {
      dialogRef.componentInstance.validator = validator;
    }
    return dialogRef.afterClosed();
  }

  public windowDialog(url: string, target: string, settings: string, callback?: () => void) {
    const dialogWindow = window.open(url, target, settings);
    dialogWindow.onbeforeunload = (e) => {
      const type = dialogWindow[DIALOG_TYPE_KEY];
      const message = dialogWindow[DIALOG_MESSAGE_KEY];
      if (message) {
        this._flashService.show(message, type);
        callback?.();
      }
    };
  }

  public static windowMessage(
    message: string,
    type: 'success' | 'warn' | 'error' | 'info' = 'success'
  ) {
    window[DIALOG_TYPE_KEY] = type;
    window[DIALOG_MESSAGE_KEY] = message;
  }

  public warn(message: string): Observable<boolean> {
    return this._alertDialog(message, 'warn');
  }
  public info(message: string): Observable<boolean> {
    return this._alertDialog(message, 'info');
  }
  public error(message: string): Observable<boolean> {
    return this._alertDialog(message, 'error');
  }
  public success(message: string): Observable<boolean> {
    return this._alertDialog(message, 'success');
  }

  private _alertDialog(
    message: string,
    type: 'success' | 'warn' | 'error' | 'info' = 'success'
  ) {
    const dialogRef = this.dialog.open<AlertDialogComponent>(
      AlertDialogComponent
    );
    dialogRef.componentInstance.message = message;
    dialogRef.componentInstance.type = type;
    return dialogRef.afterClosed();
  }
}
