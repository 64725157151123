import { Inject, Injectable } from '@angular/core';
import {
  IDataSourceService,
  setPageParams,
  getPageReturn,
  IPageParams,
  IPageReturn,
} from 'projects/msu-its-web-common/src/data/data-source';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { TokenService } from 'projects/msu-its-web-common/src/services/token.service';
import { APP_ENVIRONMENT } from 'projects/msu-its-web-common/src/utils/shared-consts';
import {
  CreateMeteoStationRequest,
  DeleteMeteoStationsRequest,
  GetMeteoStationExistRequest,
  GetMeteoStationRequest,
  GetMeteoStationsRequest,
  MeteoStation,
  MeteoStationInfo,
  MeteoStationType,
  UpdateMeteoStationRequest,
} from '../dtos/meteo.dtos';
import { MeteoApiService } from './meteo-api.service';

@Injectable()
export class MeteoStationsService implements IDataSourceService<MeteoStation, MeteoStationInfo> {
  constructor(
    private _api: MeteoApiService,
    private _tokenService: TokenService,
    @Inject(APP_ENVIRONMENT) private _appEnv
  ) {}

  getPagedList(params: IPageParams, customerId: string): Observable<IPageReturn<MeteoStationInfo>> {
    const request = new GetMeteoStationsRequest();
    request.customerId = customerId;
    setPageParams(request, params);
    return this._api.get(request).pipe(
      map((m) => {
        return getPageReturn(m);
      })
    );
  }

  getList(
    customerId: string,
    filter?: string,
    items?: number,
    groupIds?: string[],
    onlyFree?: boolean
  ): Observable<MeteoStationInfo[]> {
    const request = new GetMeteoStationsRequest();
    request.customerId = customerId;
    request.entityGroups = groupIds;
    request.page = 0;
    request.items = items ? items : 0;
    request.sort = 'number';
    request.filter = filter;
    request.onlyFree = onlyFree;

    return this._api.get(request).pipe(
      map((m) => {
        return m.items;
      })
    );
  }

  get(id: any, customerId: string): Observable<MeteoStation> {
    const request = new GetMeteoStationRequest();
    request.customerId = customerId;
    request.id = id;
    return this._api.get(request);
  }

  add(
    model: MeteoStation,
    params: {
      customerId: string;
      groups: string[];
      updateGroups: boolean;
    }
  ) {
    const request = new CreateMeteoStationRequest();
    request.customerId = params.customerId;
    request.entity = model;
    request.entity.customerId = params.customerId;
    request.entityGroups = params.groups;
    request.updateEntityGroups = params.updateGroups;
    return this._api.post(request);
  }

  update(
    model: MeteoStation,
    params: { customerId: string; groups: string[]; updateGroups: boolean }
  ) {
    const request = new UpdateMeteoStationRequest();
    request.id = model.id;
    request.entity = model;
    request.customerId = params.customerId;
    request.entityGroups = params.groups;
    request.updateEntityGroups = params.updateGroups;
    return this._api.put(request);
  }

  delete(ids: string[], customerId: string): Observable<number> {
    const request = new DeleteMeteoStationsRequest();
    request.customerId = customerId;
    request.ids = ids;
    return this._api.delete(request);
  }

  exist(_number: string, type: MeteoStationType): Observable<boolean> {
    const request = new GetMeteoStationExistRequest();
    request.number = _number;
    request.type = type;
    return this._api.get(request);
  }
}
