import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { NgModule } from '@angular/core';

import { SIDENAV_MENU } from 'projects/msu-its-web-common/src/utils/shared-consts';
import { ISidenavMenuCategory } from 'projects/msu-its-web-common/src/utils/sidenav-menu';

import { BaseModule } from 'projects/msu-its-web-common/src/modules/base.module';

import { DashboardApiService } from '../../services/dashboard-api.service';
import { DashboardApiEventService } from '../../services/dashboard-api-event.service';
import { DashboardSettingsService } from '../../services/dashboard-settings.service';
import { DashboardCameraGroupsService } from '../../services/dashboard-camera-groups.service';

const sidenav: ISidenavMenuCategory[] = [
  {
    name: _('SIDENAV_MENU.DASHBOARD'),
    items: [
      {
        name: _('DASHBOARD_SETTINGS.TITLE'),
        icon: 'dashboard',
        route: 'dashboard-settings',
        permissions: ['dashboardSettings.read'],
      },
    ],
  },
];

@NgModule({
  providers: [
    { provide: SIDENAV_MENU, useValue: sidenav, multi: true },
    DashboardApiService,
    DashboardApiEventService,
    DashboardSettingsService,
    DashboardCameraGroupsService,
  ],
  imports: [BaseModule],
  declarations: [],
  entryComponents: [],
  exports: [],
})
export class DashboardSharedModule {}
