import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { Subject } from 'rxjs';

import { TranslateService } from '@ngx-translate/core';
import { SettingsService } from 'projects/msu-its-web-common/src/services/settings.service';

import { LayoutSvgUtils } from 'projects/msu-its-web-tlc/src/dtos/layout';
import { SchemaSet, SchemaUtils } from 'projects/msu-its-web-tlc/src/dtos/schema';
import {
  PotokPlan,
  PotokPlanType,
  PotokSettings,
  TrafficMovementType,
} from 'projects/msu-its-web-tlc/src/dtos/tlc.dtos';

import { getPlanStates } from 'projects/msu-its-web-tlc/src/dtos/potok/potok-plan';

@Component({
  selector: 'potok-select-phase',
  templateUrl: './potok-select-phase.component.html',
  styleUrls: ['./potok-select-phase.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PotokSelectPhaseComponent implements OnInit, OnDestroy {
  _destroy = new Subject();

  @Input()
  schemaSet: SchemaSet;

  @Input()
  selectedMovements: TrafficMovementType[] = [];

  @Input()
  settings: PotokSettings;

  @Output()
  selected = new EventEmitter<TrafficMovementType[]>();

  phases: [TrafficMovementType[][], TrafficMovementType[][]] = [[], []];

  get schema() {
    return this.schemaSet.schema;
  }
  get schemaView() {
    return this.schemaSet.schemaView;
  }

  constructor(
    private _settingsService: SettingsService,
    private _changeDetector: ChangeDetectorRef,
    public translate: TranslateService
  ) {}

  ngOnInit() {
    this._updatePhases();
    this._changeDetector.markForCheck();
  }

  ngOnDestroy() {
    this._destroy.next();
    this._destroy.complete();
  }

  select(movements: TrafficMovementType[]) {
    this.selected.emit(movements);
  }

  setSvgState(element, stepMovements: TrafficMovementType[]) {
    const schema = this.schema;
    const schemaView = this.schemaView;
    const isDark = this._settingsService.darkTheme;
    const svg = element.contentDocument;

    const movements = [];
    stepMovements.forEach((m) => {
      movements.push(...SchemaUtils.schemaViewMovements(schema, m));
    });

    LayoutSvgUtils.updateLayoutSchema(svg, schema, schemaView, isDark);
    LayoutSvgUtils.showLayoutMovements(svg, schema, movements);
  }

  isSelected(stepMovements: TrafficMovementType[]) {
    return (
      this.selectedMovements.length == stepMovements?.length &&
      this.selectedMovements.every((m) => stepMovements.includes(m))
    );
  }

  _updatePhases() {
    const plans = this.settings.plans;
    const cyclePlans = Object.keys(plans)
      .map((k) => plans[k] as PotokPlan)
      .filter((m) => m.enabled && m.type === PotokPlanType.PhaseCycle);

    if (cyclePlans.length) {
      const plan = cyclePlans[0];
      const barriers = plan.pedestrianPhasesEnabled
        ? plan.pedestrianBarriers
        : plan.transportBarriers;
      this.phases = [].concat(getPlanStates(barriers)) as [[], []];
    } else {
      this.phases = [[], []];
    }
  }
}
