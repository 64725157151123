<mat-toolbar>
  <a mat-icon-button (click)="toggleSidenav()" class="menu-button">
    <mat-icon>menu</mat-icon>
  </a>

  <!-- <span class="logo" [class.dark]="isDark"></span> -->
  <span class="title">
    {{ title }}
  </span>

  <div class="flex-spacer"></div>

  <div style="padding: 0 6px">
    <button
      class="notify-button"
      #notifyMenuTrigger="matMenuTrigger"
      [matMenuTriggerFor]="notifyMenu"
      mat-icon-button
    >
      <mat-icon
        [matBadge]="notifyCount"
        [matBadgeHidden]="!notifyCount"
        [title]="notifyCount"
        matBadgeColor="warn"
        matBadgeSize="small"
      >
        notifications
      </mat-icon>
    </button>
  </div>

  <mat-menu
    #notifyMenu="matMenu"
    overlapTrigger="false"
    xPosition="before"
    class="notifyMenu"
  >
    <div class="mat-theme-bg" style="width: 400px">
      <div mat-menu-item *ngFor="let item of notifyItems">
        <div class="mat-secondary-color mat-small">
          {{ getActivityName(item.activity) }}
        </div>
        <div>{{ item.entityName }}</div>
        <div>{{ item.description }}</div>
        <div class="mat-secondary-color mat-small">
          {{
            item.activityTime
              | date: "dd.MM.yy HH:mm":timezone:translate.currentLang
          }}
          ({{ item.userName }})
        </div>
      </div>
    </div>
  </mat-menu>

  <div
    style="
      padding: 7px 16px;
      height: 50px;
      box-sizing: border-box;
      border-top: none;
      border-bottom: none;
    "
  >
    <div style="font-size: 16px; line-height: normal">
      {{ time | date: "mediumTime":timezone:locale }}
    </div>
    <div style="font-size: 14px; line-height: normal; font-weight: 400">
      {{ time | date: "EEE, d MMM yyyy":timezone:locale }}
    </div>
  </div>

  <button
    mat-button
    [matMenuTriggerFor]="userMenu"
    style="width: auto; padding: 0 8px 0 16px; height: 50px"
  >
    <span style="margin-right: 5px">{{ displayName }}</span>
    <mat-icon>more_vert</mat-icon>
  </button>

  <mat-menu
    #userMenu="matMenu"
    overlapTrigger="false"
    class="userMenu mat-elevation-z8"
  >
    <ng-container *hasPermissions="['system.manage']">
      <a mat-menu-item [routerLink]="['/customers']">
        <mat-icon>domain</mat-icon>
        <span>{{ "CUSTOMERS.TITLE" | translate }}</span>
      </a>
      <a mat-menu-item [routerLink]="['/external-systems']">
        <mat-icon>settings</mat-icon>
        <span>{{ "EXTERNAL_SYSTEMS.TITLE" | translate }}</span>
      </a>
      <mat-divider></mat-divider>
    </ng-container>


    <!--
    <button mat-menu-item (click)="editProfile()">
      <mat-icon>person</mat-icon>
      <span>{{ "PROFILE.TITLE" | translate }}</span>
    </button>
    <button mat-menu-item (click)="changePassword()">
      <mat-icon>lock</mat-icon>
      <span>{{ "PROFILE.CHANGE_PASSWORD" | translate }}</span>
    </button>
    -->

    <button mat-menu-item (click)="selectLang()">
      <mat-icon>language</mat-icon>
      <span
        >{{ "COMMON.LANGUAGE" | translate }}: {{ getCurrentLangName() }}</span
      >
    </button>

    <div class="mat-menu-item" onclick="event.stopPropagation();">
      <mat-slide-toggle
        #darkThemeToggle
        color="accent"
        style="vertical-align: middle"
      >
        <span>{{ "COMMON.DARK_THEME" | translate }}</span>
      </mat-slide-toggle>
    </div>

    <button mat-menu-item (click)="about()">
      <mat-icon>info</mat-icon>
      <span>{{ "ABOUT.ABOUT_PROGRAMM" | translate }}</span>
    </button>

    <mat-divider></mat-divider>

    <button mat-menu-item (click)="logout()">
      <mat-icon>exit_to_app</mat-icon>
      <span>{{ "COMMON.LOGOUT_TITLE" | translate }}</span>
    </button>
  </mat-menu>
</mat-toolbar>
