import { Injectable, Inject } from "@angular/core";
import { TokenService } from "projects/msu-its-web-common/src/services/token.service";
import { APP_ENVIRONMENT } from "projects/msu-its-web-common/src/utils/shared-consts";
import { Observable } from "rxjs";
import {
  AirParametersForecast,
  FrostsProbabilityForecast,
  GetAvailableMeteoObjectSensorsRequest,
  GetAirParametersForecast,
  GetEcoParameterForecast,
  GetFrostsProbabilityForecast,
  GetMeteoParameterAnalysis,
  GetPrecipitationForecast,
  GetMeteoObjectSensorDataGraphRequest,
  GetMeteoObjectSensorDataTableRequest,
  GetMeteoObjectSensorLatestDataRequest,
  GetMeteoObjectSensorPredictionDataGraphRequest,
  GetMeteoObjectSensorPredictionDataRequest,
  GetMeteoObjectSensorPredictionDataTableRequest,
  MeteoParameterAnalysis,
  MeteoParameterGraph,
  MeteoParameterTable,
  MeteoParameterValue,
  MeteoSensorsResponse,
  MeteoSensorType,
  PrecipitationForecast,
  EcoParameterForecast,
} from "../dtos/meteo.dtos";
import { MeteoApiService } from "./meteo-api.service";
import { EcoParameter } from "../dtos/meteo.dtos";

@Injectable()
export class MeteoObjectPopupService {
  constructor(
    private _api: MeteoApiService,
    private _tokenService: TokenService,
    @Inject(APP_ENVIRONMENT) private _appEnv
  ) {}

  getAvailableMeteoObjectSensorsRequest(
    meteoObjectId: string,
    items: number,
    page: number,
    sort: string,
    filter: string
  ): Observable<MeteoSensorsResponse> {
    const request = new GetAvailableMeteoObjectSensorsRequest();
    request.meteoObjectId = meteoObjectId;
    request.items = items;
    request.page = page;
    request.sort = sort;
    request.filter = filter;
    return this._api.get(request);
  }
  getMeteoObjectSensorLatestDataRequest(
    id: string,
    sensorType: MeteoSensorType,
    measureParameterIds: string[]
  ): Observable<MeteoParameterValue[]> {
    const request = new GetMeteoObjectSensorLatestDataRequest();
    request.sensorType = sensorType;
    request.id = id;
    request.measureParameterIds = measureParameterIds;
    return this._api.get(request);
  }
  getMeteoObjectSensorDataGraphRequest(
    id: string,
    sensorType: MeteoSensorType,
    measureParameterIds: string[],
    start: Date,
    end: Date
  ): Observable<MeteoParameterGraph[]> {
    const request = new GetMeteoObjectSensorDataGraphRequest();
    request.id = id;
    request.sensorType = sensorType;
    request.measureParameterIds = measureParameterIds as string[];
    request.startTime = start.toISOString();
    request.endTime = end.toISOString();
    request.groupBySeconds = 900;
    request.smooth = 300;
    return this._api.get(request);
  }

  getMeteoObjectSensorDataTableRequest(
    id: string,
    sensorType: MeteoSensorType,
    measureParameterIds: string,
    start: Date,
    end: Date
  ): Observable<MeteoParameterTable> {
    const request = new GetMeteoObjectSensorDataTableRequest();
    request.id = id;
    request.sensorType = sensorType;
    request.measureParameterId = measureParameterIds;
    request.startTime = start.toISOString();
    request.endTime = end.toISOString();
    request.groupBySeconds = 3600;
    // request.smooth = 300;
    return this._api.get(request);
  }
  getAirParametersForecast(
    meteoObjectId: string
  ): Observable<AirParametersForecast> {
    const request = new GetAirParametersForecast();
    request.id = meteoObjectId;
    return this._api.get(request);
  }
  getPrecipitationForecast(
    meteoObjectId: string
  ): Observable<PrecipitationForecast> {
    const request = new GetPrecipitationForecast();
    request.id = meteoObjectId;
    return this._api.get(request);
  }
  getFrostsProbabilityForecast(
    meteoObjectId: string
  ): Observable<FrostsProbabilityForecast> {
    const request = new GetFrostsProbabilityForecast();
    request.id = meteoObjectId;
    return this._api.get(request);
  }
  getMeteoParameterAnalysis(
    meteoObjectId: string
  ): Observable<MeteoParameterAnalysis[]> {
    const request = new GetMeteoParameterAnalysis();
    request.id = meteoObjectId;
    return this._api.get(request);
  }

  getEcoParameterForecast(
    meteoStationId: string
  ): Observable<EcoParameterForecast> {
    const request = new GetEcoParameterForecast();
    request.id = meteoStationId;
    return this._api.get(request);
  }

  getMeteoObjectSensorPredictionDataRequest(
    id: string,
    sensorType: MeteoSensorType,
    measureParameterIds: string[],
    time: Date
  ): Observable<MeteoParameterValue[]> {
    const request = new GetMeteoObjectSensorPredictionDataRequest();
    request.id = id;
    request.sensorType = sensorType;
    request.measureParameterIds = measureParameterIds;
    request.predictionTime = time.toISOString();
    return this._api.get(request);
  }

  getMeteoObjectSensorPredictionDataGraphRequest(
    id: string,
    sensorType: MeteoSensorType,
    measureParameterIds: string[],
    start: Date,
    end: Date
  ): Observable<MeteoParameterGraph[]> {
    const request = new GetMeteoObjectSensorPredictionDataGraphRequest();
    request.id = id;
    request.sensorType = sensorType;
    request.measureParameterIds = measureParameterIds as string[];
    request.startTime = start.toISOString();
    request.endTime = end.toISOString();
    request.groupBySeconds = 900;
    request.smooth = 300;
    return this._api.get(request);
  }
  getMeteoObjectSensorPredictionDataTableRequest(
    id: string,
    sensorType: MeteoSensorType,
    measureParameterIds: string,
    start: Date,
    end: Date
  ): Observable<MeteoParameterTable> {
    const request = new GetMeteoObjectSensorPredictionDataTableRequest();
    request.id = id;
    request.sensorType = sensorType;
    request.measureParameterId = measureParameterIds;
    request.startTime = start.toISOString();
    request.endTime = end.toISOString();
    request.groupBySeconds = 3600;
    // request.smooth = 300;
    return this._api.get(request);
  }

}
