import { Injectable } from '@angular/core';

import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import {
  getPageReturn,
  IDataSourceService,
  IPageParams,
  IPageReturn,
  setPageParams,
} from 'projects/msu-its-web-common/src/data/data-source';

import {
  ParkingSpace,
  GetParkingSpacesExistRequest,
  GetParkingSpaceRequest,
  GetParkingSpacesRequest,
  CreateParkingSpaceRequest,
  DeleteParkingSpacesRequest,
  UpdateParkingSpaceRequest,
  UploadSettingsToParkingControllerRequest,
  GetParkingStatusRequest,
  SetParkingSpaceEngineerModeRequest,
  SetParkingSpaceWorkModeRequest,
  SetParkingSpaceOffModeRequest,
  OpenParkingSpaceRequest,
  CloseParkingSpaceRequest,
  QueryParkingSpaceRequest,
  RebootParkingSpaceRequest,
  SetParkingControllerRequest,
} from '../dtos/parking.dtos';

import { ParkingApiService } from './parking-api.service';

@Injectable()
export class MsuPirsParkingService implements IDataSourceService<ParkingSpace, ParkingSpace> {
  constructor(private _api: ParkingApiService) {}

  getPagedList(
    params: IPageParams,
    requestParams: { parkingId: string; customerId: string }
  ): Observable<IPageReturn<ParkingSpace>> {
    if (!requestParams.customerId) {
      return of(null);
    }

    const request = new GetParkingSpacesRequest();
    request.parkingId = requestParams.parkingId;
    request.customerId = requestParams.customerId;

    setPageParams(request, params);
    return this._api.get(request).pipe(map((m) => getPageReturn(m)));
  }
  getList(customerId: string, parkingId?: string, filter?: string, groupIds?: string[]): Observable<ParkingSpace[]> {
    const request = new GetParkingSpacesRequest();
    request.parkingId = parkingId;
    request.customerId = customerId;
    request.entityGroups = groupIds || [];

    request.page = 0;
    request.items = 0;
    request.sort = 'fullName';
    request.filter = filter || '';

    return this._api.get(request).pipe(
      map((m) => {
        return m.items;
      })
    );
  }
  get(id: string, customerId: string) {
    const request = new GetParkingSpaceRequest({ id, customerId });
    return this._api.get(request);
  }
  add(entity: ParkingSpace, customerId: string) {
    entity.customerId = customerId;
    const request = new CreateParkingSpaceRequest({ entity, customerId });
    return this._api.post(request);
  }
  update(entity: ParkingSpace, customerId: string) {
    const request = new UpdateParkingSpaceRequest({ id: entity.id, entity, customerId });
    return this._api.put(request);
  }
  delete(
    ids: string[],
    requestParams: { parkingId: string; customerId: string }
  ): Observable<number> {
    const request = new DeleteParkingSpacesRequest({ ids, customerId: requestParams.customerId });
    return this._api.delete(request);
  }

  exist(name: string, parkingId: string, customerId: string) {
    const request = new GetParkingSpacesExistRequest({ name, parkingId, customerId });
    return this._api.get(request);
  }
  uploadSettingsToController(controllerId: string, customerId: string) {
    const request = new UploadSettingsToParkingControllerRequest({ id: controllerId, customerId });
    return this._api.post(request);
  }
  getStatus(parkingId: string, customerId: string) {
    const request = new GetParkingStatusRequest({ customerId, id: parkingId });
    return this._api.get(request);
  }

  changeController(
    id: string,
    customerId: string,
    controllerId: string,
    uploadSettings: boolean,
    description: string
  ) {
    const request = new SetParkingControllerRequest();
    request.id = id;
    request.newControllerId = controllerId;
    request.uploadSettingsToController = uploadSettings;
    request.description = description;
    request.customerId = customerId;
    return this._api.put(request);
  }


  setParkingSpaceEngineerMode(id: string, customerId: string) {
    const request = new SetParkingSpaceEngineerModeRequest({ id, customerId });
    return this._api.post(request);
  }
  setParkingSpaceWorkMode(id: string, customerId: string) {
    const request = new SetParkingSpaceWorkModeRequest({ id, customerId });
    return this._api.post(request);
  }
  setParkingSpaceOffMode(id: string, customerId: string) {
    const request = new SetParkingSpaceOffModeRequest({ id, customerId });
    return this._api.post(request);
  }

  openParkingSpaceLock(id: string, customerId: string) {
    const request = new OpenParkingSpaceRequest({ id, customerId });
    return this._api.post(request);
  }
  closeParkingSpaceLock(id: string, customerId: string) {
    const request = new CloseParkingSpaceRequest({ id, customerId });
    return this._api.post(request);
  }
  queryParkingSpaceLock(id: string, customerId: string) {
    const request = new QueryParkingSpaceRequest({ id, customerId });
    return this._api.post(request);
  }
  rebootParkingSpaceLock(id: string, customerId: string) {
    const request = new RebootParkingSpaceRequest({ id, customerId });
    return this._api.post(request);
  }
}
