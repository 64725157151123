<div fxLayout="column" fxLayoutGap="12px" style="min-width: 550px; position: relative; width: 580px">
  <div *ngIf="showCenterBlock" class="backdrop" style="position: absolute; z-index: 5; pointer-events: none">
    <div class="backdrop-inner mat-dialog-bg mat-elevation-z4" style="pointer-events: all; max-width: 350px">
      <div *ngFor="let error of controllerStatus?.errors" style="margin-bottom: 12px">
        <div fxLayout fxLayoutGap="12px">
          <mat-icon *ngIf="controllerStatus?.errors" class="dialog-warn">warning</mat-icon>
          <div>
            {{ getErrorTypeName(error.type) | translate }}
            <div class="mat-small mat-secondary-color">
              {{ error.startTime | date: "shortDate":timezone:locale }}
              {{ error.startTime | date: "shortTime":timezone:locale }}
              ({{ getDurationString(error.startTime) }})
            </div>
            <div *ngIf="error.data" class="mat-small mat-secondary-color">{{ error.data }}</div>
            <div *ngIf="error.details" class="mat-small mat-secondary-color">{{ error.details }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="loading" class="backdrop" style="position: absolute; z-index: 6">
    <div class="backdrop-inner mat-dialog-bg mat-elevation-z4" style="padding-bottom: 16px">
      <mat-spinner diameter="36"></mat-spinner>
    </div>
  </div>

  <usdk-state-mode [disabled]="disabled"></usdk-state-mode>
  <usdk-state-controller></usdk-state-controller>

  <div fxLayout="row" fxLayoutGap="12px">
    <usdk-state-buttons [disabled]="disabled"></usdk-state-buttons>

    <div class="mat-elevation-z2 tab-group-container">
      <mat-tab-group mat-stretch-tabs [selectedIndex]="selectedTab" (selectedIndexChange)="selectedTab = $event" animationDuration="0">
        <ng-container>
          <mat-tab [label]="'TRAFFIC_OBJECTS.SCHEMA' | translate"></mat-tab>
          <mat-tab [label]="'TRAFFIC_OBJECTS.TIME_LOG' | translate"></mat-tab>
          <!--
          <mat-tab [label]="'TRAFFIC_OBJECTS.DETECTORS'|translate" disabled></mat-tab>
          -->
        </ng-container>
      </mat-tab-group>
      <usdk-state-schema style="display: none" [style.display]="selectedTab == 0 ? 'flex' : 'none'"></usdk-state-schema>
      <usdk-state-timelog style="display: none" [style.display]="selectedTab == 1 ? 'flex' : 'none'"> </usdk-state-timelog>
    </div>
  </div>
</div>
