<div fxLayout="row" fxLayoutGap="12px" fxLayoutAlign="center center">
  <div class="object-state active">
    <div *ngIf="status?.mode == 'Remote' && status?.state == 'Phase'" class="text">
      {{ remotePhaseTime }}
    </div>
    <svg viewBox="0 0 36 36">
      <circle class="border" cx="18" cy="18" r="18" [style.fill]="getColor(status?.iconStatus?.borderColor)" />
      <!--
      <g class="spin">
        <circle cx="18" cy="18" r="18" />
        <path d="M 36 18 A 18 18 0 0 0 18 0" style="stroke-width: 10px;" />
      </g>
      -->
      <circle class="center" cx="18" cy="18" r="13" [style.fill]="getColor(status?.iconStatus?.color)" />
    </svg>
  </div>

  <mat-button-toggle-group
    [value]="status?.mode"
    [disabled]="disabled"
    class="mode-toggle-group"
    (change)="toggleGroupChange($event)"
    appearance="standard"
  >
    <mat-button-toggle
      value="Remote"
      title="{{ 'CONTROLLER_MODE.REMOTE' | translate }}"
      [matMenuTriggerFor]="remoteMenu"
      #remoteMenuTrigger="matMenuTrigger"
    >
      {{ "CONTROLLER_MODE.REMOTE_SHORT" | translate }}
    </mat-button-toggle>

    <mat-button-toggle value="Coordination" (change)="setCoordinationMode()" title="{{ 'CONTROLLER_MODE.COORDINATION' | translate }}">
      {{ "CONTROLLER_MODE.COORDINATION_SHORT" | translate }}
    </mat-button-toggle>

    <mat-button-toggle
      *hasModules="['tfms', 'tss']"
      value="Adaptive"
      (change)="setAdaptiveMode()"
      title="{{ 'CONTROLLER_MODE.ADAPTIVE' | translate }}"
      disabled
    >
      {{ "CONTROLLER_MODE.ADAPTIVE_SHORT" | translate }}
    </mat-button-toggle>

    <!-- 
    <mat-button-toggle value="Off" (change)="setOffMode()" title="{{ 'CONTROLLER_MODE.OFF' | translate }}">
      {{ "CONTROLLER_MODE.OFF_SHORT" | translate }}
    </mat-button-toggle> 
    -->
  </mat-button-toggle-group>
</div>

<mat-menu #remoteMenu="matMenu" class="menu-panel-extended" (closed)="items = []">
  <ng-container *ngIf="remoteMenuTrigger.menuOpen">
    <div style="padding: 12px 12px 8px">
      <movements-component [schemaSet]="trafficObjectSet.schemaSet" [conflicts]="conflicts" (selectionChange)="updateItems($event)">
      </movements-component>
    </div>
    <div class="remote">
      <button
        mat-raised-button
        *ngFor="let item of items; let i = index; trackBy: trackByMoves"
        class="mat-button-hover"
        [style.grid-column]="i + 1"
        (click)="setRemoteMode(item); remoteMenuTrigger.closeMenu()"
      >
        <div class="remote-svg mat-border">
          <object
            type="image/svg+xml"
            data="/assets/images/tlc/layout_button.svg"
            (load)="setSvgLayoutState($event.target, item)"
            [style.transform]="'rotate(' + trafficObjectSet.schemaSet?.schemaView.angle + 'deg)'"
          ></object>
        </div>
      </button>
    </div>
  </ng-container>
</mat-menu>
