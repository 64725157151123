import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import {
  ControllerEventType,
  TrafficControllerType,
  TrafficMovementType,
  TrafficObjectState,
} from './tlc.dtos';

export const MOVEMENT_TYPES = [
  { type: TrafficMovementType.Move5, name: _('MOVEMENT_TYPE.MOVE5') },
  { type: TrafficMovementType.Move2, name: _('MOVEMENT_TYPE.MOVE2') },
  { type: TrafficMovementType.Move12, name: _('MOVEMENT_TYPE.MOVE12') },
  { type: TrafficMovementType.MoveP2, name: _('MOVEMENT_TYPE.MOVEP2') },

  { type: TrafficMovementType.Move1, name: _('MOVEMENT_TYPE.MOVE1') },
  { type: TrafficMovementType.Move6, name: _('MOVEMENT_TYPE.MOVE6') },
  { type: TrafficMovementType.Move16, name: _('MOVEMENT_TYPE.MOVE16') },
  { type: TrafficMovementType.MoveP6, name: _('MOVEMENT_TYPE.MOVEP6') },

  { type: TrafficMovementType.Move7, name: _('MOVEMENT_TYPE.MOVE7') },
  { type: TrafficMovementType.Move4, name: _('MOVEMENT_TYPE.MOVE4') },
  { type: TrafficMovementType.Move14, name: _('MOVEMENT_TYPE.MOVE14') },
  { type: TrafficMovementType.MoveP4, name: _('MOVEMENT_TYPE.MOVEP4') },

  { type: TrafficMovementType.Move3, name: _('MOVEMENT_TYPE.MOVE3') },
  { type: TrafficMovementType.Move8, name: _('MOVEMENT_TYPE.MOVE8') },
  { type: TrafficMovementType.Move18, name: _('MOVEMENT_TYPE.MOVE18') },
  { type: TrafficMovementType.MoveP8, name: _('MOVEMENT_TYPE.MOVEP8') },
];

export const PEDESTRIAN_MOVEMENT_TYPES = [
  { type: TrafficMovementType.MoveP2, name: _('MOVEMENT_TYPE.MOVEP2') },
  { type: TrafficMovementType.MoveP6, name: _('MOVEMENT_TYPE.MOVEP6') },
  { type: TrafficMovementType.MoveP4, name: _('MOVEMENT_TYPE.MOVEP4') },
  { type: TrafficMovementType.MoveP8, name: _('MOVEMENT_TYPE.MOVEP8') },
];

export const WEEK_DAYS = [
  { type: 'monday', name: _('SCHEDULE.DAYS.MONDAY'), index: 1 },
  { type: 'tuesday', name: _('SCHEDULE.DAYS.TUESDAY'), index: 2 },
  { type: 'wednesday', name: _('SCHEDULE.DAYS.WEDNESDAY'), index: 3 },
  { type: 'thursday', name: _('SCHEDULE.DAYS.THURSDAY'), index: 4 },
  { type: 'friday', name: _('SCHEDULE.DAYS.FRIDAY'), index: 5 },
  { type: 'saturday', name: _('SCHEDULE.DAYS.SATURDAY'), index: 6 },
  { type: 'sunday', name: _('SCHEDULE.DAYS.SUNDAY'), index: 0 },
];

export const MONTHES = [
  { type: 'january', name: _('SCHEDULE.MONTHES.JANUARY') },
  { type: 'february', name: _('SCHEDULE.MONTHES.FEBRUARY') },
  { type: 'march', name: _('SCHEDULE.MONTHES.MARCH') },
  { type: 'april', name: _('SCHEDULE.MONTHES.APRIL') },
  { type: 'may', name: _('SCHEDULE.MONTHES.MAY') },
  { type: 'june', name: _('SCHEDULE.MONTHES.JUNE') },
  { type: 'july', name: _('SCHEDULE.MONTHES.JULY') },
  { type: 'august', name: _('SCHEDULE.MONTHES.AUGUST') },
  { type: 'september', name: _('SCHEDULE.MONTHES.SEPTEMBER') },
  { type: 'october', name: _('SCHEDULE.MONTHES.OCTOBER') },
  { type: 'november', name: _('SCHEDULE.MONTHES.NOVEMBER') },
  { type: 'december', name: _('SCHEDULE.MONTHES.DECEMBER') },
];

export const SCHEMA_STATIC_CONFLICTS = [
  {
    type: TrafficMovementType.Move2,
    conflicts: [
      TrafficMovementType.Move1,
      TrafficMovementType.Move3,
      TrafficMovementType.Move8,
      // TrafficMovementType.Move18,
      TrafficMovementType.MoveP8,
      TrafficMovementType.Move7,
      TrafficMovementType.Move4,
      TrafficMovementType.MoveP4,
    ],
  },
  {
    type: TrafficMovementType.Move6,
    conflicts: [
      TrafficMovementType.Move5,
      TrafficMovementType.Move7,
      TrafficMovementType.Move4,
      // TrafficMovementType.Move14,
      TrafficMovementType.MoveP4,
      TrafficMovementType.Move3,
      TrafficMovementType.Move8,
      TrafficMovementType.MoveP8,
    ],
  },
  {
    type: TrafficMovementType.Move4,
    conflicts: [
      TrafficMovementType.Move3,
      TrafficMovementType.Move5,
      TrafficMovementType.Move2,
      // TrafficMovementType.Move12,
      TrafficMovementType.MoveP2,
      TrafficMovementType.Move1,
      TrafficMovementType.Move6,
      TrafficMovementType.MoveP6,
    ],
  },
  {
    type: TrafficMovementType.Move8,
    conflicts: [
      TrafficMovementType.Move7,
      TrafficMovementType.Move1,
      TrafficMovementType.Move6,
      // TrafficMovementType.Move16,
      TrafficMovementType.MoveP6,
      TrafficMovementType.Move5,
      TrafficMovementType.Move2,
      TrafficMovementType.MoveP2,
    ],
  },
  {
    type: TrafficMovementType.Move1,
    conflicts: [
      TrafficMovementType.Move3,
      TrafficMovementType.Move8,
      TrafficMovementType.MoveP8,
      TrafficMovementType.Move2,
      // TrafficMovementType.Move12,
      TrafficMovementType.MoveP2,
      TrafficMovementType.Move7,
      TrafficMovementType.Move4,
    ],
  },
  {
    type: TrafficMovementType.Move3,
    conflicts: [
      TrafficMovementType.Move5,
      TrafficMovementType.Move2,
      TrafficMovementType.MoveP2,
      TrafficMovementType.Move4,
      // TrafficMovementType.Move14,
      TrafficMovementType.MoveP4,
      TrafficMovementType.Move1,
      TrafficMovementType.Move6,
    ],
  },
  {
    type: TrafficMovementType.Move5,
    conflicts: [
      TrafficMovementType.Move7,
      TrafficMovementType.Move4,
      TrafficMovementType.MoveP4,
      TrafficMovementType.Move6,
      // TrafficMovementType.Move16,
      TrafficMovementType.MoveP6,
      TrafficMovementType.Move3,
      TrafficMovementType.Move8,
    ],
  },
  {
    type: TrafficMovementType.Move7,
    conflicts: [
      TrafficMovementType.Move1,
      TrafficMovementType.Move6,
      TrafficMovementType.MoveP6,
      TrafficMovementType.Move8,
      // TrafficMovementType.Move18,
      TrafficMovementType.MoveP8,
      TrafficMovementType.Move5,
      TrafficMovementType.Move2,
    ],
  },
  {
    type: TrafficMovementType.Move12,
    conflicts: [
      TrafficMovementType.MoveP4,
      // TrafficMovementType.Move1,
      // TrafficMovementType.Move4
    ],
  },
  {
    type: TrafficMovementType.Move14,
    conflicts: [
      TrafficMovementType.MoveP6,
      // TrafficMovementType.Move3,
      // TrafficMovementType.Move6
    ],
  },
  {
    type: TrafficMovementType.Move16,
    conflicts: [
      TrafficMovementType.MoveP8,
      // TrafficMovementType.Move5,
      // TrafficMovementType.Move8
    ],
  },
  {
    type: TrafficMovementType.Move18,
    conflicts: [
      TrafficMovementType.MoveP2,
      //TrafficMovementType.Move7,
      // TrafficMovementType.Move2
    ],
  },
  {
    type: TrafficMovementType.MoveP2,
    conflicts: [
      TrafficMovementType.Move3,
      TrafficMovementType.Move8,
      TrafficMovementType.Move18,
      TrafficMovementType.Move1,
      TrafficMovementType.Move4,
    ],
  },
  {
    type: TrafficMovementType.MoveP4,
    conflicts: [
      TrafficMovementType.Move5,
      TrafficMovementType.Move2,
      TrafficMovementType.Move12,
      TrafficMovementType.Move3,
      TrafficMovementType.Move6,
    ],
  },
  {
    type: TrafficMovementType.MoveP6,
    conflicts: [
      TrafficMovementType.Move7,
      TrafficMovementType.Move4,
      TrafficMovementType.Move14,
      TrafficMovementType.Move5,
      TrafficMovementType.Move8,
    ],
  },
  {
    type: TrafficMovementType.MoveP8,
    conflicts: [
      TrafficMovementType.Move1,
      TrafficMovementType.Move6,
      TrafficMovementType.Move16,
      TrafficMovementType.Move7,
      TrafficMovementType.Move2,
    ],
  },
];

export const SCHEMA_STATIC_DEADEND_EXCLUDE_CONFLICTS = [
  {
    type: TrafficMovementType.Move1,
    conflicts: [TrafficMovementType.Move18, TrafficMovementType.MoveP2],
  },
  {
    type: TrafficMovementType.Move3,
    conflicts: [TrafficMovementType.Move12, TrafficMovementType.MoveP4],
  },
  {
    type: TrafficMovementType.Move5,
    conflicts: [TrafficMovementType.Move14, TrafficMovementType.MoveP6],
  },
  {
    type: TrafficMovementType.Move7,
    conflicts: [TrafficMovementType.Move16, TrafficMovementType.MoveP8],
  },
  {
    type: TrafficMovementType.Move12,
    conflicts: [TrafficMovementType.Move3, TrafficMovementType.MoveP2],
  },
  {
    type: TrafficMovementType.Move14,
    conflicts: [TrafficMovementType.Move5, TrafficMovementType.MoveP4],
  },
  {
    type: TrafficMovementType.Move16,
    conflicts: [TrafficMovementType.Move7, TrafficMovementType.MoveP6],
  },
  {
    type: TrafficMovementType.Move18,
    conflicts: [TrafficMovementType.Move1, TrafficMovementType.MoveP8],
  },
];

export const SCHEMA_STREET_CONFLICTS = [
  {
    type: TrafficMovementType.Move2,
    conflicts: [TrafficMovementType.MoveP4],
  },
  {
    type: TrafficMovementType.Move6,
    conflicts: [TrafficMovementType.MoveP8],
  },
  {
    type: TrafficMovementType.Move4,
    conflicts: [TrafficMovementType.MoveP6],
  },
  {
    type: TrafficMovementType.Move8,
    conflicts: [TrafficMovementType.MoveP2],
  },
  {
    type: TrafficMovementType.Move1,
    conflicts: [
      TrafficMovementType.MoveP8,
      //TrafficMovementType.Move12
    ],
  },
  {
    type: TrafficMovementType.Move3,
    conflicts: [
      TrafficMovementType.MoveP2,
      //TrafficMovementType.Move14
    ],
  },
  {
    type: TrafficMovementType.Move5,
    conflicts: [
      TrafficMovementType.MoveP4,
      //TrafficMovementType.Move16
    ],
  },
  {
    type: TrafficMovementType.Move7,
    conflicts: [
      TrafficMovementType.MoveP6,
      //TrafficMovementType.Move18
    ],
  },
  {
    type: TrafficMovementType.Move12,
    conflicts: [
      //TrafficMovementType.Move1
    ],
  },
  {
    type: TrafficMovementType.Move14,
    conflicts: [
      //TrafficMovementType.Move3
    ],
  },
  {
    type: TrafficMovementType.Move16,
    conflicts: [
      //TrafficMovementType.Move5
    ],
  },
  {
    type: TrafficMovementType.Move18,
    conflicts: [
      // TrafficMovementType.Move7
    ],
  },
  {
    type: TrafficMovementType.MoveP2,
    conflicts: [TrafficMovementType.Move3, TrafficMovementType.Move8],
  },
  {
    type: TrafficMovementType.MoveP4,
    conflicts: [TrafficMovementType.Move5, TrafficMovementType.Move2],
  },
  {
    type: TrafficMovementType.MoveP6,
    conflicts: [TrafficMovementType.Move7, TrafficMovementType.Move4],
  },
  {
    type: TrafficMovementType.MoveP8,
    conflicts: [TrafficMovementType.Move1, TrafficMovementType.Move6],
  },
];

export const MAJOR_DISABLE_MOVEMENTS = [
  TrafficMovementType.Move7,
  TrafficMovementType.Move4,
  TrafficMovementType.Move14,
  TrafficMovementType.MoveP4,
  TrafficMovementType.Move3,
  TrafficMovementType.Move8,
  TrafficMovementType.Move18,
  TrafficMovementType.MoveP8,
];

export const MINOR_DISABLE_MOVEMENTS = [
  TrafficMovementType.Move5,
  TrafficMovementType.Move2,
  TrafficMovementType.Move12,
  TrafficMovementType.MoveP2,
  TrafficMovementType.Move1,
  TrafficMovementType.Move6,
  TrafficMovementType.Move16,
  TrafficMovementType.MoveP6,
];

export const CONTROLLER_STATES = [
  {
    type: TrafficObjectState.Off,
    name: _('CONTROLLER_STATE.OFF'),
    short: _('CONTROLLER_STATE.OFF_SHORT'),
  },
  {
    type: TrafficObjectState.AllRed,
    name: _('CONTROLLER_STATE.ALL_RED'),
    short: _('CONTROLLER_STATE.ALL_RED_SHORT'),
  },
  {
    type: TrafficObjectState.YellowBlink,
    name: _('CONTROLLER_STATE.YELLOW_BLINK'),
    short: _('CONTROLLER_STATE.YELLOW_BLINK_SHORT'),
  },
  {
    type: TrafficObjectState.Phase,
    name: _('CONTROLLER_STATE.PHASE'),
    short: _('CONTROLLER_STATE.PHASE_SHORT'),
  },
];

export const CONTROLLER_TYPES = [
  {
    type: TrafficControllerType.Potok,
    name: _('CONTROLLER_TYPE.POTOK'),
    prefix: 'potok#',
  },
  {
    type: TrafficControllerType.Usdk,
    name: _('CONTROLLER_TYPE.USDK'),
    prefix: 'usdk#',
  },
  // {
  //   type: TrafficControllerType.Megapolis,
  //   name: _('CONTROLLER_TYPE.MEGAPOLIS'),
  //   prefix: 'mgpls#',
  // },
  // {
  //   type: TrafficControllerType.None,
  //   name: _('CONTROLLER_TYPE.NONE'),
  //   prefix: '',
  // },
];

// export const TRAFFIC_DETECTOR_TYPES = [
//   { type: TrafficDetectorType.Count, name: _('TRAFFIC_DETECTOR_TYPE.COUNT') },
//   { type: TrafficDetectorType.LaneFill, name: _('TRAFFIC_DETECTOR_TYPE.LANE_FILL') },
//   { type: TrafficDetectorType.Speed, name: _('TRAFFIC_DETECTOR_TYPE.SPEED') },
// ];

export const CONTROLLER_EVENT_TYPES = [
  {
    type: ControllerEventType.Conflict,
    name: _('CONTROLLER_EVENT_TYPE.CONFLICT'),
  },
  {
    type: ControllerEventType.LostConnection,
    name: _('CONTROLLER_EVENT_TYPE.LOST_CONNECTION'),
  },
  {
    type: ControllerEventType.NGParitet,
    name: _('CONTROLLER_EVENT_TYPE.NG_PARITET'),
  },
  {
    type: ControllerEventType.NegodPoPereg,
    name: _('CONTROLLER_EVENT_TYPE.NEGOD_POPEREG'),
  },
  {
    type: ControllerEventType.NoControl,
    name: _('CONTROLLER_EVENT_TYPE.NO_CONTROL'),
  },
  {
    type: ControllerEventType.NoCoordination,
    name: _('CONTROLLER_EVENT_TYPE.NO_COORDINATION'),
  },
  {
    type: ControllerEventType.Offline,
    name: _('CONTROLLER_EVENT_TYPE.OFFLINE'),
  },
  {
    type: ControllerEventType.Power220,
    name: _('CONTROLLER_EVENT_TYPE.POWER_220'),
  },
  {
    type: ControllerEventType.RezervProg,
    name: _('CONTROLLER_EVENT_TYPE.RESERV_PROG'),
  },
  {
    type: ControllerEventType.YellowBlink,
    name: _('CONTROLLER_EVENT_TYPE.YELLOW_BLINK'),
  },
  {
    type: ControllerEventType.Off,
    name: _('CONTROLLER_EVENT_TYPE.OFF'),
  },

  {
    type: ControllerEventType.ButtonNoConnection,
    name: _('CONTROLLER_EVENT_TYPE.BUTTON_NO_CONNECTION'),
  },
  {
    type: ControllerEventType.LampControl,
    name: _('CONTROLLER_EVENT_TYPE.LAMP_CONTROL'),
  },
  {
    type: ControllerEventType.Adaptive,
    name: _('CONTROLLER_EVENT_TYPE.ADAPTIVE'),
  },
  {
    type: ControllerEventType.PowerBoardOffline,
    name: _('CONTROLLER_EVENT_TYPE.POWER_BOARD_OFFLINE'),
  },
  {
    type: ControllerEventType.ControllerChange,
    name: _('CONTROLLER_EVENT_TYPE.CONTROLLER_CHANGE'),
  },
];

export const TRACKING_DEVICE_TYPES = [
  {
    type: 'Navtelecom',
    name: _('TRACKING_DEVICE_TYPE.NAVTELECOM'),
    prefix: 'navtelecom#',
  },
];
