import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';

import { NgModule } from '@angular/core';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { Router, NavigationEnd } from '@angular/router';
import { BrowserModule, Title } from '@angular/platform-browser';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { VersionService } from 'projects/msu-its-web-common/src/services/version.service';
import { SettingsService } from 'projects/msu-its-web-common/src/services/settings.service';

import {
  TranslateModule,
  TranslateLoader,
  TranslateService,
  LangChangeEvent,
} from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { CommonModule, registerLocaleData } from '@angular/common';
import localeEn from '@angular/common/locales/en';
import localeRu from '@angular/common/locales/ru';

import { environment } from '../environments/environment';

import { AppComponent } from './app.component';
import { EmptyComponent } from './empty.component';
import { AppRoutingModule } from './app-routing.module';

import { BaseModule } from 'projects/msu-its-web-common/src/modules/base.module';

import { HubSharedModule } from 'projects/msu-its-web-common/src/modules/hub-module/hub-shared.module';
import { TelematicSharedModule } from 'projects/msu-its-web-telematic/src/modules/telematic-module/telematic-shared.module';
import { TlcSharedModule } from 'projects/msu-its-web-tlc/src/modules/tlc-module/tlc-shared.module';
import { ParkingSharedModule } from 'projects/msu-its-web-parking/src/modules/parking-module/parking-shared.module';
import { TfmsSharedModule } from 'projects/msu-its-web-tfms/src/modules/tfms-module/tfms-shared.module';
import { TssSharedModule } from 'projects/msu-its-web-tss/src/modules/tss-module/tss-shared.module';
import { GisSharedModule } from 'projects/msu-its-web-gis/src/modules/gis-module/gis-shared.module';
import { MeteoSharedModule } from 'projects/msu-its-web-meteo/src/modules/meteo-module/meteo-shared.module';
import { PtmSharedModule } from 'projects/msu-its-web-ptm/src/modules/ptm-module/ptm-shared.module';
import { RuisSharedModule } from 'projects/msu-its-web-ruis/src/modules/ruis-module/ruis-shared.module';
import { RmmdSharedModule } from 'projects/msu-its-web-rmmd/src/modules/rmmd-module/rmmd-shared.module';
import { FtvSharedModule } from 'projects/msu-its-web-ftv/src/modules/ftv-module/ftv-shared.module';
import { ModellingSharedModule } from 'projects/msu-its-web-modelling/src/modules/modelling-module/modelling-shared.module';
import { DashboardSharedModule } from 'projects/msu-its-web-dashboard/src/modules/dashboard-module/dashboard-shared.module';

import { EputsComponent } from '../components/eputs/eputs.component';
import { AccidentSharedModule } from 'projects/msu-its-web-accident/src/modules/accident-module/accident-shared.module';

const startTimestamp = Date.now();

@NgModule({
  declarations: [AppComponent, EmptyComponent, EputsComponent],
  imports: [
    CommonModule,
    HttpClientModule,
    BrowserModule,
    BrowserAnimationsModule,

    BaseModule,

    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (http: HttpClient) =>
          new TranslateHttpLoader(http, './assets/i18n/', '.json?t=' + startTimestamp),
        deps: [HttpClient, VersionService],
      },
    }),

    ...(environment.modules.gis ? [GisSharedModule] : []),
    ...(environment.modules.tlc ? [TlcSharedModule] : []),
    ...(environment.modules.tss ? [TssSharedModule] : []),
    ...(environment.modules.tfms ? [TfmsSharedModule] : []),
    ...(environment.modules.meteo ? [MeteoSharedModule] : []),
    ...(environment.modules.parking ? [ParkingSharedModule] : []),
    ...(environment.modules.ptm ? [PtmSharedModule] : []),
    ...(environment.modules.ruis ? [RuisSharedModule] : []),
    ...(environment.modules.rmmd ? [RmmdSharedModule] : []),
    ...(environment.modules.ftv ? [FtvSharedModule] : []),
    ...(environment.modules.dashboard ? [DashboardSharedModule] : []),
    ...(environment.modules.telematic ? [TelematicSharedModule] : []),
    ...(environment.modules.accident ? [AccidentSharedModule] : []),
    ...(environment.modules.modelling ? [ModellingSharedModule] : []),
    HubSharedModule,

    AppRoutingModule,
  ],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(
    private _router: Router,
    private _titleService: Title,
    public dateAdapter: DateAdapter<any>,
    public settingsService: SettingsService,
    public versionService: VersionService,
    public translate: TranslateService
  ) {
    registerLocaleData(localeEn, 'en');
    registerLocaleData(localeRu, 'ru');

    translate.addLangs(['ru', 'en']);
    translate.setDefaultLang('ru');

    translate.onLangChange.subscribe((event: LangChangeEvent) => {
      settingsService.lang = event.lang;
      dateAdapter.setLocale(event.lang);
      this._updateTitle();
    });

    let lang = 'ru'; // _appEnv.defaultLangs;
    const langMatch = translate.getLangs().join('|');
    if (settingsService.lang && settingsService.lang.match(langMatch)) {
      lang = settingsService.lang;
    } else {
      const browserLang = translate.getBrowserLang();
      if (browserLang.match(langMatch)) {
        lang = browserLang;
      }
    }

    dateAdapter.setLocale(lang);
    translate.use(lang);

    // set dark class
    document.body.classList.toggle('dark', settingsService.darkTheme);
    settingsService.darkThemeChanged.subscribe((value: boolean) => {
      document.body.classList.toggle('dark', value);
    });

    // init version check
    versionService.initVersionCheck();

    // set route title
    _router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this._updateTitle();
      }
    });
  }

  private _updateTitle() {
    const titles = this._getTitle(this._router.routerState, this._router.routerState.root).concat([
      _('APP.TITLE'),
    ]);
    const t = this.translate;
    const titleParts = titles.filter((m) => m).map((key) => t.instant(key));
    this._titleService.setTitle(titleParts.join(' - '));
  }

  private _getTitle(state, parent) {
    const data: string[] = [];
    if (parent && parent.snapshot.data && parent.snapshot.data.title) {
      data.push(parent.snapshot.data.title);
    }
    if (state && parent) {
      data.push(...this._getTitle(state, state.firstChild(parent)));
    }
    return data;
  }
}
