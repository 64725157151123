import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';

import {
  DeleteEntityFileRequest,
  GetEntityFileDownloadLinkRequest,
  GetEntityFilesRequest,
} from '../dtos/hub.dtos';
import { HubApiService } from './hub-api.service';

@Injectable({ providedIn: 'root' })
export class FileService {
  constructor(private _api: HubApiService) {}

  async getUrl(fileId: string) {
    const request = new GetEntityFileDownloadLinkRequest();
    request.id = fileId;
    return await this._api.get(request).toPromise();
  }

  getList(entityId: string, filter?: string) {
    const request = new GetEntityFilesRequest();
    request.entityId = entityId;
    request.items = 0;
    request.page = 0;
    request.sort = 'name';
    request.filter = filter;
    return this._api.get(request).pipe(map((m) => m.items));
  }

  delete(entityId: string, fileIds: string[]) {
    const request = new DeleteEntityFileRequest();
    request.entityId = entityId;
    request.ids = fileIds;
    return this._api.delete(request);
  }

  upload(file, entityId: string, description?: string) {
    return this._api.upload(file, entityId, description);
  }
}
