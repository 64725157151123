import { marker as _ } from "@biesbjerg/ngx-translate-extract-marker";
import { NgModule } from "@angular/core";

import { ISidenavMenuCategory } from "projects/msu-its-web-common/src/utils/sidenav-menu";
import {
  GIS_OBJECT_GROUP,
  SIDENAV_MENU,
} from "projects/msu-its-web-common/src/utils/shared-consts";

import { AccidentApiService } from "projects/msu-its-web-accident/src/services/accident-api.service";
import { environment } from "projects/msu-its-web/src/environments/environment";
import { IGisObjectGroup } from "projects/msu-its-web-common/src/utils/gis-object-group";

import { CameraPTZService } from "projects/msu-its-web-accident/src/services/camera-ptz/camera-ptz.service";
import { CameraPTZGroupService } from "projects/msu-its-web-accident/src/services/camera-ptz/camera-ptz-group.service";
import { CameraPTZGroupEventService } from "projects/msu-its-web-accident/src/services/camera-ptz/camera-ptz-group-event.service";
import { CameraPTZGroupShareService } from "projects/msu-its-web-accident/src/services/camera-ptz/camera-ptz-group-share.service";
import { CameraPTZPopupComponent } from "../../components/cameras-ptz/camera-ptz-popup.component";
import { TrafficAccidentPopupComponent } from "../../components/traffic-accident/traffic-accident-popup.component";
import { TrafficAccidentHeatmapComponent } from "../../components/heatmap/traffic-accident-heatmap.component";

import { TrafficAccidentService } from "projects/msu-its-web-accident/src/services/traffic-accident/traffic-accident.service";
import { TrafficAccidentGroupEventService } from "projects/msu-its-web-accident/src/services/traffic-accident/traffic-accident-group-event.service";

import { AccidentApiEventService } from "projects/msu-its-web-accident/src/services/accident-api-event.service";

import { CameraPTZExternalComponent } from "../../components/external/camera-ptz-external.component";
import { CameraPTZEditExternalComponent } from "../../components/external/camera-ptz-edit-external.component";
import { BaseModule } from "projects/msu-its-web-common/src/modules/base.module";
import { CameraFTPGroupService } from "../../services/camera-ftp/camera-ftp-group.service";

import { CameraFTPGroupEventService } from "../../services/camera-ftp/camera-ftp-group-event.service";
import { CameraFTPGroupShareService } from "../../services/camera-ftp/camera-ftp-group-share.service";
import { CameraFTPService } from "../../services/camera-ftp/camera-ftp.service";
import { CameraFTPPopupService } from "../../services/camera-ftp/camera-ftp-popup.service";
import { CameraFTPSourceService } from "../../services/camera-ftp/camera-ftp-source.service";
import { CameraFTPPopupComponent } from "../../components/cameras-ftp/camera-ftp-popup.component";
import { CameraFTPImagesExternalComponent } from "../../components/cameras-ftp/camera-ftp-images-external.component";

let sidenav: ISidenavMenuCategory[] = [
  {
    name: _("SIDENAV_MENU.ACCIDENT_MODULE"),
    items: [
      {
        name: _("CAMERAS_PTZ.TITLE"),
        icon: "switch_video",
        route: "accident-cameras-ptz",
        permissions: ["accidentPtzCamera.read"],
      },
      {
        name: _("TRAFFIC_ACCIDENT.TITLE"),
        icon: "directions_car",
        route: "traffic-accident",
        permissions: ["accidentTrafficAccident.read"],
      },
      {
        name: _("CAMERAS_FTP.TITLE"),
        icon: "video_call",
        route: "cameras-ftp",
        permissions: ["accidentStaticCamera.read"],
      },
    ],
  },
];

(environment as any).system === "krudor" &&
  (sidenav[0].items = sidenav[0].items.filter(
    (item) => item.route === "cameras-ftp"
  ));
(environment as any).system !== "krudor" &&
  (sidenav[0].items = sidenav[0].items.filter(
    (item) => item.route !== "cameras-ftp"
  ));

const cameraPTZGroup: IGisObjectGroup = {
  type: "ptzCamera",
  name: _("CAMERAS_PTZ.TITLE"),
  groupServiceType: CameraPTZGroupService,
  eventServiceType: CameraPTZGroupEventService,
  popupComponentType: CameraPTZPopupComponent,
  // stateComponentType: CameraPopupComponent,
};

const cameraFTPGroup: IGisObjectGroup = (environment as any).system ===
  "krudor" && {
  type: "ftpCamera",
  name: _("CAMERAS_FTP.TITLE"),
  groupServiceType: CameraFTPGroupService,
  eventServiceType: CameraFTPGroupEventService,
  popupComponentType: CameraFTPPopupComponent,
  // stateComponentType: CameraPopupComponent,
};

@NgModule({
  providers: [
    { provide: SIDENAV_MENU, useValue: sidenav, multi: true },
    { provide: GIS_OBJECT_GROUP, useValue: cameraPTZGroup, multi: true },
    {
      provide: GIS_OBJECT_GROUP,
      multi: true,
      useValue: cameraFTPGroup,
    },

    AccidentApiEventService,
    AccidentApiService,
    CameraPTZService,
    CameraPTZGroupService,
    CameraPTZGroupShareService,
    CameraPTZGroupEventService,
    TrafficAccidentService,
    TrafficAccidentGroupEventService,

    CameraFTPService,
    CameraFTPPopupService,
    CameraFTPSourceService,
    CameraFTPGroupService,
    CameraFTPGroupShareService,
    CameraFTPGroupEventService,
  ],
  imports: [BaseModule],
  declarations: [
    TrafficAccidentPopupComponent,
    TrafficAccidentHeatmapComponent,
    CameraPTZPopupComponent,
    CameraPTZExternalComponent,
    CameraPTZEditExternalComponent,
    CameraFTPImagesExternalComponent,
    CameraFTPPopupComponent,
  ],
  entryComponents: [
    TrafficAccidentPopupComponent,
    TrafficAccidentHeatmapComponent,
    CameraPTZPopupComponent,
    CameraPTZExternalComponent,
    CameraPTZEditExternalComponent,
    CameraFTPImagesExternalComponent,
    CameraFTPPopupComponent,
  ],
  exports: [
    TrafficAccidentPopupComponent,
    CameraFTPPopupComponent,
    CameraPTZPopupComponent,
    CameraFTPImagesExternalComponent,
    CameraPTZExternalComponent,
    CameraPTZEditExternalComponent,
  ],
})
export class AccidentSharedModule {}
