import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { shareReplay, takeUntil } from 'rxjs/operators';

import { RecoveryMovement, RouteObject, StepMovement } from '../../dtos/tlc.dtos';

import { DialogService } from 'projects/msu-its-web-common/src/services/dialog.service';
import { RouteEscortState2Service } from './route-escort-state2.service';

@Component({
  selector: 'route-escort-progress2',
  templateUrl: './route-escort-progress2.component.html',
  styleUrls: ['./route-escort-progress2.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RouteEscortProgress2Component implements OnInit, OnDestroy {
  _destroy = new Subject();

  get selectedRoute() {
    return this._stateService.selectedRoute;
  }

  get sessionStatus() {
    return this._stateService.escortSessionStatusSubject.value;
  }
  // get trafficObjectsStatus() {
  //   return this.sessionStatus.trafficObjects.reverse()[0].c;
  // }

  constructor(
    private _stateService: RouteEscortState2Service,
    private _dialogService: DialogService,
    private _changeDetector: ChangeDetectorRef,
    public translate: TranslateService
  ) {}

  ngOnDestroy() {
    this._destroy.next();
    this._destroy.complete();
    this._destroy = null;
  }

  ngOnInit() {
    this._stateService.escortSessionStatusSubject
      .pipe(takeUntil(this._destroy))
      .pipe(shareReplay(1))
      .subscribe((status) => {
        this._changeDetector.markForCheck();
      });
  }

  stop() {
    this._dialogService
      .confirm(this.translate.instant(_('ROUTE_ESCORT.COMPLETE_ESCORT?')))
      .subscribe((confirm) => {
        if (confirm) {
          this._stateService.stopEscort();
          this._changeDetector.markForCheck();
        }
      });
  }

  svgLoad(target, item: RouteObject, step: StepMovement | RecoveryMovement) {
    this._stateService.objectSvgLoad(target, item, step);
  }
  getAngle(item: RouteObject) {
    return this._stateService.getObjectAngle(item);
  }
  getName(item: RouteObject) {
    return this._stateService.getObjectName(item);
  }

  stopTrafficObject(item) {
    this._stateService.stopTrafficObject(item.routeObject.trafficObjectId);
  }
  startTrafficObject(item) {
    this._stateService.startTrafficObject(item.routeObject.trafficObjectId);
  }
}
