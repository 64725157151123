import { Component, ChangeDetectionStrategy, Inject } from "@angular/core";
import { map } from "rxjs/operators";
import { TranslateService } from "@ngx-translate/core";

import { VersionService } from "../../services/version.service";
import { SettingsService } from "../../services/settings.service";
import { HubApiService } from "../../services/hub-api.service";
import { TokenService } from "../../services/token.service";

import { PERMISSIONS_TRANSLATE } from "../../utils/permissions";
import { ApplicationInfo } from "../../dtos/hub.dtos";

@Component({
  selector: "about-dialog",
  styles: [
    `
      mat-dialog-content {
        display: flex;
        flex-direction: column;
      }

      table td {
        padding: 0 16px 8px 0;
        font-size: 14px;
        text-align: left;
        vertical-align: top;
        line-height: normal;
      }
      table th {
        font-size: 16px;
        text-align: left;
        line-height: normal;
        margin-bottom: 12px;
        padding-bottom: 8px;
        padding-right: 22px;
      }

      .head {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
      }

      .title {
        margin-left: 8px;
        line-height: normal;
        font-size: 16px;
        font-weight: 700;
        text-transform: uppercase;
      }
    `,
  ],
  template: `
    <mat-dialog-content>
      <div>
        <div class="head">
          <img
            style="display: block; height: 36px;"
            src="/assets/images/logo{{ darkTheme ? '_dark' : '' }}.png"
          />

          <div class="title">
            <div>{{ "ABOUT.TITLE" | translate }}</div>
            <div style="font-size: 12px">
              {{ "ABOUT.ABOUT_PROGRAMM" | translate }}
            </div>
          </div>
        </div>
        <table style="margin-top: 32px; margin-bottom: 16px;">
          <tr>
            <td class="mat-body-strong">
              {{ "ABOUT.MANUFACTURER" | translate }}:
            </td>
            <td class="mat-body-1">
              <div>{{ manufacturer }}</div>
              <div>
                <img
                  style="display: block; height: 18px; margin: 4px 0 12px;"
                  src="/assets/images/msu_{{ darkTheme ? 'dark_' : '' }}32.png"
                />
              </div>
            </td>
          </tr>
          <tr>
            <td class="mat-body-strong">{{ "ABOUT.PHONE" | translate }}:</td>
            <td class="mat-body-1">{{ phone }}</td>
          </tr>
          <tr>
            <td class="mat-body-strong">{{ "ABOUT.EMAIL" | translate }}:</td>
            <td class="mat-body-1">
              <a
                [href]="'mailto:' + email"
                target="_blank"
                style="color: #00BFFF;"
                >{{ email }}</a
              >
            </td>
          </tr>
          <tr>
            <td class="mat-body-strong">{{ "ABOUT.SITE" | translate }}:</td>
            <td class="mat-body-1">
              <div style="margin-bottom: 12px;">
                <a
                  [href]="'http://' + site"
                  target="_blank"
                  style="color: #00BFFF;"
                  >{{ site }}</a
                >
              </div>
            </td>
          </tr>
        </table>

        <table>
          <tr>
            <th>{{ "ABOUT.MODULES" | translate }}</th>
            <th>Дата установки модуля</th>
            <th>Версия</th>
            <th>Дата релиза</th>
          </tr>
          <tr *ngFor="let appInfo of applicationInfo | async">
            <td class="mat-body-strong">
              {{ getName(appInfo.id) | translate }}
            </td>
            <td
              *ngIf="appInfo.id !== 'Msu.Its.Meteo'"
              class="mat-secondary-color"
            >
              {{
                getStartSystemDate(appInfo.id)
                  | date: "dd.MM.yyyy":timezone:locale
              }}
            </td>
            <td
              *ngIf="appInfo.id === 'Msu.Its.Meteo' && systemName === 'udib'"
              class="mat-secondary-color"
            >
              {{ "2021-11-15" | date: "dd.MM.yyyy":timezone:locale }} /
              {{ "2022-04-29" | date: "dd.MM.yyyy":timezone:locale }}
            </td>
            <td
              *ngIf="appInfo.id === 'Msu.Its.Meteo' && systemName === 'krudor'"
              class="mat-secondary-color"
            >
              {{ "2022-11-25" | date: "dd.MM.yyyy":timezone:locale }}
            </td>

            <td class="mat-body-1" title="{{ appInfo.description }}">
              {{ appInfo.version }}
            </td>
            <td class="mat-secondary-color">
              {{ appInfo.buildTime | date: "dd.MM.yyyy":timezone:locale }}
            </td>
          </tr>
          <tr>
            <td class="mat-body-strong">
              {{ "ABOUT.WEB_VERSION" | translate }}:
            </td>
            <td *ngIf="systemName === 'krudor'" class="mat-secondary-color">
              {{ "2021-05-27" | date: "dd.MM.yyyy":timezone:locale }}
            </td>
            <td  *ngIf="systemName !== 'krudor'"  class="mat-secondary-color">
              {{ "2020-12-20" | date: "dd.MM.yyyy":timezone:locale }}
            </td>
            <td class="mat-body-1">
              {{ version }}
            </td>
            <td class="mat-secondary-color">
              {{ date | date: "dd.MM.yyyy":timezone:locale }}
            </td>
          </tr>
          <!-- 
          <tr>
            <td class="mat-body-strong">
              {{ 'ABOUT.BUILD_DATE' | translate }}:
            </td>
            <td class="mat-body-1">
              {{ date | date: 'mediumDate':null:translate.currentLang }}
            </td>
          </tr> 
          -->
        </table>
      </div>
    </mat-dialog-content>
    <mat-dialog-actions align="end">
      <button mat-button [mat-dialog-close]="">
        {{ "COMMON.CLOSE" | translate }}
      </button>
    </mat-dialog-actions>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AboutDialogComponent {
  constructor(
    private _versionService: VersionService,
    private _settingsService: SettingsService,
    private _hubService: HubApiService,
    private _tokenService: TokenService,

    @Inject("APP_ENVIRONMENT") private _appEnv,
    public translate: TranslateService
  ) {}

  get timezone() {
    return this._settingsService.timezone;
  }
  get locale() {
    return this.translate.currentLang;
  }

  get date() {
    return this._versionService.timestamp;
  }
  get version() {
    return this._versionService.version;
  }
  get darkTheme() {
    return this._settingsService.darkTheme;
  }

  get manufacturer() {
    return this._appEnv.aboutManufacturer;
  }
  get email() {
    return this._appEnv.aboutEmail;
  }
  get phone() {
    return this._appEnv.aboutPhone;
  }
  get site() {
    return this._appEnv.aboutSite;
  }

  get systemName() {
    return this._appEnv.system;
  }

  applicationInfo = this._hubService.getApplicationInfo().pipe(
    map(
      (m) => {
        // console.log(m);

        const envAboutModules = this._appEnv.aboutModules as ApplicationInfo[];
        const envAboutModulesExclude = this._appEnv
          .aboutModulesExclude as string[];
        let result;
        if (this.systemName === "krudor") {
          result = m.filter(
            (m) =>
              m.id === "Msu.Its.Dashboard" ||
              m.id === "Msu.Its.Tlc" ||
              m.id === "Msu.Its.Tfms" ||
              m.id === "Msu.Its.Ruis" ||
              (m.id === "Msu.Its.Meteo" &&
                !envAboutModules?.some((n) => n.id === m.id))
          );
        } else {
          result = m.filter(
            (m) =>
              !m.id.startsWith("Msu.Its.Telematic.") &&
              !m.id.startsWith("Its.Connectors.") &&
              !m.id.startsWith("Msu.Its.Tpc") &&
              !m.id.startsWith("RMM.") &&
              !m.id.startsWith("Msu.Its.Tt") &&
              !m.id.startsWith("Msu.Parking") &&
              !m.id.startsWith("Msu.Its.Ruis") &&
              !envAboutModules?.some((n) => n.id === m.id)
          );
        }

        result.push(...envAboutModules);
        result = result.filter((m) => !envAboutModulesExclude?.includes(m.id));

        result.sort((a, b) => {
          return a.id.localeCompare(b.id);
        });
        return result;
      }
      // (this._tokenService.hasPermissions(['system.manage'])
      //   ? m
      //   : m.filter(
      //       (m) =>
      //         !m.id.startsWith('Msu.') && !m.id.startsWith('Its.Connectors.')
      //     )
      // ).sort((a, b) => {
      //   return a.id.localeCompare(b.id);
      // })
    )
  );

  getName(id: string) {
    if (PERMISSIONS_TRANSLATE[id + "_krudor"] && this.systemName === "krudor") {
      return PERMISSIONS_TRANSLATE[id + "_krudor"] || id;
    }
    return PERMISSIONS_TRANSLATE[id] || id;
  }

  getStartSystemDate(id: string) {
    switch (id) {
      case "Msu.Its.Meteo": // to do
        if (this.systemName === "krudor") {
          return "2022-11-25";
        }
        return "2022-04-29";
      case "Msu.Its.Parking":
        return "2022-11-24";
      case "Msu.Its.Ptm":
        return "2022-12-7";
      case "Msu.Its.Ruis":
        if (this.systemName === "krudor") {
          return "2022-12-15";
        }
        return "2023-12-20";
      case "Msu.Its.Tlc":
        if (this.systemName === "krudor") {
          return "2022-09-30";
        }
        return "2020-12-20";
      case "Msu.Its.Tfms":
        if (this.systemName === "krudor") {
          return "2022-10-31";
        }
        return "2020-12-20";
      case "Msu.Its.Dashboard":
        if (this.systemName === "krudor") {
          return "2022-12-10";
        }
        return "2020-12-20";
      case "Msu.Its.Rmmd":
        return "2022-12-12";
      case "Msu.Its.Vsae":
        return "2022-12-07";
      case "Msu.Its.Accident_krudor":
        return "2023-12-04";
      case "Msu.Its.Integration_external_system":
        return "2023-12-08";
      case "Msu.Its.Weight_control":
        return "2023-11-29";
      default:
        return "2020-12-20";
    }
  }
}
