<h4 mat-dialog-title>
  <span>{{ "TRAFFIC_SCENES.TRAFFIC_SCENE_ANALYSIS" | translate }}</span>
</h4>
<mat-dialog-content style="flex: 1; position: relative; min-width: 600px; min-height: 600px; max-height: 70vh impo !important">
  <div *ngIf="loading" class="backdrop" style="position: absolute">
    <div style="margin-right: 12px">{{ "TRAFFIC_SCENES.CALCULATION" | translate }}...</div>
    <mat-spinner diameter="24"></mat-spinner>
  </div>

  <div *ngIf="!analysis && !loading" class="backdrop" style="position: absolute; pointer-events: none">
    <div>{{ "TRAFFIC_SCENES.ANALYSIS_NOT_FOUND" | translate }}</div>
  </div>

  <div fxLayout="column">
    <div fxLayout fxLayoutGap="16px" fxLayoutAlign="start center">
      <mat-form-field floatLabel="always">
        <mat-label>{{ "COMMON.DAY_OF_WEEK" | translate }}</mat-label>
        <mat-select [formControl]="dayOfWeekControl" disableOptionCentering panelClass="single-select-panel left">
          <mat-option *ngFor="let item of weekDays" [value]="item.type">
            {{ item.name | translate }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <div>
        <mat-form-field floatLabel="always" style="width: 80px">
          <mat-label>{{ "COMMON.TIME" | translate }}</mat-label>
          <input matInput type="time" [formControl]="fromControl" class="spin-button" />
          <mat-icon class="date-button">schedule</mat-icon>
        </mat-form-field>
        <span style="padding: 0 8px">-</span>
        <mat-form-field floatLabel="always" style="width: 80px">
          <input matInput type="time" [formControl]="toControl" class="spin-button" />
          <mat-icon class="date-button">schedule</mat-icon>
        </mat-form-field>
      </div>

      <button mat-flat-button color="accent" (click)="calculate()" [disabled]="loading">
        {{ "TRAFFIC_SCENES.ANALYSIS" | translate }}
      </button>
    </div>

    <div *ngIf="analysis" style="flex: 1">
      <div *ngFor="let item of analysis.trafficObjectSceneAnalyses; let i = index" style="margin-bottom: 12px">
        <div style="font-size: 14px">
          {{ i + 1 }}. {{ "TRAFFIC_SCENES.RECOMMENDED_PLAN" | translate }}: {{ getObjectName(item.id) }} {{ item.plan.length }}
        </div>
        <div>
          <traffic-scene-plan [objectId]="item.id" [schemaSet]="getSchemaSet(item.id)" [statusSubject]="statusSubject"></traffic-scene-plan>
        </div>
      </div>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <div style="flex: 1"></div>
  <!-- <button mat-button (click)="save()" color="primary" [disabled]="loading">
    {{ "COMMON.SAVE" | translate }}
  </button> -->
  <button mat-button (click)="close()" [disabled]="loading">
    {{ "COMMON.CLOSE" | translate }}
  </button>
</mat-dialog-actions>
