import { Injectable } from "@angular/core";
import { Subject } from "rxjs";

import {
  IGisObjectGroupEventService,
  IGisObjectStatus,
} from "projects/msu-its-web-common/src/utils/gis-object-group";
import { AccidentApiEventService } from "../accident-api-event.service";

@Injectable()
export class CameraFTPGroupEventService implements IGisObjectGroupEventService {
  constructor(private _eventService: AccidentApiEventService) {}

  permissions = ["accidentStaticCamera.read"];

  subscribe(
    customerId: string,
    groupIds: string[],
    updateSubject: Subject<IGisObjectStatus[]>
  ) {
    // todo
  }

  unsubscribe() {
    // todo
  }
}
