import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { shareReplay } from 'rxjs/operators';

import { CustomerInfo } from '../dtos/hub.dtos';

export enum ModuleIds {
  gis = 'Msu.Its.Gis',
  tlc = 'Msu.Its.Tlc',
  tfms = 'Msu.Its.Tfms',
  tss = 'Msu.Its.Tss',
  parking = 'Msu.Its.Parking',
  meteo = 'Msu.Its.Meteo',
  ptm = 'Msu.Its.Ptm',
  ruis = 'Msu.Its.Ruis',
}

export type ModuleSettingValue = {
  moduleId: ModuleIds;
  setting: string;
  value: any;
};

@Injectable({ providedIn: 'root' })
export class CustomerStateService {
  private _destroy = new Subject();

  private readonly _customer = new BehaviorSubject<CustomerInfo>(null);
  readonly customer$ = this._customer.pipe(shareReplay(1));
  get customer() {
    return this._customer.getValue();
  }
  set customer(value) {
    this._customer.next(value);
  }

  private readonly _modulesSettings = new BehaviorSubject<{
    [moduleId: string]: any;
  }>({});
  readonly modulesSettings$ = this._modulesSettings.pipe(shareReplay(1));
  get modulesSettings() {
    return this._modulesSettings.getValue();
  }
  set modulesSettings(value) {
    this._modulesSettings.next(value);
  }

  constructor() {}

  ngOnDestroy() {
    this._destroy.next();
    this._destroy.complete();
    this._destroy = null;
  }

  hasModuleSettings(settingValues: ModuleSettingValue[]) {
    return settingValues.every(({ moduleId, setting, value }) => {
      const settings = this.modulesSettings[moduleId];
      return settings !== undefined && _getSetting(settings, setting) === value;
    });
  }
}

const _getSetting = (source: any, setting: string) => {
  if (!setting || !source) return undefined;
  return setting
    .split('.')
    .reduce(
      (result, m) => (result === undefined ? undefined : result[m]),
      source
    );
};

// Example how to use hasModuleSettings
//
// const showDeviceVersion = this._customerStateService.hasModuleSettings([
//   {
//     moduleId: ModuleIds.tfms,
//     setting: 'tableView.showDeviceVersion',
//     value: true,
//   },
// ]);
