<div style="width: 524px; position: relative;">
  <div *ngIf="loading" class="backdrop">
    <mat-spinner diameter="36" color="accent"></mat-spinner>
  </div>
  <div fxLayout="row" fxLayoutAlign="start center">
    <img fxFlex="44px" #stateImage class="state-image" />
    <div
      fxFlex
      fxLayout="row"
      class="mat-elevation-z2"
      style="padding: 2px 0px 2px 6px;"
    >
      <div style="margin-right: 24px;">
        <div class="mat-small mat-secondary-color">
          {{ !modelData?.externalSystemId ? (
          "ROAD_MAINTENANCE_VEHICLE.TERMINAL_NUMBER" | translate ):
          ("ROAD_MAINTENANCE_EXTERNAL_SYSTEM.NAME_HINT" | translate )}}
        </div>
        <div class="mat-body-1" style="line-height: normal;">
          {{ !modelData?.externalSystemId ? modelData?.terminalNumber :
          modelData?.externalSystemName }}
        </div>
      </div>

      <div style="flex: 1; margin-right: 24px;">
        <ng-container *ngIf="modelData?.online">
          <div class="mat-small mat-secondary-color">
            {{ "COMMON.CONNECTION_TIME" | translate }}:
          </div>
          <div
            class="mat-body-1"
            style="line-height: normal; padding-bottom: 3px;"
          >
            {{ modelData?.vehicleTime | date: "dd.MM.yyyy
            HH:mm:ss":timezone:locale }}
          </div>
        </ng-container>
      </div>
      <div style="flex: 1;">
        <ng-container>
          <div class="mat-small mat-secondary-color">
            {{ "COMMON.STATUS" | translate }}:
          </div>
          <div
            class="mat-body-1"
            style="line-height: normal; padding-bottom: 3px;"
          >
            {{ modelData?.online ? 'На связи' : " Не на связи"}}
          </div>
        </ng-container>
      </div>
    </div>
  </div>
  <div fxLayout="row" fxLayoutAlign="space-between center">
    <div>
      <mat-form-field style="margin-right: 8px; width: 160px;">
        <input
          matInput
          type="datetime-local"
          [formControl]="fromControl"
          class="spin-button"
        />
        <mat-icon class="date-button">today</mat-icon>
      </mat-form-field>

      <span style="margin-right: 8px;">-</span>

      <mat-form-field style="margin-right: 12px; width: 160px;">
        <input
          matInput
          type="datetime-local"
          [formControl]="toControl"
          class="spin-button"
        />
        <mat-icon class="date-button">today</mat-icon>
      </mat-form-field>
    </div>

    <button mat-raised-button color="primary" (click)="download()">
      <mat-icon>file_download</mat-icon>
      Отчет
    </button>
  </div>

  <div
    fxLayout="column"
    fxLayoutAlign="start start"
    fxLayoutGap="8px"
    style="
      box-sizing: border-box;
      border-radius: 4px;
      padding: 5px;
      background-color: #575757;
    "
  >
    <div
      fxLayout="row"
      fxLayoutAlign="start center"
      style="align-items: flex-start !important;"
    >
      <span fxFlex="160px">Подрядчик</span>
      <span fxFlex>{{modelData?.contractorName}}</span>
    </div>
    <div *ngIf="modelData?.online" fxLayout="row" fxLayoutAlign="start center">
      <span fxFlex="160px">Скорость</span>
      <span fxFlex>{{modelData?.speed.toFixed()}}</span>
    </div>
  </div>

  <div
    *ngIf="showExternalErrorMessage"
    style="
      width: 50%;
      padding: 11px;
      background-color: gray;
      border: 2px solid red;
      border-radius: 5px;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
      height: fit-content;
    "
  >
    <span>{{modelData?.errorDescription}}</span>
  </div>
</div>
