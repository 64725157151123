<div>
  <div fxLayout="column" fxLayoutAlign="space-around start" fxLayoutGap="18px">
    <div fxFlex="10">
      <h2 style="margin: 0; font-weight: 500; font-size: 16px; line-height: 1">{{ "COMMON.EXPORT" | translate }}</h2>
    </div>
    <div fxFlex="row" fxLayoutGap="12px">
      <mat-form-field fxFlex="100px" floatLabel="always">
        <input
          matInput
          [formControl]="date"
          (click)="picker.open()"
          [matDatepicker]="picker"
          placeholder="{{ 'DETECTORS.DATE_FOR_EXPORT' | translate }}"
        />
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
        <mat-error *ngIf="date.hasError('required')">
          {{ "DETECTORS.REQUIRED_DATE_FOR_EXPORT" | translate }}
        </mat-error>
      </mat-form-field>
      <!-- pattern="^[a-zA-Z0-9_-]{1,15}$" -->
      <mat-form-field fxFlex="230px" floatLabel="always">
        <input matInput readonly autocomplete="off" [formControl]="nameFile" placeholder="{{ 'DETECTORS.NAME_FILE_FOR_EXPORT' | translate }}" />
        <mat-error *ngIf="nameFile.hasError('required')">
          {{ "DETECTORS.REQUIRED_NAME_FILE_FOR_EXPORT" | translate }}
        </mat-error>
        <mat-error *ngIf="nameFile.hasError('pattern')">
          {{ "DETECTORS.UNCORRECTED_NAME_FILE_FOR_EXPORT" | translate }}
        </mat-error>
      </mat-form-field>

      <mat-form-field style="width: 100px; margin-bottom: -5px; z-index: 5" floatLabel="always">
        <mat-select [formControl]="LaneSelect" placeholder="{{ 'COMMON.LANE' | translate }}">
          <mat-option [value]="0">{{ "COMMON.SUMMARY" | translate }}</mat-option>
          <mat-option *ngFor="let item of lanes" [value]="item"> {{ "COMMON.LANE" | translate }} #{{ item }} </mat-option>
        </mat-select>
        <mat-error *ngIf="LaneSelect.hasError('required')">
          {{ "DETECTORS.REQUIRED_LANE" | translate }}
        </mat-error>
      </mat-form-field>

      <mat-form-field fxFlex="100px" floatLabel="always">
        <mat-label>{{ "DETECTORS.TYPE_FILE" | translate }}</mat-label>
        <mat-select [formControl]="typeFile" [disableOptionCentering]="true">
          <mat-option *ngFor="let item of typesFile" [value]="item.type">
            <mat-divider style></mat-divider>
            {{ item.name | translate }}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="typeFile.hasError('required')">
          {{ "DETECTORS.TYPE_FILE_REQUIRED" | translate }}
        </mat-error>
      </mat-form-field>
    </div>
    <div fxFlexFill fxLayout="row" fxLayoutAlign="end center">
      <button fxFlex="25" mat-button (click)="exportFederal()">
        <span>{{ "COMMON.EXPORT_FEDERAL" | translate }}</span>
      </button>
      <button fxFlex="25" type="submit" mat-button (click)="export()">
        <span>{{ "COMMON.EXPORT" | translate }}</span>
      </button>
      <button fxFlex="25" mat-button (click)="cancel()">
        <span>{{ "COMMON.CANCEL" | translate }}</span>
      </button>
    </div>
  </div>
</div>
