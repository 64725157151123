import { AfterViewInit, ChangeDetectionStrategy, Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { DialogService } from 'projects/msu-its-web-common/src/services/dialog.service';
import { DetectorService } from '../../services/detector.service';
import { DetectorPopupComponent } from '../detectors/detector-popup.component';

@Component({
  selector: 'detector-external',
  template: ``,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DetectorExternalComponent implements AfterViewInit {
  objectId;
  customerId;

  constructor(
    private _route: ActivatedRoute,
    private _detectorService: DetectorService,
    private _dialogService: DialogService
  ) {
    this.objectId = this._route.snapshot.paramMap.get('objectId');
    this.customerId = this._route.snapshot.paramMap.get('customerId');
  }

  ngAfterViewInit() {
    this._detectorService.get(this.objectId, this.customerId).subscribe((model) => {
      const dialog = this._dialogService.dialog.open(DetectorPopupComponent, {
        disableClose: true,
        panelClass: 'external-dialog',
      });
      dialog.componentInstance.modelId = this.objectId;
      dialog.componentInstance.modelInfo = model;
      dialog.componentInstance.customerId = this.customerId;
      dialog.componentInstance.external = true;
    });
  }
}
