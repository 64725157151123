import {
  ChangeDetectorRef,
  ChangeDetectionStrategy,
  Input,
  OnDestroy,
  OnInit,
  Component,
} from '@angular/core';
import { forkJoin, of, Subject, timer } from 'rxjs';
import { catchError, finalize, takeUntil } from 'rxjs/operators';

import { TlcApiEventService } from 'projects/msu-its-web-tlc/src/services/tlc-api-event.service';
import { TrafficObjectService } from 'projects/msu-its-web-tlc/src/services/traffic-object.service';
import { UsdkTrafficObjectService } from 'projects/msu-its-web-tlc/src/services/usdk-traffic-object.service';
import { IUsdkTrafficObjectSet, UsdkStateService } from './usdk-state.service';

import {
  UsdkSettings,
  TrafficObject,
  UsdkTrafficObjectStatus,
} from 'projects/msu-its-web-tlc/src/dtos/tlc.dtos';
import { SchemaSet } from 'projects/msu-its-web-tlc/src/dtos/schema';
import { LANGS_LOCALES } from 'projects/msu-its-web-common/src/utils/langs';
import { TranslateService } from '@ngx-translate/core';
import { SettingsService } from 'projects/msu-its-web-common/src/services/settings.service';
import { USDK_ERROR_TYPES } from 'projects/msu-its-web-tlc/src/dtos/usdk/usdk-enums';
import { TokenService } from 'projects/msu-its-web-common/src/services/token.service';

@Component({
  selector: 'usdk-state',
  templateUrl: './usdk-state.component.html',
  styleUrls: ['./usdk-state.component.css'],
  providers: [UsdkStateService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UsdkStateComponent implements OnInit, OnDestroy {
  _destroy = new Subject();

  _selectedTab = this._settingsService.TrafficObjectStateTab;
  get selectedTab() {
    return this._selectedTab;
  }
  set selectedTab(value) {
    this._selectedTab = value;
    this._settingsService.TrafficObjectStateTab = value;
  }

  status: UsdkTrafficObjectStatus;
  trafficObjectSet: IUsdkTrafficObjectSet;

  @Input()
  modelId: string;

  @Input()
  modelPermissions: string[];

  @Input()
  customerId: string;

  get loading() {
    return this._stateService.loading;
  }
  get trafficStatus() {
    return this.status?.controllerStatus?.traffic;
  }
  get controllerStatus() {
    return this.status?.controllerStatus?.controller;
  }

  get showCenterBlock() {
    return this.controllerStatus?.errors?.length;
  }

  get timezone() {
    return this._settingsService.timezone;
  }
  get locale() {
    return LANGS_LOCALES[this.translate.currentLang];
  }

  _eventChannelId: string;
  _eventChannelReceivers: any = {};

  constructor(
    private _settingsService: SettingsService,
    private _trafficObjectService: TrafficObjectService,
    private _usdkTrafficObjectService: UsdkTrafficObjectService,
    private _stateService: UsdkStateService,
    private _apiEventService: TlcApiEventService,
    private _changeDetector: ChangeDetectorRef,
    private _tokenService: TokenService,
    public translate: TranslateService
  ) {}

  ngOnInit() {
    this._stateService.modelId = this.modelId;
    this._stateService.customerId = this.customerId;
    this._updateSettings();
    this._subscibeStatusEvent();

    this._stateService.loading$.pipe(takeUntil(this._destroy)).subscribe(() => {
      this._changeDetector.markForCheck();
    });

    this._stateService.trafficObjectSet$
      .pipe(takeUntil(this._destroy))
      .subscribe((trafficObjectSet) => {
        this.trafficObjectSet = trafficObjectSet;
        this._changeDetector.markForCheck();
      });

    this._stateService.status$.pipe(takeUntil(this._destroy)).subscribe((status) => {
      this.status = status;
      this._changeDetector.markForCheck();
    });
  }

  ngOnDestroy() {
    this._destroy.next();
    this._destroy.complete();
    this._destroy = null;
    this._unsubscibeStatusEvent();
  }

  _updateSettings() {
    if (this._stateService.loading) return;
    this._stateService.loading = true;
    forkJoin([
      this._trafficObjectService.get(this.modelId, this.customerId),
      this._trafficObjectService.getSchemaSet(this.modelId, this.customerId),
      this._usdkTrafficObjectService.getSettings(this.modelId, this.customerId),
      this._usdkTrafficObjectService.getStatus(this.modelId, this.customerId),
    ])
      .pipe(finalize(() => (this._stateService.loading = false)))
      .pipe(
        catchError(() => {
          const settings = new UsdkSettings();
          this._usdkTrafficObjectService.assignSettings(settings);
          const result: [TrafficObject, SchemaSet, UsdkSettings, UsdkTrafficObjectStatus] = [
            new TrafficObject(),
            new SchemaSet(),
            settings,
            null,
          ];
          return of(result);
        })
      )
      .subscribe((result) => {
        // check modifyKey
        if (
          this._stateService.trafficObjectSet &&
          this._stateService.trafficObjectSet.model.changeKey === result[0].changeKey
        ) {
          return;
        }

        // update settings set
        this._stateService.trafficObjectSet = {
          model: result[0],
          schemaSet: result[1],
          settings: result[2],
        };

        // update status
        this._stateService.status = result[3];
        this._timerGetStatus();

        this._changeDetector.markForCheck();
      });
  }

  _timerGetStatus() {
    this._usdkTrafficObjectService
      .getStatus(this.trafficObjectSet.model.id, this._stateService.customerId)
      .pipe(takeUntil(this._destroy))
      .pipe(
        finalize(() =>
          timer(1 * 1000)
            .pipe(takeUntil(this._destroy))
            .subscribe(() => this._destroy && this._timerGetStatus())
        )
      )
      .subscribe((status) => {
        this._stateService.status = status;
      });
  }

  _subscibeStatusEvent() {
    // this._eventChannelId = `trafficObjectStatus_${this.modelId}`;
    // this._eventChannelReceivers[this._eventChannelId] = (
    //   value: TrafficObjectStatus
    // ) => {
    //   this._usdkStateService.status = value;
    // };
    // this._apiEventService.subscribeServerEvents(
    //   [this._eventChannelId],
    //   this._eventChannelReceivers
    // );
  }

  _unsubscibeStatusEvent() {
    // this._apiEventService.unsubscribeServerEvents(
    //   [this._eventChannelId],
    //   this._eventChannelReceivers
    // );
  }

  getDurationString(date) {
    const from = new Date(date).getTime();

    // check for 01.01.2000
    if (from === 946684800000) { 
      return '';
    }
    const duration = Math.floor((Date.now() - from) / 1000);
    const days = Math.floor(duration / (3600 * 24));
    const hours = Math.floor(duration / 3600) % 24;
    const minutes = Math.floor(duration / 60) % 60;

    const parts = [];
    if (days) {
      parts.push(days + this.translate.instant('COMMON.SHORT.DAY'));
    }
    if (hours) {
      parts.push(hours + this.translate.instant('COMMON.SHORT.HOUR'));
    }
    if (minutes) {
      parts.push(minutes + this.translate.instant('COMMON.SHORT.MINUTE'));
    }

    return parts.length ? parts.join(' ') : '1' + this.translate.instant('COMMON.SHORT.MINUTE');
  }

  getErrorTypeName(type: any) {
    const error = USDK_ERROR_TYPES.find((m) => m.type == type);
    return error ? error.name : type;
  }

  get disabled() {
    const controlPermission = 'trafficObject.control';

    let allowControl = false;
    if (this.trafficObjectSet?.model.customerId == this.customerId) {
      allowControl = this._tokenService.hasPermissions([controlPermission]);
    } else if (this.modelPermissions) {
      allowControl = this.modelPermissions.includes(controlPermission);
    }

    return !allowControl;
  }
}
