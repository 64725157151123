import { Inject, Injectable } from "@angular/core";
import {
  CreateDetectorRequest,
  DeleteDetectorsRequest,
  Detector,
  DetectorGraph,
  DetectorInternalGraph,
  DetectorsStateResponse,
  DetectorState,
  GetDetectorGraphRequest,
  GetDetectorImage,
  DetectorInternalGraphItem,
  GetDetectorRequest,
  GetDetectorStateRequest,
  GetDetectorsStateRequest,
  UpdateDetectorRequest,
  GetDetectorsRequest,
  GetDetectorInternalGraphRequest,
  GetDetectorGraphArchiveRequest,
  GraphDataType,
  GetDetectorGraphAnalysisRequest,
  DetectorGraphAnalysis,
  GetDetectorDevicesRequest,
  GetDetectorDevicesResponse,
  SetDetectorReplacementRequest,
  DetectorReplacement,
  GetDetectorExistRequest,
  GetDetectorInfoRequest,
  DetectorChangeCustomerRequest,
  GetDetectorCurrentUserPermissionsRequest,
  GetDetectorLanesRequest,
  DetectorInfo,
  DetectorUserStatus,
} from "../dtos/tfms.dtos";
import { TfmsApiService } from "./tfms-api.service";
import {
  IDataSourceService,
  setPageParams,
  getPageReturn,
  IPageParams,
  IPageReturn,
} from "projects/msu-its-web-common/src/data/data-source";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { TokenService } from "projects/msu-its-web-common/src/services/token.service";
import { APP_ENVIRONMENT } from "projects/msu-its-web-common/src/utils/shared-consts";
import { NumberSymbol } from "@angular/common";

@Injectable()
export class DetectorService implements IDataSourceService<Detector, Detector> {
  constructor(
    private _api: TfmsApiService,
    private _tokenService: TokenService,
    @Inject(APP_ENVIRONMENT) private _appEnv
  ) {}

  getPagedList(
    params: IPageParams,
    requestParams: {
      userStatus: DetectorUserStatus;
      customerId: string;
      filterCustomerId: string;
    }
  ): Observable<IPageReturn<Detector>> {
    const request = new GetDetectorsRequest();
    request.customerId = requestParams.customerId;
    request.userStatus = requestParams.userStatus || null;
    request.filterCustomerId = requestParams.filterCustomerId || "";
    setPageParams(request, params);
    return this._api.get(request).pipe(
      map((m) => {
        return getPageReturn(m);
      })
    );
  }

  getList(
    customerId: string,
    filter?: string,
    groupIds?: string[],
    lat?: number,
    lon?: number,
    userStatus?: DetectorUserStatus,
    distance = 200
  ): Observable<Detector[]> {
    const request = new GetDetectorsRequest();
    request.customerId = customerId;
    request.entityGroups = groupIds;

    request.page = 0;
    request.items = 0;
    request.sort = "name";
    request.filter = filter;
    request.userStatus = userStatus;

    request.lat = lat;
    request.lon = lon;
    request.distance = distance;

    return this._api.get(request).pipe(
      map((m) => {
        return m.items;
      })
    );
  }
  getListMaintenance(
    customerId: string,
    filter?: string,
    items?: number,
    groupIds?: string[],
    lat?: number,
    lon?: number,
    distance = 200
  ): Observable<Detector[]> {
    const request = new GetDetectorsRequest();
    request.customerId = customerId;
    request.entityGroups = groupIds;

    request.page = 0;
    request.items = items;
    request.sort = "name";
    request.filter = filter;

    request.lat = lat;
    request.lon = lon;
    request.distance = distance;

    return this._api.get(request).pipe(
      map((m) => {
        return m.items;
      })
    );
  }

  get(id: any, customerId: string): Observable<Detector> {
    const request = new GetDetectorRequest();
    request.customerId = customerId;
    request.id = id;
    return this._api.get(request);
  }

  add(
    model: Detector,
    params: {
      customerId: string;
      groups: string[];
      updateGroups: boolean;
    }
  ) {
    const request = new CreateDetectorRequest();
    request.customerId = params.customerId;
    request.entity = model;
    request.entityGroups = params.groups;
    request.updateEntityGroups = params.updateGroups;
    return this._api.post(request);
  }

  update(
    model: Detector,
    params: { customerId: string; groups: string[]; updateGroups: boolean }
  ) {
    const request = new UpdateDetectorRequest();
    request.id = model.id;
    request.entity = model;
    request.customerId = params.customerId;
    request.entityGroups = params.groups;
    request.updateEntityGroups = params.updateGroups;
    return this._api.put(request);
  }

  delete(ids: string[], params: { customerId: string }): Observable<number> {
    const request = new DeleteDetectorsRequest();
    request.customerId = params.customerId;
    request.ids = ids;
    return this._api.delete(request);
  }

  getDetectorsStatePageRequest(
    params: IPageParams,
    customerId: string
  ): Observable<IPageReturn<DetectorState>> {
    const request = new GetDetectorsStateRequest();
    request.customerId = customerId;
    setPageParams(request, params);
    return this._api.get(request).pipe(
      map((m) => {
        return getPageReturn(m);
      })
    );
  }
  getDetectorsStateRequest(
    customerId: string,
    itemsIds?: string[]
  ): Observable<DetectorsStateResponse> {
    const request = new GetDetectorsStateRequest();
    request.customerId = customerId;
    request.itemsIds = itemsIds;
    return this._api.get(request);
  }

  getListState(
    customerId: string,
    filter?: string,
    groupIds?: string[]
  ): Observable<DetectorState[]> {
    const request = new GetDetectorsStateRequest();
    request.customerId = customerId;
    request.entityGroups = groupIds;
    request.page = 0;
    request.items = 0;
    request.sort = "name";
    request.filter = filter;

    return this._api.get(request).pipe(
      map((m) => {
        return m.items;
      })
    );
  }

  getDetectorGraph(
    id,
    start: Date,
    end: Date,
    lane?: number
  ): Observable<DetectorGraph> {
    const request = new GetDetectorGraphRequest();
    request.id = id;
    request.startTime = start.toISOString();
    request.endTime = end.toISOString();
    request.groupBySeconds = 3600;
    request.smooth = 300;
    request.lane = lane;
    return this._api.get(request);
  }
  getDetectorGraphBySecond(
    id,
    start: Date,
    end: Date,
    groupBySeconds: number,
    lane?: number
  ): Observable<DetectorGraph> {
    const request = new GetDetectorGraphRequest();
    request.id = id;
    request.startTime = start.toISOString();
    request.endTime = end.toISOString();
    request.groupBySeconds = groupBySeconds;
    request.smooth = 300;
    request.lane = lane;
    return this._api.get(request);
  }
  getDetectorAnalysisGraph(
    id,
    start: Date,
    end: Date
  ): Observable<DetectorGraphAnalysis> {
    const request = new GetDetectorGraphAnalysisRequest();
    request.id = id;
    request.startTime = start.toISOString();
    request.endTime = end.toISOString();
    request.groupBySeconds = 900;
    request.smooth = 300;
    return this._api.get(request);
  }
  getDetectorInternalGraph(
    id,
    from: Date,
    to: Date
  ): Observable<DetectorInternalGraph> {
    const request = new GetDetectorInternalGraphRequest();
    request.id = id;
    request.startTime = from.toISOString();
    request.endTime = to.toISOString();
    request.groupBySeconds = 900;
    request.smooth = 300;
    return this._api.get(request);
  }
  getDetectorImage(id: string): Observable<Uint8Array> {
    const request = new GetDetectorImage();
    request.id = id;
    request.forceUpdate = true;
    return this._api.get(request);
  }
  getTestImages(id, force, showAreas) {
    // const url = `http://krsk.asudd24.ru:8088/device/${id}/image?forceUpdate=${force}&t=${Date.now()}`;
    const url = `${this._appEnv.modules.tfms.apiUrl}/json/reply/GetDetectorImage?id=${id}&ForceUpdate=${force}&ShowAreas=${showAreas}`;

    const headers = new Headers();
    headers.append("Authorization", `Bearer ${this._tokenService.bearerToken}`);
    const request = new Request(url, {
      method: "GET",
      headers: headers,
      mode: "cors",
      cache: "no-store",
    });
    return request;
  }

  getImagesTestRequest(
    id: string,
    force: boolean,
    showAreas: boolean
  ): Observable<Uint8Array> {
    const request = new GetDetectorImage({
      id,
      forceUpdate: force,
      showAreas,
    });
    return this._api.get(request).pipe((res) => {
      // console.log(res);
      return res;
    });
  }

  getExportFile(id, from: Date, to: Date) {
    const request = new GetDetectorGraphArchiveRequest();
    request.id = id;
    request.startTime = from.toISOString();
    request.endTime = to.toISOString();
    request.groupBySeconds = 900;
    request.smooth = 300;
    request.type = GraphDataType.Json;
    return this._api.get(request);
    // const url = `http://krsk.asudd24.ru:8088/device/${id}/image?forceUpdate=${force}&t=${Date.now()}`;
    // const url = `https://test-its-tfms.a.msu24.ru/json/reply/GetDetectorImage?id=${id}&ForceUpdate=${force}`;

    // const headers = new Headers();
    // headers.append('Authorization', `Bearer ${this._tokenService.bearerToken}`);
    // const request = new Request(url, {
    //   method: 'GET',
    //   headers: headers,
    //   mode: 'cors',
    //   cache: 'no-store',
    // });
    // return request;
  }
  getDetectorDevicesRequest(
    filter: string,
    CustomerId: string,
    items?: number
  ) {
    const request = new GetDetectorDevicesRequest();
    request.customerId = CustomerId;
    request.page = 0;
    request.items = items;
    request.sort = "number";
    request.filter = filter;
    return this._api.get(request).pipe(map((m) => m.items));
  }
  setDetectorReplacementRequest(
    customerId: string,
    entity: DetectorReplacement
  ) {
    const request = new SetDetectorReplacementRequest();
    request.customerId = customerId;
    request.entity = entity;
    request.entity.customerId = customerId;
    request.setDetectorCoordsToDevice = false;
    // request.entity.detectorDeviceId = deviceId;
    return this._api.put(request);
  }
  exist(name: string) {
    const request = new GetDetectorExistRequest();
    request.name = name;
    return this._api.get(request);
  }
  getDetectorInfo(customerId: string, id: string) {
    const request = new GetDetectorInfoRequest();
    request.id = id;
    request.customerId = customerId;
    return this._api.get(request);
  }

  changeCustomer(
    id: string,
    customerId: string,
    params: { newCustomerId: string; description: string }
  ) {
    const request = new DetectorChangeCustomerRequest();
    request.id = id;
    request.customerId = customerId;
    request.description = params.description;
    request.newCustomerId = params.newCustomerId;
    return this._api.post(request);
  }

  getPermissions(id: string, customerId: string) {
    const request = new GetDetectorCurrentUserPermissionsRequest();
    request.id = id;
    request.customerId = customerId;
    return this._api.get(request);
  }

  getLanes(id: string, customerId: string) {
    const request = new GetDetectorLanesRequest();
    request.id = id;
    return this._api.get(request, { customerId });
  }

  getParamsUrl(paramName: string, customerId: string) {
    return `${this._api.apiUrl}/customer/${customerId}/tableParams/${paramName}`;
  }
  uploadParams(file, paramName: string, customerId: string) {
    return this._api.upload(file, this.getParamsUrl(paramName, customerId));
  }
}
