import { Injectable } from '@angular/core';

import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import {
  IDataSourceService,
  setPageParams,
  getPageReturn,
  IPageParams,
  IPageReturn,
} from 'projects/msu-its-web-common/src/data/data-source';

import {
  CreateDeviceRequest,
  DeleteDevicesRequest,
  Device,
  DeviceInfo,
  DisconnectDeviceRequest,
  GetDeviceRequest,
  GetDevicesRequest,
  GetDeviceTypesRequest,
  UpdateDeviceRequest,
} from '../dtos/telematic.dtos';

import { TelematicApiService } from './telematic-api.service';

@Injectable()
export class DeviceService implements IDataSourceService<Device, DeviceInfo> {
  constructor(private _api: TelematicApiService) {}

  getPagedList(params: IPageParams, customerId: string): Observable<IPageReturn<DeviceInfo>> {
    if (!customerId) {
      return of(null);
    }

    const request = new GetDevicesRequest();
    request.customerId = customerId;

    setPageParams(request, params);
    return this._api.get(request).pipe(
      map((m) => {
        return getPageReturn(m);
      })
    );
  }

  getList(customerId: string, filter?: string): Observable<DeviceInfo[]> {
    const request = new GetDevicesRequest();
    request.customerId = customerId;

    request.page = 0;
    request.items = 0;
    request.sort = 'name';
    request.filter = filter;

    return this._api.get(request).pipe(
      map((m) => {
        return m.items;
      })
    );
  }

  get(id: any, customerId: string): Observable<Device> {
    const request = new GetDeviceRequest();
    request.id = id;
    return this._api.get(request, { customerId });
  }

  add(
    device: Device,
    params: {
      customerId: string;
    }
  ): Observable<Device> {
    const request = new CreateDeviceRequest();
    request.customerId = params.customerId;
    request.entity = device;
    return this._api.post(request);
  }

  update(
    device: Device,
    params: {
      customerId: string;
    }
  ): Observable<Device> {
    const request = new UpdateDeviceRequest();
    request.customerId = params.customerId;
    request.id = device.id;
    request.entity = device;
    return this._api.put(request);
  }

  delete(ids: string[], customerId: string): Observable<number> {
    const request = new DeleteDevicesRequest();
    request.customerId = customerId;
    request.ids = ids;
    return this._api.delete(request);
  }

  getTypes() {
    // const request = new GetDeviceTypesRequest();
    // // request.Sort = "Name";
    // return this._api.get(request).map((result) => {
    //   return result.Items.map((m) => {
    //     return {
    //       prefix: m.prefix,
    //       name: m.Name,
    //     };
    //   });
    // });
    const request = new GetDeviceTypesRequest();
    // request.Sort = "Name";
    return this._api.get(request);
  }

  exist(name: string, customerId: string) {
    // const request = new ExistDeviceNumberRequest();
    // request.name = name;
    // request.customerId = customerId;

    return false;
  }

  disconnectDevice(id: string, customerId: string) {
    const request = new DisconnectDeviceRequest();
    request.id = id;
    return this._api.post(request, { customerId });
  }
}
