<div fxLayout="column" fxLayoutGap="24px" style="min-width: 380px; position: relative">
  <div *ngIf="loading" class="backdrop" style="position: absolute; z-index: 6">
    <div class="backdrop-inner mat-dialog-bg mat-elevation-z4" style="padding-bottom: 16px">
      <mat-spinner diameter="36"></mat-spinner>
    </div>
  </div>

  <div *ngIf="noConnection" class="backdrop" style="position: absolute; z-index: 5; pointer-events: none">
    <div class="backdrop-inner mat-dialog-bg mat-elevation-z4" style="pointer-events: all; max-width: 350px">
      <div fxLayout fxLayoutGap="12px" style="margin-bottom: 12px; align-items: center">
        <mat-icon class="dialog-warn">warning</mat-icon>
        <div>{{ "COMMON.NO_CONNECTION" | translate }}</div>
      </div>
    </div>
  </div>

  <div class="state-container">
    <img #stateImage class="state-image" />
    <msu-pirs-state-controller></msu-pirs-state-controller>
  </div>
  <msu-pirs-state-spaces></msu-pirs-state-spaces>
</div>
