import { AfterViewInit, Directive, HostListener } from '@angular/core';
import { NgControl } from '@angular/forms';
import { phoneMask } from '../utils/phone';

@Directive({
  selector: '[phoneMask]',
})
export class PhoneMaskDirective implements AfterViewInit {
  constructor(public control: NgControl) {}

  ngAfterViewInit() {
    this.control.control.setValue(phoneMask(this.control.value));
  }

  @HostListener('ngModelChange', ['$event'])
  modelChange(event) {
    this.control.valueAccessor.writeValue(phoneMask(event));
  }

  @HostListener('keydown.backspace', ['$event'])
  backspace(event) {
    event.stopPropagation();
    this.control.control.setValue(phoneMask(event.target.value, false));
  }

  @HostListener('paste', ['$event'])
  paste(event) {
    event.preventDefault();
    event.stopPropagation();
    const data = event.clipboardData.getData('text');
    this.control.control.setValue(phoneMask(data));
  }
}
