<h1 mat-dialog-title>{{ "PARKINGS.LEGEND.TITLE" | translate }}</h1>
<div mat-dialog-content>
  <div fxLayout="column">
    <div
      fxFlex
      fxLayout="column"
      fxLayoutAlign="space-between stretch"
      fxLayoutGap="14px"
    >
      <div
        fxFlex
        fxLayout="row"
        fxLayoutAlign="start center"
        fxLayoutGap="12px"
      >
        <div
          fxFlex="60px"
          style="background-color: green; height: 25px; border-radius: 3px;"
        ></div>
        <span fxFlex>{{ "PARKINGS.LEGEND.SPACE_ON" | translate }}</span>
      </div>
      <div
        fxFlex
        fxLayout="row"
        fxLayoutAlign="start center"
        fxLayoutGap="12px"
      >
        <div
          fxFlex="60px"
          style="background-color: darkred; height: 25px; border-radius: 3px;"
        ></div>
        <span fxFlex>{{ "PARKINGS.LEGEND.SPACE_AVAILABLE" | translate }}</span>
      </div>
      <div
        fxFlex
        fxLayout="row"
        fxLayoutAlign="start center"
        fxLayoutGap="12px"
      >
        <div
          fxFlex="60px"
          style="background-color: red; height: 25px; border-radius: 3px;"
        ></div>
        <span fxFlex>{{ "PARKINGS.LEGEND.SPACE_DISCONNECT" | translate }}</span>
      </div>
      <div
        fxFlex
        fxLayout="row"
        fxLayoutAlign="start center"
        fxLayoutGap="12px"
      >
        <div
          fxFlex="60px"
          style="background-color: blue; height: 25px; border-radius: 3px;"
        ></div>
        <span fxFlex>{{ "PARKINGS.LEGEND.SPACE_ENGINEERING_MODE" | translate }}</span>
      </div>
      <div
        fxFlex
        fxLayout="row"
        fxLayoutAlign="start center"
        fxLayoutGap="12px"
      >
        <div
          fxFlex="60px"
          style="background-color: gray; height: 25px; border-radius: 3px;"
        ></div>
        <span fxFlex>{{ "PARKINGS.LEGEND.SPACE_OFF" | translate }}</span>
      </div>
    </div>
  </div>
</div>
<div mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>{{ "COMMON.CLOSE" | translate }}</button>
</div>
