import { marker as _ } from "@biesbjerg/ngx-translate-extract-marker";
import { NgModule } from "@angular/core";

import { ISidenavMenuCategory } from "projects/msu-its-web-common/src/utils/sidenav-menu";
import {
  GIS_OBJECT_GROUP,
  SIDENAV_MENU,
} from "projects/msu-its-web-common/src/utils/shared-consts";

import { TfmsApiService } from "projects/msu-its-web-tfms/src/services/tfms-api.service";
import { DetectorService } from "projects/msu-its-web-tfms/src/services/detector.service";
import { DetectorDeviceService } from "projects/msu-its-web-tfms/src/services/detector-device.service";
import { DetectorLogService } from "projects/msu-its-web-tfms/src/services/detector-log.service";
import { DetectorDeviceEventLogService } from "projects/msu-its-web-tfms/src/services/detector-device-event-log.service";
import { DetectorDeviceWorkModeService } from "projects/msu-its-web-tfms/src/services/detector-device-work-mode.service";
import { CameraService } from "projects/msu-its-web-tfms/src/services/camera.service";
import { DetectorGroupService } from "projects/msu-its-web-tfms/src/services/detector-group.service";
import { DetectorGroupShareService } from "projects/msu-its-web-tfms/src/services/detector-group-share.service";
import { CameraGroupService } from "projects/msu-its-web-tfms/src/services/camera-group.service";
import { CameraGroupShareService } from "projects/msu-its-web-tfms/src/services/camera-group-share.service";
import { IGisObjectGroup } from "projects/msu-its-web-common/src/utils/gis-object-group";
import { DetectorGroupEventService } from "projects/msu-its-web-tfms/src/services/detector-group-event.service";
import { CameraGroupEventService } from "projects/msu-its-web-tfms/src/services/camera-group-event.service";
import { CameraPTZService } from "projects/msu-its-web-tfms/src/services/camera-ptz.service";
import { CameraPTZGroupService } from "projects/msu-its-web-tfms/src/services/camera-ptz-group.service";
import { CameraPTZGroupEventService } from "projects/msu-its-web-tfms/src/services/camera-ptz-group-event.service";
import { CameraPTZGroupShareService } from "projects/msu-its-web-tfms/src/services/camera-ptz-group-share.service";
import { CameraPTZPopupComponent } from "../../components/cameras-ptz/camera-ptz-popup.component";
import { TfmsApiEventService } from "projects/msu-its-web-tfms/src/services/tfms-api-event.service";
import { DetectorDeviceIncidentsService } from "projects/msu-its-web-tfms/src/services/detector-device-incidents.service";
import { DetectorPopupComponent } from "../../components/detectors/detector-popup.component";
import { CameraPopupComponent } from "../../components/cameras/camera-popup.componen";
import { BaseModule } from "projects/msu-its-web-common/src/modules/base.module";
import { DetectorExternalComponent } from "../../components/external/detector-external.component";
import { CameraExternalComponent } from "../../components/external/camera-external.component";
import { CameraEditExternalComponent } from "../../components/external/camera-edit-external.component";
import { DetectorEditExternalComponent } from "../../components/external/detector-edit-external.component";
import { DetectorHistoryComponent } from "../../components/detectors/detector-history.component";
import { DetectorExportComponent } from "../../components/detectors/detector-export.component";
import { DetectorLogComponent } from "../../components/detectors/detector-log.component";
import { DetectorCompareComponent } from "../../components/detectors/detector-compare/detector-compare.component";
import { DetectorMaintenanceService } from "../../services/detector-maintenance.service";
import { DetectorMaintenanceTypeService } from "../../services/detector-maintenance-type.service";
import { DetectorIncidentsAnalysisService } from "../../services/detector-incidents-analysis.service";
import { ModuleIds } from "projects/msu-its-web-common/src/services/customer-state.service";
import { environment } from "projects/msu-its-web/src/environments/environment";
import { CameraPTZExternalComponent } from "../../components/external/camera-ptz-external.component";
import { CameraPTZEditExternalComponent } from "../../components/external/camera-ptz-edit-external.component";
import { CameraFTPService } from "../../services/camera-ftp/camera-ftp.service";
import { CameraFTPGroupService } from "../../services/camera-ftp/camera-ftp-group.service";
import { CameraFTPGroupShareService } from "../../services/camera-ftp/camera-ftp-group-share.service";
import { CameraFTPGroupEventService } from "../../services/camera-ftp/camera-ftp-group-event.service";
import { CameraFTPPopupComponent } from "../../components/cameras-ftp/camera-ftp-popup.component";

// Video Surveillance of Accidents and Emergencies (VSAE) module
const hasVsaeModule = (environment as any).aboutModules?.some(
  (m) => m.id === "Msu.Its.Vsae"
);

const sidenavVideoTitle = hasVsaeModule
  ? _("SIDENAV_MENU.VSAE")
  : _("SIDENAV_MENU.VIDEO");

const cameraGroupTitle = hasVsaeModule
  ? _("CAMERAS.TITLE_VSAE")
  : _("CAMERAS.TITLE");
const cameraPTZGroupTitle = _("CAMERAS_PTZ.TITLE");

const sidenav: ISidenavMenuCategory[] = [
  {
    name: _("SIDENAV_MENU.TFMS"),
    items: [
      {
        name: _("DETECTORS.TITLE"),
        icon: "camera",
        route: "detectors",
        permissions: ["detector.read"],
      },
      {
        name: _("DETECTOR_MAINTENANCE.TITLE"),
        icon: "report_problem",
        route: "incident",
        permissions: ["detectorMaintenance.read"],
        moduleSettings: [
          {
            moduleId: ModuleIds.tfms,
            setting: "detector.maintenance",
            value: true,
          },
        ],
      },
    ],
  },
  {
    name: sidenavVideoTitle, // _('SIDENAV_MENU.VIDEO'), // _("SIDENAV_MENU.VSAE"),
    items: [
      {
        name: _("CAMERAS.TITLE"),
        icon: "videocam",
        route: "cameras",
        permissions: ["camera.read"],
      },
      {
        name: _("CAMERAS_PTZ.TITLE"),
        icon: "switch_video",
        route: "cameras-ptz",
        permissions: ["ptzCamera.read"],
      },
    ],
  },
];

// (environment as any).system === "krudor" &&
//   sidenav[1].items.push({
//     name: _("CAMERAS_FTP.TITLE"),
//     icon: "video_call",
//     route: "cameras-ftp",
//     permissions: ["camera.read"],
//   });


const detectorGroup: IGisObjectGroup = {
  type: "detector",
  name: _("DETECTORS.TITLE"),
  groupServiceType: DetectorGroupService,
  eventServiceType: DetectorGroupEventService,
  popupComponentType: DetectorPopupComponent,
  // stateComponentType: DetectorPopupComponent,
};
const cameraGroup: IGisObjectGroup = {
  type: "camera",
  name: cameraGroupTitle,
  groupServiceType: CameraGroupService,
  eventServiceType: CameraGroupEventService,
  popupComponentType: CameraPopupComponent,
  // stateComponentType: CameraPopupComponent,
};
const cameraPTZGroup: IGisObjectGroup = {
  type: "ptzCamera",
  name: cameraPTZGroupTitle,
  groupServiceType: CameraPTZGroupService,
  eventServiceType: CameraPTZGroupEventService,
  popupComponentType: CameraPTZPopupComponent,
  // stateComponentType: CameraPopupComponent,
};
const cameraFTPGroup: IGisObjectGroup = {
  type: "ftpCamera",
  name: _("CAMERAS_FTP.TITLE"),
  groupServiceType: CameraFTPGroupService,
  eventServiceType: CameraFTPGroupEventService,
  popupComponentType: CameraFTPPopupComponent,
  // stateComponentType: CameraPopupComponent,
};

@NgModule({
  providers: [
    { provide: SIDENAV_MENU, useValue: sidenav, multi: true },
    { provide: GIS_OBJECT_GROUP, useValue: detectorGroup, multi: true },
    { provide: GIS_OBJECT_GROUP, useValue: cameraGroup, multi: true },
    { provide: GIS_OBJECT_GROUP, useValue: cameraPTZGroup, multi: true },
    // { provide: GIS_OBJECT_GROUP, useValue: cameraFTPGroup, multi: true },

    TfmsApiService,
    DetectorService,
    CameraService,
    DetectorGroupService,
    DetectorGroupShareService,
    CameraGroupService,
    CameraGroupShareService,
    CameraGroupEventService,
    CameraPTZService,
    CameraPTZGroupService,
    CameraPTZGroupShareService,
    CameraPTZGroupEventService,
    CameraFTPService,
    CameraFTPGroupService,
    CameraFTPGroupShareService,
    CameraFTPGroupEventService,
    DetectorGroupEventService,
    TfmsApiEventService,
    DetectorLogService,
    DetectorDeviceService,
    DetectorMaintenanceService,
    DetectorDeviceIncidentsService,
    DetectorMaintenanceTypeService,
    DetectorIncidentsAnalysisService,
    DetectorDeviceEventLogService,
    DetectorDeviceWorkModeService,
  ],
  imports: [BaseModule],
  declarations: [
    DetectorPopupComponent,
    CameraPopupComponent,
    CameraPTZPopupComponent,
    CameraFTPPopupComponent,
    DetectorCompareComponent,
    DetectorExternalComponent,
    CameraExternalComponent,
    CameraEditExternalComponent,
    CameraPTZExternalComponent,
    CameraPTZEditExternalComponent,
    DetectorEditExternalComponent,
    DetectorHistoryComponent,
    DetectorExportComponent,
    DetectorLogComponent,
  ],
  entryComponents: [
    DetectorPopupComponent,
    CameraPopupComponent,
    CameraPTZPopupComponent,
    CameraFTPPopupComponent,
    DetectorExternalComponent,
    CameraExternalComponent,
    CameraEditExternalComponent,
    CameraPTZExternalComponent,
    CameraPTZEditExternalComponent,
    DetectorEditExternalComponent,
    DetectorCompareComponent,
    DetectorHistoryComponent,
    DetectorExportComponent,
    DetectorLogComponent,
  ],
  exports: [
    DetectorPopupComponent,
    CameraPopupComponent,
    CameraPTZPopupComponent,
    CameraFTPPopupComponent,
    DetectorExternalComponent,
    CameraExternalComponent,
    CameraEditExternalComponent,
    CameraPTZExternalComponent,
    CameraPTZEditExternalComponent,
    DetectorEditExternalComponent,
    DetectorCompareComponent,
    DetectorHistoryComponent,
    DetectorExportComponent,
    DetectorLogComponent,
  ],
})
export class TfmsSharedModule {}
