import { Injectable } from "@angular/core";

import { Observable, of } from "rxjs";
import { map } from "rxjs/operators";

import {
  IDataSourceService,
  setPageParams,
  getPageReturn,
  IPageParams,
  IPageReturn,
} from "projects/msu-its-web-common/src/data/data-source";

import {
  CreateFixationDeviceRequest,
  DeleteFixationDevicesRequest,
  FixationDevice,
  FixationDeviceChangeCustomerRequest,
  FixationDeviceType,
  GetFixationDeviceExistRequest,
  GetFixationDeviceRequest,
  GetFixationDevicesRequest,
  UpdateFixationDeviceRequest,
} from "../../dtos/ftv.dtos";

import { FtvApiService } from "../ftv-api.service";

@Injectable()
export class FixationDeviceService
  implements IDataSourceService<FixationDevice, FixationDevice> {
  constructor(private _api: FtvApiService) {}

  getPagedList(
    params: IPageParams,
    customerId: string
  ): Observable<IPageReturn<FixationDevice>> {
    if (!customerId) {
      return of(null);
    }

    const request = new GetFixationDevicesRequest();
    request.customerId = customerId;

    setPageParams(request, params);
    return this._api.get(request).pipe(
      map((m) => {
        return getPageReturn(m);
      })
    );
  }
  getList(
    customerId: string,
    filter?: string,
    items?: number,
    groupIds?: string[],
    onlyUnused: boolean = false
  ): Observable<FixationDevice[]> {
    const request = new GetFixationDevicesRequest();
    request.customerId = customerId;
    request.entityGroups = groupIds || [];

    request.page = 0;
    request.onlyUnused = onlyUnused;
    request.items = items || 0;
    request.sort = "number";
    request.filter = filter || "";

    return this._api.get(request).pipe(
      map((m) => {
        return m.items;
      })
    );
  }

  get(id: string, customerId: string) {
    const request = new GetFixationDeviceRequest();
    request.id = id;
    request.customerId = customerId;
    return this._api.get(request);
  }
  add(
    model: FixationDevice,
    params: {
      customerId: string;
      groups: string[];
      updateGroups: boolean;
    }
  ) {
    const request = new CreateFixationDeviceRequest();
    request.entity = model;
    request.customerId = params.customerId;
    request.entityGroups = params.groups;
    request.updateEntityGroups = params.updateGroups;
    return this._api.post(request);
  }
  update(
    model: FixationDevice,
    params: { customerId: string; groups: string[]; updateGroups: boolean }
  ) {
    const request = new UpdateFixationDeviceRequest();
    request.id = model.id;
    request.entity = model;
    request.customerId = params.customerId;
    request.entityGroups = params.groups;
    request.updateEntityGroups = params.updateGroups;
    return this._api.put(request);
  }
  delete(ids: string[], customerId: string) {
    const request = new DeleteFixationDevicesRequest();
    request.ids = ids;
    request.customerId = customerId;
    return this._api.delete(request);
  }
  exist(
    prefix: string,
    number: string,
    customerId: string
  ): Observable<boolean> {
    const request = new GetFixationDeviceExistRequest({
      prefix,
      number,
      customerId,
    });
    return this._api.get(request);
  }

  changeCustomer(
    id: string,
    customerId: string,
    params: { newCustomerId: string; description: string }
  ) {
    const request = new FixationDeviceChangeCustomerRequest();
    request.id = id;
    request.customerId = customerId;
    request.description = params.description;
    request.newCustomerId = params.newCustomerId;
    return this._api.post(request);
  }
}
