/* Options:
Date: 2020-12-03 06:18:56
Version: 5.80
Tip: To override a DTO option, remove "//" prefix before updating
BaseUrl: https://test-its-telematic.a.msu24.ru:443

//GlobalNamespace: 
//AddServiceStackTypes: True
//AddResponseStatus: False
//AddImplicitVersion: 
//AddDescriptionAsComments: True
//IncludeTypes: 
//ExcludeTypes: 
//DefaultImports: 
*/

export interface IReturn<T> {
  createResponse(): T;
}

export interface IReturnVoid {
  createResponse(): void;
}

export interface IHasSessionId {
  sessionId: string;
}

export interface IHasBearerToken {
  bearerToken: string;
}

export interface IPost {}

// @DataContract
export enum DKMode {
  RU = 'RU',
  RP = 'RP',
  ZU = 'ZU',
  DU = 'DU',
  LU = 'LU',
  LRP = 'LRP',
  MGR = 'MGR',
  KU = 'KU',
  RKU = 'RKU',
}

// @DataContract
export class SessionRequestBase {
  // @DataMember
  public id: string;

  public constructor(init?: Partial<SessionRequestBase>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export enum PotokTrafficState {
  Off = 'Off',
  YellowBlink = 'YellowBlink',
  AllRed = 'AllRed',
  Phase = 'Phase',
}

// @DataContract
export enum PotokTrafficMode {
  Off = 'Off',
  Coordination = 'Coordination',
  Adaptive = 'Adaptive',
  Remote = 'Remote',
}

// @Flags()
// @DataContract
export enum PotokMovementType {
  Move1 = 'Move1',
  Move2 = 'Move2',
  Move3 = 'Move3',
  Move4 = 'Move4',
  Move5 = 'Move5',
  Move6 = 'Move6',
  Move7 = 'Move7',
  Move8 = 'Move8',
  Move12 = 'Move12',
  Move14 = 'Move14',
  Move16 = 'Move16',
  Move18 = 'Move18',
  MoveP2 = 'MoveP2',
  MoveP4 = 'MoveP4',
  MoveP6 = 'MoveP6',
  MoveP8 = 'MoveP8',
}

// @DataContract
export enum PotokPlanType {
  None = 'None',
  PhaseCycle = 'PhaseCycle',
  YellowBlink = 'YellowBlink',
  Off = 'Off',
}

// @DataContract
export class PotokPlan implements IEnabled {
  // @DataMember(Order=1)
  public id: number;

  // @DataMember(Order=2)
  public name: string;

  // @DataMember(Order=3)
  public type: PotokPlanType;

  // @DataMember(Order=4)
  public offset: number;

  // @DataMember(Order=5)
  public length: number;

  // @DataMember(Order=6)
  public enabled: boolean;

  // @DataMember(Order=7)
  public transportBarriers: PotokBarrier[];

  // @DataMember(Order=8)
  public pedestrianBarriers: PotokBarrier[];

  // @DataMember(Order=9)
  public pedestrianPhasesEnabled: boolean;

  public constructor(init?: Partial<PotokPlan>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class PotokDayTime {
  // @DataMember(Order=1)
  public planId: number;

  // @DataMember(Order=2)
  public time: string;

  public constructor(init?: Partial<PotokDayTime>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class PotokScheduleDay {
  // @DataMember(Order=1)
  public id: number;

  // @DataMember(Order=2)
  public times: PotokDayTime[];

  public constructor(init?: Partial<PotokScheduleDay>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class PotokScheduleWeek {
  // @DataMember(Order=0)
  public id: number;

  // @DataMember(Order=1)
  public monday: number;

  // @DataMember(Order=2)
  public tuesday: number;

  // @DataMember(Order=3)
  public wednesday: number;

  // @DataMember(Order=4)
  public thursday: number;

  // @DataMember(Order=5)
  public friday: number;

  // @DataMember(Order=6)
  public saturday: number;

  // @DataMember(Order=7)
  public sunday: number;

  public constructor(init?: Partial<PotokScheduleWeek>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class PotokScheduleYear {
  // @DataMember(Order=0)
  public january: number[];

  // @DataMember(Order=1)
  public february: number[];

  // @DataMember(Order=2)
  public march: number[];

  // @DataMember(Order=3)
  public april: number[];

  // @DataMember(Order=4)
  public may: number[];

  // @DataMember(Order=5)
  public june: number[];

  // @DataMember(Order=6)
  public july: number[];

  // @DataMember(Order=7)
  public august: number[];

  // @DataMember(Order=8)
  public september: number[];

  // @DataMember(Order=9)
  public october: number[];

  // @DataMember(Order=10)
  public november: number[];

  // @DataMember(Order=11)
  public december: number[];

  public constructor(init?: Partial<PotokScheduleYear>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class PotokSchedule {
  // @DataMember(Order=0)
  public days: PotokScheduleDay[];

  // @DataMember(Order=1)
  public weeks: PotokScheduleWeek[];

  // @DataMember(Order=2)
  public year: PotokScheduleYear;

  public constructor(init?: Partial<PotokSchedule>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class EntityGroupInfo
  implements
    IEntity,
    IEntityTyped,
    INamedEntity,
    INamed,
    ICustomerEntityViewModel,
    ICustomerName,
    ICustomerEntity {
  // @DataMember
  public customerId: string;

  // @DataMember
  public customerName: string;

  // @DataMember
  public name: string;

  // @DataMember
  public description: string;

  // @DataMember
  public id: string;

  // @DataMember
  public entityType: string;

  public constructor(init?: Partial<EntityGroupInfo>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class GetEntityGroupsResponse {
  // @DataMember
  public items: EntityGroupInfo[];

  // @DataMember
  public total: number;

  // @DataMember
  public page: number;

  // @DataMember
  public itemsPerPage: number;

  public constructor(init?: Partial<GetEntityGroupsResponse>) {
    (Object as any).assign(this, init);
  }
}

export interface ICustomerItemRequest extends ICustomerEntity {}

export interface ICustomerEntity {
  // @DataMember
  customerId: string;
}

// @DataContract
export class EntityGroup
  implements
    IEntity,
    IEntityTyped,
    INamedEntity,
    INamed,
    ICustomerEntityViewModel,
    ICustomerName,
    ICustomerEntity {
  // @DataMember
  public items: string[];

  // @DataMember
  public customerId: string;

  // @DataMember
  public customerName: string;

  // @DataMember
  public name: string;

  // @DataMember
  public description: string;

  // @DataMember
  public id: string;

  // @DataMember
  public entityType: string;

  public constructor(init?: Partial<EntityGroup>) {
    (Object as any).assign(this, init);
  }
}

export interface IBaseEntityItemRequest {
  id: string;
}

export class CustomerNamedBaseEntityViewModelBase
  implements
    IEntity,
    IEntityTyped,
    INamedEntity,
    INamed,
    ICustomerEntityViewModel,
    ICustomerName,
    ICustomerEntity {
  // @DataMember
  public customerId: string;

  // @DataMember
  public customerName: string;

  // @DataMember
  public name: string;

  // @DataMember
  public description: string;

  // @DataMember
  public id: string;

  // @DataMember
  public entityType: string;

  public constructor(init?: Partial<CustomerNamedBaseEntityViewModelBase>) {
    (Object as any).assign(this, init);
  }
}

export interface IEntity extends IEntityTyped {}

export interface IEntityTyped {
  // @DataMember
  entityType: string;
}

export interface INamedEntity extends IEntity, IEntityTyped, INamed {}

export interface INamed {
  // @DataMember
  name: string;
}

export interface ICustomerEntityViewModel extends ICustomerName, ICustomerEntity {}

export interface ICustomerName {
  // @DataMember
  customerName: string;
}

export interface IDescription {
  // @DataMember
  description: string;
}

// @Flags()
// @DataContract
export enum DeviceTypeEnum {
  None = 'None',
  Controller = 'Controller',
  Detector = 'Detector',
  Camera = 'Camera',
  Tracker = 'Tracker',
  All = 'All',
}

// @DataContract
export class DeviceInfo
  implements IEntity, IEntityTyped, ICustomerEntityViewModel, ICustomerName, ICustomerEntity {
  // @DataMember
  public prefix: string;

  // @DataMember
  public number: string;

  // @DataMember
  public phone1: string;

  // @DataMember
  public phone2: string;

  // @DataMember
  public imei: string;

  // @DataMember
  public lat: number;

  // @DataMember
  public lon: number;

  // @DataMember
  public useStartDate: string;

  // @DataMember
  public useEndDate: string;

  // @DataMember
  public direction: number;

  // @DataMember
  public modified: string;

  // @DataMember
  public customerId: string;

  // @DataMember
  public customerName: string;

  // @DataMember
  public description: string;

  // @DataMember
  public id: string;

  // @DataMember
  public entityType: string;

  public constructor(init?: Partial<DeviceInfo>) {
    (Object as any).assign(this, init);
  }
}

export class CustomerBaseEntityViewModelBase
  implements IEntity, IEntityTyped, ICustomerEntityViewModel, ICustomerName, ICustomerEntity {
  // @DataMember
  public customerId: string;

  // @DataMember
  public customerName: string;

  // @DataMember
  public description: string;

  // @DataMember
  public id: string;

  // @DataMember
  public entityType: string;

  public constructor(init?: Partial<CustomerBaseEntityViewModelBase>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class DeviceType implements IEntity, IEntityTyped {
  // @DataMember
  public prefix: string;

  // @DataMember
  public name: string;

  // @DataMember
  public type: DeviceTypeEnum;

  // @DataMember
  public description: string;

  // @DataMember
  public id: string;

  // @DataMember
  public entityType: string;

  public constructor(init?: Partial<DeviceType>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class SessionInfo implements IEntityTyped {
  // @DataMember
  public id: string;

  // @DataMember
  public lastReceived: string;

  // @DataMember
  public lastSent: string;

  // @DataMember
  public sessionId: string;

  // @DataMember
  public connectionTime: string;

  // @DataMember
  public receiverHost: string;

  // @DataMember
  public remoteAddress: string;

  // @DataMember
  public connected: boolean;

  // @DataMember
  public entityType: string;

  public constructor(init?: Partial<SessionInfo>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class ResponseError {
  // @DataMember(Order=1, EmitDefaultValue=false)
  public errorCode: string;

  // @DataMember(Order=2, EmitDefaultValue=false)
  public fieldName: string;

  // @DataMember(Order=3, EmitDefaultValue=false)
  public message: string;

  // @DataMember(Order=4, EmitDefaultValue=false)
  public meta: { [index: string]: string };

  public constructor(init?: Partial<ResponseError>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class ResponseStatus {
  // @DataMember(Order=1)
  public errorCode: string;

  // @DataMember(Order=2)
  public message: string;

  // @DataMember(Order=3)
  public stackTrace: string;

  // @DataMember(Order=4)
  public errors: ResponseError[];

  // @DataMember(Order=5)
  public meta: { [index: string]: string };

  public constructor(init?: Partial<ResponseStatus>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export enum PotokBarrierType {
  MajorStreet = 'MajorStreet',
  MinorStreet = 'MinorStreet',
  Static = 'Static',
}

// @DataContract
export class PotokRingStraightMovement {
  // @DataMember(Order=0)
  public enabled: boolean;

  // @DataMember(Order=1)
  public length: number;

  // @DataMember(Order=2)
  public straightMovement: boolean;

  // @DataMember(Order=3)
  public pedestrianMovement: boolean;

  // @DataMember(Order=4)
  public deadEndPedestrianMovement: boolean;

  // @DataMember(Order=5)
  public pedestrianMovementLength: number;

  // @DataMember(Order=6)
  public firstMovement: boolean;

  // @DataMember(Order=7)
  public rightMovement: boolean;

  // @DataMember(Order=8)
  public rightMovementPedestrianSeparated: boolean;

  public constructor(init?: Partial<PotokRingStraightMovement>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class PotokRingLeftMovement {
  // @DataMember(Order=0)
  public enabled: boolean;

  // @DataMember(Order=1)
  public length: number;

  // @DataMember(Order=2)
  public overlapped: boolean;

  public constructor(init?: Partial<PotokRingLeftMovement>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class PotokBarrierRing {
  // @DataMember(Order=1)
  public straightMovement: PotokRingStraightMovement;

  // @DataMember(Order=2)
  public leftMovement: PotokRingLeftMovement;

  public constructor(init?: Partial<PotokBarrierRing>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class PotokBarrier {
  // @DataMember(Order=0)
  public type: PotokBarrierType;

  // @DataMember(Order=1)
  public length: number;

  // @DataMember(Order=2)
  public ring1: PotokBarrierRing;

  // @DataMember(Order=3)
  public ring2: PotokBarrierRing;

  // @DataMember(Order=4)
  public staticMovements: PotokMovementType[];

  public constructor(init?: Partial<PotokBarrier>) {
    (Object as any).assign(this, init);
  }
}

export interface IEnabled {
  enabled: boolean;
}

// @DataContract
export class Device
  implements IEntity, IEntityTyped, ICustomerEntityViewModel, ICustomerName, ICustomerEntity {
  // @DataMember
  public prefix: string;

  // @DataMember
  public number: string;

  // @DataMember
  public phone1: string;

  // @DataMember
  public phone2: string;

  // @DataMember
  public imei: string;

  // @DataMember
  public lat: number;

  // @DataMember
  public lon: number;

  // @DataMember
  public useStartDate: string;

  // @DataMember
  public useEndDate: string;

  // @DataMember
  public direction: number;

  // @DataMember
  public modified: string;

  // @DataMember
  public customerId: string;

  // @DataMember
  public customerName: string;

  // @DataMember
  public description: string;

  // @DataMember
  public id: string;

  // @DataMember
  public entityType: string;

  public constructor(init?: Partial<Device>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class GetAllDevicesResponse {
  // @DataMember
  public devices: Device[];

  // @DataMember
  public changeKey: string;

  // @DataMember
  public items: DeviceInfo[];

  // @DataMember
  public total: number;

  // @DataMember
  public page: number;

  // @DataMember
  public itemsPerPage: number;

  public constructor(init?: Partial<GetAllDevicesResponse>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class GetDevicesResponse {
  // @DataMember
  public items: DeviceInfo[];

  // @DataMember
  public total: number;

  // @DataMember
  public page: number;

  // @DataMember
  public itemsPerPage: number;

  public constructor(init?: Partial<GetDevicesResponse>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class GetDeviceSessionsResponse {
  // @DataMember
  public items: SessionInfo[];

  // @DataMember
  public total: number;

  // @DataMember
  public page: number;

  // @DataMember
  public itemsPerPage: number;

  public constructor(init?: Partial<GetDeviceSessionsResponse>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class AuthenticateResponse implements IHasSessionId, IHasBearerToken {
  // @DataMember(Order=1)
  public userId: string;

  // @DataMember(Order=2)
  public sessionId: string;

  // @DataMember(Order=3)
  public userName: string;

  // @DataMember(Order=4)
  public displayName: string;

  // @DataMember(Order=5)
  public referrerUrl: string;

  // @DataMember(Order=6)
  public bearerToken: string;

  // @DataMember(Order=7)
  public refreshToken: string;

  // @DataMember(Order=8)
  public profileUrl: string;

  // @DataMember(Order=9)
  public roles: string[];

  // @DataMember(Order=10)
  public permissions: string[];

  // @DataMember(Order=11)
  public responseStatus: ResponseStatus;

  // @DataMember(Order=12)
  public meta: { [index: string]: string };

  public constructor(init?: Partial<AuthenticateResponse>) {
    (Object as any).assign(this, init);
  }
}

// @Route("/telematic/server/sessions/{Id}/command/USDKSetMode", "POST")
// @DataContract
export class UsdkSetModeRequest implements IReturn<boolean> {
  // @DataMember
  public mode: DKMode;

  // @DataMember
  public phase: number;

  // @DataMember
  public id: string;

  public constructor(init?: Partial<UsdkSetModeRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return false;
  }
  public getTypeName() {
    return 'UsdkSetModeRequest';
  }
}

// @Route("/telematic/server/sessions/{Id}/command/USDKUploadBindings", "POST")
// @DataContract
export class UsdkUploadBindingRequest implements IReturn<boolean> {
  // @DataMember
  public bindings: string[];

  // @DataMember
  public forceRewrite: boolean;

  // @DataMember
  public id: string;

  public constructor(init?: Partial<UsdkUploadBindingRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return false;
  }
  public getTypeName() {
    return 'UsdkUploadBindingRequest';
  }
}

// @Route("/telematic/server/sessions/{Id}/command/QueryState", "POST")
// @DataContract
export class QueryStateRequest implements IReturn<boolean> {
  // @DataMember
  public id: string;

  public constructor(init?: Partial<QueryStateRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return false;
  }
  public getTypeName() {
    return 'QueryStateRequest';
  }
}

// @Route("/telematic/server/sessions/{Id}/command/USDKCheckOnline", "POST")
// @DataContract
export class UsdkCheckOnlineRequest implements IReturn<boolean> {
  // @DataMember
  public enableLog: boolean;

  // @DataMember
  public id: string;

  public constructor(init?: Partial<UsdkCheckOnlineRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return false;
  }
  public getTypeName() {
    return 'UsdkCheckOnlineRequest';
  }
}

// @Route("/telematic/server/sessions/{Id}/command/potokGetStatus", "POST")
// @DataContract
export class PotokGetStatusRequest implements IReturn<boolean> {
  // @DataMember
  public id: string;

  public constructor(init?: Partial<PotokGetStatusRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return false;
  }
  public getTypeName() {
    return 'PotokGetStatusRequest';
  }
}

// @Route("/telematic/server/sessions/{Id}/command/potokSetMode", "POST")
// @DataContract
export class PotokSetModeCommandRequest implements IReturn<boolean> {
  // @DataMember
  public controllerState: PotokTrafficState;

  // @DataMember
  public controllerMode: PotokTrafficMode;

  // @DataMember
  public movements: PotokMovementType[];

  // @DataMember
  public modeTime: number;

  // @DataMember
  public id: string;

  public constructor(init?: Partial<PotokSetModeCommandRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return false;
  }
  public getTypeName() {
    return 'PotokSetModeCommandRequest';
  }
}

// @Route("/telematic/server/sessions/{Id}/command/potokSetAdaptiveMode", "POST")
// @DataContract
export class PotokSetAdaptiveModeCommandRequest implements IReturn<boolean> {
  // @DataMember
  public modeTime: number;

  // @DataMember
  public adaptivePlan: PotokPlan;

  // @DataMember
  public planScheduleLocalStartTime: string;

  // @DataMember
  public id: string;

  public constructor(init?: Partial<PotokSetAdaptiveModeCommandRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return false;
  }
  public getTypeName() {
    return 'PotokSetAdaptiveModeCommandRequest';
  }
}

// @Route("/telematic/server/sessions/{Id}/command/potokUploadSettings", "POST")
// @DataContract
export class PotokUploadSettingsRequest implements IReturn<boolean> {
  // @DataMember
  public newPlans: PotokPlan[];

  // @DataMember
  public newSchedule: PotokSchedule;

  // @DataMember
  public id: string;

  public constructor(init?: Partial<PotokUploadSettingsRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return false;
  }
  public getTypeName() {
    return 'PotokUploadSettingsRequest';
  }
}

// @Route("/telematic/server/sessions/{Id}/command/OpticUpdateImage", "POST")
// @DataContract
export class OpticUpdateImageRequest implements IReturn<boolean> {
  // @DataMember
  public quality: number;

  // @DataMember
  public id: string;

  public constructor(init?: Partial<OpticUpdateImageRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return false;
  }
  public getTypeName() {
    return 'OpticUpdateImageRequest';
  }
}

// @Route("/customer/{customerId}/devices/groups", "GET")
// @DataContract
export class GetDeviceGroupsRequest
  implements IReturn<GetEntityGroupsResponse>, ICustomerItemRequest, ICustomerEntity {
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public entityGroups: string[];

  // @DataMember
  public onlyOwnedItems: boolean;

  // @DataMember
  public page: number;

  // @DataMember
  public items: number;

  // @DataMember
  public sort: string;

  // @DataMember
  public filter: string;

  public constructor(init?: Partial<GetDeviceGroupsRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new GetEntityGroupsResponse();
  }
  public getTypeName() {
    return 'GetDeviceGroupsRequest';
  }
}

// @Route("/customer/{customerId}/devices/groups/{id}", "GET")
// @DataContract
export class GetDeviceGroupRequest
  implements IReturn<EntityGroup>, IBaseEntityItemRequest, ICustomerItemRequest, ICustomerEntity {
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember(IsRequired=true)
  public id: string;

  public constructor(init?: Partial<GetDeviceGroupRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new EntityGroup();
  }
  public getTypeName() {
    return 'GetDeviceGroupRequest';
  }
}

// @Route("/customer/{customerId}/devices/groups", "POST")
// @DataContract
export class CreateDeviceGroupRequest
  implements IReturn<EntityGroup>, IDescription, ICustomerItemRequest, ICustomerEntity {
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public entityGroups: string[];

  // @DataMember(IsRequired=true)
  public entity: EntityGroup;

  // @DataMember
  public description: string;

  public constructor(init?: Partial<CreateDeviceGroupRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new EntityGroup();
  }
  public getTypeName() {
    return 'CreateDeviceGroupRequest';
  }
}

// @Route("/customer/{customerId}/devices/groups/{id}", "PUT,POST")
// @DataContract
export class UpdateDeviceGroupRequest
  implements
    IReturn<EntityGroup>,
    IDescription,
    IBaseEntityItemRequest,
    ICustomerItemRequest,
    ICustomerEntity {
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember(IsRequired=true)
  public id: string;

  // @DataMember(IsRequired=true)
  public entity: EntityGroup;

  // @DataMember
  public description: string;

  public constructor(init?: Partial<UpdateDeviceGroupRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new EntityGroup();
  }
  public getTypeName() {
    return 'UpdateDeviceGroupRequest';
  }
}

// @Route("/customer/{customerId}/devices/groups", "DELETE")
// @DataContract
export class DeleteDeviceGroupsRequest
  implements IReturn<number>, IDescription, ICustomerItemRequest, ICustomerEntity {
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public description: string;

  // @DataMember(IsRequired=true)
  public ids: string[];

  public constructor(init?: Partial<DeleteDeviceGroupsRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return 0;
  }
  public getTypeName() {
    return 'DeleteDeviceGroupsRequest';
  }
}

// @Route("/devices", "GET")
// @DataContract
export class GetAllDevicesRequest implements IReturn<GetAllDevicesResponse> {
  public constructor(init?: Partial<GetAllDevicesRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new GetAllDevicesResponse();
  }
  public getTypeName() {
    return 'GetAllDevicesRequest';
  }
}

// @Route("/devices/changeKey", "GET")
// @DataContract
export class GetDevicesChangeKeyRequest implements IReturn<string> {
  public constructor(init?: Partial<GetDevicesChangeKeyRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return '';
  }
  public getTypeName() {
    return 'GetDevicesChangeKeyRequest';
  }
}

// @Route("/customer/{customerId}/devices", "GET")
// @DataContract
export class GetDevicesRequest
  implements IReturn<GetDevicesResponse>, ICustomerItemRequest, ICustomerEntity {
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public entityGroups: string[];

  // @DataMember
  public onlyOwnedItems: boolean;

  // @DataMember
  public page: number;

  // @DataMember
  public items: number;

  // @DataMember
  public sort: string;

  // @DataMember
  public filter: string;

  public constructor(init?: Partial<GetDevicesRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new GetDevicesResponse();
  }
  public getTypeName() {
    return 'GetDevicesRequest';
  }
}

// @Route("/customer/{customerId}/device/{id}", "GET")
// @DataContract
export class GetDeviceRequest
  implements IReturn<Device>, IBaseEntityItemRequest, ICustomerItemRequest, ICustomerEntity {
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember(IsRequired=true)
  public id: string;

  public constructor(init?: Partial<GetDeviceRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new Device();
  }
  public getTypeName() {
    return 'GetDeviceRequest';
  }
}

// @Route("/customer/{customerId}/device", "POST")
// @DataContract
export class CreateDeviceRequest
  implements IReturn<Device>, IDescription, ICustomerItemRequest, ICustomerEntity {
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public entityGroups: string[];

  // @DataMember(IsRequired=true)
  public entity: Device;

  // @DataMember
  public description: string;

  public constructor(init?: Partial<CreateDeviceRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new Device();
  }
  public getTypeName() {
    return 'CreateDeviceRequest';
  }
}

// @Route("/customer/{customerId}/device/{id}", "PUT")
// @DataContract
export class UpdateDeviceRequest
  implements
    IReturn<Device>,
    IDescription,
    IBaseEntityItemRequest,
    ICustomerItemRequest,
    ICustomerEntity {
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember(IsRequired=true)
  public id: string;

  // @DataMember(IsRequired=true)
  public entity: Device;

  // @DataMember
  public description: string;

  public constructor(init?: Partial<UpdateDeviceRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new Device();
  }
  public getTypeName() {
    return 'UpdateDeviceRequest';
  }
}

// @Route("/customer/{customerId}/devices", "DELETE")
// @DataContract
export class DeleteDevicesRequest
  implements IReturn<number>, IDescription, ICustomerItemRequest, ICustomerEntity {
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public description: string;

  // @DataMember(IsRequired=true)
  public ids: string[];

  public constructor(init?: Partial<DeleteDevicesRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return 0;
  }
  public getTypeName() {
    return 'DeleteDevicesRequest';
  }
}

// @Route("/deviceTypes", "GET")
// @DataContract
export class GetDeviceTypesRequest implements IReturn<DeviceType[]> {
  public constructor(init?: Partial<GetDeviceTypesRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new Array<DeviceType>();
  }
  public getTypeName() {
    return 'GetDeviceTypesRequest';
  }
}

// @Route("/telematic/server/sessions/{Id}/command/Disconnect", "POST")
// @DataContract
export class DisconnectDeviceRequest implements IReturn<Object> {
  // @DataMember
  public id: string;

  public constructor(init?: Partial<DisconnectDeviceRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new Object();
  }
  public getTypeName() {
    return 'DisconnectDeviceRequest';
  }
}

// @Route("/telematic/server/sessions", "GET, POST")
// @DataContract
export class GetDeviceSessionsRequest
  implements IReturn<GetDeviceSessionsResponse>, ICustomerItemRequest, ICustomerEntity {
  // @DataMember
  public onlyOnline: boolean;

  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public entityGroups: string[];

  // @DataMember
  public onlyOwnedItems: boolean;

  // @DataMember
  public page: number;

  // @DataMember
  public items: number;

  // @DataMember
  public sort: string;

  // @DataMember
  public filter: string;

  public constructor(init?: Partial<GetDeviceSessionsRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new GetDeviceSessionsResponse();
  }
  public getTypeName() {
    return 'GetDeviceSessionsRequest';
  }
}

// @Route("/customer/{customerId}/entity/{id}/properties", "GET")
// @DataContract
export class GetEntityPropertiesRequest
  implements IReturn<any>, IBaseEntityItemRequest, ICustomerItemRequest, ICustomerEntity {
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember(IsRequired=true)
  public id: string;

  public constructor(init?: Partial<GetEntityPropertiesRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return {};
  }
  public getTypeName() {
    return 'GetEntityPropertiesRequest';
  }
}

// @Route("/customer/{customerId}/entity/{id}/properties", "PUT")
// @DataContract
export class UpdateEntityPropertiesRequest
  implements IReturn<any>, IDescription, ICustomerItemRequest, ICustomerEntity {
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember(IsRequired=true)
  public id: string;

  // @DataMember(IsRequired=true)
  public entity: any;

  // @DataMember
  public description: string;

  public constructor(init?: Partial<UpdateEntityPropertiesRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return {};
  }
  public getTypeName() {
    return 'UpdateEntityPropertiesRequest';
  }
}

// @Route("/customer/{customerId}/entity/{id}/property/{property}", "GET")
// @DataContract
export class GetEntityPropertyRequest
  implements IReturn<string>, ICustomerItemRequest, ICustomerEntity {
  // @DataMember
  public property: string;

  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember(IsRequired=true)
  public id: string;

  public constructor(init?: Partial<GetEntityPropertyRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return '';
  }
  public getTypeName() {
    return 'GetEntityPropertyRequest';
  }
}

// @Route("/customer/{customerId}/entity/{id}/property/{property}", "PUT")
// @DataContract
export class UpdateEntityPropertyRequest
  implements IReturn<string>, IDescription, ICustomerItemRequest, ICustomerEntity {
  // @DataMember
  public property: string;

  // @DataMember
  public value: string;

  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember(IsRequired=true)
  public id: string;

  // @DataMember(IsRequired=true)
  public entity: string;

  // @DataMember
  public description: string;

  public constructor(init?: Partial<UpdateEntityPropertyRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return '';
  }
  public getTypeName() {
    return 'UpdateEntityPropertyRequest';
  }
}

// @Route("/customer/{customerId}/entities/property/{property}", "GET")
// @DataContract
export class GetEntitiesPropertyRequest
  implements IReturn<any>, ICustomerItemRequest, ICustomerEntity {
  // @DataMember
  public property: string;

  // @DataMember
  public filter: string;

  // @DataMember
  public customerId: string;

  public constructor(init?: Partial<GetEntitiesPropertyRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return {};
  }
  public getTypeName() {
    return 'GetEntitiesPropertyRequest';
  }
}

// @Route("/auth")
// @Route("/auth/{provider}")
// @DataContract
export class Authenticate implements IReturn<AuthenticateResponse>, IPost {
  // @DataMember(Order=1)
  public provider: string;

  // @DataMember(Order=2)
  public state: string;

  // @DataMember(Order=3)
  public oauth_token: string;

  // @DataMember(Order=4)
  public oauth_verifier: string;

  // @DataMember(Order=5)
  public userName: string;

  // @DataMember(Order=6)
  public password: string;

  // @DataMember(Order=7)
  public rememberMe: boolean;

  // @DataMember(Order=8)
  public continue: string;

  // @DataMember(Order=9)
  public errorView: string;

  // @DataMember(Order=10)
  public nonce: string;

  // @DataMember(Order=11)
  public uri: string;

  // @DataMember(Order=12)
  public response: string;

  // @DataMember(Order=13)
  public qop: string;

  // @DataMember(Order=14)
  public nc: string;

  // @DataMember(Order=15)
  public cnonce: string;

  // @DataMember(Order=16)
  public useTokenCookie: boolean;

  // @DataMember(Order=17)
  public accessToken: string;

  // @DataMember(Order=18)
  public accessTokenSecret: string;

  // @DataMember(Order=19)
  public scope: string;

  // @DataMember(Order=20)
  public meta: { [index: string]: string };

  public constructor(init?: Partial<Authenticate>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new AuthenticateResponse();
  }
  public getTypeName() {
    return 'Authenticate';
  }
}
