import { Injectable } from "@angular/core";

import { Observable, of } from "rxjs";
import { map } from "rxjs/operators";

import {
  IDataSourceService,
  setPageParams,
  getPageReturn,
  IPageParams,
  IPageReturn,
} from "projects/msu-its-web-common/src/data/data-source";

import { RmmdApiService } from "../rmmd-api.service";
import {
  CreateMaintenanceExternalSystemRequest,
  DeleteMaintenanceExternalSystemsRequest,
  GetMaintenanceExternalSystemExistRequest,
  GetMaintenanceExternalSystemObjectsRequest,
  GetMaintenanceExternalSystemRequest,
  GetMaintenanceExternalSystemsRequest,
  MaintenanceExternalSystem,
  MaintenanceExternalSystemObject,
  UpdateMaintenanceExternalSystemRequest,
} from "../../dtos/rmmd.dtos";

@Injectable()
export class ExternalSystemService
  implements
    IDataSourceService<MaintenanceExternalSystem, MaintenanceExternalSystem> {
  constructor(private _api: RmmdApiService) {}

  getPagedList(
    params: IPageParams,
    customerId: string
  ): Observable<IPageReturn<MaintenanceExternalSystem>> {
    if (!customerId) {
      return of(null);
    }

    const request = new GetMaintenanceExternalSystemsRequest();
    request.customerId = customerId;

    setPageParams(request, params);
    return this._api.get(request).pipe(
      map((m) => {
        return getPageReturn(m);
      })
    );
  }
  getList(
    customerId: string,
    filter?: string,
    groupIds?: string[],
    items?: number
  ): Observable<MaintenanceExternalSystem[]> {
    const request = new GetMaintenanceExternalSystemsRequest();
    request.customerId = customerId;
    request.entityGroups = groupIds || [];

    request.page = 0;
    request.items = items || 0;
    request.sort = "name";
    request.filter = filter || "";

    return this._api.get(request).pipe(
      map((m) => {
        return m.items;
      })
    );
  }
  getObjectList(
    externalSystemId: string,
    customerId: string,
    filter?: string,
    groupIds?: string[],
    items?: number
  ): Observable<MaintenanceExternalSystemObject[]> {
    const request = new GetMaintenanceExternalSystemObjectsRequest();
    request.customerId = customerId;
    request.entityGroups = groupIds || [];
    request.id = externalSystemId;
    request.page = 0;
    request.items = items || 0;
    request.sort = "name";
    request.filter = filter || "";

    return this._api.get(request).pipe(
      map((m) => {
        return m.items;
      })
    );
  }

  get(id: string, customerId: string) {
    const request = new GetMaintenanceExternalSystemRequest();
    request.id = id;
    request.customerId = customerId;
    return this._api.get(request);
  }

  add(
    model: MaintenanceExternalSystem,
    params: {
      customerId: string;
      groups: string[];
      updateGroups: boolean;
    }
  ) {
    const request = new CreateMaintenanceExternalSystemRequest();
    request.entity = model;
    request.customerId = params.customerId;
    request.entityGroups = params.groups;
    request.updateEntityGroups = params.updateGroups;
    return this._api.post(request);
  }
  update(
    model: MaintenanceExternalSystem,
    params: { customerId: string; groups: string[]; updateGroups: boolean }
  ) {
    const request = new UpdateMaintenanceExternalSystemRequest();
    request.id = model.id;
    request.entity = model;
    request.customerId = params.customerId;
    request.entityGroups = params.groups;
    request.updateEntityGroups = params.updateGroups;
    return this._api.put(request);
  }
  delete(ids: string[], customerId: string) {
    const request = new DeleteMaintenanceExternalSystemsRequest();
    request.ids = ids;
    request.customerId = customerId;
    return this._api.delete(request);
  }
  exist(name: string, customerId: string): Observable<boolean> {
    const request = new GetMaintenanceExternalSystemExistRequest({
      name,
      customerId,
    });
    return this._api.get(request);
  }

  // getControllerList(
  //   filter: string,
  //   customerId: string,
  //   items = 0,
  //   controllerType?: ParkingControllerType,
  //   onlyFree?: boolean
  // ) {
  //   const request = new GetParkingControllersRequest();

  //   request.customerId = customerId;
  //   request.filter = filter;
  //   request.controllerType = controllerType || ParkingControllerType.None;
  //   request.onlyFree = onlyFree || false;
  //   request.page = 0;
  //   request.items = items;

  //   return this._api.get(request).pipe(map((m) => m.items));
  // }

  // changeCustomer(
  //   id: string,
  //   customerId: string,
  //   params: { newCustomerId: string; description: string }
  // ) {
  //   const request = new ParkingChangeCustomerRequest();
  //   request.id = id;
  //   request.customerId = customerId;
  //   request.description = params.description;
  //   request.newCustomerId = params.newCustomerId;
  //   return this._api.post(request);
  // }

  // detachController(id: string, customerId: string, description: string) {
  //   const request = new DetachParkingControllerRequest();
  //   request.id = id;
  //   request.description = description;
  //   request.customerId = customerId;
  //   return this._api.post(request);
  // }

  // getPermissions(id: string, customerId: string) {
  //   const request = new GetParkingCurrentUserPermissionsRequest();
  //   request.id = id;
  //   request.customerId = customerId;
  //   return this._api.get(request);
  // }

  // getControllerEventTypes(id: string, customerId: string) {
  //   const request = new GetControllerEventTypesRequest();
  //   request.id = id;
  //   request.customerId = customerId;
  //   return this._api.get(request);
  // }
}
