import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy } from '@angular/core';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import {
  PotokMovementType,
  PotokPlanType,
  PotokTrafficObjectStatus,
  TrafficMovementType,
  TrafficObjectMode,
} from 'projects/msu-its-web-tlc/src/dtos/tlc.dtos';

import { IPotokTrafficObjectSet, PotokStateService } from './potok-state.service';

import { PotokTrafficObjectService } from 'projects/msu-its-web-tlc/src/services/potok-traffic-object.service';
import { SchemaUtils } from 'projects/msu-its-web-tlc/src/dtos/schema';
import { SettingsService } from 'projects/msu-its-web-common/src/services/settings.service';
import { LayoutSvgUtils } from 'projects/msu-its-web-tlc/src/dtos/layout';
import { getPlanStates, PotokPlan } from 'projects/msu-its-web-tlc/src/dtos/potok/potok-plan';
import { getColor } from 'projects/msu-its-web-common/src/utils/colors';

@Component({
  selector: 'potok-state-mode',
  templateUrl: './potok-state-mode.component.html',
  styleUrls: ['./potok-state-mode.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PotokStateModeComponent implements OnDestroy {
  _destroy = new Subject();

  @Input()
  disabled = false;

  status: PotokTrafficObjectStatus;
  trafficObjectSet: IPotokTrafficObjectSet;

  states: TrafficMovementType[][] = [];
  items: TrafficMovementType[][] = [];

  constructor(
    private _stateService: PotokStateService,
    private _potokTrafficObjectService: PotokTrafficObjectService,
    private _settingsService: SettingsService,
    private _changeDetector: ChangeDetectorRef
  ) {
    this._stateService.trafficObjectSet$
      .pipe(takeUntil(this._destroy))
      .subscribe((trafficObjectSet) => {
        this.trafficObjectSet = trafficObjectSet;
        this._loadStates();
        this._changeDetector.markForCheck();
      });

    this._stateService.status$.pipe(takeUntil(this._destroy)).subscribe((status) => {
      this.status = status;
      this._changeDetector.markForCheck();
    });
  }

  ngOnDestroy() {
    this._destroy.next();
    this._destroy.complete();
  }

  _loadStates() {
    if (!this.trafficObjectSet) {
      this.states = [];
      return;
    }

    const plans = this.trafficObjectSet.settings.plans;
    const cyclePlans = Object.keys(plans)
      .map((k) => plans[k] as PotokPlan)
      .filter((m) => m.enabled && m.type === PotokPlanType.PhaseCycle);

    if (cyclePlans.length) {
      this.states = [].concat(...getPlanStates(cyclePlans[0].transportBarriers));
    } else {
      this.states = [];
    }
  }

  toggleGroupChange(event) {
    const toggle = event.source;
    if (toggle && this.status) {
      const group = toggle.buttonToggleGroup;
      group.value = this.status.mode;
    }
  }

  setCoordinationMode() {
    this._stateService
      .loadingQuery(
        this._potokTrafficObjectService.setCoordinationMode(
          this._stateService.modelId,
          this._stateService.customerId
        )
      )
      .subscribe((status) => {
        this._stateService.status = status;
      });
  }

  setAdaptiveMode() {
    this._stateService
      .loadingQuery(
        this._potokTrafficObjectService.setAdaptiveMode(
          this._stateService.modelId,
          this._stateService.customerId
        )
      )
      .subscribe((status) => {
        this._stateService.status = status;
      });
  }

  setRemoteMode(movements?: PotokMovementType[]) {
    this._stateService
      .loadingQuery(
        this._potokTrafficObjectService.setRemoteMode(
          this._stateService.modelId,
          this._stateService.customerId,
          movements
        )
      )
      .subscribe((status) => {
        this._stateService.status = status;
      });
  }

  setOffMode() {
    this._stateService
      .loadingQuery(
        this._potokTrafficObjectService.setOffMode(
          this._stateService.modelId,
          this._stateService.customerId
        )
      )
      .subscribe((status) => {
        this._stateService.status = status;
      });
  }

  setSvgLayoutState(target, state: TrafficMovementType[]) {
    const schema = this.trafficObjectSet.schemaSet.schema;
    const schemaView = this.trafficObjectSet.schemaSet.schemaView;
    const darkTheme = this._settingsService.darkTheme;
    const svg = target.contentDocument;

    const movements = [];
    state.forEach((m) => {
      movements.push(...SchemaUtils.schemaViewMovements(schema, m));
    });

    LayoutSvgUtils.updateLayoutSchema(svg, schema, schemaView, darkTheme);
    LayoutSvgUtils.showLayoutMovements(svg, schema, movements);
  }

  updateItems(enabledMovements: TrafficMovementType[]) {
    const result = enabledMovements?.length ? [enabledMovements] : [];

    this.states.some((state) => {
      const contains = enabledMovements.every((m) => state.includes(m));

      const exist = result.some(
        (s) => s.length == state.length && s.every((m) => state.includes(m))
      );

      if (contains && !exist) {
        result.push(state);
      }

      if (result.length >= 5) {
        return true;
      }
    });

    this.items = result;
    this._changeDetector.markForCheck();
  }

  get conflicts() {
    const schema = this.trafficObjectSet.schemaSet.schema;
    return SchemaUtils.schemaStaticConflicts(schema);
  }

  get remotePhaseTime() {
    if (
      this.status &&
      this.status.mode === TrafficObjectMode.Remote &&
      this.status?.controllerStatus?.traffic?.currentPhase
    ) {
      const nowTime = new Date(this.status.serverTime || 0).getTime();
      const startTime = new Date(
        this.status.controllerStatus.traffic.currentPhase.startTime
      ).getTime();
      return Math.floor((nowTime - startTime) / 1000);
    }
    return '';
  }

  trackByMoves = (index, item: TrafficMovementType[]) => {
    return item.join('');
  };

  getColor(color) {
    return getColor(color);
  }
}
