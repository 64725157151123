<nav mat-tab-nav-bar class="mat-tab-md">
  <ng-container *ngFor="let link of tabLinks">
    <a
      mat-tab-link
      *hasPermissions="link.permissions"
      [routerLinkActiveOptions]="{ exact: true }"
      [routerLink]="link.link"
      [active]="rla.isActive"
      #rla="routerLinkActive"
      routerLinkActive
    >
      {{ link.label | translate }}
    </a>
  </ng-container>
</nav>
<div
  style="
    flex: 1;
    overflow: auto;
    overflow: overlay;
    margin: 0 -8px -8px;
    padding: 12px 8px 8px;
  "
>
  <router-outlet></router-outlet>
</div>
