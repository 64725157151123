<h4 mat-dialog-title>
  {{ "ROUTE_ESCORT.ESCORT_RESULT" | translate }}
  <br />
  <small>{{ summary.session.name }}</small>
</h4>
<mat-dialog-content style="flex: 1">
  <div style="width: 800px">
    <div fxLayout="row" fxLayoutGap="24px">
      <div *ngFor="let values of [values1, values2, values3]">
        <table mat-table mat-condenced [dataSource]="values" class="mat-elevation-z81">
          <ng-container matColumnDef="name">
            <td mat-cell *matCellDef="let element">{{ element.name | translate }}</td>
          </ng-container>
          <ng-container matColumnDef="value">
            <td mat-cell *matCellDef="let element">{{ element.value }}{{ element.unit | translate }}</td>
          </ng-container>
          <tr mat-row *matRowDef="let row; columns: ['name', 'value']"></tr>
        </table>
      </div>
    </div>
    <div #chart style="height: 300px; margin: 12px 0"></div>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>{{ "COMMON.CLOSE" | translate }}</button>
</mat-dialog-actions>
