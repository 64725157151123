import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';

import { FlashService } from '../../services/flash.service';
import { FlashComponent } from './flash.component';

@NgModule({
  providers: [
    FlashService,
  ],
  imports: [
    CommonModule,
    MatSnackBarModule,
    MatButtonModule,
    MatIconModule
  ],
  declarations: [
    FlashComponent,
  ],
  entryComponents: [
    FlashComponent,
  ],
  exports: [
  ],
})
export class FlashModule { }
