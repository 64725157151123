<div class="mat-elevation-z2" fxLayout="column" fxLayoutGap="8px" style="border-radius: 10px; padding: 10px; position: relative; overflow: hidden">
  <div fxLayout fxLayoutAlign="space-between center">
    <div>
      <div style="font-size: 14px">{{ status.name }}</div>
      <div class="mat-small mat-secondary-color">{{ status.name }}</div>
    </div>

    <button mat-icon-button [matMenuTriggerFor]="panelMenu">
      <mat-icon>more_vert</mat-icon>
    </button>

    <mat-menu #panelMenu="matMenu" overlapTrigger="false" class="mat-menu-md" xPosition="before">
      <button [disabled]="!(['panel.update'] | hasPermissions)" mat-menu-item (click)="settings()">
        {{ "COMMON.SETTINGS" | translate }}
      </button>
      <button [disabled]="!(['panel.update'] | hasPermissions)" mat-menu-item (click)="brightnessSettings()">
        {{ "PANEL.BRIGHTNESS_SETTINGS" | translate }}
      </button>
      <button [disabled]="!(['panelController.update'] | hasPermissions)" mat-menu-item (click)="uploadSettings()">
        {{ "PANEL_CONTROLLER.UPLOAD_SETTINGS" | translate }}
      </button>
    </mat-menu>
  </div>

  <div class="mat-elevation-z2" style="display: flex; padding: 2px 8px 2px 6px">
    <div style="margin-right: 24px">
      <div class="mat-small mat-secondary-color">{{ "PANEL_CONTROLLER.CONTROLLER" | translate }}:</div>
      <div class="mat-body-1" style="line-height: normal">
        {{ controllerStatus?.number }}
      </div>
    </div>

    <div style="flex: 1">
      <ng-container *ngIf="status">
        <div class="mat-small mat-secondary-color">
          <span>{{ "COMMON.CONNECTION_TIME" | translate }}:</span>
          <span class="mat-color" style="margin-left: 8px">
            {{ status?.lastReceived | date: "dd.MM.yyyy HH:mm:ss":timezone:locale }}
          </span>
        </div>
        <div class="mat-body-1" style="line-height: normal; padding-bottom: 3px">
          {{ statusString }}
        </div>
      </ng-container>
    </div>

    <div *ngIf="status?.brightness !== undefined" fxLayout fxLayoutAlign="center center" fxLayoutGap="4px">
      <mat-icon style="color: gold">wb_sunny</mat-icon>
      <span>{{ status.brightness }}%</span>
    </div>
  </div>

  <!--  -->
  <mat-button-toggle-group
    [value]="status?.mode"
    class="mode-toggle-group"
    [disabled]="isOffline || isEngineerMode"
    (change)="toggleGroupChange($event)"
    appearance="standard"
  >
    <mat-button-toggle value="Work" [matMenuTriggerFor]="workMenu" (click)="loadSchedules()" [disabled]="isDiagnosticMode">
      {{ "PANEL_MODE.WORK" | translate }}
    </mat-button-toggle>

    <mat-button-toggle value="Off" (change)="setOffMode()">
      {{ "PANEL_MODE.OFF" | translate }}
    </mat-button-toggle>
  </mat-button-toggle-group>

  <mat-menu #workMenu="matMenu" overlapTrigger="false" class="mat-menu-md">
    <div *ngIf="schedules.length === 0 && !loading" mat-menu-item class="mat-secondary-color" disabled>{{ "PANEL.NO_SCHEDULES" | translate }}</div>
    <button *ngFor="let item of schedules" mat-menu-item (click)="setWorkMode(item)">
      <mat-icon *ngIf="item.lastSelected">checked</mat-icon>
      {{ item.name }} {{ item.description }}
    </button>
  </mat-menu>

  <div class="thumbnail" style="position: relative">
    <img *ngIf="status?.thumbnailId" [src]="getThumbnailUrl(status.thumbnailId)" />

    <div *ngIf="!isOffline && !isEngineerMode && isDiagnosticMode" class="backdrop">
      <div class="backdrop-inner mat-dialog-bg mat-elevation-z4">
        <div class="mat-primary-color" style="font-size: 20px; font-weight: 500">
          {{ "CONTROLLER_MODE.DIAGNOSTIC" | translate }}
        </div>
        <div *ngIf="status.description" style="text-align: center">
          {{ status.description }}
        </div>
      </div>
    </div>

    <div *ngIf="isOffline" class="backdrop">
      <div class="backdrop-inner mat-dialog-bg mat-elevation-z4">
        <div fxLayout fxLayoutGap="12px" style="align-items: center">
          <mat-icon class="dialog-warn">warning</mat-icon>
          <div>{{ "PANEL.NO_CONNECTION" | translate }}</div>
        </div>
      </div>
    </div>

    <div *ngIf="!isOffline && isEngineerMode" class="backdrop">
      <div class="backdrop-inner mat-dialog-bg mat-elevation-z4">
        <div class="mat-primary-color" style="font-size: 20px; font-weight: 500">
          {{ "CONTROLLER_MODE.ENGINEER" | translate }}
        </div>
      </div>
    </div>

    <div *ngIf="!isOffline && !isEngineerMode && isError" class="backdrop">
      <div class="backdrop-inner mat-dialog-bg mat-elevation-z4">
        <div fxLayout fxLayoutGap="12px" style="align-items: center">
          <mat-icon class="dialog-error">warning</mat-icon>
          <div>{{ "error" }}</div>
        </div>
      </div>
    </div>

    <div *ngIf="loading" class="backdrop">
      <div class="backdrop-inner mat-dialog-bg mat-elevation-z4" style="padding-bottom: 16px">
        <mat-spinner diameter="36"></mat-spinner>
      </div>
    </div>
  </div>
</div>
