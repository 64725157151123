import {
  Component,
  ChangeDetectionStrategy,
  OnChanges,
  OnDestroy,
  DoCheck,
  ViewChild,
  ElementRef,
  Input,
  Optional,
  Self,
  HostBinding,
  AfterViewInit,
} from "@angular/core";
import { MatFormFieldControl } from "@angular/material/form-field";
import {
  CanUpdateErrorState,
  ErrorStateMatcher,
  CanUpdateErrorStateCtor,
  mixinErrorState,
} from "@angular/material/core";
import {
  ControlValueAccessor,
  NgForm,
  FormGroupDirective,
  NgControl,
} from "@angular/forms";

import { Subject } from "rxjs";

import { coerceBooleanProperty } from "@angular/cdk/coercion";

class MatInputBase {
  constructor(
    public _defaultErrorStateMatcher: ErrorStateMatcher,
    public _parentForm: NgForm,
    public _parentFormGroup: FormGroupDirective,
    /** @docs-private */
    public ngControl: NgControl
  ) {}
}
const _MatInputMixinBase: CanUpdateErrorStateCtor &
  typeof MatInputBase = mixinErrorState(MatInputBase);

@Component({
  selector: "prefix-input",
  template: `
    <span>
      <b>{{ prefix }}</b>
    </span>
    <input
      #input
      class="mat-input-element"
      [attr.placeholder]="placeholder"
      [disabled]="disabled"
      [required]="required"
      [readonly]="readonly"
      (blur)="_focusChanged(false)"
      (focus)="_focusChanged(true)"
      (input)="_onInput()"
      attr.maxlength="{{maxLength || 20}}"
    />
  `,
  styles: [
    `
      :host {
        display: flex;
        flex-direction: row;
      }
      span {
        opacity: 0;
        padding-right: 3px;
        transition: opacity 200ms;
        white-space: nowrap;
      }
      .mat-form-field-should-float :host span {
        opacity: 1;
      }
    `,
  ],
  providers: [
    { provide: MatFormFieldControl, useExisting: PrefixInputComponent },
  ],
  // host: {
  //   '[id]': 'id',
  //   '[class.floating]': 'shouldLabelFloat',
  //   '[attr.aria-describedby]': 'describedBy',
  // },
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PrefixInputComponent extends _MatInputMixinBase
  implements
    MatFormFieldControl<string>,
    ControlValueAccessor,
    OnChanges,
    OnDestroy,
    AfterViewInit,
    DoCheck,
    CanUpdateErrorState {
  @Input()
  maxLength;

  @Input()
  get prefix() {
    return this._prefix;
  }
  set prefix(pfx) {
    this._prefix = pfx;
    this.stateChanges.next();
  }

  @Input()
  get readonly(): boolean {
    return this._readonly;
  }
  set readonly(value: boolean) {
    this._readonly = coerceBooleanProperty(value);
  }

  @Input()
  get required(): boolean {
    return this._required;
  }
  set required(value: boolean) {
    this._required = coerceBooleanProperty(value);
  }

  @Input()
  get disabled(): boolean {
    if (this.ngControl && this.ngControl.disabled !== null) {
      return this.ngControl.disabled;
    }
    return this._disabled;
  }
  set disabled(value: boolean) {
    this._disabled = coerceBooleanProperty(value);
    if (this.focused) {
      this.focused = false;
      this.stateChanges.next();
    }
  }

  @Input()
  get value(): string {
    return this._input ? this._input.nativeElement.value : "";
  }
  set value(value: string) {
    if (value !== this.value) {
      if (this._input) {
        this._input.nativeElement.value = value;
        this.stateChanges.next();
      } else {
        this._previousValue = value;
      }
    }
  }

  constructor(
    @Optional() _parentForm: NgForm,
    @Optional() _parentFormGroup: FormGroupDirective,
    @Optional() @Self() public ngControl: NgControl,
    _defaultErrorStateMatcher: ErrorStateMatcher
  ) {
    super(_defaultErrorStateMatcher, _parentForm, _parentFormGroup, ngControl);

    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }

    this.id = this.id;
  }

  get empty() {
    return !this.value;
  }

  get shouldLabelFloat() {
    return this.focused || !this.empty;
  }

  static nextId = 0;
  @HostBinding("id") idBinding: "id";
  @HostBinding("class.floating") floatingBinding: "shouldLabelFloat";
  @HostBinding("attr.aria-describedby") describedByBinding: "describedBy";

  shouldPlaceholderFloat?: boolean;

  errorStateMatcher: ErrorStateMatcher;

  describedBy = "";

  stateChanges = new Subject<void>();

  focused = false;

  errorState = false;

  controlType = "prefix-input";

  id = `prefix-input-${PrefixInputComponent.nextId++}`;

  @ViewChild("input", { static: false })
  _input: ElementRef;
  private _prefix: string;
  private _readonly = false;

  @Input() placeholder: string;
  protected _required = false;
  protected _disabled = false;

  protected _previousValue: any;
  protected _dirtyCheckValue() {
    if (!this._input) {
      return;
    }
    if (!this.focused) {
      this.value = this.value.trim();
    }
    const newValue = this.value;
    if (this._previousValue !== newValue) {
      this._previousValue = newValue;
      this._onChanged(newValue);
      this.stateChanges.next();
    }
  }

  ngAfterViewInit() {
    this._input.nativeElement.value = this._previousValue;
    // this._previousValue = this.value;
  }

  ngOnChanges() {
    this.stateChanges.next();
  }

  ngOnDestroy() {
    this.stateChanges.complete();
  }

  ngDoCheck() {
    this._dirtyCheckValue();
    if (this.ngControl) {
      this.updateErrorState();
    }
  }

  setDescribedByIds(ids: string[]) {
    this.describedBy = ids.join(" ");
  }

  onContainerClick() {
    this._input.nativeElement.focus();
  }

  _focusChanged(isFocused: boolean) {
    if (isFocused !== this.focused && !this.readonly) {
      this.focused = isFocused;
      if (!isFocused) {
        this._onTouched();
      }
      this.stateChanges.next();
    }
  }

  _onInput() {}

  writeValue(value: any): void {
    this.value = value;
    // this._dirtyCheckValue();
  }

  _onChanged = (newValue) => {};
  registerOnChange(fn: any): void {
    this._onChanged = fn;
  }

  _onTouched = () => {};
  registerOnTouched(fn: any): void {
    this._onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
}
