<div *ngIf="loading" class="backdrop">
  <mat-spinner diameter="36" color="accent"></mat-spinner>
</div>

<h4 mat-dialog-title>{{ "COMMON.ACCESS" | translate }}: {{ model.name }}</h4>
<mat-dialog-content>
  <div fxLayout fxLayoutAlign="center center" fxLayoutGap="16px">
    <mat-form-field>
      <mat-label>{{ "COMMON.CUSTOMER" | translate }}</mat-label>
      <mat-select #customer disableOptionCentering panelClass="single-select-panel left" [disabled]="!(customers?.length > 0)">
        <mat-option *ngFor="let customer of customers" [value]="customer.id">
          {{ customer.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <div>
      <button mat-flat-button color="accent" [disabled]="!customer.value" (click)="create(customer.value)">
        {{ "COMMON.GRANT_ACCESS" | translate }}
      </button>
    </div>
  </div>

  <mat-list style="padding-bottom: 12px">
    <mat-list-item class="mat-hover" (dblclick)="access(item)" *ngFor="let item of shares">
      <p mat-line>{{ item.customerName }}</p>

      <button class="action" mat-icon-button title="{{ 'COMMON.EDIT' | translate }}" (click)="edit(item)">
        <mat-icon>edit</mat-icon>
      </button>
      <button class="action" mat-icon-button title="{{ 'COMMON.DELETE' | translate }}" (click)="delete(item)" style="margin-right: -12px">
        <mat-icon>delete</mat-icon>
      </button>
      <mat-divider></mat-divider>
    </mat-list-item>
  </mat-list>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button (click)="close()">
    {{ "COMMON.CLOSE" | translate }}
  </button>
</mat-dialog-actions>
