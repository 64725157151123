import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';

import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';

import { BehaviorSubject, NEVER, Subject } from 'rxjs';
import { catchError, debounceTime, finalize, map, switchMap, takeUntil } from 'rxjs/operators';

import {
  RecoveryMovement,
  Route,
  RouteInfo,
  RouteObject,
  RouteSettings,
  StepMovement,
} from '../../dtos/tlc.dtos';

import { RouteService } from '../../services/route.service';
import { RouteEscortStateService } from './route-escort-state.service';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';

@Component({
  selector: 'route-escort-select',
  templateUrl: './route-escort-select.component.html',
  styleUrls: ['./route-escort-select.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RouteEscortSelectComponent implements OnInit, OnDestroy, AfterViewInit {
  _destroy = new Subject();

  selectedRouteName = '';

  get selectedRoute() {
    return this._stateService.selectedRoute;
  }

  constructor(
    private _routeService: RouteService,
    private _stateService: RouteEscortStateService,
    private _changeDetector: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this._stateService.selectedRoute$
      .pipe(takeUntil(this._destroy))
      .subscribe(() => this._changeDetector.markForCheck());

    this.searchInputSubject
      .pipe(debounceTime(250))
      .pipe(takeUntil(this._destroy))
      .subscribe((search) => {
        this.loadRoutes(search);
      });

    setTimeout(() => this.loadRoutes(null));
  }

  ngAfterViewInit() {}

  ngOnDestroy() {
    this._destroy.next();
    this._destroy.complete();
    this._destroy = null;
  }

  searchInputSubject = new Subject<string>();
  routeListSubject = new BehaviorSubject<RouteInfo[]>([]);

  loadRoutes(search: string) {
    this._routeService
      .getList(this._stateService.customerId, search, null, 50)
      .pipe(catchError(() => NEVER))
      .subscribe((result) => {
        this.routeListSubject.next(result);
        this._changeDetector.markForCheck();
      });
  }

  selectRoute(id?: string) {
    if (!id) {
      this.selectedRouteName = null;
      this._stateService.selectedRoute = null;
      return;
    }

    this.selectedRouteName = this.routeListSubject.value?.find((m) => m.id == id)?.name;
    this._stateService.selectRoute(id).subscribe(() => {
      this._changeDetector.markForCheck();
    });
  }

  svgLoad(target, item: RouteObject, step: StepMovement | RecoveryMovement) {
    this._stateService.objectSvgLoad(target, item, step);
  }
  getAngle(item: RouteObject) {
    return this._stateService.getObjectAngle(item);
  }
  getName(item: RouteObject) {
    return this._stateService.getObjectName(item);
  }

  start() {
    this._stateService.startEscort();
  }

  drop(event: CdkDragDrop<RouteObject[]>) {
    if (event.previousIndex != event.currentIndex) {
      moveItemInArray(
        this.selectedRoute.settings.routeObjects,
        event.previousIndex,
        event.currentIndex
      );
      this._stateService.selectedRoute = this.selectedRoute;
      this._changeDetector.markForCheck();
    }
  }

  remove(item: RouteObject) {
    const newRoute = new Route(this.selectedRoute);
    newRoute.settings = new RouteSettings(this.selectedRoute.settings);
    newRoute.settings.routeObjects = [...newRoute.settings.routeObjects.filter((m) => m != item)];

    this._stateService.selectedRouteBounded = true;
    this._stateService.selectedRoute = newRoute;

    this._changeDetector.markForCheck();
  }
}
