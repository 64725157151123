<div fxFlexFill fxLayout="column" fxLayoutGap="12px">
  <div
    fxFlex
    fxLayout="column"
    fxLayoutAlign="start stretch"
    fxLayoutGap="12px"
  >
    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">
      <span style="font-size: 16px;" fxFlex="160px">{{ 'PARKINGS.PARKING_TYPE' | translate }}</span>
      <span style="font-size: 16px;" fxFlex
        >{{getNameParkingType(modelInfo.parkingType)| translate }}</span
      >
    </div>
    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">
      <span style="font-size: 16px;" fxFlex="160px">{{ 'PARKINGS.PARKING_SPACES' | translate }} </span>
      <span style="font-size: 16px;" fxFlex>{{modelInfo.spaces}}</span>
    </div>
    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">
      <span style="font-size: 16px;" fxFlex="160px"> {{ "PARKINGS.POPUP_URL_HINT" | translate }} </span>
      <a
        style="font-size: 16px;"
        target="_blank"
        [attr.href]="modelInfo.externalUrl"
        >Сайт "KeyPark"</a
      >
    </div>
  </div>
</div>
