import {
  Component,
  Input,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Inject,
  ViewChild,
  AfterViewInit,
} from '@angular/core';
import { AbstractControl } from '@angular/forms';

import { TranslateService } from '@ngx-translate/core';
import { DialogService } from '../../services/dialog.service';

import { APP_ENVIRONMENT } from '../../utils/shared-consts';
import { MapComponent } from './map.component';

declare const L;

@Component({
  selector: 'location-component',
  template: ` <map-component #map [options]="mapOptions"></map-component> `,
  styles: [
    `
      :host {
        display: block;
        width: 100%;
        height: 100%;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LocationComponent implements AfterViewInit {
  _marker;
  _directionMarker;

  mapOptions = { trafficLayer: false, doubleClickZoom: false };

  @Input() lat: number = null;
  @Input() lon: number = null;
  @Input() zoom: number = 15;
  @Input() svg: string = null;
  @Input() direction: number = null;
  @Input() disabled: boolean = false;

  @ViewChild('map', { static: true })
  _mapComponent: MapComponent;

  constructor(
    private _dialogService: DialogService,
    private _changeDetector: ChangeDetectorRef,
    public translate: TranslateService,
    @Inject(APP_ENVIRONMENT) private _appEnv
  ) {}

  ngAfterViewInit() {
    this._mapComponent.map
      .on('zoom zoomstart zoomend baselayerchange', (event) => {
        this._updateMarker();
      })
      .on(
        'dblclick',
        (event) => {
          this.setLatLon(event.latlng.lat, event.latlng.lng);
        },
        this
      )
      .on(
        'resize',
        (event) => {
          if (this.lat != undefined && this.lon != undefined) {
            this._mapComponent.map.panTo([this.lat, this.lon]);
          }
        },
        this
      );

    const lat = this.lat ? this._round(this.lat) : 56.01054;
    const lon = this.lon ? this._round(this.lon) : 92.86599;
    this._mapComponent.map.setView([lat, lon], this.zoom);

    this._marker = L.marker([lat, lon], {
      draggable: !this.disabled,
      zIndexOffset: 1000,
    })
      .on(
        'move',
        (event) => {
          this.lat = this._round(event.latlng.lat);
          this.lon = this._round(event.latlng.lng);
          this._rotateMarker();
          this._updateDirectionMarker();
          this._changeDetector.markForCheck();
        },
        this
      )
      .addTo(this._mapComponent.map);

    this._updateMarker();
  }

  get latlon() {
    return this.lat && this.lon ? `[${this.lat}, ${this.lon}]` : '';
  }

  private _round(value) {
    return Math.round(value * 1000000) / 1000000;
  }

  private _parse(coords: string) {
    const result = coords.match(/([+-]{0,1}\d+(\.\d+){0,1})/g);
    const lat = result ? parseFloat(result[0]) : null;
    const lon = result ? parseFloat(result[1]) : null;
    return [lat, lon];
  }

  prompt() {
    this._dialogService
      .prompt(
        this.latlon,
        this.translate.instant('LOCATION.TITLE'),
        this.translate.instant('LOCATION.LABEL'),
        this.translate.instant('LOCATION.HINT'),
        this.translate.instant('LOCATION.INCORRECT'),
        (control: AbstractControl) => {
          const ll = this._parse(control.value);
          const lat = ll[0];
          const lon = ll[1];
          return Number.isFinite(lat) &&
            Number.isFinite(lon) &&
            lat <= 90 &&
            lat >= -90 &&
            lon <= 180 &&
            lon >= -180
            ? null
            : { badFormat: true };
        }
      )
      .subscribe((result) => {
        if (result) {
          const ll = this._parse(result);
          this.lat = ll[0];
          this.lon = ll[1];
          this._marker.setLatLng(ll);
          this._mapComponent.map.panTo(ll);
          this._changeDetector.markForCheck();
        }
      });
    return false;
  }

  setLatLon(lat, lon) {
    this.lat = lat;
    this.lon = lon;
    this._marker.setLatLng([lat, lon]);
    this._updateMarker();
    this._mapComponent.map.panTo([lat, lon]);
    this._changeDetector.markForCheck();
  }

  setSvg(svg: string) {
    this.svg = svg;
    this._updateMarker();
  }

  setDirection(direction: number) {
    this.direction = direction;
    this._updateMarker();
  }

  setDisabled(disabled: boolean) {
    disabled ? this._marker.dragging.disable() : this._marker.dragging.enable();
  }

  private _updateMarker() {
    if (!this._marker) {
      return;
    }

    const zoom = this._mapComponent.map.getZoom();
    const zoomSize =
      zoom <= 12
        ? 12
        : zoom == 13
        ? 16
        : zoom == 14
        ? 20
        : zoom == 15
        ? 24
        : 28;

    const icon = this.svg
      ? new L.DivIcon({
          className: '',
          html: this.svg,
          iconSize: zoomSize,
        })
      : new L.Icon.Default();
    this._marker.setIcon(icon);

    this._rotateMarker();
    this._updateDirectionMarker();
  }

  private _rotateMarker() {
    if (this.direction != null) {
      const elm = this._marker.getElement().querySelector('svg');
      if (elm) {
        elm.style.transform = `rotate(${
          this.direction == null ? 90 : this.direction
        }deg)`;
      }
    }
  }

  private _updateDirectionMarker() {
    if (this.direction != null) {
      if (this._directionMarker) {
        this._directionMarker.setLatLng(this._marker.getLatLng());
      } else {
        this._directionMarker = L.marker(this._marker.getLatLng()).addTo(
          this._mapComponent.map
        );
      }
      const z = this._mapComponent.map.getZoom();
      const k = z <= 12 ? 1 : z <= 14 ? 2 : z <= 16 ? 4 : 6;
      this._directionMarker.setIcon(
        new L.DivIcon({
          className: 'leaflet-direction',
          html: `<img src="/assets/images/map/direction.svg" style="transform: rotate(${this.direction}deg)" />`,
          iconSize: [k * 27, k * 48],
          iconAnchor: [(k * 27) / 2, k * 48],
        })
      );
    } else {
      if (this._directionMarker) {
        this._mapComponent.map.removeLayer(this._directionMarker);
        this._directionMarker = null;
      }
    }
  }
}
