export const COLORS = {
  // green: 'LimeGreen',
  // grey: '#888',

  white: '#fff',
  black: '#111',
  green: '#3c3',
  grey: '#667',
  blue: '#36c',
  brown: '#b63',
  magenta: '#f6c',
  red: '#f00',
  yellow: '#fe0',
  dodgerblue: '#55f',
  lightblue: '#55f',
  darkgreen: '#383',
  blueviolet: '#c4f',
};

export function getColor(color: string): string {
  return COLORS[color?.toLocaleLowerCase()] || color;
}

// ASUDD24 colors

//   case 'White':
//     return '#fff';
//   case 'Black':
//     return '#111';
//   case 'Green':
//     return '#1b1';
//   case 'Gray':
//     return main ? '#777' : '#555';
//   case 'Blue':
//     return '#36c';
//   case 'Brown':
//     return '#b63';
//   case 'Magenta':
//     return '#f6c';
//   case 'Red':
//     return main ? '#f33' : '#f00';
//   case 'Yellow':
//     return '#fe0';
//   case 'DodgerBlue':
//   case 'LightBlue':
//     return '#55f';
//   case 'DarkGreen':
//     return '#383';
//   case 'BlueViolet':
//     return '#c4f';

