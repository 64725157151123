import {
  Component,
  ViewChild,
  Inject,
  OnInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, Validators } from '@angular/forms';
import { MatInput } from '@angular/material/input';

import { EMPTY } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';

import { AuthService } from '../../services/auth.service';
import { SettingsService } from '../../services/settings.service';
import { LANGS } from '../../utils/langs';
import { TranslateService } from '@ngx-translate/core';
import { DialogService } from '../../services/dialog.service';
import { SelectLangComponent } from '../navbar/select-lang.component';
import { FlashService } from '../../services/flash.service';
import { APP_ENVIRONMENT } from '../../utils/shared-consts';

declare const Android: any;

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoginComponent implements OnInit {
  submitted = false;
  passwordHide = true;
  errorMessage = '';

  langs = LANGS;

  formGroup = this._formBuilder.group({
    username: ['', Validators.required],
    password: ['', Validators.required],
    remember: false,
  });

  get username() {
    return this.formGroup.controls['username'];
  }
  get password() {
    return this.formGroup.controls['password'];
  }
  get remember() {
    return this.formGroup.controls['remember'];
  }

  @ViewChild('usernameInput', { static: false })
  public usernameInput: MatInput;

  constructor(
    private _router: Router,
    private _formBuilder: FormBuilder,
    private _dialogService: DialogService,
    private _flashService: FlashService,
    private _authService: AuthService,
    private _activatedRoute: ActivatedRoute,
    private _settingsService: SettingsService,
    private _changeDetector: ChangeDetectorRef,
    public translate: TranslateService,
    @Inject(APP_ENVIRONMENT) public appEnv
  ) {}

  ngOnInit() {
    if (this._authService.isLoggedIn) {
      const returnUrl = this._activatedRoute.snapshot.queryParams['returnUrl'];
      this._router.navigate([returnUrl ? returnUrl : '/']);
    }
    if (typeof Android !== 'undefined') {
      const values = JSON.parse(Android.getValues());
      this.username.setValue(values.user);
      this.password.setValue(values.password);
    }
  }

  submit() {
    if (!this.formGroup.valid || this.submitted) {
      return;
    }

    this._startSubmit();
    this._authService
      .login(this.formGroup.value)
      .pipe(
        catchError((error) => {
          this.errorMessage = error.message;
          this._resetSubmit();
          return EMPTY;
        }),
        finalize(() => {
          if (this.submitted) {
            this._resetSubmit();
          }
        })
      )
      .subscribe(() => {
        const returnUrl = this._activatedRoute.snapshot.queryParams[
          'returnUrl'
        ];
        this._router.navigate([returnUrl ? returnUrl : '/']);
        if (typeof Android !== 'undefined') {
          Android.saveValues(this.username.value, this.password.value);
        }
      });
  }

  private _startSubmit() {
    this.submitted = true;
    this.errorMessage = '';
    this.formGroup.disable();
    this._changeDetector.markForCheck();
  }

  private _resetSubmit() {
    this.submitted = false;
    this.formGroup.enable();
    this._changeDetector.markForCheck();
    if (this.errorMessage) {
      setTimeout(() => this.usernameInput.focus(), 300);
    }
  }

  get darkTheme() {
    return this._settingsService.darkTheme;
  }

  setDarkTheme() {
    this._settingsService.darkTheme = !this._settingsService.darkTheme;
  }

  getErrorMessage() {
    const t = this.translate;
    return this.errorMessage ? t.instant(this.errorMessage) : '';
  }

  selectLang() {
    const dialog = this._dialogService.dialog.open(SelectLangComponent, {
      disableClose: false,
    });
    dialog.afterClosed().subscribe((lang) => {
      if (lang) {
        this.translate.get('COMMON.LANGUAGE').subscribe((val) => {
          this._flashService.success(val + ': ' + LANGS[lang].toLowerCase());
        });
      }
    });
  }

  getCurrentLangName() {
    return LANGS[this.translate.currentLang].toLowerCase();
  }
}
