import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { MsuPirsStateService } from './msu-pirs-state.service';

import { FlashService } from 'projects/msu-its-web-common/src/services/flash.service';
import { DialogService } from 'projects/msu-its-web-common/src/services/dialog.service';

import { Parking, ParkingStatus } from 'projects/msu-its-web-parking/src/dtos/parking.dtos';

import { MsuPirsParkingService } from 'projects/msu-its-web-parking/src/services/msu-pirs-parking.service';
import { ParkingControllerService } from 'projects/msu-its-web-parking/src/services/parking-controller.service';

@Component({
  selector: 'msu-pirs-state-controller',
  templateUrl: './msu-pirs-state-controller.component.html',
  styles: [
    `
      :host {
        flex: 1;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MsuPirsStateControllerComponent implements OnDestroy {
  _destroy = new Subject();

  model: Parking;
  status: ParkingStatus;

  constructor(
    private _flashService: FlashService,
    private _stateService: MsuPirsStateService,
    private _msuPirsParkingService: MsuPirsParkingService,
    private _controllerService: ParkingControllerService,
    private _changeDetector: ChangeDetectorRef,
    private _dialogService: DialogService,
    public translate: TranslateService
  ) {
    this._stateService.parking$.pipe(takeUntil(this._destroy)).subscribe((model) => {
      this.model = model;
      this._changeDetector.markForCheck();
    });

    this._stateService.status$.pipe(takeUntil(this._destroy)).subscribe((status) => {
      this.status = status;
      this._changeDetector.markForCheck();
    });
  }

  ngOnDestroy() {
    this._destroy.next();
    this._destroy.complete();
  }

  // openControllerEdit() {
  //   const id = this.object.ControllerId;
  //   this._dialogService.windowDialog(
  //     '/settings/device-edit/' + id,
  //     'DeviceEdit' + id,
  //     'menubar=0,toolbar=0,titlebar=0,location=0,locationbar=0,width=1080,height=760,resizable=0'
  //   );
  // }

  // openControllerErrorLog() {
  //   const id = this.object.ControllerId;
  //   window.open(
  //     '/settings/device-error-log/' + id,
  //     'DeviceErrorLog' + id,
  //     'menubar=0,toolbar=0,titlebar=0,location=0,locationbar=0,width=960,height=850,resizable=0'
  //   );
  // }

  // openControllerEventLog() {
  //   const id = this.object.ControllerId;
  //   window.open(
  //     '/settings/event-log/device/' + id,
  //     'EventLog' + id,
  //     'menubar=0,toolbar=0,titlebar=0,location=0,locationbar=0,width=960,height=850,resizable=0'
  //   );
  // }

  // openControllerFiles() {
  //   const dialog = this._dialogService.dialog.open(FilesComponent, { disableClose: false });
  //   dialog.componentInstance.entityId = this.object.ControllerId;
  //   dialog.componentInstance.entityName = this.object.ControllerFullNumber;
  // }

  // openControllerAudit() {
  //   const id = this.object.ControllerId;
  //   window.open(
  //     '/settings/activity-log/device/' + id,
  //     'DeviceAudit' + id,
  //     'menubar=0,toolbar=0,titlebar=0,location=0,locationbar=0,width=960,height=850,resizable=0'
  //   );
  // }

  // openControllerSettings() {
  //   const id = this.object.ControllerId;
  //   this._dialogService.windowDialog(
  //     '/settings/device-settings/' + id,
  //     'DeviceSettings' + id,
  //     'menubar=0,toolbar=0,titlebar=0,location=0,locationbar=0,width=640,height=480,resizable=0'
  //   );
  // }

  disconnectController() {
    this._stateService
      .loadingQuery(
        this._controllerService.disconnect(this.model.controllerId, this._stateService.customerId)
      )
      .subscribe((result) => {
        if (result) {
          this._flashService.success(
            this.translate.instant('DEVICES.SESSION_DISCONNECTED', {
              number: this.model.controllerId,
            })
          );
        }
      });
  }

  controllerEventLog() {
    const url = `${this._stateService.customerId}/ext/parking-controller/${this.model.controllerId}/event-log`;
    this._dialogService.windowDialog(
      url,
      'parkingControllerEventLog_' + this.model.controllerId,
      'menubar=0,toolbar=0,titlebar=0,location=0,locationbar=0,width=950,height=850,resizable=0'
    );
  }
}
