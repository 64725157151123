<div *ngIf="loading" class="backdrop" style="z-index: 1000;">
  <mat-spinner diameter="36" color="accent"></mat-spinner>
</div>
<div fxLayout="column" fxLayoutGap="16px">
  <table fxFlex class="table">
    <tr>
      <td>{{ "COMMON.NAME" | translate }}</td>
      <td>{{ modelInfo.name }}</td>
    </tr>
    <tr>
      <td>{{ "CAMERAS_FTP.SERIAL" | translate }}</td>
      <td>{{ modelInfo.serial }}</td>
    </tr>
  </table>

  <div fxLayout="column" fxLayoutGap="16px">
    <div *ngIf="warningMessages; then thenBlock else elseBlock"></div>
    <ng-template #thenBlock
      ><span style="color: red;">Изображения не найдены</span></ng-template
    >
    <ng-template #elseBlock>
      <span
        >Дата изображения: {{dataImages | date: "short":null:locale}}</span
      ></ng-template
    >
    <div style="position: relative;">
      <img
        id="photo_{{ modelId }}"
        style="min-height: 330px; max-height: 330px; width: 100%;"
      />

      <button
        [disabled]="currentIndex === 0"
        style="position: absolute; top: 50%; left: 0;"
        (click)="prevImage()"
        mat-mini-fab
        title="Влево"
      >
        <mat-icon style="transform: rotate(180deg);">play_arrow</mat-icon>
      </button>

      <button
        [disabled]="(currentIndex + 1) >= totalImages"
        style="position: absolute; top: 50%; right: 0;"
        (click)="nextImage()"
        mat-mini-fab
        title="Вправо"
      >
        <mat-icon>play_arrow</mat-icon>
      </button>

      <div style="position: absolute; top: 0; right: 0;">
        <span>{{totalImages && (currentIndex + 1)}}{{"/"+totalImages}}</span>
      </div>
    </div>
  </div>
  <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="16px">
    <button mat-raised-button color="primary" (click)="loadAllImages()">
      Показать все {{totalImages}} изображений
    </button>
  </div>
</div>
