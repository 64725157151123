<div style="padding: 0 16px 16px">
  <div fxLayout="column" fxLayoutGap="16px">
    <div fxLayout="row" fxLayoutGap="16px">
      <div fxLayout="column" style="flex: 4">
        <mat-button-toggle
          mat-stroked-button
          value="Adaptive"
          [checked]="adaptiveEnabled"
          title="{{ 'TRAFFIC_SCENES.ADAPTIVE' | translate }}"
          (change)="start($event)"
        >
          {{ "TRAFFIC_SCENES.ADAPTIVE_SHORT" | translate }}
        </mat-button-toggle>
      </div>

      <button mat-flat-button style="flex: 1; width: 100%" color="primary" [disabled]="false" (click)="stop()">
        {{ "COMMON.STOP" | translate }}
      </button>
    </div>
  </div>
</div>

<mat-divider></mat-divider>

<!-- 
<div fxLayout fxLayoutGap="8px" fxLayoutAlign="start center" style="padding: 12px 16px; font-size: 14px" [class.mat-secondary-color]="!modeEnabled">
  <div>{{ "TRAFFIC_SCENES.CONTROL_MODE_DURATION" | translate }}:</div>
  <div>{{ modeDuration }}</div>
</div> 
-->

<mat-divider></mat-divider>

<div style="flex: 1; overflow: auto; overflow: overlay; margin-bottom: -12px">
  <table class="table">
    <tr class="mat-small mat-secondary table-head">
      <td class="mat-dialog-bg mat-secondary-color center" style="border-left: none">№</td>

      <td class="mat-dialog-bg mat-secondary-color" style="width: 100%; padding: 2px 4px" colspan="4">
        {{ "COMMON.NAME" | translate }}
      </td>
    </tr>

    <tr *ngFor="let item of objects; let i = index" class="mat-hover">
      <td>{{ i + 1 }}.</td>

      <td>
        <ng-container
          *ngTemplateOutlet="
            statusButton;
            context: {
              objectId: item.trafficObjectId,
              schemaSet: getSchemaSet(item.trafficObjectId)
            }
          "
        ></ng-container>
      </td>

      <td style="width: 100%">{{ getObjectName(item.trafficObjectId) }}</td>

      <td>
        <ng-container
          *ngTemplateOutlet="
            statusIcon;
            context: {
              status: getObjectStatus(item.trafficObjectId)
            }
          "
        ></ng-container>
      </td>

      <td class="center">{{ getObjectPlan(item.trafficObjectId) }}</td>
    </tr>
  </table>
</div>

<ng-template #statusButton let-objectId="objectId" let-schemaSet="schemaSet">
  <div class="state-button-svg mat-border-light">
    <object
      *ngIf="schemaSet"
      type="image/svg+xml"
      data="/assets/images/tlc/layout_button.svg"
      (load)="statusSvgLoad($event.target, objectId)"
      [style.transform]="'rotate(' + schemaSet?.schemaView.angle + 'deg)'"
    ></object>
  </div>
</ng-template>

<ng-template #statusIcon let-status="status">
  <div class="timer mat-dialog-bg1" [class.active]="true" [style.background-color]="getColor(status?.iconStatus?.borderColor)">
    <div class="spin" [class.active]="status?.iconStatus?.processing"></div>
    <div class="time" [style.background-color]="getColor(status?.iconStatus?.color)">
      <span>{{ getObjectModeTime(status) }}</span>
    </div>
  </div>
</ng-template>
