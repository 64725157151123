import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';

import {
  Component,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  OnDestroy,
  Input,
  AfterViewInit,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { EMPTY, Observable, Subject } from 'rxjs';
import { finalize } from 'rxjs/operators';

import { TranslateService } from '@ngx-translate/core';
import { SettingsService } from 'projects/msu-its-web-common/src/services/settings.service';
import { DialogService } from 'projects/msu-its-web-common/src/services/dialog.service';
import { FlashService } from 'projects/msu-its-web-common/src/services/flash.service';

import { PanelStandService } from 'projects/msu-its-web-ruis/src/services/panel-stands/panel-stand.service';
import { PanelService } from 'projects/msu-its-web-ruis/src/services/panels/panel.service';
import {
  PanelAvailableSchedule,
  PanelMode,
  PanelStatus,
} from 'projects/msu-its-web-ruis/src/dtos/ruis.dtos';
import { PanelControllerService } from '../../services/panel-controllers/panel-controller.service';

@Component({
  selector: 'panel-stand-state-panel',
  templateUrl: './panel-stand-state-panel.component.html',
  styles: [
    `
      .backdrop-inner {
        opacity: 0.98;
        padding: 12px 16px;
        border-radius: 5px;
        color: #fff;
        pointer-events: all;
        max-width: 350px;
      }

      .backdrop {
        position: absolute;
        z-index: 5;
        pointer-events: none;
        overflow: visible;
      }

      mat-button-toggle-group {
        display: flex;
        flex: 1;
      }
      mat-button-toggle {
        flex: 1;
      }
      mat-button-toggle.mat-button-toggle-disabled {
        pointer-events: none;
      }
      mat-button-toggle-group ::ng-deep .mat-button-toggle-label-content {
        line-height: 40px;
      }

      .mat-button-toggle-checked[value='Off'] {
        background-color: rgba(0, 0, 0, 0.8) !important;
        color: #fff !important;
      }
      .mat-button-toggle-checked[value='Work'] {
        background-color: #1b1 !important;
        color: #fff !important;
      }

      .thumbnail {
        min-width: 250px;
        min-height: 100px;
        display: flex;
        align-self: center;
        align-items: center;
        justify-content: center;
      }
      .thumbnail > img {
        max-width: 250px;
        max-height: 100px;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PanelStandStatePanelComponent implements OnDestroy, AfterViewInit {
  private _destroy = new Subject();

  loading = false;

  @Input()
  status: PanelStatus;

  customerId: string;

  schedules: PanelAvailableSchedule[] = [];

  get isOffline() {
    return !this.status.online;
  }
  get isError() {
    return false;
  }

  get isEngineerMode() {
    return this.status.mode === PanelMode.Engineer;
  }
  get isWorkMode() {
    return this.status.mode === PanelMode.Work;
  }
  get isOffMode() {
    return this.status.mode === PanelMode.Off;
  }
  get isDiagnosticMode() {
    return this.status.mode === PanelMode.Diagnostic;
  }

  get controllerStatus() {
    return this.status?.controllerStatus;
  }

  get statusString() {
    const scheduleName = this.status.schedule?.name;
    const scheduleDesc = this.status.schedule?.description;
    const sch = scheduleName ? scheduleName + (scheduleDesc ? `(${scheduleDesc})` : '') : '';

    const programName = this.status.program?.name;
    const programDesc = this.status.program?.description;
    const prg = programName ? programName + (programDesc ? `(${programDesc})` : '') : '';

    const result = (sch ? `${sch}: ` : '') + (prg ? `${prg}` : '');
    return result || '—';
  }

  get timezone() {
    return this._settingsService.timezone;
  }
  get locale() {
    return this.translate.currentLang;
  }

  constructor(
    private _dialogService: DialogService,
    private _flashService: FlashService,
    private _route: ActivatedRoute,
    private _changeDetector: ChangeDetectorRef,
    private _panelControllerService: PanelControllerService,
    private _panelStandService: PanelStandService,
    private _panelService: PanelService,
    private _settingsService: SettingsService,
    public translate: TranslateService
  ) {
    this.customerId = this._route.snapshot.paramMap.get('customerId');
  }

  ngAfterViewInit() {
    // this.loadSchedules();
  }

  ngOnDestroy() {
    this._destroy.next();
    this._destroy.complete();
  }

  toggleGroupChange(event) {
    const toggle = event.source;
    if (toggle && this.status) {
      const group = toggle.buttonToggleGroup;
      group.value = this.status.mode;
    }
  }

  getThumbnailUrl(thumbnailId: string) {
    return this._panelStandService.getThumbnailUrl(thumbnailId);
  }

  loadingQuery<T>(query: Observable<T>) {
    if (this.loading) {
      return EMPTY;
    }
    this.loading = true;
    this._changeDetector.markForCheck();
    return query.pipe(
      finalize(() => {
        this.loading = false;
        this._changeDetector.markForCheck();
      })
    );
  }

  loadSchedules() {
    this.loadingQuery(
      this._panelService.getAvailableSchedules(this.status.id, this.customerId)
    ).subscribe((schedules) => {
      this.schedules = schedules;
      this._changeDetector.markForCheck();
    });
  }

  setOffMode() {
    this.loadingQuery(this._panelService.setOffModel(this.status.id, this.customerId)).subscribe(
      () => {
        this._flashService.success(this.translate.instant('COMMAND.SENT'));
      }
    );
  }
  setWorkMode(schedule: PanelAvailableSchedule) {
    this.loadingQuery(
      this._panelService.setWorkModel(this.status.id, this.customerId, schedule.id)
    ).subscribe(() => {
      this._flashService.success(this.translate.instant('COMMAND.SENT'));
    });
  }
  uploadSettings() {
    this.loadingQuery(
      this._panelControllerService.uploadSettings(this.status.panelControllerId, this.customerId)
    ).subscribe(() => {
      this._flashService.success(this.translate.instant('COMMAND.SENT'));
    });
  }

  settings() {
    this._dialogService.windowDialog(
      `${this.customerId}/ext/panel/${this.status.id}/settings`,
      'panelSettings_' + this.status.id,
      'menubar=0,toolbar=0,titlebar=0,location=0,locationbar=0,width=1050,height=800,resizable=0'
    );
  }
  brightnessSettings() {
    this._dialogService.windowDialog(
      `${this.customerId}/ext/panel/${this.status.id}/brightness-settings`,
      'panelBrightnessSettings_' + this.status.id,
      'menubar=0,toolbar=0,titlebar=0,location=0,locationbar=0,width=1130,height=800,resizable=0'
    );
  }
}
