import { marker as _ } from "@biesbjerg/ngx-translate-extract-marker";
import {
  Component,
  ChangeDetectionStrategy,
  Input,
  OnInit,
  ChangeDetectorRef,
  ElementRef,
  ViewChild,
} from "@angular/core";

import { IGisObjectStateComponent } from "projects/msu-its-web-common/src/utils/gis-object-group";
import { DialogService } from "projects/msu-its-web-common/src/services/dialog.service";
import { Observable, of } from "rxjs";
import { TokenService } from "projects/msu-its-web-common/src/services/token.service";
import { FilesComponent } from "projects/msu-its-web-common/src/components/files/files.component";
import {
  MaintenanceVehicle,
  MaintenanceVehicleStatus,
} from "projects/msu-its-web-rmmd/src/dtos/rmmd.dtos";
import { FormControl } from "@angular/forms";
import { SettingsService } from "projects/msu-its-web-common/src/services/settings.service";
import { Subject } from "rxjs";
import { VehicleGroupEventService } from "projects/msu-its-web-rmmd/src/services/vehicle/vehicle-group-event.service";
import { delay, repeat, takeUntil } from "rxjs/operators";
import {
  getImgSrc,
  maintenanceTransportSvg,
} from "projects/msu-its-web-common/src/utils/map-markers";
import { TranslateService } from "@ngx-translate/core";
import { FlashService } from "projects/msu-its-web-common/src/services/flash.service";

@Component({
  selector: "vehicle-state",
  templateUrl: "./vehicle-state.component.html",
  styles: [
    `
      :host {
        display: flex;
        flex: 1;
        width: 100%;
        height: 100%;
      }
      .date-button {
        pointer-events: none;
        font-size: 16px;
        position: absolute;
        right: 0;
        width: 20px;
      }
      .state-image {
        width: 44px;
        height: 44px;
        border: none;
        margin-right: 8px;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VehicleStateComponent implements OnInit, IGisObjectStateComponent {
  private _destroy = new Subject();
  @Input()
  modelId: string;

  @Input()
  customerId: string;

  @ViewChild("stateImage", { static: false })
  stateImage: ElementRef;

  @Input()
  modelInfo: MaintenanceVehicle;

  modelData: MaintenanceVehicleStatus;

  modelPermissions$: Observable<string[]> = of(null);

  loading = false;
  showExternalErrorMessage = false;

  //   actions: { name: string; action: string; permissions?: string[] }[] = [
  //     // { name: _('COMMON.SETTINGS'), action: 'settings', permissions: ['parkingKiosk.update'] },
  //     // {
  //     //   name: _("COMMON.EDIT"),
  //     //   action: "edit",
  //     //   permissions: ["parkingKiosk.update"],
  //     // },
  //     // { name: _('AUDIT_LOG.TITLE'), action: 'auditLog', permissions: ['parkingKiosk.read'] },
  //     // { name: _('EVENT_LOG.TITLE'), action: 'eventLog', permissions: ['parkingKiosk.read'] },
  //     // { name: _('COMMON.FILES'), action: 'files', permissions: ['parkingKiosk.read'] },
  //   ];
  actions = null;

  fromControl = new FormControl();
  toControl = new FormControl();

  constructor(
    private _vehicleService: VehicleGroupEventService,
    private _tokenService: TokenService,
    private _dialogService: DialogService,
    private _settingsService: SettingsService,
    private _changeDetectors: ChangeDetectorRef,
    public translate: TranslateService,
    private _flashService: FlashService
  ) {}

  ngOnDestroy() {
    this._destroy.next();
    this._destroy.complete();
  }
  ngOnInit() {
    this.loading = true;
    this._vehicleService
      .get(this.modelId, this.customerId)
      .pipe(takeUntil(this._destroy))
      .subscribe((res) => {
        this.modelData = res;
        this._updateStateImage();
        this.loading = false;

        !res.online && res.errorDescription
          ? (this.showExternalErrorMessage = true)
          : (this.showExternalErrorMessage = false);

        this._changeDetectors.markForCheck();
      });

    this._updateActionsByPermissions();
  }

  private _updateActionsByPermissions() {
    this.modelPermissions$.subscribe((m) => {
      this.actions = this.actions?.filter((a) =>
        this._tokenService.hasPermissionsForItem(
          this.modelInfo,
          this.customerId,
          a.permissions,
          m
        )
      );
    });
  }

  private _getExternalUrlBase() {
    return `${this.customerId}/ext/parking-kiosk/${this.modelId}/`;
  }

  get timezone() {
    return this._settingsService.timezone;
  }

  //   openInNew() {
  //     this._dialogService.windowDialog(
  //       this._getExternalUrlBase() + "state",
  //       "parkingKioskState_" + this.modelId,
  //       "menubar=0,toolbar=0,titlebar=0,location=0,locationbar=0,width=600,height=810,resizable=0"
  //     );
  //   }

  edit() {
    this._dialogService.windowDialog(
      this._getExternalUrlBase() + "edit",
      "parkingKioskEdit_" + this.modelId,
      "menubar=0,toolbar=0,titlebar=0,location=0,locationbar=0,width=1050,height=750,resizable=0"
    );
  }

  settings() {
    this._dialogService.windowDialog(
      this._getExternalUrlBase() + "settings",
      "parkingKioskSettings_" + this.modelId,
      "menubar=0,toolbar=0,titlebar=0,location=0,locationbar=0,width=1050,height=700,resizable=0"
    );
  }

  auditLog() {
    this._dialogService.windowDialog(
      this._getExternalUrlBase() + "audit-log",
      "parkingAuditLog_" + this.modelId,
      "menubar=0,toolbar=0,titlebar=0,location=0,locationbar=0,width=950,height=850,resizable=0"
    );
  }

  eventLog() {
    this._dialogService.windowDialog(
      this._getExternalUrlBase() + "event-log",
      "parkingEventLog_" + this.modelId,
      "menubar=0,toolbar=0,titlebar=0,location=0,locationbar=0,width=950,height=850,resizable=0"
    );
  }

  files() {
    const dialog = this._dialogService.dialog.open(FilesComponent, {
      disableClose: false,
    });
    dialog.componentInstance.entityId = this.modelId;
    dialog.componentInstance.entityName = this.modelInfo.number;
  }

  _updateStateImage() {
    if (this.stateImage) {
      this.stateImage.nativeElement.src = getImgSrc(
        maintenanceTransportSvg,
        this.modelData?.online ? "#254b7e" : "#254b7e",
        this.modelData?.online ? "white" : "red"
      );
    }
  }

  download() {
    this.loading = true;
    setTimeout(() => {
      this._flashService.warn(
        this.translate.instant("ROAD_MAINTENANCE_VEHICLE.SERVICE_OFFLINE")
      );
      this.loading = false;
      this._changeDetectors.markForCheck();
    }, 1000);
  }
}
