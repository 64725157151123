import { Injectable } from '@angular/core';

import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import {
  IDataSourceService,
  setPageParams,
  getPageReturn,
  IPageParams,
  IPageReturn,
} from '../data/data-source';

import {
  User,
  GetUsersRequest,
  UserInfo,
  GetUserRequest,
  CreateUserRequest,
  UpdateUserRequest,
  DeleteUsersRequest,
  GetUserRolesRequest,
  UpdateUserRolesRequest,
  GetAllUsersRequest,
  ResetPasswordRequest,
  ExistUserNameRequest,
  LogoutEverywhereRequest,
} from '../dtos/hub.dtos';

import { HubApiService } from './hub-api.service';

@Injectable()
export class UserService implements IDataSourceService<User, UserInfo> {
  constructor(private _api: HubApiService) {}

  getPagedList(
    params: IPageParams,
    customerId: string
  ): Observable<IPageReturn<UserInfo>> {
    const request = customerId
      ? new GetUsersRequest({ customerId })
      : new GetAllUsersRequest();

    setPageParams(request, params);
    return this._api.get(request).pipe(
      map((m) => {
        return getPageReturn(m);
      })
    );
  }

  get(id: string, customerId: string): Observable<User> {
    const request = new GetUserRequest();
    request.id = id;
    request.customerId = customerId;
    return this._api.get(request);
  }

  add(
    user: User,
    params: { customerId: string; password: string }
  ): Observable<User> {
    const request = new CreateUserRequest();
    request.entity = user;
    request.password = params.password;
    request.customerId = params.customerId;
    return this._api.post(request);
  }

  update(user: User, customerId: string): Observable<User> {
    const request = new UpdateUserRequest();
    request.id = user.id;
    request.entity = user;
    request.customerId = customerId;
    return this._api.put(request);
  }

  delete(ids: string[], customerId: string): Observable<number> {
    const request = new DeleteUsersRequest();
    request.ids = ids;
    request.customerId = customerId;
    return this._api.delete(request);
  }

  exist(userName: string) {
    const request = new ExistUserNameRequest();
    request.userName = userName;
    return this._api.get(request);
  }

  resetPassword(id: string, password: string) {
    const request = new ResetPasswordRequest();
    request.id = id;
    request.password = password;
    return this._api.put(request);
  }

  getRoles(id: string, customerId: string) {
    const request = new GetUserRolesRequest();
    request.id = id;
    request.customerId = customerId;
    return this._api.get(request);
  }

  updateRoles(id: string, roles: string[], customerId: string) {
    const request = new UpdateUserRolesRequest();
    request.id = id;
    request.entity = roles;
    request.customerId = customerId;
    return this._api.put(request);
  }

  // getProfile(): Observable<User> {
  //   throw 'not implemented';
  //   // const request = new GetProfile();
  //   // return this._api.get(request);
  // }

  // updateProfile(user: User): Observable<User> {
  //   throw 'not implemented';
  //   // const request = new UpdateProfile();
  //   // request.Profile = user;
  //   // return this._api.put(request);
  // }

  logoutEverywhere(userId: string): Observable<boolean> {
    const request = new LogoutEverywhereRequest();
    request.id = userId;
    return this._api.post(request);
  }

  getPermissions(id: string, customerId: string) {
    return of([]);
    // const request = new GetUserCurrentUserPermissionsRequest();
    // request.id = id;
    // request.customerId = customerId;
    // return this._api.get(request);
  }
}
