import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ComponentFactoryResolver,
  ElementRef,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import * as echarts from 'echarts';

import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { TrafficObjectReportService } from '../../services/traffic-object-report.service';
import { Subject } from 'rxjs';
import { TrafficObjectDetectorInfo } from '../../dtos/tss.dtos';
import { SCHEMA_SVG } from '../../dtos/schema-report';
import { finalize } from 'rxjs/operators';
import { SchemaSet } from 'projects/msu-its-web-tlc/src/dtos/schema';
import { MapService } from 'projects/msu-its-web-common/src/services/map.service';
import { ECharts } from 'echarts';
import { TranslateService } from '@ngx-translate/core';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import {
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
  MomentDateAdapter,
} from '@angular/material-moment-adapter';
export const MY_FORMATS = {
  parse: {
    dateInput: 'DD.MM.YYYY',
  },
  display: {
    dateInput: 'DD.MM.YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};
@Component({
  selector: 'traffic-object-report',
  templateUrl: './traffic-object-report.component.html',
  styleUrls: ['./traffic-object-report.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },

    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class TrafficObjectReportComponent implements OnInit, AfterViewInit {
  private _destroy = new Subject();
  @Input()
  trafficObjectId: string;
  @Input()
  trafficObjectName: string;
  loading = false;

  arrayMyChart: [{ name: string; array: ECharts[] }];

  detectorsArray: TrafficObjectDetectorInfo[] = [];

  _schemaMap: any;
  mapEnabled = true;

  @Input()
  disabled = false;

  @Input()
  schemaSet: SchemaSet;

  isDisabled = true;

  @ViewChild('schemaSvg', { static: false })
  _schemaSvgContainer: ElementRef;
  _schemaSvg;

  @ViewChild('schemaMap', { static: false })
  _schemaMapContainer: ElementRef;

  @Input()
  schemaChanged: Subject<any>;

  get schema() {
    return this.schemaSet.schema;
  }
  get schemaView() {
    return this.schemaSet.schemaView;
  }

  formGroup: FormGroup = this._formBuilder.group({
    Date1: [null, [Validators.required]],
    Date2: [null, [Validators.required]],
  });
  get date1() {
    return this.formGroup.get('Date1');
  }
  get date2() {
    return this.formGroup.get('Date2');
  }

  get locale() {
    return this.translate.currentLang;
  }
  constructor(
    private _formBuilder: FormBuilder,
    private _changeDetector: ChangeDetectorRef,
    private dateAdapter: DateAdapter<any>,
    private _mapService: MapService,
    private _trafficObjectReportService: TrafficObjectReportService,
    private _dialogRef: MatDialogRef<TrafficObjectReportComponent>,
    private translate: TranslateService
  ) {}

  ngAfterViewInit(): void {
    // this.date1.valueChanges.subscribe(() => {
    //   this._loadData();
    // });
    // this.date2.valueChanges.subscribe(() => {
    //   this._loadData();
    // });
  }
  ngOnInit() {
    // this.loadData();
    this.dateAdapter.setLocale(this.locale);
  }
  download() {
    document
    .getElementById('download')
    .setAttribute(
      'href',
      `${this._trafficObjectReportService.getUrl(
        this.trafficObjectId,
        this.date2.value,
        this.date1.value
      )}`
    );
  }
  close() {
    this._dialogRef.close();
  }
  public loadData(): any {
    if (!this.formGroup.valid) {
      this.formGroup.markAllAsTouched();
      return;
    }
    this.detectorsArray = [];

    this.loading = true;
    this._trafficObjectReportService
      .getTrafficObjectDetectorsReport(
        this.trafficObjectId,
        // new DatePipe('en').transform(this.date2.value, 'yyyy-MM-dd'),
        // new DatePipe('en').transform(this.date1.value, 'yyyy-MM-dd')
        this.date1.value,
        this.date2.value

      )
      .pipe(
        finalize(() => {
          this.loading = false;
          this._changeDetector.markForCheck();
        })
      )
      .subscribe((result) => {
        this.detectorsArray = result.detectors;
        this._changeDetector.markForCheck();
        setTimeout(() => {
          this._initPipe();

          this._initSchemeMap();
          this._initSchemaSvg();
          this.isDisabled = false;
          this._changeDetector.markForCheck();

        }, 0);
        this.isDisabled = false;
        this._changeDetector.markForCheck();

 
      });

    this.isDisabled = false;
  }
  private time(value) {
    const _time = new Date(value * 1000);
    let label;
    if (_time.getMinutes() < 10) {
      label = _time.getHours() + ':0' + _time.getMinutes();
    } else {
      label = _time.getHours() + ':' + _time.getMinutes();
    }
    return label;
  }
  private _initPipe(): void {
    // let myChart: any = null;

    this.arrayMyChart = [] as any;
    this.detectorsArray.forEach((item) => {
      if (item.graphItems.length !== 0) {
        // document.getElementById(item.movement).();
        // let myChart: any = echarts.getInstanceByDom(document.getElementById(item.movement) as any);
        // if (!myChart) {
        //   myChart = echarts.init(document.getElementById(item.movement) as any, '', {
        //     width: 700,
        //     height: 400,
        //   });
        // }
        const myChart: any = echarts.init(document.getElementById(item.movement) as any);
        // document.getElementById(item.movement).style.width = '700px';
        // document.getElementById(item.movement).style.height = '400px';
        // console.log(document.getElementById(item.movement).clientHeight);
        // console.log(document.getElementById(item.movement).clientWidth);
        // myChart.resize();

        const xAxisData = [];
        const data1 = [];
        const data2 = [];
        const data3 = [];
        const data4 = [];
        item.graphItems.forEach((dataGraph) => {
          xAxisData.push(this.time(dataGraph.time));
          data1.push(dataGraph.avgSpeed);
          data2.push(dataGraph.histAvgSpeed);
          data3.push(dataGraph.count);
          data4.push(dataGraph.histCount);
        });

        const options = {
          grid: {
            top: '7%',
            bottom: '7%',
            right: '3%',
          },
          legend: {
            show: false,
            data: [
              'Средняя скорость',
              'Средняя скорость(стат.)',
              'Количество',
              'Количество(стат.)',
            ],
            selected: {
              'Средняя скорость': false,
              'Средняя скорость(стат.)': false,
              // tslint:disable-next-line:object-literal-key-quotes
              Количество: true,
              'Количество(стат.)': true,
            },
          },
          tooltip: {
            trigger: 'axis',
          },
          xAxis: {
            data: xAxisData,
            type: 'category',
            silent: false,
            splitLine: {
              show: false,
            },
            axisLabel: {
              textStyle: {
                color: 'white',
              },
            },
          },
          yAxis: {
            type: 'value',
            axisLabel: {
              textStyle: {
                color: 'white',
              },
              formatter: `{value} шт`,
            },
          },
          series: [
            {
              name: 'Средняя скорость',
              type: 'line',
              data: data1,
              animationDelay: (idx) => idx * 10,
            },
            {
              name: 'Средняя скорость(стат.)',
              type: 'line',
              data: data2,
              animationDelay: (idx) => idx * 10 + 100,
            },
            {
              name: 'Количество',
              type: 'line',
              data: data3,
              animationDelay: (idx) => idx * 10,
            },
            {
              name: 'Количество(стат.)',
              type: 'line',
              data: data4,
              animationDelay: (idx) => idx * 10 + 100,
            },
          ],
          animationEasing: 'elasticOut',
          animationDelayUpdate: (idx) => idx * 5,
        };

        myChart.setOption(options, true);
        document.getElementById(`speed_${item.movement}`).onclick = () => {
          myChart.setOption({
            legend: {
              selected: {
                'Средняя скорость': true,
                'Средняя скорость(стат.)': true,
                // tslint:disable-next-line:object-literal-key-quotes
                Количество: false,
                'Количество(стат.)': false,
              },
            },
            yAxis: {
              axisLabel: {
                formatter: `{value} км/ч`,
              },
            },
          });
          this._changeDetector.markForCheck();
        };
        document.getElementById(`count_${item.movement}`).onclick = () => {
          myChart.setOption({
            legend: {
              selected: {
                'Средняя скорость': false,
                'Средняя скорость(стат.)': false,
                // tslint:disable-next-line:object-literal-key-quotes
                Количество: true,
                'Количество(стат.)': true,
              },
            },
            yAxis: {
              axisLabel: {
                formatter: `{value} шт`,
              },
            },
          });
          this._changeDetector.markForCheck();
        };
        this.loading = false;
        // myChart.resize();
        // this._changeDetector.markForCheck();
        this.arrayMyChart.push({ name: item.movement, array: myChart });
      }
    });
  }
  _initSchemaSvg() {
    this._schemaSvgContainer.nativeElement.innerHTML = SCHEMA_SVG;
    this._schemaSvg = this._schemaSvgContainer.nativeElement.querySelector('svg');
    // this.updateChanges();
    const schemaView = this.schemaView;
    const schema = this.schema;

    if (this.schemaView && this._schemaMap) {
      const viewSettings = this.schemaView;
      this._schemaMap.setView([viewSettings.lat, viewSettings.lon], viewSettings.zoom);
    }

    if (this._schemaSvg && schemaView && schema) {
      this.schema.updateDeadEnds();

      this._updateSvgLabel('MajorStreet_Label', schemaView.majorStreet);
      this._updateSvgLabel('MinorStreet_Label', schemaView.minorStreet);
      this._updateSvgLabel('Way2_Label', schemaView.way2);
      this._updateSvgLabel('Way6_Label', schemaView.way6);
      this._updateSvgLabel('Way4_Label', schemaView.way4);
      this._updateSvgLabel('Way8_Label', schemaView.way8);
      this._updateSvgMovement('Move2', false, true, true);
      this._updateSvgMovement('Move4', false, true, true);
      this._updateSvgMovement('Move6', false, true, true);
      this._updateSvgMovement('Move8', false, true, true);
      this.detectorsArray?.forEach((item) => {
        this._updateSvgLabelPercent(
          item.movement,
          item.avgSpeedDiffPercent,
          item.avgCountDiffPercent
        );
      });
      // this._updateSvgMovement('Move1', true);
      // this._updateSvgMovement('Move3', true);
      // this._updateSvgMovement('Move5', true);
      // this._updateSvgMovement('Move7', true);
      // this._updateSvgMovement('Move12', true);
      // this._updateSvgMovement('Move14', true);
      // this._updateSvgMovement('Move16', true);
      // this._updateSvgMovement('Move18', true);
      // this._updateSvgMovement('MoveP2', false, schema.getWay8Enabled());
      // this._updateSvgMovement('MoveP4', false, schema.getWay2Enabled());
      // this._updateSvgMovement('MoveP6', false, schema.getWay4Enabled());
      // this._updateSvgMovement('MoveP8', false, schema.getWay6Enabled());
    }

    // setTimeout(() => {
    //   this._changeDetector.markForCheck();
    // });
    // this._changeDetector.markForCheck();
  }

  _initSchemeMap() {
    // if (!this._schemaMap) {
    this._schemaMap = this._mapService
      .initMap(this._schemaMapContainer.nativeElement, {
        trafficLayer: true,
        scrollWheelZoom: false,
      })
      .on('moveend', (event) => {
        const zoom = this._schemaMap.getZoom();
        const center = this._schemaMap.getCenter();
        this.schemaView.zoom = zoom;
        this.schemaView.lat = center.lat;
        this.schemaView.lon = center.lng;
      });
    this._changeDetector.markForCheck();
    // }
  }

  private _updateSvgLabel(svgLabelId, text) {
    this._schemaSvg.getElementById(svgLabelId).textContent = text;
  }
  private _updateSvgLabelPercent(svgLabelId, speed, count) {
    if (speed !== 0) {
      this._schemaSvg.getElementById(`${svgLabelId}_LabelPercentSpeed`).textContent = `${speed}%`;
      this._schemaSvg.getElementById(`${svgLabelId}_LabelPercentSpeed`).style[
        'fill'
      ] = this.movementPercentColor(speed);
    }
    if (count !== 0) {
      this._schemaSvg.getElementById(`${svgLabelId}_LabelPercentCount`).textContent = `${count}%`;
      this._schemaSvg.getElementById(`${svgLabelId}_LabelPercentCount`).style[
        'fill'
      ] = this.movementPercentColor(count);
    }
    this._changeDetector.markForCheck();
  }

  private _updateSvgMovement(
    svgMovementId,
    canProtected = false,
    canEnabled = true,
    canDeadEnd = false
  ) {
    const movement = this._schemaSvg.getElementById(svgMovementId);
    const settingsMovement = this.schema[svgMovementId];
    settingsMovement.Enabled = canEnabled ? settingsMovement.enabled : false;
    movement.classList.toggle('active', settingsMovement.enabled);
    movement.classList.toggle('notprotected', canProtected ? !settingsMovement.controlled : false);
    if (canDeadEnd) {
      movement.setAttribute('xlink:href', settingsMovement.deadEnd ? '#deadend' : '#direct');
    }
  }

  movementString(movement: string): any {
    return movement.replace('Move', 'Направление ');
  }
  movementPercentColor(movement: number): any {
    return movement < 0 ? 'red' : 'green';
  }
  abs(number): number {
    return Math.abs(number);
  }
}
