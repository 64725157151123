import { Component, ChangeDetectionStrategy } from '@angular/core';
import { FormControl, Validators, ValidatorFn } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'prompt-dialog',
  template: `
    <mat-dialog-content style="min-width: 250px;">
      <div style="margin: 0 0 12px;">{{ title }}</div>
      <mat-form-field style="font-size: 14px; display: block;">
        <input matInput cdkFocusInitial [formControl]="prompt" />
        <mat-label>{{ label }}</mat-label>
        <mat-hint>{{ hint }}</mat-hint>
        <mat-error *ngIf="prompt.invalid">{{ errorMessage }}</mat-error>
      </mat-form-field>
    </mat-dialog-content>
    <mat-dialog-actions align="end">
      <button mat-button (click)="ok()">{{ 'COMMON.OK' | translate }}</button>
      <button mat-button [mat-dialog-close]="false">
        {{ 'COMMON.CANCEL' | translate }}
      </button>
    </mat-dialog-actions>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PromptDialogComponent {
  title: string;
  label: string;
  hint: string;
  errorMessage: string;
  prompt = new FormControl('', [Validators.required]);

  constructor(
    public translate: TranslateService,
    private _dialogRef: MatDialogRef<PromptDialogComponent>
  ) {
    this.errorMessage = this.translate.instant('COMMON.REQUIRED');
  }

  set validator(validatorFn: ValidatorFn) {
    const validators = validatorFn
      ? [Validators.required, validatorFn]
      : [Validators.required];
    this.prompt.setValidators(validators);
  }

  ok() {
    if (this.prompt.valid) {
      this._dialogRef.close(this.prompt.value);
    }
  }
}
