import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { Subject } from 'rxjs';

import { TranslateService } from '@ngx-translate/core';
import { DialogService } from 'projects/msu-its-web-common/src/services/dialog.service';
import {
  EscortStepStatus,
  IEscortStep,
  RouteEscortStateService,
} from './route-escort-state.service';
import { SettingsService } from 'projects/msu-its-web-common/src/services/settings.service';

import { RecoveryMovement, RouteObject, StepMovement } from '../../dtos/tlc.dtos';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'route-escort-progress',
  templateUrl: './route-escort-progress.component.html',
  styleUrls: ['./route-escort-progress.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RouteEscortProgressComponent implements OnInit, OnDestroy {
  _destroy = new Subject();

  get selectedRoute() {
    return this._stateService.selectedRoute;
  }

  get escortSteps() {
    return this._stateService.escortSteps
      .filter((m) => m.status != EscortStepStatus.Completed)
      .reverse();
  }

  get suspendTime() {
    return this._settingsService.escortSuspendTime;
  }
  set suspendTime(value) {
    this._settingsService.escortSuspendTime = value;
  }

  get test() {
    return this._stateService.test;
  }
  set test(value) {
    this._stateService.test = value;
  }

  constructor(
    private _stateService: RouteEscortStateService,
    private _dialogService: DialogService,
    private _settingsService: SettingsService,
    private _changeDetector: ChangeDetectorRef,
    public translate: TranslateService
  ) {}

  ngOnDestroy() {
    this._destroy.next();
    this._destroy.complete();
    this._destroy = null;
  }

  ngOnInit() {
    this._stateService.escortStepsChanged.pipe(takeUntil(this._destroy)).subscribe(() => {
      this._changeDetector.markForCheck();
    });
  }

  stop() {
    this._dialogService
      .confirm(this.translate.instant(_('ROUTE_ESCORT.COMPLETE_ESCORT?')))
      .subscribe((confirm) => {
        if (confirm) {
          this._stateService.stopEscort();
          this._changeDetector.markForCheck();
        }
      });
  }

  svgLoad(target, item: RouteObject, step: StepMovement | RecoveryMovement) {
    this._stateService.objectSvgLoad(target, item, step);
  }
  getAngle(item: RouteObject) {
    return this._stateService.getObjectAngle(item);
  }
  getName(item: RouteObject) {
    return this._stateService.getObjectName(item);
  }

  stopStep(item: IEscortStep) {
    this._stateService.stopStep(item.routeObject.trafficObjectId).subscribe();
  }
  startStep(item: IEscortStep) {
    this._stateService.startStep(item.routeObject.trafficObjectId).subscribe();
  }
  getSuspendTime(item: IEscortStep) {
    const now = Date.now();
    if (item.completeTime > now) {
      return Math.round((item.completeTime - now) / 1000);
    }
    return '';
  }
}
