export const fakeModels = {
  items: [
    {
      lat: 56.102199,
      lon: 93.296273,
      note: "",
      customerId: "1996d489-d64c-4352-8cd9-49ab78e24a50",
      customerName: "Крудор",
      name: "район Березовский, а/д Есаулово-Бархатово, км 4+900",
      serial: "MDC02306-000020",
      phone: "",
      ipaddress: "10.90.0.2:8081",
      description: "",
      changeKey: "e4a6b211-7b26-432e-9f86-e6c37bc36bbf",
      created: "2023-06-06T04:55:03.4442320Z",
      createdBy: "1140c337-f547-4245-9f21-68d04890e452",
      modified: "2023-06-15T01:41:57.7235600Z",
      modifiedBy: "a1022248-9283-46cd-950a-b8a676d463e0",
      id: "5a38bfb2-107c-4c2b-8685-9778e6565297",
      entityType: "ftpCamera",
    },
    {
      lat: 56.129751,
      lon: 93.332101,
      note: "",
      customerId: "1996d489-d64c-4352-8cd9-49ab78e24a50",
      customerName: "Крудор",
      name: "район Березовский, а/д Красноярск-Железногорск, км 19+780",
      serial: "MDC02306-000021",
      phone: "",
      ipaddress: "10.90.0.22:8081",
      description: "",
      changeKey: "e4a6b211-7b26-432e-9f86-e6c37bc36bbf",
      created: "2023-06-06T04:55:03.4442320Z",
      createdBy: "1140c337-f547-4245-9f21-68d04890e452",
      modified: "2023-06-15T01:41:57.7235600Z",
      modifiedBy: "a1022248-9283-46cd-950a-b8a676d463e0",
      id: "7aca528a-9551-494f-b542-4845b85e1b07",
      entityType: "ftpCamera",
    },
    {
      lat: 56.103735,
      lon: 93.294947,
      note: "",
      customerId: "1996d489-d64c-4352-8cd9-49ab78e24a50",
      customerName: "Крудор",
      name: "район Березовский, а/д Красноярск-Железногорск, км 15+910 #1",
      serial: "MDC02306-000022",
      phone: "",
      ipaddress: "10.90.0.198:8081",
      description: "",
      changeKey: "e4a6b211-7b26-432e-9f86-e6c37bc36bbf",
      created: "2023-06-06T04:55:03.4442320Z",
      createdBy: "1140c337-f547-4245-9f21-68d04890e452",
      modified: "2023-06-15T01:41:57.7235600Z",
      modifiedBy: "a1022248-9283-46cd-950a-b8a676d463e0",
      id: "5e438306-bdfc-400d-bc84-fbac890c205d",
      entityType: "ftpCamera",
    },
    {
      lat: 56.103375,
      lon: 93.294857,
      note: "",
      customerId: "1996d489-d64c-4352-8cd9-49ab78e24a50",
      customerName: "Крудор",
      name: "район Березовский, а/д Красноярск-Железногорск, км 15+910 #2",
      serial: "MDC02306-000023",
      phone: "",
      ipaddress: "10.90.0.20:8081",
      description: "",
      changeKey: "e4a6b211-7b26-432e-9f86-e6c37bc36bbf",
      created: "2023-06-06T04:55:03.4442320Z",
      createdBy: "1140c337-f547-4245-9f21-68d04890e452",
      modified: "2023-06-15T01:41:57.7235600Z",
      modifiedBy: "a1022248-9283-46cd-950a-b8a676d463e0",
      id: "a8f7e25d-7958-4ede-9e07-b3dcb7dc1621",
      entityType: "ftpCamera",
    },
    {
      lat: 56.138818,
      lon: 93.341007,
      note: "",
      customerId: "1996d489-d64c-4352-8cd9-49ab78e24a50",
      customerName: "Крудор",
      name: "район Березовский, а/д Красноярск-Железногорск, км 20+935",
      serial: "MDC02306-000024",
      phone: "",
      ipaddress: "10.90.0.199:8081",
      description: "",
      changeKey: "e4a6b211-7b26-432e-9f86-e6c37bc36bbf",
      created: "2023-06-06T04:55:03.4442320Z",
      createdBy: "1140c337-f547-4245-9f21-68d04890e452",
      modified: "2023-06-15T01:41:57.7235600Z",
      modifiedBy: "a1022248-9283-46cd-950a-b8a676d463e0",
      id: "583defd3-8f13-492a-9fbe-eb8dbed2b91b",
      entityType: "ftpCamera",
    },
    {
      lat: 56.185752,
      lon: 92.90009,
      note: "",
      customerId: "1996d489-d64c-4352-8cd9-49ab78e24a50",
      customerName: "Крудор",
      name: "район Березовский, а/д Емельяново-Частоостровское, км 13+500",
      serial: "MDC02306-000031",
      phone: 9029579305,
      ipaddress: "10.90.0.33",
      description: "",
      changeKey: "e4a6b211-7b26-432e-9f86-e6c37bc36bbf",
      created: "2023-06-06T04:55:03.4442320Z",
      createdBy: "1140c337-f547-4245-9f21-68d04890e452",
      modified: "2023-06-15T01:41:57.7235600Z",
      modifiedBy: "a1022248-9283-46cd-950a-b8a676d463e0",
      id: "45a6b74f-cc29-40e5-b666-bdea74779725",
      entityType: "ftpCamera",
    },
    {
      lat: 56.047419,
      lon: 92.835011,
      note: "",
      customerId: "1996d489-d64c-4352-8cd9-49ab78e24a50",
      customerName: "Крудор",
      name: "район Емельяновский, а/д Красноярск-Солонцы, км 0+625 #1",
      serial: "MDC02306-000032",
      phone: 9029579652,
      ipaddress: "10.90.0.44",
      description: "",
      changeKey: "e4a6b211-7b26-432e-9f86-e6c37bc36bbf",
      created: "2023-06-06T04:55:03.4442320Z",
      createdBy: "1140c337-f547-4245-9f21-68d04890e452",
      modified: "2023-06-15T01:41:57.7235600Z",
      modifiedBy: "a1022248-9283-46cd-950a-b8a676d463e0",
      id: "7e1bf942-ec53-422a-8448-ba55d3759fbf",
      entityType: "ftpCamera",
    },
    {
      lat: 56.04742,
      lon: 92.835486,
      note: "",
      customerId: "1996d489-d64c-4352-8cd9-49ab78e24a50",
      customerName: "Крудор",
      name: "район Емельяновский, а/д Красноярск-Солонцы, км 0+625 #2",
      serial: "MDC02306-000033",
      phone: 9029580787,
      ipaddress: "10.90.0.34",
      description: "",
      changeKey: "e4a6b211-7b26-432e-9f86-e6c37bc36bbf",
      created: "2023-06-06T04:55:03.4442320Z",
      createdBy: "1140c337-f547-4245-9f21-68d04890e452",
      modified: "2023-06-15T01:41:57.7235600Z",
      modifiedBy: "a1022248-9283-46cd-950a-b8a676d463e0",
      id: "021e07f6-91af-4b3f-9c9e-a2437edbf92d",
      entityType: "ftpCamera",
    },
    {
      lat: 56.057872,
      lon: 92.833072,
      note: "",
      customerId: "1996d489-d64c-4352-8cd9-49ab78e24a50",
      customerName: "Крудор",
      name: "район Емельяновский, а/д Красноярск-Солонцы, км 1+825 #1",
      serial: "MDC02306-000034",
      phone: 9029581125,
      ipaddress: "10.90.0.30",
      description: "",
      changeKey: "e4a6b211-7b26-432e-9f86-e6c37bc36bbf",
      created: "2023-06-06T04:55:03.4442320Z",
      createdBy: "1140c337-f547-4245-9f21-68d04890e452",
      modified: "2023-06-15T01:41:57.7235600Z",
      modifiedBy: "a1022248-9283-46cd-950a-b8a676d463e0",
      id: "042c3260-f477-4803-95bd-47c6e16cfcb3",
      entityType: "ftpCamera",
    },
    {
      lat: 56.057917,
      lon: 92.833432,
      note: "",
      customerId: "1996d489-d64c-4352-8cd9-49ab78e24a50",
      customerName: "Крудор",
      name: "район Емельяновский, а/д Красноярск-Солонцы, км 1+825 #2",
      serial: "MDC02306-000035",
      phone: 9029581172,
      ipaddress: "10.90.0.43",
      description: "",
      changeKey: "e4a6b211-7b26-432e-9f86-e6c37bc36bbf",
      created: "2023-06-06T04:55:03.4442320Z",
      createdBy: "1140c337-f547-4245-9f21-68d04890e452",
      modified: "2023-06-15T01:41:57.7235600Z",
      modifiedBy: "a1022248-9283-46cd-950a-b8a676d463e0",
      id: "75b386de-85c9-4e98-9d3e-f9ed9a1fb2d3",
      entityType: "ftpCamera",
    },
    {
      lat: 55.78413,
      lon: 93.724292,
      note: "",
      customerId: "1996d489-d64c-4352-8cd9-49ab78e24a50",
      customerName: "Крудор",
      name: "район Манский, а/д Саяны, км 374+360",
      serial: "MDC02307-000045",
      phone: 9029580787,
      ipaddress: "10.90.0.34",
      description: "",
      changeKey: "e4a6b211-7b26-432e-9f86-e6c37bc36bbf",
      created: "2023-06-06T04:55:03.4442320Z",
      createdBy: "1140c337-f547-4245-9f21-68d04890e452",
      modified: "2023-06-15T01:41:57.7235600Z",
      modifiedBy: "a1022248-9283-46cd-950a-b8a676d463e0",
      id: "69279be4-90cf-4d20-873d-c48775cdc500",
      entityType: "ftpCamera",
    },
    {
      lat: 55.751211,
      lon: 93.756476,
      note: "",
      customerId: "1996d489-d64c-4352-8cd9-49ab78e24a50",
      customerName: "Крудор",
      name: "район Манский, а/д Саяны, км 370+125",
      serial: "MDC02307-000044",
      phone: 9029581125,
      ipaddress: "10.90.0.30",
      description: "",
      changeKey: "e4a6b211-7b26-432e-9f86-e6c37bc36bbf",
      created: "2023-06-06T04:55:03.4442320Z",
      createdBy: "1140c337-f547-4245-9f21-68d04890e452",
      modified: "2023-06-15T01:41:57.7235600Z",
      modifiedBy: "a1022248-9283-46cd-950a-b8a676d463e0",
      id: "49ac196a-5c51-41df-ae10-e95707c5f260",
      entityType: "ftpCamera",
    },
    {
      lat: 56.053274,
      lon: 92.834555,
      note: "",
      customerId: "1996d489-d64c-4352-8cd9-49ab78e24a50",
      customerName: "Крудор",
      name: "район Емельяновский, а/д Красноярск-Солонцы, км 1+265 #1",
      serial: "MDC02307-000043",
      phone: 9048901452,
      ipaddress: "10.90.0.69",
      description: "",
      changeKey: "e4a6b211-7b26-432e-9f86-e6c37bc36bbf",
      created: "2023-06-06T04:55:03.4442320Z",
      createdBy: "1140c337-f547-4245-9f21-68d04890e452",
      modified: "2023-06-15T01:41:57.7235600Z",
      modifiedBy: "a1022248-9283-46cd-950a-b8a676d463e0",
      id: "a84227b6-a712-4cfd-9b0f-e4cf992982e4",
      entityType: "ftpCamera",
    },
    {
      lat: 56.053293,
      lon: 92.834925,
      note: "",
      customerId: "1996d489-d64c-4352-8cd9-49ab78e24a50",
      customerName: "Крудор",
      name: "район Емельяновский, а/д Красноярск-Солонцы, км 1+265 #2",
      serial: "MDC02307-000042",
      phone: 9029929827,
      ipaddress: "10.90.0.68",
      description: "",
      changeKey: "e4a6b211-7b26-432e-9f86-e6c37bc36bbf",
      created: "2023-06-06T04:55:03.4442320Z",
      createdBy: "1140c337-f547-4245-9f21-68d04890e452",
      modified: "2023-06-15T01:41:57.7235600Z",
      modifiedBy: "a1022248-9283-46cd-950a-b8a676d463e0",
      id: "17c81ccd-d9ff-4a72-880d-d5dc21bfaaed",
      entityType: "ftpCamera",
    },
    {
      lat: 56.183551,
      lon: 92.484208,
      note: "",
      customerId: "1996d489-d64c-4352-8cd9-49ab78e24a50",
      customerName: "Крудор",
      name: "район Емельяновский, а/д Байкал-аэропорт Черемшанка, км 2+700",
      serial: "MDC02307-000041",
      phone: 9048904303,
      ipaddress: "10.90.0.67",
      description: "",
      changeKey: "e4a6b211-7b26-432e-9f86-e6c37bc36bbf",
      created: "2023-06-06T04:55:03.4442320Z",
      createdBy: "1140c337-f547-4245-9f21-68d04890e452",
      modified: "2023-06-15T01:41:57.7235600Z",
      modifiedBy: "a1022248-9283-46cd-950a-b8a676d463e0",
      id: "2b5a59ba-9033-4d02-83a4-90d932e0bdca",
      entityType: "ftpCamera",
    },
    {
      lat: 56.116581,
      lon: 93.309869,
      note: "",
      customerId: "1996d489-d64c-4352-8cd9-49ab78e24a50",
      customerName: "Крудор",
      name: "район Березовский, а/д Красноярск-Железногорск, км 17+700 #1",
      serial: "MDC02308-000046",
      phone: 9538408890,
      ipaddress: "10.90.0.85",
      description: "",
      changeKey: "e4a6b211-7b26-432e-9f86-e6c37bc36bbf",
      created: "2023-06-06T04:55:03.4442320Z",
      createdBy: "1140c337-f547-4245-9f21-68d04890e452",
      modified: "2023-06-15T01:41:57.7235600Z",
      modifiedBy: "a1022248-9283-46cd-950a-b8a676d463e0",
      id: "53e2c8ca-4911-4ad2-848b-25205867e323",
      entityType: "ftpCamera",
    },
    {
      lat: 56.11669,
      lon: 93.309278,
      note: "",
      customerId: "1996d489-d64c-4352-8cd9-49ab78e24a50",
      customerName: "Крудор",
      name: "район Березовский, а/д Красноярск-Железногорск, км 17+700 #2",
      serial: "MDC02308-000047",
      phone: 9538408941,
      ipaddress: "10.90.0.86",
      description: "",
      changeKey: "e4a6b211-7b26-432e-9f86-e6c37bc36bbf",
      created: "2023-06-06T04:55:03.4442320Z",
      createdBy: "1140c337-f547-4245-9f21-68d04890e452",
      modified: "2023-06-15T01:41:57.7235600Z",
      modifiedBy: "a1022248-9283-46cd-950a-b8a676d463e0",
      id: "7bbdf5d4-d67d-4116-8617-fb09772f5374",
      entityType: "ftpCamera",
    },
    {
      lat: 56.134213,
      lon: 93.264378,
      note: "",
      customerId: "1996d489-d64c-4352-8cd9-49ab78e24a50",
      customerName: "Крудор",
      name: "район Березовский, а/д Есаулово-Бархатово, км 0+755",
      serial: "MDC02308-000048",
      phone: 9538408954,
      ipaddress: "10.90.0.87",
      description: "",
      changeKey: "e4a6b211-7b26-432e-9f86-e6c37bc36bbf",
      created: "2023-06-06T04:55:03.4442320Z",
      createdBy: "1140c337-f547-4245-9f21-68d04890e452",
      modified: "2023-06-15T01:41:57.7235600Z",
      modifiedBy: "a1022248-9283-46cd-950a-b8a676d463e0",
      id: "8672a024-67cb-45a7-a590-7a926e5f267b",
      entityType: "ftpCamera",
    },
    {
      lat: 56.1022,
      lon: 93.292118,
      note: "",
      customerId: "1996d489-d64c-4352-8cd9-49ab78e24a50",
      customerName: "Крудор",
      name: "район Березовский, а/д Красноярск-Железногорск, км 15+655 #1",
      serial: "MDC02308-000049",
      phone: 9538409033,
      ipaddress: "10.90.0.88",
      description: "",
      changeKey: "e4a6b211-7b26-432e-9f86-e6c37bc36bbf",
      created: "2023-06-06T04:55:03.4442320Z",
      createdBy: "1140c337-f547-4245-9f21-68d04890e452",
      modified: "2023-06-15T01:41:57.7235600Z",
      modifiedBy: "a1022248-9283-46cd-950a-b8a676d463e0",
      id: "c249d966-0fe5-471e-8d73-47f7fcad88bd",
      entityType: "ftpCamera",
    },
    {
      lat: 56.101513,
      lon: 93.291401,
      note: "",
      customerId: "1996d489-d64c-4352-8cd9-49ab78e24a50",
      customerName: "Крудор",
      name: "район Березовский, а/д Красноярск-Железногорск, км 15+655 #2",
      serial: "MDC02308-000050",
      phone: 9538409032,
      ipaddress: "10.90.0.89",
      description: "",
      changeKey: "e4a6b211-7b26-432e-9f86-e6c37bc36bbf",
      created: "2023-06-06T04:55:03.4442320Z",
      createdBy: "1140c337-f547-4245-9f21-68d04890e452",
      modified: "2023-06-15T01:41:57.7235600Z",
      modifiedBy: "a1022248-9283-46cd-950a-b8a676d463e0",
      id: "de1fe151-0868-4102-a1bb-eda2fe02e8c4",
      entityType: "ftpCamera",
    },
    {
      lat: 55.95075,
      lon: 93.152471,
      note: "",
      customerId: "1996d489-d64c-4352-8cd9-49ab78e24a50",
      customerName: "Крудор",
      name: "район Березовский, а/д Красноярск-Кузнецово-Зыково, км 1+260",
      serial: "MDC02309-000052",
      phone: 9535902578,
      ipaddress: "10.90.8.101",
      description: "",
      changeKey: "e4a6b211-7b26-432e-9f86-e6c37bc36bbf",
      created: "2023-06-06T04:55:03.4442320Z",
      createdBy: "1140c337-f547-4245-9f21-68d04890e452",
      modified: "2023-06-15T01:41:57.7235600Z",
      modifiedBy: "a1022248-9283-46cd-950a-b8a676d463e0",
      id: "026d4c9e-bd63-44e7-9d8d-2c979c4d90ed",
      entityType: "ftpCamera",
    },
    {
      lat: 56.061431,
      lon: 92.715218,
      note: "",
      customerId: "1996d489-d64c-4352-8cd9-49ab78e24a50",
      customerName: "Крудор",
      name: "район Емельяновский, а/д Красноярск-Элита, км 0+100",
      serial: "MDC02309-000053",
      phone: 9535902617,
      ipaddress: "10.90.8.102",
      description: "",
      changeKey: "e4a6b211-7b26-432e-9f86-e6c37bc36bbf",
      created: "2023-06-06T04:55:03.4442320Z",
      createdBy: "1140c337-f547-4245-9f21-68d04890e452",
      modified: "2023-06-15T01:41:57.7235600Z",
      modifiedBy: "a1022248-9283-46cd-950a-b8a676d463e0",
      id: "698a510a-8456-48d6-8248-126784a848d2",
      entityType: "ftpCamera",
    },
    {
      lat: 56.061607,
      lon: 92.715546,
      note: "",
      customerId: "1996d489-d64c-4352-8cd9-49ab78e24a50",
      customerName: "Крудор",
      name: "район Емельяновский, а/д Красноярск-Элита, км 0+000",
      serial: "MDC02309-000054",
      phone: 9535902648,
      ipaddress: "10.90.8.104",
      description: "",
      changeKey: "e4a6b211-7b26-432e-9f86-e6c37bc36bbf",
      created: "2023-06-06T04:55:03.4442320Z",
      createdBy: "1140c337-f547-4245-9f21-68d04890e452",
      modified: "2023-06-15T01:41:57.7235600Z",
      modifiedBy: "a1022248-9283-46cd-950a-b8a676d463e0",
      id: "53804836-8c0b-4a4a-b800-7cfde94ccb0c",
      entityType: "ftpCamera",
    },
    {
      lat: 55.94784,
      lon: 93.045409,
      note: "",
      customerId: "1996d489-d64c-4352-8cd9-49ab78e24a50",
      customerName: "Крудор",
      name: "район Березовский, а/д Красноярск-Кузнецово-Зыково, км 3+550",
      serial: "MDC02309-000051",
      phone: 9535902631,
      ipaddress: "10.90.8.103",
      description: "",
      changeKey: "e4a6b211-7b26-432e-9f86-e6c37bc36bbf",
      created: "2023-06-06T04:55:03.4442320Z",
      createdBy: "1140c337-f547-4245-9f21-68d04890e452",
      modified: "2023-06-15T01:41:57.7235600Z",
      modifiedBy: "a1022248-9283-46cd-950a-b8a676d463e0",
      id: "6dfa6fcc-1118-4991-85a2-7938ae2618ba",
      entityType: "ftpCamera",
    },
    {
      lat: 55.951044,
      lon: 93.12569,
      note: "",
      customerId: "1996d489-d64c-4352-8cd9-49ab78e24a50",
      customerName: "Крудор",
      name: "район Березовский, а/д Красноярск-Кузнецово-Зыково, км 9+480",
      serial: "MDC02309-000055",
      phone: 9535902742,
      ipaddress: "10.90.8.105",
      description: "",
      changeKey: "e4a6b211-7b26-432e-9f86-e6c37bc36bbf",
      created: "2023-06-06T04:55:03.4442320Z",
      createdBy: "1140c337-f547-4245-9f21-68d04890e452",
      modified: "2023-06-15T01:41:57.7235600Z",
      modifiedBy: "a1022248-9283-46cd-950a-b8a676d463e0",
      id: "e373d20b-d148-46ff-9f63-5459aed8efd1",
      entityType: "ftpCamera",
    },
    {
      lat: 56.074934,
      lon: 92.592062,
      note: "",
      customerId: "1996d489-d64c-4352-8cd9-49ab78e24a50",
      customerName: "Крудор",
      name: "район Емельяновский, а/д Еловое-станция Минино, км 12+744",
      serial: "MDC02310-000059",
      phone: 9509704670,
      ipaddress: "10.90.8.117",
      description: "",
      changeKey: "e4a6b211-7b26-432e-9f86-e6c37bc36bbf",
      created: "2023-06-06T04:55:03.4442320Z",
      createdBy: "1140c337-f547-4245-9f21-68d04890e452",
      modified: "2023-06-15T01:41:57.7235600Z",
      modifiedBy: "a1022248-9283-46cd-950a-b8a676d463e0",
      id: "f3a5fab8-0db1-483b-a742-062094306a22",
      entityType: "ftpCamera",
    },
    {
      lat: 56.0806,
      lon: 92.595791,
      note: "",
      customerId: "1996d489-d64c-4352-8cd9-49ab78e24a50",
      customerName: "Крудор",
      name: "район Емельяновский, Еловое-станция Минино, км 12+140",
      serial: "MDC02310-000057",
      phone: 9504150171,
      ipaddress: "10.90.8.119",
      description: "",
      changeKey: "e4a6b211-7b26-432e-9f86-e6c37bc36bbf",
      created: "2023-06-06T04:55:03.4442320Z",
      createdBy: "1140c337-f547-4245-9f21-68d04890e452",
      modified: "2023-06-15T01:41:57.7235600Z",
      modifiedBy: "a1022248-9283-46cd-950a-b8a676d463e0",
      id: "790eb5a9-c3de-4e3d-976e-91b5f10df721",
      entityType: "ftpCamera",
    },
    {
      lat: 56.07553,
      lon: 92.609963,
      note: "",
      customerId: "1996d489-d64c-4352-8cd9-49ab78e24a50",
      customerName: "Крудор",
      name: "район Емельяновский, а/д Красноярск-Элита, км 6+915",
      serial: "MDC02310-000058",
      phone: 9504301688,
      ipaddress: "10.90.8.12",
      description: "",
      changeKey: "e4a6b211-7b26-432e-9f86-e6c37bc36bbf",
      created: "2023-06-06T04:55:03.4442320Z",
      createdBy: "1140c337-f547-4245-9f21-68d04890e452",
      modified: "2023-06-15T01:41:57.7235600Z",
      modifiedBy: "a1022248-9283-46cd-950a-b8a676d463e0",
      id: "04f3060d-f43e-4841-918b-6792ecaa1c76",
      entityType: "ftpCamera",
    },
    {
      lat: 56.07669357,
      lon: 92.6016397,
      note: "",
      customerId: "1996d489-d64c-4352-8cd9-49ab78e24a50",
      customerName: "Крудор",
      name: "район Емельяновский, а/д Красноярск-Элита, км 7+491",
      serial: "MDC02310-000060",
      phone: 9504150211,
      ipaddress: "10.90.8.118",
      description: "",
      changeKey: "e4a6b211-7b26-432e-9f86-e6c37bc36bbf",
      created: "2023-06-06T04:55:03.4442320Z",
      createdBy: "1140c337-f547-4245-9f21-68d04890e452",
      modified: "2023-06-15T01:41:57.7235600Z",
      modifiedBy: "a1022248-9283-46cd-950a-b8a676d463e0",
      id: "094b523a-6ef9-4afe-9e0a-a4e1479a234d",
      entityType: "ftpCamera",
    },
    {
      lat: 56.055231,
      lon: 92.548744,
      note: "",
      customerId: "1996d489-d64c-4352-8cd9-49ab78e24a50",
      customerName: "Крудор",
      name: "район Емельяновский, а/д Еловое-станция Минино, км 16+565",
      serial: "MDC02310-000056",
      phone: 9535902790,
      ipaddress: "10.90.8.106",
      description: "",
      changeKey: "e4a6b211-7b26-432e-9f86-e6c37bc36bbf",
      created: "2023-06-06T04:55:03.4442320Z",
      createdBy: "1140c337-f547-4245-9f21-68d04890e452",
      modified: "2023-06-15T01:41:57.7235600Z",
      modifiedBy: "a1022248-9283-46cd-950a-b8a676d463e0",
      id: "5238c802-04f3-4eb3-b5f4-a345fa39476c",
      entityType: "ftpCamera",
    },
  ],
  total: 1,
  page: 0,
  itemsPerPage: 50,
};

export const fakeGroups = {
  items: [
    {
      itemsCount: 30,
      customerId: "1996d489-d64c-4352-8cd9-49ab78e24a50",
      customerName: "Крудор",
      description: "",
      changeKey: "",
      name: "Камеры FTP",
      created: "2024-03-27T06:26:46.3115500Z",
      createdBy: "0715bc9d-ebc5-493a-974e-7f4a39e941e7",
      modified: "2024-03-27T06:26:46.3115500Z",
      modifiedBy: "0715bc9d-ebc5-493a-974e-7f4a39e941e7",
      id: "6642f1f3-e55d-4e62-a2fb-42a75656c96e",
      entityType: "entityGroup",
    },
  ],
  total: 1,
  page: 0,
  itemsPerPage: 50,
};
