import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';

import { Schema } from '../schema';

import {
  PotokBarrier,
  PotokBarrierRing,
  PotokBarrierType,
  PotokMovementType,
  PotokPlan as DtoPotokPlan,
  PotokPlanType,
  PotokRingLeftMovement,
  PotokRingStraightMovement,
  TrafficChangeIntervals,
  TrafficObjectChangeIntervals,
} from '../tlc.dtos';

export class PotokPlan extends DtoPotokPlan {
  type = PotokPlanType.PhaseCycle;
  enabled = false;
  length = 20;
  offset = 0;
  pedestrianPhasesEnabled = false;
  transportBarriers: PotokBarrier[] = [];
  pedestrianBarriers: PotokBarrier[] = [];
}

export enum RingPartType {
  Left = 'Left',
  Straight = 'Straight',
  StraightPedestrian = 'StraightPedestrian',
}

export interface IRingPart {
  type: RingPartType;
  item: PotokRingStraightMovement | PotokRingLeftMovement;
  ring: PotokBarrierRing;
  ringNumber: 1 | 2;
  partsCount?: number;
}

export const getTime = (source: { length: number }[]) => {
  return source.reduce((sum, item) => sum + item.length, 0);
};

export const getPercentTimes = (source: { length: number }[]) => {
  const time = getTime(source);
  return source.map((item) => (time === 0 ? 100 / source.length : (item.length * 100) / time));
};

export const setBarriersPercentTimes = (
  barriers: PotokBarrier[],
  barriersMinTimes: number[],
  schema: Schema,
  changeIntervals: TrafficObjectChangeIntervals,
  sizes?: number[],
  time?: number
) => {
  if (barriers.length == 0) {
    return;
  }

  sizes = sizes || getPercentTimes(barriers);
  time = Math.max(
    time || getTime(barriers),
    barriersMinTimes.reduce((result, item) => result + item, 0)
  );
  const timePercent = time / 100;

  // update sizes
  let sum = 0;
  const barrierTimes = [];
  sizes.forEach((s, i) => {
    const barrierMinTime = barriersMinTimes[i];
    let barrierTime = i === sizes.length - 1 ? time - sum : Math.round(s * timePercent);
    barrierTime = Math.max(barrierTime, barrierMinTime);
    barrierTimes.push(barrierTime);
    sum += barrierTime;
  });

  // diff
  let diff = barrierTimes.reduce((result, item) => result + item, 0) - time;
  if (diff > 0) {
    barrierTimes.some((time, i) => {
      const minTime = barriersMinTimes[i];
      const newDiff = Math.min(time - minTime, diff);
      barrierTimes[i] = time - newDiff;
      diff = diff - newDiff;
      return diff == 0;
    });
  }

  // set length
  barriers.forEach((barrier, index) => {
    barrier.length = barrierTimes[index];
    setRingPercentTimes(barrier.ring1, 1, barrier, schema, changeIntervals, null, barrier.length);
    setRingPercentTimes(barrier.ring2, 2, barrier, schema, changeIntervals, null, barrier.length);
  });

  // let sum = 0;
  // barriers.forEach((item, index) => {
  //   if (index === barriers.length - 1) {
  //     item.length = time - sum;
  //   } else {
  //     item.length = Math.round(sizes[index] * timePercent);
  //     sum += item.length;
  //   }
  //   setRingPercentTimes(item.ring1, null, item.length);
  //   setRingPercentTimes(item.ring2, null, item.length);
  // });
};

export const getRingParts = (ring: PotokBarrierRing, ringNumber: 1 | 2): IRingPart[] => {
  const result: IRingPart[] = ring
    ? (ring.straightMovement.firstMovement
        ? [
            {
              type: RingPartType.Straight,
              item: ring.straightMovement,
              ring,
              ringNumber,
            },
            {
              type: RingPartType.Left,
              item: ring.leftMovement,
              ring,
              ringNumber,
            },
          ]
        : [
            {
              type: RingPartType.Left,
              item: ring.leftMovement,
              ring,
              ringNumber,
            },
            {
              type: RingPartType.Straight,
              item: ring.straightMovement,
              ring,
              ringNumber,
            },
          ]
      ).filter((m) => m.item.enabled)
    : [];

  result.forEach((m) => (m.partsCount = result.length));
  return result;
};

export const setRingPercentTimes = (
  ring: PotokBarrierRing,
  ringNumber: 1 | 2,
  barrier: PotokBarrier,
  schema: Schema,
  changeIntervals: TrafficObjectChangeIntervals,
  sizes?: number[],
  time?: number
) => {
  const parts = getRingParts(ring, ringNumber);
  if (parts.length == 0) {
    return;
  }

  const minTimes = getRingMinTimes(schema, barrier, parts, changeIntervals);
  const minTimeSum = minTimes.reduce((result, item) => result + item, 0);

  sizes = sizes || ring['sizes'] || getPercentTimes(parts.map((m) => m.item));
  time = time || getTime(parts.map((m) => m.item));
  const timePercent = time / 100;

  let sum = 0;
  parts.forEach((m, index) => {
    if (index === parts.length - 1) {
      m.item.length = time - sum;
    } else {
      const minTime = minTimes[index];
      const newTime = Math.round(sizes[index] * timePercent);
      const maxTime = time - (minTimeSum - minTime);
      m.item.length = Math.min(maxTime, Math.max(newTime, minTime));
      sum += m.item.length;
    }
  });

  setStraightPercentTimes(ring.straightMovement, schema, barrier.type, ringNumber, changeIntervals);
};

export const getStraightParts = (straight: PotokRingStraightMovement) => {
  const result: { type: RingPartType; item: { length: number } }[] = [];

  if (!straight.enabled || !(straight.pedestrianMovement || straight.straightMovement)) {
    return result;
  }

  if (straight.pedestrianMovement && (straight.straightMovement || straight.rightMovement)) {
    result.push(
      {
        type: RingPartType.StraightPedestrian,
        item: { length: straight.pedestrianMovementLength },
      },
      {
        type: RingPartType.Straight,
        item: { length: straight.length - straight.pedestrianMovementLength },
      }
    );
  } else {
    result.push({
      type: straight.pedestrianMovement ? RingPartType.StraightPedestrian : RingPartType.Straight,
      item: { length: straight.length },
    });
  }

  return result;
};

export const setStraightPercentTimes = (
  straight: PotokRingStraightMovement,
  schema: Schema,
  barrierType: PotokBarrierType,
  ringNumber: 1 | 2,
  changeIntervals: TrafficObjectChangeIntervals,
  sizes?: number[],
  time?: number
) => {
  const parts = getStraightParts(straight);
  if (parts.length == 0) {
    return;
  }

  const pedestrianMinMax = getPedestrianMinMaxTime(
    schema,
    barrierType,
    ringNumber,
    straight,
    changeIntervals
  );

  sizes = sizes || straight['sizes'] || getPercentTimes(parts.map((m) => m.item));
  time = time || straight.length;
  const timePercent = time / 100;

  straight.length = time;
  if (parts[0].type == RingPartType.StraightPedestrian) {
    const newPedestrianTime = Math.round(sizes[0] * timePercent);
    straight.pedestrianMovementLength =
      newPedestrianTime == 0
        ? 0
        : Math.min(Math.max(pedestrianMinMax.min, newPedestrianTime), pedestrianMinMax.max);
  }
};

export const getBarrierPartNames = (
  barrierNumber: number,
  barrierType: PotokBarrierType,
  ringPart?: IRingPart,
  pedestrian?: boolean
) => {
  const result = ['Б' + barrierNumber];
  if (barrierType != PotokBarrierType.Static && ringPart) {
    const moveNumber = MOVEMENT_NUMBERS[barrierType][ringPart.ringNumber - 1][ringPart.type];
    result.push('Н' + moveNumber + (pedestrian ? 'п' : ''));
  }
  return result;
};

export const getBarrierMinTime = (
  schema: Schema,
  barrier: PotokBarrier,
  changeIntervals: TrafficObjectChangeIntervals
) => {
  let result = 0;
  if (barrier.type == PotokBarrierType.Static) {
    const tMins = getSchemaStaticTMins(schema, changeIntervals);
    result = Math.max(...barrier.staticMovements.map((m) => tMins[m]));
  } else {
    const streetTMins = getSchemaStreetTMins(schema, changeIntervals)[barrier.type];
    const ringsTMins = [];
    [barrier.ring1, barrier.ring2].forEach((ring, ringIndex) => {
      if (!ring) return;
      const ringTMins = streetTMins[ringIndex];
      let ringTMin = 0;
      if (ring.leftMovement.enabled) {
        ringTMin += ringTMins.Left;
      }
      if (ring.straightMovement.enabled) {
        const straightTMins = [];
        ring.straightMovement.straightMovement && straightTMins.push(ringTMins.Straight);
        ring.straightMovement.pedestrianMovement && straightTMins.push(ringTMins.Pedestrian);
        if (ring.straightMovement.rightMovement) {
          straightTMins.push(
            ring.straightMovement.pedestrianMovement &&
              ring.straightMovement.rightMovementPedestrianSeparated
              ? ringTMins.Pedestrian + ringTMins.Right
              : ringTMins.Right
          );
        }
        ringTMin += Math.max(...straightTMins);
      }
      ringsTMins.push(ringTMin);
    });
    result = Math.max(...ringsTMins) || 0;
  }
  return result;
};

export const getPedestrianMinMaxTime = (
  schema: Schema,
  barrierType: PotokBarrierType,
  ringNumber: 1 | 2,
  straight: PotokRingStraightMovement,
  changeIntervals: TrafficObjectChangeIntervals
) => {
  const result = { min: 0, max: straight.length };
  const streetTMins = getSchemaStreetTMins(schema, changeIntervals)[barrierType];
  const ringTMins = streetTMins[ringNumber - 1];

  result.min = ringTMins.Pedestrian;

  if (straight.rightMovement && straight.rightMovementPedestrianSeparated) {
    result.max = straight.length - ringTMins.Right;
  }

  return result;
};

export const getBarrierPartMinTime = (
  schema: Schema,
  barrier: PotokBarrier,
  ringPart: IRingPart,
  changeIntervals: TrafficObjectChangeIntervals
) => {
  const streetTMins = getSchemaStreetTMins(schema, changeIntervals)[barrier.type];
  const ringTMins = streetTMins[ringPart.ringNumber - 1];

  if (ringPart.type == RingPartType.Left) {
    return ringTMins.Left;
  } else {
    const part = ringPart.item as PotokRingStraightMovement;
    const straightTMins = [ringTMins.Straight];
    part.pedestrianMovement && straightTMins.push(ringTMins.Pedestrian);
    if (part.rightMovement) {
      straightTMins.push(
        part.pedestrianMovement && part.rightMovementPedestrianSeparated
          ? ringTMins.Pedestrian + ringTMins.Right
          : ringTMins.Right
      );
    }
    return Math.max(...straightTMins);
  }
};

export const getRingMinTimes = (
  schema: Schema,
  barrier: PotokBarrier,
  parts: IRingPart[],
  changeIntervals: TrafficObjectChangeIntervals
) => {
  return parts.map((part) => getBarrierPartMinTime(schema, barrier, part, changeIntervals));
};

const getSchemaChangeIntervals = (changeIntervals?: TrafficObjectChangeIntervals) => {
  const ci = changeIntervals?.changeIntervals || new TrafficChangeIntervals();
  return {
    Move1: ci.move1 ? ci.move1.red : 0,
    Move3: ci.move3 ? ci.move3.red : 0,
    Move5: ci.move5 ? ci.move5.red : 0,
    Move7: ci.move7 ? ci.move7.red : 0,
    Move2: ci.move2 ? ci.move2.greenBlink + ci.move2.yellow + ci.move2.red : 0,
    Move4: ci.move4 ? ci.move4.greenBlink + ci.move4.yellow + ci.move4.red : 0,
    Move6: ci.move6 ? ci.move6.greenBlink + ci.move6.yellow + ci.move6.red : 0,
    Move8: ci.move8 ? ci.move8.greenBlink + ci.move8.yellow + ci.move8.red : 0,
    Move12: ci.move12 ? ci.move12.red : 0,
    Move14: ci.move14 ? ci.move14.red : 0,
    Move16: ci.move16 ? ci.move16.red : 0,
    Move18: ci.move18 ? ci.move18.red : 0,
    MoveP2: ci.moveP2 ? ci.moveP2.greenBlink + ci.moveP2.red : 0,
    MoveP4: ci.moveP4 ? ci.moveP4.greenBlink + ci.moveP4.red : 0,
    MoveP6: ci.moveP6 ? ci.moveP6.greenBlink + ci.moveP6.red : 0,
    MoveP8: ci.moveP8 ? ci.moveP8.greenBlink + ci.moveP8.red : 0,
  };
};

const getSchemaStaticTMins = (schema: Schema, changeIntervals: TrafficObjectChangeIntervals) => {
  const ci = getSchemaChangeIntervals(changeIntervals);
  return {
    Move1: schema.Move1?.tMin + ci.Move1,
    Move3: schema.Move3?.tMin + ci.Move3,
    Move5: schema.Move5?.tMin + ci.Move5,
    Move7: schema.Move7?.tMin + ci.Move7,
    Move2: schema.Move2?.tMin + ci.Move2,
    Move4: schema.Move4?.tMin + ci.Move4,
    Move6: schema.Move6?.tMin + ci.Move6,
    Move8: schema.Move8?.tMin + ci.Move8,
    Move12: schema.Move12?.tMin + ci.Move12,
    Move14: schema.Move14?.tMin + ci.Move14,
    Move16: schema.Move16?.tMin + ci.Move16,
    Move18: schema.Move18?.tMin + ci.Move18,
    MoveP2: schema.MoveP2?.tMin + ci.MoveP2,
    MoveP4: schema.MoveP4?.tMin + ci.MoveP4,
    MoveP6: schema.MoveP6?.tMin + ci.MoveP6,
    MoveP8: schema.MoveP8?.tMin + ci.MoveP8,
  };
};

const getSchemaStreetTMins = (schema: Schema, changeIntervals: TrafficObjectChangeIntervals) => {
  const ci = getSchemaChangeIntervals(changeIntervals);
  return {
    MajorStreet: [
      {
        Left: schema.Move1?.tMin + ci.Move1,
        Straight: schema.Move2?.tMin + ci.Move2,
        Right: schema.Move12?.tMin + ci.Move12,
        Pedestrian: schema.MoveP2?.tMin + ci.MoveP2,
      },
      {
        Left: schema.Move5?.tMin + ci.Move5,
        Straight: schema.Move6?.tMin + ci.Move6,
        Right: schema.Move16?.tMin + ci.Move16,
        Pedestrian: schema.MoveP6?.tMin + ci.MoveP6,
      },
    ],
    MinorStreet: [
      {
        Left: schema.Move3?.tMin + ci.Move3,
        Straight: schema.Move4?.tMin + ci.Move4,
        Right: schema.Move14?.tMin + ci.Move14,
        Pedestrian: schema.MoveP4?.tMin + ci.MoveP4,
      },
      {
        Left: schema.Move7?.tMin + ci.Move7,
        Straight: schema.Move8?.tMin + ci.Move8,
        Right: schema.Move18?.tMin + ci.Move18,
        Pedestrian: schema.MoveP8?.tMin + ci.MoveP8,
      },
    ],
  };
};

export const setBarrierTime = (
  barrier: PotokBarrier,
  schema: Schema,
  time: number,
  changeIntervals: TrafficObjectChangeIntervals
) => {
  barrier.length = time;
  if (barrier.type != PotokBarrierType.Static) {
    setRingPercentTimes(barrier.ring1, 1, barrier, schema, changeIntervals, null, time);
    setRingPercentTimes(barrier.ring2, 2, barrier, schema, changeIntervals, null, time);
  }
};

const MOVEMENT_NUMBERS = {
  MajorStreet: [
    { Left: 1, Straight: 2 },
    { Left: 5, Straight: 6 },
  ],
  MinorStreet: [
    { Left: 3, Straight: 4 },
    { Left: 7, Straight: 8 },
  ],
};

export const updateBarrierPartSvg = (
  object: any,
  schema: Schema,
  barrierType: PotokBarrierType,
  part: IRingPart,
  pedestrian = false
) => {
  const svg = object.contentDocument;
  const hide = [];
  const addClass: string[][] = [];
  switch (part.type) {
    case RingPartType.Left:
      const left = part.item as PotokRingLeftMovement;
      !left.overlapped && hide.push('right');
      !left['deadEndPedestrianMovement'] && hide.push('deadend_pedestrian');

      break;
    case RingPartType.Straight:
      const ringIndex = part.ringNumber - 1;
      const straight = part.item as PotokRingStraightMovement;
      const wayNumber = MOVEMENT_NUMBERS[barrierType][ringIndex][part.type];
      const ways = schema.getWaysByNumber(wayNumber);
      const currentWay = ways.currentWay;

      !straight.straightMovement && hide.push('straight');
      !straight.deadEndPedestrianMovement && hide.push('deadend_pedestrian');
      !(straight.pedestrianMovement && pedestrian) && hide.push('pedestrian');

      (!currentWay.right?.enabled ||
        (!straight.straightMovement && !currentWay.right.controlled) ||
        (!straight.rightMovement && currentWay.right.controlled) ||
        (currentWay.right.controlled &&
          straight.rightMovement &&
          straight.rightMovementPedestrianSeparated &&
          pedestrian)) &&
        hide.push('right');

      (!currentWay.left?.enabled ||
        (!straight.straightMovement && !currentWay.left.controlled) ||
        currentWay.left.controlled) &&
        hide.push('left');

      // todo check rules

      currentWay.right?.controlled && addClass.push(['right', 'protected']);
      // currentWay.Left?.controlled && addClass.push(['left', 'protected']);
      currentWay.straight?.deadEnd && addClass.push(['straight', 'deadend']);

      break;
    default:
      break;
  }

  hide.forEach((e) => {
    svg.getElementById(e).style.display = 'none';
  });
  addClass.forEach((m) => {
    svg.getElementById(m[0]).classList.add(m[1]);
  });

  object.style.opacity = 1;
};

export const updateBarrierStaticSvg = (object: any, schema: Schema, barrier: PotokBarrier) => {
  const svg = object.contentDocument;
  const show = [];
  const hide = [
    ...Object.keys(PotokMovementType).filter(
      (m: PotokMovementType) => !barrier.staticMovements.includes(m)
    ),
    'MoveP',
  ];
  const addClass: string[][] = [];
  barrier.staticMovements.forEach((m) => {
    const move: any = schema[m];
    move.controlled && addClass.push([m, 'protected']);
    move.deadEnd && addClass.push([m, 'deadend']);

    const turns = MOVEMENT_TURNS[m];
    if (turns) {
      turns.forEach((n) => {
        const turnMove = schema[n];
        turnMove.enabled && !turnMove.controlled && show.push(n);
      });
    }
  });

  if (
    schema.getIsPedestrian() &&
    (barrier.staticMovements.includes(PotokMovementType.MoveP8) ||
      barrier.staticMovements.includes(PotokMovementType.MoveP4))
  ) {
    hide.push(PotokMovementType.MoveP8, PotokMovementType.MoveP4);
    show.push('MoveP');
  }

  hide
    .filter((m) => !show.includes(m))
    .forEach((e) => {
      svg.getElementById(e).style.display = 'none';
    });
  addClass.forEach((m) => {
    svg.getElementById(m[0]).classList.add(m[1]);
  });
};

const MOVEMENT_TURNS = {
  Move2: [PotokMovementType.Move12, PotokMovementType.Move5],
  Move4: [PotokMovementType.Move14, PotokMovementType.Move7],
  Move6: [PotokMovementType.Move16, PotokMovementType.Move1],
  Move8: [PotokMovementType.Move18, PotokMovementType.Move3],
};

const MAJOR_MOVEMENTS = [
  PotokMovementType.Move1,
  PotokMovementType.Move2,
  PotokMovementType.Move5,
  PotokMovementType.Move6,
];

export const getPlanStates = (
  planBarriers: PotokBarrier[]
): [PotokMovementType[][], PotokMovementType[][]] => {
  const result: [PotokMovementType[][], PotokMovementType[][]] = [[], []];

  // generate states
  planBarriers.forEach((barrier) => {
    if (barrier.type == PotokBarrierType.Static) {
      const movements = barrier.staticMovements;
      const streetIndex = movements.some((m) => MAJOR_MOVEMENTS.includes(m)) ? 0 : 1;
      result[streetIndex].push(movements);
    } else {
      const STREET = STREET_RING_MOVEMENTS[barrier.type];
      const streetIndex = barrier.type == PotokBarrierType.MajorStreet ? 0 : 1;
      const streetStates = result[streetIndex];

      const ringsStates: PotokMovementType[][][] = [];
      [barrier.ring1, barrier.ring2].forEach((ring, ringIndex) => {
        if (!ring) return;
        const RING = STREET[ringIndex];
        const ringStates: PotokMovementType[][] = [];

        // left
        if (ring.leftMovement.enabled) {
          const movements = [RING.Left];
          ring.leftMovement.overlapped && movements.push(RING.LeftOverlapped);
          ringStates.push(movements);
        }

        // straight
        if (ring.straightMovement.enabled) {
          const movements = [];
          ring.straightMovement.rightMovement && movements.push(RING.Right);
          ring.straightMovement.straightMovement && movements.push(RING.Straight);
          ring.straightMovement.deadEndPedestrianMovement &&
            movements.push(RING.StraightDeadEndPedestrian);

          ring.straightMovement.pedestrianMovement &&
            ringStates.push([...movements, RING.Pedestrian]);
          ringStates.push(movements);
        }

        ringStates.length && ringsStates.push(ringStates);
      });

      if (ringsStates.length > 1) {
        const ring1 = ringsStates[0];
        const ring2 = ringsStates[1];
        ring1.forEach((m1) => {
          ring2.forEach((m2) => {
            streetStates.push([...m1, ...m2]);
          });
        });
      } else if (ringsStates.length == 1) {
        streetStates.push(...ringsStates[0]);
      }
    }
  });

  // remove duplicates
  for (let index = 0; index < result.length; index++) {
    const uniqueStates: PotokMovementType[][] = [];
    const streetStates = result[index];
    streetStates.forEach((state) => {
      const exist = uniqueStates.some(
        (s) => s.length == state.length && s.every((m) => state.includes(m))
      );
      !exist && state.length && uniqueStates.push(state);
    });
    result[index] = uniqueStates;
  }

  return result;
};

const STREET_RING_MOVEMENTS = {
  MajorStreet: [
    {
      Left: PotokMovementType.Move1,
      LeftOverlapped: PotokMovementType.Move18,
      LeftDeadEndPedestrian: PotokMovementType.MoveP4,
      Straight: PotokMovementType.Move2,
      Right: PotokMovementType.Move12,
      Pedestrian: PotokMovementType.MoveP2,
      StraightDeadEndPedestrian: PotokMovementType.MoveP8,
    },
    {
      Left: PotokMovementType.Move5,
      LeftOverlapped: PotokMovementType.Move14,
      LeftDeadEndPedestrian: PotokMovementType.MoveP8,
      Straight: PotokMovementType.Move6,
      Right: PotokMovementType.Move16,
      Pedestrian: PotokMovementType.MoveP6,
      StraightDeadEndPedestrian: PotokMovementType.MoveP4,
    },
  ],
  MinorStreet: [
    {
      Left: PotokMovementType.Move3,
      LeftOverlapped: PotokMovementType.Move12,
      LeftDeadEndPedestrian: PotokMovementType.MoveP6,
      Straight: PotokMovementType.Move4,
      Right: PotokMovementType.Move14,
      Pedestrian: PotokMovementType.MoveP4,
      StraightDeadEndPedestrian: PotokMovementType.MoveP2,
    },
    {
      Left: PotokMovementType.Move7,
      LeftOverlapped: PotokMovementType.Move16,
      LeftDeadEndPedestrian: PotokMovementType.MoveP2,
      Straight: PotokMovementType.Move8,
      Right: PotokMovementType.Move18,
      Pedestrian: PotokMovementType.MoveP8,
      StraightDeadEndPedestrian: PotokMovementType.MoveP6,
    },
  ],
};

export const validatePlanTMins = (
  schema: Schema,
  changeIntervals: TrafficObjectChangeIntervals,
  plan: PotokPlan
) => {
  let result = true;

  const staticTMins = getSchemaStaticTMins(schema, changeIntervals);
  const streetTMins = getSchemaStreetTMins(schema, changeIntervals);

  const barriers = [
    ...plan.transportBarriers,
    ...(plan.pedestrianPhasesEnabled ? plan.pedestrianBarriers : []),
  ];

  barriers.forEach((barrier) => {
    if (barrier.type === PotokBarrierType.Static) {
      const minTime = Math.max(...barrier.staticMovements.map((m) => staticTMins[m]));
      if (barrier.length !== 0 && barrier.length < minTime) {
        result = false;
      }
    } else {
      const barrierTMins = streetTMins[barrier.type];

      [barrier.ring1, barrier.ring2].forEach((ring, ringIndex) => {
        const ringTMins = barrierTMins[ringIndex];

        if (
          ring.leftMovement.enabled &&
          ring.leftMovement.length !== 0 &&
          ring.leftMovement.length < ringTMins.Left
        ) {
          result = false;
        }

        if (ring.straightMovement.enabled) {
          if (
            ring.straightMovement.straightMovement &&
            ring.straightMovement.length !== 0 &&
            ring.straightMovement.length < ringTMins.Straight
          ) {
            result = false;
          }

          if (
            ring.straightMovement.pedestrianMovement &&
            ring.straightMovement.pedestrianMovementLength !== 0 &&
            ring.straightMovement.pedestrianMovementLength < ringTMins.Pedestrian
          ) {
            result = false;
          }

          if (
            ring.straightMovement.rightMovement &&
            ring.straightMovement.rightMovementPedestrianSeparated === true &&
            ring.straightMovement.length !== ring.straightMovement.pedestrianMovementLength &&
            ring.straightMovement.length - ring.straightMovement.pedestrianMovementLength <
              ringTMins.Right
          ) {
            result = false;
          }

          if (
            ring.straightMovement.rightMovement &&
            ring.straightMovement.rightMovementPedestrianSeparated === false &&
            ring.straightMovement.pedestrianMovementLength !== 0 &&
            ring.straightMovement.pedestrianMovementLength < ringTMins.Right
          ) {
            result = false;
          }
        }
      });
    }
  });

  return result;
};
