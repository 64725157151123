import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';

import {
  ApplicationRef,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ComponentFactoryResolver,
  ComponentRef,
  EventEmitter,
  Injector,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { DatePipe } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';

import { merge, Observable, of, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';

import {
  PotokSettings,
  Route,
  RouteObject,
  TrafficControllerType,
  UsdkSettings,
} from '../../dtos/tlc.dtos';
import { TrackingDeviceInfo, TrackingDeviceStatus } from '../../dtos/tpc.dtos';

import { RouteEscortState2Service } from './route-escort-state2.service';
import { DialogService } from 'projects/msu-its-web-common/src/services/dialog.service';

import { UsdkSelectPhaseComponent } from '../traffic-objects/usdk/shared/usdk-select-phase.component';
import { PotokSelectPhaseComponent } from '../traffic-objects/potok/shared/potok-select-phase.component';
import { RouteEscortSummary2Component } from './route-escort-summary2.component';

declare const L;

@Component({
  selector: 'route-escort2',
  templateUrl: './route-escort2.component.html',
  styles: [
    `
      :host {
        display: flex;
        flex-direction: column;
        height: calc(100% + 12px);
        min-width: 392px;
        max-width: 392px;
        margin: 0 -12px -12px;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [RouteEscortState2Service],
})
export class RouteEscort2Component implements OnDestroy, OnInit {
  _destroy = new Subject();

  _animate = true;

  _currentRoute: Route;
  _currentRouteLine = L.polyline([], {
    weight: 8,
    color: '#55e',
    opacity: 0.8,
  });

  _trackerMarker = createMarker();
  _tracker2Marker = createMarker();
  _trackerCircle = L.circle([0, 0], {
    radius: 0,
    stroke: false,
    interactive: false,
    lastLL: { lat: 0, lon: 0 },
  });

  _detectionMarkers = new Map<string, any>();

  routeChanged = new EventEmitter<Route>();

  @Input()
  customerId: string;

  @Input()
  map: any;

  @Input()
  mapLayers: Map<string, any>;

  get loading() {
    return this._stateService.loading;
  }

  get selectedRoute() {
    return this._stateService.selectedRoute;
  }

  get escortStarted() {
    return this._stateService.escortSession;
  }

  _testSummary = { name: 'test summary', action: 'testSummary' };
  _createRoute = { name: _('ROUTES.CREATE_ROUTE'), action: 'create' };
  _editRoute = { name: _('ROUTES.EDIT_ROUTE'), action: 'edit' };
  _refresh = { name: _('COMMON.REFRESH'), action: 'refresh' };
  _refreshState = { name: _('COMMON.REFRESH'), action: 'refreshState' };

  _selectedRouteActions = [this._refresh, this._editRoute, this._createRoute, this._testSummary];
  _noRouteActions = [this._createRoute, this._refreshState, this._testSummary];

  get actions(): { name: string; action: string }[] {
    return this.escortStarted
      ? []
      : this.selectedRoute
      ? this._selectedRouteActions
      : this._noRouteActions;
  }

  constructor(
    private _appRef: ApplicationRef,
    private _cfr: ComponentFactoryResolver,
    private _injector: Injector,
    private _stateService: RouteEscortState2Service,
    private _dialogService: DialogService,
    private _changeDetector: ChangeDetectorRef,
    private _datePipe: DatePipe,
    public translate: TranslateService
  ) {}

  ngOnInit() {
    this._stateService.customerId = this.customerId;
    this.refreshState();

    this._stateService.trackersStatusSubject.pipe(takeUntil(this._destroy)).subscribe(() => {
      this.updateTrackerMarkers();
    });

    this._stateService.selectedRoute$.pipe(takeUntil(this._destroy)).subscribe((route) => {
      this._resetRoute(this._currentRoute);
      this._currentRoute = route;
      this.updateRoute();
      this.routeChanged.next(this._currentRoute);
    });

    merge(this._stateService.escortSession$, this._stateService.loading$)
      .pipe(takeUntil(this._destroy))
      .subscribe(() => this._changeDetector.markForCheck());

    this._stateService.escortSessionStatusSubject.pipe(takeUntil(this._destroy)).subscribe(() => {
      if (this.escortStarted) {
        this.updateDetections();
      } else {
        this.clearDetections();
      }
      this._changeDetector.markForCheck();
    });

    this._stateService.escortSessionSummarySubject
      .pipe(takeUntil(this._destroy))
      .subscribe((summary) => {
        summary && this.showEscortSummary();
      });
  }

  ngOnDestroy() {
    this._destroySelectPopupComponentRef();
    this._resetRoute(this._currentRoute);
    this._destroy.next();
    this._destroy.complete();
    this._destroy = null;
  }

  fitBounds() {
    if (
      !this._stateService.selectedRouteBounded &&
      this.selectedRoute?.settings?.routeObjects?.length
    ) {
      const bounds = L.latLngBounds(
        this.selectedRoute.settings.routeObjects.map((m) => {
          const object = this._stateService.trafficObjects.get(m.trafficObjectId);
          return [object.lat, object.lon];
        })
      );
      bounds.isValid() &&
        this.map.fitBounds(bounds, {
          paddingTopLeft: [50, 50],
          paddingBottomRight: [400, 50],
        });
    }
    this._stateService.selectedRouteBounded = false;
  }

  refresh() {
    this._stateService.selectRoute(this.selectedRoute.id).subscribe(() => {
      this.updateRoute();
      this._changeDetector.markForCheck();

      this._stateService.loadRoutes();
      this._stateService.loadTrackers();
    });
  }

  refreshState() {
    this._stateService.loadRoutes();
    this._stateService.loadTrackers();
  }

  testSummary() {
    this._stateService.testSummary();
  }

  updateRoute() {
    if (!this.mapLayers) return;

    const route = this.selectedRoute;
    if (route?.routeGeoJson) {
      const geoJson = JSON.parse(route?.routeGeoJson);
      const latlngs = geoJson?.features[0]?.geometry?.coordinates.map((m) => L.latLng(m[1], m[0]));
      latlngs && this._currentRouteLine.setLatLngs(latlngs);
      this._currentRouteLine.addTo(this.map);
      // this.fitBounds();
    }

    route?.settings.routeObjects?.forEach((m, i) => {
      const layer = this.mapLayers.get(m.trafficObjectId);
      layer &&
        layer.setStatus({
          radius: 28,
          weight: 7,
          label: { text: i + 1, color: '#fff' },
        });
      layer?.bringToFront();
    });

    this.fitBounds();
  }
  _resetRoute(route: Route) {
    this._destroySelectPopupComponentRef();

    if (!this.mapLayers) return;

    route?.settings.routeObjects?.forEach((m, i) => {
      const layer = this.mapLayers.get(m.trafficObjectId);
      layer &&
        layer.setStatus({
          radius: 18,
          weight: 6,
          label: undefined,
        });

      delete layer?.options.eventHandlers;
    });

    // if (this._currentRouteLine) {
    this.map.removeLayer(this._currentRouteLine);
    // }
  }

  //_updateRouteSteps() {
  // this._stateService.escortSteps.forEach((step) => {
  //   const layer = this.mapLayers.get(step.routeObject.trafficObjectId);
  //   const now = Date.now();
  //   let text;
  //   if (
  //     (step.status == EscortStepStatus.InProgress || step.status == EscortStepStatus.Wait) &&
  //     step.completeTime > now
  //   ) {
  //     text = Math.round((step.completeTime - now) / 1000);
  //   } else if (step.status == EscortStepStatus.InProgress) {
  //     this.escortStarted &&
  //       this._stateService.stopStep(step.routeObject.trafficObjectId).subscribe();
  //     text = 0;
  //   } else if (step.status == EscortStepStatus.Wait) {
  //   } else {
  //     text =
  //       this.selectedRoute.settings.routeObjects.findIndex(
  //         (m) => m.trafficObjectId == step.routeObject.trafficObjectId
  //       ) + 1;
  //   }
  //   layer.setStatus({
  //     label: { text, color: '#fff' },
  //   });
  //   if (this._stateService.test) {
  //     step.status == EscortStepStatus.Wait ? layer.startSpin() : layer.stopSpin();
  //   }
  // });
  //}

  edit() {
    this._dialogService.windowDialog(
      `${this.customerId}/ext/route/${this.selectedRoute.id}/edit`,
      'routeEdit_' + this.selectedRoute.id,
      'menubar=0,toolbar=0,titlebar=0,location=0,locationbar=0,width=1400,height=800,resizable=0',
      () => {
        this._stateService.loadRoutes();
      }
    );
  }
  create() {
    this._dialogService.windowDialog(
      `${this.customerId}/ext/route/create`,
      'routeEdit_create',
      'menubar=0,toolbar=0,titlebar=0,location=0,locationbar=0,width=1400,height=800,resizable=0',
      () => {
        this._stateService.loadRoutes();
      }
    );
  }

  clickLayer(objectId, layer) {
    this._destroySelectPopupComponentRef();

    const routeObjects = this.selectedRoute.settings.routeObjects.filter(
      (m) => m.trafficObjectId == objectId
    );

    if (this.escortStarted && routeObjects.length === 1) {
      // start
      const item = routeObjects[0].stepMovement;
      this._stateService.startTrafficObject(objectId, item.phase, item.movements);
      return;
    }
    if (this.escortStarted && routeObjects.length > 1) {
      // select phase and start
      this._selectPhase(objectId, layer).subscribe((routeObject) => {
        this._destroySelectPopupComponentRef();
        const item = routeObject.stepMovement;
        this._stateService.startTrafficObject(objectId, item.phase, item.movements);
      });
      return;
    }
    if (this.escortStarted && routeObjects.length === 0) {
      // load settings select phase and start
      this._stateService.loadObjectDetails(objectId).subscribe(() => {
        this._selectPhase(objectId, layer).subscribe((routeObject) => {
          this._destroySelectPopupComponentRef();
          const item = routeObject.stepMovement;
          this._stateService.startTrafficObject(objectId, item.phase, item.movements);
        });
      });
      return;
    }

    if (routeObjects.length === 0) {
      // return false for default layer handler
      return false;
    }
  }
  contextmenuLayer(objectId, layer) {
    const routeObjects = this.selectedRoute.settings.routeObjects.filter(
      (m) => m.trafficObjectId == objectId
    );

    if (
      this.escortStarted
      // && (routeObjects.length ||
      //   this._stateService.escortSessionStatusSubject.value.trafficObjects.some(
      //     (m) => m.id === objectId
      //   ))
    ) {
      // stop
      this._stateService.stopTrafficObject(objectId);
    }

    if (routeObjects.length === 0) {
      // return false for default layer handler
      return false;
    }
  }

  _selectPopupComponentRef: ComponentRef<any>;
  _destroySelectPopupComponentRef() {
    if (this._selectPopupComponentRef) {
      this._selectPopupComponentRef['_popup'].remove();
      this._selectPopupComponentRef?.destroy();
      this._selectPopupComponentRef = null;
    }
  }
  _selectPhase(objectId, layer): Observable<RouteObject> {
    const object = this._stateService.trafficObjects.get(objectId);
    const settings = this._stateService.trafficObjectsSettings.get(objectId);
    const schemaSet = this._stateService.trafficObjectsSchemaSets.get(objectId);

    let result: Observable<RouteObject>;

    switch (object.controllerType) {
      case TrafficControllerType.Usdk:
        const usdkComponentRef = this._cfr
          .resolveComponentFactory(UsdkSelectPhaseComponent)
          .create(this._injector);
        this._appRef.attachView(usdkComponentRef.hostView);
        usdkComponentRef.instance.schemaSet = schemaSet;
        usdkComponentRef.instance.settings = settings as UsdkSettings;
        result = usdkComponentRef.instance.selected.asObservable().pipe(
          map((m) => {
            return new RouteObject({
              trafficObjectId: objectId,
              stepMovement: {
                phase: m.id,
                movements: m.movements,
              },
            });
          })
        );
        this._selectPopupComponentRef = usdkComponentRef;
        break;
      case TrafficControllerType.Potok:
        const potokComponentRef = this._cfr
          .resolveComponentFactory(PotokSelectPhaseComponent)
          .create(this._injector);
        this._appRef.attachView(potokComponentRef.hostView);
        potokComponentRef.instance.schemaSet = schemaSet;
        potokComponentRef.instance.settings = settings as PotokSettings;
        result = potokComponentRef.instance.selected.pipe(
          map((m) => {
            return new RouteObject({
              trafficObjectId: objectId,
              stepMovement: {
                phase: 0,
                movements: m,
              },
            });
          })
        );
        this._selectPopupComponentRef = potokComponentRef;
        break;
      default:
        break;
    }

    if (!this._selectPopupComponentRef) return;

    const popup = L.popup({
      offset: [0, 0],
      autoPan: true,
      autoPanPadding: [20, 20],
      minWidth: 108,
      closeOnClick: true,
      closeButton: false,
      autoClose: false,
      keepInView: false,
    })
      .setContent(this._selectPopupComponentRef.location.nativeElement)
      .setLatLng([object.lat, object.lon])
      .on('remove', (e) => {
        this._destroySelectPopupComponentRef();
      });

    layer._map.openPopup(popup);
    this._selectPopupComponentRef['_id'] = object.id;
    this._selectPopupComponentRef['_popup'] = popup;
    setTimeout(() => layer.closePopup());

    return result || of(null);
  }
  // _addToRoute(item: RouteObject) {
  //   const newRoute = new Route(this.selectedRoute);
  //   newRoute.settings = new RouteSettings(this.selectedRoute.settings);
  //   newRoute.settings.routeObjects = [...newRoute.settings.routeObjects, item];

  //   this._stateService.selectedRouteBounded = true;
  //   this._stateService.selectedRoute = newRoute;

  //   this._changeDetector.markForCheck();
  // }

  closeSelectedRoute() {
    this._stateService.selectedRoute = null;
  }

  // ======================================================

  updateTrackerMarkers() {
    const tracker = this._stateService.tracker;
    const tracker2 =
      this._stateService.tracker2 === this._stateService.tracker
        ? null
        : this._stateService.tracker2;

    if (tracker) {
      const status = this._stateService.trackersStatusSubject.value[tracker.id];
      if (status) {
        this._updateMarkerStatus(this._trackerMarker, tracker, status);
        this._trackerMarker.addTo(this.map);
      }
    } else if (this._trackerMarker) {
      this.map.removeLayer(this._trackerMarker);
    }

    if (tracker2) {
      const status = this._stateService.trackersStatusSubject.value[tracker2.id];
      if (status) {
        this._updateMarkerStatus(this._tracker2Marker, tracker2, status);
        this._tracker2Marker.addTo(this.map);
      }
    } else if (this._tracker2Marker) {
      this.map.removeLayer(this._tracker2Marker);
    }

    if (tracker) {
      const status = this._stateService.trackersStatusSubject.value[tracker.id];
      if (status) {
        this._updateCircleMarkerStatus(
          this._trackerCircle,
          this._stateService.detectionRadius,
          status
        );
        this._trackerCircle.addTo(this.map);
      }
    } else {
      this.map.removeLayer(this._trackerCircle);
    }
  }
  _updateMarkerStatus(marker, tracker: TrackingDeviceInfo, status: TrackingDeviceStatus) {
    const lat = status.position.coordinate.lat;
    const lon = status.position.coordinate.lon;
    const direction = status.position.direction;
    const deviceTime = status.position.deviceTime;
    const speed = status.speed;

    const lastLL = marker.options.lastLL;
    const slide = lat !== lastLL.lat || lon !== lastLL.lon;

    marker.options.lastLL = { lat, lon };
    marker._popup.setContent(
      `${this._datePipe.transform(deviceTime, 'dd.MM.yyyy HH:mm:ss')}<br/>${speed} км/ч`
    );
    marker.setIcon(getMarkerIcon(direction, tracker.description));
    if (slide) {
      this._animate && marker._map
        ? marker.slideTo([lat, lon], { duration: 1000, keepAtCenter: false })
        : marker.setLatLng([lat, lon]);
    }
  }
  _updateCircleMarkerStatus(marker, radius: number, status: TrackingDeviceStatus) {
    const lat = status.position.coordinate.lat;
    const lon = status.position.coordinate.lon;

    const lastLL = marker.options.lastLL;
    const slide = lat !== lastLL.lat || lon !== lastLL.lon;

    marker.options.lastLL = { lat, lon };
    marker.setRadius(radius);

    if (slide) {
      this._animate && marker._map
        ? marker.slideTo([lat, lon], { duration: 1000, keepAtCenter: false })
        : marker.setLatLng([lat, lon]);
    }
  }

  updateDetections() {
    const detections = this._stateService.escortSessionStatusSubject.value?.detections || [];

    this._detectionMarkers.forEach((val, key, map) => {
      if (!detections.some((m) => m.id === key)) {
        this.map.removeLayer(val);
        map.delete(key);
      }
    });

    detections.forEach((item) => {
      const lat = item.detectionPoint.lat;
      const lon = item.detectionPoint.lon;
      const radius = item.radius;

      let marker = this._detectionMarkers.get(item.id);
      if (!marker) {
        marker = L.circle([lat, lon], {
          radius,
          stroke: false,
          fillColor: '#000',
          interactive: false,
        }).addTo(this.map);
        this._detectionMarkers.set(item.id, marker);
      }

      marker.setRadius(item.radius);
      marker.setLatLng([lat, lon]);
    });
  }
  clearDetections() {
    this._detectionMarkers.forEach((val, key, map) => {
      this.map.removeLayer(val);
      map.delete(key);
    });
  }

  showEscortSummary() {
    const summary = this._stateService.escortSessionSummarySubject.value;
    summary &&
      this._dialogService.dialog
        .open(RouteEscortSummary2Component, {
          disableClose: true,
          data: { summary: this._stateService.escortSessionSummarySubject.value },
        })
        .afterClosed()
        .subscribe(() => this._stateService.escortSessionSummarySubject.next(null));
  }
}

const createMarker = () => {
  return L.marker([0, 0], {
    zIndexOffset: 1000,
    riseOffset: 1000,
    riseOnHover: true,
    lastLL: { lat: 0, lon: 0 },
  }).bindPopup('', {
    closeButton: false,
    keepInView: false,
    autoPan: false,
    autoClose: false,
    offset: [0, -16],
  });
};
const getMarkerIcon = (direction = 0, label = '') => {
  return new L.DivIcon({
    className: 'car-64 bus', // this.animate ? 'bus transition-transform' : 'bus',
    bgPos: [64 * Math.floor(direction / 5), 0],
    html: `<div class="label mat-body-2"><div class="label-inner">${label}</div></div>`,
  });
};
