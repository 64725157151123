import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';

import {
  Component,
  ChangeDetectionStrategy,
  Input,
  OnInit,
  ChangeDetectorRef,
  OnDestroy,
} from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { forkJoin, of, Subject, timer } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

import { FlashService } from 'projects/msu-its-web-common/src/services/flash.service';
import { DialogService } from 'projects/msu-its-web-common/src/services/dialog.service';
import { SettingsService } from 'projects/msu-its-web-common/src/services/settings.service';

import { LANGS_LOCALES } from 'projects/msu-its-web-common/src/utils/langs';
import { getImgSrc, panelStand2col } from 'projects/msu-its-web-common/src/utils/map-markers';

import {
  PanelStand,
  PanelStandStatus,
  PanelStatus,
} from 'projects/msu-its-web-ruis/src/dtos/ruis.dtos';

import { PanelStandStateService } from './panel-stand-state.service';
import { PanelStandService } from 'projects/msu-its-web-ruis/src/services/panel-stands/panel-stand.service';
import { IGisObjectStateComponent } from 'projects/msu-its-web-common/src/utils/gis-object-group';

@Component({
  selector: 'panel-stand-state',
  templateUrl: './panel-stand-state.component.html',
  styles: [
    `
      .backdrop-inner {
        opacity: 0.98;
        padding: 16px 16px 4px;
        border-radius: 5px;
        color: #fff;
      }

      .state-container {
        display: flex;
        flex-direction: row;
      }

      .state-image {
        width: 44px;
        height: 44px;
        border: none;
        margin-right: 8px;
      }

      .mode-btn {
        font-size: 12px;
        line-height: 32px;
        padding: 0 9px;
      }

      .mode-auto {
        background-color: #b4e;
        color: #fff;
      }
    `,
  ],
  providers: [PanelStandStateService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PanelStandStateComponent implements IGisObjectStateComponent, OnInit, OnDestroy {
  private _destroy = new Subject();
  @Input()
  modelId: string;

  @Input()
  customerId: string;

  @Input()
  modelInfo: PanelStand;

  status: PanelStandStatus;
  statusImage: SafeUrl;

  get loading() {
    return this._stateService.loading;
  }
  get timezone() {
    return this._settingsService.timezone;
  }
  get locale() {
    return LANGS_LOCALES[this.translate.currentLang];
  }
  get lastReceived() {
    return this.status.controllers?.[0]?.lastReceived;
  }

  //   actions: { name: string; action: string; permissions?: string[] }[] = [
  //     // { name: _('COMMON.SETTINGS'), action: 'settings', permissions: ['parkingKiosk.update'] },
  //     // {
  //     //   name: _("COMMON.EDIT"),
  //     //   action: "edit",
  //     //   permissions: ["parkingKiosk.update"],
  //     // },
  //     // { name: _('AUDIT_LOG.TITLE'), action: 'auditLog', permissions: ['parkingKiosk.read'] },
  //     // { name: _('EVENT_LOG.TITLE'), action: 'eventLog', permissions: ['parkingKiosk.read'] },
  //     // { name: _('COMMON.FILES'), action: 'files', permissions: ['parkingKiosk.read'] },
  //   ];
  actions = null;

  constructor(
    private _flashService: FlashService,
    private _dialogService: DialogService,
    private _settingsService: SettingsService,
    private _changeDetector: ChangeDetectorRef,
    private _stateService: PanelStandStateService,
    private _panelStandService: PanelStandService,
    private _sanitize: DomSanitizer,
    public translate: TranslateService
  ) {}

  ngOnDestroy() {
    this._destroy.next();
    this._destroy.complete();
  }
  ngOnInit() {
    this._stateService.modelId = this.modelId;
    this._stateService.customerId = this.customerId;
    this._stateService.panelStand = this.modelInfo;

    this._stateService.loading$.pipe(takeUntil(this._destroy)).subscribe(() => {
      this._changeDetector.markForCheck();
    });

    this._stateService.status$.pipe(takeUntil(this._destroy)).subscribe((status) => {
      this.status = status;
      this._updateStatusImage();
      this._changeDetector.markForCheck();
    });

    this._stateService.panelStand$.pipe(takeUntil(this._destroy)).subscribe((model) => {
      this.modelInfo = model;
      this._changeDetector.markForCheck();
    });

    this._updateSettings(true);
    this._updateStatusImage();

    timer(30000, 30000)
      .pipe(takeUntil(this._destroy))
      .subscribe(() => this._updateSettings());
  }

  private _updateSettings(init = false) {
    if (this._stateService.loading) return;
    if (init) this._stateService.loading = true;
    forkJoin([
      this._panelStandService.get(this.modelId, this.customerId),
      init ? this._panelStandService.getStatus(this.modelId, this.customerId) : of(null),
    ])
      .pipe(finalize(() => (this._stateService.loading = false)))
      .subscribe((result) => {
        this._stateService.panelStand = result[0];

        // update status if init
        if (init) {
          this._stateService.status = result[1];
          this._timerGetStatus();
        }

        this._changeDetector.markForCheck();
      });
  }
  private _updateStatusImage() {
    this.statusImage = this._sanitize.bypassSecurityTrustUrl(
      getImgSrc(panelStand2col, this.status?.iconStatus.color, this.status?.iconStatus.borderColor)
    );
  }
  private _timerGetStatus() {
    this._panelStandService
      .getStatus(this.modelId, this.customerId)
      .pipe(takeUntil(this._destroy))
      .pipe(
        finalize(() =>
          timer(5 * 1000)
            .pipe(takeUntil(this._destroy))
            .subscribe(() => this._destroy && this._timerGetStatus())
        )
      )
      .subscribe((status) => {
        this._stateService.status = status;
      });
  }

  // private _getExternalUrlBase() {
  //   return `${this.customerId}/ext/parking-kiosk/${this.modelId}/`;
  // }

  //   openInNew() {
  //     this._dialogService.windowDialog(
  //       this._getExternalUrlBase() + "state",
  //       "parkingKioskState_" + this.modelId,
  //       "menubar=0,toolbar=0,titlebar=0,location=0,locationbar=0,width=600,height=810,resizable=0"
  //     );
  //   }

  // edit() {
  //   this._dialogService.windowDialog(
  //     this._getExternalUrlBase() + 'edit',
  //     'parkingKioskEdit_' + this.modelId,
  //     'menubar=0,toolbar=0,titlebar=0,location=0,locationbar=0,width=1050,height=750,resizable=0'
  //   );
  // }

  // settings() {
  //   this._dialogService.windowDialog(
  //     this._getExternalUrlBase() + 'settings',
  //     'parkingKioskSettings_' + this.modelId,
  //     'menubar=0,toolbar=0,titlebar=0,location=0,locationbar=0,width=1050,height=700,resizable=0'
  //   );
  // }

  // auditLog() {
  //   this._dialogService.windowDialog(
  //     this._getExternalUrlBase() + 'audit-log',
  //     'parkingAuditLog_' + this.modelId,
  //     'menubar=0,toolbar=0,titlebar=0,location=0,locationbar=0,width=950,height=850,resizable=0'
  //   );
  // }

  // eventLog() {
  //   this._dialogService.windowDialog(
  //     this._getExternalUrlBase() + 'event-log',
  //     'parkingEventLog_' + this.modelId,
  //     'menubar=0,toolbar=0,titlebar=0,location=0,locationbar=0,width=950,height=850,resizable=0'
  //   );
  // }

  //files() {
  // const dialog = this._dialogService.dialog.open(FilesComponent, {
  //   disableClose: false,
  // });
  // dialog.componentInstance.entityId = this.modelId;
  // dialog.componentInstance.entityName = this.modelInfo.number;
  //}

  disconnect() {
    this._stateService
      .loadingQuery(this._panelStandService.disconnect(this.modelId, this.customerId))
      .subscribe((result) => {
        if (result) {
          this._flashService.success(
            this.translate.instant('DEVICES.SESSION_DISCONNECTED', {
              number: this.modelInfo.name,
            })
          );
        }
      });
  }

  controllerEventLog() {
    if (this.status.controllers?.[0]?.id) {
      const url = `${this.status.customerId}/ext/panel-controller/${this.status.controllers[0].id}/event-log`;
      this._dialogService.windowDialog(
        url,
        'panelControllerEventLog_' + this.status.controllers[0].id,
        'menubar=0,toolbar=0,titlebar=0,location=0,locationbar=0,width=950,height=850,resizable=0'
      );
    }
  }

  trackById(index: number, item: PanelStatus) {
    return item.id;
  }

  setCustomMode(customMode: string) {
    const newMode = this.status.customMode === customMode ? null : customMode;
    this._stateService
      .loadingQuery(this._panelStandService.setCustomMode(this.modelId, this.customerId, newMode))
      .subscribe((status) => {
        this.status = status;
        this._changeDetector.markForCheck();
      });
  }
}
