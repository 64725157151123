<app-navbar></app-navbar>
<mat-sidenav-container
  [hasBackdrop]="expanded"
  (backdropClick)="sidenavService.toggle(false)"
>
  <mat-sidenav
    opened="true"
    mode="over"
    disableClose="true"
    [@sidenavAnimation]="
      expanded ? 'expanded' : over ? 'collapsedOver' : 'collapsed'
    "
  >
    <mat-nav-list style="overflow-x: hidden">
      <ng-container *ngFor="let category of menuCategories; let i = index">
        <mat-divider *ngIf="i"></mat-divider>
        <div mat-subheader *ngIf="over || expanded">
          {{ category.name | translate }}
        </div>
        <a
          *ngFor="let item of category.items"
          mat-list-item
          [routerLink]="item.external ? null : [item.route]"
          [routerLinkActive]="item.external ? '' : 'mat-selected'"
          matTooltip="{{ expanded ? '' : (item.name | translate) }}"
          matTooltipPosition="right"
          (click)="
            sidenavService.toggle(false); item.external && openExternal(item)
          "
        >
          <mat-icon matListIcon [svgIcon]="item.svgIcon">
            {{ item.icon }}
          </mat-icon>
          <p matLine *ngIf="over || expanded">{{ item.name | translate }}</p>
        </a>
      </ng-container>
    </mat-nav-list>
  </mat-sidenav>
  <mat-sidenav-content
    [@sidenavContentAnimation]="over ? '' : expanded ? 'expanded' : 'collapsed'"
    (@sidenavContentAnimation.done)="contentAnimationDone()"
  >
    <router-outlet></router-outlet>
  </mat-sidenav-content>
</mat-sidenav-container>
