import {
  Component,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Injector,
  Input,
  OnDestroy,
  AfterViewInit,
} from "@angular/core";
import { FormBuilder, Validators, FormGroup } from "@angular/forms";
import { Observable, Subject } from "rxjs";
import { debounceTime, finalize, takeUntil, tap } from "rxjs/operators";

import { FormValidators } from "../../utils/form-validators";
import { DataSourceForm } from "../../data/data-source-form";

import { MatListOption } from "@angular/material/list";

class EntityGroup {
  id: string;
  name: string;
  description: string;
  items: string[];
  customerId: string;
  customerName: string;
  entityType: string;
  changeKey: string;
  created: string;
  createdBy: string;
  modified: string;
  modifiedBy: string;
  externalSystemId?: string;
  externalSystemName: string;
  externalSystemEntityId?: string;
}

class Entity {
  id: string;
  name: string;
  description: string;
  search?: string;
}

interface IObjectsService<T> {
  getList(customerId: string): Observable<T[]>;
}

@Component({
  selector: "group-edit",
  templateUrl: "./group-edit.component.html",
  styleUrls: ["./group-edit.component.css"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GroupEditComponent extends DataSourceForm<EntityGroup>
  implements OnDestroy, AfterViewInit {
  _destroy = new Subject();

  @Input()
  customerId: string;

  @Input()
  objectService: IObjectsService<Entity>;

  _objects: Entity[] = [];

  clickedItems: MatListOption[] = [];

  get freeItems() {
    return this._objects.filter(
      (m) =>
        !this.items.value.includes(m.id) &&
        m.search.indexOf(this.freeFilter.toLocaleLowerCase()) >= 0
    );
  }

  get selectedItems() {
    return this._objects.filter((m) => this.items.value.includes(m.id));
  }

  freeFilter: string = "";
  searchFreeSubject = new Subject<string>();

  constructor(injector: Injector, changeDetector: ChangeDetectorRef) {
    super(injector, changeDetector);
  }

  ngAfterViewInit() {
    this.searchFreeSubject
      .pipe(debounceTime(250))
      .pipe(takeUntil(this._destroy))
      .subscribe((filter) => {
        this.freeFilter = filter;
        this.clickedItems = this.clickedItems.filter((m) =>
          this.freeItems.find((o) => o.id === m.value)
        );
        this.changeDetector.markForCheck();
      });
  }

  ngOnDestroy() {
    this._destroy.next();
    this._destroy.complete();
  }

  initFormGroup(fb: FormBuilder): FormGroup {
    this._loadObjects();

    const modelService: any = this.modelService;

    return fb.group({
      name: [
        "",
        [
          Validators.required,
          Validators.minLength(4),
          Validators.maxLength(100),
        ],
        modelService.exist
          ? [
              FormValidators.exist(this.model.name, (name: string) =>
                modelService.exist(name, this.customerId)
              ),
            ]
          : [],
      ],
      items: [{ value: null }, FormValidators.arrayLength(0)],
      description: "",
    });
  }

  get name() {
    return this.formGroup.controls["name"];
  }
  get items() {
    return this.formGroup.controls["items"];
  }
  get description() {
    return this.formGroup.controls["description"];
  }

  _loadObjects() {
    if (this.loading) return;
    this.loading = true;
    this.objectService
      .getList(this.customerId)
      .pipe(
        finalize(() => {
          this.loading = false;
          this.changeDetector.markForCheck();
        })
      )
      .pipe(
        tap((m) => {
          // create full properties string for good search
          // except standard fields
          m.forEach((item) => {
            const searchFields = { ...item } as any;
            // delete standard fields
            delete searchFields.id;
            delete searchFields.modified;
            delete searchFields.customerId;
            delete searchFields.lat;
            delete searchFields.lon;
            delete searchFields.geoJson;
            delete searchFields.entityType;
            item.search = Object.values(searchFields)
              .join(" ")
              .toLocaleLowerCase();
          });
        })
      )
      .subscribe((m) => {
        this._objects = m;
        this.changeDetector.markForCheck();
      });
  }

  prepareModelToSave(): EntityGroup {
    const model = Object.assign(
      new EntityGroup(),
      this.model,
      this.formGroup.value
    );
    return model;
  }
  prepareParamsToSave() {
    const customerId = this.customerId;
    return { customerId };
  }

  select(options?: MatListOption[]) {
    const ids = options?.map((m) => m.value) || this.freeItems.map((m) => m.id);
    const items: string[] = this.items.value || [];
    const newIds = ids.filter((item) => items.indexOf(item) < 0);
    this.items.setValue(items.concat(newIds));
    this.clickedItems = [];
  }
  unselect(options?: MatListOption[]) {
    const ids = options?.map((m) => m.value) || this.items.value || [];
    const items: string[] = this.items.value || [];
    const newIds = items.filter((m) => ids.indexOf(m) < 0);
    this.items.setValue(newIds);
  }

  selectById(id, options?: MatListOption[]) {
    const items: string[] = this.items.value || [];
    this.items.setValue(items.concat([id]));
    this.clickedItems = options.filter((m) => m.value != id);
  }
  unselectById(id) {
    const items: string[] = this.items.value || [];
    const newIds = items.filter((m) => m != id);
    this.items.setValue(newIds);
  }
  onGroupsChange(options: MatListOption[]) {
    this.clickedItems = options;
  }
}
