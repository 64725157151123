import { AfterViewInit, ChangeDetectionStrategy, Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { DialogService } from 'projects/msu-its-web-common/src/services/dialog.service';
import { CameraService } from '../../services/camera.service';
import { CameraEditComponent } from '../cameras/camera-edit.component';
import { CameraPopupComponent } from '../cameras/camera-popup.componen';

@Component({
  selector: 'camera-edit-external',
  template: ``,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CameraEditExternalComponent implements AfterViewInit {
  objectId;
  customerId;

  constructor(
    private _route: ActivatedRoute,
    private _cameraService: CameraService,
    private _dialogService: DialogService
  ) {
    this.objectId = this._route.snapshot.paramMap.get('objectId');
    this.customerId = this._route.snapshot.paramMap.get('customerId');
  }

  ngAfterViewInit() {
    this._cameraService.get(this.objectId, this.customerId).subscribe((model) => {
      const dialog = this._dialogService.dialog.open(CameraEditComponent, {
        disableClose: true,
        panelClass: 'external-dialog',
        height: '756',
        width: '940',
      });
      dialog.componentInstance.initForm(model, this._cameraService, false, null);
      //   dialog.componentInstance.model = model;
      dialog.componentInstance.customerId = this.customerId;
      //   dialog.componentInstance.external = true;
      //   dialog.componentInstance.modelId = this.objectId;
    });
  }
}
