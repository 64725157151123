<div fxFlexFill fxLayout="column" fxLayoutGap="12px"  >
  <div *ngIf="external" fxFlex>
    <h4 class="mat-subheading-1" style="margin-bottom: 0; line-height: 1">
      {{ modelInfo.name }}
    </h4>
    <span class="mat-body mat-secondary" style="font-size: 12px">
      {{ modelInfo.description}}
    </span>
  </div>

  <div fxLayout="row" fxLayoutGap="24px" fxFlexFill>
    <div *ngIf="!external" style="flex: 1; width: 95%;  height: 300px;">
      <web-camera #webcamera></web-camera>
    </div>
    <div *ngIf="external" style="flex: 1; width: 95%; height: 98%;">
      <web-camera #webcamera></web-camera>
    </div>
  </div>
</div>
<!-- <div style="flex: 1; width: 650px">
  <web-camera #webcamera></web-camera>
</div> -->
