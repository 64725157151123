<table class="table">
  <tr>
    <td>{{ "COMMON.NAME" | translate }}</td>
    <td>{{ modelInfo.name }}</td>
  </tr>
  <tr>
    <td>{{ "CAMERAS_FTP.SERIAL" | translate }}</td>
    <td>{{ modelInfo.serial }}</td>
  </tr>
  <tr>
    <td>{{ "CAMERAS_FTP.PHONE" | translate }}</td>
    <td>{{ modelInfo.phone }}</td>
  </tr>
  <tr>
    <td>{{ "CAMERAS_FTP.IPADDRESS" | translate }}</td>
    <td>{{ modelInfo.ipaddress }}</td>
  </tr>
</table>
