<div fxFlexFill fxLayout="column" fxLayoutGap="12px">
  <div fxLayout="row" fxLayoutGap="24px" fxFlexFill>
    <div *ngIf="!external" style="flex: 1; width: 95%; height: 300px;">
      <div style="position: absolute; right: 8px; z-index: 10; opacity: 0.8; ">
        <div
          fxLayout="column"
          fxLayoutGap="5px"
          fxLayoutAlign="space-between center"
          style="transform: scale(0.75);"
        >
          <div
            fxLayoutGap="5px"
            fxLayout="row"
            fxLayoutAlign="space-between center"
          >
            <button
              (click)="camControl('ZoomIn')"
              mat-mini-fab
              title="Увеличить"
              style="transform: scale(0.75);"
            >
              <mat-icon>add</mat-icon>
            </button>
            <button
              (click)="camControl('MoveUp')"
              mat-mini-fab
              title="Повернуть вверх"
            >
              <mat-icon style="transform: rotate(270deg);">play_arrow</mat-icon>
            </button>
            <button
              (click)="camControl('ZoomOut')"
              mat-mini-fab
              title="Уменьшить"
              style="transform: scale(0.75);"
            >
              <mat-icon>remove</mat-icon>
            </button>
          </div>
          <div
            fxLayoutGap="5px"
            fxLayout="row"
            fxLayoutAlign="space-between center"
          >
            <button
              (click)="camControl('MoveLeft')"
              mat-mini-fab
              title="Повернуть налево"
            >
              <mat-icon style="transform: rotate(180deg);">play_arrow</mat-icon>
            </button>
            <button
              (click)="camControl('MoveHome')"
              mat-mini-fab
              title="Вернуть домой"
            >
              <mat-icon>home</mat-icon>
            </button>
            <button
              (click)="camControl('MoveRight')"
              mat-mini-fab
              title="Повернуть направо"
            >
              <mat-icon>play_arrow</mat-icon>
            </button>
          </div>
          <div
            fxLayoutGap="5px"
            fxLayout="row"
            fxLayoutAlign="space-between center"
          >
            <button
              (click)="camControl('FocusIn')"
              mat-mini-fab
              title="Увеличить фокус"
              style="transform: scale(0.85);"
            >
              <div style="position: relative;">
                <mat-icon style="transform: scale(1.2);">crop_free</mat-icon>
                <mat-icon
                  style="position: absolute; left: 0%; transform: scale(0.6);"
                  >add</mat-icon
                >
              </div>
            </button>
            <button
              (click)="camControl('MoveDown')"
              mat-mini-fab
              title="Повернуть вниз"
            >
              <mat-icon style="transform: rotate(90deg);">play_arrow</mat-icon>
            </button>
            <button
              (click)="camControl('FocusOut')"
              mat-mini-fab
              title="Уменьшить фокус"
              style="transform: scale(0.85);"
            >
              <div style="position: relative;">
                <mat-icon style="transform: scale(1.2);">crop_free</mat-icon>
                <mat-icon
                  style="position: absolute; left: 0%; transform: scale(0.6);"
                  >remove</mat-icon
                >
              </div>
            </button>
          </div>
        </div>
      </div>
      <web-camera #webcamera></web-camera>
    </div>
    <div
      *ngIf="external"
      style="flex: 1; width: 100%; height: calc(100% - 24px);"
    >
      <div style="position: absolute; right: 24px; z-index: 10; opacity: 0.8;">
        <div
          fxLayout="column"
          fxLayoutGap="5px"
          fxLayoutAlign="space-between center"
        >
          <div
            fxLayoutGap="5px"
            fxLayout="row"
            fxLayoutAlign="space-between center"
          >
            <button
              (click)="camControl('ZoomIn')"
              mat-mini-fab
              title="Увеличить"
              style="transform: scale(0.75);"
            >
              <mat-icon>add</mat-icon>
            </button>
            <button
              (click)="camControl('MoveUp')"
              mat-mini-fab
              title="Повернуть вверх"
            >
              <mat-icon style="transform: rotate(270deg);">play_arrow</mat-icon>
            </button>
            <button
              (click)="camControl('ZoomOut')"
              mat-mini-fab
              title="Уменьшить"
              style="transform: scale(0.75);"
            >
              <mat-icon>remove</mat-icon>
            </button>
          </div>
          <div
            fxLayoutGap="5px"
            fxLayout="row"
            fxLayoutAlign="space-between center"
          >
            <button
              (click)="camControl('MoveLeft')"
              mat-mini-fab
              title="Повернуть налево"
            >
              <mat-icon style="transform: rotate(180deg);">play_arrow</mat-icon>
            </button>
            <button
              (click)="camControl('MoveHome')"
              mat-mini-fab
              title="Вернуть домой"
            >
              <mat-icon>home</mat-icon>
            </button>
            <button
              (click)="camControl('MoveRight')"
              mat-mini-fab
              title="Повернуть направо"
            >
              <mat-icon>play_arrow</mat-icon>
            </button>
          </div>
          <div
            fxLayoutGap="5px"
            fxLayout="row"
            fxLayoutAlign="space-between center"
          >
            <button
              (click)="camControl('FocusIn')"
              mat-mini-fab
              title="Увеличить фокус"
              style="transform: scale(0.85);"
            >
              <div style="position: relative;">
                <mat-icon style="transform: scale(1.2);">crop_free</mat-icon>
                <mat-icon
                  style="position: absolute; left: 0%; transform: scale(0.6);"
                  >add</mat-icon
                >
              </div>
            </button>
            <button
              (click)="camControl('MoveDown')"
              mat-mini-fab
              title="Повернуть вниз"
            >
              <mat-icon style="transform: rotate(90deg);">play_arrow</mat-icon>
            </button>
            <button
              (click)="camControl('FocusOut')"
              mat-mini-fab
              title="Уменьшить фокус"
              style="transform: scale(0.85);"
            >
              <div style="position: relative;">
                <mat-icon style="transform: scale(1.2);">crop_free</mat-icon>
                <mat-icon
                  style="position: absolute; left: 0%; transform: scale(0.6);"
                  >remove</mat-icon
                >
              </div>
            </button>
          </div>
        </div>
      </div>

      <web-camera #webcamera></web-camera>
    </div>
  </div>
</div>
