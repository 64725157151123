import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from '@angular/router';

import { TokenService } from '../services/token.service';

@Injectable({ providedIn: 'root' })
export class PermissionsGuard implements CanActivate {
  constructor(private _tokenService: TokenService) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const permissions: string[] = route.data['permissions'];
    const logic: 'or' | 'and' = route.data['permissionsLogic'];
    return this._tokenService.hasPermissions(permissions, logic);
  }
}

// Usage
// const routes: Routes = [
//   {
//     path: 'users',
//     pathMatch: 'full',
//     component: UsersComponent,
//     canActivate: [AuthGuard, PermissionsGuard],
//     data: { permissions: ['user.create', 'user.read'], permissionsLogic: 'and' }
//   },
// ];
