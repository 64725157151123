import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  ViewChild,
} from '@angular/core';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { TranslateService } from '@ngx-translate/core';
import { SettingsService } from 'projects/msu-its-web-common/src/services/settings.service';
import { MapService } from 'projects/msu-its-web-common/src/services/map.service';

import { LayoutSvgUtils } from 'projects/msu-its-web-tlc/src/dtos/layout';
import { SchemaSvgUtils, SchemaUtils } from 'projects/msu-its-web-tlc/src/dtos/schema';
import {
  TrafficMovementLight,
  TrafficObjectMode,
  TrafficObjectStatus,
} from 'projects/msu-its-web-tlc/src/dtos/tlc.dtos';

import { IPotokTrafficObjectSet, PotokStateService } from './potok-state.service';

@Component({
  selector: 'potok-state-schema',
  templateUrl: './potok-state-schema.component.html',
  styleUrls: ['./potok-state-schema.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PotokStateSchemaComponent implements AfterViewInit, OnDestroy {
  _destroy = new Subject();

  @Input()
  disabled = false;

  status: TrafficObjectStatus;
  trafficObjectSet: IPotokTrafficObjectSet;

  mapEnabled = false;
  schemaMap;

  @ViewChild('schemaSvg')
  _schemaSvg: ElementRef;
  _schemaSvgLoaded = false;

  @ViewChild('schemaMap')
  _svgSchemaMap: ElementRef;

  get schema() {
    return this.trafficObjectSet?.schemaSet.schema;
  }
  get schemaView() {
    return this.trafficObjectSet?.schemaSet.schemaView;
  }

  constructor(
    private _mapService: MapService,
    private _stateService: PotokStateService,
    private _settingsService: SettingsService,
    private _changeDetector: ChangeDetectorRef,
    public translate: TranslateService
  ) {}

  ngAfterViewInit() {
    this._initSchemeMap();

    this._settingsService.darkThemeChanged.pipe(takeUntil(this._destroy)).subscribe(() => {
      this._updateSchemaTheme();
    });

    this._stateService.trafficObjectSet$
      .pipe(takeUntil(this._destroy))
      .subscribe((trafficObjectSet) => {
        this.trafficObjectSet = trafficObjectSet;
        this._updateSchema();
        this._updateMapView();
      });

    this._stateService.status$.pipe(takeUntil(this._destroy)).subscribe((status) => {
      this.status = status;
      this._updateSchemaState();
    });
  }

  ngOnDestroy() {
    this._destroy.next();
    this._destroy.complete();
  }

  initSchemaSvg() {
    this._schemaSvgLoaded = true;
    this._updateSchema();
  }

  _initSchemeMap() {
    this.schemaMap = this._mapService.initMap(this._svgSchemaMap.nativeElement, {
      trafficLayer: false,
    });
    this.schemaMap.dragging.disable();
    this.schemaMap.touchZoom.disable();
    this.schemaMap.doubleClickZoom.disable();
    this.schemaMap.scrollWheelZoom.disable();
    this.schemaMap.boxZoom.disable();
    this.schemaMap.keyboard.disable();
    if (this.schemaMap.tap) {
      this.schemaMap.tap.disable();
    }
    this._svgSchemaMap.nativeElement.style['cursor'] = 'default';
    this._svgSchemaMap.nativeElement.style['z-index'] = 0;
  }

  _updateMapView() {
    if (this.schemaView && this.schemaMap) {
      this.schemaMap.setView([this.schemaView.lat, this.schemaView.lon], this.schemaView.zoom);
    }
  }

  _updateSchemaTheme() {
    const svg = this._schemaSvg.nativeElement.contentDocument;
    const dark = this._settingsService.darkTheme && !this.mapEnabled;
    SchemaSvgUtils.updateTheme(svg, dark);
  }

  _updateSchema() {
    if (!this._schemaSvgLoaded || !this.trafficObjectSet) return;

    const schema = this.schema;
    const schemaView = this.schemaView;
    const svg = this._schemaSvg.nativeElement.contentDocument;
    const dark = this._settingsService.darkTheme && !this.mapEnabled;

    LayoutSvgUtils.updateLayoutSchema(svg, schema, schemaView, dark);
    LayoutSvgUtils.updateLayoutControl(svg, schema);

    SchemaSvgUtils.updateMovements(svg, schema);
    SchemaSvgUtils.updateTheme(svg, dark);

    setTimeout(() => {
      this._schemaSvg.nativeElement.style.visibility = 'visible';
    }, 250);
  }

  _updateSchemaState() {
    const svg = this._schemaSvg.nativeElement.contentDocument;
    if (svg && this.status?.schemaLights && this.schema) {
      const lights = this.status.schemaLights;
      const schema = this.schema;

      this._updateSvgLight(svg, 'Move2', lights.move2, schema.Move2.enabled);
      this._updateSvgLight(svg, 'Move4', lights.move4, schema.Move4.enabled);
      this._updateSvgLight(svg, 'Move6', lights.move6, schema.Move6.enabled);
      this._updateSvgLight(svg, 'Move8', lights.move8, schema.Move8.enabled);
      this._updateSvgLight(svg, 'Move1', lights.move1, schema.Move1.enabled);
      this._updateSvgLight(svg, 'Move3', lights.move3, schema.Move3.enabled);
      this._updateSvgLight(svg, 'Move5', lights.move5, schema.Move5.enabled);
      this._updateSvgLight(svg, 'Move7', lights.move7, schema.Move7.enabled);
      this._updateSvgLight(svg, 'Move12', lights.move12, schema.Move12.enabled);
      this._updateSvgLight(svg, 'Move14', lights.move14, schema.Move14.enabled);
      this._updateSvgLight(svg, 'Move16', lights.move16, schema.Move16.enabled);
      this._updateSvgLight(svg, 'Move18', lights.move18, schema.Move18.enabled);
      this._updateSvgLight(svg, 'MoveP2', lights.moveP2, schema.MoveP2.enabled);
      this._updateSvgLight(svg, 'MoveP4', lights.moveP4, schema.MoveP4.enabled);
      this._updateSvgLight(svg, 'MoveP6', lights.moveP6, schema.MoveP6.enabled);
      this._updateSvgLight(svg, 'MoveP8', lights.moveP8, schema.MoveP8.enabled);

      const movements =
        this.status.mode != TrafficObjectMode.Off
          ? SchemaUtils.schemaLightToMovements(schema, lights)
          : [];

      LayoutSvgUtils.showLayoutMovements(svg, schema, movements);
    }
    this._changeDetector.markForCheck();
  }

  _updateSvgLight(svg, svgMovementId: string, state: TrafficMovementLight, enabled: boolean) {
    const elm = svg.getElementById(svgMovementId);
    if (elm === null) {
      return;
    }

    let fill = '#777';
    let stroke = '#999';
    let opacity = 1;
    let blink = '';

    if (enabled) {
      switch (state) {
        case TrafficMovementLight.Green:
          fill = '#2c2';
          stroke = '#080';
          break;
        case TrafficMovementLight.GreenBlink:
          fill = 'transparent';
          stroke = '#080';
          blink = 'green';
          break;
        case TrafficMovementLight.Red:
          fill = '#e00';
          stroke = '#800';
          break;
        case TrafficMovementLight.RedYellow:
          fill = 'url(#redyellow)';
          stroke = 'url(#redyellow_stroke)';
          break;
        case TrafficMovementLight.Yellow:
          fill = '#fe4';
          stroke = '#880';
          break;
        case TrafficMovementLight.YellowBlink:
          fill = 'transparent';
          stroke = '#880';
          blink = 'yellow';
          break;
        default:
          opacity = 0;
          break;
      }
    } else {
      opacity = 0;
    }

    elm.style.fill = fill;
    elm.style.stroke = stroke;
    elm.style.opacity = opacity;
    elm.setAttribute('data-blink', blink);
  }

  setMapEnabled(enabled: boolean) {
    this.mapEnabled = enabled;
    this.schemaMap.invalidateSize();
    this._updateSchemaTheme();
  }
}
