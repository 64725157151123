import { marker as _ } from "@biesbjerg/ngx-translate-extract-marker";

import { Inject, Injectable } from "@angular/core";
import { SettingsService } from "./settings.service";
import { TranslateService } from "@ngx-translate/core";

declare const L;
declare const HeatmapOverlay;

L.Icon.Default.imagePath = "/assets/images/map/";
L.Icon.Default.imageMarkerIcon = "/assets/images/map/marker-icon.png";

import "../utils/map-markers";

const EMPTY_TILE_URL = "/assets/images/map/tile-empty.png";
const EMPTY_GRID_TILE_URL = "/assets/images/map/tile-grid.png";


@Injectable({ providedIn: "root" })
export class MapService {
  constructor(
    @Inject("APP_ENVIRONMENT") private _appEnv,
    private _settingsService: SettingsService,
    public translate: TranslateService
  ) {}

  /// Options:
  /// allowLayers: [] - list of allowed layers for map instance, default: ['yandex', 'google', 'doublegis', 'osm', 'satellite', 'empty',]
  /// trafficLayer: boolean - allow enable traffic layer, default: true
  initMap(element, options?, options2?) {
    options = Object.assign(
      {
        trafficLayer: true,
        allowLayers: [
          "yandex",
          "google",
          "doublegis",
          "osm",
          "satellite",
          "empty",
        ],
        preferCanvas: true,
      },
      options,
      {
        doubleClickZoom: false,
        zoomControl: false,
        layersControl: false,

        zoomSnap: 0.25,
        zoomDelta: 0.25,
        minZoom: 4,
      },
      options2
    );

    if (this._appEnv.allowMapLayers) {
      options.allowLayers = options.allowLayers.filter((m) =>
        this._appEnv.allowMapLayers.includes(m)
      );
    }
    if (this._appEnv.disallowMapTraffic) {
      options.trafficLayer = false;
    }

    const yandexLayer = L.tileLayer(
      "https://core-renderer-tiles.maps.yandex.net/tiles?l=map&v={version}&x={x}&y={y}&z={z}&scale=1&lang=ru_RU",
      {
        id: "yandex",
        attribution: "&copy; Yandex",
        crs: L.CRS.EPSG3395,
        maxZoom: 19,
        subdomains: "1234",
        version: "21.06.07-1",
        className: "main-layer",
        key: _("MAP.YANDEX"),
      }
    );
    const yandexTrafficLayer = L.tileLayer(
      "https://core-jams-rdr-cache.maps.yandex.net/tiles?trf&l=trf&x={x}&y={y}&z={z}&scale=1&tm={tm}",
      {
        attribution: "&copy; Yandex",
        crs: L.CRS.EPSG3395,
        maxZoom: 19,
        errorTileUrl: EMPTY_TILE_URL,
        tm: () => Math.floor(new Date().getTime() / 1000),
        className: "traffic-layer",
      }
    );
    const yandex = options.trafficLayer
      ? L.layerGroup([yandexLayer, yandexTrafficLayer], {
          id: "yandex",
          crs: L.CRS.EPSG3395,
          maxZoom: 19,
          trafficLayer: yandexTrafficLayer,
        })
      : yandexLayer;

    const googleLayer = L.tileLayer(
      "https://maps.googleapis.com/maps/vt?lyrs=m&hl=ru&x={x}&y={y}&z={z}&scale=1&apistyle=s.t:2|p.v:off",
      {
        id: "google",
        attribution: "&copy; Google",
        crs: L.CRS.EPSG3857,
        maxZoom: 20,
        className: "main-layer",
      }
    );
    const googleTrafficLayer = L.tileLayer(
      "https://maps.googleapis.com/maps/vt?lyrs=h,traffic&hl=ru&x={x}&y={y}&z={z}&scale=1&apistyle=s.t:2,3|p.v:off",
      {
        attribution: "&copy; Google",
        crs: L.CRS.EPSG3857,
        maxZoom: 20,
        errorTileUrl: EMPTY_TILE_URL,
        className: "traffic-layer",
        key: _("MAP.GOOGLE"),
      }
    );
    const google = options.trafficLayer
      ? L.layerGroup([googleLayer, googleTrafficLayer], {
          id: "google",
          crs: L.CRS.EPSG3857,
          maxZoom: 20,
          trafficLayer: googleTrafficLayer,
        })
      : googleLayer;

    const doublegisLayer = L.tileLayer(
      "https://tile{s}.maps.2gis.com/tiles?x={x}&y={y}&z={z}&ts=online_sd",
      {
        id: "doublegis",
        attribution: "&copy; 2GIS",
        crs: L.CRS.EPSG3857,
        maxZoom: 18,
        subdomains: "0123",
        className: "main-layer",
      }
    );
    const doublegisTrafficLayer = L.tileLayer(
      "https://traffic{s}.maps.2gis.com/krasnoyarsk/traffic/{z}/{x}/{y}/speed/0/",
      {
        attribution: "&copy; 2GIS",
        crs: L.CRS.EPSG3857,
        subdomains: "123456789",
        maxZoom: 18,
        errorTileUrl: EMPTY_TILE_URL,
        className: "traffic-layer",
        key: _("MAP.DOUBLEGIS"),
      }
    );
    const doublegis = options.trafficLayer
      ? L.layerGroup([doublegisLayer, doublegisTrafficLayer], {
          id: "doublegis",
          crs: L.CRS.EPSG3857,
          maxZoom: 18,
          trafficLayer: doublegisTrafficLayer,
        })
      : doublegisLayer;

    const osmLayer = L.tileLayer(
      "https://tile.openstreetmap.org/{z}/{x}/{y}.png",
      {
        // const osmLayer = L.tileLayer('https://{s}.osm.rrze.fau.de/osmhd/{z}/{x}/{y}.png', { // hd
        // const osmLayer = L.tileLayer('http://tile.osmand.net/hd/{z}/{x}/{y}.png', { // hd
        id: "osm",
        attribution: "&copy; openstreetmap",
        crs: L.CRS.EPSG3857,
        maxZoom: 19,
        subdomains: "abc",
        className: "main-layer",
      }
    );
    const osm = osmLayer;

    const satelliteLayer = L.tileLayer(
      "https://maps.googleapis.com/maps/vt?lyrs=s,h&hl=ru&x={x}&y={y}&z={z}&scale=1&apistyle=s.t:2|p.v:off",
      {
        id: "satellite",
        attribution: "&copy; google",
        crs: L.CRS.EPSG3857,
        maxZoom: 20,
        className: "main-layer",
      }
    );
    const satellite = satelliteLayer;

    const emptyLayer = L.tileLayer(EMPTY_GRID_TILE_URL, {
      id: "empty",
      attribution: "",
      crs: L.CRS.EPSG3857,
      maxZoom: 20,
      className: "main-layer",
    });
    const empty = emptyLayer;







    google.options.key = _("MAP.GOOGLE");
    yandex.options.key = _("MAP.YANDEX");
    doublegis.options.key = _("MAP.DOUBLEGIS");
    satellite.options.key = _("MAP.SATELLITE");
    empty.options.key = _("MAP.EMPTY");
    osm.options.key = _("MAP.OSM");

    const layerLists = [
      osm,
      yandex,
      google,
      doublegis,
      satellite,
      empty,
    ].filter((m) => options.allowLayers.some((id) => m.options.id === id));

    const initLayer =
      layerLists.find((m) => m.options.id === this._settingsService.mapLayer) ||
      layerLists[0];

    const map = L.map(element, {
      crs: initLayer.options.crs,
      maxZoom: initLayer.options.maxZoom,
      layers: [initLayer],
      currentLayer: initLayer,
      layerLists,
      ...options,
    }).on("baselayerchange", (event) => {
      const center = map.getCenter();
      const zoom = map.getZoom();
      map.options.crs = event.layer.options.crs;
      map.options.maxZoom = options.maxZoom
        ? options.maxZoom
        : event.layer.options.maxZoom;
      map.options.currentLayer = event.layer;
      map.setView(center, zoom, { reset: true });

      this._settingsService.mapLayer = event.layer.options.id;
      this._checkTrafficLayer(map);
    });

    map.attributionControl.setPrefix("");

    if (options.trafficLayer) {
      const mapTrafficSubscription = this._settingsService.mapTrafficChanged.subscribe(
        () => {
          this._checkTrafficLayer(map);
          this._settingsService.mapTraffic && this._updateTrafficLayer(map);
        }
      );

      this._checkTrafficLayer(map);

      const redrawTrafficTimer = setInterval(
        () => this._updateTrafficLayer(map),
        30000
      );

      map.on("unload", () => {
        clearInterval(redrawTrafficTimer);
        mapTrafficSubscription.unsubscribe();
      });
    }

    map._container.classList.toggle("leaflet-touch", false);
    return map;
  }

  private _updateTrafficLayer(map) {
    const l = map.options.currentLayer;
    if (l && l.options.trafficLayer && this._settingsService.mapTraffic) {
      l.options.trafficLayer.redraw();
    }
  }

  private _checkTrafficLayer(map) {
    map.options.currentLayer.options.trafficLayer?.setOpacity(
      this._settingsService.mapTraffic ? 0.8 : 0
    );
  }
}
