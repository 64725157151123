import { marker as _ } from "@biesbjerg/ngx-translate-extract-marker";

import {
  Component,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Inject,
  Input,
  OnInit,
  OnDestroy,
} from "@angular/core";
import { TranslateService } from "@ngx-translate/core";

import { Subject } from "rxjs";

import { DialogService } from "projects/msu-its-web-common/src/services/dialog.service";

import { APP_ENVIRONMENT } from "projects/msu-its-web-common/src/utils/shared-consts";

import { FixationPoint, FixationPointInfo } from "../../dtos/ftv.dtos";
import { FixationPointService } from "../../services/fixation-point/fixation-point.service";
import { LANGS_LOCALES } from "projects/msu-its-web-common/src/utils/langs";

@Component({
  selector: "fixation-point-popup",
  templateUrl: "./fixation-point-popup.component.html",
  styles: [``],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FixationPointPopupComponent implements OnInit, OnDestroy {
  private _destroy = new Subject();

  @Input()
  modelId: string;

  @Input()
  external = false;

  @Input()
  customerId: string;

  @Input()
  modelInfo: FixationPoint;

  modelInfoSession: FixationPointInfo;

  loading = false;

  get locale() {
    return LANGS_LOCALES[this.translate.currentLang];
  }

  actions: { name: string; action: string; permissions?: string[] }[] = [
    {
      name: _("FIXATING_TRAFFIC_EVENTS.TABLE_TITLE"),
      action: "openEdit",
      permissions: ["fixationPoint.read"],
    },
  ];

  constructor(
    private _changeDetector: ChangeDetectorRef,
    private _dialogService: DialogService,
    public translate: TranslateService,
    private _pointService: FixationPointService,
    @Inject(APP_ENVIRONMENT) private appEnv: any
  ) {}

  openInNew() {
    this._dialogService.windowDialog(
      `${this.customerId}/ext/fixation-traffic-events/${this.modelId}`,
      "fixationEvent_" + this.modelId,
      "width=1550,height=800"
    );
  }

  openEdit() {
    this._dialogService.windowDialog(
      `${this.customerId}/ext/fixation-traffic-events/${this.modelId}`,
      "fixationEvent_" + this.modelId,
      "width=1550,height=800"
    );
  }

  onResize($event) {
    // this.chartParams.resize();
    this._changeDetector.markForCheck();
  }
  ngOnInit() {
    this.loading = true;
    this._pointService
      .getFixationPointInfo(this.modelId, this.customerId)
      .subscribe((res) => {
        this.modelInfoSession = res;
        this.loading = false;
        // console.log(res);
        this._changeDetector.markForCheck();
      });
  }

  ngOnDestroy() {
    this._destroy.next();
    this._destroy.complete();
  }
}
