import { Injectable } from '@angular/core';
import {
  getPageReturn,
  IDataSourceService,
  IPageParams,
  IPageReturn,
  setPageParams,
} from 'projects/msu-its-web-common/src/data/data-source';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import {
  StartPriorityControlSessionRequest,
  StopPriorityControlSessionRequest,
  GetPriorityControlSessionStatusRequest,
  ControlSession,
  PriorityControlSessionStartTrafficObjectRequest,
  PriorityControlSessionStopTrafficObjectRequest,
  TrafficMovementType,
  ControlSessionSettings,
  GetPriorityControlSessionSummaryRequest,
  GetPriorityControlSessionsRequest,
} from '../dtos/tpc.dtos';

import { TpcApiService } from './tpc-api.service';

@Injectable()
export class ControlSessionService implements IDataSourceService<ControlSession, ControlSession> {
  constructor(private _api: TpcApiService) {}

  getPagedList(params: IPageParams, customerId: string): Observable<IPageReturn<ControlSession>> {
    if (!customerId) {
      return of(null);
    }

    const request = new GetPriorityControlSessionsRequest();
    request.customerId = customerId;

    setPageParams(request, params);
    return this._api.get(request).pipe(
      map((m) => {
        return getPageReturn(m);
      })
    );
  }

  get(id: any, params?: any): Observable<ControlSession> {
    throw new Error('Method not implemented.');
  }
  add(model: ControlSession, params?: any): Observable<ControlSession> {
    throw new Error('Method not implemented.');
  }
  update(model: ControlSession, params?: any): Observable<ControlSession> {
    throw new Error('Method not implemented.');
  }
  delete(ids: any[], params?: any): Observable<number> {
    throw new Error('Method not implemented.');
  }

  start(
    session: {
      name: string;
      routeId: string;
      trackerId: string;
      tracker2Id?: string;
      detectionRadius: number;
      suspendTime: number;
    },
    customerId: string
  ) {
    const request = new StartPriorityControlSessionRequest();
    request.customerId = customerId;
    request.entity = new ControlSession({
      name: session.name,
      routeId: session.routeId,
      trackingDevice: session.trackerId,
      trackingDevice2: session.tracker2Id || session.trackerId,
      settings: new ControlSessionSettings({
        detectionRadius: session.detectionRadius,
        // suspendTime: session.suspendTime,
      }),
      customerId,
    });
    return this._api.post(request);
  }

  stop(id: string, customerId: string) {
    const request = new StopPriorityControlSessionRequest();
    request.id = id;
    request.customerId = customerId;
    return this._api.post(request);
  }

  getStatus(id: string, customerId: string) {
    const request = new GetPriorityControlSessionStatusRequest();
    request.id = id;
    request.customerId = customerId;
    return this._api.get(request);
  }

  getSummary(id: string, customerId: string) {
    const request = new GetPriorityControlSessionSummaryRequest();
    request.id = id;
    request.customerId = customerId;
    return this._api.get(request);
  }

  startTrafficObject(
    id: string,
    customerId: string,
    trafficObjectId: string,
    phaseId?: number,
    movements?: TrafficMovementType[]
  ) {
    const request = new PriorityControlSessionStartTrafficObjectRequest();
    request.id = id;
    request.customerId = customerId;
    request.trafficObjectId = trafficObjectId;
    request.phaseId = phaseId;
    request.movements = movements;
    return this._api.post(request);
  }

  stopTrafficObject(id: string, customerId: string, trafficObjectId: string) {
    const request = new PriorityControlSessionStopTrafficObjectRequest();
    request.id = id;
    request.customerId = customerId;
    request.trafficObjectId = trafficObjectId;
    return this._api.post(request);
  }
}
