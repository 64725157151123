<div>
  <div *ngIf="loading" class="backdrop" style="z-index: 1;">
    <mat-spinner diameter="36" color="accent"></mat-spinner>
  </div>

  <div style="overflow: auto; padding: 8px;">
    <table class="table" style="width: 100%;">
      <tr *ngIf="modelInfoSession?.fullNumber " class="mat-hover">
        <td>{{ "FIXATING_VIOLATION_POINTS.POPUP.FULL_NUMBER" | translate }}</td>
        <td>{{ modelInfoSession?.fullNumber }}</td>
      </tr>
      <tr *ngIf="modelInfoSession?.created " class="mat-hover">
        <td>
          {{ "FIXATING_VIOLATION_POINTS.POPUP.CREATED_OBJECT" | translate }}
        </td>
        <td>{{ modelInfoSession?.created | date: "long":null:locale }}</td>
      </tr>
      <tr *ngIf="modelInfoSession?.modified " class="mat-hover">
        <td>
          {{ "FIXATING_VIOLATION_POINTS.POPUP.LAST_CHANGES_OBJECT" | translate
          }}
        </td>
        <td>{{ modelInfoSession?.modified | date: "long":null:locale }}</td>
      </tr>
      <tr
        *ngIf="modelInfoSession?.session?.lastConnectionTime"
        class="mat-hover"
      >
        <td>
          {{ "FIXATING_VIOLATION_POINTS.POPUP.LAST_CONNECTION_TIME" | translate
          }}
        </td>
        <td>
          {{ modelInfoSession?.session?.lastConnectionTime | date:
          "long":null:locale }}
        </td>
      </tr>
      <tr *ngIf="modelInfoSession?.session?.deviceNumber" class="mat-hover">
        <td>
          {{ "FIXATING_VIOLATION_POINTS.POPUP.DEVICE_NUMBER" | translate }}
        </td>
        <td>
          {{ modelInfoSession?.session?.deviceNumber }}
        </td>
      </tr>
    </table>
  </div>
</div>
