import {
  Component,
  ChangeDetectionStrategy,
  AfterViewInit,
  Input,
  ChangeDetectorRef,
  Inject,
  Injector,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { LocationComponent } from 'projects/msu-its-web-common/src/components/map/location.component';

import { DataSourceForm } from 'projects/msu-its-web-common/src/data/data-source-form';
import { ExternalSystem } from 'projects/msu-its-web-common/src/dtos/hub.dtos';
import { DialogService } from 'projects/msu-its-web-common/src/services/dialog.service';
import { ExternalSystemService } from 'projects/msu-its-web-common/src/services/external-system.service';
import { FormValidators } from 'projects/msu-its-web-common/src/utils/form-validators';
import { APP_ENVIRONMENT } from 'projects/msu-its-web-common/src/utils/shared-consts';
import { Subject, BehaviorSubject, NEVER, EMPTY, forkJoin } from 'rxjs';
import { debounceTime, takeUntil, catchError, map } from 'rxjs/operators';

import { EntityGroupInfo, MeteoObject, MeteoStationInfo } from '../../dtos/meteo.dtos';
import { MeteoObjectGroupService } from '../../services/meteo-object-group.service';
import { MeteoObjectsService } from '../../services/meteo-objects.service';
import { MeteoStationsService } from '../../services/meteo-stations.service';

@Component({
  selector: 'meteo-object-edit',
  templateUrl: './meteo-object-edit.component.html',
  styles: [
    `
      :host {
        display: flex;
        flex-direction: column;
      }
      .checkbox label {
        text-align: right;
        float: left;
      }
      .checkbox input {
        margin-left: 16px;
        float: right;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MeteoObjectEditComponent extends DataSourceForm<MeteoObject> implements AfterViewInit {
  _destroy = new Subject();
  customerId: string;
  groupList: EntityGroupInfo[] = [];
  selectedGroups = new FormControl({ value: [] as string[], disabled: true });

  @Input()
  external = false;

  @ViewChild('location', { static: true })
  location: LocationComponent;

  searchTemp;
  searchInputSubject = new Subject<string>();
  controllerListSubject = new BehaviorSubject<MeteoStationInfo[]>([]);
  selectedController: MeteoStationInfo;

  externalSystem: boolean = false;
  externalSystemList: ExternalSystem[] = [];

  constructor(
    injector: Injector,
    changeDetector: ChangeDetectorRef,
    private _route: ActivatedRoute,
    public dialogService: DialogService,
    private _meteoStationService: MeteoStationsService,
    private _meteoObjectGroupService: MeteoObjectGroupService,
    private _externalSystemService: ExternalSystemService,
    @Inject(APP_ENVIRONMENT) private _appEnv
  ) {
    super(injector, changeDetector);
    this.customerId = this._route.snapshot.paramMap.get('customerId');
  }

  ngAfterViewInit() {
    this.onlyFreeCheck.setValue(true);
    this.onlyFreeCheck.valueChanges.pipe(takeUntil(this._destroy)).subscribe(() => {
      this._loadControllers(this.searchTemp);
    });

    if (this.isNew) {
      this.location.lat = 56.010499;
      this.location.lon = 92.86593;
      this.changeDetector.markForCheck();
    }
  }

  initFormGroup(fb: FormBuilder): FormGroup {
    if (this.isNew) {
      this.searchInputSubject
        .pipe(debounceTime(250))
        .pipe(takeUntil(this._destroy))
        .subscribe((search) => {
          this.searchTemp = search;
          this._loadControllers(search);
        });
    }

    const controllers = this.controllerListSubject.getValue();
    this.controllerListSubject.next(
      controllers.filter((m) => m.id !== this.selectedController?.id)
    );

    setTimeout(() => {
      this._loadGroups();
      this._loadControllers(null);
      this._loadControllersEdit(null);
      this._loadExternalSystems();
    });

    this.location.lat = this.model.lat;
    this.location.lon = this.model.lon;
    this.location.direction = this.model.direction;
    this.externalSystem = !!this.model.externalSystemId;

    return fb.group({
      name: [
        '',
        [Validators.required, Validators.minLength(4), Validators.maxLength(100)],
        [
          FormValidators.exist(this.model.name, (name: string) =>
            (this.modelService as MeteoObjectsService).exist(name)
          ),
        ],
      ],
      description: '',
      direction: '',
      meteoStationId: [{ value: '', disabled: !this.isNew }],
      onlyFreeCheck: [''],
      externalSystemId: [''],
      externalSystemEntityId: [''],
    });
  }

  _loadControllers(search: string) {
    if (this.isNew) {
      this._meteoStationService
        .getList(this.customerId, search, 11, [''], this.onlyFreeCheck.value)
        .pipe(catchError(() => NEVER))
        .pipe(takeUntil(this._destroy))
        .subscribe((result) => {
          this.controllerListSubject.next(
            result.filter((m) => m.id !== this.meteoStationId?.value)
          );
          this.changeDetector.markForCheck();
        });
    }
  }

  _loadExternalSystems() {
    this._externalSystemService
      .getList()
      .pipe(catchError(() => NEVER))
      .pipe(takeUntil(this._destroy))
      .subscribe((result) => {
        this.externalSystemList = result;
        this.changeDetector.markForCheck();
      });
  }

  _loadControllersEdit(search: string) {
    if (!this.isNew) {
      this._meteoStationService
        .getList(this.customerId, search)
        .pipe(catchError(() => NEVER))
        .pipe(takeUntil(this._destroy))
        .subscribe((result) => {
          this.selectedController = result.find((m) => m.id === this.model.meteoStationId);
          this.changeDetector.markForCheck();
        });
    }
  }

  controllerSelected(controllerId) {
    const controllers = this.controllerListSubject.getValue();
    this.selectedController = controllers.find((m) => m.id === controllerId);
    this.controllerListSubject.next(
      controllers.filter((m) => m.id !== this.selectedController?.id)
    );
  }

  _loadGroups() {
    forkJoin([
      this._meteoObjectGroupService.getList(this.customerId, null, true),
      this._meteoObjectGroupService
        .getObjectGroups(this.model.id, this.customerId)
        .pipe(map((m) => m.map((n) => n.id))),
    ])
      .pipe(catchError(() => EMPTY))
      .pipe(takeUntil(this._destroy))
      .subscribe((result) => {
        this.groupList = result[0];
        this.selectedGroups.setValue(result[1]);
        this.selectedGroups.enable();
        this.changeDetector.markForCheck();
      });
  }
  onGroupRemoved(groupId: string) {
    const groupIds = this.selectedGroups.value as string[];
    this.selectedGroups.setValue(groupIds.filter((m) => m !== groupId));
  }
  getGroupName(groupId: string) {
    return this.groupList.find((m) => m.id === groupId)?.name || groupId;
  }

  prepareModelToSave(): MeteoObject {
    const model = Object.assign(new MeteoObject(), this.model, this.formGroup.value);
    model.lat = this.location.lat;
    model.lon = this.location.lon;

    return model;
  }
  prepareParamsToSave() {
    const customerId = this.customerId;
    const groups = this.selectedGroups.value;
    const updateGroups = this.selectedGroups.enabled;

    if (this.isNew) {
      return { customerId, groups, updateGroups };
    } else {
      return { customerId, groups, updateGroups };
    }
  }

  onDirectionChanged() {
    this.location.setDirection(this.direction.value);
    this.changeDetector.markForCheck();
  }

  changeDevice() {
    // this.getEdit(this.model.id).subscribe((result) => {
    //   const dialog = this.dialogService.dialog.open(DetectorChangeDeviceComponent, {
    //     disableClose: true,
    //   });
    //   dialog.componentInstance.modelId = this.model.id;
    //   dialog.componentInstance.model = result;
    //   dialog.componentInstance.customerId = this.customerId;
    //   return dialog;
    // });
  }

  getEdit(id) {
    return this.modelService.get(id, this.customerId);
  }

  get name() {
    return this.formGroup.controls['name'];
  }

  get description() {
    return this.formGroup.controls['description'];
  }
  get direction() {
    return this.formGroup.controls['direction'];
  }
  get meteoStationId() {
    return this.formGroup.controls['meteoStationId'];
  }
  get userStatus() {
    return this.formGroup.controls['userStatus'];
  }
  get onlyFreeCheck() {
    return this.formGroup.controls['onlyFreeCheck'];
  }
  get externalSystemId() {
    return this.formGroup.controls['externalSystemId'];
  }
  get externalSystemEntityId() {
    return this.formGroup.controls['externalSystemEntityId'];
  }
}
