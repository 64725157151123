import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
} from "@angular/core";
import { ActivatedRoute } from "@angular/router";

import { DialogService } from "projects/msu-its-web-common/src/services/dialog.service";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { MeteoObjectsService } from "../../services/meteo-objects.service";
import { MeteoObjectPopupComponent } from "../meteo-popup/meteo-object-popup.component";

@Component({
  selector: "meteo-object-external",
  template: ``,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MeteoObjectExternalComponent implements AfterViewInit, OnDestroy {
  objectId;
  customerId;
  private _destroy = new Subject();

  constructor(
    private _route: ActivatedRoute,
    private _meteoObjectService: MeteoObjectsService,
    private _dialogService: DialogService
  ) {
    this.objectId = this._route.snapshot.paramMap.get("objectId");
    this.customerId = this._route.snapshot.paramMap.get("customerId");
  }

  ngAfterViewInit() {
    this._meteoObjectService
      .get(this.objectId, this.customerId)
      .pipe(takeUntil(this._destroy))
      .subscribe((model) => {
        const dialog = this._dialogService.dialog.open(
          MeteoObjectPopupComponent,
          {
            disableClose: true,
            panelClass: "external-dialog",
          }
        );
        dialog.componentInstance.modelId = this.objectId;
        dialog.componentInstance.modelInfo = model;
        dialog.componentInstance.customerId = this.customerId;
        dialog.componentInstance.external = true;
      });
  }
  ngOnDestroy() {
    this._destroy.next();
    this._destroy.complete();
  }
}
