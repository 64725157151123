<div *ngIf="loading" class="backdrop">
  <mat-spinner diameter="36" color="accent"></mat-spinner>
</div>

<h4 mat-dialog-title>
  <span *ngIf="isNew">{{ "CUSTOMERS.CREATE_CUSTOMER" | translate }}</span>
  <span *ngIf="!isNew">{{ "CUSTOMERS.EDIT_CUSTOMER" | translate }}</span>
</h4>
<mat-dialog-content>
  <form [formGroup]="formGroup" (ngSubmit)="submit()" autocomplete="off">
    <div style="min-width: 320px; padding-bottom: 24px">
      <mat-form-field style="width: 100%">
        <input
          matInput
          [formControl]="name"
          placeholder="{{ 'COMMON.NAME' | translate }}"
          autocomplete="off"
          maxlength="100"
          trimValue
        />
        <mat-hint>{{ "CUSTOMERS.NAME_HINT" | translate }}</mat-hint>
        <mat-error
          *ngIf="name.hasError('minlength') || name.hasError('maxlength')"
        >
          {{ "CUSTOMERS.NAME_LENGTH" | translate: { min: 3, max: 100 } }}
        </mat-error>
        <mat-error *ngIf="name.hasError('required')">
          {{ "CUSTOMERS.NAME_REQUIRED" | translate }}
        </mat-error>
        <mat-error *ngIf="name.hasError('exist')">
          {{ "CUSTOMERS.NAME_EXIST" | translate }}
        </mat-error>
      </mat-form-field>
    </div>
  </form>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button (click)="submit()" color="primary">
    <span *ngIf="isNew">{{ "COMMON.CREATE" | translate }}</span>
    <span *ngIf="!isNew">{{ "COMMON.SAVE" | translate }}</span>
  </button>
  <button mat-button (click)="close()">
    {{ "COMMON.CANCEL" | translate }}
  </button>
</mat-dialog-actions>
