import { Pipe, PipeTransform } from '@angular/core';

import { TokenService } from '../services/token.service';

@Pipe({ name: 'hasPermissions' })
export class HasPermissionsPipe implements PipeTransform {
  constructor(private _tokenService: TokenService) {}

  transform(permissions: string[], logic: 'or' | 'and' = 'or') {
    return this._tokenService.hasPermissions(permissions, logic);
  }
}

// Usage
// <button [disabled]="!(['user.create', 'user.read'] | hasPermissions)"></button> - test permissions 'or'
// <button [disabled]="!(['user.create', 'user.read'] | hasPermissions : 'and')"></button> - test permissions 'and'
