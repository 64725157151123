import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';

import {
  Component,
  ChangeDetectionStrategy,
  Injector,
  Input,
  OnInit,
} from '@angular/core';

import { EventItemDetails } from '../../dtos/hub.dtos';

import { TableBaseComponent } from '../tables/table-base.component';
import { EventLogService } from '../../services/event-log.service';
import { SettingsService } from '../../services/settings.service';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'event-log',
  templateUrl: './event-log.component.html',
  styles: [
    `
      :host {
        display: flex;
        flex: 1;
        width: 100%;
        height: 100%;
      }
      /*
      .mat-column-entityName {
        flex: 2;
      }
      */
      .mat-column-description {
        flex: 2;
      }
      .date-button {
        pointer-events: none;
        font-size: 16px;
        position: absolute;
        right: 0;
        width: 20px;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EventLogComponent
  extends TableBaseComponent<EventItemDetails, EventItemDetails>
  implements OnInit {
  @Input()
  entityId: string;

  @Input()
  entityName: string;

  displayedColumns = [
    'eventTime',
    'eventType',
    // 'userName',
    // 'activity',
    'entityName',
    'description',
  ];

  rowActions = [
    // { action: 'edit', name: _('COMMON.EDIT'), icon: 'edit' },
    // { action: 'delete', name: _('COMMON.DELETE'), icon: 'delete' },
  ];

  msgAddSaved = null;
  msgEditSaved = null;
  msgDeleteConfirm = null;
  msgDeleteSuccess = null;
  msgDeleteSelectedConfirm = null;
  msgDeleteSelectedSuccess = null;

  fromControl = new FormControl();
  toControl = new FormControl();

  get timezone() {
    return this._settingsService.timezone;
  }
  get locale() {
    return this.translate.currentLang;
  }

  constructor(injector: Injector, private _settingsService: SettingsService) {
    super(injector, injector.get(EventLogService));

    this.fromControl.valueChanges.subscribe((value) => this._setDateRange());
    this.toControl.valueChanges.subscribe((value) => this._setDateRange());
  }

  ngOnInit() {
    this.dataSource.requestParams = { entityId: this.entityId };
  }

  _setDateRange() {
    this.dataSource.requestParams.from = this.fromControl.value
      ? new Date(this.fromControl.value + this.timezone).toISOString()
      : null;

    this.dataSource.requestParams.to = this.toControl.value
      ? new Date(this.toControl.value + this.timezone).toISOString()
      : null;

    this.dataSource.reloadData();
  }

  getNew() {
    return null;
  }

  getEdit(id) {
    return null;
  }

  getEditDialog() {
    return null;
  }
}
