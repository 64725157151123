import {
  Directive,
  Input,
  TemplateRef,
  ViewContainerRef,
  OnInit,
} from '@angular/core';

import { TokenService } from '../services/token.service';

@Directive({
  selector: '[hasPermissions]',
})
export class HasPermissionsDirective implements OnInit {
  private _hidden = true;

  private _logic: 'or' | 'and' = 'or';
  private _permissions: string[] = [];

  @Input()
  set hasPermissions(value) {
    this._permissions = value;
    this._updateView();
  }

  @Input()
  set hasPermissionsLogic(value: 'or' | 'and') {
    this._logic = value;
    this._updateView();
  }

  constructor(
    private _tokenService: TokenService,
    private _templateRef: TemplateRef<any>,
    private _viewContainer: ViewContainerRef
  ) {}

  ngOnInit() {
    this._updateView();
  }

  private _updateView() {
    if (this._tokenService.hasPermissions(this._permissions, this._logic)) {
      if (this._hidden) {
        this._viewContainer.createEmbeddedView(this._templateRef);
        this._hidden = false;
      }
    } else {
      this._hidden = true;
      this._viewContainer.clear();
    }
  }
}

// Usage
// <button *hasPermissions="['user.create', 'user.read']"></button> - test permissions 'or'
// <button *hasPermissions="['user.create', 'user.read']" hasPermissionsLogic="and"></button> - test permissions 'and'
