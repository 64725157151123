import { Injectable, OnDestroy } from '@angular/core';
import { EMPTY, of, Subject, Subscription } from 'rxjs';
import { catchError, delay, mergeMap, repeat, tap } from 'rxjs/operators';

import {
  IGisObjectGroupEventService,
  IGisObjectStatus,
} from 'projects/msu-its-web-common/src/utils/gis-object-group';

import {
  GetPublicTransportVehicleStatusesRequest,
  PublicTransportVehicleType,
} from '../../dtos/ptm.dtos';
import { PtmApiEventService } from '../ptm-api-event.service';
import { PtmApiService } from '../ptm-api.service';

@Injectable()
export class VehicleGroupEventService implements IGisObjectGroupEventService, OnDestroy {
  _subscription: Subscription;
  permissions = ['publicTransportVehicle.read'];

  constructor(private _apiService: PtmApiService, private _eventService: PtmApiEventService) {}

  ngOnDestroy() {
    this.unsubscribe();
  }

  subscribe(customerId: string, groupIds: string[], updateSubject: Subject<IGisObjectStatus[]>) {
    this.unsubscribe();

    const request = new GetPublicTransportVehicleStatusesRequest({
      customerId,
      entityGroups: groupIds,
      items: 0,
      page: 0,
    });

    this._subscription = of(null)
      .pipe(
        mergeMap(() => this._apiService.getUnsafe(request).pipe(catchError(() => EMPTY))),
        tap((result) =>
          updateSubject.next(
            result.items.map((item) => ({
              id: item.id,
              iconStatus: {
                borderColor: 'white',
                // borderColor: getColor((item as any).iconStatus?.borderColor ?? 'white'),
                color:
                  item.vehicleType === PublicTransportVehicleType.Bus
                    ? 'steelblue'
                    : item.vehicleType === PublicTransportVehicleType.Trolleybus
                    ? 'green'
                    : item.vehicleType === PublicTransportVehicleType.Train
                    ? 'orange'
                    : 'gray',
              },
              data: item,
            }))
          )
        ),
        delay(5000),
        repeat()
      )
      .subscribe();
  }

  unsubscribe() {
    if (this._subscription) {
      this._subscription.unsubscribe();
      this._subscription = null;
    }
  }
}
