<table-layout-dialog
  [dataSource]="dataSource"
  [displayedColumns]="displayedColumns"
  [rowActions]="rowActions"
  titleText="{{ 'AUDIT_LOG.TITLE' | translate }}{{
    entityName ? ': ' + entityName : ''
  }}"
  [showCreate]="false"
  [showDelete]="false"
  [showRefresh]="false"
>
  <ng-container table-layout-actions>
    <!-- <span>{{ "COMMON.DATE_RANGE" | translate }}</span> -->
    <mat-form-field style="margin-right: 8px; width: 160px">
      <input
        matInput
        type="datetime-local"
        [formControl]="fromControl"
        class="spin-button"
      />
      <mat-icon class="date-button">today</mat-icon>
    </mat-form-field>

    <span style="margin-right: 8px">-</span>

    <mat-form-field style="margin-right: 12px; width: 160px">
      <input
        matInput
        type="datetime-local"
        [formControl]="toControl"
        class="spin-button"
      />
      <mat-icon class="date-button">today</mat-icon>
    </mat-form-field>
  </ng-container>

  <ng-container table-layout-actions-end>
    <div fxLayout fxLayoutGap="8px">
      <div>
        <button
          mat-raised-button
          color="accent"
          (click)="dataSource?.reloadData()"
        >
          <mat-icon>loop</mat-icon>
          {{ "COMMON.REFRESH" | translate }}
        </button>
      </div>
      <div>
        <button mat-raised-button color="primary" (click)="saveCsv()">
          <mat-icon>file_download</mat-icon>
          CSV
        </button>
      </div>
    </div>
  </ng-container>

  <ng-container ngPackageAs="table-layout-columns" matSort>
    <ng-container matColumnDef="activityTime">
      <mat-header-cell *matHeaderCellDef mat-sort-header>
        {{ "COMMON.TIME" | translate }}
      </mat-header-cell>
      <mat-cell *matCellDef="let item">
        <b>
          {{ item.activityTime | date: "dd.MM.yyyy HH:mm":timezone:locale }}
        </b>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="userName">
      <mat-header-cell *matHeaderCellDef>
        {{ "COMMON.USERNAME" | translate }}
      </mat-header-cell>
      <mat-cell *matCellDef="let item">
        <b>{{ item.userName }}</b>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="entityName">
      <mat-header-cell *matHeaderCellDef>
        {{ "AUDIT_LOG.ENTITY_NAME" | translate }}
      </mat-header-cell>
      <mat-cell *matCellDef="let item">
        <b>{{ item.entityName }}</b>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="activity">
      <mat-header-cell *matHeaderCellDef>
        {{ "AUDIT_LOG.ACTIVITY" | translate }}
      </mat-header-cell>
      <mat-cell *matCellDef="let item">
        <b>{{ item.activity }}</b>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="description">
      <mat-header-cell *matHeaderCellDef>
        {{ "COMMON.DESCRIPTION" | translate }}
      </mat-header-cell>
      <mat-cell *matCellDef="let item">
        <b>{{ item.description }}</b>
      </mat-cell>
    </ng-container>
  </ng-container>
</table-layout-dialog>

<!--
<ng-container matColumnDef="entityType">
  <mat-header-cell *matHeaderCellDef mat-sort-header>
    {{ "AUDIT_LOG.ENTITY_TYPE" | translate }}
  </mat-header-cell>
  <mat-cell *matCellDef="let item">
    <b>{{ item.entityType }}</b>
  </mat-cell>
</ng-container>

<ng-container matColumnDef="entitySubType">
  <mat-header-cell *matHeaderCellDef mat-sort-header>
    {{ "AUDIT_LOG.ENTITY_SUBTYPE" | translate }}
  </mat-header-cell>
  <mat-cell *matCellDef="let item">
    <b>{{ item.entitySubType }}</b>
  </mat-cell>
</ng-container>

<ng-container matColumnDef="moduleId">
  <mat-header-cell *matHeaderCellDef mat-sort-header>
    {{ "AUDIT_LOG.MODULE" | translate }}
  </mat-header-cell>
  <mat-cell *matCellDef="let item">
    <b>{{ item.moduleId }}</b>
  </mat-cell>
</ng-container>
-->
