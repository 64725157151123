import {
  Component,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Injector,
} from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { DataSourceForm } from '../../data/data-source-form';
import { ExternalSystem } from '../../dtos/hub.dtos';

const urlPattern = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/;

@Component({
  selector: 'external-system-edit',
  templateUrl: './external-system-edit.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ExternalSystemEditComponent extends DataSourceForm<ExternalSystem> {
  constructor(injector: Injector, changeDetector: ChangeDetectorRef) {
    super(injector, changeDetector);
  }

  initFormGroup(fb: FormBuilder): FormGroup {
    return fb.group({
      name: ['', [Validators.required, Validators.maxLength(100)]],
      url: ['', [Validators.required, Validators.pattern(urlPattern)]],
      userName: [''],
      password: [''],
    });
  }

  prepareModelToSave(): ExternalSystem {
    const model = Object.assign(
      new ExternalSystem(),
      this.model,
      this.formGroup.value
    );
    return model;
  }

  get name() {
    return this.formGroup.controls['name'];
  }

  get url() {
    return this.formGroup.controls['url'];
  }

  get userName() {
    return this.formGroup.controls['userName'];
  }

  get password() {
    return this.formGroup.controls['password'];
  }
}
