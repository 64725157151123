import { Injectable } from '@angular/core';
import {
  IDataSourceService,
  IPageParams,
  IPageReturn,
  setPageParams,
  getPageReturn,
} from 'projects/msu-its-web-common/src/data/data-source';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  Camera,
  CameraLocation,
  CreateCameraRequest,
  DeleteCamerasRequest,
  GetCameraCurrentUserPermissionsRequest,
  GetCameraRequest,
  GetCamerasLocationRequest,
  UpdateCameraRequest,
} from '../dtos/tfms.dtos';
import { TfmsApiService } from './tfms-api.service';

@Injectable()
export class CameraService implements IDataSourceService<CameraLocation, CameraLocation> {
  constructor(private _api: TfmsApiService) {}

  getPagedList(params: IPageParams, customerId: string): Observable<IPageReturn<CameraLocation>> {
    const request = new GetCamerasLocationRequest();
    request.customerId = customerId;
    setPageParams(request, params);
    return this._api.get(request).pipe(
      map((m) => {
        return getPageReturn(m);
      })
    );
  }

  getList(customerId: string, filter?: string, groupIds?: string[]): Observable<CameraLocation[]> {
    const request = new GetCamerasLocationRequest();
    request.customerId = customerId;

    request.entityGroups = groupIds;
    request.page = 0;
    request.items = 0;
    request.sort = 'name';
    request.filter = filter;

    return this._api.get(request).pipe(
      map((m) => {
        return m.items;
      })
    );
  }

  // getList(customerId: string, filter?: string, groupIds?: string[]): Observable<Camera[]> {
  //   const request = new GetCamerasPageRequest();
  //   request.customerId = customerId;
  //   request.entityGroups = groupIds;
  //   request.page = 0;
  //   request.items = 0;
  //   request.sort = 'name';
  //   request.filter = filter;

  //   return this._api.get(request).pipe(
  //     map((m) => {
  //       return m.items;
  //     })
  //   );
  // }

  get(id: any, customerId: string): Observable<Camera> {
    const request = new GetCameraRequest();
    request.customerId = customerId;
    request.id = id;
    return this._api.get(request);
  }

  add(
    model: Camera,
    params: {
      customerId: string;
      groups: string[];
      updateGroups: boolean;
    }
  ) {
    const request = new CreateCameraRequest();
    request.customerId = params.customerId;
    request.entity = model;
    request.entityGroups = params.groups;
    request.updateEntityGroups = params.updateGroups;
    return this._api.post(request);
  }

  update(
    model: Camera,
    params: {
      customerId: string;
      groups: string[];
      updateGroups: boolean;
    }
  ) {
    const request = new UpdateCameraRequest();
    request.customerId = params.customerId;
    request.id = model.id;
    request.entity = model;
    request.entityGroups = params.groups;
    request.updateEntityGroups = params.updateGroups;
    return this._api.put(request);
  }

  delete(ids: string[], customerId: string): Observable<number> {
    const request = new DeleteCamerasRequest();
    request.customerId = customerId;
    request.ids = ids;
    return this._api.delete(request);
  }

  getPermissions(id: string, customerId: string) {
    const request = new GetCameraCurrentUserPermissionsRequest();
    request.id = id;
    request.customerId = customerId;
    return this._api.get(request);
  }
}
