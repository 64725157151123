<div class="mat-elevation-z2" style="display: flex; padding: 2px 0px 2px 6px">
  <div style="margin-right: 24px">
    <div class="mat-small mat-secondary-color">{{ "PARKINGS.CONTROLLER" | translate }}:</div>
    <div class="mat-body-1" style="line-height: normal">
      {{ model?.controller?.fullNumber }}
    </div>
  </div>

  <div style="flex: 1">
    <ng-container *ngIf="status">
      <div class="mat-small mat-secondary-color">{{ "COMMON.CONNECTION_TIME" | translate }}:</div>
      <div class="mat-body-1" style="line-height: normal; padding-bottom: 3px">
        {{ status?.connectionTime | date: "dd.MM.yyyy HH:mm:ss":timezone:locale }}
      </div>
    </ng-container>
  </div>

  <button mat-icon-button [matMenuTriggerFor]="controllerMenu">
    <mat-icon>more_vert</mat-icon>
  </button>
</div>

<mat-menu #controllerMenu="matMenu" overlapTrigger="false" class="mat-menu-md" xPosition="before">
  <!-- <button [disabled]="!(['device.read'] | hasPermissions)" mat-menu-item (click)="openControllerFiles()">
    {{ "COMMON.FILES" | translate }}
  </button>
  <button [disabled]="!(['device.read'] | hasPermissions)" mat-menu-item (click)="openControllerAudit()">
    {{ "COMMON.AUDIT" | translate }}
  </button>
  <button [disabled]="!(['device.read'] | hasPermissions)" mat-menu-item (click)="openControllerErrorLog()">
    {{ "COMMON.ERROR_LOG" | translate }}
  </button>
  
  <button [disabled]="!(['device.modify'] | hasPermissions)" mat-menu-item (click)="openControllerEdit()">
    {{ "COMMON.EDIT" | translate }}
  </button>
  <button [disabled]="!(['device.control'] | hasPermissions)" mat-menu-item (click)="disconnectController()">
    {{ "DEVICES.SESSION_DISCONNECT" | translate }}
  </button> -->
  <button mat-menu-item (click)="controllerEventLog()">
    {{ "EVENT_LOG.TITLE" | translate }}
  </button>
  <button mat-menu-item (click)="disconnectController()">
    {{ "DEVICES.SESSION_DISCONNECT" | translate }}
  </button>
</mat-menu>
