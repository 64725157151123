import { Injectable } from '@angular/core';

import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import {
  IDataSourceService,
  setPageParams,
  getPageReturn,
  IPageParams,
  IPageReturn,
} from 'projects/msu-its-web-common/src/data/data-source';

import {
  CreatePanelControllerRequest,
  DeletePanelControllersRequest,
  DisconnectPanelControllerRequest,
  GetPanelControllerExistRequest,
  GetPanelControllerRequest,
  GetPanelControllersRequest,
  PanelController,
  UpdatePanelControllerRequest,
  UploadPanelControllerSettingsRequest,
} from '../../dtos/ruis.dtos';

import { RuisApiService } from '../ruis-api.service';

@Injectable()
export class PanelControllerService
  implements IDataSourceService<PanelController, PanelController>
{
  constructor(private _api: RuisApiService) {}

  getPagedList(params: IPageParams, customerId: string): Observable<IPageReturn<PanelController>> {
    if (!customerId) {
      return of(null);
    }

    const request = new GetPanelControllersRequest();
    request.customerId = customerId;

    setPageParams(request, params);
    return this._api.get(request).pipe(
      map((m) => {
        return getPageReturn(m);
      })
    );
  }
  getList(
    customerId: string,
    filter?: string,
    groupIds?: string[],
    items?: number
  ): Observable<PanelController[]> {
    const request = new GetPanelControllersRequest();
    request.customerId = customerId;
    request.entityGroups = groupIds || [];

    request.page = 0;
    request.items = items || 0;
    request.sort = 'number';
    request.filter = filter || '';

    return this._api.get(request).pipe(
      map((m) => {
        return m.items;
      })
    );
  }

  get(id: string, customerId: string) {
    const request = new GetPanelControllerRequest();
    request.id = id;
    request.customerId = customerId;
    return this._api.get(request);
  }
  add(
    model: PanelController,
    params: {
      customerId: string;
      groups: string[];
      updateGroups: boolean;
    }
  ) {
    const request = new CreatePanelControllerRequest();
    request.entity = model;
    request.customerId = params.customerId;
    request.entityGroups = params.groups;
    request.updateEntityGroups = params.updateGroups;
    return this._api.post(request);
  }
  update(
    model: PanelController,
    params: { customerId: string; groups: string[]; updateGroups: boolean }
  ) {
    const request = new UpdatePanelControllerRequest();
    request.id = model.id;
    request.entity = model;
    request.customerId = params.customerId;
    request.entityGroups = params.groups;
    request.updateEntityGroups = params.updateGroups;
    return this._api.put(request);
  }
  delete(ids: string[], customerId: string) {
    const request = new DeletePanelControllersRequest();
    request.ids = ids;
    request.customerId = customerId;
    return this._api.delete(request);
  }
  exist(prefix: string, number: string, customerId: string): Observable<boolean> {
    const request = new GetPanelControllerExistRequest({
      prefix,
      number,
      customerId,
    } as any);
    return this._api.get(request);
  }

  uploadSettings(id: string, customerId: string) {
    const request = new UploadPanelControllerSettingsRequest({ id, customerId });
    return this._api.post(request);
  }

  disconnect(id: string, customerId: string) {
    const request = new DisconnectPanelControllerRequest({ id, customerId });
    return this._api.post(request);
  }
}
