import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

import {
  IGisObjectGroupEventService,
  IGisObjectStatus,
} from 'projects/msu-its-web-common/src/utils/gis-object-group';

import { TssApiEventService } from './tss-api-event.service';

@Injectable()
export class TrafficSceneGroupEventService implements IGisObjectGroupEventService {
  constructor(private _eventService: TssApiEventService) {}

  permissions = ['trafficScene.read'];

  subscribe(customerId: string, groupIds: string[], updateSubject: Subject<IGisObjectStatus[]>) {
    // todo
  }

  unsubscribe() {
    // todo
  }
}
