/* Options:
Date: 2023-11-19 05:59:09
Version: 6.70
Tip: To override a DTO option, remove "//" prefix before updating
BaseUrl: https://tss.dev.its.msu24.ru

//GlobalNamespace: 
//MakePropertiesOptional: False
//AddServiceStackTypes: True
//AddResponseStatus: False
//AddImplicitVersion: 
//AddDescriptionAsComments: True
//IncludeTypes: 
//ExcludeTypes: 
//DefaultImports: 
*/

export interface IReturn<T> {
  createResponse(): T;
}

export interface IReturnVoid {
  createResponse(): void;
}

export interface IHasSessionId {
  sessionId: string;
}

export interface IHasBearerToken {
  bearerToken: string;
}

export interface IPost {}

// @DataContract
export class GetEntityGroupsRequestBase implements ICustomerItemRequest {
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public entityGroups: string[];

  // @DataMember
  public itemsIds: string[];

  // @DataMember
  public onlyOwnedItems: boolean;

  // @DataMember
  public page: number;

  // @DataMember
  public items: number;

  // @DataMember
  public sort: string;

  // @DataMember
  public filter: string;

  // @DataMember
  public propertiesTest: { [index: string]: number };

  public constructor(init?: Partial<GetEntityGroupsRequestBase>) {
    (Object as any).assign(this, init);
  }
}

export interface ICustomerItemRequest extends ICustomerEntity {}

export interface ICustomerEntity {
  // @DataMember
  customerId: string;
}

// @DataContract
export class GetEntityGroupRequestBase implements ICustomerItemRequest, IBaseEntityItemRequest {
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember(IsRequired=true)
  public id: string;

  public constructor(init?: Partial<GetEntityGroupRequestBase>) {
    (Object as any).assign(this, init);
  }
}

export interface IBaseEntityItemRequest {
  id: string;
}

// @DataContract
export class EntityGroup
  implements ICustomerEntityViewModel, INamedEntity, IChangeKey, IDescription, IModified, IEntity
{
  // @DataMember
  public items: string[];

  // @DataMember
  public customerId: string;

  // @DataMember
  public customerName: string;

  // @DataMember
  public name: string;

  // @DataMember
  public description: string;

  // @DataMember
  public changeKey: string;

  // @DataMember
  public created: string;

  // @DataMember
  public createdBy: string;

  // @DataMember
  public modified: string;

  // @DataMember
  public modifiedBy: string;

  // @DataMember
  public id: string;

  // @DataMember
  public entityType: string;

  public constructor(init?: Partial<EntityGroup>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class CreateEntityGroupRequestBase implements ICustomerItemRequest, IDescription {
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public updateEntityGroups: boolean;

  // @DataMember
  public entityGroups: string[];

  // @DataMember(IsRequired=true)
  public entity: EntityGroup;

  // @DataMember
  public description: string;

  public constructor(init?: Partial<CreateEntityGroupRequestBase>) {
    (Object as any).assign(this, init);
  }
}

export interface IDescription {
  // @DataMember
  description: string;
}

export interface IRequestWithEntity {}

// @DataContract
export class UpdateEntityGroupRequestBase
  implements ICustomerItemRequest, IBaseEntityItemRequest, IDescription
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public updateEntityGroups: boolean;

  // @DataMember
  public entityGroups: string[];

  // @DataMember(IsRequired=true)
  public id: string;

  // @DataMember(IsRequired=true)
  public entity: EntityGroup;

  // @DataMember
  public description: string;

  public constructor(init?: Partial<UpdateEntityGroupRequestBase>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class DeleteEntityGroupsRequestBase implements ICustomerItemRequest, IDescription {
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public description: string;

  // @DataMember(IsRequired=true)
  public ids: string[];

  public constructor(init?: Partial<DeleteEntityGroupsRequestBase>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class PageRequest {
  // @DataMember
  public page: number;

  // @DataMember
  public items: number;

  // @DataMember
  public sort: string;

  // @DataMember
  public filter: string;

  // @DataMember
  public propertiesTest: { [index: string]: number };

  public constructor(init?: Partial<PageRequest>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class AdaptiveSceneInfo
  implements ICustomerEntityViewModel, INamedEntity, IChangeKey, IDescription, IModified, IEntity
{
  // @DataMember
  public customerId: string;

  // @DataMember
  public customerName: string;

  // @DataMember
  public name: string;

  // @DataMember
  public description: string;

  // @DataMember
  public changeKey: string;

  // @DataMember
  public created: string;

  // @DataMember
  public createdBy: string;

  // @DataMember
  public modified: string;

  // @DataMember
  public modifiedBy: string;

  // @DataMember
  public id: string;

  // @DataMember
  public entityType: string;

  public constructor(init?: Partial<AdaptiveSceneInfo>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class AdaptiveSceneIconStatus {
  // @DataMember
  public color: string;

  // @DataMember
  public borderColor: string;

  public constructor(init?: Partial<AdaptiveSceneIconStatus>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class AdaptiveSceneMapStatus
  implements ICustomerEntityViewModel, INamedEntity, IChangeKey, IDescription, IModified, IEntity
{
  // @DataMember
  public iconStatus: AdaptiveSceneIconStatus;

  // @DataMember
  public adaptiveSceneGeoJson: string;

  // @DataMember
  public customerId: string;

  // @DataMember
  public customerName: string;

  // @DataMember
  public name: string;

  // @DataMember
  public description: string;

  // @DataMember
  public changeKey: string;

  // @DataMember
  public created: string;

  // @DataMember
  public createdBy: string;

  // @DataMember
  public modified: string;

  // @DataMember
  public modifiedBy: string;

  // @DataMember
  public id: string;

  // @DataMember
  public entityType: string;

  public constructor(init?: Partial<AdaptiveSceneMapStatus>) {
    (Object as any).assign(this, init);
  }
}

export class AdaptiveModeRequestBase implements IBaseEntityItemRequest, ICustomerItemRequest {
  // @DataMember
  public id: string;

  // @DataMember
  public customerId: string;

  public constructor(init?: Partial<AdaptiveModeRequestBase>) {
    (Object as any).assign(this, init);
  }
}

export class DeleteCustomerItemsRequest implements ICustomerItemRequest, IDescription {
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public description: string;

  // @DataMember(IsRequired=true)
  public ids: string[];

  public constructor(init?: Partial<DeleteCustomerItemsRequest>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class DeleteItemsRequest implements IDescription {
  // @DataMember
  public description: string;

  // @DataMember(IsRequired=true)
  public ids: string[];

  public constructor(init?: Partial<DeleteItemsRequest>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export enum ReportFormat {
  Json = 'Json',
  Csv = 'Csv',
  Xlsx = 'Xlsx',
}

// @DataContract
export class TrafficSceneInfo
  implements ICustomerEntityViewModel, INamedEntity, IChangeKey, IDescription, IModified, IEntity
{
  // @DataMember
  public customerId: string;

  // @DataMember
  public customerName: string;

  // @DataMember
  public name: string;

  // @DataMember
  public description: string;

  // @DataMember
  public changeKey: string;

  // @DataMember
  public created: string;

  // @DataMember
  public createdBy: string;

  // @DataMember
  public modified: string;

  // @DataMember
  public modifiedBy: string;

  // @DataMember
  public id: string;

  // @DataMember
  public entityType: string;

  public constructor(init?: Partial<TrafficSceneInfo>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class TrafficSceneIconStatus {
  // @DataMember
  public color: string;

  // @DataMember
  public borderColor: string;

  public constructor(init?: Partial<TrafficSceneIconStatus>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class TrafficSceneMapStatus
  implements ICustomerEntityViewModel, INamedEntity, IChangeKey, IDescription, IModified, IEntity
{
  // @DataMember
  public iconStatus: TrafficSceneIconStatus;

  // @DataMember
  public trafficSceneGeoJson: string;

  // @DataMember
  public customerId: string;

  // @DataMember
  public customerName: string;

  // @DataMember
  public name: string;

  // @DataMember
  public description: string;

  // @DataMember
  public changeKey: string;

  // @DataMember
  public created: string;

  // @DataMember
  public createdBy: string;

  // @DataMember
  public modified: string;

  // @DataMember
  public modifiedBy: string;

  // @DataMember
  public id: string;

  // @DataMember
  public entityType: string;

  public constructor(init?: Partial<TrafficSceneMapStatus>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export enum TrafficObjectSwitchMode {
  Sequential = 'Sequential',
  SequentialDelay = 'SequentialDelay',
  Parallel = 'Parallel',
}

export class StartTrafficSceneControlModeRequestBase
  implements IBaseEntityItemRequest, ICustomerItemRequest
{
  // @DataMember
  public id: string;

  // @DataMember
  public customerId: string;

  // @DataMember
  public modeTime: number;

  // @DataMember
  public forwardDirection: boolean;

  // @DataMember
  public switchMode: TrafficObjectSwitchMode;

  public constructor(init?: Partial<StartTrafficSceneControlModeRequestBase>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export enum TrafficSceneControlMode {
  None = 'None',
  GreenStreet = 'GreenStreet',
  RedStreet = 'RedStreet',
  Adaptive = 'Adaptive',
}

// @DataContract
export enum TrafficLightType {
  Static = 'Static',
  Actuated = 'Actuated',
  DelayBased = 'DelayBased',
}

export interface ICustomerIntegrationIdRequest {
  customerIntegrationId: string;
}

// @DataContract
export class EntityGroupInfo
  implements ICustomerEntityViewModel, INamedEntity, IChangeKey, IDescription, IModified, IEntity
{
  // @DataMember
  public itemsCount: number;

  // @DataMember
  public customerId: string;

  // @DataMember
  public customerName: string;

  // @DataMember
  public name: string;

  // @DataMember
  public description: string;

  // @DataMember
  public changeKey: string;

  // @DataMember
  public created: string;

  // @DataMember
  public createdBy: string;

  // @DataMember
  public modified: string;

  // @DataMember
  public modifiedBy: string;

  // @DataMember
  public id: string;

  // @DataMember
  public entityType: string;

  public constructor(init?: Partial<EntityGroupInfo>) {
    (Object as any).assign(this, init);
  }
}

export interface IChangeKey extends IReadonlyChangeKey {
  // @DataMember
  changeKey: string;
}

export interface IReadonlyChangeKey {
  // @DataMember
  changeKey: string;
}

export class CustomerNamedBaseEntityViewModelBase
  implements ICustomerEntityViewModel, INamedEntity, IChangeKey, IDescription, IModified, IEntity
{
  // @DataMember
  public customerId: string;

  // @DataMember
  public customerName: string;

  // @DataMember
  public name: string;

  // @DataMember
  public description: string;

  // @DataMember
  public changeKey: string;

  // @DataMember
  public created: string;

  // @DataMember
  public createdBy: string;

  // @DataMember
  public modified: string;

  // @DataMember
  public modifiedBy: string;

  // @DataMember
  public id: string;

  // @DataMember
  public entityType: string;

  public constructor(init?: Partial<CustomerNamedBaseEntityViewModelBase>) {
    (Object as any).assign(this, init);
  }
}

export interface IEntity {}

export interface IEntityTyped {
  // @DataMember
  entityType: string;
}

export interface IModified {
  // @DataMember
  modified: string;

  // @DataMember
  modifiedBy: string;
}

export interface INamedEntity extends IEntity, INamed {}

export interface INamed {
  // @DataMember
  name: string;
}

export interface ICustomerEntityViewModel extends ICustomerName, ICustomerEntity {}

export interface ICustomerName {
  // @DataMember
  customerName: string;
}

// @DataContract
export class BaseTrafficObjectDetector {
  // @DataMember
  public id: string;

  public constructor(init?: Partial<BaseTrafficObjectDetector>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class BaseTrafficObjectPlan {
  // @DataMember
  public index: number;

  // @DataMember
  public name: string;

  // @DataMember
  public value: number;

  public constructor(init?: Partial<BaseTrafficObjectPlan>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class AdaptiveSceneBaseTrafficObjectSettings {
  // @DataMember
  public trafficObjectId: string;

  // @DataMember
  public detectors: BaseTrafficObjectDetector[];

  // @DataMember
  public plans: BaseTrafficObjectPlan[];

  public constructor(init?: Partial<AdaptiveSceneBaseTrafficObjectSettings>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class AdaptiveSceneSettings implements IEntity {
  // @DataMember
  public trafficObjects: AdaptiveSceneObject[];

  // @DataMember
  public baseTrafficObject: AdaptiveSceneBaseTrafficObjectSettings;

  // @DataMember
  public adaptiveSceneGeoJson: string;

  // @DataMember
  public id: string;

  // @DataMember
  public entityType: string;

  public constructor(init?: Partial<AdaptiveSceneSettings>) {
    (Object as any).assign(this, init);
  }
}

export interface IBaseEntity extends IEntity, IModified, IDescription, IChangeKey {
  deleted: boolean;
}

// @DataContract
export enum TrafficPlanType {
  PhaseCycle = 'PhaseCycle',
  YellowBlink = 'YellowBlink',
  Off = 'Off',
}

// @DataContract
export class TrafficPlan implements IEnabled {
  // @DataMember
  public barriers: TrafficPhaseBarrier[];

  // @DataMember
  public id: number;

  // @DataMember
  public name: string;

  // @DataMember
  public type: TrafficPlanType;

  // @DataMember
  public offset: number;

  // @DataMember
  public length: number;

  // @DataMember
  public enabled: boolean;

  public constructor(init?: Partial<TrafficPlan>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export enum TrafficObjectMode {
  Off = 'Off',
  Coordination = 'Coordination',
  Adaptive = 'Adaptive',
  Remote = 'Remote',
  Local = 'Local',
}

// @DataContract
export enum TrafficObjectState {
  Off = 'Off',
  YellowBlink = 'YellowBlink',
  AllRed = 'AllRed',
  Phase = 'Phase',
}

// @DataContract
export class TrafficObjectIconStatus {
  // @DataMember
  public color: string;

  // @DataMember
  public borderColor: string;

  // @DataMember
  public processing: boolean;

  public constructor(init?: Partial<TrafficObjectIconStatus>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export enum TrafficMovementLight {
  Off = 'Off',
  Red = 'Red',
  Yellow = 'Yellow',
  Green = 'Green',
  RedYellow = 'RedYellow',
  YellowBlink = 'YellowBlink',
  GreenBlink = 'GreenBlink',
}

// @DataContract
export class TrafficSchemaLight {
  // @DataMember(Order=0)
  public move1: TrafficMovementLight;

  // @DataMember(Order=1)
  public move2: TrafficMovementLight;

  // @DataMember(Order=2)
  public move3: TrafficMovementLight;

  // @DataMember(Order=3)
  public move4: TrafficMovementLight;

  // @DataMember(Order=4)
  public move5: TrafficMovementLight;

  // @DataMember(Order=5)
  public move6: TrafficMovementLight;

  // @DataMember(Order=6)
  public move7: TrafficMovementLight;

  // @DataMember(Order=7)
  public move8: TrafficMovementLight;

  // @DataMember(Order=8)
  public move12: TrafficMovementLight;

  // @DataMember(Order=9)
  public move14: TrafficMovementLight;

  // @DataMember(Order=10)
  public move16: TrafficMovementLight;

  // @DataMember(Order=11)
  public move18: TrafficMovementLight;

  // @DataMember(Order=12)
  public moveP2: TrafficMovementLight;

  // @DataMember(Order=13)
  public moveP4: TrafficMovementLight;

  // @DataMember(Order=14)
  public moveP6: TrafficMovementLight;

  // @DataMember(Order=15)
  public moveP8: TrafficMovementLight;

  public constructor(init?: Partial<TrafficSchemaLight>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class PedestrianAdaptiveStatus implements IEnabled {
  // @DataMember
  public enabled: boolean;

  // @DataMember
  public active: boolean;

  public constructor(init?: Partial<PedestrianAdaptiveStatus>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class PublicTransportAdaptiveStatus implements IEnabled {
  // @DataMember
  public enabled: boolean;

  // @DataMember
  public active: boolean;

  public constructor(init?: Partial<PublicTransportAdaptiveStatus>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class SpecialTransportAdaptiveStatus implements IEnabled {
  // @DataMember
  public enabled: boolean;

  // @DataMember
  public active: boolean;

  public constructor(init?: Partial<SpecialTransportAdaptiveStatus>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class TransportAdaptiveStatus implements IEnabled {
  // @DataMember
  public enabled: boolean;

  // @DataMember
  public active: boolean;

  public constructor(init?: Partial<TransportAdaptiveStatus>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class LocalAdaptiveStatus implements IEnabled {
  // @DataMember
  public enabled: boolean;

  // @DataMember
  public active: boolean;

  public constructor(init?: Partial<LocalAdaptiveStatus>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class TrafficObjectAdaptiveStatus {
  // @DataMember
  public pedestrian: PedestrianAdaptiveStatus;

  // @DataMember
  public publicTransport: PublicTransportAdaptiveStatus;

  // @DataMember
  public specialTransport: SpecialTransportAdaptiveStatus;

  // @DataMember
  public transport: TransportAdaptiveStatus;

  // @DataMember
  public local: LocalAdaptiveStatus;

  public constructor(init?: Partial<TrafficObjectAdaptiveStatus>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class TrafficObjectAdaptiveSceneStatus implements IEntity {
  // @DataMember
  public controlModeStartTime?: string;

  // @DataMember
  public adaptivePlan: TrafficPlan;

  // @DataMember
  public serverTime: string;

  // @DataMember
  public stateTime: string;

  // @DataMember
  public mode: TrafficObjectMode;

  // @DataMember
  public state: TrafficObjectState;

  // @DataMember
  public iconStatus: TrafficObjectIconStatus;

  // @DataMember
  public online: boolean;

  // @DataMember
  public controllerLastConnectionTime: string;

  // @DataMember
  public controllerLastPacketTime: string;

  // @DataMember
  public controllerTime: string;

  // @DataMember
  public controllerStatusDescription: string;

  // @DataMember
  public controllerErrorDescription: string;

  // @DataMember
  public schemaLights: TrafficSchemaLight;

  // @DataMember
  public commandProcessing: boolean;

  // @DataMember
  public coordinationPlan: number;

  // @DataMember
  public planTime: number;

  // @DataMember
  public adaptiveStatus: TrafficObjectAdaptiveStatus;

  // @DataMember
  public id: string;

  // @DataMember
  public entityType: string;

  public constructor(init?: Partial<TrafficObjectAdaptiveSceneStatus>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export enum TrafficMovementType {
  Move1 = 'Move1',
  Move2 = 'Move2',
  Move3 = 'Move3',
  Move4 = 'Move4',
  Move5 = 'Move5',
  Move6 = 'Move6',
  Move7 = 'Move7',
  Move8 = 'Move8',
  Move12 = 'Move12',
  Move14 = 'Move14',
  Move16 = 'Move16',
  Move18 = 'Move18',
  MoveP2 = 'MoveP2',
  MoveP4 = 'MoveP4',
  MoveP6 = 'MoveP6',
  MoveP8 = 'MoveP8',
}

// @DataContract
export class TrafficObjectDetector {
  // @DataMember
  public movementType: TrafficMovementType;

  // @DataMember
  public detectorId: string;

  // @DataMember
  public detectorName: string;

  // @DataMember
  public detectorNumber: string;

  // @DataMember
  public trafficLaneId: number;

  // @DataMember
  public detectorLaneId: number;

  // @DataMember
  public weight: number;

  public constructor(init?: Partial<TrafficObjectDetector>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class DetectorSummaryGraphItem {
  // @DataMember(Order=0)
  public time: number;

  // @DataMember(Order=1)
  public count: number;

  // @DataMember(Order=2)
  public histCount: number;

  // @DataMember(Order=3)
  public avgSpeed: number;

  // @DataMember(Order=4)
  public histAvgSpeed: number;

  // @DataMember(Order=5)
  public laneFill: number;

  // @DataMember(Order=6)
  public histLaneFill: number;

  // @DataMember(Order=7)
  public speedDiff: number;

  // @DataMember(Order=8)
  public countDiff: number;

  // @DataMember(Order=9)
  public laneFillDiff: number;

  // @DataMember(Order=10)
  public speedDiffPercent: number;

  // @DataMember(Order=11)
  public countDiffPercent: number;

  // @DataMember(Order=12)
  public laneFillDiffPercent: number;

  public constructor(init?: Partial<DetectorSummaryGraphItem>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class TrafficObjectDetectorInfo {
  // @DataMember
  public id: string;

  // @DataMember
  public name: string;

  // @DataMember
  public movement: TrafficMovementType;

  // @DataMember
  public avgSpeedDiffPercent: number;

  // @DataMember
  public avgCountDiffPercent: number;

  // @DataMember
  public avgLaneFillDiffPercent: number;

  // @DataMember
  public speedDiff: number;

  // @DataMember
  public countDiff: number;

  // @DataMember
  public laneFillDiff: number;

  // @DataMember
  public graphItems: DetectorSummaryGraphItem[];

  public constructor(init?: Partial<TrafficObjectDetectorInfo>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class NamedEntityViewModelBase implements INamedEntity, IEntity {
  // @DataMember
  public name: string;

  // @DataMember
  public id: string;

  // @DataMember
  public entityType: string;

  public constructor(init?: Partial<NamedEntityViewModelBase>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class StepMovements {
  // @DataMember
  public movements: TrafficMovementType[];

  // @DataMember
  public phase: number;

  public constructor(init?: Partial<StepMovements>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class GreenStreetStep {
  // @DataMember
  public trafficObjectId: string;

  // @DataMember
  public forwardMovements: StepMovements;

  // @DataMember
  public forwardRedMovements: StepMovements;

  // @DataMember
  public backwardMovements: StepMovements;

  // @DataMember
  public backwardRedMovements: StepMovements;

  public constructor(init?: Partial<GreenStreetStep>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class TrafficSceneGreenStreetSettings {
  // @DataMember
  public steps: GreenStreetStep[];

  public constructor(init?: Partial<TrafficSceneGreenStreetSettings>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class TrafficSceneSettings implements IEntity {
  // @DataMember
  public trafficObjects: TrafficSceneObject[];

  // @DataMember
  public greenStreet: TrafficSceneGreenStreetSettings;

  // @DataMember
  public twoWay: boolean;

  // @DataMember
  public trafficSceneGeoJson: string;

  // @DataMember
  public id: string;

  // @DataMember
  public entityType: string;

  public constructor(init?: Partial<TrafficSceneSettings>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class TrafficObjectSceneStatus implements IEntity {
  // @DataMember
  public controlModeStartTime?: string;

  // @DataMember
  public adaptivePlan: TrafficPlan;

  // @DataMember
  public serverTime: string;

  // @DataMember
  public stateTime: string;

  // @DataMember
  public mode: TrafficObjectMode;

  // @DataMember
  public state: TrafficObjectState;

  // @DataMember
  public iconStatus: TrafficObjectIconStatus;

  // @DataMember
  public online: boolean;

  // @DataMember
  public controllerLastConnectionTime: string;

  // @DataMember
  public controllerLastPacketTime: string;

  // @DataMember
  public controllerTime: string;

  // @DataMember
  public controllerStatusDescription: string;

  // @DataMember
  public controllerErrorDescription: string;

  // @DataMember
  public schemaLights: TrafficSchemaLight;

  // @DataMember
  public commandProcessing: boolean;

  // @DataMember
  public coordinationPlan: number;

  // @DataMember
  public planTime: number;

  // @DataMember
  public adaptiveStatus: TrafficObjectAdaptiveStatus;

  // @DataMember
  public id: string;

  // @DataMember
  public entityType: string;

  public constructor(init?: Partial<TrafficObjectSceneStatus>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class TrafficObjectSceneAnalysis {
  // @DataMember
  public id: string;

  // @DataMember
  public plan: TrafficPlan;

  public constructor(init?: Partial<TrafficObjectSceneAnalysis>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class BaseEntityViewModelBase implements IChangeKey, IDescription, IModified, IEntity {
  // @DataMember
  public description: string;

  // @DataMember
  public changeKey: string;

  // @DataMember
  public created: string;

  // @DataMember
  public createdBy: string;

  // @DataMember
  public modified: string;

  // @DataMember
  public modifiedBy: string;

  // @DataMember
  public id: string;

  // @DataMember
  public entityType: string;

  public constructor(init?: Partial<BaseEntityViewModelBase>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class ResponseError {
  // @DataMember(Order=1)
  public errorCode: string;

  // @DataMember(Order=2)
  public fieldName: string;

  // @DataMember(Order=3)
  public message: string;

  // @DataMember(Order=4)
  public meta: { [index: string]: string };

  public constructor(init?: Partial<ResponseError>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class ResponseStatus {
  // @DataMember(Order=1)
  public errorCode: string;

  // @DataMember(Order=2)
  public message: string;

  // @DataMember(Order=3)
  public stackTrace: string;

  // @DataMember(Order=4)
  public errors: ResponseError[];

  // @DataMember(Order=5)
  public meta: { [index: string]: string };

  public constructor(init?: Partial<ResponseStatus>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class NamedBaseEntityViewModelBase
  implements INamedEntity, IChangeKey, IDescription, IModified, IEntity
{
  // @DataMember
  public name: string;

  // @DataMember
  public description: string;

  // @DataMember
  public changeKey: string;

  // @DataMember
  public created: string;

  // @DataMember
  public createdBy: string;

  // @DataMember
  public modified: string;

  // @DataMember
  public modifiedBy: string;

  // @DataMember
  public id: string;

  // @DataMember
  public entityType: string;

  public constructor(init?: Partial<NamedBaseEntityViewModelBase>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class AdaptiveSceneObject {
  // @DataMember
  public trafficObjectId: string;

  // @DataMember
  public distance: number;

  public constructor(init?: Partial<AdaptiveSceneObject>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class EntityViewModelBase implements IEntity {
  // @DataMember
  public id: string;

  // @DataMember
  public entityType: string;

  public constructor(init?: Partial<EntityViewModelBase>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class TrafficObjectStatus implements IEntity {
  // @DataMember
  public serverTime: string;

  // @DataMember
  public stateTime: string;

  // @DataMember
  public mode: TrafficObjectMode;

  // @DataMember
  public state: TrafficObjectState;

  // @DataMember
  public iconStatus: TrafficObjectIconStatus;

  // @DataMember
  public online: boolean;

  // @DataMember
  public controllerLastConnectionTime: string;

  // @DataMember
  public controllerLastPacketTime: string;

  // @DataMember
  public controllerTime: string;

  // @DataMember
  public controllerStatusDescription: string;

  // @DataMember
  public controllerErrorDescription: string;

  // @DataMember
  public schemaLights: TrafficSchemaLight;

  // @DataMember
  public commandProcessing: boolean;

  // @DataMember
  public coordinationPlan: number;

  // @DataMember
  public planTime: number;

  // @DataMember
  public adaptiveStatus: TrafficObjectAdaptiveStatus;

  // @DataMember
  public id: string;

  // @DataMember
  public entityType: string;

  public constructor(init?: Partial<TrafficObjectStatus>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class TrafficSceneObject {
  // @DataMember
  public trafficObjectId: string;

  // @DataMember
  public distance: number;

  public constructor(init?: Partial<TrafficSceneObject>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export enum TrafficBarrierType {
  MajorStreet = 'MajorStreet',
  MinorStreet = 'MinorStreet',
  Static = 'Static',
}

// @DataContract
export class TrafficRingStraightMovement {
  // @DataMember(Order=0)
  public enabled: boolean;

  // @DataMember(Order=1)
  public length: number;

  // @DataMember(Order=2)
  public straightMovement: boolean;

  // @DataMember(Order=3)
  public pedestrianMovement: boolean;

  // @DataMember(Order=4)
  public deadEndPedestrianMovement: boolean;

  // @DataMember(Order=5)
  public pedestrianMovementLength: number;

  // @DataMember(Order=6)
  public firstMovement: boolean;

  // @DataMember(Order=7)
  public rightMovement: boolean;

  // @DataMember(Order=8)
  public rightMovementPedestrianSeparated: boolean;

  public constructor(init?: Partial<TrafficRingStraightMovement>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class TrafficRingLeftMovement {
  // @DataMember(Order=0)
  public enabled: boolean;

  // @DataMember(Order=1)
  public length: number;

  // @DataMember(Order=2)
  public overlapped: boolean;

  public constructor(init?: Partial<TrafficRingLeftMovement>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class TrafficBarrierRing {
  // @DataMember(Order=1)
  public straightMovement: TrafficRingStraightMovement;

  // @DataMember(Order=2)
  public leftMovement: TrafficRingLeftMovement;

  public constructor(init?: Partial<TrafficBarrierRing>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class TrafficPhaseBarrier {
  // @DataMember(Order=0)
  public type: TrafficBarrierType;

  // @DataMember(Order=2)
  public ring1: TrafficBarrierRing;

  // @DataMember(Order=3)
  public ring2: TrafficBarrierRing;

  // @DataMember(Order=4)
  public staticMovements: TrafficMovementType[];

  // @DataMember
  public length: number;

  // @DataMember
  public phase: number;

  public constructor(init?: Partial<TrafficPhaseBarrier>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class TrafficPlanBase implements IEnabled {
  // @DataMember
  public id: number;

  // @DataMember
  public name: string;

  // @DataMember
  public type: TrafficPlanType;

  // @DataMember
  public offset: number;

  // @DataMember
  public length: number;

  // @DataMember
  public enabled: boolean;

  public constructor(init?: Partial<TrafficPlanBase>) {
    (Object as any).assign(this, init);
  }
}

export interface IEnabled {
  enabled: boolean;
}

// @DataContract
export class PageRequestBase {
  // @DataMember
  public page: number;

  // @DataMember
  public items: number;

  // @DataMember
  public sort: string;

  // @DataMember
  public filter: string;

  // @DataMember
  public propertiesTest: { [index: string]: number };

  public constructor(init?: Partial<PageRequestBase>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class PlanBarrier {
  // @DataMember
  public length: number;

  // @DataMember
  public phase: number;

  public constructor(init?: Partial<PlanBarrier>) {
    (Object as any).assign(this, init);
  }
}

export class AdaptiveStatusBase implements IEnabled {
  // @DataMember
  public enabled: boolean;

  // @DataMember
  public active: boolean;

  public constructor(init?: Partial<AdaptiveStatusBase>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class GetEntityGroupsResponse implements IChangeKey {
  // @DataMember
  public items: EntityGroupInfo[];

  // @DataMember
  public total: number;

  // @DataMember
  public page: number;

  // @DataMember
  public itemsPerPage: number;

  // @DataMember
  public changeKey: string;

  public constructor(init?: Partial<GetEntityGroupsResponse>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class GetAdaptiveScenesResponse implements IChangeKey {
  // @DataMember
  public items: AdaptiveSceneInfo[];

  // @DataMember
  public total: number;

  // @DataMember
  public page: number;

  // @DataMember
  public itemsPerPage: number;

  // @DataMember
  public changeKey: string;

  public constructor(init?: Partial<GetAdaptiveScenesResponse>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class AdaptiveScene
  implements
    IBaseEntity,
    ICustomerEntityViewModel,
    INamedEntity,
    IChangeKey,
    IDescription,
    IModified,
    IEntity
{
  // @DataMember
  public settings: AdaptiveSceneSettings;

  // @DataMember
  public deleted: boolean;

  // @DataMember
  public customerId: string;

  // @DataMember
  public customerName: string;

  // @DataMember
  public name: string;

  // @DataMember
  public description: string;

  // @DataMember
  public changeKey: string;

  // @DataMember
  public created: string;

  // @DataMember
  public createdBy: string;

  // @DataMember
  public modified: string;

  // @DataMember
  public modifiedBy: string;

  // @DataMember
  public id: string;

  // @DataMember
  public entityType: string;

  public constructor(init?: Partial<AdaptiveScene>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class GetAdaptiveScenesDetailsResponse implements IChangeKey {
  // @DataMember
  public items: AdaptiveScene[];

  // @DataMember
  public total: number;

  // @DataMember
  public page: number;

  // @DataMember
  public itemsPerPage: number;

  // @DataMember
  public changeKey: string;

  public constructor(init?: Partial<GetAdaptiveScenesDetailsResponse>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class GetAdaptiveScenesStatusesResponse implements IChangeKey {
  // @DataMember
  public items: AdaptiveSceneMapStatus[];

  // @DataMember
  public total: number;

  // @DataMember
  public page: number;

  // @DataMember
  public itemsPerPage: number;

  // @DataMember
  public changeKey: string;

  public constructor(init?: Partial<GetAdaptiveScenesStatusesResponse>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class AdaptiveSceneStatus
  implements ICustomerEntityViewModel, INamedEntity, IChangeKey, IDescription, IModified, IEntity
{
  // @DataMember
  public trafficObjectStatuses: TrafficObjectAdaptiveSceneStatus[];

  // @DataMember
  public controlModeStartTime?: string;

  /**
   * Статус адаптивного режима
   */
  // @DataMember
  // @ApiMember(Description="Статус адаптивного режима")
  public enabled: boolean;

  // @DataMember
  public serverTime: string;

  // @DataMember
  public error: string;

  // @DataMember
  public statusDescription: string;

  // @DataMember
  public iconStatus: AdaptiveSceneIconStatus;

  // @DataMember
  public customerId: string;

  // @DataMember
  public customerName: string;

  // @DataMember
  public name: string;

  // @DataMember
  public description: string;

  // @DataMember
  public changeKey: string;

  // @DataMember
  public created: string;

  // @DataMember
  public createdBy: string;

  // @DataMember
  public modified: string;

  // @DataMember
  public modifiedBy: string;

  // @DataMember
  public id: string;

  // @DataMember
  public entityType: string;

  public constructor(init?: Partial<AdaptiveSceneStatus>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class TrafficObjectDetectors {
  // @DataMember
  public detectors: TrafficObjectDetector[];

  // @DataMember
  public id: string;

  // @DataMember
  public entityType: string;

  public constructor(init?: Partial<TrafficObjectDetectors>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class TrafficObjectDetectorsReport implements INamedEntity, IEntity {
  // @DataMember
  public lat: number;

  // @DataMember
  public lon: number;

  // @DataMember
  public detectors: TrafficObjectDetectorInfo[];

  // @DataMember
  public name: string;

  // @DataMember
  public id: string;

  // @DataMember
  public entityType: string;

  public constructor(init?: Partial<TrafficObjectDetectorsReport>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class GetTrafficScenesResponse implements IChangeKey {
  // @DataMember
  public items: TrafficSceneInfo[];

  // @DataMember
  public total: number;

  // @DataMember
  public page: number;

  // @DataMember
  public itemsPerPage: number;

  // @DataMember
  public changeKey: string;

  public constructor(init?: Partial<GetTrafficScenesResponse>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class TrafficScene
  implements
    IBaseEntity,
    ICustomerEntityViewModel,
    INamedEntity,
    IChangeKey,
    IDescription,
    IModified,
    IEntity
{
  // @DataMember
  public settings: TrafficSceneSettings;

  // @DataMember
  public deleted: boolean;

  // @DataMember
  public customerId: string;

  // @DataMember
  public customerName: string;

  // @DataMember
  public name: string;

  // @DataMember
  public description: string;

  // @DataMember
  public changeKey: string;

  // @DataMember
  public created: string;

  // @DataMember
  public createdBy: string;

  // @DataMember
  public modified: string;

  // @DataMember
  public modifiedBy: string;

  // @DataMember
  public id: string;

  // @DataMember
  public entityType: string;

  public constructor(init?: Partial<TrafficScene>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class GetTrafficScenesDetailsResponse implements IChangeKey {
  // @DataMember
  public items: TrafficScene[];

  // @DataMember
  public total: number;

  // @DataMember
  public page: number;

  // @DataMember
  public itemsPerPage: number;

  // @DataMember
  public changeKey: string;

  public constructor(init?: Partial<GetTrafficScenesDetailsResponse>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class GetTrafficScenesStatusesResponse implements IChangeKey {
  // @DataMember
  public items: TrafficSceneMapStatus[];

  // @DataMember
  public total: number;

  // @DataMember
  public page: number;

  // @DataMember
  public itemsPerPage: number;

  // @DataMember
  public changeKey: string;

  public constructor(init?: Partial<GetTrafficScenesStatusesResponse>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class TrafficSceneStatus
  implements ICustomerEntityViewModel, INamedEntity, IChangeKey, IDescription, IModified, IEntity
{
  // @DataMember
  public trafficObjectStatuses: TrafficObjectSceneStatus[];

  // @DataMember
  public controlMode: TrafficSceneControlMode;

  // @DataMember
  public controlModeStartTime?: string;

  // @DataMember
  public modeTime?: number;

  // @DataMember
  public serverTime: string;

  // @DataMember
  public manual: boolean;

  // @DataMember
  public forwardDirection: boolean;

  // @DataMember
  public modeLog: string;

  // @DataMember
  public error: string;

  // @DataMember
  public statusDescription: string;

  // @DataMember
  public iconStatus: TrafficSceneIconStatus;

  // @DataMember
  public customerId: string;

  // @DataMember
  public customerName: string;

  // @DataMember
  public name: string;

  // @DataMember
  public description: string;

  // @DataMember
  public changeKey: string;

  // @DataMember
  public created: string;

  // @DataMember
  public createdBy: string;

  // @DataMember
  public modified: string;

  // @DataMember
  public modifiedBy: string;

  // @DataMember
  public id: string;

  // @DataMember
  public entityType: string;

  public constructor(init?: Partial<TrafficSceneStatus>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class TrafficSceneAnalysis
  implements ICustomerEntityViewModel, INamedEntity, IChangeKey, IDescription, IModified, IEntity
{
  // @DataMember
  public trafficObjectSceneAnalyses: TrafficObjectSceneAnalysis[];

  // @DataMember
  public serverTime: string;

  // @DataMember
  public customerId: string;

  // @DataMember
  public customerName: string;

  // @DataMember
  public name: string;

  // @DataMember
  public description: string;

  // @DataMember
  public changeKey: string;

  // @DataMember
  public created: string;

  // @DataMember
  public createdBy: string;

  // @DataMember
  public modified: string;

  // @DataMember
  public modifiedBy: string;

  // @DataMember
  public id: string;

  // @DataMember
  public entityType: string;

  public constructor(init?: Partial<TrafficSceneAnalysis>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class EntityIntegration implements IChangeKey, IDescription, IModified, IEntity {
  // @DataMember
  public entityId: string;

  // @DataMember
  public customerIntegrationId: string;

  // @DataMember
  public externalId: string;

  // @DataMember
  public settings: string;

  // @DataMember
  public integrationId: string;

  // @DataMember
  public integrationName: string;

  // @DataMember
  public integrationType: string;

  // @DataMember
  public description: string;

  // @DataMember
  public changeKey: string;

  // @DataMember
  public created: string;

  // @DataMember
  public createdBy: string;

  // @DataMember
  public modified: string;

  // @DataMember
  public modifiedBy: string;

  // @DataMember
  public id: string;

  // @DataMember
  public entityType: string;

  public constructor(init?: Partial<EntityIntegration>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class GetEntityIntegrationResponse implements IChangeKey {
  // @DataMember
  public items: EntityIntegration[];

  // @DataMember
  public total: number;

  // @DataMember
  public page: number;

  // @DataMember
  public itemsPerPage: number;

  // @DataMember
  public changeKey: string;

  public constructor(init?: Partial<GetEntityIntegrationResponse>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class GetEntitiesResponse implements IChangeKey {
  // @DataMember
  public items: Object[];

  // @DataMember
  public total: number;

  // @DataMember
  public page: number;

  // @DataMember
  public itemsPerPage: number;

  // @DataMember
  public changeKey: string;

  public constructor(init?: Partial<GetEntitiesResponse>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class AuthenticateResponse implements IHasSessionId, IHasBearerToken {
  // @DataMember(Order=1)
  public userId: string;

  // @DataMember(Order=2)
  public sessionId: string;

  // @DataMember(Order=3)
  public userName: string;

  // @DataMember(Order=4)
  public displayName: string;

  // @DataMember(Order=5)
  public referrerUrl: string;

  // @DataMember(Order=6)
  public bearerToken: string;

  // @DataMember(Order=7)
  public refreshToken: string;

  // @DataMember(Order=8)
  public profileUrl: string;

  // @DataMember(Order=9)
  public roles: string[];

  // @DataMember(Order=10)
  public permissions: string[];

  // @DataMember(Order=11)
  public responseStatus: ResponseStatus;

  // @DataMember(Order=12)
  public meta: { [index: string]: string };

  public constructor(init?: Partial<AuthenticateResponse>) {
    (Object as any).assign(this, init);
  }
}

// @Route("/customer/{customerId}/adaptiveScenes/groups", "GET")
// @DataContract
export class GetAdaptiveSceneGroupsRequest
  implements IReturn<GetEntityGroupsResponse>, ICustomerItemRequest
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public entityGroups: string[];

  // @DataMember
  public itemsIds: string[];

  // @DataMember
  public onlyOwnedItems: boolean;

  // @DataMember
  public page: number;

  // @DataMember
  public items: number;

  // @DataMember
  public sort: string;

  // @DataMember
  public filter: string;

  // @DataMember
  public propertiesTest: { [index: string]: number };

  public constructor(init?: Partial<GetAdaptiveSceneGroupsRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new GetEntityGroupsResponse();
  }
  public getTypeName() {
    return 'GetAdaptiveSceneGroupsRequest';
  }
}

// @Route("/customer/{customerId}/adaptiveScenes/groups/{id}", "GET")
// @DataContract
export class GetAdaptiveSceneGroupRequest
  implements IReturn<EntityGroup>, ICustomerItemRequest, IBaseEntityItemRequest
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember(IsRequired=true)
  public id: string;

  public constructor(init?: Partial<GetAdaptiveSceneGroupRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new EntityGroup();
  }
  public getTypeName() {
    return 'GetAdaptiveSceneGroupRequest';
  }
}

// @Route("/customer/{customerId}/adaptiveScenes/groups", "POST")
// @DataContract
export class CreateAdaptiveSceneGroupRequest
  implements IReturn<EntityGroup>, ICustomerItemRequest, IDescription
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public updateEntityGroups: boolean;

  // @DataMember
  public entityGroups: string[];

  // @DataMember(IsRequired=true)
  public entity: EntityGroup;

  // @DataMember
  public description: string;

  public constructor(init?: Partial<CreateAdaptiveSceneGroupRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new EntityGroup();
  }
  public getTypeName() {
    return 'CreateAdaptiveSceneGroupRequest';
  }
}

// @Route("/customer/{customerId}/adaptiveScenes/groups/{id}", "PUT,POST")
// @DataContract
export class UpdateAdaptiveSceneGroupRequest
  implements IReturn<EntityGroup>, ICustomerItemRequest, IBaseEntityItemRequest, IDescription
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public updateEntityGroups: boolean;

  // @DataMember
  public entityGroups: string[];

  // @DataMember(IsRequired=true)
  public id: string;

  // @DataMember(IsRequired=true)
  public entity: EntityGroup;

  // @DataMember
  public description: string;

  public constructor(init?: Partial<UpdateAdaptiveSceneGroupRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new EntityGroup();
  }
  public getTypeName() {
    return 'UpdateAdaptiveSceneGroupRequest';
  }
}

// @Route("/customer/{customerId}/adaptiveScenes/groups", "DELETE")
// @DataContract
export class DeleteAdaptiveSceneGroupsRequest
  implements IReturn<number>, ICustomerItemRequest, IDescription
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public description: string;

  // @DataMember(IsRequired=true)
  public ids: string[];

  public constructor(init?: Partial<DeleteAdaptiveSceneGroupsRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return 0;
  }
  public getTypeName() {
    return 'DeleteAdaptiveSceneGroupsRequest';
  }
}

// @Route("/customer/{customerId}/adaptiveScenes", "GET")
// @DataContract
export class GetAdaptiveScenesRequest
  implements IReturn<GetAdaptiveScenesResponse>, ICustomerItemRequest
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public entityGroups: string[];

  // @DataMember
  public itemsIds: string[];

  // @DataMember
  public onlyOwnedItems: boolean;

  // @DataMember
  public page: number;

  // @DataMember
  public items: number;

  // @DataMember
  public sort: string;

  // @DataMember
  public filter: string;

  // @DataMember
  public propertiesTest: { [index: string]: number };

  public constructor(init?: Partial<GetAdaptiveScenesRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new GetAdaptiveScenesResponse();
  }
  public getTypeName() {
    return 'GetAdaptiveScenesRequest';
  }
}

// @Route("/customer/{customerId}/adaptiveScenesDetails", "GET")
// @DataContract
export class GetAdaptiveScenesDetailsRequest
  implements IReturn<GetAdaptiveScenesDetailsResponse>, ICustomerItemRequest
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public entityGroups: string[];

  // @DataMember
  public itemsIds: string[];

  // @DataMember
  public onlyOwnedItems: boolean;

  // @DataMember
  public page: number;

  // @DataMember
  public items: number;

  // @DataMember
  public sort: string;

  // @DataMember
  public filter: string;

  // @DataMember
  public propertiesTest: { [index: string]: number };

  public constructor(init?: Partial<GetAdaptiveScenesDetailsRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new GetAdaptiveScenesDetailsResponse();
  }
  public getTypeName() {
    return 'GetAdaptiveScenesDetailsRequest';
  }
}

// @Route("/customer/{customerId}/adaptiveScenes/statuses", "GET")
// @DataContract
export class GetAdaptiveScenesStatusesRequest
  implements IReturn<GetAdaptiveScenesStatusesResponse>, ICustomerItemRequest
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public entityGroups: string[];

  // @DataMember
  public itemsIds: string[];

  // @DataMember
  public onlyOwnedItems: boolean;

  // @DataMember
  public page: number;

  // @DataMember
  public items: number;

  // @DataMember
  public sort: string;

  // @DataMember
  public filter: string;

  // @DataMember
  public propertiesTest: { [index: string]: number };

  public constructor(init?: Partial<GetAdaptiveScenesStatusesRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new GetAdaptiveScenesStatusesResponse();
  }
  public getTypeName() {
    return 'GetAdaptiveScenesStatusesRequest';
  }
}

// @Route("/customer/{customerId}/adaptiveScene/{id}", "GET")
// @DataContract
export class GetAdaptiveSceneRequest
  implements IReturn<AdaptiveScene>, ICustomerItemRequest, IBaseEntityItemRequest
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember(IsRequired=true)
  public id: string;

  public constructor(init?: Partial<GetAdaptiveSceneRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new AdaptiveScene();
  }
  public getTypeName() {
    return 'GetAdaptiveSceneRequest';
  }
}

// @Route("/customer/{customerId}/adaptiveScene/{id}/status", "GET")
// @DataContract
export class GetAdaptiveSceneStatusRequest
  implements IReturn<AdaptiveSceneStatus>, ICustomerItemRequest, IBaseEntityItemRequest
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember(IsRequired=true)
  public id: string;

  public constructor(init?: Partial<GetAdaptiveSceneStatusRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new AdaptiveSceneStatus();
  }
  public getTypeName() {
    return 'GetAdaptiveSceneStatusRequest';
  }
}

// @Route("/customer/{customerId}/adaptiveScene/{id}/startAdaptive", "POST")
// @DataContract
export class StartAdaptiveSceneRequest
  implements IReturn<AdaptiveSceneStatus>, IBaseEntityItemRequest, ICustomerItemRequest
{
  // @DataMember
  public id: string;

  // @DataMember
  public customerId: string;

  public constructor(init?: Partial<StartAdaptiveSceneRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new AdaptiveSceneStatus();
  }
  public getTypeName() {
    return 'StartAdaptiveSceneRequest';
  }
}

// @Route("/customer/{customerId}/adaptiveScene/{id}/stop", "POST")
// @DataContract
export class StopAdaptiveSceneControlMode
  implements IReturn<AdaptiveSceneStatus>, IBaseEntityItemRequest, ICustomerItemRequest
{
  // @DataMember
  public id: string;

  // @DataMember
  public customerId: string;

  public constructor(init?: Partial<StopAdaptiveSceneControlMode>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new AdaptiveSceneStatus();
  }
  public getTypeName() {
    return 'StopAdaptiveSceneControlMode';
  }
}

// @Route("/customer/{customerId}/adaptiveScenes", "POST")
// @DataContract
export class CreateAdaptiveSceneRequest
  implements IReturn<AdaptiveScene>, ICustomerItemRequest, IDescription
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public updateEntityGroups: boolean;

  // @DataMember
  public entityGroups: string[];

  // @DataMember(IsRequired=true)
  public entity: AdaptiveScene;

  // @DataMember
  public description: string;

  public constructor(init?: Partial<CreateAdaptiveSceneRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new AdaptiveScene();
  }
  public getTypeName() {
    return 'CreateAdaptiveSceneRequest';
  }
}

// @Route("/customer/{customerId}/adaptiveScene/{id}", "PUT")
// @DataContract
export class UpdateAdaptiveSceneRequest
  implements IReturn<AdaptiveScene>, ICustomerItemRequest, IBaseEntityItemRequest, IDescription
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public updateEntityGroups: boolean;

  // @DataMember
  public entityGroups: string[];

  // @DataMember(IsRequired=true)
  public id: string;

  // @DataMember(IsRequired=true)
  public entity: AdaptiveScene;

  // @DataMember
  public description: string;

  public constructor(init?: Partial<UpdateAdaptiveSceneRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new AdaptiveScene();
  }
  public getTypeName() {
    return 'UpdateAdaptiveSceneRequest';
  }
}

// @Route("/customer/{customerId}/adaptiveScenes", "DELETE")
// @DataContract
export class DeleteAdaptiveSceneRequest
  implements IReturn<number>, ICustomerItemRequest, IDescription
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public description: string;

  // @DataMember(IsRequired=true)
  public ids: string[];

  public constructor(init?: Partial<DeleteAdaptiveSceneRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return 0;
  }
  public getTypeName() {
    return 'DeleteAdaptiveSceneRequest';
  }
}

// @Route("/customer/{customerId}/trafficObject/{id}/detectorsSet", "GET")
// @DataContract
export class GetTrafficObjectDetectorsRequest
  implements IReturn<TrafficObjectDetectors>, ICustomerItemRequest, IBaseEntityItemRequest
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember(IsRequired=true)
  public id: string;

  public constructor(init?: Partial<GetTrafficObjectDetectorsRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new TrafficObjectDetectors();
  }
  public getTypeName() {
    return 'GetTrafficObjectDetectorsRequest';
  }
}

// @Route("/customer/{customerId}/trafficObject/{id}/detectorsSet", "PUT")
// @DataContract
export class UpdateTrafficObjectDetectorsRequest
  implements
    IReturn<TrafficObjectDetectors>,
    ICustomerItemRequest,
    IBaseEntityItemRequest,
    IDescription
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public updateEntityGroups: boolean;

  // @DataMember
  public entityGroups: string[];

  // @DataMember(IsRequired=true)
  public id: string;

  // @DataMember(IsRequired=true)
  public entity: TrafficObjectDetectors;

  // @DataMember
  public description: string;

  public constructor(init?: Partial<UpdateTrafficObjectDetectorsRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new TrafficObjectDetectors();
  }
  public getTypeName() {
    return 'UpdateTrafficObjectDetectorsRequest';
  }
}

// @Route("/detectorsSet/{Ids}", "DELETE")
// @DataContract
export class DeleteTrafficObjectDetectorsRequest implements IReturn<number>, IDescription {
  // @DataMember
  public description: string;

  // @DataMember(IsRequired=true)
  public ids: string[];

  public constructor(init?: Partial<DeleteTrafficObjectDetectorsRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return 0;
  }
  public getTypeName() {
    return 'DeleteTrafficObjectDetectorsRequest';
  }
}

// @Route("/trafficObjectDetectorsReport")
// @DataContract
export class GetTrafficObjectDetectorsReport
  implements IReturn<TrafficObjectDetectorsReport>, ICustomerItemRequest, IBaseEntityItemRequest
{
  // @DataMember(Order=0)
  public sourceDate: string;

  // @DataMember(Order=1, IsRequired=true)
  public compareWithDate: string;

  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember(IsRequired=true)
  public id: string;

  public constructor(init?: Partial<GetTrafficObjectDetectorsReport>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new TrafficObjectDetectorsReport();
  }
  public getTypeName() {
    return 'GetTrafficObjectDetectorsReport';
  }
}

// @Route("/trafficObjectDetectorsReportFile/{Id}")
// @DataContract
export class GetTrafficObjectDetectorsReportFile implements IReturn<Object> {
  // @DataMember(Order=0, IsRequired=true)
  public id: string;

  // @DataMember(Order=1)
  public customerId: string;

  // @DataMember(Order=2)
  public sourceDate: string;

  // @DataMember(Order=3, IsRequired=true)
  public compareWithDate: string;

  // @DataMember(Order=4, IsRequired=true)
  public format: ReportFormat;

  public constructor(init?: Partial<GetTrafficObjectDetectorsReportFile>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new Object();
  }
  public getTypeName() {
    return 'GetTrafficObjectDetectorsReportFile';
  }
}

// @Route("/customer/{customerId}/trafficScenes/groups", "GET")
// @DataContract
export class GetTrafficSceneGroupsRequest
  implements IReturn<GetEntityGroupsResponse>, ICustomerItemRequest
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public entityGroups: string[];

  // @DataMember
  public itemsIds: string[];

  // @DataMember
  public onlyOwnedItems: boolean;

  // @DataMember
  public page: number;

  // @DataMember
  public items: number;

  // @DataMember
  public sort: string;

  // @DataMember
  public filter: string;

  // @DataMember
  public propertiesTest: { [index: string]: number };

  public constructor(init?: Partial<GetTrafficSceneGroupsRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new GetEntityGroupsResponse();
  }
  public getTypeName() {
    return 'GetTrafficSceneGroupsRequest';
  }
}

// @Route("/customer/{customerId}/trafficScenes/groups/{id}", "GET")
// @DataContract
export class GetTrafficSceneGroupRequest
  implements IReturn<EntityGroup>, ICustomerItemRequest, IBaseEntityItemRequest
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember(IsRequired=true)
  public id: string;

  public constructor(init?: Partial<GetTrafficSceneGroupRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new EntityGroup();
  }
  public getTypeName() {
    return 'GetTrafficSceneGroupRequest';
  }
}

// @Route("/customer/{customerId}/trafficScenes/groups", "POST")
// @DataContract
export class CreateTrafficSceneGroupRequest
  implements IReturn<EntityGroup>, ICustomerItemRequest, IDescription
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public updateEntityGroups: boolean;

  // @DataMember
  public entityGroups: string[];

  // @DataMember(IsRequired=true)
  public entity: EntityGroup;

  // @DataMember
  public description: string;

  public constructor(init?: Partial<CreateTrafficSceneGroupRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new EntityGroup();
  }
  public getTypeName() {
    return 'CreateTrafficSceneGroupRequest';
  }
}

// @Route("/customer/{customerId}/trafficScenes/groups/{id}", "PUT,POST")
// @DataContract
export class UpdateTrafficSceneGroupRequest
  implements IReturn<EntityGroup>, ICustomerItemRequest, IBaseEntityItemRequest, IDescription
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public updateEntityGroups: boolean;

  // @DataMember
  public entityGroups: string[];

  // @DataMember(IsRequired=true)
  public id: string;

  // @DataMember(IsRequired=true)
  public entity: EntityGroup;

  // @DataMember
  public description: string;

  public constructor(init?: Partial<UpdateTrafficSceneGroupRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new EntityGroup();
  }
  public getTypeName() {
    return 'UpdateTrafficSceneGroupRequest';
  }
}

// @Route("/customer/{customerId}/trafficScenes/groups", "DELETE")
// @DataContract
export class DeleteTrafficSceneGroupsRequest
  implements IReturn<number>, ICustomerItemRequest, IDescription
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public description: string;

  // @DataMember(IsRequired=true)
  public ids: string[];

  public constructor(init?: Partial<DeleteTrafficSceneGroupsRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return 0;
  }
  public getTypeName() {
    return 'DeleteTrafficSceneGroupsRequest';
  }
}

// @Route("/customer/{customerId}/trafficScenes", "GET")
// @DataContract
export class GetTrafficScenesRequest
  implements IReturn<GetTrafficScenesResponse>, ICustomerItemRequest
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public entityGroups: string[];

  // @DataMember
  public itemsIds: string[];

  // @DataMember
  public onlyOwnedItems: boolean;

  // @DataMember
  public page: number;

  // @DataMember
  public items: number;

  // @DataMember
  public sort: string;

  // @DataMember
  public filter: string;

  // @DataMember
  public propertiesTest: { [index: string]: number };

  public constructor(init?: Partial<GetTrafficScenesRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new GetTrafficScenesResponse();
  }
  public getTypeName() {
    return 'GetTrafficScenesRequest';
  }
}

// @Route("/customer/{customerId}/trafficScenesDetails", "GET")
// @DataContract
export class GetTrafficScenesDetailsRequest
  implements IReturn<GetTrafficScenesDetailsResponse>, ICustomerItemRequest
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public entityGroups: string[];

  // @DataMember
  public itemsIds: string[];

  // @DataMember
  public onlyOwnedItems: boolean;

  // @DataMember
  public page: number;

  // @DataMember
  public items: number;

  // @DataMember
  public sort: string;

  // @DataMember
  public filter: string;

  // @DataMember
  public propertiesTest: { [index: string]: number };

  public constructor(init?: Partial<GetTrafficScenesDetailsRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new GetTrafficScenesDetailsResponse();
  }
  public getTypeName() {
    return 'GetTrafficScenesDetailsRequest';
  }
}

// @Route("/customer/{customerId}/trafficScenes/statuses", "GET")
// @DataContract
export class GetTrafficScenesStatusesRequest
  implements IReturn<GetTrafficScenesStatusesResponse>, ICustomerItemRequest
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public entityGroups: string[];

  // @DataMember
  public itemsIds: string[];

  // @DataMember
  public onlyOwnedItems: boolean;

  // @DataMember
  public page: number;

  // @DataMember
  public items: number;

  // @DataMember
  public sort: string;

  // @DataMember
  public filter: string;

  // @DataMember
  public propertiesTest: { [index: string]: number };

  public constructor(init?: Partial<GetTrafficScenesStatusesRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new GetTrafficScenesStatusesResponse();
  }
  public getTypeName() {
    return 'GetTrafficScenesStatusesRequest';
  }
}

// @Route("/customer/{customerId}/trafficScene/{id}", "GET")
// @DataContract
export class GetTrafficSceneRequest
  implements IReturn<TrafficScene>, ICustomerItemRequest, IBaseEntityItemRequest
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember(IsRequired=true)
  public id: string;

  public constructor(init?: Partial<GetTrafficSceneRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new TrafficScene();
  }
  public getTypeName() {
    return 'GetTrafficSceneRequest';
  }
}

// @Route("/customer/{customerId}/trafficScene/{id}/status", "GET")
// @DataContract
export class GetTrafficSceneStatusRequest
  implements IReturn<TrafficSceneStatus>, ICustomerItemRequest, IBaseEntityItemRequest
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember(IsRequired=true)
  public id: string;

  public constructor(init?: Partial<GetTrafficSceneStatusRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new TrafficSceneStatus();
  }
  public getTypeName() {
    return 'GetTrafficSceneStatusRequest';
  }
}

// @Route("/customer/{customerId}/trafficScene/{id}/analysis", "GET")
// @DataContract
export class GetTrafficSceneAnalysisRequest
  implements IReturn<TrafficSceneAnalysis>, ICustomerItemRequest, IBaseEntityItemRequest
{
  // @DataMember
  public startTime: string;

  // @DataMember
  public finishTime: string;

  // @DataMember
  public dayOfWeek: number;

  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember(IsRequired=true)
  public id: string;

  public constructor(init?: Partial<GetTrafficSceneAnalysisRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new TrafficSceneAnalysis();
  }
  public getTypeName() {
    return 'GetTrafficSceneAnalysisRequest';
  }
}

// @Route("/customer/{customerId}/trafficScene/{id}/startRedStreetMode", "POST")
// @DataContract
export class StartTrafficSceneRedStreetModeRequest
  implements IReturn<TrafficSceneStatus>, IBaseEntityItemRequest, ICustomerItemRequest
{
  // @DataMember
  public manual: boolean;

  // @DataMember
  public id: string;

  // @DataMember
  public customerId: string;

  // @DataMember
  public modeTime: number;

  // @DataMember
  public forwardDirection: boolean;

  // @DataMember
  public switchMode: TrafficObjectSwitchMode;

  public constructor(init?: Partial<StartTrafficSceneRedStreetModeRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new TrafficSceneStatus();
  }
  public getTypeName() {
    return 'StartTrafficSceneRedStreetModeRequest';
  }
}

// @Route("/customer/{customerId}/trafficScene/{id}/startGreenStreetMode", "POST")
// @DataContract
export class StartTrafficSceneGreenStreetModeRequest
  implements IReturn<TrafficSceneStatus>, IBaseEntityItemRequest, ICustomerItemRequest
{
  // @DataMember
  public manual: boolean;

  // @DataMember
  public id: string;

  // @DataMember
  public customerId: string;

  // @DataMember
  public modeTime: number;

  // @DataMember
  public forwardDirection: boolean;

  // @DataMember
  public switchMode: TrafficObjectSwitchMode;

  public constructor(init?: Partial<StartTrafficSceneGreenStreetModeRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new TrafficSceneStatus();
  }
  public getTypeName() {
    return 'StartTrafficSceneGreenStreetModeRequest';
  }
}

// @Route("/customer/{customerId}/trafficScene/{id}/startAdaptiveMode", "POST")
// @DataContract
export class StartTrafficSceneAdaptiveModeRequest
  implements IReturn<TrafficSceneStatus>, IBaseEntityItemRequest, ICustomerItemRequest
{
  // @DataMember
  public id: string;

  // @DataMember
  public customerId: string;

  // @DataMember
  public modeTime: number;

  // @DataMember
  public forwardDirection: boolean;

  // @DataMember
  public switchMode: TrafficObjectSwitchMode;

  public constructor(init?: Partial<StartTrafficSceneAdaptiveModeRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new TrafficSceneStatus();
  }
  public getTypeName() {
    return 'StartTrafficSceneAdaptiveModeRequest';
  }
}

// @Route("/customer/{customerId}/trafficScene/{id}/startObjectStepManual", "POST")
// @DataContract
export class StartTrafficSceneObjectStepManualRequest
  implements IReturn<TrafficSceneStatus>, IBaseEntityItemRequest, ICustomerItemRequest
{
  // @DataMember
  public id: string;

  // @DataMember
  public customerId: string;

  // @DataMember
  public trafficObjectId: string;

  // @DataMember
  public forwardDirection: boolean;

  // @DataMember
  public controlMode: TrafficSceneControlMode;

  // @DataMember
  public modeTime: number;

  public constructor(init?: Partial<StartTrafficSceneObjectStepManualRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new TrafficSceneStatus();
  }
  public getTypeName() {
    return 'StartTrafficSceneObjectStepManualRequest';
  }
}

// @Route("/customer/{customerId}/trafficScene/{id}/stopObjectStepManual", "POST")
// @DataContract
export class StopTrafficSceneObjectStepManualRequest
  implements IReturn<TrafficSceneStatus>, IBaseEntityItemRequest, ICustomerItemRequest
{
  // @DataMember
  public id: string;

  // @DataMember
  public customerId: string;

  // @DataMember
  public trafficObjectId: string;

  public constructor(init?: Partial<StopTrafficSceneObjectStepManualRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new TrafficSceneStatus();
  }
  public getTypeName() {
    return 'StopTrafficSceneObjectStepManualRequest';
  }
}

// @Route("/customer/{customerId}/trafficScene/{id}/stopControlMode", "POST")
// @DataContract
export class StopTrafficSceneControlMode
  implements IReturn<TrafficSceneStatus>, IBaseEntityItemRequest, ICustomerItemRequest
{
  // @DataMember
  public id: string;

  // @DataMember
  public customerId: string;

  public constructor(init?: Partial<StopTrafficSceneControlMode>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new TrafficSceneStatus();
  }
  public getTypeName() {
    return 'StopTrafficSceneControlMode';
  }
}

// @Route("/customer/{customerId}/trafficScenes", "POST")
// @DataContract
export class CreateTrafficSceneRequest
  implements IReturn<TrafficScene>, ICustomerItemRequest, IDescription
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public updateEntityGroups: boolean;

  // @DataMember
  public entityGroups: string[];

  // @DataMember(IsRequired=true)
  public entity: TrafficScene;

  // @DataMember
  public description: string;

  public constructor(init?: Partial<CreateTrafficSceneRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new TrafficScene();
  }
  public getTypeName() {
    return 'CreateTrafficSceneRequest';
  }
}

// @Route("/customer/{customerId}/trafficScene/{id}", "PUT")
// @DataContract
export class UpdateTrafficSceneRequest
  implements IReturn<TrafficScene>, ICustomerItemRequest, IBaseEntityItemRequest, IDescription
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public updateEntityGroups: boolean;

  // @DataMember
  public entityGroups: string[];

  // @DataMember(IsRequired=true)
  public id: string;

  // @DataMember(IsRequired=true)
  public entity: TrafficScene;

  // @DataMember
  public description: string;

  public constructor(init?: Partial<UpdateTrafficSceneRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new TrafficScene();
  }
  public getTypeName() {
    return 'UpdateTrafficSceneRequest';
  }
}

// @Route("/customer/{customerId}/trafficScenes", "DELETE")
// @DataContract
export class DeleteTrafficSceneRequest
  implements IReturn<number>, ICustomerItemRequest, IDescription
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public description: string;

  // @DataMember(IsRequired=true)
  public ids: string[];

  public constructor(init?: Partial<DeleteTrafficSceneRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return 0;
  }
  public getTypeName() {
    return 'DeleteTrafficSceneRequest';
  }
}

// @Route("/sumoCalculator/{id}", "GET")
// @DataContract
export class GetSumoConvertedTrafficObjectPlanRequest
  implements IReturn<string>, IBaseEntityItemRequest
{
  // @DataMember
  public offset: number;

  // @DataMember
  public programId: number;

  // @DataMember
  public type: TrafficLightType;

  // @DataMember(IsRequired=true)
  public id: string;

  public constructor(init?: Partial<GetSumoConvertedTrafficObjectPlanRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return '';
  }
  public getTypeName() {
    return 'GetSumoConvertedTrafficObjectPlanRequest';
  }
}

// @Route("/healthStatus", "GET")
// @DataContract
export class GetHealthStatusRequest implements IReturn<string> {
  public constructor(init?: Partial<GetHealthStatusRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return '';
  }
  public getTypeName() {
    return 'GetHealthStatusRequest';
  }
}

// @Route("/customer/{customerId}/integration/{customerIntegrationId}/entities", "GET")
// @DataContract
export class GetEntityIntegrationsRequest
  implements
    IReturn<GetEntityIntegrationResponse>,
    ICustomerIntegrationIdRequest,
    ICustomerItemRequest
{
  // @DataMember
  public customerIntegrationId: string;

  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public entityGroups: string[];

  // @DataMember
  public itemsIds: string[];

  // @DataMember
  public onlyOwnedItems: boolean;

  // @DataMember
  public page: number;

  // @DataMember
  public items: number;

  // @DataMember
  public sort: string;

  // @DataMember
  public filter: string;

  // @DataMember
  public propertiesTest: { [index: string]: number };

  public constructor(init?: Partial<GetEntityIntegrationsRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new GetEntityIntegrationResponse();
  }
  public getTypeName() {
    return 'GetEntityIntegrationsRequest';
  }
}

// @Route("/customer/{customerId}/integration/{customerIntegrationId}/entity/{id}", "GET")
// @DataContract
export class GetEntityIntegrationRequest
  implements
    IReturn<EntityIntegration>,
    ICustomerIntegrationIdRequest,
    ICustomerItemRequest,
    IBaseEntityItemRequest
{
  // @DataMember
  public customerIntegrationId: string;

  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember(IsRequired=true)
  public id: string;

  public constructor(init?: Partial<GetEntityIntegrationRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new EntityIntegration();
  }
  public getTypeName() {
    return 'GetEntityIntegrationRequest';
  }
}

// @Route("/customer/{customerId}/integration/{customerIntegrationId}/entities", "POST")
// @DataContract
export class CreateEntityIntegrationRequest
  implements
    IReturn<EntityIntegration>,
    ICustomerIntegrationIdRequest,
    ICustomerItemRequest,
    IDescription
{
  // @DataMember
  public customerIntegrationId: string;

  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public updateEntityGroups: boolean;

  // @DataMember
  public entityGroups: string[];

  // @DataMember(IsRequired=true)
  public entity: EntityIntegration;

  // @DataMember
  public description: string;

  public constructor(init?: Partial<CreateEntityIntegrationRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new EntityIntegration();
  }
  public getTypeName() {
    return 'CreateEntityIntegrationRequest';
  }
}

// @Route("/customer/{customerId}/integration/{customerIntegrationId}/entity/{id}", "PUT")
// @DataContract
export class UpdateEntityIntegrationRequest
  implements
    IReturn<EntityIntegration>,
    ICustomerIntegrationIdRequest,
    ICustomerItemRequest,
    IBaseEntityItemRequest,
    IDescription
{
  // @DataMember
  public customerIntegrationId: string;

  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public updateEntityGroups: boolean;

  // @DataMember
  public entityGroups: string[];

  // @DataMember(IsRequired=true)
  public id: string;

  // @DataMember(IsRequired=true)
  public entity: EntityIntegration;

  // @DataMember
  public description: string;

  public constructor(init?: Partial<UpdateEntityIntegrationRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new EntityIntegration();
  }
  public getTypeName() {
    return 'UpdateEntityIntegrationRequest';
  }
}

// @Route("/entity/{id}/properties", "GET")
// @DataContract
export class GetEntityPropertiesRequest implements IReturn<any>, IBaseEntityItemRequest {
  // @DataMember(IsRequired=true)
  public id: string;

  public constructor(init?: Partial<GetEntityPropertiesRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return {};
  }
  public getTypeName() {
    return 'GetEntityPropertiesRequest';
  }
}

// @Route("/entity/{id}/properties", "PUT")
// @DataContract
export class UpdateEntityPropertiesRequest implements IReturn<any> {
  // @DataMember(IsRequired=true)
  public id: string;

  // @DataMember
  public description: string;

  // @DataMember
  public entity: { [index: string]: string };

  public constructor(init?: Partial<UpdateEntityPropertiesRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return {};
  }
  public getTypeName() {
    return 'UpdateEntityPropertiesRequest';
  }
}

// @Route("/entity/{id}/property/{property}", "GET")
// @DataContract
export class GetEntityPropertyRequest implements IReturn<string> {
  // @DataMember
  public property: string;

  // @DataMember(IsRequired=true)
  public id: string;

  public constructor(init?: Partial<GetEntityPropertyRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return '';
  }
  public getTypeName() {
    return 'GetEntityPropertyRequest';
  }
}

// @Route("/entity/{id}/property/{property}", "PUT")
// @DataContract
export class UpdateEntityPropertyRequest implements IReturn<string> {
  // @DataMember(IsRequired=true)
  public id: string;

  // @DataMember
  public description: string;

  // @DataMember
  public property: string;

  // @DataMember
  public value: string;

  public constructor(init?: Partial<UpdateEntityPropertyRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return '';
  }
  public getTypeName() {
    return 'UpdateEntityPropertyRequest';
  }
}

// @Route("/entities/property/{property}", "GET")
// @DataContract
export class GetEntitiesPropertyRequest implements IReturn<any> {
  // @DataMember
  public property: string;

  // @DataMember
  public filter: string;

  public constructor(init?: Partial<GetEntitiesPropertyRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return {};
  }
  public getTypeName() {
    return 'GetEntitiesPropertyRequest';
  }
}

// @Route("/customer/{customerId}/entity/{Id}/groups", "GET")
// @DataContract
export class GetItemEntityGroupsRequest
  implements IReturn<GetEntityGroupsResponse>, ICustomerItemRequest
{
  // @DataMember
  public id: string;

  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public entityGroups: string[];

  // @DataMember
  public itemsIds: string[];

  // @DataMember
  public onlyOwnedItems: boolean;

  // @DataMember
  public page: number;

  // @DataMember
  public items: number;

  // @DataMember
  public sort: string;

  // @DataMember
  public filter: string;

  // @DataMember
  public propertiesTest: { [index: string]: number };

  public constructor(init?: Partial<GetItemEntityGroupsRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new GetEntityGroupsResponse();
  }
  public getTypeName() {
    return 'GetItemEntityGroupsRequest';
  }
}

// @Route("/entities/remoteRepository/{repositoryId}/data", "GET")
// @DataContract
export class GetEntitiesRequest implements IReturn<GetEntitiesResponse> {
  // @DataMember
  public repositoryId: string;

  // @DataMember
  public modified?: string;

  // @DataMember
  public page: number;

  // @DataMember
  public items: number;

  // @DataMember
  public sort: string;

  // @DataMember
  public filter: string;

  // @DataMember
  public propertiesTest: { [index: string]: number };

  public constructor(init?: Partial<GetEntitiesRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new GetEntitiesResponse();
  }
  public getTypeName() {
    return 'GetEntitiesRequest';
  }
}

/**
 * Sign In
 */
// @Route("/auth", "OPTIONS,GET,POST,DELETE")
// @Route("/auth/{provider}", "OPTIONS,GET,POST,DELETE")
// @Api(Description="Sign In")
// @DataContract
export class Authenticate implements IReturn<AuthenticateResponse>, IPost {
  /**
   * AuthProvider, e.g. credentials
   */
  // @DataMember(Order=1)
  public provider: string;

  // @DataMember(Order=2)
  public state: string;

  // @DataMember(Order=3)
  public oauth_token: string;

  // @DataMember(Order=4)
  public oauth_verifier: string;

  // @DataMember(Order=5)
  public userName: string;

  // @DataMember(Order=6)
  public password: string;

  // @DataMember(Order=7)
  public rememberMe?: boolean;

  // @DataMember(Order=9)
  public errorView: string;

  // @DataMember(Order=10)
  public nonce: string;

  // @DataMember(Order=11)
  public uri: string;

  // @DataMember(Order=12)
  public response: string;

  // @DataMember(Order=13)
  public qop: string;

  // @DataMember(Order=14)
  public nc: string;

  // @DataMember(Order=15)
  public cnonce: string;

  // @DataMember(Order=17)
  public accessToken: string;

  // @DataMember(Order=18)
  public accessTokenSecret: string;

  // @DataMember(Order=19)
  public scope: string;

  // @DataMember(Order=20)
  public meta: { [index: string]: string };

  public constructor(init?: Partial<Authenticate>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new AuthenticateResponse();
  }
  public getTypeName() {
    return 'Authenticate';
  }
}
