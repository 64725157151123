<div *ngIf="loading" class="backdrop">
  <mat-spinner diameter="36" color="accent"></mat-spinner>
</div>
<h4 mat-dialog-title>
  <span>Сравнительный анализ работы СО "{{ trafficObjectName }}"</span>
</h4>
<mat-dialog-content style="flex: 1; width: 1038px">
  <div fxFlexFill fxLayout="column" fxLayoutAlign="space-around stretch" fxLayoutGap="18px">
    <div [formGroup]="formGroup" fxFlex="initial" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">
      <mat-form-field fxFlex="130px" floatLabel="always">
        <input
          matInput
          [formControl]="date1"
          (click)="picker.open()"
          [matDatepicker]="picker"
          placeholder="{{ 'DETECTORS.DATE_FOR_EXPORT' | translate }}"
        />
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
        <mat-error *ngIf="date1.hasError('required')">
          {{ "DETECTORS.REQUIRED_DATE_FOR_EXPORT" | translate }}
        </mat-error>
      </mat-form-field>
      <mat-form-field fxFlex="130px" floatLabel="always">
        <input
          matInput
          [formControl]="date2"
          (click)="picker2.open()"
          [matDatepicker]="picker2"
          placeholder="{{ 'DETECTORS.DATE_FOR_EXPORT' | translate }}"
        />
        <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
        <mat-datepicker #picker2></mat-datepicker>
        <mat-error *ngIf="date2.hasError('required')">
          {{ "DETECTORS.REQUIRED_DATE_FOR_EXPORT" | translate }}
        </mat-error>
      </mat-form-field>
      <button fxFlex="nogrow" mat-button (click)="loadData()" style="width: 175">
        <!-- {{ "COMMON.CANCEL" | translate }} -->
        {{ "TRAFFIC_OBJECT_REPORT.TAKE_REPORT" | translate }}
      </button>
    </div>

    <div
      *ngIf="detectorsArray.length"
      fxflex="475px"
      fxLayoutAlign="center center"
      style="position: relative; min-width: 475px; min-height: 475px; overflow: hidden"
    >
      <div #schemaMap class="schema-map" [style.opacity]="0.6"></div>
      <div #schemaSvg class="schema-svg" [style.transform]="'rotate(' + schemaView.angle + 'deg)'"></div>
    </div>

    <div fxFlexFill *ngFor="let detector of detectorsArray; let index = index; let last = last" style="margin-bottom: 12px !important">
      <!-- <ng-container > -->
      <!-- <div *ngIf="0 === index % 2" fxLayout="row" fxLayoutAlign="center start">
          <div fxFlex="700px" fxLayout="column" fxLayoutAlign="space-around stretch">
            <div fxFlex fxLayout="column" fxLayoutAlign="start center">
              <h4>{{ movementString(detector.movement) }}</h4>
              <span>{{ detector.name }}</span>
            </div>
            <div fxFlex="400px" id="{{ detector.movement }}" style="height: 400px"></div>
            <div fxFlex fxLayout="row" fxLayoutAlign="space-evenly center">
              <div fxFlex align="center" style="margin: 5px">
                <button id="speed_{{ detector.movement }}" mat-raised-button>Показать только скоростьо</button>
              </div>
              <div fxFlex align="center" style="margin: 5px">
                <button id="count_{{ detector.movement }}" mat-raised-button>Показать только количество</button>
     
              </div>
            </div>
          </div>
          <div fxFlex="300px"></div>
        </div> -->
      <div *ngIf="0 === index % 2" fxLayout="column">
        <div fxFlex fxLayout="column" fxLayoutAlign="start center">
          <h4>{{ movementString(detector.movement) }}</h4>
          <span>{{ detector.name }}</span>
        </div>
        <div *ngIf="detector.graphItems.length > 0" fxLayout="row" fxLayoutAlign="center start">
          <div fxFlex="700px" fxLayout="column" fxLayoutAlign="space-around stretch">
            <div fxFlex="400px !important" id="{{ detector.movement }}" style="width: 700px !important; height: 400px !important"></div>
            <div fxFlex fxLayout="row" fxLayoutAlign="space-evenly center">
              <div fxFlex align="center" style="margin: 5px">
                <button id="speed_{{ detector.movement }}" mat-raised-button>{{ "TRAFFIC_OBJECT_REPORT.ONLY_ABG_SPEED" | translate }}</button>
              </div>
              <div fxFlex align="center" style="margin: 5px">
                <button id="count_{{ detector.movement }}" mat-raised-button>{{ "TRAFFIC_OBJECT_REPORT.ONLY_COUNT" | translate }}</button>
              </div>
            </div>
          </div>
          <div fxFlex="320px">
            <div fxFlex fxLayout="column" fxLayoutAlign="space-between start" align="left">
              <p *ngIf="detector.avgSpeedDiffPercent > 0 || detector.avgSpeedDiffPercen == 0" class="borderGreen">
                {{ "TRAFFIC_OBJECT_REPORT.INCREASE_SPEED_ON" | translate }} {{ detector.avgSpeedDiffPercent }}%<br>
                 <!-- Увеличилось на {{detector.speedDiff}}км/ч. -->
              </p>
              <p *ngIf="detector.avgCountDiffPercent > 0 || detector.avgCountDiffPercent == 0" class="borderGreen">
                {{ "TRAFFIC_OBJECT_REPORT.INCREASE_COUNT_ON" | translate }} {{ detector.avgCountDiffPercent }}%<br>
                <!-- Увеличилось на {{detector.countDiff}}шт. -->
              </p>
              <p *ngIf="detector.avgSpeedDiffPercent < 0" class="borderRed">
                {{ "TRAFFIC_OBJECT_REPORT.DECREASE_SPEED_ON" | translate }} {{ abs(detector.avgSpeedDiffPercent) }}%<br>
                <!-- Уменьшилось на {{detector.speedDiff}}км/ч. -->
              </p>
              <p *ngIf="detector.avgCountDiffPercent < 0" class="borderRed">
                {{ "TRAFFIC_OBJECT_REPORT.DECREASE_COUNT_ON" | translate }} {{ abs(detector.avgCountDiffPercent) }}%<br>
                <!-- Уменьшилось на {{detector.countDiff}}шт. -->
              </p>
            </div>
          </div>
        </div>
        <div *ngIf="!detector.graphItems.length > 0">
          <p>{{ "TRAFFIC_OBJECT_REPORT.NO_DETECTORS_DATA" | translate }}</p>
        </div>
      </div>
      <div *ngIf="0 !== index % 2" fxLayout="column">
        <div fxFlex fxLayout="column" fxLayoutAlign="start center">
          <h4>{{ movementString(detector.movement) }}</h4>
          <span>{{ detector.name }}</span>
        </div>
        <div *ngIf="detector.graphItems.length > 0" fxLayout="row" fxLayoutAlign="center start">
          <div fxFlex="320px">
            <div fxFlex fxLayout="column" fxLayoutAlign="space-between start" align="left">
              <p *ngIf="detector.avgSpeedDiffPercent > 0 || detector.avgSpeedDiffPercen == 0" class="borderGreen">
                {{ "TRAFFIC_OBJECT_REPORT.INCREASE_SPEED_ON" | translate }} {{ detector.avgSpeedDiffPercent }}%<br>
                 <!-- Увеличилось на {{detector.speedDiff}}км/ч. -->
              </p>
              <p *ngIf="detector.avgCountDiffPercent > 0 || detector.avgCountDiffPercent == 0" class="borderGreen">
                {{ "TRAFFIC_OBJECT_REPORT.INCREASE_COUNT_ON" | translate }} {{ detector.avgCountDiffPercent }}%<br>
                <!-- Увеличилось на {{detector.countDiff}}шт. -->
              </p>
              <p *ngIf="detector.avgSpeedDiffPercent < 0" class="borderRed">
                {{ "TRAFFIC_OBJECT_REPORT.DECREASE_SPEED_ON" | translate }} {{ abs(detector.avgSpeedDiffPercent) }}%<br>
                <!-- Уменьшилось на {{detector.speedDiff}}км/ч. -->
              </p>
              <p *ngIf="detector.avgCountDiffPercent < 0" class="borderRed">
                {{ "TRAFFIC_OBJECT_REPORT.DECREASE_COUNT_ON" | translate }} {{ abs(detector.avgCountDiffPercent) }}%<br>
                <!-- Уменьшилось на {{detector.countDiff}}шт. -->
              </p>
            </div>
          </div>
          <div fxFlex="700px" fxLayout="column" fxLayoutAlign="space-around stretch">
            <div fxFlex="400px !important" id="{{ detector.movement }}" style="width: 700px !important; height: 400px !important"></div>
            <div fxFlex fxLayout="row" fxLayoutAlign="space-evenly center">
              <div fxFlex align="center" style="margin: 5px">
                <button id="speed_{{ detector.movement }}" mat-raised-button>{{ "TRAFFIC_OBJECT_REPORT.ONLY_ABG_SPEED" | translate }}</button>
              </div>
              <div fxFlex align="center" style="margin: 5px">
                <button id="count_{{ detector.movement }}" mat-raised-button>{{ "TRAFFIC_OBJECT_REPORT.ONLY_COUNT" | translate }}</button>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="!detector.graphItems.length > 0">
          <p>{{ "TRAFFIC_OBJECT_REPORT.NO_DETECTORS_DATA" | translate }}</p>
        </div>
      </div>
      <mat-divider *ngIf="!last"></mat-divider>
      <!-- </ng-container> -->
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <!-- <a id="download" mat-button color="primary"(click)="close()" [disabled]="isDisabled">
  <span >{{ "COMMON.SAVE" | translate }}</span>
    <span> {{ "COMMON.DOWNLOAD" | translate }}</span>
  </a> -->
  <!-- <button mat-button (click)="download()">
    {{ "COMMON.DOWNLOAD" | translate }}
  </button> -->
  <button mat-button (click)="close()">
    {{ "COMMON.CANCEL" | translate }}
  </button>
</mat-dialog-actions>
