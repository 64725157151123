import {
  ChangeDetectorRef,
  ChangeDetectionStrategy,
  Input,
  OnDestroy,
  OnInit,
  Component,
  ViewChild,
  ElementRef,
} from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { forkJoin, of, Subject, timer } from "rxjs";
import { finalize, takeUntil } from "rxjs/operators";

import { LANGS_LOCALES } from "projects/msu-its-web-common/src/utils/langs";

import { FlashService } from "projects/msu-its-web-common/src/services/flash.service";
import { DialogService } from "projects/msu-its-web-common/src/services/dialog.service";
import { SettingsService } from "projects/msu-its-web-common/src/services/settings.service";

import {
  ParkingKioskStatus,
  ParkingKiosk,
  PirsKioskError,
  ParkingKioskMode,
} from "projects/msu-its-web-parking/src/dtos/parking.dtos";

import { ParkingKioskService } from "projects/msu-its-web-parking/src/services/parking-kiosk.service";
import { MsuPirsParkingKioskService } from "projects/msu-its-web-parking/src/services/msu-pirs-parking-kiosk.service";
import { MsuPirsParkingKioskStateService } from "./msu-pirs-parking-kiosk-state.service";
import {
  getImgSrc,
  parkingKioskSvg,
} from "projects/msu-its-web-common/src/utils/map-markers";
import { PIRS_KIOSK_ERROR_TYPES } from "projects/msu-its-web-parking/src/dtos/enums";

@Component({
  selector: "msu-pirs-parking-kiosk-state",
  templateUrl: "./msu-pirs-parking-kiosk-state.component.html",
  styleUrls: ["./msu-pirs-parking-kiosk-state.component.css"],
  providers: [MsuPirsParkingKioskStateService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MsuPirsParkingKioskStateComponent implements OnInit, OnDestroy {
  _destroy = new Subject();

  model: ParkingKiosk;
  status: ParkingKioskStatus;
  modeStatus: "Work" | "Off" | "" = "";

  @ViewChild("stateImage", { static: false })
  stateImage: ElementRef;

  @Input()
  modelId: string;

  @Input()
  modelPermissions: string[];

  @Input()
  customerId: string;

  get loading() {
    return this._stateService.loading;
  }

  loadingMode = false;

  get timezone() {
    return this._settingsService.timezone;
  }
  get locale() {
    return LANGS_LOCALES[this.translate.currentLang];
  }

  get isOffline() {
    return !this.status?.online;
  }
  get isError() {
    return false;
  }
  get errors() {
    return this.status?.status?.errors;
  }

  get isEngineerMode() {
    return this.status?.mode === ParkingKioskMode.Engineer;
  }
  get isWorkMode() {
    return this.status?.mode === ParkingKioskMode.Work;
  }
  get isOffMode() {
    return this.status?.mode === ParkingKioskMode.Off;
  }

  _eventChannelId: string;
  _eventChannelReceivers: any = {};

  constructor(
    private _settingsService: SettingsService,
    private _parkingKioskService: ParkingKioskService,
    private _msuPirsParkingKioskService: MsuPirsParkingKioskService,
    private _stateService: MsuPirsParkingKioskStateService,
    private _dialogService: DialogService,
    private _flashService: FlashService,
    private _changeDetector: ChangeDetectorRef,
    public translate: TranslateService
  ) {}

  ngOnInit() {
    this._stateService.modelId = this.modelId;
    this._stateService.customerId = this.customerId;

    this._stateService.loading$.pipe(takeUntil(this._destroy)).subscribe(() => {
      this._changeDetector.markForCheck();
    });

    this._stateService.status$
      .pipe(takeUntil(this._destroy))
      .subscribe((status) => {
        this.status = status;
        this._updateStateImage();
        this._changeDetector.markForCheck();
      });

    this._stateService.parkingKiosk$
      .pipe(takeUntil(this._destroy))
      .subscribe((model) => {
        this.model = model;
        this._changeDetector.markForCheck();
      });

    this._updateSettings(true);
    this._subscibeStatusEvent();

    timer(30000, 30000)
      .pipe(takeUntil(this._destroy))
      .subscribe(() => this._updateSettings());
  }

  _loadModeStatus(text: string) {
    this.loadingMode = true;
    // console.log("_loadModeStatusStart___" + text, this.loadingMode);
    this._msuPirsParkingKioskService
      .getMode(this.modelId, this.customerId)
      .pipe(
        finalize(() => {
          this.loadingMode = false;
          // console.log("_loadModeStatusFinalize___" + text, this.loadingMode);
        })
      )
      .subscribe((result) => {
        // console.log("_loadModeStatusResult___" + text);
        this.modeStatus = result ? "Off" : "Work";
        this._changeDetector.markForCheck();
      });
  }
  ngOnDestroy() {
    this._destroy.next();
    this._destroy.complete();
    this._destroy = null;
    this._unsubscibeStatusEvent();
  }

  _updateSettings(init = false) {
    if (this._stateService.loading) return;
    if (init) this._stateService.loading = true;
    forkJoin([
      this._parkingKioskService.get(this.modelId, this.customerId),
      init
        ? this._msuPirsParkingKioskService.getStatus(
            this.modelId,
            this.customerId
          )
        : of(null),
    ])
      .pipe(finalize(() => (this._stateService.loading = false)))
      .subscribe((result) => {
        this._stateService.parkingKiosk = result[0];

        // update status if init
        if (init) {
          this._stateService.status = result[1];
          // console.log("_loadModeStatusInitStart", this.loadingMode);
          // result[1].online &&
          //   !this.loadingMode &&
          //   this._loadModeStatus("START");

          this._timerGetStatus();

          result[1].online && !this.loadingMode && this._timerGetMode();
        }

        this._changeDetector.markForCheck();
      });
  }

  _timerGetStatus() {
    this._msuPirsParkingKioskService
      .getStatus(this.modelId, this.customerId)
      .pipe(takeUntil(this._destroy))
      .pipe(
        finalize(() =>
          timer(5 * 1000)
            .pipe(takeUntil(this._destroy))
            .subscribe(() => this._destroy && this._timerGetStatus())
        )
      )
      .subscribe((status) => {
        this._stateService.status = status;
      });
  }

  _timerGetMode() {
    this.loadingMode = true;
    // console.log("_loadModeStatusStart___" + text, this.loadingMode);
    this._msuPirsParkingKioskService
      .getMode(this.modelId, this.customerId)
      .pipe(takeUntil(this._destroy))
      .pipe(
        finalize(() => {
          this.loadingMode = false;
          // console.log("_loadModeStatusFinalize___" + text, this.loadingMode);
          timer(15 * 1000)
            .pipe(takeUntil(this._destroy))
            .subscribe(() => this._destroy && this._timerGetMode());
        })
      )
      .subscribe((result) => {
        // console.log("_loadModeStatusResult___" + text);
        this.modeStatus = result ? "Off" : "Work";
        this._changeDetector.markForCheck();
      });
  }
  _subscibeStatusEvent() {
    // this._eventChannelId = `trafficObjectStatus_${this.modelId}`;
    // this._eventChannelReceivers[this._eventChannelId] = (
    //   value: TrafficObjectStatus
    // ) => {
    //   this._potokStateService.status = value;
    // };
    // this._apiEventService.subscribeServerEvents(
    //   [this._eventChannelId],
    //   this._eventChannelReceivers
    // );
  }

  _unsubscibeStatusEvent() {
    // this._apiEventService.unsubscribeServerEvents(
    //   [this._eventChannelId],
    //   this._eventChannelReceivers
    // );
  }

  _updateStateImage() {
    if (this.stateImage) {
      this.stateImage.nativeElement.src = getImgSrc(
        parkingKioskSvg,
        this.status?.iconStatus.color,
        this.status?.iconStatus.borderColor
      );
    }
  }

  eventLog() {
    const url = `${this._stateService.customerId}/ext/parking-kiosk/${this.model.id}/event-log`;
    this._dialogService.windowDialog(
      url,
      "parkingKioskEventLog_" + this.model.id,
      "menubar=0,toolbar=0,titlebar=0,location=0,locationbar=0,width=950,height=850,resizable=0"
    );
  }

  getErrorName(error: PirsKioskError) {
    const type = PIRS_KIOSK_ERROR_TYPES.find((m) => m.type === error);
    return (type && this.translate.instant(type.name)) ?? error;
  }

  setOffMode() {
    this._stateService
      .loadingQuery(
        this._parkingKioskService.setOffModel(
          this.status.id,
          this._stateService.customerId
        )
      )
      .subscribe(() => {
        this.modeStatus = "Off";
        this._flashService.success(this.translate.instant("COMMAND.SENT"));
      });
  }
  setWorkMode() {
    this._stateService
      .loadingQuery(
        this._parkingKioskService.setWorkModel(
          this.status.id,
          this._stateService.customerId
        )
      )
      .subscribe(() => {
        this.modeStatus = "Work";
        this._flashService.success(this.translate.instant("COMMAND.SENT"));
      });
  }
  // getWorkMode() {
  //   this._stateService
  //     .loadingGetModeQuery(
  //       this._msuPirsParkingKioskService.getMode(
  //         this.status.id,
  //         this._stateService.customerId
  //       )
  //     )
  //     .subscribe((result) => {
  //       console.log("getWorkMode", result);
  //       this.modeStatus = result ? "Off" : "Work";
  //       this._changeDetector.markForCheck();
  //     });
  // }

  toggleGroupChange(event) {
    console.log(event);
    const toggle = event.source;
    if (toggle && this.status) {
      const group = toggle.buttonToggleGroup;
      group.value = this.status.mode;
    }
  }
}
