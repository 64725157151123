import { Injectable } from "@angular/core";

import { Observable, of } from "rxjs";
import { map } from "rxjs/operators";

import {
  IDataSourceService,
  setPageParams,
  getPageReturn,
  IPageParams,
  IPageReturn,
} from "projects/msu-its-web-common/src/data/data-source";
import { PtmApiService } from "../ptm-api.service";
import {
  PriorityEvent,
  GetPublicTransportVehiclePriorityEventsRequest,
  PriorityEventType,
} from "../../dtos/ptm.dtos";

@Injectable()
export class VehicleEventsService
  implements IDataSourceService<PriorityEvent, PriorityEvent> {
  constructor(private _api: PtmApiService) {}

  getPagedList(
    params: IPageParams,
    requestParams: {
      customerId: string;
      onlyActive: boolean;
      eventType?: PriorityEventType;
      startDate?: string;
      endDate?: string;
    }
  ): Observable<IPageReturn<PriorityEvent>> {
    if (!requestParams.customerId) {
      return of(null);
    }
    const request = new GetPublicTransportVehiclePriorityEventsRequest();
    request.customerId = requestParams.customerId;
    request.eventType =  requestParams.eventType || null;
    request.startDate =  requestParams.startDate || '';
    request.endDate =  requestParams.endDate || '';

    setPageParams(request, params);
    return this._api.get(request).pipe(
      map((m) => {
        return getPageReturn(m);
      })
    );
  }
  getList(
    customerId: string,
    filter?: string,
    groupIds?: string[],
    items?: number
  ): Observable<PriorityEvent[]> {
    const request = new GetPublicTransportVehiclePriorityEventsRequest();
    request.customerId = customerId;
    request.entityGroups = groupIds || [];

    request.page = 0;
    request.items = items || 0;
    request.sort = "number";
    request.filter = filter || "";

    return this._api.get(request).pipe(
      map((m) => {
        return m.items;
      })
    );
  }

  get(id: string, customerId: string): Observable<PriorityEvent> {
    throw "not implemented";
  }
  add(
    model: PriorityEvent,
    params: {
      customerId: string;
      groups: string[];
      updateGroups: boolean;
    }
  ): Observable<PriorityEvent> {
    throw "not implemented";
  }
  update(
    model: PriorityEvent,
    params: { customerId: string; groups: string[]; updateGroups: boolean }
  ): Observable<PriorityEvent> {
    throw "not implemented";
  }
  exist(number: string, customerId: string): Observable<boolean> {
    throw "not implemented";
  }

  delete(ids: string[]): Observable<number> {
    throw "not implemented";
  }
}
