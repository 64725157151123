import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
  ViewChild,
} from "@angular/core";
import { marker as _ } from "@biesbjerg/ngx-translate-extract-marker";

import { DialogService } from "projects/msu-its-web-common/src/services/dialog.service";

import { WebCameraComponent } from "projects/msu-its-web-common/src/components/camera/web-camera.component";
import { Observable, of } from "rxjs";
import { TokenService } from "projects/msu-its-web-common/src/services/token.service";
import { PtzCamera } from "../../dtos/tfms.dtos";
import { CameraPTZService } from "../../services/camera-ptz.service";
import { CONTROL_CAMERA } from "../../dtos/enum";
import { FlashService } from "projects/msu-its-web-common/src/services/flash.service";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "camera-ptz-popup",
  templateUrl: "./camera-ptz-popup.component.html",
  styles: [
    `
      :host {
        height: 100% !important;
        width: 100% !important;
        /* padding: 28px; */
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CameraPTZPopupComponent implements OnInit, AfterViewInit {
  @Input()
  modelId: string;

  external: boolean = false;

  @Input()
  customerId: string;

  @Input()
  modelInfo: PtzCamera;

  @Input()
  closeIfNew = true;

  _cameraControl = CONTROL_CAMERA;

  modelPermissions$: Observable<string[]> = of(null);

  @ViewChild("webcamera", { static: false })
  webcamera: WebCameraComponent;

  actions: { name: string; action: string; permissions?: string[] }[] = [
    {
      name: _("COMMON.EDIT"),
      action: "openEdit",
      permissions: ["ptzCamera.update"],
    },
  ];

  openInNew() {
    this._dialogService.windowDialog(
      `${this.customerId}/ext/cameras-ptz/${this.modelId}`,
      "Camera_" + this.modelId,
      "menubar=0,toolbar=0,titlebar=0,location=0,locationbar=0,width=880,height=645,resizable=0"
    );
  }
  openEdit() {
    this._dialogService.windowDialog(
      `${this.customerId}/ext/cameras-ptz/${this.modelId}/edit`,
      "CameraEdit_" + this.modelId,
      "width=940,height=756"
    );
  }

  constructor(
    private _cameraPtzService: CameraPTZService,
    public translate: TranslateService,
    private _changeDetector: ChangeDetectorRef,
    private _flashService: FlashService,
    private _dialogService: DialogService,
    private _tokenService: TokenService
  ) {}

  ngAfterViewInit(): void {
    this._cameraPtzService
      .get(this.modelId, this.customerId)
      .subscribe((result) => {
        this.webcamera.url = result.rtmp;
        this._changeDetector.markForCheck();
      });
    // this.webcamera.url = this.modelInfo.url;
    // this._changeDetector.markForCheck();
  }
  ngOnInit() {
    // console.log("12312312");
    // permissions
    if (this.modelInfo.customerId != this.customerId) {
      this.modelPermissions$ = this._cameraPtzService.getPermissions(
        this.modelId,
        this.customerId
      );
    }
    this._updateActionsByPermissions();
  }

  private _updateActionsByPermissions() {
    this.modelPermissions$.subscribe((m) => {
      this.actions = this.actions.filter((a) =>
        this._tokenService.hasPermissionsForItem(
          this.modelInfo,
          this.customerId,
          a.permissions,
          m
        )
      );
    });
  }

  camControl(move: string) {
    const control = this._cameraControl.find((item) => item.name === move);
    this._flashService.success(this.translate.instant("COMMAND.SUCCESS"));
    this._cameraPtzService
      .camControl(this.modelId, control.command)
      .subscribe((res) => {
        if (res) {
          this._flashService.success(this.translate.instant("COMMAND.SUCCESS"));
        } else {
          this._flashService.success(this.translate.instant("COMMAND.ERROR"));
        }
      });

    this._changeDetector.markForCheck();
  }
}
