import { marker as _ } from "@biesbjerg/ngx-translate-extract-marker";

import { NgModule } from "@angular/core";
import { DatePipe } from "@angular/common";
// import { MatIconRegistry } from '@angular/material/icon';
// import { DomSanitizer } from '@angular/platform-browser';

import { ParkingApiService } from "../../services/parking-api.service";
import { ParkingApiEventService } from "../../services/parking-api-event.service";

import { ParkingService } from "../../services/parking.service";
import { ParkingControllerService } from "../../services/parking-controller.service";
import { ParkingControllerEventLogService } from "../../services/parking-controller-event-log.service";
// import { ParkingEventLogService } from '../../services/parking-event-log.service';
import { ParkingGroupService } from "../../services/parking-group.service";
import { ParkingGroupEventService } from "../../services/parking-group-event.service";
import { ParkingGroupShareService } from "../../services/parking-group-share.service";
// import { ParkingGroupEventLogService } from '../../services/parking-group-event-log.service';

import { ParkingKioskService } from "../../services/parking-kiosk.service";
import { ParkingKioskGroupService } from "../../services/parking-kiosk-group.service";
import { ParkingKioskEventLogService } from "../../services/parking-kiosk-event-log.service";
import { ParkingKioskGroupEventService } from "../../services/parking-kiosk-group-event.service";
import { ParkingKioskGroupShareService } from "../../services/parking-kiosk-group-share.service";
// import { ParkingKioskGroupEventLogService } from '../../services/parking-kiosk-group-event-log.service';

import { ParkingCameraService } from "../../services/parking-cameras/parking-camera.service";
import { ParkingCameraGroupService } from "../../services/parking-cameras/parking-camera-group.service";
import { ParkingCameraGroupShareService } from "../../services/parking-cameras/parking-camera-group-share.service";
import { ParkingCameraGroupEventService } from "../../services/parking-cameras/parking-camera-group-event.service";
import { ParkingCameraEventsService } from "../../services/parking-cameras/parking-camera-events.service";

import { MsuPirsParkingService } from "../../services/msu-pirs-parking.service";
import { MsuPirsParkingKioskService } from "../../services/msu-pirs-parking-kiosk.service";

import { ISidenavMenuCategory } from "projects/msu-its-web-common/src/utils/sidenav-menu";
import { IGisObjectGroup } from "projects/msu-its-web-common/src/utils/gis-object-group";
import {
  GIS_OBJECT_GROUP,
  SIDENAV_MENU,
} from "projects/msu-its-web-common/src/utils/shared-consts";

import { BaseModule } from "projects/msu-its-web-common/src/modules/base.module";

import {
  MsuPirsLegendDialog,
  ParkingStateComponent,
} from "../../components/parkings/parking-state.component";
import { ParkingKioskStateComponent } from "../../components/parking-kiosks/parking-kiosk-state.component";
import { MsuPirsStateComponent } from "../../components/parkings/msu-pirs/state/msu-pirs-state.component";
import { MsuPirsStateSpacesComponent } from "../../components/parkings/msu-pirs/state/msu-pirs-state-spaces.component";
import { MsuPirsStateControllerComponent } from "../../components/parkings/msu-pirs/state/msu-pirs-state-controller.component";
import { MsuPirsParkingKioskStateComponent } from "../../components/parking-kiosks/msu-pirs-parking-kiosk/state/msu-pirs-parking-kiosk-state.component";
import { ParkingCameraPopupComponent } from "../../components/parking-cameras/parking-camera-popup.component";
import { ParkingPopupComponent } from "../../components/parkings/parking-popup.component";

const sidenav: ISidenavMenuCategory[] = [
  {
    name: _("SIDENAV_MENU.PARKING"),
    items: [
      {
        name: _("PARKINGS.TITLE"),
        icon: "local_parking",
        route: "parkings",
        permissions: ["parking.read"],
      },
      {
        name: _("PARKING_KIOSKS.TITLE"),
        icon: "account_balance_wallet",
        route: "parking-kiosks",
        permissions: ["parkingKiosk.read"],
      },
      {
        name: _("PARKING_CAMERAS.TITLE"),
        icon: "videocam",
        route: "parking-cameras",
        permissions: ["parkingCamera.read"],
      },
    ],
  },
];

const parkingGroup: IGisObjectGroup = {
  type: "parking",
  name: _("PARKINGS.TITLE"),
  groupServiceType: ParkingGroupService,
  eventServiceType: ParkingGroupEventService,
  stateComponentType: ParkingStateComponent,
  popupComponentType: ParkingPopupComponent
};

const parkingKioskGroup: IGisObjectGroup = {
  type: "parkingKiosk",
  name: _("PARKING_KIOSKS.TITLE"),
  groupServiceType: ParkingKioskGroupService,
  eventServiceType: ParkingKioskGroupEventService,
  stateComponentType: ParkingKioskStateComponent,
};
const parkingCameraGroup: IGisObjectGroup = {
  type: "parkingCamera",
  name: _("PARKING_CAMERAS.TITLE"),
  groupServiceType: ParkingCameraGroupService,
  eventServiceType: ParkingCameraGroupEventService,
  popupComponentType: ParkingCameraPopupComponent,
  // stateComponentType:
};

@NgModule({
  providers: [
    { provide: SIDENAV_MENU, useValue: sidenav, multi: true },
    { provide: GIS_OBJECT_GROUP, useValue: parkingGroup, multi: true },
    { provide: GIS_OBJECT_GROUP, useValue: parkingKioskGroup, multi: true },
    { provide: GIS_OBJECT_GROUP, useValue: parkingCameraGroup, multi: true },
    ParkingApiService,
    ParkingApiEventService,

    ParkingService,
    // ParkingEventLogService,
    ParkingControllerService,
    ParkingControllerEventLogService,
    ParkingGroupService,
    ParkingGroupEventService,
    ParkingGroupShareService,
    // ParkingGroupEventLogService,

    ParkingKioskService,
    ParkingKioskEventLogService,
    ParkingKioskGroupService,
    ParkingKioskGroupEventService,
    ParkingKioskGroupShareService,
    // ParkingKioskGroupEventLogService,

    ParkingCameraService,
    ParkingCameraGroupService,
    ParkingCameraEventsService,
    ParkingCameraGroupShareService,
    ParkingCameraGroupEventService,

    MsuPirsParkingService,
    MsuPirsParkingKioskService,
    DatePipe,
  ],
  imports: [BaseModule],
  declarations: [
    MsuPirsLegendDialog,
    ParkingStateComponent,
    ParkingKioskStateComponent,
    MsuPirsStateComponent,
    MsuPirsStateSpacesComponent,
    MsuPirsStateControllerComponent,
    MsuPirsParkingKioskStateComponent,
    ParkingCameraPopupComponent,
    // ParkingCameraEventsComponent,
  ],
  entryComponents: [
    ParkingStateComponent,
    MsuPirsLegendDialog,
    MsuPirsStateComponent,
    MsuPirsStateSpacesComponent,
    MsuPirsStateControllerComponent,
    ParkingCameraPopupComponent,
    // ParkingCameraEventsComponent,
  ],
  exports: [
    ParkingStateComponent,
    MsuPirsLegendDialog,
    ParkingKioskStateComponent,
    MsuPirsStateComponent,
    ParkingCameraPopupComponent,
    // ParkingCameraEventsComponent,
  ],
})
export class ParkingSharedModule {
  // constructor(private _matIconRegistry: MatIconRegistry, private _domSanitizer: DomSanitizer) {
  //    this._registerIcon('parking-route', '/assets/images/tlc/icons/route.svg');
  // }
  // private _registerIcon(name: string, url: string) {
  //   this._matIconRegistry.addSvgIcon(name, this._domSanitizer.bypassSecurityTrustResourceUrl(url));
  // }
}
