import { Injectable } from '@angular/core';

import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import {
  IDataSourceService,
  setPageParams,
  getPageReturn,
  IPageParams,
  IPageReturn,
} from 'projects/msu-its-web-common/src/data/data-source';

import {
  CreatePanelRequest,
  DeletePanelsRequest,
  GetPanelAvailableSchedulesRequest,
  GetPanelDayBrightnessSettingsRequest,
  GetPanelExistRequest,
  GetPanelRequest,
  GetPanelScheduledBrightnessSettingsRequest,
  GetPanelShowSettingsRequest,
  GetPanelsRequest,
  Panel,
  PanelDayBrightnessSettings,
  PanelProgram,
  PanelPrograms,
  PanelSchedule,
  PanelScheduledBrightnessSettings,
  PanelSchedules,
  PanelShowSettings,
  SetPanelOffModeRequest,
  SetPanelScheduledBrightnessSettingsRequest,
  SetPanelWorkModeRequest,
  UpdatePanelRequest,
  UpdatePanelShowSettingsRequest,
  UnbindControllerFromPanelRequest,
} from '../../dtos/ruis.dtos';

import { RuisApiService } from '../ruis-api.service';

@Injectable()
export class PanelService implements IDataSourceService<Panel, Panel> {
  constructor(private _api: RuisApiService) {}

  getPagedList(params: IPageParams, customerId: string): Observable<IPageReturn<Panel>> {
    if (!customerId) {
      return of(null);
    }

    const request = new GetPanelsRequest();
    request.customerId = customerId;

    setPageParams(request, params);
    return this._api.get(request).pipe(
      map((m) => {
        return getPageReturn(m);
      })
    );
  }
  getList(
    customerId: string,
    filter?: string,
    groupIds?: string[],
    items?: number
  ): Observable<Panel[]> {
    const request = new GetPanelsRequest();
    request.customerId = customerId;
    request.entityGroups = groupIds || [];

    request.page = 0;
    request.items = items || 0;
    request.sort = 'number';
    request.filter = filter || '';

    return this._api.get(request).pipe(
      map((m) => {
        return m.items;
      })
    );
  }

  get(id: string, customerId: string) {
    const request = new GetPanelRequest({ id, customerId });
    return this._api.get(request);
  }
  add(
    model: Panel,
    params: {
      customerId: string;
      groups: string[];
      updateGroups: boolean;
    }
  ) {
    const request = new CreatePanelRequest();
    request.entity = model;
    request.customerId = params.customerId;
    request.entityGroups = params.groups;
    request.updateEntityGroups = params.updateGroups;
    return this._api.post(request);
  }
  update(model: Panel, params: { customerId: string; groups: string[]; updateGroups: boolean }) {
    const request = new UpdatePanelRequest();
    request.id = model.id;
    request.entity = model;
    request.customerId = params.customerId;
    request.entityGroups = params.groups;
    request.updateEntityGroups = params.updateGroups;
    return this._api.put(request);
  }
  delete(ids: string[], customerId: string) {
    const request = new DeletePanelsRequest();
    request.ids = ids;
    request.customerId = customerId;
    return this._api.delete(request);
  }
  exist(name: string, customerId: string): Observable<boolean> {
    const request = new GetPanelExistRequest({
      name,
      customerId,
    });
    return this._api.get(request);
  }

  setOffModel(id: string, customerId: string) {
    const request = new SetPanelOffModeRequest({ id, customerId });
    return this._api.post(request);
  }
  setWorkModel(id: string, customerId: string, scheduleId: string) {
    const request = new SetPanelWorkModeRequest({ id, customerId, scheduleId });
    return this._api.post(request);
  }

  getSettings(id: string, customerId: string) {
    const request = new GetPanelShowSettingsRequest({ id, customerId });
    return this._api.get(request).pipe(map((m) => this.assignSettings(m)));
  }
  updateSettings(id: string, customerId: string, settings: PanelShowSettings) {
    const request = new UpdatePanelShowSettingsRequest({ id, customerId, entity: settings });
    return this._api.put(request);
  }

  assignSettings(settings: PanelShowSettings) {
    const result = settings || new PanelShowSettings();

    const programs = new PanelPrograms({
      program01: new PanelProgram({ frames: [] }),
      program02: new PanelProgram({ frames: [] }),
      program03: new PanelProgram({ frames: [] }),
      program04: new PanelProgram({ frames: [] }),
      program05: new PanelProgram({ frames: [] }),
      program06: new PanelProgram({ frames: [] }),
      program07: new PanelProgram({ frames: [] }),
      program08: new PanelProgram({ frames: [] }),
      program09: new PanelProgram({ frames: [] }),
      program10: new PanelProgram({ frames: [] }),
      program11: new PanelProgram({ frames: [] }),
      program12: new PanelProgram({ frames: [] }),
      program13: new PanelProgram({ frames: [] }),
      program14: new PanelProgram({ frames: [] }),
      program15: new PanelProgram({ frames: [] }),
      program16: new PanelProgram({ frames: [] }),
      program17: new PanelProgram({ frames: [] }),
      program18: new PanelProgram({ frames: [] }),
      program19: new PanelProgram({ frames: [] }),
      program20: new PanelProgram({ frames: [] }),
      program21: new PanelProgram({ frames: [] }),
      program22: new PanelProgram({ frames: [] }),
      program23: new PanelProgram({ frames: [] }),
      program24: new PanelProgram({ frames: [] }),
    });
    if (result.programs) {
      Object.keys(programs).forEach((k) => {
        result.programs[k] = Object.assign(programs[k], result.programs[k]);
      });
    } else {
      result.programs = programs;
    }

    const schedules = new PanelSchedules({
      schedule01: new PanelSchedule(),
      schedule02: new PanelSchedule(),
      schedule03: new PanelSchedule(),
      schedule04: new PanelSchedule(),
      schedule05: new PanelSchedule(),
      schedule06: new PanelSchedule(),
      schedule07: new PanelSchedule(),
      schedule08: new PanelSchedule(),
      schedule09: new PanelSchedule(),
      schedule10: new PanelSchedule(),
      schedule11: new PanelSchedule(),
      schedule12: new PanelSchedule(),
      schedule13: new PanelSchedule(),
      schedule14: new PanelSchedule(),
      schedule15: new PanelSchedule(),
      schedule16: new PanelSchedule(),
      schedule17: new PanelSchedule(),
      schedule18: new PanelSchedule(),
      schedule19: new PanelSchedule(),
      schedule20: new PanelSchedule(),
      schedule21: new PanelSchedule(),
      schedule22: new PanelSchedule(),
      schedule23: new PanelSchedule(),
      schedule24: new PanelSchedule(),
    });
    if (result.schedules) {
      Object.keys(schedules).forEach((k) => {
        result.schedules[k] = Object.assign(schedules[k], result.schedules[k]);
      });
    } else {
      result.schedules = schedules;
    }

    return result;
  }

  getScheduledBrightnessSettings(id: string, customerId: string) {
    const request = new GetPanelScheduledBrightnessSettingsRequest({ id, customerId });
    return this._api.get(request);
  }
  updateScheduledBrightnessSettings(
    id: string,
    customerId: string,
    settings: PanelScheduledBrightnessSettings
  ) {
    const request = new SetPanelScheduledBrightnessSettingsRequest({ id, customerId, settings });
    return this._api.post(request);
  }

  getDayBrightnessSettings(id: string, customerId: string, day: number, month: number) {
    const request = new GetPanelDayBrightnessSettingsRequest({ id, customerId, day, month });
    return this._api.get(request);
  }
  updateDayBrightnessSettings(
    id: string,
    customerId: string,
    settings: PanelDayBrightnessSettings
  ) {
    return of(settings);
    // const request = new UpdatePanelDayBrightnessSettingsRequest({ id, customerId, settings });
    // return this._api.post(request);
  }

  getAvailableSchedules(id: string, customerId: string) {
    const request = new GetPanelAvailableSchedulesRequest({ id, customerId });
    return this._api.get(request);
  }

  UnbindControllerFromPanel(model: Panel, customerId: string) {
    const request = new UnbindControllerFromPanelRequest();
    request.id = model.id;
    request.entity = model;
    request.customerId = customerId;
    return this._api.put(request);
  }
  
}
