<h3 [style.margin]="'0 12px 0 0'">
  <ng-content select="[title]"></ng-content>
</h3>

<div
  fxLayout
  fxLayoutGap="16px"
  fxLayoutAlign="center center"
  style="height: 40px;"
>
  <ng-content select="[actions]"></ng-content>
</div>
