<mat-card style="width: 275px; max-width: 275px; padding: 48px 32px 16px">
  <div *ngIf="submitted" class="backdrop"></div>
  <mat-card-content>
    <!--
    <div style="margin-bottom: 40px; text-align: center;">
      <img
        *ngIf="darkTheme"
        src="/assets/images/logo_dark.png"
        style="height: 20px;"
      />
      <img
        *ngIf="!darkTheme"
        src="/assets/images/logo.png"
        style="height: 20px;"
      />
    </div>
    -->
    <form [formGroup]="formGroup" (ngSubmit)="submit()">
      <div style="margin-bottom: 24px">
        <div style="display: flex; flex-direction: column">
          <mat-form-field style="margin-bottom: 10px">
            <input
              matInput
              [formControl]="username"
              placeholder="{{ 'COMMON.USERNAME' | translate }}"
              #usernameInput="matInput"
              autocomplete="off"
              spellcheck="false"
            />
            <mat-error>
              {{ "COMMON.USERNAME_REQUIRED" | translate }}
            </mat-error>
            <mat-icon matSuffix class="field-icon" style="cursor: default">
              person
            </mat-icon>
          </mat-form-field>

          <mat-form-field style="margin-bottom: 16px">
            <input
              matInput
              [formControl]="password"
              placeholder="{{ 'USERS.PASSWORD' | translate }}"
              [type]="passwordHide ? 'password' : 'text'"
              autocomplete="new-password"
            />
            <mat-error>
              {{ "USERS.PASSWORD_REQUIRED" | translate }}
            </mat-error>
            <mat-icon
              matSuffix
              class="field-icon"
              style="cursor: pointer"
              (click)="passwordHide = !passwordHide"
            >
              {{ passwordHide ? "visibility_off" : "visibility" }}
            </mat-icon>
          </mat-form-field>
        </div>
        <div>
          <mat-checkbox [formControl]="remember" disableRipple="true">
            {{ "USERS.REMEMBER_ME" | translate }}
          </mat-checkbox>
        </div>
      </div>
      <div>
        <div [hidden]="submitted">
          <button
            type="submit"
            mat-flat-button
            color="primary"
            style="width: 100%"
            [disabled]="submitted"
          >
            {{ "USERS.SIGN_IN" | translate }}
          </button>
        </div>
        <div [hidden]="!submitted">
          <mat-spinner diameter="36" style="margin: 0 auto"></mat-spinner>
        </div>
      </div>
    </form>

    <div>
      <mat-error class="mat-small" style="margin-top: 16px; text-align: center">
        {{ getErrorMessage() }}
      </mat-error>
    </div>

    <div class="lang-block">
      <button mat-button class="lang-button mat-small" (click)="selectLang()">
        <mat-icon style="font-size: 14px; line-height: 22px">
          language
        </mat-icon>
        <span>
          {{ "COMMON.LANGUAGE" | translate }}: {{ getCurrentLangName() }}
        </span>
      </button>
    </div>
  </mat-card-content>
</mat-card>

<mat-slide-toggle
  class="dark-theme-slide"
  [checked]="darkTheme"
  (change)="setDarkTheme()"
>
  {{ "COMMON.DARK_THEME" | translate }}
</mat-slide-toggle>
