import { marker as _ } from "@biesbjerg/ngx-translate-extract-marker";

import {
  ApplicationRef,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ComponentFactoryResolver,
  ComponentRef,
  EventEmitter,
  Injector,
  Input,
  OnDestroy,
  OnInit,
} from "@angular/core";
import { merge, Observable, of, Subject } from "rxjs";
import { finalize, map, switchMap, takeUntil } from "rxjs/operators";

import { DialogService } from "projects/msu-its-web-common/src/services/dialog.service";
import { TRAFFIC_ACCIDENT_TYPE, itemArray, itemArray2 } from "../../dtos/enum";

declare const L;

import "leaflet";
import "leaflet.markercluster";
import "heatmap.js";
import "leaflet.heat/dist/leaflet-heat.js";
import { IGisObject } from "projects/msu-its-web-common/src/utils/gis-object-group";
import { FormControl } from "@angular/forms";
import { MatSlideToggleChange } from "@angular/material/slide-toggle";
import { TrafficAccidentService } from "../../services/traffic-accident/traffic-accident.service";
import {
  AccidentType,
  TrafficAccidentLocation,
} from "../../dtos/accident.dtos";
import { TrafficAccidentPopupComponent } from "../traffic-accident/traffic-accident-popup.component";

@Component({
  selector: "traffic-accident-heatmap",
  templateUrl: "./traffic-accident-heatmap.component.html",
  styles: [
    `
      .date-button {
        pointer-events: none;
        font-size: 16px;
        position: absolute;
        right: 0;
        width: 20px;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  // providers: [RouteEscortStateService],
})
export class TrafficAccidentHeatmapComponent implements OnDestroy, OnInit {
  _destroy = new Subject();
  loading = false;
  @Input()
  customerId: string;

  @Input()
  map: any;

  @Input()
  mapLayers: Map<string, any>;

  _popups = new Map<string, { popup: any; componentRef: ComponentRef<any> }>();

  _canvasLayerHeatMap: any;
  _clusterMarker;

  types = TRAFFIC_ACCIDENT_TYPE;
  fromControl = new FormControl();
  toControl = new FormControl();
  accidentTypeControl = new FormControl();
  allInjuredKilled = new FormControl(true);
  hasKilled = new FormControl(3);
  hasInjured = new FormControl(3);
  showClusterMarker = new FormControl(false);

  data: TrafficAccidentLocation[] = [];

  constructor(
    private _appRef: ApplicationRef,
    private _cfr: ComponentFactoryResolver,
    private _injector: Injector,

    private _dialogService: DialogService,
    private _changeDetector: ChangeDetectorRef,
    private _trafficAccidentService: TrafficAccidentService
  ) {
    this.fromControl.valueChanges.subscribe((value) => {
      if (value) {
        this._loadData();
      }
    });
    this.toControl.valueChanges.subscribe((value) => {
      if (value) {
        this._loadData();
      }
    });
    this.accidentTypeControl.valueChanges.subscribe(() => {
      this._loadData();
    });
    this.hasKilled.valueChanges.subscribe((value) => {
      this._loadData();
    });
    this.hasInjured.valueChanges.subscribe((value) => {
      this._loadData();
    });
    this.showClusterMarker.valueChanges.subscribe((value) => {
      if (value) {
        this._updateCluster(this.data);
      } else {
        this._removeCluster();
      }
    });
  }

  ngOnInit() {
    const date = new Date();
    const month = "0" + (date.getMonth() + 1);
    this.fromControl.setValue(date.getFullYear() + "-" + month.slice(-2));
    this.toControl.setValue(date.getFullYear() + "-" + month.slice(-2));
    this._changeDetector.markForCheck();
    // this._loadData();
  }

  resetDataPicker() {
    this.fromControl.setValue(null);
    this.toControl.setValue(null);
    this._loadData();
  }
  ngOnDestroy() {
    this._clusterMarker && this._removeCluster();
    this._removeHeatMap();
    this._popups &&
      this._popups.forEach((m) => {
        m.componentRef.destroy();
        m.popup.close();
      });
    this._destroy.next();
    this._destroy.complete();
    this._destroy = null;
  }

  _loadData() {
    this.loading = true;
    const tempFrom = new Date(this.fromControl.value);
    const tempTo = new Date(this.toControl.value);
    function getLastDayOfMonth(year, month) {
      let date = new Date(year, month + 1, 0);
      return date.getDate();
    }
    this._trafficAccidentService
      .getLocationComponent(
        this.customerId,
        this.fromControl.value
          ? new Date(
              tempFrom.getFullYear(),
              tempFrom.getMonth(),
              tempFrom.getDate()
            ).toISOString()
          : null,
        this.toControl.value
          ? new Date(
              tempTo.getFullYear(),
              tempTo.getMonth(),
              getLastDayOfMonth(tempTo.getFullYear(), tempTo.getMonth()) + 1
            ).toISOString()
          : null,
        this.accidentTypeControl.value,
        this.hasKilled.value === 3 ? null : this.hasKilled.value,
        this.hasInjured.value === 3 ? null : this.hasInjured.value
      )
      .pipe(
        finalize(() => {
          this.loading = false;
          this._changeDetector.markForCheck();
        })
      )
      .subscribe((item) => {
        this.data = item;
        this.showClusterMarker.value && this._updateCluster(item);
        this._updateHeatMap(item);
        this.loading = false;
      });
  }

  _updateCluster(array: TrafficAccidentLocation[]) {
    if (this._clusterMarker) {
      this._clusterMarker.clearLayers();
      array.forEach((m) => {
        const tempMarker = L.marker([m.lat, m.lon]).on("click", (e) => {
          L.DomEvent.stop(e);
          this._openObjectPopupComponent(m);
        });
        this._clusterMarker.addLayer(tempMarker);
        // this._clusterMarker.addLayer(L.marker([m.lat, m.lon]));
      });
    } else {
      this._clusterMarker = L.markerClusterGroup();
      array.forEach((m) => {
        const tempMarker = L.marker([m.lat, m.lon]).on("click", (e) => {
          L.DomEvent.stop(e);
          this._openObjectPopupComponent(m);
        });
        this._clusterMarker.addLayer(tempMarker);
      });
      this._clusterMarker.addTo(this.map);
    }
    this._changeDetector.markForCheck();
  }

  _updateHeatMap(array: TrafficAccidentLocation[]) {
    if (this._canvasLayerHeatMap) {
      const temp = array.map((item) => [item.lat, item.lon]);
      this._canvasLayerHeatMap.setLatLngs(temp);
    } else {
      const temp = array.map((item) => [item.lat, item.lon]);
      this._canvasLayerHeatMap = L.heatLayer(temp, {
        radius: 35,
      });
      this._canvasLayerHeatMap.addTo(this.map);
    }
    this._changeDetector.markForCheck();
  }

  _removeHeatMap() {
    this.map.removeLayer(this._canvasLayerHeatMap);
  }

  _removeCluster() {
    this._popups.forEach((m) => {
      m.componentRef.destroy();
      m.popup.close();
    });
    this.map.removeLayer(this._clusterMarker);
    this._clusterMarker = null;
  }

  _openObjectPopupComponent(item: TrafficAccidentLocation, popupOptions?: any) {
    if (this._popups.has(item.id)) return;

    const componentRef = this._cfr
      .resolveComponentFactory(TrafficAccidentPopupComponent)
      .create(this._injector);
    this._appRef.attachView(componentRef.hostView);

    componentRef.instance.modelId = item.id;
    componentRef.instance.customerId = this.customerId;

    componentRef.instance.close = () => {
      setTimeout(() => componentRef.destroy(), 250);
      this._popups.delete(item.id);
      popup.remove();
    };
    const popup = L.popup({
      offset: [0, 0],
      autoPan: true,
      autoPanPadding: [50, 50],
      closeOnClick: false,
      maxWidth: 700,
      minWidth: 700,
      maxHeight: 600,
      closeButton: false,
      autoClose: false,
      keepInView: false,
      className: "popup-label",
    })
      .setContent(componentRef.location.nativeElement)
      .setLatLng([item.lat, item.lon]);

    this.map.openPopup(popup);

    this._popups.set(item.id, { popup, componentRef });

    componentRef.instance.onMouseOver.subscribe(() => {
      this._popupBringToFront(popup);
    });
  }

  _popupBringToFront(devicePopup) {
    this._popups.forEach((n) => {
      n.popup._container.style.zIndex = "0";
    });
    devicePopup._container.style.zIndex = "100";
    this._changeDetector.markForCheck();
    // this._popups.forEach((n) => {
    //   zIndex.push(parseInt(n.popup._container.style.zIndex) || 0);
    // });

    // devicePopup._container.style.zIndex = Math.max.apply(Math, zIndex) + 1;
    // this._changeDetector.markForCheck();
  }
}
