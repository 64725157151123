<div *ngIf="loading" class="backdrop">
  <mat-spinner diameter="36" color="accent"></mat-spinner>
</div>

<h4 mat-dialog-title>
  <span *ngIf="isNew">{{ "GROUPS.CREATE_GROUP" | translate }}</span>
  <span *ngIf="!isNew">{{ "GROUPS.EDIT_GROUP" | translate }}</span>
</h4>
<mat-dialog-content style="flex: 1;">
  <form [formGroup]="formGroup" (ngSubmit)="submit()" autocomplete="off">
    <div fxLayout="column" fxLayoutGap="12px" style="min-width: 900px;">
      <mat-form-field style="width: 100%;">
        <input
          matInput
          [formControl]="name"
          placeholder="{{ 'COMMON.NAME' | translate }}"
          autocomplete="off"
          maxlength="100"
        />
        <mat-hint>{{ "GROUPS.NAME_HINT" | translate }}</mat-hint>
        <mat-error
          *ngIf="name.hasError('minlength') || name.hasError('maxlength')"
        >
          {{ "GROUPS.NAME_LENGTH" | translate: { min: 4, max: 100 } }}
        </mat-error>
        <mat-error *ngIf="name.hasError('required')">
          {{ "GROUPS.NAME_REQUIRED" | translate }}
        </mat-error>
        <mat-error *ngIf="name.hasError('exist')">
          {{ "GROUPS.NAME_EXIST" | translate }}
        </mat-error>
      </mat-form-field>

      <mat-form-field>
        <input
          matInput
          [formControl]="description"
          placeholder="{{ 'COMMON.DESCRIPTION' | translate }}"
          type="text"
          autocomplete="off"
          spellcheck="false"
        />
      </mat-form-field>

      <div fxLayout="row" fxLayoutGap="12px" class="boxSelectItem">
        <div fxFlex>
          <div class="mat-caption">
            {{ "GROUPS.ALL_OBJETCS" | translate }}
            <span class="mat-secondary-color" style="float: right;">
              ({{clickedItems.length}}/{{ freeItems.length }})
            </span>
          </div>
          <div class="mat-border selection-list-container" fxLayout="column">
            <div class="mat-theme-bg search-box">
              <mat-icon style="margin: 0 4px;">search</mat-icon>
              <input
                matInput
                placeholder="{{ 'COMMON.SEARCH' | translate | lowercase }}"
                style="flex: 1;"
                [value]="freeFilter"
                (input)="searchFreeSubject.next($event.target.value)"
              />
            </div>
            <div style="flex: 1; overflow: overlay;">
              <mat-selection-list
                #freeList
                style="padding: 0;"
                (selectionChange)="onGroupsChange(freeList.selectedOptions.selected)"
              >
                <mat-list-option
                  disableRipple
                  (dblclick)="selectById(item.id, freeList.selectedOptions.selected)"
                  *ngFor="let item of freeItems"
                  [value]="item.id"
                >
                  <span [title]="item.name">{{ item.name }}</span>
                </mat-list-option>
              </mat-selection-list>
            </div>
          </div>
        </div>

        <div
          fxFlex="90px"
          fxLayout="column"
          fxLayoutGap="12px"
          fxLayoutAlign="ceneter center"
          style="padding-top: 100px;"
        >
          <button
            type="button"
            [disabled]="freeItems.length == 0"
            mat-raised-button
            (click)="select()"
            title="{{ 'GROUPS.MOVE_ALL' | translate }}"
            style="min-width: 40px; width: 56px;"
          >
            <div style="transform: rotate(180deg); display: inline-flex;">
              <mat-icon style="margin-right: -20px;">chevron_left</mat-icon>
              <mat-icon style="margin-left: -10px;">chevron_left</mat-icon>
            </div>
          </button>
          <button
            type="button"
            [disabled]="freeList.selectedOptions.selected.length == 0"
            title="{{ 'GROUPS.MOVE_SELECTED' | translate }}"
            (click)="select(freeList.selectedOptions.selected)"
            mat-raised-button
            style="min-width: 40px;"
          >
            <div style="transform: rotate(180deg); display: inline-flex;">
              <mat-icon>chevron_left</mat-icon>
            </div>
          </button>
          <button
            type="button"
            [disabled]="selectedList.selectedOptions.selected.length == 0"
            title="{{ 'GROUPS.MOVE_SELECTED' | translate }}"
            (click)="unselect(selectedList.selectedOptions.selected)"
            mat-raised-button
            style="min-width: 40px;"
          >
            <mat-icon>chevron_left</mat-icon>
          </button>
          <button
            type="button"
            [disabled]="selectedItems.length == 0"
            title="{{ 'GROUPS.MOVE_ALL' | translate }}"
            (click)="unselect()"
            mat-raised-button
            style="min-width: 40px; width: 56px;"
          >
            <mat-icon style="margin-right: -20px;">chevron_left</mat-icon>
            <mat-icon style="margin-left: -10px;">chevron_left</mat-icon>
          </button>
        </div>

        <div fxFlex>
          <div
            class="mat-caption"
            [class.mat-error]="items.hasError('arrayLength')"
          >
            {{ "GROUPS.GROUP_OBJETCS" | translate }}
            <span class="mat-secondary-color" style="float: right;">
              ({{ selectedItems.length }})
            </span>
          </div>

          <div class="mat-border selection-list-container">
            <mat-selection-list #selectedList style="padding: 0;">
              <mat-list-option
                disableRipple
                (dblclick)="unselectById(item.id)"
                *ngFor="let item of selectedItems"
                [value]="item.id"
              >
                <div title="{{ item.name }}">
                  {{ item.name }}
                </div>
              </mat-list-option>
            </mat-selection-list>
          </div>
        </div>
      </div>
    </div>
  </form>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button (click)="submit()" color="primary">
    <span *ngIf="isNew">{{ "COMMON.CREATE" | translate }}</span>
    <span *ngIf="!isNew">{{ "COMMON.SAVE" | translate }}</span>
  </button>
  <button mat-button (click)="close()">
    {{ "COMMON.CANCEL" | translate }}
  </button>
</mat-dialog-actions>
