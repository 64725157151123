import {
  Component,
  ViewChild,
  HostListener,
  ChangeDetectorRef,
  Injector,
  AfterViewInit,
  Inject,
  OnDestroy,
} from '@angular/core';

import { MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';

import { Subject, Observable, EMPTY } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';

import { IDataSourceService, DataSource } from '../../data/data-source';
import { DataSourceForm } from '../../data/data-source-form';

import {
  TableLayoutComponent,
  ITableLayoutRowAction,
} from './table-layout.component';

import { FlashService } from '../../services/flash.service';
import { DialogService } from '../../services/dialog.service';
import { SettingsService } from '../../services/settings.service';
import { TableLayoutDialogComponent } from './table-layout-dialog.component';

@Component({
  selector: 'table-base',
  template: '',
})
export abstract class TableBaseComponent<T, TInfo>
  implements AfterViewInit, OnDestroy {
  destroy = new Subject();

  abstract displayedColumns: string[];
  abstract rowActions: ITableLayoutRowAction[];

  dataSource: DataSource<TInfo>;

  @ViewChild(TableLayoutComponent, { static: false })
  tableLayout: TableLayoutComponent<TInfo>;

  @ViewChild(TableLayoutDialogComponent, { static: false })
  tableLayoutDialog: TableLayoutComponent<TInfo>;

  @HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent) {
    if (event.ctrlKey && event.key === 'ArrowRight') {
      this.layout.paginator.nextPage();
    }
    if (event.ctrlKey && event.key === 'ArrowLeft') {
      this.layout.paginator.previousPage();
    }
  }

  get layout() {
    return this.tableLayout || this.tableLayoutDialog;
  }

  protected dataService: IDataSourceService<T, TInfo>;
  protected flashService: FlashService;
  protected dialogService: DialogService;
  protected settingsService: SettingsService;
  protected changeDetector: ChangeDetectorRef;
  protected translate: TranslateService;

  constructor(
    injector: Injector,
    @Inject(null) dataService: IDataSourceService<T, TInfo>
  ) {
    this.dataService = dataService;
    this.flashService = injector.get(FlashService);
    this.dialogService = injector.get(DialogService);
    this.settingsService = injector.get(SettingsService);
    this.translate = injector.get(TranslateService);
    this.changeDetector = injector.get(ChangeDetectorRef);

    this.dataSource = new DataSource<TInfo>(
      this.dataService,
      this.settingsService
    );
  }

  ngOnDestroy() {
    this.dataSource.destroy();
  }

  ngAfterViewInit() {
    this.layout.rowActionClick
      .pipe(takeUntil(this.destroy))
      .subscribe((item) => {
        this.runAction(item.action, item.id);
      });

    setTimeout(() => {
      this.dataSource.reloadData();
    });
  }

  abstract msgAddSaved: string;
  abstract msgEditSaved: string;
  abstract msgDeleteConfirm: string;
  abstract msgDeleteSuccess: string;
  abstract msgDeleteSelectedConfirm: string;
  abstract msgDeleteSelectedSuccess: string;

  afterAdded() {}
  afterEdited() {}
  afterDeleted() {}
  afterDeletedSelected() {}

  abstract getNew(): T;
  abstract getEdit(id): Observable<T>;
  abstract getEditDialog(): MatDialogRef<DataSourceForm<T>, any>;

  private _editDialog(model: T, isNew: boolean) {
    const dialog = this.getEditDialog();
    dialog.componentInstance.initForm(model, this.dataService, isNew, dialog);
    return dialog;
  }

  add() {
    this._editDialog(this.getNew(), true)
      .afterClosed()
      .subscribe((result) => {
        if (result) {
          this.flashService.success(this.translate.instant(this.msgAddSaved));
          this.dataSource.reloadData();
          this.afterAdded();
        }
      });
  }

  edit(id) {
    this.getEdit(id).subscribe((model) => {
      if (!model) {
        return;
      }
      this._editDialog(model, false)
        .afterClosed()
        .subscribe((result) => {
          if (result) {
            this.flashService.success(
              this.translate.instant(this.msgEditSaved)
            );
            this.dataSource.reloadData();
            this.afterEdited();
          }
        });
    });
  }

  delete(id) {
    this.dialogService
      .confirm(this.translate.instant(this.msgDeleteConfirm))
      .subscribe((confirm) => {
        if (!confirm) {
          return;
        }
        this.dataSource.loading.next(true);
        this.dataService
          .delete([id], this.dataSource.requestParams)
          .pipe(finalize(() => this.dataSource.loadingComplete()))
          .subscribe(() => {
            this.flashService.success(
              this.translate.instant(this.msgDeleteSuccess)
            );
            this.dataSource.reloadData();
            this.afterDeleted();
          });
      });
  }

  deleteSelected() {
    this.dialogService
      .confirm(this.translate.instant(this.msgDeleteSelectedConfirm))
      .subscribe((confirm) => {
        if (!confirm) {
          return;
        }
        this.dataSource.loading.next(true);
        this.dataService
          .delete(
            this.dataSource.selection.selected,
            this.dataSource.requestParams
          )
          .pipe(finalize(() => this.dataSource.loadingComplete()))
          .subscribe(() => {
            this.flashService.success(
              this.translate.instant(this.msgDeleteSelectedSuccess)
            );
            this.dataSource.reloadData();
            this.afterDeletedSelected();
          });
      });
  }

  runAction(action: string, id) {
    if (this[action]) {
      this[action](id);
    }
  }

  loadingQuery<T>(query: Observable<T>) {
    if (this.dataSource.loading.value) {
      return EMPTY;
    }
    this.dataSource.loading.next(true);
    this.changeDetector.markForCheck();
    return query.pipe(
      finalize(() => {
        this.dataSource.loadingComplete();
        this.changeDetector.markForCheck();
      })
    );
  }
}
