const DEFAULT_CODE = '+7';

export function phoneMask(
  value: string,
  returnCodeIfEmpty = true,
  code = DEFAULT_CODE
) {
  if (!value) return value;

  let resultValue = value.replace(RegExp('^\\' + code), '').replace(/\D/g, '');

  if (resultValue.length === 0) {
    resultValue = '';
  } else if (resultValue.length <= 3) {
    resultValue = resultValue.replace(/^(\d{0,3})/, '$1');
  } else if (resultValue.length <= 6) {
    resultValue = resultValue.replace(/^(\d{0,3})(\d{0,3})/, '$1-$2');
  } else if (resultValue.length <= 8) {
    resultValue = resultValue.replace(
      /^(\d{0,3})(\d{0,3})(\d{0,2})/,
      '$1-$2-$3'
    );
  } else {
    resultValue = resultValue.substring(0, 10);
    resultValue = resultValue.replace(
      /^(\d{0,3})(\d{0,3})(\d{0,2})(\d{0,2})/,
      '$1-$2-$3-$4'
    );
  }
  return resultValue ? code + '-' + resultValue : returnCodeIfEmpty ? code : '';
}

export function phoneNumber(value: string, code = DEFAULT_CODE) {
  let resultValue = value?.replace(RegExp('^\\' + code), '').replace(/\D/g, '');
  return resultValue ? code + resultValue : '';
}

export function phonePattern(code = DEFAULT_CODE) {
  return RegExp('^\\' + code + '-\\d{3}-\\d{3}-\\d{2}-\\d{2}$');
}
