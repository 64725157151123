<table-layout
  [dataSource]="dataSource"
  [displayedColumns]="displayedColumns"
  [rowActions]="rowActions"
  titleText="{{ 'EXTERNAL_SYSTEMS.TITLE' | translate }}"
  addText="{{ 'EXTERNAL_SYSTEMS.CREATE_EXTERNAL_SYSTEM' | translate }}"
  deleteText="{{ 'COMMON.DELETE' | translate }}"
  (addBtnClick)="add()"
  (deleteBtnClick)="deleteSelected()"
  (rowDblClick)="edit($event)"
>
  <ng-container table-layout-actions-end>
    <button mat-button (click)="close()">{{ "COMMON.CLOSE" | translate }}</button>
  </ng-container>

  <ng-container ngPackageAs="table-layout-columns" matSort>
    <ng-container matColumnDef="name">
      <mat-header-cell *matHeaderCellDef mat-sort-header>
        {{ "COMMON.NAME" | translate }}
      </mat-header-cell>
      <mat-cell *matCellDef="let item">
        <b>{{ item.name }}</b>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="url">
      <mat-header-cell *matHeaderCellDef mat-sort-header>
        {{ "EXTERNAL_SYSTEMS.URL" | translate }}
      </mat-header-cell>
      <mat-cell *matCellDef="let item">
        {{ item.url }}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="userName">
      <mat-header-cell *matHeaderCellDef mat-sort-header>
        {{ "EXTERNAL_SYSTEMS.USER_NAME" | translate }}
      </mat-header-cell>
      <mat-cell *matCellDef="let item">
        {{ item.userName }}
      </mat-cell>
    </ng-container>
  </ng-container>
</table-layout>
