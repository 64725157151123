<div style="padding: 0 16px">
  <div fxLayout="column" fxLayoutGap="16px">
    <div fxLayout="row" fxLayoutGap="16px">
      <div fxLayout="column" style="flex: 4">
        <mat-button-toggle-group class="mode-toggle-group" appearance="standard" [value]="status?.controlMode" (change)="toggleGroupChange($event)">
          <mat-button-toggle value="GreenStreet" title="{{ 'TRAFFIC_SCENES.GREEN_STREET' | translate }}" (click)="startGreenStreetMode()">
            {{ "TRAFFIC_SCENES.GREEN_STREET_SHORT" | translate }}
          </mat-button-toggle>
          <mat-button-toggle value="RedStreet" title="{{ 'TRAFFIC_SCENES.RED_STREET' | translate }}" (click)="startRedStreetMode()">
            {{ "TRAFFIC_SCENES.RED_STREET_SHORT" | translate }}
          </mat-button-toggle>
          <mat-button-toggle value="Adaptive" title="{{ 'TRAFFIC_SCENES.ADAPTIVE' | translate }}" (click)="startAdaptiveMode()">
            {{ "TRAFFIC_SCENES.ADAPTIVE_SHORT" | translate }}
          </mat-button-toggle>
        </mat-button-toggle-group>
      </div>

      <button mat-flat-button style="flex: 1; width: 100%" color="primary" [disabled]="false" (click)="stopControlMode()">
        {{ "COMMON.STOP" | translate }}
      </button>
    </div>

    <div fxLayout="row" fxLayoutGap="16px" style="font-size: 14px">
      <mat-form-field style="min-width: 70px; flex: 2.5">
        <mat-select
          placeholder="{{ 'COMMON.DIRECTION' | translate }}"
          [value]="forwardDirection"
          (selectionChange)="forwardDirection = $event.value; updateChanges()"
          [disabled]="modeEnabled || !modelInfo.settings.twoWay"
          disableOptionCentering
          panelClass="single-select-panel"
        >
          <mat-option [value]="true">{{ "TRAFFIC_SCENES.FORWARD_DIRECTION" | translate }}</mat-option>
          <mat-option [value]="false">{{ "TRAFFIC_SCENES.BACKWARD_DIRECTION" | translate }}</mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field style="min-width: 120px; flex: 3">
        <mat-select
          placeholder="{{ 'TRAFFIC_SCENES.SWITCH_TYPE' | translate }}"
          [value]="switchType"
          (selectionChange)="switchType = $event.value; updateChanges()"
          disableOptionCentering
          [disabled]="modeEnabled"
          panelClass="single-select-panel"
        >
          <mat-option *ngFor="let item of switchTypes" [value]="item.type">{{ item.name | translate }}</mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field style="min-width: 80px; flex: 1">
        <input
          matInput
          type="number"
          min="60"
          max="3600"
          step="10"
          [value]="modeTimeValue"
          (input)="modeTime = $event.target.value; updateChanges()"
          placeholder="{{ 'COMMON.DURATION' | translate }}"
          [disabled]="modeEnabled"
        />
      </mat-form-field>
    </div>
  </div>
</div>

<mat-divider></mat-divider>

<div fxLayout fxLayoutGap="8px" fxLayoutAlign="start center" style="padding: 12px 16px; font-size: 14px" [class.mat-secondary-color]="!modeEnabled">
  <div>{{ "TRAFFIC_SCENES.CONTROL_MODE_DURATION" | translate }}:</div>
  <div>{{ modeDuration }}</div>
</div>

<mat-divider></mat-divider>

<div style="flex: 1; overflow: auto; overflow: overlay; margin-bottom: -12px">
  <table *ngIf="status?.controlMode != 'Adaptive'" class="table">
    <tr class="mat-small mat-secondary table-head">
      <td class="mat-dialog-bg mat-secondary-color center" style="border-left: none">№</td>
      <td class="mat-dialog-bg mat-secondary-color" style="width: 100%; padding: 2px 4px" colspan="3">
        {{ "COMMON.NAME" | translate }}
      </td>

      <td *ngIf="forwardDirection" class="mat-dialog-bg mat-secondary-color center">
        <span>{{ "TRAFFIC_SCENES.GREEN_STREET_SHORT" | translate }}</span>
      </td>
      <td *ngIf="forwardDirection" class="mat-dialog-bg mat-secondary-color center">
        <span>{{ "TRAFFIC_SCENES.RED_STREET_SHORT" | translate }}</span>
      </td>
      <td *ngIf="!forwardDirection && modelInfo.settings.twoWay" class="mat-dialog-bg mat-secondary-color center">
        <span>{{ "TRAFFIC_SCENES.GREEN_STREET_SHORT" | translate }}</span>
      </td>
      <td *ngIf="!forwardDirection && modelInfo.settings.twoWay" class="mat-dialog-bg mat-secondary-color center">
        <span>{{ "TRAFFIC_SCENES.RED_STREET_SHORT" | translate }}</span>
      </td>
    </tr>

    <tr *ngFor="let item of objects; let i = index" class="mat-hover">
      <td>{{ i + 1 }}.</td>

      <td>
        <ng-container
          *ngTemplateOutlet="
            statusButton;
            context: {
              objectId: item.trafficObjectId,
              schemaSet: getSchemaSet(item.trafficObjectId)
            }
          "
        ></ng-container>
      </td>

      <td style="width: 100%">{{ getObjectName(item.trafficObjectId) }}</td>

      <td>
        <ng-container
          *ngTemplateOutlet="
            statusIcon;
            context: {
              status: getObjectStatus(item.trafficObjectId)
            }
          "
        ></ng-container>
      </td>

      <td class="center" *ngIf="forwardDirection">
        <ng-container
          *ngTemplateOutlet="
            stepButton;
            context: {
              objectId: item.trafficObjectId,
              schemaSet: getSchemaSet(item.trafficObjectId),
              stepMovements: getStep(item.trafficObjectId).forwardMovements,
              mode: 'GreenStreet'
            }
          "
        ></ng-container>
      </td>
      <td class="center" *ngIf="forwardDirection">
        <ng-container
          *ngTemplateOutlet="
            stepButton;
            context: {
              objectId: item.trafficObjectId,
              schemaSet: getSchemaSet(item.trafficObjectId),
              stepMovements: getStep(item.trafficObjectId).forwardRedMovements,
              mode: 'RedStreet'
            }
          "
        ></ng-container>
      </td>
      <td class="center" *ngIf="!forwardDirection && modelInfo.settings.twoWay">
        <ng-container
          *ngTemplateOutlet="
            stepButton;
            context: {
              objectId: item.trafficObjectId,
              schemaSet: getSchemaSet(item.trafficObjectId),
              stepMovements: getStep(item.trafficObjectId).backwardMovements,
              mode: 'GreenStreet'
            }
          "
        ></ng-container>
      </td>
      <td class="center" *ngIf="!forwardDirection && modelInfo.settings.twoWay">
        <ng-container
          *ngTemplateOutlet="
            stepButton;
            context: {
              objectId: item.trafficObjectId,
              schemaSet: getSchemaSet(item.trafficObjectId),
              stepMovements: getStep(item.trafficObjectId).backwardRedMovements,
              mode: 'RedStreet'
            }
          "
        ></ng-container>
      </td>
    </tr>
  </table>

  <table *ngIf="status?.controlMode == 'Adaptive'" class="table">
    <tr *ngFor="let item of objects; let i = index" class="mat-hover">
      <td style="padding: 8px 16px">
        <div style="font-size: 14px">{{ i + 1 }}. {{ getObjectName(item.trafficObjectId) }} ({{ getPlanLength(item.trafficObjectId) }})</div>
        <div>
          <traffic-scene-plan
            [objectId]="item.trafficObjectId"
            [schemaSet]="getSchemaSet(item.trafficObjectId)"
            [statusSubject]="statusSubject"
          ></traffic-scene-plan>
        </div>
      </td>
    </tr>
  </table>
</div>

<ng-template #stepButton let-objectId="objectId" let-schemaSet="schemaSet" let-stepMovements="stepMovements" let-mode="mode">
  <button
    mat-raised-button
    class="state-button mat-button-hover"
    (click)="startManual(objectId, mode)"
    (contextmenu)="stopManual(objectId); $event.stopPropagation(); $event.preventDefault()"
  >
    <div class="state-button-svg mat-border-light">
      <object
        *ngIf="schemaSet"
        type="image/svg+xml"
        data="/assets/images/tlc/layout_button.svg"
        (load)="stepSvgLoad($event.target, objectId, stepMovements)"
        [style.transform]="'rotate(' + schemaSet?.schemaView.angle + 'deg)'"
      ></object>
    </div>
  </button>
</ng-template>

<ng-template #statusButton let-objectId="objectId" let-schemaSet="schemaSet">
  <div class="state-button-svg mat-border-light">
    <object
      *ngIf="schemaSet"
      type="image/svg+xml"
      data="/assets/images/tlc/layout_button.svg"
      (load)="statusSvgLoad($event.target, objectId)"
      [style.transform]="'rotate(' + schemaSet?.schemaView.angle + 'deg)'"
    ></object>
  </div>
</ng-template>

<ng-template #statusIcon let-status="status">
  <div class="timer mat-dialog-bg1" [class.active]="true" [style.background-color]="getColor(status?.iconStatus?.borderColor)">
    <div class="spin" [class.active]="status?.iconStatus?.processing"></div>
    <div class="time" [style.background-color]="getColor(status?.iconStatus?.color)">
      <span>{{ getObjectModeTime(status) }}</span>
    </div>
  </div>
</ng-template>
