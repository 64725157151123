import { Injectable } from "@angular/core";

import { Observable, of } from "rxjs";
import { map } from "rxjs/operators";

import {
  IDataSourceService,
  setPageParams,
  getPageReturn,
  IPageParams,
  IPageReturn,
} from "projects/msu-its-web-common/src/data/data-source";

import { PtmApiService } from "../ptm-api.service";
import { CreatePublicTransportGlonassTerminalRequest, DeletePublicTransportGlonassTerminalsRequest, GetPublicTransportGlonassTerminalExistRequest, GetPublicTransportGlonassTerminalRequest, GetPublicTransportGlonassTerminalsRequest, PublicTransportGlonassTerminal, UpdatePublicTransportGlonassTerminalRequest } from "../../dtos/ptm.dtos";


@Injectable()
export class TerminalService
  implements
    IDataSourceService<
      PublicTransportGlonassTerminal,
      PublicTransportGlonassTerminal
    > {
  constructor(private _api: PtmApiService) {}

  getPagedList(
    params: IPageParams,
    customerId: string
  ): Observable<IPageReturn<PublicTransportGlonassTerminal>> {
    if (!customerId) {
      return of(null);
    }

    const request = new GetPublicTransportGlonassTerminalsRequest();
    request.customerId = customerId;

    setPageParams(request, params);
    return this._api.get(request).pipe(
      map((m) => {
        return getPageReturn(m);
      })
    );
  }
  getList(
    customerId: string,
    filter?: string,
    groupIds?: string[],
    items?: number
  ): Observable<PublicTransportGlonassTerminal[]> {
    const request = new GetPublicTransportGlonassTerminalsRequest();
    request.customerId = customerId;
    request.entityGroups = groupIds || [];

    request.page = 0;
    request.items = items || 0;
    request.sort = "number";
    request.filter = filter || "";

    return this._api.get(request).pipe(
      map((m) => {
        return m.items;
      })
    );
  }

  get(id: string, customerId: string) {
    const request = new GetPublicTransportGlonassTerminalRequest();
    request.id = id;
    request.customerId = customerId;
    return this._api.get(request);
  }
  add(
    model: PublicTransportGlonassTerminal,
    params: {
      customerId: string;
      groups: string[];
      updateGroups: boolean;
    }
  ) {
    const request = new CreatePublicTransportGlonassTerminalRequest();
    request.entity = model;
    request.customerId = params.customerId;
    request.entityGroups = params.groups;
    request.updateEntityGroups = params.updateGroups;
    return this._api.post(request);
  }
  update(
    model: PublicTransportGlonassTerminal,
    params: { customerId: string; groups: string[]; updateGroups: boolean }
  ) {
    const request = new UpdatePublicTransportGlonassTerminalRequest();
    request.id = model.id;
    request.entity = model;
    request.customerId = params.customerId;
    request.entityGroups = params.groups;
    request.updateEntityGroups = params.updateGroups;
    return this._api.put(request);
  }
  delete(ids: string[], customerId: string) {
    const request = new DeletePublicTransportGlonassTerminalsRequest();
    request.ids = ids;
    request.customerId = customerId;
    return this._api.delete(request);
  }
  exist(number: string, customerId: string): Observable<boolean> {
    const request = new GetPublicTransportGlonassTerminalExistRequest({
      number,
      customerId,
    });
    return this._api.get(request);
  }

  // getControllerList(
  //   filter: string,
  //   customerId: string,
  //   items = 0,
  //   controllerType?: ParkingControllerType,
  //   onlyFree?: boolean
  // ) {
  //   const request = new GetParkingControllersRequest();

  //   request.customerId = customerId;
  //   request.filter = filter;
  //   request.controllerType = controllerType || ParkingControllerType.None;
  //   request.onlyFree = onlyFree || false;
  //   request.page = 0;
  //   request.items = items;

  //   return this._api.get(request).pipe(map((m) => m.items));
  // }

  // changeCustomer(
  //   id: string,
  //   customerId: string,
  //   params: { newCustomerId: string; description: string }
  // ) {
  //   const request = new ParkingChangeCustomerRequest();
  //   request.id = id;
  //   request.customerId = customerId;
  //   request.description = params.description;
  //   request.newCustomerId = params.newCustomerId;
  //   return this._api.post(request);
  // }

  // detachController(id: string, customerId: string, description: string) {
  //   const request = new DetachParkingControllerRequest();
  //   request.id = id;
  //   request.description = description;
  //   request.customerId = customerId;
  //   return this._api.post(request);
  // }

  // getPermissions(id: string, customerId: string) {
  //   const request = new GetParkingCurrentUserPermissionsRequest();
  //   request.id = id;
  //   request.customerId = customerId;
  //   return this._api.get(request);
  // }

  // getControllerEventTypes(id: string, customerId: string) {
  //   const request = new GetControllerEventTypesRequest();
  //   request.id = id;
  //   request.customerId = customerId;
  //   return this._api.get(request);
  // }
}
