/* Options:
Date: 2024-05-17 05:36:59
Version: 6.70
Tip: To override a DTO option, remove "//" prefix before updating
BaseUrl: https://tfms.dev.its.msu24.ru

//GlobalNamespace: 
//MakePropertiesOptional: False
//AddServiceStackTypes: True
//AddResponseStatus: False
//AddImplicitVersion: 
//AddDescriptionAsComments: True
//IncludeTypes: 
//ExcludeTypes: 
//DefaultImports: 
*/

export interface IReturn<T> {
  createResponse(): T;
}

export interface IReturnVoid {
  createResponse(): void;
}

export interface IHasSessionId {
  sessionId: string;
}

export interface IHasBearerToken {
  bearerToken: string;
}

export interface IPost {}

// @DataContract
export class GetEntityGroupsRequestBase implements ICustomerItemRequest {
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public entityGroups: string[];

  // @DataMember
  public itemsIds: string[];

  // @DataMember
  public onlyOwnedItems: boolean;

  // @DataMember
  public page: number;

  // @DataMember
  public items: number;

  // @DataMember
  public sort: string;

  // @DataMember
  public filter: string;

  // @DataMember
  public propertiesTest: { [index: string]: number };

  public constructor(init?: Partial<GetEntityGroupsRequestBase>) {
    (Object as any).assign(this, init);
  }
}

export interface ICustomerItemRequest extends ICustomerEntity {}

export interface ICustomerEntity {
  // @DataMember
  customerId: string;
}

// @DataContract
export class GetEntityGroupRequestBase implements ICustomerItemRequest, IBaseEntityItemRequest {
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember(IsRequired=true)
  public id: string;

  public constructor(init?: Partial<GetEntityGroupRequestBase>) {
    (Object as any).assign(this, init);
  }
}

export interface IBaseEntityItemRequest {
  id: string;
}

// @DataContract
export class EntityGroup
  implements
    ICustomerEntityViewModel,
    IExternalSystemEntity,
    INamedEntity,
    IChangeKey,
    IDescription,
    IModified,
    IEntity
{
  // @DataMember
  public items: string[];

  // @DataMember
  public customerId: string;

  // @DataMember
  public customerName: string;

  // @DataMember
  public externalSystemId?: string;

  // @DataMember
  public externalSystemName: string;

  // @DataMember
  public externalSystemEntityId?: string;

  // @DataMember
  public name: string;

  // @DataMember
  public description: string;

  // @DataMember
  public changeKey: string;

  // @DataMember
  public created: string;

  // @DataMember
  public createdBy: string;

  // @DataMember
  public modified: string;

  // @DataMember
  public modifiedBy: string;

  // @DataMember
  public id: string;

  // @DataMember
  public entityType: string;

  public constructor(init?: Partial<EntityGroup>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class CreateEntityGroupRequestBase implements ICustomerItemRequest, IDescription {
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public updateEntityGroups: boolean;

  // @DataMember
  public entityGroups: string[];

  // @DataMember(IsRequired=true)
  public entity: EntityGroup;

  // @DataMember
  public description: string;

  public constructor(init?: Partial<CreateEntityGroupRequestBase>) {
    (Object as any).assign(this, init);
  }
}

export interface IDescription {
  // @DataMember
  description: string;
}

export interface IRequestWithEntity {}

// @DataContract
export class UpdateEntityGroupRequestBase
  implements ICustomerItemRequest, IBaseEntityItemRequest, IDescription
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public updateEntityGroups: boolean;

  // @DataMember
  public entityGroups: string[];

  // @DataMember(IsRequired=true)
  public id: string;

  // @DataMember(IsRequired=true)
  public entity: EntityGroup;

  // @DataMember
  public description: string;

  public constructor(init?: Partial<UpdateEntityGroupRequestBase>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class DeleteEntityGroupsRequestBase implements ICustomerItemRequest, IDescription {
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public description: string;

  // @DataMember(IsRequired=true)
  public ids: string[];

  public constructor(init?: Partial<DeleteEntityGroupsRequestBase>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class GetEntityGroupSharesRequestBase
  implements IBaseEntityItemRequest, ICustomerItemRequest
{
  // @DataMember
  public id: string;

  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public entityGroups: string[];

  // @DataMember
  public itemsIds: string[];

  // @DataMember
  public onlyOwnedItems: boolean;

  // @DataMember
  public page: number;

  // @DataMember
  public items: number;

  // @DataMember
  public sort: string;

  // @DataMember
  public filter: string;

  // @DataMember
  public propertiesTest: { [index: string]: number };

  public constructor(init?: Partial<GetEntityGroupSharesRequestBase>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class EntityGroupPermissions implements IEntityTyped, ICustomerEntityViewModel {
  // @DataMember
  public customerId: string;

  // @DataMember
  public customerName: string;

  // @DataMember
  public entityGroupId: string;

  // @DataMember
  public permissions: string[];

  // @DataMember
  public entityType: string;

  public constructor(init?: Partial<EntityGroupPermissions>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class UpgradeEntityGroupShareRequestBase implements ICustomerItemRequest, IDescription {
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember(IsRequired=true)
  public id: string;

  // @DataMember(IsRequired=true)
  public entity: EntityGroupPermissions;

  // @DataMember
  public description: string;

  public constructor(init?: Partial<UpgradeEntityGroupShareRequestBase>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class PageRequest {
  // @DataMember
  public page: number;

  // @DataMember
  public items: number;

  // @DataMember
  public sort: string;

  // @DataMember
  public filter: string;

  // @DataMember
  public propertiesTest: { [index: string]: number };

  public constructor(init?: Partial<PageRequest>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class CameraLocation
  implements
    ICustomerEntityViewModel,
    IExternalSystemEntity,
    INamedEntity,
    IChangeKey,
    IDescription,
    IModified,
    IEntity
{
  // @DataMember
  public lat: number;

  // @DataMember
  public lon: number;

  // @DataMember
  public direction: number;

  // @DataMember
  public note: string;

  // @DataMember
  public customerId: string;

  // @DataMember
  public customerName: string;

  // @DataMember
  public externalSystemId?: string;

  // @DataMember
  public externalSystemName: string;

  // @DataMember
  public externalSystemEntityId?: string;

  // @DataMember
  public name: string;

  // @DataMember
  public description: string;

  // @DataMember
  public changeKey: string;

  // @DataMember
  public created: string;

  // @DataMember
  public createdBy: string;

  // @DataMember
  public modified: string;

  // @DataMember
  public modifiedBy: string;

  // @DataMember
  public id: string;

  // @DataMember
  public entityType: string;

  public constructor(init?: Partial<CameraLocation>) {
    (Object as any).assign(this, init);
  }
}

export class DeleteCustomerItemsRequest implements ICustomerItemRequest, IDescription {
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public description: string;

  // @DataMember(IsRequired=true)
  public ids: string[];

  public constructor(init?: Partial<DeleteCustomerItemsRequest>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class GetCustomerEntityCurrentUserPermissionsRequestBase
  implements ICustomerItemRequest, IBaseEntityItemRequest
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember(IsRequired=true)
  public id: string;

  public constructor(init?: Partial<GetCustomerEntityCurrentUserPermissionsRequestBase>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class VideoStreamingOptions implements IEnabled {
  // @DataMember
  public enabled: boolean;

  public constructor(init?: Partial<VideoStreamingOptions>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class DetectorDeviceWorkModeSettings {
  // @DataMember
  public videoStreaming: VideoStreamingOptions;

  public constructor(init?: Partial<DetectorDeviceWorkModeSettings>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export enum DetectorDeviceType {
  None = 'None',
  Optic = 'Optic',
  Gortrans = 'Gortrans',
  Aist = 'Aist',
  RDScience = 'RDScience',
  Andromeda = 'Andromeda',
}

// @DataContract
export class WorkModeDetectorDeviceInfo
  implements
    ICustomerEntity,
    ICustomerName,
    IDeviceItem,
    IOwnedDeviceItem,
    IChangeKey,
    IDescription,
    IModified,
    IEntity
{
  // @DataMember
  public workModeSettings: DetectorDeviceWorkModeSettings;

  // @DataMember
  public deviceType: DetectorDeviceType;

  // @DataMember
  public isAligned: boolean;

  // @DataMember
  public customerId: string;

  // @DataMember
  public customerName: string;

  // @DataMember
  public ownerObjectName: string;

  // @DataMember
  public ownerObjectId: string;

  // @DataMember
  public prefix: string;

  // @DataMember
  public number: string;

  // @DataMember
  public phone1: string;

  // @DataMember
  public phone2: string;

  // @DataMember
  public ip1: string;

  // @DataMember
  public ip2: string;

  // @DataMember
  public imei: string;

  // @DataMember
  public lat: number;

  // @DataMember
  public lon: number;

  // @DataMember
  public direction: number;

  // @DataMember
  public fullNumber: string;

  // @DataMember
  public softwareVersion: string;

  // @DataMember
  public firmwareVersion: string;

  // @DataMember
  public hardwareVersion: string;

  // @DataMember
  public description: string;

  // @DataMember
  public changeKey: string;

  // @DataMember
  public created: string;

  // @DataMember
  public createdBy: string;

  // @DataMember
  public modified: string;

  // @DataMember
  public modifiedBy: string;

  // @DataMember
  public id: string;

  // @DataMember
  public entityType: string;

  public constructor(init?: Partial<WorkModeDetectorDeviceInfo>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class DeleteItemsRequest implements IDescription {
  // @DataMember
  public description: string;

  // @DataMember(IsRequired=true)
  public ids: string[];

  public constructor(init?: Partial<DeleteItemsRequest>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export enum DetectorIncidentType {
  CameraOverlight = 'CameraOverlight',
  CameraContrast = 'CameraContrast',
  CountError = 'CountError',
}

// @DataContract
export enum IncidentStatus {
  New = 'New',
  Canceled = 'Canceled',
  Resolved = 'Resolved',
}

// @DataContract
export enum DataType {
  Csv = 'Csv',
  Xls = 'Xls',
}

// @DataContract
export class GetDetectorGraphRequestBase {
  // @DataMember(IsRequired=true)
  public id: string;

  // @DataMember(IsRequired=true)
  public startTime: string;

  // @DataMember(IsRequired=true)
  public endTime: string;

  // @DataMember
  public groupBySeconds: number;

  // @DataMember
  public smooth: number;

  // @DataMember
  public lane?: number;

  public constructor(init?: Partial<GetDetectorGraphRequestBase>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export enum GraphDataType {
  Json = 'Json',
  Csv = 'Csv',
  Xml = 'Xml',
  Xls = 'Xls',
}

// @DataContract
export class GetInternalDetectorGraphRequestBase {
  // @DataMember(IsRequired=true)
  public id: string;

  // @DataMember(IsRequired=true)
  public startTime: string;

  // @DataMember(IsRequired=true)
  public endTime: string;

  // @DataMember
  public groupBySeconds: number;

  // @DataMember
  public smooth: number;

  public constructor(init?: Partial<GetInternalDetectorGraphRequestBase>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class DetectorDeviceInfo
  implements
    ICustomerEntity,
    ICustomerName,
    IDeviceItem,
    IOwnedDeviceItem,
    IChangeKey,
    IDescription,
    IModified,
    IEntity
{
  // @DataMember
  public deviceType: DetectorDeviceType;

  // @DataMember
  public isAligned: boolean;

  // @DataMember
  public customerId: string;

  // @DataMember
  public customerName: string;

  // @DataMember
  public ownerObjectName: string;

  // @DataMember
  public ownerObjectId: string;

  // @DataMember
  public prefix: string;

  // @DataMember
  public number: string;

  // @DataMember
  public phone1: string;

  // @DataMember
  public phone2: string;

  // @DataMember
  public ip1: string;

  // @DataMember
  public ip2: string;

  // @DataMember
  public imei: string;

  // @DataMember
  public lat: number;

  // @DataMember
  public lon: number;

  // @DataMember
  public direction: number;

  // @DataMember
  public fullNumber: string;

  // @DataMember
  public softwareVersion: string;

  // @DataMember
  public firmwareVersion: string;

  // @DataMember
  public hardwareVersion: string;

  // @DataMember
  public description: string;

  // @DataMember
  public changeKey: string;

  // @DataMember
  public created: string;

  // @DataMember
  public createdBy: string;

  // @DataMember
  public modified: string;

  // @DataMember
  public modifiedBy: string;

  // @DataMember
  public id: string;

  // @DataMember
  public entityType: string;

  public constructor(init?: Partial<DetectorDeviceInfo>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class ChangeCustomerRequestBase
  implements IDescription, ICustomerItemRequest, IBaseEntityItemRequest
{
  // @DataMember
  public description: string;

  // @DataMember
  public newCustomerId: string;

  // @DataMember
  public customerId: string;

  // @DataMember
  public id: string;

  public constructor(init?: Partial<ChangeCustomerRequestBase>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class EntityEventLogDetails {
  // @DataMember
  public id: number;

  // @DataMember
  public eventTime: string;

  // @DataMember
  public eventId: number;

  // @DataMember
  public message: string;

  // @DataMember
  public data: string;

  public constructor(init?: Partial<EntityEventLogDetails>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export enum DetectorUserStatus {
  InWork = 'InWork',
  Demounted = 'Demounted',
}

// @DataContract
export class PtzCameraLocation
  implements
    ICustomerEntityViewModel,
    IExternalSystemEntity,
    INamedEntity,
    IChangeKey,
    IDescription,
    IModified,
    IEntity
{
  // @DataMember
  public lat: number;

  // @DataMember
  public lon: number;

  // @DataMember
  public note: string;

  // @DataMember
  public customerId: string;

  // @DataMember
  public customerName: string;

  // @DataMember
  public externalSystemId?: string;

  // @DataMember
  public externalSystemName: string;

  // @DataMember
  public externalSystemEntityId?: string;

  // @DataMember
  public name: string;

  // @DataMember
  public description: string;

  // @DataMember
  public changeKey: string;

  // @DataMember
  public created: string;

  // @DataMember
  public createdBy: string;

  // @DataMember
  public modified: string;

  // @DataMember
  public modifiedBy: string;

  // @DataMember
  public id: string;

  // @DataMember
  public entityType: string;

  public constructor(init?: Partial<PtzCameraLocation>) {
    (Object as any).assign(this, init);
  }
}

export enum CameraCommand {
  MoveLeft = 'MoveLeft',
  MoveRight = 'MoveRight',
  MoveUp = 'MoveUp',
  MoveDown = 'MoveDown',
  MoveHome = 'MoveHome',
  FocusIn = 'FocusIn',
  FocusOut = 'FocusOut',
  ZoomIn = 'ZoomIn',
  ZoomOut = 'ZoomOut',
}

export interface ICustomerIntegrationIdRequest {
  customerIntegrationId: string;
}

// @DataContract
export class EntityGroupInfo
  implements
    ICustomerEntityViewModel,
    IExternalSystemEntity,
    INamedEntity,
    IChangeKey,
    IDescription,
    IModified,
    IEntity
{
  // @DataMember
  public itemsCount: number;

  // @DataMember
  public customerId: string;

  // @DataMember
  public customerName: string;

  // @DataMember
  public externalSystemId?: string;

  // @DataMember
  public externalSystemName: string;

  // @DataMember
  public externalSystemEntityId?: string;

  // @DataMember
  public name: string;

  // @DataMember
  public description: string;

  // @DataMember
  public changeKey: string;

  // @DataMember
  public created: string;

  // @DataMember
  public createdBy: string;

  // @DataMember
  public modified: string;

  // @DataMember
  public modifiedBy: string;

  // @DataMember
  public id: string;

  // @DataMember
  public entityType: string;

  public constructor(init?: Partial<EntityGroupInfo>) {
    (Object as any).assign(this, init);
  }
}

export interface IChangeKey extends IReadonlyChangeKey {
  // @DataMember
  changeKey: string;
}

export interface IReadonlyChangeKey {
  // @DataMember
  changeKey: string;
}

export class CustomerNamedBaseEntityViewModelBase
  implements
    ICustomerEntityViewModel,
    IExternalSystemEntity,
    INamedEntity,
    IChangeKey,
    IDescription,
    IModified,
    IEntity
{
  // @DataMember
  public customerId: string;

  // @DataMember
  public customerName: string;

  // @DataMember
  public externalSystemId?: string;

  // @DataMember
  public externalSystemName: string;

  // @DataMember
  public externalSystemEntityId?: string;

  // @DataMember
  public name: string;

  // @DataMember
  public description: string;

  // @DataMember
  public changeKey: string;

  // @DataMember
  public created: string;

  // @DataMember
  public createdBy: string;

  // @DataMember
  public modified: string;

  // @DataMember
  public modifiedBy: string;

  // @DataMember
  public id: string;

  // @DataMember
  public entityType: string;

  public constructor(init?: Partial<CustomerNamedBaseEntityViewModelBase>) {
    (Object as any).assign(this, init);
  }
}

export interface IEntity {}

export interface IEntityTyped {
  // @DataMember
  entityType: string;
}

export interface IModified {
  // @DataMember
  modified: string;

  // @DataMember
  modifiedBy: string;
}

export interface INamedEntity extends IEntity, INamed {}

export interface INamed {
  // @DataMember
  name: string;
}

export interface ICustomerEntityViewModel extends ICustomerName, ICustomerEntity {}

export interface ICustomerName {
  // @DataMember
  customerName: string;
}

export interface IExternalSystemEntity {
  // @DataMember
  externalSystemId?: string;

  // @DataMember
  externalSystemName: string;

  // @DataMember
  externalSystemEntityId?: string;
}

// @DataContract
export class BaseEntityViewModelBase implements IChangeKey, IDescription, IModified, IEntity {
  // @DataMember
  public description: string;

  // @DataMember
  public changeKey: string;

  // @DataMember
  public created: string;

  // @DataMember
  public createdBy: string;

  // @DataMember
  public modified: string;

  // @DataMember
  public modifiedBy: string;

  // @DataMember
  public id: string;

  // @DataMember
  public entityType: string;

  public constructor(init?: Partial<BaseEntityViewModelBase>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class DetectorGraphItem {
  // @DataMember
  public time: string;

  // @DataMember
  public avgSpeed?: number;

  // @DataMember
  public count?: number;

  // @DataMember
  public countCars?: number;

  // @DataMember
  public countTrucks?: number;

  // @DataMember
  public laneFill?: number;

  public constructor(init?: Partial<DetectorGraphItem>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class DetectorInternalGraphItem {
  // @DataMember
  public time: string;

  // @DataMember
  public humidityPercent?: number;

  // @DataMember
  public temperature?: number;

  // @DataMember
  public heaterOn?: boolean;

  // @DataMember
  public powerOn?: boolean;

  public constructor(init?: Partial<DetectorInternalGraphItem>) {
    (Object as any).assign(this, init);
  }
}

export class CustomerDeviceViewModelBase
  implements
    ICustomerEntity,
    ICustomerName,
    IDeviceItem,
    IOwnedDeviceItem,
    IChangeKey,
    IDescription,
    IModified,
    IEntity
{
  // @DataMember
  public customerId: string;

  // @DataMember
  public customerName: string;

  // @DataMember
  public ownerObjectName: string;

  // @DataMember
  public ownerObjectId: string;

  // @DataMember
  public prefix: string;

  // @DataMember
  public number: string;

  // @DataMember
  public phone1: string;

  // @DataMember
  public phone2: string;

  // @DataMember
  public ip1: string;

  // @DataMember
  public ip2: string;

  // @DataMember
  public imei: string;

  // @DataMember
  public deviceSettings: string;

  // @DataMember
  public lat: number;

  // @DataMember
  public lon: number;

  // @DataMember
  public direction: number;

  // @DataMember
  public fullNumber: string;

  // @DataMember
  public softwareVersion: string;

  // @DataMember
  public firmwareVersion: string;

  // @DataMember
  public hardwareVersion: string;

  // @DataMember
  public description: string;

  // @DataMember
  public changeKey: string;

  // @DataMember
  public created: string;

  // @DataMember
  public createdBy: string;

  // @DataMember
  public modified: string;

  // @DataMember
  public modifiedBy: string;

  // @DataMember
  public id: string;

  // @DataMember
  public entityType: string;

  public constructor(init?: Partial<CustomerDeviceViewModelBase>) {
    (Object as any).assign(this, init);
  }
}

export interface IDeviceItem extends IDeviceVersion {
  // @DataMember
  prefix: string;

  // @DataMember
  number: string;

  // @DataMember
  phone1: string;

  // @DataMember
  phone2: string;

  // @DataMember
  ip1: string;

  // @DataMember
  ip2: string;

  // @DataMember
  imei: string;

  // @DataMember
  lat: number;

  // @DataMember
  lon: number;

  // @DataMember
  direction: number;
}

export interface IDeviceVersion {
  // @DataMember
  softwareVersion: string;

  // @DataMember
  firmwareVersion: string;

  // @DataMember
  hardwareVersion: string;
}

export interface IOwnedDeviceItem {
  // @DataMember
  ownerObjectName: string;

  // @DataMember
  ownerObjectId: string;
}

// @DataContract
export enum EntityEventLogLevel {
  Trace = 'Trace',
  Debug = 'Debug',
  Info = 'Info',
  Warn = 'Warn',
  Error = 'Error',
  Fatal = 'Fatal',
}

// @DataContract
export class EntityEventLogType {
  // @DataMember
  public id: number;

  // @DataMember
  public name: string;

  // @DataMember
  public level: EntityEventLogLevel;

  public constructor(init?: Partial<EntityEventLogType>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class NamedBaseEntityViewModelBase
  implements INamedEntity, IChangeKey, IDescription, IModified, IEntity
{
  // @DataMember
  public name: string;

  // @DataMember
  public description: string;

  // @DataMember
  public changeKey: string;

  // @DataMember
  public created: string;

  // @DataMember
  public createdBy: string;

  // @DataMember
  public modified: string;

  // @DataMember
  public modifiedBy: string;

  // @DataMember
  public id: string;

  // @DataMember
  public entityType: string;

  public constructor(init?: Partial<NamedBaseEntityViewModelBase>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export enum ReplacementReason {
  Demount = 'Demount',
  Replace = 'Replace',
  Other = 'Other',
}

export class CustomerBaseEntityViewModelBase
  implements
    ICustomerEntityViewModel,
    IExternalSystemEntity,
    IChangeKey,
    IDescription,
    IModified,
    IEntity
{
  // @DataMember
  public customerId: string;

  // @DataMember
  public customerName: string;

  // @DataMember
  public externalSystemId?: string;

  // @DataMember
  public externalSystemName: string;

  // @DataMember
  public externalSystemEntityId?: string;

  // @DataMember
  public description: string;

  // @DataMember
  public changeKey: string;

  // @DataMember
  public created: string;

  // @DataMember
  public createdBy: string;

  // @DataMember
  public modified: string;

  // @DataMember
  public modifiedBy: string;

  // @DataMember
  public id: string;

  // @DataMember
  public entityType: string;

  public constructor(init?: Partial<CustomerBaseEntityViewModelBase>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class EntityViewModelBase implements IEntity {
  // @DataMember
  public id: string;

  // @DataMember
  public entityType: string;

  public constructor(init?: Partial<EntityViewModelBase>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class IconStatus {
  // @DataMember
  public color: string;

  // @DataMember
  public borderColor: string;

  public constructor(init?: Partial<IconStatus>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class ResponseError {
  // @DataMember(Order=1)
  public errorCode: string;

  // @DataMember(Order=2)
  public fieldName: string;

  // @DataMember(Order=3)
  public message: string;

  // @DataMember(Order=4)
  public meta: { [index: string]: string };

  public constructor(init?: Partial<ResponseError>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class ResponseStatus {
  // @DataMember(Order=1)
  public errorCode: string;

  // @DataMember(Order=2)
  public message: string;

  // @DataMember(Order=3)
  public stackTrace: string;

  // @DataMember(Order=4)
  public errors: ResponseError[];

  // @DataMember(Order=5)
  public meta: { [index: string]: string };

  public constructor(init?: Partial<ResponseStatus>) {
    (Object as any).assign(this, init);
  }
}

export class CustomerDeviceInfoViewModelBase
  implements
    ICustomerEntity,
    ICustomerName,
    IDeviceItem,
    IOwnedDeviceItem,
    IChangeKey,
    IDescription,
    IModified,
    IEntity
{
  // @DataMember
  public customerId: string;

  // @DataMember
  public customerName: string;

  // @DataMember
  public ownerObjectName: string;

  // @DataMember
  public ownerObjectId: string;

  // @DataMember
  public prefix: string;

  // @DataMember
  public number: string;

  // @DataMember
  public phone1: string;

  // @DataMember
  public phone2: string;

  // @DataMember
  public ip1: string;

  // @DataMember
  public ip2: string;

  // @DataMember
  public imei: string;

  // @DataMember
  public lat: number;

  // @DataMember
  public lon: number;

  // @DataMember
  public direction: number;

  // @DataMember
  public fullNumber: string;

  // @DataMember
  public softwareVersion: string;

  // @DataMember
  public firmwareVersion: string;

  // @DataMember
  public hardwareVersion: string;

  // @DataMember
  public description: string;

  // @DataMember
  public changeKey: string;

  // @DataMember
  public created: string;

  // @DataMember
  public createdBy: string;

  // @DataMember
  public modified: string;

  // @DataMember
  public modifiedBy: string;

  // @DataMember
  public id: string;

  // @DataMember
  public entityType: string;

  public constructor(init?: Partial<CustomerDeviceInfoViewModelBase>) {
    (Object as any).assign(this, init);
  }
}

export class DeviceViewModelBase
  implements IDeviceItem, IOwnedDeviceItem, IChangeKey, IDescription, IModified, IEntity
{
  // @DataMember
  public ownerObjectName: string;

  // @DataMember
  public ownerObjectId: string;

  // @DataMember
  public prefix: string;

  // @DataMember
  public number: string;

  // @DataMember
  public phone1: string;

  // @DataMember
  public phone2: string;

  // @DataMember
  public ip1: string;

  // @DataMember
  public ip2: string;

  // @DataMember
  public imei: string;

  // @DataMember
  public deviceSettings: string;

  // @DataMember
  public lat: number;

  // @DataMember
  public lon: number;

  // @DataMember
  public direction: number;

  // @DataMember
  public fullNumber: string;

  // @DataMember
  public softwareVersion: string;

  // @DataMember
  public firmwareVersion: string;

  // @DataMember
  public hardwareVersion: string;

  // @DataMember
  public description: string;

  // @DataMember
  public changeKey: string;

  // @DataMember
  public created: string;

  // @DataMember
  public createdBy: string;

  // @DataMember
  public modified: string;

  // @DataMember
  public modifiedBy: string;

  // @DataMember
  public id: string;

  // @DataMember
  public entityType: string;

  public constructor(init?: Partial<DeviceViewModelBase>) {
    (Object as any).assign(this, init);
  }
}

export class DeviceInfoViewModelBase
  implements IDeviceItem, IOwnedDeviceItem, IChangeKey, IDescription, IModified, IEntity
{
  // @DataMember
  public ownerObjectName: string;

  // @DataMember
  public ownerObjectId: string;

  // @DataMember
  public prefix: string;

  // @DataMember
  public number: string;

  // @DataMember
  public phone1: string;

  // @DataMember
  public phone2: string;

  // @DataMember
  public ip1: string;

  // @DataMember
  public ip2: string;

  // @DataMember
  public imei: string;

  // @DataMember
  public lat: number;

  // @DataMember
  public lon: number;

  // @DataMember
  public direction: number;

  // @DataMember
  public fullNumber: string;

  // @DataMember
  public softwareVersion: string;

  // @DataMember
  public firmwareVersion: string;

  // @DataMember
  public hardwareVersion: string;

  // @DataMember
  public description: string;

  // @DataMember
  public changeKey: string;

  // @DataMember
  public created: string;

  // @DataMember
  public createdBy: string;

  // @DataMember
  public modified: string;

  // @DataMember
  public modifiedBy: string;

  // @DataMember
  public id: string;

  // @DataMember
  public entityType: string;

  public constructor(init?: Partial<DeviceInfoViewModelBase>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class WorkModeOptions implements IEnabled {
  // @DataMember
  public enabled: boolean;

  public constructor(init?: Partial<WorkModeOptions>) {
    (Object as any).assign(this, init);
  }
}

export interface IEnabled {
  enabled: boolean;
}

// @DataContract
export class PageRequestBase {
  // @DataMember
  public page: number;

  // @DataMember
  public items: number;

  // @DataMember
  public sort: string;

  // @DataMember
  public filter: string;

  // @DataMember
  public propertiesTest: { [index: string]: number };

  public constructor(init?: Partial<PageRequestBase>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class TfmsModuleSettings {
  public constructor(init?: Partial<TfmsModuleSettings>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class ModuleSettingsBase {
  public constructor(init?: Partial<ModuleSettingsBase>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class TfmsModuleTableViewSettings {
  // @DataMember
  public showDeviceVersion: boolean;

  public constructor(init?: Partial<TfmsModuleTableViewSettings>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class TfmsModuleCustomerSettings {
  // @DataMember
  public tableView: TfmsModuleTableViewSettings;

  public constructor(init?: Partial<TfmsModuleCustomerSettings>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class ModuleCustomerSettingsBase {
  public constructor(init?: Partial<ModuleCustomerSettingsBase>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class GetEntityGroupsResponse implements IChangeKey {
  // @DataMember
  public items: EntityGroupInfo[];

  // @DataMember
  public total: number;

  // @DataMember
  public page: number;

  // @DataMember
  public itemsPerPage: number;

  // @DataMember
  public changeKey: string;

  public constructor(init?: Partial<GetEntityGroupsResponse>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class GetEntityGroupSharesResponse implements IChangeKey {
  // @DataMember
  public items: EntityGroupPermissions[];

  // @DataMember
  public total: number;

  // @DataMember
  public page: number;

  // @DataMember
  public itemsPerPage: number;

  // @DataMember
  public changeKey: string;

  public constructor(init?: Partial<GetEntityGroupSharesResponse>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class GetCamerasLocationResponse implements IChangeKey {
  // @DataMember
  public items: CameraLocation[];

  // @DataMember
  public total: number;

  // @DataMember
  public page: number;

  // @DataMember
  public itemsPerPage: number;

  // @DataMember
  public changeKey: string;

  public constructor(init?: Partial<GetCamerasLocationResponse>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class Camera
  implements
    ICustomerEntityViewModel,
    IExternalSystemEntity,
    INamedEntity,
    IChangeKey,
    IDescription,
    IModified,
    IEntity
{
  // @DataMember
  public lat: number;

  // @DataMember
  public lon: number;

  // @DataMember
  public direction: number;

  // @DataMember
  public url: string;

  // @DataMember
  public rtmp: string;

  // @DataMember
  public note: string;

  // @DataMember
  public customerId: string;

  // @DataMember
  public customerName: string;

  // @DataMember
  public externalSystemId?: string;

  // @DataMember
  public externalSystemName: string;

  // @DataMember
  public externalSystemEntityId?: string;

  // @DataMember
  public name: string;

  // @DataMember
  public description: string;

  // @DataMember
  public changeKey: string;

  // @DataMember
  public created: string;

  // @DataMember
  public createdBy: string;

  // @DataMember
  public modified: string;

  // @DataMember
  public modifiedBy: string;

  // @DataMember
  public id: string;

  // @DataMember
  public entityType: string;

  public constructor(init?: Partial<Camera>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class GetDetectorDevicesWorkModeResponse implements IChangeKey {
  // @DataMember
  public items: WorkModeDetectorDeviceInfo[];

  // @DataMember
  public total: number;

  // @DataMember
  public page: number;

  // @DataMember
  public itemsPerPage: number;

  // @DataMember
  public changeKey: string;

  public constructor(init?: Partial<GetDetectorDevicesWorkModeResponse>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class DetectorDevice
  implements
    ICustomerEntity,
    ICustomerName,
    IDeviceItem,
    IOwnedDeviceItem,
    IChangeKey,
    IDescription,
    IModified,
    IEntity
{
  // @DataMember
  public deviceType: DetectorDeviceType;

  // @DataMember
  public isAligned: boolean;

  // @DataMember
  public customerId: string;

  // @DataMember
  public customerName: string;

  // @DataMember
  public ownerObjectName: string;

  // @DataMember
  public ownerObjectId: string;

  // @DataMember
  public prefix: string;

  // @DataMember
  public number: string;

  // @DataMember
  public phone1: string;

  // @DataMember
  public phone2: string;

  // @DataMember
  public ip1: string;

  // @DataMember
  public ip2: string;

  // @DataMember
  public imei: string;

  // @DataMember
  public deviceSettings: string;

  // @DataMember
  public lat: number;

  // @DataMember
  public lon: number;

  // @DataMember
  public direction: number;

  // @DataMember
  public fullNumber: string;

  // @DataMember
  public softwareVersion: string;

  // @DataMember
  public firmwareVersion: string;

  // @DataMember
  public hardwareVersion: string;

  // @DataMember
  public description: string;

  // @DataMember
  public changeKey: string;

  // @DataMember
  public created: string;

  // @DataMember
  public createdBy: string;

  // @DataMember
  public modified: string;

  // @DataMember
  public modifiedBy: string;

  // @DataMember
  public id: string;

  // @DataMember
  public entityType: string;

  public constructor(init?: Partial<DetectorDevice>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class DetectorDevicesPageResponse implements IChangeKey {
  // @DataMember
  public items: DetectorDevice[];

  // @DataMember
  public total: number;

  // @DataMember
  public page: number;

  // @DataMember
  public itemsPerPage: number;

  // @DataMember
  public changeKey: string;

  public constructor(init?: Partial<DetectorDevicesPageResponse>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class DetectorDeviceIncidentWorkerInfo {
  // @DataMember
  public lastStart: string;

  // @DataMember
  public elapsedTime: number;

  // @DataMember
  public ignoredCount: number;

  // @DataMember
  public updatedCount: number;

  // @DataMember
  public withoutErrorCount: number;

  // @DataMember
  public totalCount: number;

  public constructor(init?: Partial<DetectorDeviceIncidentWorkerInfo>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class Detector
  implements
    IDeviceVersion,
    ICustomerEntityViewModel,
    IExternalSystemEntity,
    INamedEntity,
    IChangeKey,
    IDescription,
    IModified,
    IEntity
{
  // @DataMember
  public detectorDeviceId?: string;

  // @DataMember
  public lat: number;

  // @DataMember
  public lon: number;

  // @DataMember
  public direction: number;

  // @DataMember
  public userStatus: DetectorUserStatus;

  // @DataMember
  public deviceType: DetectorDeviceType;

  // @DataMember
  public serialNumber: string;

  // @DataMember
  public detectorDevice: DetectorDevice;

  // @DataMember
  public softwareVersion: string;

  // @DataMember
  public firmwareVersion: string;

  // @DataMember
  public hardwareVersion: string;

  // @DataMember
  public detectionArea: string;

  // @DataMember
  public customerSerialNumber: string;

  // @DataMember
  public customerId: string;

  // @DataMember
  public customerName: string;

  // @DataMember
  public externalSystemId?: string;

  // @DataMember
  public externalSystemName: string;

  // @DataMember
  public externalSystemEntityId?: string;

  // @DataMember
  public name: string;

  // @DataMember
  public description: string;

  // @DataMember
  public changeKey: string;

  // @DataMember
  public created: string;

  // @DataMember
  public createdBy: string;

  // @DataMember
  public modified: string;

  // @DataMember
  public modifiedBy: string;

  // @DataMember
  public id: string;

  // @DataMember
  public entityType: string;

  public constructor(init?: Partial<Detector>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class IncidentsAnalysisDetectorsResponse implements IChangeKey {
  // @DataMember
  public items: Detector[];

  // @DataMember
  public total: number;

  // @DataMember
  public page: number;

  // @DataMember
  public itemsPerPage: number;

  // @DataMember
  public changeKey: string;

  public constructor(init?: Partial<IncidentsAnalysisDetectorsResponse>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class DetectorDeviceIncident implements IChangeKey, IDescription, IModified, IEntity {
  // @DataMember
  public incidentDate: string;

  // @DataMember
  public detectorId?: string;

  // @DataMember
  public detectorName: string;

  // @DataMember
  public detectorDeviceId: string;

  // @DataMember
  public serialNumber: string;

  // @DataMember
  public userName: string;

  // @DataMember
  public status: IncidentStatus;

  // @DataMember
  public incidentType: DetectorIncidentType;

  // @DataMember
  public detectorDevice: DetectorDevice;

  // @DataMember
  public detector: Detector;

  // @DataMember
  public description: string;

  // @DataMember
  public changeKey: string;

  // @DataMember
  public created: string;

  // @DataMember
  public createdBy: string;

  // @DataMember
  public modified: string;

  // @DataMember
  public modifiedBy: string;

  // @DataMember
  public id: string;

  // @DataMember
  public entityType: string;

  public constructor(init?: Partial<DetectorDeviceIncident>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class DetectorDeviceIncidentsResponse implements IChangeKey {
  // @DataMember
  public items: DetectorDeviceIncident[];

  // @DataMember
  public total: number;

  // @DataMember
  public page: number;

  // @DataMember
  public itemsPerPage: number;

  // @DataMember
  public changeKey: string;

  public constructor(init?: Partial<DetectorDeviceIncidentsResponse>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class DetectorGraph {
  // @DataMember
  public avgSpeed?: number;

  // @DataMember
  public avgSpeedTotal?: number;

  // @DataMember
  public count?: number;

  // @DataMember
  public countTotal?: number;

  // @DataMember
  public countCars?: number;

  // @DataMember
  public countCarsTotal?: number;

  // @DataMember
  public countTruck?: number;

  // @DataMember
  public countTruckTotal?: number;

  // @DataMember
  public laneFill?: number;

  // @DataMember
  public laneFillTotal?: number;

  // @DataMember
  public items: DetectorGraphItem[];

  public constructor(init?: Partial<DetectorGraph>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class DetectorInternalGraph {
  // @DataMember
  public humidity?: number;

  // @DataMember
  public temperature?: number;

  // @DataMember
  public items: DetectorInternalGraphItem[];

  public constructor(init?: Partial<DetectorInternalGraph>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class DetectorDeviceSession {
  // @DataMember
  public lastReceived: string;

  // @DataMember
  public lastSent: string;

  // @DataMember
  public deviceNumber: string;

  // @DataMember
  public connectionTime: string;

  // @DataMember
  public connected: boolean;

  // @DataMember
  public prefix: string;

  public constructor(init?: Partial<DetectorDeviceSession>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class DetectorDeviceStatusSummary {
  // @DataMember
  public total: number;

  // @DataMember
  public online: number;

  // @DataMember
  public opticTotal: number;

  // @DataMember
  public opticOnline: number;

  public constructor(init?: Partial<DetectorDeviceStatusSummary>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class GetDetectorDevicesResponse implements IChangeKey {
  // @DataMember
  public items: DetectorDeviceInfo[];

  // @DataMember
  public total: number;

  // @DataMember
  public page: number;

  // @DataMember
  public itemsPerPage: number;

  // @DataMember
  public changeKey: string;

  public constructor(init?: Partial<GetDetectorDevicesResponse>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class SessionInfo {
  // @DataMember
  public id: string;

  // @DataMember
  public lastReceived: string;

  // @DataMember
  public lastSent: string;

  // @DataMember
  public deviceNumber: string;

  // @DataMember
  public sessionId: string;

  // @DataMember
  public connectionTime: string;

  // @DataMember
  public receiverHost: string;

  // @DataMember
  public remoteAddress: string;

  // @DataMember
  public connected: boolean;

  // @DataMember
  public lastUpdated: string;

  // @DataMember
  public entityType: string;

  // @DataMember
  public nextCheckSession: string;

  public constructor(init?: Partial<SessionInfo>) {
    (Object as any).assign(this, init);
  }
}

export class GetDeviceEventLogResponse implements IChangeKey {
  // @DataMember
  public items: EntityEventLogDetails[];

  // @DataMember
  public total: number;

  // @DataMember
  public page: number;

  // @DataMember
  public itemsPerPage: number;

  // @DataMember
  public changeKey: string;

  public constructor(init?: Partial<GetDeviceEventLogResponse>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class DetectorDeviceWorkModeOptions implements IChangeKey {
  // @DataMember
  public changeKey: string;

  // @DataMember
  public workModeSettings: DetectorDeviceWorkModeSettings;

  // @DataMember
  public id: string;

  // @DataMember
  public entityType: string;

  public constructor(init?: Partial<DetectorDeviceWorkModeOptions>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class DetectorMaintenance implements IChangeKey, IDescription, IModified, IEntity {
  // @DataMember
  public detectorId: string;

  // @DataMember
  public date: string;

  // @DataMember
  public author: string;

  // @DataMember
  public detector: Detector;

  // @DataMember
  public description: string;

  // @DataMember
  public changeKey: string;

  // @DataMember
  public created: string;

  // @DataMember
  public createdBy: string;

  // @DataMember
  public modified: string;

  // @DataMember
  public modifiedBy: string;

  // @DataMember
  public id: string;

  // @DataMember
  public entityType: string;

  public constructor(init?: Partial<DetectorMaintenance>) {
    (Object as any).assign(this, init);
  }
}

export class DetectorMaintenanceType
  implements INamedEntity, IChangeKey, IDescription, IModified, IEntity
{
  // @DataMember
  public name: string;

  // @DataMember
  public description: string;

  // @DataMember
  public changeKey: string;

  // @DataMember
  public created: string;

  // @DataMember
  public createdBy: string;

  // @DataMember
  public modified: string;

  // @DataMember
  public modifiedBy: string;

  // @DataMember
  public id: string;

  // @DataMember
  public entityType: string;

  public constructor(init?: Partial<DetectorMaintenanceType>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class DetectorMaintenanceDetails implements IChangeKey, IDescription, IModified, IEntity {
  // @DataMember
  public detectorMaintenanceId: string;

  // @DataMember
  public detectorMaintenanceTypeId: string;

  // @DataMember
  public maintenance: DetectorMaintenance;

  // @DataMember
  public maintenanceType: DetectorMaintenanceType;

  // @DataMember
  public description: string;

  // @DataMember
  public changeKey: string;

  // @DataMember
  public created: string;

  // @DataMember
  public createdBy: string;

  // @DataMember
  public modified: string;

  // @DataMember
  public modifiedBy: string;

  // @DataMember
  public id: string;

  // @DataMember
  public entityType: string;

  public constructor(init?: Partial<DetectorMaintenanceDetails>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class DetectorsMaintenanceDetailsResponse implements IChangeKey {
  // @DataMember
  public items: DetectorMaintenanceDetails[];

  // @DataMember
  public total: number;

  // @DataMember
  public page: number;

  // @DataMember
  public itemsPerPage: number;

  // @DataMember
  public changeKey: string;

  public constructor(init?: Partial<DetectorsMaintenanceDetailsResponse>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class DetectorsMaintenanceTypeResponse implements IChangeKey {
  // @DataMember
  public items: DetectorMaintenanceType[];

  // @DataMember
  public total: number;

  // @DataMember
  public page: number;

  // @DataMember
  public itemsPerPage: number;

  // @DataMember
  public changeKey: string;

  public constructor(init?: Partial<DetectorsMaintenanceTypeResponse>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class DetectorsMaintenanceResponse implements IChangeKey {
  // @DataMember
  public items: DetectorMaintenance[];

  // @DataMember
  public total: number;

  // @DataMember
  public page: number;

  // @DataMember
  public itemsPerPage: number;

  // @DataMember
  public changeKey: string;

  public constructor(init?: Partial<DetectorsMaintenanceResponse>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class DetectorReplacement
  implements
    ICustomerEntityViewModel,
    IExternalSystemEntity,
    IChangeKey,
    IDescription,
    IModified,
    IEntity
{
  // @DataMember
  public date: string;

  // @DataMember
  public detectorId: string;

  // @DataMember
  public oldDetectorDeviceId?: string;

  // @DataMember
  public detectorDeviceId?: string;

  // @DataMember
  public modifiedBy: string;

  // @DataMember
  public modifiedByName: string;

  // @DataMember
  public newDeviceNumber: string;

  // @DataMember
  public oldDeviceNumber: string;

  // @DataMember
  public reason: ReplacementReason;

  // @DataMember
  public customerId: string;

  // @DataMember
  public customerName: string;

  // @DataMember
  public externalSystemId?: string;

  // @DataMember
  public externalSystemName: string;

  // @DataMember
  public externalSystemEntityId?: string;

  // @DataMember
  public description: string;

  // @DataMember
  public changeKey: string;

  // @DataMember
  public created: string;

  // @DataMember
  public createdBy: string;

  // @DataMember
  public modified: string;

  // @DataMember
  public id: string;

  // @DataMember
  public entityType: string;

  public constructor(init?: Partial<DetectorReplacement>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class DetectorReplacementsResponse implements IChangeKey {
  // @DataMember
  public items: DetectorReplacement[];

  // @DataMember
  public total: number;

  // @DataMember
  public page: number;

  // @DataMember
  public itemsPerPage: number;

  // @DataMember
  public changeKey: string;

  public constructor(init?: Partial<DetectorReplacementsResponse>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class DetectorGraphAnalysis implements IEntity {
  // @DataMember
  public currentSpeed?: number;

  // @DataMember
  public currentCount?: number;

  // @DataMember
  public currentLaneFill?: number;

  // @DataMember
  public currentItems: DetectorGraphItem[];

  // @DataMember
  public pastYearAvgSpeed?: number;

  // @DataMember
  public pastYearAvgCount?: number;

  // @DataMember
  public pastYearAvgLaneFill?: number;

  // @DataMember
  public speedDifferencePercent?: number;

  // @DataMember
  public countDifferecePercent?: number;

  // @DataMember
  public laneFillDifferencePercent?: number;

  // @DataMember
  public pastYearAverage: DetectorGraphItem[];

  // @DataMember
  public id: string;

  // @DataMember
  public entityType: string;

  public constructor(init?: Partial<DetectorGraphAnalysis>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class DetectorsResponse implements IChangeKey {
  // @DataMember
  public items: Detector[];

  // @DataMember
  public total: number;

  // @DataMember
  public page: number;

  // @DataMember
  public itemsPerPage: number;

  // @DataMember
  public changeKey: string;

  public constructor(init?: Partial<DetectorsResponse>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class DetectorInfo
  implements
    IDeviceVersion,
    ICustomerEntityViewModel,
    IExternalSystemEntity,
    INamedEntity,
    IChangeKey,
    IDescription,
    IModified,
    IEntity
{
  // @DataMember
  public detectorDeviceId?: string;

  // @DataMember
  public created: string;

  // @DataMember
  public lastChanges: string;

  // @DataMember
  public serialNumber: string;

  // @DataMember
  public lat: number;

  // @DataMember
  public lon: number;

  // @DataMember
  public direction: number;

  // @DataMember
  public imei: string;

  // @DataMember
  public deviceType: DetectorDeviceType;

  // @DataMember
  public userStatus: DetectorUserStatus;

  // @DataMember
  public detectorDeviceSession: DetectorDeviceSession;

  // @DataMember
  public softwareVersion: string;

  // @DataMember
  public firmwareVersion: string;

  // @DataMember
  public hardwareVersion: string;

  // @DataMember
  public detectionArea: string;

  // @DataMember
  public customerSerialNumber: string;

  // @DataMember
  public customerId: string;

  // @DataMember
  public customerName: string;

  // @DataMember
  public externalSystemId?: string;

  // @DataMember
  public externalSystemName: string;

  // @DataMember
  public externalSystemEntityId?: string;

  // @DataMember
  public name: string;

  // @DataMember
  public description: string;

  // @DataMember
  public changeKey: string;

  // @DataMember
  public createdBy: string;

  // @DataMember
  public modified: string;

  // @DataMember
  public modifiedBy: string;

  // @DataMember
  public id: string;

  // @DataMember
  public entityType: string;

  public constructor(init?: Partial<DetectorInfo>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class DetectorState {
  // @DataMember
  public id: string;

  // @DataMember
  public name: string;

  // @DataMember
  public description: string;

  // @DataMember
  public iconStatus: IconStatus;

  public constructor(init?: Partial<DetectorState>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class DetectorsStateResponse implements IChangeKey {
  // @DataMember
  public items: DetectorState[];

  // @DataMember
  public total: number;

  // @DataMember
  public page: number;

  // @DataMember
  public itemsPerPage: number;

  // @DataMember
  public changeKey: string;

  public constructor(init?: Partial<DetectorsStateResponse>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class DetectorData
  implements
    ICustomerEntityViewModel,
    IExternalSystemEntity,
    IChangeKey,
    IDescription,
    IModified,
    IEntity
{
  // @DataMember
  public detectorId?: string;

  // @DataMember
  public date: string;

  // @DataMember
  public value: number;

  // @DataMember
  public customerId: string;

  // @DataMember
  public customerName: string;

  // @DataMember
  public externalSystemId?: string;

  // @DataMember
  public externalSystemName: string;

  // @DataMember
  public externalSystemEntityId?: string;

  // @DataMember
  public description: string;

  // @DataMember
  public changeKey: string;

  // @DataMember
  public created: string;

  // @DataMember
  public createdBy: string;

  // @DataMember
  public modified: string;

  // @DataMember
  public modifiedBy: string;

  // @DataMember
  public id: string;

  // @DataMember
  public entityType: string;

  public constructor(init?: Partial<DetectorData>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class GetPtzCamerasLocationResponse implements IChangeKey {
  // @DataMember
  public items: PtzCameraLocation[];

  // @DataMember
  public total: number;

  // @DataMember
  public page: number;

  // @DataMember
  public itemsPerPage: number;

  // @DataMember
  public changeKey: string;

  public constructor(init?: Partial<GetPtzCamerasLocationResponse>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class PtzCamera
  implements
    ICustomerEntityViewModel,
    IExternalSystemEntity,
    INamedEntity,
    IChangeKey,
    IDescription,
    IModified,
    IEntity
{
  // @DataMember
  public lat: number;

  // @DataMember
  public lon: number;

  // @DataMember
  public url: string;

  // @DataMember
  public username: string;

  // @DataMember
  public password: string;

  // @DataMember
  public rtmp: string;

  // @DataMember
  public note: string;

  // @DataMember
  public customerId: string;

  // @DataMember
  public customerName: string;

  // @DataMember
  public externalSystemId?: string;

  // @DataMember
  public externalSystemName: string;

  // @DataMember
  public externalSystemEntityId?: string;

  // @DataMember
  public name: string;

  // @DataMember
  public description: string;

  // @DataMember
  public changeKey: string;

  // @DataMember
  public created: string;

  // @DataMember
  public createdBy: string;

  // @DataMember
  public modified: string;

  // @DataMember
  public modifiedBy: string;

  // @DataMember
  public id: string;

  // @DataMember
  public entityType: string;

  public constructor(init?: Partial<PtzCamera>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class EntityIntegration implements IChangeKey, IDescription, IModified, IEntity {
  // @DataMember
  public entityId: string;

  // @DataMember
  public customerIntegrationId: string;

  // @DataMember
  public externalId: string;

  // @DataMember
  public settings: string;

  // @DataMember
  public integrationId: string;

  // @DataMember
  public integrationName: string;

  // @DataMember
  public integrationType: string;

  // @DataMember
  public description: string;

  // @DataMember
  public changeKey: string;

  // @DataMember
  public created: string;

  // @DataMember
  public createdBy: string;

  // @DataMember
  public modified: string;

  // @DataMember
  public modifiedBy: string;

  // @DataMember
  public id: string;

  // @DataMember
  public entityType: string;

  public constructor(init?: Partial<EntityIntegration>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class GetEntityIntegrationResponse implements IChangeKey {
  // @DataMember
  public items: EntityIntegration[];

  // @DataMember
  public total: number;

  // @DataMember
  public page: number;

  // @DataMember
  public itemsPerPage: number;

  // @DataMember
  public changeKey: string;

  public constructor(init?: Partial<GetEntityIntegrationResponse>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class GetEntitiesResponse implements IChangeKey {
  // @DataMember
  public items: Object[];

  // @DataMember
  public total: number;

  // @DataMember
  public page: number;

  // @DataMember
  public itemsPerPage: number;

  // @DataMember
  public changeKey: string;

  public constructor(init?: Partial<GetEntitiesResponse>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class AuthenticateResponse implements IHasSessionId, IHasBearerToken {
  // @DataMember(Order=1)
  public userId: string;

  // @DataMember(Order=2)
  public sessionId: string;

  // @DataMember(Order=3)
  public userName: string;

  // @DataMember(Order=4)
  public displayName: string;

  // @DataMember(Order=5)
  public referrerUrl: string;

  // @DataMember(Order=6)
  public bearerToken: string;

  // @DataMember(Order=7)
  public refreshToken: string;

  // @DataMember(Order=8)
  public profileUrl: string;

  // @DataMember(Order=9)
  public roles: string[];

  // @DataMember(Order=10)
  public permissions: string[];

  // @DataMember(Order=11)
  public responseStatus: ResponseStatus;

  // @DataMember(Order=12)
  public meta: { [index: string]: string };

  public constructor(init?: Partial<AuthenticateResponse>) {
    (Object as any).assign(this, init);
  }
}

// @Route("/customer/{customerId}/cameras/groups", "GET")
// @DataContract
export class GetCameraGroupsRequest
  implements IReturn<GetEntityGroupsResponse>, ICustomerItemRequest
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public entityGroups: string[];

  // @DataMember
  public itemsIds: string[];

  // @DataMember
  public onlyOwnedItems: boolean;

  // @DataMember
  public page: number;

  // @DataMember
  public items: number;

  // @DataMember
  public sort: string;

  // @DataMember
  public filter: string;

  // @DataMember
  public propertiesTest: { [index: string]: number };

  public constructor(init?: Partial<GetCameraGroupsRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new GetEntityGroupsResponse();
  }
  public getTypeName() {
    return 'GetCameraGroupsRequest';
  }
}

// @Route("/customer/{customerId}/cameras/groups/{id}", "GET")
// @DataContract
export class GetCameraGroupRequest
  implements IReturn<EntityGroup>, ICustomerItemRequest, IBaseEntityItemRequest
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember(IsRequired=true)
  public id: string;

  public constructor(init?: Partial<GetCameraGroupRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new EntityGroup();
  }
  public getTypeName() {
    return 'GetCameraGroupRequest';
  }
}

// @Route("/customer/{customerId}/cameras/groups", "POST")
// @DataContract
export class CreateCameraGroupRequest
  implements IReturn<EntityGroup>, ICustomerItemRequest, IDescription
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public updateEntityGroups: boolean;

  // @DataMember
  public entityGroups: string[];

  // @DataMember(IsRequired=true)
  public entity: EntityGroup;

  // @DataMember
  public description: string;

  public constructor(init?: Partial<CreateCameraGroupRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new EntityGroup();
  }
  public getTypeName() {
    return 'CreateCameraGroupRequest';
  }
}

// @Route("/customer/{customerId}/cameras/groups/{id}", "PUT")
// @DataContract
export class UpdateCameraGroupRequest
  implements IReturn<EntityGroup>, ICustomerItemRequest, IBaseEntityItemRequest, IDescription
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public updateEntityGroups: boolean;

  // @DataMember
  public entityGroups: string[];

  // @DataMember(IsRequired=true)
  public id: string;

  // @DataMember(IsRequired=true)
  public entity: EntityGroup;

  // @DataMember
  public description: string;

  public constructor(init?: Partial<UpdateCameraGroupRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new EntityGroup();
  }
  public getTypeName() {
    return 'UpdateCameraGroupRequest';
  }
}

// @Route("/customer/{customerId}/cameras/groups", "DELETE")
// @DataContract
export class DeleteCameraGroupsRequest
  implements IReturn<number>, ICustomerItemRequest, IDescription
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public description: string;

  // @DataMember(IsRequired=true)
  public ids: string[];

  public constructor(init?: Partial<DeleteCameraGroupsRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return 0;
  }
  public getTypeName() {
    return 'DeleteCameraGroupsRequest';
  }
}

// @Route("/customer/{customerId}/cameras/groups/{id}", "GET")
// @DataContract
export class GetCameraGroupSharesRequest
  implements IReturn<GetEntityGroupSharesResponse>, IBaseEntityItemRequest, ICustomerItemRequest
{
  // @DataMember
  public id: string;

  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public entityGroups: string[];

  // @DataMember
  public itemsIds: string[];

  // @DataMember
  public onlyOwnedItems: boolean;

  // @DataMember
  public page: number;

  // @DataMember
  public items: number;

  // @DataMember
  public sort: string;

  // @DataMember
  public filter: string;

  // @DataMember
  public propertiesTest: { [index: string]: number };

  public constructor(init?: Partial<GetCameraGroupSharesRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new GetEntityGroupSharesResponse();
  }
  public getTypeName() {
    return 'GetCameraGroupSharesRequest';
  }
}

// @Route("/customer/{customerId}/cameras/groups/{id}", "PUT")
// @DataContract
export class UpdateCameraGroupShareRequest
  implements IReturn<EntityGroupPermissions>, ICustomerItemRequest, IDescription
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember(IsRequired=true)
  public id: string;

  // @DataMember(IsRequired=true)
  public entity: EntityGroupPermissions;

  // @DataMember
  public description: string;

  public constructor(init?: Partial<UpdateCameraGroupShareRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new EntityGroupPermissions();
  }
  public getTypeName() {
    return 'UpdateCameraGroupShareRequest';
  }
}

// @Route("/customer/{customerId}/camerasLocation", "GET")
// @DataContract
export class GetCamerasLocationRequest
  implements IReturn<GetCamerasLocationResponse>, ICustomerItemRequest
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public entityGroups: string[];

  // @DataMember
  public itemsIds: string[];

  // @DataMember
  public onlyOwnedItems: boolean;

  // @DataMember
  public page: number;

  // @DataMember
  public items: number;

  // @DataMember
  public sort: string;

  // @DataMember
  public filter: string;

  // @DataMember
  public propertiesTest: { [index: string]: number };

  public constructor(init?: Partial<GetCamerasLocationRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new GetCamerasLocationResponse();
  }
  public getTypeName() {
    return 'GetCamerasLocationRequest';
  }
}

// @Route("/customer/{customerId}/camera/{Id}", "GET")
// @DataContract
export class GetCameraRequest
  implements IReturn<Camera>, ICustomerItemRequest, IBaseEntityItemRequest
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember(IsRequired=true)
  public id: string;

  public constructor(init?: Partial<GetCameraRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new Camera();
  }
  public getTypeName() {
    return 'GetCameraRequest';
  }
}

// @Route("/customer/{customerId}/camera", "POST")
// @DataContract
export class CreateCameraRequest implements IReturn<Camera>, ICustomerItemRequest, IDescription {
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public updateEntityGroups: boolean;

  // @DataMember
  public entityGroups: string[];

  // @DataMember(IsRequired=true)
  public entity: Camera;

  // @DataMember
  public description: string;

  public constructor(init?: Partial<CreateCameraRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new Camera();
  }
  public getTypeName() {
    return 'CreateCameraRequest';
  }
}

// @Route("/customer/{customerId}/camera/{Id}", "PUT")
// @DataContract
export class UpdateCameraRequest
  implements IReturn<Camera>, ICustomerItemRequest, IBaseEntityItemRequest, IDescription
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public updateEntityGroups: boolean;

  // @DataMember
  public entityGroups: string[];

  // @DataMember(IsRequired=true)
  public id: string;

  // @DataMember(IsRequired=true)
  public entity: Camera;

  // @DataMember
  public description: string;

  public constructor(init?: Partial<UpdateCameraRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new Camera();
  }
  public getTypeName() {
    return 'UpdateCameraRequest';
  }
}

// @Route("/customer/{customerId}/cameras/{Ids}", "DELETE")
// @DataContract
export class DeleteCamerasRequest implements IReturn<number>, ICustomerItemRequest, IDescription {
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public description: string;

  // @DataMember(IsRequired=true)
  public ids: string[];

  public constructor(init?: Partial<DeleteCamerasRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return 0;
  }
  public getTypeName() {
    return 'DeleteCamerasRequest';
  }
}

// @Route("/customer/{customerId}/camera/{id}/currentUserPermissions", "GET")
// @DataContract
export class GetCameraCurrentUserPermissionsRequest
  implements IReturn<string[]>, ICustomerItemRequest, IBaseEntityItemRequest
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember(IsRequired=true)
  public id: string;

  public constructor(init?: Partial<GetCameraCurrentUserPermissionsRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new Array<string>();
  }
  public getTypeName() {
    return 'GetCameraCurrentUserPermissionsRequest';
  }
}

// @Route("/customer/{customerId}/detectorDevice/workMode", "GET")
// @DataContract
export class GetDetectorDevicesWorkModeRequest
  implements IReturn<GetDetectorDevicesWorkModeResponse>, ICustomerItemRequest
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public entityGroups: string[];

  // @DataMember
  public itemsIds: string[];

  // @DataMember
  public onlyOwnedItems: boolean;

  // @DataMember
  public page: number;

  // @DataMember
  public items: number;

  // @DataMember
  public sort: string;

  // @DataMember
  public filter: string;

  // @DataMember
  public propertiesTest: { [index: string]: number };

  public constructor(init?: Partial<GetDetectorDevicesWorkModeRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new GetDetectorDevicesWorkModeResponse();
  }
  public getTypeName() {
    return 'GetDetectorDevicesWorkModeRequest';
  }
}

// @Route("/detectorDeviceSelectedIncidents/available", "GET")
// @DataContract
export class GetAvailableToSelectDetectorDevicesRequest
  implements IReturn<DetectorDevicesPageResponse>
{
  // @DataMember
  public page: number;

  // @DataMember
  public items: number;

  // @DataMember
  public sort: string;

  // @DataMember
  public filter: string;

  // @DataMember
  public propertiesTest: { [index: string]: number };

  public constructor(init?: Partial<GetAvailableToSelectDetectorDevicesRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new DetectorDevicesPageResponse();
  }
  public getTypeName() {
    return 'GetAvailableToSelectDetectorDevicesRequest';
  }
}

// @Route("/detectorDeviceSelectedIncidents", "GET")
// @DataContract
export class GetDetectorDeviceSelectedIncidentsRequest
  implements IReturn<DetectorDevicesPageResponse>
{
  // @DataMember
  public page: number;

  // @DataMember
  public items: number;

  // @DataMember
  public sort: string;

  // @DataMember
  public filter: string;

  // @DataMember
  public propertiesTest: { [index: string]: number };

  public constructor(init?: Partial<GetDetectorDeviceSelectedIncidentsRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new DetectorDevicesPageResponse();
  }
  public getTypeName() {
    return 'GetDetectorDeviceSelectedIncidentsRequest';
  }
}

// @Route("/detectorDeviceSelectedIncidents/{DetectorDeviceIds}", "POST")
// @DataContract
export class AddToSelectedIncidentsDetectorDevicesRequest
  implements IReturn<DetectorDevicesPageResponse>
{
  // @DataMember
  public detectorDeviceIds: string[];

  public constructor(init?: Partial<AddToSelectedIncidentsDetectorDevicesRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new DetectorDevicesPageResponse();
  }
  public getTypeName() {
    return 'AddToSelectedIncidentsDetectorDevicesRequest';
  }
}

// @Route("/detectorDeviceSelectedIncidents/{Ids}", "DELETE")
// @DataContract
export class DeleteSelectedIncidentsDetectorDevicesRequest
  implements IReturn<number>, IDescription
{
  // @DataMember
  public description: string;

  // @DataMember(IsRequired=true)
  public ids: string[];

  public constructor(init?: Partial<DeleteSelectedIncidentsDetectorDevicesRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return 0;
  }
  public getTypeName() {
    return 'DeleteSelectedIncidentsDetectorDevicesRequest';
  }
}

// @Route("/detectorDeviceIncidents/{Link}/report", "GET")
// @DataContract
export class GetDetectorDeviceIncidentsReportRequest implements IReturn<Object> {
  // @DataMember
  public link: string;

  // @DataMember
  public incidentType?: DetectorIncidentType;

  // @DataMember
  public detectorDeviceId?: string;

  // @DataMember
  public detectorId?: string;

  // @DataMember
  public status?: IncidentStatus;

  // @DataMember
  public start?: string;

  // @DataMember
  public end?: string;

  // @DataMember
  public type: DataType;

  // @DataMember
  public timeZone: number;

  public constructor(init?: Partial<GetDetectorDeviceIncidentsReportRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new Object();
  }
  public getTypeName() {
    return 'GetDetectorDeviceIncidentsReportRequest';
  }
}

// @Route("/detectorDeviceIncidents/report/getLink", "GET")
// @DataContract
export class GetIncidentsReportDownloadLinkRequest implements IReturn<string> {
  // @DataMember
  public expiredIn: number;

  public constructor(init?: Partial<GetIncidentsReportDownloadLinkRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return '';
  }
  public getTypeName() {
    return 'GetIncidentsReportDownloadLinkRequest';
  }
}

// @Route("/detectorDeviceIncidents/WorkerInfo", "GET")
// @DataContract
export class GetDetectorDeviceIncidentsWorkerInfoRequest
  implements IReturn<DetectorDeviceIncidentWorkerInfo>
{
  public constructor(init?: Partial<GetDetectorDeviceIncidentsWorkerInfoRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new DetectorDeviceIncidentWorkerInfo();
  }
  public getTypeName() {
    return 'GetDetectorDeviceIncidentsWorkerInfoRequest';
  }
}

// @Route("/incidentAnalysisDetectors", "GET")
// @DataContract
export class GetIncidentsAnalysisDetectorsRequest
  implements IReturn<IncidentsAnalysisDetectorsResponse>
{
  // @DataMember
  public showIncluded: boolean;

  // @DataMember
  public showExcluded: boolean;

  // @DataMember
  public page: number;

  // @DataMember
  public items: number;

  // @DataMember
  public sort: string;

  // @DataMember
  public filter: string;

  // @DataMember
  public propertiesTest: { [index: string]: number };

  public constructor(init?: Partial<GetIncidentsAnalysisDetectorsRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new IncidentsAnalysisDetectorsResponse();
  }
  public getTypeName() {
    return 'GetIncidentsAnalysisDetectorsRequest';
  }
}

// @Route("/detectorDeviceIncidents", "GET")
// @DataContract
export class GetDetectorDeviceIncidentsRequest implements IReturn<DetectorDeviceIncidentsResponse> {
  // @DataMember
  public incidentType?: DetectorIncidentType;

  // @DataMember
  public detectorDeviceId?: string;

  // @DataMember
  public detectorId?: string;

  // @DataMember
  public status?: IncidentStatus;

  // @DataMember
  public start?: string;

  // @DataMember
  public end?: string;

  // @DataMember
  public page: number;

  // @DataMember
  public items: number;

  // @DataMember
  public sort: string;

  // @DataMember
  public filter: string;

  // @DataMember
  public propertiesTest: { [index: string]: number };

  public constructor(init?: Partial<GetDetectorDeviceIncidentsRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new DetectorDeviceIncidentsResponse();
  }
  public getTypeName() {
    return 'GetDetectorDeviceIncidentsRequest';
  }
}

// @Route("/detectorDeviceIncident/{Id}", "GET")
// @DataContract
export class GetDetectorDeviceIncidentRequest
  implements IReturn<DetectorDeviceIncident>, IBaseEntityItemRequest
{
  // @DataMember(IsRequired=true)
  public id: string;

  public constructor(init?: Partial<GetDetectorDeviceIncidentRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new DetectorDeviceIncident();
  }
  public getTypeName() {
    return 'GetDetectorDeviceIncidentRequest';
  }
}

// @Route("/detectorDeviceIncident", "POST")
// @DataContract
export class CreateDetectorDeviceIncidentRequest
  implements IReturn<DetectorDeviceIncident>, IDescription
{
  // @DataMember(IsRequired=true)
  public entity: DetectorDeviceIncident;

  // @DataMember
  public description: string;

  public constructor(init?: Partial<CreateDetectorDeviceIncidentRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new DetectorDeviceIncident();
  }
  public getTypeName() {
    return 'CreateDetectorDeviceIncidentRequest';
  }
}

// @Route("/detectorDeviceIncident/{Id}", "PUT")
// @DataContract
export class UpdateDetectorDeviceIncidentRequest
  implements IReturn<DetectorDeviceIncident>, IBaseEntityItemRequest, IDescription
{
  // @DataMember(IsRequired=true)
  public id: string;

  // @DataMember(IsRequired=true)
  public entity: DetectorDeviceIncident;

  // @DataMember
  public description: string;

  public constructor(init?: Partial<UpdateDetectorDeviceIncidentRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new DetectorDeviceIncident();
  }
  public getTypeName() {
    return 'UpdateDetectorDeviceIncidentRequest';
  }
}

// @Route("/detectorDeviceIncident/{Ids}", "DELETE")
// @DataContract
export class DeleteDetectorDeviceIncidentRequest implements IReturn<number>, IDescription {
  // @DataMember
  public description: string;

  // @DataMember(IsRequired=true)
  public ids: string[];

  public constructor(init?: Partial<DeleteDetectorDeviceIncidentRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return 0;
  }
  public getTypeName() {
    return 'DeleteDetectorDeviceIncidentRequest';
  }
}

// @Route("/detectorDevice/{Id}/graph")
// @DataContract
export class GetDetectorDeviceGraphRequest implements IReturn<DetectorGraph> {
  // @DataMember(IsRequired=true)
  public id: string;

  // @DataMember(IsRequired=true)
  public startTime: string;

  // @DataMember(IsRequired=true)
  public endTime: string;

  // @DataMember
  public groupBySeconds: number;

  // @DataMember
  public smooth: number;

  // @DataMember
  public lane?: number;

  public constructor(init?: Partial<GetDetectorDeviceGraphRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new DetectorGraph();
  }
  public getTypeName() {
    return 'GetDetectorDeviceGraphRequest';
  }
}

// @Route("/detectorDevice/{Id}/report")
// @DataContract
export class GetDetectorDeviceGraphReportRequest implements IReturn<Uint8Array> {
  // @DataMember(IsRequired=true)
  public type: GraphDataType;

  // @DataMember
  public timeZone: number;

  // @DataMember(IsRequired=true)
  public id: string;

  // @DataMember(IsRequired=true)
  public startTime: string;

  // @DataMember(IsRequired=true)
  public endTime: string;

  // @DataMember
  public groupBySeconds: number;

  // @DataMember
  public smooth: number;

  public constructor(init?: Partial<GetDetectorDeviceGraphReportRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new Uint8Array(0);
  }
  public getTypeName() {
    return 'GetDetectorDeviceGraphReportRequest';
  }
}

// @Route("/detectorDevice/{Id}/internalGraph")
// @DataContract
export class GetDetectorDeviceInternalGraphRequest implements IReturn<DetectorInternalGraph> {
  // @DataMember(IsRequired=true)
  public id: string;

  // @DataMember(IsRequired=true)
  public startTime: string;

  // @DataMember(IsRequired=true)
  public endTime: string;

  // @DataMember
  public groupBySeconds: number;

  // @DataMember
  public smooth: number;

  public constructor(init?: Partial<GetDetectorDeviceInternalGraphRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new DetectorInternalGraph();
  }
  public getTypeName() {
    return 'GetDetectorDeviceInternalGraphRequest';
  }
}

// @Route("/detectorDevice/{Id}/internalReport")
// @DataContract
export class GetDetectorDeviceInternalGraphReportRequest implements IReturn<Uint8Array> {
  // @DataMember(IsRequired=true)
  public type: GraphDataType;

  // @DataMember
  public timeZone: number;

  // @DataMember(IsRequired=true)
  public id: string;

  // @DataMember(IsRequired=true)
  public startTime: string;

  // @DataMember(IsRequired=true)
  public endTime: string;

  // @DataMember
  public groupBySeconds: number;

  // @DataMember
  public smooth: number;

  public constructor(init?: Partial<GetDetectorDeviceInternalGraphReportRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new Uint8Array(0);
  }
  public getTypeName() {
    return 'GetDetectorDeviceInternalGraphReportRequest';
  }
}

// @Route("/detectorDevice/{id}/image", "GET")
// @DataContract
export class GetDetectorDeviceImageRequest implements IReturn<Uint8Array> {
  // @DataMember(IsRequired=true)
  public id: string;

  // @DataMember
  public forceUpdate: boolean;

  public constructor(init?: Partial<GetDetectorDeviceImageRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new Uint8Array(0);
  }
  public getTypeName() {
    return 'GetDetectorDeviceImageRequest';
  }
}

// @Route("/detectorDeviceSessionInfo/{Id}", "GET")
// @DataContract
export class GetDetectorDeviceSessionInfoRequest
  implements IReturn<DetectorDeviceSession>, IBaseEntityItemRequest
{
  // @DataMember(IsRequired=true)
  public id: string;

  public constructor(init?: Partial<GetDetectorDeviceSessionInfoRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new DetectorDeviceSession();
  }
  public getTypeName() {
    return 'GetDetectorDeviceSessionInfoRequest';
  }
}

// @Route("/customer/{customerId}/detectorDeviceStatusSummary", "GET")
// @DataContract
export class GetDetectorDeviceStatusSummaryRequest implements IReturn<DetectorDeviceStatusSummary> {
  // @DataMember
  public customerId: string;

  public constructor(init?: Partial<GetDetectorDeviceStatusSummaryRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new DetectorDeviceStatusSummary();
  }
  public getTypeName() {
    return 'GetDetectorDeviceStatusSummaryRequest';
  }
}

// @Route("/customer/{customerId}/detectorDevice/exist", "GET")
// @DataContract
export class GetDetectorDeviceExistRequest implements IReturn<boolean> {
  // @DataMember(IsRequired=true)
  public type: DetectorDeviceType;

  // @DataMember(IsRequired=true)
  public number: string;

  public constructor(init?: Partial<GetDetectorDeviceExistRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return false;
  }
  public getTypeName() {
    return 'GetDetectorDeviceExistRequest';
  }
}

// @Route("/customer/{customerId}/detectorDevices", "GET")
// @DataContract
export class GetDetectorDevicesRequest
  implements IReturn<GetDetectorDevicesResponse>, ICustomerItemRequest
{
  // @DataMember
  public onlyUsed: boolean;

  // @DataMember
  public onlyFree: boolean;

  // @DataMember
  public deviceType: DetectorDeviceType;

  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public entityGroups: string[];

  // @DataMember
  public itemsIds: string[];

  // @DataMember
  public onlyOwnedItems: boolean;

  // @DataMember
  public page: number;

  // @DataMember
  public items: number;

  // @DataMember
  public sort: string;

  // @DataMember
  public filter: string;

  // @DataMember
  public propertiesTest: { [index: string]: number };

  public constructor(init?: Partial<GetDetectorDevicesRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new GetDetectorDevicesResponse();
  }
  public getTypeName() {
    return 'GetDetectorDevicesRequest';
  }
}

// @Route("/customer/{customerId}/detectorDevice/{id}", "GET")
// @DataContract
export class GetDetectorDeviceRequest
  implements IReturn<DetectorDevice>, ICustomerItemRequest, IBaseEntityItemRequest
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember(IsRequired=true)
  public id: string;

  public constructor(init?: Partial<GetDetectorDeviceRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new DetectorDevice();
  }
  public getTypeName() {
    return 'GetDetectorDeviceRequest';
  }
}

// @Route("/customer/{customerId}/detectorDevice/{id}", "PUT")
// @DataContract
export class UpdateDetectorDeviceRequest
  implements IReturn<DetectorDevice>, ICustomerItemRequest, IBaseEntityItemRequest, IDescription
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public updateEntityGroups: boolean;

  // @DataMember
  public entityGroups: string[];

  // @DataMember(IsRequired=true)
  public id: string;

  // @DataMember(IsRequired=true)
  public entity: DetectorDevice;

  // @DataMember
  public description: string;

  public constructor(init?: Partial<UpdateDetectorDeviceRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new DetectorDevice();
  }
  public getTypeName() {
    return 'UpdateDetectorDeviceRequest';
  }
}

// @Route("/customer/{customerId}/detectorDevice", "POST")
// @DataContract
export class CreateDetectorDeviceRequest
  implements IReturn<DetectorDevice>, ICustomerItemRequest, IDescription
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public updateEntityGroups: boolean;

  // @DataMember
  public entityGroups: string[];

  // @DataMember(IsRequired=true)
  public entity: DetectorDevice;

  // @DataMember
  public description: string;

  public constructor(init?: Partial<CreateDetectorDeviceRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new DetectorDevice();
  }
  public getTypeName() {
    return 'CreateDetectorDeviceRequest';
  }
}

// @Route("/customer/{customerId}/detectorDevices/{Ids}", "DELETE")
// @DataContract
export class DeleteDetectorDevicesRequest
  implements IReturn<number>, ICustomerItemRequest, IDescription
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public description: string;

  // @DataMember(IsRequired=true)
  public ids: string[];

  public constructor(init?: Partial<DeleteDetectorDevicesRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return 0;
  }
  public getTypeName() {
    return 'DeleteDetectorDevicesRequest';
  }
}

// @Route("/customer/{customerId}/detectorDevice/{id}/disconnect", "POST")
// @DataContract
export class DisconnectDetectorDeviceRequest
  implements IReturn<SessionInfo>, ICustomerItemRequest, IBaseEntityItemRequest
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember(IsRequired=true)
  public id: string;

  public constructor(init?: Partial<DisconnectDetectorDeviceRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new SessionInfo();
  }
  public getTypeName() {
    return 'DisconnectDetectorDeviceRequest';
  }
}

// @Route("/customer/{customerId}/detectorDevice/{id}/changeCustomer", "POST")
// @DataContract
export class DetectorDeviceChangeCustomerRequest
  implements IReturn<boolean>, IDescription, ICustomerItemRequest, IBaseEntityItemRequest
{
  // @DataMember
  public description: string;

  // @DataMember
  public newCustomerId: string;

  // @DataMember
  public customerId: string;

  // @DataMember
  public id: string;

  public constructor(init?: Partial<DetectorDeviceChangeCustomerRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return false;
  }
  public getTypeName() {
    return 'DetectorDeviceChangeCustomerRequest';
  }
}

// @Route("/customer/{customerId}/detectorDevice/{id}/eventTypes", "GET")
// @DataContract
export class GetDetectorDeviceEventTypesRequest
  implements IReturn<EntityEventLogType[]>, IBaseEntityItemRequest
{
  // @DataMember
  public id: string;

  public constructor(init?: Partial<GetDetectorDeviceEventTypesRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new Array<EntityEventLogType>();
  }
  public getTypeName() {
    return 'GetDetectorDeviceEventTypesRequest';
  }
}

// @Route("/customer/{customerId}/detectorDevice/{id}/eventLog", "GET")
// @DataContract
export class GetDetectorDeviceEventLogRequest
  implements IReturn<GetDeviceEventLogResponse>, IBaseEntityItemRequest, ICustomerItemRequest
{
  // @DataMember
  public id: string;

  // @DataMember
  public start?: string;

  // @DataMember
  public end?: string;

  // @DataMember
  public filterEvents: number[];

  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public entityGroups: string[];

  // @DataMember
  public itemsIds: string[];

  // @DataMember
  public onlyOwnedItems: boolean;

  // @DataMember
  public page: number;

  // @DataMember
  public items: number;

  // @DataMember
  public sort: string;

  // @DataMember
  public filter: string;

  // @DataMember
  public propertiesTest: { [index: string]: number };

  public constructor(init?: Partial<GetDetectorDeviceEventLogRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new GetDeviceEventLogResponse();
  }
  public getTypeName() {
    return 'GetDetectorDeviceEventLogRequest';
  }
}

// @Route("/customer/{customerId}/detectorDevice/{id}/workMode", "PUT")
// @DataContract
export class UpdateDetectorDeviceWorkModeRequest
  implements
    IReturn<DetectorDeviceWorkModeOptions>,
    ICustomerItemRequest,
    IBaseEntityItemRequest,
    IDescription
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public updateEntityGroups: boolean;

  // @DataMember
  public entityGroups: string[];

  // @DataMember(IsRequired=true)
  public id: string;

  // @DataMember(IsRequired=true)
  public entity: DetectorDeviceWorkModeOptions;

  // @DataMember
  public description: string;

  public constructor(init?: Partial<UpdateDetectorDeviceWorkModeRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new DetectorDeviceWorkModeOptions();
  }
  public getTypeName() {
    return 'UpdateDetectorDeviceWorkModeRequest';
  }
}

// @Route("/customer/{customerId}/detectorDevice/{id}/workMode", "GET")
// @DataContract
export class GetDetectorDeviceWorkModeRequest
  implements IReturn<DetectorDeviceWorkModeOptions>, ICustomerItemRequest, IBaseEntityItemRequest
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember(IsRequired=true)
  public id: string;

  public constructor(init?: Partial<GetDetectorDeviceWorkModeRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new DetectorDeviceWorkModeOptions();
  }
  public getTypeName() {
    return 'GetDetectorDeviceWorkModeRequest';
  }
}

// @Route("/detectors/intensityReport", "GET")
// @DataContract
export class GetDetectorsIntensityReport
  implements IReturn<Uint8Array>, ICustomerItemRequest, IBaseEntityItemRequest
{
  // @DataMember(IsRequired=true)
  public from: string;

  // @DataMember(IsRequired=true)
  public to: string;

  // @DataMember
  public timeZoneOffsetFromUtc: number;

  // @DataMember
  public detectorsIds: string[];

  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember(IsRequired=true)
  public id: string;

  public constructor(init?: Partial<GetDetectorsIntensityReport>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new Uint8Array(0);
  }
  public getTypeName() {
    return 'GetDetectorsIntensityReport';
  }
}

// @Route("/customer/{customerId}/tableParams/{Name}", "GET")
// @DataContract
export class GetTableParamsRequest implements IReturn<Object>, ICustomerItemRequest {
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public name: string;

  public constructor(init?: Partial<GetTableParamsRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new Object();
  }
  public getTypeName() {
    return 'GetTableParamsRequest';
  }
}

// @Route("/customer/{customerId}/tableParams/{Name}", "POST")
// @DataContract
export class UploadTableParamsRequest implements IReturn<string>, ICustomerItemRequest {
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public name: string;

  public constructor(init?: Partial<UploadTableParamsRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return '';
  }
  public getTypeName() {
    return 'UploadTableParamsRequest';
  }
}

// @Route("/detectorsMaintenanceDetails", "GET")
// @DataContract
export class GetDetectorsMaintenanceDetailsRequest
  implements IReturn<DetectorsMaintenanceDetailsResponse>
{
  // @DataMember
  public typeId?: string;

  // @DataMember
  public maintenanceId?: string;

  // @DataMember
  public page: number;

  // @DataMember
  public items: number;

  // @DataMember
  public sort: string;

  // @DataMember
  public filter: string;

  // @DataMember
  public propertiesTest: { [index: string]: number };

  public constructor(init?: Partial<GetDetectorsMaintenanceDetailsRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new DetectorsMaintenanceDetailsResponse();
  }
  public getTypeName() {
    return 'GetDetectorsMaintenanceDetailsRequest';
  }
}

// @Route("/detectorsMaintenanceDetails/{Id}", "GET")
// @DataContract
export class GetDetectorMaintenanceDetailsRequest
  implements IReturn<DetectorMaintenanceDetails>, IBaseEntityItemRequest
{
  // @DataMember(IsRequired=true)
  public id: string;

  public constructor(init?: Partial<GetDetectorMaintenanceDetailsRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new DetectorMaintenanceDetails();
  }
  public getTypeName() {
    return 'GetDetectorMaintenanceDetailsRequest';
  }
}

// @Route("/detectorsMaintenanceDetails", "POST")
// @DataContract
export class CreateDetectorMaintenanceDetailsRequest
  implements IReturn<DetectorMaintenanceDetails>, IDescription
{
  // @DataMember(IsRequired=true)
  public entity: DetectorMaintenanceDetails;

  // @DataMember
  public description: string;

  public constructor(init?: Partial<CreateDetectorMaintenanceDetailsRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new DetectorMaintenanceDetails();
  }
  public getTypeName() {
    return 'CreateDetectorMaintenanceDetailsRequest';
  }
}

// @Route("/detectorsMaintenanceDetails/{Id}", "PUT")
// @DataContract
export class UpdateDetectorMaintenanceDetailsRequest
  implements IReturn<DetectorMaintenanceDetails>, IBaseEntityItemRequest, IDescription
{
  // @DataMember(IsRequired=true)
  public id: string;

  // @DataMember(IsRequired=true)
  public entity: DetectorMaintenanceDetails;

  // @DataMember
  public description: string;

  public constructor(init?: Partial<UpdateDetectorMaintenanceDetailsRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new DetectorMaintenanceDetails();
  }
  public getTypeName() {
    return 'UpdateDetectorMaintenanceDetailsRequest';
  }
}

// @Route("/detectorsMaintenanceDetails/{Ids}", "DELETE")
// @DataContract
export class DeleteDetectorsMaintenanceDetailsRequest implements IReturn<number>, IDescription {
  // @DataMember
  public description: string;

  // @DataMember(IsRequired=true)
  public ids: string[];

  public constructor(init?: Partial<DeleteDetectorsMaintenanceDetailsRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return 0;
  }
  public getTypeName() {
    return 'DeleteDetectorsMaintenanceDetailsRequest';
  }
}

// @Route("/detectorsMaintenanceType", "GET")
// @DataContract
export class GetDetectorsMaintenanceTypeRequest
  implements IReturn<DetectorsMaintenanceTypeResponse>
{
  // @DataMember
  public page: number;

  // @DataMember
  public items: number;

  // @DataMember
  public sort: string;

  // @DataMember
  public filter: string;

  // @DataMember
  public propertiesTest: { [index: string]: number };

  public constructor(init?: Partial<GetDetectorsMaintenanceTypeRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new DetectorsMaintenanceTypeResponse();
  }
  public getTypeName() {
    return 'GetDetectorsMaintenanceTypeRequest';
  }
}

// @Route("/detectorsMaintenanceType/{Id}", "GET")
// @DataContract
export class GetDetectorMaintenanceTypeRequest
  implements IReturn<DetectorMaintenanceType>, IBaseEntityItemRequest
{
  // @DataMember(IsRequired=true)
  public id: string;

  public constructor(init?: Partial<GetDetectorMaintenanceTypeRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new DetectorMaintenanceType();
  }
  public getTypeName() {
    return 'GetDetectorMaintenanceTypeRequest';
  }
}

// @Route("/detectorsMaintenanceType", "POST")
// @DataContract
export class CreateDetectorMaintenanceTypeRequest
  implements IReturn<DetectorMaintenanceType>, IDescription
{
  // @DataMember(IsRequired=true)
  public entity: DetectorMaintenanceType;

  // @DataMember
  public description: string;

  public constructor(init?: Partial<CreateDetectorMaintenanceTypeRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new DetectorMaintenanceType();
  }
  public getTypeName() {
    return 'CreateDetectorMaintenanceTypeRequest';
  }
}

// @Route("/detectorsMaintenanceType/{Id}", "PUT")
// @DataContract
export class UpdateDetectorMaintenanceTypeRequest
  implements IReturn<DetectorMaintenanceType>, IBaseEntityItemRequest, IDescription
{
  // @DataMember(IsRequired=true)
  public id: string;

  // @DataMember(IsRequired=true)
  public entity: DetectorMaintenanceType;

  // @DataMember
  public description: string;

  public constructor(init?: Partial<UpdateDetectorMaintenanceTypeRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new DetectorMaintenanceType();
  }
  public getTypeName() {
    return 'UpdateDetectorMaintenanceTypeRequest';
  }
}

// @Route("/detectorsMaintenanceType/{Ids}", "DELETE")
// @DataContract
export class DeleteDetectorsMaintenanceTypeRequest implements IReturn<number>, IDescription {
  // @DataMember
  public description: string;

  // @DataMember(IsRequired=true)
  public ids: string[];

  public constructor(init?: Partial<DeleteDetectorsMaintenanceTypeRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return 0;
  }
  public getTypeName() {
    return 'DeleteDetectorsMaintenanceTypeRequest';
  }
}

// @Route("/detectorsMaintenance", "GET")
// @DataContract
export class GetDetectorsMaintenanceRequest implements IReturn<DetectorsMaintenanceResponse> {
  // @DataMember
  public detectorId?: string;

  // @DataMember
  public start?: string;

  // @DataMember
  public end?: string;

  // @DataMember
  public maintenanceTypeId?: string;

  // @DataMember
  public page: number;

  // @DataMember
  public items: number;

  // @DataMember
  public sort: string;

  // @DataMember
  public filter: string;

  // @DataMember
  public propertiesTest: { [index: string]: number };

  public constructor(init?: Partial<GetDetectorsMaintenanceRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new DetectorsMaintenanceResponse();
  }
  public getTypeName() {
    return 'GetDetectorsMaintenanceRequest';
  }
}

// @Route("/detectorsMaintenance/{Id}", "GET")
// @DataContract
export class GetDetectorMaintenanceRequest
  implements IReturn<DetectorMaintenance>, IBaseEntityItemRequest
{
  // @DataMember(IsRequired=true)
  public id: string;

  public constructor(init?: Partial<GetDetectorMaintenanceRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new DetectorMaintenance();
  }
  public getTypeName() {
    return 'GetDetectorMaintenanceRequest';
  }
}

// @Route("/detectorsMaintenance", "POST")
// @DataContract
export class CreateDetectorMaintenanceRequest
  implements IReturn<DetectorMaintenance>, IDescription
{
  // @DataMember(IsRequired=true)
  public entity: DetectorMaintenance;

  // @DataMember
  public description: string;

  public constructor(init?: Partial<CreateDetectorMaintenanceRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new DetectorMaintenance();
  }
  public getTypeName() {
    return 'CreateDetectorMaintenanceRequest';
  }
}

// @Route("/detectorsMaintenance/{Id}", "PUT")
// @DataContract
export class UpdateDetectorMaintenanceRequest
  implements IReturn<DetectorMaintenance>, IBaseEntityItemRequest, IDescription
{
  // @DataMember(IsRequired=true)
  public id: string;

  // @DataMember(IsRequired=true)
  public entity: DetectorMaintenance;

  // @DataMember
  public description: string;

  public constructor(init?: Partial<UpdateDetectorMaintenanceRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new DetectorMaintenance();
  }
  public getTypeName() {
    return 'UpdateDetectorMaintenanceRequest';
  }
}

// @Route("/detectorsMaintenance/{Ids}", "DELETE")
// @DataContract
export class DeleteDetectorsMaintenanceRequest implements IReturn<number>, IDescription {
  // @DataMember
  public description: string;

  // @DataMember(IsRequired=true)
  public ids: string[];

  public constructor(init?: Partial<DeleteDetectorsMaintenanceRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return 0;
  }
  public getTypeName() {
    return 'DeleteDetectorsMaintenanceRequest';
  }
}

// @DataContract
export class SetDetectorReplacementRequest
  implements
    IReturn<DetectorReplacement>,
    ICustomerItemRequest,
    IBaseEntityItemRequest,
    IDescription
{
  // @DataMember
  public setDetectorCoordsToDevice: boolean;

  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public updateEntityGroups: boolean;

  // @DataMember
  public entityGroups: string[];

  // @DataMember(IsRequired=true)
  public id: string;

  // @DataMember(IsRequired=true)
  public entity: DetectorReplacement;

  // @DataMember
  public description: string;

  public constructor(init?: Partial<SetDetectorReplacementRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new DetectorReplacement();
  }
  public getTypeName() {
    return 'SetDetectorReplacementRequest';
  }
}

// @Route("/customer/{customerId}/detectorsReplacements", "GET")
// @DataContract
export class GetDetectorReplacementsRequest
  implements IReturn<DetectorReplacementsResponse>, ICustomerItemRequest
{
  // @DataMember(IsRequired=true)
  public detectorId: string;

  // @DataMember
  public from?: string;

  // @DataMember
  public to?: string;

  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public entityGroups: string[];

  // @DataMember
  public itemsIds: string[];

  // @DataMember
  public onlyOwnedItems: boolean;

  // @DataMember
  public page: number;

  // @DataMember
  public items: number;

  // @DataMember
  public sort: string;

  // @DataMember
  public filter: string;

  // @DataMember
  public propertiesTest: { [index: string]: number };

  public constructor(init?: Partial<GetDetectorReplacementsRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new DetectorReplacementsResponse();
  }
  public getTypeName() {
    return 'GetDetectorReplacementsRequest';
  }
}

// @Route("/customer/{customerId}/detectorsReplacements/{Id}", "GET")
// @DataContract
export class GetDetectorReplacementRequest
  implements IReturn<DetectorReplacement>, ICustomerItemRequest, IBaseEntityItemRequest
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember(IsRequired=true)
  public id: string;

  public constructor(init?: Partial<GetDetectorReplacementRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new DetectorReplacement();
  }
  public getTypeName() {
    return 'GetDetectorReplacementRequest';
  }
}

// @Route("/detector/{id}/graphCsv", "GET")
// @DataContract
export class GetDetectorGraphAnalysisRequest implements IReturn<DetectorGraphAnalysis> {
  // @DataMember(IsRequired=true)
  public id: string;

  // @DataMember(IsRequired=true)
  public startTime: string;

  // @DataMember(IsRequired=true)
  public endTime: string;

  // @DataMember
  public groupBySeconds: number;

  // @DataMember
  public smooth: number;

  // @DataMember
  public lane?: number;

  public constructor(init?: Partial<GetDetectorGraphAnalysisRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new DetectorGraphAnalysis();
  }
  public getTypeName() {
    return 'GetDetectorGraphAnalysisRequest';
  }
}

// @Route("/detector/{id}/internalGraph", "GET")
// @DataContract
export class GetDetectorInternalGraphRequest implements IReturn<DetectorInternalGraph> {
  // @DataMember(IsRequired=true)
  public id: string;

  // @DataMember(IsRequired=true)
  public startTime: string;

  // @DataMember(IsRequired=true)
  public endTime: string;

  // @DataMember
  public groupBySeconds: number;

  // @DataMember
  public smooth: number;

  public constructor(init?: Partial<GetDetectorInternalGraphRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new DetectorInternalGraph();
  }
  public getTypeName() {
    return 'GetDetectorInternalGraphRequest';
  }
}

// @Route("/detector/{id}/graph", "GET")
// @DataContract
export class GetDetectorGraphRequest implements IReturn<DetectorGraph> {
  // @DataMember(IsRequired=true)
  public id: string;

  // @DataMember(IsRequired=true)
  public startTime: string;

  // @DataMember(IsRequired=true)
  public endTime: string;

  // @DataMember
  public groupBySeconds: number;

  // @DataMember
  public smooth: number;

  // @DataMember
  public lane?: number;

  public constructor(init?: Partial<GetDetectorGraphRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new DetectorGraph();
  }
  public getTypeName() {
    return 'GetDetectorGraphRequest';
  }
}

// @Route("/detector/{id}/graphCsv", "GET")
// @DataContract
export class GetDetectorGraphArchiveRequest implements IReturn<Uint8Array> {
  // @DataMember
  public type: GraphDataType;

  // @DataMember
  public timeZone: number;

  // @DataMember(IsRequired=true)
  public id: string;

  // @DataMember(IsRequired=true)
  public startTime: string;

  // @DataMember(IsRequired=true)
  public endTime: string;

  // @DataMember
  public groupBySeconds: number;

  // @DataMember
  public smooth: number;

  // @DataMember
  public lane?: number;

  public constructor(init?: Partial<GetDetectorGraphArchiveRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new Uint8Array(0);
  }
  public getTypeName() {
    return 'GetDetectorGraphArchiveRequest';
  }
}

// @Route("/detector/{id}/federalReport", "GET")
// @DataContract
export class GetDetectorGraphFederalReportRequest implements IReturn<Uint8Array> {
  // @DataMember
  public timeZone: number;

  // @DataMember(IsRequired=true)
  public id: string;

  // @DataMember(IsRequired=true)
  public startTime: string;

  // @DataMember(IsRequired=true)
  public endTime: string;

  // @DataMember
  public groupBySeconds: number;

  // @DataMember
  public smooth: number;

  // @DataMember
  public lane?: number;

  public constructor(init?: Partial<GetDetectorGraphFederalReportRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new Uint8Array(0);
  }
  public getTypeName() {
    return 'GetDetectorGraphFederalReportRequest';
  }
}

// @Route("/customer/{customerId}/detectors/groups", "GET")
// @DataContract
export class GetDetectorGroupsRequest
  implements IReturn<GetEntityGroupsResponse>, ICustomerItemRequest
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public entityGroups: string[];

  // @DataMember
  public itemsIds: string[];

  // @DataMember
  public onlyOwnedItems: boolean;

  // @DataMember
  public page: number;

  // @DataMember
  public items: number;

  // @DataMember
  public sort: string;

  // @DataMember
  public filter: string;

  // @DataMember
  public propertiesTest: { [index: string]: number };

  public constructor(init?: Partial<GetDetectorGroupsRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new GetEntityGroupsResponse();
  }
  public getTypeName() {
    return 'GetDetectorGroupsRequest';
  }
}

// @Route("/customer/{customerId}/detectors/groups/{id}", "GET")
// @DataContract
export class GetDetectorGroupRequest
  implements IReturn<EntityGroup>, ICustomerItemRequest, IBaseEntityItemRequest
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember(IsRequired=true)
  public id: string;

  public constructor(init?: Partial<GetDetectorGroupRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new EntityGroup();
  }
  public getTypeName() {
    return 'GetDetectorGroupRequest';
  }
}

// @Route("/customer/{customerId}/detectors/groups", "POST")
// @DataContract
export class CreateDetectorGroupRequest
  implements IReturn<EntityGroup>, ICustomerItemRequest, IDescription
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public updateEntityGroups: boolean;

  // @DataMember
  public entityGroups: string[];

  // @DataMember(IsRequired=true)
  public entity: EntityGroup;

  // @DataMember
  public description: string;

  public constructor(init?: Partial<CreateDetectorGroupRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new EntityGroup();
  }
  public getTypeName() {
    return 'CreateDetectorGroupRequest';
  }
}

// @Route("/customer/{customerId}/detectors/groups/{id}", "PUT")
// @DataContract
export class UpdateDetectorGroupRequest
  implements IReturn<EntityGroup>, ICustomerItemRequest, IBaseEntityItemRequest, IDescription
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public updateEntityGroups: boolean;

  // @DataMember
  public entityGroups: string[];

  // @DataMember(IsRequired=true)
  public id: string;

  // @DataMember(IsRequired=true)
  public entity: EntityGroup;

  // @DataMember
  public description: string;

  public constructor(init?: Partial<UpdateDetectorGroupRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new EntityGroup();
  }
  public getTypeName() {
    return 'UpdateDetectorGroupRequest';
  }
}

// @Route("/customer/{customerId}/detectors/groups", "DELETE")
// @DataContract
export class DeleteDetectorGroupsRequest
  implements IReturn<number>, ICustomerItemRequest, IDescription
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public description: string;

  // @DataMember(IsRequired=true)
  public ids: string[];

  public constructor(init?: Partial<DeleteDetectorGroupsRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return 0;
  }
  public getTypeName() {
    return 'DeleteDetectorGroupsRequest';
  }
}

// @Route("/customer/{customerId}/detectors/groups/{id}", "GET")
// @DataContract
export class GetDetectorGroupSharesRequest
  implements IReturn<GetEntityGroupSharesResponse>, IBaseEntityItemRequest, ICustomerItemRequest
{
  // @DataMember
  public id: string;

  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public entityGroups: string[];

  // @DataMember
  public itemsIds: string[];

  // @DataMember
  public onlyOwnedItems: boolean;

  // @DataMember
  public page: number;

  // @DataMember
  public items: number;

  // @DataMember
  public sort: string;

  // @DataMember
  public filter: string;

  // @DataMember
  public propertiesTest: { [index: string]: number };

  public constructor(init?: Partial<GetDetectorGroupSharesRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new GetEntityGroupSharesResponse();
  }
  public getTypeName() {
    return 'GetDetectorGroupSharesRequest';
  }
}

// @Route("/customer/{customerId}/detectors/groups/{id}", "PUT")
// @DataContract
export class UpdateDetectorGroupShareRequest
  implements IReturn<EntityGroupPermissions>, ICustomerItemRequest, IDescription
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember(IsRequired=true)
  public id: string;

  // @DataMember(IsRequired=true)
  public entity: EntityGroupPermissions;

  // @DataMember
  public description: string;

  public constructor(init?: Partial<UpdateDetectorGroupShareRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new EntityGroupPermissions();
  }
  public getTypeName() {
    return 'UpdateDetectorGroupShareRequest';
  }
}

// @Route("/customer/{customerId}/detectors", "GET")
// @DataContract
export class GetDetectorsRequest implements IReturn<DetectorsResponse>, ICustomerItemRequest {
  // @DataMember
  public lat?: number;

  // @DataMember
  public lon?: number;

  // @DataMember
  public distance?: number;

  // @DataMember
  public userStatus?: DetectorUserStatus;

  // @DataMember
  public filterCustomerId?: string;

  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public entityGroups: string[];

  // @DataMember
  public itemsIds: string[];

  // @DataMember
  public onlyOwnedItems: boolean;

  // @DataMember
  public page: number;

  // @DataMember
  public items: number;

  // @DataMember
  public sort: string;

  // @DataMember
  public filter: string;

  // @DataMember
  public propertiesTest: { [index: string]: number };

  public constructor(init?: Partial<GetDetectorsRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new DetectorsResponse();
  }
  public getTypeName() {
    return 'GetDetectorsRequest';
  }
}

// @Route("/customer/{customerId}/detector/{Id}", "GET")
// @DataContract
export class GetDetectorRequest
  implements IReturn<Detector>, ICustomerItemRequest, IBaseEntityItemRequest
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember(IsRequired=true)
  public id: string;

  public constructor(init?: Partial<GetDetectorRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new Detector();
  }
  public getTypeName() {
    return 'GetDetectorRequest';
  }
}

// @Route("/customer/{customerId}/detector", "POST")
// @DataContract
export class CreateDetectorRequest
  implements IReturn<Detector>, ICustomerItemRequest, IDescription
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public updateEntityGroups: boolean;

  // @DataMember
  public entityGroups: string[];

  // @DataMember(IsRequired=true)
  public entity: Detector;

  // @DataMember
  public description: string;

  public constructor(init?: Partial<CreateDetectorRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new Detector();
  }
  public getTypeName() {
    return 'CreateDetectorRequest';
  }
}

// @Route("/customer/{customerId}/detector/{Id}", "PUT")
// @DataContract
export class UpdateDetectorRequest
  implements IReturn<Detector>, ICustomerItemRequest, IBaseEntityItemRequest, IDescription
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public updateEntityGroups: boolean;

  // @DataMember
  public entityGroups: string[];

  // @DataMember(IsRequired=true)
  public id: string;

  // @DataMember(IsRequired=true)
  public entity: Detector;

  // @DataMember
  public description: string;

  public constructor(init?: Partial<UpdateDetectorRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new Detector();
  }
  public getTypeName() {
    return 'UpdateDetectorRequest';
  }
}

// @Route("/customer/{customerId}/detectors/{Ids}", "DELETE")
// @DataContract
export class DeleteDetectorsRequest implements IReturn<number>, ICustomerItemRequest, IDescription {
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public description: string;

  // @DataMember(IsRequired=true)
  public ids: string[];

  public constructor(init?: Partial<DeleteDetectorsRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return 0;
  }
  public getTypeName() {
    return 'DeleteDetectorsRequest';
  }
}

// @Route("/detector/Lane/{Id}", "GET")
// @DataContract
export class GetDetectorLanesRequest implements IReturn<number> {
  // @DataMember(IsRequired=true)
  public id: string;

  public constructor(init?: Partial<GetDetectorLanesRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return 0;
  }
  public getTypeName() {
    return 'GetDetectorLanesRequest';
  }
}

// @Route("/customer/{customerId}/detector/exist", "GET")
// @DataContract
export class GetDetectorExistRequest implements IReturn<boolean> {
  // @DataMember(IsRequired=true)
  public name: string;

  public constructor(init?: Partial<GetDetectorExistRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return false;
  }
  public getTypeName() {
    return 'GetDetectorExistRequest';
  }
}

// @Route("/customer/{customerId}/detectorInfo/{Id}", "GET")
// @DataContract
export class GetDetectorInfoRequest
  implements IReturn<DetectorInfo>, ICustomerItemRequest, IBaseEntityItemRequest
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember(IsRequired=true)
  public id: string;

  public constructor(init?: Partial<GetDetectorInfoRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new DetectorInfo();
  }
  public getTypeName() {
    return 'GetDetectorInfoRequest';
  }
}

// @Route("/customer/{customerId}/detectorsState/{Id}", "GET")
// @DataContract
export class GetDetectorStateRequest
  implements IReturn<DetectorState>, ICustomerItemRequest, IBaseEntityItemRequest
{
  // @DataMember
  public forceUpdate: boolean;

  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember(IsRequired=true)
  public id: string;

  public constructor(init?: Partial<GetDetectorStateRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new DetectorState();
  }
  public getTypeName() {
    return 'GetDetectorStateRequest';
  }
}

// @Route("/customer/{customerId}/detectorsState", "GET")
// @DataContract
export class GetDetectorsStateRequest
  implements IReturn<DetectorsStateResponse>, ICustomerItemRequest
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public entityGroups: string[];

  // @DataMember
  public itemsIds: string[];

  // @DataMember
  public onlyOwnedItems: boolean;

  // @DataMember
  public page: number;

  // @DataMember
  public items: number;

  // @DataMember
  public sort: string;

  // @DataMember
  public filter: string;

  // @DataMember
  public propertiesTest: { [index: string]: number };

  public constructor(init?: Partial<GetDetectorsStateRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new DetectorsStateResponse();
  }
  public getTypeName() {
    return 'GetDetectorsStateRequest';
  }
}

// @Route("/detector/{id}/image", "GET")
// @DataContract
export class GetDetectorImage implements IReturn<Uint8Array> {
  // @DataMember(IsRequired=true)
  public id: string;

  // @DataMember
  public forceUpdate: boolean;

  // @DataMember
  public showAreas: boolean;

  public constructor(init?: Partial<GetDetectorImage>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new Uint8Array(0);
  }
  public getTypeName() {
    return 'GetDetectorImage';
  }
}

// @Route("/customer/{customerId}/detector/{id}/changeCustomer", "POST")
// @DataContract
export class DetectorChangeCustomerRequest
  implements IReturn<boolean>, IDescription, ICustomerItemRequest, IBaseEntityItemRequest
{
  // @DataMember
  public description: string;

  // @DataMember
  public newCustomerId: string;

  // @DataMember
  public customerId: string;

  // @DataMember
  public id: string;

  public constructor(init?: Partial<DetectorChangeCustomerRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return false;
  }
  public getTypeName() {
    return 'DetectorChangeCustomerRequest';
  }
}

// @Route("/customer/{customerId}/detector/{id}/currentUserPermissions", "GET")
// @DataContract
export class GetDetectorCurrentUserPermissionsRequest
  implements IReturn<string[]>, ICustomerItemRequest, IBaseEntityItemRequest
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember(IsRequired=true)
  public id: string;

  public constructor(init?: Partial<GetDetectorCurrentUserPermissionsRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new Array<string>();
  }
  public getTypeName() {
    return 'GetDetectorCurrentUserPermissionsRequest';
  }
}

// @Route("/customer/{customerId}/detectorData", "POST")
// @DataContract
export class CreateDetectorDataRequest
  implements IReturn<DetectorData>, ICustomerItemRequest, IDescription
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public updateEntityGroups: boolean;

  // @DataMember
  public entityGroups: string[];

  // @DataMember(IsRequired=true)
  public entity: DetectorData;

  // @DataMember
  public description: string;

  public constructor(init?: Partial<CreateDetectorDataRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new DetectorData();
  }
  public getTypeName() {
    return 'CreateDetectorDataRequest';
  }
}

// @Route("/customer/{customerId}/ptzCameras/groups", "GET")
// @DataContract
export class GetPtzCameraGroupsRequest
  implements IReturn<GetEntityGroupsResponse>, ICustomerItemRequest
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public entityGroups: string[];

  // @DataMember
  public itemsIds: string[];

  // @DataMember
  public onlyOwnedItems: boolean;

  // @DataMember
  public page: number;

  // @DataMember
  public items: number;

  // @DataMember
  public sort: string;

  // @DataMember
  public filter: string;

  // @DataMember
  public propertiesTest: { [index: string]: number };

  public constructor(init?: Partial<GetPtzCameraGroupsRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new GetEntityGroupsResponse();
  }
  public getTypeName() {
    return 'GetPtzCameraGroupsRequest';
  }
}

// @Route("/customer/{customerId}/ptzCameras/groups/{id}", "GET")
// @DataContract
export class GetPtzCameraGroupRequest
  implements IReturn<EntityGroup>, ICustomerItemRequest, IBaseEntityItemRequest
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember(IsRequired=true)
  public id: string;

  public constructor(init?: Partial<GetPtzCameraGroupRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new EntityGroup();
  }
  public getTypeName() {
    return 'GetPtzCameraGroupRequest';
  }
}

// @Route("/customer/{customerId}/ptzCameras/groups", "POST")
// @DataContract
export class CreatePtzCameraGroupRequest
  implements IReturn<EntityGroup>, ICustomerItemRequest, IDescription
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public updateEntityGroups: boolean;

  // @DataMember
  public entityGroups: string[];

  // @DataMember(IsRequired=true)
  public entity: EntityGroup;

  // @DataMember
  public description: string;

  public constructor(init?: Partial<CreatePtzCameraGroupRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new EntityGroup();
  }
  public getTypeName() {
    return 'CreatePtzCameraGroupRequest';
  }
}

// @Route("/customer/{customerId}/ptzCameras/groups/{id}", "PUT")
// @DataContract
export class UpdatePtzCameraGroupRequest
  implements IReturn<EntityGroup>, ICustomerItemRequest, IBaseEntityItemRequest, IDescription
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public updateEntityGroups: boolean;

  // @DataMember
  public entityGroups: string[];

  // @DataMember(IsRequired=true)
  public id: string;

  // @DataMember(IsRequired=true)
  public entity: EntityGroup;

  // @DataMember
  public description: string;

  public constructor(init?: Partial<UpdatePtzCameraGroupRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new EntityGroup();
  }
  public getTypeName() {
    return 'UpdatePtzCameraGroupRequest';
  }
}

// @Route("/customer/{customerId}/ptzCameras/groups", "DELETE")
// @DataContract
export class DeletePtzCameraGroupsRequest
  implements IReturn<number>, ICustomerItemRequest, IDescription
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public description: string;

  // @DataMember(IsRequired=true)
  public ids: string[];

  public constructor(init?: Partial<DeletePtzCameraGroupsRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return 0;
  }
  public getTypeName() {
    return 'DeletePtzCameraGroupsRequest';
  }
}

// @Route("/customer/{customerId}/ptzCameras/groups/{id}", "GET")
// @DataContract
export class GetPtzCameraGroupSharesRequest
  implements IReturn<GetEntityGroupSharesResponse>, IBaseEntityItemRequest, ICustomerItemRequest
{
  // @DataMember
  public id: string;

  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public entityGroups: string[];

  // @DataMember
  public itemsIds: string[];

  // @DataMember
  public onlyOwnedItems: boolean;

  // @DataMember
  public page: number;

  // @DataMember
  public items: number;

  // @DataMember
  public sort: string;

  // @DataMember
  public filter: string;

  // @DataMember
  public propertiesTest: { [index: string]: number };

  public constructor(init?: Partial<GetPtzCameraGroupSharesRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new GetEntityGroupSharesResponse();
  }
  public getTypeName() {
    return 'GetPtzCameraGroupSharesRequest';
  }
}

// @Route("/customer/{customerId}/ptzCameras/groups/{id}", "PUT")
// @DataContract
export class UpdatePtzCameraGroupShareRequest
  implements IReturn<EntityGroupPermissions>, ICustomerItemRequest, IDescription
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember(IsRequired=true)
  public id: string;

  // @DataMember(IsRequired=true)
  public entity: EntityGroupPermissions;

  // @DataMember
  public description: string;

  public constructor(init?: Partial<UpdatePtzCameraGroupShareRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new EntityGroupPermissions();
  }
  public getTypeName() {
    return 'UpdatePtzCameraGroupShareRequest';
  }
}

// @Route("/customer/{customerId}/ptzCamerasLocation", "GET")
// @DataContract
export class GetPtzCamerasLocationRequest
  implements IReturn<GetPtzCamerasLocationResponse>, ICustomerItemRequest
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public entityGroups: string[];

  // @DataMember
  public itemsIds: string[];

  // @DataMember
  public onlyOwnedItems: boolean;

  // @DataMember
  public page: number;

  // @DataMember
  public items: number;

  // @DataMember
  public sort: string;

  // @DataMember
  public filter: string;

  // @DataMember
  public propertiesTest: { [index: string]: number };

  public constructor(init?: Partial<GetPtzCamerasLocationRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new GetPtzCamerasLocationResponse();
  }
  public getTypeName() {
    return 'GetPtzCamerasLocationRequest';
  }
}

// @Route("/customer/{customerId}/ptzCamera/{Id}", "GET")
// @DataContract
export class GetPtzCameraRequest
  implements IReturn<PtzCamera>, ICustomerItemRequest, IBaseEntityItemRequest
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember(IsRequired=true)
  public id: string;

  public constructor(init?: Partial<GetPtzCameraRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new PtzCamera();
  }
  public getTypeName() {
    return 'GetPtzCameraRequest';
  }
}

// @Route("/camera/{Id}/command", "POST")
// @DataContract
export class PtzCameraCommandRequest implements IReturn<boolean> {
  // @DataMember(IsRequired=true)
  public id: string;

  // @DataMember
  public command: CameraCommand;

  public constructor(init?: Partial<PtzCameraCommandRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return false;
  }
  public getTypeName() {
    return 'PtzCameraCommandRequest';
  }
}

// @Route("/customer/{customerId}/ptzCamera", "POST")
// @DataContract
export class CreatePtzCameraRequest
  implements IReturn<PtzCamera>, ICustomerItemRequest, IDescription
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public updateEntityGroups: boolean;

  // @DataMember
  public entityGroups: string[];

  // @DataMember(IsRequired=true)
  public entity: PtzCamera;

  // @DataMember
  public description: string;

  public constructor(init?: Partial<CreatePtzCameraRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new PtzCamera();
  }
  public getTypeName() {
    return 'CreatePtzCameraRequest';
  }
}

// @Route("/customer/{customerId}/camera/{Id}", "PUT")
// @DataContract
export class UpdatePtzCameraRequest
  implements IReturn<PtzCamera>, ICustomerItemRequest, IBaseEntityItemRequest, IDescription
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public updateEntityGroups: boolean;

  // @DataMember
  public entityGroups: string[];

  // @DataMember(IsRequired=true)
  public id: string;

  // @DataMember(IsRequired=true)
  public entity: PtzCamera;

  // @DataMember
  public description: string;

  public constructor(init?: Partial<UpdatePtzCameraRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new PtzCamera();
  }
  public getTypeName() {
    return 'UpdatePtzCameraRequest';
  }
}

// @Route("/customer/{customerId}/ptzCameras/{Ids}", "DELETE")
// @DataContract
export class DeletePtzCamerasRequest
  implements IReturn<number>, ICustomerItemRequest, IDescription
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public description: string;

  // @DataMember(IsRequired=true)
  public ids: string[];

  public constructor(init?: Partial<DeletePtzCamerasRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return 0;
  }
  public getTypeName() {
    return 'DeletePtzCamerasRequest';
  }
}

// @Route("/customer/{customerId}/ptzCamera/{id}/currentUserPermissions", "GET")
// @DataContract
export class GetPtzCameraCurrentUserPermissionsRequest
  implements IReturn<string[]>, ICustomerItemRequest, IBaseEntityItemRequest
{
  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember(IsRequired=true)
  public id: string;

  public constructor(init?: Partial<GetPtzCameraCurrentUserPermissionsRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new Array<string>();
  }
  public getTypeName() {
    return 'GetPtzCameraCurrentUserPermissionsRequest';
  }
}

// @Route("/healthStatus", "GET")
// @DataContract
export class GetHealthStatusRequest implements IReturn<string> {
  public constructor(init?: Partial<GetHealthStatusRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return '';
  }
  public getTypeName() {
    return 'GetHealthStatusRequest';
  }
}

// @Route("/customer/{customerId}/integration/{customerIntegrationId}/entities", "GET")
// @DataContract
export class GetEntityIntegrationsRequest
  implements
    IReturn<GetEntityIntegrationResponse>,
    ICustomerIntegrationIdRequest,
    ICustomerItemRequest
{
  // @DataMember
  public customerIntegrationId: string;

  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public entityGroups: string[];

  // @DataMember
  public itemsIds: string[];

  // @DataMember
  public onlyOwnedItems: boolean;

  // @DataMember
  public page: number;

  // @DataMember
  public items: number;

  // @DataMember
  public sort: string;

  // @DataMember
  public filter: string;

  // @DataMember
  public propertiesTest: { [index: string]: number };

  public constructor(init?: Partial<GetEntityIntegrationsRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new GetEntityIntegrationResponse();
  }
  public getTypeName() {
    return 'GetEntityIntegrationsRequest';
  }
}

// @Route("/customer/{customerId}/integration/{customerIntegrationId}/entity/{id}", "GET")
// @DataContract
export class GetEntityIntegrationRequest
  implements
    IReturn<EntityIntegration>,
    ICustomerIntegrationIdRequest,
    ICustomerItemRequest,
    IBaseEntityItemRequest
{
  // @DataMember
  public customerIntegrationId: string;

  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember(IsRequired=true)
  public id: string;

  public constructor(init?: Partial<GetEntityIntegrationRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new EntityIntegration();
  }
  public getTypeName() {
    return 'GetEntityIntegrationRequest';
  }
}

// @Route("/customer/{customerId}/integration/{customerIntegrationId}/entities", "POST")
// @DataContract
export class CreateEntityIntegrationRequest
  implements
    IReturn<EntityIntegration>,
    ICustomerIntegrationIdRequest,
    ICustomerItemRequest,
    IDescription
{
  // @DataMember
  public customerIntegrationId: string;

  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public updateEntityGroups: boolean;

  // @DataMember
  public entityGroups: string[];

  // @DataMember(IsRequired=true)
  public entity: EntityIntegration;

  // @DataMember
  public description: string;

  public constructor(init?: Partial<CreateEntityIntegrationRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new EntityIntegration();
  }
  public getTypeName() {
    return 'CreateEntityIntegrationRequest';
  }
}

// @Route("/customer/{customerId}/integration/{customerIntegrationId}/entity/{id}", "PUT")
// @DataContract
export class UpdateEntityIntegrationRequest
  implements
    IReturn<EntityIntegration>,
    ICustomerIntegrationIdRequest,
    ICustomerItemRequest,
    IBaseEntityItemRequest,
    IDescription
{
  // @DataMember
  public customerIntegrationId: string;

  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public updateEntityGroups: boolean;

  // @DataMember
  public entityGroups: string[];

  // @DataMember(IsRequired=true)
  public id: string;

  // @DataMember(IsRequired=true)
  public entity: EntityIntegration;

  // @DataMember
  public description: string;

  public constructor(init?: Partial<UpdateEntityIntegrationRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new EntityIntegration();
  }
  public getTypeName() {
    return 'UpdateEntityIntegrationRequest';
  }
}

// @Route("/entity/{id}/properties", "GET")
// @DataContract
export class GetEntityPropertiesRequest implements IReturn<any>, IBaseEntityItemRequest {
  // @DataMember(IsRequired=true)
  public id: string;

  public constructor(init?: Partial<GetEntityPropertiesRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return {};
  }
  public getTypeName() {
    return 'GetEntityPropertiesRequest';
  }
}

// @Route("/entity/{id}/properties", "PUT")
// @DataContract
export class UpdateEntityPropertiesRequest implements IReturn<any> {
  // @DataMember(IsRequired=true)
  public id: string;

  // @DataMember
  public description: string;

  // @DataMember
  public entity: { [index: string]: string };

  public constructor(init?: Partial<UpdateEntityPropertiesRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return {};
  }
  public getTypeName() {
    return 'UpdateEntityPropertiesRequest';
  }
}

// @Route("/entity/{id}/property/{property}", "GET")
// @DataContract
export class GetEntityPropertyRequest implements IReturn<string> {
  // @DataMember
  public property: string;

  // @DataMember(IsRequired=true)
  public id: string;

  public constructor(init?: Partial<GetEntityPropertyRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return '';
  }
  public getTypeName() {
    return 'GetEntityPropertyRequest';
  }
}

// @Route("/entity/{id}/property/{property}", "PUT")
// @DataContract
export class UpdateEntityPropertyRequest implements IReturn<string> {
  // @DataMember(IsRequired=true)
  public id: string;

  // @DataMember
  public description: string;

  // @DataMember
  public property: string;

  // @DataMember
  public value: string;

  public constructor(init?: Partial<UpdateEntityPropertyRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return '';
  }
  public getTypeName() {
    return 'UpdateEntityPropertyRequest';
  }
}

// @Route("/entities/property/{property}", "GET")
// @DataContract
export class GetEntitiesPropertyRequest implements IReturn<any> {
  // @DataMember
  public property: string;

  // @DataMember
  public filter: string;

  public constructor(init?: Partial<GetEntitiesPropertyRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return {};
  }
  public getTypeName() {
    return 'GetEntitiesPropertyRequest';
  }
}

// @Route("/customer/{customerId}/entity/{Id}/groups", "GET")
// @DataContract
export class GetItemEntityGroupsRequest
  implements IReturn<GetEntityGroupsResponse>, ICustomerItemRequest
{
  // @DataMember
  public id: string;

  // @DataMember(IsRequired=true)
  public customerId: string;

  // @DataMember
  public entityGroups: string[];

  // @DataMember
  public itemsIds: string[];

  // @DataMember
  public onlyOwnedItems: boolean;

  // @DataMember
  public page: number;

  // @DataMember
  public items: number;

  // @DataMember
  public sort: string;

  // @DataMember
  public filter: string;

  // @DataMember
  public propertiesTest: { [index: string]: number };

  public constructor(init?: Partial<GetItemEntityGroupsRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new GetEntityGroupsResponse();
  }
  public getTypeName() {
    return 'GetItemEntityGroupsRequest';
  }
}

// @Route("/entities/remoteRepository/{repositoryId}/data", "GET")
// @DataContract
export class GetEntitiesRequest implements IReturn<GetEntitiesResponse> {
  // @DataMember
  public repositoryId: string;

  // @DataMember
  public modified?: string;

  // @DataMember
  public page: number;

  // @DataMember
  public items: number;

  // @DataMember
  public sort: string;

  // @DataMember
  public filter: string;

  // @DataMember
  public propertiesTest: { [index: string]: number };

  public constructor(init?: Partial<GetEntitiesRequest>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new GetEntitiesResponse();
  }
  public getTypeName() {
    return 'GetEntitiesRequest';
  }
}

/**
 * Sign In
 */
// @Route("/auth", "OPTIONS,GET,POST,DELETE")
// @Route("/auth/{provider}", "OPTIONS,GET,POST,DELETE")
// @Api(Description="Sign In")
// @DataContract
export class Authenticate implements IReturn<AuthenticateResponse>, IPost {
  /**
   * AuthProvider, e.g. credentials
   */
  // @DataMember(Order=1)
  public provider: string;

  // @DataMember(Order=2)
  public state: string;

  // @DataMember(Order=3)
  public oauth_token: string;

  // @DataMember(Order=4)
  public oauth_verifier: string;

  // @DataMember(Order=5)
  public userName: string;

  // @DataMember(Order=6)
  public password: string;

  // @DataMember(Order=7)
  public rememberMe?: boolean;

  // @DataMember(Order=9)
  public errorView: string;

  // @DataMember(Order=10)
  public nonce: string;

  // @DataMember(Order=11)
  public uri: string;

  // @DataMember(Order=12)
  public response: string;

  // @DataMember(Order=13)
  public qop: string;

  // @DataMember(Order=14)
  public nc: string;

  // @DataMember(Order=15)
  public cnonce: string;

  // @DataMember(Order=17)
  public accessToken: string;

  // @DataMember(Order=18)
  public accessTokenSecret: string;

  // @DataMember(Order=19)
  public scope: string;

  // @DataMember(Order=20)
  public meta: { [index: string]: string };

  public constructor(init?: Partial<Authenticate>) {
    (Object as any).assign(this, init);
  }
  public createResponse() {
    return new AuthenticateResponse();
  }
  public getTypeName() {
    return 'Authenticate';
  }
}
