import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';

import {
  Component,
  ChangeDetectionStrategy,
  Input,
  OnInit,
} from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

import { TranslateService } from '@ngx-translate/core';
import { PERMISSIONS_TRANSLATE } from '../../utils/permissions';

import { EntityGroupInfo } from '../../dtos/hub.dtos';
import { EntityGroupPermissions } from '../../dtos/hub.dtos.ext';

@Component({
  selector: 'group-share-edit',
  templateUrl: './group-share-edit.component.html',
  styles: [``],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GroupShareEditComponent implements OnInit {
  @Input()
  model: EntityGroupInfo;

  @Input()
  permissions: string[];

  @Input()
  share: EntityGroupPermissions;

  selectedPermissions: string[] = [];

  constructor(
    private _dialog: MatDialogRef<GroupShareEditComponent>,
    public translate: TranslateService
  ) {}

  ngOnInit() {
    this.selectedPermissions = [...this.share.permissions];
  }

  close() {
    this._dialog.close(false);
  }

  apply() {
    this.share.permissions = this.selectedPermissions;
    this._dialog.close(true);
  }

  getPermissionChecked(id: string) {
    return this.selectedPermissions.some((m) => m == id);
  }

  setPermissionChecked(id: string, checked: boolean) {
    if (checked) {
      this.selectedPermissions.every((m) => m != id) &&
        this.selectedPermissions.push(id);
    } else {
      this.selectedPermissions.some((m) => m == id) &&
        (this.selectedPermissions = this.selectedPermissions.filter(
          (m) => m != id
        ));
    }
  }

  getPermissionName(id: string) {
    const parts = id.split('.');
    const name = parts[1];
    return this.translate.instant(PERMISSIONS_TRANSLATE[name] ?? name);
  }
}
