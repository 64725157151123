import { Injectable } from '@angular/core';

import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import {
  IDataSourceService,
  setPageParams,
  getPageReturn,
  IPageParams,
  IPageReturn,
} from 'projects/msu-its-web-common/src/data/data-source';

import { TlcApiService } from './tlc-api.service';

import {
  CreateRouteRequest,
  DeleteRouteRequest,
  GetRouteRequest,
  GetRoutesRequest,
  Route,
  RouteInfo,
  UpdateRouteRequest,
} from '../dtos/tlc.dtos';

@Injectable()
export class RouteService implements IDataSourceService<Route, RouteInfo> {
  constructor(private _api: TlcApiService) {}

  getPagedList(params: IPageParams, customerId: string): Observable<IPageReturn<RouteInfo>> {
    if (!customerId) {
      return of(null);
    }

    const request = new GetRoutesRequest();
    request.customerId = customerId;

    setPageParams(request, params);
    return this._api.get(request).pipe(
      map((m) => {
        return getPageReturn(m);
      })
    );
  }
  getList(customerId: string, filter?: string, groupIds?: string[], count = 0) {
    const request = new GetRoutesRequest();
    request.customerId = customerId;
    request.entityGroups = groupIds;

    request.page = 0;
    request.items = count;
    request.sort = 'name';
    request.filter = filter;

    return this._api.get(request).pipe(
      map((m) => {
        return m.items;
      })
    );
  }

  get(id: string, customerId: string) {
    const request = new GetRouteRequest();
    request.id = id;
    request.customerId = customerId;
    return this._api.get(request);
  }
  add(
    model: Route,
    params: {
      customerId: string;
      groups: string[];
      updateGroups: boolean;
    }
  ) {
    const request = new CreateRouteRequest();
    request.entity = model;
    request.customerId = params.customerId;
    request.entityGroups = params.groups;
    request.updateEntityGroups = params.updateGroups;
    return this._api.post(request);
  }
  update(model: Route, params: { customerId: string; groups: string[]; updateGroups: boolean }) {
    const request = new UpdateRouteRequest();
    request.id = model.id;
    request.entity = model;
    request.customerId = params.customerId;
    request.entityGroups = params.groups;
    request.updateEntityGroups = params.updateGroups;
    return this._api.put(request);
  }
  delete(ids: string[], customerId: string) {
    const request = new DeleteRouteRequest();
    request.ids = ids;
    request.customerId = customerId;
    return this._api.delete(request);
  }
  exist(name: string, customerId: string): Observable<boolean> {
    // const request = new ExistRouteNameRequest();
    // request.name = name;
    // request.customerId = customerId;
    // return this._api.get(request);
    return of(false);
  }
}
