import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';

import {
  Component,
  ChangeDetectionStrategy,
  Input,
  OnInit,
  OnDestroy,
  ChangeDetectorRef,
} from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

import { forkJoin, Observable, Subject } from 'rxjs';
import { finalize } from 'rxjs/operators';

import {
  CustomerInfo,
  EntityGroupInfo,
} from '../../dtos/hub.dtos';
import { CustomerService } from '../../services/customer.service';

import { TranslateService } from '@ngx-translate/core';
import { DialogService } from '../../services/dialog.service';
import { FlashService } from '../../services/flash.service';

import { GroupShareEditComponent } from './group-share-edit.component';
import { EntityGroupPermissions } from '../../dtos/hub.dtos.ext';

export interface IGroupShareService {
  getList: (
    id: string,
    customerId: string
  ) => Observable<EntityGroupPermissions[]>;
  update: (
    model: EntityGroupPermissions,
    customerId: string
  ) => Observable<EntityGroupPermissions>;
}

@Component({
  selector: 'group-shares',
  templateUrl: './group-shares.component.html',
  styles: [
    `
      .action {
        opacity: 0.1;
      }
      .mat-hover:hover .action {
        opacity: 0.9;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GroupSharesComponent implements OnInit, OnDestroy {
  _destroy = new Subject();

  loading = false;
  shares: EntityGroupPermissions[];
  customers: CustomerInfo[] = [];

  @Input()
  model: EntityGroupInfo;
  @Input()
  customerId: string;
  @Input()
  entityType = 'trafficObject';
  @Input()
  entityActions: string[] = ['read', 'control', 'update', 'delete'];
  @Input()
  groupShareService: IGroupShareService;

  constructor(
    private _dialog: MatDialogRef<GroupSharesComponent>,
    private _dialogService: DialogService,
    private _flashService: FlashService,
    private _customerService: CustomerService,
    private _changeDetector: ChangeDetectorRef,
    public translate: TranslateService
  ) {}
  ngOnInit() {
    this._load();
  }

  ngOnDestroy() {
    this._destroy.next();
    this._destroy.complete();
  }

  _load() {
    this.loading = true;
    this._changeDetector.markForCheck();
    forkJoin([
      this.groupShareService.getList(this.model.id, this.customerId),
      this._customerService.getList(),
    ])
      .pipe(
        finalize(() => {
          this.loading = false;
          this._changeDetector.markForCheck();
        })
      )
      .subscribe((result) => {
        this.shares = result[0];

        this.customers = result[1].filter((m) => {
          return (
            m.id != this.customerId &&
            !this.shares.some((n) => m.id == n.customerId)
          );
        });

        this._changeDetector.markForCheck();
      });
  }

  close() {
    if (this._dialog) {
      this._dialog.close(false);
    } else {
      window.close();
    }
  }

  create(customerId: string) {
    const customer = this.customers.find((m) => m.id == customerId);
    const newShare = new EntityGroupPermissions({
      entityGroupId: this.model.id,
      customerId: customer.id,
      customerName: customer.name,
      permissions: [],
    });
    this.edit(newShare);
  }

  edit(model: EntityGroupPermissions) {
    const dialog = this._dialogService.dialog.open(GroupShareEditComponent, {
      disableClose: true,
    });
    dialog.componentInstance.share = model;
    dialog.componentInstance.model = this.model;
    dialog.componentInstance.permissions = this.entityActions.map(
      (m) => `${this.entityType}.${m}`
    );
    dialog.afterClosed().subscribe((result) => {
      if (result) {
        this._saveShare(model);
      }
    });
  }

  delete(model: EntityGroupPermissions) {
    model.permissions = [];
    this._saveShare(model);
  }

  _saveShare(model: EntityGroupPermissions) {
    this.loading = true;
    this._changeDetector.markForCheck();

    this.groupShareService
      .update(model, this.customerId)
      .pipe(
        finalize(() => {
          this.loading = false;
          this._changeDetector.markForCheck();
        })
      )
      .subscribe(() => {
        this._flashService.success(
          this.translate.instant('COMMON.CHANGES_SAVED')
        );
        this._load();
      });
  }
}
