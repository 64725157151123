import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  Input,
} from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { GraphDataType } from "../../dtos/tfms.dtos";
import { DetectorService } from "../../services/detector.service";
import { marker as _ } from "@biesbjerg/ngx-translate-extract-marker";
import { MatDialogRef } from "@angular/material/dialog";
import { DatePipe } from "@angular/common";
import { TokenService } from "projects/msu-its-web-common/src/services/token.service";
import { TfmsApiService } from "../../services/tfms-api.service";
import { APP_ENVIRONMENT } from "projects/msu-its-web-common/src/utils/shared-consts";
import { DateAdapter } from "@angular/material/core";
import { TranslateService } from "@ngx-translate/core";
import { combineLatest } from "rxjs";

const TYPE_EXPORT = [
  { type: GraphDataType.Csv, name: _("DETECTORS.EXPORT_CSV") },
  { type: GraphDataType.Json, name: _("DETECTORS.EXPORT_JSON") },
  { type: GraphDataType.Xls, name: _("DETECTORS.EXPORT_XLS") },
  { type: GraphDataType.Xml, name: _("DETECTORS.EXPORT_XML") },
];

@Component({
  selector: "detector-export",
  templateUrl: "./detector-export.component.html",
  styles: [``],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DetectorExportComponent implements AfterViewInit {
  @Input()
  modelId: string;
  @Input()
  modelName: string;
  typesFile = TYPE_EXPORT;
  lane = 0;

  get lanes() {
    return [1, 2, 3, 4, 5, 6, 7, 8];
  }

  formGroup: FormGroup = this._formBuilder.group({
    TypeFile: [null, [Validators.required]],
    NameFile: [null, [Validators.required]],
    // NameFile: [null, [Validators.required, Validators.pattern('^[a-zA-Zа-яА-Я0-9_!]+$')]],
    LaneSelect: [null, [Validators.required]],
    Date: [null, [Validators.required]],
  });
  get typeFile() {
    return this.formGroup.get("TypeFile");
  }
  get nameFile() {
    return this.formGroup.get("NameFile");
  }
  get date() {
    return this.formGroup.get("Date");
  }
  get LaneSelect() {
    return this.formGroup.get("LaneSelect");
  }

  get locale() {
    return this.translate.currentLang;
  }
  get name() {
    return this.modelName.replace(/\s+/g, "").replace(/[\\\\/:*?\"<>|]/g, "");
  }

  constructor(
    private dateAdapter: DateAdapter<any>,
    private translate: TranslateService,
    private _route: ActivatedRoute,
    private _dialogRef: MatDialogRef<DetectorExportComponent>,
    private _detectorService: DetectorService,
    private _api: TfmsApiService,
    private _tokenService: TokenService,
    private _changeDetector: ChangeDetectorRef,
    private _formBuilder: FormBuilder,
    @Inject(APP_ENVIRONMENT) private appEnv: any
  ) {}

  ngAfterViewInit() {
    this.lane = 0;

    this.dateAdapter.setLocale(this.locale);
    this.nameFile.setValue(this.name);
    // this.lane.
    combineLatest([
      this.date.valueChanges,
      this.LaneSelect.valueChanges,
    ]).subscribe(() => {
      this.nameFile.setValue(
        `${this.name}_${new DatePipe("en").transform(
          this.date.value,
          "yyyy_MM_dd"
        )}_${
          this.LaneSelect.value === 0 ? "Общее" : "П" + this.LaneSelect.value
        }`
      );
    });
    // this.date.valueChanges.subscribe((arg) => {
    //   this.nameFile.setValue(
    //     `Данные_За_${new DatePipe('en').transform(this.date.value, 'yyyy_MM_dd')}_${
    //       this.lane === 0 ? 'Общее' : 'П' + this.LaneSelect.value
    //     }`
    //   );
    // });
  }

  export() {
    if (!this.formGroup.valid) {
      this.formGroup.markAllAsTouched();
      return;
    }

    const from = new Date(this.date.value);
    const to = new Date(this.date.value);

    to.setDate(to.getDate());
    from.setDate(from.getDate() + 1);

    const url = `${
      this.appEnv.modules.tfms.apiUrl
    }/json/reply/GetDetectorGraphArchiveRequest?id=${
      this.modelId
    }&startTime=${to.toISOString()}&endTime=${from.toISOString()}&groupBySeconds=900&smooth=300&timezone=+7&type=${
      this.typeFile.value
    }&lane=${this.LaneSelect.value}`;
    return fetch(url, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + this._tokenService.bearerToken,
      },
    })
      .then(function (resp) {
        return resp.blob();
      })
      .then((blob) => {
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement("a");
        a.href = url;
        a.download = `${this.nameFile.value}.${this.typeFile.value}`;
        document.body.appendChild(a);
        a.click();
        a.remove();
      });
  }
  exportFederal() {
    if (!this.date.valid || !this.LaneSelect.valid) {
      this.date.markAsTouched();
      this.LaneSelect.markAsTouched();
      return;
    }

    const from = new Date(this.date.value);
    const to = new Date(this.date.value);

    to.setDate(to.getDate());
    from.setDate(from.getDate() + 1);

    const url = `${
      this.appEnv.modules.tfms.apiUrl
    }/json/reply/GetDetectorGraphFederalReportRequest?id=${
      this.modelId
    }&startTime=${to.toISOString()}&endTime=${from.toISOString()}&groupBySeconds=900&smooth=300&timezone=+7&lane=${
      this.LaneSelect.value
    }`;
    
    return fetch(url, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + this._tokenService.bearerToken,
      },
    })
      .then(function (resp) {
        return resp.blob();
      })
      .then((blob) => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = `ФЗ_${this.nameFile.value}.pdf`;
        document.body.appendChild(a);
        a.click();
        a.remove();
      });
  }
  cancel() {
    this._dialogRef.close(false);
  }
}
