import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
} from "@angular/core";
import { ActivatedRoute } from "@angular/router";

import { DialogService } from "projects/msu-its-web-common/src/services/dialog.service";
import { CameraFTPImagesComponent } from "./camera-ftp-images.component";

@Component({
  selector: "camera-ftp-images-external",
  template: ``,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CameraFTPImagesExternalComponent implements AfterViewInit {
  objectId;
  customerId;

  constructor(
    private _route: ActivatedRoute,
    private _dialogService: DialogService
  ) {
    this.objectId = this._route.snapshot.paramMap.get("objectId");
    this.customerId = this._route.snapshot.paramMap.get("customerId");
  }

  ngAfterViewInit() {
    const dialog = this._dialogService.dialog.open(CameraFTPImagesComponent, {
      disableClose: true,
      panelClass: "external-dialog",
    });
    dialog.componentInstance.external = true;
    dialog.componentInstance.modelId = this.objectId;
    dialog.componentInstance.customerId = this.customerId;
  }
}
