import {
  Component,
  ChangeDetectionStrategy,
  Input,
  ViewChild,
  ElementRef,
  AfterContentInit,
} from '@angular/core';
import { DataSource } from '../../data/data-source';

@Component({
  selector: 'table-search',
  templateUrl: './table-search.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TableSearchComponent implements AfterContentInit {
  @Input() dataSource: DataSource<any>;

  @Input() disabled = false;

  @ViewChild('search', { static: true })
  searchInput: ElementRef;

  ngAfterContentInit() {
    if (this.dataSource) {
      this.dataSource.setSearch(this.searchInput);
      setTimeout(() => this.searchInput.nativeElement.focus(), 50);
    }
  }
}
