<div #schemaMap class="schema-map" style="opacity: 0" [style.opacity]="mapEnabled ? 0.85 : 0"></div>
<object
  #schemaSvg
  type="image/svg+xml"
  data="/assets/images/tlc/layout_full.svg"
  [style.transform]="'rotate(' + schemaView?.angle + 'deg)'"
  (load)="initSchemaSvg()"
  style="visibility: hidden"
></object>
<div style="position: absolute; top: 8px; left: 12px">
  <mat-slide-toggle [checked]="mapEnabled" (change)="setMapEnabled($event.checked)"></mat-slide-toggle>
</div>
