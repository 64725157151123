import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

import {MatDialogModule} from '@angular/material/dialog';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatFormFieldModule} from '@angular/material/form-field';

import { DialogService } from '../../services/dialog.service';
import { ConfirmDialogComponent } from './confirm-dialog.component';
import { AlertDialogComponent } from './alert-dialog.component';
import { AboutDialogComponent } from './about-dialog.component';
import { PromptDialogComponent } from './prompt-dialog.component';

import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  providers: [
    DialogService,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatDialogModule,
    MatIconModule,
    MatInputModule,
    MatFormFieldModule,
    TranslateModule,
  ],
  declarations: [
    ConfirmDialogComponent,
    AlertDialogComponent,
    AboutDialogComponent,
    PromptDialogComponent,
  ],
  entryComponents: [
    ConfirmDialogComponent,
    AlertDialogComponent,
    AboutDialogComponent,
    PromptDialogComponent,
  ],
  exports: [
  ],
})
export class DialogModule { }
