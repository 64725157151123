import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  ViewChild,
} from '@angular/core';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { TranslateService } from '@ngx-translate/core';
import { SettingsService } from 'projects/msu-its-web-common/src/services/settings.service';

import { LayoutSvgUtils } from 'projects/msu-its-web-tlc/src/dtos/layout';
import { SchemaUtils } from 'projects/msu-its-web-tlc/src/dtos/schema';
import {
  PotokBarrier,
  PotokBarrierType,
  PotokPlanPhasesType,
  PotokRingStraightMovement,
  PotokSchemaPhaseStep,
  PotokTrafficObjectStatus,
  TrafficMovementType,
  TrafficObjectMode,
  TrafficObjectState,
  TrafficObjectStatus,
} from 'projects/msu-its-web-tlc/src/dtos/tlc.dtos';

import { IPotokTrafficObjectSet, PotokStateService } from './potok-state.service';
import {
  getBarrierPartNames,
  getRingParts,
  PotokPlan,
  RingPartType,
} from 'projects/msu-its-web-tlc/src/dtos/potok/potok-plan';
import { CONTROLLER_STATES } from 'projects/msu-its-web-tlc/src/dtos/enums';

interface IStateTime {
  id: any;
  phase: PotokSchemaPhaseStep;
  mode: TrafficObjectMode;
  state: TrafficObjectState;
  movements: TrafficMovementType[];
  changeTime: number;
  mainTime: number;
  planTime: number;
}

@Component({
  selector: 'potok-state-timelog',
  templateUrl: './potok-state-timelog.component.html',
  styleUrls: ['./potok-state-timelog.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PotokStateTimelogComponent implements AfterViewInit, OnDestroy {
  _destroy = new Subject();

  @Input()
  disabled = false;

  @ViewChild('container', { static: false })
  container: ElementRef;

  status: PotokTrafficObjectStatus;
  trafficObjectSet: IPotokTrafficObjectSet;

  stateTimes: IStateTime[] = [];
  currentStatus: TrafficObjectStatus;

  get schema() {
    return this.trafficObjectSet.schemaSet.schema;
  }
  get schemaView() {
    return this.trafficObjectSet.schemaSet.schemaView;
  }

  get trafficStatus() {
    return this.status?.controllerStatus?.traffic;
  }
  get controllerStatus() {
    return this.status?.controllerStatus?.controller;
  }

  constructor(
    private _stateService: PotokStateService,
    private _settingsService: SettingsService,
    private _changeDetector: ChangeDetectorRef,
    public translate: TranslateService
  ) {
    this._stateService.trafficObjectSet$
      .pipe(takeUntil(this._destroy))
      .subscribe((trafficObjectSet) => {
        this.trafficObjectSet = trafficObjectSet;
        this._changeDetector.markForCheck();
      });

    this._stateService.status$.pipe(takeUntil(this._destroy)).subscribe((status) => {
      this.status = status;
      this._updateTimeState();
    });
  }

  ngAfterViewInit() {}

  ngOnDestroy() {
    this._destroy.next();
    this._destroy.complete();
  }

  private _log = localStorage.getItem('log');
  _updateTimeState() {
    if (!this.trafficStatus) {
      return;
    }

    let updateContainerScorll = false;

    const currentPhase = this.trafficStatus.currentPhase;
    const currentTime = new Date(this.status.controllerTime).getTime();
    const currentPlanTime = this.trafficStatus.planTime;

    const lastItem = this.stateTimes.length ? this.stateTimes[this.stateTimes.length - 1] : null;
    if (lastItem === null || lastItem.phase.startTime !== currentPhase.startTime) {
      const schema = this.schema;
      const prevPhase = this.status.controllerStatus.traffic.previousPhase;
      if (lastItem !== null) {
        // update prev
        if (this.status.mode != TrafficObjectMode.Adaptive) { // need to fix !!!
          lastItem.phase = prevPhase;
          this._updateTimeStateItem(lastItem);
        }
      }
      // add new
      const newItem = {
        id: Date.now(),
        phase: currentPhase,
        mode: this.status.mode,
        state: this.status.state,
        movements: SchemaUtils.schemaLightToMovements(schema, currentPhase.lights),
        mainTime: null,
        changeTime: null,
        planTime: currentPlanTime,
      };
      this._updateTimeStateItem(newItem, currentTime);
      this.stateTimes.push(newItem);
      updateContainerScorll = true;
    } else {
      // update current
      lastItem.phase = currentPhase;
      this._updateTimeStateItem(lastItem, currentTime);
    }
    this._changeDetector.markForCheck();
    if (updateContainerScorll) {
      this._updateContainerScroll();
    }
  }

  _updateTimeStateItem(item: IStateTime, currentTime?: number) {
    const phase = item.phase;
    if (!phase) return;

    const startTime = new Date(phase.startTime).getTime();
    const changeTime = new Date(phase.changeIntervalStart).getTime();

    const nowTime = currentTime ? currentTime : new Date(phase.endTime).getTime();

    // if (phase.changeIntervalStart) {
    //   item.mainTime = this._round(changeTime - startTime);
    //   item.changeTime = this._round(nowTime - changeTime);
    // } else if (nowTime > startTime) {
    //   item.mainTime = this._round(nowTime - startTime);
    // }

    if (changeTime && nowTime > changeTime) {
      item.mainTime = this._round(changeTime - startTime);
      item.changeTime = this._round(nowTime - changeTime);
    } else if (nowTime > startTime) {
      item.mainTime = this._round(nowTime - startTime);
    }

    // this._log &&
    //   console.log(
    //     JSON.stringify({
    //       // palnTime: item.planTime,
    //       mainTime: item.mainTime,
    //       changeTime: item.changeTime || 0,
    //       totalTime: item.mainTime + (item.changeTime ? item.changeTime : 0),
    //     })
    //   );
  }

  _updateContainerScroll() {
    setTimeout(() => {
      if (this.container) {
        const element = this.container.nativeElement;
        const newScrollTop = element.scrollHeight - element.clientHeight;
        if (newScrollTop - element.scrollTop <= 90) {
          element.scrollTop = newScrollTop;
        }
      }
    });
  }

  setSvgState(element, movements: TrafficMovementType[]) {
    const schema = this.schema;
    const schemaView = this.schemaView;

    const svg = element.contentDocument;
    const isDark = this._settingsService.darkTheme;

    LayoutSvgUtils.updateLayoutSchema(svg, schema, schemaView, isDark);
    LayoutSvgUtils.showLayoutMovements(svg, schema, movements);
  }

  getStateName(stateTime: IStateTime) {
    if (stateTime.state === TrafficObjectState.Phase) {
      return this._getPlanPartNamesByTime(stateTime.planTime).join(':');
    } else {
      const type = CONTROLLER_STATES.find((n) => n.type === stateTime.state);
      return type ? this.translate.instant(type.short) : null;
    }
  }

  _currentPlanNumber: number;
  _currentPlanPhasesType: PotokPlanPhasesType;
  _currentPlanBarriers: PotokBarrier[];
  _getPlanPartNamesByTime(planTime: number) {
    if (
      this._currentPlanNumber != this.status.controllerStatus.traffic.coordinationPlan ||
      this._currentPlanPhasesType != this.status.controllerStatus.traffic.planPhasesType
    ) {
      this._currentPlanNumber = this.status.controllerStatus.traffic.coordinationPlan;
      this._currentPlanPhasesType = this.status.controllerStatus.traffic.planPhasesType;

      const leadZero = this._currentPlanNumber >= 10 ? '' : '0';
      const plan: PotokPlan = this.trafficObjectSet.settings.plans[
        'plan' + leadZero + this._currentPlanNumber
      ];

      this._currentPlanBarriers =
        this.status.controllerStatus.traffic.planPhasesType === PotokPlanPhasesType.Pedestrian
          ? plan?.pedestrianBarriers
          : plan?.transportBarriers;
    }

    const result = [];
    let barrierTime = 0;
    this._currentPlanBarriers?.some((barrier, barrierIndex) => {
      const barrierStartTime = barrierTime;
      const barrierEndTime = barrier.length + barrierTime;
      barrierTime = barrierEndTime;

      if (planTime >= barrierStartTime && planTime < barrierEndTime) {
        result.push(getBarrierPartNames(barrierIndex + 1, barrier.type)[0]);

        if (barrier.type != PotokBarrierType.Static) {
          [barrier.ring1, barrier.ring2].forEach((ring, ringIndex) => {
            if (!ring) return;
            const ringNumber: any = ringIndex + 1;
            let partTime = barrierStartTime;
            getRingParts(ring, ringNumber).some((part) => {
              const partStartTime = partTime;
              const partEndTime = part.item.length + partTime;
              const partPedestrianTime =
                part.type == RingPartType.Straight
                  ? (part.item as PotokRingStraightMovement).pedestrianMovementLength + partTime
                  : 0;

              partTime = partEndTime;
              if (planTime >= partStartTime && planTime < partEndTime) {
                result.push(
                  getBarrierPartNames(
                    barrierIndex + 1,
                    barrier.type,
                    part,
                    planTime < partPedestrianTime
                  )[1]
                );
                return true;
              }
            });
          });
        }

        return true;
      }
    });

    return result;
  }

  reset() {
    this.stateTimes = [];
  }

  _round(value: number) {
    return Math.ceil(Math.floor(value / 100) / 10);
  }

  trackById(index, item) {
    return item.id;
  }
}
