import {
  Directive,
  Input,
  TemplateRef,
  ViewContainerRef,
  OnInit,
  Inject,
} from '@angular/core';

import { APP_ENVIRONMENT } from '../utils/shared-consts';

@Directive({
  selector: '[hasModules]',
})
export class HasModulesDirective implements OnInit {
  private _hidden = true;
  private _modules: string[] = [];

  @Input()
  set hasModules(value) {
    this._modules = value;
    this._updateView();
  }

  constructor(
    private _templateRef: TemplateRef<any>,
    private _viewContainer: ViewContainerRef,
    @Inject(APP_ENVIRONMENT) private _appEnv
  ) {}

  ngOnInit() {
    this._updateView();
  }

  private _updateView() {
    if (this._hasMosules(this._modules)) {
      if (this._hidden) {
        this._viewContainer.createEmbeddedView(this._templateRef);
        this._hidden = false;
      }
    } else {
      this._hidden = true;
      this._viewContainer.clear();
    }
  }

  private _hasMosules(modules: string[]) {
    return modules.every((m) => this._appEnv.modules[m]);
  }
}

// Usage
// <button *hasModules="['tlc', 'tss']"></button>
