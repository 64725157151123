import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import {
  IDataSourceService,
  setPageParams,
  getPageReturn,
  IPageParams,
} from '../data/data-source';

import {
  AuditItem,
  AuditItemDetails,
  GetEntityAuditRequest,
} from '../dtos/hub.dtos';

import { HubApiService } from './hub-api.service';

@Injectable({ providedIn: 'root' })
export class AuditLogService
  implements IDataSourceService<AuditItem, AuditItemDetails> {
  constructor(private _api: HubApiService) {}

  getPagedList(
    params: IPageParams,
    requestParams: {
      customerId: string;
      id: string;
      from?: string;
      to?: string;
    }
  ) {
    const request = new GetEntityAuditRequest();
    setPageParams(request, params);

    request.id = requestParams?.id;
    request.from = requestParams?.from;
    request.to = requestParams?.to;
    // request.customerId = requestParams.customerId;

    return this._api.get(request).pipe(
      map((m) => {
        return getPageReturn(m);
      })
    );
  }

  getList(
    id?: string,
    filter?: string,
    from?: string,
    to?: string,
    count = 20
  ) {
    const request = new GetEntityAuditRequest();

    request.id = id;
    request.from = from;
    request.to = to;

    request.page = 0;
    request.items = count;
    request.filter = filter;

    request.sort = 'activityTime desc';

    return this._api.get(request).pipe(
      map((m) => {
        return getPageReturn(m).data;
      })
    );
  }

  get(id: any): Observable<AuditItem> {
    throw 'not implemented';
  }

  add(model: AuditItem): Observable<AuditItem> {
    throw 'not implemented';
  }

  update(model: AuditItem): Observable<AuditItem> {
    throw 'not implemented';
  }

  delete(ids: string[]): Observable<number> {
    throw 'not implemented';
  }
}
