<div
  div
  fxLayout="row"
  fxLayoutAlign="space-between center"
  class="mat-small mat-secondary-color"
>
  <div>{{ "PARKING_SPACES.TITLE" | translate }}:</div>

  <div>
    <mat-slide-toggle (change)="onChangeToggle($event)">
      {{ "PARKING_SPACES.SHOW_VERSION" | translate }}</mat-slide-toggle
    >
  </div>
</div>
<div fxLayout="column" fxLayoutGap="8px" style="min-height: 200px;">
  <div
    *ngFor="let space of status?.spaces; trackBy: trackStatus"
    fxLayout="row"
    fxLayoutAlign="center center"
    class="mat-elevation-z1"
    style="border-radius: 3px;"
    [style.backgroundColor]="space?.iconStatus?.color"
  >
    <div
      fxLayout="row"
      fxLayoutAlign="start end"
      fxLayoutGap="4px"
      style="padding: 0 16px 0px 12px; flex: 1;"
    >
      <span>{{ space.fullName }}</span>
      <span *ngIf="showVersion" style="font-size: 12px;">
        {{space?.parameters ? space.parameters['VERSION']? 'v' +
        space.parameters['VERSION'] :'' : ''}}</span
      >
    </div>

    <div class="mat-small">{{ getStatus(space) }}</div>
    <button
      mat-icon-button
      [matMenuTriggerFor]="actionMenu"
      [matMenuTriggerData]="space"
    >
      <mat-icon>more_vert</mat-icon>
    </button>
  </div>
</div>

<mat-menu #actionMenu class="mat-menu-md">
  <ng-template matMenuContent let-id="id" let-online="online" let-mode="mode">
    <button
      mat-menu-item
      [disabled]="!(online && mode === 'Work')"
      (click)="openParkingSpace(id)"
    >
      {{ "PARKING_SPACE.OPEN_LOCK" | translate }}
    </button>
    <button
      mat-menu-item
      [disabled]="true || !(online && mode === 'Work')"
      (click)="closeParkingSpace(id)"
    >
      {{ "PARKING_SPACE.CLOSE_LOCK" | translate }}
    </button>
    <mat-divider></mat-divider>
    <button
      mat-menu-item
      [disabled]="true || !(online && mode === 'Work')"
      (click)="queryParkingSpace(id)"
    >
      {{ "PARKING_SPACE.QUERY_LOCK" | translate }}
    </button>
    <!-- <button mat-menu-item [disabled]="!(online && mode === 'Work')" (click)="rebootParkingSpace(id)">
      {{ "PARKING_SPACE.REBOOT_LOCK" | translate }}
    </button> -->
  </ng-template>
</mat-menu>
