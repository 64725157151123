import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy } from '@angular/core';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import {
  UsdkPlanType,
  TrafficMovementType,
  TrafficObjectStatus,
  UsdkPhase,
  UsdkTrafficObjectStatus,
  DKMode,
  TrafficObjectMode,
} from 'projects/msu-its-web-tlc/src/dtos/tlc.dtos';

import { IUsdkTrafficObjectSet, UsdkStateService } from './usdk-state.service';

import { SettingsService } from 'projects/msu-its-web-common/src/services/settings.service';
import { UsdkTrafficObjectService } from 'projects/msu-its-web-tlc/src/services/usdk-traffic-object.service';

import { SchemaUtils } from 'projects/msu-its-web-tlc/src/dtos/schema';
import { LayoutSvgUtils } from 'projects/msu-its-web-tlc/src/dtos/layout';
import { getColor } from 'projects/msu-its-web-common/src/utils/colors';

@Component({
  selector: 'usdk-state-mode',
  templateUrl: './usdk-state-mode.component.html',
  styleUrls: ['./usdk-state-mode.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UsdkStateModeComponent implements OnDestroy {
  _destroy = new Subject();

  @Input()
  disabled = false;

  status: UsdkTrafficObjectStatus;
  trafficObjectSet: IUsdkTrafficObjectSet;

  get mode(): DKMode {
    return this.status?.controllerStatus?.traffic.trafficMode;
  }

  constructor(
    private _stateService: UsdkStateService,
    private _usdkTrafficObjectService: UsdkTrafficObjectService,
    private _settingsService: SettingsService,
    private _changeDetector: ChangeDetectorRef
  ) {
    this._stateService.trafficObjectSet$
      .pipe(takeUntil(this._destroy))
      .subscribe((trafficObjectSet) => {
        this.trafficObjectSet = trafficObjectSet;
        this._changeDetector.markForCheck();
      });

    this._stateService.status$.pipe(takeUntil(this._destroy)).subscribe((status) => {
      this.status = status;
      this._changeDetector.markForCheck();
    });
  }

  ngOnDestroy() {
    this._destroy.next();
    this._destroy.complete();
  }

  toggleGroupChange(event) {
    const toggle = event.source;
    if (toggle && this.status) {
      const group = toggle.buttonToggleGroup;
      group.value = this.status.mode;
    }
  }

  setCoordinationMode() {
    this._stateService
      .loadingQuery(
        this._usdkTrafficObjectService.setCoordinationMode(
          this._stateService.modelId,
          this._stateService.customerId
        )
      )
      .subscribe((status) => {
        this._stateService.status = status;
      });
  }
  setAdaptiveMode() {
    this._stateService
      .loadingQuery(
        this._usdkTrafficObjectService.setAdaptiveMode(
          this._stateService.modelId,
          this._stateService.customerId
        )
      )
      .subscribe((status) => {
        this._stateService.status = status;
      });
  }
  setLocalMode() {
    this._stateService
      .loadingQuery(
        this._usdkTrafficObjectService.setLocalMode(
          this._stateService.modelId,
          this._stateService.customerId
        )
      )
      .subscribe((status) => {
        this._stateService.status = status;
      });
  }
  setRemoteMode(phase: UsdkPhase) {
    this._stateService
      .loadingQuery(
        this._usdkTrafficObjectService.setRemoteMode(
          this._stateService.modelId,
          this._stateService.customerId,
          phase.id
        )
      )
      .subscribe((status) => {
        this._stateService.status = status;
      });
  }
  setYellowBlinkMode() {
    this._stateService
      .loadingQuery(
        this._usdkTrafficObjectService.setYellowBlinkMode(
          this._stateService.modelId,
          this._stateService.customerId
        )
      )
      .subscribe((status) => {
        this._stateService.status = status;
      });
  }
  setOfflineMode() {
    this._stateService
      .loadingQuery(
        this._usdkTrafficObjectService.setOfflineMode(
          this._stateService.modelId,
          this._stateService.customerId
        )
      )
      .subscribe((status) => {
        this._stateService.status = status;
      });
  }

  setSvgLayoutState(target, phase: UsdkPhase) {
    const schema = this.trafficObjectSet.schemaSet.schema;
    const schemaView = this.trafficObjectSet.schemaSet.schemaView;
    const darkTheme = this._settingsService.darkTheme;
    const svg = target.contentDocument;

    const movements = [];
    phase.movements.forEach((m) => {
      movements.push(...SchemaUtils.schemaViewMovements(schema, m));
    });

    LayoutSvgUtils.updateLayoutSchema(svg, schema, schemaView, darkTheme);
    LayoutSvgUtils.showLayoutMovements(svg, schema, movements);
  }

  get remotePhaseTime() {
    if (
      this.status &&
      this.status.mode === TrafficObjectMode.Remote &&
      this.status?.controllerStatus?.traffic?.currentPhase
    ) {
      const nowTime = new Date(this.status.serverTime || 0).getTime();
      const startTime = new Date(
        this.status.controllerStatus.traffic.currentPhase.startTime
      ).getTime();
      return Math.floor((nowTime - startTime) / 1000);
    }
    return '';
  }

  getColor(color) {
    return getColor(color);
  }
}
