import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';

export const PERMISSIONS_SORT_PRIORITY = [
  'customer',
  'user',
  'device',
  'receiver',
  'trafficObject',
  'trafficScene',
  'tfms',
  'Tfms',
  'workspace',
  'Workspace',
].reverse();

export const SKIP_ENTITY_TYPES = [
  'customer',
  'entityGroup',
  'receiver',
  // 'customerRole',
  // 'user'
];

export const PERMISSIONS_TRANSLATE = {
  // modules
  'Msu.Its.Hub': _('PERMISSION_MODULES.HUB'),
  'Msu.Its.Gis': _('PERMISSION_MODULES.GIS'),
  'Msu.Its.Tlc': _('PERMISSION_MODULES.TLC'),
  'Msu.Its.Tlc_krudor': _('PERMISSION_MODULES.TLC_KRUDOR'),
  'Msu.Its.Tfms': _('PERMISSION_MODULES.TFMS'),
  'Msu.Its.Tfms_krudor': _('PERMISSION_MODULES.TFMS_KRUDOR'),
  'Msu.Its.Tss': _('PERMISSION_MODULES.TSS'),
  'Msu.Its.Parking': _('PERMISSION_MODULES.PARKING'),
  'Msu.Its.Video': _('PERMISSION_MODULES.VIDEO'),
  'Msu.Its.Vsae': _('PERMISSION_MODULES.VSAE'),
  'Msu.Its.Telematic': _('PERMISSION_MODULES.TELEMATIC'),
  'Msu.Its.Meteo': _('PERMISSION_MODULES.METEO'),
  'Msu.Its.Meteo_krudor': _('PERMISSION_MODULES.METEO_KRUDOR'),
  'Msu.Its.Ptm': _('PERMISSION_MODULES.PTM'),
  'Msu.Its.Ruis': _('PERMISSION_MODULES.RUIS'),
  'Msu.Its.Ruis_krudor': _('PERMISSION_MODULES.RUIS_KRUDOR'),
  'Msu.Its.Rmmd': _('PERMISSION_MODULES.RMMD'),
  'Msu.Its.CoordControl': _('PERMISSION_MODULES.COORDCONTROL'),
  'Msu.Its.Accident': _('PERMISSION_MODULES.ACCIDENT'),
  'Msu.Its.Modelling': _('PERMISSION_MODULES.MODELLING'),

  'Msu.Its.Ptm2': _('PERMISSION_MODULES.PTM2'),
  'Msu.Its.Ptm3': _('PERMISSION_MODULES.PTM3'),

  'Msu.Its.Tpc': _('PERMISSION_MODULES.TPC'),
  'Msu.Its.Dashboard': _('PERMISSION_MODULES.DASHBOARD'),
  'Msu.Its.Dashboard_krudor': _('PERMISSION_MODULES.DASHBOARD_KRUDOR'),
  'Msu.Workplace.Mayor': _('PERMISSION_MODULES.MAYOR'),
  'Msu.Workplace.Briz': _('PERMISSION_MODULES.BRIZ'),

  'Msu.Its.Telematic.Api': _('PERMISSION_MODULES.TELEMATIC_API'),
  'Msu.Its.Telematic.DataApi': _('PERMISSION_MODULES.TELEMATIC_DATA_API'),

  'Msu.Its.Telematic.Receiver.OPTIC': _(
    'PERMISSION_MODULES.TELEMATIC_RECEIVER_OPTIC'
  ),
  'Msu.Its.Telematic.Receiver.POTOK': _(
    'PERMISSION_MODULES.TELEMATIC_RECEIVER_POTOK'
  ),
  'Msu.Its.Telematic.Receiver.USDK': _(
    'PERMISSION_MODULES.TELEMATIC_RECEIVER_USDK'
  ),

  'Msu.Workplace': _('PERMISSION_MODULES.WORKPLACE'),
  'RMM.Workspaces': _('PERMISSION_MODULES.WORKSPACES'),

  'Msu.Its.Ftv': _('PERMISSION_MODULES.FTV'),
  'Msu.Parking': _('PERMISSION_MODULES.PARKING_ADM'),
  'Msu.Its.Accident_krudor': _('PERMISSION_MODULES.ACCIDENT_KRUDOR'),
  'Msu.Its.Integration_external_system': _('PERMISSION_MODULES.INTEGRATION_EXTERNAL_SYSTEM'),
  'Msu.Its.Weight_control': _('PERMISSION_MODULES.WEIGHT_CONTROL'),

  // categories
  user: _('PERMISSION_CATEGORIES.USERS'),
  customer: _('PERMISSION_CATEGORIES.CUSTOMERS'),
  customerRole: _('PERMISSION_CATEGORIES.CUSTOMER_ROLES'),
  device: _('PERMISSION_CATEGORIES.DEVICE'),
  receiver: _('PERMISSION_CATEGORIES.RECEIVER'),
  trafficObject: _('PERMISSION_CATEGORIES.TRAFFIC_OBJECTS'),

  parking: _('PERMISSION_CATEGORIES.PARKINGS'),
  parkingSpace: _('PERMISSION_CATEGORIES.PARKING_SPACES'),
  parkingKiosk: _('PERMISSION_CATEGORIES.PARKING_KIOSKS'),
  parkingCamera: _('PERMISSION_CATEGORIES.PARKING_CAMERA'),
  parkingController: _('PERMISSION_CATEGORIES.PARKING_CONTROLLER'),
  parkingSpaceStatistic: _('PERMISSION_CATEGORIES.PARKING_SPACE_STATISTIC'),

  fixationDevice: _('PERMISSION_CATEGORIES.FIXATION_DEVICE'),
  fixationEvent: _('PERMISSION_CATEGORIES.FIXATION_EVENT'),
  fixationPoint: _('PERMISSION_CATEGORIES.FIXATION_POINT'),
  weightControlPoint: _('PERMISSION_CATEGORIES.WEIGHT_CONTROL_POINT'),
  account: _('PERMISSION_CATEGORIES.ACCOUNT'),
  order: _('PERMISSION_CATEGORIES.ORDER'),

  systemStatus: _('PERMISSION_CATEGORIES.SYSTEM_STATUS'),
  tariff: _('PERMISSION_CATEGORIES.TARIFF'),
  route: _('PERMISSION_CATEGORIES.ROUTES'),
  camera: _('PERMISSION_CATEGORIES.CAMERAS'),
  ptzCamera: _('PERMISSION_CATEGORIES.PTZ_CAMERA'),
  detector: _('PERMISSION_CATEGORIES.DETECTORS'),
  detectorDevice: _('PERMISSION_CATEGORIES.DETECTOR_DEVICES'),
  trafficScene: _('PERMISSION_CATEGORIES.TRAFFIC_SCENE'),
  geoObject: _('PERMISSION_CATEGORIES.GEO_OBJECT'),
  geoObjectType: _('PERMISSION_CATEGORIES.GEO_OBJECT_TYPE'),
  file: _('PERMISSION_CATEGORIES.FILE'),
  entityGroup: _('PERMISSION_CATEGORIES.ENTITY_GROUP'),

  accidentPtzCamera: _('PERMISSION_CATEGORIES.ACCIDENT_PTZ_CAMERA'),
  accidentStaticCamera: _('PERMISSION_CATEGORIES.ACCIDENT_STATIC_CAMERA'),
  accidentStaticCameraImage: _('PERMISSION_CATEGORIES.ACCIDENT_STATIC_CAMERA_IMAGE'),
  accidentStaticCameraSource: _('PERMISSION_CATEGORIES.ACCIDENT_STATIC_CAMERA_SOURCE'),

  meteoObject: _('PERMISSION_CATEGORIES.METEO_OBJECT'),
  controlSession: _('PERMISSION_CATEGORIES.CONTROL_SESSION'),
  trackingDevice: _('PERMISSION_CATEGORIES.TRACKING_DEVICE'),

  publicTransportWaybill: _('PERMISSION_CATEGORIES.PUBLIC_TRANSPORT_WAYBILL'),
  publicTransportPriorityEvent: _('PERMISSION_CATEGORIES.PUBLIC_TRANSPORT_PRIORITY_EVENT'),
  publicTransportPrioritySettings: _('PERMISSION_CATEGORIES.PUBLIC_TRANSPORT_PRIORITY_SETTINGS'),
  publicTransportVehiclePriority: _('PERMISSION_CATEGORIES.PUBLIC_TRANSPORT_VEHICLE_PRIORITY'),

  publicTransportCarrier: _('PERMISSION_CATEGORIES.PUBLIC_TRANSPORT_CARRIER'),
  publicTransportRoute: _('PERMISSION_CATEGORIES.PUBLIC_TRANSPORT_ROUTE'),
  publicTransportSchedule: _('PERMISSION_CATEGORIES.PUBLIC_TRANSPORT_SCHEDULE'),
  publicTransportStop: _('PERMISSION_CATEGORIES.PUBLIC_TRANSPORT_STOP'),
  publicTransportVehicle: _('PERMISSION_CATEGORIES.PUBLIC_TRANSPORT_VEHICLE'),
  publicTransportGlonassTerminal: _(
    'PERMISSION_CATEGORIES.PUBLIC_TRANSPORT_GLONASS_TERMINAL'
  ),

  cameragroup: _('PERMISSION_CATEGORIES.CAMERA_GROUPS'),

  dashboard: _('PERMISSION_CATEGORIES.DASHBOARD'),
  Dashboard: _('PERMISSION_CATEGORIES.DASHBOARD'),
  dashboardSettings: _('PERMISSION_CATEGORIES.DASHBOARD_SETTINGS'),
  dashboardCamera: _('PERMISSION_CATEGORIES.DASHBOARD_CAMERA'),
  workspace: _('PERMISSION_CATEGORIES.WORKSPACE'),
  Workspace: _('PERMISSION_CATEGORIES.WORKSPACE'),
  tfms: _('PERMISSION_CATEGORIES.TFMS'),
  Tfms: _('PERMISSION_CATEGORIES.TFMS'),

  Mayor: _('PERMISSION_CATEGORIES.MAYOR'),
  Briz: _('PERMISSION_CATEGORIES.BRIZ'),
  FormulaK: _('PERMISSION_CATEGORIES.FORMULA_K'),

  detectorDeviceIncident: _('PERMISSION_CATEGORIES.DETECTOR_DEVICE_INCIDENT'),
  detectorMaintenance: _('PERMISSION_CATEGORIES.DETECTOR_MAINTENANCE'),

  trafficObjectDetectors: _('PERMISSION_CATEGORIES.TRAFFIC_OBJECT_DETECTORS'),
  trafficObjectDetectorsReport: _(
    'PERMISSION_CATEGORIES.TRAFFIC_OBJECT_DETECTORS_REPORT'
  ),

  panel: _('PERMISSION_CATEGORIES.PANEL'),
  panelController: _('PERMISSION_CATEGORIES.PANEL_CONTROLLER'),
  panelStand: _('PERMISSION_CATEGORIES.PANEL_STAND'),
  frameTemplateLibrary: _('PERMISSION_CATEGORIES.FRAME_TEMPLATE_LIBRARY'),

  maintenanceContractor: _('PERMISSION_CATEGORIES.MAINTENANCE_CONTRACTOR'),
  maintenanceExternalSystem: _(
    'PERMISSION_CATEGORIES.MAINTENANCE_EXTERNAL_SYSTEM'
  ),
  maintenanceRoute: _('PERMISSION_CATEGORIES.MAINTENANCE_ROUTE'),
  maintenanceVehicle: _('PERMISSION_CATEGORIES.MAINTENANCE_VEHICLE'),
  maintenanceVehicleTerminal: _(
    'PERMISSION_CATEGORIES.MAINTENANCE_VEHICLE_TERMINAL'
  ),
  maintenanceWork: _('PERMISSION_CATEGORIES.MAINTENANCE_WORK'),

  transportedGoodsVolume: _('PERMISSION_CATEGORIES.TRANSPORTED_GOODS_VOLUME'),
  pitsRepairWidget: _('PERMISSION_CATEGORIES.PITS_REPAIR_WIDGET'),
  pitsRepairObject: _('PERMISSION_CATEGORIES.PITS_REPAIR_OBJECT'),
  passengersNumber: _('PERMISSION_CATEGORIES.PASSENGERS_NUMBER'),
  parkingWidget: _('PERMISSION_CATEGORIES.PARKING_WIDGET'),
  measureUnitBinding: _('PERMISSION_CATEGORIES.MEASURE_UNIT_BINDING'),
  measureUnit: _('PERMISSION_CATEGORIES.MEASURE_UNIT'),

  modellingScene: _('PERMISSION_CATEGORIES.MODELLING_SCENE'),


  ruisWidget: _('PERMISSION_CATEGORIES.RUIS_WIDGET'), //  = ДИТ и ЗПИ,
  itsTrafficAccidentWidget: _('PERMISSION_CATEGORIES.ITS_TRAFFIC_ACCIDENT_WIDGET'), // = ДТП ИТС

  // actions
  control: _('PERMISSION_ACTIONS.CONTROL'),
  create: _('PERMISSION_ACTIONS.CREATE'),
  read: _('PERMISSION_ACTIONS.READ'),
  update: _('PERMISSION_ACTIONS.UPDATE'),
  delete: _('PERMISSION_ACTIONS.DELETE'),
  block: _('PERMISSION_ACTIONS.BLOCK'),
  assign: _('PERMISSION_ACTIONS.ASSIGN'),
  manageAccess: _('PERMISSION_ACTIONS.MANAGE_ACCESS'),
  editGroup: _('PERMISSION_ACTIONS.EDIT_GROUP'),
  deleteGroup: _('PERMISSION_ACTIONS.DELETE_GROUP'),
  shareGroup: _('PERMISSION_ACTIONS.SHARE_GROUP'),
  manage: _('PERMISSION_ACTIONS.MANAGE'),
  filter: _('PERMISSION_ACTIONS.FILTER'),

  // models
  workspaceWidgetComponent: _('PERMISSION_MODELS.WORKSPACE_WIDGET_COMPONENT'),

  // МЭР:
  budgetaryFond: _('PERMISSION_MODELS.BUDGETARY_FOND'),
  budgetaryFondPlan: _('PERMISSION_MODELS.BUDGETARY_FOND_PLAN'),
  budgetaryFondPlanReport: _('PERMISSION_MODELS.BUDGETARY_FOND_PLAN_REPORT'),
  budgetaryFondsWidget: _('PERMISSION_MODELS.BUDGETARY_FONDS_WIDGET'),
  catchDogsWidget: _('PERMISSION_MODELS.CATCH_DOGS_WIDGET'),
  catchDog: _('PERMISSION_MODELS.CATCH_DOG'),
  district: _('PERMISSION_MODELS.DISTRICT'),

  detectorsStateWidget: _('PERMISSION_MODELS.DETECTORS_STATE_WIDGET'),
  detectorsTrafficLightsStateWidget: _(
    'PERMISSION_MODELS.DETECTORS_TRAFFIC_LIGHTS_STATE_WIDGET'
  ),
  efficiencyITSWidget: _('PERMISSION_MODELS.EFFICIENCY_ITS_WIDGET'),
  publicTransportReleasedWidget: _(
    'PERMISSION_MODELS.PUBLIC_TRANSPORT_RELEASE_WIDGET'
  ),
  specialMachineryAntiIcingMaterial: _(
    'PERMISSION_MODELS.SPECIAL_MACHINERY_ANTI_ICING_MATERIAL'
  ),
  accidentTrafficAccident: _('PERMISSION_MODELS.TRAFFIC_ACCIDENT'),
  trafficAccident: _('PERMISSION_MODELS.TRAFFIC_ACCIDENT_WIDGET_NEW'),
  trafficAccidentWidget: _('PERMISSION_MODELS.TRAFFIC_ACCIDENT_WIDGET'),

  heatSupplyContractor: _('PERMISSION_MODELS.HEAT_SUPPLY_CONTRACTOR'),
  heatSupplyObject: _('PERMISSION_MODELS.HEAT_SUPPLY_OBJECT'),
  heatSupplyObjectWork: _('PERMISSION_MODELS.HEAT_SUPPLY_OBJECT_WORK'),
  heatSupplyWidget: _('PERMISSION_MODELS.HEAT_SUPPLY_WIDGET'),
  publicTransportWidget: _('PERMISSION_MODELS.PUBLIC_TRANSPORT_WIDGET'),
  repairOrder: _('PERMISSION_MODELS.REPAIR_ORDER'),
  repairOrderContractor: _('PERMISSION_MODELS.REPAIR_ORDER_CONTRACTOR'),
  repairOrderStatus: _('PERMISSION_MODELS.REPAIR_ORDER_STATUS'),
  repairOrderType: _('PERMISSION_MODELS.REPAIR_ORDER_TYPE'),
  repairOrderWidget: _('PERMISSION_MODELS.REPAIR_ORDER_WIDGET'),
  roadMaintenanceContract: _('PERMISSION_MODELS.ROAD_MAINTENANCE_CONTRACT'),
  roadMaintenanceContractor: _('PERMISSION_MODELS.ROAD_MAINTENANCE_CONTRACTOR'),
  roadMaintenanceContractReport: _(
    'PERMISSION_MODELS.ROAD_MAINTENANCE_CONTRACT_REPORT'
  ),
  roadMaintenanceWidget: _('PERMISSION_MODELS.ROAD_MAINTENANCE_WIDGET'),
  safeRoadsContractor: _('PERMISSION_MODELS.SAFE_ROADS_CONTRACTOR'),
  safeRoadsObject: _('PERMISSION_MODELS.SAFE_ROADS_OBJECT'),
  safeRoadsObjectWork: _('PERMISSION_MODELS.SAFE_ROADS_OBJECT_WORK'),
  safeRoadsObjectWorkReport: _(
    'PERMISSION_MODELS.SAFE_ROADS_OBJECT_WORK_REPORT'
  ),
  safeRoadsWidget: _('PERMISSION_MODELS.SAFE_ROADS_WIDGET'),
  safeRoadsWorksProgress: _('PERMISSION_MODELS.SAFE_ROADS_WORKS_PROGRESS'),
  safeRoadsWorkType: _('PERMISSION_MODELS.SAFE_ROADS_WORK_TYPE'),
  specialMachineryContractor: _(
    'PERMISSION_MODELS.SPECIAL_MACHINERY_CONTRACTOR'
  ),
  specialMachineryTask: _('PERMISSION_MODELS.SPECIAL_MACHINERY_TASK'),
  specialMachineryType: _('PERMISSION_MODELS.SPECIAL_MACHINERY_TYPE'),
  specialMachineryWidget: _('PERMISSION_MODELS.SPECIAL_MACHINERY_WIDGET'),
  speedInDistrictWidget: _('PERMISSION_MODELS.SPEED_IN_DISTRICT_WIDGET'),
  superServiceWidget: _('PERMISSION_MODELS.SUPER_SERVICE_WIDGET'),
  trafficInDistrictWidget: _('PERMISSION_MODELS.TRAFFIC_IN_DISTRICT_WIDGET'),
  trafficLightsStateWidget: _('PERMISSION_MODELS.TRAFFIC_LIGHTS_STATE_WIDGET'),
  urbanComfortContractor: _('PERMISSION_MODELS.URBAN_COMFORT_CONTRACTOR'),
  urbanComfortObject: _('PERMISSION_MODELS.URBAN_COMFORT_OBJECT'),
  urbanComfortObjectWork: _('PERMISSION_MODELS.URBAN_COMFORT_OBJECT_WORK'),
  urbanComfortObjectWorkReport: _(
    'PERMISSION_MODELS.URBAN_COMFORT_OBJECT_WORK_REPORT'
  ),
  urbanComfortWidget: _('PERMISSION_MODELS.URBAN_COMFORT_WIDGET'),
  urbanComfortWorksProgress: _(
    'PERMISSION_MODELS.URBAN_COMFORT_WORKS_PROGRESS'
  ),
  urbanComfortWorkType: _('PERMISSION_MODELS.URBAN_COMFORT_WORK_TYPE'),
  urbanImprovementContract: _('PERMISSION_MODELS.URBAN_IMPROVEMENT_CONTRACT'),
  urbanImprovementContractor: _(
    'PERMISSION_MODELS.URBAN_IMPROVEMENT_CONTRACTOR'
  ),
  urbanImprovementContractReport: _(
    'PERMISSION_MODELS.URBAN_IMPROVEMENT_CONTRACT_REPORT'
  ),
  urbanImprovementWidget: _('PERMISSION_MODELS.URBAN_IMPROVEMENT_WIDGET'),
  vacuumDeviceContract: _('PERMISSION_MODELS.VACUUM_DEVICE_CONTRACT'),
  vacuumDeviceContractReport: _(
    'PERMISSION_MODELS.VACUUM_DEVICE_CONTRACT_REPORT'
  ),
  weatherWidget: _('PERMISSION_MODELS.WEATHER_WIDGET'),
  yardRepairContractor: _('PERMISSION_MODELS.YARD_REPAIR_CONTRACTOR'),
  yardRepairManagementCompany: _(
    'PERMISSION_MODELS.YARD_REPAIR_MANAGEMENT_COMPANY'
  ),
  yardRepairObject: _('PERMISSION_MODELS.YARD_REPAIR_OBJECT'),
  yardRepairObjectWork: _('PERMISSION_MODELS.YARD_REPAIR_OBJECT_WORK'),
  yardRepairWidget: _('PERMISSION_MODELS.YARD_REPAIR_WIDGET'),

  procurement: _('PERMISSION_MODELS.PROCUREMENT'),
  procurementBudget: _('PERMISSION_MODELS.PROCUREMENT_BUDGET'),
  procurementsWidget: _('PERMISSION_MODELS.PROCUREMENT_WIDGET'),

  municipalityData: _('PERMISSION_MODELS.MUNICIPALITY_DATA'),
  roadInfrastructureWidget: _('PERMISSION_MODELS.ROAD_INFRASTRUCTURE_WIDGET'),
  weightControlEventsWidget: _('PERMISSION_MODELS.WEIGHT_CONTROL_EVENTS_WIDGET'),
  weightControlFinesWidget: _('PERMISSION_MODELS.WEIGHT_CONTROL_FINES_WIDGET'),

  // БРИЗ:
  contactPerson: _('PERMISSION_MODELS.CONTACT_PERSON'),
  contract: _('PERMISSION_MODELS.CONTRACT'),
  contractWork: _('PERMISSION_MODELS.CONTRACT_WORK'),
  contractWarehouse: _('PERMISSION_MODELS.CONTRACT_WAREHOUSE'),

  observationContractsWidget: _(
    'PERMISSION_MODELS.OBSERVATION_CONTRACTS_WIDGET'
  ),
  observationObjectsWidget: _('PERMISSION_MODELS.OBSERVATION_OBJECTS_WIDGET'),
  observationObjectType: _('PERMISSION_MODELS.OBSERVATION_OBJECT_TYPE'),
  observationObjectTypeWork: _(
    'PERMISSION_MODELS.OBSERVATION_OBJECT_TYPE_WORK'
  ),
  region: _('PERMISSION_MODELS.REGION'),
  material: _('PERMISSION_MODELS.MATERIAL'),
  materialTransfer: _('PERMISSION_MODELS.MATERIAL_TRANSFER'),
  contractWareHouse: _('PERMISSION_MODELS.CONTRACT_WAREHOUSE'),
  contractMaterial: _('PERMISSION_MODELS.CONTRACT_MATERIAL'),
  contractMaterialTransfer: _('PERMISSION_MODELS.CONTRACT_MATERIAL_TRANSFER'),
  warehouse: _('PERMISSION_MODELS.WAREHOUSE'),
  warehouseMaterial: _('PERMISSION_MODELS.WAREHOUSE_MATERIAL'),
  warehouseMaterialTransfer: _('PERMISSION_MODELS.WAREHOUSE_MATERIAL_TRANSFER'),

  brizPlanningWidget: _('PERMISSION_MODELS.BRIZ_PLANNING_WIDGET'),
  brizWarehouseWidget: _('PERMISSION_MODELS.BRIZ_WAREHOUSE_TRANSFER_WIDGET'),
  brizContractMaterialsWidget: _(
    'PERMISSION_MODELS.BRIZ_CONTRACT_MATERIALS_WIDGET'
  ),

  // Формула К:
  formulaKBrigade: _('PERMISSION_MODELS.FORMULAK_BRIGADE'),
  formulaKLineType: _('PERMISSION_MODELS.FORMULAK_LINE_TYPE'),
  formulaKMaterial: _('PERMISSION_MODELS.FORMULAK_MATERIAL'),
  formulaKMaterialType: _('PERMISSION_MODELS.FORMULAK_MATERIAL_TYPE'),
  formulaKObject: _('PERMISSION_MODELS.FORMULAK_OBJECT'),
  formulaKObjectMaterial: _('PERMISSION_MODELS.FORMULAK_OBJECT_MATERIAL'),
  formulaKObjectWork: _('PERMISSION_MODELS.FORMULAK_OBJECT_WORK'),
  formulaKObjectWorkReport: _('PERMISSION_MODELS.FORMULAK_OBJECT_WORK_REPORT'),
  formulaKRegion: _('PERMISSION_MODELS.FORMULAK_REGION'),
  formulaKStorageType: _('PERMISSION_MODELS.FORMULAK_STORAGE_TYPE'),
  formulaKSurfaceConditionMoisture: _(
    'PERMISSION_MODELS.FORMULAK_SURFACE_CONDITION_MOISTURE'
  ),
  formulaKSurfaceConditionPollution: _(
    'PERMISSION_MODELS.FORMULAK_SURFACE_CONDITION_POLLUTION'
  ),

  ftvWidget: _('PERMISSION_MODELS.FTV_WIDGET'),
  indexITSCargoTurnoverWidget: _(
    'PERMISSION_MODELS.INDEX_ITS_CARGO_TURNOVER_WIDGET'
  ),
  indexITSEcologyWidget: _('PERMISSION_MODELS.INDEX_ITS_ECOLOGY_WIDGET'),
  indexITSPassengerTransportationWidget: _(
    'PERMISSION_MODELS.INDEX_ITS_PASSENGER_TRANSPORTATION_WIDGET'
  ),
  indexITSRoadCongestionWidget: _(
    'PERMISSION_MODELS.INDEX_ITS_ROAD_CONGESTION_WIDGET'
  ),
  indexITSRoadSafetyWidget: _('PERMISSION_MODELS.INDEX_ITS_ROAD_SAFETY_WIDGET'),
  indexITSRoadTrafficWidget: _(
    'PERMISSION_MODELS.INDEX_ITS_ROAD_TRAFFIC_WIDGET'
  ),
};
