import { Inject, Injectable } from "@angular/core";
import {
  IDataSourceService,
  IPageParams,
  IPageReturn,
  setPageParams,
  getPageReturn,
} from "projects/msu-its-web-common/src/data/data-source";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import {
  AccidentType,
  CreateTrafficAccidentRequest,
  DeleteTrafficAccidentsRequest,
  GetPtzCamerasLocationRequest,
  GetTrafficAccidentRequest,
  GetTrafficAccidentsLocationsRequest,
  GetTrafficAccidentsRequest,
  TrafficAccident,
  UpdateTrafficAccidentRequest,
} from "../../dtos/accident.dtos";
import { AccidentApiService } from "../accident-api.service";
import { APP_ENVIRONMENT } from "projects/msu-its-web-common/src/utils/shared-consts";

@Injectable()
export class TrafficAccidentService
  implements IDataSourceService<TrafficAccident, TrafficAccident> {
  constructor(
    private _api: AccidentApiService,
    @Inject(APP_ENVIRONMENT) private _appEnv
  ) {}

  getPagedList(
    params: IPageParams,
    // customerId: string,
    requestParams: {
      customerId?: string;
      from?: string;
      to?: string;
      accidentType?: AccidentType[];
    }
  ): Observable<IPageReturn<TrafficAccident>> {
    const request = new GetTrafficAccidentsRequest();
    setPageParams(request, params);
    request.customerId = requestParams?.customerId;
    request.from = requestParams?.from;
    request.to = requestParams?.to;
    request.accidentTypes = requestParams?.accidentType
      ? requestParams?.accidentType
      : null;
    // setPageParams(request, params);
    return this._api.get(request).pipe(
      map((m) => {
        return getPageReturn(m);
      })
    );
  }

  getList(
    customerId: string,
    filter?: string,
    groupIds?: string[]
  ): Observable<TrafficAccident[]> {
    const request = new GetTrafficAccidentsRequest();
    request.customerId = customerId;

    request.entityGroups = groupIds;
    request.page = 0;
    request.items = 0;
    request.sort = "name";
    request.filter = filter;

    return this._api.get(request).pipe(
      map((m) => {
        return m.items;
      })
    );
  }

  // getList(customerId: string, filter?: string, groupIds?: string[]): Observable<Camera[]> {
  //   const request = new GetCamerasPageRequest();
  //   request.customerId = customerId;
  //   request.entityGroups = groupIds;
  //   request.page = 0;
  //   request.items = 0;
  //   request.sort = 'name';
  //   request.filter = filter;

  //   return this._api.get(request).pipe(
  //     map((m) => {
  //       return m.items;
  //     })
  //   );
  // }

  get(id: any, customerId: string): Observable<TrafficAccident> {
    const request = new GetTrafficAccidentRequest();
    request.customerId = customerId;
    request.id = id;
    return this._api.get(request);
  }

  add(
    model: TrafficAccident,
    params: {
      customerId: string;
      groups: string[];
      updateGroups: boolean;
    }
  ) {
    const request = new CreateTrafficAccidentRequest();
    request.customerId = params.customerId;
    request.entity = model;
    request.entityGroups = params.groups;
    request.updateEntityGroups = params.updateGroups;
    return this._api.post(request);
  }

  update(
    model: TrafficAccident,
    params: {
      customerId: string;
      groups: string[];
      updateGroups: boolean;
    }
  ) {
    const request = new UpdateTrafficAccidentRequest();
    request.customerId = params.customerId;
    request.id = model.id;
    request.entity = model;
    request.entityGroups = params.groups;
    request.updateEntityGroups = params.updateGroups;
    return this._api.put(request);
  }

  delete(
    ids: string[],
    requestParams: {
      customerId: string;
    }
  ): Observable<number> {
    const request = new DeleteTrafficAccidentsRequest();
    request.customerId = requestParams.customerId;
    request.ids = ids;
    return this._api.delete(request);
  }

  getLocationComponent(
    customerId: string,
    from?: string,
    to?: string,
    accidentType?: AccidentType[],
    hasKilled?: boolean,
    hasInjured?: boolean
  ) {
    const request = new GetTrafficAccidentsLocationsRequest();
    request.customerId = customerId;
    request.from = from;
    request.to = to;
    request.accidentTypes = accidentType ? accidentType : null;
    request.hasKilled = hasKilled;
    request.hasInjured = hasInjured;
    return this._api.get(request);
  }
}
