import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';

import { NgModule } from '@angular/core';

import { TelematicApiService } from '../../services/telematic-api.service';
import { DeviceService } from '../../services/device.service';

import { ISidenavMenuCategory } from 'projects/msu-its-web-common/src/utils/sidenav-menu';
import { SIDENAV_MENU } from 'projects/msu-its-web-common/src/utils/shared-consts';

const sidenav: ISidenavMenuCategory[] = [
  {
    name: _('SIDENAV_MENU.TELEMATIC'),
    items: [
      {
        name: _('DEVICES.TITLE'),
        icon: 'devices_other',
        route: 'devices',
        permissions: ['device.read'],
      },
    ],
  },
];

@NgModule({
  providers: [
    { provide: SIDENAV_MENU, useValue: sidenav, multi: true },
    TelematicApiService,
    DeviceService,
  ],
})
export class TelematicSharedModule {}
