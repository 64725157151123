<div *ngIf="loading" class="backdrop">
  <mat-spinner diameter="36" color="accent"></mat-spinner>
</div>

<h4 mat-dialog-title>
  {{ titleText }}
</h4>

<mat-dialog-content style="flex: 1; padding-bottom: 24px">
  <div fxLayout="column" style="height: 100%">
    <div
      fxLayout
      fxLayoutGap="16px"
      fxLayoutAlign="start center"
      style="
        height: 52px;
        padding-bottom: 12px;
        position: sticky;
        top: 0;
        z-index: 2;
      "
      class="mat-dialog-bg1"
    >
      <ng-content
        *ngIf="dataSource?.selection.isEmpty()"
        select="[table-layout-actions]"
      ></ng-content>

      <table-search
        *ngIf="showSearch && dataSource?.selection.isEmpty()"
        [disabled]="disabled"
        [dataSource]="dataSource"
      >
      </table-search>

      <div *ngIf="showRefresh && dataSource?.selection.isEmpty()">
        <button
          mat-icon-button
          [disabled]="disabled"
          (click)="dataSource?.reloadData()"
          title="{{ 'COMMON.REFRESH' | translate }}"
          style="margin-right: -8px"
        >
          <mat-icon>loop</mat-icon>
        </button>
      </div>

      <div
        fxLayout
        fxLayoutAlign="center center"
        *ngIf="!dataSource?.selection.isEmpty()"
      >
        {{ "COMMON.SELECTED" | translate }}
        {{ dataSource?.selection.selected.length }}
      </div>

      <button
        *ngIf="showCreate && dataSource?.selection.isEmpty()"
        mat-raised-button
        color="accent"
        [disabled]="disabled"
        (click)="add()"
      >
        <mat-icon>playlist_add</mat-icon>
        {{ addText }}
      </button>

      <button
        *ngIf="showDelete && !dataSource?.selection.isEmpty()"
        mat-raised-button
        color="primary"
        [disabled]="disabled"
        (click)="delete()"
      >
        <mat-icon>delete</mat-icon>
        {{ deleteText }}
      </button>

      <ng-content select="[table-layout-actions-end]"></ng-content>
    </div>

    <ng-content select="[table-layout-content]"></ng-content>

    <div
      fxLayout="column"
      fxFlex="grow"
      style="overflow: auto; overflow: overlay"
      class="mat-elevation-z2"
    >
      <mat-table [dataSource]="dataSource">
        <ng-container matColumnDef="select">
          <mat-header-cell *matHeaderCellDef>
            <mat-checkbox
              (change)="$event ? dataSource?.selectionAllToggle() : null"
              [checked]="dataSource?.isAllSelected()"
              [indeterminate]="
                dataSource?.selection.hasValue() && !dataSource?.isAllSelected()
              "
            >
            </mat-checkbox>
          </mat-header-cell>
          <mat-cell *matCellDef="let item">
            <mat-checkbox
              (click)="$event.stopPropagation()"
              (change)="
                $event ? dataSource?.selection.toggle(item[idField]) : null
              "
              [checked]="dataSource?.selection.isSelected(item[idField])"
            >
            </mat-checkbox>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="actions">
          <mat-header-cell *matHeaderCellDef></mat-header-cell>
          <mat-cell *matCellDef="let item">
            <button
              mat-icon-button
              [matMenuTriggerFor]="actionMenu"
              [matMenuTriggerData]="item"
              (click)="$event.stopPropagation()"
              (mousedown)="$event.stopPropagation()"
            >
              <mat-icon>more_vert</mat-icon>
            </button>
          </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row
          *matRowDef="let item; columns: displayedColumns"
          [class.selected]="dataSource?.selection.isSelected(item[idField])"
          (dblclick)="dblClick(item[idField])"
        >
        </mat-row>
      </mat-table>

      <mat-menu #actionMenu>
        <ng-template matMenuContent let-id="id">
          <button
            mat-menu-item
            *ngFor="let action of rowActions"
            (click)="rowAction(action.action, id)"
          >
            <mat-icon *ngIf="action.icon">{{ action.icon }}</mat-icon>
            {{ action.name | translate }}
          </button>
        </ng-template>
      </mat-menu>

      <mat-paginator
        #paginator
        [class.hidden]="!showPaginator"
        [pageSizeOptions]="[25, 50, 100, 500, 1000]"
        [showFirstLastButtons]="false"
      >
      </mat-paginator>
    </div>
  </div>
</mat-dialog-content>
