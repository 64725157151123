import {
  ChangeDetectorRef,
  ChangeDetectionStrategy,
  Input,
  OnDestroy,
  OnInit,
  Component,
  ViewChild,
  ElementRef,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { forkJoin, of, Subject, timer } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';

import { LANGS_LOCALES } from 'projects/msu-its-web-common/src/utils/langs';

import { SettingsService } from 'projects/msu-its-web-common/src/services/settings.service';
import { TokenService } from 'projects/msu-its-web-common/src/services/token.service';

import { ParkingStatus, Parking } from 'projects/msu-its-web-parking/src/dtos/parking.dtos';

import { ParkingApiEventService } from 'projects/msu-its-web-parking/src/services/parking-api-event.service';
import { ParkingService } from 'projects/msu-its-web-parking/src/services/parking.service';
import { MsuPirsParkingService } from 'projects/msu-its-web-parking/src/services/msu-pirs-parking.service';
import { MsuPirsStateService } from './msu-pirs-state.service';
import { getImgSrc, parkingSvg } from 'projects/msu-its-web-common/src/utils/map-markers';

@Component({
  selector: 'msu-pirs-state',
  templateUrl: './msu-pirs-state.component.html',
  styleUrls: ['./msu-pirs-state.component.css'],
  providers: [MsuPirsStateService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MsuPirsStateComponent implements OnInit, OnDestroy {
  _destroy = new Subject();

  model: Parking;
  status: ParkingStatus;

  @ViewChild('stateImage', { static: false })
  stateImage: ElementRef;

  @Input()
  modelId: string;

  @Input()
  modelPermissions: string[];

  @Input()
  customerId: string;

  get loading() {
    return this._stateService.loading;
  }

  get timezone() {
    return this._settingsService.timezone;
  }
  get locale() {
    return LANGS_LOCALES[this.translate.currentLang];
  }

  _eventChannelId: string;
  _eventChannelReceivers: any = {};

  constructor(
    private _settingsService: SettingsService,
    private _parkingService: ParkingService,
    private _msuPirsParkingService: MsuPirsParkingService,
    private _stateService: MsuPirsStateService,
    private _apiEventService: ParkingApiEventService,
    private _changeDetector: ChangeDetectorRef,
    private _tokenService: TokenService,
    public translate: TranslateService
  ) {}

  ngOnInit() {
    this._stateService.modelId = this.modelId;
    this._stateService.customerId = this.customerId;

    this._stateService.loading$.pipe(takeUntil(this._destroy)).subscribe(() => {
      this._changeDetector.markForCheck();
    });

    this._stateService.status$.pipe(takeUntil(this._destroy)).subscribe((status) => {
      this.status = status;
      this._updateStateImage();
      this._changeDetector.markForCheck();
    });

    this._updateSettings(true);
    this._subscibeStatusEvent();

    timer(30000, 30000)
      .pipe(takeUntil(this._destroy))
      .subscribe(() => this._updateSettings());
  }

  ngOnDestroy() {
    this._destroy.next();
    this._destroy.complete();
    this._destroy = null;
    this._unsubscibeStatusEvent();
  }

  _updateSettings(init = false) {
    if (this._stateService.loading) return;
    if (init) this._stateService.loading = true;
    forkJoin([
      this._parkingService.get(this.modelId, this.customerId),
      init ? this._msuPirsParkingService.getStatus(this.modelId, this.customerId) : of(null),
    ])
      .pipe(finalize(() => (this._stateService.loading = false)))
      .subscribe((result) => {
        this._stateService.parking = result[0];

        // update status if init
        if (init) {
          this._stateService.status = result[1];
          this._timerGetStatus();
        }

        this._changeDetector.markForCheck();
      });
  }

  _timerGetStatus() {
    this._msuPirsParkingService
      .getStatus(this.modelId, this.customerId)
      .pipe(takeUntil(this._destroy))
      .pipe(
        finalize(() =>
          timer(3 * 1000)
            .pipe(takeUntil(this._destroy))
            .subscribe(() => this._destroy && this._timerGetStatus())
        )
      )
      .subscribe((status) => {
        this._stateService.status = status;
      });
  }

  _subscibeStatusEvent() {
    // this._eventChannelId = `trafficObjectStatus_${this.modelId}`;
    // this._eventChannelReceivers[this._eventChannelId] = (
    //   value: TrafficObjectStatus
    // ) => {
    //   this._potokStateService.status = value;
    // };
    // this._apiEventService.subscribeServerEvents(
    //   [this._eventChannelId],
    //   this._eventChannelReceivers
    // );
  }

  _unsubscibeStatusEvent() {
    // this._apiEventService.unsubscribeServerEvents(
    //   [this._eventChannelId],
    //   this._eventChannelReceivers
    // );
  }

  _updateStateImage() {
    if (this.stateImage) {
      this.stateImage.nativeElement.src = getImgSrc(
        parkingSvg,
        this.status?.iconStatus.color,
        this.status?.iconStatus.borderColor
      );
    }
  }

  get noConnection() {
    return !this.status?.online;
  }
}
