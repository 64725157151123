import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';

import {
  Component,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  ContentChild,
  ContentChildren,
  QueryList,
  AfterContentInit,
  ChangeDetectorRef,
  OnDestroy,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MatColumnDef, MatTable } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';

import { Observable, of, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { DataSource } from '../../data/data-source';
import { TokenService } from '../../services/token.service';
import { MatMenuTrigger } from '@angular/material/menu';

export interface ITableLayoutRowAction {
  action: string;
  name: string;
  icon?: string;
  permissions?: string[];
  allowForItem?: (item: any) => boolean;
}

@Component({
  selector: 'table-layout',
  templateUrl: './table-layout.component.html',
  styleUrls: ['./table-layout.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TableLayoutComponent<T> implements AfterContentInit, OnDestroy {
  _destroy = new Subject();

  @ContentChildren(MatColumnDef, { descendants: true })
  columns: QueryList<MatColumnDef>;

  @ViewChild(MatTable, { static: true })
  table: MatTable<T>;

  @ViewChild(MatPaginator, { static: true })
  paginator: MatPaginator;

  @ContentChild(MatSort, { static: true })
  sort: MatSort;

  @Input() dataSource: DataSource<T>;
  @Input() displayedColumns: string[];
  @Input() rowActions: ITableLayoutRowAction[] = [
    { action: 'edit', name: _('COMMON.EDIT'), icon: 'edit' },
    {
      action: 'delete',
      name: _('COMMON.DELETE'),
      icon: 'delete',
    },
  ];

  @Input() disabled = false;
  @Input() showSearch = true;
  @Input() showRefresh = true;
  @Input() showCreate = true;
  @Input() showDelete = true;
  @Input() showPaginator = true;

  @Input() titleText: string;
  @Input() addText: string;
  @Input() deleteText: string;

  @Input() customerId: string;

  @Output() addBtnClick = new EventEmitter();
  @Output() deleteBtnClick = new EventEmitter();
  @Output() rowDblClick = new EventEmitter();

  @Input() getPermissions: (id: string) => Observable<string[]>;

  rowActionClick = new EventEmitter<{ action: string; id }>();

  constructor(
    private _changeDetector: ChangeDetectorRef,
    private _tokenService: TokenService,
    private _route: ActivatedRoute
  ) {
    this.customerId = this._route.snapshot.paramMap.get('customerId');
  }

  ngOnDestroy() {
    this._destroy.next();
    this._destroy.complete();
  }

  get loading() {
    return this.dataSource?.loading.value;
  }

  add() {
    this.addBtnClick.emit();
  }

  delete() {
    this.deleteBtnClick.emit();
  }

  dblClick(id) {
    this.rowDblClick.emit(id);
  }

  rowAction(action: string, id) {
    this.rowActionClick.emit({ action, id });
  }

  ngAfterContentInit() {
    this.columns.forEach((column) => this.table.addColumnDef(column));
    this.dataSource.setSortAndPaginator(this.sort, this.paginator);
    this.dataSource.loading.pipe(takeUntil(this._destroy)).subscribe(() => {
      this._changeDetector.markForCheck();
    });
  }

  get idField() {
    return this.dataSource.idField;
  }

  _itemsPermissionsCache = new Map<string, Observable<string[]>>();

  getItemPermissions(item, trigger?: MatMenuTrigger): Observable<string[]> {
    if (!this._itemsPermissionsCache.has(item.id)) {
      if (item.customerId && item.customerId == this.customerId) {
        this._itemsPermissionsCache.set(item.id, of([]));
      } else if (this.getPermissions) {
        this._itemsPermissionsCache.set(item.id, this.getPermissions(item.id));
      } else {
        this._itemsPermissionsCache.set(item.id, of([]));
      }
    }

    const result = this._itemsPermissionsCache.get(item.id);

    result.subscribe((p) => {
      const allowedActions = this.rowActions.filter((a) =>
        this.allowItemAction(a, item, p)
      );
      allowedActions.length === 0 && trigger.closeMenu();
    });

    return result;
  }

  allowItemAction(action: ITableLayoutRowAction, item, permissions: string[]) {
    const hasPerissions = this._tokenService.hasPermissionsForItem(
      item,
      this.customerId,
      action.permissions,
      permissions
    );

    const allowForItem = action.allowForItem ? action.allowForItem(item) : true;

    return hasPerissions && allowForItem;

    // if (action.permissions) {
    //   if (item.customerId && item.customerId == this.customerId) {
    //     return this._tokenService.hasPermissions(action.permissions);
    //   } else if (permissions) {
    //     return action.permissions.some((n) => permissions.includes(n));
    //   } else {
    //     return false;
    //   }
    // }
    // return true;
  }
}
