import { Injectable } from "@angular/core";

import { Observable, of } from "rxjs";
import { map } from "rxjs/operators";

import { IGisObjectGroupService } from "projects/msu-its-web-common/src/utils/gis-object-group";

import {
  getPageReturn,
  IDataSourceService,
  IPageParams,
  IPageReturn,
  setPageParams,
} from "projects/msu-its-web-common/src/data/data-source";

import { FtvApiService } from "../ftv-api.service";
import {
  CreateWeightControlPointGroupRequest,
  DeleteWeightControlPointGroupsRequest,
  EntityGroup,
  EntityGroupInfo,
  GetItemEntityGroupsRequest,
  GetWeightControlPointGroupRequest,
  GetWeightControlPointGroupsRequest,
  UpdateWeightControlPointGroupRequest,
} from "../../dtos/ftv.dtos";
import { WeightControlPointService } from "./weight-control-point.service";

@Injectable()
export class WeightControlPointGroupService
  implements
    IDataSourceService<EntityGroup, EntityGroupInfo>,
    IGisObjectGroupService {
  constructor(
    private _api: FtvApiService,
    private _modelService: WeightControlPointService
  ) {}

  permissions = ["weightControlPoint.read"];

  getGroupList(customerId: string) {
    return this.getList(customerId);
  }
  getObjectList(customerId: string, groupIds: string[]) {
    return this._modelService.getList(customerId, null, groupIds);
  }

  getObjectGroups(objectId: string, customerId: string) {
    const request = new GetItemEntityGroupsRequest({
      id: objectId,
      customerId,
    });

    return this._api.get(request).pipe(map((m) => m.items));
  }

  getPagedList(
    params: IPageParams,
    customerId: string
  ): Observable<IPageReturn<EntityGroupInfo>> {
    if (!customerId) {
      return of(null);
    }

    const request = new GetWeightControlPointGroupsRequest();
    request.customerId = customerId;

    setPageParams(request, params);
    return this._api.get(request).pipe(
      map((m) => {
        return getPageReturn(m);
      })
    );
  }
  getList(customerId: string, filter?: string, owned = false) {
    const request = new GetWeightControlPointGroupsRequest();
    request.customerId = customerId;

    request.page = 0;
    request.items = 0;
    request.sort = "name";
    request.filter = filter || "";
    request.onlyOwnedItems = owned;

    return this._api.get(request).pipe(
      map((m) => {
        return m.items;
      })
    );
  }

  get(id: string, customerId: string) {
    const request = new GetWeightControlPointGroupRequest();
    request.id = id;
    request.customerId = customerId;
    return this._api.get(request);
  }
  add(model: EntityGroup, params: { customerId: string }) {
    const request = new CreateWeightControlPointGroupRequest();
    request.customerId = params.customerId;
    request.entity = model;
    return this._api.post(request);
  }
  update(model: EntityGroup, params: { customerId: string }) {
    const request = new UpdateWeightControlPointGroupRequest();
    request.id = model.id;
    request.entity = model;
    request.customerId = params.customerId;
    return this._api.put(request);
  }
  delete(ids: string[], customerId: string): Observable<number> {
    const request = new DeleteWeightControlPointGroupsRequest();
    request.ids = ids;
    request.customerId = customerId;
    return this._api.delete(request);
  }
  exist(name: string, customerId: string) {
    return of(false);
  }
}
