import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from "@angular/core";
import { marker as _ } from "@biesbjerg/ngx-translate-extract-marker";

import { TranslateService } from "@ngx-translate/core";
import { CameraFTPPopupService } from "../../services/camera-ftp/camera-ftp-popup.service";
import { DateAdapter } from "@angular/material/core";
import { LANGS_LOCALES } from "projects/msu-its-web-common/src/utils/langs";
import { Subject } from "rxjs";
import { DialogService } from "projects/msu-its-web-common/src/services/dialog.service";
import { StaticCameraImage } from "../../dtos/accident.dtos";
import { MatDialogRef } from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { CameraFTPImagesViewerComponent } from "./camera-ftp-images-viewer.component";
import { finalize } from "rxjs/operators";

@Component({
  selector: "camera-ftp-images",
  templateUrl: "./camera-ftp-images.component.html",
  styleUrls: ["./camera-ftp-images.component.scss"],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CameraFTPImagesComponent
  implements OnInit, AfterViewInit, OnDestroy {
  _destroy = new Subject();
  loading = false;

  external = false;

  customerId: string;
  modelId: string;

  get locale() {
    return LANGS_LOCALES[this.translate.currentLang];
  }

  imagesData: StaticCameraImage[] = [];

  @ViewChild(MatPaginator, { static: true })
  paginator: MatPaginator;

  constructor(
    private _dateAdapter: DateAdapter<any>,
    private _dialogService: DialogService,
    private _dialog: MatDialogRef<CameraFTPImagesComponent>,
    public translate: TranslateService,
    private _popupImagesService: CameraFTPPopupService,
    private _changeDetector: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this._dateAdapter.setLocale(this.locale);
    this.paginator.page.subscribe(() => {
      this.loadData();
    });
  }
  ngAfterViewInit(): void {
    this.paginator.pageSize = 6;
    this.loadData();
  }

  ngOnDestroy() {
    this._destroy.next();
    this._destroy.complete();
  }
  loadData() {
    this.loading = true;
    this._popupImagesService
      .getStaticCameraImagesRequest(
        this.paginator.pageIndex,
        this.paginator.pageSize,
        this.customerId,
        this.modelId
      )
      .subscribe((result) => {
        this.imagesData = result.items;
        if (this.paginator) {
          this.paginator.length = result.total;
          this.paginator.pageIndex = result.page;
          this.paginator.pageSize = result.itemsPerPage;
        }
        this.loading = false;
        // this.drawAllImages(result.items);
      });
  }

  // drawAllImages(item: StaticCameraImage[]) {
  //   var main = document.getElementById(
  //     `photo_${this.modelId}`
  //   ) as HTMLDivElement;
  //   if (main.children.length > 0) {
  //     while (main.firstChild) {
  //       main.removeChild(main.lastChild);
  //     }
  //   }
  //   item.forEach((item: StaticCameraImage) => {
  //     // let img = document.createElement("img");
  //     this.drawImage(item, main);
  //   });
  //   this.loading = false;
  // }

  // drawImage(item: StaticCameraImage, element: HTMLDivElement) {
  //   const newDivElement = document.createElement("div");
  //   newDivElement.onclick = () => this.showViewer();
  //   newDivElement.classList.add("imageCard");

  //   const newImageElement = document.createElement("img");
  //   newImageElement.setAttribute("id", `image_ftp_${item.id}`);
  //   // newImageElement.style.objectFit = "contain";
  //   // newImageElement.style.width = "100%";
  //   // newImageElement.style.height = "100%";

  //   fetch(`data:image/png;base64,${item.image}`)
  //     .then((res) => res.blob())
  //     .then((res) => {
  //       newImageElement.src = URL.createObjectURL(res);
  //       this._changeDetector.markForCheck();
  //     });
  //   newDivElement.appendChild(newImageElement);

  //   element.childElementCount;
  //   element.appendChild(newDivElement);
  //   this._changeDetector.markForCheck();
  // }

  showViewer(id: string) {
    this.loading = true;
    this._popupImagesService
      .getImageRequest(id, this.customerId)
      .pipe(
        finalize(() => {
          this._changeDetector.markForCheck();
          this.loading = false;
        })
      )
      .subscribe((item) => {
        const img = new Image();
        img.src = `data:image/png;base64,${item.image}`;
        this.loading = false;
        img.onload = () => {
          const dialog = this._dialogService.dialog.open(
            CameraFTPImagesViewerComponent,
            {
              disableClose: true,
              maxHeight: `90vh`,
              width: `${img.width + 54}px`,
            }
          );
          dialog.componentInstance.imageData = item;
          this._changeDetector.markForCheck();
        };
        this._changeDetector.markForCheck();
      });
  }

  close() {
    if (this._dialog) {
      this._dialog.close(false);
    } else {
      window.close();
    }
  }
}
