import { ErrorHandler, NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";

import { FlashModule } from "./flash-module/flash.module";
import { DialogModule } from "./dialog-module/dialog.module";

import { TranslateModule } from "@ngx-translate/core";

import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { MatButtonModule } from "@angular/material/button";
import { MatTableModule } from "@angular/material/table";
import { MatSortModule } from "@angular/material/sort";
import {
  MatPaginatorIntl,
  MatPaginatorModule,
} from "@angular/material/paginator";

import { FlexLayoutModule } from "@angular/flex-layout";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { MatDialogModule } from "@angular/material/dialog";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatMomentDateModule } from "@angular/material-moment-adapter";
import { MatMenuModule } from "@angular/material/menu";
import { MatIconModule } from "@angular/material/icon";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { MatDividerModule } from "@angular/material/divider";
import { MatSelectModule } from "@angular/material/select";
import { MatTooltipModule } from "@angular/material/tooltip";
import { MatTabsModule } from "@angular/material/tabs";
import { MatSliderModule } from "@angular/material/slider";
import { MatButtonToggleModule } from "@angular/material/button-toggle";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatRadioModule } from "@angular/material/radio";
import { MatListModule } from "@angular/material/list";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { DragDropModule } from "@angular/cdk/drag-drop";

import { FilesDropDirective } from "../directives/file-drop.directive";
import { TrimValueDirective } from "../directives/trim-value.directive";
import { HasPermissionsPipe } from "../pipes/has-permissions.pipe";
import { HasPermissionsDirective } from "../directives/has-permissions.directive";

import { GlobalErrorHandler } from "../services/error-handler";
import { LocalizedMatPaginatorIntl } from "../services/localized-mat-paginator-intl";

import { MapComponent } from "../components/map/map.component";
import { LocationComponent } from "../components/map/location.component";
import { WebCameraComponent } from "../components/camera/web-camera.component";
import { TabnavComponent } from "../components/tabnav/tabnav.component";

import { GroupEditComponent } from "../components/groups/group-edit.component";
import { GroupSharesComponent } from "../components/groups/group-shares.component";
import { GroupShareEditComponent } from "../components/groups/group-share-edit.component";

import { TableHeaderComponent } from "../components/tables/table-header.component";
import { TableSearchComponent } from "../components/tables/table-search.component";
import { TableLayoutComponent } from "../components/tables/table-layout.component";
import { PrefixInputComponent } from "../components/prefix-input/prefix-input.component";
import { ExternalComponent } from "../components/external/external.component";
import { MatChipsModule } from "@angular/material/chips";
import { MatBadgeModule } from "@angular/material/badge";
import { AuditLogComponent } from "../components/audit-log/audit-log.component";
import { TableLayoutDialogComponent } from "../components/tables/table-layout-dialog.component";
import { EventLogComponent } from "../components/event-log/event-log.component";
import { PhoneMaskDirective } from "../directives/phone-mask.directive";
import { PhoneMaskPipe } from "../pipes/phone-mask.pipe";
import { SafeHtml } from "../pipes/safeHtml.pipe";
import { HasModulesDirective } from "../directives/has-modules.directive";
import { FilesComponent } from "../components/files/files.component";
import { MatExpansionModule } from "@angular/material/expansion";

// export * from 'projects/msu-its-web-common/src/components/camera/web-camera.component';

@NgModule({
  providers: [
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandler,
    },
    {
      provide: MatPaginatorIntl,
      useClass: LocalizedMatPaginatorIntl,
    },
  ],
  imports: [
    CommonModule,
    FlexLayoutModule,
    TranslateModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,

    MatBadgeModule,
    MatDialogModule,
    MatCheckboxModule,
    MatChipsModule,
    MatIconModule,
    MatButtonModule,
    MatInputModule,
    MatFormFieldModule,
    MatMenuModule,
    MatTableModule,
    MatTabsModule,
    MatSortModule,
    MatListModule,
    MatPaginatorModule,
    MatTooltipModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    MatSidenavModule,
    MatDividerModule,
    MatRadioModule,
    MatDatepickerModule,
    MatSelectModule,
    DragDropModule,
  ],
  declarations: [
    ExternalComponent,
    TabnavComponent,
    GroupEditComponent,
    GroupSharesComponent,
    GroupShareEditComponent,

    TableHeaderComponent,
    TableSearchComponent,
    TableLayoutComponent,
    TableLayoutDialogComponent,

    PrefixInputComponent,

    MapComponent,
    LocationComponent,
    WebCameraComponent,
    FilesComponent,

    AuditLogComponent,
    EventLogComponent,

    HasPermissionsPipe,
    HasPermissionsDirective,
    HasModulesDirective,
    PhoneMaskDirective,
    PhoneMaskPipe,
    SafeHtml,

    TrimValueDirective,
    FilesDropDirective,
  ],
  entryComponents: [],
  exports: [
    CommonModule,
    FlexLayoutModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    RouterModule,

    MatBadgeModule,
    MatButtonModule,
    MatInputModule,
    MatFormFieldModule,
    MatTableModule,
    MatSortModule,
    MatPaginatorModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    MatDialogModule,
    MatCheckboxModule,
    MatChipsModule,
    MatMomentDateModule,
    MatMenuModule,
    MatIconModule,
    MatSlideToggleModule,
    MatSliderModule,
    MatDividerModule,
    MatSelectModule,
    MatTooltipModule,
    MatTabsModule,
    MatButtonToggleModule,
    MatSidenavModule,
    MatRadioModule,
    MatListModule,
    MatDatepickerModule,
    DragDropModule,
    MatExpansionModule,

    FlashModule,
    DialogModule,

    ExternalComponent,
    TabnavComponent,
    GroupEditComponent,
    GroupSharesComponent,
    GroupShareEditComponent,

    TableHeaderComponent,
    TableSearchComponent,
    TableLayoutComponent,
    TableLayoutDialogComponent,

    PrefixInputComponent,

    MapComponent,
    LocationComponent,
    WebCameraComponent,
    FilesComponent,

    AuditLogComponent,
    EventLogComponent,

    HasPermissionsPipe,
    HasPermissionsDirective,
    HasModulesDirective,
    PhoneMaskDirective,
    PhoneMaskPipe,
    SafeHtml,

    TrimValueDirective,
    FilesDropDirective,
  ],
})
export class BaseModule {}
