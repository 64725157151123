import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';

import { NgModule } from '@angular/core';

import { TssApiService } from '../../services/tss-api.service';
import { TrafficObjectReportService } from '../../services/traffic-object-report.service';
import { TssApiEventService } from '../../services/tss-api-event.service';
import { TrafficSceneService } from '../../services/traffic-scene.service';
import { TrafficSceneGroupService } from '../../services/traffic-scene-group.service';
import { TrafficSceneGroupEventService } from '../../services/traffic-scene-group-event.service';
import { AdaptiveSceneService } from '../../services/adaptive-scene.service';
import { AdaptiveSceneGroupService } from '../../services/adaptive-scene-group.service';
import { AdaptiveSceneGroupEventService } from '../../services/adaptive-scene-group-event.service';

import { ISidenavMenuCategory } from 'projects/msu-its-web-common/src/utils/sidenav-menu';
import { IGisObjectGroup } from 'projects/msu-its-web-common/src/utils/gis-object-group';
import {
  GIS_OBJECT_GROUP,
  SIDENAV_MENU,
} from 'projects/msu-its-web-common/src/utils/shared-consts';

import { BaseModule } from 'projects/msu-its-web-common/src/modules/base.module';

import { AdaptiveSceneStateComponent } from '../../components/adaptive-scenes/adaptive-scene-state.component';

import { TrafficSceneStateComponent } from '../../components/traffic-scenes/traffic-scene-state.component';
import { TrafficScenePlanComponent } from '../../components/traffic-scenes/traffic-scene-plan.component';
import { TrafficSceneAnalysisComponent } from '../../components/traffic-scenes/traffic-scene-analysis.component';
import { TrafficObjectReportComponent } from '../../components/traffic-object-report/traffic-object-report.component';

const sidenav: ISidenavMenuCategory[] = [
  {
    name: _('SIDENAV_MENU.TSS'),
    items: [
      {
        name: _('ADAPTIVE_SCENES.TITLE'),
        icon: 'directions',
        route: 'adaptive-scenes',
        permissions: ['adaptiveScene.read'],
      },
      {
        name: _('TRAFFIC_SCENES.TITLE'),
        icon: 'directions',
        route: 'traffic-scenes',
        permissions: ['trafficScene.read'],
      },
    ],
  },
];

const trafficSceneGroup: IGisObjectGroup = {
  type: 'trafficScene',
  name: _('TRAFFIC_SCENES.TITLE'),
  groupServiceType: TrafficSceneGroupService,
  eventServiceType: TrafficSceneGroupEventService,
  stateComponentType: TrafficSceneStateComponent,
  // popupComponentType: ,
};

const adaptiveSceneGroup: IGisObjectGroup = {
  type: 'adaptiveScene',
  name: _('ADAPTIVE_SCENES.TITLE'),
  groupServiceType: AdaptiveSceneGroupService,
  eventServiceType: AdaptiveSceneGroupEventService,
  stateComponentType: AdaptiveSceneStateComponent,
  // popupComponentType: ,
};

@NgModule({
  providers: [
    { provide: SIDENAV_MENU, useValue: sidenav, multi: true },
    { provide: GIS_OBJECT_GROUP, useValue: trafficSceneGroup, multi: true },
    { provide: GIS_OBJECT_GROUP, useValue: adaptiveSceneGroup, multi: true },
    TssApiService,
    TssApiEventService,
    TrafficSceneService,
    TrafficSceneGroupService,
    TrafficSceneGroupEventService,
    AdaptiveSceneService,
    AdaptiveSceneGroupService,
    AdaptiveSceneGroupEventService,
    TrafficObjectReportService,
  ],
  imports: [BaseModule],
  declarations: [
    AdaptiveSceneStateComponent,
    TrafficObjectReportComponent,
    TrafficSceneStateComponent,
    TrafficScenePlanComponent,
    TrafficSceneAnalysisComponent,
  ],
  entryComponents: [
    AdaptiveSceneStateComponent,
    TrafficObjectReportComponent,
    TrafficSceneStateComponent,
    TrafficScenePlanComponent,
    TrafficSceneAnalysisComponent,
  ],
  exports: [AdaptiveSceneStateComponent, TrafficSceneStateComponent, TrafficObjectReportComponent],
})
export class TssSharedModule {}
