import { Injectable, OnDestroy } from '@angular/core';
import { EMPTY, of, Subject, Subscription } from 'rxjs';
import { catchError, delay, mergeMap, repeat, tap } from 'rxjs/operators';

import {
  IGisObjectGroupEventService,
  IGisObjectStatus,
} from 'projects/msu-its-web-common/src/utils/gis-object-group';

import {
  GetMaintenanceVehicleStatusesRequest,
  GetMaintenanceVehicleStatusRequest,
  MaintenanceVehicleType,
} from '../../dtos/rmmd.dtos';
import { RmmdApiEventService } from '../rmmd-api-event.service';
import { RmmdApiService } from '../rmmd-api.service';
import { getColor } from 'projects/msu-its-web-common/src/utils/colors';

@Injectable()
export class VehicleGroupEventService implements IGisObjectGroupEventService, OnDestroy {
  _subscription: Subscription;

  permissions = ['maintenanceVehicle.read'];

  constructor(private _apiService: RmmdApiService, private _eventService: RmmdApiEventService) {}

  ngOnDestroy() {
    this.unsubscribe();
  }

  subscribe(customerId: string, groupIds: string[], updateSubject: Subject<IGisObjectStatus[]>) {
    this.unsubscribe();

    const request = new GetMaintenanceVehicleStatusesRequest({
      customerId,
      entityGroups: groupIds,
      items: 0,
      page: 0,
    });

    this._subscription = of(null)
      .pipe(
        mergeMap(() => this._apiService.getUnsafe(request).pipe(catchError(() => EMPTY))),
        tap((result) =>
          updateSubject.next(
            result.items.map((item) => ({
              id: item.id,
              iconStatus: {
                // borderColor: item.,
                borderColor: getColor((item as any).iconStatus?.borderColor ?? 'white'),
                color: item.vehicleType === MaintenanceVehicleType.SnowRemoval ? '#254b7e' : '#254b7e',
              },
              data: item,
            }))
          )
        ),
        delay(5000),
        repeat()
      )
      .subscribe();
  }

  unsubscribe() {
    if (this._subscription) {
      this._subscription.unsubscribe();
      this._subscription = null;
    }
  }

  get(id: string, customerId: string) {
    const request = new GetMaintenanceVehicleStatusRequest();
    request.id = id;
    request.customerId = customerId;
    return this._apiService.get(request);
  }
}
