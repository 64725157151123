import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { CdkDragDrop } from '@angular/cdk/drag-drop';

import { BehaviorSubject, combineLatest, Subject } from 'rxjs';
import { debounceTime, startWith, takeUntil } from 'rxjs/operators';

import { TrackingDeviceInfo } from '../../dtos/tpc.dtos';
import { RecoveryMovement, RouteInfo, RouteObject, StepMovement } from '../../dtos/tlc.dtos';

import { RouteEscortState2Service } from './route-escort-state2.service';

@Component({
  selector: 'route-escort-select2',
  templateUrl: './route-escort-select2.component.html',
  styleUrls: ['./route-escort-select2.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RouteEscortSelect2Component implements OnInit, OnDestroy {
  _destroy = new Subject();

  routeSearchSubject = new Subject<string>();
  routeListSubject = new BehaviorSubject<RouteInfo[]>([]);

  trackerSearchSubject = new Subject<string>();
  trackerListSubject = new BehaviorSubject<TrackingDeviceInfo[]>([]);

  tracker2SearchSubject = new Subject<string>();
  tracker2ListSubject = new BehaviorSubject<TrackingDeviceInfo[]>([]);

  get selectedRoute() {
    return this._stateService.selectedRoute;
  }
  get tracker() {
    return this._stateService.tracker;
  }
  get tracker2() {
    return this._stateService.tracker2;
  }
  get detectionRadius() {
    return this._stateService.detectionRadius;
  }
  get suspendTime() {
    return this._stateService.suspendTime;
  }

  constructor(
    private _stateService: RouteEscortState2Service,
    private _changeDetector: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this._stateService.selectedRoute$
      .pipe(takeUntil(this._destroy))
      .subscribe(() => this._changeDetector.markForCheck());

    combineLatest([
      this._stateService.routesSubject.pipe(startWith(this._stateService.routesSubject.value)),
      this.routeSearchSubject.pipe(debounceTime(250), startWith('')),
    ])
      .pipe(takeUntil(this._destroy))
      .subscribe((result) => {
        this.routeListSubject.next(
          result[1]
            ? result[0].filter((m) =>
                `${m.name} ${m.description}`.includes(result[1].toLowerCase())
              )
            : result[0]
        );
        this._changeDetector.markForCheck();
      });

    combineLatest([
      this._stateService.trackersSubject.pipe(startWith(this._stateService.trackersSubject.value)),
      this.trackerSearchSubject.pipe(debounceTime(250), startWith('')),
    ])
      .pipe(takeUntil(this._destroy))
      .subscribe((result) => {
        this.trackerListSubject.next(
          result[1]
            ? result[0].filter((m) =>
                `${m.fullNumber} ${m.description}`.includes(result[1].toLowerCase())
              )
            : result[0]
        );
        this._changeDetector.markForCheck();
      });

    combineLatest([
      this._stateService.trackersSubject.pipe(startWith(this._stateService.trackersSubject.value)),
      this.tracker2SearchSubject.pipe(debounceTime(250), startWith('')),
    ])
      .pipe(takeUntil(this._destroy))
      .subscribe((result) => {
        this.tracker2ListSubject.next(
          result[1]
            ? result[0].filter((m) =>
                `${m.fullNumber} ${m.description}`.includes(result[1].toLowerCase())
              )
            : result[0]
        );
        this._changeDetector.markForCheck();
      });
  }

  ngOnDestroy() {
    this._destroy.next();
    this._destroy.complete();
    this._destroy = null;

    this.routeSearchSubject.complete();
    this.routeListSubject.complete();

    this.trackerSearchSubject.complete();
    this.trackerListSubject.complete();

    this.tracker2SearchSubject.complete();
    this.tracker2ListSubject.complete();
  }

  selectRoute(id?: string) {
    this._stateService.selectRoute(id).subscribe(() => {
      this._changeDetector.markForCheck();
    });
  }
  selectTracker(id?: string) {
    this._stateService.selectTracker(id);
  }
  selectTracker2(id?: string) {
    this._stateService.selectTracker2(id);
  }
  setDetectionRadius(value: string) {
    this._stateService.detectionRadius = value ? +value : 500;
  }
  setSuspendTime(value: string) {
    this._stateService.suspendTime = value ? +value : 180;
  }

  svgLoad(target, item: RouteObject, step: StepMovement | RecoveryMovement) {
    this._stateService.objectSvgLoad(target, item, step);
  }
  getAngle(item: RouteObject) {
    return this._stateService.getObjectAngle(item);
  }
  getName(item: RouteObject) {
    return this._stateService.getObjectName(item);
  }

  start() {
    this._stateService.startEscort();
  }

  drop(event: CdkDragDrop<RouteObject[]>) {
    //   if (event.previousIndex != event.currentIndex) {
    //     moveItemInArray(
    //       this.selectedRoute.settings.routeObjects,
    //       event.previousIndex,
    //       event.currentIndex
    //     );
    //     this._stateService.selectedRoute = this.selectedRoute;
    //     this._changeDetector.markForCheck();
    //   }
    // }
    // remove(item: RouteObject) {
    //   const newRoute = new Route(this.selectedRoute);
    //   newRoute.settings = new RouteSettings(this.selectedRoute.settings);
    //   newRoute.settings.routeObjects = [...newRoute.settings.routeObjects.filter((m) => m != item)];
    //   this._stateService.selectedRouteBounded = true;
    //   this._stateService.selectedRoute = newRoute;
    //   this._changeDetector.markForCheck();
  }
}
